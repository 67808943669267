// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";

import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

import { Section } from "components/layout";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { isEmpty } from "utils/object";

import { AppEnvironmentStatuses } from "../../_shared/AppEnvironmentStatuses";
import { APP_TYPES_CONFIG } from "../../constants";
import { APP_TYPE } from "../constants";
import { SubsectionWithUpdatedStatus } from "./_shared";
import { CommentableList } from "./_shared/CommentableList";
import { getNames, getOtherComment } from "./_utils/technicalDetails";
import styles from "./TechnicalDetails.scss";

// returns false, use for elements which should never be marked as updated
const isUpdatedFalse = () => false;

export class TechnicalDetails extends Component {
  static propTypes = {
    containsVideo: PropTypes.bool,
    containsLiveVideo: PropTypes.bool,
    containsVOD: PropTypes.bool,
    videoFormats: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        comment: PropTypes.string,
      })
    ),
    videoDRMs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        comment: PropTypes.string,
      })
    ),
    videoAdaptiveStreaming: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        comment: PropTypes.string,
      })
    ),
    appType: PropTypes.oneOf(Object.values(APP_TYPE)),
    appEnvironments: PropTypes.object.isRequired,
    profiles: PropTypes.object.isRequired,
  };

  static defaultProps = {
    containsVideo: false,
    containsLiveVideo: false,
    containsVOD: false,
    videoFormats: [],
    videoDRMs: [],
    videoAdaptiveStreaming: [],
  };

  isVideoUpdated = (activeVersion, latestVersion) => {
    return (
      activeVersion.technicalDetails.containsVideo !==
      latestVersion.technicalDetails.containsVideo
    );
  };

  isTypeUpdated = (activeVersion, latestVersion) => {
    const containsLiveVideoChanged =
      activeVersion.technicalDetails.containsLiveVideo !==
      latestVersion.technicalDetails.containsLiveVideo;
    const containsVODChanged =
      activeVersion.technicalDetails.containsVOD !==
      latestVersion.technicalDetails.containsVOD;

    return containsLiveVideoChanged || containsVODChanged;
  };

  isListUpdated = (key) => (activeVersion, latestVersion) => {
    const activeNames = getNames(activeVersion, key);
    const latestNames = getNames(latestVersion, key);

    return !isEqual(activeNames.slice().sort(), latestNames.slice().sort());
  };

  isCommentUpdated = (key) => (activeVersion, latestVersion) => {
    const activeComment = getOtherComment(activeVersion, key);
    const latestComment = getOtherComment(latestVersion, key);

    return activeComment !== latestComment;
  };

  renderVideoSubsections = () => {
    const {
      containsVOD,
      containsLiveVideo,
      videoFormats,
      videoDRMs,
      videoAdaptiveStreaming,
    } = this.props;
    const content = [];
    if (containsVOD) {
      content.push({ key: "vod", text: trans.APP_DETAILS_VERSION__VOD() });
    }
    if (containsLiveVideo) {
      content.push({
        key: "live-video",
        text: trans.APP_DETAILS_VERSION__LIVE_VIDEO(),
      });
    }

    return (
      <Fragment>
        <SubsectionWithUpdatedStatus
          headerContent={trans.APP_DETAILS_VERSION__VIDEO_TYPE_TITLE()}
          isUpdatedFunc={this.isTypeUpdated}
        >
          {isEmpty(content) ? (
            NO_VALUE_PLACEHOLDER
          ) : (
            <ul className={classes(styles.inlineList, styles.titleText)}>
              {content.map(({ key, text }) => (
                <li key={key}>{text}</li>
              ))}
            </ul>
          )}
        </SubsectionWithUpdatedStatus>
        <CommentableList
          listTitle={trans.APP_DETAILS_VERSION__VIDEO_FORMATS_TITLE()}
          data={videoFormats}
          isListUpdatedFunc={this.isListUpdated("videoFormats")}
          isCommentUpdatedFunc={this.isCommentUpdated("videoFormats")}
        />
        <CommentableList
          listTitle={trans.APP_DETAILS_VERSION__ADAPTIVE_STREAMINGS_TITLE()}
          data={videoAdaptiveStreaming}
          isListUpdatedFunc={this.isListUpdated("videoAdaptiveStreaming")}
          isCommentUpdatedFunc={this.isCommentUpdated("videoAdaptiveStreaming")}
        />
        <CommentableList
          listTitle={trans.APP_DETAILS_VERSION__DRMS_TITLE()}
          data={videoDRMs}
          isListUpdatedFunc={this.isListUpdated("videoDRMs")}
          isCommentUpdatedFunc={this.isCommentUpdated("videoDRMs")}
        />
      </Fragment>
    );
  };

  render() {
    const { appType, appEnvironments, containsVideo, profiles } = this.props;

    return (
      <Section
        header={trans.APP_DETAILS_VERSION__SECTION_TECHNICAL_DETAILS()}
        dataTestId="technical-details-section"
      >
        <SubsectionWithUpdatedStatus
          headerContent={trans.APP_DETAILS_VERSION__VIDEO_AVAILABLE_TITLE()}
          isUpdatedFunc={this.isVideoUpdated}
        >
          {containsVideo ? trans.YES() : trans.NO()}
        </SubsectionWithUpdatedStatus>
        {containsVideo && this.renderVideoSubsections()}
        <SubsectionWithUpdatedStatus
          headerContent={trans.APP_DETAILS_VERSION__APP_TYPE_TITLE()}
          isUpdatedFunc={isUpdatedFalse}
        >
          {APP_TYPES_CONFIG[appType]?.text ?? NO_VALUE_PLACEHOLDER}
        </SubsectionWithUpdatedStatus>
        <SubsectionWithUpdatedStatus
          headerContent={trans.APP_DETAILS_VERSION__APP_ENVIRONMENT_TITLE()}
          isUpdatedFunc={isUpdatedFalse}
        >
          <AppEnvironmentStatuses
            appType={appType}
            environments={appEnvironments}
            profiles={profiles}
          />
        </SubsectionWithUpdatedStatus>
      </Section>
    );
  }
}
