// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { combineReducers } from "redux";
import { createReducer } from "reduxReusable/createReducer";
import { selectedTargets } from "reduxReusable/selectedTargets/reducer";

import { auth } from "containers/Auth/reducer";
import { breadcrumbs } from "containers/Breadcrumbs/reducer";
import { menuSwitcher } from "containers/Layout/SideMenu/MenuSwitcher/reducer";
import { REDUX_KEY as REQUEST_REDUX_KEY } from "containers/Request/constants";
import { request } from "containers/Request/reducer";
import { SELECTED_TARGETS_ACTION_SUBTYPE as MODERATION_SELECTED_TARGETS_ACTION_SUBTYPE } from "pages/Applications/AppDetails/Moderation/redux/constants";
import { addAssetDevice } from "pages/SoftwareAssets/ModalDevices/reducer";
import { addDeviceRegions } from "pages/SoftwareAssets/ModalRegions/reducer";

export default combineReducers({
  auth,
  menuSwitcher,
  breadcrumbs,
  [REQUEST_REDUX_KEY]: request,
  addAssetDevice,
  addDeviceRegions,
  moderation: combineReducers({
    selectedTargets: createReducer(
      selectedTargets,
      MODERATION_SELECTED_TARGETS_ACTION_SUBTYPE
    ),
  }),
});
