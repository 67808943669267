// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import intersection from "lodash-es/intersection";

import { ROLES } from "../roles";

const isAllowedToEditUserInOrganization = (user, activeOrganization) => {
  const authorizations = get(user, "authorizations", []);
  const activeOrganizationId = get(activeOrganization, "id");
  const isInActiveOrg = authorizations.find(
    (auth) => auth.organization === activeOrganizationId
  );

  const allowedRoles = [ROLES.organization.roleManager];
  const roles = get(activeOrganization, "roles", []);
  const hasRequiredRole = intersection(allowedRoles, roles).length > 0;

  return Boolean(isInActiveOrg) && hasRequiredRole;
};

export const isAllowedToEditUser =
  (user, allowEditSelf = true) =>
  ({ activeOrganization, currentUser }) => {
    const userId = get(user, "id");
    const editsSelf = allowEditSelf && userId === currentUser.id;
    const roles = get(activeOrganization, "roles", []);
    const isUserAdmin = roles.some(
      (role) => role === ROLES.administrator.userAdmin
    );

    return (
      editsSelf ||
      isAllowedToEditUserInOrganization(user, activeOrganization) ||
      isUserAdmin
    );
  };
