// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const tableColPropType = PropTypes.shape({
  /** Column id - used to hide/show columns */
  id: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  /** Column name */
  label: PropTypes.node,
  /** Sort string that describes this column */
  sortBy: PropTypes.string,
});

/** Defines if table header stays in place on table scroll */
export const tableHeadPosPropType = PropTypes.oneOf(["default", "sticky"]);

/** Table layout - fixed or auto */
export const tableLayoutPropType = PropTypes.oneOf(["auto", "fixed"]);

/** Target for opening URLs */
export const tableURLPropType = PropTypes.oneOf([
  "blank",
  "parent",
  "self",
  "top",
]);
