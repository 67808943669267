// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { ValidationBadge, Label } from "components/form";
import { getAriaProps, getErrorId } from "components/form/fields";
import { classes } from "utils/classes";
import { capitalize as capitalizeStr } from "utils/string";

import styles from "./select.scss";

const LABEL_POSITION = {
  TOP: "top",
  LEFT: "left",
};

/**
 * `<Select>` component that displays list of options to choose from. It can also
 * be used as action selector.
 */
class Select extends Component {
  static propTypes = {
    /** This is mostly for scenario where we need to link custom label to select element */
    id: PropTypes.string,
    label: PropTypes.string,
    /** List of values in the select */
    values: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        disabled: PropTypes.bool,
      })
    ),
    /** Unique checkbox name. Used as HTML `name` */
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onFocus: PropTypes.func,
    /**
     * Change callback.
     *
     * Type: (e: Event) => void
     */
    onChange: PropTypes.func.isRequired,
    onSelectChange: PropTypes.func,
    required: PropTypes.bool,
    tooltip: PropTypes.string,
    /** Validation error. Shown when `touched` is true */
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    /**
     * Array of values to exclude. Corresponds to `values.value` field.
     * You cannot exclude currently selected value
     * @deprecated Please don't use this field - just filter your values properly
     */
    excluded: PropTypes.array,
    /** Allows to sort the values before the render
     * @deprecated Please, sort values before passing them into select
     */
    sort: PropTypes.func,
    /** Automatically capitalize the displayed `values.text`
     * @deprecated This should be handled in CSS
     */
    capitalize: PropTypes.bool,
    /**
     * Initially selected option. It does not have to be a value from `values`
     * prop. If provided, it is always displayed as first, disabled option.
     */
    initOption: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    }),
    /** Optional children that will be rendered between label and validation message */
    children: PropTypes.node,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    size: PropTypes.oneOf(["compact", "big"]),
    /**
     * Variants:
     *
     * - "wrapped" - has grey horizontal line below. It is most common in forms.
     * - "simple" - input with no additional decorators
     */
    look: PropTypes.oneOf(["wrapped", "simple"]),
    labelPosition: PropTypes.oneOf(Object.values(LABEL_POSITION)),
  };

  static defaultProps = {
    size: "big",
    capitalize: true,
    look: "wrapped",
    dataTestId: "select",
    values: [],
    labelPosition: LABEL_POSITION.TOP,
  };

  isExcluded(itemValue) {
    const { value, excluded } = this.props;

    return (
      Array.isArray(excluded) &&
      itemValue !== value &&
      excluded.indexOf(itemValue) !== -1
    );
  }

  render() {
    const {
      id,
      label,
      values,
      required,
      tooltip,
      error,
      touched,
      name,
      value,
      onFocus,
      onChange,
      onSelectChange,
      disabled,
      excluded,
      sort,
      capitalize,
      initOption,
      children,
      size,
      className,
      dataTestId,
      look,
      labelPosition,
    } = this.props;

    let items = values;
    let selectValue = value;

    if (capitalize) {
      items = items.map((item) => {
        const text = item.text || "";

        return {
          ...item,
          text: capitalizeStr(text),
        };
      });
    }

    if (sort) {
      items = sort(items, "text");
    }
    if (initOption) {
      items = [{ ...initOption, disabled: true }, ...items];
    }
    if (!selectValue && initOption) {
      selectValue = initOption.value;
    }
    // ultimate fallback for no value ;)
    if (selectValue === undefined && items[0] !== undefined) {
      selectValue = items[0].value;
    }
    const options = items.map((item, index) => {
      if (!excluded || !this.isExcluded(item.value)) {
        return (
          <option
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.value}_${index}`}
            disabled={item.disabled}
            value={item.value}
            data-test-id={`${dataTestId}-option`}
          >
            {item.text}
          </option>
        );
      }
      return null;
    });
    const selectWrapper = (
      <div className={styles.selectWrapper}>
        <select
          className={classes(error && touched ? styles.error : styles.normal)}
          name={name}
          value={selectValue}
          onFocus={onFocus}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            if (onSelectChange) {
              onSelectChange(e);
            }
          }}
          disabled={disabled}
          data-test-id={`${dataTestId}-input`}
          id={id}
          {...getAriaProps({ name, error, touched })}
        >
          {options}
        </select>
      </div>
    );

    return (
      <div
        data-test-id={dataTestId}
        className={classes(styles[look], styles[size], className, {
          [styles.leftPositionedCompact]:
            labelPosition === LABEL_POSITION.LEFT && size === "compact",
          [styles.leftPositionedBig]:
            labelPosition === LABEL_POSITION.LEFT && size === "big",
        })}
      >
        {label ? (
          <Label
            text={label}
            required={required}
            tooltip={tooltip}
            className={classes({
              [styles.horizontal]: labelPosition === LABEL_POSITION.LEFT,
            })}
            tooltipAlignment={
              labelPosition === LABEL_POSITION.LEFT ? "top" : "right"
            }
          >
            {selectWrapper}
          </Label>
        ) : (
          selectWrapper
        )}
        <div className={styles.children}>{children}</div>
        <ValidationBadge
          className={styles.error}
          error={error}
          touched={touched}
          errorId={getErrorId(name)}
        />
      </div>
    );
  }
}

// styleguidist does not like exporting classes that use decorators
export { Select };
