// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const ORGANIZATION_TYPES = {
  provider: "Provider",
  distributor: "Distributor",
  moderator: "Moderator",
  organization: "Organization",
  administrator: "Administrator",
  softwareUpdates: "SoftwareUpdates",
  deviceInventory: "DeviceInventory",
};
