// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { SelectField } from "components/form/fields";
import { Row, Column } from "components/layout";
import { ContentBox, Header } from "components/typography";
import { FIELDS, SECTIONS } from "pages/Snap/shared/constants";

import { DEFAULT_SOURCE, SOURCE_TYPES_HELP } from "./sourceDefinition";

export const SelectSource = ({ name, getFieldMeta }) => {
  const { value } = getFieldMeta(name);
  return (
    <Row>
      <Column>
        <Header bold={true} color="secondary" uppercase={true}>
          {SECTIONS.DETAILS.header}
        </Header>
        <SelectField
          name={name}
          values={Object.keys(SOURCE_TYPES_HELP).map((type) => ({
            text: SOURCE_TYPES_HELP[type].title,
            value: type,
          }))}
          required={true}
          capitalize={false}
          initOption={{ value: "", text: FIELDS.DETAILS.text }}
          label={FIELDS.DETAILS.label}
          dataTestId="hosting-service-field"
        />
      </Column>
      <Column>
        <ContentBox {...(value ? SOURCE_TYPES_HELP[value] : DEFAULT_SOURCE)} />
      </Column>
    </Row>
  );
};

SelectSource.propTypes = {
  name: PropTypes.string.isRequired,
  getFieldMeta: PropTypes.func.isRequired,
};
