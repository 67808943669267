// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext } from "react";

import { Loader } from "components/elements";
import { Table, TableBody, TableRow, TableRowColumn } from "components/layout";
import { TextOverflowPopup, Tooltip } from "components/popups";
import { HtmlTranslation, trans } from "src/translations";

import { FIELD_DESCRIPTION } from "../constants";
import { ManifestDataContext } from "../index";
import { ManifestPreviewModal } from "./ManifestPreviewModal";
import styles from "./ManifestSummary.scss";
import { ManifestSummaryUserScript } from "./ManifestSummaryUserScript";

const valuePrefix = (key, value) =>
  key === "keyRemapping" ? `key '${value}'` : value;

const renderKey = (key) => {
  const transFunc = FIELD_DESCRIPTION[key];
  if (typeof transFunc === "function") {
    return (
      <>
        {`${key} `}
        <Tooltip
          content={<HtmlTranslation transFunc={transFunc} component="span" />}
        >
          (?)
        </Tooltip>
      </>
    );
  }
  return key;
};

const renderValue = (value, key) => {
  if (typeof value === "boolean") {
    return value.toString();
  }

  switch (key) {
    case "userScript":
      return <ManifestSummaryUserScript value={value} />;
    case "displayIcon":
      return (
        <ManifestPreviewModal
          title={trans.APP_DETAILS_MANIFEST__DISPLAY_IMG_TITLE()}
        >
          <img src={value} className={styles.imagePreview} />
        </ManifestPreviewModal>
      );
    default:
      return <TextOverflowPopup>{value}</TextOverflowPopup>;
  }
};

const renderRow = (key, value, description) => (
  <TableRow key={`prop-${key}`}>
    <TableRowColumn columnClass={styles.nameRow}>
      {renderKey(key)}
    </TableRowColumn>
    <TableRowColumn colspan={description ? 1 : 2}>
      {renderValue(value, key)}
    </TableRowColumn>
    {description && <TableRowColumn>{description}</TableRowColumn>}
  </TableRow>
);

const renderRows = (manifest = {}) => {
  const dataKeys = Object.keys(manifest).filter(
    (key) => manifest[key] !== null
  );

  if (dataKeys.length === 0) {
    return (
      <TableRow>
        <TableRowColumn>
          {trans.APP_DETAILS_MANIFEST__CONFIG_NO_DATA()}
        </TableRowColumn>
      </TableRow>
    );
  }

  return dataKeys.map((key) => {
    const value = manifest[key];
    if (Array.isArray(value)) {
      return value.map((item) =>
        renderRow(valuePrefix(key, item.key), item.value, item.description)
      );
    }

    return renderRow(key, value);
  });
};

export const ManifestSummaryTable = () => {
  const { loading, results } = useContext(ManifestDataContext);

  if (loading) {
    return <Loader />;
  }

  return (
    <Table className={styles.summaryTable} layout="fixed">
      <TableBody>{renderRows(results?.manifest ?? {})}</TableBody>
    </Table>
  );
};
