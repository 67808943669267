// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { SearchableList } from "components/elements";
import { Label, ValidationBadge } from "components/form";
import { Checkbox } from "components/form/elements";
import { Avatar } from "components/images";
import { whenOneChange } from "utils/component";

import styles from "./MembersList.scss";

export class MembersList extends Component {
  static propTypes = {
    params: PropTypes.object,
    users: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    disabledUsers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ),
    members: PropTypes.array,
    field: PropTypes.object,
    label: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.string,
  };

  static defaultProps = {
    disabledUsers: [],
  };

  shouldComponentUpdate = whenOneChange(["users", "field"]);

  isMember(userId) {
    const { value } = this.props.field;
    return value && value.indexOf(userId) !== -1;
  }

  memberAdd(userId) {
    const { value } = this.props.field;
    const memberToAdd = userId;

    if (!this.isMember(userId)) {
      return [...value, memberToAdd];
    }

    return value.filter((el) => el !== memberToAdd);
  }

  renderRow(user) {
    const { field, disabledUsers } = this.props;

    return (
      <li className={styles.memberRow} key={user.id}>
        <label>
          <div className={styles.memberAvatar}>
            <Avatar src={user.avatar} size="30px" />
          </div>
          <div className={styles.memberInfo}>
            <span className={styles.username}> {user.username}</span>
            <span className={styles.email}>{user.email}</span>
          </div>
          <div className={styles.memberCheckbox}>
            <Checkbox
              {...field}
              checked={this.isMember(user.id)}
              onChange={() => field.onChange(this.memberAdd(user.id))}
              disabled={disabledUsers.some(
                (disabledUser) => disabledUser.id === user.id
              )}
            />
          </div>
        </label>
      </li>
    );
  }

  render() {
    const { users, label, required, error } = this.props;
    // quick hax for inconsistent API.
    const userList = users.results ? users.results : users;

    return (
      <div className={styles.memberList}>
        <Label text={label} required={required}>
          <SearchableList list={userList} searchBy="username">
            {(user) => this.renderRow(user)}
          </SearchableList>
          <ValidationBadge error={error} touched={true} />
        </Label>
      </div>
    );
  }
}
