// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField, Field, FieldArray } from "formik";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Input, Select } from "components/form/elements";
import { Column, Row } from "components/layout";
import { FIELDS } from "pages/Snap/shared/constants";
import { SectionsTable } from "pages/Snap/SnapEdit/components/Translations/SectionsTable";

import styles from "./TranslationContent.scss";

export const TranslationContent = ({
  activeTab,
  languages,
  name,
  excluded,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, , { setValue }] = useField(name);

  const totalTranslations = field.value.length;

  if (totalTranslations === 0) {
    return null;
  }

  const clearDefaultTranslations = () => {
    const fieldsReseted = field.value.map((fieldValues) => ({
      ...fieldValues,
      default_translation: false,
    }));
    setValue(fieldsReseted);
  };

  /*
   *  Live index is index to live section.
   *  Live section is unique section which is able to contain live video streams (other sections contains static videos)
   */

  const getLiveIndex = () => {
    const { sections } = field.value[activeTab];
    const liveIndex = sections.findIndex(({ live }) => {
      return Boolean(live);
    });
    return liveIndex < 0 ? false : liveIndex;
  };

  const formSection = `${name}[${activeTab}]`;

  return (
    <div>
      <Row className={styles.languageSelector}>
        <Column>
          <Field name={`${formSection}.${FIELDS.TRANSLATION_SELECT.name}`}>
            {({ field, meta }) => {
              return (
                <Select
                  required={true}
                  {...field}
                  {...meta}
                  values={languages.map(({ eng_name: text, code: value }) => ({
                    text,
                    value,
                  }))}
                  excluded={excluded}
                  capitalize={true}
                  initOption={{
                    value: "",
                    text: FIELDS.TRANSLATION_SELECT.text,
                  }}
                  label={FIELDS.TRANSLATION_SELECT.label}
                  look="simple"
                  dataTestId="language-field"
                  type="select"
                />
              );
            }}
          </Field>
        </Column>
        <Column className={styles.buttonWrapper}>
          <Field
            name={`${formSection}.${FIELDS.TRANSLATION_DEFAULT.name}`}
            component={({
              field: { value, name },
              form: { setFieldValue },
            }) => {
              return (
                <Button
                  className={styles.defaultButton}
                  disabled={totalTranslations === 1 || value}
                  onClick={(e) => {
                    e.preventDefault();
                    clearDefaultTranslations();
                    setFieldValue(name, true);
                  }}
                  dataTestId="default-language-button"
                >
                  {FIELDS.TRANSLATION_DEFAULT.label}
                </Button>
              );
            }}
          />
        </Column>
      </Row>
      <Field name={`${formSection}.${FIELDS.TRANSLATION_APP_TITLE.name}`}>
        {({ field, meta }) => (
          <Input
            required={true}
            props={field}
            {...meta}
            label={FIELDS.TRANSLATION_APP_TITLE.label}
            dataTestId="application-title-field"
          />
        )}
      </Field>
      <FieldArray
        name={`${formSection}.sections`}
        render={(arrayHelpers) => (
          <SectionsTable getLiveIndex={getLiveIndex} {...arrayHelpers} />
        )}
      />
    </div>
  );
};

TranslationContent.propTypes = {
  name: PropTypes.string.isRequired,
  excluded: PropTypes.array,
  activeTab: PropTypes.number.isRequired,
  languages: PropTypes.array.isRequired,
};
