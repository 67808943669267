// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Radio } from "components/form/elements";
import { Modal } from "components/layout";
import { trans } from "src/translations";
import {
  CONTINENT_WORLD,
  getContinentNames,
  getCountriesForContinent,
} from "utils/continents";
import { createObjectWithKeys } from "utils/object";

import { calculateOpenContinentTab } from "../TargetsField/_utils";
import { CountriesList } from "./CountriesList";
import styles from "./RegionsModal.scss";

const MODE_WORLDWIDE = "worldwide";
const MODE_COUNTRIES = "countries";

export class RegionsModal extends Component {
  static propTypes = {
    label: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
    selectedCountriesCodes: PropTypes.array,
    allCountries: PropTypes.array,
    allowEmpty: PropTypes.bool,
  };

  static defaultProps = {
    selectedCountriesCodes: [],
    allowEmpty: false,
  };

  state = {
    radioValue: MODE_COUNTRIES,
    continent: CONTINENT_WORLD,
    countries: {},
    prevIsOpen: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.isOpen !== state.prevIsOpen) {
      const { selectedCountriesCodes, allCountries } = props;

      const countries = createObjectWithKeys(selectedCountriesCodes, true);

      const { isRadioWorld, continent } = calculateOpenContinentTab(
        selectedCountriesCodes || [],
        allCountries
      );

      return {
        countries,
        radioValue: isRadioWorld ? MODE_WORLDWIDE : MODE_COUNTRIES,
        continent,
        prevIsOpen: props.isOpen,
      };
    }
    return null;
  }

  handleRadioChange = (e) => {
    this.setState({ radioValue: e.target.value });
  };

  handleTabClick = (tab) => {
    this.setState({ continent: tab.text });
  };

  handleSelect = () => {
    this.props.onSelect(this.getCountriesCodes());
  };

  handleCountriesChange = (countryCodes, value) => {
    this.setState((prevState) => ({
      countries: {
        ...prevState.countries,
        ...createObjectWithKeys(countryCodes, value),
      },
    }));
  };

  getCountriesCodes() {
    const { allCountries } = this.props;
    const { radioValue } = this.state;

    const selectedCountries =
      radioValue === MODE_WORLDWIDE
        ? allCountries
        : allCountries.filter(this.isChecked);
    return selectedCountries.map((country) => country.code);
  }

  isChecked = (country) => {
    const { countries } = this.state;
    return countries[country.code] || false;
  };

  render() {
    const { isOpen, onClose, label, allCountries, allowEmpty } = this.props;
    const { radioValue, continent, publishing } = this.state;
    const selectedCount = this.getCountriesCodes().length;

    return (
      <Modal
        title={trans.TARGETS__REGIONS_MODAL_TITLE()}
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.handleSelect}
              processing={publishing}
              disabled={allowEmpty ? false : selectedCount === 0}
            >
              {trans.DONE()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <h1 className={styles.header}>{label}</h1>
        <Radio
          dataTestId="regions-modal-radio-worldwide"
          label={trans.TARGETS__REGIONS_MODAL_WORLDWIDE()}
          defaultValue={MODE_WORLDWIDE}
          value={radioValue}
          onChange={this.handleRadioChange}
        />
        <Radio
          dataTestId="regions-modal-radio-countries"
          label={trans.TARGETS__REGIONS_MODAL_SPECIFIC_COUNTRIES()}
          defaultValue={MODE_COUNTRIES}
          value={radioValue}
          onChange={this.handleRadioChange}
        />
        {radioValue === MODE_COUNTRIES && (
          <CountriesList
            continents={getContinentNames()}
            activeContinent={continent}
            countries={getCountriesForContinent(allCountries, continent)}
            onTabClick={this.handleTabClick}
            onCountriesChecked={this.handleCountriesChange}
            isChecked={this.isChecked}
            selectedCount={selectedCount}
          />
        )}
      </Modal>
    );
  }
}
