// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable react/no-unused-prop-types */
import PropTypes from "prop-types";

import { Pill } from "components/elements";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";

import styles from "./CommandStatus.scss";
import { STATUS_NAMES } from "./constant";

const DEFAULT_EXPIRATION_TIME_MS = 30000;

const hasTTLExpired = (creationDate, timeToLive = 0) => {
  const currentTimestamp = new Date().getTime();
  const creationDateTimestamp = new Date(creationDate).getTime();
  const expirationDate =
    creationDateTimestamp + timeToLive + DEFAULT_EXPIRATION_TIME_MS;

  return expirationDate - currentTimestamp <= 0;
};

const STATUSES = {
  [STATUS_NAMES.PROCESSING]: {
    text: "PROCESSING",
    getClassName: () => styles.baseStatus,
  },
  [STATUS_NAMES.SENT]: {
    text: "SENT",
    getClassName: (props) =>
      hasTTLExpired(props.creationDate, props.timeToLive)
        ? styles.error
        : styles.baseStatus,
    getErrorMessage: (props) =>
      hasTTLExpired(props.creationDate, props.timeToLive)
        ? trans.DEVICES__COMMANDS_LIST_TTL_PASSED()
        : undefined,
  },
  [STATUS_NAMES.REJECTED]: {
    text: "REJECTED",
    getClassName: () => styles.error,
    getErrorMessage: (props) => props.errorMessage,
  },
  [STATUS_NAMES.RECEIVED]: {
    text: "RECEIVED",
    getClassName: () => styles.success,
  },
  [STATUS_NAMES.STATUS_IN_DETAILS]: {
    text: "STATUS IN DETAILS",
    getClassName: () => styles.baseStatus,
  },
};

export const CommandStatus = (props) => {
  const pillProps = STATUSES[props.status] ?? STATUSES.PROCESSING;
  const errorMessage =
    pillProps.getErrorMessage && pillProps.getErrorMessage(props);

  return (
    <Tooltip alignment="left" content={errorMessage}>
      <Pill text={pillProps.text} className={pillProps.getClassName(props)} />
    </Tooltip>
  );
};

CommandStatus.propTypes = {
  status: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  timeToLive: PropTypes.number,
  errorMessage: PropTypes.string,
};
