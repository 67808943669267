// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable react/prop-types */
import { Component } from "react";

import PropTypes from "prop-types";

import { Section } from "components/layout";
import { CommentEditor } from "pages/Applications/AppDetails/Messages/CommentEditor";
import { trans } from "src/translations";

import { MessagesList } from "./MessagesList/MessagesList";

export class MessagesRend extends Component {
  static propTypes = {
    applicationId: PropTypes.string.isRequired,
    inboxes: PropTypes.arrayOf(
      PropTypes.shape({ key: PropTypes.string, text: PropTypes.string })
    ).isRequired,
    oems: PropTypes.array,
    messages: PropTypes.object,
    messageFilters: PropTypes.array,
    markMessageAsRead: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.object,
    initialValues: PropTypes.object.isRequired,
    refreshData: PropTypes.func.isRequired,
  };

  render() {
    const {
      applicationId,
      inboxes,
      oems,
      messages,
      markMessageAsRead,
      messageFilters,
      loading,
      error,
      initialValues,
      refreshData,
    } = this.props;

    return (
      <Section>
        <Section header={trans.APP_DETAILS_MESSAGES__SECTION_NEW_MESSAGE()}>
          <CommentEditor
            applicationId={applicationId}
            inboxes={inboxes.map(({ key, text }) => ({ value: key, text }))}
            oems={oems}
            initialValues={initialValues}
            refreshData={refreshData}
          />
        </Section>
        <Section header={trans.APP_DETAILS_MESSAGES__SECTION_ALL_MESSAGES()}>
          <MessagesList
            messages={messages}
            markMessageAsRead={markMessageAsRead}
            messageFilters={messageFilters}
            loading={loading}
            error={error}
          />
        </Section>
      </Section>
    );
  }
}
