// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect } from "react";

import PropTypes from "prop-types";

import { TextareaField, RadioGroupField } from "src/components/form/fields";
import { trans } from "src/translations";

import { CHANGELOG_TYPES } from "../constants";

const radios = [
  {
    label: trans.APP_EDIT_FOR_MODERATORS__CHANGELOG_MAJOR_CHANGE_LABEL(),
    value: CHANGELOG_TYPES.REWRITE,
    dataTestId: "changelog-major-field",
  },
  {
    label: trans.APP_EDIT_FOR_MODERATORS__CHANGELOG_MINOR_CHANGE_LABEL(),
    value: CHANGELOG_TYPES.BUGFIXES,
    dataTestId: "changelog-minor-field",
  },
  {
    label: trans.APP_EDIT_FOR_MODERATORS__CHANGELOG_ASSETS_CHANGE_LABEL(),
    value: CHANGELOG_TYPES.NOCODECHANGES,
    dataTestId: "changelog-assets-field",
  },
  {
    label: trans.OTHER(),
    value: CHANGELOG_TYPES.OTHER,
    dataTestId: "changelog-other-field",
  },
];

export const Changelog = ({
  changelogTypeField,
  changelogAdditionalInfoField,
  getFieldProps,
  setFieldValue,
}) => {
  useEffect(() => {
    const { value } = getFieldProps(changelogTypeField);
    if (value === CHANGELOG_TYPES.INITIAL) {
      setFieldValue(changelogTypeField, CHANGELOG_TYPES.REWRITE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RadioGroupField
        key={"changelog-radio"}
        name={changelogTypeField}
        label={trans.SCOPE()}
        inline={false}
        values={radios}
        required={true}
      />
      <TextareaField
        name={changelogAdditionalInfoField}
        label={trans.APP_EDIT_FOR_MODERATORS__APP_CHANGELOG_DESCRIPTION_LABEL()}
        tooltip={trans.APP_EDIT_FOR_MODERATORS__APP_CHANGELOG_DESCRIPTION_LABEL_TOOLTIP()}
        required={true}
        dataTestId="changelog-description-field"
      />
    </>
  );
};

Changelog.propTypes = {
  changelogTypeField: PropTypes.string.isRequired,
  changelogAdditionalInfoField: PropTypes.string.isRequired,

  // from @Formik
  getFieldProps: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
