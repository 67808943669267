// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import queryString from "query-string";

import { GrabsonIcon } from "components/icons";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import { isValidPageNumber, createPageUrl } from "utils/pagination";

import { PageInput } from "./PageInput";
import { Results } from "./Results";
import styles from "./TablePagination.scss";

const FIRST_PAGE = 1;

/**
 * Table pagination. Automatically creates links to other pages using
 * `react-router` convention.
 */
@withRouter
class TablePagination extends Component {
  static propTypes = {
    /** How many items are per each page */
    rowsPerPage: PropTypes.number.isRequired,
    /** Total count of items */
    totalCount: PropTypes.number.isRequired,
    dataTestId: PropTypes.string,
    legend: PropTypes.node,

    // from @withRouter
    /** @ignore */
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    dataTestId: "pagination",
  };

  getCurrentPage() {
    const {
      location: { search },
    } = this.props;
    return Number(queryString.parse(search).page) || FIRST_PAGE;
  }

  getPagesCount() {
    const { totalCount, rowsPerPage } = this.props;
    return Math.ceil(totalCount / rowsPerPage);
  }

  createLink(page, children, key) {
    const { location } = this.props;

    const pageUrl = createPageUrl(location, page);

    return (
      <div key={key}>
        <Link to={pageUrl}>{children}</Link>
      </div>
    );
  }

  previous() {
    const { dataTestId } = this.props;

    const previousPage = this.getCurrentPage() - 1;
    const pagesCount = this.getPagesCount();

    if (isValidPageNumber(pagesCount, previousPage)) {
      return this.createLink(
        previousPage,
        <p data-test-id={`${dataTestId}-previous`} className={styles.previous}>
          <GrabsonIcon
            name="arrow-left"
            size="sm"
            font="micro"
            className={styles.arrowIcon}
          />
          <span className={styles.buttonText}>{trans.PREVIOUS()}</span>
        </p>,
        "previous"
      );
    }
    return null;
  }

  next() {
    const { dataTestId } = this.props;

    const nextPage = this.getCurrentPage() + 1;
    const pagesCount = this.getPagesCount();

    if (isValidPageNumber(pagesCount, nextPage)) {
      return this.createLink(
        nextPage,
        <p data-test-id={`${dataTestId}-next`} className={styles.next}>
          <span className={styles.buttonText}>{trans.NEXT()}</span>
          <GrabsonIcon
            name="arrow-right"
            size="sm"
            font="micro"
            className={styles.arrowIcon}
          />
        </p>,
        "next"
      );
    }
    return null;
  }

  last() {
    const { dataTestId } = this.props;

    return this.createLink(
      this.getPagesCount(),
      <p data-test-id={`${dataTestId}-last`}>
        <span className={styles.buttonText}>{this.getPagesCount()}</span>
      </p>,
      "last"
    );
  }

  render() {
    const { dataTestId, rowsPerPage, totalCount, legend } = this.props;

    const currentPage = this.getCurrentPage();
    // show page numbers if there is more than one page
    const showNavigation = totalCount > rowsPerPage;

    return (
      <div className={styles.paginationWrapper}>
        <Results
          page={currentPage}
          total={totalCount}
          rowsPerPage={rowsPerPage}
          legend={legend}
        />
        {showNavigation && (
          <div data-test-id={dataTestId} className={styles.pagination}>
            {this.previous()}
            <PageInput
              dataTestId={dataTestId}
              currentPage={currentPage}
              lastPage={this.getPagesCount()}
            />{" "}
            - {this.last()}
            {this.next()}
          </div>
        )}
      </div>
    );
  }
}

// styleguidist does not like exporting classes that use decorators
export { TablePagination };
