// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { restrictedArea } from "containers/Permissions";
import { classes } from "utils/classes";

import styles from "./subItem.scss";

@restrictedArea(({ allowed }) => ({
  allowed: allowed,
  showFallback: false,
}))
export class SubItem extends Component {
  static propTypes = {
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    href: PropTypes.string,
    label: PropTypes.string.isRequired,
    collapsed: PropTypes.bool.isRequired,
    exact: PropTypes.bool,
    isActive: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    allowed: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    ),
    dataTestId: PropTypes.string,
    props: PropTypes.object,
  };

  static defaultProps = {
    exact: true,
    dataTestId: "sub-item",
    props: {},
  };

  isActive = () => this.props.isActive();

  render() {
    const { path, href, label, collapsed, exact, dataTestId, props } =
      this.props;

    return (
      <li>
        {path ? (
          <NavLink
            end={exact}
            className={({ isActive }) => {
              const isCollapsed = collapsed ? styles.collapse : styles.expand;
              const isActiveLink =
                isActive || this.props.isActive ? styles.active : undefined;
              return classes(isCollapsed, isActiveLink);
            }}
            to={path}
            data-test-id={dataTestId}
            {...props}
          >
            <span>{label}</span>
          </NavLink>
        ) : (
          <a
            data-test-id={dataTestId}
            href={href}
            className={collapsed ? styles.collapse : styles.expand}
            {...props}
          >
            {label}
          </a>
        )}
      </li>
    );
  }
}
