// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { ROLES } from "containers/Permissions";
import { allowInOrganization } from "containers/Permissions/groups";
import { trans } from "src/translations";

import certification from "./certificationSection";
import contracts from "./contractsSection";
import distribution from "./distributionSection";

export default {
  name: trans.APP_EDIT_CERTIFICATION__TAB(),
  url: "certification-distribution-contract",
  sections: [certification, distribution, contracts],
  permissions: (props) => ({
    allowed: [
      allowInOrganization(props.appData.owner_public_id, [
        ROLES.organization.owner,
        ROLES.provider.signatory,
        ROLES.provider.creator,
      ]),
    ],
  }),
  dataTestId: "agreements-tab",
};
