// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useField } from "formik";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { ValidationBadge } from "components/form";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableNoResults,
} from "components/layout";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

import { AddOrganizationModal } from "./AddOrganizationModal";
import { OrganizationItem } from "./OrganizationItem";
import styles from "./OrganizationsTable.scss";
import { haveOrganizationsChanged } from "./utils";

const promotionItemsColumns = [
  {
    id: "organization-name",
    label: trans.PROMOTION_TYPE_EDIT__ORGANIZATIONS_TABLE_HEADER(),
  },
];

const transformOrganizationsData = (organizationsData) =>
  organizationsData.results.reduce((acc, response) => {
    const id = response.id;
    return {
      ...acc,
      [id]: {
        id,
        name: response.name,
      },
    };
  }, {});

const Body = ({ loading, error, organizations }) => {
  const organizationsArray = Object.entries(organizations).map(
    ([, organization]) => organization
  );

  if (error) {
    return (
      <TableNoResults
        message={trans.DEFAULT_REQUEST_ERROR_MESSAGE()}
        isError={Boolean(error)}
        colspan={1}
        loading={loading}
      />
    );
  }

  if (loading) {
    return <TableNoResults colspan={1} loading={loading} />;
  }

  if (!organizationsArray.length) {
    return (
      <TableBody>
        <OrganizationItem
          organization={{
            name: trans.PROMOTION_TYPE_EDIT__ORGANIZATIONS_NO_ITEMS(),
          }}
        />
      </TableBody>
    );
  }

  return (
    <TableBody>
      {organizationsArray.map((organization) => (
        <OrganizationItem key={organization.id} organization={organization} />
      ))}
    </TableBody>
  );
};

Body.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  organizations: PropTypes.object.isRequired,
};

const AvailabilityComponent = ({ name, getOrganizations }) => {
  const [field, meta, helpers] = useField(name);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizations, setOrganizations] = useState(null);

  const updateOrganizations = useCallback(
    async (organizationIds) => {
      setIsLoading(true);
      setError(null);
      try {
        if (organizationIds.length === 0) {
          setOrganizations({});
        } else {
          const organizationsData = await getOrganizations(organizationIds);
          const fetchedOrganizations =
            transformOrganizationsData(organizationsData);
          setOrganizations(fetchedOrganizations);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [getOrganizations]
  );

  const setupOrganizations = useCallback(async () => {
    if (isLoading) {
      return;
    }
    updateOrganizations(field.value);
  }, [field.value, isLoading, updateOrganizations]);

  useEffect(() => {
    if (!organizations) {
      setupOrganizations();
    } else {
      const nextOrganizationIds = Object.keys(organizations);
      if (haveOrganizationsChanged(field.value, nextOrganizationIds)) {
        helpers.setValue(nextOrganizationIds);
      }
    }
  }, [field.value, helpers, organizations, setupOrganizations]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleModalSubmit = async (organizationIds) => {
    toggleModal();
    await updateOrganizations(organizationIds);
  };

  return (
    <>
      <Table layout="fixed">
        <TableHeader position="sticky" loading={isLoading}>
          {promotionItemsColumns.map(({ id, label, className }) => (
            <TableHeaderColumn key={id} className={className}>
              {label}
            </TableHeaderColumn>
          ))}
        </TableHeader>
        <Body
          organizations={organizations ?? {}}
          error={error}
          loading={isLoading}
        />
      </Table>
      <ValidationBadge {...meta} />

      {isModalOpen && (
        <AddOrganizationModal
          selectedOrganizations={field.value}
          isOpen={isModalOpen}
          onClose={toggleModal}
          onSubmit={handleModalSubmit}
        />
      )}
      <div className={styles.addButtonContainer}>
        <Button dataTestId="add-promotion-button" onClick={toggleModal}>
          {trans.PROMOTION_TYPE_EDIT__ORGANIZATIONS_MANAGE()}
        </Button>
      </div>
    </>
  );
};

AvailabilityComponent.propTypes = {
  name: PropTypes.string.isRequired,
  getOrganizations: PropTypes.func.isRequired,
};

export const AvailabilityField = connect(null, (dispatch) => ({
  getOrganizations: (ids) =>
    dispatch(
      apiThatThrows.getOrganizationsPaginated.action({
        queryParams: { id: ids, limit: ids.length, page: 1 },
      })
    ),
}))(AvailabilityComponent);
