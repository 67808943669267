// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getDeviceHistoricalPdidsQuery = gql`
  query deviceHistoricalPdids(
    $deviceId: String!
    $pagination: PaginationInput!
  ) {
    deviceHistoricalPdids(deviceId: $deviceId, pagination: $pagination) {
      pdids {
        id
        isCurrent
        alertState
        lastUsedAt
        generatedAt
        vcc
      }
    }
  }
`;
