// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { ROLES, restrictedArea } from "containers/Permissions";
import { withCountries } from "utils/continents";
import { withRouter, withSearch, withSorting } from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import {
  ITEMS_PER_PAGE,
  getDeviceListErrorMessage,
  TABS_CONFIG,
} from "../_shared/DeviceInventoryTable";
import { DeviceFilterDetailsRend } from "./DeviceFilterDetailsRend";
import { getDeviceFilterQuery, getDeviceListQuery } from "./gql";
import { mapFilterDetailsForSearchBar } from "./utils";

export const DETAILS_TAB = "details";
export const DEVICE_LIST_TAB = "devices";

@restrictedArea(() => ({ allowed: [ROLES.deviceInventory.deviceManager] }))
@withActiveOrganization
@withRouter
@withSearch
@withQuery({
  name: "getDeviceFilter",
  query: getDeviceFilterQuery,
})
@withQuery({
  name: "getDeviceList",
  query: getDeviceListQuery,
})
@withCountries
@withSorting({
  defaultSortBy: "last_activity_date",
  allowedSortByValues: TABS_CONFIG[0].columns
    .map(({ sortBy }) => sortBy)
    .filter((column) => Boolean(column)),
  defaultSortOrder: "DESC",
})
export class DeviceFilterDetailsLayoutWithData extends PureComponent {
  static propTypes = {
    // from @withRouter

    params: PropTypes.shape({
      id: PropTypes.string,
      tabId: PropTypes.string,
    }),

    location: PropTypes.object,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withQuery
    getDeviceFilterQuery: PropTypes.func,
    getDeviceFilterQueryStatus: queryResultType,
    getDeviceListQuery: PropTypes.func,
    getDeviceListQueryStatus: queryResultType,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withCountries
    countriesLoading: PropTypes.bool.isRequired,
    countriesError: PropTypes.object,
    allCountries: PropTypes.array.isRequired,

    // from @withSorting
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  };

  async componentDidMount() {
    this.fetchDeviceFilterDetails();
    this.fetchDeviceList();
  }

  async componentDidUpdate(prevProps) {
    const changedOrganization =
      this.props.activeOrganization.id !== prevProps.activeOrganization.id;
    if (changedOrganization) {
      this.fetchDeviceFilterDetails();
    }

    const isDevicesTab = this.getTabId() === DEVICE_LIST_TAB;
    const changedLocation = this.props.location !== prevProps.location;
    if (isDevicesTab && changedLocation) {
      this.fetchDeviceList();
    }
  }

  getDeviceFilterId = () => this.props.params.id;

  getTabId() {
    const { tabId } = this.props.params;
    return tabId === DEVICE_LIST_TAB ? DEVICE_LIST_TAB : DETAILS_TAB;
  }

  fetchDeviceFilterDetails = async () => {
    const { getDeviceFilterQuery } = this.props;

    await getDeviceFilterQuery({
      variables: {
        id: this.getDeviceFilterId(),
      },
    });
  };

  fetchDeviceList = () => {
    const { getDeviceListQuery, searchValue, sortBy, sortOrder } = this.props;
    getDeviceListQuery({
      variables: {
        id: this.getDeviceFilterId(),
        search: {
          query: searchValue,
        },
        sort: {
          name: sortBy.toUpperCase(),
          order: sortOrder,
        },
        pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
      },
    });
  };

  getDeviceFilterData = () => {
    const { data, error, loading } = this.props.getDeviceFilterQueryStatus;
    const details = get(data, "deviceFilter.deviceFilter");
    const availableFilters = get(
      data,
      "deviceSearchSuggestions.deviceSearchSuggestions"
    );

    return {
      error: error && getGqlErrorMessage(error),
      loading: loading || !details,
      data: details
        ? mapFilterDetailsForSearchBar(details, availableFilters)
        : undefined,
    };
  };

  getDeviceListData = () => {
    const { data, error, loading } = this.props.getDeviceListQueryStatus;

    return {
      error: getDeviceListErrorMessage(error),
      loading,
      data: get(data, "deviceFilter.deviceFilter.devices.items", []),
      count: get(data, "deviceFilter.deviceFilter.devices.pagination.count", 0),
    };
  };

  render() {
    const {
      searchValue,
      changeSearch,
      countriesError,
      countriesLoading,
      allCountries,
    } = this.props;
    const deviceFilterStatus = this.getDeviceFilterData();
    const deviceListStatus = this.getDeviceListData();
    const name = get(deviceFilterStatus, "data.name", "");
    return (
      <Fragment>
        <PageHeader>{name}</PageHeader>
        <PageContent>
          <DeviceFilterDetailsRend
            filterId={this.getDeviceFilterId()}
            tabId={this.getTabId()}
            countries={{
              error: Boolean(countriesError),
              loading: countriesLoading,
              allCountries,
            }}
            deviceFilter={deviceFilterStatus}
            deviceList={deviceListStatus}
            searchValue={searchValue}
            changeSearch={changeSearch}
            refreshList={this.fetchDeviceList}
          />
        </PageContent>
      </Fragment>
    );
  }
}
