// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";

import { PromotionDetailsData } from "./PromotionDetailsData";

@withRouter
export class PromotionDetails extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,

    // from @withRouter
    params: PropTypes.object.isRequired,
  };

  isEdit() {
    const { params } = this.props;
    return typeof params.id !== "undefined";
  }

  render() {
    const { forAdmin } = this.props;

    return (
      <div>
        <PageHeader>
          {this.isEdit()
            ? trans.PROMOTION_EDIT__PAGE_HEADER()
            : trans.PROMOTION_CREATE__PAGE_HEADER()}
        </PageHeader>
        <PageContent>
          <PromotionDetailsData forAdmin={forAdmin} />
        </PageContent>
      </div>
    );
  }
}
