// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withRouter, withSearch, withSorting } from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import { DefaultSortContext } from "../../_shared/Context/defaultSortContext";
import { listUpdateJobsQuery } from "./gql/listUpdateJobsQuery";
import { UpdateJobListRend } from "./UpdateJobListRend";

export const ITEMS_PER_PAGE = 50;
export const ACTIVE_JOBS_TAB = "active";
export const ARCHIVE_JOBS_TAB = "archive";
// Steps start from 0, but we want for user to see it as first step
const NUMBER_TO_CONVERT_STEPS_TO_READABLE_FORMAT = 1;

@restrictedArea(() => ({
  allowed: [ROLES.softwareUpdates.updateManager],
}))
@withQuery({ name: "listUpdateJobs", query: listUpdateJobsQuery })
@withSearch
@withRouter
@withActiveOrganization
@withSorting({
  defaultSortBy: "created_at",
  allowedSortByValues: [
    "name",
    "created_at",
    "status",
    "update_step",
    "is_repeatable",
  ],
  defaultSortOrder: "DESC",
})
export class UpdateJobListData extends PureComponent {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object,

    params: PropTypes.shape({
      tabId: PropTypes.string,
    }),

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withQuery
    listUpdateJobsQuery: PropTypes.func,
    listUpdateJobsQueryStatus: queryResultType,

    //from @withSorting
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  };

  state = {
    createdUpdateJobName: null,
    deletedUpdateJobName: null,
    sortingDefault: {
      sortBy: this.props.sortBy,
      sortOrder: this.props.sortOrder,
    },
  };

  componentDidMount() {
    this.fetchUpdateJobs();

    const { state } = this.props.location;
    if (state && state.updateJobName) {
      this.setState({
        createdUpdateJobName: state.updateJobName,
      });
    }

    if (state && state.deletedUpdateJobName) {
      this.setState({
        deletedUpdateJobName: state.deletedUpdateJobName,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location === prevProps.location &&
      this.props.activeOrganization.id === prevProps.activeOrganization.id
    ) {
      return;
    }

    this.setState({
      createdUpdateJobName: null,
      deletedUpdateJobName: null,
    });
    this.fetchUpdateJobs();
  }

  fetchUpdateJobs = () => {
    const { listUpdateJobsQuery, sortBy, sortOrder, searchValue } = this.props;

    listUpdateJobsQuery({
      variables: {
        pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
        sort: {
          name: sortBy.toUpperCase(),
          order: sortOrder,
        },
        search: {
          query: searchValue || "",
          isArchived: this.props.params.tabId === ARCHIVE_JOBS_TAB,
        },
      },
    });
  };

  getUpdateStepColumnData = (activeStepId, steps) => {
    const stepIndex = steps.findIndex((step) => activeStepId === step.id);
    if (activeStepId === null || stepIndex === -1) {
      return "-";
    }
    const activeStepNum =
      stepIndex + NUMBER_TO_CONVERT_STEPS_TO_READABLE_FORMAT;
    const allStagesNum = steps.length;
    return `${activeStepNum} of ${allStagesNum}`;
  };

  refetch = () => {
    this.setState({
      createdUpdateJobName: null,
      deletedUpdateJobName: null,
    });

    return this.props.listUpdateJobsQueryStatus.refetch();
  };

  setUpdateJobDeleted = (id) => {
    const updateJob = this.getResults().results.find((job) => job.id === id);
    if (!updateJob) {
      return;
    }

    this.setState({
      deletedUpdateJobName: updateJob.name,
    });
  };

  getResults = () => {
    const { data, loading, error } = this.props.listUpdateJobsQueryStatus;

    const count = get(data, "updateJobs.updateJobs.pagination.count", 0);
    const results = get(data, "updateJobs.updateJobs.items", []);

    return {
      results: results.map(this.adaptUpdateJob),
      count,
      loading,
      refetch: this.refetch,
      error: error && getGqlErrorMessage(error),
    };
  };

  adaptUpdateJob = (updateJob) => {
    return {
      id: updateJob.id,
      name: updateJob.name,
      status: updateJob.status,
      statusActions: updateJob.statusActions,
      creationDate: new Date(updateJob.creationDate),
      updateStep: this.getUpdateStepColumnData(
        updateJob.activeStepId,
        updateJob.steps
      ),
      isRepeatable: updateJob.isRepeatable,
    };
  };

  render() {
    const {
      params: { tabId },
      changeSearch,
      searchValue,
    } = this.props;

    const { createdUpdateJobName, deletedUpdateJobName, sortingDefault } =
      this.state;

    return (
      <DefaultSortContext.Provider value={sortingDefault}>
        <UpdateJobListRend
          {...this.getResults()}
          createdUpdateJobName={createdUpdateJobName}
          deletedUpdateJobName={deletedUpdateJobName}
          setUpdateJobDeleted={this.setUpdateJobDeleted}
          onSearch={changeSearch}
          searchValue={searchValue}
          tabId={tabId}
        />
      </DefaultSortContext.Provider>
    );
  }
}
