// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

// https://www.apollographql.com/docs/apollo-server/data/errors/
export const APOLLO_STATUS = {
  BAD_REQUEST: "BAD_REQUEST",
  BAD_USER_INPUT: "BAD_USER_INPUT",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  // API Gateway
  UNAUTHENTICATED: "UNAUTHENTICATED",
};

export const DEFAULT_APOLLO_OPTIONS = {
  notifyOnNetworkStatusChange: true,
  fetchPolicy: "network-only",
};

// https://grpc.github.io/grpc/core/md_doc_statuscodes.html
export const GRPC_STATUS = {
  OK_0: 0,
  INVALID_ARGUMENT_3: 3,
  NOT_FOUND_5: 5,
  ALREADY_EXISTS_6: 6,
  PERMISSION_DENIED_7: 7,
  _END: 17,
};
