// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

import { Subsection, Accordion } from "components/layout";
import {
  IsVersionUpdated,
  HeaderContentWithUpdatedLabel,
} from "pages/Applications/AppDetails/AppVersions/_shared/IsVersionUpdated";
import { trans } from "src/translations";
import { getContinentNames, getCountriesForContinent } from "utils/continents";

import styles from "./Markets.scss";

export class Markets extends Component {
  static propTypes = {
    simple: PropTypes.bool,
    selectedLocations: PropTypes.object.isRequired,
    allCountries: PropTypes.array,
    countryCodesKey: PropTypes.oneOf([
      "app_designed_for",
      "app_is_available_on",
    ]),
  };

  static defaultProps = {
    allCountries: [],
    simple: false,
  };

  static getHeaderText(region, howMany, from) {
    let header = "";

    if (howMany === from) {
      header = `${region} (${trans.ALL()})`;
    } else {
      header = `${region} (${howMany}/${from})`;
    }

    return header;
  }

  static renderCountryList(selectedCountries) {
    return (
      <ul className={styles.countryList}>
        {selectedCountries.map((country) => (
          <li key={country.name} className={styles.item}>
            {country.name}
          </li>
        ))}
      </ul>
    );
  }

  isSelected = (country) => {
    const { selectedLocations } = this.props;
    return selectedLocations.country_codes.includes(country.code);
  };

  isRegionUpdated = (allRegionCountries) => (activeVersion, latestVersion) => {
    const { countryCodesKey } = this.props;
    const allRegionCountryCodes = allRegionCountries.map((c) => c.code);

    const getCountryCodes = (version) => {
      return version.markets[countryCodesKey].country_codes;
    };

    const activeCountryCodes = getCountryCodes(activeVersion, countryCodesKey);
    const latestCountryCodes = getCountryCodes(latestVersion, countryCodesKey);

    const activeCountries = activeCountryCodes.filter((c) => {
      return allRegionCountryCodes.includes(c);
    });
    const latestCountries = latestCountryCodes.filter((c) => {
      return allRegionCountryCodes.includes(c);
    });

    return !isEqual(activeCountries.sort(), latestCountries.sort());
  };

  updatedMarkets = (region, allRegionCountries, selectedCountries) => {
    return (
      <IsVersionUpdated isUpdated={this.isRegionUpdated(allRegionCountries)}>
        {(isUpdated) => (
          <Accordion
            header={
              <HeaderContentWithUpdatedLabel
                content={Markets.getHeaderText(
                  region,
                  selectedCountries.length,
                  allRegionCountries.length
                )}
                isUpdated={isUpdated}
              />
            }
            disabled={!selectedCountries.length}
          >
            {Markets.renderCountryList(selectedCountries)}
          </Accordion>
        )}
      </IsVersionUpdated>
    );
  };

  simpleMarkets = (region, allRegionCountries, selectedCountries) => {
    return (
      <Accordion
        header={Markets.getHeaderText(
          region,
          selectedCountries.length,
          allRegionCountries.length
        )}
        disabled={!selectedCountries.length}
      >
        {Markets.renderCountryList(selectedCountries)}
      </Accordion>
    );
  };

  render() {
    const { allCountries, simple } = this.props;

    return getContinentNames().map((region) => {
      const allRegionCountries = getCountriesForContinent(allCountries, region);
      const selectedCountries = allRegionCountries.filter(this.isSelected);

      return (
        <Subsection key={region}>
          {simple
            ? this.simpleMarkets(region, allRegionCountries, selectedCountries)
            : this.updatedMarkets(
                region,
                allRegionCountries,
                selectedCountries
              )}
        </Subsection>
      );
    });
  }
}
