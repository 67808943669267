// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";
import { innerRefType } from "utils/types";

import { tableLayoutPropType } from "./propTypes";
import styles from "./Table.scss";

/**
 * This component allows to easly display tabular data. It may be preferable to use
 * [TabbedTable](#tabbedtable) instead as it is simpler to setup. When using `<Table>`
 * You have to manually set:
 *
 * - no result message
 * - table headers
 * - isLoading state
 * - pagination buttons
 *
 * This component uses url as a means of storing table metadata like sorting,
 * pagination etc.
 *
 *
 * Related components:
 *
 * - [TableActions](#tableactions) &ndash; place for addional table actions like pagination or export. Should be placed before `<Table>` tag.
 * - [TableBody](#tablebody) &ndash; corresponds to `<tbody>`.
 * - [TableHeader](#tableheade) &ndash; corresponds to `<thead>` and children `<tr>`.
 * - [TableHeaderColumn](#tableheadercolumn) &ndash; corresponds to `<th>`.
 * - [TableLoader](#tableloader) &ndash; part of `<TableHeader>`, indicates loading data.
 * - [TableNoResults](#tablenoresults) &ndash; information about no matching results.
 * - [TableRow](#tablerow) &ndash; corresponds to `<tr>`.
 * - [TableSubRow](#tablesubrow) &ndash; describes subrows of the table. Should be child of `<TableRow>`.
 * - [TableRowColumn](#tablerowcolumn) &ndash; very simple table cell data. Corresponds to `<td>`.
 * - [TableRowColumnExtended](#tablerowcolumnextended) &ndash; complex yet flexible table cell data. Corresponds to `<td>`, but has many extensions.
 * - [TabbedTable](#tabbedtable) &ndash; table component with many usueful features. Preferable to `<Table>`.
 *
 */
export class Table extends Component {
  static propTypes = {
    /**
     * Table elements. Should be an array of:
     *
     * - [TableHeader](#tableheader) (max one)
     * - [TableBody](#tablebody) (can be many)
     */
    children: PropTypes.node,
    /** User class name. */
    className: PropTypes.string,
    innerRef: innerRefType,
    layout: tableLayoutPropType,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    layout: "auto",
    dataTestId: "table",
  };

  render() {
    const { children, className, layout, dataTestId, innerRef } = this.props;

    return (
      <table
        data-test-id={dataTestId}
        ref={innerRef}
        className={classes(styles[layout], className)}
      >
        {children}
      </table>
    );
  }
}

export default Table;
