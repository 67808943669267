// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

export const UserActionMenu = ({ user, setActive }) => (
  <ActionMenu>
    <MenuItem to={`/users/admin/${user.id}/edit`} dataTestId="edit-item">
      {trans.EDIT_DETAILS()}
    </MenuItem>
    <MenuItem
      onClick={() => setActive(user.id, !user.is_active)}
      dataTestId="set-active-item"
    >
      {user.is_active
        ? trans.USER_ADMIN__DEACTIVATE_USER()
        : trans.USER_ADMIN__ACTIVATE_USER()}
    </MenuItem>
  </ActionMenu>
);

UserActionMenu.propTypes = {
  user: PropTypes.object.isRequired,
  setActive: PropTypes.func.isRequired,
};
