// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { TabContent } from "components/navigation";
import { trans } from "src/translations";

import { UpdateJobStatusModal } from "../UpdateJobStatusModal/UpdateJobStatusModal";
import { resultsPropTypes } from "./propTypes";
import styles from "./UpdateJobList.scss";
import { ACTIVE_JOBS_TAB } from "./UpdateJobListData";
import { UpdateJobListTable } from "./UpdateJobListTable";
import { UpdateJobListTabs } from "./UpdateJobListTabs";

export class UpdateJobListRend extends PureComponent {
  static propTypes = {
    createdUpdateJobName: PropTypes.string,
    deletedUpdateJobName: PropTypes.string,
    setUpdateJobDeleted: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    tabId: PropTypes.string,
    results: resultsPropTypes,
    count: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    refetch: PropTypes.func,
  };

  static defaultProps = {
    tabId: ACTIVE_JOBS_TAB,
  };

  state = {
    isUpdateStateModalOpen: false,
    updateStateModalJobId: null,
    updateStateModalAction: null,
  };

  openModal = (updateStateModalJobId, updateStateModalAction) => {
    this.setState({
      isUpdateStateModalOpen: true,
      updateStateModalJobId,
      updateStateModalAction,
    });
  };

  handleModalSuccess = () => {
    const { updateStateModalJobId, updateStateModalAction } = this.state;
    const { refetch, setUpdateJobDeleted } = this.props;

    this.handleModalClose();

    refetch();

    if (updateStateModalAction === "DELETE") {
      setUpdateJobDeleted(updateStateModalJobId);
    }
  };

  handleModalClose = () =>
    this.setState({
      isUpdateStateModalOpen: false,
      updateStateModalJobId: null,
      updateStateModalAction: null,
    });

  renderCreateUpdateJobButton = () => {
    return (
      <Button
        type="green"
        dataTestId="create-update-job-button"
        to="/updates/create"
      >
        {trans.UPDATES__UPDATE_JOB_LIST__CREATE_UPDATE_JOB()}
      </Button>
    );
  };

  render() {
    const {
      results,
      onSearch,
      searchValue,
      tabId,
      count,
      loading,
      error,
      createdUpdateJobName,
      deletedUpdateJobName,
    } = this.props;

    const {
      updateStateModalJobId,
      updateStateModalAction,
      isUpdateStateModalOpen,
    } = this.state;

    return (
      <Fragment>
        <Section>
          <ButtonsWrapper className={styles.button}>
            {this.renderCreateUpdateJobButton()}
          </ButtonsWrapper>
        </Section>

        {Boolean(createdUpdateJobName) && (
          <Section className={styles.wordBreak}>
            <Info type={"normal"}>
              {trans.UPDATES__UPDATE_JOB_LIST__JOB_CREATED({
                name: createdUpdateJobName,
              })}
            </Info>
          </Section>
        )}

        {Boolean(deletedUpdateJobName) && (
          <Section className={styles.wordBreak}>
            <Info type={"normal"}>
              {trans.UPDATES__UPDATE_JOB_LIST__JOB_DELETED({
                name: deletedUpdateJobName,
              })}
            </Info>
          </Section>
        )}

        <Section>
          <UpdateJobListTabs tabId={tabId} />
        </Section>
        <Section>
          <SearchBar
            onSearch={onSearch}
            value={searchValue || ""}
            placeholder={trans.UPDATES__UPDATE_JOB_LIST__SEARCH_PLACEHOLDER()}
            dataTestId="update-job-search"
          />
          <TabContent>
            <UpdateJobListTable
              results={results}
              count={count}
              loading={loading}
              error={error}
              openModal={this.openModal}
            />
          </TabContent>
          <ButtonsWrapper className={styles.button}>
            {this.renderCreateUpdateJobButton()}
          </ButtonsWrapper>
        </Section>

        {isUpdateStateModalOpen && (
          <UpdateJobStatusModal
            updateJobId={updateStateModalJobId}
            action={updateStateModalAction}
            isOpen={isUpdateStateModalOpen}
            onClose={this.handleModalClose}
            onSuccess={this.handleModalSuccess}
          />
        )}
      </Fragment>
    );
  }
}
