// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import styles from "./ValueDropdownOption.scss";

const ICON_SIZE = "20px";

export class ValueDropdownOption extends Component {
  static propTypes = {
    option: PropTypes.shape({
      display: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }).isRequired,
    focused: PropTypes.bool,
  };

  render() {
    const { option, focused } = this.props;

    return (
      <div
        className={styles.ValueDropdownOption}
        data-focused={Boolean(focused)}
      >
        {option.icon && (
          <img
            src={option.icon}
            width={ICON_SIZE}
            height={ICON_SIZE}
            alt="Icon"
            className={styles.Icon}
          />
        )}
        <span className={styles.DisplayValue}>{option.display}</span>
      </div>
    );
  }
}
