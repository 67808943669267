// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import styles from "./changeMenu.scss";

export class ChangeMenu extends Component {
  static propTypes = {
    value: PropTypes.string,
    buttonText: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      })
    ).isRequired,
    valueClassName: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    value: NO_VALUE_PLACEHOLDER,
    buttonText: `(${trans.CHANGE()})`,
    valueClassName: "",
  };

  renderChangeButton = () => {
    const { buttonText } = this.props;
    return <span className={styles.changeButton}>{buttonText}</span>;
  };

  render() {
    const { value, options, valueClassName, disabled } = this.props;

    return (
      <div>
        {value && <span className={valueClassName}>{value}</span>}
        {!disabled && (
          <ActionMenu
            alignment="right"
            triggerChildren={this.renderChangeButton}
            shifted={false}
          >
            {options.map(({ id, text, onClick }) => (
              <MenuItem key={id} onClick={onClick}>
                {text}
              </MenuItem>
            ))}
          </ActionMenu>
        )}
      </div>
    );
  }
}
