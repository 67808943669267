// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withQueryParams, withRouter } from "utils/decorators";
import {
  removeQueryParamsFromLocation,
  addQueryParamsToLocation,
} from "utils/url";

import { OrganizationsTableRend } from "./OrganizationsTableRend";

const MEMBERS_COUNT_FILTER = "members_count__gt";
const MEMBERS_COUNT_VALUE = "1";

@withRouter
@withQueryParams
@restrictedArea(() => ({
  allowed: [ROLES.administrator.organizationAdmin],
}))
@connect(null, (dispatch) => ({
  getOrganizationsPaginated: (queryParams) =>
    dispatch(
      apiThatThrows.getOrganizationsPaginated.action({
        queryParams,
      })
    ),
  getTypesForOrganization: (id) =>
    dispatch(
      apiThatThrows.getTypesForOrganizationPaginated.action({
        params: { organizationId: id },
        queryParams: { limit: "nolimit" },
      })
    ),
}))
export class OrganizationsTableData extends Component {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @connect
    getOrganizationsPaginated: PropTypes.func.isRequired,
    getTypesForOrganization: PropTypes.func.isRequired,
  };

  state = {
    organizations: [],
    count: 0,
    error: undefined,
    loading: true,
    areFreelanceOrgsHidden: false,
  };

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.refreshData();
    }
  }

  refreshData = () => {
    this.updateData();
    this.updateFreelanceOrgsVisibility();
  };

  createOrganization = async (org) => {
    const { getTypesForOrganization } = this.props;
    const { results } = await getTypesForOrganization(org.id);
    return {
      id: org.id,
      icon: org.logo,
      name: org.name,
      types: results,
      usersCount: org.members_count,
    };
  };

  updateData = async () => {
    const { getOrganizationsPaginated, queryParams } = this.props;

    this.setState({ loading: true });

    try {
      const { results, meta } = await getOrganizationsPaginated(queryParams);

      const requests = results.map(this.createOrganization);
      const organizations = await Promise.all(requests);

      this.setState({
        organizations,
        count: meta.count,
        error: undefined,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  };

  updateFreelanceOrgsVisibility = () => {
    const { queryParams } = this.props;
    const isHidden = queryParams[MEMBERS_COUNT_FILTER] === MEMBERS_COUNT_VALUE;

    this.setState({ areFreelanceOrgsHidden: isHidden });
  };

  hideFreelanceOrganizations = () => {
    const { location, navigate } = this.props;
    const newLocation = addQueryParamsToLocation(location, {
      page: 1,
      [MEMBERS_COUNT_FILTER]: MEMBERS_COUNT_VALUE,
    });
    navigate(newLocation);
  };

  showFreelanceOrganizations = () => {
    const { location, navigate } = this.props;
    const newLocation = removeQueryParamsFromLocation(location, [
      MEMBERS_COUNT_FILTER,
    ]);
    navigate(newLocation);
  };

  toggleFreelanceOrgsVisibility = () => {
    if (this.state.areFreelanceOrgsHidden) {
      this.showFreelanceOrganizations();
    } else {
      this.hideFreelanceOrganizations();
    }
  };

  render() {
    const { organizations, count, error, loading } = this.state;

    return (
      <OrganizationsTableRend
        organizations={organizations}
        count={count}
        error={error}
        loading={loading}
        toggleFreelanceOrgsVisibility={this.toggleFreelanceOrgsVisibility}
        areFreelanceOrgsHidden={this.state.areFreelanceOrgsHidden}
      />
    );
  }
}
