// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { DatePicker } from "components/form";
import { Input } from "components/form/elements";
import { trans } from "src/translations";
import { formatDate } from "utils/date";
import { flatten } from "utils/serialize";
import { isRequired, pipeValidators } from "utils/validation";

const DATE_SEPARATOR = "\u21D2";
const FIELD_NAME = "scope";

export const ScopeField = ({ isReadonly }) => {
  const [isOpen, setIsOpen] = useState(false);

  const formatValue = (start, end) =>
    start && end
      ? `${formatDate(start)}  ${DATE_SEPARATOR}  ${formatDate(end)}`
      : "";

  const getRange = (start, end) => ({
    start: start ? start : new Date(),
    end: end ? end : new Date(),
  });

  const validate = (values) => {
    let error = "";

    const errors = pipeValidators(
      isRequired("start"),
      isRequired("end")
    )(flatten(values));

    if (errors) {
      error = errors?.start || errors?.end;
    }

    return error;
  };

  return (
    <Field name={FIELD_NAME} validate={validate}>
      {({ field: { value }, meta, form: { setFieldValue } }) => (
        <>
          <Input
            {...meta}
            label={trans.CAMPAIGN_EDIT__FIELD_SCOPE()}
            tooltip={trans.CAMPAIGN_EDIT__FIELD_SCOPE_TOOLTIP()}
            value={formatValue(value.start, value.end)}
            onFocus={() => {
              setIsOpen(true);
            }}
            required={true}
            readOnly={isReadonly}
            dataTestId="campaign-scope"
          />
          {!isReadonly && (
            <DatePicker
              isOpen={isOpen}
              range={true}
              selected={getRange(value.start, value.end)}
              onSelect={(start, end) => {
                setIsOpen(false);
                setFieldValue(FIELD_NAME, { start, end });
              }}
              onClose={() => {
                setIsOpen(false);
              }}
            />
          )}
        </>
      )}
    </Field>
  );
};

ScopeField.propTypes = {
  isReadonly: PropTypes.bool,
};
