// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableNoResults,
  TableRow,
  TableRowColumn,
} from "components/layout";
import { trans } from "src/translations";

import styles from "./SignaturesTable.scss";

export class SignaturesTable extends PureComponent {
  static propTypes = {
    signatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  renderRow = (signature) => (
    <TableRow key={signature}>
      <TableRowColumn
        dataTestId="signature-name-column"
        className={styles.tableSignatureName}
      >
        {signature}
      </TableRowColumn>
    </TableRow>
  );

  render() {
    const { signatures } = this.props;
    return (
      <Table className={styles.table}>
        <TableHeader position="sticky">
          <TableHeaderColumn dataTestId="signature-name-column-header">
            {trans.DEVICES__DEVICE_DETAILS_SIGNATURES_NAME_HEADER()}
          </TableHeaderColumn>
        </TableHeader>

        {!signatures || signatures.length === 0 ? (
          <TableNoResults
            colspan={1}
            message={trans.DEVICES__DEVICE_DETAILS_TABLE_NO_SUPPORTED_SIGNATURES()}
          />
        ) : (
          <TableBody>{signatures.map(this.renderRow)}</TableBody>
        )}
      </Table>
    );
  }
}
