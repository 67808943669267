// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import set from "lodash-es/set";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { api } from "containers/Request";
import { TargetsField } from "pages/_shared";
import { withTargetsSend } from "pages/_shared/TargetsField/decorators/withTargetsSend";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";
import { prepareErrorsForForm } from "utils/errors";
import { dataFormatter } from "utils/jsonApi";

import { DistributionContext } from "../../_shared/Context/distributionContext";
import { DistributorField } from "../_shared/fields";

const jsonPointerToFieldName = {
  "/data/relationships/applications": "applications",
  "/data/relationships/targets/data": "targets._error",
};

@withRouter
@withTargetsSend({
  distributorFieldNameInRequest: "device_owners",
  distributorDeviceType: "device_owner",
})
@connect(null, (dispatch) => ({
  updateDefaultPlan: (id, body) =>
    dispatch(
      api.updateDefaultDistributionPlan.action({
        params: { planId: id },
        options: { body },
      })
    ),
}))
export class AdminDefaultPlanForm extends Component {
  static propTypes = {
    planId: PropTypes.string.isRequired,
    initialValues: PropTypes.object,

    // from @withRouter
    navigate: PropTypes.func.isRequired,

    // from @withTargetsSend
    createTargetsBody: PropTypes.func,

    // from @connect
    updateDefaultPlan: PropTypes.func.isRequired,
  };

  createBody = ({ targets }) => {
    // Due to targets relationship, this request has a very unusual structure
    // that does not conform with jsona.
    const { createTargetsBody } = this.props;

    const data = {
      type: "default-plans",
    };
    const body = dataFormatter.serialize({ stuff: data });

    const targetsBody = createTargetsBody("targets", targets);
    set(body, "data.relationships.targets", targetsBody);

    return JSON.stringify(body);
  };

  handleSubmit = async (values, { setErrors }) => {
    const { updateDefaultPlan, planId, navigate } = this.props;

    const body = this.createBody(values);

    const { error } = await updateDefaultPlan(planId, body);

    if (error) {
      const submitErrors = prepareErrorsForForm(error, jsonPointerToFieldName);
      setErrors(submitErrors);
    } else {
      navigate(
        {
          pathname: "/distribution/admin-plans",
        },
        {
          state: { message: trans.CHANGES_SAVE_SUCCESS() },
        }
      );
    }
  };

  render() {
    const { initialValues } = this.props;

    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({ errors, dirty, isSubmitting, values, handleSubmit }) => {
          return (
            <Form aria-label="form">
              <Section header={trans.BASIC_DETAILS()}>
                <DistributorField disabled={true} />
              </Section>
              <Section header={trans.DISTR_PLAN__SECTION_TARGETS()}>
                <DistributionContext.Provider
                  value={{ useDistributorFilter: true }}
                >
                  <TargetsField
                    devicesDistributorId={values["distributor"]}
                    required={true}
                    forAdmin={true}
                  />
                </DistributionContext.Provider>
              </Section>
              {errors?._error && <Info type="error">{errors._error}</Info>}
              <ButtonsWrapper>
                <Button
                  dataTestId="submit-button"
                  type="green"
                  processing={isSubmitting}
                  disabled={
                    isSubmitting ||
                    !dirty ||
                    Boolean(Object.entries(errors).length)
                  }
                  onClick={handleSubmit}
                >
                  {trans.SUBMIT_CHANGES()}
                </Button>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
