// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { FieldArray } from "formik";
import PropTypes from "prop-types";

import { FIELDS } from "../constants";
import { ContentItems } from "../ContentItems";

export const ContentItemsField = ({ name }) => {
  const fieldName = `${name}.${FIELDS.CONTENT_ITEMS.name}`;

  return (
    <FieldArray name={fieldName}>
      {({ remove, move, push, insert, form: { getFieldMeta, errors } }) => {
        const { value, touched } = getFieldMeta(fieldName);
        const [, order, nestedField] = fieldName.split(".");

        const error = errors.apps?.[Number(order)]?.[nestedField];

        return (
          <ContentItems
            contentItems={value}
            fields={{
              remove,
              move,
              push,
              insert,
            }}
            name={name}
            error={error}
            touched={touched}
          />
        );
      }}
    </FieldArray>
  );
};

ContentItemsField.propTypes = {
  name: PropTypes.string.isRequired,
};
