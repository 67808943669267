// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { SubTabs, SubTab } from "components/navigation";
import { tabsConfig } from "pages/Snap/SnapEdit/tabs";

import styles from "./SnapTabs.scss";

export class SnapTabs extends Component {
  static propTypes = {
    appId: PropTypes.string,
    activeTabIndex: PropTypes.number,
    errors: PropTypes.object,
  };

  renderTabs = (key, value, idx) => {
    const { appId, activeTabIndex, errors = {} } = this.props;
    const isInvalid = Object.keys(errors).some((field) =>
      value.fields.includes(field)
    );
    const otherProps = {
      icon: isInvalid ? "warning" : "ok",
      iconColor: isInvalid ? "error" : "success",
    };

    return (
      <SubTab
        key={key}
        active={activeTabIndex === idx}
        to={`/snap/${appId}/edit/${key}`}
        text={value.tabTitle}
        dataTestId={value.dataTestId}
        {...otherProps}
      />
    );
  };

  render() {
    return (
      <SubTabs className={styles.margin}>
        {Object.entries(tabsConfig).map(([key, value], idx) =>
          this.renderTabs(key, value, idx)
        )}
      </SubTabs>
    );
  }
}
