// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Distribution } from "../../../components/index";
import { DISTRIBUTION_TYPES } from "../../../constants";

export default {
  components: [
    {
      component: Distribution,
      fields: ["metadata.distribute_in_tvstore", "status"],
      initialValues: {
        metadata: {
          distribute_in_tvstore: DISTRIBUTION_TYPES.VEWD_CATALOGUE,
        },
      },
      props: ({ appData, formikProps }) => {
        return {
          isSubmitted: appData.has_submitted_version,
          appType: appData.type,
          ...formikProps,
        };
      },
    },
  ],
};
