// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import {
  TableRowColumn,
  TableRow,
  TableRowColumnExtended,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./adminPlanRow.scss";

export class AdminPlanRow extends Component {
  static propTypes = {
    plan: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isDefault: PropTypes.bool.isRequired,
      appsCount: PropTypes.number.isRequired,
      distributorName: PropTypes.string.isRequired,
    }).isRequired,
  };

  editLink = () => {
    const { plan } = this.props;
    if (plan.isDefault) {
      return `/distribution/admin-plans/default-plans/${plan.id}`;
    } else {
      return `/distribution/admin-plans/edit/${plan.id}`;
    }
  };

  editText = () => {
    const { plan } = this.props;
    if (plan.isDefault) {
      return trans.DISTR_PLAN__ADMIN_EDIT_DEFAULT_PLAN_BUTTON();
    } else {
      return trans.EDIT_DETAILS();
    }
  };

  render() {
    const { plan } = this.props;

    return (
      <TableRow>
        <TableRowColumnExtended
          text={plan.name}
          to={this.editLink()}
          textClassName={classes(styles.name, {
            [styles.defaultPlanName]: plan.isDefault,
          })}
        />
        <TableRowColumn>{plan.appsCount}</TableRowColumn>
        <TableRowColumn>{plan.distributorName}</TableRowColumn>
        <TableRowColumn>
          <ActionMenu>
            <MenuItem to={this.editLink()}>{this.editText()}</MenuItem>
          </ActionMenu>
        </TableRowColumn>
      </TableRow>
    );
  }
}
