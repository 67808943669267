// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { ButtonsWrapper, Button } from "components/buttons";
import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";

import { resultsPropTypes } from "./propTypes";
import { SoftwarePackagesTable } from "./SoftwarePackagesTable";
import { UploadSoftwarePackageModal } from "./UploadSoftwarePackageModal/UploadSoftwarePackageModal";

export class SoftwarePackagesRend extends PureComponent {
  static propTypes = {
    results: resultsPropTypes,
    onSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    count: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  state = {
    isUploadModalOpen: false,
    showUploadSuccessMessage: false,
  };

  handleCloseModals = () => {
    this.setState({ isUploadModalOpen: false });
  };

  handleOpenUploadModal = () => {
    this.setState({
      isUploadModalOpen: true,
      showUploadSuccessMessage: false,
    });
  };

  handleUploadSuccess = () => {
    this.setState({
      showUploadSuccessMessage: true,
    });
  };

  renderUploadSoftwarePackageButton = () => {
    return (
      <ButtonsWrapper>
        <Button
          dataTestId={"upload-software-package-button"}
          onClick={this.handleOpenUploadModal}
        >
          {trans.UPDATES__SOFTWARE_PACKAGES_UPLOAD_BUTTON()}
        </Button>
      </ButtonsWrapper>
    );
  };

  render() {
    const { results, count, loading, error, onSearch, searchValue } =
      this.props;
    const { isUploadModalOpen, showUploadSuccessMessage } = this.state;

    return (
      <Fragment>
        {showUploadSuccessMessage && (
          <Info type={"normal"}>
            {trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_SUCCESS_A()}{" "}
            <Link to="/updates/queue" data-test-id="processing-queue-button">
              {trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_PROCESSING_QUEUE_LINK()}
            </Link>{" "}
            {trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_SUCCESS_B()}
          </Info>
        )}

        <Section header={trans.UPDATES__SOFTWARE_PACKAGES_SOFTWARE_LIST()}>
          <SearchBar
            onSearch={onSearch}
            value={searchValue || ""}
            placeholder={trans.UPDATES__SOFTWARE_PACKAGES_SEARCH_PLACEHOLDER()}
          />
        </Section>
        <Section>
          {this.renderUploadSoftwarePackageButton()}
          <SoftwarePackagesTable
            results={results}
            count={count}
            loading={loading}
            error={error}
          />
          {this.renderUploadSoftwarePackageButton()}
        </Section>
        <UploadSoftwarePackageModal
          isOpen={isUploadModalOpen}
          onClose={this.handleCloseModals}
          handleUploadSuccess={this.handleUploadSuccess}
        />
      </Fragment>
    );
  }
}
