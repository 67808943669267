// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { NumberField } from "components/form/fields";
import { Column } from "components/layout";
import { Header } from "components/typography";
import { FIELDS, SECTIONS } from "pages/Snap/shared/constants";
import { isValidNaturalNumber } from "pages/Snap/SnapEdit/validation";

const numFields = (formId) => {
  const {
    AD_PRE_OFFSET: preOffset,
    AD_PRE_INTERVAL: preInterval,
    AD_PRE_MIN_CONTENT_LENGTH: minContentLength,
  } = FIELDS;

  return [preOffset, preInterval, minContentLength].map(
    ({ name, label, tooltip }, idx) => (
      <NumberField
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        name={`${formId}.${name}`}
        label={label}
        tooltip={tooltip}
        validate={isValidNaturalNumber}
        required={false}
        min={0}
        step={1}
      />
    )
  );
};

export const AdPreRoll = ({ formId }) => {
  return (
    <Column>
      <Header bold={true} color="secondary" uppercase={true}>
        {SECTIONS.ADS.prerollHeader}
      </Header>
      {numFields(formId)}
    </Column>
  );
};
AdPreRoll.propTypes = {
  formId: PropTypes.string.isRequired,
};
