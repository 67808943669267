// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import partition from "lodash-es/partition";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { FormHasErrors } from "components/form";
import {
  SlugField,
  CheckboxGroupField,
  TextField,
} from "components/form/fields";
import { Section, Subsection } from "components/layout";
import { api } from "containers/Request";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";
import {
  pipeValidators,
  isRequired,
  isRequiredWhen,
  isDeviceSlug,
} from "utils/validation";

import { FORMAT_OTHER } from "../../constants";
import { getOrganizationName } from "./_utils/getOrganizationName";
import { FIELDS } from "./constants";
import {
  DistributionCustomerField,
  SettingsFields,
  DeviceFamiliesSelectField,
} from "./fields";

const jsonPointerToFieldName = {
  "/data/attributes/name": "name",
  "/data/attributes/slug": "slug",
  "/data/attributes/is_auto_slug": "autoSlug",
  "/data/relationships/owner": "owner",
  "/data/relationships/device_family": "deviceFamily",
  "/data/attributes/is_enabled": "enabled",
  "/data/attributes/is_testing": "testing",
  "/data/attributes/video_formats/:id/video_format_type":
    "videoFormatSupport[:id]",
  "/data/attributes/video_formats/:id/comment": "videoFormatSupportComment",
  "/data/attributes/streaming_formats/:id/streaming_format_type":
    "streamingSupport[:id]",
  "/data/attributes/streaming_formats/:id/comment": "streamingSupportComment",
  "/data/attributes/drm_formats/:id/drm_format_type": "drmSupport[:id]",
  "/data/attributes/drm_formats/:id/comment": "drmSupportComment",
};

@withRouter
@connect(null, (dispatch) => ({
  createDeviceModel: (body) =>
    dispatch(api.createDeviceModel.action({ options: { body } })),
  updateDeviceModel: (id, body) =>
    dispatch(
      api.updateDeviceModel.action({ params: { id }, options: { body } })
    ),
}))
export class DeviceModelForm extends Component {
  static propTypes = {
    isEdit: PropTypes.bool.isRequired,
    otherCheckboxesIds: PropTypes.object.isRequired,
    videoFormats: PropTypes.array,
    streamingFormats: PropTypes.array,
    drmFormats: PropTypes.array,
    organizations: PropTypes.array,
    initialValues: PropTypes.object,

    // from @withRouter
    navigate: PropTypes.func.isRequired,

    // from @connect
    createDeviceModel: PropTypes.func.isRequired,
    updateDeviceModel: PropTypes.func.isRequired,
  };

  getModelId = () => {
    return this.props.initialValues.id;
  };

  validate = (values) => {
    const validators = [
      isRequired("name"),
      isRequiredWhen("slug", () => !values.autoSlug),
      isRequired("organization"),
    ];
    if (this.props.initialValues.slug !== values.slug && !values.autoSlug) {
      validators.push(isDeviceSlug("slug"));
    }
    return pipeValidators(...validators)(values);
  };

  submit = async (values, { setErrors }) => {
    const {
      otherCheckboxesIds,
      createDeviceModel,
      updateDeviceModel,
      navigate,
      isEdit,
    } = this.props;

    const body = createBody({
      type: "DeviceModel",
      id: this.getModelId(),
      name: values.name,
      slug: values.slug,
      is_auto_slug: values.autoSlug,
      drm_formats: values.drmSupport.map((id) => ({
        drm_format_type: Number(id),
        comment:
          otherCheckboxesIds.drmFormatSupportOtherId === id
            ? values.drmSupportComment
            : "",
      })),
      video_formats: values.videoFormatSupport.map((id) => ({
        video_format_type: Number(id),
        comment:
          otherCheckboxesIds.videoFormatSupportOtherId === id
            ? values.videoFormatSupportComment
            : "",
      })),
      streaming_formats: values.streamingSupport.map((id) => ({
        streaming_format_type: Number(id),
        comment:
          otherCheckboxesIds.streamingFormatSupportOtherId === id
            ? values.streamingSupportComment
            : "",
      })),
      is_testing: values.testing,
      is_enabled: values.enabled,
      device_family: { type: "DeviceFamily", id: values.deviceFamily },
      owner: { type: "Owner", id: values.organization },
      relationshipNames: ["device_family", "owner"],
    });

    let result;

    if (isEdit) {
      result = await updateDeviceModel(Number(this.getModelId()), body);
    } else {
      result = await createDeviceModel(body);
    }

    if (result.error) {
      const errors = prepareErrorsForForm(result.error, jsonPointerToFieldName);
      throw new setErrors({
        ...errors,
        _submitFailed: true,
      });
    }

    const message = isEdit
      ? trans.CHANGES_SAVE_SUCCESS()
      : trans.DEVICES__DEVICE_MODEL_ADDED();
    navigate(
      {
        pathname: "/devices/models",
      },
      {
        state: { message: message },
      }
    );
  };

  isOtherOption = (item) => item?.display_name?.toLowerCase() === FORMAT_OTHER;

  prepareSupportData = (data) => {
    const [otherOption, options] = partition(data, this.isOtherOption);

    return [...options, ...otherOption].map((item) => ({
      value: item.id,
      label: item.display_name,
    }));
  };

  containsOtherOption = (value, data) =>
    value.some((id) => this.isOtherOption(data.find((item) => item.id === id)));

  render() {
    const {
      organizations,
      isEdit,
      videoFormats,
      streamingFormats,
      drmFormats,
      initialValues: {
        name,
        organization,
        slug,
        autoSlug,
        deviceFamily,
        enabled,
        testing,
        videoFormatSupport,
        videoFormatSupportComment,
        streamingSupport,
        streamingSupportComment,
        drmSupport,
        drmSupportComment,
      },
    } = this.props;

    const initialValues = {
      name: name ?? "",
      organization: organization ?? "",
      slug: slug ?? "",
      autoSlug: autoSlug ?? true,
      deviceFamily: deviceFamily ?? null,
      enabled: enabled ?? true,
      testing: testing ?? false,
      videoFormatSupport: videoFormatSupport ?? [],
      videoFormatSupportComment,
      streamingSupport: streamingSupport ?? [],
      streamingSupportComment,
      drmSupport: drmSupport ?? [],
      drmSupportComment,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.submit}
        validate={this.validate}
      >
        {({
          values,
          isSubmitting,
          handleSubmit,
          dirty,
          handleBlur,
          errors,
          isValid,
          submitCount,
        }) => {
          const submitFailed =
            ((!isValid && submitCount > 0) || errors?._submitFailed) ?? false;
          const errorDuplicated =
            errors?._error === trans.DEFAULT_FORM_SUBMIT_ERROR_MESSAGE() &&
            submitFailed;

          const {
            videoFormatSupport,
            streamingSupport,
            drmSupport,
            name,
            organization,
          } = values;
          const organizationName = getOrganizationName(
            organization,
            organizations
          );

          return (
            <Form>
              <Section header={trans.DEVICES__MODEL_DETAILS_HEADER()}>
                <TextField {...FIELDS.name} />
                <DistributionCustomerField
                  {...FIELDS.organization}
                  disabled={isEdit}
                  organizations={organizations}
                  onBlur={handleBlur}
                />
                <SlugField
                  {...FIELDS.slug}
                  autoSlugLabel={FIELDS.autoSlug.label}
                  nameFieldValue={name}
                  organizationName={organizationName}
                />
                {values.organization && (
                  <DeviceFamiliesSelectField ownerId={values.organization} />
                )}
                <Subsection header={trans.DEVICES__SETTINGS_HEADER()}>
                  <SettingsFields />
                </Subsection>
              </Section>
              <Section header={trans.DEVICES__SUPPORT_HEADER()}>
                <Subsection header={trans.DEVICES__VIDEO_FORMATS_HEADER()}>
                  <CheckboxGroupField
                    name="videoFormatSupport"
                    values={this.prepareSupportData(videoFormats)}
                  />
                  {this.containsOtherOption(
                    videoFormatSupport,
                    videoFormats
                  ) && (
                    <TextField
                      name="videoFormatSupportComment"
                      label={trans.PLEASE_SPECIFY()}
                      required
                    />
                  )}
                </Subsection>
                <Subsection header={trans.DEVICES__ADAPTIVE_STREAMING_HEADER()}>
                  <CheckboxGroupField
                    dataTestId="streaming-support-field"
                    name="streamingSupport"
                    values={this.prepareSupportData(streamingFormats)}
                  />
                  {this.containsOtherOption(
                    streamingSupport,
                    streamingFormats
                  ) && (
                    <TextField
                      name="streamingSupportComment"
                      label={trans.PLEASE_SPECIFY()}
                      required
                    />
                  )}
                </Subsection>
                <Subsection header={trans.DEVICES__DRM_HEADER()}>
                  <CheckboxGroupField
                    name="drmSupport"
                    values={this.prepareSupportData(drmFormats)}
                  />
                  {this.containsOtherOption(drmSupport, drmFormats) && (
                    <TextField
                      name="drmSupportComment"
                      label={trans.PLEASE_SPECIFY()}
                      required
                    />
                  )}
                </Subsection>
              </Section>
              <ButtonsWrapper>
                <Button
                  type="green"
                  disabled={isSubmitting || !dirty}
                  processing={isSubmitting}
                  onClick={() => {
                    handleSubmit(this.submit);
                  }}
                >
                  {isEdit
                    ? trans.SUBMIT_CHANGES()
                    : trans.DEVICES__ADD_NEW_MODEL()}
                </Button>
              </ButtonsWrapper>
              {errors?._error && !errorDuplicated && (
                <Info type="error">{errors._error}</Info>
              )}
              <FormHasErrors submitFailed={submitFailed} />
            </Form>
          );
        }}
      </Formik>
    );
  }
}
