// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { FIELDS, TABS } from "pages/Snap/shared/constants";

export const tabsConfig = {
  [TABS.CONTENT.name]: {
    title: TABS.CONTENT.title,
    tabTitle: TABS.CONTENT.label,
    fields: [
      FIELDS.AUTODARKEN_BG.name,
      FIELDS.BACKGROUND.name,
      FIELDS.KEYWORDS_PREFIX.name,
      FIELDS.LOGO.name,
      FIELDS.VT_TYPE.name,
      FIELDS.TRANSLATIONS.name,
    ],
    dataTestId: "content-and-graphics-tab",
  },
  [TABS.MONETIZATION.name]: {
    title: TABS.MONETIZATION.title,
    tabTitle: TABS.MONETIZATION.label,
    fields: [
      FIELDS.ANALYTICS.name,
      FIELDS.AD_PRE_OFFSET.name,
      FIELDS.AD_PRE_INTERVAL.name,
      FIELDS.AD_PRE_MIN_CONTENT_LENGTH.name,
      FIELDS.AD_MID_OFFSET.name,
      FIELDS.AD_MID_INTERVAL.name,
      FIELDS.AD_MID_MIN_OFFSET.name,
      FIELDS.AD_MID_MIN_INTERVAL.name,
    ],
    dataTestId: "monetization-and-stats-tab",
  },
};
