// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { TextField, TextareaField, SelectField } from "components/form/fields";
import { trans } from "src/translations";
import { sortLanguages } from "utils/translations";

import styles from "./DescriptionTab.scss";

export const DescriptionTab = ({
  index,
  languages,
  excluded,
  toggleDefaultTranslation,
  isLanguageRequired,
  fieldName,
  isTitleRequired,
  isDescriptionRequired,
  translationsCount,
  language: selectedLang,
}) => {
  return (
    <div>
      <SelectField
        name={`${fieldName}.language`}
        initOption={{ value: "", text: trans.CHOOSE_LANGUAGE() }}
        label={trans.LANGUAGE()}
        required={isLanguageRequired}
        values={sortLanguages(languages).map((language) => ({
          value: language.code,
          text: language.eng_name,
        }))}
        excluded={excluded}
        dataTestId="description-language-field"
        tooltip={trans.APP_EDIT__LANGUAGE_TOOLTIP()}
      />
      <div className={!selectedLang ? styles.hidden : ""}>
        <TextField
          name={`${fieldName}.name`}
          label={trans.TITLE()}
          dataTestId="description-title-field"
          tooltip={trans.APP_EDIT__DESCRIPTION_TITLE_TOOLTIP()}
          required={isTitleRequired}
        />
        <TextareaField
          name={`${fieldName}.short_description`}
          label={trans.DESCRIPTION()}
          dataTestId="description-description-field"
          tooltip={trans.APP_EDIT__DESCRIPTION_TOOLTIP()}
          required={isDescriptionRequired}
        />
        <Button
          onClick={() => toggleDefaultTranslation(index)}
          disabled={translationsCount <= 1}
          dataTestId="description-set-as-default-button"
        >
          {trans.APP_EDIT__LANGUAGE_SET_AS_DEFAULT()}
        </Button>
      </div>
    </div>
  );
};

DescriptionTab.propTypes = {
  languages: PropTypes.array,
  fieldName: PropTypes.string.isRequired,
  index: PropTypes.number,
  excluded: PropTypes.array,
  toggleDefaultTranslation: PropTypes.func,
  translationsCount: PropTypes.number,
  language: PropTypes.string,

  isLanguageRequired: PropTypes.bool,
  isTitleRequired: PropTypes.bool,
  isDescriptionRequired: PropTypes.bool,
};

DescriptionTab.defaultProps = {
  isLanguageRequired: true,
  isTitleRequired: true,
  isDescriptionRequired: true,
  translationsCount: 0,
};
