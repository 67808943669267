// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TextOverflowPopup } from "components/popups";

import styles from "./TableRowColumn.scss";

/**
 * Very simple table cell data. Corresponds to `<td>`.
 * Should be placed inside `<TableRow>` or `<TableSubRow>`.
 *
 * IMPORTANT: This only works with "fixed" table layout.
 */
export const TableRowColumnOverflow = ({
  children,
  colspan,
  dataTestId = "table-column",
  overflowText,
}) => {
  return (
    <td
      data-test-id={dataTestId}
      className={styles.container}
      colSpan={colspan}
    >
      <TextOverflowPopup overflowText={overflowText}>
        {children}
      </TextOverflowPopup>
    </td>
  );
};

TableRowColumnOverflow.propTypes = {
  /** Content. Can be anything */
  children: PropTypes.node,
  /** Cell can span many columns */
  colspan: PropTypes.number,
  /** Id for testing */
  dataTestId: PropTypes.string,
  /** Alternative content for overflow */
  overflowText: PropTypes.node,
};
