// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as actions from "./actionTypes";

/**
 * Results structure:
 *
 * results = [
 *   { id: 1, name: "name", families: [
 *     { id: 1, name: "name", devices: [
 *       { id: 1, name: "name" }
 *       ...
 *     ]}
 *     ...
 *   ] }
 *   ...
 * ]
 */
export const updateTargetsActionCreator = (subtype) => (results) => ({
  type: actions.UPDATE_TARGETS,
  subtype,
  results,
});

export const toggleDeviceModelActionCreator =
  (subtype) => (distributorId, familyId, modelId) => ({
    type: actions.TOGGLE_DEVICE_MODEL,
    subtype,
    distributorId,
    familyId,
    modelId,
  });

export const toggleDeviceFamilyActionCreator =
  (subtype) => (distributorId, familyId) => ({
    type: actions.TOGGLE_DEVICE_FAMILY,
    subtype,
    distributorId,
    familyId,
  });

export const toggleDistributorActionCreator = (subtype) => (distributorId) => ({
  type: actions.TOGGLE_DISTRIBUTOR,
  subtype,
  distributorId,
});

export const deselectAllActionCreator = (subtype) => () => ({
  type: actions.DESELECT_ALL,
  subtype,
});
