// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import queryString from "query-string";

import { VERSION_TABS, VALID_TABS, VERSION_URL_PARAM_NAME } from "../constants";

export const getVersionSubTabFromUrl = (location) => {
  const searchObj = queryString.parse(location.search);
  const tab = searchObj[VERSION_URL_PARAM_NAME];
  return VALID_TABS.includes(tab) ? tab : VERSION_TABS.active;
};
