// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

/** Corresponds to `<tbody>`. Should be placed inside `<Table>` */
export class TableBody extends Component {
  static propTypes = {
    /** User custom class name. */
    className: PropTypes.string,
    /**
     *  [TableRow](#tablerow), [TablePagination](#tablepagination),
     *  [TableNoResult](#tablenoresult) or [TableLoader](#tableloader) elements.
     */
    children: PropTypes.node,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    return (
      <tbody className={this.props.className}>{this.props.children}</tbody>
    );
  }
}

export default TableBody;
