// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { ButtonsWrapper, Button } from "components/buttons";
import { Loader, CodeEditor } from "components/elements";
import { Info } from "components/feedback";
import { Column, Row, Section, Subsection } from "components/layout";
import { updateJobFormLocationWithVersion } from "pages/Updates/UpdateJobForm/utils";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";

import styles from "./SoftwarePackageDetailsVersion.scss";

export class SoftwarePackageDetailsVersionRend extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDownloading: false,
      downloadError: false,
    };
  }

  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    softwarePackageVersion: PropTypes.shape({
      id: PropTypes.string,
      packageName: PropTypes.string,
      version: PropTypes.string,
      fileName: PropTypes.string,
      fileSize: PropTypes.string,
      uploadDate: PropTypes.string,
      manifestFormatted: PropTypes.string,
      manifestIsValid: PropTypes.bool,
    }),
    downloadSignedPackage: PropTypes.func,
  };

  handleDownloadButtonClick = async () => {
    this.setState({
      isDownloading: true,
    });

    try {
      await this.props.downloadSignedPackage();
    } catch (e) {
      this.setState({
        downloadError: getGqlErrorMessage(e),
      });
      // rethrow for sentry
      throw e;
    } finally {
      this.setState({
        isDownloading: false,
      });
    }
  };

  render() {
    const { loading, error, softwarePackageVersion } = this.props;
    const { downloadError, isDownloading } = this.state;

    if (error) {
      return <Info type={"error"}>{error}</Info>;
    }

    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <Section>
          <div className={styles.headerContainer}>
            <div className={styles.header}>
              {trans.UPDATES__SOFTWARE_PACKAGES__PACKAGE_DETAILS_HEADER()}
            </div>
            <Button
              type="normal"
              onClick={this.handleDownloadButtonClick}
              disabled={isDownloading}
              processing={isDownloading}
              className={styles.downloadButton}
            >
              {trans.UPDATES__SOFTWARE_PACKAGES__PACKAGE_PACKAGE_DOWNLOAD()}
            </Button>
          </div>
          {downloadError && <Info type={"error"}>{downloadError}</Info>}
          <Row>
            <Column>
              <Subsection
                header={trans.UPDATES__SOFTWARE_PACKAGES__PACKAGE_DETAILS_UPLOAD_DATE()}
                dataTestId="version-upload-date"
              >
                {softwarePackageVersion.uploadDate}
              </Subsection>
            </Column>
            <Column>
              <Subsection
                header={trans.UPDATES__SOFTWARE_PACKAGES__PACKAGE_DETAILS_PACKAGE_NAME()}
                dataTestId="version-package-name"
              >
                {softwarePackageVersion.fileName}
              </Subsection>
            </Column>
          </Row>
          <Row>
            <Column>
              <Subsection
                header={trans.UPDATES__SOFTWARE_PACKAGES__PACKAGE_DETAILS_PACKAGE_SIZE()}
                dataTestId="version-package-size"
              >
                {softwarePackageVersion.fileSize}
              </Subsection>
            </Column>
          </Row>
        </Section>
        <Section
          header={trans.UPDATES__SOFTWARE_PACKAGES__PACKAGE_MANIFEST_HEADER()}
        >
          {softwarePackageVersion.manifestIsValid ? (
            <CodeEditor
              value={softwarePackageVersion.manifestFormatted}
              download={"manifest.json"}
              dataTestId="version-manifest"
            />
          ) : (
            <Info type={"error"}>
              {trans.UPDATES__SOFTWARE_PACKAGES__CODE_SNIPPET_ERROR()}
            </Info>
          )}
          <ButtonsWrapper>
            <Button
              type="green"
              {...updateJobFormLocationWithVersion(
                softwarePackageVersion.id,
                softwarePackageVersion.packageName,
                softwarePackageVersion.version
              )}
              dataTestId="create-update-job-button"
            >
              {trans.UPDATES__SOFTWARE_PACKAGES__PACKAGE_CREATE_UPDATE_JOB()}
            </Button>
          </ButtonsWrapper>
        </Section>
      </>
    );
  }
}
