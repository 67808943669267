// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Header } from "components/typography";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { isEmpty } from "utils/object";

import { SubsectionWithUpdatedStatus } from ".";
import styles from "./CommentableList.scss";
import {
  HeaderContentWithUpdatedLabel,
  IsVersionUpdated,
} from "./IsVersionUpdated";

export const sortListItems = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA === "OTHER") {
    return 1;
  }

  if (nameB === "OTHER") {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }

  // names must be equal
  return 0;
};

export class CommentableList extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        comment: PropTypes.string,
      })
    ),
    listTitle: PropTypes.string,
    isListUpdatedFunc: PropTypes.func.isRequired,
    isCommentUpdatedFunc: PropTypes.func.isRequired,
    defaultEmptyComment: PropTypes.bool,
  };

  static defaultProps = {
    defaultEmptyComment: true,
  };

  renderList = () => {
    const { data } = this.props;

    if (isEmpty(data)) {
      return NO_VALUE_PLACEHOLDER;
    }

    return (
      <ul className={styles.list}>
        {data
          .slice()
          .sort(sortListItems)
          .map(({ name, comment }) => (
            <li className={styles.titleText} key={name}>
              {name}
              {comment ? " *" : null}
            </li>
          ))}
      </ul>
    );
  };

  renderComment = () => {
    const { defaultEmptyComment, isCommentUpdatedFunc, data } = this.props;

    const commentsStr = data
      .map(({ comment }) => comment)
      .filter((c) => c)
      .join(", ");

    if (!commentsStr && !defaultEmptyComment) {
      return null;
    }

    return (
      <div>
        <Header
          className={styles.commentHeader}
          type="h6"
          uppercase={true}
          color="tertiary"
        >
          <IsVersionUpdated isUpdated={isCommentUpdatedFunc}>
            {(isCommentUpdated) => (
              <HeaderContentWithUpdatedLabel
                content={`* ${trans.APP_DETAILS_VERSION__COMMENT_FROM_AUTHOR()}`}
                isUpdated={isCommentUpdated}
              />
            )}
          </IsVersionUpdated>
        </Header>
        {commentsStr ? (
          <p className={styles.commentText}>{commentsStr}</p>
        ) : (
          NO_VALUE_PLACEHOLDER
        )}
      </div>
    );
  };

  render() {
    const { listTitle, isListUpdatedFunc } = this.props;

    return (
      <SubsectionWithUpdatedStatus
        headerContent={listTitle}
        isUpdatedFunc={isListUpdatedFunc}
      >
        {this.renderList()}
        {this.renderComment()}
      </SubsectionWithUpdatedStatus>
    );
  }
}
