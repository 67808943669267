// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const NO_VALUE_PLACEHOLDER = "-";

export const CONTACT_US_MAIL = "cloud-dev@vewd.com";
// To be used inside `href`
export const CONTACT_US_MAIL_AS_LINK = `mailto:${CONTACT_US_MAIL}`;
