// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useMemo, useState } from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { DatePicker } from "components/form";
import { createMixedValidator } from "components/form/fields";
import { formatDate } from "utils/date";
import { pipeValidators, isRequired } from "utils/validation";

import { Input } from "../elements";
import { FieldProps } from "./propTypes";

const DATE_SEPARATOR = "\u21D2";

const formatValue = (start, end) =>
  start && end
    ? `${formatDate(start)}  ${DATE_SEPARATOR}  ${formatDate(end)}`
    : "";

const getRange = (start, end) => ({
  start: start ? start : new Date(),
  end: end ? end : new Date(),
});

const createDateValidator = (name, required, validate) => (value) => {
  const errors = createMixedValidator(
    name,
    required,
    pipeValidators(isRequired("start"), isRequired("end"), validate)
  )(value);

  return errors?.start ?? errors?.end;
};

export const DateScopeField = ({
  name,
  validate: val = () => {},
  className,
  readOnly = false,
  label,
  tooltip,
  placeholder,
  required = true,
  dataTestId,
  datePickerClassName,
}) => {
  const validate = useMemo(
    () => createDateValidator(name, required, val),
    [name, required, val]
  );
  const [isOpen, setOpen] = useState(false);
  const [field, meta, { setValue }] = useField({
    value: {},
    name,
    validate,
  });

  return (
    <div className={className}>
      <Input
        {...field}
        {...meta}
        label={label}
        tooltip={tooltip}
        placeholder={placeholder}
        value={formatValue(field.value?.start, field.value?.end)}
        onFocus={() => setOpen(true)}
        onChange={() => {}}
        required={required}
        dataTestId={dataTestId}
      />
      {!readOnly && (
        <DatePicker
          isOpen={isOpen}
          range
          selected={getRange(field.value?.start, field.value?.end)}
          onSelect={(start, end) => {
            setValue({ start, end });
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
          className={datePickerClassName}
        />
      )}
    </div>
  );
};

DateScopeField.propTypes = {
  ...FieldProps,
  readOnly: PropTypes.bool,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
  datePickerClassName: PropTypes.string,
};
