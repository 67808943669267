import * as Yup from "yup";

import { trans } from "src/translations";

import { ACTION_VALUES, FIELDS } from "./constants";

export const promotionSchemaGenerator = (conf) => {
  const appSchema = {
    ...(conf.action && {
      [FIELDS.ACTION.name]: Yup.string().required(),
      [FIELDS.ACTION_CUSTOM.name]: Yup.string().when(FIELDS.ACTION.name, {
        is: ACTION_VALUES.CUSTOM,
        then: (schema) => schema.required(trans.ERROR__FIELD_REQUIRED()),
      }),
    }),
    ...(conf.appsCount && {
      [FIELDS.APPLICATION.name]: Yup.string().required(
        trans.ERROR__FIELD_REQUIRED()
      ),
    }),
    ...(conf.background && {
      [FIELDS.BACKGROUND.name]: Yup.object({
        url: Yup.string().required(trans.ERROR__FIELD_REQUIRED()),
      }),
    }),
    ...(conf.contentItems && {
      [FIELDS.CONTENT_ITEMS.name]: Yup.array().min(
        1,
        trans.ERROR__ONE_ELEMENT_REQUIRED()
      ),
    }),
    ...(conf.description && {
      [FIELDS.DESCRIPTION.name]: Yup.string().required(
        trans.ERROR__FIELD_REQUIRED()
      ),
    }),
    ...(conf.icon && {
      [FIELDS.ICON.name]: Yup.object({
        url: Yup.string().required(trans.ERROR__FIELD_REQUIRED()),
      }),
    }),
    ...(conf.json && {
      [FIELDS.JSON.name]: Yup.string().test(
        "JSON",
        trans.ERROR__INVALID_JSON(),
        (value) => {
          try {
            JSON.parse(value);
          } catch (e) {
            return false;
          }
          return true;
        }
      ),
    }),
    ...(conf.thumbnail && {
      [FIELDS.THUMBNAIL.name]: Yup.object({
        url: Yup.string().required(trans.ERROR__FIELD_REQUIRED()),
      }),
    }),
    ...(conf.title && {
      [FIELDS.TITLE.name]: Yup.string().required(trans.ERROR__FIELD_REQUIRED()),
    }),
  };

  return Yup.object().shape({
    [FIELDS.PROMO_NAME.name]: Yup.string().required(
      trans.ERROR__FIELD_REQUIRED()
    ),
    [FIELDS.PROMO_TYPE.name]: Yup.string().required(
      trans.ERROR__FIELD_REQUIRED()
    ),
    [FIELDS.PROMO_APPS.name]: Yup.array().of(Yup.object().shape(appSchema)),
  });
};
