// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { FieldArray } from "formik";
import PropTypes from "prop-types";

import { OrganizationMembership } from "./OrganizationMembership";

export const OrganizationMembershipField = ({ name }) => (
  <FieldArray name={name} component={OrganizationMembership} />
);

OrganizationMembershipField.propTypes = {
  name: PropTypes.string,
};
