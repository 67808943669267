// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";
import { pipeValidators, isRequired } from "utils/validation";

import { Category } from "../../../components";
import { APP_PROFILES_SECTION_APP_TYPES } from "../../../constants";

export default {
  name: trans.APP_EDIT__GENERAL_SECTION_NAME(),
  isEnabled: (props) =>
    APP_PROFILES_SECTION_APP_TYPES.includes(props.appData.type),
  components: [
    {
      component: Category,
      fields: ["metadata.category"],
      validate: pipeValidators(isRequired("metadata.category")),
      props: ({ formikProps }) => ({
        fieldName: "metadata.category",
        ...formikProps,
      }),
    },
  ],
};
