// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { getKeyInStore } from "containers/Request/getKeyInStore";
import { addQueryParamsToUrl, pathToUrl } from "utils/url";

import { REDUX_KEY } from "./constants";

export const getRequestState = (
  state,
  methodName,
  path,
  params,
  queryParams
) => {
  let url = pathToUrl(path, params);
  url = addQueryParamsToUrl(url, queryParams);
  const keyInStore = getKeyInStore(methodName, url);

  return get(state, [REDUX_KEY, keyInStore]);
};

export const getResult = (state, apiEndpoint, params = {}, queryParams) => {
  const rawState = getRequestState(
    state,
    apiEndpoint.methodName,
    apiEndpoint.path,
    params,
    queryParams
  );
  return get(rawState, "result");
};

export const getPending = (state, apiEndpoint, params = {}, queryParams) => {
  const rawState = getRequestState(
    state,
    apiEndpoint.methodName,
    apiEndpoint.path,
    params,
    queryParams
  );
  return get(rawState, "pending");
};

export const getError = (state, apiEndpoint, params = {}, queryParams) => {
  const rawState = getRequestState(
    state,
    apiEndpoint.methodName,
    apiEndpoint.path,
    params,
    queryParams
  );

  return get(rawState, "error");
};
