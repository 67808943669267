// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { createExportQueryParams } from "components/layout";
import { ROLES, restrictedArea } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withQueryParams } from "utils/decorators";

import { APP_ROUTE_AVAILABLE } from "../../constants";
import { availableAppsTabsConfig } from "../ApplicationsTabbedTableConfig";
import { ApplicationsTabbedTableLogic } from "../ApplicationsTabbedTableLogic";

@restrictedArea(() => ({
  allowed: [
    ROLES.moderator.moderator,
    ROLES.moderator.contentManager,
    ROLES.administrator.distributionAdmin,
  ],
}))
@withQueryParams // required for @connect to realize props have changed
@connect(null, (dispatch) => ({
  getApplicationsPaginated: (queryParams) =>
    dispatch(
      apiThatThrows.getDistributionApplicationsPaginated.action({
        queryParams,
      })
    ),
  getAvailableFilters: () =>
    dispatch(
      apiThatThrows.getDistributionApplicationsFilters.action({
        cache: true,
      })
    ),
  getSearchSuggestions: (queryParams, abortSignal) =>
    dispatch(
      apiThatThrows.getDistributionApplicationsSuggestions.action({
        queryParams,
        abortSignal,
      })
    ),
  exportDistributionApplicationsPaginated: async (queryParams) => {
    await dispatch(
      apiThatThrows.exportDistributionApplicationsPaginated.action({
        queryParams,
      })
    );
  },
}))
export class AvailableAppListContent extends Component {
  static propTypes = {
    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @connect
    getAvailableFilters: PropTypes.func.isRequired,
    getApplicationsPaginated: PropTypes.func.isRequired,
    getSearchSuggestions: PropTypes.func.isRequired,
    exportDistributionApplicationsPaginated: PropTypes.func.isRequired,
  };

  onExport = async ({ fileFormat, includeSensitiveData }) => {
    const { exportDistributionApplicationsPaginated, queryParams } = this.props;

    const exportQueryParams = createExportQueryParams({
      fileFormat,
      includeSensitiveData,
      queryParams,
      isAppListExclusive: true,
    });

    await exportDistributionApplicationsPaginated(exportQueryParams);
  };

  render() {
    const {
      getSearchSuggestions,
      getApplicationsPaginated,
      getAvailableFilters,
    } = this.props;

    return (
      <ApplicationsTabbedTableLogic
        appRouteType={APP_ROUTE_AVAILABLE}
        tabsConfig={availableAppsTabsConfig}
        getApplicationsPaginated={getApplicationsPaginated}
        getAvailableFilters={getAvailableFilters}
        getSearchSuggestions={getSearchSuggestions}
        onExport={this.onExport}
        isAppListExclusive={true}
      />
    );
  }
}
