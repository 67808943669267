// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getUpdateJobDevicesQuery = gql`
  query updateJobDevices(
    $id: ID!
    $devicesPagination: PaginationInput!
    $search: SearchInput
    $sort: EligibleDevicesSortInput
  ) {
    updateJob(updateJobId: $id) {
      updateJob {
        id
        eligibleDevicesCount
        eligibleDevices(
          pagination: $devicesPagination
          search: $search
          sort: $sort
        ) {
          id
          status
          lastContactDate
        }
      }
    }
  }
`;

export const getUpdateJobQuery = gql`
  query updateJob($id: ID!) {
    updateJob(updateJobId: $id) {
      updateJob {
        id
        name
        type
        description
        status
        statusActions
        softwarePackageVersion {
          id
          fileName
          softwarePackage {
            id
          }
        }
        activeStepId
        steps {
          id
          status
          deviceCount
          successDeviceCount
          failureDeviceCount
          postUpdateRebootCounters {
            rebootFailuresCounter
            rebootPendingCounter
          }
          inProgressDeviceCount
        }
        target {
          id
          name
          devices(pagination: {}) {
            pagination {
              count
            }
          }
        }
        threshold
        creationDate
        availabilityStartDate
        availabilityEndDate
        downloadUrl
        isRepeatable
        eligibleDevicesCount
      }
    }
  }
`;
