// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Children, Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import { tableHeadPosPropType } from "./propTypes";
import styles from "./TableHeader.scss";
import TableLoader from "./TableLoader";

/** Corresponds to `<thead>` and children `<tr>`. Should be placed inside `<Table>` */
export class TableHeader extends Component {
  static propTypes = {
    /** [TableHeaderColumn](#tableheadercolumn) elements. */
    children: PropTypes.node,
    loading: PropTypes.bool,
    dataTestId: PropTypes.string,
    /** Defines if table header stays in place on table scroll */
    position: tableHeadPosPropType,
  };

  static defaultProps = {
    loading: false,
    dataTestId: "table-header",
    position: "default",
  };

  render() {
    const { dataTestId, loading, children, position } = this.props;
    const arr = Children.toArray(children);
    const span = arr.length
      ? arr.reduce((acc, node) => acc + (node.props.colspan || 1), 0)
      : 1;

    return (
      <thead
        className={classes(styles.container, styles[position])}
        data-test-id={dataTestId}
      >
        <tr className={styles.row}>{children}</tr>
        <TableLoader loading={loading} colspan={span} position={position} />
      </thead>
    );
  }
}

export default TableHeader;
