// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { SearchBar, ScrollTable, Loader } from "components/elements";
import { Info } from "components/feedback";
import { Checkbox } from "components/form/elements";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { InfiniteData } from "containers/InfiniteData/InfiniteData";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

import { getIncludeAppsTitles } from "../PromotionItem";
import { getAppNames } from "../PromotionsTable";
import styles from "./PromotionsSearchableList.scss";

const ITEMS_PER_PAGE = 20;

const COLUMNS = [
  {
    id: "select",
    dataTestId: "select-column-header",
    className: styles.selectHeader,
  },
  {
    id: "name",
    label: trans.CAMPAIGN_EDIT__FIELD_PROMOTIONS_PROMOTION_NAME(),
    dataTestId: "promotion-name-header",
  },
  {
    id: "include-apps",
    label: trans.CAMPAIGN_EDIT__FIELD_PROMOTIONS_INCLUDE_APPS(),
    dataTestId: "include-apps-header",
  },
];

@connect(null, (dispatch, ownProps) => ({
  getPromotionsPaginated: (queryParams) => {
    const getPromotionsAction = ownProps.forAdmin
      ? apiThatThrows.getPromotionsPaginated.action
      : apiThatThrows.getMyPromotionsPaginated.action;
    return dispatch(
      getPromotionsAction({
        queryParams: {
          ...queryParams,
          limit: ITEMS_PER_PAGE,
          admin_managed: Boolean(ownProps.forAdmin),
          include: ["promotion_details"],
        },
      })
    );
  },
  getApplications: async (appIds, page = 1) => {
    if (appIds.length === 0) {
      return [];
    }

    const { results } = await dispatch(
      apiThatThrows.getApplicationsPublicInfoPaginated.action({
        queryParams: {
          public_id: appIds,
          limit: appIds.length,
          page,
        },
      })
    );

    return results;
  },
}))
export class PromotionsSearchableList extends Component {
  static propTypes = {
    selectedCount: PropTypes.number,
    loading: PropTypes.bool,
    error: PropTypes.object,
    onCheck: PropTypes.func,
    onCheckAll: PropTypes.func,
    isChecked: PropTypes.func,
    newDataLoaded: PropTypes.func,
    allChecked: PropTypes.bool,
    forAdmin: PropTypes.bool,

    // from @connect
    getPromotionsPaginated: PropTypes.func,
    getApplications: PropTypes.func,
  };

  state = {
    appDetails: {},
  };

  fetchData = async (queryParams) => {
    const { getApplications, getPromotionsPaginated } = this.props;
    const { appDetails } = this.state;
    const promotionsData = await getPromotionsPaginated({
      ...queryParams,
    });

    const applicationIds = promotionsData.results.flatMap((promotion) =>
      promotion.promotion_details
        .map((details) => details.application_id)
        .filter((e) => e)
    );

    const fetchedAppDetails = await getAppNames(
      applicationIds,
      getApplications
    );

    this.setState({ appDetails: { ...appDetails, ...fetchedAppDetails } });

    return promotionsData;
  };

  renderSelectAll = (items, pending) => {
    const { selectedCount, onCheckAll } = this.props;
    const checked = selectedCount === items.length;

    return (
      <Checkbox
        className={styles.selectAll}
        checked={checked}
        disabled={items.length <= 0 || pending}
        onChange={() => onCheckAll(items)}
        value="items"
      />
    );
  };

  renderStatus = (count) => {
    const { selectedCount } = this.props;
    return (
      <p className={styles.selectedStatus}>
        {trans.CAMPAIGN_EDIT__STATUS({
          count,
          selected: selectedCount,
        })}
      </p>
    );
  };

  renderSearchComponent = (onSearch) => {
    return (
      <SearchBar
        placeholder={trans.CAMPAIGN_EDIT__FIELD_PROMOTIONS_SEARCH_FOR_PROMOTION()}
        onSearch={onSearch}
      />
    );
  };

  renderPromotionItem = (promotion, pending) => {
    const { name, id, promotion_details } = promotion;
    const { appDetails } = this.state;
    const applicationIds = promotion_details.map((app) => app.application_id);

    const { isChecked, onCheck, forAdmin } = this.props;
    return (
      <TableRow key={id} highlighting={false}>
        <TableRowColumn>
          <Checkbox
            disabled={pending}
            name={`promotion-checkbox-${id}`}
            checked={isChecked(id)}
            onChange={() => onCheck(id)}
            value={id}
          />
        </TableRowColumn>
        <TableRowColumnExtended
          to={`/promotions${forAdmin ? "/admin" : ""}/edit/${id}`}
          urlTarget="blank"
          text={name}
          columnClass={styles.nameColumn}
        />
        <TableRowColumn className={styles.includeAppsColumn}>
          {getIncludeAppsTitles(applicationIds, appDetails)}
        </TableRowColumn>
      </TableRow>
    );
  };

  render() {
    const { loading, error, newDataLoaded, allChecked } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message}</Info>;
    }

    return (
      <InfiniteData
        fetchData={this.fetchData}
        mapData={(data) => data}
        itemsPerPage={ITEMS_PER_PAGE}
        fetchAllData={allChecked}
        newDataLoaded={newDataLoaded}
      >
        {({
          data,
          pending,
          error,
          count,
          handleScroll,
          handleSearchChange,
        }) => (
          <div className={styles.wrapper}>
            {this.renderSearchComponent(handleSearchChange)}
            <ScrollTable
              columns={COLUMNS}
              className={styles.table}
              data={{
                results: data,
                loading: pending,
                error: error,
              }}
              layout="fixed"
              dataTestId="promotions-list"
              onScroll={handleScroll}
              selectAllComponent={this.renderSelectAll(data, pending)}
            >
              {(item) => this.renderPromotionItem(item, pending)}
            </ScrollTable>
            {this.renderStatus(count)}
          </div>
        )}
      </InfiniteData>
    );
  }
}
