// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./row.scss";

/**
 * Row component to be used with `<Column>`.
 * Please do not use it as substitute to `<Table>` components.
 * It is only for e.g. 2 column layouts on details views.
 */
export class Row extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "row",
  };

  render() {
    const { children, className, dataTestId, ...props } = this.props;

    return (
      <div
        className={classes(styles.row, className)}
        data-test-id={dataTestId}
        {...props}
      >
        {children}
      </div>
    );
  }
}
