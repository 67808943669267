// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { classes } from "utils/classes";

import styles from "./pill.scss";

/** Pill */
export const Pill = ({
  text,
  icon,
  iconSize = "sm",
  className = "",
  dataTestId = "pill",
}) => (
  <span
    className={classes(className, styles.topPill)}
    data-test-id={dataTestId}
  >
    {text}
    {icon && (
      <GrabsonIcon
        dataTestId={`${dataTestId}-icon`}
        name={icon}
        className={styles.icon}
        size={iconSize}
      />
    )}
  </span>
);

Pill.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
