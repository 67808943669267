import { useDispatch } from "react-redux";

import { Button } from "components/buttons";
import { PageContent } from "components/layout";
import { unauthorize } from "containers/Auth/actions";
import { UNAUTHORIZED } from "containers/Auth/actionTypes";
import { NoPermissions } from "src/containers/Permissions/fallbacks";
import { trans } from "src/translations";

export function Unauthorized() {
  const dispatch = useDispatch();
  const handleRefresh = () => {
    dispatch(unauthorize());
  };

  return (
    <>
      <PageContent>
        <NoPermissions type={UNAUTHORIZED}>
          <Button type="green" onClick={handleRefresh}>
            {trans.REFRESH_TOKEN()}
          </Button>
        </NoPermissions>
      </PageContent>
    </>
  );
}
