// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { IsUpdatedConsumer } from "utils/context/IsUpdated";

import { VERSION_TABS } from "../../constants";
import { VersionContext } from "./context";

export const IsVersionUpdated = ({ children, path, isUpdated }) => {
  return (
    <IsUpdatedConsumer
      Context={VersionContext}
      path={path}
      isUpdated={isUpdated}
    >
      {({ isUpdated, extraValue }) => {
        const currentVersionId = extraValue && extraValue.currentVersionId;
        const showIsUpdated = Boolean(
          isUpdated && currentVersionId === VERSION_TABS.latest
        );
        return children(showIsUpdated);
      }}
    </IsUpdatedConsumer>
  );
};

IsVersionUpdated.propTypes = {
  children: PropTypes.func.isRequired,
  /** If not provided, 'isUpdated' prop required */
  path: PropTypes.string,
  /** If not provided, internal this.isUpdated used and 'path' prop required */
  isUpdated: PropTypes.func,
};
