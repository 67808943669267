// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";

import { GeoMap } from "components/elements";
import { ValidationBadge } from "components/form";

export default class Countries extends Component {
  static propTypes = {
    locationCodes: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.string),
      invalid: PropTypes.bool,
      touched: PropTypes.bool,
      error: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    disabledLocationCodes: PropTypes.array,
    onCountriesCodes: PropTypes.func,
    allCountries: PropTypes.array.isRequired,
  };

  onCountryToggle = (changedCountryCodes, value) => {
    const {
      locationCodes: { value: currentlySelected },
      onCountriesCodes,
    } = this.props;

    let countriesCodes;
    if (value) {
      countriesCodes = uniq([...currentlySelected, ...changedCountryCodes]);
    } else {
      countriesCodes = currentlySelected.filter(
        (c) => !changedCountryCodes.includes(c)
      );
    }
    onCountriesCodes(countriesCodes);
  };

  render() {
    const { locationCodes, disabledLocationCodes, allCountries } = this.props;

    return (
      <div>
        <GeoMap
          markedCountries={locationCodes.value}
          onItemsSelected={this.onCountryToggle}
          disabledCountries={disabledLocationCodes}
          allCountries={allCountries}
          fieldName={locationCodes.name}
        />
        {locationCodes.error && (
          <ValidationBadge
            error={locationCodes.error}
            touched={locationCodes.touched}
          />
        )}
      </div>
    );
  }
}
