// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import iconSVG from "images/logoNameDark.svg";

import styles from "./pageLoader.scss";

/**
 * Component that covers whole page with "Loading" state.
 * Only used durin app initialization.
 */
export class PageLoader extends Component {
  static propTypes = {
    text: PropTypes.string,
  };

  render() {
    const { text } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <img src={iconSVG} className={styles.logo} />
          <Loader size="large" />
          {text && <p className={styles.text}>{text}</p>}
        </div>
      </div>
    );
  }
}
