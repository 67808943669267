// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { UPDATE_JOB_STATUSES } from "src/pages/Updates/_shared/UpdateJobStatus.js";
import { UPDATE_JOB_STEP_STATUSES } from "src/pages/Updates/_shared/UpdateJobStepStatus.js";

export const UpdateJobStepPropType = PropTypes.shape({
  stepId: PropTypes.string.isRequired,
  stepIndex: PropTypes.number.isRequired,
  hasStarted: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(Object.keys(UPDATE_JOB_STEP_STATUSES)),
  ratio: PropTypes.number,
  isWithinThreshold: PropTypes.bool.isRequired,

  // devices count:
  successes: PropTypes.number.isRequired,
  inProgress: PropTypes.number.isRequired,
  failures: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
});

export const UpdateJobPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.keys(UPDATE_JOB_STATUSES)),
  threshold: PropTypes.number.isRequired,
  statusActions: PropTypes.arrayOf(PropTypes.string).isRequired,
  creationDate: PropTypes.string.isRequired,
  availabilityStartDate: PropTypes.string.isRequired,
  availabilityEndDate: PropTypes.string.isRequired,
  logsStartDate: PropTypes.instanceOf(Date), // in UTC. Use raw value from backend!
  description: PropTypes.string.isRequired,
  activeStepId: PropTypes.string,
  steps: PropTypes.arrayOf(UpdateJobStepPropType).isRequired,
  softwarePackageVersion: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    softwarePackage: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    deviceCount: PropTypes.number.isRequired,
  }).isRequired,
});
