// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { urlRegex } from "utils/validation";

import {
  SNAP_EDIT_MINIMUM_SECTION_NUMBER,
  HOME_ID,
  ERRORS,
  FIELDS,
} from "../shared/constants";

const NUM_FIELDS_AD_CONFIG = [
  FIELDS.AD_PRE_OFFSET.name,
  FIELDS.AD_PRE_INTERVAL.name,
  FIELDS.AD_PRE_MIN_CONTENT_LENGTH.name,
  FIELDS.AD_MID_OFFSET.name,
  FIELDS.AD_MID_INTERVAL.name,
  FIELDS.AD_MID_MIN_OFFSET.name,
  FIELDS.AD_MID_MIN_INTERVAL.name,
];

const required = (text) => (value) => (value ? undefined : text);

const isUrl = (value) => urlRegex.test(value);

const isValidNaturalNumber = (value) => {
  if (typeof value === "number") {
    return value >= 0 && parseInt(value, 10) === value
      ? undefined
      : ERRORS.INCORRECT_VALUE;
  }
  if (typeof value === "string") {
    const int = parseInt(value, 10);
    return int >= 0 && int.toString() === value
      ? undefined
      : ERRORS.INCORRECT_VALUE;
  }
  return ERRORS.INCORRECT_VALUE;
};

const isValidStream = ({
  url,
  name,
  thumbnail,
  thumbnail_width,
  thumbnail_height,
  feed_type,
  description,
}) =>
  isUrl(url) &&
  name &&
  isUrl(thumbnail) &&
  thumbnail_width &&
  thumbnail_height &&
  feed_type &&
  description;

const isValidHomeSection = ({ show, url }, fieldsNumber) => {
  // if home is visible but there is no url or any other sections to get content from, then home is not valid
  return (
    !show ||
    isUrl(url) ||
    (!url && fieldsNumber > SNAP_EDIT_MINIMUM_SECTION_NUMBER)
  );
};

const isValidLiveSection = ({ name, icon, live_feeds }) => {
  const liveStreamsValid =
    live_feeds && live_feeds.length > 0 && live_feeds.every(isValidStream);
  return name && icon && liveStreamsValid;
};

const isValidSection = (section, index) => {
  if (index !== HOME_ID && section) {
    const { live, name, url, icon } = section;
    return !live ? name && isUrl(url) && icon : isValidLiveSection(section);
  }
  return true;
};

const isValidTranslation = (translation) => {
  if (!translation || !translation.sections) {
    return false;
  }
  const homeSection = translation.sections[HOME_ID];
  // home section is always defined (even if it's hidden)
  const fieldsNumber = homeSection.show
    ? translation.sections.length
    : translation.sections.length - 1;

  const isMetaOK = Boolean(
    translation.application_title && translation.language
  );
  const isAmountOK = fieldsNumber >= SNAP_EDIT_MINIMUM_SECTION_NUMBER;
  const isSectionsOK = translation.sections.every(isValidSection);
  const isHomeOK = isValidHomeSection(homeSection, fieldsNumber);

  return isMetaOK && isAmountOK && isSectionsOK && isHomeOK;
};

const getTranslationErrors = (translation) => {
  const errorObject = {};

  if (!translation.application_title) {
    errorObject.application_title = ERRORS.ENTER_APPLICATION_TITLE;
  }

  if (!translation.language) {
    errorObject.language = ERRORS.SELECT_LANGUAGE;
  }
  return errorObject;
};

const snapEditValidation = (values, appId) => {
  const errors = {};
  const possibleAppIdErrors = {};

  if (values && values[appId]) {
    const snapEdit = values[appId];
    const areTranslationsValid =
      snapEdit.translations.every(isValidTranslation);

    NUM_FIELDS_AD_CONFIG.forEach((field) => {
      const hasError = isValidNaturalNumber(snapEdit[field]);
      if (hasError) {
        possibleAppIdErrors[field] = hasError;
      }
    });

    if (!areTranslationsValid) {
      possibleAppIdErrors.translations =
        snapEdit.translations.map(getTranslationErrors);
    }

    if (!snapEdit.vt_type) {
      possibleAppIdErrors.vt_type = ERRORS.VIDEO_SOURCE_SELECT;
    }

    if (Object.keys(possibleAppIdErrors).length !== 0) {
      errors[appId] = possibleAppIdErrors;
    }
  }
  return errors;
};

export {
  required,
  isValidSection,
  isValidTranslation,
  isValidHomeSection,
  isValidLiveSection,
  isValidNaturalNumber,
  snapEditValidation,
  isUrl,
  isValidStream,
};
