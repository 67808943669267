// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { adaptQueryParamsToGqlSearchArg } from "pages/_utils/adaptQueryParamsToGqlSearchArg";
import { trans } from "src/translations";
import {
  withQueryParams,
  withRouter,
  withSearch,
  withSorting,
} from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { queryResultType, withQuery } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";
import { createLocationWithSearch } from "utils/url";

import { CommandsListRend } from "./CommandsListRend";
import { ITEMS_PER_PAGE, TABS_CONFIG } from "./config";
import { getCommandsFiltersQuery } from "./gql/getCommandsFiltersQuery";
import { getCommandsListQuery } from "./gql/getCommandsListQuery";

@withSearch
@withRouter
@withQueryParams
@withActiveOrganization
@withQuery({
  name: "commandsList",
  query: getCommandsListQuery,
})
@withQuery({
  name: "commandsFilters",
  query: getCommandsFiltersQuery,
})
@withSorting({
  defaultSortBy: "creation_date",
  allowedSortByValues: TABS_CONFIG[0].columns
    .map(({ sortBy }) => sortBy)
    .filter((sortBy) => Boolean(sortBy)),
  defaultSortOrder: "DESC",
})
export class CommandsList extends Component {
  static propTypes = {
    // from @withQueryParams
    queryParams: PropTypes.object,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withQuery
    commandsListQuery: PropTypes.func,
    commandsListQueryStatus: queryResultType,
    commandsFiltersQuery: PropTypes.func,
    commandsFiltersQueryStatus: queryResultType,

    // from @withSorting
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  };

  fetchCommandsList() {
    const { queryParams, location, sortBy, sortOrder } = this.props;
    const variables = {
      pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
      sort: {
        name: sortBy.toUpperCase(),
        order: sortOrder,
      },
      search: adaptQueryParamsToGqlSearchArg(queryParams),
    };

    this.props.commandsListQuery({ variables });
  }

  fetchCommandsFilters() {
    this.props.commandsFiltersQuery();
  }

  componentDidMount() {
    this.fetchCommandsList();
    this.fetchCommandsFilters();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location === prevProps.location &&
      this.props.activeOrganization.id === prevProps.activeOrganization.id
    ) {
      return;
    }
    this.fetchCommandsList();
  }

  onSearch = (filters) => {
    const { location, navigate } = this.props;
    const newLocation = createLocationWithSearch(location, filters);
    navigate(newLocation);
  };

  get commandsList() {
    const { data, error, loading } = this.props.commandsListQueryStatus;

    return {
      error: error && getGqlErrorMessage(error),
      loading,
      count: data?.commands?.commands?.pagination?.count || 0,
      results: data?.commands?.commands?.items || [],
    };
  }

  get commandsFilters() {
    const { data } = this.props.commandsFiltersQueryStatus;
    const filters = data?.commandsListFilters?.commandsListFilters;
    return filters && { sections: [filters] };
  }

  render() {
    return (
      <div>
        <PageHeader>{trans.DEVICES__COMMANDS_HEADER()}</PageHeader>
        <PageContent>
          <CommandsListRend
            availableFilters={this.commandsFilters}
            commands={this.commandsList}
            onSearch={this.onSearch}
          />
        </PageContent>
      </div>
    );
  }
}
