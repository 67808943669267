// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const optionPropType = PropTypes.shape({
  suggestion: PropTypes.string,
});

export const sectionPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(optionPropType).isRequired,
});
