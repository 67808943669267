// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { ScheduleChart } from "components/charts";
import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { trans } from "src/translations";
import { withRouter, withSearch } from "utils/decorators";

@withRouter
@withSearch
@withActiveOrganization
export class Schedule extends Component {
  static propTypes = {
    campaigns: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.string,
    forAdmin: PropTypes.bool,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  };

  handleEventClick = (id) => {
    const { navigate, forAdmin } = this.props;
    navigate(`/promotions/${forAdmin ? "admin/" : ""}campaigns/${id}`);
  };

  render() {
    const {
      campaigns,
      loading,
      error,
      searchValue,
      changeSearch,
      location,
      forAdmin,
      activeOrganization: { name },
    } = this.props;
    const locationMessage = get(location, "state.message");

    return (
      <div>
        <Section
          header={
            forAdmin
              ? trans.SCHEDULE__ACTUAL_SCHEDULE_HEADER_ADMIN()
              : trans.SCHEDULE__ACTUAL_SCHEDULE_HEADER({ organization: name })
          }
        >
          <Section>
            <SearchBar
              onSearch={changeSearch}
              value={searchValue}
              placeholder={trans.FILTER()}
            />
          </Section>
          <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
          {error ? (
            <Info type="error">{error}</Info>
          ) : (
            <ScheduleChart
              data={campaigns}
              onEventClick={this.handleEventClick}
              loading={loading}
            />
          )}
        </Section>
      </div>
    );
  }
}
