// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField } from "formik";

import { createMixedValidator } from "components/form/fields/fieldUtils";

import { Select } from "../elements";
import { FieldWithOptionsProps } from "./propTypes";

export const SelectField = (props) => {
  const { values, required = true, validate, ...rest } = props;

  const [field, meta] = useField({
    ...props,
    required,
    validate: createMixedValidator(props.name, required, validate),
    type: "select",
  });

  const getValues = () =>
    values.map(({ label, value, ...rest }) => ({
      value: value,
      text: label,
      ...rest,
    }));

  return (
    <Select
      required={required}
      {...field}
      {...meta}
      {...rest}
      values={getValues()}
    />
  );
};

SelectField.propTypes = FieldWithOptionsProps;
