// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { api } from "containers/Request";

const LIST_NO_RESULTS = {
  result: {
    results: [],
    meta: { count: 0 },
  },
};

/**
 * Depending if the user has activeOrganization, we use different endpoints
 * for getUserProfile, patchUserProfile. This decorator hides this fact,
 * exposing only generic methods.
 */
export const withUserViewEdit = (ComposedComponent) => {
  @withActiveOrganization
  @connect(
    ({ auth }) => ({
      currentUserId: auth.profile.id,
    }),
    (dispatch, props) => ({
      getUserProfile: (id) => {
        const getProfileAction = props.forUserAdmin
          ? api.getUserProfileNoOrganization.action
          : api.getUserProfile.action;
        return dispatch(getProfileAction({ params: { id } }));
      },
      getCurrentUser: () => dispatch(api.getCurrentUser.action()),
      getUserGrants: (userId) =>
        dispatch(
          api.getUserGrantsPaginated.action({
            params: { userId },
            queryParams: { limit: "nolimit" },
          })
        ),
      patchUser: (id, body) => {
        const action = props.forUserAdmin
          ? api.patchUserProfileNoOrganization.action
          : api.patchUserProfile.action;
        return dispatch(
          action({
            params: { id },
            options: { body },
          })
        );
      },
      patchUserProfileNoOrganization: (id, body) =>
        dispatch(
          api.patchUserProfileNoOrganization.action({
            params: { id },
            options: { body },
          })
        ),
    })
  )
  class WithUserViewEditWrapper extends Component {
    static propTypes = {
      forUserAdmin: PropTypes.bool,

      // from @connect
      currentUserId: PropTypes.string.isRequired,
      getUserProfile: PropTypes.func.isRequired,
      getCurrentUser: PropTypes.func.isRequired,
      getUserGrants: PropTypes.func.isRequired,
      patchUser: PropTypes.func.isRequired,
      patchUserProfileNoOrganization: PropTypes.func.isRequired,

      // from @withActiveOrganization
      activeOrganization: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    };

    shouldRequestSelfIfNoOrganization = (userId) => {
      const { currentUserId, activeOrganization } = this.props;
      return !activeOrganization && currentUserId === userId;
    };

    getUserProfile = (userId) => {
      const { getUserProfile, getCurrentUser } = this.props;

      if (this.shouldRequestSelfIfNoOrganization(userId)) {
        return getCurrentUser();
      }

      return getUserProfile(userId);
    };

    getUserGrants = async (userId) => {
      const { getUserGrants } = this.props;

      if (this.shouldRequestSelfIfNoOrganization(userId)) {
        return LIST_NO_RESULTS;
      }

      return await getUserGrants(userId);
    };

    patchUserProfile = (userId, data) => {
      const { patchUser, patchUserProfileNoOrganization } = this.props;

      if (this.shouldRequestSelfIfNoOrganization(userId)) {
        return patchUserProfileNoOrganization(userId, data);
      }

      return patchUser(userId, data);
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          getUserProfile={this.getUserProfile}
          getUserGrants={this.getUserGrants}
          patchUserProfile={this.patchUserProfile}
        />
      );
    }
  }

  return WithUserViewEditWrapper;
};
