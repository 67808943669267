// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { UploaderAWSModal } from "components/form";
import { Link } from "components/typography";
import { trans } from "src/translations";
import { withMutation } from "utils/graphql";

import {
  MANIFEST_INSTRUCTIONS,
  FILE_TYPE_MIME,
  FILE_TYPE_STRING,
} from "./constants";
import { createSoftwarePackageVersion } from "./gql/createSoftwarePackageVersion";
import { generateSoftwarePackageUploadUrlMutation } from "./gql/generateSoftwarePackageUploadUrlMutation";
import styles from "./UploadSoftwarePackageModal.scss";

@withMutation({
  name: "generateUploadUrl",
  mutation: generateSoftwarePackageUploadUrlMutation,
})
@withMutation({
  name: "createSoftwarePackageVersion",
  mutation: createSoftwarePackageVersion,
})
export class UploadSoftwarePackageModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleUploadSuccess: PropTypes.func.isRequired,

    // from @withMutation
    generateUploadUrlMutation: PropTypes.func.isRequired,
    createSoftwarePackageVersionMutation: PropTypes.func.isRequired,
  };

  getCredentials = async () => {
    const { generateUploadUrlMutation } = this.props;

    const resp = await generateUploadUrlMutation();
    const data = get(
      resp,
      "data.generateSoftwarePackageUploadUrl.softwarePackageUploadUrl",
      {}
    );

    return {
      url: data.url,
      headers: JSON.parse(data.headers),
    };
  };

  createSoftwarePackage = async (fileData) => {
    const { createSoftwarePackageVersionMutation, handleUploadSuccess } =
      this.props;

    await createSoftwarePackageVersionMutation({
      variables: {
        input: {
          url: fileData.url,
          name: fileData.name,
        },
      },
    });

    // executed if mutation did not threw
    handleUploadSuccess();
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <UploaderAWSModal
        isOpen={isOpen}
        onClose={onClose}
        onUpload={this.createSoftwarePackage}
        getCredentials={this.getCredentials}
        formats={FILE_TYPE_MIME}
        texts={{
          title: trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_TITLE(),
          description: (
            <Fragment>
              {trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_TEXT_A()}
              <br />
              {trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_TEXT_B()}{" "}
              <Link
                target="_blank"
                href={MANIFEST_INSTRUCTIONS}
                className={styles.manifestGuidelinesLink}
                data-test-id="show-link-input-button"
              >
                {trans.HERE()}
              </Link>
              {"."}
            </Fragment>
          ),
          fileExtensionHint: (
            <Fragment>
              <span className={styles.bold}>{FILE_TYPE_STRING}</span>{" "}
              {trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_MODAL_EXTENSION_HINT()}
            </Fragment>
          ),
          httpWarningDescription: (
            <Fragment>
              <p className={styles.httpDescriptionSpacer}>
                {trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_HTTP_WARNING_TEXT_LINE_1()}
              </p>
              <p className={styles.httpDescriptionSpacer}>
                {trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_HTTP_WARNING_TEXT_LINE_2()}
              </p>
            </Fragment>
          ),
          httpWarningCheckbox:
            trans.UPDATES__UPLOAD_SOFTWARE_PACKAGE_HTTP_WARNING_CHECKBOX(),
        }}
      />
    );
  }
}
