// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "utils/decorators/withRouter";
import { pathToLocation } from "utils/url";

import { PreviousLocationConsumer } from "./context";

export const withPreviousLocation =
  ({ defaultPath } = {}) =>
  (ComposedComponent) => {
    @withRouter
    class WithPreviousLocation extends Component {
      static propTypes = {
        // from @withRouter
        navigate: PropTypes.func.isRequired,
      };

      goBack = (previousLocation) => (state) => {
        const { navigate } = this.props;

        let defaultLocation = { pathname: "/" };
        if (defaultPath) {
          defaultLocation = pathToLocation(defaultPath);
        } else {
          console.warn(
            "Please pass 'defaultPath' to @withPreviousLocation. Otherwise, if previous location does not exist, user will be redirected to '/' path!"
          );
        }

        const location = previousLocation || defaultLocation;

        navigate(
          {
            ...location,
          },
          { state }
        );
      };

      render() {
        return (
          <PreviousLocationConsumer>
            {({ previousLocation }) => (
              <ComposedComponent
                {...this.props}
                previousLocation={previousLocation}
                goBack={this.goBack(previousLocation)}
              />
            )}
          </PreviousLocationConsumer>
        );
      }
    }

    return WithPreviousLocation;
  };
