// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { Section, TableBody, Modal } from "components/layout";
import { Link } from "components/typography";
import {
  organizationsPropTypes,
  profilePropTypes,
} from "containers/Auth/propTypes";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { isModerator } from "utils/user";

import { UPGRADE_TO_PREMIUM_ORG_MAIL } from "../../constants";
import { Actions } from "./Actions";
import { DeviceRow } from "./DeviceRow";
import { FamilyRow } from "./FamilyRow";
import { NoEnvironmentModal } from "./Modals/NoEnvironmentModal";
import { NoPermissionModal } from "./Modals/NoPermissionModal";
import { OnHoldAppOnTargets } from "./OnHoldAppOnTargets/OnHoldAppOnTargets";
import { OrganizationRow } from "./OrganizationRow";
import { RejectTargets } from "./RejectTargets/RejectTargets";
import styles from "./SectionTargetsRenderer.scss";

@connect(({ auth }) => ({
  organizations: auth.organizations,
  currentUser: auth.profile,
}))
export class SectionTargetsRenderer extends Component {
  static propTypes = {
    application: PropTypes.object,
    isRejectTargetsModalOpen: PropTypes.bool.isRequired,
    isOnHoldModalOpen: PropTypes.bool.isRequired,
    hasOnHoldTargets: PropTypes.bool,
    approving: PropTypes.bool.isRequired,
    proceeding: PropTypes.bool.isRequired,
    showRevenueWarning: PropTypes.bool,
    successMessage: PropTypes.string,
    isNoAgreementModalOpen: PropTypes.bool.isRequired,
    isNoEnvironmentModalOpen: PropTypes.bool.isRequired,
    toggleNoAgreementModal: PropTypes.func.isRequired,
    toggleNoEnvironmentModal: PropTypes.func.isRequired,
    onProceedNoAgreementModal: PropTypes.func.isRequired,

    onCloseRejectTargetsModal: PropTypes.func.isRequired,
    onCloseOnHoldModal: PropTypes.func.isRequired,

    onApprove: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    onOnHold: PropTypes.func.isRequired,
    onProceed: PropTypes.func.isRequired,

    onDistributorToggle: PropTypes.func.isRequired,
    onFamilyToggle: PropTypes.func.isRequired,
    onModelToggle: PropTypes.func.isRequired,

    results: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    dataError: PropTypes.object,
    requestError: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    handleModerationActionSuccess: PropTypes.func.isRequired,

    isAnyModelSelected: PropTypes.bool.isRequired,

    moderationId: PropTypes.string,

    // from @connect
    organizations: organizationsPropTypes,
    currentUser: profilePropTypes,
  };

  state = {
    organizationsExpanded: {},
    familiesExpanded: {},
    isNoEnvironmentsModalOpen: false,
    isNoPermissionModalOpen: false,
    isReadonly: false,
  };

  componentDidMount() {
    const { organizations, currentUser } = this.props;
    this.setState({
      isReadonly: !isModerator(organizations, currentUser),
    });
  }

  static tabsConfig = [
    {
      dataTestId: "moderation-targets-list",
      columns: [
        { label: "" },
        { label: trans.NAME() },
        { label: trans.PENDING(), className: styles.statusColumn },
        { label: trans.APPROVED(), className: styles.statusColumn },
        { label: trans.REJECTED(), className: styles.statusColumn },
        {
          label: trans.APP_DETAILS_MODERATION_TARGETS__ON_HOLD(),
          className: styles.statusColumn,
        },
      ],
    },
  ];

  onExpandToggle = (stateKey) => (id) => () => {
    this.setState((prevState) => ({
      [stateKey]: {
        ...prevState[stateKey],
        [id]: !prevState[stateKey][id],
      },
    }));
  };

  tableBody = (data) => {
    const { organizationsExpanded, familiesExpanded, isReadonly } = this.state;

    const { onDistributorToggle, onFamilyToggle, onModelToggle } = this.props;

    return (
      <TableBody>
        {data.reduce((acc, organization) => {
          acc.push(
            <OrganizationRow
              key={`organization-${organization.id}`}
              id={organization.id}
              name={organization.name}
              isExpanded={Boolean(organizationsExpanded[organization.id])}
              isExpandable={Boolean(
                organization.families && organization.families.length > 0
              )}
              onToggle={this.onExpandToggle("organizationsExpanded")}
              onChecked={onDistributorToggle}
              pendingDevicesCount={organization.pendingDevicesCount}
              acceptedDevicesCount={organization.acceptedDevicesCount}
              rejectedDevicesCount={organization.rejectedDevicesCount}
              onHoldDevicesCount={organization.onHoldDevicesCount}
              isReadonly={isReadonly}
            />
          );

          if (organizationsExpanded[organization.id]) {
            organization.families.forEach((family) => {
              acc.push(
                <FamilyRow
                  key={`family-${family.id}`}
                  id={family.id}
                  name={family.name}
                  isExpanded={Boolean(familiesExpanded[family.id])}
                  isExpandable={Boolean(
                    family.devices && family.devices.length > 0
                  )}
                  distributorId={organization.id}
                  onToggle={this.onExpandToggle("familiesExpanded")}
                  onChecked={onFamilyToggle}
                  pendingDevicesCount={family.pendingDevicesCount}
                  acceptedDevicesCount={family.acceptedDevicesCount}
                  rejectedDevicesCount={family.rejectedDevicesCount}
                  onHoldDevicesCount={family.onHoldDevicesCount}
                  isReadonly={isReadonly}
                />
              );

              if (familiesExpanded[family.id]) {
                family.devices.forEach((device) => {
                  acc.push(
                    <DeviceRow
                      key={`device-${device.id}`}
                      id={device.id}
                      name={device.name}
                      moderationStatus={device.moderationStatus}
                      isOnHold={device.isOnHold}
                      isTesting={device.isTesting}
                      isDisabled={device.isDisabled}
                      distributorId={organization.id}
                      familyId={family.id}
                      onChecked={onModelToggle}
                      isReadonly={isReadonly}
                    />
                  );
                });
              }
            });
          }
          return acc;
        }, [])}
      </TableBody>
    );
  };

  render() {
    const { isReadonly } = this.state;
    const {
      application,
      isRejectTargetsModalOpen,
      isOnHoldModalOpen,
      isAnyModelSelected,
      hasOnHoldTargets,
      approving,
      proceeding,
      onCloseRejectTargetsModal,
      onCloseOnHoldModal,
      onReject,
      onOnHold,
      onProceed,
      onApprove,
      results,
      count,
      loading,
      dataError,
      requestError,
      handleModerationActionSuccess,
      moderationId,
      showRevenueWarning,
      successMessage,
      isNoAgreementModalOpen,
      isNoEnvironmentModalOpen,
      toggleNoAgreementModal,
      toggleNoEnvironmentModal,
      onProceedNoAgreementModal,
    } = this.props;
    return (
      <div>
        <Modal
          isOpen={isRejectTargetsModalOpen}
          onClose={onCloseRejectTargetsModal}
          title={trans.APP_DETAILS_MODERATION_TARGETS__REJECT_MODAL_TITLE()}
        >
          <RejectTargets
            handleModerationActionSuccess={handleModerationActionSuccess}
            onClose={onCloseRejectTargetsModal}
            moderationId={moderationId}
            application={application}
          />
        </Modal>
        <Modal
          isOpen={isOnHoldModalOpen}
          onClose={onCloseOnHoldModal}
          title={trans.APP_DETAILS_MODERATION_TARGETS__ON_HOLD_MODAL_TITLE()}
        >
          <OnHoldAppOnTargets
            handleModerationActionSuccess={handleModerationActionSuccess}
            onClose={onCloseOnHoldModal}
            moderationId={moderationId}
            application={application}
          />
        </Modal>

        <NoPermissionModal
          isOpen={isNoAgreementModalOpen}
          onClose={toggleNoAgreementModal}
          onProceed={onProceedNoAgreementModal}
        />
        <NoEnvironmentModal
          isOpen={isNoEnvironmentModalOpen}
          onClose={toggleNoEnvironmentModal}
        />

        <Section
          header={trans.APP_DETAILS_MODERATION_TARGETS__DEVICES_SECTION_HEADER()}
        >
          <div className={styles.sectionContent}>
            {!loading && showRevenueWarning && (
              <Info type="warning" className={styles.revenueWarning}>
                {`${trans.APP_DETAILS_MODERATION_TARGETS__APP_REVENUE_NOT_PREMIUM_WARN_A()} `}
                <Link href={`mailto:${UPGRADE_TO_PREMIUM_ORG_MAIL}`}>
                  {UPGRADE_TO_PREMIUM_ORG_MAIL}
                </Link>
                {` ${trans.APP_DETAILS_MODERATION_TARGETS__APP_REVENUE_NOT_PREMIUM_WARN_B()}`}
              </Info>
            )}
            {!loading && application.is_exclusive && (
              <Info type="warning">
                {`${trans.APP_DETAILS_MODERATION_TARGETS__EXCLUSIVE_WARNING()} `}
              </Info>
            )}
            {!loading && hasOnHoldTargets && (
              <Info type="warning" className={styles.infoMessage}>
                {trans.APP_DETAILS_MODERATION_TARGETS__SOME_ON_HOLD_WARNING()}
              </Info>
            )}
            {!loading && successMessage && (
              <Info type="normal" className={styles.infoMessage}>
                {successMessage}
              </Info>
            )}
            {requestError && <Info type="error">{requestError}</Info>}

            {!isReadonly && (
              <Actions
                onApprove={onApprove}
                approving={approving}
                onReject={onReject}
                onProceed={onProceed}
                proceeding={proceeding}
                onOnHold={onOnHold}
                isAnyModelSelected={isAnyModelSelected}
              />
            )}
            <TabbedTable
              className={styles.table}
              tabsConfig={SectionTargetsRenderer.tabsConfig}
              renderTableBody={this.tableBody}
              data={{ results, count, loading, error: dataError }}
              disableInteraction={approving || proceeding}
              rowsPerPage={count}
            />
            {!isReadonly && (
              <Actions
                onApprove={onApprove}
                approving={approving}
                onReject={onReject}
                onProceed={onProceed}
                proceeding={proceeding}
                onOnHold={onOnHold}
                isAnyModelSelected={isAnyModelSelected}
              />
            )}
          </div>
        </Section>
      </div>
    );
  }
}
