// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Row, Column } from "components/layout";
import { RestrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";

import { CERTIFICATION_STATUS } from "./constants";
import { Status } from "./Status";
import styles from "./Versions.scss";

export class Versions extends Component {
  static propTypes = {
    versions: PropTypes.array,
    updateCertificationTarget: PropTypes.func,
    loading: PropTypes.bool,
  };

  isStatus(version, status) {
    return CERTIFICATION_STATUS[version.status].id === status.id;
  }

  setStatus = (version, status) => () => {
    const { updateCertificationTarget } = this.props;
    return updateCertificationTarget(version.id, status.id);
  };

  render() {
    const { versions, loading } = this.props;
    return (
      <div>
        {versions.map((v) => (
          <Row key={v.number} className={styles.container}>
            <Column className={styles.numberAndStatusColumn}>
              {v.number}
              <span className={styles.dash}>-</span>
              <Status status={v.status} />
            </Column>
            <RestrictedArea
              allowed={[ROLES.moderator.moderator]}
              showFallback={false}
            >
              <Column>
                <ButtonsWrapper>
                  {this.isStatus(v, CERTIFICATION_STATUS.ACCEPTED) ||
                  this.isStatus(v, CERTIFICATION_STATUS.REJECTED) ? (
                    <Button
                      size="small"
                      disabled={loading}
                      onClick={this.setStatus(v, CERTIFICATION_STATUS.PENDING)}
                    >
                      {trans.RESET()}
                    </Button>
                  ) : null}
                  {this.isStatus(v, CERTIFICATION_STATUS.ACCEPTED) ? (
                    <Button
                      size="small"
                      disabled={loading}
                      // TODO YGG-3043 Send certification email feature is blocked by currently ongoing message-center implementation
                      onClick={() => {}}
                    >
                      {trans.APP_DETAILS_CERTIFICATION__SEND_EMAIL()}
                    </Button>
                  ) : null}
                  {this.isStatus(v, CERTIFICATION_STATUS.PENDING) ? (
                    <Button
                      size="small"
                      type="green"
                      disabled={loading}
                      onClick={this.setStatus(v, CERTIFICATION_STATUS.ACCEPTED)}
                    >
                      {trans.APPROVE()}
                    </Button>
                  ) : null}
                  {this.isStatus(v, CERTIFICATION_STATUS.PENDING) ? (
                    <Button
                      size="small"
                      type="red"
                      disabled={loading}
                      onClick={this.setStatus(v, CERTIFICATION_STATUS.REJECTED)}
                    >
                      {trans.REJECT()}
                    </Button>
                  ) : null}
                </ButtonsWrapper>
              </Column>
            </RestrictedArea>
          </Row>
        ))}
      </div>
    );
  }
}
