// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import { flattenDeep, compact, cloneDeep } from "lodash";
import PropTypes from "prop-types";

import { APP_ROUTE_MY } from "pages/Applications/constants";
import { trans } from "src/translations";
import { flatten } from "utils/serialize";

import { APP_DETAILS } from "../AppList/ApplicationsTabbedTableConfig";
import { BaseForm } from "./BaseForm";
import { useSubmissionRequests } from "./useSubmissionRequests";
import { validate } from "./utils/config";
import { onSuccess, checkResponse } from "./utils/response";

export const EditAppForm = ({
  initialValues,
  config,
  reloadState,
  appData,
}) => {
  const { saveDraft, discardDraft, validateDraft, saveTest, submit } =
    useSubmissionRequests();

  const navigate = useNavigate();

  const handleSubmitForm = async (args, redirectToInvalidTab) => {
    const [values, formikHelpers] = args;

    const redirect = appData.is_test
      ? () => {
          navigate(
            { pathname: `/applications/${APP_ROUTE_MY}/${APP_DETAILS}` },
            {
              state: {
                message: trans.TEST_APP_EDIT__SUBMIT_SUCCESS(),
              },
            }
          );
        }
      : () => {
          navigate(`/applications/${APP_ROUTE_MY}/${appData.id}/status`);
        };

    const submitFunc = appData.is_test
      ? async () => saveTest(values, appData)
      : async () => submit(values, appData);

    try {
      const data = checkResponse(await submitFunc());
      onSuccess(data, values);
      setTimeout(redirect);
    } catch (err) {
      redirectToInvalidTab(err);
      window.scrollTo(0, 0);
      formikHelpers.setErrors({ _error: err._error ?? err.toString() });
    }
  };

  const checkIfDraftValid = async (formikProps) => {
    let message;
    formikProps.setStatus({ validatingDraft: true });
    try {
      const response = await validateDraft(appData.id);
      if (response?.error) {
        throw new Error();
      }
      message = { text: trans.APP_EDIT__DRAFT_SAVE_SUCCESS() };
    } catch (error) {
      message = { text: trans.APP_EDIT__DRAFT_SAVE_WARNING(), type: "warning" };
    } finally {
      formikProps.setStatus({ validatingDraft: false, message });
    }
    return false;
  };

  const handleDiscardDraft = async (formikProps) => {
    formikProps.setStatus({ discarding: true });
    try {
      const discardResult = await discardDraft(appData.id);
      if (discardResult.error) {
        throw new Error(discardResult.error);
      }
      await reloadState();
    } catch (err) {
      formikProps.setStatus({ discarding: false });
      formikProps.setErrors({ _error: err.toString() });
    }
  };

  const handleSaveDraft = async (formikProps) => {
    const copyValues = cloneDeep(formikProps.values);
    formikProps.setStatus({ saving: true });

    try {
      const response = await saveDraft(copyValues, appData);
      checkResponse(response);
      await reloadState({ runServerValidation: true });
    } catch (error) {
      const err = error._error ? error._error : error.toString();
      formikProps.setErrors({
        _error: err,
      });
    }
  };

  const hasErrors = (errors, sections) => {
    const tabFields = flattenDeep(
      sections.map(({ components }) =>
        compact(components.map(({ fields }) => fields))
      )
    );

    return Object.keys(errors).some((error) =>
      tabFields.includes(error.replace(/[\d]/, ""))
    );
  };

  const redirectToInvalidTab = (errors) => {
    config.tabs.some((tab) => {
      const invalid = hasErrors(flatten(errors), tab.sections);
      if (invalid) {
        navigate({ pathname: tab.url });
        return true;
      }
      return false;
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(...args) => handleSubmitForm(args, redirectToInvalidTab)}
      validate={(values) => validate(config)(values, appData)}
    >
      {(formik) => (
        <>
          <BaseForm
            formikProps={formik}
            config={config}
            appData={appData}
            handleSaveDraft={() => handleSaveDraft(formik)}
            checkIfDraftValid={checkIfDraftValid}
            saveDraft={saveDraft}
            handleDiscardDraft={() => handleDiscardDraft(formik)}
            redirectToInvalidTab={redirectToInvalidTab}
          />
        </>
      )}
    </Formik>
  );
};
EditAppForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  appData: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  reloadState: PropTypes.func.isRequired,
};
