// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import {
  TableRow,
  TableRowColumn,
  TableRowColumnOverflow,
} from "components/layout";
import { Link } from "components/typography";
import { SECTIONS } from "pages/Snap/shared/constants";
import styles from "pages/Snap/shared/Translations.scss";
import { classes } from "utils/classes";

import { SnapGraphics } from "../SnapGraphics";

export class SectionLiveStream extends PureComponent {
  static propTypes = {
    feed: PropTypes.object,
  };

  render() {
    const { feed } = this.props;
    return (
      <Fragment>
        <TableRow
          className={classes(styles.rowLive, styles.noBorder)}
          highlighting={false}
        >
          <TableRowColumn />
          <TableRowColumn columnClass={styles.thumbnail}>
            {SnapGraphics.renderImage(feed.thumbnail, "liveThumbnail")}
          </TableRowColumn>
          <TableRowColumnOverflow columnClass={styles.title}>
            {feed.name}
          </TableRowColumnOverflow>
          <TableRowColumnOverflow overflowText={feed.url}>
            <Link href={feed.url} target="_blank">
              {feed.url}
            </Link>
          </TableRowColumnOverflow>
          <TableRowColumn>{SECTIONS.TRANSLATIONS.streamLive}</TableRowColumn>
        </TableRow>
        <TableRow
          className={classes(styles.rowLive, styles.rowDescription)}
          highlighting={false}
        >
          <TableRowColumn colSpan={2} />
          <TableRowColumnOverflow colSpan={2}>
            {feed.description}
          </TableRowColumnOverflow>
          <TableRowColumn />
        </TableRow>
      </Fragment>
    );
  }
}
