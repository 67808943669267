// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const DEVICE_TYPE_MODEL = "model";
export const DEVICE_TYPE_FAMILY = "family";
export const DEVICE_TYPE_DISTRIBUTOR = "distributor";
export const DEVICE_TYPE_ALL = "all";
export const DEVICE_TYPE_QUERY = "query";
export const DEVICE_TYPE_NOT_ASSIGNED = "not_assigned";
export const TARGETS_FIELD_NAME = "targets";

/**
 * Target Fields works in 3 different modes:
 * 1. GRANULAR
 *      - for basic and granular targeting (by query)
 * 2. ALL_DEVICES
 *      - for basic targeting (non granular) and all devices mode
 * 3. NON_GRANUALR
 *      - for basic targeting (non granular)
 *      - will be removed once everything is transitioned to granular
 */
export const TARGET_FIELD_MODE = {
  GRANULAR: "granular",
  ALL_DEVICES: "all-devices",
  NON_GRANULAR: "non-granular",
};
