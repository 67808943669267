// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Header } from "components/typography";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import { Progressbar } from "../Progressbar";
import styles from "./UploaderAWSProgress.scss";

const MAX_INCOMPLETE_PERCENTAGE = 99;
const PROGRESS_PERCENTAGE_DONE = 100;

export const UploaderAWSProgress = ({
  progressPercentage,
  startDate,
  onCancel,
  isDone,
  isError,
}) => {
  // do not show 100% unless `isDone` is true
  const percent = isDone
    ? PROGRESS_PERCENTAGE_DONE
    : Math.min(Math.floor(progressPercentage), MAX_INCOMPLETE_PERCENTAGE);

  let statusText = trans.UPLOADER__STATUS_IN_PROGRESS();
  if (isDone) {
    statusText = trans.UPLOADER__STATUS_DONE();
  }
  if (isError) {
    statusText = trans.UPLOADER__STATUS_ERROR();
  }

  return (
    <div className={styles.uploaderProgressContainer}>
      <Header
        type="h4"
        bold={true}
        uppercase={true}
        className={styles.progressHeader}
      >
        {trans.UPLOADER__UPLOADING_FILE()}
      </Header>
      <Header
        type="h6"
        color="secondary"
        bold={false}
        uppercase={true}
        className={styles.progressHeader}
        dataTestId="uploader-aws-status"
      >
        {trans.UPLOADER__STATUS()}{" "}
        <strong className={styles.bold}>{statusText}</strong>
      </Header>
      <Progressbar
        loaded={percent}
        total={PROGRESS_PERCENTAGE_DONE}
        showPercentage={true}
        isError={isError}
        className={styles.uploaderProgressBar}
      />
      <Header
        color="secondary"
        bold={false}
        uppercase={true}
        type="h6"
        dataTestId="uploader-aws-date"
      >
        {trans.UPLOADER__STARTED_DATE()}{" "}
        <strong className={styles.bold}>{formatDateTime(startDate)}</strong>
      </Header>
      <div className={styles.progressCancelButton} onClick={onCancel}>
        <GrabsonIcon
          name="close-filled"
          size="xl"
          className={styles.progressCancelIcon}
          dataTestId="cancel-uploader-aws-button"
        />
      </div>
    </div>
  );
};

UploaderAWSProgress.propTypes = {
  progressPercentage: PropTypes.number.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  onCancel: PropTypes.func.isRequired,
  isDone: PropTypes.bool,
  isError: PropTypes.bool,
};

UploaderAWSProgress.defaultProps = {
  isDone: false,
  isError: false,
};
