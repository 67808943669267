// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { TextField } from "components/form/fields";
import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";

import styles from "./AppNameForm.scss";
import { StatusBadge } from "./StatusBadge";

const fieldName = "appName";

export const AppNameForm = ({
  appData,
  onSubmit,
  inEdit,
  onModeToggle,
  showAppState,
}) => {
  const [displayAppName, setDisplayAppName] = useState(appData?.name);

  const triggerEditBtn = (
    <Tooltip
      classNamePopup={styles.tooltipText}
      content={trans.APP_EDIT__NAME_TOOLTIP()}
      alignment="bottom"
    >
      <Button
        className={styles.triggerEditBtn}
        onClick={onModeToggle}
        dataTestId="trigger-edit-button"
      >
        <GrabsonIcon name="edit" size="lg" />
      </Button>
    </Tooltip>
  );

  const cancelEditBtn = (isSubmitting, setFieldValue) => (
    <Button
      className={styles.cancelEditBtn}
      disabled={isSubmitting}
      onClick={() => {
        onModeToggle();
        setFieldValue(fieldName, displayAppName);
      }}
      dataTestId="cancel-edit-button"
    >
      <GrabsonIcon
        name="close-filled"
        size="2xl"
        className={styles.cancelEditIcon}
      />
    </Button>
  );

  const submitBtn = (processing, disabled) => (
    <Button
      className={styles.saveBtn}
      buttonType="submit"
      processing={processing}
      disabled={disabled}
      type="green"
      dataTestId="save-app-name-button"
    >
      {trans.SAVE()}
    </Button>
  );

  const displayMode = (
    <>
      <span data-test-id="app-name">{displayAppName}</span>
      {showAppState ? <StatusBadge appData={appData} /> : null}
      {triggerEditBtn}
    </>
  );

  const editMode = ({
    values: { appName },
    isSubmitting,
    errors,
    setFieldValue,
  }) => {
    const disabled = Boolean(
      appName === displayAppName || Object.keys(errors).length
    );
    return (
      <TextField name={fieldName} className={styles.appNameInput}>
        <div className={styles.actionBtns}>
          {cancelEditBtn(isSubmitting, setFieldValue)}
          {submitBtn(isSubmitting, disabled)}
        </div>
      </TextField>
    );
  };

  const onNameChange = (values, { setSubmitting, resetForm }) => {
    const resetName = () => {
      resetForm();
      setDisplayAppName(appData.name ?? "");
    };

    setDisplayAppName(values.appName);
    onSubmit(values, { setSubmitting, resetName });
  };

  return (
    <Formik
      initialValues={{ [fieldName]: appData.name }}
      initialTouched={{ [fieldName]: true }}
      onSubmit={onNameChange}
      enableReinitialize={true}
    >
      {(helpers) => (
        <Form aria-label="form">
          <div className={styles.appNameWrapper}>
            {inEdit ? editMode(helpers) : displayMode}
          </div>
        </Form>
      )}
    </Formik>
  );
};

AppNameForm.propTypes = {
  appData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inEdit: PropTypes.bool.isRequired,
  onModeToggle: PropTypes.func.isRequired,
  showAppState: PropTypes.bool,
};
