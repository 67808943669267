// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import {
  DateScopeField,
  SelectField,
  SwitchField,
  NumberField,
  RadioGroupField,
  TextField,
  TextareaField,
  TargetDevicesField,
} from "components/form/fields";
import { Section } from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";
import { parseGqlError } from "utils/errors";
import { createValidator, createFieldValidator } from "utils/validation";

import {
  DEFAULT_DEVICES_PER_STEP,
  UPDATE_JOB_PROP_TYPES,
  FIELDS,
  TARGET_DEVICES_ITEMS_PER_PAGE,
} from "./constants";
import {
  StepsDeviceQuantitiesField,
  SoftwarePackageField,
  IsRepeatableField,
} from "./fields";
import styles from "./UpdateJobForm.scss";
import { validateDeviceQuantity } from "./utils";
import { isValidThreshold } from "./utils/validators";

const validate = (values) => {
  const errors = {};

  // Has to be done here to fix YGG-5498
  const deviceQuantityErrors = validateDeviceQuantity(
    values.deviceQuantityPerStep
  );
  if (deviceQuantityErrors.length) {
    errors.deviceQuantityPerStep = deviceQuantityErrors;
  }

  return errors;
};

export class UpdateJobFormRend extends PureComponent {
  static propTypes = {
    createUpdateJob: PropTypes.func.isRequired,
    fetchDeviceFilters: PropTypes.func.isRequired,
    showRequirementsWarning: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf(Object.values(UPDATE_JOB_PROP_TYPES)),
      description: PropTypes.string,
      softwarePackageVersion: PropTypes.shape({
        id: PropTypes.string,
        packageName: PropTypes.string,
        versionName: PropTypes.string,
      }),
      targetDevices: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      deviceQuantityPerStep: PropTypes.arrayOf(PropTypes.string).isRequired,
      threshold: PropTypes.string,
      availabilityScope: PropTypes.shape({
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
      }),
      startAutomatically: PropTypes.bool,
      isRepeatable: PropTypes.bool,
    }),
    navigate: PropTypes.func.isRequired,
  };

  onSubmitForm = async (values, { setErrors, setFieldError }) => {
    setErrors({});
    try {
      await this.props.createUpdateJob(values);

      const { navigate } = this.props;

      navigate(
        {
          pathname: "/updates/jobs/list/active",
        },
        {
          state: {
            updateJobName: values.name,
          },
        }
      );
    } catch (error) {
      const gqlError = parseGqlError(error);
      if (gqlError?.isAlreadyExists) {
        setFieldError("name", trans.UPDATES__FORM_NAME_EXISTS_ERROR());
      } else {
        setErrors({
          requestError:
            gqlError?.message ?? trans.DEFAULT_REQUEST_ERROR_MESSAGE(),
          ...gqlError?.formMessages,
        });
      }
    }
  };

  setStepQuantity = (event, setFieldValue, deviceQuantityPerStep) => {
    const newQuantity = parseInt(event.target.value, 10);
    const currentQuantity = (deviceQuantityPerStep || []).length;

    setFieldValue("numberOfSteps", newQuantity);

    Array.from({ length: newQuantity - 1 }, (i) => i).forEach((index) => {
      if (index > currentQuantity) {
        setFieldValue(
          `deviceQuantityPerStep[${index}]`,
          DEFAULT_DEVICES_PER_STEP[index]
        );
      }
    });
  };

  renderRequirementsWarning() {
    return (
      <Info type="warning" dataTestId="requirements-warning">
        {trans.UPDATES__FORM_REQUIREMENTS_WARNING_A()}{" "}
        <Link to="/devices/inventory">
          {trans.UPDATES__FORM_REQUIREMENTS_WARNING_DEVICE_FILTER_CREATED()}
        </Link>{" "}
        {trans.UPDATES__FORM_REQUIREMENTS_WARNING_B()}{" "}
        <Link to="/updates/packages">
          {trans.UPDATES__FORM_REQUIREMENTS_WARNING_SOFTWARE_PACKAGE_UPLOADED()}
        </Link>{" "}
        {trans.UPDATES__FORM_REQUIREMENTS_WARNING_C()}
      </Info>
    );
  }

  render() {
    const { fetchDeviceFilters, showRequirementsWarning, initialValues } =
      this.props;

    return (
      <Formik
        validate={validate}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={this.onSubmitForm}
      >
        {({
          isSubmitting,
          dirty,
          errors: { requestError },
          setFieldValue,
          values,
        }) => {
          const { deviceQuantityPerStep, numberOfSteps, showAdvancedOptions } =
            values;

          const today = new Date();
          today.setHours(0, 0, 0, 0);

          return (
            <Form className={styles.wordBreak}>
              {showRequirementsWarning && this.renderRequirementsWarning()}

              <Section header={trans.UPDATES__FORM_SECTION_GENERAL()}>
                <RadioGroupField {...FIELDS.TYPE} />
                <TextField {...FIELDS.NAME} />
                <TextareaField {...FIELDS.DESCRIPTION} />
              </Section>
              <Section header={trans.UPDATES__FORM_SECTION_SOFTWARE_PACKAGE()}>
                <SoftwarePackageField />
              </Section>

              <Section header={trans.UPDATES__FORM_SECTION_TARGET_DEVICES()}>
                <TargetDevicesField
                  {...FIELDS.TARGET_DEVICES}
                  fetchTargets={{
                    callback: fetchDeviceFilters,
                    itemsPerPage: TARGET_DEVICES_ITEMS_PER_PAGE,
                  }}
                  modalConfig={{
                    ...FIELDS.TARGET_DEVICES.modalConfig,
                    renderHeaders: () => {
                      return (
                        <div className={styles.targetDevicesHeaderRow}>
                          <span className={styles.targetDevicesHeaderGroupName}>
                            {trans.UPDATES__FORM_SELECT_TARGET_MODAL_GROUP_NAME()}
                          </span>
                        </div>
                      );
                    },
                  }}
                />
              </Section>

              <Section header={trans.UPDATES__FORM_SECTION_ROLLOUT_STEPS()}>
                <SelectField
                  {...FIELDS.NUMBER_OF_STEPS}
                  onChange={(event) =>
                    this.setStepQuantity(
                      event,
                      setFieldValue,
                      deviceQuantityPerStep
                    )
                  }
                  className={styles.narrowField}
                />
                <StepsDeviceQuantitiesField steps={numberOfSteps} />
                <NumberField
                  {...FIELDS.THRESHOLD}
                  validate={createFieldValidator(
                    FIELDS.THRESHOLD.name,
                    isValidThreshold
                  )}
                  className={styles.narrowField}
                />
                <DateScopeField
                  name="availabilityScope"
                  required
                  dataTestId="availability-field"
                  className={styles.dateField}
                  datePickerClassName={styles.datePicker}
                  label={trans.UPDATES__FORM_FIELD_TIME_SCOPE()}
                  tooltip={trans.UPDATES__FORM_FIELD_TIME_SCOPE_TOOLTIP()}
                  placeholder={trans.UPDATES__FORM_FIELD_TIME_SCOPE_PLACEHOLDER()}
                  validate={createValidator(
                    (value) => today > value,
                    trans.ERROR__START_DATE_PAST(),
                    "start"
                  )}
                />
              </Section>

              <Section>
                <SwitchField
                  name={"showAdvancedOptions"}
                  required={false}
                  label={trans.UPRATES__FORM_SECTION_ADVANCED_OPTIONS_SWITCH()}
                  labelClassName={
                    showAdvancedOptions
                      ? styles.switchOnLabel
                      : styles.switchOffLabel
                  }
                  dataTestId="advanced-options-toggle"
                />
                {showAdvancedOptions && <IsRepeatableField />}
              </Section>
              {requestError && <Info type="error">{requestError}</Info>}
              <ButtonsWrapper>
                <Button
                  type="normal"
                  processing={isSubmitting}
                  disabled={isSubmitting || !dirty}
                  onClick={() => setFieldValue("startAutomatically", false)}
                  dataTestId="save-button"
                  buttonType="submit"
                >
                  {trans.SAVE()}
                </Button>
                <Button
                  type="green"
                  processing={isSubmitting}
                  disabled={isSubmitting || !dirty}
                  onClick={() => setFieldValue("startAutomatically", true)}
                  dataTestId="save-and-start-button"
                  buttonType="submit"
                >
                  {trans.UPDATES__CREATE_SAVE_AND_START()}
                </Button>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
