// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { Switch } from "components/form/elements";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { formatDate } from "utils/date";

import { getCampaignStatus, STATUSES, STATUS_NAMES } from "../_utils";
import { CampaignActionMenu } from "./CampaignActionMenu";
import styles from "./CampaignRow.scss";

export class CampaignRow extends Component {
  static propTypes = {
    campaign: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      score: PropTypes.number,
      isEnabled: PropTypes.bool,
      isReadonly: PropTypes.bool,
    }),
    expanded: PropTypes.bool,
    onToggleExpand: PropTypes.func,
    onToggleCampaign: PropTypes.func,
    onRemoveCampaign: PropTypes.func,
    forAdmin: PropTypes.bool,
  };

  handleToggleExpand = () => {
    const { campaign, onToggleExpand } = this.props;
    onToggleExpand(campaign);
  };

  handleToggleCampaign = () => {
    const { campaign, onToggleCampaign } = this.props;
    onToggleCampaign(campaign, !campaign.isEnabled);
  };

  render() {
    const { campaign, expanded, onRemoveCampaign, forAdmin } = this.props;
    const { name, startDate, endDate, score, isEnabled, isReadonly } = campaign;

    const status = getCampaignStatus(startDate, endDate);

    return (
      <TableRow>
        <TableRowColumnExtended
          className={styles.name}
          fontIcon={expanded ? "arrow-up" : "arrow-down"}
          fontIconType="micro"
          fontIconSize="sm"
          onClick={this.handleToggleExpand}
          text={name}
          to={`/promotions${forAdmin ? "/admin" : ""}/campaigns/${campaign.id}`}
          rightPillText={isReadonly ? trans.PREDEFINED() : undefined}
        />
        <TableRowColumn className={styles.date}>
          {formatDate(parseISO(String(startDate)))}
        </TableRowColumn>
        <TableRowColumn className={styles.date}>
          {formatDate(parseISO(String(endDate)))}
        </TableRowColumn>
        <TableRowColumn>{score}</TableRowColumn>
        <TableRowColumn className={classes(styles[status])}>
          {STATUS_NAMES[status]}
        </TableRowColumn>
        <TableRowColumn>
          {status !== STATUSES.FINISHED && (
            <Switch
              onChange={this.handleToggleCampaign}
              name={name}
              value={name}
              checked={isEnabled}
            />
          )}
        </TableRowColumn>
        <TableRowColumn className={styles.menu}>
          <CampaignActionMenu
            campaign={campaign}
            status={status}
            onRemoveCampaign={onRemoveCampaign}
            forAdmin={forAdmin}
            isReadonly={isReadonly}
          />
        </TableRowColumn>
      </TableRow>
    );
  }
}
