// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import set from "lodash-es/set";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextField, TextareaField } from "components/form/fields";
import { Section, Subsection } from "components/layout";
import { api } from "containers/Request";
import { TargetsField } from "pages/_shared";
import { withTargetsSend } from "pages/_shared/TargetsField/decorators/withTargetsSend";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";
import { prepareErrorsForForm } from "utils/errors";
import { dataFormatter } from "utils/jsonApi";
import { pipeValidators, hasOneElement } from "utils/validation";

import { DistributionContext } from "../../_shared/Context/distributionContext";
import { AppsField, DistributorField } from "../_shared/fields";

const jsonPointerToFieldName = {
  "/data/attributes/label": "name",
  "/data/attributes/description": "description",
  "/data/relationships/applications": "applications",
  "/data/relationships/targets/data": "targets._error",
};
@withRouter
@withTargetsSend({
  distributorFieldNameInRequest: "device_owners",
  distributorDeviceType: "device_owner",
})
@connect(null, (dispatch) => ({
  createPlan: (body) =>
    dispatch(api.createDistributionPlan.action({ options: { body } })),
  updatePlan: (planId, body) =>
    dispatch(
      api.updateDistributionPlan.action({
        params: { id: planId },
        options: { body },
      })
    ),
}))
export class AdminPlanFormRend extends Component {
  static propTypes = {
    planId: PropTypes.string,
    isEdit: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    // from @withRouter
    navigate: PropTypes.func.isRequired,

    // from @connect
    createPlan: PropTypes.func.isRequired,
    updatePlan: PropTypes.func.isRequired,

    // from @withTargetsSend
    createTargetsBody: PropTypes.func,
  };

  createBody(values) {
    // Due to targets relationship, this request has a very unusual structure
    // that does not conform with jsona.
    const { createTargetsBody } = this.props;

    const data = {
      type: "plans",
      label: values.name,
      description: values.description,
      owner: values.distributor,
      applications: values.applications.map((appId) => ({
        type: "applications",
        id: appId,
      })),

      relationshipNames: ["applications"],
    };
    const body = dataFormatter.serialize({ stuff: data });

    const targetsBody = createTargetsBody("targets", values.targets);
    set(body, "data.relationships.targets", targetsBody);

    return JSON.stringify(body);
  }

  validate = (formValues) => {
    const validators = [
      hasOneElement("applications"),
      hasOneElement("targets"),
    ];
    return pipeValidators(...validators)(formValues);
  };

  handleSubmit = async (values, { setErrors }) => {
    const { isEdit, updatePlan, createPlan, planId, navigate } = this.props;

    const body = this.createBody(values);

    let request;

    if (isEdit) {
      request = await updatePlan(planId, body);
    } else {
      request = await createPlan(body);
    }

    if (request.error) {
      const submitErrors = prepareErrorsForForm(
        request.error,
        jsonPointerToFieldName
      );
      setErrors(submitErrors);
    } else {
      const message = isEdit
        ? trans.CHANGES_SAVE_SUCCESS()
        : trans.DISTR_PLAN__CREATE_SUCCESS();

      navigate(
        {
          pathname: "/distribution/admin-plans",
        },
        {
          state: { message: message },
        }
      );
    }
  };

  render() {
    const { initialValues, isEdit } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        validate={this.validate}
      >
        {({ dirty, errors, touched, isSubmitting, values }) => {
          return (
            <Form aria-label="form">
              <Section header={trans.DISTR_PLAN__SECTION_BASIC_DETAILS()}>
                <TextField
                  name="name"
                  label={trans.DISTR_PLAN__FIELD_NAME()}
                  dataTestId="name-field"
                />
                <TextareaField
                  name="description"
                  label={trans.DISTR_PLAN__FIELD_DESCRIPTION()}
                  dataTestId="description-field"
                />
                <DistributorField
                  disabled={
                    isEdit ||
                    (touched.applications && !errors.applications) ||
                    values.targets.length > 0
                  }
                />
              </Section>
              {values.distributor && (
                <DistributionContext.Provider
                  value={{ useDistributorFilter: true }}
                >
                  <Section header={trans.DISTR_PLAN__SECTION_TARGETS()}>
                    <TargetsField
                      devicesDistributorId={values.distributor}
                      forAdmin={true}
                      required={true}
                    />
                  </Section>
                  <Section
                    header={trans.DISTR_PLAN__SECTION_APPS_TO_DISTRIBUTE()}
                  >
                    <Subsection
                      header={trans.DISTR_PLAN__SECTION_APPS_TO_DISTRIBUTE_HELP()}
                    >
                      <AppsField
                        forAdmin={true}
                        devicesDistributorId={values.distributor}
                      />
                    </Subsection>
                  </Section>
                  {errors?._error && <Info type="error">{errors._error}</Info>}
                  <ButtonsWrapper>
                    <Button
                      buttonType="submit"
                      type="green"
                      processing={isSubmitting}
                      disabled={
                        isSubmitting ||
                        !dirty ||
                        Boolean(Object.entries(errors).length)
                      }
                    >
                      {isEdit ? trans.SUBMIT_CHANGES() : trans.PUBLISH()}
                    </Button>
                  </ButtonsWrapper>
                </DistributionContext.Provider>
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}
