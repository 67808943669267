// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Modal } from "components/layout";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";

@withRouter
@connect(null, (dispatch) => ({
  removeDistributionPlan: (id) =>
    dispatch(
      apiThatThrows.removeMyDistributionPlan.action({
        params: {
          id,
        },
      })
    ),
}))
export class RemoveCustomPlanModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    plan: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),

    // from @withRouter
    navigate: PropTypes.func.isRequired,

    // from @connect
    removeDistributionPlan: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    onClose: Function.propTypes,
  };

  state = {
    submitting: false,
    error: null,
  };

  handleRemove = async () => {
    const { removeDistributionPlan, plan, onClose, navigate } = this.props;

    this.setState({ submitting: true, error: null });

    try {
      await removeDistributionPlan(plan.id);
      onClose();
      // eslint-disable-next-line no-undef
      navigate(_, {
        state: { message: trans.DISTR_CUSTOM_PLANS__REMOVE_SUCCESS() },
      });
    } catch (err) {
      this.setState({ error: err.message || err });
    } finally {
      this.setState({ submitting: false });
    }
  };

  render() {
    const { isOpen, onClose, plan } = this.props;

    return (
      <Modal
        title={trans.DISTR_CUSTOM_PLANS__REMOVE_MODAL_TITLE()}
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.NO()}
            </Button>
            <Button
              type="green"
              processing={this.state.submitting}
              onClick={this.handleRemove}
              disabled={this.state.submitting}
            >
              {trans.YES()}
            </Button>
          </ButtonsWrapper>
        }
      >
        {trans.DISTR_CUSTOM_PLANS__REMOVE_MODAL_TEXT({
          name: plan ? plan.name : "",
        })}
        {this.state.error && <Info type="error">{this.state.error}</Info>}
      </Modal>
    );
  }
}
