// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { urlRegex } from "utils/validation/validators";

export const getFinalFileToUploadUrls = (
  externalFileUrlRaw,
  uploadedFileUrlRaw,
  uploadedFileNameRaw
) => {
  const uploadedFileUrl = (uploadedFileUrlRaw || "").trim();
  const externalFileUrl = (externalFileUrlRaw || "").trim();
  const uploadedFileName = (uploadedFileNameRaw || "").trim();

  if (uploadedFileUrl.length > 0) {
    return {
      isExternalUrl: false,
      url: uploadedFileUrl,
      name: uploadedFileName,
    };
  }

  // name should be the provided url - not a bug!
  return {
    isExternalUrl: true,
    url: externalFileUrl,
    name: externalFileUrl,
  };
};

export const validateExternalUploadUrl = (url) => {
  return url.length > 0 && urlRegex.test(url);
};

const HTTP_PROTOCOL = "http://";

export const isFileUploadUsingHttp = (fileToUpload) =>
  fileToUpload.isExternalUrl &&
  fileToUpload.url.toLowerCase().startsWith(HTTP_PROTOCOL);
