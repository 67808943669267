// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./rangeinput.scss";

export const RangeInput = ({
  required,
  value,
  onChange,
  readOnly,
  disabled,
  className,
  min,
  max,
  step,
  name,
  dataTestId = "range",
}) => (
  <div className={classes(styles.scoreComponent, className)}>
    <fieldset>
      <input
        required={required}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        name={name}
        data-test-id={`${dataTestId}-input`}
      />
      <output className={styles.scoreValue}>{value}</output>
    </fieldset>
  </div>
);

RangeInput.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  /** Minimum value. Inclusive */
  min: PropTypes.number,
  /** Maximum value. Inclusive */
  max: PropTypes.number,
  step: PropTypes.number,
  dataTestId: PropTypes.string,
};
