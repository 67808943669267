// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Header } from "components/typography";
import { classes } from "utils/classes";

import styles from "./section.scss";

/**
 * Generic component that groups similar content. This component is very often
 * used in e.g. forms.
 */
export class Section extends Component {
  static propTypes = {
    children: PropTypes.node,
    header: PropTypes.node,
    className: PropTypes.string,
    tooltip: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "section",
  };

  render() {
    const { children, header, className, tooltip, dataTestId } = this.props;

    return (
      <div
        className={classes(styles.section, className)}
        data-test-id={dataTestId}
      >
        {header ? (
          <Header
            className={styles.header}
            uppercase={true}
            bold={true}
            color="secondary"
            tooltip={tooltip}
          >
            {header}
          </Header>
        ) : null}
        {children}
      </div>
    );
  }
}

export default Section;
