// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import queryString from "query-string";

import { SearchBar } from "components/elements";
import { PageHeader, PageContent, Section } from "components/layout";
import { RestrictedArea } from "containers/Permissions";
import { ALL_PROVIDERS } from "containers/Permissions/groups";
import { apiThatThrows } from "containers/Request";
import { withSearch } from "utils/decorators";

import { SECTIONS } from "../shared/constants";
import SnapApplicationTable from "./components/SnapApplicationTable";
import styles from "./SnapList.scss";

@withSearch
@connect(null, (dispatch) => ({
  getSnapApplicationsPaginated: (queryParams) =>
    dispatch(
      apiThatThrows.getSnapApplicationsPaginated.action({
        queryParams,
      })
    ),
}))
export class SnapList extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    // from @connect
    getSnapApplicationsPaginated: PropTypes.func.isRequired,
    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
    //from @ExtendedRouter
    navigate: PropTypes.func.isRequired,
  };

  state = {
    applications: [],
    count: 0,
    error: undefined,
    loading: true,
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.updateData();
    }
  }

  async updateData() {
    const {
      location: { search },
      getSnapApplicationsPaginated,
    } = this.props;

    try {
      this.setState({ loading: true });

      const queryParams = queryString.parse(search);
      const result = await getSnapApplicationsPaginated(queryParams);
      this.setState({
        applications: result.results,
        count: result.count,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }

  gotoApplicationsTab = () => {
    const { navigate } = this.props;
    navigate("/applications");
  };

  renderContent() {
    const { changeSearch, searchValue } = this.props;
    const { loading, error, applications, count } = this.state;

    return (
      <div>
        <Section>
          <SearchBar onSearch={changeSearch} value={searchValue} />
        </Section>
        <Section>
          <SnapApplicationTable
            applicationList={applications}
            applicationsNumber={count}
            loading={loading}
            error={error}
          />
        </Section>
        <Section className={styles.publicationStateInfo}>
          To see the publication state of your applications, view the{" "}
          <span className={styles.link} onClick={this.gotoApplicationsTab}>
            My Apps list
          </span>
        </Section>
      </div>
    );
  }

  render() {
    return (
      <div>
        <PageHeader>{SECTIONS.HEADER}</PageHeader>
        <PageContent>
          <RestrictedArea allowed={ALL_PROVIDERS}>
            {this.renderContent()}
          </RestrictedArea>
        </PageContent>
      </div>
    );
  }
}
