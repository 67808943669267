// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { ColoredText } from "components/elements";

import { CERTIFICATION_STATUS } from "./constants";

export class Status extends Component {
  static propTypes = {
    status: PropTypes.oneOf(
      Object.values(CERTIFICATION_STATUS).map(({ id }) => id)
    ),
  };

  render() {
    const { status } = this.props;
    const { color, name } = get(
      CERTIFICATION_STATUS,
      status,
      CERTIFICATION_STATUS.PENDING
    );
    return <ColoredText color={color}>{name}</ColoredText>;
  }
}
