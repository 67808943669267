// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { PageHeader, PageContent, Section } from "components/layout";
import { RestrictedArea } from "src/containers/Permissions";
import { ALL_ORGANIZATION_MEMBERS } from "src/containers/Permissions/groups";
import { trans } from "src/translations";

import { DevicePairingListData } from "./DevicePairingListData";
import styles from "./DevicePairingPage.scss";

export class DevicePairingPage extends Component {
  render() {
    return (
      <div>
        <PageHeader>{trans.DEVICE_PAIRING__PAGE_HEADER()}</PageHeader>
        <PageContent>
          <RestrictedArea allowed={ALL_ORGANIZATION_MEMBERS}>
            <div className={styles.devicePairing}>
              <Section header={trans.DEVICE_PAIRING__SECTION_HEADER()}>
                <p>
                  {trans.DEVICE_PAIRING__ID_FOR_DEVELOPERS_TEXT()}{" "}
                  <strong>
                    {trans.DEVICE_PAIRING__ID_FOR_DEVELOPERS_CODE()}
                  </strong>{" "}
                  {trans.DEVICE_PAIRING__ID_FOR_DEVELOPERS_64_CHAR()}{" "}
                  <strong>
                    {trans.DEVICE_PAIRING__ID_FOR_DEVELOPERS_DEVICE_ID()}
                  </strong>
                  .
                </p>
                <p>{trans.DEVICE_PAIRING__DEVELOPMENT_CATEGORY()}</p>
                <p className={styles.midHeader}>
                  {trans.DEVICE_PAIRING__REQUIRES_CONFIRMATION()}
                </p>

                <p>{trans.DEVICE_PAIRING__ID_PLACEMENT_IN_STORE()}</p>
                <p>{trans.DEVICE_PAIRING__PROMPT_INFO()}</p>
                <p>{trans.DEVICE_PAIRING__DONT_LEAVE_ABOUT_PAGE()}</p>
                <p className={styles.midHeader}>
                  {trans.DEVICE_PAIRING__NO_CONFIRMATION_REQUIRED()}
                </p>
                <p>{trans.DEVICE_PAIRING__64_CHAR_PLACEMENT()}</p>
              </Section>
            </div>
            <DevicePairingListData />
          </RestrictedArea>
        </PageContent>
      </div>
    );
  }
}
