// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Label, ValidationBadge } from "components/form";
import { getAriaProps, getErrorId } from "components/form/fields";
import { popupAlignmentType } from "components/popups/Popup/types";
import { classes } from "utils/classes";
import { withFieldGuard } from "utils/fieldGuard";

import styles from "./textarea.scss";

@withFieldGuard
class Textarea extends Component {
  static propTypes = {
    /** This is mostly for scenario where we need to link custom label to textarea element */
    id: PropTypes.string,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    /** Label string displayed to the user */
    label: PropTypes.node,
    required: PropTypes.bool,
    /** Used as `<textarea>` name attribute */
    name: PropTypes.string.isRequired,
    /** Force hide validation errors */
    disabledValidation: PropTypes.bool,
    /**
     * Current value. Use `\n` to indicate line breaks, but You will probably
     * not have to do that manually.
     */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    /**
     * Change callback.
     *
     * Type: (e: Event) => void
     */
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    tooltip: PropTypes.string,
    /** Validation error. Shown when `touched` is true */
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    tooltipAlignment: popupAlignmentType,
    look: PropTypes.oneOf(["wrapped", "simple"]),
  };

  static defaultProps = {
    dataTestId: "textarea",
    look: "wrapped",
  };

  render() {
    const {
      id,
      name,
      value,
      onChange,
      onFocus,
      onBlur,
      label,
      look,
      required,
      className,
      dataTestId,
      tooltip,
      error,
      touched,
      readOnly,
      disabled,
      tooltipAlignment,
    } = this.props;

    return (
      <div
        data-test-id={dataTestId}
        className={classes(styles[look], className)}
      >
        <Label
          text={label}
          required={required}
          tooltip={tooltip}
          tooltipAlignment={tooltipAlignment}
        >
          <textarea
            className={error && touched ? styles.error : styles.normal}
            id={id}
            name={name}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            data-test-id={`${dataTestId}-input`}
            {...getAriaProps({ name, error, touched })}
          />
          {this.props.disabledValidation ? (
            ""
          ) : (
            <ValidationBadge
              error={error}
              touched={touched}
              errorId={getErrorId(name)}
            />
          )}
        </Label>
      </div>
    );
  }
}

// styleguidist does not like exporting classes that use decorators
export { Textarea };
