// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";
import queryString from "query-string";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { apiThatThrows } from "containers/Request";
import { INBOXES } from "pages/Applications/AppDetails/Messages/constants";
import {
  APP_ROUTE_MY,
  APP_ROUTE_MODERATION,
  APP_ROUTE_DISTRIBUTION,
} from "pages/Applications/constants";
import { formatDate } from "utils/date";
import { withRouter } from "utils/decorators/withRouter";
import { getAuthorName } from "utils/user";

import { NOTIFICATIONS_PER_PAGE } from "./constants";
import { Notifications } from "./Notifications";

@withRouter
@connect(null, (dispatch) => ({
  getDashboardMessagesPaginated: async (location) => {
    const searchObj = queryString.parse(location.search);

    return await dispatch(
      apiThatThrows.getDashboardMessagesPaginated.action({
        queryParams: {
          include: ["author", "author_organization"],
          page: searchObj.page || 1,
          limit: NOTIFICATIONS_PER_PAGE,
        },
      })
    );
  },
}))
export class NotificationsData extends Component {
  static propTypes = {
    getDashboardMessagesPaginated: PropTypes.func,

    // from @withRouter
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.refreshNotificationList();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location !== location) {
      this.refreshNotificationList();
    }
  }

  state = {
    notifications: [],
    notificationsCount: 0,
    loading: true,
    error: undefined,
  };

  getNotificationUrl(notification) {
    const { application_public_id: appId, inbox } = notification;

    let routeType = APP_ROUTE_MY;
    if (inbox === INBOXES.moderation.key) {
      routeType = APP_ROUTE_MODERATION;
    } else if (inbox === INBOXES.distribution.key) {
      routeType = APP_ROUTE_DISTRIBUTION;
    }
    return `/applications/${routeType}/${appId}/details/messages`;
  }

  createNotification = (notification) => {
    const { id, created_at, application_public_id, contents } = notification;
    return {
      id: id,
      type: "unread", // todo: change when YGG-1776 ready
      date: formatDate(new Date(created_at)),
      appId: application_public_id,
      author: {
        name: getAuthorName(notification),
        avatar: get(notification, "author_organization.logo"),
      },
      text: contents,
      url: this.getNotificationUrl(notification),
    };
  };

  refreshNotificationList = async () => {
    const { location, getDashboardMessagesPaginated } = this.props;

    try {
      this.setState({ loading: true, error: undefined });

      const { results, meta } = await getDashboardMessagesPaginated(location);

      this.setState({
        notifications: results.map((n) => this.createNotification(n)),
        notificationsCount: meta.count,
      });
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { notifications, notificationsCount, loading, error } = this.state;
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message || error}</Info>;
    }

    return (
      <Notifications
        notifications={notifications}
        notificationsCount={notificationsCount}
      />
    );
  }
}
