// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Navigate } from "react-router-dom";

import get from "lodash-es/get";
import intersection from "lodash-es/intersection";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { SubTab, SubTabs } from "components/navigation";
import { withActiveOrganization } from "containers/Auth/decorators";
import { ROLES } from "containers/Permissions";
import {
  ALL_MODERATORS,
  ALL_PROVIDERS,
  allowInOrganization,
} from "containers/Permissions/groups";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import { addQueryParamsToLocation } from "utils/url";

import {
  QA_STATUS_ACCEPTED,
  QA_STATUS_PENDING,
  QA_STATUS_REJECTED,
} from "../../constants";
import { VERSION_TABS, VERSION_URL_PARAM_NAME } from "../constants";
import { getVersionSubTabFromUrl } from "./getVersionSubTabFromUrl";
import styles from "./versionSubTabs.scss";

@withRouter
@withActiveOrganization
export class VersionSubTabs extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool,
    latestVersion: PropTypes.object,
    activeVersion: PropTypes.object,
    application: PropTypes.object.isRequired,
    children: PropTypes.func,

    // from @withRouter
    location: PropTypes.object,

    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,
  };

  static defaultProps = {
    loading: false,
    error: null,
  };

  isAppOwner() {
    const { application, activeOrganization } = this.props;
    return allowInOrganization(
      application.owner_public_id,
      ALL_PROVIDERS
    )({
      activeOrganization,
    });
  }

  canViewActiveVersion() {
    const { activeOrganization } = this.props;

    if (this.isAppOwner()) {
      return true;
    }

    return (
      intersection(activeOrganization.roles, [
        ...ALL_MODERATORS,
        ROLES.distributor.readOnlyDistributionPlanner,
        ROLES.distributor.distributionPlanner,
        ROLES.administrator.deviceAdmin,
        ROLES.administrator.distributionAdmin,
      ]).length > 0
    );
  }

  canViewLatestVersion() {
    const { activeOrganization } = this.props;

    if (this.isAppOwner()) {
      return true;
    }

    return intersection(activeOrganization.roles, ALL_MODERATORS).length > 0;
  }

  generateSubTabLocation(subTabId) {
    return addQueryParamsToLocation(location, {
      [VERSION_URL_PARAM_NAME]: subTabId,
    });
  }

  isActiveAndLatestSameVersion() {
    const { activeVersion, latestVersion } = this.props;
    return (
      activeVersion && latestVersion && latestVersion.id === activeVersion.id
    );
  }

  activeVersionHasApprovedMetadata() {
    const { activeVersion } = this.props;
    const moderationStatus = get(activeVersion, "qa_status");
    // don't use activeVersion from ModerationResult object due to permissions
    return moderationStatus === QA_STATUS_ACCEPTED;
  }

  createTabsList() {
    const { latestVersion } = this.props;
    const tabs = [];

    const showActiveTab =
      this.canViewActiveVersion() && this.activeVersionHasApprovedMetadata();

    const showLatestTab =
      !this.isActiveAndLatestSameVersion() || !showActiveTab;
    if (this.canViewLatestVersion() && showLatestTab) {
      // show 'Latest' tab when one of the following happens:
      // 1) metadata was approved and there are some yet to be approved changes
      // 2) metadata was approved and changes were rejected (as 'Rejected' tab)
      // 3) metadata was NOT approved
      // 4) application is marked as 'Test application' and there is no way to approve metadata
      // Don't use latestVersionModerationResult due to permissions
      const qaStatus = get(latestVersion, "qa_status");

      if (qaStatus === QA_STATUS_PENDING) {
        tabs.push({
          to: this.generateSubTabLocation(VERSION_TABS.latest),
          key: VERSION_TABS.latest,
          text: trans.APP_DETAILS_VERSION__LATEST(),
        });
      } else if (qaStatus === QA_STATUS_REJECTED) {
        tabs.push({
          to: this.generateSubTabLocation(VERSION_TABS.latest),
          key: VERSION_TABS.latest,
          text: trans.APP_DETAILS_VERSION__REJECTED(),
        });
      }
    }

    if (showActiveTab) {
      tabs.unshift({
        to: this.generateSubTabLocation(VERSION_TABS.active),
        key: VERSION_TABS.active,
        text: trans.APP_DETAILS_VERSION__ACTIVE(),
      });
    }

    return tabs;
  }

  isTabShown = (tabId) => {
    const tabs = this.createTabsList();
    return tabs.findIndex((t) => t.key === tabId) !== -1;
  };

  renderNoTabToShowError() {
    return (
      <Info type="error">
        {trans.APP_DETAILS_VERSION__NO_TAB_TO_SHOW_ERROR()}
      </Info>
    );
  }

  renderTabsList = () => {
    const subTabId = getVersionSubTabFromUrl(this.props.location);
    const tabsList = this.createTabsList();

    return (
      <SubTabs className={styles.tabs}>
        {tabsList.map((tabProps) => (
          <SubTab
            {...tabProps}
            key={tabProps.key}
            active={subTabId === tabProps.key}
          />
        ))}
      </SubTabs>
    );
  };

  render() {
    const { loading, error, latestVersion, activeVersion, children, location } =
      this.props;

    if (loading) {
      return <Loader message={`${trans.LOADING()}...`} />;
    }
    if (error) {
      return <Info type="error">{error}</Info>;
    }

    const subTabId = getVersionSubTabFromUrl(location);
    const tabsList = this.createTabsList();

    if (!this.isTabShown(subTabId)) {
      if (tabsList.length > 0) {
        return <Navigate to={tabsList[0].to} />;
      } else {
        return this.renderNoTabToShowError();
      }
    }

    return children({
      subTabId,
      renderTabsList: this.renderTabsList,
      isTabShown: this.isTabShown,
      appVersion:
        subTabId === VERSION_TABS.latest ? latestVersion : activeVersion,
    });
  }
}
