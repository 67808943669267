// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { Formik, Form } from "formik";
import cloneDeep from "lodash-es/cloneDeep";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { RadioGroupField, TextField } from "components/form/fields";
import { Section } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { ORGANIZATION_TYPES, restrictedArea } from "containers/Permissions";
import { ALL_PROVIDERS } from "containers/Permissions/groups";
import { api } from "containers/Request";
import { ResetFormOnOrgChange } from "pages/_shared";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";

import {
  APP_INTRO_FORM,
  APP_INTRO_FORM_STATE,
  APP_ROUTE_MY,
  APP_TYPES,
} from "../constants";
import styles from "./CreateApp.scss";
import { IsTestSection } from "./IsTestSection";

const jsonPointerToFieldName = {
  "/data/attributes/name": "name",
  "/data/attributes/type": "type",
};

@restrictedArea(() => ({
  allowed: ALL_PROVIDERS,
}))
@withActiveOrganization
@connect(null, (dispatch) => ({
  createApplication: (body) =>
    dispatch(api.createApplication.action({ options: { body } })),
}))
export class IntroductionTab extends Component {
  static propTypes = {
    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,

    // from @connect
    createApplication: PropTypes.func.isRequired,
    //form @ExtendedRoute
    navigate: PropTypes.func.isRequired,
  };

  getAppTypesFieldProps = () => {
    const { activeOrganization } = this.props;
    const fieldProps = cloneDeep(APP_INTRO_FORM.TYPES);
    fieldProps.values = fieldProps.values.filter((type) =>
      type.value !== APP_TYPES.NATIVE
        ? true
        : activeOrganization.types.includes(ORGANIZATION_TYPES.administrator)
    );
    return fieldProps;
  };

  submit = async (values, { setErrors }) => {
    const { navigate, createApplication } = this.props;
    const { name, is_test, type } = values;

    const body = JSON.stringify({
      data: {
        type: "ApplicationModel",
        attributes: {
          name: name.trim(),
          type,
          is_test,
        },
      },
    });

    const { result, error } = await createApplication(body);

    if (error) {
      const submitErrors = prepareErrorsForForm(error, jsonPointerToFieldName);
      throw new setErrors({
        ...submitErrors,
        _submitFailed: true,
      });
    } else {
      navigate({
        pathname: `/applications/${APP_ROUTE_MY}/${result.results.id}/edit`,
      });
    }
  };

  getAppNameFieldProps = () => {
    const { activeOrganization } = this.props;
    const isAdminOrg = activeOrganization.types.includes(
      ORGANIZATION_TYPES.administrator
    );

    return {
      ...APP_INTRO_FORM.NAME,
      label: isAdminOrg
        ? APP_INTRO_FORM.NAME.employeeLabel
        : APP_INTRO_FORM.NAME.label,
    };
  };

  render() {
    return (
      <Formik initialValues={APP_INTRO_FORM_STATE} onSubmit={this.submit}>
        {({ dirty, errors, isSubmitting, values }) => (
          <Form aria-label="form">
            <Section header={trans.WELCOME()} className={styles.welcome}>
              <p>
                {trans.APP_SUBMIT__THANK_YOU_FOR_SUBMITTING()}{" "}
                <NavLink to="/guidelines">
                  {trans.APP_SUBMIT__SUBMIT_NOTES_LINK()}
                </NavLink>
              </p>
            </Section>
            <Section header={trans.LETS_BEGIN()}>
              <TextField {...this.getAppNameFieldProps()} />
              <RadioGroupField
                {...this.getAppTypesFieldProps()}
                disabled={values.is_test}
              />
            </Section>
            <IsTestSection />
            <ButtonsWrapper position="center">
              <Button
                buttonType="submit"
                disabled={!dirty || isSubmitting}
                type="wizardNormal"
                size="large"
                dataTestId="submit-button"
              >
                {trans.START()}
              </Button>
            </ButtonsWrapper>
            {errors?._error && <Info type="error">{errors._error}</Info>}
            <ResetFormOnOrgChange />
          </Form>
        )}
      </Formik>
    );
  }
}
