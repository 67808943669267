// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext } from "react";

import { Loader } from "components/elements";
import { Section } from "components/layout";
import { SubTab, SubTabs, TabContent } from "components/navigation";
import { RestrictedArea, ROLES } from "containers/Permissions";
import { ManifestDataContext } from "pages/AppManifests/_shared";
import { getTabsConfig } from "pages/AppManifests/_shared/constants";
import {
  createTabPath,
  getActiveTabId,
} from "pages/AppManifests/_shared/utils";
import { trans } from "src/translations";

import { ManifestEditTab } from "./ManifestEditTab";

export const ManifestEdit = () => {
  const { appType, loading } = useContext(ManifestDataContext);
  const activeTabId = getActiveTabId(appType);

  return (
    <RestrictedArea
      allowed={[ROLES.moderator.appIntegrator]}
      showFallback={false}
    >
      <Section header={trans.APP_DETAILS_MANIFEST__EDIT_HEADER()}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <SubTabs>
              {getTabsConfig(appType).map(({ id, text }) => (
                <SubTab
                  key={id}
                  active={id === activeTabId}
                  text={text()}
                  to={createTabPath(id)}
                  look="gray2"
                />
              ))}
            </SubTabs>
            <TabContent>
              <ManifestEditTab tabId={activeTabId} />
            </TabContent>
          </>
        )}
      </Section>
    </RestrictedArea>
  );
};
