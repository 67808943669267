import * as React from "react";

import { ParsedQuery } from "query-string";

import { withQueryParams, WithQueryParamsProps } from "./withQueryParams";

export const ASC = "ASC";
export const DESC = "DESC";

type Order = typeof ASC | typeof DESC;

export interface WithSortingProps {
  sortBy: string;
  sortOrder: Order;
}

/**
 * Usage:
 * @withSorting()
 * @withSorting({ defaultSortBy: 'name', allowedSortByValues: ['name', 'date'] })
 */
export const withSorting =
  ({
    defaultSortBy = "",
    allowedSortByValues = [],
    defaultSortOrder = ASC,
  }: {
    defaultSortBy: string;
    allowedSortByValues: string[];
    defaultSortOrder: Order;
  }) =>
  <TProps extends WithSortingProps = WithSortingProps>(
    ComposedComponent: React.ComponentType<TProps>
  ) => {
    class WithSorting extends React.Component<TProps & WithQueryParamsProps> {
      getSortOrder = (queryParams: ParsedQuery) => {
        const { order } = queryParams;

        return order === ASC || order === DESC ? order : defaultSortOrder;
      };

      getSortBy = (queryParams: ParsedQuery): string => {
        const { sort_by: sortBy } = queryParams;
        if (!sortBy) {
          return defaultSortBy;
        }

        if (Array.isArray(sortBy)) {
          return defaultSortBy;
        }

        if (
          !Array.isArray(allowedSortByValues) ||
          allowedSortByValues.length === 0
        ) {
          return sortBy;
        }

        if (allowedSortByValues.includes(sortBy)) {
          return sortBy;
        }

        return defaultSortBy;
      };

      render() {
        return (
          <ComposedComponent
            {...this.props}
            sortBy={this.getSortBy(this.props.queryParams)}
            sortOrder={this.getSortOrder(this.props.queryParams)}
          />
        );
      }
    }

    return withQueryParams(WithSorting);
  };
