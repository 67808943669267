// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Checkbox } from "components/form/elements";
import { GrabsonIcon } from "components/icons";
import { Modal } from "components/layout";
import { trans } from "src/translations";

import { UPLOAD_STATUS } from "../constants";
import { AwsUploaderChildrenProps, FormatPropType } from "../propTypes";
import { TextsPropTypes } from "./propTypes";
import { UploaderAWSModalContent } from "./UploaderAWSModalContent";
import styles from "./UploaderAWSModalContent.scss";
import {
  getFinalFileToUploadUrls,
  validateExternalUploadUrl,
  isFileUploadUsingHttp,
} from "./utils";

const DEFAULT_IDLE_STATE = {
  // instead of normal content, show warning about 'http' url protocol
  isHttpWarningShown: false,
  isHttpCheckboxChecked: false,
  // user tried to submit, but the external url was not valid
  hasSubmittedInvalidExternalUrl: false,
};

const STATE_SHOW_HTTP_WARNING = {
  isHttpWarningShown: true,
  isHttpCheckboxChecked: false,
};

export class UploaderAWSModalRend extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    formats: FormatPropType,
    texts: TextsPropTypes,
    uploaderStatus: AwsUploaderChildrenProps.isRequired,
    submitting: PropTypes.bool.isRequired,
    hasSubmitRequestError: PropTypes.bool.isRequired,
    onResetSubmitError: PropTypes.func.isRequired,
    onExternalUrlInputChange: PropTypes.func.isRequired,
    externalFileUrl: PropTypes.string.isRequired,
    uploadedFileUrl: PropTypes.string.isRequired,
    uploadedFileName: PropTypes.string.isRequired,
  };

  state = { ...DEFAULT_IDLE_STATE };

  componentDidUpdate(prevProps) {
    const { isOpen, uploaderStatus } = this.props;

    if (prevProps.isOpen !== isOpen) {
      this.setState({ ...DEFAULT_IDLE_STATE });
      // reset uploader state to clear currently selected file/error status etc.
      uploaderStatus.onCancel();
    }
  }

  getUploadUrls() {
    return getFinalFileToUploadUrls(
      this.props.externalFileUrl,
      this.props.uploadedFileUrl,
      this.props.uploadedFileName
    );
  }

  handleCancel = () => {
    if (this.state.isHttpWarningShown) {
      this.setState({ isHttpWarningShown: false });
      this.props.onResetSubmitError();
    } else {
      this.props.onClose();
    }
  };

  handleHttpCheckbox = () => {
    this.setState((state) => ({
      isHttpCheckboxChecked: !state.isHttpCheckboxChecked,
    }));
  };

  handleUpload = () => {
    const { onUpload } = this.props;
    const uploadFileUrls = this.getUploadUrls();

    if (uploadFileUrls.isExternalUrl) {
      // Use external file (the link from text input)
      this.handleUploadExternalUrl(uploadFileUrls);
    } else {
      // Use AWS file
      onUpload(uploadFileUrls);
    }
  };

  handleUploadExternalUrl(uploadFileUrls) {
    const { onUpload } = this.props;

    if (!validateExternalUploadUrl(uploadFileUrls.url)) {
      // url is not even correct, nothing to do here
      this.setState({ hasSubmittedInvalidExternalUrl: true });
    } else if (isFileUploadUsingHttp(uploadFileUrls)) {
      // if file is HTTP either show the warning or use the file if user is really sure
      this.handleUploadExternalHttpUrl(uploadFileUrls);
    } else {
      // we are using valid `https://` external url
      onUpload(uploadFileUrls);
    }
  }

  handleUploadExternalHttpUrl(uploadFileUrls) {
    const { onUpload } = this.props;
    const { isHttpWarningShown, isHttpCheckboxChecked } = this.state;

    if (!isHttpWarningShown) {
      this.setState(STATE_SHOW_HTTP_WARNING);
    } else if (isHttpCheckboxChecked) {
      onUpload(uploadFileUrls);
    }
  }

  renderModalTitle() {
    if (this.state.isHttpWarningShown) {
      return (
        <Fragment>
          <GrabsonIcon
            className={styles.titleIcon}
            name="alert-circle"
            size="xl"
          />{" "}
          {trans.WARNING()}
        </Fragment>
      );
    }

    return this.props.texts.title;
  }

  renderHttpWarning() {
    const { texts } = this.props;
    const { isHttpCheckboxChecked } = this.state;

    return (
      <Fragment>
        <p
          className={styles.httpWarnText}
          data-test-id="http-warning-description"
        >
          {texts.httpWarningDescription}
        </p>
        <Checkbox
          onChange={this.handleHttpCheckbox}
          value={isHttpCheckboxChecked}
          checked={isHttpCheckboxChecked}
          dataTestId="http-warning-checkbox"
        >
          {texts.httpWarningCheckbox}
        </Checkbox>
      </Fragment>
    );
  }

  render() {
    const {
      isOpen,
      uploaderStatus,
      onExternalUrlInputChange,
      hasSubmitRequestError,
      formats,
      texts,
      submitting,
      externalFileUrl,
    } = this.props;
    const {
      isHttpWarningShown,
      isHttpCheckboxChecked,
      hasSubmittedInvalidExternalUrl,
    } = this.state;

    const isUploadingToAWS = uploaderStatus === UPLOAD_STATUS.progress;
    const needsHttpConfirm = isHttpWarningShown && !isHttpCheckboxChecked;
    const uploadFileUrls = this.getUploadUrls();
    const pristine = uploadFileUrls.url.length < 1;

    return (
      <Modal
        isOpen={isOpen}
        onClose={this.handleCancel}
        title={this.renderModalTitle()}
        dataTestId="uploader-aws-modal"
        size="medium"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={this.handleCancel}
              dataTestId="close-button"
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.handleUpload}
              dataTestId="continue-button"
              disabled={
                pristine || submitting || isUploadingToAWS || needsHttpConfirm
              }
              processing={submitting}
            >
              {trans.UPLOAD()}
            </Button>
          </ButtonsWrapper>
        }
      >
        {isHttpWarningShown ? (
          this.renderHttpWarning()
        ) : (
          <UploaderAWSModalContent
            uploaderStatus={uploaderStatus}
            onExternalUrlInputChange={onExternalUrlInputChange}
            formats={formats}
            texts={texts}
            externalFileUrl={externalFileUrl}
            hasSubmittedInvalidExternalUrl={hasSubmittedInvalidExternalUrl}
          />
        )}

        {hasSubmitRequestError && (
          <Info type="error" className={styles.errorMessage}>
            {trans.UPLOADER_AWS_MODAL_ERROR()}
          </Info>
        )}
      </Modal>
    );
  }
}
