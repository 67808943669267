// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./ColoredText.scss";

export const ColoredText = ({ color, children, className, dataTestId }) => (
  <span className={classes(styles[color], className)} data-test-id={dataTestId}>
    {children}
  </span>
);

ColoredText.propTypes = {
  color: PropTypes.oneOf(["red", "green", "yellow", "grey", "black"]),
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default ColoredText;
