// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { PageHeader, PageContent } from "components/layout";
import { Tabs, Tab, TabContent } from "components/navigation";
import { trans } from "src/translations";

import { IntroductionTab } from "./IntroductionTab";

export class CreateApp extends Component {
  render() {
    return (
      <div>
        <PageHeader>{trans.APP_SUBMIT__PAGE_HEADER()}</PageHeader>
        <PageContent>
          <Tabs>
            <Tab to="/applications/create" text={trans.INTRODUCTION()} />
          </Tabs>
          <TabContent>
            <IntroductionTab />
          </TabContent>
        </PageContent>
      </div>
    );
  }
}
