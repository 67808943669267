// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { innerRefType } from "utils/types";

import styles from "./PromotionItem.scss";

const DIRECTIONS = {
  UP: -1,
  DOWN: 1,
};

export const getIncludeAppsTitles = (includeApps, appDetails) =>
  includeApps
    .map((id) => (appDetails[id] ? appDetails[id].name : id))
    .join(", ");

export const PromotionItem = ({
  promotion,
  appDetails,
  innerRef,
  index,
  onRemove,
  isDragging,
  onMove,
  forAdmin,
  isReadonly,
  ...draggingProps
}) => {
  return (
    <TableRow
      highlighting={false}
      dragProps={draggingProps}
      innerRef={innerRef}
      className={classes(styles.row, { [styles.dragging]: isDragging })}
    >
      <TableRowColumn columnClass={styles.dragColumn}>
        {!isReadonly && (
          <GrabsonIcon name="dragdrop2" className={styles.dragIcon} />
        )}
      </TableRowColumn>
      <TableRowColumnExtended
        to={`/promotions${forAdmin ? "/admin" : ""}/edit/${promotion.id}`}
        urlTarget="blank"
        text={promotion.name}
        columnClass={styles.nameColumn}
      />
      <TableRowColumn columnClass={styles.includeAppsColumn}>
        {getIncludeAppsTitles(promotion.includeApps, appDetails)}
      </TableRowColumn>
      <TableRowColumn columnClass={styles.arrows}>
        {!isReadonly && (
          <Fragment>
            <GrabsonIcon
              className={styles.moveIcon}
              dataTestId="move-up"
              font="micro"
              name="arrow-up"
              onClick={() => onMove(index, DIRECTIONS.UP)}
              size="sm"
            />
            <GrabsonIcon
              className={styles.moveIcon}
              dataTestId="move-down"
              font="micro"
              name="arrow-down"
              onClick={() => onMove(index, DIRECTIONS.DOWN)}
              size="sm"
            />
          </Fragment>
        )}
      </TableRowColumn>
      <TableRowColumn columnClass={styles.menuColumn}>
        {!isReadonly && (
          <ActionMenu>
            <MenuItem onClick={() => onRemove(index)}>
              {trans.DELETE()}
            </MenuItem>
          </ActionMenu>
        )}
      </TableRowColumn>
    </TableRow>
  );
};

PromotionItem.propTypes = {
  innerRef: innerRefType,
  appDetails: PropTypes.object.isRequired,
  promotion: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isDragging: PropTypes.bool,
  isReadonly: PropTypes.bool,
  onMove: PropTypes.func.isRequired,
  forAdmin: PropTypes.bool,
};
