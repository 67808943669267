// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { AppName, AppNameChangeFeedback } from "components/form/AppName";
import { PageHeader, PageContent, PageError } from "components/layout";
import { restrictedArea } from "containers/Permissions";
import {
  ALL_PROVIDERS,
  allowInOrganization,
} from "containers/Permissions/groups";
import { appDataType } from "pages/Applications/types";
import { trans } from "src/translations";
import { deepMerge } from "utils/object";

import { EditAppForm } from "./EditAppForm";
import { getInitialValues } from "./utils/config";

@restrictedArea(({ appData }) => {
  const appOwnerId = appData.owner_public_id;

  return {
    allowed: [allowInOrganization(appOwnerId, ALL_PROVIDERS)],
    fallbackChildren: (
      <PageError
        pageHeader={trans.APP_EDIT__ERROR_PAGE_HEADER()}
        error={{ isForbidden: true }}
        defaultMessage={trans.APP_EDIT__ERROR_PAGE_DEFAULT_MESSAGE()}
      />
    ),
  };
})
export class EditApp extends Component {
  static propTypes = {
    initAppData: PropTypes.object.isRequired,
    appData: appDataType.isRequired,
    config: PropTypes.object.isRequired,
    reloadState: PropTypes.func.isRequired,
  };

  state = {
    appRenamingStatus: {
      success: null,
      error: null,
    },
  };

  updateAppRenamingStatus = (status) =>
    this.setState({ appRenamingStatus: status });

  render() {
    const { config, appData, initAppData } = this.props;
    const initialConfigValues = getInitialValues(config);
    const initialValues = deepMerge(initialConfigValues, initAppData);
    return (
      <>
        <PageHeader>
          <AppName
            appData={appData}
            showAppState={true}
            updateStatus={this.updateAppRenamingStatus}
          />
        </PageHeader>
        <PageContent>
          <AppNameChangeFeedback status={this.state.appRenamingStatus} />
          <EditAppForm initialValues={initialValues} {...this.props} />
        </PageContent>
      </>
    );
  }
}
