// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { SubTab, SubTabs } from "components/navigation";
import { trans } from "src/translations";

import {
  DETAILS_TAB,
  DEVICE_LIST_TAB,
} from "./DeviceFilterDetailsLayoutWithData";

export const DeviceFilterDetailsTabs = ({ tabId, filterId }) => {
  return (
    <SubTabs>
      <SubTab
        key={DETAILS_TAB}
        active={tabId === DETAILS_TAB}
        to={`/devices/filters/${filterId}/${DETAILS_TAB}`}
        text={trans.DEVICES__FILTERS_DETAILS_DETAILS_TAB()}
        dataTestId="details-tab"
      />
      <SubTab
        key={DEVICE_LIST_TAB}
        active={tabId === DEVICE_LIST_TAB}
        to={`/devices/filters/${filterId}/${DEVICE_LIST_TAB}`}
        text={trans.DEVICES__FILTERS_DETAILS_DEVICE_LIST_TAB()}
        dataTestId="device-list-tab"
      />
    </SubTabs>
  );
};

DeviceFilterDetailsTabs.propTypes = {
  tabId: PropTypes.string,
  filterId: PropTypes.string,
};

DeviceFilterDetailsTabs.defaultProps = {
  tabId: DETAILS_TAB,
};
