// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import { createDropdownSuggestion } from "./utils";

/**
 * Create dummy section to display value suggestions in.
 */
const createValueSection = (filterObject, values, userInput) => {
  const createValueWithSuggestion = (value) => ({
    ...value,
    suggestion: createDropdownSuggestion(filterObject, value, userInput),
  });

  return {
    title: `${filterObject.displayName} ${trans.POSSIBLE_VALUES()}:`,
    options: values.map(createValueWithSuggestion),
  };
};

/**
 * Prepares data to be displayed in SearchDropdown as ValueDropdownOption.
 * All possible values will be grouped under '... possible values:' section.
 * This function expects list of possible values from the external source
 * e.g. backend endpoint.
 *
 * Used when we have already selected some filter and there are known values
 * for it (e.g. Yes/No)
 */
export const getValueSuggestions = (
  filterObject,
  userInput,
  filterPossibleValues = []
) => {
  if (filterObject == null || filterPossibleValues.length <= 0) {
    return [];
  }
  return [createValueSection(filterObject, filterPossibleValues, userInput)];
};
