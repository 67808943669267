// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const isScrolledIntoView = (
  scrollNode: HTMLElement,
  itemNode: HTMLElement
): boolean => {
  return (
    scrollNode.scrollTop <= itemNode.offsetTop &&
    scrollNode.scrollTop + scrollNode.clientHeight >=
      itemNode.offsetTop + itemNode.clientHeight
  );
};

export const scrollToBottom = (
  scrollNode: HTMLElement,
  itemNode: HTMLElement
): void => {
  scrollNode.scrollTop =
    itemNode.offsetTop - scrollNode.clientHeight + itemNode.clientHeight;
};

export const scrollToTop = (
  scrollNode: HTMLElement,
  itemNode: HTMLElement
): void => {
  scrollNode.scrollTop = itemNode.offsetTop;
};

export const scrollToCenter = (
  scrollNode: HTMLElement,
  itemNode: HTMLElement
): void => {
  scrollNode.scrollTop =
    itemNode.offsetTop -
    scrollNode.clientHeight / 2 +
    itemNode.clientHeight / 2;
};
