// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Checkbox } from "components/form/elements";
import { Section, TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { withRouter, withSearch } from "utils/decorators";

import { OrganizationRow } from "./OrganizationRow";
import styles from "./OrganizationsTable.scss";

@withRouter
@withSearch
export class OrganizationsTableRend extends Component {
  static propTypes = {
    // from parent data component
    organizations: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    toggleFreelanceOrgsVisibility: PropTypes.func.isRequired,
    areFreelanceOrgsHidden: PropTypes.bool.isRequired,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
  };

  static tabsConfig = [
    {
      dataTestId: "organizations-list",
      columns: [
        {
          label: trans.ORGANIZATION_LIST__ICON_COLUMN_LABEL(),
          className: styles.iconColumn,
        },
        {
          label: trans.ORGANIZATION_LIST__NAME_COLUMN_LABEL(),
          className: styles.nameColumn,
        },
        {
          label: trans.ORGANIZATION_LIST__TYPE_COLUMN_LABEL(),
          className: styles.typeColumn,
        },
        {
          label: trans.ORGANIZATION_LIST__USERS_COLUMN_LABEL(),
          className: styles.countColumn,
        },
        { label: "", className: styles.optionsColumn },
      ],
    },
  ];

  renderBody = (organizations) => {
    return (
      <TableBody>
        {organizations.map((o) => (
          <OrganizationRow key={o.id} organization={o} />
        ))}
      </TableBody>
    );
  };

  renderCreateButton = () => {
    return (
      <ButtonsWrapper>
        <Button type="green" to="/organization/create">
          {trans.ORGANIZATION__CREATE_BUTTON()}
        </Button>
      </ButtonsWrapper>
    );
  };

  render() {
    const {
      organizations,
      count,
      error,
      loading,
      searchValue,
      changeSearch,
      toggleFreelanceOrgsVisibility,
      areFreelanceOrgsHidden,
      location,
    } = this.props;

    const locationMessage = get(location, "state.message");

    return (
      <div>
        <Section header={trans.ORGANIZATION_LIST__HEADER()}>
          <Section>
            <SearchBar
              onSearch={changeSearch}
              value={searchValue}
              placeholder={trans.ORGANIZATION_LIST__SEARCH_PLACEHOLDER()}
            />
          </Section>
          <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
          {this.renderCreateButton()}
          <Section>
            <Checkbox
              label={trans.ORGANIZATION_LIST__HIDE_FREELANCE_LABEL()}
              value={areFreelanceOrgsHidden}
              checked={areFreelanceOrgsHidden}
              onChange={toggleFreelanceOrgsVisibility}
              className={styles.hideFreelanceOrganization}
            />
            <TabbedTable
              tabsConfig={OrganizationsTableRend.tabsConfig}
              data={{ results: organizations, count, loading, error }}
              renderTableBody={this.renderBody}
              layout="fixed"
            />
          </Section>
          {this.renderCreateButton()}
        </Section>
      </div>
    );
  }
}
