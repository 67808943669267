// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import styles from "pages/Snap/SnapEdit/components/SelectSource/selectSources.scss";
import { classes } from "utils/classes";

export class SourceTypeContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    logoClass: PropTypes.string,
  };

  render() {
    const { children, logoClass } = this.props;

    return (
      <div className={styles.helpBoxTextWrapper}>
        {children}
        <div className={classes(logoClass, styles.logoWrapper)} />
      </div>
    );
  }
}
