// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import {
  handleScrollDecorator,
  handleSearchChangeDecorator,
} from "./decorators";

@handleScrollDecorator
@handleSearchChangeDecorator
export class Handlers extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,

    // handlers
    handleScroll: PropTypes.func.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
  };

  render() {
    const { children, handleScroll, handleSearchChange } = this.props;
    return children({ handlers: { handleScroll, handleSearchChange } });
  }
}
