// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { GrabsonIcon } from "components/icons";

import styles from "./ManifestEdit.scss";

export const ManifestEditFieldReset = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();
  const value = values[name];

  return value ? (
    <Button
      className={styles.clearValueButton}
      type="plain"
      onClick={() => setFieldValue(name, "")}
    >
      <GrabsonIcon name="close-filled" font="micro" size="xl" />
    </Button>
  ) : null;
};

ManifestEditFieldReset.propTypes = {
  name: PropTypes.string.isRequired,
};
