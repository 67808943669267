// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TableRowColumn, TableSubRow } from "components/layout";

import styles from "./infoRow.scss";

export const InfoRow = ({ colspan, children }) => {
  return (
    <TableSubRow>
      <TableRowColumn className={styles.row} colspan={colspan}>
        {children}
      </TableRowColumn>
    </TableSubRow>
  );
};

InfoRow.propTypes = {
  colspan: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
