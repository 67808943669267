// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { trans } from "src/translations";
import {
  getTranslationLanguageName,
  sortTranslations,
} from "utils/translations";

import { BasicDetailsDiffProvider } from "./BasicDetailsDiffProvider";

export class BasicDetailsTooltip extends Component {
  static propTypes = {
    languages: PropTypes.array.isRequired,
  };

  getTranslationName(translation) {
    return getTranslationLanguageName(this.props.languages, translation);
  }

  renderContent = (changes) => {
    const { languages } = this.props;

    const removedTranslations = sortTranslations(
      languages,
      changes.removedTranslations
    );
    const addedTranslations = sortTranslations(
      languages,
      changes.addedTranslations
    );

    return (
      <ul>
        {removedTranslations.map((t) => (
          <li key={t.language}>
            {"- "}
            {trans.APP_DETAILS_VERSION__SECTION_BASIC_DETAILS_REMOVED({
              translation: this.getTranslationName(t),
            })}
          </li>
        ))}
        {addedTranslations.map((t) => (
          <li key={t.language}>
            {"- "}
            {trans.APP_DETAILS_VERSION__SECTION_BASIC_DETAILS_ADDED({
              translation: this.getTranslationName(t),
            })}
          </li>
        ))}
        {changes.newDefaultTranslation ? (
          <li key="default-translation">
            {"- "}
            {trans.APP_DETAILS_VERSION__SECTION_BASIC_DETAILS_SET_AS_DEFAULT({
              translation: this.getTranslationName(
                changes.newDefaultTranslation
              ),
            })}
          </li>
        ) : null}
      </ul>
    );
  };

  render() {
    return (
      <BasicDetailsDiffProvider>{this.renderContent}</BasicDetailsDiffProvider>
    );
  }
}
