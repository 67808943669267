// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { Input } from "components/form/elements";
import { NumberField } from "components/form/fields";
import { trans } from "src/translations";

import styles from "./StepsDeviceQuantitiesField.scss";

const createDataTestId = (idx) => `step-device-quantity-${idx + 1}-field`;

const renderQuantityLabel = (index) => (
  <Fragment>
    {trans.UPDATES__FORM_FIELD_STEP_QUANTITY_LABEL()}{" "}
    <span className={styles.labelStepIndex}>
      {trans.UPDATES__FORM_FIELD_STEP_QUANTITY_LABEL_STEP({
        step: index + 1,
      })}
    </span>
  </Fragment>
);

export const StepsDeviceQuantitiesField = ({ steps }) => {
  return (
    <>
      {Array.from({ length: steps - 1 }, (_, index) => (
        <NumberField
          name={`deviceQuantityPerStep[${index}]`}
          key={index}
          label={renderQuantityLabel(index)}
          required={true}
          className={styles.narrowField}
          look="simple"
          dataTestId={createDataTestId(index)}
          min={1}
          step={1}
        />
      ))}
      <Input
        label={renderQuantityLabel(steps - 1)}
        value={trans.UPDATES__FORM_FIELD_STEP_QUANTITY_LAST_STEP_VALUE()}
        onChange={Function.prototype}
        className={styles.infinityStep}
        dataTestId={createDataTestId(steps - 1)}
      />
    </>
  );
};

StepsDeviceQuantitiesField.propTypes = {
  steps: PropTypes.number.isRequired,
};
