// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import differenceBy from "lodash-es/differenceBy";
import get from "lodash-es/get";

const getChangedDefault = (activeTrans, latestTrans) => {
  const activeDefault = activeTrans.find((t) => t.is_default);
  const latestDefault = latestTrans.find((t) => t.is_default);
  return get(activeDefault, "language") !== get(latestDefault, "language")
    ? latestDefault
    : undefined;
};

const isBasicDetailsChanged = (changes) => {
  return (
    !Boolean(changes.newDefaultTranslation) &&
    changes.addedTranslations.length === 0 &&
    changes.removedTranslations.length === 0
  );
};

export const createBasicDetailsDiff = (activeVersion, latestVersion) => {
  const activeTrans = get(activeVersion, "translations", []);
  const latestTrans = get(latestVersion, "translations", []);
  const getCode = (t) => t.language;

  const changes = {
    newDefaultTranslation: getChangedDefault(activeTrans, latestTrans),
    addedTranslations: differenceBy(latestTrans, activeTrans, getCode),
    removedTranslations: differenceBy(activeTrans, latestTrans, getCode),
  };

  return isBasicDetailsChanged(changes) ? null : changes;
};
