// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import { createSelector } from "reselect";

import { ID_PREFIX } from "./constants";

export const getSelectedDeviceModels = createSelector(
  [(state, { storePath }) => get(state, `${storePath}`)],
  (selectedTargets) => {
    const models = [];

    if (!selectedTargets) {
      return models;
    }

    for (const [distributorIdStr, distributor] of Object.entries(
      selectedTargets
    )) {
      if (!distributor.families) {
        continue;
      }
      for (const [familyIdStr, family] of Object.entries(
        distributor.families
      )) {
        if (!family.models) {
          continue;
        }
        for (const [modelIdStr, model] of Object.entries(family.models)) {
          if (model.isSelected) {
            const modelId = Number(modelIdStr.replace(ID_PREFIX, ""));
            const distributorId = distributorIdStr.replace(ID_PREFIX, "");
            const familyId = Number(familyIdStr.replace(ID_PREFIX, ""));

            models.push({
              id: modelId,
              name: model.name,
              distributorId: distributorId,
              distributorName: distributor.name,
              familyId: familyId,
              familyName: family.name,
            });
          }
        }
      }
    }
    return models;
  }
);

export const isDistributorSelected = createSelector(
  [
    (state, { storePath, distributorId }) => {
      return get(state, `${storePath}.${ID_PREFIX}${distributorId}.isSelected`);
    },
  ],
  (isSelected) => {
    return Boolean(isSelected);
  }
);

export const isFamilySelected = createSelector(
  [
    (state, { storePath, distributorId, familyId }) => {
      return get(
        state,
        `${storePath}.${ID_PREFIX}${distributorId}.families.${ID_PREFIX}${familyId}.isSelected`
      );
    },
  ],
  (isSelected) => Boolean(isSelected)
);

export const isModelSelected = createSelector(
  [
    (state, { storePath, distributorId, familyId, modelId }) => {
      return get(
        state,
        `${storePath}.${ID_PREFIX}${distributorId}.families.${ID_PREFIX}${familyId}.models.${ID_PREFIX}${modelId}.isSelected`
      );
    },
  ],
  (isSelected) => Boolean(isSelected)
);
