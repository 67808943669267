// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { PageContent, PageHeader } from "components/layout";
import { trans } from "src/translations";

import { DeviceInventoryData } from "./DeviceInventoryData";

export const DeviceInventory = () => (
  <Fragment>
    <PageHeader>{trans.DEVICES__DEVICE_INVENTORY_HEADER()}</PageHeader>
    <PageContent>
      <DeviceInventoryData />
    </PageContent>
  </Fragment>
);
