// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { ValidationBadge } from "components/form";
import { getAriaProps, getErrorId } from "components/form/fields";
import { Tooltip } from "components/popups";
import { classes } from "utils/classes";
import { withFieldGuard } from "utils/fieldGuard";

import styles from "./checkbox.scss";

@withFieldGuard
class Checkbox extends Component {
  static propTypes = {
    /** Replacement for label */
    children: PropTypes.node,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    tooltipClassName: PropTypes.string,
    /** Label string displayed to the user */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    required: PropTypes.bool,
    /** Unique checkbox name. Used as HTML `id` */
    name: PropTypes.string,
    disabled: PropTypes.bool,
    /** Current state  */
    checked: PropTypes.bool,
    /** Special third state of the checkbox as per HTML spec */
    indeterminate: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
    ]),
    /**
     * Change callback.
     *
     * Type: (e: Event) => void
     */
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    tooltip: PropTypes.string,
    /** Children displayed if checkbox checked */
    childrenIfChecked: PropTypes.node,
    /** Relative position of icon and text */
    isRtl: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    isRtl: false,
    dataTestId: "checkbox",
    checked: false,
    indeterminate: false,
  };

  render() {
    const {
      children,
      label,
      tooltip = "",
      className,
      inputClassName,
      labelClassName,
      tooltipClassName,
      isRtl,
      childrenIfChecked,
      dataTestId,
      name,
      ...field
    } = this.props;
    const {
      value,
      onFocus,
      onChange,
      checked,
      disabled,
      error,
      touched,
      indeterminate,
    } = field;

    const labelEl = (
      <label
        htmlFor={"form-id-" + (field.name || name)}
        className={classes(styles.label, labelClassName)}
        data-test-id={`${dataTestId}-label`}
      >
        {children ? children : label}
      </label>
    );

    return (
      <div
        className={classes(className, { [styles.disabled]: disabled })}
        data-test-id={dataTestId}
      >
        <div className={styles.checkbox}>
          <Tooltip content={tooltip} className={tooltipClassName}>
            {isRtl && labelEl}
            <input
              id={"form-id-" + (field.name || name)}
              disabled={disabled}
              name={name}
              type="checkbox"
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              checked={Boolean(checked)}
              className={classes(styles.checkboxInput, inputClassName)}
              data-test-id={`${dataTestId}-input`}
              ref={(el) => el && (el.indeterminate = indeterminate)}
              {...getAriaProps({ name, error, touched })}
            />
            {!isRtl && labelEl}
          </Tooltip>
          <ValidationBadge
            error={error}
            touched={touched}
            errorId={getErrorId(name)}
          />
        </div>
        {checked && childrenIfChecked}
      </div>
    );
  }
}

// styleguidist does not like exporting classes that use decorators
export { Checkbox };
