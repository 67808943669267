// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { ValidationBadge } from "components/form";
import { Input } from "components/form/elements";
import { Modal } from "components/layout";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";
import { HtmlTranslation } from "src/translations/utils/components/HtmlTranslation";
import { getGqlErrorMessage } from "utils/errors";

import {
  TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_KEY,
  TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_VALUE,
} from "../constants";
import { validateAttribute } from "../utils/validateAttribute";
import styles from "./AttributeModal.scss";

const DEFAULT_STATE = {
  error: null,
  hasValidationError: false,
  nameInput: "",
  submitting: false,
  valueInput: "",
};

export class AttributeAddModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setAttributeRequest: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
  };

  state = { ...DEFAULT_STATE };

  handleClose = () => {
    this.setState({ ...DEFAULT_STATE });
    this.props.onClose();
  };

  handleInputNameChange = (nameInput) => {
    this.setState((prevState) => ({
      nameInput,
      hasValidationError: false,
      valueInput:
        nameInput === TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_KEY
          ? TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_VALUE
          : prevState.valueInput,
    }));
  };

  handleInputValueChange = (valueInput) => {
    this.setState({
      valueInput,
      hasValidationError: false,
    });
  };

  handleSubmit = async () => {
    const { setAttributeRequest, setSuccessMessage } = this.props;
    const { nameInput, valueInput } = this.state;
    this.setState({
      submitting: true,
    });
    if (!validateAttribute(nameInput, valueInput)) {
      this.setState({ hasValidationError: true, submitting: false });
      return;
    }
    try {
      await setAttributeRequest(nameInput, valueInput);
    } catch (e) {
      this.setState((prevState) => {
        if (!prevState.submitting) {
          // The modal has been closed.
          return prevState;
        }
        return {
          error: getGqlErrorMessage(e),
          submitting: false,
        };
      });
      return;
    }
    this.setState({
      submitting: false,
    });
    setSuccessMessage(
      trans.DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_SUCCESS_ADD()
    );
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { error, hasValidationError, nameInput, submitting, valueInput } =
      this.state;

    const isTestDeviceUnitInput =
      nameInput === TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_KEY;
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        className={styles.modal}
        size="big"
        title={trans.DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_MODAL_HEADER()}
        dataTestId="add-attribute-modal"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={onClose}
              dataTestId="add-attribute-modal-cancel-button"
              disabled={submitting}
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.handleSubmit}
              dataTestId="add-attribute-modal-submit-button"
              disabled={submitting || hasValidationError}
              processing={submitting}
            >
              {trans.DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_MODAL_BUTTON()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <div className={styles.modalDescription}>
          {trans.DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_MODAL_DESCRIPTION()}
        </div>

        <form className={styles.modalForm}>
          <Input
            label={trans.DEVICES__DEVICE_DETAILS_ATTRIBUTE_NAME()}
            required={true}
            className={styles.modalInput}
            onChange={(e) => this.handleInputNameChange(e.target.value)}
            value={nameInput}
          />
          <span className={styles.modalColon}>{":"}</span>
          <Input
            label={trans.DEVICES__DEVICE_DETAILS_ATTRIBUTE_VALUE()}
            required={true}
            className={styles.modalInput}
            onChange={(e) => this.handleInputValueChange(e.target.value)}
            disabled={isTestDeviceUnitInput}
            value={valueInput}
          />
          {isTestDeviceUnitInput && (
            <Tooltip
              content={
                <HtmlTranslation
                  transFunc={
                    trans.DEVICES__DEVICE_DETAILS_ADD_TEST_DEVICE_UNIT_ATTRIBUTE_TOOLTIP
                  }
                />
              }
              alignment="bottom-start"
              className={styles.testDeviceUnitTooltip}
              dataTestId="add-test-device-unit-tooltip"
            >
              ?
            </Tooltip>
          )}
        </form>

        <ValidationBadge
          error={trans.DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_MODAL_VALIDATION()}
          touched={hasValidationError}
          dataTestId="add-attribute-modal-validation-error"
        />

        {error && <Info type="error">{error}</Info>}
      </Modal>
    );
  }
}
