// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Routes, Navigate, Route } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";
import { trans } from "src/translations";

import { AdminDefaultPlan } from "./AdminDefaultPlan/AdminDefaultPlan";
import { AdminPlanForm } from "./AdminPlanForm/AdminPlanForm";
import { AdminPlans } from "./AdminPlans/AdminPlans";
import { CustomPlanForm } from "./CustomPlanForm/CustomPlanForm";
import { CustomPlans } from "./CustomPlans/CustomPlans";
import { DefaultPlan } from "./DefaultPlan/DefaultPlan";

export class DistributionRoute extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<RedirectDependingOnRoles />} />
        <Route
          exact
          path="/default-plan"
          element={
            <ExtendedRoute
              name={trans.DISTR_DEFAULT_PLAN__PLAN_ROUTE_NAME()}
              component={DefaultPlan}
            />
          }
        />
        <Route
          exact
          path="/custom-plans"
          element={
            <ExtendedRoute
              name={trans.DISTR_CUSTOM_PLANS__ROUTE_NAME()}
              component={CustomPlans}
            />
          }
        />
        <Route
          exact
          path="/admin-plans"
          element={
            <ExtendedRoute
              name={trans.DISTR_PLAN__ADMIN_ROUTE_NAME()}
              component={AdminPlans}
            />
          }
        />
        <Route
          exact
          path="/custom-plans/create"
          element={
            <ExtendedRoute
              name={trans.DISTR_PLAN__ADD_ROUTE_NAME()}
              component={CustomPlanForm}
            />
          }
        />
        <Route
          exact
          path="/admin-plans/create"
          element={
            <ExtendedRoute
              name={trans.DISTR_PLAN__ADD_ROUTE_NAME()}
              component={AdminPlanForm}
            />
          }
        />
        <Route
          exact
          path="/custom-plans/edit/:id"
          element={
            <ExtendedRoute
              name={trans.DISTR_PLAN__EDIT_ROUTE_NAME()}
              component={CustomPlanForm}
            />
          }
        />
        <Route
          exact
          path="/admin-plans/edit/:id"
          element={
            <ExtendedRoute
              name={trans.DISTR_PLAN__EDIT_ROUTE_NAME()}
              component={AdminPlanForm}
            />
          }
        />
        <Route
          exact
          path="/admin-plans/default-plans/:id"
          element={
            <ExtendedRoute
              path="/distribution/admin-plans/default-plans/:id"
              name={trans.DISTR_DEFAULT_PLAN__PLAN_ROUTE_NAME()}
              component={AdminDefaultPlan}
            />
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    );
  }
}
