// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { RgbaStringColorPicker } from "react-colorful";

import { colord } from "colord";
import { useField } from "formik";
import debounce from "lodash-es/debounce";

import { Button } from "components/buttons";
import { Input } from "components/form/elements";
import { GrabsonIcon } from "components/icons";
import { PopupOnClick, Tooltip } from "components/popups";
import { popupAlignmentType } from "components/popups/Popup/types";
import { Header } from "components/typography";
import transparentSvg from "images/transparent.svg";
import { trans } from "src/translations";

import { createMixedValidator } from ".";
import styles from "./ColorField.scss";
import { FieldProps } from "./propTypes";

const debounceDelay = 100;

const getBackgroundColor = (value) =>
  value
    ? `linear-gradient(to right, ${value}, ${value}), url(${transparentSvg})`
    : `url(${transparentSvg})`;

const renderPresets = (field, helpers, presets) =>
  presets && (
    <>
      <Header
        type="h6"
        color="tertiary"
        uppercase={true}
        className={styles.colorPresetHeader}
      >
        {trans.COLOR_FIELD_PRESETS_HEADER()}
      </Header>
      <ul className={styles.colorPresetList}>
        {presets.map((color, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${field.name}-${color}-${index}`}>
            <Button
              className={styles.colorPresetButton}
              onClick={() => helpers.setValue(color.value)}
              type="plain"
            >
              <Tooltip
                alignment="top"
                title={color.label}
                classNamePopup={styles.colorPresetTooltip}
              >
                <span
                  className={styles.colorPresetIcon}
                  style={{
                    background: getBackgroundColor(color.value),
                  }}
                />
              </Tooltip>
            </Button>
          </li>
        ))}
      </ul>
    </>
  );

const renderTrigger = (field, meta, rest) => (
  <Input
    className={styles.colorTrigger}
    required={field.required}
    props={{
      autoComplete: "off",
      ...field,
    }}
    {...meta}
    {...rest}
  >
    <div
      className={styles.colorPreview}
      style={{
        background: getBackgroundColor(field.value),
      }}
    />
    <GrabsonIcon className={styles.colorIcon} name="edit" size="xl" />
  </Input>
);

const renderPopup = (field, helpers, presets) => (
  <div className={styles.colorPopup}>
    <RgbaStringColorPicker
      className={styles.colorPicker}
      color={colord(field.value).toRgbString()}
      onChange={debounce(
        (value) => helpers.setValue(colord(value).toHex()),
        debounceDelay
      )}
    />
    {renderPresets(field, helpers, presets)}
  </div>
);

export const ColorField = (props) => {
  const { popupPosition, required = true, validate, presets, ...rest } = props;

  const [field, meta, helpers] = useField({
    ...props,
    popupPosition,
    required,
    validate: createMixedValidator(props.name, required, validate),
    type: "text",
  });

  return (
    <PopupOnClick
      alignment={popupPosition}
      className={styles.colorWrapper}
      classNamePopupWrapper={styles.colorPopupWrapper}
      renderPopup={() => renderPopup(field, helpers, presets)}
      renderTrigger={() => renderTrigger(field, meta, rest)}
    />
  );
};

ColorField.propTypes = {
  popupPosition: popupAlignmentType,
  ...FieldProps,
};
