// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useMutation } from "@apollo/client";

// Currently impossible to properly type in TS 4.2
export const withMutation =
  ({ name, mutation }) =>
  (ComposedComponent) => {
    return function WithMutationWrapper(props) {
      const [mutationFunction, mutationStatus] = useMutation(mutation);

      return (
        <ComposedComponent
          {...props}
          {...{
            [`${name}Mutation`]: mutationFunction,
            [`${name}MutationStatus`]: mutationStatus,
          }}
        />
      );
    };
  };
