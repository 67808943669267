// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { StaticPage } from "components/layout";
import { Link } from "components/typography";
import { trans, HtmlTranslation } from "src/translations";

export const Guidelines = () => (
  <StaticPage title={trans.GUIDELINES__PAGE_HEADER()}>
    <h1>{trans.GUIDELINES__SUBMITTING_TO_APP_STORE_HEADER()}</h1>
    <HtmlTranslation
      transFunc={trans.GUIDELINES__SUBMITTING_TO_VEWD_APP_STORE_CONTENT}
      transParams={{
        link: (
          <Link href="https://developer.vewd.com">
            {trans.GUIDELINES__INTRODUCTION_LINK()}
          </Link>
        ),
      }}
    />

    <h1>{trans.GUIDELINES__REQUIREMENTS_FOR_APPS_HEADER()}</h1>
    <HtmlTranslation
      transFunc={trans.GUIDELINES__REQUIREMENTS_FOR_APPS_CONTENT}
      transParams={{
        link: (
          <Link href="https://developer.vewd.com">
            {trans.GUIDELINES__APP_REQUIREMENTS_LINK()}
          </Link>
        ),
      }}
    />

    <h1 id="appstore-requirements">
      {trans.GUIDELINES__REQUIREMENTS_FOR_DIST_APPS_HEADER()}
    </h1>
    <HtmlTranslation
      transFunc={trans.GUIDELINES__REQUIREMENTS_FOR_DIST_APPS_CONTENT}
      transParams={{
        link: (
          <Link href="#acceptance-criteria">
            {trans.GUIDELINES__ACCEPTANCE_CRITERIA_LINK()}
          </Link>
        ),
      }}
    />

    <h1 id="acceptance-criteria">
      {trans.GUIDELINES__ACCEPTANCE_CRITERIA_HEADER()}
    </h1>
    <HtmlTranslation
      transFunc={trans.GUIDELINES__ACCEPTANCE_CRITERIA_CONTENT}
      transParams={{
        link: (
          <Link href="mailto:tvstore-content@vewd.com">
            tvstore-content@vewd.com
          </Link>
        ),
      }}
    />

    <h1>{trans.GUIDELINES__AFTER_SUBMISSION_HEADER()}</h1>
    <HtmlTranslation transFunc={trans.GUIDELINES__AFTER_SUBMISSION_CONTENT} />
  </StaticPage>
);
