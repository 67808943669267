// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Routes, Navigate, Route } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";
import { trans } from "src/translations";

import { AdminCampaignForm } from "./AdminCampaignForm/AdminCampaignForm";
import { CampaignsTablePage } from "./CampaignsTable/CampaignsTablePage";
import { PromotionDetails } from "./PromotionDetails";
import { Promotions } from "./PromotionsTable/Promotions";
import { PromotionTypesTablePage } from "./PromotionsTypesTable/PromotionTypesTablePage";
import { PromotionTypeFormPage } from "./PromotionTypeForm/PromotionTypeFormPage";
import { SchedulePage } from "./Schedule/SchedulePage";

export class PromotionsRoute extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<RedirectDependingOnRoles />} />
        <Route
          exact
          path="/admin/create"
          element={
            <ExtendedRoute
              name={trans.PROMOTION_CREATE__ROUTE_NAME()}
              component={PromotionDetails}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/admin/edit/:id"
          element={
            <ExtendedRoute
              name={trans.PROMOTION_EDIT__ROUTE_NAME()}
              component={PromotionDetails}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/admin/list"
          element={
            <ExtendedRoute
              name={trans.PROMOTION_ADMIN_LIST__ROUTE_NAME()}
              component={Promotions}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/create"
          element={
            <ExtendedRoute
              name={trans.PROMOTION_CREATE__ROUTE_NAME()}
              component={PromotionDetails}
              componentProps={{ forAdmin: false }}
            />
          }
        />
        <Route
          exact
          path="/edit/:id"
          element={
            <ExtendedRoute
              name={trans.PROMOTION_EDIT__ROUTE_NAME()}
              component={PromotionDetails}
              componentProps={{ forAdmin: false }}
            />
          }
        />
        <Route
          exact
          path="/list"
          element={
            <ExtendedRoute
              name={trans.PROMOTION_LIST__ROUTE_NAME()}
              component={Promotions}
              componentProps={{ forAdmin: false }}
            />
          }
        />
        <Route
          exact
          path="/admin/campaigns/create"
          element={
            <ExtendedRoute
              name={trans.CAMPAIGN_CREATE__ROUTE_NAME()}
              component={AdminCampaignForm}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/admin/campaigns/:id"
          element={
            <ExtendedRoute
              name={trans.CAMPAIGN_EDIT__ROUTE_NAME()}
              component={AdminCampaignForm}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/admin/campaigns"
          element={
            <ExtendedRoute
              name={trans.CAMPAIGN_ADMIN_LIST__ROUTE_NAME()}
              component={CampaignsTablePage}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/campaigns/create"
          element={
            <ExtendedRoute
              name={trans.CAMPAIGN_CREATE__ROUTE_NAME()}
              component={AdminCampaignForm}
              componentProps={{ forAdmin: false }}
            />
          }
        />
        <Route
          exact
          path="/campaigns/:id"
          element={
            <ExtendedRoute
              name={trans.CAMPAIGN_EDIT__ROUTE_NAME()}
              component={AdminCampaignForm}
              componentProps={{ forAdmin: false }}
            />
          }
        />
        <Route
          exact
          path="/campaigns"
          element={
            <ExtendedRoute
              name={trans.CAMPAIGN_LIST__ROUTE_NAME()}
              component={CampaignsTablePage}
              componentProps={{ forAdmin: false }}
            />
          }
        />
        <Route
          exact
          path="/admin/schedule"
          element={
            <ExtendedRoute
              name={trans.SCHEDULE_ADMIN__ROUTE_NAME()}
              component={SchedulePage}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/schedule"
          element={
            <ExtendedRoute
              name={trans.SCHEDULE__ROUTE_NAME()}
              component={SchedulePage}
              componentProps={{ forAdmin: false }}
            />
          }
        />
        <Route
          exact
          path="/admin/types/create"
          element={
            <ExtendedRoute
              name={trans.PROMOTION_TYPE_CREATE__ROUTE_NAME()}
              component={PromotionTypeFormPage}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/admin/types/edit/:id"
          element={
            <ExtendedRoute
              name={trans.PROMOTION_TYPE_EDIT__ROUTE_NAME()}
              component={PromotionTypeFormPage}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/admin/types"
          element={
            <ExtendedRoute
              name={trans.PROMOTION_TYPE_LIST__ROUTE_NAME()}
              component={PromotionTypesTablePage}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    );
  }
}
