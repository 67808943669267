// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import styles from "./ManifestsList/ManifestList.scss";

export const ITEMS_PER_PAGE = 20;
export const TABS_CONFIG = [
  {
    id: "",
    dataTestId: "manifest-list",
    label: "",
    columns: [
      {
        id: "app-id",
        label: "Application Name or Id",
        dataTestId: "app-id-column-header",
        className: styles.appIdColumn,
      },
      {
        id: "created_date",
        label: "Creation date",
        sortBy: "created_date",
        dataTestId: "creation-date-column-header",
        className: styles.dateColumn,
      },
      {
        id: "modified_date",
        label: "Last edited",
        dataTestId: "modified-date-column-header",
        className: styles.dateColumn,
      },
      {
        id: "actions",
        label: "",
        dataTestId: "actions-column-header",
        className: styles.actionMenu,
      },
    ],
  },
];

export const CREATE_TAB_PATH = "/manifests/create/application-id";
export const EDIT_TAB_PATH = "/manifests/edit/:appId/application-id";
