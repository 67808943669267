// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextField, TextareaField } from "components/form/fields";
import { Header } from "components/typography";
import { api } from "containers/Request";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";

const jsonPointerToFieldName = {
  "/data/attributes/name": "name",
  "/data/attributes/description": "description",
  "/data/relationships/owner": "owner",
};

const fieldName = {
  name: "name",
  label: trans.DEVICES__FAMILY_NAME_LABEL(),
  dataTestId: "name-field",
};
const fieldDescription = {
  name: "description",
  label: trans.DEVICES__FAMILY_DESCRIPTION_LABEL(),
  dataTestId: "description-field",
};

@connect(null, (dispatch) => ({
  createDeviceFamily: (body) =>
    dispatch(api.createDeviceFamily.action({ options: { body } })),
  updateDeviceFamily: (id, body) =>
    dispatch(
      api.updateDeviceFamily.action({ params: { id }, options: { body } })
    ),
}))
export class DeviceFamilyForm extends Component {
  static propTypes = {
    ownerId: PropTypes.string.isRequired,
    onRequestSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    // from @connect
    createDeviceFamily: PropTypes.func.isRequired,
    updateDeviceFamily: PropTypes.func.isRequired,
  };

  getFamilyId = () => {
    return this.props.initialValues.id;
  };

  isEdit = () => {
    return Boolean(this.getFamilyId());
  };

  handleSubmit = async (values, { setErrors }) => {
    const {
      ownerId,
      createDeviceFamily,
      updateDeviceFamily,
      onRequestSuccess,
    } = this.props;

    const body = createBody({
      type: "DeviceFamily",
      id: this.getFamilyId(),
      name: values[fieldName.name],
      description: values[fieldDescription.name],
      owner: { type: "Owner", id: ownerId },
      relationshipNames: ["owner"],
    });

    let response;

    if (this.isEdit()) {
      response = await updateDeviceFamily(Number(this.getFamilyId()), body);
    } else {
      response = await createDeviceFamily(body);
    }

    if (response.error) {
      const submitErrors = prepareErrorsForForm(
        response.error,
        jsonPointerToFieldName
      );
      setErrors(submitErrors);
    } else {
      const deviceFamilyId = response.result.results.id;
      onRequestSuccess(deviceFamilyId, this.isEdit());
    }
  };

  render() {
    const { onCancel, initialValues } = this.props;
    return (
      <Formik
        initialValues={{
          [fieldDescription.name]: initialValues[fieldDescription.name] ?? "",
          [fieldName.name]: initialValues[fieldName.name] ?? "",
        }}
        onSubmit={this.handleSubmit}
      >
        {({ dirty, errors, isSubmitting, isValid }) => (
          <Form>
            <Header uppercase={true} bold={true} color="secondary">
              {this.isEdit()
                ? trans.DEVICES__EDIT_FAMILY()
                : trans.DEVICES__ADD_FAMILY()}
            </Header>
            <TextField {...fieldName} />
            <TextareaField {...fieldDescription} required={false} />
            {errors?._error && <Info type="error">{errors._error}</Info>}
            <ButtonsWrapper>
              <Button type="normal" onClick={onCancel}>
                {trans.CANCEL()}
              </Button>
              <Button
                type="green"
                disabled={isSubmitting || !dirty || !isValid}
                processing={isSubmitting}
                buttonType="submit"
              >
                {this.isEdit()
                  ? trans.SUBMIT_CHANGES()
                  : trans.DEVICES__ADD_NEW_FAMILY()}
              </Button>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    );
  }
}
