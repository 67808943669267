// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { changeActiveOrganization } from "containers/Auth/actions";
import { withRouter } from "utils/decorators/withRouter";
import { removeQueryParamsFromLocation } from "utils/url";

import { getActiveOrganizationSelector } from "../selectors";

export const activeOrganizationPropTypes = PropTypes.shape({
  id: PropTypes.string,
  /**
   * Its image url really
   */
  img: PropTypes.string,
  name: PropTypes.string,
  /**
   * For roles, see src/containers/Permissions/roles.js
   * Use constants from that file instead of strings
   */
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const withActiveOrganization = (ComposedComponent) => {
  @withRouter
  @connect(
    (state) => ({
      activeOrganization: getActiveOrganizationSelector(state),
    }),
    (dispatch) => ({
      changeActiveOrganization: (organizationId) =>
        dispatch(changeActiveOrganization(organizationId)),
    })
  )
  class WithActiveOrganization extends Component {
    static propTypes = {
      // from @withRouter
      location: PropTypes.object.isRequired,
      navigate: PropTypes.func.isRequired,

      // from @connect
      activeOrganization: activeOrganizationPropTypes,
      changeActiveOrganization: PropTypes.func.isRequired,
    };

    state = {
      isChangingActiveOrganizationCount: 0,
      changingActiveOrganizationError: undefined,
    };

    onChangeActiveOrganization = async (organizationId) => {
      const { changeActiveOrganization, location, navigate } = this.props;

      try {
        this.setState(({ isChangingActiveOrganizationCount }) => ({
          isChangingActiveOrganizationCount:
            isChangingActiveOrganizationCount + 1,
        }));

        await changeActiveOrganization(organizationId);
        const newLocation = removeQueryParamsFromLocation(location, [
          "page",
          "search",
        ]);
        navigate(newLocation);

        this.setState({ changingActiveOrganizationError: undefined });
      } catch (err) {
        this.setState({ changingActiveOrganizationError: err.message });
      } finally {
        this.setState(({ isChangingActiveOrganizationCount }) => ({
          isChangingActiveOrganizationCount:
            isChangingActiveOrganizationCount - 1,
        }));
      }
    };

    render() {
      const { activeOrganization } = this.props;

      return (
        <ComposedComponent
          {...this.props}
          activeOrganization={activeOrganization}
          changeActiveOrganization={this.onChangeActiveOrganization}
          isChangingActiveOrganization={Boolean(
            this.state.isChangingActiveOrganizationCount
          )}
          changingActiveOrganizationError={
            this.state.changingActiveOrganizationError
          }
        />
      );
    }
  }

  return WithActiveOrganization;
};
