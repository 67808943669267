// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { NO_VALUE_PLACEHOLDER } from "src/constants";

/**
 * This function convert data from new elastic search service api, to old
 * IronMaiden api, which UI components accept.
 * @author s.kaczorowski
 */
export const fromElasticSearchDataToIm = (esData) => {
  let imData = {};

  if (esData) {
    const priority = get(esData, "moderation.priority", "");
    const status = get(esData, "moderation.status", "").toUpperCase();
    const ownerPlan = get(esData, "owner.organization_plan");
    const dateSubmitted = get(esData, "version_submitted.date_submitted", "");
    const dateAvailableSince = get(esData, "first_approved_date", "");
    imData = {
      id: esData.id,
      name: esData.name,
      date_submitted: new Date(dateSubmitted),
      has_submitted_version: true,
      category: get(esData, "version_submitted.app_category", ""),
      icon_small: esData.icon,
      type: esData.type,
      top_annotation: esData.top_annotation || [],
      status,
      owner: {
        name: get(esData, "owner.display_name", ""),
        organizationPlan: ownerPlan,
      },

      // moderation apps
      moderator: get(esData, "moderation.moderator.display_name", ""),
      contentManager: get(
        esData,
        "certification.content_manager.display_name",
        ""
      ),
      priority: priority === "None" ? NO_VALUE_PLACEHOLDER : priority,
      needAction: status.toLowerCase() === "PENDING",

      // distribution admin apps
      available_since: new Date(dateAvailableSince),
    };
  }
  return imData;
};
