// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const tabsConfig = [
  {
    dataTestId: "assets-list",
    columns: [
      {
        id: "name",
        label: trans.NAME(),
      },
      {
        id: "description",
        label: trans.DESCRIPTION(),
      },
      {
        id: "menu",
        label: "",
      },
    ],
  },
];
