// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { PageHeader, PageContent, PageContentError } from "components/layout";
import { Tabs, TabContent, Tab } from "components/navigation";
import { RestrictedArea } from "containers/Permissions";
import { isAllowedToEditUser } from "containers/Permissions/groups";
import { trans } from "src/translations";

import { withUserViewEdit } from "./_utils";

@withUserViewEdit
export class UserLayout extends Component {
  static propTypes = {
    children: PropTypes.node,

    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,

    forUserAdmin: PropTypes.bool,

    // from @withUserViewEdit
    getUserProfile: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.updateUser();
  };

  componentDidUpdate = (prevProps) => {
    const id = this.getUserId();
    const prevId = prevProps.params.id;
    const userChanged = id !== prevId;

    if (userChanged) {
      this.updateUser();
    }
  };

  state = {
    user: undefined,
    isLoading: true,
    error: undefined,
  };

  getUserId = () => {
    return this.props.params.id;
  };

  updateUser = async () => {
    const { getUserProfile } = this.props;

    try {
      this.setState({ isLoading: true, error: undefined });

      const { error, result } = await getUserProfile(this.getUserId());

      if (error) {
        throw error;
      }

      this.setState({ user: result.results });
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderContent = () => {
    const { isLoading, error, user } = this.state;
    const { children, forUserAdmin } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return <PageContentError error={error} />;
    }

    return (
      <Fragment>
        <Tabs>
          <Tab
            to={`/users/${
              forUserAdmin ? "admin/" : ""
            }${this.getUserId()}/details`}
            text={trans.USER_DETAILS__TAB_DETAILS()}
            dataTestId="details-tab"
          />
          <RestrictedArea
            allowed={[isAllowedToEditUser(user)]}
            showFallback={false}
          >
            <Tab
              to={`/users/${
                forUserAdmin ? "admin/" : ""
              }${this.getUserId()}/edit`}
              text={trans.USER_DETAILS__TAB_EDIT()}
              dataTestId="edit-tab"
            />
          </RestrictedArea>
        </Tabs>
        <TabContent>
          <TabContent>{children}</TabContent>
        </TabContent>
      </Fragment>
    );
  };

  render() {
    return (
      <div>
        <PageHeader>{trans.USER_DETAILS__PAGE_HEADER()}</PageHeader>
        <PageContent>{this.renderContent()}</PageContent>
      </div>
    );
  }
}
