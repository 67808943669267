// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withRouter, withSearch, withSorting } from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import {
  getUpdateJobQuery,
  getUpdateJobDevicesQuery,
} from "./gql/getUpdateJobQuery";
import {
  TABS_CONFIG,
  ITEMS_PER_PAGE,
} from "./UpdateJobDetailsEligibleDevicesTable";
import {
  TAB_ID_ELIGIBLE_DEVICE,
  TAB_ID_MAIN,
  UpdateJobDetailsRend,
} from "./UpdateJobDetailsRend";
import { adaptDevices } from "./utils/adaptEligibleDevices";
import { adaptUpdateJobData } from "./utils/adaptUpdateJobData";

export const getUpdateJobDefaultPath = (id) => `/updates/jobs/${id}`;
export const getUpdateJobEligibleDevicesPath = (id) =>
  `/updates/jobs/${id}/eligible_devices`;

@restrictedArea(() => ({
  allowed: [ROLES.softwareUpdates.updateManager],
}))
@withRouter
@withSearch
@withSorting({
  defaultSortBy: "LAST_CONTACT_DATE",
  allowedSortByValues: TABS_CONFIG[0].columns
    .map(({ sortBy }) => sortBy)
    .filter((column) => Boolean(column)),
  defaultSortOrder: "DESC",
})
@withActiveOrganization
@withQuery({ name: "updateJob", query: getUpdateJobQuery })
@withQuery({ name: "updateJobDevices", query: getUpdateJobDevicesQuery })
export class UpdateJobDetailsData extends PureComponent {
  static propTypes = {
    // from @withRouter

    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withQuery
    updateJobQuery: PropTypes.func,
    updateJobQueryStatus: queryResultType,
    updateJobDevicesQuery: PropTypes.func,
    updateJobDevicesQueryStatus: queryResultType,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withSorting
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  };

  state = {
    modalIsOpen: false,
    modalAction: null,
    devicesRefreshDate: null,
  };

  componentDidMount() {
    this.fetchUpdateJob();
    this.fetchDevices();
  }

  componentDidUpdate(prevProps) {
    const prevParams = prevProps.params;
    const organizationId = this.props.activeOrganization.id;

    if (
      prevProps.activeOrganization.id !== organizationId ||
      prevParams.id !== this.updateJobId()
    ) {
      this.fetchUpdateJob();
      this.setState({
        modalIsOpen: false,
        modalAction: null,
      });
    }

    if (this.props.location !== prevProps.location) {
      this.fetchDevices();
    }
  }

  updateJobId = () => this.props.params.id;

  fetchUpdateJob = async () => {
    const { updateJobQuery } = this.props;

    await updateJobQuery({
      variables: {
        id: this.updateJobId(),
      },
    });
    this.setState({
      devicesRefreshDate: new Date(),
    });
  };

  fetchDevices = async () => {
    const { updateJobDevicesQuery, searchValue, sortBy, sortOrder } =
      this.props;

    await updateJobDevicesQuery({
      variables: {
        devicesPagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
        search: {
          query: searchValue,
        },
        sort: {
          name: sortBy.toUpperCase(),
          order: sortOrder,
        },
        id: this.updateJobId(),
      },
    });
    this.setState({
      devicesRefreshDate: new Date(),
    });
  };

  openModal = (modalAction) =>
    this.setState({ modalIsOpen: true, modalAction });

  handleModalSuccess = () => {
    const {
      navigate,
      updateJobQueryStatus: { refetch },
    } = this.props;

    if (this.state.modalAction === "DELETE") {
      navigate(
        {
          pathname: "/updates/jobs/list/active",
        },
        {
          state: {
            deletedUpdateJobName: this.getData().data.name,
          },
        }
      );
      return;
    }

    this.setState({
      modalIsOpen: false,
      modalAction: null,
    });

    refetch();
  };

  handleModalClose = () =>
    this.setState({
      modalIsOpen: false,
      modalAction: null,
    });

  getData = () => {
    const { loading, error, data } = this.props.updateJobQueryStatus;
    const updateJob = get(data, "updateJob.updateJob", undefined);

    return {
      loading,
      data: adaptUpdateJobData(updateJob),
      error: error && getGqlErrorMessage(error),
    };
  };

  getFetchDevicesStatus = () => {
    const { updateJobDevicesQueryStatus: status } = this.props;
    const items = adaptDevices(
      status?.data?.updateJob?.updateJob?.eligibleDevices ?? []
    );

    return {
      loading: status.loading,
      error: status.error && getGqlErrorMessage(status.error),
      data: {
        items,
        total:
          Number(status?.data?.updateJob?.updateJob?.eligibleDevicesCount) || 0,
      },
    };
  };

  render() {
    const { location, changeSearch } = this.props;
    const { modalIsOpen, modalAction, devicesRefreshDate } = this.state;
    const { loading, data, error } = this.getData();

    const tabId =
      location.pathname === getUpdateJobDefaultPath(this.updateJobId())
        ? TAB_ID_MAIN
        : TAB_ID_ELIGIBLE_DEVICE;

    return (
      <UpdateJobDetailsRend
        jobId={this.updateJobId()}
        tabId={tabId}
        loading={loading}
        error={error}
        data={data}
        modalIsOpen={modalIsOpen}
        modalAction={modalAction}
        openModal={this.openModal}
        onModalClose={this.handleModalClose}
        onModalSuccess={this.handleModalSuccess}
        devicesRefreshDate={devicesRefreshDate}
        fetchDevices={this.fetchDevices}
        fetchDevicesStatus={this.getFetchDevicesStatus()}
        changeSearch={changeSearch}
      />
    );
  }
}
