// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/**
 * Helper function that unflattens path: value structure to a tree
 */

export const unflatten = (obj: Record<string, any>): Record<string, any> => {
  if (Object(obj) !== obj || Array.isArray(obj)) {
    return obj;
  }

  const regex = /\.?([^.[\]]+)|\[(\d+)\]/g;
  const output: Record<string, any> = {};

  for (const [key, val] of Object.entries(obj)) {
    let current = output;
    let prop = "";
    let match;

    while ((match = regex.exec(key))) {
      current = current[prop] || (current[prop] = match[2] ? [] : {});
      prop = match[2] || match[1];
    }

    current[prop] = val;
  }

  return output[""] || output;
};

/**
 * Helper function that flattens tree object to path: value structure
 */

export const flatten = (
  tree: Record<string, any>,
  constraints: string[] = []
): Record<string, any> => {
  const recurse = (
    current: any,
    prop = "",
    output: Record<string, any> = {}
  ) => {
    // if current is a leaf
    if (constraints && constraints.includes(prop.replace(/\[\d+\]/, "[]"))) {
      output[prop] = current;
      return output;
    }

    // if current is an array
    if (current instanceof Array) {
      for (const [index, val] of current.entries()) {
        // eslint-disable-next-line no-param-reassign
        output = recurse(val, prop + "[" + index + "]", output);
      }

      if (current.length === 0) {
        output[prop] = [];
      }

      return output;
    }

    // if current is an object
    if (typeof current === "object") {
      if (current === null) {
        output[prop] = null;
      } else if (Object.entries(current).length > 0) {
        for (const [key, val] of Object.entries(current)) {
          // eslint-disable-next-line no-param-reassign
          output = recurse(val, prop ? prop + "." + key : key, output);
        }
      } else {
        output[prop] = {};
      }

      return output;
    }

    output[prop] = current;

    return output;
  };

  return recurse(tree);
};
