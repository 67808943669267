// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createRef, useContext, useState } from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { RadioGroup } from "components/form/elements";
import { trans } from "src/translations";

import { ACTION_VALUES, FIELDS } from "../constants";
import { ModeContext } from "../context";
import { ActionCustomField } from "./ActionCustomField";
import styles from "./ActionField.scss";

const defaultRadios = [
  {
    label: trans.PROMOTION_DETAILS__WATCH_ACTION_LABEL(),
    defaultValue: ACTION_VALUES.WATCH,
    dataTestId: "action-field-watch",
  },
  {
    label: trans.PROMOTION_DETAILS__LISTEN_ACTION_LABEL(),
    defaultValue: ACTION_VALUES.LISTEN,
    dataTestId: "action-field-listen",
  },
  {
    label: trans.PROMOTION_DETAILS__OPEN_ACTION_LABEL(),
    defaultValue: ACTION_VALUES.OPEN,
    dataTestId: "action-field-open",
  },
  {
    label: trans.PROMOTION_DETAILS__MORE_ACTION_LABEL(),
    defaultValue: ACTION_VALUES.MORE,
    dataTestId: "action-field-more",
  },
];

export const ActionField = ({ name }) => {
  const customFieldRef = createRef();
  const { isReadonly } = useContext(ModeContext);
  const [customFieldTouched, setCustomFieldTouched] = useState(false);

  const radios = [
    ...defaultRadios,
    {
      children: (
        <ActionCustomField
          name={name}
          isDisabled={isReadonly}
          reference={customFieldRef}
          touched={customFieldTouched}
          setTouched={setCustomFieldTouched}
        />
      ),
      defaultValue: ACTION_VALUES.CUSTOM,
      className: styles.customFieldRadio,
      dataTestId: "action-field-custom-name",
    },
  ];

  const onActionRadioFocus = (e) => {
    if (e.target.value === ACTION_VALUES.CUSTOM) {
      customFieldRef.current.focus();
    } else {
      setCustomFieldTouched(false);
    }
  };

  return (
    <Field name={`${name}.${FIELDS.ACTION.name}`}>
      {({ field, meta }) => (
        <RadioGroup
          {...FIELDS.ACTION}
          {...field}
          {...meta}
          disabled={isReadonly}
          radios={radios}
          onFocus={onActionRadioFocus}
        />
      )}
    </Field>
  );
};

ActionField.propTypes = {
  name: PropTypes.string.isRequired,
};
