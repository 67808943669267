// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import times from "lodash-es/times";
import * as pdfjs from "pdfjs-dist/webpack";
import PropTypes from "prop-types";

import { whenOneChange } from "utils/component";

import styles from "./pdf.scss";
import { PDFPage } from "./PDFPage";

/**
 * Display PDF document in browser. See [PDFViewer](#pdfviewer).
 */
export class PDF extends Component {
  static propTypes = {
    /** Url of the pdf document */
    url: PropTypes.string,
    className: PropTypes.string,
  };

  state = {
    error: null,
    pages: null,
  };

  shouldComponentUpdate = whenOneChange(["url"], ["error", "pages"]);

  componentDidMount() {
    this.loadDocument();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.loadDocument();
    }
  }

  async loadDocument() {
    const { url } = this.props;
    if (!url) {
      return;
    }
    try {
      const doc = await pdfjs.getDocument(url).promise;
      const pages = times(doc.numPages, (page) => doc.getPage(page + 1));
      this.setState({
        pages: await Promise.all(pages),
      });
    } catch (e) {
      this.setState({ error: e.message });
    }
  }

  render() {
    const { className } = this.props;

    if (this.state.error) {
      return <div>{this.state.error}</div>;
    }

    if (!this.state.pages) {
      return null;
    }

    return (
      <div className={className}>
        <div className={styles.pdf}>
          {this.state.pages.map((page) => (
            <PDFPage key={page.pageNumber} id={page.pageNumber} page={page} />
          ))}
        </div>
      </div>
    );
  }
}
