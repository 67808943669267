// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import {
  CheckAllButton,
  SelectedCountFooter,
  SearchableList,
} from "components/elements";
import { SubTabs, SubTab, TabContent } from "components/navigation";

import styles from "./CountriesList.scss";
import { RegionsRow } from "./RegionsRow";

export class CountriesList extends Component {
  static propTypes = {
    continents: PropTypes.arrayOf(PropTypes.string),
    activeContinent: PropTypes.string,
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ),
    onTabClick: PropTypes.func,
    isChecked: PropTypes.func,
    onCountriesChecked: PropTypes.func,
    selectedCount: PropTypes.number,
  };

  toggleMany = (items) => (value) => {
    const { onCountriesChecked } = this.props;
    onCountriesChecked(
      items.map((e) => e.code),
      value
    );
  };

  toggleOne = (country, value) => this.toggleMany([country])(value);

  renderRow = (country) => {
    const { isChecked } = this.props;
    return (
      <RegionsRow
        key={country.code}
        country={country}
        isChecked={isChecked(country)}
        onChecked={this.toggleOne}
      />
    );
  };

  render() {
    const {
      continents,
      countries,
      activeContinent,
      onTabClick,
      isChecked,
      selectedCount,
    } = this.props;

    return (
      <div className={styles.tabsContainer}>
        <SubTabs>
          {continents.map((continentName) => (
            <SubTab
              key={continentName}
              text={continentName}
              active={continentName === activeContinent}
              onClick={onTabClick}
              scrollIntoView={true}
            />
          ))}
        </SubTabs>

        <TabContent>
          <SearchableList
            dataTestId="regions-modal-countries-list"
            list={countries}
            searchBy="name"
            classNameList={styles.searchableList}
            optionsListElement={(visibleItems) => (
              <CheckAllButton
                fieldName="targets-countries-list"
                onChange={this.toggleMany(visibleItems)}
                checked={visibleItems.every(isChecked)}
                disabled={visibleItems.length === 0}
                className={styles.checkAllButton}
                isRtl={true}
              />
            )}
          >
            {this.renderRow}
          </SearchableList>
          <SelectedCountFooter selectedCount={selectedCount} />
        </TabContent>
      </div>
    );
  }
}
