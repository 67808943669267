// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Modal } from "components/layout";

import styles from "./WarningModal.scss";

/**
 * Adds warning styles into Modal component.
 */
export const WarningModal = ({
  title,
  titleClassName,
  children,
  icon,
  ...props
}) => (
  <Modal
    {...props}
    title={
      <span className={titleClassName}>
        <GrabsonIcon className={styles.icon} name={icon} size="xl" /> {title}
      </span>
    }
  >
    {children}
  </Modal>
);

WarningModal.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  title: PropTypes.node.isRequired,
  titleClassName: PropTypes.string,
};

WarningModal.defaultProps = {
  icon: "alert-circle",
};
