// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable no-magic-numbers */

export const CHANGELOG_TYPES = {
  INITIAL: "INITIAL",
  REWRITE: "REWRITE",
  BUGFIXES: "BUGFIXES",
  NOCODECHANGES: "NOCODECHANGES",
  OTHER: "OTHER",
};

export const UPGRADE_TO_PREMIUM_ORG_MAIL = "Vewd_cloud-bizdev@xperi.com";

export const SCREENSHOT = {
  MIN_WIDTH: 1280,
  MIN_HEIGHT: 720,
  MAX_SIZE: 1024 * 1024,
  ASPECT_RATIO: "16:9",
};

export const LOGO = {
  MIN_WIDTH: 160,
  MIN_HEIGHT: 160,
  MAX_WIDTH: 300,
  MAX_HEIGHT: 200,
};

export const ICON_SMALL = {
  MIN_WIDTH: 128,
  MIN_HEIGHT: 128,
  MAX_SIZE: 500 * 1024,
  ASPECT_RATIO: "1:1",
};

export const ICON_BIG = {
  MIN_WIDTH: 512,
  MIN_HEIGHT: 512,
  MAX_SIZE: 1024 * 1024,
  ASPECT_RATIO: "1:1",
};

export const THUMBNAIL = {
  MIN_WIDTH: 480,
  MIN_HEIGHT: 270,
  MAX_SIZE: 1024 * 1024,
  ASPECT_RATIO: "16:9",
};

export const BILLBOARD = {
  MIN_WIDTH: 1800,
  MIN_HEIGHT: 450,
  MAX_SIZE: 1024 * 1024,
  ASPECT_RATIO: "4:1",
};

export const APP_TYPES = {
  HTML: "html",
  HBBTV: "hbbtv",
  NATIVE: "native",
  SNAP: "snap",
};

export const DISABLED_APPLICATION_URL_APP_TYPES = [APP_TYPES.SNAP];

export const GENERAL_SECTION_APP_TYPES = [
  APP_TYPES.HTML,
  APP_TYPES.SNAP,
  APP_TYPES.HBBTV,
];

export const APP_PROFILES_SECTION_APP_TYPES = [APP_TYPES.NATIVE];

export const DISTRIBUTION_TYPES = {
  VEWD_CATALOGUE: "vewd-catalogue",
  VEWD_CERTIFICATION_PROGRAM: "vewd-certification-program",
  EXAMINE: "examine",
};

// Format: YY-MM
export const AGREEMENT_VERSION = "24-02";
