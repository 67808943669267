// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { SearchableListPanel } from "components/elements";
import { Radio } from "components/form/elements";

import {
  packagePropTypes,
  versionPropTypes,
  currentVersionPropTypes,
} from "./propTypes";
import styles from "./SelectSoftwarePackageModal.scss";

export class SoftwarePackageVersionRow extends Component {
  static propTypes = {
    idx: PropTypes.number.isRequired,
    version: versionPropTypes,
    softwarePackage: packagePropTypes,
    setCurrentVersion: PropTypes.func.isRequired,
    currentVersion: currentVersionPropTypes,
  };

  handleChecked = () => {
    const { softwarePackage, version, setCurrentVersion } = this.props;

    setCurrentVersion({
      id: version.id,
      packageName: softwarePackage.name,
      versionName: version.name,
    });
  };

  render() {
    const { version, currentVersion, idx } = this.props;
    const name = `software-package-version-${idx}`;

    return (
      <SearchableListPanel>
        <Radio
          name={name}
          dataTestId={name}
          value={currentVersion ? currentVersion.id : ""}
          defaultValue={version.id}
          onChange={this.handleChecked}
          className={styles.versionRowRadio}
        >
          <span className={styles.versionRowName}>{version.name}</span>
        </Radio>
      </SearchableListPanel>
    );
  }
}
