// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/**
 * This function is promisified version of setTimeout.
 *
 * @example
 * async someFunction(){
 *   await timeout(1000);
 *   // do something after delay
 * }
 */
export const timeout = (delayMs: number): Promise<NodeJS.Timeout> => {
  return new Promise<NodeJS.Timeout>((resolve) => {
    const timerId = setTimeout(() => {
      resolve(timerId);
    }, delayMs);
  });
};
