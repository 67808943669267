// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Overlay } from "components/layout";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import { Buttons } from "./Buttons";
import { Item } from "./Item";
import styles from "./menu.scss";

export class Menu extends Component {
  static propTypes = {
    organizations: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    activeOrganization: PropTypes.object.isRequired,
    isChangingActiveOrganization: PropTypes.bool.isRequired,
    changeActiveOrganization: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "account-menu-popup",
  };

  handleChangeOrganization = (organizationId) => async () => {
    const { changeActiveOrganization } = this.props;
    await changeActiveOrganization(organizationId);
  };

  render() {
    const {
      organizations,
      currentUser,
      activeOrganization,
      isChangingActiveOrganization,
      dataTestId,
    } = this.props;
    return (
      <Fragment>
        {activeOrganization ? (
          <Overlay active={isChangingActiveOrganization}>
            <ul data-test-id={dataTestId} className={styles.list}>
              {Object.keys(organizations).map((organizationId) => (
                <li
                  data-test-id={`${dataTestId}-item`}
                  key={organizationId}
                  className={classes(styles.listItem, {
                    [styles.activeOrganization]:
                      organizationId === activeOrganization.id,
                  })}
                  onClick={this.handleChangeOrganization(organizationId)}
                >
                  <Item
                    organizationImg={organizations[organizationId].img}
                    userImg={currentUser.img}
                    text={organizations[organizationId].name}
                  />
                </li>
              ))}
            </ul>
          </Overlay>
        ) : (
          <div className={styles.noOrganization}>
            <p className={styles.noOrganizationInfo}>
              {trans.ACCOUNT_MENU__NOT_ASSIGNED_TO_ORGANIZATION()}
            </p>
            <Button
              to="/organization/create"
              className={styles.createOrganization}
              type="green"
            >
              {trans.ACCOUNT_MENU__CREATE_ORGANIZATION_BUTTON()}
            </Button>
          </div>
        )}
        <Buttons currentUserProfileId={currentUser.id} />
      </Fragment>
    );
  }
}
