// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";

import styles from "./staticPage.scss";

/**
 * Page that contains only text or non interactive elements.
 */
export class StaticPage extends Component {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
  };

  render() {
    return (
      <div className={styles.staticPageWrapper}>
        <PageHeader>{this.props.title}</PageHeader>
        <PageContent className={styles.staticPage}>
          {this.props.children}
        </PageContent>
      </div>
    );
  }
}
