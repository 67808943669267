// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { DEF_LANG } from "../langs/def_lang";
import en from "../langs/en";
import { DEFAULT_LANG_KEY } from "./constants";

export const getAllTranslations = (): {
  [DEFAULT_LANG_KEY]: typeof DEF_LANG;
  en: typeof en;
} => ({
  [DEFAULT_LANG_KEY]: DEF_LANG,
  en,
});
