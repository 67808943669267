// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as Yup from "yup";

import { trans } from "src/translations";
import { colorRegex, resolutionRegex } from "utils/validation";

import { APP_TYPE } from "../../Applications/AppDetails/constants";
import {
  APP_ID_FORM_FIELDS,
  MANIFEST_FIELDS,
} from "../CreateEditManifest/components/constants";

export const FIELD_DESCRIPTION = {
  type: trans.APP_DETAILS_MANIFEST__FIELD_TYPE,
  resolution: trans.APP_DETAILS_MANIFEST__FIELD_RESOLUTION,
  transparent: trans.APP_DETAILS_MANIFEST__FIELD_TRANSPARENT,
  displayName: trans.APP_DETAILS_MANIFEST__FIELD_DISPLAY_NAME,
  displayIcon: trans.APP_DETAILS_MANIFEST__FIELD_DISPLAY_ICON,
  defaultBackgroundColor: trans.APP_DETAILS_MANIFEST__FIELD_BACKGROUND,
  userAgent: trans.APP_DETAILS_MANIFEST__FIELD_USER_AGENT,
  allowScriptsToCloseWindows: trans.APP_DETAILS_MANIFEST__FIELD_CLOSE_WINDOW,
  spatialNavigationEnabled: trans.APP_DETAILS_MANIFEST__FIELD_SPATNAV,
  inputMethod: trans.APP_DETAILS_MANIFEST__FIELD_INPUT_METHOD,
  historyOnBack: trans.APP_DETAILS_MANIFEST__FIELD_HISTORY_BACK,
  blockMixedActiveContent:
    trans.APP_DETAILS_MANIFEST__FIELD_MIXED_ACTIVE_CONTENT,
  xhrOriginCheckEnabled: trans.APP_DETAILS_MANIFEST__FIELD_CORS,
  blockMixedXhrContent: trans.APP_DETAILS_MANIFEST__FIELD_MIXED_XHR_CONTENT,
  blockMixedFetchContent: trans.APP_DETAILS_MANIFEST__FIELD_MIXED_FETCH_CONTENT,
  noCorsHeaderGuard: trans.APP_DETAILS_MANIFEST__FIELD_CORS_GUARD,
  custom: trans.APP_DETAILS_MANIFEST__FIELD_CUSTOM,
  zoom: trans.APP_DETAILS_MANIFEST__FIELD_ZOOM,
};

const getSelectOption = (name, label, value) => ({
  label,
  name,
  value,
});

// Content of this array is added later below
// It contains list of fields that need to be converted between form & request
export const BOOL_FIELDS_CONVERSION = [];

const getBoolSelectField = (fieldName) => {
  BOOL_FIELDS_CONVERSION.push(fieldName);
  return {
    name: fieldName,
    type: "select",
    values: [
      getSelectOption(fieldName, trans.APP_DETAILS_MANIFEST__FIELD_EMPTY(), ""),
      getSelectOption(
        fieldName,
        trans.APP_DETAILS_MANIFEST__FIELD_BOOL_TRUE(),
        "true"
      ),
      getSelectOption(
        fieldName,
        trans.APP_DETAILS_MANIFEST__FIELD_BOOL_FALSE(),
        "false"
      ),
    ],
  };
};

const virtualKeys = [
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_PLACEHOLDER(),
    value: "",
    disabled: true,
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_LEFT(),
    value: "LEFT",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_RIGHT(),
    value: "RIGHT",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_UP(),
    value: "UP",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_DOWN(),
    value: "DOWN",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_ENTER(),
    value: "ENTER",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_BACK(),
    value: "BACK",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_BLUE(),
    value: "BLUE",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_RED(),
    value: "RED",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_GREEN(),
    value: "GREEN",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_YELLOW(),
    value: "YELLOW",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_MENU(),
    value: "MENU",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_0(),
    value: "0",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_1(),
    value: "1",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_2(),
    value: "2",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_3(),
    value: "3",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_4(),
    value: "4",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_5(),
    value: "5",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_6(),
    value: "6",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_7(),
    value: "7",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_8(),
    value: "8",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_9(),
    value: "9",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_PLAY(),
    value: "PLAY",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_PAUSE(),
    value: "PAUSE",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_STOP(),
    value: "STOP",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_TRACK_NEXT(),
    value: "TRACK_NEXT",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_TRACK_PREV(),
    value: "TRACK_PREV",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_FAST_FWD(),
    value: "FAST_FWD",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_REWIND(),
    value: "REWIND",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_SUBTITLE(),
    value: "SUBTITLE",
  },
  {
    label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_INFO(),
    value: "INFO",
  },
];

export const getKeyValues = (name) =>
  virtualKeys.map(({ ...props }) => ({
    name,
    ...props,
  }));

export const FIELDS = {
  allowScriptsToCloseWindows: getBoolSelectField("allowScriptsToCloseWindows"),
  blockMixedActiveContent: getBoolSelectField("blockMixedActiveContent"),
  blockMixedFetchContent: getBoolSelectField("blockMixedFetchContent"),
  blockMixedXhrContent: getBoolSelectField("blockMixedXhrContent"),
  custom: {
    name: "custom",
    type: "array",
  },
  defaultBackgroundColor: {
    name: "defaultBackgroundColor",
    type: "color",
  },
  displayName: { name: "displayName", type: "text" },
  displayIcon: {
    name: "displayIcon",
    type: "image",
    meta: { maxSize: 1024 * 1024 },
  },
  historyOnBack: getBoolSelectField("historyOnBack"),
  inputMethod: {
    name: "inputMethod",
    type: "select",
    values: [
      getSelectOption(
        "inputMethod",
        trans.APP_DETAILS_MANIFEST__FIELD_EMPTY(),
        ""
      ),
      getSelectOption(
        "inputMethod",
        trans.APP_DETAILS_MANIFEST__FIELD_SPATNAV_VIRTUAL(),
        "virtual_keyboard"
      ),
      getSelectOption("inputMethod", "Multitap", "multitap"),
      getSelectOption(
        "inputMethod",
        trans.APP_DETAILS_MANIFEST__FIELD_SPATNAV_OMI(),
        "omi_events"
      ),
    ],
  },
  keyRemapping: {
    name: "keyRemapping",
    type: "array",
  },
  noCorsHeaderGuard: getBoolSelectField("noCorsHeaderGuard"),
  resolution: {
    name: "resolution",
    type: "resolution",
  },
  spatialNavigationEnabled: getBoolSelectField("spatialNavigationEnabled"),
  type: {
    name: "type",
    type: "select",
    values: [
      getSelectOption("type", trans.APP_DETAILS_MANIFEST__FIELD_EMPTY(), ""),
      getSelectOption(
        "type",
        trans.APP_DETAILS_MANIFEST__FIELD_TYPE_CSP(),
        "csp"
      ),
      getSelectOption(
        "type",
        trans.APP_DETAILS_MANIFEST__FIELD_TYPE_STORE(),
        "store"
      ),
      getSelectOption(
        "type",
        trans.APP_DETAILS_MANIFEST__FIELD_TYPE_HBBTV(),
        "hbbtv"
      ),
    ],
  },
  transparent: getBoolSelectField("transparent"),
  userAgent: { name: "userAgent", type: "text" },
  userScript: { name: "userScript", type: "javascript" },
  xhrOriginCheckEnabled: getBoolSelectField("xhrOriginCheckEnabled"),
  zoom: { name: "zoom", type: "number" },
};

export const ENCODED_FIELDS_KEYS = [FIELDS.userScript.name];

export const IMAGE_FIELD_KEYS = [FIELDS.displayIcon.name];

export const TABS_CONFIG = [
  {
    id: "generic",
    header: trans.APP_DETAILS_MANIFEST__GENERIC_HEADER,
    text: trans.APP_DETAILS_MANIFEST__GENERIC_TAB,
    fields: [
      FIELDS.type,
      FIELDS.displayName,
      FIELDS.displayIcon,
      FIELDS.resolution,
      FIELDS.zoom,
      FIELDS.transparent,
      FIELDS.defaultBackgroundColor,
      FIELDS.userAgent,
      FIELDS.allowScriptsToCloseWindows,
    ],
  },
  {
    id: "navigation",
    header: trans.APP_DETAILS_MANIFEST__NAVIGATION_HEADER,
    text: trans.APP_DETAILS_MANIFEST__NAVIGATION_TAB,
    fields: [
      FIELDS.spatialNavigationEnabled,
      FIELDS.inputMethod,
      FIELDS.historyOnBack,
      FIELDS.keyRemapping,
    ],
  },
  {
    id: "security",
    header: trans.APP_DETAILS_MANIFEST__SECURITY_HEADER,
    text: trans.APP_DETAILS_MANIFEST__SECURITY_TAB,
    description: trans.APP_DETAILS_MANIFEST__SECURITY_DESCRIPTION,
    fields: [
      FIELDS.blockMixedActiveContent,
      FIELDS.blockMixedFetchContent,
      FIELDS.blockMixedXhrContent,
      FIELDS.xhrOriginCheckEnabled,
      FIELDS.noCorsHeaderGuard,
    ],
  },
  {
    id: "custom",
    header: trans.APP_DETAILS_MANIFEST__CUSTOM_HEADER,
    text: trans.APP_DETAILS_MANIFEST__CUSTOM_TAB,
    fields: [FIELDS.custom],
  },
  {
    id: "userscript",
    header: trans.APP_DETAILS_MANIFEST__SCRIPT_HEADER,
    text: trans.APP_DETAILS_MANIFEST__SCRIPT_TAB,
    description: trans.APP_DETAILS_MANIFEST__SCRIPT_DESCRIPTION,
    fields: [FIELDS.userScript],
  },
];

export const getTabsConfig = (appType) => {
  if (appType === APP_TYPE.NATIVE) {
    return TABS_CONFIG.filter((tab) => tab.id === "custom");
  }
  return TABS_CONFIG;
};

export const VALIDATION_SCHEMA = Yup.object()
  .shape({
    [APP_ID_FORM_FIELDS.applicationId]: Yup.string().required(
      trans.ERROR__FIELD_REQUIRED()
    ),
    [MANIFEST_FIELDS.resolution]: Yup.string().when({
      is: (exists) => Boolean(exists),
      then: (schema) =>
        schema.test(
          "resolution",
          trans.APP_DETAILS_MANIFEST__VALIDATION_RESOLUTION_ERROR(),
          (value) => resolutionRegex.test(value)
        ),
    }),
    [MANIFEST_FIELDS.defaultBackgroundColor]: Yup.string().when({
      is: (exists) => Boolean(exists),
      then: (schema) =>
        schema.test(
          "background-color",
          trans.APP_DETAILS_MANIFEST__VALIDATION_COLOR_ERROR(),
          (value) => colorRegex.test(value)
        ),
    }),
    [MANIFEST_FIELDS.keyRemapping]: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required(trans.ERROR__FIELD_REQUIRED()),
        value: Yup.mixed().required(trans.ERROR__FIELD_REQUIRED()),
      })
    ),
    [MANIFEST_FIELDS.custom]: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required(trans.ERROR__FIELD_REQUIRED()),
        value: Yup.mixed().required(trans.ERROR__FIELD_REQUIRED()),
        description: Yup.string().required(trans.ERROR__FIELD_REQUIRED()),
      })
    ),
  })
  .test("at-least-one-field-in-manifest-attributes", "Error", (object, ctx) => {
    const { applicationId, internalApp, ...restValues } = object;

    const otherFieldsCount = Object.values(restValues).filter(
      (value) =>
        value !== "" &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0)
    ).length;

    if (otherFieldsCount === 0) {
      return ctx.createError({
        path: MANIFEST_FIELDS.manifestError,
        message: "Missing manifest payload",
      });
    }
    return true;
  });
