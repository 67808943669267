// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext, useState } from "react";

import PropTypes from "prop-types";

import {
  ManifestEditForm,
  FormComponent,
  adaptDataToForm,
  ManifestDataContext,
  ManifestEditButtons,
} from "pages/AppManifests/_shared";
import { getTabsConfig } from "pages/AppManifests/_shared/constants";

export const ManifestEditTab = ({ tabId }) => {
  const { appId, appType, results } = useContext(ManifestDataContext);
  // Errors banner should only be visible after "submit" or when request fails
  // State is reset when:
  // - form is reset
  // - validation pass (errors are fixed)
  // - form is successfully submitted
  const [errorsBanner, setErrorsBanner] = useState(false);

  const tabData = getTabsConfig(appType).find((tab) => tab.id === tabId);

  return (
    <ManifestEditForm
      initialValues={{
        ...adaptDataToForm(results?.manifest ?? {}),
        applicationId: appId,
      }}
      render={({ errors }) => (
        <FormComponent
          tabId={tabId}
          tabData={tabData}
          renderButton={(position) => (
            <ManifestEditButtons
              setErrorsBanner={setErrorsBanner}
              position={position ?? "top"}
            />
          )}
          errors={errors}
          errorsBanner={errorsBanner}
        />
      )}
      manifestData={results}
      setErrorsBanner={setErrorsBanner}
    />
  );
};

ManifestEditTab.propTypes = {
  tabId: PropTypes.string.isRequired,
};
