// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";
import semver from "semver";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withRouter } from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";

import { getSoftwarePackageQuery } from "./gql/getSoftwarePackageQuery";
import { SoftwarePackageDetailsRend } from "./SoftwarePackageDetailsRend";

@restrictedArea(() => ({
  allowed: [ROLES.softwareUpdates.updateManager],
}))
@withActiveOrganization
@withRouter
@withQuery({ name: "softwarePackage", query: getSoftwarePackageQuery })
export class SoftwarePackageDetailsData extends PureComponent {
  static propTypes = {
    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,

    // from @withRouter

    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired,
    }),

    // from @withQuery
    softwarePackageQuery: PropTypes.func,
    softwarePackageQueryStatus: queryResultType,
  };

  componentDidMount() {
    this.fetchSoftwarePackage();
  }

  componentDidUpdate(prevProps) {
    const prevParams = prevProps.params;
    const organizationId = this.props.activeOrganization.id;
    const { id } = this.props.params;

    if (
      prevProps.activeOrganization.id !== organizationId ||
      prevParams.id !== id
    ) {
      this.fetchSoftwarePackage();
    }
  }

  fetchSoftwarePackage = () => {
    const { id } = this.props.params;
    const { softwarePackageQuery } = this.props;
    softwarePackageQuery({ variables: { id } });
  };

  getPackageData = () => {
    const { loading, data, error } = this.props.softwarePackageQueryStatus;

    return {
      loading,
      error: error && getGqlErrorMessage(error),
      name: get(data, "softwarePackage.softwarePackage.name", ""),
      versions: get(data, "softwarePackage.softwarePackage.versions", [])
        .map(({ id, version }) => ({ id, version }))
        .sort(({ version }, { version: version2 }) =>
          semver.rcompare(version, version2)
        ),
    };
  };

  render() {
    const { id, version } = this.props.params;
    const { name, versions, loading, error } = this.getPackageData();

    return (
      <SoftwarePackageDetailsRend
        packageId={id}
        activeVersionId={version}
        name={name}
        versions={versions}
        error={error}
        loading={loading}
      />
    );
  }
}
