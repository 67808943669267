// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { SearchBar } from "components/elements";
import { Section } from "components/layout";
import { trans } from "src/translations";
import { CountriesPropType } from "utils/continents";

import {
  DeviceInventoryTable,
  DEVICE_PROP_TYPES,
} from "../_shared/DeviceInventoryTable";
import { fromDevicePropertyGetCountry } from "../DeviceDetails/components";
import { DEVICE_INVENTORY_ALERTS_PATH } from "./DeviceInventoryRend";

export const DeviceInventoryWithAlertsTabContent = ({
  handleFiltersChange,
  searchValue,
  path,
  results,
  loading,
  count,
  error,
  fetchDevicesWithActiveAlertCount,
  countries,
}) => {
  return (
    <Fragment>
      <Section>
        <SearchBar
          value={searchValue}
          onSearch={handleFiltersChange}
          placeholder={trans.SEARCH()}
          dataTestId={"device-inventory-alerts-search"}
        />
      </Section>
      <Section>
        <DeviceInventoryTable
          results={results}
          loading={loading}
          count={count}
          error={error}
          hasBulkDismissAlerts={path === DEVICE_INVENTORY_ALERTS_PATH}
          fetchDevicesWithActiveAlertCount={fetchDevicesWithActiveAlertCount}
          getCountry={fromDevicePropertyGetCountry("country", countries)}
        />
      </Section>
    </Fragment>
  );
};

DeviceInventoryWithAlertsTabContent.propTypes = {
  searchValue: PropTypes.string,
  path: PropTypes.string,
  handleFiltersChange: PropTypes.func,
  results: PropTypes.arrayOf(DEVICE_PROP_TYPES).isRequired,
  loading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  error: PropTypes.string,
  fetchDevicesWithActiveAlertCount: PropTypes.func.isRequired,
  countries: CountriesPropType,
};
