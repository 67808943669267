// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createAbortError } from "utils/decorators/withAbort";

const HTTP_METHOD = "post";
const DONE_STATE = 4;
const SIGNAL_EVENT_ABORT = "abort";

// eslint-disable-next-line no-magic-numbers
const isRequestOk = (request) => request.status >= 200 && request.status < 400;

const createResponse = (ok, request) => ({
  ok,
  text: request.responseText,
  contentType: request.getResponseHeader("content-type"),
});

/**
 * Fetch does not have `onProgress`, forced to use XMLHttpRequest instead.
 */
export const sendFileUploadRequest = (url, { body, signal, onProgress }) => {
  return new Promise((resolve, reject) => {
    if (signal && signal.aborted) {
      reject(createAbortError());
      return;
    }

    const request = new XMLHttpRequest();

    const onAbort = () => {
      reject(createAbortError());
    };

    const onLoad = () => {
      if (isRequestOk(request)) {
        return resolve(createResponse(true, request));
      }

      return reject(createResponse(false, request));
    };

    const forceAbort = () => {
      request.abort();
    };

    request.addEventListener("load", onLoad);
    request.addEventListener("abort", onAbort);
    request.addEventListener("error", reject);
    request.addEventListener("timeout", reject);
    request.upload.addEventListener("progress", onProgress);

    if (signal) {
      signal.addEventListener(SIGNAL_EVENT_ABORT, forceAbort);

      request.onreadystatechange = () => {
        if (request.readyState === DONE_STATE) {
          signal.removeEventListener(SIGNAL_EVENT_ABORT, forceAbort);
        }
      };
    }

    request.open(HTTP_METHOD, url, true);
    request.send(body || null);
  });
};
