// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import times from "lodash-es/times";
import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./TabIndicator.scss";

export class TabIndicator extends Component {
  static propTypes = {
    tabsCount: PropTypes.number.isRequired,
    activeTab: PropTypes.number.isRequired,
  };

  render() {
    const { tabsCount, activeTab } = this.props;

    return (
      <ul className={styles.container}>
        {times(tabsCount, (i) => (
          <li
            key={i}
            className={classes(styles.item, {
              [styles.active]: i === activeTab,
            })}
          />
        ))}
      </ul>
    );
  }
}
