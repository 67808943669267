// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import sortBy from "lodash-es/sortBy";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";
import { withCountries } from "utils/continents";
import { withRouter } from "utils/decorators/withRouter";
import { parseGqlError } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";

import { TABS_IDS, TAB_ID_DEFAULT } from "./constants";
import { DeviceDetailsRend } from "./DeviceDetailsRend";
import {
  getDeviceQuery,
  getDeviceUpdateJobFragmentQuery,
} from "./gql/getDeviceQuery";
import {
  INSTALLED_PACKAGES_SUBTAB_ID,
  UPDATE_SUBTAB_ID,
} from "./tabs/SoftwareTab";

@restrictedArea(() => ({
  allowed: [ROLES.deviceInventory.deviceManager],
}))
@withRouter
@withActiveOrganization
@withQuery({
  name: "device",
  query: getDeviceQuery,
})
@withQuery({
  name: "deviceUpdateJob",
  query: getDeviceUpdateJobFragmentQuery,
})
@withCountries
export class DeviceDetailsData extends PureComponent {
  static propTypes = {
    // from @withRouter

    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tabId: PropTypes.string,
      subTabId: PropTypes.string,
    }).isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withQuery
    deviceQuery: PropTypes.func,
    deviceQueryStatus: queryResultType,
    deviceUpdateJobQuery: PropTypes.func,
    deviceUpdateJobQueryStatus: queryResultType,

    // from @withCountries
    countriesLoading: PropTypes.bool.isRequired,
    countriesError: PropTypes.object,
    allCountries: PropTypes.array.isRequired,
  };

  state = {
    deviceUpdateJobRefreshDate: new Date(),
  };

  componentDidMount() {
    this.fetchDevice();
  }

  componentDidUpdate(prevProps) {
    const changedOrganization =
      this.props.activeOrganization.id !== prevProps.activeOrganization.id;
    if (changedOrganization) {
      this.fetchDevice();
    }
  }

  fetchDevice = () => {
    const { deviceQuery } = this.props;

    deviceQuery({
      variables: {
        id: this.getDeviceId(),
      },
    });
  };

  fetchCurrentUpdateJob = async () => {
    const { deviceUpdateJobQuery } = this.props;

    await deviceUpdateJobQuery({ variables: { id: this.getDeviceId() } });
    this.setState({
      deviceUpdateJobRefreshDate: new Date(),
    });
  };

  getDeviceId = () => this.props.params.id;

  getTabId = () => {
    const tabId = this.props.params.tabId || "";
    const isValidTabId = TABS_IDS.some((id) => id === tabId);
    return isValidTabId ? tabId : TAB_ID_DEFAULT;
  };

  getSubTabId = () => {
    const subTabId = this.props.params.subTabId;
    if (
      subTabId === INSTALLED_PACKAGES_SUBTAB_ID ||
      subTabId === UPDATE_SUBTAB_ID
    ) {
      return subTabId;
    }
    // default subtab for software tab
    return INSTALLED_PACKAGES_SUBTAB_ID;
  };

  adaptDevice = (device) => {
    if (device == null) {
      return null;
    }

    return {
      ...device,
      managerAttributes: sortBy(device.managerAttributes, [(attr) => attr.key]),
      activationDate: new Date(device.activationDate),
      lastActivityDate: new Date(device.lastActivityDate),
      pdids: {
        ...device.pdids,
        items: (device.pdids.items.slice() || []).sort((a, b) =>
          b.lastUsedAt.localeCompare(a.lastUsedAt)
        ),
      },
      currentUpdateJob: device.currentUpdateJob,
    };
  };

  getDeviceData = () => {
    const { data, error, loading } = this.props.deviceQueryStatus;
    return {
      error:
        error &&
        (parseGqlError(error, { allowRawApolloMessages: true })?.message ??
          trans.DEFAULT_REQUEST_ERROR_MESSAGE()),
      loading,
      device: this.adaptDevice(get(data, "device.device")),
    };
  };

  getDeviceUpdateJobData = () => {
    const { data, error, loading } = this.props.deviceUpdateJobQueryStatus;
    return {
      error:
        error &&
        (parseGqlError(error, { allowRawApolloMessages: true })?.message ??
          trans.DEFAULT_REQUEST_ERROR_MESSAGE()),
      loading,
      currentUpdateJob: get(data, "currentUpdateJob", {
        name: null,
        lastActivityDate: null,
        status: null,
        updateJobId: null,
      }),
    };
  };

  render() {
    const { error, loading, device } = this.getDeviceData();
    const { error: updateJobError, loading: updateJobLoading } =
      this.getDeviceUpdateJobData();
    const { countriesError, countriesLoading, allCountries } = this.props;

    return (
      <DeviceDetailsRend
        device={device}
        countries={{
          error: Boolean(countriesError),
          loading: countriesLoading,
          allCountries,
        }}
        loading={loading}
        error={error}
        tabId={this.getTabId()}
        subTabId={this.getSubTabId()}
        fetchCurrentUpdateJob={this.fetchCurrentUpdateJob}
        updateJobError={updateJobError}
        updateJobLoading={updateJobLoading}
        deviceUpdateJobRefreshDate={this.state.deviceUpdateJobRefreshDate}
      />
    );
  }
}
