// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { restrictedArea, ROLES } from "containers/Permissions";
import { api } from "containers/Request";
import { withRouter } from "utils/decorators";

import { APPS_COUNT_NONE } from "./constants";
import { PromotionTypeForm } from "./PromotionTypeForm";

const DEFAULT_INITIAL_VALUES = {
  name: "",
  slug: "",
  autoSlug: true,
  appsCount: APPS_COUNT_NONE,
  softwareAssets: [],
  icon: false,
  thumbnail: false,
  title: false,
  description: false,
  background: false,
  json: false,
  action: false,
  contentItems: false,
  distributors: [],
};

@withRouter
@restrictedArea(() => {
  return {
    allowed: [ROLES.administrator.promotionAdmin],
  };
})
@connect(null, (dispatch) => ({
  getPromotionType: (id) =>
    dispatch(api.getPromotionType.action({ params: { id } })),
}))
export class PromotionTypeFormData extends Component {
  static propTypes = {
    // from @connect
    getPromotionType: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  state = {
    isLoading: false,
    initialValues: DEFAULT_INITIAL_VALUES,
    error: "",
  };

  componentDidMount() {
    this.updateInitialValues();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.updateInitialValues();
    }
  }

  getPromotionTypeId() {
    return this.props.params.id;
  }

  isEdit() {
    return typeof this.getPromotionTypeId() !== "undefined";
  }

  updateInitialValues = async () => {
    const { getPromotionType } = this.props;

    this.setState({ isLoading: true, error: "" });

    if (this.isEdit()) {
      const id = this.getPromotionTypeId();
      const promotionTypeRequest = await getPromotionType(id);

      if (promotionTypeRequest.error) {
        this.setState({
          isLoading: false,
          error: promotionTypeRequest.error,
        });
        return;
      }

      const promotionType = promotionTypeRequest.result.results;

      this.setState({
        isLoading: false,
        initialValues: {
          name: promotionType.name,
          slug: promotionType.slug,
          autoSlug: promotionType.slug_autogenerated,
          appsCount: promotionType.number_of_apps.toString(),
          softwareAssets: promotionType.software_assets.map((sa) => sa.id),
          distributors: promotionType.distributors.map(
            (distributor) => distributor.id
          ),
          icon: promotionType.custom_icon,
          thumbnail: promotionType.custom_thumbnail,
          title: promotionType.custom_title,
          description: promotionType.custom_description,
          background: promotionType.additional_background_image,
          json: promotionType.additional_metadata,
          action: promotionType.additional_action,
          promotionsCount: promotionType.promotions_count,
          contentItems: promotionType.additional_application_assets,
        },
      });

      return;
    }

    this.setState({ isLoading: false, initialValues: DEFAULT_INITIAL_VALUES });
  };

  render() {
    const { isLoading, error, initialValues } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message || error}</Info>;
    }

    return (
      <PromotionTypeForm isEdit={this.isEdit()} initialValues={initialValues} />
    );
  }
}
