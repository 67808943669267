// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { withRouter, withSearch } from "utils/decorators";

import { FamilyFormModal } from "../_shared/DeviceFamilyForm/FamilyFormModal";
import { withToggleModel } from "../_shared/DeviceList/withToggleModel";
import { ORGANIZATION_NONE, DEVICE_FAMILY_NONE } from "../constants";
import { ITEMS_PER_PAGE } from "./DevicesData";
import { DevicesTableBody } from "./DevicesTableBody";

@withRouter
@withSearch
@withToggleModel()
export class DevicesRend extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool.isRequired,
    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // props from parent data component
    results: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    refreshData: PropTypes.func,
    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withToggleModel
    toggleModel: PropTypes.func.isRequired,
    toggleModelInProgress: PropTypes.bool.isRequired,
  };

  static tabsConfig = [
    {
      dataTestId: "device-models-list",
      columns: [{ label: trans.DEVICES__NAME_COLUMN_NAME() }, { label: "" }],
    },
  ];

  state = {
    isModalOpen: false,
    ownerId: ORGANIZATION_NONE,
    deviceFamilyId: DEVICE_FAMILY_NONE,
  };

  handleDeviceFamilyRequestSuccess = (deviceFamilyId, isEdit) => {
    const { navigate, location, refreshData } = this.props;

    this.closeDeviceFamilyModal();

    const message = isEdit
      ? trans.CHANGES_SAVE_SUCCESS()
      : trans.DEVICES__DEVICE_FAMILY_ADDED();

    navigate(
      {
        ...location,
      },
      {
        state: { message: message },
      }
    );

    refreshData();
  };

  handleToggleDeviceModel = async (modelId, enable) => {
    const { toggleModel } = this.props;
    return toggleModel(modelId, enable);
  };

  handleAddDeviceFamily = (ownerId) => () => {
    this.setState({ isModalOpen: true, ownerId });
  };

  handleEditDeviceFamily = (ownerId, deviceFamilyId) => () => {
    this.setState({
      isModalOpen: true,
      ownerId,
      deviceFamilyId,
    });
  };

  closeDeviceFamilyModal = () => {
    this.setState({
      isModalOpen: false,
      ownerId: ORGANIZATION_NONE,
      deviceFamilyId: DEVICE_FAMILY_NONE,
    });
  };

  tableBody = (data) => {
    const { toggleModelInProgress, forAdmin } = this.props;

    return (
      <DevicesTableBody
        forAdmin={forAdmin}
        data={data}
        onToggleDeviceModel={this.handleToggleDeviceModel}
        onAddDeviceFamily={this.handleAddDeviceFamily}
        onEditDeviceFamily={this.handleEditDeviceFamily}
        toggleDeviceModelInProgress={toggleModelInProgress}
      />
    );
  };

  render() {
    const { results, count, error, loading, searchValue, changeSearch } =
      this.props;

    const locationMessage = get(this.props, "location.state.message");

    return (
      <div>
        <FamilyFormModal
          isModalOpen={this.state.isModalOpen}
          ownerId={this.state.ownerId}
          deviceFamilyId={this.state.deviceFamilyId}
          onRequestSuccess={this.handleDeviceFamilyRequestSuccess}
          closeModal={this.closeDeviceFamilyModal}
          forAdmin={true}
        />
        <Section header={trans.DEVICES__DEVICE_LIST_HEADER()}>
          <Section>
            <SearchBar
              onSearch={changeSearch}
              value={searchValue}
              placeholder={trans.DEVICES__DEVICE_LIST_SEARCH_PLACEHOLDER()}
              actionHint={trans.DEVICES__DEVICE_LIST_SEARCH_HINT()}
            />
          </Section>
          <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
          <Section>
            <TabbedTable
              tabsConfig={DevicesRend.tabsConfig}
              renderTableBody={this.tableBody}
              data={{ results, count, loading, error }}
              rowsPerPage={ITEMS_PER_PAGE}
            />
          </Section>
        </Section>
      </div>
    );
  }
}
