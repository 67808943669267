// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { apiThatThrows } from "containers/Request";
import { createBody } from "utils/jsonApi";

import { getFullName } from "../../_utils";
import {
  ActivationWarningModal,
  WARNING_MODAL_MODES,
} from "../../AdminUsersTable/ActivationWarningModal";
import { AccountStatus } from "./AccountStatus";

export function AccountStatusData({ onRefreshData, user }) {
  const [processedUser, setProcessedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warningModalMode, setWarningModalMode] = useState(
    WARNING_MODAL_MODES.CLOSED
  );
  const dispatch = useDispatch();
  const { setErrors } = useFormikContext();

  const setUserStatus = useCallback(
    (id, body) =>
      dispatch(
        apiThatThrows.patchUserProfileNoOrganization.action({
          params: { id },
          options: { body },
        })
      ),
    [dispatch]
  );

  const performSetActiveRequest = async () => {
    setLoading(true);
    const body = createBody({
      type: "users",
      is_active: warningModalMode === WARNING_MODAL_MODES.ACTIVATE,
    });

    try {
      await setUserStatus(processedUser.id, body);
      await onRefreshData();
    } catch (error) {
      setErrors({ _error: error.message });
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  };

  const handleSetActive = useCallback(
    (status) => {
      setProcessedUser({ id: user.id, name: getFullName(user) });
      setWarningModalMode(
        status ? WARNING_MODAL_MODES.ACTIVATE : WARNING_MODAL_MODES.DEACTIVATE
      );
    },
    [user]
  );

  const handleCloseModal = () => {
    setWarningModalMode(WARNING_MODAL_MODES.CLOSED);
    setProcessedUser(null);
  };

  return (
    <>
      <AccountStatus
        isActive={user.is_active}
        onActivate={() => handleSetActive(true)}
        onDeactivate={() => handleSetActive(false)}
      />
      <ActivationWarningModal
        userName={processedUser ? processedUser.name : null}
        mode={warningModalMode}
        onCancel={handleCloseModal}
        onConfirm={performSetActiveRequest}
        loading={loading}
      />
    </>
  );
}

AccountStatusData.propTypes = {
  user: PropTypes.object.isRequired,
  onRefreshData: PropTypes.func,
};
