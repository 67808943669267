// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { ROLES } from "containers/Permissions";
import { getUserFullName } from "utils/string";

export const isSignatoryUser = (organizations, currentUser) => {
  return organizations[currentUser.activeOrganizationId].roles.includes(
    ROLES.provider.signatory
  );
};

export const isModerator = (organizations, currentUser) => {
  return organizations[currentUser.activeOrganizationId].roles.includes(
    ROLES.moderator.moderator
  );
};

export const getAuthorName = (message) => {
  if (message.was_autogenerated && !message.author) {
    return "Vewd Moderator";
  }
  return getUserFullName(message.author.given_name, message.author.family_name);
};
