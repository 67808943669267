// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Route, Routes, Navigate } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";
import { trans } from "src/translations";

import { CREATE_TAB_PATH } from "./constants";
import { CreateEditManifest } from "./CreateEditManifest/CreateEditManifest";
import { ManifestList } from "./ManifestsList";

export function AppManifestsRoute() {
  return (
    <Routes>
      <Route index element={<RedirectDependingOnRoles />} />
      <Route
        path="/list"
        element={<ExtendedRoute component={ManifestList} />}
      />
      <Route exact path="/create" element={<Navigate to={CREATE_TAB_PATH} />} />
      <Route
        exact
        path="/create/:tabId"
        element={
          <ExtendedRoute
            name={trans.APP_MANIFESTS_CREATE_ROUTE_NAME()}
            component={CreateEditManifest}
          />
        }
      />
      <Route
        exact
        path="/edit/:id/:tabId"
        element={
          <ExtendedRoute
            name={trans.APP_MANIFESTS_EDIT_ROUTE_NAME()}
            component={CreateEditManifest}
          />
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}
