// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import { PROCESSING_QUEUE_ITEM_STATUSES } from "../ProcessingQueueStatus";

const OTHER_ERROR_ID = PROCESSING_QUEUE_ITEM_STATUSES.OTHER_ERROR.id;

// Source: package-inventory/package_inventory/package_validation.py
export const KNOWN_ERRORS = {
  archiveInvalid: {
    pythonErrorClass: "BadZipFile",
    parsePythonError: (error) =>
      trans.UPDATES__PROCESSING_QUEUE__ERROR_ARCHIVE_INVALID({ error }),
  },
  archiveManifestNotFound: {
    pythonErrorClass: "ManifestNotFound",
    parsePythonError: (error) =>
      trans.UPDATES__PROCESSING_QUEUE__ERROR_ARCHIVE_MANIFEST_NOT_FOUND({
        error,
      }),
  },
  archiveMultipleManifestsFound: {
    pythonErrorClass: "MultipleManifestsFound",
    parsePythonError: (error) =>
      trans.UPDATES__PROCESSING_QUEUE__ERROR_ARCHIVE_MULTIPLE_MANIFESTS_FOUND({
        error,
      }),
  },
  manifestInvalidJson: {
    status: PROCESSING_QUEUE_ITEM_STATUSES.MANIFEST_ERROR.id,
    pattern: /manifest\.json is not a proper json file/i,
    parseMessage: () =>
      trans.UPDATES__PROCESSING_QUEUE__ERROR_ARCHIVE_INVALID_JSON(),
  },
  manifestParsingError: {
    status: PROCESSING_QUEUE_ITEM_STATUSES.MANIFEST_ERROR.id,
    pythonErrorClass: "ManifestParsingError",
    parsePythonError: (error) =>
      trans.UPDATES__PROCESSING_QUEUE__ERROR_MANIFEST_PARSING_ERROR({ error }),
  },
  packageAlreadyExists: {
    pattern:
      /provided values \(package_id\[(.+)\], version\[(.+)\]\) already exists in the system/i,
    parseMessage: (matches) => {
      const [, packageId, version] = matches;
      return trans.UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_ALREADY_EXISTS({
        packageId,
        version,
      });
    },
  },
  packageUnavailable: {
    pythonErrorClass: "PackageNotAvailable",
    parsePythonError: (error) => {
      const pattern = /package under url: (.+) is not available/i;
      const matches = error.match(pattern);
      if (matches) {
        const url = matches[1];
        return trans.UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_UNAVAILABLE_WITH_URL(
          { url }
        );
      }
      return trans.UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_UNAVAILABLE({
        error,
      });
    },
  },
  packageValidationError: {
    pythonErrorClass: "PackageValidationError",
    parsePythonError: (error) => {
      const pattern = /parsed file_size offset is greater than whole package/i;
      if (pattern.test(error)) {
        return trans.UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_VALIDATION_ERROR_PAYLOAD_SIZE(
          { error }
        );
      }
      return trans.UPDATES__PROCESSING_QUEUE__ERROR_PACKAGE_VALIDATION_ERROR({
        error,
      });
    },
  },
  serverConnectionError: {
    pythonErrorClass: "reqConnectionError",
    parsePythonError: (error) =>
      trans.UPDATES__PROCESSING_QUEUE__ERROR_SERVER_CONNECTION_ERROR({ error }),
  },
  serverDatabaseError: {
    pythonErrorClass: "DbRowNotFound",
    parsePythonError: (error) =>
      trans.UPDATES__PROCESSING_QUEUE__ERROR_SERVER_DATABASE_ERROR({ error }),
  },
  serverGenericError: {
    pattern: /something went wrong/i,
    parseMessage: () =>
      trans.UPDATES__PROCESSING_QUEUE__ERROR_SERVER_GENERIC_ERROR(),
  },
};

export const UNKNOWN_ERROR_ID = "unknown";

export const parseItemErrorMessage = (errorMessage) => {
  if (typeof errorMessage !== "string" || errorMessage.length === 0) {
    return null;
  }
  for (const [errorId, errorData] of Object.entries(KNOWN_ERRORS)) {
    if (errorData.pattern) {
      const matches = errorMessage.match(errorData.pattern);
      if (matches) {
        if (!errorData.parseMessage) {
          return {
            id: errorId,
            status: errorData.status ?? OTHER_ERROR_ID,
            message: errorMessage,
          };
        }
        return {
          id: errorId,
          status: errorData.status ?? OTHER_ERROR_ID,
          message: errorData.parseMessage(matches),
        };
      }
    }
    if (errorData.pythonErrorClass) {
      const pattern = new RegExp(
        `${errorData.pythonErrorClass}\\(('|")(.+)('|")\\)`
      );
      const matches = errorMessage.match(pattern);
      if (matches) {
        if (!errorData.parsePythonError) {
          return {
            id: errorId,
            status: errorData.status ?? OTHER_ERROR_ID,
            message: matches[2],
          };
        }
        return {
          id: errorId,
          status: errorData.status ?? OTHER_ERROR_ID,
          message: errorData.parsePythonError(matches[2]),
        };
      }
    }
  }
  return {
    id: UNKNOWN_ERROR_ID,
    status: OTHER_ERROR_ID,
    message: trans.UPDATES__PROCESSING_QUEUE__ERROR_UNKNOWN_ERROR({
      error: errorMessage,
    }),
  };
};
