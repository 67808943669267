// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import {
  addDays,
  addMonths,
  format,
  isToday,
  isThisMonth,
  differenceInSeconds,
  getDaysInMonth,
  areIntervalsOverlapping,
} from "date-fns";
import times from "lodash-es/times";

import { getCampaignStatus } from "pages/Promotions/_utils";

const FULL_WIDTH = 100;
const WEEK_DAYS = 7;
const MONTH_DAYS = 31;
const YEAR_DAYS = 366;
const YEAR_MONTHS = 12;
const SECONDS = 60;
const MINUTES = 60;
const HOURS = 24;

const DAY_WIDTH = {
  year: FULL_WIDTH / YEAR_DAYS,
  month: FULL_WIDTH / MONTH_DAYS,
  week: FULL_WIDTH / WEEK_DAYS,
};

const calculateDistance = (startDate, endDate, scale) => {
  const minutes = differenceInSeconds(startDate, endDate) / SECONDS;
  const hours = minutes / MINUTES;
  const days = hours / HOURS;
  return days * DAY_WIDTH[scale];
};

const calculateChartEndDate = (chartStartDate, scale) => {
  let daysToAdd;
  switch (scale) {
    case "year":
      daysToAdd = YEAR_MONTHS;
      break;
    case "month":
      daysToAdd = MONTH_DAYS;
      break;
    case "week":
    default:
      daysToAdd = WEEK_DAYS;
      break;
  }
  return addMonths(chartStartDate, daysToAdd);
};

const createDayUnits = (
  startDate,
  length,
  formatString,
  subFormatString,
  width
) => {
  return times(length, (i) => {
    const date = addDays(startDate, i);
    return {
      active: isToday(date),
      label: format(date, formatString),
      subLabel: format(date, subFormatString),
      isThisMonth: isThisMonth(date),
      width,
    };
  });
};

const createMonthUnits = (startDate) => {
  return times(YEAR_MONTHS, (i) => {
    const date = addMonths(startDate, i);
    return {
      active: isThisMonth(date),
      label: format(date, "LLL"),
      subLabel: format(date, "yyyy"),
      width: getDaysInMonth(date) * DAY_WIDTH.year,
    };
  });
};

export const createUnits = (scale, startDate) => {
  switch (scale) {
    case "year":
      return createMonthUnits(startDate);
    case "month":
      return createDayUnits(startDate, MONTH_DAYS, "d", "LLL", DAY_WIDTH.month);
    case "week":
    default:
      return createDayUnits(
        startDate,
        WEEK_DAYS,
        "iii d",
        "LLL",
        DAY_WIDTH.week
      );
  }
};

export const createEventsData = (scale, chartStartDate, data) => {
  const chartEndDate = calculateChartEndDate(chartStartDate, scale);

  return data
    .filter(({ startDate, endDate }) =>
      areIntervalsOverlapping(
        { start: chartStartDate, end: chartEndDate },
        { start: startDate, end: endDate }
      )
    )
    .map(({ id, label, startDate, endDate, isEnabled }) => {
      const width = calculateDistance(endDate, startDate, scale);
      const left = calculateDistance(startDate, chartStartDate, scale);
      const leftOverflow = left < 0;

      return {
        id,
        label,
        width: leftOverflow ? width - Math.abs(left) : width,
        left: leftOverflow ? 0 : left,
        leftOverflow,
        status: getCampaignStatus(startDate, endDate),
        isEnabled,
      };
    });
};

export const createBeginOfDayDate = (stringDate) => {
  const date = new Date(stringDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return new Date(year, month, day, 0, 0, 0, 0);
};

export const beginOfMonth = (stringDate) => {
  const date = new Date(stringDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = 1;
  return new Date(year, month, day, 0, 0, 0, 0);
};

export const createBeginOfMonthDate = (scale, chartDate) => {
  return scale === "year"
    ? beginOfMonth(chartDate)
    : createBeginOfDayDate(chartDate);
};

export const addUnits = (scale, date, amount) => {
  return scale === "year" ? addMonths(date, amount) : addDays(date, amount);
};
