// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";
import {
  pipeValidators,
  isRequiredWhen,
  hasAtLeastOneElementInFields,
  isAtLeastOneTrueInFields,
} from "utils/validation";

import { TechnicalInfo } from "../../../components";

const OTHER_SLUG = "other";

export default {
  name: trans.APP_EDIT__TECHNICAL_INFO_SECTION_NAME(),
  components: [
    {
      component: TechnicalInfo,
      fields: [
        "metadata.contains_video",
        "metadata.contains_live_video",
        "metadata.contains_vod",
        "metadata.metadata_video_formats[].format",
        "metadata.metadata_video_formats[].comment",
        "metadata.metadata_video_adaptive_streamings[].streaming",
        "metadata.metadata_video_adaptive_streamings[].comment",
        "metadata.metadata_video_drms[].drm",
        "metadata.metadata_video_drms[].comment",
        "metadata.video_formats_error", // special field only for displaying errors for group of fields
        "metadata.video_types_error",
      ],
      validate: (flatValues, values, errors, props) => {
        const {
          metadata_video_formats,
          metadata_video_adaptive_streamings,
          metadata_video_drms,
          contains_video,
        } = values.metadata;

        const formatOtherIdx = metadata_video_formats.findIndex(
          (item) => item.format === OTHER_SLUG
        );

        const streamingOtherIdx = metadata_video_adaptive_streamings.findIndex(
          (item) => item.streaming === OTHER_SLUG
        );

        const drmOtherIdx = metadata_video_drms.findIndex(
          (item) => item.drm === OTHER_SLUG
        );

        return pipeValidators(
          isRequiredWhen(
            `metadata.metadata_video_formats[${formatOtherIdx}].comment`,
            () => formatOtherIdx !== -1
          ),
          isRequiredWhen(
            `metadata.metadata_video_adaptive_streamings[${streamingOtherIdx}].comment`,
            () => streamingOtherIdx !== -1
          ),
          isRequiredWhen(
            `metadata.metadata_video_drms[${drmOtherIdx}].comment`,
            () => drmOtherIdx !== -1
          ),
          hasAtLeastOneElementInFields(
            "metadata.video_formats_error",
            [
              "metadata.metadata_video_formats",
              "metadata.metadata_video_adaptive_streamings",
              "metadata.metadata_video_drms",
            ],
            trans.APP_EDIT__TECHNICAL_INFO_VIDEO_FORMATS_ERROR(),
            () => Boolean(contains_video)
          ),
          isAtLeastOneTrueInFields(
            "metadata.video_types_error",
            ["metadata.contains_live_video", "metadata.contains_vod"],
            () => Boolean(contains_video),
            trans.APP_EDIT__VIDEO_TYPES_ERROR()
          )
        )(flatValues, values, errors, props);
      },
      initialValues: {
        metadata: {
          contains_vod: false,
          contains_live_video: false,
        },
      },
      props: ({ formikProps }) => {
        return {
          ...formikProps,
          videoFormatsError: formikProps.errors?.metadata?.video_formats_error,
          videoTypesError: formikProps.errors?.metadata?.video_types_error,
        };
      },
    },
  ],
};
