// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { restrictedArea, ROLES } from "containers/Permissions";
import { api } from "containers/Request";

import { ORGANIZATION_NONE, DEVICE_FAMILY_NONE } from "../../constants";
import { DeviceFamilyForm } from "./DeviceFamilyForm";

@restrictedArea(() => ({
  allowed: [ROLES.administrator.deviceAdmin],
}))
@connect(null, (dispatch, ownProps) => ({
  getDeviceFamily: async (id) => {
    if (ownProps.forAdmin) {
      return await dispatch(api.getDeviceFamily.action({ params: { id } }));
    }
    return await dispatch(api.getMyDeviceFamily.action({ params: { id } }));
  },
}))
export class DeviceFamilyFormData extends Component {
  static propTypes = {
    ownerId: PropTypes.string.isRequired,
    deviceFamilyId: PropTypes.string,
    onRequestSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,

    // from @connect
    // eslint-disable-next-line react/no-unused-prop-types
    getDeviceFamily: PropTypes.func.isRequired,
  };

  state = {
    initialData: {},
    loading: false,
    error: "",
  };

  async componentDidMount() {
    const { ownerId, deviceFamilyId } = this.props;

    if (
      ownerId !== ORGANIZATION_NONE &&
      deviceFamilyId !== undefined &&
      deviceFamilyId !== DEVICE_FAMILY_NONE
    ) {
      this.updateInitialData();
    }
  }

  async updateInitialData() {
    const { getDeviceFamily, deviceFamilyId } = this.props;

    this.setState({ loading: true });

    const { result, error } = await getDeviceFamily(deviceFamilyId);

    // handle fetch errors
    if (error) {
      this.setState({ loading: false, error });
      return;
    }

    const deviceFamily = result.results;

    this.setState({
      initialData: {
        id: deviceFamilyId,
        name: deviceFamily.name,
        description: deviceFamily.description,
      },
      loading: false,
      error: "",
    });
  }

  render() {
    const { ownerId, onRequestSuccess, onCancel } = this.props;
    const { initialData, loading, error } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message || error}</Info>;
    }

    return (
      <DeviceFamilyForm
        ownerId={ownerId}
        onRequestSuccess={onRequestSuccess}
        onCancel={onCancel}
        initialValues={initialData}
      />
    );
  }
}
