// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { WarningModal } from "components/layout";
import { trans } from "src/translations";

export const WARNING_MODAL_MODES = {
  ACTIVATE: "activate",
  DEACTIVATE: "deactivate",
  CLOSED: "closed",
};

export const ActivationWarningModal = ({
  onCancel,
  onConfirm,
  userName,
  mode,
  loading,
}) => {
  const isOpen =
    mode === WARNING_MODAL_MODES.ACTIVATE ||
    mode === WARNING_MODAL_MODES.DEACTIVATE;

  const isDeactivateMode = mode === WARNING_MODAL_MODES.DEACTIVATE;

  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onCancel}
      title={trans.USER_ADMIN__REMOVE_HEADER()}
      dataTestId="remove-label-modal"
      actions={
        <ButtonsWrapper position="modal">
          <Button
            type="normal"
            onClick={onCancel}
            dataTestId="remove-label-cancel-button"
          >
            {trans.CANCEL()}
          </Button>
          <Button
            type="green"
            onClick={onConfirm}
            dataTestId="remove-label-ok-button"
            processing={loading}
            disabled={loading}
          >
            {isDeactivateMode
              ? trans.USER_ADMIN__DEACTIVATE_USER_CONFIRM()
              : trans.USER_ADMIN__ACTIVATE_USER_CONFIRM()}
          </Button>
        </ButtonsWrapper>
      }
    >
      <p>
        {isDeactivateMode
          ? trans.USER_ADMIN__DEACTIVATE_USER_WARNING({ name: userName })
          : trans.USER_ADMIN__ACTIVATE_USER_WARNING({ name: userName })}
      </p>
    </WarningModal>
  );
};

ActivationWarningModal.propTypes = {
  userName: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(WARNING_MODAL_MODES)),
  loading: PropTypes.bool,
};
