// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable react/no-multi-comp */
import { Field } from "formik";
import PropTypes from "prop-types";

import { Switch } from "components/form/elements";
import { trans } from "src/translations";

export const EnableAutoScoreField = ({ isReadonly }) => (
  <Field name={"is_score_autogenerated"}>
    {({ field, meta }) => (
      <Switch
        {...field}
        checked={field.value}
        label={trans.CAMPAIGN_EDIT__FIELD_AUTO_SCORE()}
        disabled={isReadonly}
        {...meta}
        dataTestId="campaign-enable-auto-score"
      />
    )}
  </Field>
);

EnableAutoScoreField.propTypes = {
  isReadonly: PropTypes.bool,
};
