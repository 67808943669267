// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { PageNavigation, Footer } from "components/layout";
import { Breadcrumbs } from "containers/Breadcrumbs";

import { AccountMenu } from "./AccountMenu/AccountMenu";
import backgroundImg from "./images/bg.png";
import styles from "./LayoutSideBar.scss";
import NavMenu from "./NavMenu/NavMenu";
import { SideMenu } from "./SideMenu/SideMenu";

@connect((state) => {
  const { collapsed } = state.menuSwitcher;
  return { collapsed };
})
export class LayoutSideBar extends Component {
  static propTypes = {
    children: PropTypes.node,
    collapsed: PropTypes.bool.isRequired,
  };

  render() {
    const { collapsed, children } = this.props;
    return (
      <div className={styles.wrapper}>
        <img src={backgroundImg} className={styles.backgroundImg} />
        <SideMenu collapsed={collapsed} />
        <div className={collapsed ? styles.pageCollapsed : styles.pageExpanded}>
          <div className={styles.pageContent}>
            <PageNavigation>
              <div className={styles.topMenu}>
                <NavMenu />
                <AccountMenu />
              </div>
              <Breadcrumbs />
            </PageNavigation>
            {children}
          </div>
          <Footer className={styles.footer} />
        </div>
      </div>
    );
  }
}
