// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TableRowColumn, TableRow } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { BUTTONS, SECTIONS } from "pages/Snap/shared/constants";
import styles from "pages/Snap/shared/Translations.scss";
import { OrderColumn } from "pages/Snap/SnapEdit/components/Translations/OrderColumn";
import { isValidStream } from "pages/Snap/SnapEdit/validation";
import { classes } from "utils/classes";

const SectionLiveStream = ({
  openLiveStreamModal,
  fields,
  index,
  remove,
  ...rest
}) => {
  const { url, name } = fields[index];

  const rowClass = isValidStream(fields[index])
    ? styles.rowSuccess
    : styles.rowError;

  return (
    <TableRow
      className={classes(rowClass, styles.rowLive, styles.clickable)}
      dataTestId="live-stream-section-draggable-table-row"
    >
      <TableRowColumn />
      <TableRowColumn />
      <TableRowColumn
        columnClass={styles.titleColumn}
        onClick={() => openLiveStreamModal(true, index, "editLive")}
      >
        {name}
      </TableRowColumn>
      <TableRowColumn columnClass={styles.contentColumn}>
        <Link href={url} target="_blank">
          {url}
        </Link>
      </TableRowColumn>
      <TableRowColumn
        onClick={() => openLiveStreamModal(false, index, "editLive")}
      >
        {SECTIONS.TRANSLATIONS.streamLive}
      </TableRowColumn>
      <OrderColumn
        arrowUpPossible={index - 1 >= 0}
        arrowDownPossible={index + 1 !== fields.length}
        fields={fields}
        index={index}
        isLive={true}
        {...rest}
      />
      <TableRowColumn>
        <ActionMenu>
          <MenuItem
            onClick={() => openLiveStreamModal(false, index, "editLive")}
            dataTestId="edit-menu-item"
          >
            {BUTTONS.DROPLIST.edit}
          </MenuItem>
          <MenuItem
            onClick={() => {
              remove(index);
            }}
            dataTestId="delete-menu-item"
          >
            {BUTTONS.DROPLIST.delete}
          </MenuItem>
        </ActionMenu>
      </TableRowColumn>
    </TableRow>
  );
};

export default SectionLiveStream;

SectionLiveStream.propTypes = {
  openLiveStreamModal: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.array.isRequired,
  // Formik field helper
  remove: PropTypes.func.isRequired,
};
