// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";
import queryString from "query-string";

import { Pill } from "components/elements/Pill";
import { GrabsonIcon } from "components/icons";
import {
  TableBody,
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { Tooltip } from "components/popups";
import { api } from "containers/Request";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import premiumIconImg from "images/premium.svg";
import {
  DISTRIBUTION_STATUS,
  SUBMISSION_STATUS,
} from "pages/Applications/constants";
import { ORGANIZATION_PLAN } from "pages/Organization/constants";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { formatDate } from "utils/date";
import { withRouter } from "utils/decorators/withRouter";

import { MODERATION_STATUS } from "../AppDetails/constants";
import { ApplicationPopupMenu } from "./ApplicationPopupMenu";
import styles from "./ApplicationsTabbedTable.scss";
import { dataForAppListType } from "./types";

@withRouter
@connect(null, (dispatch) => ({
  getApplicationCategories: () =>
    dispatch(
      api.getApplicationCategoriesPaginated.action({
        cache: true,
        queryParams: { limit: "nolimit" },
      })
    ),
}))
export class ApplicationsTabbedTable extends Component {
  static propTypes = {
    appRouteType: PropTypes.string.isRequired,
    tabsConfig: PropTypes.array.isRequired,
    data: PropTypes.shape({
      results: PropTypes.arrayOf(dataForAppListType.isRequired),
      count: PropTypes.number,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.object,
    }).isRequired,
    onExport: PropTypes.func,

    // from @withRouter
    location: PropTypes.object,
    params: PropTypes.shape({
      tabId: PropTypes.string,
    }),
    // from @connect
    getApplicationCategories: PropTypes.func.isRequired,
  };

  state = {
    categories: {},
  };

  async componentDidMount() {
    const { getApplicationCategories } = this.props;
    const categoriesResult = await getApplicationCategories();
    const categories = {};
    if (categoriesResult.result) {
      categoriesResult.result.results.forEach((category) => {
        categories[category.slug] = category.human_name;
      });
      this.setState({
        categories,
      });
    }
  }

  getStatusText(status, statusValues) {
    const statusObject = Object.values(statusValues).find(
      (s) => s.id === status
    );
    return get(statusObject, "name", NO_VALUE_PLACEHOLDER);
  }

  getOwnerPlanTooltip(owner) {
    switch (owner.organizationPlan) {
      case ORGANIZATION_PLAN.Premium:
        return trans.APP_LIST__PREMIUM_OWNER_TOOLTIP({
          organizationPlan: "Premium",
        });
      case ORGANIZATION_PLAN.Enterprise:
        return trans.APP_LIST__PREMIUM_OWNER_TOOLTIP({
          organizationPlan: "Enterprise",
        });
      default:
        return undefined;
    }
  }

  renderOwnerColumn(app) {
    const premiumTooltip = this.getOwnerPlanTooltip(app.owner || {});

    return (
      <Fragment>
        <span>{app.owner.name}</span>
        {premiumTooltip && (
          <Tooltip alignment="bottom" content={premiumTooltip}>
            <GrabsonIcon
              dataTestId="owner-premium-icon"
              name="star"
              size="sm"
              className={styles.ownerIsPremiumIcon}
            />
          </Tooltip>
        )}
      </Fragment>
    );
  }

  renderTopPill(app) {
    return (
      <Fragment>
        <span>{app.name}</span>
        {app.top_annotation && app.top_annotation.length > 0 && (
          <Tooltip
            className={styles.topPillTooltip}
            alignment="bottom"
            content={
              app.top_annotation.includes("GLOBAL")
                ? trans.APP_DETAILS_APP_RANK__PICKED_GLOBAL()
                : trans.APP_DETAILS_APP_RANK__PICKED_LOCAL()
            }
          >
            <Pill text={trans.APP_DETAILS_APP_RANK__TOP()} />
          </Tooltip>
        )}
        {app.partial_result && (
          <Tooltip
            className={styles.topPillTooltip}
            alignment="bottom"
            content={trans.APP_DETAILS_PARTIAL_RESULT__DESCRIPTION()}
          >
            <Pill
              className={styles.topPillPartialResult}
              text={trans.APP_DETAILS_PARTIAL_RESULT__TITLE()}
              icon="alert-circle"
            />
          </Tooltip>
        )}
      </Fragment>
    );
  }

  getVisibleColumns() {
    const {
      location: { search },
      tabsConfig,
      params: { tabId },
    } = this.props;

    let columns = [];

    const activeTab = tabId ?? queryString.parse(search).tab;
    const currentTabConfig =
      tabsConfig.find((tab) => tab.id === activeTab) || tabsConfig[0];

    if (currentTabConfig) {
      columns = currentTabConfig.columns;
    }

    return columns.reduce((obj, c) => {
      obj[c.id] = true;
      return obj;
    }, {});
  }

  render() {
    const { data, tabsConfig, appRouteType, onExport } = this.props;
    const { categories } = this.state;
    const visibleColumns = this.getVisibleColumns();

    return (
      <TabbedTable
        tabsConfig={tabsConfig}
        data={data}
        onExport={onExport}
        withSensitiveDataCheckbox={true}
        renderTableBody={(apps) => {
          return (
            <TableBody>
              {apps.map((app) => {
                const isAppSubmitted = app.has_submitted_version;
                const appCategory =
                  categories[app.category] || NO_VALUE_PLACEHOLDER;
                return (
                  <TableRow key={app.id}>
                    {visibleColumns.icon && (
                      <TableRowColumnExtended
                        icon={app.icon_small}
                        defaultIcon
                      />
                    )}
                    {visibleColumns.name && (
                      <TableRowColumnExtended
                        rightIcon={app.is_premium ? premiumIconImg : ""}
                        to={
                          isAppSubmitted
                            ? `/applications/${appRouteType}/${app.id}/details`
                            : ""
                        }
                        text={this.renderTopPill(app)}
                        className={styles.nameColumn}
                      />
                    )}
                    {visibleColumns.category && (
                      <TableRowColumnExtended text={appCategory} />
                    )}
                    {visibleColumns.owner && (
                      <TableRowColumn>
                        {this.renderOwnerColumn(app)}
                      </TableRowColumn>
                    )}
                    {visibleColumns.status && (
                      <TableRowColumnExtended
                        className={
                          styles[
                            (app.flow_status &&
                              app.flow_status.toLowerCase()) ||
                              "not_submitted"
                          ]
                        }
                        text={app.flow_status || NO_VALUE_PLACEHOLDER}
                      />
                    )}
                    {visibleColumns.dateSubmitted && (
                      <TableRowColumnExtended
                        text={
                          app.date_submitted
                            ? formatDate(app.date_submitted)
                            : NO_VALUE_PLACEHOLDER
                        }
                      />
                    )}
                    {visibleColumns.availableSince && (
                      <TableRowColumnExtended
                        text={
                          app.available_since
                            ? formatDate(app.available_since)
                            : NO_VALUE_PLACEHOLDER
                        }
                      />
                    )}
                    {visibleColumns.submissionStatus && (
                      <TableRowColumnExtended
                        text={this.getStatusText(
                          app.submission_status,
                          SUBMISSION_STATUS
                        )}
                      />
                    )}
                    {visibleColumns.distributionStatus && (
                      <TableRowColumnExtended
                        text={this.getStatusText(
                          app.distribution_status,
                          DISTRIBUTION_STATUS
                        )}
                      />
                    )}
                    {visibleColumns.certification && (
                      <TableRowColumnExtended
                        className={styles["todo"]}
                        text={"TODO"}
                      />
                    )}
                    {visibleColumns.moderationStatus && (
                      <TableRowColumnExtended
                        className={classes(
                          styles.moderationStatusCell,
                          styles[
                            (app.status && app.status.toLowerCase()) ||
                              "not_submitted"
                          ]
                        )}
                        text={get(
                          MODERATION_STATUS,
                          `${app.status}.name`,
                          NO_VALUE_PLACEHOLDER
                        )}
                      />
                    )}

                    {visibleColumns.contentManager && (
                      <TableRowColumnExtended
                        fontIcon="users"
                        text={`${app.contentManager || NO_VALUE_PLACEHOLDER}`}
                      />
                    )}

                    {visibleColumns.targetStatus && (
                      <TableRowColumnExtended
                        className={styles.targetStatus}
                        text={"TODO"}
                      />
                    )}
                    {visibleColumns.distribution && (
                      <TableRowColumnExtended text={"TODO"} />
                    )}
                    {visibleColumns.moderator && (
                      <TableRowColumnExtended
                        fontIcon="users"
                        text={`${app.moderator || NO_VALUE_PLACEHOLDER}`}
                      />
                    )}
                    {visibleColumns.priority && (
                      <TableRowColumnExtended
                        className={styles[app.priority]}
                        text={app.priority.toUpperCase()}
                      />
                    )}
                    {visibleColumns.appType && (
                      <TableRowColumnExtended
                        className={styles[app.type]}
                        text={app.type}
                      />
                    )}
                    {visibleColumns.videoSpec && (
                      <TableRowColumnExtended text={"TODO"} />
                    )}
                    {visibleColumns.needAction && (
                      <TableRowColumnExtended
                        fontIcon="warning"
                        // fontIconActive={true} // todo
                        // tooltip={true ? "Review application! (MOCKED)" : ""} // todo
                      />
                    )}
                    {visibleColumns.message && (
                      <TableRowColumnExtended
                        fontIcon="message"
                        // fontIconActive={true} // todo
                        // tooltip={true ? "You have new message! (MOCKED)" : ""} // todo
                      />
                    )}
                    <TableRowColumn>
                      <ApplicationPopupMenu
                        app={app}
                        appRouteType={appRouteType}
                      />
                    </TableRowColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          );
        }}
      />
    );
  }
}
