// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Section, Subsection, PageContentError } from "components/layout";
import { trans } from "src/translations";

export class DeviceModelDetailsRend extends Component {
  static propTypes = {
    deviceModel: PropTypes.object.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
  };

  render() {
    const { deviceModel, error, loading } = this.props;

    if (loading) {
      return <Loader />;
    }
    if (error) {
      return <PageContentError error={error} />;
    }

    return (
      <div>
        <Section header={trans.DEVICES__DEVICE_DETAILS_HEADER()}>
          <Subsection header={trans.DEVICES__MODEL_NAME_HEADER()}>
            {deviceModel.name}
          </Subsection>
          <Subsection header={trans.DEVICES__DISTRIBUTOR_HEADER()}>
            {deviceModel.distributorName}
          </Subsection>
          <Subsection header={trans.DEVICES__DEVICE_FAMILY_HEADER()}>
            {deviceModel.familyName}
          </Subsection>
          <Subsection header={trans.DEVICES__DEVICE_SLUG_HEADER()}>
            {deviceModel.slug}
          </Subsection>
        </Section>
      </div>
    );
  }
}
