// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const devicesAcceptingCommandsQuery = gql`
  query devicesAcceptingCommands(
    $pagination: PaginationInput!
    $search: SearchInput
  ) {
    devicesAcceptingCommands(pagination: $pagination, search: $search) {
      devicesAcceptingCommands {
        items {
          id
        }
        pagination {
          count
        }
      }
    }
  }
`;
