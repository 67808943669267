// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import androidProfilesSection from "./androidProfilesSection";
import description from "./descriptionSection";
import generalNativeSection from "./generalNativeSection";
import general from "./generalSection";
import linuxProfilesSection from "./linuxProfilesSection";
import media from "./mediaSection";
import support from "./supportSection";
import technical from "./technicalSection";

export default {
  name: trans.APP_EDIT_BASIC__TAB(),
  url: "basic-details",
  sections: [
    general,
    androidProfilesSection,
    linuxProfilesSection,
    generalNativeSection,
    description,
    support,
    media,
    technical,
  ],
  dataTestId: "basic-details-tab",
};
