// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { saveAs } from "file-saver";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { ValidationBadge } from "components/form";
import { Checkbox } from "components/form/elements";
import { Section, Subsection } from "components/layout";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors/gqlErrors";
import {
  withQuery,
  withMutation,
  mutationResultType,
  queryResultType,
} from "utils/graphql";
import { timeout } from "utils/timeout";

import { sendUpdateJobLogsToUserMutation } from "../gql/sendUpdateJobLogsToUserMutation";
import { updateJobLogsQuery } from "../gql/updateJobLogsQuery";
import { UpdateJobPropType } from "../propTypes";
import { LogsDownloadModal } from "./LogsDownloadModal";
import styles from "./UpdateJobLogs.scss";

const POLL_BASE_DELAY = 5000;

const POLL_RETRY_LIMIT = 4;

@withMutation({
  name: "sendUpdateJobLogsToUser",
  mutation: sendUpdateJobLogsToUserMutation,
})
@withQuery({
  name: "updateJobLogs",
  query: updateJobLogsQuery,
})
export class UpdateJobLogs extends PureComponent {
  static propTypes = {
    data: UpdateJobPropType,
    setSuccessMessage: PropTypes.func.isRequired,

    // from @withMutation
    sendUpdateJobLogsToUserMutation: PropTypes.func.isRequired,
    sendUpdateJobLogsToUserMutationStatus: mutationResultType,

    // from @withQuery
    updateJobLogsQuery: PropTypes.func,
    updateJobLogsQueryStatus: queryResultType,
  };

  state = {
    checkedSteps: {},
    isModalOpen: false,
    polling: false,
    showValidationError: false,
  };

  isStepChecked = (step) => this.state.checkedSteps[step.stepId] === true;

  toggleStep = (step) => {
    this.setState((state) => ({
      checkedSteps: {
        ...state.checkedSteps,
        [step.stepId]: !state.checkedSteps[step.stepId],
      },
    }));
  };

  getFileName = (createdAt, updateJobId, steps) => {
    return (
      createdAt +
      "-ID-" +
      updateJobId +
      "-steps-[" +
      steps.map((s) => s + 1).join(",") +
      "].csv"
    );
  };

  getMetadata = async () => {
    const { data, updateJobLogsQuery } = this.props;
    let pollStart;
    let pollDelay = POLL_BASE_DELAY;
    let pollRetries = POLL_RETRY_LIMIT;
    this.setState({ polling: true });
    while (pollRetries > 0) {
      try {
        pollStart = Date.now();
        await updateJobLogsQuery({
          variables: {
            updateJobId: data.id,
          },
        });
        break;
      } catch {
        const pollDuration = Date.now() - pollStart;
        if (pollDuration < pollDelay) {
          await timeout(pollDelay - pollDuration);
        }
        pollDelay *= 2;
        pollRetries--;
      }
    }
    this.setState({ polling: false });
    if (pollRetries === 0) {
      throw new Error("Retry limit reached");
    }
    const metadata = this.getParsedMetadata();
    if (!metadata.downloadUrl) {
      throw new Error("The download URL is empty");
    }
    return metadata;
  };

  getParsedMetadata = () => {
    const { data } = this.props.updateJobLogsQueryStatus;
    return {
      createdAt: data?.updateJobLogs?.updateJobLogs?.createdAt || "",
      downloadUrl: data?.updateJobLogs?.updateJobLogs?.presignedUrl || "",
    };
  };

  getRequestStatus = () => {
    const {
      sendUpdateJobLogsToUserMutationStatus: sendLogsStatus,
      updateJobLogsQueryStatus: getLogsStatus,
    } = this.props;
    const { polling } = this.state;
    const status = {
      loading: sendLogsStatus.loading || getLogsStatus.loading || polling,
    };
    if (!status.loading) {
      if (sendLogsStatus.error) {
        status.error = getGqlErrorMessage(sendLogsStatus.error);
      } else if (getLogsStatus.error) {
        status.error = getGqlErrorMessage(getLogsStatus.error);
      }
    }
    return status;
  };

  getSelectedStepIndices = () => {
    const { data } = this.props;

    return data.steps.reduce((acc, step, idx) => {
      if (this.isStepChecked(step)) {
        acc.push(idx);
      }
      return acc;
    }, []);
  };

  getValidationError() {
    const { data } = this.props;
    const firstStep = data.steps[0];
    const anyDevicesWereUpdated =
      firstStep.successes > 0 || firstStep.failures > 0;
    if (!anyDevicesWereUpdated) {
      return trans.UPDATES__UPDATE_DETAILS__LOGS_ERROR_NO_AVAILABLE();
    }
    const selectedSteps = this.getSelectedStepIndices();
    if (selectedSteps.length === 0) {
      return trans.UPDATES__UPDATE_DETAILS__LOGS_ERROR_NO_STEPS_SELECTED();
    }
    return undefined;
  }

  handleDownload = async () => {
    const { data, setSuccessMessage, sendUpdateJobLogsToUserMutation } =
      this.props;
    const selectedSteps = this.getSelectedStepIndices();

    await sendUpdateJobLogsToUserMutation({
      variables: {
        input: {
          updateJobId: data.id,
          logsStartDate: data.logsStartDate,
          updateJobStepIndices: selectedSteps,
        },
      },
    });

    const metadata = await this.getMetadata();
    const response = await fetch(metadata.downloadUrl);
    const blob = await response.blob();

    saveAs(blob, this.getFileName(metadata.createdAt, data.id, selectedSteps));

    this.handleModalClose();
    setSuccessMessage(trans.UPDATES__UPDATE_DETAILS__LOGS_SUCCESS_MESSAGE());
  };

  handleDownloadBtnClick = () => {
    const { setSuccessMessage } = this.props;
    if (this.getValidationError() != null) {
      this.setState({ showValidationError: true });
    } else {
      this.setState({ showValidationError: false, isModalOpen: true });
    }
    setSuccessMessage(undefined);
  };

  handleModalClose = () => this.setState({ isModalOpen: false });

  render() {
    const { data } = this.props;
    const { showValidationError } = this.state;
    const requestStatus = this.getRequestStatus();
    const error = this.getValidationError();

    return (
      <Section header={trans.UPDATES__UPDATE_DETAILS__UPDATE_LOGS_HEADER()}>
        <Subsection
          header={trans.UPDATES__UPDATE_DETAILS__UPDATE_LOGS_SELECT_STEPS()}
        >
          <div className={styles.checkboxContainer}>
            {data.steps.map((step, idx) => {
              const hasLogs =
                Boolean(step.inProgress) ||
                Boolean(step.successes) ||
                Boolean(step.failures);

              return (
                <Checkbox
                  key={step.stepId}
                  name={`step-${idx}`}
                  value={step.stepId}
                  checked={this.isStepChecked(step)}
                  onChange={() => this.toggleStep(step)}
                  disabled={!step.hasStarted && !hasLogs}
                  className={styles.checkbox}
                  dataTestId="update-logs-step"
                >
                  {trans.UPDATES__UPDATE_DETAILS__UPDATE_LOGS_STEP({
                    step: String(idx + 1),
                  })}
                </Checkbox>
              );
            })}
          </div>

          <Button
            onClick={this.handleDownloadBtnClick}
            dataTestId="update-logs-download-button"
          >
            {trans.DOWNLOAD()}
          </Button>
          <ValidationBadge touched={showValidationError} error={error} />
        </Subsection>

        <LogsDownloadModal
          isOpen={this.state.isModalOpen}
          onClose={this.handleModalClose}
          onConfirm={this.handleDownload}
          isSubmitting={requestStatus.loading}
          error={requestStatus.error}
        />
      </Section>
    );
  }
}
