// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { Label } from "components/form";
import { CheckboxField } from "components/form/fields/";
import styles from "components/form/form.scss";
import { FIELDS } from "pages/Snap/shared/constants";

export const AdType = ({
  midRollType,
  preRollType,
  formPrefix,
  onTypeChange,
}) => {
  const { setFieldValue } = useFormikContext();
  const toggleType = (type) => {
    return type === "DISABLED" ? "OUR" : "DISABLED";
  };

  return (
    <div className={styles.radioGroup}>
      <Label text={FIELDS.AD_TYPES.label}>
        <CheckboxField
          name={`${formPrefix}.${FIELDS.AD_TYPES.PRE.name}`}
          label={FIELDS.AD_TYPES.PRE.label}
          checked={preRollType !== "DISABLED"}
          onChange={(e) => {
            setFieldValue(
              `${formPrefix}.${FIELDS.AD_TYPES.PRE.name}`,
              toggleType(e.target.value)
            );
          }}
        />
        <CheckboxField
          name={`${formPrefix}.${FIELDS.AD_TYPES.MID.name}`}
          label={FIELDS.AD_TYPES.MID.label}
          checked={midRollType !== "DISABLED"}
          onChange={(e) => {
            onTypeChange({
              type: "toggle_type",
              payload: toggleType(e.target.value),
            });
          }}
        />
      </Label>
    </div>
  );
};
AdType.propTypes = {
  formPrefix: PropTypes.string.isRequired,
  midRollType: PropTypes.string.isRequired,
  preRollType: PropTypes.string.isRequired,
  onTypeChange: PropTypes.func.isRequired,
};
