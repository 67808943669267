// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const getActiveTabIndex = (params, tabParamName, tabsList) => {
  const activeTabUrl = params[tabParamName];

  const tabIdx = tabsList.findIndex(({ url }) => activeTabUrl === url);

  return tabIdx === -1 ? 0 : tabIdx;
};
