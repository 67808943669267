// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Checkbox } from "components/form/elements";
import {
  Modal,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
} from "components/layout";
import {
  isAllowedToAddRole,
  isAllowedToRemoveRole,
} from "containers/Permissions";
import { trans } from "src/translations";

import { RoleDescriptionTooltip } from "../RoleDescriptionTooltip";

export class RolesModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    organizationRoles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        role: PropTypes.string,
      })
    ),
    currentUserRoles: PropTypes.array,
    editUserRoles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        role: PropTypes.string,
      })
    ),
    onSave: PropTypes.func,
  };

  state = {
    roles: this.props?.editUserRoles.map(({ role }) => role) || [],
  };

  toggle = (id) => {
    const { roles } = this.state;

    const index = roles.indexOf(id);

    if (index === -1) {
      roles.push(id);
    } else {
      roles.splice(index, 1);
    }

    this.setState({
      roles: roles,
    });
  };

  onSave = () => {
    const { onSave, onClose } = this.props;
    onSave(this.state.roles);
    onClose();
  };

  isChecked(roleId) {
    return this.state.roles.indexOf(roleId) !== -1;
  }

  isAllowedToChange(currentUserRoles, editUserRoles, role, removeAction) {
    return removeAction
      ? isAllowedToRemoveRole(currentUserRoles, editUserRoles, role)
      : isAllowedToAddRole(currentUserRoles, editUserRoles, role);
  }

  renderTableBody() {
    const { currentUserRoles, organizationRoles = [] } = this.props;

    return (
      <TableBody>
        {organizationRoles.map(({ name, id }) => (
          <TableRow key={id} dataTestId="roles-modal-row">
            <TableRowColumn>
              <Checkbox
                checked={this.isChecked(id)}
                onChange={() => this.toggle(id)}
                value={id}
                dataTestId="roles-modal-checkbox"
                disabled={
                  !this.isAllowedToChange(
                    currentUserRoles,
                    this.state.roles,
                    id,
                    this.isChecked(id)
                  )
                }
              />
            </TableRowColumn>
            <TableRowColumn>
              <RoleDescriptionTooltip id={id} name={name} />
            </TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Modal
        title={trans.NEW_ROLE()}
        isOpen={isOpen}
        onClose={onClose}
        size="medium"
        actions={
          <ButtonsWrapper>
            <Button
              type="normal"
              onClick={onClose}
              dataTestId="roles-modal-close-button"
            >
              {trans.CLOSE()}
            </Button>
            <Button
              type="normal"
              onClick={this.onSave}
              dataTestId="roles-modal-save-button"
            >
              {trans.SAVE()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <Table>
          <TableHeader>
            <TableHeaderColumn />
            <TableHeaderColumn>{trans.ROLE()}</TableHeaderColumn>
          </TableHeader>
          {this.renderTableBody()}
        </Table>
      </Modal>
    );
  }
}
