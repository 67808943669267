// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import times from "lodash-es/times";

import { trans } from "src/translations";

export const FORM_NAME = "createUpdateJobForm";

export const UPDATE_JOB_TYPES = [
  {
    value: "EXPLICIT",
    text: trans.UPDATES__FORM_FIELD_UPDATE_TYPE_EXPLICIT(),
    tooltip: trans.UPDATES__FORM_FIELD_UPDATE_TYPE_EXPLICIT_TOOLTIP(),
  },
  {
    value: "FORCED",
    text: trans.UPDATES__FORM_FIELD_UPDATE_TYPE_FORCED(),
    tooltip: trans.UPDATES__FORM_FIELD_UPDATE_TYPE_FORCED_TOOLTIP(),
  },
  {
    value: "SILENT",
    text: trans.UPDATES__FORM_FIELD_UPDATE_TYPE_SILENT(),
    tooltip: trans.UPDATES__FORM_FIELD_UPDATE_TYPE_SILENT_TOOLTIP(),
  },
];
export const UPDATE_JOB_PROP_TYPES = UPDATE_JOB_TYPES.map((e) => e.value);

// The last step is not configurable and is always displayed.
// This explains the values not matching the text. Values are number of
// *configurable* steps, not steps in total.
export const UPDATE_JOB_STEP_COUNTS = [
  {
    value: 1,
    text: trans.UPDATES__FORM_FIELD_STEP_QUANTITY_ONE(),
  },
  {
    value: 2,
    text: trans.UPDATES__FORM_FIELD_STEP_QUANTITY_MANY({ steps: 2 }),
  },
  {
    value: 3,
    text: trans.UPDATES__FORM_FIELD_STEP_QUANTITY_MANY({ steps: 3 }),
  },
  {
    value: 4,
    text: trans.UPDATES__FORM_FIELD_STEP_QUANTITY_MANY({ steps: 4 }),
  },
  {
    value: 5,
    text: trans.UPDATES__FORM_FIELD_STEP_QUANTITY_MANY({ steps: 5 }),
  },
];

// last step is always infinity, so it is not configurable
export const MAX_EDITABLE_UPDATE_JOB_STEPS = UPDATE_JOB_STEP_COUNTS.length - 1;

export const DEFAULT_DEVICES_PER_STEP = ["100", "1000", "10000", "100000"];

export const THRESHOLD_MIN = 0;
export const THRESHOLD_MAX = 100;
const THRESHOLD_STEP = 1;

export const CREATE_INITIAL_VALUES = {
  name: "",
  threshold: "90",
  type: UPDATE_JOB_TYPES[0].value,
  deviceQuantityPerStep: times(
    MAX_EDITABLE_UPDATE_JOB_STEPS,
    (idx) => DEFAULT_DEVICES_PER_STEP[idx] || ""
  ),
  softwarePackageVersion: undefined,
  targetDevices: undefined,
  availabilityScope: { start: undefined, end: undefined },
  showAdvancedOptions: false,
};

export const FIELDS = {
  TYPE: {
    name: "type",
    label: trans.UPDATES__FORM_FIELD_UPDATE_TYPE(),
    required: true,
    values: UPDATE_JOB_TYPES.map(({ text, value, tooltip }) => ({
      label: text,
      value,
      dataTestId: `type-field-${value.toLowerCase()}`,
      tooltip,
    })),
    dataTestId: "type-field",
  },
  NAME: {
    name: "name",
    required: true,
    label: trans.UPDATES__FORM_FIELD_NAME(),
    placeholder: trans.UPDATES__FORM_FIELD_PLACEHOLDER(),
    dataTestId: "name-field",
  },
  DESCRIPTION: {
    name: "description",
    required: false,
    label: trans.UPDATES__FORM_FIELD_DESCRIPTION(),
    dataTestId: "description-field",
  },
  NUMBER_OF_STEPS: {
    name: "numberOfSteps",
    required: true,
    label: trans.UPDATES__FORM_FIELD_STEP_QUANTITY(),
    values: UPDATE_JOB_STEP_COUNTS,
    dataTestId: "step-quantity-field",
  },
  TARGET_DEVICES: {
    name: "targetDevices",
    label: trans.UPDATES__FORM_FIELD_TARGET_DEVICE(),
    modalConfig: {
      placeholder: trans.UPDATES__FORM_SELECT_TARGET_MODAL_SEARCH_PLACEHOLDER(),
      title: trans.UPDATES__FORM_SELECT_TARGET_MODAL_TITLE(),
    },
    required: true,
    dataTestId: "target-devices-field",
  },
  THRESHOLD: {
    name: "threshold",
    required: true,
    label: trans.UPDATES__FORM_FIELD_THRESHOLD(),
    tooltip: trans.UPDATES__FORM_FIELD_THRESHOLD_TOOLTIP(),
    dataTestId: "threshold-field",
    min: THRESHOLD_MIN,
    max: THRESHOLD_MAX,
    step: THRESHOLD_STEP,
  },
};

export const TARGET_DEVICES_DEFAULT_OFFSET = 0;
export const TARGET_DEVICES_ITEMS_PER_PAGE = 10;
