// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import cloneDeep from "lodash-es/cloneDeep";

import { checkAccess } from "containers/Permissions";

import generic from "./generic";
import test from "./test";

export const config = {
  test: test,
  generic: generic,
};

export const selectConfig = (props) => {
  const { type, is_test } = props.appData;
  return is_test ? config.test : config[type] || config.generic;
};

export const removeTabsIfNoPermissions = (
  config,
  props,
  activeOrganization
) => ({
  ...config,
  tabs: config.tabs.filter((tab) => {
    if (tab.permissions) {
      const { allowed } = tab.permissions(props);
      return checkAccess({ activeOrganization }, allowed);
    }

    return true;
  }),
});

export const removeDisabledTabs = (config, props) => ({
  ...config,
  tabs: config.tabs.filter((tab) => !tab.isEnabled || tab.isEnabled(props)),
});

export const removeSectionsIfNoPermissions = (
  config,
  props,
  activeOrganization
) => ({
  ...config,
  tabs: config.tabs.map((tab) => ({
    ...tab,
    sections: tab.sections.filter((section) => {
      if (section.permissions) {
        const { allowed } = section.permissions(props);
        return checkAccess({ activeOrganization }, allowed);
      }

      return true;
    }),
  })),
});

export const removeDisabledSections = (config, props) => ({
  ...config,
  tabs: config.tabs.map((tab) => ({
    ...tab,
    sections: tab.sections.filter(
      (section) => !section.isEnabled || section.isEnabled(props)
    ),
  })),
});

export const removeComponentsIfNoPermissions = (
  config,
  props,
  activeOrganization
) => ({
  ...config,
  tabs: config.tabs.map((tab) => ({
    ...tab,
    sections: tab.sections.map((section) => ({
      ...section,
      components: section.components.filter((component) => {
        if (component.permissions) {
          const { allowed } = component.permissions(props);
          return checkAccess({ activeOrganization }, allowed);
        }

        return true;
      }),
    })),
  })),
});

export const getConfig = (props, activeOrganization) => {
  let conf;

  // order matters!
  conf = cloneDeep(selectConfig(props));
  conf = removeTabsIfNoPermissions(conf, props, activeOrganization);
  conf = removeDisabledTabs(conf, props);
  conf = removeSectionsIfNoPermissions(conf, props, activeOrganization);
  conf = removeDisabledSections(conf, props);
  conf = removeComponentsIfNoPermissions(conf, props, activeOrganization);

  return conf;
};
