// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";
import { classes } from "utils/classes";

import styles from "./PageBanner.scss";

export const PageBanner = ({
  text,
  icon,
  tooltipContent = "",
  className = "",
  dataTestId = "page-banner",
}) => (
  <span
    className={classes(className, styles.topBanner)}
    data-test-id={dataTestId}
  >
    <Tooltip
      content={tooltipContent}
      disabled={!tooltipContent}
      alignment="bottom"
    >
      <span className={styles.topBannerText}>
        {icon && (
          <GrabsonIcon
            dataTestId={`${dataTestId}-icon`}
            name={icon}
            className={styles.icon}
          />
        )}
        {text}
        {tooltipContent ? (
          <span
            className={styles.tooltipIcon}
            data-test-id={`${dataTestId}-tooltip`}
          >
            (?)
          </span>
        ) : null}
      </span>
    </Tooltip>
  </span>
);

PageBanner.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
