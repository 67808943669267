// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { buttonSizeType } from "components/popups/ActionMenu/types";
import { trans } from "src/translations";

export class AssetActionMenu extends Component {
  static propTypes = {
    asset: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      versions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          number: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
    versionNumber: PropTypes.string,
    buttonSize: buttonSizeType,
  };

  getEditUrl() {
    const { asset, versionNumber } = this.props;

    const location = {
      pathname: `/software-assets/${asset.id}`,
    };

    if (versionNumber) {
      location.state = {
        versionNumber,
      };
    }

    return location;
  }

  render() {
    const { buttonSize } = this.props;
    return (
      <ActionMenu buttonSize={buttonSize}>
        <MenuItem to={this.getEditUrl()}>{trans.EDIT_DETAILS()}</MenuItem>
      </ActionMenu>
    );
  }
}
