// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Select } from "components/form/elements";
import { trans } from "src/translations";

import styles from "./Actions.scss";

const ACCEPT_ACTION = "accept";
const REJECT_ACTION = "reject";
const PROCEED_ACTION = "proceed";
const ON_HOLD_ACTION = "on-hold";

export class Actions extends Component {
  static propTypes = {
    onApprove: PropTypes.func,
    approving: PropTypes.bool,
    onReject: PropTypes.func,
    onProceed: PropTypes.func.isRequired,
    proceeding: PropTypes.bool.isRequired,
    onOnHold: PropTypes.func.isRequired,
    isAnyModelSelected: PropTypes.bool.isRequired,
  };

  state = {
    action: ACCEPT_ACTION,
  };

  handleAction = () => {
    const { onApprove, onReject, onProceed, onOnHold } = this.props;

    switch (this.state.action) {
      case ACCEPT_ACTION: {
        onApprove();
        break;
      }
      case REJECT_ACTION: {
        onReject();
        break;
      }
      case PROCEED_ACTION: {
        onProceed();
        break;
      }
      case ON_HOLD_ACTION: {
        onOnHold();
        break;
      }
    }
  };

  render() {
    const { approving, proceeding, isAnyModelSelected } = this.props;

    const processing = approving || proceeding;
    const disabled = approving || proceeding || !isAnyModelSelected;

    return (
      <div className={styles.container}>
        <Select
          name="action"
          className={styles.actionSelect}
          values={[
            {
              value: ACCEPT_ACTION,
              text: trans.APP_DETAILS_MODERATION_TARGETS__ACTION_ACCEPT(),
            },
            {
              value: REJECT_ACTION,
              text: trans.APP_DETAILS_MODERATION_TARGETS__ACTION_REJECT(),
            },
            {
              value: PROCEED_ACTION,
              text: trans.APP_DETAILS_MODERATION_TARGETS__ACTION_PROCEED(),
            },
            {
              value: ON_HOLD_ACTION,
              text: trans.APP_DETAILS_MODERATION_TARGETS__ACTION_ON_HOLD(),
            },
          ]}
          value={this.state.action}
          onChange={(ev) => this.setState({ action: ev.target.value })}
          size="compact"
          disabled={disabled}
        />
        <Button
          onClick={this.handleAction}
          disabled={disabled}
          processing={processing}
          className={styles.actionButton}
        >
          {trans.APP_DETAILS_MODERATION_TARGETS__ACTION_PREFORM_BUTTON()}
        </Button>
      </div>
    );
  }
}
