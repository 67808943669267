// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { PageContentError } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea } from "containers/Permissions";
import { ALL_DISTRIBUTION_PLANNERS } from "containers/Permissions/groups";
import { api, apiThatThrows } from "containers/Request";
import { withTargetsReceive } from "pages/_shared/TargetsField/decorators/withTargetsReceive";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";

import { PLAN_ID_DEFAULT } from "../constants";
import { CustomPlanFormRend } from "./CustomPlanFormRend";

const INITIAL_VALUES = {
  name: "",
  description: "",
  targets: [],
  applications: [],
};

@restrictedArea(() => ({
  allowed: ALL_DISTRIBUTION_PLANNERS,
}))
@withRouter
@withActiveOrganization
@withTargetsReceive({ distributorFieldNameInRequest: "device_owners" })
@connect(null, (dispatch) => ({
  getPlan: (id) =>
    dispatch(apiThatThrows.getMyDistributionPlan.action({ params: { id } })),
  getTargetOfPlan: async (planId, targetId) => {
    const request = await dispatch(
      api.getTargetOfMyDistributionPlan.action({
        params: {
          planId,
          targetId,
        },
      })
    );
    if (!request.result) {
      throw {
        message: trans.DISTR_PLAN__TARGET_NOT_FOUND_ERROR({
          targetId,
          planId,
        }),
      };
    }
    return request.result.results;
  },
}))
export class CustomPlanFormData extends Component {
  static propTypes = {
    isEdit: PropTypes.bool.isRequired,

    // from @connect
    getPlan: PropTypes.func.isRequired,
    getTargetOfPlan: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,

    // from @withTargetsReceive
    getTargetsData: PropTypes.func,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  };

  state = {
    loading: true,
    error: null,
    initialValues: INITIAL_VALUES,
  };

  componentDidMount() {
    this.updateInitialValues();
  }

  componentDidUpdate(prevProps) {
    const { activeOrganization, location } = this.props;

    const organizationChanged =
      activeOrganization.id !== prevProps.activeOrganization.id;
    const locationChanged = location !== prevProps.location;
    if (organizationChanged || locationChanged) {
      this.refreshData();
    }
  }

  getApplicationIdsFromLocation() {
    const { location } = this.props;
    return get(location, "state.appIds", []);
  }

  updateInitialValues = async () => {
    const { isEdit } = this.props;

    if (isEdit) {
      this.setState({ loading: true });

      try {
        const initialValues = await this.getInitialEditValues();
        this.setState({
          error: null,
          initialValues,
        });
      } catch (error) {
        this.setState({ error });
      } finally {
        this.setState({ loading: false });
      }
    } else {
      this.setState({
        loading: false,
        error: null,
        initialValues: {
          ...INITIAL_VALUES,
          applications: this.getApplicationIdsFromLocation(),
        },
      });
    }
  };

  async getInitialEditValues() {
    const { getPlan, params, getTargetOfPlan, getTargetsData } = this.props;

    const { results: plan } = await getPlan(params.id);

    const targetsOfPlan = await Promise.all(
      plan.targets.map((target) => getTargetOfPlan(plan.id, target.id))
    );
    const targets = await getTargetsData(targetsOfPlan);

    return {
      name: plan.label,
      description: plan.description,
      targets: targets,
      applications: plan.applications.map((app) => app.id),
    };
  }

  refreshData = () => {
    this.updateInitialValues();
  };

  render() {
    const { id = PLAN_ID_DEFAULT } = this.props.params;
    const { loading, error, initialValues } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <PageContentError error={error} />;
    }

    return <CustomPlanFormRend id={id} initialValues={initialValues} />;
  }
}
