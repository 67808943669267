// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Jsona } from "jsona";
import { TJsonaModel } from "jsona/lib/JsonaTypes";

export const dataFormatter = new Jsona();

export const createBody = (data: TJsonaModel | TJsonaModel[]): string =>
  JSON.stringify(dataFormatter.serialize({ stuff: data }));
