// Copyright © 2023 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useState } from "react";

const THEME_SETTING = "vs-manifest-edit-theme";
export const THEMES = { dark: "vs-dark", light: "light" };
const defaultTheme = localStorage.getItem(THEME_SETTING) || THEMES.dark;

export const useEditorTheme = () => {
  const [editorTheme, setEditorTheme] = useState(defaultTheme);
  const toggleTheme = useCallback(() => {
    setEditorTheme((currentTheme) => {
      const newTheme =
        currentTheme === THEMES.light ? THEMES.dark : THEMES.light;
      localStorage.setItem(THEME_SETTING, newTheme);
      return newTheme;
    });
  }, []);

  return {
    editorTheme,
    toggleTheme,
  };
};
