// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { ROLES } from "containers/Permissions/roles";
import { api, apiThatThrows } from "containers/Request";
import { UsersModal } from "pages/_shared";
import { isVersionValid } from "pages/Applications/_utils";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import { createBody } from "utils/jsonApi";

import { prepareAppData } from "../appVersionDataAdapters";
import {
  UPDATE_SCOPE_NONE,
  INVALID_ID,
  PRIORITY_P1,
  UNKNOWN_VALUE,
  MODERATION_STATUS,
  QA_STATUS_PENDING,
  QA_STATUS_REJECTED,
} from "../constants";
import { SectionModeration } from "./SectionModeration";

@withRouter
@connect(null, (dispatch) => ({
  getUser: (id) => {
    return dispatch(api.getUserProfile.action({ params: { id } }));
  },
  patchQASummary: (id, body) => {
    return dispatch(
      api.patchQASummary.action({ params: { id }, options: { body } })
    );
  },
  patchModerationResults: (id, body) => {
    return dispatch(
      api.patchModerationResults.action({
        params: { id },
        options: { body },
      })
    );
  },
  startModeration: (id) => {
    return dispatch(api.startModeration.action({ params: { id } }));
  },
  doneModeration: (id) => {
    return dispatch(api.doneModeration.action({ params: { id } }));
  },
  approveMetadata: (moderationResultId) =>
    dispatch(
      api.approveModeration.action({
        params: { id: moderationResultId },
      })
    ),
  rejectMetadata: (moderationResultId, body) =>
    dispatch(
      apiThatThrows.rejectModeration.action({
        params: { id: moderationResultId },
        options: { body },
      })
    ),
  setExclusive: (appId, body) =>
    dispatch(
      apiThatThrows.setExclusive.action({
        params: { id: appId },
        options: { body },
      })
    ),
  getOrganization: (organizationId) =>
    dispatch(
      apiThatThrows.getOrganizationPublicInfo.action({
        params: { organizationId },
      })
    ),
  getAppVersion: (version_id) =>
    dispatch(
      apiThatThrows.getApplicationFullVersion.action({
        params: { version_id },
      })
    ),
}))
export class SectionModerationData extends Component {
  static propTypes = {
    applicationId: PropTypes.string,
    application: PropTypes.object.isRequired,
    qaSummaries: PropTypes.object.isRequired,
    refreshQASummaries: PropTypes.func.isRequired,
    refreshData: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object,

    // from @connect
    getUser: PropTypes.func,
    patchQASummary: PropTypes.func,
    patchModerationResults: PropTypes.func,
    startModeration: PropTypes.func.isRequired,
    doneModeration: PropTypes.func.isRequired,
    approveMetadata: PropTypes.func.isRequired,
    rejectMetadata: PropTypes.func.isRequired,
    getOrganization: PropTypes.func.isRequired,
    getAppVersion: PropTypes.func.isRequired,
    setExclusive: PropTypes.func.isRequired,
  };

  state = {
    error: null,
    loading: true,
    usersModalError: null,
    usersModalLoading: false,
    moderationId: INVALID_ID,
    moderationStatus: UNKNOWN_VALUE,
    moderationPriority: PRIORITY_P1,
    updateScope: UPDATE_SCOPE_NONE,
    supportLevel: false,
    isExclusive: false,
    changeModerator: false,
    changeContentManager: false,
    moderator: { id: INVALID_ID, name: UNKNOWN_VALUE },
    contentManager: { id: INVALID_ID, name: UNKNOWN_VALUE },
    qualityRound: UNKNOWN_VALUE,
    qualitySummaryId: INVALID_ID,
    isAppStoreTesting: false,
    appOwner: null,
    latestVersionData: undefined,
  };

  componentDidMount() {
    this.isStillMounted = true;
    this.fetchModerationData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchModerationData();
    }
  }

  componentWillUnmount() {
    this.isStillMounted = false;
  }

  getModerationResult(qaSummaries) {
    const {
      latest_moderation_result: latestModerationResult = {
        id: INVALID_ID,
        status: UNKNOWN_VALUE,
        automatic_update_scope: UNKNOWN_VALUE,
      },
      active_moderation_result: activeModerationResult = {
        id: INVALID_ID,
      },
    } = qaSummaries;

    let moderationResult = {
      id: INVALID_ID,
      status: UNKNOWN_VALUE,
      automatic_update_scope: UNKNOWN_VALUE,
    };

    if (
      latestModerationResult.status === QA_STATUS_PENDING ||
      latestModerationResult.status === QA_STATUS_REJECTED
    ) {
      moderationResult = latestModerationResult;
    } else if (activeModerationResult) {
      moderationResult = activeModerationResult;
    }

    return moderationResult;
  }

  async fetchModerationData() {
    const { qaSummaries, application } = this.props;
    const {
      qa_round = UNKNOWN_VALUE,
      priority = PRIORITY_P1,
      main_moderator_id: moderatorId,
      content_manager_id: managerId,
      is_premium = false,
      id = INVALID_ID,
      is_app_store_testing: isAppStoreTesting,
    } = qaSummaries || {};

    const moderationResult = this.getModerationResult(qaSummaries || {});

    try {
      this.setStateSafely({ loading: true });

      const moderator = await this.getUser(moderatorId);
      const contentManager = await this.getUser(managerId);

      const appOwner = await this.getAppOwner();
      const latestVersionData = await this.getLatestVersionData();

      this.setStateSafely({
        moderationStatus: moderationResult.flow_status,
        moderationId: moderationResult.id,
        qualityRound: qa_round,
        moderationPriority: priority,
        updateScope: moderationResult.moderator_update_scope
          ? moderationResult.moderator_update_scope
          : moderationResult.automatic_update_scope,
        moderator,
        contentManager,
        supportLevel: is_premium,
        isExclusive: application.is_exclusive,
        qualitySummaryId: id,
        isAppStoreTesting: isAppStoreTesting,
        appOwner,
        latestVersionData,
        loading: false,
      });
    } catch (err) {
      this.setStateSafely({ error: err });
    } finally {
      this.setStateSafely({ loading: false });
    }
  }

  adaptUserDataForUi = (userId, userData, isUnknown = false) => ({
    isUnknown,
    id: userId,
    givenName: userData?.given_name,
    familyName: userData?.family_name,
  });

  getUser = async (userId) => {
    if (userId) {
      const { getUser } = this.props;
      const { result, error } = await getUser(userId);

      return this.adaptUserDataForUi(userId, result?.results, Boolean(error));
    }

    return null;
  };

  async getAppOwner() {
    const { getOrganization, application } = this.props;
    const { results } = await getOrganization(application.owner_public_id);
    return results;
  }

  async getLatestVersionData() {
    const { getAppVersion, application } = this.props;
    const versionId = get(application, "last_submitted_version.id");

    const { results } = await getAppVersion(versionId);

    if (!isVersionValid(results)) {
      throw {
        message: trans.APP_DETAILS_VERSION__UNEXPECTED_ERROR(),
      };
    }

    return prepareAppData(results);
  }

  handlePriorityChange = (value) => async () => {
    const { patchQASummary } = this.props;
    const { qualitySummaryId } = this.state;

    this.setStateSafely({ loading: true });

    const body = createBody({
      type: "QASummary",
      id: qualitySummaryId,
      priority: value,
    });

    const { error } = await patchQASummary(qualitySummaryId, body);

    if (error) {
      this.setStateSafely({ error, loading: false });
    } else {
      this.setStateSafely({ moderationPriority: value, loading: false });
    }
  };

  handleUpdateScopeChange = (value) => async () => {
    const { patchModerationResults, refreshQASummaries } = this.props;
    const { moderationId } = this.state;

    this.setStateSafely({ loading: true });

    const body = createBody({
      type: "ModerationResult",
      id: moderationId,
      moderator_update_scope: value,
    });

    const { error } = await patchModerationResults(moderationId, body);

    if (error) {
      this.setStateSafely({ error, loading: false });
    } else {
      this.setStateSafely({ updateScope: value, loading: false });
      refreshQASummaries();
    }
  };

  handleSupportLevelChange = async ({ isPremium, priority }) => {
    const { patchQASummary } = this.props;
    const { qualitySummaryId } = this.state;

    this.setStateSafely({ loading: true });

    const body = createBody({
      type: "QASummary",
      id: qualitySummaryId,
      priority,
      is_premium: isPremium,
    });

    const { error } = await patchQASummary(qualitySummaryId, body);

    if (error) {
      this.setStateSafely({ error, loading: false });
    } else {
      this.setStateSafely({
        supportLevel: isPremium,
        moderationPriority: priority,
        loading: false,
      });
    }
  };

  handleExclusivityChange = async (exclusivity) => {
    const { applicationId, setExclusive } = this.props;
    this.setStateSafely({ loading: true });

    const body = createBody({
      type: "ApplicationModel",
      id: applicationId,
      is_exclusive: exclusivity,
    });

    try {
      await setExclusive(applicationId, body);
      this.props.refreshData();
    } catch (error) {
      this.setStateSafely({ error });
    } finally {
      this.setStateSafely({ loading: false });
    }
  };

  handleContentManagerChange = () => {
    this.setStateSafely({ changeContentManager: true });
  };

  handleModeratorChange = () => {
    this.setStateSafely({ changeModerator: true });
  };

  handleSelectUser = async (user) => {
    const { patchQASummary } = this.props;
    const { qualitySummaryId, changeModerator } = this.state;

    this.setStateSafely({ usersModalLoading: true });

    const body = createBody({
      type: "QASummary",
      id: qualitySummaryId,
      [changeModerator ? "main_moderator_id" : "content_manager_id"]: user.id,
    });
    const { error } = await patchQASummary(qualitySummaryId, body);
    if (error) {
      this.setStateSafely({ usersModalError: error, usersModalLoading: false });
    } else {
      this.setStateSafely({
        [changeModerator ? "moderator" : "contentManager"]: user,
        usersModalLoading: false,
        changeModerator: false,
        changeContentManager: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setStateSafely({
      changeModerator: false,
      changeContentManager: false,
    });
  };

  handleModerationStatusChange = async (newModerationStatus) => {
    const { startModeration, doneModeration, refreshQASummaries } = this.props;
    const { moderationId, moderationStatus } = this.state;

    this.setStateSafely({ loading: true });

    let action;

    switch (newModerationStatus) {
      case MODERATION_STATUS.TESTING.id: {
        action = startModeration;
        break;
      }
      case MODERATION_STATUS.DONE.id: {
        action = doneModeration;
        break;
      }
      default:
        break;
    }

    if (!action) {
      this.setStateSafely({
        error: trans.APP_DETAILS_MODERATION__INVALID_STATUS(),
        loading: false,
      });
      return;
    }

    const { error } = await action(moderationId);

    if (error) {
      this.setStateSafely({ error, loading: false });
      return;
    }

    // When the moderation status changes from PENDING to TESTING,
    // the Vewd App Store testing status should be automatically switched to true
    if (
      moderationStatus === MODERATION_STATUS.PENDING.id &&
      newModerationStatus === MODERATION_STATUS.TESTING.id
    ) {
      await this.handleIsAppStoreTestingChange(true)();
    }

    // When the moderation status changes to DONE,
    // the Vewd App Store testing status should be automatically switched to false
    if (newModerationStatus === MODERATION_STATUS.DONE.id) {
      await this.handleIsAppStoreTestingChange(false)();
    }

    this.setStateSafely({ loading: false });
    refreshQASummaries();
  };

  handleIsAppStoreTestingChange = (isAppStoreTesting) => async () => {
    const { patchQASummary, refreshQASummaries } = this.props;
    const { qualitySummaryId } = this.state;

    this.setStateSafely({ loading: true });

    const body = createBody({
      type: "QASummary",
      id: qualitySummaryId,
      is_app_store_testing: isAppStoreTesting,
    });

    const { error } = await patchQASummary(qualitySummaryId, body);

    if (error) {
      this.setStateSafely({ error, loading: false });
    } else {
      this.setStateSafely({ loading: false });
      refreshQASummaries();
    }
  };

  handleApproveMetadata = async () => {
    const { approveMetadata, refreshQASummaries, refreshData } = this.props;

    const { error } = await approveMetadata(this.getLatestModerationId());

    if (error) {
      this.setStateSafely({ error });
    } else {
      refreshData();
      refreshQASummaries();
    }
  };

  handleRejectMetadata = async (reason) => {
    const { rejectMetadata, refreshQASummaries, refreshData } = this.props;

    const moderationId = this.getLatestModerationId();
    const body = createBody({
      type: "ModerationResult",
      id: moderationId,
      reject_reason: reason,
    });

    await rejectMetadata(moderationId, body);

    refreshData();
    refreshQASummaries();
  };

  getLatestModerationId() {
    const { qaSummaries } = this.props;
    return get(qaSummaries, "latest_moderation_result.id");
  }

  setStateSafely(newState) {
    if (this.isStillMounted) {
      this.setState(newState);
    }
  }

  render() {
    const { applicationId, qaSummaries, refreshData } = this.props;
    const {
      error,
      loading,
      usersModalError,
      usersModalLoading,
      moderationStatus,
      moderationPriority,
      updateScope,
      supportLevel,
      isExclusive,
      changeModerator,
      changeContentManager,
      moderator,
      contentManager,
      qualityRound,
      isAppStoreTesting,
      appOwner,
      latestVersionData,
    } = this.state;

    const initialSelectedUser = changeModerator ? moderator : contentManager;

    return (
      <div>
        <SectionModeration
          error={error}
          loading={loading}
          moderationStatus={moderationStatus}
          qualityRound={qualityRound}
          moderationPriority={moderationPriority}
          updateScope={updateScope}
          mainModerator={moderator}
          contentManager={contentManager}
          supportLevel={supportLevel}
          isExclusive={isExclusive}
          applicationId={applicationId}
          isAppStoreTesting={isAppStoreTesting}
          appOwner={appOwner}
          latestModerationResult={qaSummaries.latest_moderation_result}
          refreshData={refreshData}
          latestVersionData={latestVersionData}
          onPriorityChange={this.handlePriorityChange}
          onUpdateScopeChange={this.handleUpdateScopeChange}
          onSupportLevelChange={this.handleSupportLevelChange}
          onExclusivityChange={this.handleExclusivityChange}
          onContentManagerChange={this.handleContentManagerChange}
          onModeratorChange={this.handleModeratorChange}
          onChangeModerationStatus={this.handleModerationStatusChange}
          onIsAppStoreTestingChange={this.handleIsAppStoreTestingChange}
          onApproveMetadata={this.handleApproveMetadata}
          onRejectMetadata={this.handleRejectMetadata}
        />
        <UsersModal
          isOpen={changeModerator || changeContentManager}
          roleFilter={
            changeModerator
              ? ROLES.moderator.moderator
              : ROLES.moderator.contentManager
          }
          title={
            changeModerator
              ? trans.APP_DETAILS_MODERATION__SELECT_MODERATOR()
              : trans.APP_DETAILS_MODERATION__SELECT_CONTENT_MANAGER()
          }
          onClose={this.handleCloseModal}
          onUserSelect={this.handleSelectUser}
          loading={usersModalLoading}
          error={usersModalError}
          initialSelectedUserId={
            !initialSelectedUser || initialSelectedUser.isUnknown
              ? null
              : initialSelectedUser.id
          }
        />
      </div>
    );
  }
}
