// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";
import { getSelectedDeviceModels } from "reduxReusable/selectedTargets/selectors";

import { api } from "containers/Request";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import { createBody } from "utils/jsonApi";

import { DEVICE_ACCEPTED } from "../constants";
import {
  deselectAll,
  toggleDeviceFamily,
  toggleDeviceModel,
  toggleDistributor,
  updateTargets,
} from "./redux/actions";
import { SELECTED_TARGETS_STORE_PATH } from "./redux/constants";
import { SectionTargetsRenderer } from "./SectionTargetsRenderer";

const makeMapStateToProps = () => {
  return (state) => {
    return {
      selectedModels: getSelectedDeviceModels(state, {
        storePath: SELECTED_TARGETS_STORE_PATH,
      }),
    };
  };
};

@withRouter
@connect(makeMapStateToProps, (dispatch) => ({
  updateTargets: (results) => dispatch(updateTargets(results)),
  toggleDeviceModel: (distributorId, familyId, modelId) =>
    dispatch(toggleDeviceModel(distributorId, familyId, modelId)),
  toggleDeviceFamily: (distributorId, familyId) =>
    dispatch(toggleDeviceFamily(distributorId, familyId)),
  toggleDistributor: (distributorId) =>
    dispatch(toggleDistributor(distributorId)),
  patchModerationResults: (id, body) => {
    return dispatch(
      api.patchModerationResults.action({
        params: { id },
        options: { body },
      })
    );
  },
  deselectAll: () => dispatch(deselectAll()),
}))
export class SectionTargets extends Component {
  static propTypes = {
    application: PropTypes.object,
    results: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    hasOnHoldTargets: PropTypes.bool,
    moderationId: PropTypes.string,
    showRevenueWarning: PropTypes.bool,
    refreshQASummaries: PropTypes.func.isRequired,
    organizationsWithAgreements: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasAnyEnvironmentApproved: PropTypes.bool.isRequired,
    isDistributable: PropTypes.bool.isRequired,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,

    // from @connect
    updateTargets: PropTypes.func.isRequired,
    toggleDeviceModel: PropTypes.func.isRequired,
    toggleDeviceFamily: PropTypes.func.isRequired,
    toggleDistributor: PropTypes.func.isRequired,
    patchModerationResults: PropTypes.func.isRequired,
    deselectAll: PropTypes.func.isRequired,

    selectedModels: PropTypes.array.isRequired,
  };

  state = {
    isRejectTargetsModalOpen: false,
    isOnHoldModalOpen: false,
    approving: false,
    proceeding: false,
    isNoAgreementModalOpen: false,
    isNoEnvironmentModalOpen: false,
    error: "",
  };

  constructor(props) {
    super(props);

    props.updateTargets(props.results);
  }

  componentDidMount() {
    this.isStillMounted = true;
    this.props.deselectAll();
  }

  componentWillUnmount() {
    this.isStillMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { results, moderationId, deselectAll, updateTargets } = this.props;

    if (results !== prevProps.results) {
      updateTargets(results);
    } else if (moderationId !== prevProps.moderationId) {
      deselectAll();
    }
  }

  handleModelToggle = (distributorId, familyId, modelId) => {
    const { toggleDeviceModel } = this.props;
    toggleDeviceModel(distributorId, familyId, modelId);
  };

  handleFamilyToggle = (distributorId, familyId) => {
    const { toggleDeviceFamily } = this.props;
    toggleDeviceFamily(distributorId, familyId);
  };

  handleDistributorToggle = (distributorId) => {
    const { toggleDistributor } = this.props;
    toggleDistributor(distributorId);
  };

  toggleNoAgreementModal = () => {
    this.setState((state) => ({
      isNoAgreementModalOpen: !state.isNoAgreementModalOpen,
    }));
  };

  toggleNoEnvironmentModal = () => {
    this.setState((state) => ({
      isNoEnvironmentModalOpen: !state.isNoEnvironmentModalOpen,
    }));
  };

  onProceedNoAgreementModal = () => {
    const { hasAnyEnvironmentApproved } = this.props;

    this.toggleNoAgreementModal();

    if (!hasAnyEnvironmentApproved) {
      this.toggleNoEnvironmentModal();
    } else {
      this.performApproveAction();
    }
  };

  isAgreementProvided = () => {
    const { organizationsWithAgreements, selectedModels, isDistributable } =
      this.props;

    if (isDistributable) {
      return true;
    }

    return selectedModels.every((model) =>
      organizationsWithAgreements.includes(model.distributorId)
    );
  };

  handleApprove = () => {
    const { hasAnyEnvironmentApproved } = this.props;

    if (!this.isAgreementProvided()) {
      this.setState({ isNoAgreementModalOpen: true });
    } else if (!hasAnyEnvironmentApproved) {
      this.setState({ isNoEnvironmentModalOpen: true });
    } else {
      this.performApproveAction();
    }
  };

  performApproveAction = async () => {
    const {
      patchModerationResults,
      selectedModels,
      moderationId,
      application,
    } = this.props;

    const body = createBody({
      type: "ModerationResult",
      id: moderationId,
      moderation_targets: selectedModels.map((model) => ({
        device_model_id: model.id,
        owner_id: model.distributorId,
        internal_status: DEVICE_ACCEPTED,
      })),
    });

    this.setState({ approving: true, error: "" });

    const { error } = await patchModerationResults(moderationId, body);
    if (error) {
      this.setState({ error: error.message, approving: false });
    } else {
      this.setState({ approving: false });
      this.handleModerationActionSuccess(
        trans.APP_DETAILS_MODERATION_TARGETS__ACTION_SUCCESS({
          actionName: "accepted",
          appName: application.name,
        })
      );
    }
  };

  handleReject = () => {
    this.setState({ isRejectTargetsModalOpen: true });
  };

  handleOnHold = () => {
    this.setState({ isOnHoldModalOpen: true });
  };

  handleCloseRejectTargetsModal = () => {
    this.setState({ isRejectTargetsModalOpen: false });
  };

  handleCloseOnHoldModal = () => {
    this.setState({ isOnHoldModalOpen: false });
  };

  handleProceed = async () => {
    const { hasAnyEnvironmentApproved } = this.props;

    if (!hasAnyEnvironmentApproved) {
      this.setState({ isNoEnvironmentModalOpen: true });
    } else {
      this.performProceedAction();
    }
  };

  performProceedAction = async () => {
    const {
      patchModerationResults,
      moderationId,
      selectedModels,
      application,
    } = this.props;

    const body = createBody({
      type: "ModerationResult",
      id: moderationId,
      moderation_targets: selectedModels.map((model) => ({
        device_model_id: model.id,
        owner_id: model.distributorId,
        is_on_hold: false,
      })),
    });

    this.setState({ proceeding: true, error: "" });

    const { error } = await patchModerationResults(moderationId, body);
    if (error) {
      this.setState({ proceeding: false, error: error.message });
    } else {
      this.setState({ proceeding: false });
      this.handleModerationActionSuccess(
        trans.APP_DETAILS_MODERATION_TARGETS__ACTION_SUCCESS({
          actionName: "proceeded",
          appName: application.name,
        })
      );
    }
  };

  handleModerationActionSuccess = async (message) => {
    const { deselectAll, refreshQASummaries, location, navigate, application } =
      this.props;

    if (this.isStillMounted) {
      navigate(
        {
          ...location,
        },
        {
          state: {
            appId: application.id,
            message,
          },
        }
      );
    }

    deselectAll();
    await refreshQASummaries();
  };

  getSuccessMessage() {
    const { application, location } = this.props;
    if (application.id !== get(location, "state.appId")) {
      return null;
    }
    return get(location, "state.message");
  }

  render() {
    const {
      application,
      results,
      count,
      loading,
      error,
      selectedModels,
      moderationId,
      hasOnHoldTargets,
      showRevenueWarning,
    } = this.props;

    const isAnyModelSelected = selectedModels.length > 0;

    return (
      <SectionTargetsRenderer
        isRejectTargetsModalOpen={this.state.isRejectTargetsModalOpen}
        isOnHoldModalOpen={this.state.isOnHoldModalOpen}
        isNoAgreementModalOpen={this.state.isNoAgreementModalOpen}
        isNoEnvironmentModalOpen={this.state.isNoEnvironmentModalOpen}
        toggleNoAgreementModal={this.toggleNoAgreementModal}
        toggleNoEnvironmentModal={this.toggleNoEnvironmentModal}
        onProceedNoAgreementModal={this.onProceedNoAgreementModal}
        hasOnHoldTargets={hasOnHoldTargets}
        approving={this.state.approving}
        proceeding={this.state.proceeding}
        onCloseRejectTargetsModal={this.handleCloseRejectTargetsModal}
        onCloseOnHoldModal={this.handleCloseOnHoldModal}
        onApprove={this.handleApprove}
        onReject={this.handleReject}
        onOnHold={this.handleOnHold}
        onProceed={this.handleProceed}
        onDistributorToggle={this.handleDistributorToggle}
        onFamilyToggle={this.handleFamilyToggle}
        onModelToggle={this.handleModelToggle}
        results={results}
        count={count}
        dataError={error}
        requestError={this.state.error}
        loading={loading}
        handleModerationActionSuccess={this.handleModerationActionSuccess}
        isAnyModelSelected={isAnyModelSelected}
        moderationId={moderationId}
        showRevenueWarning={showRevenueWarning}
        successMessage={this.getSuccessMessage()}
        application={application}
      />
    );
  }
}
