// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";

import styles from "./Breadcrumbs.scss";
import { breadcrumbPropType } from "./propTypes";

function BreadcrumbsList({ breadcrumbs }) {
  const count = breadcrumbs.length;
  return breadcrumbs.map((breadcrumb, index) => {
    const isLast = index === count - 1;
    return (
      <span
        className={isLast ? styles.active : styles.inactive}
        key={breadcrumb.id || index}
      >
        {breadcrumb.url ? (
          <Link to={breadcrumb.url} className={styles.text}>
            {breadcrumb.name}
          </Link>
        ) : (
          <span className={styles.text}>{breadcrumb.name}</span>
        )}
        {isLast ? null : (
          <GrabsonIcon
            name="arrow-right"
            size="xs"
            font="micro"
            className={styles.icon}
          />
        )}
      </span>
    );
  });
}

BreadcrumbsList.propTypes = {
  breadcrumbs: PropTypes.arrayOf(breadcrumbPropType),
};

export { BreadcrumbsList };
