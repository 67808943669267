// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

export const checkAccess = (values, allowed) => {
  if (!allowed || allowed.length === 0) {
    return true;
  }

  const roles = get(values, "activeOrganization.roles", []);

  return allowed.some((roleOrCheck) => {
    if (typeof roleOrCheck === "function") {
      return roleOrCheck(values);
    } else {
      return roles.includes(roleOrCheck);
    }
  });
};

export const checkOrganizationTypeAccess = (types, allowedTypes) => {
  if (!allowedTypes || allowedTypes.length === 0) {
    return true;
  }

  return allowedTypes.some((type) => types.includes(type));
};
