// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useRef, useCallback } from "react";

import { useLazyQuery } from "@apollo/client";

import { DEFAULT_APOLLO_OPTIONS } from "utils/graphql/constants";

// Currently impossible to properly type in TS 4.2
export const withQuery =
  ({ name, query, options = {} }) =>
  (ComposedComponent) => {
    return function WithQueryWrapper(props) {
      const graphQLPromise = useRef(null);

      const resolvePromise = (result) => {
        graphQLPromise.current.resolve(result);

        if (options.onCompleted) {
          options.onCompleted(result);
        }
      };

      const rejectPromise = (errResult) => {
        graphQLPromise.current.reject(errResult);

        if (options.onError) {
          options.onError(errResult);
        }
      };

      const [queryFunction, queryStatus] = useLazyQuery(query, {
        ...DEFAULT_APOLLO_OPTIONS,
        ...options,
        onCompleted: resolvePromise,
        onError: rejectPromise,
      });

      return (
        <ComposedComponent
          {...props}
          {...{
            [`${name}Query`]: useCallback(
              (opts) => {
                return new Promise((resolve, reject) => {
                  graphQLPromise.current = {
                    resolve,
                    reject,
                  };
                  queryFunction(opts);
                });
              },
              [queryFunction]
            ),
            [`${name}QueryStatus`]: queryStatus,
          }}
        />
      );
    };
  };
