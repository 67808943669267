// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import {
  PAIR_VALUES_SEPARATOR,
  FILTER_EXCLUSION_TRIGGER_CHARACTER,
} from "../constants";
import { FilterDropdownOption } from "../dropdownOptions/FilterDropdownOption";
import { ValueDropdownOption } from "../dropdownOptions/ValueDropdownOption";
import { createFilterFromSearchString } from "./createFilterFromSearchString";
import { filterShownValues } from "./filterShownValues";
import { getOptionSuggestions } from "./getOptionSuggestions";
import { getValueSuggestions } from "./getValueSuggestions";
import { createDropdownSuggestion } from "./utils";

/**
 * Very core function of the SearchBarWithFilters. Takes suggestions data
 * and current user input and returns suggestions. Be careful, There are many
 * different use cases here.
 */
export const getSuggestions = (
  value,
  filtersDataChoices,
  backendSuggestions
) => {
  const isExcludeModeOn = value.startsWith(FILTER_EXCLUSION_TRIGGER_CHARACTER);

  const filter = createFilterFromSearchString(value, filtersDataChoices);
  const regex = new RegExp(`\\${PAIR_VALUES_SEPARATOR}`, "g");
  const isValuePair = filter?.value && (value.match(regex) || []).length === 2;
  // Case 1: user has not selected a concrete filter yet
  // e.g. "/" or "/application_descri", but not "/application_id:"
  // Case 2:  user is entering second value from value pair ("PAIR_VALUES_SEPARATOR" used to separate values)
  //          -- suggestions are displayed for the first value; for the second one filter description is rendered
  if (filter == null || isValuePair) {
    const newValue = isValuePair
      ? value.split(PAIR_VALUES_SEPARATOR)[0]
      : value;
    const options = getOptionSuggestions(
      filtersDataChoices,
      newValue,
      isExcludeModeOn
    );
    const possibleOptions = filterShownValues(newValue, options);
    return {
      suggestions: possibleOptions,
      optionComponent: FilterDropdownOption,
      filterBeforeDisplay: false,
    };
  }

  const filterObject = filter.filterSpecObject;
  const values = get(filterObject, "values", []);
  const hasBackendValueSuggestions =
    backendSuggestions.hasBackendValueSuggestions(filter);
  const filterWillNeverSuggestValues =
    values.length === 0 && !hasBackendValueSuggestions;

  // Case 2: filter value can be any string and there are no suggestions.
  // In this case show the filter to provide hints for e.g. date format
  // e.g. "/start_date:", "/start_date:199"
  if (filterWillNeverSuggestValues) {
    const options = getOptionSuggestions(
      filtersDataChoices,
      value,
      isExcludeModeOn
    );
    const valueAsSuggestion = createDropdownSuggestion(
      filterObject,
      undefined,
      value
    );
    const possibleOptions = filterShownValues(valueAsSuggestion, options);
    return {
      suggestions: possibleOptions,
      optionComponent: FilterDropdownOption,
      filterBeforeDisplay: false,
    };
  }

  // Case 3: Filter value suggestions are loaded from backend
  // Will show loading state and errors if needed. More importantly,
  // it will show backend suggestions refreshed after every change.
  if (hasBackendValueSuggestions) {
    const backendValues = backendSuggestions.suggestions || [];
    const suggestions = getValueSuggestions(filterObject, value, backendValues);
    return {
      suggestions: suggestions,
      optionComponent: ValueDropdownOption,
      filterBeforeDisplay: false,
    };
  }

  // Case 4: Show value suggestions that are hardcoded in `filtersDataChoices`
  // if there are any.
  const options = getValueSuggestions(filterObject, value, values);
  const possibleOptions = filterShownValues(value, options);
  return {
    suggestions: possibleOptions,
    optionComponent: ValueDropdownOption,
    filterBeforeDisplay: false,
  };
};
