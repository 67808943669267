// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import { shouldChangePage, createPageUrl } from "utils/pagination";

import styles from "./PageInput.scss";

@withRouter
export class PageInput extends Component {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    dataTestId: PropTypes.string,
    currentPage: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
  };

  static defaultProps = {
    dataTestId: "page-input",
  };

  state = {
    inputValue: this.props.currentPage,
  };

  componentDidUpdate(prevProps) {
    const { currentPage } = this.props;
    if (currentPage !== prevProps.currentPage) {
      this.setState({ inputValue: currentPage });
    }
  }

  loadPage() {
    const { location, navigate, currentPage, lastPage } = this.props;
    const { inputValue } = this.state;

    if (!shouldChangePage(currentPage, lastPage, Number(inputValue))) {
      this.setState({ inputValue: currentPage });
      return;
    }

    const pageUrl = createPageUrl(location, inputValue);
    navigate(pageUrl);
  }

  render() {
    const { dataTestId } = this.props;

    return (
      <div data-test-id={`${dataTestId}-current`} className={styles.wrapper}>
        <input
          className={styles.input}
          type="text"
          placeholder={trans.PAGINATION_INPUT_PLACEHOLDER()}
          value={this.state.inputValue}
          onChange={(e) => {
            this.setState({ inputValue: e.target.value });
          }}
          onBlur={() => this.loadPage()}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              this.loadPage();
            }
          }}
        />
      </div>
    );
  }
}
