// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Field } from "formik";
import PropTypes from "prop-types";

import { Label, ValidationBadge } from "components/form";
import { Radio } from "components/form/elements/Radio";
import {
  organizationsPropTypes,
  profilePropTypes,
} from "containers/Auth/propTypes";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { isSignatoryUser } from "utils/user";

import { DISTRIBUTION_TYPES, APP_TYPES } from "../constants";
import blurStyle from "../utils/blur.scss";
import styles from "./Distribution.scss";

@connect(({ auth }) => ({
  organizations: auth.organizations,
  currentUser: auth.profile,
}))
export class Distribution extends Component {
  static propTypes = {
    isSubmitted: PropTypes.bool.isRequired,
    appType: PropTypes.string.isRequired,
    // from @Formik
    setFieldValue: PropTypes.func.isRequired,
    // from @connect
    organizations: organizationsPropTypes,
    currentUser: profilePropTypes,
  };

  getVewdCatalogueSubLabel = () => {
    const { appType } = this.props;
    if (appType === APP_TYPES.HTML) {
      return trans.APP_EDIT_CERTIFICATION__VEWD_CATALOGUE_DESCRIPTION_HTML();
    }
    return trans.APP_EDIT_CERTIFICATION__VEWD_CATALOGUE_DESCRIPTION();
  };

  getDistributionRadios = ({ initialValue }) => {
    const { appType } = this.props;
    const radioButtons = {
      [DISTRIBUTION_TYPES.VEWD_CATALOGUE]: {
        distributionType: DISTRIBUTION_TYPES.VEWD_CATALOGUE,
        label: trans.APP_EDIT_CERTIFICATION__VEWD_CATALOGUE(),
        sublabel: this.getVewdCatalogueSubLabel(),
        defaultValue: DISTRIBUTION_TYPES.VEWD_CATALOGUE,
      },
      [DISTRIBUTION_TYPES.VEWD_CERTIFICATION_PROGRAM]: {
        distributionType: DISTRIBUTION_TYPES.VEWD_CERTIFICATION_PROGRAM,
        label: trans.APP_EDIT_CERTIFICATION__VEWD_CERTIFICATION_PROGRAM(),
        sublabel:
          trans.APP_EDIT_CERTIFICATION__VEWD_CERTIFICATION_PROGRAM_DESCRIPTION(),
        defaultValue: DISTRIBUTION_TYPES.VEWD_CERTIFICATION_PROGRAM,
      },
    };
    if (initialValue === DISTRIBUTION_TYPES.EXAMINE) {
      radioButtons[DISTRIBUTION_TYPES.EXAMINE] = {
        distributionType: DISTRIBUTION_TYPES.EXAMINE,
        label: trans.APP_EDIT_CERTIFICATION__EXAMINE(),
        sublabel: trans.APP_EDIT_CERTIFICATION__EXAMINE_DESCRIPTION(),
        defaultValue: DISTRIBUTION_TYPES.EXAMINE,
      };
    }
    if (appType === APP_TYPES.HTML) {
      return Object.values(radioButtons);
    }
    return [
      radioButtons[DISTRIBUTION_TYPES.VEWD_CATALOGUE],
      radioButtons[DISTRIBUTION_TYPES.EXAMINE],
    ].filter((value) => typeof value !== "undefined");
  };

  isDisabled = (distributionType, { initialValue }) => {
    const { isSubmitted } = this.props;
    if (distributionType === DISTRIBUTION_TYPES.EXAMINE) {
      return true;
    }
    const isAlreadySubmitted = isSubmitted && initialValue;
    if (!isAlreadySubmitted || initialValue === DISTRIBUTION_TYPES.EXAMINE) {
      return false;
    }
    return !(initialValue === DISTRIBUTION_TYPES.VEWD_CERTIFICATION_PROGRAM);
  };

  render() {
    const { organizations, currentUser, setFieldValue } = this.props;
    const classNames = isSignatoryUser(organizations, currentUser)
      ? [styles.container]
      : [styles.container, blurStyle.blur];

    return (
      <div className={classes(...classNames)}>
        <Label
          text={`${trans.PROGRAM()}:`}
          tooltip={trans.APP_EDIT_CERTIFICATION__DISTRIBUTION_TOOLTIP()}
        />
        <Field
          name="metadata.distribute_in_tvstore"
          validate={(value) => {
            let errorMessage;
            if (value === DISTRIBUTION_TYPES.EXAMINE) {
              errorMessage = trans.APP_EDIT_CERTIFICATION__EXAMINE_ERROR();
            }
            return errorMessage;
          }}
        >
          {({ field, meta }) => (
            <>
              {this.getDistributionRadios(meta).map((radio) => (
                <Radio
                  {...field}
                  {...meta}
                  key={radio.distributionType}
                  className={styles.radio}
                  defaultValue={radio.defaultValue}
                  disabled={this.isDisabled(radio.distributionType, meta)}
                  onChange={(e) => {
                    setFieldValue("metadata.terms_accepted", false);
                    setFieldValue("metadata.accepted_criteria", false);
                    field.onChange(e);
                  }}
                  dataTestId="distribution-field"
                >
                  <span>
                    <span>{radio.label}</span>
                    <div className={styles.sublabel}>{radio.sublabel}</div>
                  </span>
                </Radio>
              ))}
              <ValidationBadge error={meta.error} touched={meta.touched} />
            </>
          )}
        </Field>
      </div>
    );
  }
}
