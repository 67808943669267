// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { SourceTypeContainer } from "./SourceTypeContainer";
import styles from "./sprite.scss";

export const SOURCE_TYPES_HELP = {
  MRSS: {
    title: "Other",
    text: (
      <SourceTypeContainer logoClass={styles.mrss}>
        <p>You can add MRSS feeds with VOD content and Livestreams.</p>
        <p>
          TV Snap 3.0 Template uses Vewd Media RSS Specification (VMRSS). For
          detailed description, please read{" "}
          <a
            href="https://developer.vewd.com/display/OTV/Vewd+Snap+Data+Format+Requirements"
            target="_blank"
            rel="noopener noreferrer"
          >
            the Vewd Snap Data Format Requirements page
          </a>
          .
        </p>
        <p>
          MRSS may contain more tags than TV Snap Template supports, but they
          will be ignored.
        </p>
        <p>
          Watch{" "}
          <a
            href="https://www.youtube.com/watch?v=hZQ2V5R92FI"
            target="_blank"
            rel="noopener noreferrer"
          >
            the video tutorial
          </a>{" "}
          for more on the submission process.
        </p>
      </SourceTypeContainer>
    ),
  },
  COMCAST: {
    title: "Comcast Technology Solutions",
    text: (
      <SourceTypeContainer logoClass={styles.comcast}>
        <p>
          TV Snap 3.0 Template uses Vewd Media RSS Specification (VMRSS). For
          detailed description, please read{" "}
          <a
            href="https://developer.vewd.com/display/OTV/Vewd+Snap+Data+Format+Requirements"
            target="_blank"
            rel="noopener noreferrer"
          >
            the Vewd Snap Data Format Requirements page
          </a>
          .
        </p>
        <p>
          MRSS may contain more tags than TV Snap Template supports, but they
          will be ignored.
        </p>
        <p>
          Watch{" "}
          <a
            href="https://www.youtube.com/watch?v=hZQ2V5R92FI"
            target="_blank"
            rel="noopener noreferrer"
          >
            the video tutorial
          </a>{" "}
          for more on the submission process.
        </p>
        <p>
          For help on how to generate a MRSS feed in Comcast dashboard, please
          contact Comcast customer support.
        </p>
      </SourceTypeContainer>
    ),
  },

  JWPLAYER: {
    title: "JWPlayer",
    text: (
      <SourceTypeContainer logoClass={styles.jwplayer}>
        <p>
          In order to create Vewd Snap application based on your JWPlayer
          account content, please check{" "}
          <a
            href="https://developer.vewd.com/display/OTV/How+to+generate+an+MRSS+feed+on+JW"
            target="_blank"
            rel="noopener noreferrer"
          >
            this tutorial
          </a>
          .
        </p>
      </SourceTypeContainer>
    ),
  },
  KALTURA: {
    title: "Kaltura",
    text: (
      <SourceTypeContainer logoClass={styles.kaltura}>
        <p>
          For help on how to generate an MRSS feed in Kaltura, please check{" "}
          <a
            href="https://knowledge.kaltura.com/setting-opera-tv-channel-kaltura-solution-using-opera-tv-snap"
            target="_blank"
            rel="noopener noreferrer"
          >
            the Kaltura documentation
          </a>
          .
        </p>
      </SourceTypeContainer>
    ),
  },
  OOYALA: {
    title: "Ooyala",
    text: (
      <SourceTypeContainer logoClass={styles.ooyala}>
        <p>
          In order to create Vewd Snap application based on your Ooyala content,
          please check our{" "}
          <a
            href="https://developer.vewd.com/display/OTV/How+to+generate+an+MRSS+feed+on+Ooyala"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ooyala MRSS guide
          </a>
          .
        </p>
      </SourceTypeContainer>
    ),
  },
  ZYPE: {
    title: "Zype",
    text: (
      <SourceTypeContainer logoClass={styles.zype}>
        <p>
          In order to create Vewd Snap application based on your Zype account
          content, please check{" "}
          <a
            href="https://support.zype.com/hc/en-us/articles/115001558068"
            target="_blank"
            rel="noopener noreferrer"
          >
            this tutorial
          </a>
          .
        </p>
        <p>
          For additional help with generating a compatible Vewd Snap feed,
          please contact your Zype representative.
        </p>
      </SourceTypeContainer>
    ),
  },
  IVS: {
    uppercaseTitle: false,
    title: "iVS",
    text: (
      <SourceTypeContainer logoClass={styles.ivs}>
        <p>
          In order to create Vewd Snap application based on your iVS account
          content, please check{" "}
          <a
            href="https://sites.google.com/ivideosmart.com/tutorials/create-vewd-application-from-existing-account"
            target="_blank"
            rel="noopener noreferrer"
          >
            this tutorial
          </a>
          .
        </p>
        <p>
          For additional help with generating a compatible Vewd Snap feed,
          please contact your iVS representative.
        </p>
      </SourceTypeContainer>
    ),
  },
  UNREEL: {
    title: "Unreel",
    text: (
      <SourceTypeContainer logoClass={styles.unreel}>
        <p>
          Please contact your Unreel representative for help in generating an
          MRSS feed.
        </p>
        <p>
          For detailed MRSS description, please read{" "}
          <a
            href="https://developer.vewd.com/display/OTV/Vewd+Snap+Data+Format+Requirements"
            target="_blank"
            rel="noopener noreferrer"
          >
            the Vewd Snap Data Format Requirements page
          </a>
          .
        </p>
      </SourceTypeContainer>
    ),
  },
};

export const DEFAULT_SOURCE = {
  title: "Hosting service",
  text: (
    <SourceTypeContainer>
      <p>
        Select the hosting service you are using in the Hosting service
        drop-down.
      </p>
    </SourceTypeContainer>
  ),
};
