// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { TextField } from "components/form/fields";
import { trans } from "src/translations";
import { pipeValidators, isUrl } from "utils/validation";

export default {
  name: trans.TEST_APP_EDIT__INFORMATION_SECTION_NAME(),
  components: [
    {
      component: TextField,
      fields: ["metadata.application_url"],
      validate: pipeValidators(isUrl("metadata.application_url")),
      props: () => ({
        placeholder: trans.URL_PLACEHOLDER(),
        name: "metadata.application_url",
        label: trans.APP_EDIT__URL_LABEL(),
        tooltip: trans.APP_EDIT__URL_LABEL_TOOLTIP(),
        dataTestId: "url-field",
      }),
    },
  ],
};
