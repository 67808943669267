// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Link } from "components/typography";

import styles from "./SnapState.scss";
import { KEYS } from "./useSnapState";

/*
Snap API states:
- NOT_SUBMITTED (default),
- SUBMISSION_STARTED,
- SUBMITTED,
- ACCEPTED,
- REJECTED

Snap Creation states:
- New (no revision)
- Draft (latest rev NOT_SUBMITTED)
- Active (ACCEPTED)
- Created (any other)
*/

export function SnapState({
  appId,
  appStates = [],
  style = "default",
  withLinks = false,
}) {
  return (
    <span className={styles[style]}>
      {appStates.map((state) =>
        appId && state.key !== KEYS.NEW && withLinks ? (
          <Link
            key={`appState-${state.key}`}
            className={styles[state.key.toLowerCase()]}
            to={`/snap/${appId}/details/${state.key.toLowerCase()}`}
          >
            {state.label}
          </Link>
        ) : (
          <span
            key={`appState-${state.key}`}
            className={styles[state.key.toLowerCase()]}
            data-test-id="app-status"
          >
            {state.label}
          </span>
        )
      )}
    </span>
  );
}

SnapState.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  appId: PropTypes.string,
  style: PropTypes.oneOf(["light", "default"]),
  withLinks: PropTypes.bool,
  appStates: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      revision: PropTypes.object,
    })
  ),
};
