// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { API_URLS } from "config";
import PropTypes from "prop-types";

import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";

import styles from "./Buttons.scss";

@withRouter
export class Buttons extends Component {
  static propTypes = {
    currentUserProfileId: PropTypes.string,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
  };

  logout = () => {
    location.href = `${
      API_URLS.AUTH_BAKER
    }/logout?target_uri=${encodeURIComponent(location.origin)}`;
  };

  goToUserProfile = () => {
    const { navigate, currentUserProfileId } = this.props;
    navigate(`/users/${currentUserProfileId}/details`);
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.button} onClick={this.goToUserProfile}>
          {trans.MY_ACCOUNT()}
        </div>
        <div className={styles.button} onClick={this.logout}>
          {trans.LOGOUT()}
        </div>
      </div>
    );
  }
}
