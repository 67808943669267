// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import description from "./descriptionSection";
import info from "./infoSection";
import media from "./mediaSection";

export default {
  name: trans.TEST_APP_EDIT__TAB(),
  url: "test-application",
  sections: [info, description, media],
  dataTestId: "test-application-tab",
};
