// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import styles from "./TableActions.scss";

/**
 * Place for table-related buttons like pagination or export button.
 * Should be placed before `<Table>` tag.
 */
export const TableActions = ({ children }) => {
  return <div className={styles.tableActions}>{children}</div>;
};

TableActions.propTypes = {
  children: PropTypes.node,
};

export default TableActions;
