// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { restrictedArea } from "containers/Permissions";
import { ALL_DISTRIBUTORS } from "containers/Permissions/groups";
import { api } from "containers/Request";
import { withQueryParams } from "utils/decorators";

import { MyDevicesRend } from "./MyDevicesRend";

@withQueryParams
@restrictedArea(() => ({
  allowed: ALL_DISTRIBUTORS,
}))
@connect(null, (dispatch) => ({
  getMyFamiliesPaginated: (queryParams) =>
    dispatch(
      api.getMyFamiliesPaginated.action({
        queryParams: {
          ...queryParams,
          include: ["device_models"],
          nocache: uuid(),
        },
      })
    ),
}))
export class MyDevicesData extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @connect
    getMyFamiliesPaginated: PropTypes.func.isRequired,
  };

  static defaultProps = {
    forAdmin: false,
  };

  state = {
    familiesData: [],
    count: 0,
    error: undefined,
    loading: 0,
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.updateData();
    }
  }

  updateData = async () => {
    const { getMyFamiliesPaginated, queryParams } = this.props;

    this.setState((prevState) => ({ loading: prevState.loading + 1 }));

    const familiesRequest = await getMyFamiliesPaginated(queryParams);

    if (familiesRequest.error) {
      this.setState((prevState) => ({
        error: familiesRequest.error,
        loading: prevState.loading - 1,
      }));
      return;
    }

    const familiesResults = familiesRequest.result.results;
    const familiesMeta = familiesRequest.result.meta;

    this.setState((prevState) => ({
      familiesData: familiesResults.map((f) => ({
        id: f.id,
        name: f.name,
        ownerId: f.owner.id,
        deviceModels: f.device_models.map((m) => ({
          id: m.id,
          name: m.name,
          isDisabled: !m.is_enabled,
          isTesting: m.is_testing,
        })),
      })),
      count: familiesMeta.count,
      error: undefined,
      loading: prevState.loading - 1,
    }));
  };

  render() {
    const { familiesData, count, error, loading } = this.state;
    const { forAdmin } = this.props;

    return (
      <MyDevicesRend
        familiesData={familiesData}
        count={count}
        error={error}
        loading={Boolean(loading)}
        refreshData={this.updateData}
        forAdmin={forAdmin}
      />
    );
  }
}
