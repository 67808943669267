// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, useState } from "react";

import { debounce } from "lodash-es";

import { useSearchParams } from "utils/hooks";

import { createSearchParams } from "../_utils";
import { AvailableFilters, Filter } from "../_utils/types";
import { SUGGESTIONS_REQUEST_DEBOUNCE } from "../constants";
import { useFilters } from "./useFilters";

const scheduleSuggestionsRefresh = debounce(
  async (searchBarValue: string, backendSuggestions) => {
    backendSuggestions.refreshSuggestions(searchBarValue);
  },
  SUGGESTIONS_REQUEST_DEBOUNCE
);

type UseSearchBar = (props: {
  availableFilters: AvailableFilters;
  backendSuggestions: any;
  onSearch?: (searchbarValue: string) => void;
  onChange?: (searchbarValue: string) => void;
}) => {
  searchValue: string;
  setSearchValue: (newSearchValue: string) => void;
  onSearchBarChangeHandler: (newSearchValue: string) => void;
  onSearchHandler: (searchValue: string, canStartTextSearch: boolean) => void;
  includeFilters: Filter[];
  excludeFilters: Filter[];
  addFilter: (searchValue: string) => void;
  removeFilter: (filterIdx: number) => void;
};

export const useSearchBar: UseSearchBar = ({
  onChange,
  onSearch,
  backendSuggestions,
  availableFilters,
}) => {
  const searchParams = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    (searchParams?.search as string) || ""
  );

  const { includeFilters, excludeFilters, addFilter, removeFilter } =
    useFilters({
      availableFilters,
    });

  const startNewSearch = () => {
    backendSuggestions.abortFetchingSuggestions();
    if (onSearch) {
      const newSearchParams = createSearchParams(searchParams, {
        value: searchValue,
        filters: includeFilters,
        exclusionFilters: excludeFilters,
      });

      onSearch(newSearchParams);
    }
  };

  const onSearchBarChangeHandler = (newSearchValue: string) => {
    setSearchValue(newSearchValue);
    backendSuggestions.abortFetchingSuggestions(newSearchValue);

    if (onChange) {
      onChange(newSearchValue);
    }
  };

  const onSearchHandler = (
    searchValue: string,
    canStartTextSearch: boolean
  ) => {
    const isFilterAdded = addFilter(searchValue);
    const canPerformTextSearch =
      canStartTextSearch && !backendSuggestions.isLoadingSuggestions;

    if (isFilterAdded) {
      setSearchValue("");
      return;
    }

    if (canPerformTextSearch) {
      startNewSearch();
    }
  };

  useEffect(() => {
    startNewSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeFilters.length, excludeFilters.length]);

  useEffect(() => {
    scheduleSuggestionsRefresh(searchValue, backendSuggestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return {
    searchValue,
    setSearchValue,
    onSearchBarChangeHandler,
    onSearchHandler,
    addFilter,
    removeFilter,
    includeFilters,
    excludeFilters,
  };
};
