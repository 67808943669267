// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createContext } from "react";

import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { THEMES, useEditorTheme } from "components/elements";
import { getManifestQuery } from "pages/AppManifests/_shared/gql";
import { base64ToText, textToBase64, urlToBase64 } from "utils/converters";
import { initialFile } from "utils/formik/utils";
import { stripTypename } from "utils/graphql";
import { DEFAULT_APOLLO_OPTIONS } from "utils/graphql/constants";

import { APP_TYPE } from "../../Applications/AppDetails/constants";
import {
  BOOL_FIELDS_CONVERSION,
  FIELDS,
  ENCODED_FIELDS_KEYS,
  IMAGE_FIELD_KEYS,
} from "./constants";

export const ManifestDataContext = createContext({ results: {} });
export const EditorThemeContext = createContext({
  editorTheme: THEMES.dark,
  toggleTheme: () => {},
});

const getResult = (data) => {
  const manifest = stripTypename(data?.manifest?.manifest);
  const id = data?.manifest?.id;
  return manifest && id
    ? {
        id,
        manifest,
      }
    : null;
};

export const adaptDataToForm = (data) => {
  const formData = { ...data }; // shallow copy
  // null to empty string (we don't want to have uncontrolled inputs)
  Object.keys(formData).forEach((key) => {
    if (formData[key] === null) {
      formData[key] = FIELDS[key].type === "array" ? [] : "";
    }
  });
  BOOL_FIELDS_CONVERSION.forEach((key) => {
    if (typeof formData[key] === "boolean") {
      formData[key] = JSON.stringify(formData[key]);
    }
  });
  ENCODED_FIELDS_KEYS.forEach((key) => {
    if (formData[key]) {
      formData[key] = base64ToText(formData[key]);
    }
  });
  for (const key of IMAGE_FIELD_KEYS) {
    formData[key] = initialFile(formData[key] || "", key, 0);
  }

  return formData;
};

export const adaptDataToRequest = async (formData) => {
  const data = Object.assign({}, formData); // shallow copy
  // empty strings and arrays to undefined, as backend does not like empty data
  Object.keys(data).forEach((key) => {
    if (data[key] === "" || (data[key] && data[key].length === 0)) {
      delete data[key];
    }
  });
  BOOL_FIELDS_CONVERSION.forEach((key) => {
    if (data[key]) {
      data[key] = JSON.parse(data[key]);
    } else {
      delete data[key];
    }
  });
  ENCODED_FIELDS_KEYS.forEach((key) => {
    if (data[key]) {
      data[key] = textToBase64(data[key]);
    }
  });
  for (const key of IMAGE_FIELD_KEYS) {
    const notPlaceholderValue = data[key]?.url;
    if (notPlaceholderValue) {
      data[key] = await urlToBase64(notPlaceholderValue);
    } else {
      delete data[key];
    }
  }
  if (!Object.keys(stripTypename(data)).length) {
    return null;
  }
  return data;
};

export const ManifestData = ({ appId, appType, children }) => {
  const { editorTheme, toggleTheme } = useEditorTheme();
  const { loading, error, data } = useQuery(getManifestQuery, {
    ...DEFAULT_APOLLO_OPTIONS,
    variables: {
      applicationId: appId,
    },
  });

  return (
    <ManifestDataContext.Provider
      value={{ appId, appType, results: getResult(data), loading, error }}
    >
      <EditorThemeContext.Provider value={{ editorTheme, toggleTheme }}>
        {children}
      </EditorThemeContext.Provider>
    </ManifestDataContext.Provider>
  );
};

ManifestData.propTypes = {
  appId: PropTypes.string.isRequired,
  appType: PropTypes.oneOf(Object.values(APP_TYPE)),
  children: PropTypes.node.isRequired,
};
