// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const listDeviceFiltersQuery = gql`
  query deviceFilters(
    $pagination: PaginationInput
    $sort: DeviceFiltersSortInput
    $search: SearchInput
  ) {
    deviceFilters(pagination: $pagination, sort: $sort, search: $search) {
      deviceFilters {
        items {
          id
          name
          creationDate
          filtersCount
        }
        pagination {
          count
        }
      }
    }
  }
`;
