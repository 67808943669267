// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import styles from "./uploaderPreview.scss";

export class UploaderPreview extends Component {
  static propTypes = {
    icon_small: PropTypes.string,
    icon: PropTypes.string,
    thumbnail: PropTypes.string,
    billboard: PropTypes.string,
    screenshot: PropTypes.string,
  };

  render() {
    const { icon, thumbnail, billboard, screenshot, icon_small } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.background}>
          {icon_small ? (
            <img className={styles.smallIcon} src={icon_small} />
          ) : null}
          {icon ? <img className={styles.icon} src={icon} /> : null}
          {thumbnail ? (
            <img className={styles.thumbnail} src={thumbnail} />
          ) : null}
          {billboard ? (
            <img className={styles.billboard} src={billboard} />
          ) : null}
          {screenshot ? (
            <div className={styles.screenshot}>
              <img className={styles.screenshotImg} src={screenshot} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
