// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { ROLES } from "containers/Permissions";

export { allowInOrganization } from "./allowInOrganization";
export { isAllowedToEditUser } from "./isAllowedToEditUser";

export const ALL_USERS = []; // even those without organization

export const ALL_ORGANIZATION_MEMBERS = [ROLES.organization.member];

export const ALL_PROVIDERS = Object.values(ROLES.provider);

export const ALL_DISTRIBUTORS = [
  ROLES.distributor.readOnlyDistributionPlanner,
  ROLES.distributor.distributionPlanner,
  ROLES.distributor.restrictedDistributionPlanner,
];

export const ALL_DISTRIBUTION_PLANNERS = [
  ROLES.distributor.distributionPlanner,
  ROLES.distributor.restrictedDistributionPlanner,
];

export const ALL_MODERATORS = Object.values(ROLES.moderator);
