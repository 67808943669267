// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const listUpdateJobsQuery = gql`
  query updateJobs(
    $pagination: PaginationInput
    $search: UpdateJobsSearchInput
    $sort: UpdateJobsSortInput
  ) {
    updateJobs(pagination: $pagination, search: $search, sort: $sort) {
      updateJobs {
        items {
          id
          name
          activeStepId
          status
          statusActions
          isArchived
          creationDate
          downloadUrl
          isRepeatable
          steps {
            id
          }
        }
        pagination {
          count
        }
      }
    }
  }
`;
