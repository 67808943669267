// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { findDOMNode } from "react-dom";

import { throttle } from "lodash-es";
import * as pdfjs from "pdfjs-dist/webpack";
import PropTypes from "prop-types";

const SET_DIMENSIONS_THROTTLE = 300;

export class PDFPage extends Component {
  static propTypes = {
    page: PropTypes.object,
  };

  state = {
    width: 0,
    height: 0,
    viewport: null,
  };

  setDimensions = throttle(
    () => {
      const { page } = this.props;
      if (!page || !this.page) {
        return;
      }
      const { offsetWidth: width } = findDOMNode(this.page);
      const viewport = page.getViewport({
        scale: width / page.getViewport({ scale: 1.0 }).width,
      });
      this.setStateSafely({ width, height: viewport.height, viewport });
    },
    SET_DIMENSIONS_THROTTLE,
    { tailing: false, leading: false }
  );

  componentDidMount() {
    this.isStillMounted = true;
    window.addEventListener("resize", this.setDimensions);
    this.setDimensions();
  }

  componentWillUnmount() {
    this.isStillMounted = false;
    window.removeEventListener("resize", this.setDimensions);
  }

  async componentDidUpdate() {
    const { page } = this.props;
    const { viewport } = this.state;
    if (!viewport || !page) {
      return;
    }
    const canvasContext = findDOMNode(this.canvas).getContext("2d");
    const container = findDOMNode(this.textLayer);
    const textContentSource = await page.getTextContent();
    canvasContext.clearRect(0, 0, viewport.width, viewport.height);
    page.render({ canvasContext, viewport });
    pdfjs.renderTextLayer({ textContentSource, viewport, container });
  }

  setStateSafely(state) {
    if (this.isStillMounted) {
      this.setState(state);
    }
  }

  render() {
    const { width, height } = this.state;

    return (
      <div
        ref={(c) => {
          this.page = c;
        }}
        style={{ position: "relative" }}
      >
        <canvas
          ref={(c) => {
            this.canvas = c;
          }}
          width={width}
          height={height}
        />
        <div
          ref={(c) => {
            this.textLayer = c;
          }}
          className="textLayer"
        />
      </div>
    );
  }
}
