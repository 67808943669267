// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { classes } from "utils/classes";

import { TableRow } from "./TableRow";
import styles from "./TableRow.scss";

class TableRowDraggable extends TableRow {
  static propTypes = {
    dataTestId: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    handleDragEnd: PropTypes.func,
    handleDragStart: PropTypes.func.isRequired,
    handleDragEnter: PropTypes.func,
    handleDrop: PropTypes.func,
    handleDragLeave: PropTypes.func,
    highlighting: PropTypes.bool,
  };

  static defaultProps = {
    dataTestId: "draggable-table-row",
    highlighting: true,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      dataTestId,
      children,
      className,
      handleDragStart,
      handleDrop,
      handleDragEnter,
      handleDragLeave,
      handleDragEnd,
      highlighting,
    } = this.props;

    return (
      <tr
        data-test-id={dataTestId}
        draggable
        onDragEnd={handleDragEnd}
        onDragLeave={handleDragLeave}
        onDragEnter={handleDragEnter}
        onDragStart={handleDragStart}
        onDrop={handleDrop}
        className={classes(styles.container, className, {
          [styles.highlight]: highlighting,
        })}
      >
        {children}
      </tr>
    );
  }
}

export { TableRowDraggable };
