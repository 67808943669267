// Copyright © 2024 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const updateJobLogsQuery = gql`
  query updateJobLogs($updateJobId: ID!) {
    updateJobLogs(updateJobId: $updateJobId) {
      updateJobLogs {
        createdAt
        presignedUrl
        stages
      }
    }
  }
`;
