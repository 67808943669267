// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as React from "react";

/**
 * Function returns an implementation of shouldComponentUpdate.
 *
 * When reference of provided keys changes, shouldComponentUpdate returs true, false otherwise.
 * Useful when comparing props or state that uses immutable pattern. (Redux store, immutable.js, etc)
 *
 * @param propsKeys List of prop keys that when change will trigger component to update
 * @param stateKeys List of state keys that when change will trigger component to update
 * @return implementation of shouldComponentUpdate
 *
 * @example
 * class Example extends Component {
 *   ...
 *   shouldComponentUpdate = whenOneChange(['propkey'], ['statekey'])
 *   ...
 * }
 */
export const whenOneChange = (
  propsKeys: string[],
  stateKeys: string[] = []
) => {
  /* eslint-disable  babel/no-invalid-this */
  return function <
    TProps extends Record<string, unknown> = Record<string, unknown>,
    TState extends Record<string, unknown> = Record<string, unknown>,
  >(
    this: React.Component<TProps, TState>,
    newProps: TProps,
    newState: TState
  ): boolean {
    for (const key of propsKeys) {
      if (newProps[key] !== this.props[key]) {
        return true;
      }
    }
    for (const key of stateKeys) {
      if (newState[key] !== this.state[key]) {
        return true;
      }
    }
    /* eslint-enable babel/no-invalid-this */
    return false;
  };
};
