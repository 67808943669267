// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import intersection from "lodash-es/intersection";
import pick from "lodash-es/pick";
import pickBy from "lodash-es/pickBy";
import PropTypes from "prop-types";

import { apiThatThrows } from "containers/Request";
import { createObjectWithKeys } from "utils/object";

import { AddToCustomPlanModalRend } from "./AddToCustomPlanModalRend";

export const ITEMS_PER_PAGE = 10;
const PLAN_STATE_NEVER_CHANGED = undefined;

const DEFAULT_STATE = {
  selectedApps: {},
  changedPlans: {},
  isSubmitting: false,
  submitError: null,
  loading: false,
  error: undefined,
  applications: null,
};

@connect(null, (dispatch) => ({
  getPlansPaginated: (queryParams) =>
    dispatch(
      apiThatThrows.getMyDistributionPlansPaginated.action({
        queryParams: {
          ...queryParams,
          limit: ITEMS_PER_PAGE,
        },
      })
    ),
  getMyDistributionApplications: (appIds) =>
    dispatch(
      apiThatThrows.getMyDistributionApplicationsPaginated.action({
        queryParams: { id: appIds, limit: appIds.length },
      })
    ),
}))
export class AddToCustomPlanModalData extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedApplications: PropTypes.arrayOf(PropTypes.string).isRequired,

    // from @connect
    getPlansPaginated: PropTypes.func.isRequired,
    getMyDistributionApplications: PropTypes.func.isRequired,
  };

  state = {
    ...DEFAULT_STATE,
  };

  planToInitialAppsMap = new Map();

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.resetModalState();
      this.loadAppsList();
    }
  }

  resetModalState() {
    this.setState({ ...DEFAULT_STATE });
  }

  async loadAppsList() {
    const { getMyDistributionApplications, selectedApplications } = this.props;

    try {
      this.setState({ loading: true, error: undefined });

      const { results } =
        await getMyDistributionApplications(selectedApplications);
      const appIds = results.map((app) => app.id);
      this.setState({
        loading: false,
        applications: results.map((app) => pick(app, ["id", "icon", "name"])),
        selectedApps: createObjectWithKeys(appIds, true),
      });
    } catch (error) {
      this.setState({ loading: false, error });
    }
  }

  fetchData = (queryParams) => {
    const { getPlansPaginated } = this.props;
    return getPlansPaginated(queryParams);
  };

  mapData = (data) => {
    const { selectedApplications } = this.props;

    const getAppsForPlan = (plan) => {
      const planApplications = plan.applications.map((app) => app.id);
      return intersection(selectedApplications, planApplications);
    };

    return data.map((plan) => {
      const initialApps = getAppsForPlan(plan);
      this.planToInitialAppsMap.set(plan.id, initialApps);

      return {
        id: plan.id,
        name: plan.label,
      };
    });
  };

  isAppChecked = (appId) => {
    const { selectedApps } = this.state;
    return Boolean(selectedApps[appId]);
  };

  isAppChangeDisabled(nextIsChecked) {
    const selectedAppsCount = this.getSelectedAppIds().length;
    return selectedAppsCount === 1 && !nextIsChecked;
  }

  setAppChecked = (appId, nextValue) => {
    if (this.isAppChangeDisabled(nextValue)) {
      return;
    }

    this.setState((prevState) => ({
      selectedApps: {
        ...prevState.selectedApps,
        [appId]: Boolean(nextValue),
      },
    }));
  };

  toggleMany = (planIds, value) => {
    this.setState((prevState) => {
      return {
        changedPlans: {
          ...prevState.changedPlans,
          ...createObjectWithKeys(planIds, value),
        },
      };
    });
  };

  getPlanSelectionState = (selectedAppIds, planId) => {
    const { changedPlans } = this.state;
    const checked = changedPlans[planId];

    // calculate state if the plan's checkbox was never changed (pristine)
    const initialApps = this.planToInitialAppsMap.get(planId);
    const selectedCount = intersection(selectedAppIds, initialApps).length;
    const planHadAllApps = selectedCount >= selectedAppIds.length;
    const preChecked = selectedAppIds.length > 0 && planHadAllApps;
    const wasIndeterminate = selectedCount > 0 && !planHadAllApps;

    if (checked === PLAN_STATE_NEVER_CHANGED) {
      return {
        id: planId,
        checked: preChecked,
        indeterminate: wasIndeterminate,
        dirty: false,
        selectedCount,
      };
    } else {
      return {
        id: planId,
        checked,
        indeterminate: false,
        dirty: wasIndeterminate || checked !== preChecked,
        selectedCount: checked ? selectedAppIds.length : 0,
      };
    }
  };

  getChangedPlanIds(selectedAppIds) {
    const { changedPlans } = this.state;

    const planSelectionStates = Object.keys(changedPlans).map((planId) =>
      this.getPlanSelectionState(selectedAppIds, planId)
    );
    const changedPlanIds = planSelectionStates.filter((p) => p.dirty);

    const getPlansByCheckedState = (state) =>
      changedPlanIds.filter((p) => p.checked === state).map((p) => p.id);

    return {
      plansAdded: getPlansByCheckedState(true),
      plansRemoved: getPlansByCheckedState(false),
    };
  }

  getSelectedAppIds() {
    const { selectedApps } = this.state;
    return Object.keys(pickBy(selectedApps, (value) => Boolean(value)));
  }

  handleSubmit = async () => {
    const { onSubmit, onClose } = this.props;

    this.setState({
      isSubmitting: true,
    });

    try {
      const selectedAppIds = this.getSelectedAppIds();
      const changedPlans = this.getChangedPlanIds(selectedAppIds);

      await onSubmit(selectedAppIds, changedPlans);

      this.resetModalState();
      onClose(true);
    } catch (error) {
      this.setState({
        submitError: error,
        isSubmitting: false,
      });
    }
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { isSubmitting, submitError, applications, loading, error } =
      this.state;

    const selectedAppIds = this.getSelectedAppIds();
    const changedPlans = this.getChangedPlanIds(selectedAppIds);

    return (
      <AddToCustomPlanModalRend
        isOpen={isOpen}
        loading={loading}
        error={error}
        submitError={submitError}
        applications={applications}
        isSubmitting={isSubmitting}
        isAppChecked={this.isAppChecked}
        setAppChecked={this.setAppChecked}
        selectedApps={selectedAppIds}
        addedCount={changedPlans.plansAdded.length}
        removedCount={changedPlans.plansRemoved.length}
        toggleMany={this.toggleMany}
        fetchData={this.fetchData}
        mapData={this.mapData}
        onClose={onClose}
        onSubmit={this.handleSubmit}
        getPlanSelectionState={this.getPlanSelectionState}
      />
    );
  }
}
