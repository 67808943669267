// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { whenOneChange } from "utils/component";

export class LazyComponent extends Component {
  static propTypes = {
    component: PropTypes.func,
  };

  state = {
    loaded: false,
    component: null,
  };

  shouldComponentUpdate = whenOneChange(["component"], ["component"]);

  async componentDidMount() {
    const { component } = this.props;
    this.setState({ component: await component(), loaded: true });
  }

  async componentDidUpdate(oldProps) {
    if (oldProps.component === this.props.component) {
      return;
    }

    const { component } = this.props;
    this.setState({ component: await component(), loaded: true });
  }

  render() {
    const { component: componentPromise, ...props } = this.props;
    const { component = {}, loaded } = this.state;

    if (!componentPromise || !loaded) {
      return <div />;
    }

    const Component = component.default || component;

    if (!Component) {
      throw new Error(
        "`component` prop resolved to something different than a react component"
      );
    }

    return <Component {...props} />;
  }
}
