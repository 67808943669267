// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import set from "lodash-es/set";

import { validateVersionNumber } from "./validateVersionNumber";
import { validateVersionUrl } from "./validateVersionUrl";

export const validateForm = (allValues) => {
  const errors = [];

  allValues.versions.forEach((version, idx) => {
    const numberError = validateVersionNumber(
      allValues,
      `versions[${idx}].number`
    );
    const urlError = validateVersionUrl(allValues, `versions[${idx}].url`);

    if (numberError) {
      set(errors, `[${idx}].number`, numberError);
    }

    if (urlError) {
      set(errors, `[${idx}].url`, urlError);
    }
  });

  if (errors.length === 0) {
    return undefined;
  }

  return { versions: errors };
};
