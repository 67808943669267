// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { capitalize } from "utils/string";

interface Translation {
  language: string;
  default_translation: boolean;
  code: string;
  name: string;
}

export const getDefaultTranslationIdx = (
  translations: Translation[]
): number => {
  const idx = translations.findIndex(
    (translation) => translation.default_translation
  );

  if (idx < 0) {
    throw new Error("Cannot find default translation!");
  }

  return idx;
};

export const sortLanguages = (languages: Translation[]): Translation[] => {
  const result = [...languages];
  return result.sort((a, b) => {
    const aName = capitalize(a.name);
    const bName = capitalize(b.name);
    return aName.localeCompare(bName);
  });
};

interface Language {
  code: string;
  name: string;
}

export const getTranslationLanguageName = (
  languages: Language[],
  translation: Translation
): string => {
  const lang = languages.find((lang) => lang.code === translation.language);
  const langName = lang ? lang.name : "";
  return capitalize(langName);
};

export const sortTranslations = (
  languages: Language[],
  translations: Translation[]
): Translation[] => {
  const result = [...translations];
  return result.sort((a, b) => {
    const aName = getTranslationLanguageName(languages, a);
    const bName = getTranslationLanguageName(languages, b);
    return aName.localeCompare(bName);
  });
};
