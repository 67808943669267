// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { sharedDefaultValues } from "src/configureStore";
import { initialFile } from "utils/formik/utils";

import { AGREEMENT_VERSION, DISTRIBUTION_TYPES } from "../constants";

export const MAX_SCREENSHOT_NUMBER = 6;

export const createAddScreenshotBtn = () =>
  initialFile("", "screenshot", "add-new-screenshot");

const getScreenshotFieldsCount = (screenshot) => {
  // We add additional screenshot that has no value.
  // It will be displayed as 'add new screenshot' button,
  // which simplifies logic for when fields are pristine
  // (pristine is just deepEquals between initial and current values)
  return Math.min(screenshot.length + 1, MAX_SCREENSHOT_NUMBER);
};

const createScreenshotValues = (screenshot) => {
  const createScreenshot = (idx) => {
    const item = screenshot[idx];
    if (item && item.url) {
      return initialFile(item.url, "screenshot", item.id);
    }

    return createAddScreenshotBtn();
  };

  return [...new Array(getScreenshotFieldsCount(screenshot)).keys()].map(
    createScreenshot
  );
};

// DO NOT MUTATE DATA IN THIS FUNCTION!!!
export const parseInitialData = (data, isDraft = false) => {
  let result = {
    ...data,
    metadata: { ...data.metadata },
  };

  const translations = result?.metadata?.translations;
  const icon_small = result?.metadata?.icon_small;
  const icon_big = result?.metadata?.icon_big;
  const thumbnail = result?.metadata?.thumbnail;
  const billboard = result?.metadata?.billboard;
  const screenshot = result?.metadata?.screenshot ?? [];
  const developers = result?.developers;

  result = {
    ...result,
    metadata: {
      ...result.metadata,
      icon_small: initialFile(icon_small, "icon_small"),
      icon_big: initialFile(icon_big, "icon_big"),
      thumbnail: initialFile(thumbnail, "thumbnail"),
      billboard: initialFile(billboard, "billboard"),
      screenshot: createScreenshotValues(screenshot),
      contains_video: result?.metadata?.contains_video ? 1 : 0,
    },
  };

  if (translations && Array.isArray(translations) && !translations.length) {
    result = {
      ...result,
      metadata: {
        ...result.metadata,
        translations: [
          ...translations,
          {
            default_translation: true,
            language: sharedDefaultValues.defaultLanguageCode,
            name: "",
            short_description: "",
          },
        ],
      },
    };
  }

  if (developers && Array.isArray(developers)) {
    result = {
      ...result,
      developers: developers.map((developer) => developer.id),
    };
  }

  result = {
    ...result,
    metadata: {
      ...result.metadata,
      distribute_in_tvstore:
        data.metadata?.distribute_in_tvstore ??
        DISTRIBUTION_TYPES.VEWD_CATALOGUE,
      agreement_version: AGREEMENT_VERSION,
      terms_accepted: false,
      accepted_criteria: false,
      produce_revenue_value: data.metadata?.produce_revenue_value ?? {},
      metadata_video_formats: data.metadata?.metadata_video_formats ?? [],
      metadata_video_drms: data.metadata?.metadata_video_drms ?? [],
      metadata_video_adaptive_streamings:
        data.metadata?.metadata_video_adaptive_streamings ?? [],
    },
  };

  // clear additional information to force a change
  if (!isDraft) {
    result = {
      ...result,
      changelog: {
        ...result.changelog,
        additional_information: "",
      },
    };
  }

  return result;
};
