// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useMemo } from "react";

import { useField } from "formik";

import { createMixedValidator } from ".";
import { Input } from "../elements";
import { FieldProps } from "./propTypes";

export const TextField = (props) => {
  const { name, required = true, validate: validateFn, ...rest } = props;

  const validate = useMemo(
    () => createMixedValidator(name, required, validateFn),
    [name, required, validateFn]
  );

  const [field, meta] = useField({
    ...props,
    required,
    validate,
    type: "text",
  });

  return <Input required={required} props={field} {...meta} {...rest} />;
};

TextField.propTypes = FieldProps;
