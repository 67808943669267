// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { parseItemErrorMessage } from "./parseItemErrorMessage";

export const adaptProcessingQueueItem = (item) => {
  const error = parseItemErrorMessage(item.errorMessage);
  let finalStatus = item.status;
  if (item.status === "VALIDATION_ERROR" && error?.status) {
    finalStatus = error.status;
  }
  return {
    ...item,
    status: finalStatus,
    errorMessage: error?.message,
  };
};
