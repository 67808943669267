// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { TextareaField } from "components/form/fields";
import { trans } from "src/translations";

export default {
  name: trans.APP_EDIT_FOR_MODERATORS__ADDITIONAL_COMMENTS_SECTION_NAME(),
  components: [
    {
      component: TextareaField,
      fields: ["metadata.additional_comments"],
      initialValues: { metadata: { additional_comments: "" } },
      props: () => {
        return {
          name: "metadata.additional_comments",
          required: false,
          label: trans.APP_EDIT_FOR_MODERATORS__ADDITIONAL_COMMENTS_LABEL(),
          tooltip:
            trans.APP_EDIT_FOR_MODERATORS__ADDITIONAL_COMMENTS_LABEL_TOOLTIP(),
          dataTestId: "additional-comments-field",
        };
      },
    },
  ],
};
