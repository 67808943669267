// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const updateJobPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusActions: PropTypes.arrayOf(PropTypes.string).isRequired,
  creationDate: PropTypes.instanceOf(Date),
  updateStep: PropTypes.string.isRequired,
  isRepeatable: PropTypes.bool.isRequired,
});

export const resultsPropTypes = PropTypes.arrayOf(updateJobPropTypes);
