// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

/*interface FieldProps {
  name: string;
  label: string;
  value: string | number;
  required?: boolean
  disabled?: boolean;
  dataTestId?: string;
  className?: string;
  tooltip?: string;
  validate?: function;
  children?: React.ReactNode;
}

interface RadioGroupProps extends FieldProps {
  options: FieldProps
}*/

export const FieldProps = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  look: PropTypes.oneOf(["wrapped", "simple"]),
  tooltip: PropTypes.string,
  validate: PropTypes.func,
  children: PropTypes.node,
};

const { id, look, name, value, ...FieldNestedProps } = FieldProps;

export const FieldWithOptionsProps = {
  ...FieldProps,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      ...FieldNestedProps,
    })
  ),
};
