// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Avatar } from "components/images";
import { TableRow, TableRowColumn } from "components/layout";
import { classes } from "utils/classes";
import { formatDate } from "utils/date";

import styles from "./MessageHeadersRow.scss";

export class MessageHeadersRow extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    expanded: PropTypes.bool.isRequired,
    setExpanded: PropTypes.func.isRequired,
    authorName: PropTypes.string.isRequired,
    authorAvatar: PropTypes.string,
    date: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  };

  onRowClicked = () => {
    const { setExpanded, id, expanded } = this.props;
    if (!expanded) {
      setExpanded(id, true);
    }
  };

  render() {
    const { authorName, authorAvatar, date, type, className, expanded } =
      this.props;
    return (
      <TableRow
        className={classes(
          className,
          styles.TableRow,
          expanded ? styles.TableRowExpanded : styles.TableRowCollapsed
        )}
        onClick={this.onRowClicked}
      >
        <TableRowColumn>
          <Avatar
            className={styles.AuthorAvatar}
            src={authorAvatar}
            size="24px"
            round={true}
          />
          <span className={styles.AuthorName}>{authorName}</span>
        </TableRowColumn>
        <TableRowColumn className={styles.Type}>{type}</TableRowColumn>
        <TableRowColumn className={styles.Date}>
          {formatDate(new Date(date))}
        </TableRowColumn>
      </TableRow>
    );
  }
}
