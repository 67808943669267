// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { PageContent, PageHeader } from "components/layout";
import { trans } from "src/translations";

import { DeviceQueriesData } from "./DeviceQueriesData";

export class DeviceQueries extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>
          {trans.DEVICES__DEVICE_FILTERS_AND_QUERIES_HEADER()}
        </PageHeader>
        <PageContent>
          <DeviceQueriesData />
        </PageContent>
      </div>
    );
  }
}
