// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import isEqual from "lodash-es/isEqual";
import pick from "lodash-es/pick";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import {
  CheckAllButton,
  SelectedCountFooter,
  SearchableList,
} from "components/elements";
import { Radio } from "components/form/elements";
import { Modal } from "components/layout";
import { SubTabs, SubTab, TabContent } from "components/navigation";
import { api } from "containers/Request";
import { trans } from "src/translations";
import {
  CONTINENT_WORLD,
  withCountries,
  getContinentNames,
  getCountriesForContinent,
} from "utils/continents";
import { createObjectWithKeys } from "utils/object";

import * as actions from "./action";
import { CountryRow } from "./CountryRow";
import styles from "./ModalRegions.scss";

const RADIO_WORLDWIDE = "worldwide";
const RADIO_COUNTRIES = "countries";
const ERR_CODE_VERSION_CONFLICT = "sav_country_publication_conflict";

@connect(
  ({ addDeviceRegions }) => ({ addDeviceRegions }),
  (dispatch) => ({
    addDeviceRegionsInactive: () =>
      dispatch(actions.addDeviceRegionsInactive()),
    postAssetRegions: (assetId, assetVersion, deviceModel, options) =>
      dispatch(
        api.postAssetRegions.action({
          params: { assetId, assetVersion, deviceModel },
          options,
        })
      ),
    postAssetRegionsWorldwide: (assetId, assetVersion, deviceModel, options) =>
      dispatch(
        api.postAssetRegionsWorldwide.action({
          params: { assetId, assetVersion, deviceModel },
          options,
        })
      ),
  })
)
@withCountries
export class ModalRegions extends Component {
  static propTypes = {
    // from @connect
    addDeviceRegions: PropTypes.object,
    addDeviceRegionsInactive: PropTypes.func,
    postAssetRegions: PropTypes.func,
    postAssetRegionsWorldwide: PropTypes.func,

    // from @withCountries
    allCountries: PropTypes.array.isRequired,
    countriesError: PropTypes.object,
  };

  state = {
    radioValue: RADIO_COUNTRIES,
    continent: CONTINENT_WORLD,
    countries: {},
    publishing: false,
    error: null,
  };

  componentDidUpdate(prevProps) {
    const getRegionFromProps = (props) =>
      pick(props.addDeviceRegions.model, ["countries", "isWorldwide"]);

    const regions = getRegionFromProps(this.props);
    const prevRegions = getRegionFromProps(prevProps);

    if (!isEqual(regions, prevRegions)) {
      const { countries, isWorldwide } = regions;
      this.setState({
        countries: isWorldwide ? [] : createObjectWithKeys(countries, true),
        radioValue: isWorldwide ? RADIO_WORLDWIDE : RADIO_COUNTRIES,
      });
    }
  }

  handleRadioChange = (e) => {
    this.setState({ radioValue: e.target.value });
  };

  handleTabClick = (tab) => {
    this.setState({ continent: tab.text });
  };

  handleCountryChange = (country, value) => {
    this.setState((prevState) => ({
      countries: {
        ...prevState.countries,
        [country.code]: value,
      },
    }));
  };

  toggleMany = (items) => (value) => {
    const codes = items.map((c) => c.code);
    this.setState((prevState) => ({
      countries: {
        ...prevState.countries,
        ...createObjectWithKeys(codes, value),
      },
    }));
  };

  isCountryChecked = (country) => {
    const { countries } = this.state;
    return Boolean(countries[country.code]);
  };

  handlePublish = async () => {
    const {
      addDeviceRegions: { asset, version, model },
      postAssetRegions,
      addDeviceRegionsInactive,
      postAssetRegionsWorldwide,
    } = this.props;

    const { countries, radioValue } = this.state;

    let rsp;
    if (radioValue === RADIO_WORLDWIDE) {
      const body = {};

      this.setState({ publishing: true });

      rsp = await postAssetRegionsWorldwide(asset.id, version, model.id, {
        body,
      });
    } else {
      const body = JSON.stringify({
        data: Object.entries(countries).map(([countryCode, isChecked]) => {
          return {
            type: "country_alpha2",
            id: countryCode,
            attributes: {
              is_available: isChecked,
            },
          };
        }),
      });

      this.setState({ publishing: true });

      rsp = await postAssetRegions(asset.id, version, model.id, {
        body,
      });
    }

    this.setState({
      publishing: false,
      error: rsp.error,
    });

    if (!rsp.error) {
      addDeviceRegionsInactive();
    }
  };

  getSelectedCountries() {
    const { allCountries } = this.props;
    const { radioValue, countries } = this.state;

    if (radioValue === RADIO_WORLDWIDE) {
      return allCountries.map((country) => country.code);
    }
    return Object.entries(countries).reduce((acc, [code, checked]) => {
      if (checked) {
        acc.push(code);
      }
      return acc;
    }, []);
  }

  handleCancel = () => {
    const { addDeviceRegionsInactive } = this.props;
    addDeviceRegionsInactive();
    this.setState({ error: null });
  };

  renderConflictError = (err) => {
    const { allCountries } = this.props;

    const getCountryName = (code) => {
      const country = allCountries.find((c) => c.code === code);
      return get(country, "name", code);
    };

    const renderConflict = (conflict) => {
      const { countries_alpha2: countries } = conflict;
      const countryCnt =
        countries.length === 1
          ? `${trans.COUNTRY()} `
          : `${trans.COUNTRIES()} `;

      return (
        <div key={conflict.version} className={styles.errorMessage}>
          {trans.ASSET__VERSION_CONFLICT_ERR_MSG({
            version: <b>{conflict.version}</b>,
            countryCnt,
          })}
          <b>{countries.map(getCountryName).join(", ")}</b>
        </div>
      );
    };

    return err.meta.version_conflicts.map(renderConflict);
  };

  renderError(error) {
    if (error.serverErrors && error.serverErrors.length) {
      return error.serverErrors.map((err, i) => {
        if (err.code === ERR_CODE_VERSION_CONFLICT) {
          return this.renderConflictError(err);
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className={styles.errorMessage}>
            {err.title || err.detail}
          </div>
        );
      });
    }

    return <div className={styles.errorMessage}>{error.message}</div>;
  }

  render() {
    const {
      addDeviceRegions: { asset, isActive },
      allCountries,
      countriesError,
    } = this.props;
    const { radioValue, continent, publishing } = this.state;
    const selectedCount = this.getSelectedCountries().length;

    const error = this.state.error || countriesError;
    if (error) {
      return (
        <Modal
          title={trans.ASSET__PUBLISH_TO_REGION_TITLE()}
          isOpen={isActive}
          onClose={this.handleCancel}
          actions={
            <ButtonsWrapper>
              <Button key={0} type="normal" onClick={this.handleCancel}>
                {trans.CANCEL()}
              </Button>
            </ButtonsWrapper>
          }
        >
          <h1 className={styles.header}>
            {trans.ASSET__SELECT_REGIONS_FOR_HEADER()}
            <span className={styles.asset}> {asset.name}</span>
          </h1>
          {this.renderError(error)}
        </Modal>
      );
    }

    return (
      <Modal
        title={trans.ASSET__PUBLISH_TO_REGION_TITLE()}
        isOpen={isActive}
        onClose={this.handleCancel}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={this.handleCancel}>
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.handlePublish}
              disabled={selectedCount === 0}
              processing={publishing}
            >
              {trans.PUBLISH()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <h1 className={styles.header}>
          {trans.ASSET__SELECT_REGIONS_FOR_HEADER()}
          <span className={styles.asset}> {asset.name}</span>
        </h1>
        <Radio
          label={trans.WORLDWIDE()}
          defaultValue={RADIO_WORLDWIDE}
          value={radioValue}
          onChange={this.handleRadioChange}
        />
        <Radio
          label={trans.APP_EDIT_MARKETS__SPECIFIC_COUNTRIES()}
          defaultValue={RADIO_COUNTRIES}
          value={radioValue}
          onChange={this.handleRadioChange}
        />
        {radioValue === RADIO_COUNTRIES && (
          <div className={styles.tabsContainer}>
            <SubTabs>
              {getContinentNames().map((c) => (
                <SubTab
                  key={c}
                  text={c}
                  active={c === continent}
                  onClick={this.handleTabClick}
                />
              ))}
            </SubTabs>
            <TabContent>
              <SearchableList
                list={getCountriesForContinent(allCountries, continent)}
                searchBy="name"
                classNameList={styles.searchableList}
                optionsListElement={(visibleItems) => (
                  <CheckAllButton
                    fieldName="software-assets-modal-regions"
                    onChange={this.toggleMany(visibleItems)}
                    checked={visibleItems.every(this.isCountryChecked)}
                    disabled={visibleItems.length === 0}
                    isRtl={true}
                    className={styles.checkAllButton}
                  />
                )}
              >
                {(country) => (
                  <CountryRow
                    key={country.code}
                    country={country}
                    isChecked={this.isCountryChecked(country)}
                    onChange={this.handleCountryChange}
                  />
                )}
              </SearchableList>
              <SelectedCountFooter selectedCount={selectedCount} />
            </TabContent>
          </div>
        )}
      </Modal>
    );
  }
}
