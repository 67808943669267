// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./SearchableListPanel.scss";

/** Wrapper for row items in `<SearchableList>`. */
export class SearchableListPanel extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    dataTestId: "searchable-list-panel",
  };

  render() {
    const { children, className, disabled, dataTestId } = this.props;
    return (
      <li
        className={classes(styles.container, className, {
          [styles.disabled]: disabled,
        })}
        data-test-id={dataTestId}
      >
        {children}
      </li>
    );
  }
}
