// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Navigate } from "react-router-dom";

import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";

import { getRedirectPath } from "./getRedirectPath";

export const MatchPath = ({ activeOrganization, match }) => {
  const redirectPath = getRedirectPath(match.pathname, activeOrganization);
  return <Navigate to={redirectPath} replace={true} />;
};

MatchPath.propTypes = {
  // @route
  match: PropTypes.object.isRequired,
  // from @withActiveOrganization
  activeOrganization: PropTypes.shape({
    roles: PropTypes.array.isRequired,
  }),
};

export const RedirectDependingOnRoles = withActiveOrganization(MatchPath);
