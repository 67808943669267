// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Modal } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { api } from "containers/Request";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";

@withRouter
@connect(null, (dispatch, ownProps) => ({
  removePromotion: (promotionId) => {
    const removePromotionAction = ownProps.forAdmin
      ? api.removePromotion.action
      : api.removeMyPromotion.action;
    return dispatch(
      removePromotionAction({
        params: { id: promotionId },
      })
    );
  },
}))
export class PromotionActionMenu extends Component {
  static propTypes = {
    promotionId: PropTypes.string.isRequired,
    promotionName: PropTypes.string.isRequired,
    campaignsCount: PropTypes.number.isRequired,
    removePromotion: PropTypes.func.isRequired,
    forAdmin: PropTypes.bool,
    isReadonly: PropTypes.bool,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
  };

  state = { isOpen: false, removing: false, error: undefined };

  handleRemovePromotion = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirmRemovingPromotion = async () => {
    const { navigate, removePromotion, promotionId, forAdmin } = this.props;

    try {
      this.setState({ removing: true });
      await removePromotion(promotionId);
      this.setState({ isOpen: false, error: undefined });
      navigate(`/promotions${forAdmin ? "/admin" : ""}/list`);
    } catch (err) {
      this.setState({ error: err.message });
    } finally {
      this.setState({ removing: false });
    }
  };

  render() {
    const { promotionId, promotionName, campaignsCount, forAdmin, isReadonly } =
      this.props;
    const { isOpen, error } = this.state;

    const isPromotionRemovable = campaignsCount === 0 && !isReadonly;

    return (
      <Fragment>
        <ActionMenu>
          <MenuItem
            to={`/promotions${forAdmin ? "/admin" : ""}/edit/${promotionId}`}
          >
            {isReadonly ? trans.VIEW_DETAILS() : trans.EDIT_DETAILS()}
          </MenuItem>
          <MenuItem
            onClick={this.handleRemovePromotion}
            disabled={!isPromotionRemovable}
          >
            {trans.PROMOTION_LIST__REMOVE_PROMOTION()}
          </MenuItem>
        </ActionMenu>
        <Modal
          title={trans.PROMOTION_LIST__REMOVE_PROMOTION_MODAL_TITLE()}
          isOpen={isOpen}
          onClose={this.handleClose}
          actions={
            <ButtonsWrapper>
              <Button onClick={this.handleClose}>{trans.NO()}</Button>
              <Button
                onClick={this.handleConfirmRemovingPromotion}
                type="green"
                processing={this.state.removing}
              >
                {trans.YES()}
              </Button>
            </ButtonsWrapper>
          }
        >
          {error && <Info type="error">{error}</Info>}
          {trans.PROMOTION_LIST__REMOVE_PROMOTION_MODAL_TEXT({
            promotionName,
          })}
        </Modal>
      </Fragment>
    );
  }
}
