import { Component, Context, ReactNode } from "react";

import { IsUpdatedInterface } from "./IsUpdated.interface";

export interface IsUpdatedProviderProps<T, S, U> {
  Context: Context<IsUpdatedInterface<T, S, U>>;
  firstObj: T;
  secondObj: S;
  extraValue: U;
  children: ReactNode;
}

export class IsUpdatedProvider<
  T = unknown,
  S = unknown,
  U = unknown,
> extends Component<IsUpdatedProviderProps<T, S, U>> {
  render() {
    const { Context, children, firstObj, secondObj, extraValue } = this.props;

    return (
      <Context.Provider
        value={{
          firstObj,
          secondObj,
          extraValue,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}
