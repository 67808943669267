// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField } from "formik";
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";

import { createMixedValidator } from "components/form/fields";
import { InputWithModal } from "src/components/form/elements";
import { trans } from "src/translations";

import { SelectSoftwarePackageModalData } from "../modals/selectSoftwarePackageModal/SelectSoftwarePackageModalData";

const fieldName = "softwarePackageVersion";

function getSoftwarePackageVersionName(input) {
  const packageName = get(input, "value.packageName", "").trim();
  const versionName = get(input, "value.versionName", "").trim();

  if (packageName.length === 0 || versionName.length === 0) {
    return "";
  }
  return `${packageName}/${versionName}`;
}

const validate = createMixedValidator(fieldName, true);

export const SoftwarePackageField = () => {
  const [field, meta, { setValue }] = useField({
    name: fieldName,
    validate,
  });

  return (
    <InputWithModal
      label={trans.UPDATES__FORM_FIELD_SOFTWARE_PACKAGE()}
      dataTestId="software-package-field"
      value={getSoftwarePackageVersionName(field)}
      required={true}
      meta={meta}
    >
      {(isOpen, onClose) => (
        <SelectSoftwarePackageModalData
          isOpen={isOpen}
          onSubmit={setValue}
          onClose={onClose}
          currentVersion={isEmpty(field.value) ? undefined : field.value}
        />
      )}
    </InputWithModal>
  );
};
