// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const listProcessingQueueQuery = gql`
  query processingDeviceAttributes(
    $search: SearchInput
    $pagination: PaginationInput!
  ) {
    processingDeviceAttributes(search: $search, pagination: $pagination) {
      processingDeviceAttributes {
        pagination {
          count
        }
        items {
          id
          filename
          status
          uploadDate
        }
      }
    }
  }
`;
