// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import get from "lodash-es/get";
import pickBy from "lodash-es/pickBy";
import PropTypes from "prop-types";

import { Checkbox } from "components/form/elements";
import { GrabsonIcon } from "components/icons";
import {
  TableBody,
  TableRow,
  TableRowColumn,
  TableRowColumnOverflow,
} from "components/layout";
import { Tooltip } from "components/popups";
import { Link } from "components/typography";
import {
  withActiveOrganization,
  activeOrganizationPropTypes,
} from "containers/Auth/decorators/withActiveOrganization";
import { TabbedTable } from "containers/TabbedTable";
import { DefaultSortContext } from "pages/_shared/Context/defaultSortContext";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";
import { withMutation } from "utils/graphql";
import { createObjectWithKeys } from "utils/object";

import { DismissAlertModal } from "../../DeviceDetails/components";
import { getDeviceFragmentQuery } from "../../DeviceDetails/gql/getDeviceQuery";
import {
  BULK_ACTION_SELECT_VALUES,
  BULK_ACTIONS,
  ITEMS_PER_PAGE,
  TABS_CONFIG,
} from "./constants";
import styles from "./DeviceInventoryTable.scss";
import { dismissAllPdidAlertsForDevicesMutation } from "./gql/dismissAllPdidAlertsForDevicesMutation";
import { DEVICE_PROP_TYPES } from "./propTypes";

const DEFAULT_STATE = {
  isModalOpen: false,
  pdidToDismiss: null,
  isSingleDismiss: false,
};

@withMutation({
  name: "dismissAllPdidAlertsForDevices",
  mutation: dismissAllPdidAlertsForDevicesMutation,
})
@withActiveOrganization
export class DeviceInventoryTable extends PureComponent {
  static propTypes = {
    results: PropTypes.arrayOf(DEVICE_PROP_TYPES).isRequired,
    loading: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.node,
    hasBulkDismissAlerts: PropTypes.bool,
    fetchDevicesWithActiveAlertCount: PropTypes.func,
    getCountry: PropTypes.func.isRequired,

    // from @withActiveOrganization
    activeOrganization: activeOrganizationPropTypes,

    //from @withMutation
    dismissAllPdidAlertsForDevicesMutation: PropTypes.func,
  };

  state = {
    ...DEFAULT_STATE,
    selectedDevices: {},
  };

  handleModalClose = () => {
    this.setState({ ...DEFAULT_STATE });
  };

  getActiveAlertTooltip = (id) => {
    return (
      <Tooltip
        content={
          <div>
            <p>
              {trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_ACTIVE_ALERT_TOOLTIP()}
            </p>
            <p className={styles.dismissAlert}>
              <span
                onClick={() => {
                  this.setState({
                    isModalOpen: true,
                    pdidToDismiss: id,
                    isSingleDismiss: true,
                  });
                }}
              >
                {trans.DEVICES__DEVICE_DETAILS_TABLE_DISMISS_ALERT_BUTTON()}
              </span>
            </p>
          </div>
        }
        alignment="bottom-end"
        classNamePopup={styles.alertsTooltip}
        dataTestId="pdid-alert-active-tooltip"
      >
        <GrabsonIcon
          name="alert-circle"
          font="normal"
          className={styles.error}
          size="normal"
          dataTestId="pdid-alert-active-row-icon"
        />
      </Tooltip>
    );
  };

  getDismissedAlertTooltip = () => {
    return (
      <Tooltip
        content={trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_DISMISSED_ALERT_TOOLTIP()}
        alignment="bottom-end"
        classNamePopup={styles.alertsTooltip}
        dataTestId="pdid-alert-dismissed-tooltip"
      >
        <GrabsonIcon
          name="alert-circle"
          font="normal"
          className={styles.dismissed}
          size="normal"
          dataTestId="pdid-alert-dismissed-row-icon"
        />
      </Tooltip>
    );
  };

  getDeviceModel = (device) => {
    return get(device, "deviceModel", "");
  };

  getPdidAlertIconWithTooltip = (
    id,
    activeAlertsNumber = 0,
    dismissedAlertsNumber = 0
  ) => {
    // active alert state is more important, so we want to be sure that
    // if any pdid has active alert, we will show red alert icon
    if (activeAlertsNumber) {
      return this.getActiveAlertTooltip(id);
    }
    if (dismissedAlertsNumber) {
      return this.getDismissedAlertTooltip();
    }
    return undefined;
  };

  toggleMany = (deviceIds, value) => {
    this.setState((prevState) => ({
      selectedDevices: {
        ...prevState.selectedDevices,
        ...createObjectWithKeys(deviceIds, value),
      },
    }));
  };

  isChecked = (device) => {
    const { selectedDevices } = this.state;
    return Boolean(selectedDevices[device.id]);
  };

  checkDevice = (e) => {
    const deviceId = e.target.value;
    this.toggleMany([deviceId], e.target.checked);
  };

  getCheckedDeviceIds = () => {
    const { selectedDevices } = this.state;
    return Object.keys(pickBy(selectedDevices, (value) => Boolean(value)));
  };

  handleOnClickSelect = async (e) => {
    const selectedDevices = this.getCheckedDeviceIds();
    if (selectedDevices.length === 0) {
      return;
    }

    if (e.target.value === BULK_ACTIONS.DISMISS_ALERT) {
      this.setState({
        isModalOpen: true,
        isSingleDismiss: false,
      });
    }
  };

  handleDismissAlerts = async () => {
    const {
      dismissAllPdidAlertsForDevicesMutation,
      fetchDevicesWithActiveAlertCount,
    } = this.props;
    const { pdidToDismiss, isSingleDismiss } = this.state;
    if (isSingleDismiss) {
      await dismissAllPdidAlertsForDevicesMutation({
        variables: {
          input: {
            pdids: [pdidToDismiss],
          },
        },
        update: (apolloClient) => {
          const deviceFromCache = apolloClient.readFragment({
            fragment: getDeviceFragmentQuery,
            id: `DeviceType:${pdidToDismiss}`,
          });
          apolloClient.writeFragment({
            id: `DeviceType:${pdidToDismiss}`,
            fragment: getDeviceFragmentQuery,
            data: {
              ...deviceFromCache,
              activeAlertsNumber: 0,
              dismissedAlertsNumber:
                deviceFromCache.dismissedAlertsNumber +
                deviceFromCache.activeAlertsNumber,
            },
          });
        },
      });
    } else {
      await dismissAllPdidAlertsForDevicesMutation({
        variables: {
          input: {
            pdids: this.getCheckedDeviceIds(),
          },
        },
        update: (
          apolloClient,
          { data: data = { dismissAllPdidAlertsForDevices: [] } }
        ) => {
          // https://github.com/apollographql/apollo-client/issues/4724#issuecomment-487373566
          // there is no good way of getting all devices from apollo's cache so rather than serializing it all (slow)
          // we iterate over every device we dismiss alerts for
          data.dismissAllPdidAlertsForDevices.forEach((item) => {
            const deviceFromCache = apolloClient.readFragment({
              fragment: getDeviceFragmentQuery,
              id: `DeviceType:${item.id}`,
            });
            apolloClient.writeFragment({
              id: `DeviceType:${item.id}`,
              fragment: getDeviceFragmentQuery,
              data: {
                ...deviceFromCache,
                activeAlertsNumber: 0,
                dismissedAlertsNumber:
                  deviceFromCache.dismissedAlertsNumber +
                  deviceFromCache.activeAlertsNumber,
              },
            });
          });
        },
      });
    }
    // we don't have dismiss functionality in e.g. devices list on filter query details page
    if (fetchDevicesWithActiveAlertCount) {
      fetchDevicesWithActiveAlertCount();
    }
  };

  getTableHeaders() {
    const { hasBulkDismissAlerts } = this.props;

    return TABS_CONFIG.map((tabConfig) => ({
      ...tabConfig,
      columns: tabConfig.columns.map((column) => ({
        ...column,
        className:
          column.id === "checkbox"
            ? hasBulkDismissAlerts
              ? styles.checkboxColumn
              : styles.noCheckboxColumn
            : column.className,
      })),
    }));
  }

  renderTableBody = (rows) => {
    return (
      <TableBody>
        {rows.map((row) => {
          return (
            <TableRow key={row.id}>
              <TableRowColumn>
                {this.props.hasBulkDismissAlerts && (
                  <Checkbox
                    name={`device-inventory-checkbox-${row.id}`}
                    onChange={(e) => this.checkDevice(e)}
                    value={row.id}
                    checked={this.isChecked(row)}
                    className={styles.checkOne}
                  />
                )}
              </TableRowColumn>

              <TableRowColumn
                dataTestId="id-column"
                className={styles.deviceId}
              >
                <Link to={`/devices/inventory/${row.id}/details`}>
                  {row.id}
                </Link>
                {this.getPdidAlertIconWithTooltip(
                  row.id,
                  row.activeAlertsNumber,
                  row.dismissedAlertsNumber
                )}
              </TableRowColumn>

              <TableRowColumnOverflow
                dataTestId="device-model-column"
                overflowText={this.getDeviceModel(row)}
              >
                {this.getDeviceModel(row)}
              </TableRowColumnOverflow>

              <TableRowColumnOverflow
                dataTestId="country-column"
                overflowText={this.props.getCountry(row)}
              >
                {this.props.getCountry(row)}
              </TableRowColumnOverflow>

              <TableRowColumn dataTestId="activation-date-column">
                {formatDateTime(new Date(row.activationDate))}
              </TableRowColumn>
              <TableRowColumn dataTestId="last-activity-date-column">
                {formatDateTime(new Date(row.lastActivityDate))}
              </TableRowColumn>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  render() {
    const { isModalOpen } = this.state;
    const {
      results,
      loading,
      count,
      error,
      hasBulkDismissAlerts,
      activeOrganization,
    } = this.props;
    const areAllSelected = results.every(this.isChecked);
    const selectedDevices = this.getCheckedDeviceIds();

    return (
      <Fragment>
        <DefaultSortContext.Consumer>
          {(defaultOrder) => (
            <TabbedTable
              data={{ results, count, loading, error, defaultOrder }}
              noResultsMessage={
                <>
                  {trans.TABLE_EMPTY()}
                  <Tooltip
                    alignment="bottom"
                    content={
                      <span>
                        {trans.DEVICES__DEVICE_INVENTORY_NO_RESULTS_TOOLTIP({
                          organization: activeOrganization.name,
                        })}
                      </span>
                    }
                  >
                    (?)
                  </Tooltip>
                </>
              }
              tabsConfig={this.getTableHeaders()}
              renderTableBody={this.renderTableBody}
              rowsPerPage={ITEMS_PER_PAGE}
              bulkActions={
                hasBulkDismissAlerts
                  ? {
                      areAllSelected,
                      toggleMany: this.toggleMany,
                      select: {
                        values: BULK_ACTION_SELECT_VALUES,
                        onChange: this.handleOnClickSelect,
                      },
                      selectedCount: selectedDevices.length,
                    }
                  : undefined
              }
              layout="fixed"
            />
          )}
        </DefaultSortContext.Consumer>
        <DismissAlertModal
          isOpen={isModalOpen}
          onClose={this.handleModalClose}
          dismissAlert={this.handleDismissAlerts}
        />
      </Fragment>
    );
  }
}
