import PropTypes from "prop-types";

import { Label, ValidationBadge } from "components/form";
import { CheckboxField } from "components/form/fields";
import styles from "components/form/form.scss";
import { trans } from "src/translations";

const videoTypes = [
  {
    label: trans.APP_EDIT__MY_APP_HAS_VOD_LABEL(),
    name: "metadata.contains_vod",
    dataTestId: "has-vod-field",
  },
  {
    label: trans.APP_EDIT__MY_APP_HAS_LIVE_VIDEO_LABEL(),
    name: "metadata.contains_live_video",
    dataTestId: "has-live-video-field",
  },
];

export const VideoTypes = ({ videoTypesError }) => {
  return (
    <div className={styles.field}>
      <Label text={trans.APP_EDIT__VIDEO_TYPES_LABEL()} />
      {videoTypes.map(({ name, ...rest }, index) => (
        <CheckboxField
          // eslint-disable-next-line react/no-array-index-key
          key={`${name}_${index}`}
          name={name}
          className={styles.videoType}
          {...rest}
        />
      ))}
      {videoTypesError ? (
        <ValidationBadge error={videoTypesError} touched={true} />
      ) : null}
    </div>
  );
};

VideoTypes.propTypes = {
  videoTypesError: PropTypes.string,
};
