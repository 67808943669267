// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/**
 * Joins class names based on given conditions.
 *
 *
 * Example:
 * -----------------------
 * import {classes} from "utils/classes";
 * import styles from "./MyComponent.scss";
 *
 * export class MyComponent extends Component {
 *   render() {
 *     return (
 *       <div classNames={classes(styles.name1, { [styles.name2]: true })}>
 *         ...
 *       </div>
 *     );
 *   }
 * }
 * -----------------------
 */

export const classes = (
  ...args: Array<string | Record<string, boolean>>
): string => {
  const classes: string[] = [];

  args.forEach((arg) => {
    if (typeof arg !== "undefined") {
      if (typeof arg === "string") {
        if (arg) {
          classes.push(arg);
        }
      } else if (arg !== null && typeof arg === "object") {
        const map = Object.entries(arg);
        for (const [className, isActive] of map) {
          if (className !== "undefined" && isActive) {
            classes.push(className);
          }
        }
      } else {
        console.warn("Function supports only strings and objects");
      }
    }
  });

  return classes.join(" ");
};
