// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";
import queryString from "query-string";

import { Checkbox, Select } from "components/form/elements";
import {
  ExportButton,
  Overlay,
  Table,
  tableColPropType,
  TableHeader,
  TableHeaderColumn,
  TableActions,
  TableNoResults,
  TablePagination,
  tableLayoutPropType,
} from "components/layout";
import { SubTabs, SubTab } from "components/navigation";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { withRouter } from "utils/decorators/withRouter";

import styles from "./TabbedTable.scss";

/**
 * This component allows to easily display tabular data. In fact, it's actually
 * preferable to use this component instead of [Table](#table), since it automatically
 * handles table headers etc. You can always use this component with only one tab.
 * In that case, the tabs will not be visible to user, but You will still get
 * all benefits from this solution.
 *
 * This component uses url as a means of storing table metadata like sorting,
 * pagination etc.
 */
@withRouter
export class TabbedTable extends Component {
  static propTypes = {
    className: PropTypes.string,
    /** Ignore user events. Under the hood, this just uses `<Overlay>` */
    disableInteraction: PropTypes.bool,
    /** Custom message if there is no data to display */
    noResultsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * Render function that takes array of row data, info about visible columns
     * and returns rendered table rows. Should use `<TableBody>`.
     */
    renderTableBody: PropTypes.func.isRequired,
    /** How many items are per each page */
    rowsPerPage: PropTypes.number,
    /** Table tabs configuration. Can be just a single item */
    tabsConfig: PropTypes.arrayOf(
      PropTypes.shape({
        /** Tab id - used for tab switching mechanism */
        id: PropTypes.node,
        /** Tab name */
        label: PropTypes.node,
        /** Array of columns */
        columns: PropTypes.arrayOf(tableColPropType).isRequired,
        dataTestId: PropTypes.string,
      })
    ).isRequired,

    /** Data to display. Usually paginated */
    data: PropTypes.shape({
      /** Items to display */
      results: PropTypes.array.isRequired,
      /** Total count of items */
      count: PropTypes.number,
      /** Data order by column */
      defaultOrder: PropTypes.shape({
        sortBy: PropTypes.string,
        sortOrder: PropTypes.string,
      }),
      /** True if loading more data */
      loading: PropTypes.bool.isRequired,
      /** Optional error that happened e.g. during data request */
      error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
            .isRequired,
        }),
      ]),
    }).isRequired,
    dataTestId: PropTypes.string,
    /**
     * Optional handler for export button.
     *
     * Type: (fileFormat: string) => void
     */
    onExport: PropTypes.func,

    // from @withRouter
    /** @ignore */
    location: PropTypes.object,

    params: PropTypes.shape({
      tabId: PropTypes.string,
    }),
    /* Display pagination */
    withPagination: PropTypes.bool,

    /* Display checkbox to include sensitive data */
    withSensitiveDataCheckbox: PropTypes.bool,

    /*
     * Optional checkboxes (with one checkbox that selects all loaded items)
     * for every row and do some action using select on every selected item
     */
    bulkActions: PropTypes.shape({
      /* value to know wheter bulk checkbox should be checked or not */
      areAllSelected: PropTypes.bool.isRequired,
      /* function for handling checking both in bulk and singular checkboxes */
      toggleMany: PropTypes.func.isRequired,
      selectedCount: PropTypes.number.isRequired,
      /* select that will be triggered for every checked table item */
      select: PropTypes.shape({
        values: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string,
          })
        ).isRequired,
        /* what should happen when select value got selected */
        onChange: PropTypes.func.isRequired,
      }).isRequired,
    }),
    layout: tableLayoutPropType,
    legend: PropTypes.node,
  };

  static defaultProps = {
    dataTestId: "table",
    rowsPerPage: 50,
    disableInteraction: false,
    withPagination: true,
    layout: "auto",
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  getTabLink(pathname, tabId) {
    const basePath = pathname.split("/");
    basePath.splice(-1, 1, tabId);

    return basePath.join("/");
  }

  renderTabs() {
    const {
      location: { pathname, search },
      tabsConfig,
      params: { tabId },
    } = this.props;

    const tabs = tabsConfig.filter((t) => t.label);

    if (tabs.length === 1 && typeof tabs[0].id === "undefined") {
      tabs[0].id = "";
    }

    const activeTab = tabId ?? queryString.parse(search).tab;
    let activeTabIndex = tabs.findIndex((tab) => tab.id === activeTab);
    if (activeTabIndex === -1) {
      activeTabIndex = 0;
    }

    if (!tabs || tabs.length === 0) {
      return null;
    }

    return (
      <SubTabs>
        {tabs.map((tab, i) => {
          const to = tabId
            ? this.getTabLink(pathname, tab.id)
            : `${pathname}?${queryString.stringify({ tab: tab.id })}`;
          return (
            <SubTab
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              active={i === activeTabIndex}
              to={to}
              text={tab.label}
              dataTestId={tab.dataTestId}
            />
          );
        })}
      </SubTabs>
    );
  }

  renderHeader(columns, loading) {
    const {
      location: { pathname, search },
      data: { defaultOrder },
    } = this.props;

    const currentSearchParams = queryString.parse(search);

    const searchObj = {
      sort_by: currentSearchParams.sort_by ?? defaultOrder?.sortBy,
      order: currentSearchParams.order ?? defaultOrder?.sortOrder,
    };

    return (
      <TableHeader loading={loading}>
        {columns.map((column, i) => {
          let iconName = null;
          let to;

          if (column.sortBy && searchObj.sort_by === column.sortBy) {
            iconName = searchObj.order === "DESC" ? "arrow-down" : "arrow-up";
            to = `${pathname}?${queryString.stringify({
              ...currentSearchParams,
              sort_by: searchObj.sort_by,
              order: searchObj.order === "DESC" ? "ASC" : "DESC",
            })}`;
          } else if (column.sortBy) {
            to = column.sortBy
              ? `${pathname}?${queryString.stringify({
                  ...currentSearchParams,
                  sort_by: column.sortBy,
                  order: "ASC",
                })}`
              : false;
          }

          return (
            <TableHeaderColumn
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              icon={iconName}
              className={column.className}
              dataTestId={column.dataTestId}
            >
              {to ? <NavLink to={to}>{column.label}</NavLink> : column.label}
            </TableHeaderColumn>
          );
        })}
      </TableHeader>
    );
  }

  getColumns() {
    const {
      location: { search },
      tabsConfig,
      params: { tabId },
    } = this.props;

    let columns = [];

    const activeTab = tabId ?? queryString.parse(search).tab;
    const currentTab =
      tabsConfig.find((tab) => tab.id === activeTab) || tabsConfig[0];
    if (currentTab) {
      columns = currentTab.columns;
    }

    return columns;
  }

  renderBody(columns, results) {
    const { renderTableBody, noResultsMessage, data } = this.props;
    const { error, loading } = data;

    const errorMessage = typeof error === "string" ? error : error?.message;

    if (errorMessage) {
      return (
        <TableNoResults
          message={errorMessage}
          isError={true}
          loading={loading}
          colspan={columns.length}
        />
      );
    }

    if (results.length === 0) {
      return (
        <TableNoResults
          message={noResultsMessage}
          loading={loading}
          colspan={columns.length}
        />
      );
    }

    return renderTableBody(results);
  }

  renderTableActions = () => {
    return (
      <TableActions>
        {this.renderPagination()}
        {this.renderExportButton()}
      </TableActions>
    );
  };

  renderPagination = () => {
    const { rowsPerPage, data, withPagination, legend } = this.props;
    if (!withPagination) {
      return null;
    }
    return (
      <TablePagination
        totalCount={data.count}
        rowsPerPage={rowsPerPage}
        legend={legend}
      />
    );
  };

  renderExportButton = () => {
    const { onExport, data, withSensitiveDataCheckbox } = this.props;

    if (!onExport) {
      return null;
    }
    return (
      <ExportButton
        onExport={onExport}
        disabled={data.loading || data.count <= 0}
        withSensitiveDataCheckbox={withSensitiveDataCheckbox}
      />
    );
  };

  renderBulkActions = () => {
    if (!this.props.bulkActions) {
      return null;
    }

    const {
      bulkActions: {
        areAllSelected,
        toggleMany,
        selectedCount,
        select: { values, onChange },
      },
      data: { results, loading },
    } = this.props;

    return (
      <Fragment>
        <Checkbox
          name="tabbed-table-bulk-actions-all-checkbox"
          checked={areAllSelected}
          value={areAllSelected}
          onChange={() =>
            toggleMany(
              results.map((result) => result.id),
              !areAllSelected
            )
          }
          disabled={loading}
          className={styles.checkAll}
          dataTestId="tabbed-table-bulk-actions-all-checkbox"
        />
        <Select
          name="tabbed-table-bulk-actions-select"
          dataTestId="tabbed-table-bulk-actions-select"
          values={values.map((value) => ({
            ...value,
            disabled: selectedCount === 0,
          }))}
          initOption={{
            value: "",
            text: trans.CHOOSE_ACTION(),
            selected: true,
          }}
          onChange={onChange}
          size="compact"
          className={classes(styles.bulkActionSelect, {
            [styles.bulkActionSelectDisabled]: selectedCount === 0,
          })}
          value=""
        />
      </Fragment>
    );
  };

  isRefreshingData() {
    const { data } = this.props;
    const { loading, results } = data;
    return loading && results && results.length > 0;
  }

  render() {
    const { className, data, disableInteraction, dataTestId, layout } =
      this.props;
    const { loading, results } = data;

    const columns = this.getColumns();

    return (
      <Overlay active={disableInteraction || this.isRefreshingData()}>
        <div
          className={className}
          style={{ pointerEvents: loading ? "none" : "" }}
          data-test-id={dataTestId}
        >
          {this.renderTableActions()}
          {this.renderTabs()}
          {this.renderBulkActions()}
          <Table layout={layout}>
            {this.renderHeader(columns, loading)}
            {this.renderBody(columns, results)}
          </Table>
          {this.renderTableActions()}
        </div>
      </Overlay>
    );
  }
}
