// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Field } from "formik";
import PropTypes from "prop-types";

import { Select } from "components/form/elements";
import { trans } from "src/translations";

import { ORGANIZATION_NONE } from "../../../constants";

export const DistributionCustomerField = ({
  name,
  organizations,
  disabled,
}) => (
  <Field name={name} organizations={organizations} disabled={disabled}>
    {({ field, meta }) => (
      <Select
        {...field}
        {...meta}
        name={field.name}
        capitalize={false}
        initOption={{
          value: ORGANIZATION_NONE,
          text: trans.DEVICES__CHOOSE_DISTR_CUSTOMER(),
        }}
        label={trans.DEVICES__DISTR_CUSTOMER_LABEL()}
        required={true}
        values={organizations.map((o) => ({
          text: o.name,
          value: o.id,
        }))}
        disabled={disabled}
      />
    )}
  </Field>
);

DistributionCustomerField.propTypes = {
  organizations: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};
