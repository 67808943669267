// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { SubTab, SubTabs } from "components/navigation";
import { trans } from "src/translations";

import {
  getUpdateJobDefaultPath,
  getUpdateJobEligibleDevicesPath,
} from "./UpdateJobDetailsData";
import { TAB_ID_ELIGIBLE_DEVICE, TAB_ID_MAIN } from "./UpdateJobDetailsRend";

export const UpdateJobDetailsTabs = ({ tabId, jobId }) => {
  return (
    <SubTabs>
      <SubTab
        key="update_job"
        active={tabId === TAB_ID_MAIN}
        to={getUpdateJobDefaultPath(jobId)}
        text={trans.UPDATES__UPDATE_DETAILS__DETAILS_TAB_NAME()}
      />
      <SubTab
        key="eligible_devices"
        active={tabId === TAB_ID_ELIGIBLE_DEVICE}
        to={getUpdateJobEligibleDevicesPath(jobId)}
        text={trans.UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_TAB_NAME()}
      />
    </SubTabs>
  );
};

UpdateJobDetailsTabs.propTypes = {
  tabId: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
};
