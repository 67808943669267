// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { Tooltip } from "components/popups";
import { classes } from "utils/classes";

import styles from "./Header.scss";

const COLOR_PRIMARY = "primary";
const COLOR_SECONDARY = "secondary";
const COLOR_TERTIARY = "tertiary";

/**
 * Header component that is a replacement for `<h*>` tags. This page contains
 * reset.css, that removes margin
 */
export const Header = ({
  children,
  type,
  uppercase,
  bold,
  color,
  className,
  dataTestId,
  id,
  tooltip,
}) => {
  const Component = type.toString();

  return (
    <Component
      className={classes(
        styles[type],
        {
          [styles.uppercase]: uppercase,
          [styles.bold]: bold,
          [styles.primary]: color === COLOR_PRIMARY,
          [styles.secondary]: color === COLOR_SECONDARY,
          [styles.tertiary]: color === COLOR_TERTIARY,
        },
        className
      )}
      // eslint-disable-next-line react/forbid-component-props
      data-test-id={dataTestId}
      id={id}
    >
      <Fragment>
        {children}
        {tooltip ? (
          <Tooltip content={tooltip}>
            <span className={styles.tooltipIcon}>(?)</span>
          </Tooltip>
        ) : null}
      </Fragment>
    </Component>
  );
};

Header.propTypes = {
  /** Text of the header */
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  uppercase: PropTypes.bool,
  bold: PropTypes.bool,
  color: PropTypes.oneOf([COLOR_PRIMARY, COLOR_SECONDARY, COLOR_TERTIARY]),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  tooltip: PropTypes.string,
};

Header.defaultProps = {
  type: "h3",
  uppercase: false,
  bold: false,
  color: COLOR_PRIMARY,
  dataTestId: "header",
};

export default Header;
