// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Form, Formik } from "formik";

import { APP_ENVIRONMENT } from "pages/Applications/AppDetails/constants";

import { ProfileModal } from "./ProfileModal";
import { Profiles } from "./Profiles";

const CREATE_INITIAL_VALUES = {
  name: "",
  action: "",
  package: "",
  class: "",
};

export const createModalForm = (profileValues, props) => (
  <Formik initialValues={profileValues ?? CREATE_INITIAL_VALUES}>
    {({ dirty, isValid }) => (
      <Form>
        <ProfileModal {...props} invalid={!dirty || !isValid}></ProfileModal>
      </Form>
    )}
  </Formik>
);

export const AndroidProfilesForm = (props) => {
  return (
    <Profiles
      {...props}
      createModalForm={createModalForm}
      appEnvironmentType={APP_ENVIRONMENT.ANDROID}
    />
  );
};
