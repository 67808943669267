// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import truncate from "lodash-es/truncate";
import PropTypes from "prop-types";

import { Avatar } from "components/images";
import { Link } from "components/typography";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./Notification.scss";

export const Notification = (props) => {
  const { className, author, date, text, type, url } = props;
  return (
    <div className={classes(styles.notification, styles[type], className)}>
      <div className={styles.info}>
        <Avatar size="30px" src={author.avatar} />
        <div className={styles.name}>{author.name}</div>
        <div className={styles.date}>{date}</div>
      </div>
      <div className={styles.message}>
        <span>{truncate(text, { length: 150, separator: "..." })}</span>{" "}
        <Link to={url}>{trans.MORE()}</Link>
      </div>
    </div>
  );
};

Notification.propTypes = {
  /** Notification text, truncated to 150 characters */
  text: PropTypes.string.isRequired,
  author: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  date: PropTypes.string,
  className: PropTypes.string,
  /** Url on "more" button */
  url: PropTypes.string,
  type: PropTypes.oneOf(["unread", "message", "info"]),
};
