// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import { Header } from "./Header";
import { Uploader } from "./Uploader";
import styles from "./UploaderGroup.scss";

/** This component manages multiple `<Uploader>` components. */
export class UploaderGroup extends Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    info: PropTypes.string,
    formats: PropTypes.arrayOf(PropTypes.string).isRequired,
    kind: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.func,
    preprocess: PropTypes.func,
    onPreviewClick: PropTypes.func,
    onRemove: PropTypes.func,
    currentlyPreviewedId: PropTypes.number,
    onNewItem: PropTypes.func,
    dataTestId: PropTypes.string,
    disablePreview: PropTypes.bool,
    tooltip: PropTypes.string,
    // from @Formik
    getFieldMeta: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
  };

  static defaultProps = {
    required: true,
    dataTestId: "uploader-group",
  };

  onSuccess = (idx) => () => {
    const { onNewItem } = this.props;

    if (onNewItem) {
      onNewItem(idx);
    }
  };

  handleOnRemove = (idx, onRemove, setFieldTouched) => {
    const { fieldName } = this.props;
    onRemove(idx);
    setFieldTouched(`${fieldName}[${0}].url`);
  };

  render() {
    const {
      label,
      tooltip,
      formats,
      fieldName,
      required,
      info,
      currentlyPreviewedId,
      disablePreview,
      dataTestId,
      getFieldMeta,
      setFieldTouched,
      preprocess,
      validate,
      onRemove,
      onPreviewClick,
      kind,
    } = this.props;

    const { value: fields } = getFieldMeta(fieldName);

    return (
      <div data-test-id={dataTestId}>
        {label ? (
          <Header
            text={label}
            field={fields}
            required={required}
            tooltip={tooltip}
          />
        ) : null}

        {(fields ?? []).map((item, idx) => (
          <Uploader
            // eslint-disable-next-line react/no-array-index-key
            key={`${fieldName}-${idx}`}
            name={`${fieldName}[${idx}]`}
            className={classes({
              [styles.activePreview]: idx === currentlyPreviewedId,
              [styles.inactivePreview]: idx !== currentlyPreviewedId,
            })}
            dataTestId={`${dataTestId}-row-${idx}`}
            info={item.status.addButton ? info : undefined}
            formats={formats}
            preprocess={preprocess}
            validate={validate}
            kind={kind}
            required={false}
            disablePreview={disablePreview}
            onClick={() => onPreviewClick(idx)}
            onSuccess={this.onSuccess(idx)}
            onRemove={() => this.handleOnRemove(idx, onRemove, setFieldTouched)}
          />
        ))}
      </div>
    );
  }
}
