// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import { Locations, MARKET_COUNTRIES } from "../../../components";

export default {
  components: [
    {
      component: Locations,
      fields: [
        "metadata.available_location_codes",
        "metadata.available_location_market",
      ],
      initialValues: {
        metadata: {
          available_location_market: MARKET_COUNTRIES,
          available_location_codes: [],
        },
      },
      props: ({ formikProps }) => ({
        required: false,
        description: trans.APP_EDIT_MARKETS__APP_ALSO_AVIALABLE_IN(),
        tooltip: trans.APP_EDIT_MARKETS__APP_ALSO_AVIALABLE_IN_TOOLTIP(),
        worldwideLabel: trans.WORLDWIDE(),
        countriesLabel: trans.APP_EDIT_MARKETS__ONLY_IN_SPECIFIC_COUNTRIES(),
        locationCodesField: "metadata.available_location_codes",
        locationMarketField: "metadata.available_location_market",
        mainLocationCodes: formikProps.values.metadata.location_codes,
        dataTestId: "markets-available-section",
        ...formikProps,
      }),
    },
  ],
};
