// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { isBefore, isAfter } from "date-fns";

import { trans } from "src/translations";

export const STATUSES = {
  FINISHED: "finished",
  ONGOING: "ongoing",
  SCHEDULED: "scheduled",
};

export const STATUS_NAMES = {
  scheduled: trans.SCHEDULED(),
  ongoing: trans.ONGOING(),
  finished: trans.FINISHED(),
};

export const getCampaignStatus = (startDate, endDate) => {
  const now = new Date();

  if (isBefore(now, startDate)) {
    return STATUSES.SCHEDULED;
  }

  if (isAfter(now, endDate)) {
    return STATUSES.FINISHED;
  }

  return STATUSES.ONGOING;
};
