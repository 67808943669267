// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { AutocompleteRenderer } from "./AutocompleteRenderer";
import {
  NONE_ITEM_IDX,
  DEFAULT_SEARCH,
  NONE_ITEM,
  DEFAULT_VALUE,
} from "./constants";

export class Autocomplete extends Component {
  static propTypes = {
    /**
     * Called on enter key in searchbar or when user selects dropdown option
     * using mouse.
     *
     * Type: (value: string) => void
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Called on every new letter typed into searchbar.
     *
     * Type: (letter: Character) => void
     */
    handleSearchChange: PropTypes.func.isRequired,
    initialItem: PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      img: PropTypes.string,
    }),

    // AutocompleteRenderer
    /** Field name */
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    /** Validation error. Shown if `touched` prop is true */
    error: PropTypes.string,
    touched: PropTypes.bool,
    /** placeholder */
    inputPlaceholder: PropTypes.string,
    /** Items to render */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        img: PropTypes.string,
      })
    ),
    /** IsLoading flag */
    dataPending: PropTypes.bool,
    dataError: PropTypes.string,
    /** All data loaded flag */
    dataLoaded: PropTypes.bool,
    handleScroll: PropTypes.func,
    /** Initial value of the search field. Can be changed over time through props if needed */
    value: PropTypes.string,
    className: PropTypes.string,
    withoutIcon: PropTypes.bool,
    // used if value from outside item list is acceptable
    isCustomValueAccepted: PropTypes.bool,

    /** props required for Formik managed field */
    name: PropTypes.string,
  };

  static defaultProps = {
    initialItem: null,
    items: [],
    value: "",
    dataPending: false,
    className: "",
    withoutIcon: false,
    isCustomValueAccepted: false,
  };

  state = {
    // eslint-disable-next-line react/no-unused-state
    value: DEFAULT_VALUE,
    search: DEFAULT_SEARCH,
    selectedItem: NONE_ITEM,
    highlightedItemIdx: NONE_ITEM_IDX,
    isListOpen: false,
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.value !== prevState.value) {
      const selectedItemIdx = nextProps.items.findIndex(
        (i) => i.value === nextProps.value
      );

      if (selectedItemIdx === -1) {
        if (
          nextProps.initialItem &&
          nextProps.initialItem.value === nextProps.value
        ) {
          return {
            value: nextProps.initialItem.value,
            search: nextProps.initialItem.label,
            selectedItem: nextProps.initialItem,
            highlightedItemIdx: NONE_ITEM_IDX,
          };
        }

        return {
          value: DEFAULT_VALUE,
          search: DEFAULT_SEARCH,
          selectedItem: NONE_ITEM,
          highlightedItemIdx: NONE_ITEM_IDX,
        };
      }

      const selectedItem = nextProps.items[selectedItemIdx];
      return {
        value: nextProps.value,
        search: selectedItem.label,
        selectedItem: selectedItem,
        highlightedItemIdx: NONE_ITEM_IDX,
      };
    }

    return null;
  };

  changeHighlightedItemIdx = (idx) => {
    this.setState({ highlightedItemIdx: idx });
  };

  selectItem = (idx) => {
    const { onChange, items } = this.props;

    let search = DEFAULT_SEARCH;
    let value = "";

    const selectedAny = idx !== NONE_ITEM_IDX;
    if (selectedAny) {
      const item = items[idx];
      search = item.label;
      value = item.value;
    }

    if (selectedAny || !this.getDataError()) {
      this.changeSearch(search);
      onChange(value);
    }
  };

  changeSearch = (value) => {
    const { handleSearchChange, onChange, isCustomValueAccepted } = this.props;

    handleSearchChange(value);
    this.setState({
      search: value,
      highlightedItemIdx: NONE_ITEM_IDX,
    });

    if (isCustomValueAccepted) {
      onChange(value);
    }
  };

  changeIsListOpen = (value) => {
    this.setState({ isListOpen: value });
  };

  getDataError() {
    const { dataError } = this.props;
    if (!dataError) {
      return null;
    }
    return dataError.message || dataError;
  }

  render() {
    const {
      label,
      required,
      tooltip,
      error,
      touched,
      inputPlaceholder,
      items,
      dataPending,
      dataLoaded,
      handleScroll,
      className,
      withoutIcon,
      disabled,
      name,
    } = this.props;

    return (
      <AutocompleteRenderer
        search={this.state.search}
        label={label}
        required={required}
        tooltip={tooltip}
        error={error}
        touched={touched}
        inputPlaceholder={inputPlaceholder}
        items={items}
        dataPending={dataPending}
        dataError={this.getDataError()}
        dataLoaded={dataLoaded}
        handleListScroll={handleScroll}
        selectItem={this.selectItem}
        changeHighlightedItemIdx={this.changeHighlightedItemIdx}
        changeSearch={this.changeSearch}
        changeIsListOpen={this.changeIsListOpen}
        highlightedItemIdx={this.state.highlightedItemIdx}
        selectedItem={this.state.selectedItem}
        isListOpen={this.state.isListOpen}
        className={className}
        withoutIcon={withoutIcon}
        disabled={disabled}
        name={name}
      />
    );
  }
}

export default Autocomplete;
