// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Modal } from "components/layout";
import { api } from "containers/Request";
import { trans } from "src/translations";

@connect(null, (dispatch) => ({
  removePromotionType: (id) =>
    dispatch(
      api.removePromotionType.action({
        params: {
          id,
        },
      })
    ),
}))
export class RemovePromotionTypeModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    promotionType: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    refreshData: PropTypes.func.isRequired,

    // from @connect
    removePromotionType: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    onClose: Function.propTypes,
  };

  state = {
    submitting: false,
    error: null,
  };

  handleRemove = async () => {
    const { removePromotionType, promotionType, onClose, refreshData } =
      this.props;

    this.setState({ submitting: true });

    const { error } = await removePromotionType(promotionType.id);

    if (error) {
      this.setState({ submitting: false, error: error });
      return;
    }

    this.setState({ submitting: false, error: null });
    onClose();
    refreshData();
  };

  render() {
    const { isOpen, onClose, promotionType } = this.props;

    return (
      <Modal
        title={trans.PROMOTION_TYPE_LIST__REMOVE_TYPE_MODAL_TITLE()}
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.NO()}
            </Button>
            <Button
              type="normal"
              processing={this.state.submitting}
              onClick={this.handleRemove}
            >
              {trans.YES()}
            </Button>
          </ButtonsWrapper>
        }
      >
        {trans.PROMOTION_TYPE_LIST__REMOVE_TYPE_MODAL_TEXT({
          promotionTypeName: promotionType.name,
        })}
        {this.state.error && <Info type="error">{this.state.error}</Info>}
      </Modal>
    );
  }
}
