// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { Column, Row, Section, Subsection, Table } from "components/layout";
import { FIELDS, SECTIONS } from "pages/Snap/shared/constants";
import { NO_VALUE_PLACEHOLDER } from "src/constants";

import styles from "../SnapApplicationDetails.scss";

export class MonetizationAndStats extends PureComponent {
  static propTypes = {
    revision: PropTypes.object.isRequired,
  };

  render() {
    const { revision } = this.props;
    const types = [];
    if (revision.preroll_type !== "DISABLED") {
      types.push(FIELDS.AD_TYPES.PRE.label);
    }
    if (revision.midroll_type !== "DISABLED") {
      types.push(FIELDS.AD_TYPES.MID.label);
    }
    const midrollUnit = revision.midroll_type === "OUR_PERCENT" ? "%" : "s";

    return (
      <Fragment>
        <Row>
          <Column>
            <Section header={SECTIONS.MONETIZATION.header}>
              <Subsection header={SECTIONS.MONETIZATION.typeHeader}>
                {revision.ads_enabled === "true"
                  ? SECTIONS.MONETIZATION.enabledAds
                  : SECTIONS.MONETIZATION.disabled}
              </Subsection>
            </Section>
          </Column>
          <Column>
            <Section header={SECTIONS.ANALYTICS.header}>
              <Subsection header={FIELDS.ANALYTICS.label}>
                {revision.ga_tracker_id || SECTIONS.ANALYTICS.disabled}
              </Subsection>
            </Section>
          </Column>
        </Row>
        {revision.ads_enabled === "true" && (
          <Section header={SECTIONS.ADS.header}>
            <Subsection header={FIELDS.AD_PROVIDER_NAME.label}>
              {FIELDS.AD_PROVIDER_NAME.radios[0].label}
            </Subsection>
            <Subsection header={FIELDS.AD_TYPES.label}>
              {types.length ? types.join(", ") : NO_VALUE_PLACEHOLDER}
            </Subsection>
          </Section>
        )}
        <Row>
          {revision.ads_enabled === "true" &&
            revision.preroll_type !== "DISABLED" && (
              <Column>
                <Section header={FIELDS.AD_TYPES.PRE.label}>
                  <Table className={styles.detailsTable}>
                    <thead>
                      <tr>
                        <th>{FIELDS.AD_PRE_OFFSET.label}</th>
                        <th>{FIELDS.AD_PRE_INTERVAL.label}</th>
                        <th>{FIELDS.AD_PRE_MIN_CONTENT_LENGTH.label}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{revision.preroll_offset}</td>
                        <td>{revision.preroll_interval}</td>
                        <td>{revision.preroll_minimum_content_length}s</td>
                      </tr>
                    </tbody>
                  </Table>
                </Section>
              </Column>
            )}
          {revision.ads_enabled === "true" &&
            revision.midroll_type !== "DISABLED" && (
              <Column>
                <Section header={FIELDS.AD_TYPES.MID.label}>
                  <Table className={styles.detailsTable}>
                    <thead>
                      <tr>
                        <th colSpan="2">{FIELDS.AD_MID_TYPE_SELECT.label}</th>
                        <th>{FIELDS.AD_MID_OFFSET.label}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          {revision.midroll_type === "OUR_PERCENT"
                            ? FIELDS.AD_MID_TYPE_SELECT.options[1].text
                            : FIELDS.AD_MID_TYPE_SELECT.options[0].text}
                        </td>
                        <td>
                          {revision.midroll_offset}
                          {midrollUnit}
                        </td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>{FIELDS.AD_MID_INTERVAL.label}</th>
                        <th>{FIELDS.AD_MID_MIN_OFFSET.label}</th>
                        <th>{FIELDS.AD_MID_MIN_INTERVAL.label}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {revision.midroll_interval}
                          {midrollUnit}
                        </td>
                        <td>{revision.midroll_min_offset}s</td>
                        <td>{revision.midroll_min_interval}s</td>
                      </tr>
                    </tbody>
                  </Table>
                </Section>
              </Column>
            )}
        </Row>
      </Fragment>
    );
  }
}
