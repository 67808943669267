// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Header } from "components/typography";
import { classes } from "utils/classes";

import styles from "./accordion.scss";

export class Accordion extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    /** Text that is always visible */
    header: PropTypes.node.isRequired,
    /**
     * If provided, it will be displayed when accordion is expanded instead of
     * standard header
     */
    expandedHeader: PropTypes.node,
    disabled: PropTypes.bool,
    /**
     * If provided, it will set initial state of accordion to expanded or collapsed.
     */
    initiallyCollapsed: PropTypes.bool,
    /**
     * Theme of accordion. Look at styleguide.
     */
    look: PropTypes.oneOf(["default", "compact"]),
    className: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    initiallyCollapsed: true,
    look: "default",
  };

  state = {
    collapsed: true,
  };

  constructor(props) {
    super(props);
    const { initiallyCollapsed } = props;
    this.state.collapsed = initiallyCollapsed;
  }

  handleClick = () => {
    const { disabled } = this.props;

    if (disabled) {
      return;
    }

    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  };

  render() {
    const { children, header, disabled, look, className, expandedHeader } =
      this.props;

    const { collapsed } = this.state;

    const headerInner = expandedHeader
      ? !collapsed
        ? expandedHeader
        : header
      : header;

    const headerStyle =
      look === "compact"
        ? { type: "h6", uppercase: true, color: "tertiary" }
        : { type: "h4", uppercase: false, color: "primary", bold: true };

    return (
      <div
        className={classes(
          styles.Accordion,
          styles[look],
          {
            [styles.collapsed]: collapsed,
            [styles.expanded]: !collapsed,
            [styles.enabled]: !disabled,
          },
          className
        )}
      >
        <div className={styles.headerWrapper} onClick={this.handleClick}>
          <Header className={styles.header} {...headerStyle}>
            {headerInner}
          </Header>
          {!disabled ? <span className={styles.sign} /> : null}
        </div>
        <div className={classes(styles.childrenWrapper)}>
          <div className={styles.children}>{children}</div>
        </div>
      </div>
    );
  }
}

export default Accordion;
