// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { FormHasErrors } from "components/form";
import {
  SelectField,
  TextareaField,
  TextField,
  UploaderWithPreviewField,
} from "components/form/fields";
import { Section, Row, Column, Overlay } from "components/layout";
import { updateAuthCookie } from "containers/Auth/actions";
import { withActiveOrganization } from "containers/Auth/decorators";
import { checkAccess, ROLES } from "containers/Permissions";
import { api } from "containers/Request";
import { ResetFormOnOrgChange } from "pages/_shared";
import { trans } from "src/translations";
import { urlToBase64 } from "utils/converters";
import { withRouter } from "utils/decorators";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";
import {
  pipeValidators,
  isRequired,
  isEmail,
  isUrl,
  isPhoneNumber,
  hasOneElement,
} from "utils/validation";

import { LOGO, jsonPointerToFieldName, FIELDS } from "./constants";
import styles from "./CreateEditOrganizationForm.scss";
import { TypeField } from "./fields/TypeField";

const SUBMIT_ATTEMPTED = "submit-attempted";
const ORG_TYPE_FIELD_NAME = "type";

@withRouter
@withActiveOrganization
@connect(null, (dispatch, ownProps) => ({
  createOrganization: (body) =>
    dispatch(api.createOrganization.action({ options: { body } })),
  updateOrganization: async (id, body) => {
    if (ownProps.forAdmin) {
      return await dispatch(
        api.updateOrganization.action({
          params: { organizationId: id },
          options: { body },
        })
      );
    }
    return await dispatch(
      api.updateMyOrganization.action({
        params: { organizationId: id },
        options: { body },
      })
    );
  },
  addTypeToOrganization: (id, body) =>
    dispatch(
      api.putTypeForOrganization.action({
        params: { organizationId: id },
        options: { body },
      })
    ),
  removeTypeFromOrganization: (id, typeId) =>
    dispatch(
      api.removeTypeFromOrganization.action({
        params: {
          organizationId: id,
          organizationType: typeId,
        },
      })
    ),
  updateAuthCookie: () => dispatch(updateAuthCookie()),
}))
/**
 * The form works in two modes: edit and create
 */
export class CreateEditOrganizationForm extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    forAdmin: PropTypes.bool.isRequired,
    /**
     * Switches between "edit" and "create" modes
     */
    isEdit: PropTypes.bool.isRequired,
    organizationId: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    countries: PropTypes.array,
    refreshData: PropTypes.func.isRequired,
    allOrganizationTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),

    // from @withActiveOrganization
    activeOrganization: PropTypes.object,
    changeActiveOrganization: PropTypes.func.isRequired,

    // from @connect
    createOrganization: PropTypes.func.isRequired,
    updateOrganization: PropTypes.func.isRequired,
    addTypeToOrganization: PropTypes.func.isRequired,
    removeTypeFromOrganization: PropTypes.func.isRequired,
    updateAuthCookie: PropTypes.func.isRequired,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
  };

  state = {
    info: null,
  };

  componentDidUpdate(prevProps) {
    if (this.isOrganizationChanged(prevProps)) {
      this.setState({ info: null });
    }
  }

  isOrganizationChanged(prevProps) {
    const activeOrganizationId = get(this.props, "activeOrganization.id");
    const prevActiveOrganizationId = get(prevProps, "activeOrganization.id");
    return activeOrganizationId !== prevActiveOrganizationId;
  }

  prepareBody = async (values) => {
    const data = {
      type: "organizations",
      name: values.name,
      description: values.description,
      support_email: values.supportEmail,
      website: values.website,
      phone: values.phone,
      logo: await urlToBase64(values[FIELDS.LOGO.name]),
      street: values.street,
      city: values.city,
      zip_code: values.zipCode,
      country: values.country,
    };

    return createBody(data);
  };

  handleError = (error, setErrors) => {
    const submitErrors = prepareErrorsForForm(error, jsonPointerToFieldName);
    setErrors({
      ...submitErrors,
      _submitFailed: true,
    });
  };

  updateOrganization = async (values, setErrors, setStatus) => {
    const { updateOrganization, updateAuthCookie, refreshData } = this.props;

    this.setState({
      info: null,
    });

    const body = await this.prepareBody(values);
    let { error } = await updateOrganization(this.getOrganizationId(), body);

    if (this.allowChangeType() && !error) {
      error = await this.updateTypes(this.getOrganizationId(), values);
    }

    if (error) {
      return this.handleError(error, setErrors);
    }

    await updateAuthCookie();

    await refreshData();

    setStatus(null);

    this.setState({
      info: trans.CHANGES_SAVE_SUCCESS(),
    });

    return false;
  };

  createOrganization = async (values, setErrors, setStatus) => {
    const {
      createOrganization,
      updateAuthCookie,
      changeActiveOrganization,
      refreshData,
      navigate,
    } = this.props;

    const body = await this.prepareBody(values);
    const { error, result } = await createOrganization(body);

    if (error) {
      return this.handleError(error, setErrors);
    }

    await updateAuthCookie();
    await changeActiveOrganization(result.results.id);

    await refreshData();

    setStatus(null);

    this.setState({
      info: trans.ORGANIZATION__CREATED(),
    });

    navigate("/organization/my/details");

    return false;
  };

  updateTypes = async (organizationId, values) => {
    const {
      addTypeToOrganization,
      removeTypeFromOrganization,
      initialValues,
      allOrganizationTypes,
    } = this.props;
    if (!allOrganizationTypes || allOrganizationTypes.length === 0) {
      return false;
    }

    const isSelected = (type, formValues) => {
      const selectedTypes = get(formValues, "type", []);
      return selectedTypes.find((typeId) => typeId === type.id) !== undefined;
    };
    const promises = [];

    allOrganizationTypes.forEach(async (type) => {
      const wasSelected = isSelected(type, initialValues);
      const willBeSelected = isSelected(type, values);

      if (!wasSelected && willBeSelected) {
        const data = {
          id: type.id,
          type: "organization-types",
        };
        promises.push(addTypeToOrganization(organizationId, createBody(data)));
      }

      if (wasSelected && !willBeSelected) {
        promises.push(removeTypeFromOrganization(organizationId, type.id));
      }
    });

    const results = await Promise.all(promises);
    const failedResult = results.find(({ error }) => Boolean(error));

    return failedResult?.error ?? false;
  };

  onSubmit = async (values, { setErrors, setStatus }) => {
    const { isEdit } = this.props;

    if (isEdit) {
      return await this.updateOrganization(values, setErrors, setStatus);
    }
    return await this.createOrganization(values, setErrors, setStatus);
  };

  allowChangeType() {
    const { isEdit, activeOrganization } = this.props;
    return (
      isEdit &&
      checkAccess({ activeOrganization }, [
        ROLES.administrator.organizationAdmin,
      ])
    );
  }

  getOrganizationId() {
    return this.props.organizationId;
  }

  getCountryValues = () => [
    {
      value: "",
      label: trans.ORGANIZATION__SELECT_COUNTRY_PLACEHOLDER(),
    },
    ...this.props.countries.map(({ code, name }) => ({
      value: code,
      label: name,
    })),
  ];

  validate = async (values) => {
    const errors = {};

    const requiredFields = Object.values(FIELDS)
      .map((field) => field.name)
      .filter((name) => name !== FIELDS.LOGO.name);
    const validators = [
      ...requiredFields.map((fieldName) => isRequired(fieldName)),
      isEmail(FIELDS.SUPPORT_EMAIL.name),
      isUrl(FIELDS.WEBSITE.name),
      isPhoneNumber(FIELDS.PHONE.name),
      hasOneElement(ORG_TYPE_FIELD_NAME),
    ];

    return pipeValidators(...validators)(values, null, errors);
  };

  render() {
    const {
      isEdit,
      isLoading,
      initialValues,
      activeOrganization,
      allOrganizationTypes,
    } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validate={this.validate}
        enableReinitialize={true}
      >
        {({ isSubmitting, dirty, errors, handleSubmit, status, setStatus }) => {
          const submitFailed =
            (errors?._submitFailed ||
              (!isEmpty(errors) && status === SUBMIT_ATTEMPTED)) ??
            false;
          return (
            <Form>
              <Overlay active={isLoading}>
                <Section header={trans.ORGANIZATION__BASIC_INFO_HEADER()}>
                  <Row>
                    <Column className={styles.baseTextInfoColumn}>
                      <TextField {...FIELDS.NAME} />
                      <TextareaField {...FIELDS.DESCRIPTION} />
                      {this.allowChangeType() && (
                        <TypeField
                          fieldname={ORG_TYPE_FIELD_NAME}
                          allOrganizationTypes={allOrganizationTypes || []}
                        />
                      )}
                    </Column>
                    <Column className={styles.logoColumn}>
                      <UploaderWithPreviewField
                        {...FIELDS.LOGO}
                        key={activeOrganization?.id}
                        formats={["image/png"]}
                        validationImgOptions={{
                          minWidth: LOGO.MIN_WIDTH,
                          minHeight: LOGO.MIN_HEIGHT,
                          maxWidth: LOGO.MAX_WIDTH,
                          maxHeight: LOGO.MAX_HEIGHT,
                        }}
                      />
                    </Column>
                  </Row>
                </Section>
                <Section header={trans.ORGANIZATION__ADDRESS_HEADER()}>
                  <TextField {...FIELDS.STREET} />
                  <TextField {...FIELDS.CITY} />
                  <TextField {...FIELDS.ZIP_CODE} />
                  <SelectField
                    {...FIELDS.COUNTRY}
                    values={this.getCountryValues()}
                  />
                </Section>
                <Section header={trans.ORGANIZATION__SUPPORT_HEADER()}>
                  <TextField {...FIELDS.SUPPORT_EMAIL} />
                  <TextField {...FIELDS.WEBSITE} />
                  <TextField {...FIELDS.PHONE} />
                </Section>
                {this.state.info && <Info>{this.state.info}</Info>}
                <FormHasErrors submitFailed={submitFailed} />

                <ButtonsWrapper>
                  <Button
                    type="green"
                    disabled={!dirty || isSubmitting}
                    processing={isSubmitting}
                    dataTestId="submit-button"
                    onClick={() => {
                      this.setState({ info: null });
                      setStatus(SUBMIT_ATTEMPTED);
                      handleSubmit(this.onSubmit);
                    }}
                  >
                    {isEdit
                      ? trans.UPDATE()
                      : trans.ORGANIZATION__CREATE_BUTTON()}
                  </Button>
                </ButtonsWrapper>
              </Overlay>
              <ResetFormOnOrgChange />
            </Form>
          );
        }}
      </Formik>
    );
  }
}
