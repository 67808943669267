// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { ORGANIZATION_PLAN } from "pages/Organization/constants";

export const organizationsPropTypes = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string,
    /** Its image url really */
    img: PropTypes.string,
    name: PropTypes.string,
    /**
     * For roles, see src/containers/Permissions/roles.js
     * Use constants from that file instead of strings
     */
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    types: PropTypes.array,
    organizationPlan: PropTypes.oneOf(Object.values(ORGANIZATION_PLAN)),
  })
).isRequired;

export const profilePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  img: PropTypes.string.isRequired,
  activeOrganizationId: PropTypes.string,
}).isRequired;
