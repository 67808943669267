// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useParams } from "react-router-dom";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { PromotionTypeFormData } from "./PromotionTypeFormData";

export const PromotionTypeFormPage = () => {
  const { id } = useParams();

  const isEdit = () => id !== undefined;

  return (
    <div>
      <PageHeader>
        {isEdit()
          ? trans.PROMOTION_TYPE_EDIT__PAGE_HEADER()
          : trans.PROMOTION_TYPE_CREATE__PAGE_HEADER()}
      </PageHeader>
      <PageContent>
        <PromotionTypeFormData />
      </PageContent>
    </div>
  );
};
