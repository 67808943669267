// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withVersions } from "pages/Applications/AppDetails/AppVersions/_shared/withVersionsDecorator";
import { trans } from "src/translations";

import { SectionCertification } from "./SectionCertification";

@restrictedArea(() => ({
  allowed: [ROLES.moderator.moderator, ROLES.moderator.contentManager],
}))
@withVersions
export class CertificationTab extends Component {
  static propTypes = {
    qaSummaries: PropTypes.object.isRequired,
    application: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string.isRequired,
    }).isRequired,

    // from @withVersions
    refreshVersions: PropTypes.func.isRequired,
    versionsLoading: PropTypes.bool.isRequired,
    versionsError: PropTypes.string,
    activeVersion: PropTypes.object,
  };

  componentDidMount() {
    this.getLatestVersionData();
  }

  getLatestVersionData = async () => {
    const { application, refreshVersions } = this.props;
    refreshVersions(application);
  };

  render() {
    const {
      qaSummaries: {
        accepted,
        latest_certification_result: latestCertificationResult,
      },
      application: { id, type },
      versionsLoading,
      versionsError,
      activeVersion,
    } = this.props;

    const alreadyProcessed = typeof accepted === "boolean";

    if (versionsLoading) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    if (versionsError) {
      return (
        <div>
          <Info type={"error"}>{trans.DEFAULT_ERROR_MESSAGE()}</Info>
        </div>
      );
    }

    const {
      is_certifiable: isCertifiable,
      is_distributable: isDistributable,
      for_examination_only: isForExaminationOnly,
    } = activeVersion;

    const certificationVersionId = get(latestCertificationResult, "id", null);

    return (
      <div>
        <SectionCertification
          id={id}
          type={type}
          certificationVersionId={certificationVersionId}
          alreadyProcessed={alreadyProcessed}
          accepted={accepted}
          isCertifiable={isCertifiable}
          isDistributable={isDistributable}
          isForExaminationOnly={isForExaminationOnly}
        />
      </div>
    );
  }
}
