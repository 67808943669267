// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { SearchBar } from "components/elements";
import { Section, TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";

import { ITEMS_PER_PAGE, TABS_CONFIG } from "./constants";
import { ProcessingQueueItemRow } from "./ProcessingQueueItemRow";
import { ProcessingQueueItemPropType } from "./propTypes";

export class ProcessingQueueRend extends PureComponent {
  static propTypes = {
    results: PropTypes.arrayOf(ProcessingQueueItemPropType),
    onSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    count: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  renderTableBody = (rows) => (
    <TableBody>
      {rows.map((item) => (
        <ProcessingQueueItemRow
          key={item.id}
          item={item}
          openConfirmModal={this.openConfirmModal}
        />
      ))}
    </TableBody>
  );

  render() {
    const { results, count, loading, error, onSearch, searchValue } =
      this.props;

    return (
      <Fragment>
        <Section>{trans.DEVICES__PROCESSING_QUEUE__DESCRIPTION()}</Section>

        <Section>
          <SearchBar
            onSearch={onSearch}
            value={searchValue || ""}
            placeholder={trans.DEVICES__PROCESSING_QUEUE__SEARCH_PLACEHOLDER()}
          />
        </Section>

        <Section>
          <TabbedTable
            tabsConfig={TABS_CONFIG}
            data={{
              loading,
              results,
              count,
              error,
            }}
            rowsPerPage={ITEMS_PER_PAGE}
            renderTableBody={this.renderTableBody}
          />
        </Section>
      </Fragment>
    );
  }
}
