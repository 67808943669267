// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Section } from "components/layout";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import { SubsectionWithUpdatedStatus } from "./_shared";
import styles from "./Changelog.scss";

const scopeDescriptionsMap = {
  REWRITE: trans.APP_EDIT_FOR_MODERATORS__CHANGELOG_MAJOR_CHANGE_LABEL(),
  BUGFIXES: trans.APP_EDIT_FOR_MODERATORS__CHANGELOG_MINOR_CHANGE_LABEL(),
  NOCODECHANGES: trans.APP_EDIT_FOR_MODERATORS__CHANGELOG_ASSETS_CHANGE_LABEL(),
  OTHER: trans.OTHER(),
};

export class Changelog extends Component {
  static propTypes = {
    scope: PropTypes.string,
    description: PropTypes.string,
  };

  render() {
    const { scope, description } = this.props;
    return (
      <Section header={trans.CHANGELOG()} dataTestId="changelog-section">
        <SubsectionWithUpdatedStatus
          headerContent={trans.SCOPE()}
          isUpdatedPath="changelog.scope"
        >
          <p className={styles.titleText}>
            {scopeDescriptionsMap[scope] || NO_VALUE_PLACEHOLDER}
          </p>
        </SubsectionWithUpdatedStatus>

        <SubsectionWithUpdatedStatus
          headerContent={trans.APP_EDIT_FOR_MODERATORS__APP_CHANGELOG_DESCRIPTION_LABEL()}
          isUpdatedPath="changelog.description"
          subsectionClassName={styles.descriptionText}
        >
          {description || NO_VALUE_PLACEHOLDER}
        </SubsectionWithUpdatedStatus>
      </Section>
    );
  }
}
