// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext } from "react";

import PropTypes from "prop-types";

import { Uploader } from "components/form/Uploader";
import { trans } from "src/translations";
import { cropImage, validateImage } from "utils/image";

import { FIELDS } from "../constants";
import { ModeContext } from "../context";

export const ICON_MIN_WIDTH = 320;
export const ICON_MIN_HEIGHT = 320;

export const IconField = ({ name }) => {
  const { isReadonly } = useContext(ModeContext);

  const validate = (blobUrl) => {
    return validateImage(blobUrl, {
      minWidth: ICON_MIN_WIDTH,
      minHeight: ICON_MIN_HEIGHT,
      maxWidth: 1024,
      maxHeight: 1024,
      maxSize: 9999999,
      validateAspectRatio: true,
    });
  };

  return (
    <Uploader
      {...FIELDS.ICON}
      name={`${name}.${FIELDS.ICON.name}`}
      info={trans.PROMOTION_DETAILS__FIELD_ICON_INFO({
        format: "png",
        minWidth: ICON_MIN_WIDTH,
        minHeight: ICON_MIN_HEIGHT,
        aspectRatio: "1:1",
      })}
      validate={validate}
      disabled={isReadonly}
      preprocess={(blobUrl, type) =>
        cropImage(blobUrl, type, ICON_MIN_WIDTH, ICON_MIN_HEIGHT)
      }
    />
  );
};

IconField.propTypes = {
  name: PropTypes.string.isRequired,
};
