// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import partition from "lodash-es/partition";
import PropTypes from "prop-types";

import {
  Section,
  Subsection,
  Table,
  TableHeader,
  TableHeaderColumn,
} from "components/layout";
import { SubTabs, SubTab } from "components/navigation";
import { SECTIONS, FIELDS } from "pages/Snap/shared/constants";
import styles from "pages/Snap/shared/Translations.scss";
import {
  getTranslationLanguageName,
  sortTranslations,
} from "utils/translations";

import { SectionFeed } from "./SectionFeed";
import { SectionLive } from "./SectionLive";

export class Translations extends PureComponent {
  static propTypes = {
    languages: PropTypes.array.isRequired,
    revision: PropTypes.object.isRequired,
    revisionType: PropTypes.string.isRequired,
  };

  state = {
    currentLanguage: 0,
    revisionType: this.props.revisionType,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.revisionType !== state.revisionType) {
      return {
        currentLanguage: 0,
        revisionType: props.revisionType,
      };
    }
    return null;
  }

  setLanguage(index) {
    return () => {
      const { currentLanguage } = this.state;
      if (index === currentLanguage) {
        return;
      }
      this.setState({ currentLanguage: index });
    };
  }

  showLanguageTranslations(translations) {
    const { currentLanguage } = this.state;
    const lang = translations[currentLanguage];

    lang.sections.sort((first, second) => {
      return first.order - second.order;
    });
    const sections = lang.sections.filter(
      (section) => !section.hasOwnProperty("show") || section.show
    );

    return sections.map((section, index) => {
      if (section.live) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <SectionLive section={section} index={index} key={"row-" + index} />
        );
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <SectionFeed section={section} index={index} key={"row-" + index} />
      );
    });
  }

  isDefaultTranslation = (translation) =>
    translation.language === this.props.revision.default_lang;

  getLanguageName(translation) {
    const { languages } = this.props;
    let name = getTranslationLanguageName(languages, translation);
    if (this.isDefaultTranslation(translation)) {
      name += " (default)";
    }
    return name.length === 0 ? "Untitled" : name;
  }

  sortTranslationsForRender(translations) {
    const { languages } = this.props;
    const [defaultTrans, notDefaultTrans] = partition(
      translations,
      this.isDefaultTranslation
    );

    return [
      ...sortTranslations(languages, defaultTrans),
      ...sortTranslations(languages, notDefaultTrans),
    ];
  }

  render() {
    const { revision } = this.props;
    const { currentLanguage } = this.state;
    const { translations } = revision;
    const sortedTranslations = this.sortTranslationsForRender(translations);

    return (
      <Section header={SECTIONS.CONFIGURATION.header}>
        <SubTabs>
          {sortedTranslations.map((translation, index) => {
            const label = this.getLanguageName(translation);
            return (
              <SubTab
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                text={label}
                active={index === currentLanguage}
                onClick={this.setLanguage(index)}
              />
            );
          })}
        </SubTabs>
        <Subsection header={FIELDS.TRANSLATION_APP_TITLE.label}>
          {sortedTranslations[currentLanguage].application_title}
        </Subsection>
        <Table layout="fixed" className={styles.table}>
          <TableHeader>
            <TableHeaderColumn className={styles.iconColumn}>
              {SECTIONS.TRANSLATIONS.colIcon}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.titleColumn}>
              {SECTIONS.TRANSLATIONS.colTitle}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.liveTitleColumn} />
            <TableHeaderColumn className={styles.contentColumn}>
              {SECTIONS.TRANSLATIONS.colURL}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.typeColumn}>
              {SECTIONS.TRANSLATIONS.colType}
            </TableHeaderColumn>
          </TableHeader>
          <tbody>{this.showLanguageTranslations(sortedTranslations)}</tbody>
        </Table>
      </Section>
    );
  }
}
