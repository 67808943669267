import { useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { restrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";

import {
  DEVICE_TYPE_QUERY,
  INITIAL_VALUES,
  FIELDS,
  DEVICE_TYPE_PDID,
} from "./constants";
import { CreateCommandRend } from "./CreateCommandRend";
import { createCommandMutation } from "./gql/createCommandMutation";

const adaptError = (error) => {
  if (!error) {
    return null;
  }
  return trans.DEVICES__COMMANDS_CREATE_SEND_COMMAND_ERROR();
};

const CreateDeviceCommandDataInner = () => {
  const [createCommand, { data, error, loading }] = useMutation(
    createCommandMutation
  );
  const navigate = useNavigate();
  const { state = {} } = useLocation();
  const handleSubmit = useCallback(
    async (values) => {
      const target = {};
      if (values.targetDevice.type === DEVICE_TYPE_QUERY) {
        target.collectionID = values.targetDevice.id;
      } else {
        target.devicePublicID = values.targetDevice.id;
      }

      await createCommand({
        variables: {
          input: {
            command: {
              name: values.name,
              payload: values.payload,
              timeToLive: values.timeToLive,
            },
            target,
          },
        },
      });
    },
    [createCommand]
  );

  useEffect(() => {
    if (data) {
      navigate(`/devices/commands/${data.createCommand.id}`);
    }
  }, [data, navigate]);

  const { command } = state;
  const initialValues = command
    ? {
        [FIELDS.name.name]: command.name,
        [FIELDS.payload.name]: command.payload,
        [FIELDS.targetDevice.name]: {
          id: command.target.collectionID || command.target.devicePublicID,
          name: command.target.collectionName || command.target.devicePublicID,
          type: command.target.collectionID
            ? DEVICE_TYPE_QUERY
            : DEVICE_TYPE_PDID,
          deviceCount: command.target.collectionDeviceCount,
        },
        [FIELDS.timeToLive.name]: command.timeToLive,
      }
    : INITIAL_VALUES;

  return (
    <CreateCommandRend
      error={adaptError(error)}
      loading={loading}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      isClone={Boolean(command)}
    />
  );
};

export const CreateCommandData = restrictedArea(() => ({
  allowed: [ROLES.deviceInventory.configControlManager],
}))(CreateDeviceCommandDataInner);
