// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Column, Row, Section } from "components/layout";

import { DevicePropType } from "../propTypes";
import styles from "./DevicePropertiesSection.scss";
import { DeviceProperty, DevicePropertyPropType } from "./DeviceProperty";

const partitionIntoColumns = (items) => {
  return items.reduce(
    ([a, b], thing, i) =>
      i % 2 === 0 ? [[...a, thing], b] : [a, [...b, thing]],
    [[], []]
  );
};

export const DevicePropertiesSection = ({
  header,
  device,
  properties,
  isSingleColumn = false,
  hiddenIfEmpty = false,
}) => {
  const renderProperty = (property) => (
    <DeviceProperty
      key={property.name}
      device={device}
      property={property}
      hiddenIfEmpty={hiddenIfEmpty}
    />
  );

  if (isSingleColumn) {
    return <Section header={header}>{properties.map(renderProperty)}</Section>;
  }

  const [leftColumnProps, rightColumnProps] = partitionIntoColumns(properties);

  return (
    <Section header={header}>
      <Row>
        <Column className={styles.columnHalfWidth}>
          {leftColumnProps.map(renderProperty)}
        </Column>
        <Column className={styles.columnHalfWidth}>
          {rightColumnProps.map(renderProperty)}
        </Column>
      </Row>
    </Section>
  );
};

DevicePropertiesSection.propTypes = {
  header: PropTypes.node.isRequired,
  device: DevicePropType,
  properties: PropTypes.arrayOf(DevicePropertyPropType),
  isSingleColumn: PropTypes.bool,
  hiddenIfEmpty: PropTypes.bool,
};
