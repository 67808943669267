// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { Loader } from "components/elements";
import { Select } from "components/form/elements";
import { Link } from "components/typography";
import { api } from "containers/Request";
import { trans } from "src/translations";

import { DEVICE_FAMILY_NONE } from "../../../constants";
import { FamilyFormModal } from "../../DeviceFamilyForm/FamilyFormModal";
import styles from "./deviceFamiliesSelect.scss";

@connect(null, (dispatch) => ({
  getDeviceOwnerWithFamilies: (id) =>
    dispatch(
      api.getDeviceFamiliesPaginated.action({
        queryParams: { owner: id, limit: "nolimit", nocache: uuid() },
      })
    ),
}))
export class DeviceFamiliesSelect extends Component {
  static propTypes = {
    ownerId: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,

    // from @connect
    getDeviceOwnerWithFamilies: PropTypes.func.isRequired,
  };

  state = {
    deviceFamilies: [],
    deviceFamiliesLoading: false,
    isModalOpen: false,
  };

  componentDidMount() {
    this.updateDeviceFamilies();
  }

  componentDidUpdate(prevProps) {
    const { ownerId, input } = this.props;

    if (prevProps.ownerId !== ownerId) {
      input.onChange(DEVICE_FAMILY_NONE);
      this.updateDeviceFamilies();
    }
  }

  openAddDeviceFamilyModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeAddDeviceFamilyModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleAddDeviceFamilySuccess = async (deviceFamilyId) => {
    const { input } = this.props;

    this.closeAddDeviceFamilyModal();
    await this.updateDeviceFamilies();
    input.onChange(deviceFamilyId);
  };

  async updateDeviceFamilies() {
    const { ownerId, getDeviceOwnerWithFamilies } = this.props;

    if (ownerId) {
      this.setState({ deviceFamiliesLoading: true });

      const deviceOwner = await getDeviceOwnerWithFamilies(ownerId);

      // handle fetch errors
      if (deviceOwner.error) {
        this.setState({ deviceFamilies: [], deviceFamiliesLoading: false });
        return;
      }
      const deviceFamilies = deviceOwner.result.results;

      const deviceFamiliesSelect = deviceFamilies.map((df) => {
        return {
          value: df.id,
          text: df.name,
        };
      });

      this.setState({
        deviceFamilies: deviceFamiliesSelect,
        deviceFamiliesLoading: false,
      });
    }
  }

  render() {
    const { input, meta, ownerId } = this.props;

    return (
      <div>
        <FamilyFormModal
          isModalOpen={this.state.isModalOpen}
          ownerId={ownerId}
          onRequestSuccess={this.handleAddDeviceFamilySuccess}
          closeModal={this.closeAddDeviceFamilyModal}
          forAdmin={true}
        />
        {this.state.deviceFamiliesLoading ? (
          <Loader />
        ) : (
          <Select
            initOption={{
              value: DEVICE_FAMILY_NONE,
              text: trans.DEVICES__CHOOSE_FAMILY(),
            }}
            capitalize={false}
            label={trans.DEVICES__FAMILY_LABEL()}
            required={true}
            values={this.state.deviceFamilies}
            {...input}
            {...meta}
          >
            <p className={styles.description}>
              {trans.DEVICES__IF_YOU_CANNOT_FIND()}{" "}
              <Link
                className={styles.addDeviceFamilyLink}
                onClick={this.openAddDeviceFamilyModal}
              >
                {trans.DEVICES__ADD_NEW_FAMILY()}
              </Link>
            </p>
          </Select>
        )}
      </div>
    );
  }
}
