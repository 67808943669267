// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import * as actions from "./actions";
import styles from "./MenuSwitcher.scss";

@connect(null, (dispatch) => ({
  switchMenu: () => {
    dispatch(actions.switchMenu());
  },
}))
export class MenuSwitcher extends Component {
  static propTypes = {
    switchMenu: PropTypes.func,
    collapsed: PropTypes.bool,
    className: PropTypes.string,
  };

  getIconName() {
    const { collapsed } = this.props;
    return collapsed ? "arrow-right" : "arrow-left";
  }

  render() {
    const { switchMenu, collapsed, className } = this.props;

    return (
      <div
        className={classes(styles.menuSwitcher, className, {
          [styles.collapsed]: collapsed,
        })}
      >
        <a
          href="#"
          title={trans.SIDE_MENU__COLLAPSE_EXPAND_HINT()}
          onClick={() => switchMenu()}
          className={styles.icon}
        >
          <GrabsonIcon name={this.getIconName()} font="micro" />
        </a>
      </div>
    );
  }
}
