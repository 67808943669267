// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { trans } from "src/translations";

/**
 * Component that informs user that some value in form has invalid value.
 * Usually placed near submit button.
 */
export const FormHasErrors = ({ submitFailed }) => {
  if (!submitFailed) {
    return null;
  }

  return <Info type="error">{trans.DEFAULT_FORM_SUBMIT_ERROR_MESSAGE()}</Info>;
};

FormHasErrors.propTypes = {
  submitFailed: PropTypes.bool.isRequired,
};
