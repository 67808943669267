// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { RestrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";

import { ChangeMenu } from "../_shared";

export class Exclusivity extends Component {
  static propTypes = {
    onExclusivityChange: PropTypes.func.isRequired,
    isExclusive: PropTypes.bool,
  };

  render() {
    const { isExclusive, onExclusivityChange } = this.props;

    return (
      <RestrictedArea
        allowed={[ROLES.moderator.moderator]}
        fallbackChildren={
          <p>{isExclusive ? trans.EXCLUSIVE() : trans.STANDARD()}</p>
        }
      >
        <ChangeMenu
          value={isExclusive ? trans.EXCLUSIVE() : trans.STANDARD()}
          options={[
            {
              id: "standard",
              text: trans.STANDARD(),
              onClick: () => onExclusivityChange(false),
            },
            {
              id: "exclusive",
              text: trans.EXCLUSIVE(),
              onClick: () => onExclusivityChange(true),
            },
          ]}
        />
      </RestrictedArea>
    );
  }
}
