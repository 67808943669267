import { PageContent, PageHeader } from "components/layout";
import { trans } from "src/translations";

import { ManifestListData } from "./ManifestListData";

export const ManifestList = () => {
  return (
    <>
      <PageHeader>{trans.APP_MANIFESTS_ROUTE_NAME()}</PageHeader>
      <PageContent>
        <ManifestListData />
      </PageContent>
    </>
  );
};
