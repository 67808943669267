// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";
import queryString from "query-string";

import { SearchBarWithFilters } from "components/elements";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { withQueryParams, withRouter } from "utils/decorators";
import { adaptSortingQueryParams, retrieveFiltersFromInclude } from "utils/url";

import { APP_ROUTE_MODERATION } from "../constants";
import { ApplicationsTabbedTable } from "./ApplicationsTabbedTable";
import {
  parseApplicationsListFilters,
  fromElasticSearchDataToIm,
} from "./utils";

@withRouter
@withQueryParams
@withActiveOrganization
export class ApplicationsTabbedTableLogic extends Component {
  static propTypes = {
    appRouteType: PropTypes.string.isRequired,
    tabsConfig: PropTypes.array.isRequired,
    getApplicationsPaginated: PropTypes.func.isRequired,
    getAvailableFilters: PropTypes.func.isRequired,
    getSearchSuggestions: PropTypes.func,
    onExport: PropTypes.func,
    isAppListExclusive: PropTypes.bool,

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    apps: [],
    count: 0,
    loading: 0,
    filtersDataChoices: parseApplicationsListFilters(),
    errorOnUpdatingApplications: null,
    errorOnUpdatingAvailableFilters: null,
    defaultOrder: {},
  };

  componentDidMount() {
    this.updateAvailableFilters();
    this.updateApplications();
  }

  componentDidUpdate(prevProps) {
    if (
      this.isLocationChanged(prevProps.location) ||
      this.props.activeOrganization.id !== prevProps.activeOrganization.id
    ) {
      this.updateApplications();
    }
  }

  isLocationChanged(prevLocation) {
    const { location } = this.props;
    return !isEqual(
      this.createQueryParams(location),
      this.createQueryParams(prevLocation)
    );
  }

  getDefaultOrder(meta) {
    const {
      accepted_params: { order },
    } = meta;
    const isDesc = order.charAt(0) === "-";

    return isDesc
      ? {
          sortBy: order.slice(1),
          sortOrder: "DESC",
        }
      : {
          sortBy: order,
          sortOrder: "ASC",
        };
  }

  async updateApplications() {
    const { getApplicationsPaginated, location } = this.props;

    try {
      this.setState((prevState) => ({
        loading: prevState.loading + 1,
      }));

      const { results: appsResults, meta } = await getApplicationsPaginated(
        this.createQueryParams(location)
      );

      this.setState({
        apps: appsResults.map(fromElasticSearchDataToIm),
        count: meta.count,
        defaultOrder: this.getDefaultOrder(meta),
        errorOnUpdatingApplications: null,
      });
    } catch (error) {
      this.setState({
        errorOnUpdatingApplications: error,
      });
    } finally {
      this.setState((prevState) => ({
        loading: prevState.loading - 1,
      }));
    }
  }

  updateAvailableFilters = async () => {
    const { getAvailableFilters } = this.props;

    try {
      this.setState((prevState) => ({
        loading: prevState.loading + 1,
      }));

      const { results } = await getAvailableFilters();

      this.setState({
        errorOnUpdatingAvailableFilters: null,
        filtersDataChoices: parseApplicationsListFilters(results),
      });
    } catch (err) {
      this.setState({ errorOnUpdatingAvailableFilters: err });
    } finally {
      this.setState((prevState) => ({
        loading: prevState.loading - 1,
      }));
    }
  };

  onSearchBarChange = (searchParams) => {
    let queryParams = searchParams;
    if (typeof searchParams === "string") {
      queryParams = { search: searchParams };
    }
    const {
      location: { pathname },
      navigate,
    } = this.props;
    const searchString = queryString.stringify(queryParams);
    navigate(`${pathname}?${searchString}`);
  };

  createQueryParams(location) {
    const { isAppListExclusive, appRouteType } = this.props;
    let queryParams = queryString.parse(location.search);
    const isSortUndefined = !(queryParams.sort_by && queryParams.order);

    if (isSortUndefined && appRouteType === APP_ROUTE_MODERATION) {
      Object.assign(queryParams, { sort_by: "date_submitted", order: "DESC" });
    }

    queryParams = adaptSortingQueryParams(queryParams);

    delete queryParams.tab;
    queryParams = retrieveFiltersFromInclude(queryParams);
    delete queryParams.include;
    return {
      page: 1,
      /* eslint-disable camelcase */
      ...(isAppListExclusive ? { is_exclusive: false } : {}),
      ...queryParams,
    };
  }

  render() {
    const {
      filtersDataChoices,
      apps,
      count,
      loading,
      errorOnUpdatingApplications,
      errorOnUpdatingAvailableFilters,
      defaultOrder,
    } = this.state;
    const {
      queryParams,
      onExport,
      tabsConfig,
      appRouteType,
      getSearchSuggestions,
    } = this.props;
    const locationMessage = this.props.location.state?.message;

    return (
      <div>
        <Section>
          <SearchBarWithFilters
            filtersDataChoices={filtersDataChoices}
            onSearch={this.onSearchBarChange}
            searchParams={queryParams}
            suggestionApiEndpoint={getSearchSuggestions}
          />
        </Section>
        <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
        <Section>
          <ApplicationsTabbedTable
            tabsConfig={tabsConfig}
            appRouteType={appRouteType}
            data={{
              results: apps,
              count: count,
              loading: loading > 0,
              defaultOrder,
              error:
                errorOnUpdatingApplications || errorOnUpdatingAvailableFilters,
            }}
            onExport={onExport}
          />
        </Section>
      </div>
    );
  }
}
