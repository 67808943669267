// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { deepMerge } from "utils/object";
import { flatten, unflatten } from "utils/serialize";

export const forEachComponent = (config, func) => {
  for (const tab of config.tabs) {
    for (const section of tab.sections) {
      for (const component of section.components) {
        func(component);
      }
    }
  }
};

export const forEachComponentAsync = async (config, func) => {
  for (const tab of config.tabs) {
    for (const section of tab.sections) {
      for (const component of section.components) {
        await func(component);
      }
    }
  }
};

export const forEachComponentInTab = (tabNode, func) => {
  const config = { tabs: [tabNode] };
  forEachComponent(config, func);
};

export const getFields = (config) => {
  let fields = [];

  forEachComponent(config, (component) => {
    if (component.fields) {
      fields = [...fields, ...component.fields];
    }
  });

  return fields;
};

export const validate = function (config) {
  return function (values, props) {
    let errors = {};

    const fields = getFields(config);

    const flatValues = flatten(values, fields);
    forEachComponent(config, (component) => {
      if (component.validate) {
        errors = component.validate(flatValues, values, errors, props);
      }
    });

    return unflatten(errors);
  };
};

export const getInitialValues = (config) => {
  let initialValues = {};

  forEachComponent(config, (component) => {
    if (component.initialValues) {
      initialValues = deepMerge(component.initialValues, initialValues);
    }
  });

  return initialValues;
};
