// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TableRow, TableRowColumn } from "components/layout";

export const OrganizationItem = ({ organization }) => {
  return (
    <TableRow highlighting={false}>
      <TableRowColumn>{organization.name}</TableRowColumn>
    </TableRow>
  );
};

OrganizationItem.propTypes = {
  organization: PropTypes.object.isRequired,
};
