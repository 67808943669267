// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Section } from "components/layout";
import { SubTab, SubTabs } from "components/navigation";
import { trans } from "src/translations";

import { MAX_VISIBLE_DEVICES_WITH_ALERT_COUNT } from "./constants";
import {
  DEVICE_INVENTORY_DEFAULT_PATH,
  DEVICE_INVENTORY_ALERTS_PATH,
  DEVICE_INVENTORY_DISMISSED_PATH,
} from "./DeviceInventoryRend";
import styles from "./DeviceInventoryTabs.scss";

export const DeviceInventoryTabs = ({ path, devicesWithAlertCount }) => {
  return (
    <Section>
      <SubTabs>
        <SubTab
          key="device_inventory"
          active={path === DEVICE_INVENTORY_DEFAULT_PATH}
          to={DEVICE_INVENTORY_DEFAULT_PATH}
          text={trans.DEVICES__DEVICE_INVENTORY_ALL_DEVICES_TAB()}
          dataTestId="device-inventory-main-tab"
        />
        <SubTab
          key="alerts"
          active={path === DEVICE_INVENTORY_ALERTS_PATH}
          to={DEVICE_INVENTORY_ALERTS_PATH}
          text={trans.DEVICES__DEVICE_INVENTORY_ALERTS_TAB()}
          dataTestId="device-inventory-alerts-tab"
          customIcon={
            devicesWithAlertCount ? (
              <div className={styles.container}>
                <div className={styles.alertsCounter}>
                  <div
                    className={styles.alertsCounterValue}
                    data-test-id="device-inventory-alerts-counter"
                  >
                    {devicesWithAlertCount <
                    MAX_VISIBLE_DEVICES_WITH_ALERT_COUNT
                      ? devicesWithAlertCount
                      : trans.DEVICES__DEVICE_INVENTORY_OVER_THOUSAND_DEVICES_WITH_ALERT_COUNTER()}
                  </div>
                </div>
              </div>
            ) : null
          }
        />
        <SubTab
          key="dismissed_alerts"
          active={path === DEVICE_INVENTORY_DISMISSED_PATH}
          to={DEVICE_INVENTORY_DISMISSED_PATH}
          text={trans.DEVICES__DEVICE_INVENTORY_DISMISSED_ALERTS_TAB()}
          dataTestId="device-inventory-dismissed-tab"
        />
      </SubTabs>
    </Section>
  );
};

DeviceInventoryTabs.propTypes = {
  path: PropTypes.string.isRequired,
  devicesWithAlertCount: PropTypes.number,
};
