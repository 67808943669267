import { useFormikContext } from "formik";

import { Info } from "components/feedback";
import { trans } from "src/translations";

import { APP_ID_FORM_FIELDS, MANIFEST_FIELDS } from "./constants";

const ERRORS = {
  applicationIdForm: APP_ID_FORM_FIELDS.applicationId,
  manifestForm: MANIFEST_FIELDS.manifestError,
  request: "requestError",
};

export const ErrorNotification = () => {
  const { isValid, isValidating, errors } = useFormikContext();

  const prepareErrorMessage = (errors) => {
    switch (true) {
      case ERRORS.applicationIdForm in errors:
        return trans.APP_MANIFESTS_FORM_VALIDATION_ERROR({
          text: "The app Id field is required but was not found",
        });
      case ERRORS.manifestForm in errors:
        return trans.APP_MANIFESTS_FORM_VALIDATION_ERROR({
          text: "All fields related to the app configuration are currently empty",
        });
      case ERRORS.request in errors:
        return errors[ERRORS.request];
      default:
        return trans.APP_MANIFESTS_FORM_VALIDATION_ERROR({
          text: "There are some errors or missing details in your form",
        });
    }
  };

  if (!isValid && !isValidating) {
    return <Info type="error">{prepareErrorMessage(errors)}</Info>;
  }
  return null;
};
