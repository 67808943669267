// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withSearch, withSorting } from "utils/decorators";
import { withRouter } from "utils/decorators/withRouter";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import { DefaultSortContext } from "../../_shared/Context/defaultSortContext";
import { listProcessingQueueQuery } from "./gql/listProcessingQueueQuery";
import { ITEMS_PER_PAGE, ProcessingQueueRend } from "./ProcessingQueueRend";
import { adaptProcessingQueueItem } from "./utils";

@restrictedArea(() => ({
  allowed: [ROLES.softwareUpdates.updateManager],
}))
@withQuery({ name: "processingQueue", query: listProcessingQueueQuery })
@withSearch
@withRouter
@withActiveOrganization
@withSorting({
  defaultSortBy: "created_at",
  allowedSortByValues: ["name", "created_at", "status"],
  defaultSortOrder: "DESC",
})
export class ProcessingQueueData extends PureComponent {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withQuery
    processingQueueQuery: PropTypes.func,
    processingQueueQueryStatus: queryResultType,

    //from @withSorting
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  };

  state = {
    sortingDefault: {
      sortBy: this.props.sortBy,
      sortOrder: this.props.sortOrder,
    },
  };

  componentDidMount() {
    this.fetchProcessingQueue();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location === prevProps.location &&
      this.props.activeOrganization.id === prevProps.activeOrganization.id
    ) {
      return;
    }

    this.fetchProcessingQueue();
  }

  fetchProcessingQueue = () => {
    const { processingQueueQuery, location, searchValue, sortOrder, sortBy } =
      this.props;

    processingQueueQuery({
      variables: {
        pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
        sort: {
          name: sortBy.toUpperCase(),
          order: sortOrder,
        },
        search: {
          query: searchValue || "",
        },
      },
    });
  };

  getResults = () => {
    const { data, loading, error } = this.props.processingQueueQueryStatus;

    const count = get(
      data,
      "processingSoftwarePackages.processingSoftwarePackages.pagination.count",
      0
    );
    const results = get(
      data,
      "processingSoftwarePackages.processingSoftwarePackages.items",
      []
    );

    return {
      results: results.map(adaptProcessingQueueItem),
      count,
      loading,
      error: error && getGqlErrorMessage(error),
    };
  };

  render() {
    return (
      <DefaultSortContext.Provider value={this.state.sortingDefault}>
        <ProcessingQueueRend
          {...this.getResults()}
          onSearch={this.props.changeSearch}
          searchValue={this.props.searchValue}
        />
      </DefaultSortContext.Provider>
    );
  }
}
