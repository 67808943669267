// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { LineLoader } from "components/elements/LineLoader";
import { Info } from "components/feedback";
import { api } from "containers/Request";
import { trans } from "src/translations";
import { createBody } from "utils/jsonApi";

import { CERTIFICATION_STATUS } from "./constants";
import { Versions } from "./Versions";

@connect(
  ({ auth }) => ({
    userId: auth.profile.id,
  }),
  (dispatch) => ({
    patchCertification: (id, body) => {
      return dispatch(
        api.patchCertification.action({
          params: { id },
          options: { body },
        })
      );
    },
    getSDKVersionsPaginated: () => {
      return dispatch(
        api.getSDKVersionsPaginated.action({
          cache: true,
          queryParams: { limit: "nolimit" },
        })
      );
    },
    /* We would like to be sure to only get certification_targets for given certification_results_id,
  so we are using include in certification results endpoint */
    getCertficationTargets: (id) => {
      return dispatch(
        api.getCertificationResults.action({
          params: { id },
          queryParams: {
            include: "certification_targets",
            limit: "nolimit",
          },
        })
      );
    },
  })
)
export class VersionsData extends Component {
  static propTypes = {
    certificationVersionId: PropTypes.string,

    // from @connect
    getCertficationTargets: PropTypes.func,
    getSDKVersionsPaginated: PropTypes.func,
    patchCertification: PropTypes.func,
  };

  state = {
    submitting: false,
    loading: false,
    error: null,
    sdkVersions: [],
    certificationTargets: [],
  };

  async updateCertificationTarget(id, status) {
    const { certificationVersionId } = this.props;
    this.setState({ error: null, submitting: true });
    const { patchCertification } = this.props;
    try {
      const body = createBody({
        type: "CertificationResult",
        id: certificationVersionId,
        certification_targets: [
          {
            sdk_id: id,
            status,
            //to be removed, when backend will get rid of validating such field
            owner_id: 1,
          },
        ],
      });
      const { error } = await patchCertification(certificationVersionId, body);
      this.setState({ submitting: false });
      if (error) {
        const statusName = CERTIFICATION_STATUS[status].name;
        this.setState({
          error: `${trans.APP_DETAILS_CERTIFICATION__STATUS_CHANGE_ERROR()} ${statusName}`,
        });
        return;
      }
      this.refreshData();
    } catch (error) {
      this.setState({ submitting: false, error: error.message });
    }
  }

  parseStatus(status) {
    const validStatuses = [
      CERTIFICATION_STATUS.ACCEPTED.id,
      CERTIFICATION_STATUS.REJECTED.id,
      CERTIFICATION_STATUS.PENDING.id,
    ];
    return validStatuses.includes(status)
      ? status
      : CERTIFICATION_STATUS.PENDING.id;
  }

  /*  We would like to map sdk versions to statuses fetched form certification targets */
  prepareCertificationTargets(sdkVersions, certificationTargets) {
    const { certification_targets } = certificationTargets;
    return sdkVersions.map((version) => {
      const target = certification_targets.find(
        (target) => Number(target.sdk_id) === Number(version.id)
      );
      return {
        id: version.id,
        number: version.name,
        status: this.parseStatus(get(target, "status")),
      };
    });
  }

  refreshData = async () => {
    const { certificationVersionId } = this.props;
    try {
      this.setState({ loading: true });
      const { getSDKVersionsPaginated, getCertficationTargets } = this.props;
      const results = [
        await getSDKVersionsPaginated(),
        await getCertficationTargets(certificationVersionId),
      ];
      const [sdkVersions, certificationTargets] = results.map((res) => {
        const { error, result } = res;
        if (error) {
          throw error.message;
        }
        if (!result.results) {
          throw trans.APP_DETAILS_VERSION__UNEXPECTED_ERROR();
        }
        return result.results;
      });
      this.setState({
        loading: false,
        sdkVersions,
        certificationTargets,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
    }
  };
  componentDidMount() {
    this.refreshData();
  }
  render() {
    const { sdkVersions, certificationTargets, submitting, error, loading } =
      this.state;
    const versions = this.prepareCertificationTargets(
      sdkVersions,
      certificationTargets
    );
    return (
      <div>
        <LineLoader loading={submitting || loading} />
        <br />
        {versions.length === 0 ? (
          <div>-</div>
        ) : (
          <div>
            {error && <Info type="error">{error}</Info>}
            <Versions
              versions={versions}
              loading={submitting || loading}
              updateCertificationTarget={(id, status) =>
                this.updateCertificationTarget(id, status)
              }
            />
          </div>
        )}
      </div>
    );
  }
}
