// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Modal } from "components/layout";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

import { OrganizationsSearchableList } from "./OrganizationsSearchableList";
import { haveOrganizationsChanged } from "./utils";

export const ITEMS_PER_PAGE = 10;

const AddOrganizationComponent = ({
  getOrganizationsPaginated,
  selectedOrganizations,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [organizations, setOrganizations] = useState(selectedOrganizations);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setOrganizations(selectedOrganizations);
    setTouched(false);
  }, [selectedOrganizations]);

  const updateOrganizations = (organizations) => {
    setOrganizations(organizations);
    setTouched(haveOrganizationsChanged(selectedOrganizations, organizations));
  };

  const checkOrganization = (id) => {
    const index = organizations.indexOf(id);
    if (index >= 0) {
      const organizationsCopy = organizations.slice();
      organizationsCopy.splice(index, 1);
      updateOrganizations(organizationsCopy);
    } else {
      updateOrganizations([...organizations, id]);
    }
  };

  const isChecked = (id) => organizations.includes(id);

  const checkAll = (items, checked) => {
    if (checked) {
      updateOrganizations(items.map((item) => item.id));
    } else {
      updateOrganizations([]);
    }
  };

  return (
    <Modal
      title={trans.PROMOTION_TYPE_EDIT__ORGANIZATIONS_MODAL_TITLE()}
      size="big"
      isOpen={isOpen}
      onClose={onClose}
      dataTestId="add-organization-items-modal"
      actions={
        <ButtonsWrapper position="modal">
          <Button
            type="normal"
            onClick={onClose}
            dataTestId="add-organization-cancel-button"
          >
            {trans.CANCEL()}
          </Button>
          <Button
            type="green"
            disabled={!touched}
            onClick={() => onSubmit(organizations)}
            dataTestId="add-organization-add-button"
          >
            {trans.SAVE()}
          </Button>
        </ButtonsWrapper>
      }
    >
      <OrganizationsSearchableList
        getOrganizationsPaginated={getOrganizationsPaginated}
        organizations={organizations}
        isChecked={isChecked}
        onCheck={checkOrganization}
        onCheckAll={checkAll}
      />
    </Modal>
  );
};

AddOrganizationComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  selectedOrganizations: PropTypes.array.isRequired,
  getOrganizationsPaginated: PropTypes.func.isRequired,
};

export const AddOrganizationModal = connect(null, (dispatch) => ({
  getOrganizationsPaginated: (queryParams) =>
    dispatch(
      apiThatThrows.getOrganizationsPaginated.action({
        queryParams: {
          ...queryParams,
          type: "PromotionManagement",
          limit: ITEMS_PER_PAGE,
        },
      })
    ),
}))(AddOrganizationComponent);
