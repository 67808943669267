// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const jsonPointerToFieldName = {
  "/data/attributes/application_url": "metadata.application_url",
  "/data/attributes/category": "metadata.category",

  // profiles
  "/data/attributes/profiles/android_profiles":
    "metadata.android_profiles_error",
  "/data/attributes/profiles/linux_profiles": "metadata.linux_profiles_error",

  // images
  "/data/attributes/images/icon_small/image": "metadata.icon_small",
  "/data/attributes/images/icon_big/image": "metadata.metadata.icon_big",
  "/data/attributes/images/thumbnail/image": "metadata.metadata.thumbnail",
  "/data/attributes/images/billboard/image": "metadata.metadata.billboard",
  "/data/attributes/images/screenshots/:id/image": "metadata.screenshot[:id]",

  "/data/attributes/support_email": "support_email",

  // translations
  "/data/attributes/translations/:id/is_default":
    "metadata.translations[:id].default_translation",
  "/data/attributes/translations/:id/language":
    "metadata.translations[:id].language",
  "/data/attributes/translations/:id/app_title":
    "metadata.translations[:id].name",
  "/data/attributes/translations/:id/app_short_description":
    "metadata.translations[:id].short_description",

  // technical info
  "/data/attributes/technical_details/contains_video":
    "metadata.contains_video",
  "/data/attributes/technical_details/video_type/contains_live_video":
    "metadata.contains_live_video",
  "/data/attributes/technical_details/video_type/contains_vod":
    "metadata.contains_vod",
  "/data/attributes/technical_details/video_formats/:id/slug":
    "metadata.metadata_video_formats[:id].format",
  "/data/attributes/technical_details/video_formats/:id/comment":
    "metadata.metadata_video_formats[:id].comment",
  "/data/attributes/technical_details/video_adaptive_streaming/:id/slug":
    "metadata.metadata_video_adaptive_streamings[:id].streaming",
  "/data/attributes/technical_details/video_adaptive_streaming/:id/comment":
    "metadata.metadata_video_adaptive_streamings[:id].comment",
  "/data/attributes/technical_details/video_drms/:id/slug":
    "metadata.metadata_video_drms[:id].drm",
  "/data/attributes/technical_details/video_drms/:id/comment":
    "metadata.metadata_video_drms[:id].comment",

  // additional comments
  "/data/attributes/additional_comments": "metadata.additional_comments",

  // changelog
  "/data/attributes/changelog/change_type": "changelog.change_type",
  "/data/attributes/changelog/additional_information":
    "changelog.additional_information",

  // comment
  "/data/attributes/description_for_moderators": "metadata.comment",

  // availability
  "/data/attributes/markets/app_is_available_on/country_codes":
    "metadata.available_location_codes",
  "/data/attributes/markets/app_is_available_on/selection":
    "metadata.available_location_market",

  // markets
  "/data/attributes/markets/app_designed_for/country_codes":
    "metadata.location_codes",
  "/data/attributes/markets/app_designed_for/selection":
    "metadata.location_market",

  // monetization
  "/data/attributes/monetization": "metadata.produce_revenue",
  "/data/attributes/monetization/other":
    "metadata.produce_revenue_value.other_comment",
  "/data/attributes/monetization/transactions":
    "metadata.produce_revenue_value.transactions",
  "/data/attributes/monetization/subscriptions":
    "metadata.produce_revenue_value.subscriptions",
  "/data/attributes/monetization/advertisement":
    "metadata.produce_revenue_value.advertisement",

  // contracts
  "/data/attributes/agreement_version": "metadata.agreement_version",
  "/data/attributes/terms_accepted": "metadata.terms_accepted",
  "/data/attributes/accepted_criteria": "metadata.accepted_criteria",

  // distribution
  "/data/attributes/is_distributable": "metadata.distribute_in_tvstore",
};
