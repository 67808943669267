// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import {
  CheckAllButton,
  SelectedCountFooter,
  SearchableList,
} from "components/elements";
import { getCountriesForContinent } from "utils/continents";

import styles from "./CountriesList.scss";
import { GeoMapListItem } from "./GeoMapListItem";

export class CountriesList extends Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    countries: PropTypes.array.isRequired,
    continent: PropTypes.string,
    isCountryDisabled: PropTypes.func.isRequired,
    isCountryChecked: PropTypes.func.isRequired,
    onCountriesSelected: PropTypes.func.isRequired,
  };

  handleCountrySelected = (code) => {
    const { onCountriesSelected, isCountryChecked } = this.props;
    onCountriesSelected([code], !isCountryChecked(code));
  };

  toggleMany = (items) => (value) => {
    const { onCountriesSelected } = this.props;
    onCountriesSelected(
      items.map((e) => e.code),
      value
    );
  };

  isCountryChecked = (country) => this.props.isCountryChecked(country.code);

  getSelectedCount() {
    const { countries } = this.props;
    return countries.filter(this.isCountryChecked).length;
  }

  getDisplayedCountries() {
    const { countries, continent } = this.props;
    return getCountriesForContinent(countries, continent);
  }

  render() {
    const { isCountryDisabled, isCountryChecked, fieldName } = this.props;

    return (
      <Fragment>
        <SearchableList
          list={this.getDisplayedCountries()}
          searchBy="name"
          classNameList={styles.list}
          optionsListElement={(visibleItems) => (
            <CheckAllButton
              fieldName={`${fieldName}--countries-list`}
              onChange={this.toggleMany(visibleItems)}
              checked={visibleItems.every(this.isCountryChecked)}
              disabled={visibleItems.length === 0}
              className={styles.checkAllButton}
              isRtl={true}
            />
          )}
        >
          {(country) => (
            <GeoMapListItem
              key={country.code}
              className={styles.listRow}
              labelClassName={styles.countryLabel}
              disabled={isCountryDisabled(country.code)}
              checked={isCountryChecked(country.code)}
              onChange={this.handleCountrySelected}
              flagCode={country.code.toLowerCase()}
              value={country.code}
              text={country.name}
              dataTestId="country-item"
            />
          )}
        </SearchableList>
        <SelectedCountFooter selectedCount={this.getSelectedCount()} />
      </Fragment>
    );
  }
}
