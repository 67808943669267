// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchableList, SearchableListPanel } from "components/elements";
import { Info } from "components/feedback";
import { Radio } from "components/form/elements";
import { Modal } from "components/layout";
import { InfiniteData } from "containers/InfiniteData/InfiniteData";
import { apiThatThrows } from "containers/Request";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import styles from "./UsersModal.scss";

const INVALID_ID = "-1";
const ITEMS_PER_PAGE = 10;

@connect(null, (dispatch) => ({
  getMyUsersPaginated: (queryParams) =>
    dispatch(
      apiThatThrows.getMyUsersPaginated.action({
        queryParams: { ...queryParams, limit: ITEMS_PER_PAGE },
      })
    ),
}))
export class UsersModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    onUserSelect: PropTypes.func,
    getMyUsersPaginated: PropTypes.func,
    roleFilter: PropTypes.string,
    error: PropTypes.object,
    loading: PropTypes.bool,
    initialSelectedUserId: PropTypes.string,
  };

  static defaultProps = {
    title: "Select user",
    roleFilter: "",
  };

  state = {
    users: [],
    selectedUserId: INVALID_ID,
    prevInitialSelectedUserId: INVALID_ID,
  };

  static getDerivedStateFromProps(props, state) {
    const { initialSelectedUserId } = props;
    const { prevInitialSelectedUserId } = state;

    if (initialSelectedUserId !== prevInitialSelectedUserId) {
      return {
        selectedUserId: initialSelectedUserId,
        prevInitialSelectedUserId: initialSelectedUserId,
      };
    }

    return null;
  }

  handleUserChange = (e) => {
    const userId = e.target.value;
    this.setState({ selectedUserId: userId });
  };

  handleUserSelect = () => {
    const { onUserSelect } = this.props;
    const { selectedUserId, users } = this.state;

    const user = users.find((u) => u.id === selectedUserId);
    if (onUserSelect && user) {
      onUserSelect(user);
    }
  };

  fetchData = (queryParams) => {
    const { getMyUsersPaginated, roleFilter } = this.props;
    queryParams.role = roleFilter;
    return getMyUsersPaginated(queryParams);
  };

  mapData = (data) => {
    const users = data.map(({ id, given_name, family_name }) => ({
      id,
      name: [given_name, family_name].join(" ") || NO_VALUE_PLACEHOLDER,
      givenName: given_name,
      familyName: family_name,
    }));
    this.setState((prevState) => ({
      users: uniq(prevState.users.concat(users), "id"),
    }));
    return users;
  };

  render() {
    const { isOpen, title, onClose, error, loading } = this.props;
    const { selectedUserId } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        title={title}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button onClick={onClose} disabled={loading}>
              {trans.CANCEL()}
            </Button>
            <Button
              processing={loading}
              type="green"
              disabled={loading || selectedUserId === INVALID_ID}
              onClick={this.handleUserSelect}
            >
              {trans.SELECT()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <InfiniteData
          fetchData={this.fetchData}
          mapData={this.mapData}
          itemsPerPage={ITEMS_PER_PAGE}
        >
          {({
            data,
            pending,
            error,
            allDataLoaded,
            handleScroll,
            handleSearchChange,
          }) => {
            return (
              <SearchableList
                list={data}
                classNameList={styles.searchableList}
                onScroll={handleScroll}
                onSearchChange={handleSearchChange}
                withLoader={true}
                loading={pending}
                allDataLoaded={allDataLoaded}
                error={error.message}
              >
                {(user) => {
                  return (
                    <SearchableListPanel key={user.id}>
                      <Radio
                        defaultValue={user.id}
                        value={selectedUserId}
                        onChange={this.handleUserChange}
                        label={user.name}
                        disabled={loading}
                      />
                    </SearchableListPanel>
                  );
                }}
              </SearchableList>
            );
          }}
        </InfiniteData>
        {error && <Info type="error">{error.message}</Info>}
      </Modal>
    );
  }
}
