// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { TableBody } from "components/layout";

import { AdminPlanRow } from "./AdminPlanRow";

export class AdminPlansTableBody extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  };

  render() {
    const { data } = this.props;

    return (
      <TableBody>
        {data.reduce((acc, plan) => {
          acc.push(<AdminPlanRow key={`plan-${plan.id}`} plan={plan} />);
          return acc;
        }, [])}
      </TableBody>
    );
  }
}
