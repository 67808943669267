// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import {
  updateTargetsActionCreator,
  toggleDeviceModelActionCreator,
  toggleDeviceFamilyActionCreator,
  toggleDistributorActionCreator,
  deselectAllActionCreator,
} from "reduxReusable/selectedTargets/actionCreators";

import { SELECTED_TARGETS_ACTION_SUBTYPE } from "./constants";

export const updateTargets = updateTargetsActionCreator(
  SELECTED_TARGETS_ACTION_SUBTYPE
);

export const toggleDeviceModel = toggleDeviceModelActionCreator(
  SELECTED_TARGETS_ACTION_SUBTYPE
);

export const toggleDeviceFamily = toggleDeviceFamilyActionCreator(
  SELECTED_TARGETS_ACTION_SUBTYPE
);

export const toggleDistributor = toggleDistributorActionCreator(
  SELECTED_TARGETS_ACTION_SUBTYPE
);

export const deselectAll = deselectAllActionCreator(
  SELECTED_TARGETS_ACTION_SUBTYPE
);
