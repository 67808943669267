// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Field } from "formik";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { ButtonsWrapper, Button } from "components/buttons";
import { ValidationBadge } from "components/form";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableNoResults,
  Subsection,
} from "components/layout";
import { trans } from "src/translations";
import { initialFile } from "utils/formik/utils";

import { ModeContext } from "../context";
import { AddContentItemModal } from "./AddContentItemModal";
import { CONTENT_TYPES } from "./constants";
import { ContentItemRow } from "./ContentItemRow";
import styles from "./ContentItems.scss";

const TABLE_HEADERS = [
  {
    id: "drag",
    label: "",
    className: styles.dragHeader,
  },
  {
    id: "title",
    label: trans.PROMOTION_DETAILS__CONTENT_ITEM_TITLE(),
    className: styles.titleHeader,
  },
  {
    id: "content",
    label: trans.PROMOTION_DETAILS__CONTENT_ITEM_CONTENT(),
    className: styles.contentHeader,
  },
  {
    id: "description",
    label: trans.PROMOTION_DETAILS__CONTENT_ITEM_DESCRIPTION(),
    className: styles.descriptionHeader,
  },
  {
    id: "menu",
    label: "",
    className: styles.menuHeader,
  },
];

const transformContentItem = (contentItem) => {
  const { contentType, fullPageLandscape, videoUrlThumbnail } = contentItem;

  return {
    id: uuid(),
    ...contentItem,
    fullPageLandscape:
      contentType === CONTENT_TYPES.FULL_PAGE_LANDSCAPE && fullPageLandscape,
    videoUrlThumbnail:
      contentType === CONTENT_TYPES.VIDEO_URL && videoUrlThumbnail,
  };
};

export class ContentItems extends Component {
  static contextType = ModeContext;

  static propTypes = {
    fields: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    contentItems: PropTypes.array.isRequired,
    touched: PropTypes.bool,
  };

  state = {
    isModalOpen: false,
    wasModalOpened: false,
    editItemIdx: null,
  };

  handleOpenModal = ({ editItemIdx }) =>
    this.setState({ isModalOpen: true, editItemIdx });

  handleCloseModal = () =>
    this.setState({
      isModalOpen: false,
      wasModalOpened: true,
      editItemIdx: null,
    });

  handleSubmit = (contentItem) => {
    const { fields } = this.props;
    const { editItemIdx } = this.state;

    if (editItemIdx !== null) {
      this.setState({ editItemIdx: null });
      fields.remove(editItemIdx);
      fields.insert(editItemIdx, transformContentItem(contentItem));
    } else {
      fields.push(transformContentItem(contentItem));
    }
    this.handleCloseModal();
  };

  handleMove = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    const { fields } = this.props;
    fields.move(source.index, destination.index);
  };

  handleRemove = (idx) => this.props.fields.remove(idx);

  prepareModalInitialValues = () => {
    const { editItemIdx } = this.state;
    const { name = "apps[0]", contentItems } = this.props;

    if (editItemIdx === null) {
      return {
        fullPageLandscape: initialFile("", "fullPageLandscape", name),
        videoUrlThumbnail: initialFile("", "videoUrlThumbnail", name),
        customDescription: "",
        customTitle: "",
        deepLinkUrl: "",
        videoUrl: "",
        contentType: CONTENT_TYPES.VIDEO_URL,
      };
    }

    const contentItemToEdit = { ...contentItems[editItemIdx] };

    /** remove fields set to 'false' to allow for reinitialization below */
    const emptyFields = Object.keys(contentItemToEdit).filter(
      (key) => !contentItemToEdit[key]
    );
    emptyFields.forEach((emptyFieldKey) => {
      delete contentItemToEdit[emptyFieldKey];
    });

    return {
      fullPageLandscape: initialFile("", "fullPageLandscape", name),
      videoUrlThumbnail: initialFile("", "videoUrlThumbnail", name),
      contentItemInEdit: `content-item-${editItemIdx}-app-${name}`,
      ...contentItemToEdit,
    };
  };

  renderField = ({ item, index, field: { name }, form: { getFieldMeta } }) => {
    /**
     * error propagation - needs CS-1961
     * as of now won't work */
    const { error } = getFieldMeta(name);

    return (
      <Draggable
        draggableId={item.id}
        index={index}
        isDragDisabled={this.context.isReadonly}
      >
        {(provided, snapshot) => (
          <ContentItemRow
            index={index}
            isDragging={snapshot.isDragging}
            key={item.id}
            onRemove={this.handleRemove}
            onEdit={this.handleOpenModal}
            contentItem={item}
            innerRef={provided.innerRef}
            error={error}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          />
        )}
      </Draggable>
    );
  };

  renderBody = (provided) => {
    const { name = "apps[0]", contentItems } = this.props;

    if (!contentItems?.length) {
      return (
        <TableNoResults
          message={trans.PROMOTION_DETAILS__CONTENT_NO_ITEMS()}
          isError={false}
          colspan={6}
        />
      );
    }

    return (
      <TableBody>
        {contentItems.map((item, index) => {
          return (
            <Field
              key={item.id}
              name={`content-item-${index}-app-${name}`}
              component={this.renderField}
              item={item}
              index={index}
            />
          );
        })}
        {provided.placeholder}
      </TableBody>
    );
  };

  renderTable = () => {
    return (
      <DragDropContext onDragEnd={this.handleMove}>
        <Droppable droppableId="package-items-droppable">
          {(provided) => (
            <Table
              innerRef={provided.innerRef}
              layout="fixed"
              className={styles.table}
            >
              <TableHeader position="sticky">
                {TABLE_HEADERS.map(({ id, label, className }) => (
                  <TableHeaderColumn key={id} className={className}>
                    {label}
                  </TableHeaderColumn>
                ))}
              </TableHeader>
              {this.renderBody(provided)}
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  render() {
    const { isModalOpen, wasModalOpened } = this.state;
    const { touched, error } = this.props;

    return (
      <>
        <Subsection header={trans.PROMOTION_DETAILS__CONTENT_TITLE()} />
        {this.renderTable()}
        <ValidationBadge
          className={styles.error}
          error={error}
          touched={touched || wasModalOpened}
        />
        {!this.context.isReadonly && (
          <ButtonsWrapper position={"center"}>
            <Button
              dataTestId={"add-content-button"}
              onClick={() => this.handleOpenModal({ editItemIdx: null })}
            >
              {trans.PROMOTION_DETAILS__CONTENT_ADD_ITEM()}
            </Button>
          </ButtonsWrapper>
        )}
        {isModalOpen && (
          <AddContentItemModal
            onClose={this.handleCloseModal}
            onSubmit={this.handleSubmit}
            initialValues={this.prepareModalInitialValues()}
          />
        )}
      </>
    );
  }
}
