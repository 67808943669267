// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { SearchBarWithFilters } from "components/elements";
import { Info } from "components/feedback";
import { Section, TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { withQueryParams } from "utils/decorators";

import styles from "../_shared/UsersTable.scss";
import { ActivationWarningModal } from "./ActivationWarningModal";
import { UserRow } from "./UserRow";

const USERS_FILTERS = {
  sections: [
    {
      title: trans.PROMOTION_LIST__FILTER_SECTION_NAME(),
      options: [
        {
          id: "status",
          displayName: "userstatus",
          param: "status",
          description: trans.USER_ADMIN__FILTERS_STATUS(),
          values: [
            { query: "active", display: "Active" },
            { query: "inactive", display: "Deactivated" },
          ],
        },
        {
          id: "organization",
          displayName: "organization",
          param: "ID",
          description: trans.USER_ADMIN__FILTERS_ORGANIZATION_ID(),
        },
        {
          id: "email",
          displayName: "email",
          param: "example@example.com",
          description: trans.USER_ADMIN__FILTERS_EMAIL(),
        },
      ],
    },
  ],
};

@withQueryParams
export class UsersTableRend extends Component {
  static propTypes = {
    // from parent data component
    users: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    setActive: PropTypes.func.isRequired,
    warningModalMode: PropTypes.string.isRequired,
    onCancelModal: PropTypes.func.isRequired,
    onConfirmModal: PropTypes.func.isRequired,
    processedUser: PropTypes.object,
    setStatusLoading: PropTypes.bool,
    onChangeSearch: PropTypes.func.isRequired,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,
  };

  static tabsConfig = [
    {
      dataTestId: "users-list",
      columns: [
        {
          label: trans.ICON(),
          dataTestId: "column-header-icon",
          className: styles.avatarColumn,
        },
        {
          label: trans.NAME(),
          dataTestId: "column-header-name",
          className: styles.nameColumn,
        },
        {
          label: trans.ORGANIZATIONS(),
          dataTestId: "column-header-organizations",
          className: styles.roleColumn,
        },
        {
          label: "",
          dataTestId: "column-header-action-menu",
          className: styles.optionsColumn,
        },
      ],
    },
  ];

  renderBody = (users) => {
    return (
      <TableBody>
        {users.map((user) => (
          <UserRow key={user.id} user={user} setActive={this.props.setActive} />
        ))}
      </TableBody>
    );
  };

  render() {
    const {
      users,
      count,
      error,
      loading,
      onChangeSearch,
      queryParams,
      warningModalMode,
      onCancelModal,
      onConfirmModal,
      processedUser,
      setStatusLoading,
    } = this.props;

    const locationMessage = get(this.props, "location.state.message");
    return (
      <div>
        <Section header={trans.USER_LIST__SECTION_NAME()}>
          <Section>
            <SearchBarWithFilters
              onSearch={onChangeSearch}
              look="for-white-bg"
              placeholder={trans.USER_LIST__SEARCH_PLACEHOLDER()}
              searchParams={queryParams}
              filtersDataChoices={USERS_FILTERS}
            />
          </Section>
          <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
          <Section>
            <TabbedTable
              tabsConfig={UsersTableRend.tabsConfig}
              data={{ results: users, count, loading, error }}
              renderTableBody={this.renderBody}
              layout="fixed"
            />
          </Section>
        </Section>
        <ActivationWarningModal
          userName={processedUser ? processedUser.name : null}
          mode={warningModalMode}
          onCancel={onCancelModal}
          onConfirm={onConfirmModal}
          loading={setStatusLoading}
        />
      </div>
    );
  }
}
