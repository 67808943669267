// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Section, TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";

import { PromotionTypeRow } from "./PromotionTypeRow";
import styles from "./PromotionTypesTable.scss";
import { RemovePromotionTypeModal } from "./RemovePromotionTypeModal";

@withRouter
export class PromotionTypesTable extends Component {
  static propTypes = {
    promotionTypes: PropTypes.array,
    count: PropTypes.number,
    loading: PropTypes.bool,
    error: PropTypes.object,
    refreshData: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object,
  };

  static tabsConfig = [
    {
      dataTestId: "promotion-types-list",
      columns: [
        { label: trans.NAME() },
        { label: trans.PROMOTION_TYPE_LIST__COLUMN_ASSETS() },
        {
          label: (
            <span>
              {trans.PROMOTION_TYPE_LIST__COLUMN_PROMOTION_COUNT_A()}
              <br />
              {trans.PROMOTION_TYPE_LIST__COLUMN_PROMOTION_COUNT_B()}
              <br />
              {trans.PROMOTION_TYPE_LIST__COLUMN_PROMOTION_COUNT_C()}
            </span>
          ),
        },
        { label: "" },
      ],
    },
  ];

  state = {
    isRemovePromotionTypeModalOpen: false,
    promotionTypeToRemove: {},
  };

  handleRemove = (promotionType) => {
    this.setState({
      isRemovePromotionTypeModalOpen: true,
      promotionTypeToRemove: promotionType,
    });
  };

  handleClose = () => {
    this.setState({
      isRemovePromotionTypeModalOpen: false,
      promotionTypeToRemove: {},
    });
  };

  renderBody = (promotionTypes) => {
    return (
      <TableBody>
        {promotionTypes.map((promotionType) => (
          <PromotionTypeRow
            key={promotionType.id}
            promotionType={promotionType}
            onRemove={this.handleRemove}
          />
        ))}
      </TableBody>
    );
  };

  renderAddButton = () => {
    return (
      <ButtonsWrapper>
        <Button
          type="green"
          className={styles.button}
          to="/promotions/admin/types/create"
        >
          {trans.PROMOTION_TYPE_LIST__CREATE_BUTTON()}
        </Button>
      </ButtonsWrapper>
    );
  };

  render() {
    const { promotionTypes, count, loading, error, refreshData, location } =
      this.props;
    const locationMessage = get(location, "state.message");

    return (
      <div>
        <Section header={trans.PROMOTION_TYPE_LIST__SECTION_NAME()}>
          <RemovePromotionTypeModal
            isOpen={this.state.isRemovePromotionTypeModalOpen}
            onClose={this.handleClose}
            promotionType={this.state.promotionTypeToRemove}
            refreshData={refreshData}
          />
          <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
          {this.renderAddButton()}
          <TabbedTable
            tabsConfig={PromotionTypesTable.tabsConfig}
            data={{ results: promotionTypes, count, loading, error }}
            renderTableBody={this.renderBody}
          />
          {this.renderAddButton()}
        </Section>
      </div>
    );
  }
}
