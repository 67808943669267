// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { isModelSelected } from "reduxReusable/selectedTargets/selectors";

import { Status } from "components/feedback";
import { Checkbox } from "components/form/elements";
import { TableRowColumn, TableSubRow } from "components/layout";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import { DEVICE_ACCEPTED, DEVICE_PENDING, DEVICE_REJECTED } from "../constants";
import styles from "./DeviceRow.scss";
import { SELECTED_TARGETS_STORE_PATH } from "./redux/constants";

const makeMapStateToProps = () => {
  return (state, props) => {
    const distributorId = props.distributorId;
    const familyId = props.familyId;
    const modelId = props.id;

    return {
      isModelSelected: isModelSelected(state, {
        storePath: SELECTED_TARGETS_STORE_PATH,
        distributorId,
        familyId,
        modelId,
      }),
    };
  };
};

@connect(makeMapStateToProps)
export class DeviceRow extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    moderationStatus: PropTypes.string.isRequired,
    isOnHold: PropTypes.bool.isRequired,
    isTesting: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,

    onChecked: PropTypes.func.isRequired,
    distributorId: PropTypes.string.isRequired,
    familyId: PropTypes.number.isRequired,
    isModelSelected: PropTypes.bool.isRequired,
    isReadonly: PropTypes.bool,
  };

  handleChange = () => {
    const { distributorId, familyId, id, onChecked } = this.props;
    onChecked(distributorId, familyId, id);
  };

  getStatusColumnText(status) {
    const { moderationStatus } = this.props;
    if (status !== moderationStatus) {
      return null;
    }
    switch (status) {
      case DEVICE_ACCEPTED:
        return trans.APPROVED();
      case DEVICE_REJECTED:
        return trans.REJECTED();
      default:
        return `${trans.PENDING()}...`;
    }
  }

  renderStatusColumn(status, className) {
    let text = this.getStatusColumnText(status);
    if (!text) {
      text = NO_VALUE_PLACEHOLDER;
      // eslint-disable-next-line no-param-reassign
      className = undefined;
    }

    return (
      <TableRowColumn className={styles.columnStatus}>
        <span className={classes(styles.status, className)}>{text}</span>
      </TableRowColumn>
    );
  }

  render() {
    const {
      name,
      isOnHold,
      isModelSelected,
      isTesting,
      isDisabled,
      isReadonly,
    } = this.props;

    return (
      <TableSubRow>
        <TableRowColumn>
          {!isReadonly && (
            <Checkbox
              value={name}
              onChange={this.handleChange}
              checked={isModelSelected}
            />
          )}
        </TableRowColumn>
        <TableRowColumn>
          <span className={styles.columnName}>
            {name}{" "}
            {isTesting && (
              <Status>
                {trans.APP_DETAILS_MODERATION_TARGETS__TEST_DEVICE()}
              </Status>
            )}{" "}
            {isDisabled && <Status>{trans.DISABLED()}</Status>}
          </span>
        </TableRowColumn>
        {this.renderStatusColumn(DEVICE_PENDING, styles.yellow)}
        {this.renderStatusColumn(DEVICE_ACCEPTED, styles.green)}
        {this.renderStatusColumn(DEVICE_REJECTED, styles.red)}
        <TableRowColumn className={styles.columnStatus}>
          <span className={styles.status}>
            {isOnHold
              ? trans.APP_DETAILS_MODERATION_TARGETS__ON_HOLD()
              : NO_VALUE_PLACEHOLDER}
          </span>
        </TableRowColumn>
      </TableSubRow>
    );
  }
}
