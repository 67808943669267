// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/**
 * Slugifies string
 */
export const slugify = (text: string): string => {
  return text
    .toString()
    .replace(/\s+/g, "_") // Replace spaces with _
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/-+/g, "-") // Replace multiple - with single -
    .replace(/_+/g, "_") // Replace multiple _ with single _
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

/**
 * Splits url string by ? and adds missing / if needed
 *
 * @param {string} url
 * @return {string}
 */
export const fixUrl = (url: string): string => {
  const parts = url.trim().split("?");
  if (!parts[0].endsWith("/")) {
    parts[0] = parts[0] + "/";
  }
  if (parts[0].startsWith("/")) {
    parts[0] = parts[0].slice(1);
  }
  return parts.join("?");
};

export const capitalize = (str: string): string =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const fileNameFromUrl = (url: string): string | undefined =>
  url.split("/").pop();

export const getUserFullName = (
  givenName: string,
  familyName: string
): string => `${givenName} ${familyName}`;
