// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const DEFAULT_PLAN_STATUSES = {
  pending: "PENDING",
  approved: "APPROVED",
  rejected: "REJECTED",
};

export const DEFAULT_PLAN_STATUSES_NAMES = {
  PENDING: trans.PENDING(),
  APPROVED: trans.APPROVED(),
  REJECTED: trans.REJECTED(),
};

export const BULK_ACTION = {
  MANAGE: "bulk_manage",
  CREATE_NEW: "bulk_create_new",
};

export const BULK_ACTION_SELECT_VALUES = [
  {
    value: BULK_ACTION.MANAGE,
    text: trans.APP_LIST__ACTION_MENU_MANAGE_APPS_IN_PLANS(),
  },
  {
    value: BULK_ACTION.CREATE_NEW,
    text: trans.APP_LIST__ACTION_MENU_CREATE_CUSTOM_PLAN(),
  },
];

export const BULK_ACTION_INIT_OPTION = {
  value: "",
  text: trans.APP_LIST_DISTRIBUTION__BULK_ACTIONS_PLACEHOLDER(),
  selected: true,
};
