// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";
import { v4 } from "uuid";

import { getAriaProps } from "components/form/fields";
import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";
import { classes } from "utils/classes";
import { withFieldGuard } from "utils/fieldGuard";

import styles from "./radio.scss";

export const RadioValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
]);

@withFieldGuard
class Radio extends Component {
  static propTypes = {
    /** Replacement for label */
    children: PropTypes.node,
    /** Label string displayed to the user */
    label: PropTypes.string,
    /** Unique checkbox name. Used as HTML `name` */
    name: PropTypes.string,
    /**
     * Current value of the field (field can be represented by many `<Radio>`).
     * It will equal 'defaultValue' prop if THIS `<Radio>` is checked.
     * Otherwise, some other `<Radio>` is checked.
     */
    value: RadioValuePropType,
    /**
     * Value for this radio button. If equals to current field value (the 'value' prop),
     * the `<Radio>` will be checked.
     */
    defaultValue: RadioValuePropType,
    /**
     * Change callback.
     *
     * Type: (e: Event) => void
     */
    tooltip: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    icon: PropTypes.string,
    /** Validation error. Shown when `touched` is true */
    error: PropTypes.string,
    touched: PropTypes.bool,
  };

  static defaultProps = {
    dataTestId: "radio",
  };

  state = {
    uid: v4(),
  };

  isChecked() {
    const { defaultValue, value } = this.props;
    return value === defaultValue;
  }

  generateId() {
    const { uid } = this.state;
    let baseKey = `form-id-${uid}`;
    // there is a bug that the <input> element does not change
    // value of 'checked' attribute. Assigning unique key
    // based on isChecked solves the problem
    if (this.isChecked()) {
      baseKey += "-checked";
    }
    return baseKey;
  }

  render() {
    const {
      children,
      label,
      defaultValue,
      name,
      className,
      disabled,
      tooltip,
      dataTestId,
      icon,
      error,
      touched,
      ...field
    } = this.props;
    const id = this.generateId();

    return (
      <div
        data-test-id={dataTestId}
        className={classes(styles.radio, className, {
          [styles.disabled]: disabled,
        })}
      >
        <input
          key={id}
          id={id}
          data-test-id={`${dataTestId}-input`}
          type="radio"
          value={defaultValue}
          onChange={field.onChange}
          onFocus={field.onFocus}
          disabled={disabled}
          checked={this.isChecked()}
          name={name}
          {...getAriaProps({ name, error, touched })}
        />
        <Tooltip content={tooltip}>
          <label htmlFor={id}>{children ? children : label}</label>
        </Tooltip>
        {icon && (
          <GrabsonIcon name={icon} size="normal" className={styles.icon} />
        )}
      </div>
    );
  }
}

// styleguidist does not like exporting classes that use decorators
export { Radio };
