// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.ga) {
      window.ga("send", "pageview", location.pathname);
    }
  }, [location]);

  return null;
};
