// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { FieldArray, useFormikContext } from "formik";
import PropTypes from "prop-types";

import { HtmlTranslation } from "src/translations";

import styles from "./ManifestEdit.scss";

const renderDescription = (label, descriptionFunc) => (
  <>
    {label && <div className={styles.label}>{label}</div>}
    {descriptionFunc && (
      <HtmlTranslation
        transFunc={descriptionFunc}
        component="p"
        className={styles.description}
      />
    )}
  </>
);

export const ManifestEditFieldArray = ({
  name,
  label,
  descriptionFunc,
  newItemValue,
  renderFieldRow,
}) => {
  const { values } = useFormikContext();
  const keyProps = values[name];

  return (
    <div className={styles.formArrayRow}>
      {renderDescription(label, descriptionFunc)}
      <FieldArray name={name}>
        {({ push, remove }) => (
          <>
            {keyProps &&
              keyProps.map((obj, index) =>
                renderFieldRow({
                  fieldName: `${name}[${index}]`,
                  action: () => remove(index),
                })
              )}
            {renderFieldRow({
              fieldName: `${name}-addButton`,
              action: () => push(newItemValue),
              isPlaceholder: true,
            })}
          </>
        )}
      </FieldArray>
    </div>
  );
};

ManifestEditFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  descriptionFunc: PropTypes.func,
  newItemValue: PropTypes.object.isRequired,
  renderFieldRow: PropTypes.func.isRequired,
};
