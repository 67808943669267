// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { STATES } from "components/form/Uploader/constants";

const FILE_PROGRESS_TOTAL = 100;

export const initialFile = (value = "", kind, id = 0) => ({
  id: id,
  loaded: value ? FILE_PROGRESS_TOTAL : 0,
  total: FILE_PROGRESS_TOTAL,
  status: value ? STATES.uploaded : STATES.idle,
  url: value,
  kind: kind,
  error: null,
  name: undefined,
  preview: undefined,
});

export const reorderFields = (values, fromIndex, direction, move) => {
  const toIndex = fromIndex + direction;

  if (toIndex >= values.length || toIndex < 0) {
    return;
  }

  move(fromIndex, toIndex);
};
