// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import some from "lodash-es/some";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { ValidationBadge } from "components/form";
import {
  Checkbox,
  AutocompleteWithInfiniteScroll,
} from "components/form/elements";
import {
  Modal,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableNoResults,
  TableRowColumn,
} from "components/layout";
import {
  organizationsPropTypes,
  profilePropTypes,
} from "containers/Auth/propTypes";
import {
  isAllowedToAddRole,
  isAllowedToRemoveRole,
} from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { RoleDescriptionTooltip } from "pages/_shared/RoleDescriptionTooltip";
import { trans } from "src/translations";

const ITEMS_PER_PAGE = 20;

const mapOrganizations = (organizations) =>
  organizations.map((org) => ({ id: org.id, label: org.name, value: org.id }));

const isRoleAllowedForOrganization = (roleId, orgTypes) => {
  return some(orgTypes, (orgType) =>
    some(orgType.roles, (typeRole) => typeRole.id === roleId)
  );
};

@connect(
  ({ auth }) => ({
    currentUser: auth.profile,
    organizations: auth.organizations,
  }),
  (dispatch) => ({
    getOrganizationTypes: async (id) => {
      const { results } = await dispatch(
        apiThatThrows.getTypesForOrganizationPaginated.action({
          params: { organizationId: id },
          queryParams: { limit: "nolimit" },
        })
      );
      return results;
    },
    getOrganizations: ({ page, search }) => {
      return dispatch(
        apiThatThrows.getOrganizationsPaginated.action({
          queryParams: { page, name_or_id: search, limit: ITEMS_PER_PAGE },
        })
      );
    },
  })
)
export class ChangeGrantsModal extends Component {
  static propTypes = {
    assignedOrganizations: PropTypes.arrayOf(PropTypes.string),
    editedOrganization: PropTypes.string,
    allRoles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        role: PropTypes.string,
      })
    ),
    selectedRoles: PropTypes.arrayOf(PropTypes.string),

    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,

    // from @connect
    currentUser: profilePropTypes,
    organizations: organizationsPropTypes,
    getOrganizations: PropTypes.func.isRequired,
    getOrganizationTypes: PropTypes.func,
  };

  state = {
    organization: undefined,
    roles: this.props.selectedRoles || [],
    types: [],
    loading: false,
    error: undefined,
    formError: undefined,
  };

  async componentDidMount() {
    const { editedOrganization } = this.props;
    if (editedOrganization) {
      this.fetchOrganizationTypes(editedOrganization);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { organization } = this.state;
    if (prevState.organization !== organization) {
      this.fetchOrganizationTypes(organization);
    }
  }

  fetchOrganizationTypes = async (organizationId) => {
    const { getOrganizationTypes } = this.props;

    if (!organizationId) {
      this.setState({ types: [] });
      return;
    }

    this.setState({ loading: true });
    try {
      const types = await getOrganizationTypes(organizationId);
      this.setState({ types, loading: false, error: undefined });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  };

  toggle = (id) => {
    const { roles } = this.state;

    const index = roles.indexOf(id);

    if (index === -1) {
      roles.push(id);
    } else {
      roles.splice(index, 1);
    }

    this.setState({
      roles: roles,
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({ organization: undefined, roles: [] });
    onClose();
  };

  onSave = () => {
    const { onSave, onClose, editedOrganization } = this.props;
    const { organization, roles } = this.state;
    if (!roles.length) {
      this.setState({ formError: trans.USER_ADMIN__NO_ROLES_ERROR() });
      return;
    }
    onSave({ organization, roles, editedOrganization });
    onClose();
  };

  isChecked(roleId) {
    return this.state.roles.indexOf(roleId) !== -1;
  }

  fetchOrganizations = async (queryParams) => {
    const { assignedOrganizations, getOrganizations } = this.props;
    const response = await getOrganizations({
      ...queryParams,
    });
    if (
      !assignedOrganizations ||
      typeof response !== "object" ||
      response === null
    ) {
      return response;
    }
    return {
      ...response,
      results: response.results?.filter?.(
        (organization) => !assignedOrganizations.includes(organization.id)
      ),
    };
  };

  onChangeOrganization = (id) => {
    this.setState({ organization: id });
  };

  getRoles = () => {
    const { allRoles = [] } = this.props;
    const { types = [] } = this.state;

    return allRoles.filter((role) =>
      isRoleAllowedForOrganization(role.id, types)
    );
  };

  isAllowedToChange(currentUserRoles, editUserRoles, role, removeAction) {
    return removeAction
      ? isAllowedToRemoveRole(currentUserRoles, editUserRoles, role)
      : isAllowedToAddRole(currentUserRoles, editUserRoles, role);
  }

  renderTableBody = () => {
    const { organizations, currentUser } = this.props;
    const { error, loading } = this.state;

    const currentUserRoles =
      organizations[currentUser.activeOrganizationId].roles;

    if (error) {
      return (
        <TableNoResults
          message={error && error.message}
          isError={Boolean(error)}
          colspan={4}
          loading={loading}
        />
      );
    }

    if (loading) {
      return <TableNoResults colspan={4} loading={loading} />;
    }

    return (
      <TableBody>
        {this.getRoles().map(({ name, id }) => (
          <TableRow key={id} dataTestId="roles-modal-row">
            <TableRowColumn>
              <Checkbox
                checked={this.isChecked(id)}
                onChange={() => this.toggle(id)}
                value={id}
                dataTestId="roles-modal-checkbox"
                disabled={
                  !this.isAllowedToChange(
                    currentUserRoles,
                    this.state.roles,
                    id,
                    this.isChecked(id)
                  )
                }
              />
            </TableRowColumn>
            <TableRowColumn>
              <RoleDescriptionTooltip id={id} name={name} />
            </TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  render() {
    const { isOpen, editedOrganization } = this.props;
    const { formError } = this.state;

    return (
      <Modal
        title={
          editedOrganization
            ? trans.USER_ADMIN__CHANGE_ROLE()
            : trans.USER_ADMIN__ADD_USER_TO_ORGANIZATION()
        }
        isOpen={isOpen}
        onClose={this.handleClose}
        size="medium"
        actions={
          <ButtonsWrapper>
            <Button
              type="normal"
              onClick={this.handleClose}
              dataTestId="roles-modal-close-button"
            >
              {trans.CLOSE()}
            </Button>
            <Button
              type="normal"
              onClick={this.onSave}
              dataTestId="roles-modal-save-button"
            >
              {trans.SAVE()}
            </Button>
          </ButtonsWrapper>
        }
      >
        {!editedOrganization && (
          <AutocompleteWithInfiniteScroll
            value={this.state.organization}
            label={trans.ORGANIZATION()}
            inputPlaceholder={trans.SEARCH_BY_ID_OR_NAME()}
            itemsPerPage={ITEMS_PER_PAGE}
            fetchData={this.fetchOrganizations}
            mapData={mapOrganizations}
            onChange={this.onChangeOrganization}
          />
        )}
        <Table>
          <TableHeader>
            <TableHeaderColumn />
            <TableHeaderColumn>{trans.ROLE()}</TableHeaderColumn>
          </TableHeader>
          {this.renderTableBody()}
        </Table>
        <ValidationBadge error={formError} touched={Boolean(formError)} />
      </Modal>
    );
  }
}
