// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField } from "formik";
import PropTypes from "prop-types";

import { Uploader } from "components/form/";
import { classes } from "utils/classes";

import styles from "./snapGraphics.scss";

export const LogoUploader = ({
  validation,
  preprocess,
  info,
  label,
  formats,
  mode,
  required,
  uploaderDataTestId,
  name,
  kind,
}) => {
  const [field] = useField(`${name}.url`);

  return (
    <div className={styles.uploaderWrapper}>
      <Uploader
        className={styles.uploader}
        name={name}
        label={label}
        formats={formats}
        preprocess={preprocess}
        validate={validation}
        info={info}
        required={required}
        disablePreview={true}
        dataTestId={uploaderDataTestId}
        kind={kind}
      />

      <div
        className={classes(styles.imageWrapper, mode ? styles[mode] : {})}
        data-test-id={`${uploaderDataTestId}-preview`}
      >
        {field?.value && (
          <img
            src={field.value}
            data-test-id={`${uploaderDataTestId}-preview-img`}
          />
        )}
      </div>
    </div>
  );
};

LogoUploader.propTypes = {
  name: PropTypes.string.isRequired,
  formats: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validation: PropTypes.func.isRequired,
  info: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  preprocess: PropTypes.func.isRequired,
  uploaderDataTestId: PropTypes.string.isRequired,
  kind: PropTypes.string,
};
