// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import { feature } from "topojson";

import { getGeoMapWorldShapes } from "./continents";

const getContinentObject = (allContinents, continentName) => {
  let continentObj = allContinents.find((c) => c.name === continentName);

  if (!continentObj) {
    continentObj = {
      type: "FeatureCollection",
      name: continentName,
      id: allContinents.length + 1,
      features: [],
    };
    allContinents.push(continentObj);
  }

  return continentObj;
};

const getContinent = (allCountries, geomapCountry) => {
  const { code, continent } = geomapCountry;

  const countryData = allCountries.find((c) => c.code === code);
  const continents = get(countryData, "continents", []);
  if (continents.length === 1) {
    return continents[0];
  }

  // If backend does not have entry for a country or backend says that
  // country belongs to multiple continents, we have to override with own
  // data. Currently this case happens for 21 countries.
  return continent.name;
};

const prepareCountryShapesList = (allCountries) => {
  const geomapData = getGeoMapWorldShapes();
  const geomapCountries = feature(
    geomapData,
    geomapData.objects.countries
  ).features;

  return geomapCountries.map((geomapCountry) => ({
    ...geomapCountry,
    properties: {
      code: geomapCountry.properties.code,
      continent: getContinent(allCountries, geomapCountry.properties),
    },
  }));
};

// builds continent->country features list
export const prepareContinentsForD3 = (allCountries) => {
  const countries = prepareCountryShapesList(allCountries);
  const continents = [];

  countries.forEach((country) => {
    const { continent } = country.properties;
    const continentObj = getContinentObject(continents, continent);
    continentObj.features.push(country);
  });

  return continents;
};
