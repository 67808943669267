// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { trans, HtmlTranslation } from "src/translations";

import { UpdateJobPropType } from "./propTypes";
import { UpdateJobDetailsEligibleDevicesTab } from "./UpdateJobDetailsEligibleDevicesTab";
import { UpdateJobDetailsMainTab } from "./UpdateJobDetailsMainTab";
import styles from "./UpdateJobDetailsRend.scss";
import { UpdateJobDetailsTabs } from "./UpdateJobDetailsTabs";

export const TAB_ID_MAIN = "main";
export const TAB_ID_ELIGIBLE_DEVICE = "eligibleDevices";

// eslint-disable-next-line consistent-return
const getTabContent = (tabId, props) => {
  switch (tabId) {
    case TAB_ID_ELIGIBLE_DEVICE:
      return <UpdateJobDetailsEligibleDevicesTab {...props} />;
    case TAB_ID_MAIN:
      return <UpdateJobDetailsMainTab {...props} />;
  }
};

export const UpdateJobDetailsRend = ({ tabId, jobId, ...props }) => {
  const updateJobName = props.data?.name ?? "";
  const isRepeatable = props.data?.isRepeatable ?? false;

  return (
    <>
      <PageHeader>{updateJobName}</PageHeader>
      <PageContent
        bannerClassName={styles.banner}
        bannerText={
          isRepeatable
            ? trans.UPDATES__UPDATE_DETAILS__PAGE_BANNER()
            : undefined
        }
        bannerTooltipContent={
          <HtmlTranslation
            transFunc={trans.UPDATES__UPDATE_DETAILS__PAGE_BANNER_TOOLTIP}
          />
        }
      >
        <UpdateJobDetailsTabs tabId={tabId} jobId={jobId} />
        {getTabContent(tabId, props)}
      </PageContent>
    </>
  );
};

UpdateJobDetailsRend.propTypes = {
  jobId: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  data: UpdateJobPropType,
  devicesRefreshDate: PropTypes.instanceOf(Date),
  fetchDevices: PropTypes.func.isRequired,
  fetchDevicesStatus: PropTypes.shape({
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  modalAction: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onModalSuccess: PropTypes.func.isRequired,
};
