// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { createExportQueryParams } from "components/layout";
import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withQueryParams, withRouter } from "utils/decorators";

import { DeviceModelApplicationsRend } from "./DeviceModelApplicationsRend";

@withRouter
@restrictedArea((props) => {
  const allowed = [ROLES.moderator.moderator];

  if (props.forAdmin) {
    allowed.push(ROLES.administrator.deviceAdmin);
  } else {
    allowed.push(ROLES.distributor.distributionPlanner);
  }

  return {
    allowed,
  };
})
@withQueryParams
@connect(null, (dispatch, ownProps) => ({
  getPublishedApplicationsPaginated: async (queryParams) => {
    let response;
    if (ownProps.forAdmin) {
      response = await dispatch(
        apiThatThrows.getDistributionApplicationsPaginated.action({
          queryParams,
        })
      );
    } else {
      response = await dispatch(
        apiThatThrows.getMyDistributionApplicationsPaginated.action({
          queryParams,
        })
      );
    }

    return response;
  },
  exportDeviceModelApplicationsPaginated: async (queryParams) => {
    if (ownProps.forAdmin) {
      await dispatch(
        apiThatThrows.exportDeviceModelApplicationsPaginated.action({
          queryParams,
        })
      );
    } else {
      await dispatch(
        apiThatThrows.exportMyDeviceModelApplicationsPaginated.action({
          queryParams,
        })
      );
    }
  },
  getDeviceFamily: async (id) => {
    let response;

    if (ownProps.forAdmin) {
      response = await dispatch(
        apiThatThrows.getDeviceFamily.action({
          params: { id },
        })
      );
    } else {
      response = await dispatch(
        apiThatThrows.getMyDeviceFamily.action({
          params: { id },
        })
      );
    }

    const { results } = response;
    return results;
  },
  getDeviceModel: async (id) => {
    let response;
    if (ownProps.forAdmin) {
      response = await dispatch(
        apiThatThrows.getDeviceModel.action({
          params: { id },
        })
      );
    } else {
      response = await dispatch(
        apiThatThrows.getMyDeviceModel.action({
          params: { id },
        })
      );
    }

    return response.results;
  },
  getOrganizationPublicInfo: async (id) => {
    const { results } = await dispatch(
      apiThatThrows.getOrganizationPublicInfo.action({
        params: { organizationId: id },
      })
    );

    return results;
  },
}))
export class DeviceModelApplicationsData extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,

    // from @withRouter
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @connect
    getPublishedApplicationsPaginated: PropTypes.func.isRequired,
    exportDeviceModelApplicationsPaginated: PropTypes.func.isRequired,
    getDeviceFamily: PropTypes.func.isRequired,
    getDeviceModel: PropTypes.func.isRequired,
    getOrganizationPublicInfo: PropTypes.func.isRequired,
  };

  state = {
    applicationsData: [],
    count: 0,
    error: undefined,
    loading: 0,
    deviceModelName: undefined,
    distributorName: undefined,
    familyName: undefined,
    loadingDeviceModelName: 0,
    errorDeviceModelName: undefined,
  };

  async componentDidMount() {
    await this.updateDeviceModelName();
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateData();
    }
  }

  prepareApplicationData = (app) => {
    return {
      id: app.id,
      icon: app.icon,
      name: app.name,
    };
  };

  updateDeviceModelName = async () => {
    const {
      getDeviceFamily,
      getDeviceModel,
      getOrganizationPublicInfo,
      params,
    } = this.props;

    try {
      this.setState((prevState) => ({
        loadingDeviceModelName: prevState.loadingDeviceModelName + 1,
        errorDeviceModelName: undefined,
      }));
      const deviceModel = await getDeviceModel(params.id);
      const deviceFamily = await getDeviceFamily(deviceModel.device_family.id);
      const organization = await getOrganizationPublicInfo(
        deviceFamily.owner.id
      );
      this.setState({
        deviceModelName: deviceModel.name,
        distributorName: organization.name,
        familyName: deviceFamily.name,
      });
    } catch (err) {
      this.setState({ errorDeviceModelName: err });
    } finally {
      this.setState((prevState) => ({
        loadingDeviceModelName: prevState.loadingDeviceModelName - 1,
      }));
    }
  };

  updateData = async () => {
    const { getPublishedApplicationsPaginated, queryParams } = this.props;

    this.setState((prevState) => ({ loading: prevState.loading + 1 }));

    try {
      const publishedApplicationsQueryParams =
        await this.createPublishedApplicationsQueryParams(queryParams);
      const { results: appsResults, meta: appsMeta } =
        await getPublishedApplicationsPaginated(
          publishedApplicationsQueryParams
        );

      this.setState((prevState) => ({
        applicationsData: appsResults.map(this.prepareApplicationData),
        count: appsMeta.count,
        loading: prevState.loading - 1,
        error: undefined,
      }));
    } catch (err) {
      this.setState((prevState) => ({
        error: err,
        loading: prevState.loading - 1,
      }));
    }
  };

  createPublishedApplicationsQueryParams = async (queryParams) => {
    const { deviceModelName, distributorName, familyName } = this.state;

    return {
      ...queryParams,
      include_test_apps: true,
      distributed_by: distributorName,
      distributed_on_models: deviceModelName,
      distributed_on_families: familyName,
    };
  };

  onExport = async ({ fileFormat }) => {
    const { exportDeviceModelApplicationsPaginated, queryParams } = this.props;

    const publishedApplicationsQueryParams =
      await this.createPublishedApplicationsQueryParams(queryParams);
    const exportQueryParams = createExportQueryParams({
      fileFormat,
      queryParams: publishedApplicationsQueryParams,
    });

    await exportDeviceModelApplicationsPaginated(exportQueryParams);
  };

  getError = () => {
    const { error, errorDeviceModelName } = this.state;

    if (error) {
      return error;
    }

    if (errorDeviceModelName) {
      return errorDeviceModelName;
    }

    return undefined;
  };

  isLoading = () => {
    const { loading, loadingDeviceModelName } = this.state;
    return Boolean(loading || loadingDeviceModelName);
  };

  render() {
    const { applicationsData, count } = this.state;

    return (
      <DeviceModelApplicationsRend
        applicationsData={applicationsData}
        count={count}
        error={this.getError()}
        loading={this.isLoading()}
        onExport={this.onExport}
      />
    );
  }
}
