// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createContext } from "react";

export const ModeContext = createContext({
  forAdmin: undefined,
  isEdit: undefined,
  isReadonly: undefined,
});
