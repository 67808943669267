// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";

import PropTypes from "prop-types";

import { SearchableListPanel } from "components/elements";
import { GrabsonIcon } from "components/icons";

import { packagePropTypes, currentVersionPropTypes } from "./propTypes";
import styles from "./SelectSoftwarePackageModal.scss";
import { SoftwarePackageVersionRow } from "./SoftwarePackageVersionRow";

export class SoftwarePackageRow extends Component {
  static propTypes = {
    idx: PropTypes.number.isRequired,
    softwarePackage: packagePropTypes,
    setCurrentVersion: PropTypes.func.isRequired,
    currentVersion: currentVersionPropTypes,
  };

  state = {
    isExpanded: false,
  };

  onToggle = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  renderVersionRow = (version, idx) => {
    const { softwarePackage, currentVersion, setCurrentVersion } = this.props;
    return (
      <SoftwarePackageVersionRow
        key={version.id}
        idx={idx}
        version={version}
        softwarePackage={softwarePackage}
        currentVersion={currentVersion}
        setCurrentVersion={setCurrentVersion}
      />
    );
  };

  render() {
    const { isExpanded } = this.state;
    const { softwarePackage, idx } = this.props;

    const versions = isExpanded ? softwarePackage.versions : [];

    return (
      <Fragment>
        <SearchableListPanel className={styles.packageRow}>
          <GrabsonIcon
            name={isExpanded ? "arrow-up" : "arrow-down"}
            size="sm"
            font="micro"
            className={styles.packageToggleIcon}
            dataTestId={`expand-software-package-${idx}`}
            onClick={this.onToggle}
          />
          <span className={styles.packageName}>{softwarePackage.name}</span>
          <span className={styles.packageVersionQuantity}>
            {softwarePackage.versions.length}
          </span>
        </SearchableListPanel>
        {versions.map(this.renderVersionRow)}
      </Fragment>
    );
  }
}
