// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import { MAX_VISIBLE_DEVICES_WITH_ALERT_COUNT } from "../DeviceInventory/constants";
import styles from "./DeviceDetails.scss";

export const TAB_ID_DEFAULT = "general_information";
export const TAB_ID_MANAGER_ATTRIBUTES = "manager_attributes";
export const TAB_ID_SOFTWARE = "software";
export const TAB_ID_SIGNATURES = "signatures";
export const TAB_ID_PDID_HISTORY = "pdid_history";
export const TAB_ID_GENERIC_ATTRIBUTES = "generic_attributes";

export const TABS_IDS = [
  TAB_ID_DEFAULT,
  TAB_ID_MANAGER_ATTRIBUTES,
  TAB_ID_SOFTWARE,
  TAB_ID_SIGNATURES,
  TAB_ID_PDID_HISTORY,
  TAB_ID_GENERIC_ATTRIBUTES,
];

export const getTab = (tabId, device = {}) => {
  switch (tabId) {
    case TAB_ID_MANAGER_ATTRIBUTES: {
      return {
        id: TAB_ID_MANAGER_ATTRIBUTES,
        text: trans.DEVICES__DEVICE_DETAILS_TAB_MANAGER_ATTRIBUTES(),
        dataTestId: "tab-manager-attributes",
      };
    }
    case TAB_ID_SOFTWARE: {
      return {
        id: TAB_ID_SOFTWARE,
        text: trans.DEVICES__DEVICE_DETAILS_TAB_SOFTWARE(),
        dataTestId: "tab-installed-packages",
      };
    }
    case TAB_ID_SIGNATURES: {
      return {
        id: TAB_ID_SIGNATURES,
        text: trans.DEVICES__DEVICE_DETAILS_TAB_SIGNATURES(),
        dataTestId: "tab-signatures",
      };
    }
    case TAB_ID_PDID_HISTORY: {
      return {
        id: TAB_ID_PDID_HISTORY,
        text: trans.DEVICES__DEVICE_DETAILS_TAB_PDID_HISTORY(),
        dataTestId: "tab-pdid-history",
        icon: device.activeAlertsNumber ? "alert-circle" : undefined,
        iconColor: "error",
        customIcon: device.activeAlertsNumber ? (
          <div className={styles.alertsCounter}>
            <div
              className={styles.alertsCounterValue}
              data-test-id="device-details-alerts-counter"
            >
              {device.activeAlertsNumber < MAX_VISIBLE_DEVICES_WITH_ALERT_COUNT
                ? device.activeAlertsNumber
                : trans.DEVICES__DEVICE_INVENTORY_OVER_THOUSAND_DEVICES_WITH_ALERT_COUNTER()}
            </div>
          </div>
        ) : null,
      };
    }
    case TAB_ID_GENERIC_ATTRIBUTES: {
      const isEmpty = Object.keys(device).filter(
        (key) => key.startsWith("generic") && device[key] != null
      );

      return {
        id: TAB_ID_GENERIC_ATTRIBUTES,
        text: trans.DEVICES__DEVICE_DETAILS_TAB_GENERIC_ATTRIBUTES(),
        dataTestId: "tab-generic-attributes",
        isEmpty: isEmpty.length === 0,
      };
    }
    default:
      return {
        id: TAB_ID_DEFAULT,
        text: trans.DEVICES__DEVICE_DETAILS_TAB_GENERAL_INFORMATION(),
        dataTestId: "tab-general-information",
      };
  }
};

export const DEVICE_ATTRIBUTE_DEVICE_MODEL = "deviceModel";

export const ALERT_STATES = {
  NONE: "NONE",
  ACTIVE: "ACTIVE",
  DISMISSED: "DISMISSED",
};

// special manager attribute that makes a device unit test one, only works when it's value is set to Yes.
// in any other case it is deleted
export const TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_KEY = "test_device_unit";
export const TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_VALUE = "yes";
