// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";
import { createValidator, VALIDATION_PASSED } from "utils/validation";

import { THRESHOLD_MIN, THRESHOLD_MAX } from "../constants";

export const isValidThreshold = (path) =>
  createValidator(
    (valueStr) => {
      const value = Number(valueStr);

      const isInteger = Number.isInteger(parseFloat(valueStr));
      const isInRange = value >= THRESHOLD_MIN && value <= THRESHOLD_MAX;
      const isValid = !isNaN(value) && isInteger && isInRange;

      return isValid ? VALIDATION_PASSED : !VALIDATION_PASSED;
    },
    trans.UPDATES__FORM_FIELD_THRESHOLD_ERROR(),
    path
  );

export const validateDeviceQuantity = (values = []) => {
  const errors = [];

  values.forEach((valueStr, idx) => {
    const value = Number(valueStr);
    const previousStepValue = Number(values[idx - 1]);

    const isInteger = Number.isInteger(parseFloat(valueStr));
    const isInRange = value > 0;
    const isValid = !isNaN(value) && isInteger && isInRange;

    let isGreaterThanPreviousStep = true;
    if (idx > 0 && !isNaN(previousStepValue)) {
      isGreaterThanPreviousStep = value >= previousStepValue;
    }

    if (!isValid) {
      errors[idx] = trans.UPDATES__FORM_FIELD_STEP_QUANTITY_ERROR();
    } else if (!isGreaterThanPreviousStep) {
      errors[idx] = trans.ERROR__FIELD_LARGER_THAN({
        value: previousStepValue,
      });
    }
  });

  return errors;
};
