// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import Portal from "react-minimalist-portal";

import PropTypes from "prop-types";

import { Header } from "components/typography";
import { BreadcrumbsList } from "containers/Breadcrumbs";
import { classes } from "utils/classes";

import styles from "./Modal.scss";

export class Modal extends Component {
  static propTypes = {
    /** Called when close button clicked */
    onClose: PropTypes.func,
    /** If true modal opens up */
    isOpen: PropTypes.bool.isRequired,
    /** Modal content */
    children: PropTypes.node,
    /** Modal title */
    title: PropTypes.node,
    /** Modal action buttons */
    actions: PropTypes.node,
    /** Breadcrumbs list */
    breadcrumbs: PropTypes.array,
    /** User class name */
    className: PropTypes.string,
    /** Id for automated tests */
    dataTestId: PropTypes.string,
    /** Class name for 'x' that closes the button */
    buttonCloseClassName: PropTypes.string,
    /** Width of the modal */
    size: PropTypes.oneOf(["small", "medium", "big", "huge"]),
  };

  componentDidMount() {
    this.updateBodyClass(this.props.isOpen);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.updateBodyClass(this.props.isOpen);
    }
  }

  componentWillUnmount() {
    this.updateBodyClass(false);
  }

  updateBodyClass(isOpen) {
    if (isOpen) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }
  }

  static defaultProps = {
    size: "small",
    dataTestId: "modal",
  };

  render() {
    const {
      isOpen,
      onClose,
      children,
      title,
      actions,
      breadcrumbs,
      className,
      dataTestId,
      buttonCloseClassName,
      size,
    } = this.props;

    if (!isOpen) {
      return null;
    }

    const labelId = `${dataTestId}-label`;

    return (
      <Portal>
        <div className={styles.container}>
          <div
            role="dialog"
            aria-labelledby={labelId}
            aria-modal="true"
            data-test-id={dataTestId}
            className={classes(styles[size], className)}
          >
            <button
              type="button"
              data-test-id={`${dataTestId}-close-button`}
              className={classes(styles.buttonClose, buttonCloseClassName)}
              onClick={onClose}
            >
              x
            </button>
            {breadcrumbs && (
              <div className={styles.breadcrumbs}>
                <BreadcrumbsList breadcrumbs={breadcrumbs} />
              </div>
            )}
            {title && (
              <Header
                className={styles.header}
                uppercase={true}
                color="secondary"
                bold={true}
                dataTestId={`${dataTestId}-header`}
                id={labelId}
              >
                {title}
              </Header>
            )}
            <div
              className={styles.content}
              data-test-id={`${dataTestId}-content`}
            >
              {children}
            </div>
            {actions && <div>{actions}</div>}
          </div>
        </div>
      </Portal>
    );
  }
}

export default Modal;
