// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { GrabsonIcon } from "components/icons";
import { classes } from "utils/classes";

import styles from "./menuButton.scss";
import { buttonSizeType } from "./types";

export class MenuButton extends Component {
  static propTypes = {
    size: buttonSizeType,
    isPopupOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    size: "normal",
  };

  sizes = {
    normal: {
      iconSize: "sm",
      buttonType: "menuButton",
    },
    small: {
      iconSize: "xs",
      buttonType: "menuButtonSmall",
    },
  };

  render() {
    const { size, isPopupOpen } = this.props;
    const sizeProps = get(this.sizes, size, {});

    return (
      <Button
        size="small"
        type={sizeProps.buttonType}
        className={classes(styles.button, {
          [styles.buttonActive]: isPopupOpen,
        })}
      >
        <GrabsonIcon name="menu" size={sizeProps.iconSize} />
      </Button>
    );
  }
}
