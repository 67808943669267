// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { RestrictedArea, ROLES } from "containers/Permissions";
import { allowInOrganization } from "containers/Permissions/groups";
import { trans } from "src/translations";

export class DeviceRowActionMenu extends Component {
  static propTypes = {
    device: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool.isRequired,
    }).isRequired,
    toggleDeviceModelInProgress: PropTypes.bool.isRequired,
    ownerId: PropTypes.string.isRequired,
    onToggleDeviceModel: PropTypes.func.isRequired,
    forAdmin: PropTypes.bool.isRequired,
  };

  renderMenuItems = ({ closePopup }) => {
    const {
      device,
      toggleDeviceModelInProgress,
      ownerId,
      onToggleDeviceModel,
      forAdmin,
    } = this.props;
    const linkToDetails = `/devices/${forAdmin ? "models" : "my"}/${device.id}`;
    const linkToEdit = `/devices/${forAdmin ? "models" : "my"}/${
      device.id
    }/edit`;

    const handleToggleDeviceModel = async () => {
      await onToggleDeviceModel(device.id, device.isDisabled);
      closePopup();
    };

    return (
      <Fragment>
        <RestrictedArea
          allowed={[ROLES.administrator.deviceAdmin]}
          showFallback={true}
          fallbackChildren={
            <Fragment>
              <ActionMenu disabled={true}>{trans.EDIT_DETAILS()}</ActionMenu>
              <MenuItem disabled={true}>
                {device.isDisabled ? trans.ENABLE() : trans.DISABLE()}
              </MenuItem>
            </Fragment>
          }
        >
          <MenuItem to={linkToEdit}>{trans.EDIT_DETAILS()}</MenuItem>
          <MenuItem
            onClick={handleToggleDeviceModel}
            isLoading={toggleDeviceModelInProgress}
          >
            <span>{device.isDisabled ? trans.ENABLE() : trans.DISABLE()}</span>
          </MenuItem>
        </RestrictedArea>
        <RestrictedArea
          allowed={[
            ROLES.moderator.moderator,
            ROLES.administrator.deviceAdmin,
            allowInOrganization(ownerId, [
              ROLES.distributor.distributionPlanner,
            ]),
          ]}
          showFallback={true}
          fallbackChildren={
            <MenuItem disabled={true}>{trans.VIEW_DETAILS()}</MenuItem>
          }
        >
          <MenuItem to={linkToDetails}>{trans.VIEW_DETAILS()}</MenuItem>
        </RestrictedArea>
      </Fragment>
    );
  };

  render() {
    return <ActionMenu buttonSize="small">{this.renderMenuItems}</ActionMenu>;
  }
}
