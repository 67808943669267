// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

import { DEFAULT_PLAN_STATUSES } from "./constants";

const ALLOW_APPROVE_STATUSES = [
  DEFAULT_PLAN_STATUSES.rejected,
  DEFAULT_PLAN_STATUSES.pending,
];

const ALLOW_REJECT_STATUSES = [
  DEFAULT_PLAN_STATUSES.approved,
  DEFAULT_PLAN_STATUSES.pending,
];

export class DistributionAppPopupMenu extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    onApproveForDefPlan: PropTypes.func.isRequired,
    onRejectForDefPlan: PropTypes.func.isRequired,
    approveForDefPlanProgress: PropTypes.object.isRequired,
    rejectForDefPlanProgress: PropTypes.object.isRequired,
    onCreateNewCustomDistributionPlan: PropTypes.func.isRequired,
    openManageAppInCustomPlanModal: PropTypes.func.isRequired,
  };

  onManageInCustomPlan = (closePopup) => {
    const { openManageAppInCustomPlanModal, app } = this.props;
    openManageAppInCustomPlanModal(app.id);
    closePopup();
  };

  onCreateNewCustomDistributionPlan = () => {
    const { onCreateNewCustomDistributionPlan, app } = this.props;
    onCreateNewCustomDistributionPlan([app.id]);
  };

  renderMenuItems = ({ closePopup }) => {
    const {
      app,
      onApproveForDefPlan,
      onRejectForDefPlan,
      approveForDefPlanProgress,
      rejectForDefPlanProgress,
    } = this.props;

    const handleApproveForDefPlan = async () => {
      await onApproveForDefPlan(app);
      closePopup();
    };

    const handleRejectForDefPlan = async () => {
      await onRejectForDefPlan(app);
      closePopup();
    };

    return (
      <Fragment>
        <MenuItem
          dataTestId="approve-for-default-item"
          onClick={handleApproveForDefPlan}
          disabled={!ALLOW_APPROVE_STATUSES.includes(app.defaultPlanStatus)}
          isLoading={approveForDefPlanProgress[app.id]}
        >
          {trans.APP_LIST__ACTION_MENU_APPROVE_FOR_DEFAULT_PLAN()}
        </MenuItem>
        <MenuItem
          dataTestId="reject-for-default-item"
          onClick={handleRejectForDefPlan}
          disabled={!ALLOW_REJECT_STATUSES.includes(app.defaultPlanStatus)}
          isLoading={rejectForDefPlanProgress[app.id]}
        >
          {trans.APP_LIST__ACTION_MENU_REJECT_FOR_DEFAULT_PLAN()}
        </MenuItem>
        <MenuItem
          dataTestId="manage-in-custom-plan-item"
          onClick={() => this.onManageInCustomPlan(closePopup)}
        >
          {trans.APP_LIST__ACTION_MENU_MANAGE_APP_IN_PLANS()}
        </MenuItem>
        <MenuItem
          dataTestId="create-custom-plan-item"
          onClick={this.onCreateNewCustomDistributionPlan}
        >
          {trans.APP_LIST__ACTION_MENU_CREATE_CUSTOM_PLAN()}
        </MenuItem>
      </Fragment>
    );
  };

  render() {
    return <ActionMenu>{this.renderMenuItems}</ActionMenu>;
  }
}
