// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { addBreadcrumb } from "@sentry/browser";

export const reduxMiddlewareForSentry = () => (next) => (action) => {
  addBreadcrumb({
    type: "default",
    level: "info",
    category: "redux-action",
    message: action.type,
    data: {
      actionTree: action,
    },
  });
  return next(action);
};
