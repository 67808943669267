// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "../../translations";
import {
  getContinentNames,
  getCountriesForContinent,
  CONTINENT_WORLD,
  Country,
} from "./utils";

const getStringForContinent = (
  checkedCountries: string[],
  countries: Country[],
  continent: string
): string | undefined => {
  if (continent === CONTINENT_WORLD) {
    return undefined;
  }

  const continentCountries = getCountriesForContinent(countries, continent);
  const checkedOnContinent = continentCountries.filter((country) =>
    checkedCountries.includes(country.code)
  );

  if (checkedOnContinent.length === 0) {
    return undefined;
  } else if (checkedOnContinent.length === continentCountries.length) {
    return `${continent} (${trans.CONTINENTS__ALL_SELECTED()})`;
  }

  const numStr = `${checkedOnContinent.length}/${continentCountries.length}`;
  return `${continent} (${numStr})`;
};

/**
 * Format countries codes array into string. If array contains all countries
 * "Worldwide (all)" is returned otherwise countries count per continent i.e
 * "Asia (1/46), Africa(4/45)" and so on.
 */
export const toRegionsString = (
  checkedCountries: string[],
  countries: Country[]
): string => {
  if (!checkedCountries) {
    return "";
  }

  if (checkedCountries.length === countries.length) {
    return trans.CONTINENTS__WORLDWIDE_ALL_SELECTED();
  }

  return getContinentNames()
    .reduce<string[]>((acc: string[], continent: string) => {
      const continentStr = getStringForContinent(
        checkedCountries,
        countries,
        continent
      );

      if (continentStr) {
        acc.push(continentStr);
      }
      return acc;
    }, [])
    .join(", ");
};
