// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const IconPropTypes = {
  size: PropTypes.oneOf(["xs", "sm", "normal", "lg", "xl", "2xl", "3xl"]),
};
