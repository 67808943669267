// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { getSelectedDeviceModels } from "reduxReusable/selectedTargets/selectors";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextareaField, TextField } from "components/form/fields";
import { api } from "containers/Request";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";

import { DEVICE_REJECTED } from "../../constants";
import { SelectedModels } from "../_shared";
import { SELECTED_TARGETS_STORE_PATH } from "../redux/constants";

const jsonPointerToFieldName = {
  "/data/attributes/moderation_targets/:id/device_model_id": "_error",
  "/data/attributes/moderation_targets/:id/owner_id": "_error",
  "/data/attributes/moderation_targets/:id/internal_status": "_error",
  "/data/attributes/moderation_targets/:id/reject_reason": "reason",
  "/data/attributes/moderation_targets/:id/issues": "issues",
};

const FIELDS = {
  TEXTAREA_FIELD: {
    name: "reason",
    label: trans.APP_DETAILS_MODERATION_TARGETS__REJECT_REASON(),
  },
  TEXT_FIELD: {
    name: "issues",
    label: trans.ISSUES(),
  },
};

const makeMapStateToProps = () => {
  return (state) => {
    return {
      selectedModels: getSelectedDeviceModels(state, {
        storePath: SELECTED_TARGETS_STORE_PATH,
      }),
    };
  };
};

@connect(makeMapStateToProps, (dispatch) => ({
  patchModerationResults: (id, body) =>
    dispatch(
      api.patchModerationResults.action({
        params: { id },
        options: { body },
      })
    ),
}))
export class RejectTargets extends Component {
  static propTypes = {
    application: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    selectedModels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        familyId: PropTypes.number.isRequired,
        distributorId: PropTypes.string.isRequired,
      })
    ).isRequired,
    patchModerationResults: PropTypes.func,
    handleModerationActionSuccess: PropTypes.func.isRequired,
    moderationId: PropTypes.string.isRequired,
  };

  submit = async (values, { setErrors }) => {
    const {
      onClose,
      selectedModels,
      patchModerationResults,
      handleModerationActionSuccess,
      moderationId,
      application,
    } = this.props;

    const body = createBody({
      type: "ModerationResult",
      id: moderationId,
      moderation_targets: selectedModels.map((model) => ({
        device_model_id: model.id,
        owner_id: model.distributorId,
        internal_status: DEVICE_REJECTED,
        reject_reason: values.reason,
        issues: values.issues,
      })),
    });

    const result = await patchModerationResults(moderationId, body);

    if (result.error) {
      const submitErrors = prepareErrorsForForm(
        result.error,
        jsonPointerToFieldName
      );
      setErrors(submitErrors);
    } else {
      onClose();
      handleModerationActionSuccess(
        trans.APP_DETAILS_MODERATION_TARGETS__ACTION_SUCCESS({
          actionName: "rejected",
          appName: application.name,
        })
      );
    }
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    return (
      <Formik
        initialValues={{
          [FIELDS.TEXTAREA_FIELD.name]: "",
          [FIELDS.TEXT_FIELD.name]: "",
        }}
        onSubmit={this.submit}
      >
        {({ dirty, errors, isSubmitting }) => (
          <Form>
            <TextareaField {...FIELDS.TEXTAREA_FIELD} />
            <SelectedModels />
            <TextField {...FIELDS.TEXT_FIELD} />
            {errors?._error && <Info type="error">{errors._error}</Info>}
            <ButtonsWrapper>
              <Button onClick={this.handleClose}>{trans.CANCEL()}</Button>
              <Button
                buttonType="submit"
                type="red"
                processing={isSubmitting}
                disabled={
                  isSubmitting ||
                  !dirty ||
                  Boolean(Object.entries(errors).length)
                }
              >
                {trans.APP_DETAILS_MODERATION_TARGETS__ACTION_REJECT()}
              </Button>
            </ButtonsWrapper>
          </Form>
        )}
      </Formik>
    );
  }
}
