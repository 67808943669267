// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const popupAlignmentType = PropTypes.oneOf([
  "bottom-end",
  "bottom-start",
  "bottom",
  "top-end",
  "top-start",
  "top",
  "left-end",
  "left-start",
  "left",
  "right-end",
  "right-start",
  "right",
  "auto",
  "auto-start",
  "auto-end",
]);
