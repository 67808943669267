// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { WarningModal } from "components/layout";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";

import { deleteManifestMutation } from "../gql";
import styles from "./ManifestRemoveModal.scss";

export const ManifestRemoveModal = ({
  manifestData,
  isOpen,
  toggleOpen,
  refetchQuery = {},
}) => {
  const [deleteMutation, { loading, error, reset }] = useMutation(
    deleteManifestMutation,
    {
      // @todo updating cache might be a better solution
      refetchQueries: [refetchQuery],
    }
  );

  const handleClose = () => {
    reset();
    toggleOpen();
  };

  const handleDelete = async () => {
    await deleteMutation({ variables: { id: manifestData.id } });
    handleClose();
  };

  return (
    <WarningModal
      actions={
        <ButtonsWrapper position="modal">
          <Button type="normal" onClick={handleClose}>
            {trans.CANCEL()}
          </Button>
          <Button
            type="red"
            onClick={handleDelete}
            processing={loading}
            disabled={loading}
          >
            {trans.APP_DETAILS_MANIFEST__REMOVE_CONFIRM_BUTTON()}
          </Button>
        </ButtonsWrapper>
      }
      isOpen={isOpen}
      onClose={toggleOpen}
      size="medium"
      title={trans.WARNING()}
    >
      <p className={styles.removeModalText}>
        {trans.APP_DETAILS_MANIFEST__REMOVE_MESSAGE()}
      </p>
      {error && <Info type="error">{getGqlErrorMessage(error)}</Info>}
    </WarningModal>
  );
};

ManifestRemoveModal.propTypes = {
  manifestData: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
  refetchQuery: PropTypes.object,
};
