// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Modal } from "components/layout";
import { trans } from "src/translations";

import styles from "./PromotionDetailsWarnModal.scss";

export class PromotionDetailsWarnModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    submitting: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    ongoingCampaigns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  static defaultProps = {
    isOpen: false,
    submitting: false,
  };

  handleSubmit = async () => {
    const { onSubmit } = this.props;
    await onSubmit();
  };

  render() {
    const { isOpen, submitting, onClose, ongoingCampaigns } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              processing={submitting}
              disabled={submitting}
              onClick={this.handleSubmit}
            >
              {trans.CONFIRM()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <p>{trans.PROMOTION_EDIT__ONGOING_CAMPAIGN_WARNING_A()}</p>
        <ul className={styles.campaignsList}>
          {ongoingCampaigns.map((c) => (
            <li key={c.id}>{c.name}</li>
          ))}
        </ul>
        <p> {trans.PROMOTION_EDIT__ONGOING_CAMPAIGN_WARNING_B()}</p>
      </Modal>
    );
  }
}
