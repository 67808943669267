// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import { FieldArray } from "formik";
import PropTypes from "prop-types";

import {
  TableRowColumnExtended,
  TableRowColumn,
  TableRow,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { BUTTONS, SECTIONS } from "pages/Snap/shared/constants";
import styles from "pages/Snap/shared/Translations.scss";
import * as iconStyles from "pages/Snap/SnapEdit/components/SnapIconPicker/icons.scss";
import { OrderColumn } from "pages/Snap/SnapEdit/components/Translations/OrderColumn";
import SectionLiveStream from "pages/Snap/SnapEdit/components/Translations/SectionLiveStream";
import { isValidLiveSection } from "pages/Snap/SnapEdit/validation";
import { classes } from "utils/classes";

export const SectionLive = ({
  fieldName,
  fields,
  item,
  openLiveSectionModal,
  openLiveStreamModal,
  index,
  remove,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const arrowIcon = isOpen ? "arrow-up" : "arrow-down";

  const rowClass = isValidLiveSection(item)
    ? styles.rowSuccess
    : styles.rowError;

  return (
    <>
      <TableRow
        className={classes(rowClass, styles.clickable)}
        dataTestId="live-section-table-row"
      >
        <TableRowColumnExtended
          fontIcon={arrowIcon}
          fontIconType="micro"
          fontIconSize="sm"
          onClick={() => setIsOpen((prevState) => !prevState)}
        />

        <TableRowColumn onClick={openLiveSectionModal}>
          <div className={iconStyles[item.icon]} />
        </TableRowColumn>
        <TableRowColumn
          columnClass={styles.titleColumn}
          onClick={openLiveSectionModal}
        >
          {item.name}
        </TableRowColumn>
        <TableRowColumn />
        <TableRowColumn onClick={openLiveSectionModal}>
          {SECTIONS.TRANSLATIONS.sectionLive}
        </TableRowColumn>
        <OrderColumn
          arrowUpPossible={index - 1 >= 1}
          arrowDownPossible={index + 1 !== fields.length}
          fields={fields}
          index={index}
          {...rest}
        />
        <TableRowColumn>
          <ActionMenu>
            <MenuItem
              onClick={openLiveSectionModal}
              dataTestId="edit-menu-item"
            >
              {BUTTONS.DROPLIST.edit}
            </MenuItem>
            <MenuItem
              onClick={() => remove(index)}
              dataTestId="delete-menu-item"
            >
              {BUTTONS.DROPLIST.delete}
            </MenuItem>
          </ActionMenu>
        </TableRowColumn>
      </TableRow>
      {isOpen ? (
        <FieldArray
          name={`${fieldName}.live_feeds`}
          render={({ ...helpers }) => {
            const { value: fields } = helpers.form.getFieldMeta(
              `${fieldName}.live_feeds`
            );
            return fields.map((member, index) => {
              return (
                <SectionLiveStream
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${member}_${index}`}
                  openLiveStreamModal={openLiveStreamModal}
                  index={index}
                  fields={fields}
                  {...helpers}
                />
              );
            });
          }}
        />
      ) : null}
    </>
  );
};

SectionLive.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  openLiveStreamModal: PropTypes.func.isRequired,
  openLiveSectionModal: PropTypes.func.isRequired,
  // Formik field helper
  remove: PropTypes.func.isRequired,
};
