// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { TranslationKeysType } from "translations/langs/def_lang";

import {
  getAllTranslationsAsFunc,
  TranslateFunction,
} from "./getAllTranslationsAsFunc";

export const getCurrentTranslations = (): Record<
  TranslationKeysType,
  TranslateFunction
> => {
  const allTranslationsAsFunc = getAllTranslationsAsFunc();

  const currentLanguage = "en"; // currently, only english supported
  return allTranslationsAsFunc[currentLanguage];
};
