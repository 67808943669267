// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Label } from "components/form";

import styles from "./header.scss";
import { Status } from "./Status";

export class Header extends Component {
  static propTypes = {
    text: PropTypes.string,
    info: PropTypes.string,
    field: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    required: PropTypes.bool,
    tooltip: PropTypes.string,
  };

  render() {
    const { text, required, field, info, tooltip } = this.props;

    return (
      <div className={styles.header}>
        <Label
          className={styles.label}
          text={text}
          info={info}
          required={required}
          tooltip={tooltip}
        />
        <Status field={field} />
      </div>
    );
  }
}
