// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Label } from "components/form";
import { trans } from "src/translations";

import styles from "./AccountStatus.scss";

export const AccountStatus = (props) => {
  const { isActive, onActivate, onDeactivate } = props;
  return (
    <div className={styles.container}>
      <Label text={trans.USER_ADMIN__ACCOUNT_STATUS()}>
        <div className={styles.content}>
          <span>
            {`${trans.STATUS()} `}
            <span className={isActive ? styles.green : styles.red}>
              {isActive
                ? trans.USER_ADMIN__ACTIVE()
                : trans.USER_ADMIN__DEACTIVATED()}
            </span>
          </span>
          <div className={styles.buttons}>
            <Button
              type="green"
              disabled={isActive}
              onClick={onActivate}
              size="small"
            >
              {trans.USER_ADMIN__ACTIVATE()}
            </Button>
            <Button
              type="red"
              disabled={!isActive}
              onClick={onDeactivate}
              size="small"
            >
              {trans.USER_ADMIN__DEACTIVATE()}
            </Button>
          </div>
        </div>
      </Label>
    </div>
  );
};

AccountStatus.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
};
