// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { getSelectedDeviceModels } from "reduxReusable/selectedTargets/selectors";

import { Accordion } from "components/layout";
import { trans } from "src/translations";

import { SELECTED_TARGETS_STORE_PATH } from "../redux/constants";
import styles from "./selectedModels.scss";

const makeMapStateToProps = () => {
  return (state) => {
    return {
      selectedModels: getSelectedDeviceModels(state, {
        storePath: SELECTED_TARGETS_STORE_PATH,
      }),
    };
  };
};

@connect(makeMapStateToProps)
export class SelectedModels extends Component {
  static propTypes = {
    className: PropTypes.string,
    selectedModels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        familyId: PropTypes.number.isRequired,
        distributorId: PropTypes.string.isRequired,
      })
    ).isRequired,
  };

  accordionHeader = () => {
    const { selectedModels } = this.props;
    return (
      <span>
        {`${trans.APP_DETAILS_MODERATION_TARGETS__AFFECTED_DEVICES()}:`}
        <span className={styles.accordionHeaderList}>
          {selectedModels.map((m) => m.name).join(", ")}
        </span>
      </span>
    );
  };

  render() {
    const { selectedModels, className } = this.props;

    return (
      <Accordion
        header={this.accordionHeader()}
        expandedHeader={trans.APP_DETAILS_MODERATION__AFFECTED_DEVICE_MODELS()}
        look="compact"
        className={className}
      >
        <ul>
          {selectedModels.map((m) => (
            <li key={m.id}>{m.name}</li>
          ))}
        </ul>
      </Accordion>
    );
  }
}
