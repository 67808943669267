// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useState } from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchableList, SearchableListPanel } from "components/elements";
import { InputWithModal, Radio } from "components/form/elements";
import { Modal } from "components/layout";
import { Tooltip } from "components/popups";
import { InfiniteData } from "containers/InfiniteData";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";

import styles from "./TargetDevicesField.scss";

const VISIBLE_ROW_LABEL_MAX_LENGTH = 55;

const getFieldDisplayValue = (value) => {
  // None
  if (!value) {
    return "";
  }
  // Query
  if ("deviceCount" in value) {
    return `${value.name} (${trans.TARGET_DEVICES__DEVICE_COUNT({
      deviceCount: value.deviceCount,
    })})`;
  }
  // Single device
  return value.name;
};

const validate = (value, required) => {
  let error;
  if (required && !value) {
    error = trans.ERROR__FIELD_REQUIRED();
  }
  return error;
};

const TargetDevicesModal = ({
  config,
  initialTargetId,
  isOpen,
  dataTestId,
  fetchTargets,
  onSubmit,
  onClose,
  children,
}) => {
  const [target, setTarget] = useState({ id: initialTargetId });
  const targetId = target?.id ?? null;

  const renderRow = (rowTarget) => {
    const radioProps = {
      className: styles.row,
      defaultValue: rowTarget.id,
      value: targetId,
      dataTestId: `${dataTestId}-modal-list-option`,
      onChange: () => setTarget(rowTarget),
    };

    return (
      <SearchableListPanel key={rowTarget.id}>
        {children ? (
          children(rowTarget, radioProps)
        ) : (
          <Radio {...radioProps}>
            <Tooltip
              alignment="bottom-end"
              className={styles.tooltipWrapper}
              classNamePopup={styles.tooltipPopup}
              content={rowTarget.name}
              disabled={rowTarget.name.length < VISIBLE_ROW_LABEL_MAX_LENGTH}
            >
              <div className={styles.rowLabel}>{rowTarget.name}</div>
            </Tooltip>
          </Radio>
        )}
      </SearchableListPanel>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      size="medium"
      title={config.title}
      dataTestId={`${dataTestId}-modal`}
      actions={
        <ButtonsWrapper position="modal">
          <Button
            type="normal"
            onClick={onClose}
            dataTestId={`${dataTestId}-modal-close-button`}
          >
            {trans.CANCEL()}
          </Button>
          <Button
            disabled={targetId === initialTargetId}
            type="green"
            onClick={() => onSubmit(target)}
            dataTestId={`${dataTestId}-modal-submit-button`}
          >
            {trans.SELECT()}
          </Button>
        </ButtonsWrapper>
      }
      onClose={onClose}
    >
      {config.description && (
        <p className={styles.description}>{config.description}</p>
      )}
      <InfiniteData
        fetchData={fetchTargets.callback}
        itemsPerPage={fetchTargets.itemsPerPage}
      >
        {({
          allDataLoaded,
          count,
          data,
          error,
          pending,
          handleScroll,
          handleSearchChange,
        }) => (
          <>
            <SearchableList
              allDataLoaded={allDataLoaded}
              error={error && getGqlErrorMessage(error)}
              list={data}
              loading={pending}
              optionsListElement={config.renderHeaders}
              placeholder={config.placeholder}
              withLoader={true}
              dataTestId={`${dataTestId}-modal-list`}
              onScroll={handleScroll}
              onSearchChange={handleSearchChange}
            >
              {renderRow}
            </SearchableList>
            {data && data.length ? (
              <span
                className={styles.itemCount}
                data-test-id={`${dataTestId}-item-count`}
              >
                {trans.TARGET_DEVICES__ELEMENT_COUNT({ count })}
              </span>
            ) : null}
          </>
        )}
      </InfiniteData>
    </Modal>
  );
};

export const TargetDevicesField = ({
  name,
  label,
  modalConfig,
  required = false,
  dataTestId,
  fetchTargets,
  children,
}) => {
  const [field, meta, { setTouched, setValue }] = useField({
    name,
    validate: (value) => validate(value, required),
  });

  const handleClose = useCallback(
    (onClose) => {
      setTouched(true);
      onClose();
    },
    [setTouched]
  );

  const handleSubmit = useCallback(
    (value, onClose) => {
      handleClose(onClose);
      setValue(value);
    },
    [handleClose, setValue]
  );

  return (
    <InputWithModal
      label={label}
      look="horizontal"
      meta={meta}
      required={required}
      value={getFieldDisplayValue(field.value)}
      dataTestId={dataTestId ?? name}
    >
      {(isOpen, onClose) =>
        isOpen && (
          <TargetDevicesModal
            config={modalConfig}
            initialTargetId={field.value?.id ?? null}
            isOpen={isOpen}
            dataTestId={dataTestId ?? name}
            fetchTargets={fetchTargets}
            onClose={() => handleClose(onClose)}
            onSubmit={(value) => handleSubmit(value, onClose)}
          >
            {children}
          </TargetDevicesModal>
        )
      }
    </InputWithModal>
  );
};

TargetDevicesModal.propTypes = {
  config: PropTypes.shape({
    description: PropTypes.string,
    placeholder: PropTypes.string,
    title: PropTypes.string.isRequired,
    renderHeaders: PropTypes.func,
  }),
  fetchTargets: PropTypes.shape({
    callback: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  initialTargetId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.func,
};

TargetDevicesField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  modalConfig: TargetDevicesModal.propTypes.config,
  required: PropTypes.bool,
  dataTestId: PropTypes.string,
  fetchTargets: TargetDevicesModal.propTypes.fetchTargets,
  children: PropTypes.func,
};
