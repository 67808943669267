// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { classes } from "utils/classes";

import styles from "./TableHeaderColumn.scss";

/**
 * Corresponds to `<th>`. Should be placed inside `<TableHeader>`.
 * All additional props for this component will be set on result `<th>` tag
 */
export class TableHeaderColumn extends Component {
  static propTypes = {
    /** Content. */
    children: PropTypes.node,
    /** Icon name. See [GrabsonIcon](#grabsonicon) for available icons. */
    icon: PropTypes.string,
    className: PropTypes.string,
    /** Header can span many columns */
    colspan: PropTypes.number,
    /** Name using for sorting */
    name: PropTypes.string,
    /** Current sorting value. Equal to `name` prop if sorting by this column */
    value: PropTypes.string,
    /** True if sorting ascending */
    sortUp: PropTypes.bool,
    /**
     * Event handler.
     *
     * Type: `(e: Event) => void`
     */
    onClick: PropTypes.func,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "column-header",
  };

  handleClick = () => {
    const { name, onClick } = this.props;
    if (onClick) {
      onClick(name);
    }
  };

  render() {
    const {
      children,
      icon,
      className,
      colspan,
      name,
      value,
      sortUp,
      dataTestId,
      ...props
    } = this.props;

    return (
      <th
        className={classes(styles.container, className, {
          [styles.sortable]: name,
        })}
        colSpan={colspan}
        onClick={this.handleClick}
        data-test-id={dataTestId}
        {...props}
      >
        {children}
        {value && name === value && (
          <GrabsonIcon name={sortUp ? "arrow-up" : "arrow-down"} font="micro" />
        )}
        {icon && <GrabsonIcon name={icon} />}
      </th>
    );
  }
}

export default TableHeaderColumn;
