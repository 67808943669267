// Copyright © 2021 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import * as React from "react";

interface FieldHoCProps {
  style: Record<string, string>;
  className: string;
  value: any;
  onChange: (...args: any[]) => any;
}

export const withFieldGuard = <P extends object>(
  ComposedComponent: React.ComponentType<P>
) => {
  return class FieldHoC extends Component<P & FieldHoCProps> {
    el?: HTMLElement;

    componentDidMount() {
      const { value, onChange } = this.props;
      const hasMissingProps = value === undefined || onChange === undefined;

      if (!this.isProductionBuild() && hasMissingProps) {
        console.error(
          "Either value or onChange property is missing for ",
          ComposedComponent.name,
          ". ",
          "This can happen if you mispelled property spread name ( eg. {...field.misspelled} ) ",
          "or you didn't include component in @createForm decorator"
        );
      }
    }

    isProductionBuild() {
      return process.env.NODE_ENV === "production";
    }

    render() {
      return (
        <ComposedComponent
          ref={(el: HTMLElement) => {
            this.el = el;
          }}
          {...this.props}
        />
      );
    }
  };
};
