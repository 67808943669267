// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { validateImage } from "utils/image";

export const landscapeImgDimensions = {
  minWidth: 1600,
  minHeight: 900,
  maxWidth: 1920,
  maxHeight: 1080,
};

export const validateFullPageLandscapeField = (blobUrl) =>
  validateImage(blobUrl, {
    ...landscapeImgDimensions,
    maxSize: 9999999,
    validateAspectRatio: true,
  });

export const validateVideoUrlThumbnail = (blobUrl) =>
  validateImage(blobUrl, {
    minWidth: 16, // just to validate apsect ratio
    minHeight: 9, // just to validate apsect ratio
    maxSize: 2097152, // 2MB in bytes
    validateAspectRatio: true,
  });
