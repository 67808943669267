import PropTypes from "prop-types";

import { SearchableList, SearchableListPanel } from "components/elements";
import { Radio } from "components/form/elements";
import {
  Section,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
} from "components/layout";
import { Link } from "components/typography";
import { InfiniteData } from "containers/InfiniteData";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";

import { DEVICE_TYPE_QUERY } from "../constants";
import styles from "./TargetDevices.scss";

export const TargetRow = ({
  fetchFunc,
  header,
  description,
  selectedItem,
  mode,
  onSelectItem,
  itemsPerPage,
}) => {
  const renderRow = (item) => {
    const deviceInventoryItemPage =
      mode === DEVICE_TYPE_QUERY
        ? `/devices/filters/${item.id}/devices`
        : `/devices/inventory/${item.id}/details`;
    return (
      <SearchableListPanel key={item.id} className={styles.row}>
        <Radio
          onChange={() => onSelectItem(item)}
          value={selectedItem && selectedItem.id}
          defaultValue={item.id}
          className={styles.radio}
        />
        <Link
          target="_blank"
          href={deviceInventoryItemPage}
          className={styles.link}
        >
          {item.name}
        </Link>
      </SearchableListPanel>
    );
  };

  return (
    <div className={styles.container}>
      <Section className={styles.section} header={header}>
        {description}
        <InfiniteData fetchData={fetchFunc} itemsPerPage={itemsPerPage}>
          {({
            data,
            pending,
            error,
            allDataLoaded,
            handleScroll,
            handleSearchChange,
          }) => (
            <Table className={styles.table}>
              <TableBody>
                <SearchableList
                  dataTestId="add-target-query-list"
                  withLoader={true}
                  list={data}
                  searchBy="name"
                  onSearchChange={handleSearchChange}
                  loading={pending}
                  allDataLoaded={allDataLoaded}
                  error={error && getGqlErrorMessage(error)}
                  placeholder={trans.SEARCH()}
                  onScroll={handleScroll}
                  optionsListElement={() => (
                    <TableHeader>
                      <TableHeaderColumn className={styles.tableHeaderColumn}>
                        {trans.QUERY_NAME()}
                      </TableHeaderColumn>
                    </TableHeader>
                  )}
                >
                  {(item) => renderRow(item)}
                </SearchableList>
              </TableBody>
            </Table>
          )}
        </InfiniteData>
      </Section>
    </div>
  );
};

TargetRow.propTypes = {
  selectedItem: PropTypes.object,
  onSelectItem: PropTypes.func.isRequired,
  header: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  fetchFunc: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
};
