import {
  TableBody,
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import { NO_VALUE_PLACEHOLDER } from "../../../constants";
import { DeviceUpdateJobStatus } from "../../Devices/DeviceDetails/_shared/DeviceUpdateJobStatus";
import styles from "./UpdateJobDetailsEligibleDevicesTab.scss";
import { AdaptedDevice } from "./utils/adaptEligibleDevices";

export const ITEMS_PER_PAGE = 50;

export const TABS_CONFIG = [
  {
    id: "EligibleDevicesTable",
    columns: [
      {
        id: "devicePdid",
        label: trans.UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_PDID(),
      },
      {
        id: "lastContactDate",
        label:
          trans.UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_CONTACT_DATE(),
        sortBy: "LAST_CONTACT_DATE",
      },
      {
        id: "updateStatus",
        label:
          trans.UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_UPDATE_STATUS(),
      },
    ],
  },
];

export const UpdateJobDetailsEligibleDevicesTable = ({
  loading,
  results,
  count,
  error,
}: {
  loading: boolean;
  results: AdaptedDevice[];
  count: number;
  error?: string;
}) => {
  return (
    <TabbedTable
      withPagination
      data={{ loading, results, count, error }}
      tabsConfig={TABS_CONFIG}
      rowsPerPage={ITEMS_PER_PAGE}
      renderTableBody={(row: AdaptedDevice[]) => (
        <TableBody>
          {row.map(({ id, status, lastContactDate }) => (
            <TableRow key={id}>
              <TableRowColumnExtended
                to={`/devices/inventory/${id}/details`}
                text={id}
                className={styles.deviceId}
              />
              <TableRowColumn>
                {lastContactDate
                  ? formatDateTime(lastContactDate)
                  : NO_VALUE_PLACEHOLDER}
              </TableRowColumn>
              <TableRowColumn>
                <DeviceUpdateJobStatus status={status} />
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      )}
    />
  );
};
