// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Modal } from "components/layout";

import { DeviceFamilyFormData } from "./DeviceFamilyFormData";

export const FamilyFormModal = ({
  isModalOpen,
  ownerId,
  deviceFamilyId,
  onRequestSuccess,
  closeModal,
  forAdmin,
}) => {
  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <DeviceFamilyFormData
        ownerId={ownerId}
        deviceFamilyId={deviceFamilyId}
        onRequestSuccess={onRequestSuccess}
        onCancel={closeModal}
        forAdmin={forAdmin}
      />
    </Modal>
  );
};

FamilyFormModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  ownerId: PropTypes.string.isRequired,
  deviceFamilyId: PropTypes.string,
  onRequestSuccess: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  forAdmin: PropTypes.bool,
};

FamilyFormModal.defaultProps = {
  forAdmin: false,
};
