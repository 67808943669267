// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Navigate } from "react-router-dom";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { AppName, AppNameChangeFeedback } from "components/form/AppName";
import { PageHeader, PageContent, PageError } from "components/layout";
import { Tabs, TabContent, Tab } from "components/navigation";
import { ROLES, checkAccess } from "containers/Permissions";
import { ALL_MODERATORS, ALL_PROVIDERS } from "containers/Permissions/groups";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";

import { getAppRouteType } from "../_utils";
import { AppRankTab } from "./AppRankTab";
import { VersionsTabData } from "./AppVersions/VersionsTabData";
import { CertificationTab } from "./Certification/CertificationTab";
import { ManifestTab } from "./ManifestTab";
import { MessagesTab } from "./MessagesTab";
import { ModerationTab } from "./ModerationTab";
import { ModerationTargetsTab } from "./ModerationTargetsTab";
import { RightsAndContractTab } from "./RightsAndContract/RightsAndContractTab";

const wasSubmittedAtLeastOnce = (qaSummaries) => Boolean(qaSummaries);
@withRouter
export class AppDetails extends Component {
  static propTypes = {
    // from @withRouter

    params: PropTypes.shape({
      tabId: PropTypes.string,
      id: PropTypes.string,
    }),

    location: PropTypes.object.isRequired,
    /**
     * Displayed application data
     */
    application: PropTypes.object,
    qaSummaries: PropTypes.object,
    refreshData: PropTypes.func.isRequired,
    refreshQASummaries: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.object,

    activeOrganization: PropTypes.object,
  };

  /**
   * Definition of tabs displayed.
   * tabId is corelated with url, text is displayed on tab, and TabComponent is used to
   * render content of given tab.
   *
   * @see AppDetailsData.canRequestQASummaries
   */
  tabInfo = [
    {
      tabId: "moderation",
      text: trans.APP_DETAILS_MODERATION__TAB(),
      TabComponent: ModerationTab,
      isVisible: (qaSummaries, activeOrganization) => {
        if (!wasSubmittedAtLeastOnce(qaSummaries)) {
          return false;
        }

        return checkAccess({ activeOrganization }, [
          ROLES.moderator.moderator,
          ROLES.moderator.contentManager,
        ]);
      },
      getUrl: (routeType, appId) =>
        `/applications/${routeType}/${appId}/details/moderation`,
    },
    {
      tabId: "targets",
      text: trans.APP_DETAILS_MODERATION_TARGETS__TAB(),
      TabComponent: ModerationTargetsTab,
      isVisible: (qaSummaries, activeOrganization) => {
        if (!wasSubmittedAtLeastOnce(qaSummaries)) {
          return false;
        }

        return checkAccess({ activeOrganization }, [
          ROLES.moderator.moderator,
          ROLES.moderator.contentManager,
        ]);
      },
      getUrl: (routeType, appId) =>
        `/applications/${routeType}/${appId}/details/targets`,
    },
    {
      tabId: "versions",
      text: trans.APP_DETAILS_VERSION__TAB(),
      TabComponent: VersionsTabData,
      isVisible: (qaSummaries, activeOrganization) => {
        return checkAccess({ activeOrganization }, [
          ...ALL_PROVIDERS,
          ...ALL_MODERATORS,
          ROLES.distributor.readOnlyDistributionPlanner,
          ROLES.distributor.distributionPlanner,
          ROLES.administrator.distributionAdmin,
        ]);
      },
      getUrl: (routeType, appId) =>
        `/applications/${routeType}/${appId}/details/versions`,
    },
    {
      tabId: "manifest",
      text: trans.APP_DETAILS_MANIFEST__TAB(),
      TabComponent: ManifestTab,
      isVisible: (qaSummaries, activeOrganization) => {
        return checkAccess({ activeOrganization }, ALL_MODERATORS);
      },
      getUrl: (routeType, appId) =>
        `/applications/${routeType}/${appId}/details/manifest`,
    },
    {
      tabId: "messages",
      text: trans.APP_DETAILS_MESSAGES__TAB(),
      TabComponent: MessagesTab,
      isVisible: (qaSummaries, activeOrganization) => {
        return checkAccess({ activeOrganization }, [
          ...ALL_PROVIDERS,
          ROLES.moderator.moderator,
          ROLES.moderator.contentManager,
          ROLES.distributor.distributionPlanner,
        ]);
      },
      getUrl: (routeType, appId) =>
        `/applications/${routeType}/${appId}/details/messages`,
    },
    {
      tabId: "rights",
      text: trans.APP_DETAILS_RIGHTS__TAB(),
      TabComponent: RightsAndContractTab,
      isVisible: (qaSummaries, activeOrganization) => {
        if (!wasSubmittedAtLeastOnce(qaSummaries)) {
          return false;
        }

        return checkAccess({ activeOrganization }, [
          ROLES.moderator.moderator,
          ROLES.moderator.contentManager,
        ]);
      },
      getUrl: (routeType, appId) =>
        `/applications/${routeType}/${appId}/details/rights`,
    },
    {
      tabId: "certification",
      text: trans.APP_DETAILS_CERTIFICATION__TAB(),
      TabComponent: CertificationTab,
      isVisible: (qaSummaries, activeOrganization) => {
        if (!wasSubmittedAtLeastOnce(qaSummaries)) {
          return false;
        }

        return checkAccess({ activeOrganization }, [
          ROLES.moderator.moderator,
          ROLES.moderator.contentManager,
        ]);
      },
      getUrl: (routeType, appId) =>
        `/applications/${routeType}/${appId}/details/certification`,
    },
    {
      tabId: "app-rank",
      text: trans.APP_DETAILS_APP_RANK__TAB(),
      TabComponent: AppRankTab,
      isVisible: (qaSummaries, activeOrganization) => {
        if (!wasSubmittedAtLeastOnce(qaSummaries)) {
          return false;
        }
        return checkAccess({ activeOrganization }, [
          ROLES.moderator.moderator,
          ROLES.moderator.contentManager,
        ]);
      },
      getUrl: (routeType, appId) =>
        `/applications/${routeType}/${appId}/details/app-rank`,
    },
  ];

  state = {
    appRenamingStatus: {
      success: null,
      error: null,
    },
  };

  updateAppRenamingStatus = (status) =>
    this.setState({ appRenamingStatus: status });

  getAppId() {
    return this.props.params.id;
  }

  getVisibleTabsInfo() {
    const { qaSummaries, activeOrganization } = this.props;
    return this.tabInfo.filter((tab) =>
      tab.isVisible(qaSummaries, activeOrganization)
    );
  }

  render() {
    const {
      loading,
      error,
      qaSummaries,
      refreshData,
      refreshQASummaries,
      application,
      location,
    } = this.props;

    const { appRenamingStatus } = this.state;

    if (loading) {
      return <Loader message={`${trans.LOADING()}...`} />;
    }

    if (error) {
      return (
        <PageError
          pageHeader={trans.APP_DETAILS__ERROR_PAGE_HEADER()}
          error={error}
        />
      );
    }

    const { tabId } = this.props.params;
    const visibleTabsInfo = this.getVisibleTabsInfo();

    const currentTabData = visibleTabsInfo.find((tab) => tab.tabId === tabId);

    if (!currentTabData) {
      const firstValidTab = visibleTabsInfo[0];

      // If for this user there is no visible tabs, it means that he doesn't have
      // permissions to view details at all.
      if (!firstValidTab) {
        return (
          <PageError
            pageHeader={trans.APP_DETAILS__ERROR_PAGE_HEADER()}
            error={{ isForbidden: true }}
          />
        );
      }

      return this.renderRedirectToTab(firstValidTab);
    }

    const appId = this.getAppId();
    const routeType = getAppRouteType(location.pathname);

    const CurrentTabComponent = currentTabData.TabComponent;

    return (
      <div>
        <PageHeader>
          <AppName
            appData={application}
            updateStatus={this.updateAppRenamingStatus}
          />
        </PageHeader>
        <PageContent>
          <AppNameChangeFeedback status={appRenamingStatus} />
          <Tabs>
            {visibleTabsInfo.map(({ tabId, text, getUrl }) => {
              return (
                <Tab key={tabId} to={getUrl(routeType, appId)} text={text} />
              );
            })}
          </Tabs>
          <TabContent>
            <CurrentTabComponent
              refreshData={refreshData}
              refreshQASummaries={refreshQASummaries}
              qaSummaries={qaSummaries}
              application={application}
            />
          </TabContent>
        </PageContent>
      </div>
    );
  }

  renderRedirectToTab(tab) {
    const { location } = this.props;
    const appId = this.getAppId();
    const routeType = getAppRouteType(location.pathname);

    const validTabUrl = tab.getUrl(routeType, appId);
    return <Navigate to={validTabUrl} replace={true} />;
  }
}
