// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as actions from "./actionTypes";

const defaultSubState = {
  pending: false,
  error: "",
  result: {},
};

const removeItem = (state, key) => {
  const { [key]: _, ...remainingCache } = state;
  return remainingCache;
};

export const request = (state = {}, action) => {
  switch (action.type) {
    case actions.REQUEST:
    case actions.CACHE:
      return {
        ...state,
        [action.keyInStore]: {
          ...defaultSubState,
          ...state[action.keyInStore],
          pending: true,
          error: undefined,
        },
      };
    case actions.REQUEST_SUCCESS:
    case actions.CACHE_SUCCESS:
      return {
        ...state,
        [action.keyInStore]: {
          ...defaultSubState,
          ...state[action.keyInStore],
          pending: false,
          result: action.result,
        },
      };
    case actions.REQUEST_FAILURE:
      return {
        ...state,
        [action.keyInStore]: {
          ...defaultSubState,
          ...state[action.keyInStore],
          pending: false,
          result: {},
          error: action.error,
        },
      };
    case actions.CACHE_REMOVE:
      return removeItem(state, action.keyInStore);
    default:
      return state;
  }
};
