// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

const createSearchBarFilter = (filter) => ({
  id: filter.id,
  displayName: filter.display_name,
  param: filter.param,
  description: filter.description,
  values: [],
  hasSuggestions: filter.has_suggestions,
});

export const parseApplicationsListFilters = (esData) => ({
  sections: [
    {
      title: trans.DEFAULT_SECTION(),
      options: (esData || []).map(createSearchBarFilter),
    },
  ],
});
