// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { TextField } from "components/form/fields";
import { Modal } from "components/layout";
import { APP_ENVIRONMENT } from "pages/Applications/AppDetails/constants";
import { trans } from "src/translations";

const createAcceptChangesArgs = (appEnvironmentType, values) => {
  switch (appEnvironmentType) {
    case APP_ENVIRONMENT.LINUX: {
      return {
        name: values.name,
        native_id: values.native_id,
      };
    }
    case APP_ENVIRONMENT.ANDROID: {
      return {
        name: values.name,
        action: values.action,
        package: values.package,
        class: values.class,
      };
    }
    default: {
      return {};
    }
  }
};

export const ProfileModal = ({
  appEnvironmentType,
  isEdit,
  acceptChanges,
  onClose,
  invalid,
  readOnly,
}) => {
  let title = trans.APP_EDIT__PROFILE_ACTION_ADD_PROFILE();
  let confirmationLabel = trans.APP_EDIT__PROFILE_ACTION_ADD();

  const { values } = useFormikContext();

  if (isEdit) {
    title = trans.APP_EDIT__PROFILE_ACTION_EDIT_PROFILE();
    confirmationLabel = trans.SAVE();
  }

  if (readOnly) {
    title = trans.APP_DETAILS_VERSION__PROFILE_PREVIEW_MODAL_TITLE({
      profileName: values.name,
    });
  }

  return (
    <Modal
      isOpen
      size={"big"}
      title={title}
      onClose={onClose}
      actions={
        <ButtonsWrapper>
          <Button onClick={onClose}>
            {readOnly ? trans.BACK() : trans.CANCEL()}
          </Button>
          {!readOnly && (
            <Button
              onClick={() =>
                acceptChanges(
                  createAcceptChangesArgs(appEnvironmentType, values)
                )
              }
              type={"green"}
              disabled={invalid}
            >
              {confirmationLabel}
            </Button>
          )}
        </ButtonsWrapper>
      }
    >
      <TextField
        name="name"
        label={trans.APP_EDIT__PROFILE_NAME_LABEL()}
        required
        readOnly={readOnly}
        dataTestId="profile-name"
      />

      {appEnvironmentType === APP_ENVIRONMENT.LINUX && (
        <TextField
          name="native_id"
          label={trans.APP_EDIT__PROFILE_NATIVE_ID_LABEL()}
          required
          readOnly={readOnly}
          dataTestId="profile-native-id"
        />
      )}

      {appEnvironmentType === APP_ENVIRONMENT.ANDROID && (
        <Fragment>
          <TextField
            name="package"
            required
            label={trans.APP_EDIT__PROFILE_PACKAGE_LABEL()}
            readOnly={readOnly}
            dataTestId="profile-package"
          />

          <TextField
            name="action"
            required={false}
            label={trans.APP_EDIT__PROFILE_ACTION_LABEL()}
            readOnly={readOnly}
            dataTestId="profile-action"
          />

          <TextField
            name="class"
            required={false}
            label={trans.APP_EDIT__PROFILE_CLASS_LABEL()}
            readOnly={readOnly}
            dataTestId="profile-class"
          />
        </Fragment>
      )}
    </Modal>
  );
};

ProfileModal.propTypes = {
  appEnvironmentType: PropTypes.oneOf([
    APP_ENVIRONMENT.LINUX,
    APP_ENVIRONMENT.ANDROID,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  // Required only when readOnly=false
  acceptChanges: PropTypes.func,
  isEdit: PropTypes.bool,
  invalid: PropTypes.bool,
  readOnly: PropTypes.bool,
};

ProfileModal.defaultProps = {
  isEdit: false,
  invalid: false,
  readOnly: false,
};
