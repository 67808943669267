// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getCommandQuery = gql`
  query command($id: ID!) {
    command(commandId: $id) {
      command {
        name
        payload
        target {
          collectionID
          collectionName
          collectionDeviceCount
          devicePublicID
        }
        status {
          processing
          sent
          received
          rejected
        }
        message
        creationDate
        timeToLive
        receivedCount
      }
    }
  }
`;
