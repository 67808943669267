// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import pickBy from "lodash-es/pickBy";
import PropTypes from "prop-types";

import { SearchBarWithFilters } from "components/elements";
import { Info } from "components/feedback";
import { Checkbox, Select } from "components/form/elements";
import { GrabsonIcon } from "components/icons";
import {
  TableBody,
  TableRow,
  TableRowColumnExtended,
  TableRowColumn,
  Section,
} from "components/layout";
import { Tooltip } from "components/popups";
import { TabbedTable } from "containers/TabbedTable";
import { DefaultSortContext } from "pages/_shared/Context/defaultSortContext";
import { APP_ROUTE_DISTRIBUTION } from "pages/Applications/constants";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { withRouter } from "utils/decorators/withRouter";
import { createObjectWithKeys } from "utils/object";

import { AddToCustomPlanModalData } from "./AddToCustomPlanModal";
import {
  DEFAULT_PLAN_STATUSES,
  DEFAULT_PLAN_STATUSES_NAMES,
  BULK_ACTION,
  BULK_ACTION_SELECT_VALUES,
  BULK_ACTION_INIT_OPTION,
} from "./constants";
import styles from "./DistributionAppListRend.scss";
import { DistributionAppPopupMenu } from "./DistributionAppPopupMenu";
import { WarningsCell } from "./WarningsCell";

const DEFAULT_STATE = {
  manageInCustomPlanAppId: null,
  isModalForManageManyAppsOpen: false,
  selectedApps: {},
};
@withRouter
export class DistributionAppListRend extends Component {
  static propTypes = {
    applications: PropTypes.array,
    count: PropTypes.number,
    loading: PropTypes.bool,
    error: PropTypes.string,
    onApproveForDefPlan: PropTypes.func,
    onRejectForDefPlan: PropTypes.func,
    searchParams: PropTypes.object,
    onChangeSearchParams: PropTypes.func,
    onManageAppsInCustomPlans: PropTypes.func.isRequired,
    onCreateNewCustomDistributionPlan: PropTypes.func.isRequired,
    approveForDefPlanProgress: PropTypes.object.isRequired,
    rejectForDefPlanProgress: PropTypes.object.isRequired,
    getSearchSuggestions: PropTypes.func.isRequired,
    info: PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["normal", "warning", "error"]).isRequired,
    }),
    availableFilters: PropTypes.shape({
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              description: PropTypes.string,
              displayName: PropTypes.string,
              id: PropTypes.string,
              param: PropTypes.string,
              values: PropTypes.array,
            })
          ).isRequired,
        })
      ).isRequired,
    }).isRequired,
    onExport: PropTypes.func.isRequired,

    // from @ExtendedRoute
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    applications: [],
    count: 0,
    loading: false,
    error: "",
    onApproveForDefPlan: Function.prototype,
    onRejectForDefPlan: Function.prototype,
    searchParams: {},
    onChangeSearchParams: Function.prototype,
  };

  static tabsConfig = [
    {
      dataTestId: "app-distribution-list",
      columns: [
        { dataTestId: "column-header-checkbox" },
        {
          label: trans.APP_LIST__COLUMN_ICON(),
          dataTestId: "column-header-icon",
        },
        {
          label: trans.APP_LIST__COLUMN_NAME(),
          sortBy: "name",
          dataTestId: "column-header-name",
        },
        {
          label: trans.APP_LIST__COLUMN_CATEGORY(),
          sortBy: "category",
          dataTestId: "column-header-category",
        },
        {
          label: (
            <span>
              {trans.APP_LIST__COLUMN_DEFAULT_DISTRIBUTION_LINE_1()}
              <br />
              {trans.APP_LIST__COLUMN_DEFAULT_DISTRIBUTION_LINE_2()}
            </span>
          ),
          dataTestId: "column-header-default-distribution",
        },
        {
          label: trans.APP_LIST__COLUMN_WARNINGS(),
          dataTestId: "column-header-warnings",
          className: styles.warningsColumnHeader,
        },
        { label: "", dataTestId: "column-header-action-menu" },
      ],
    },
  ];

  state = { ...DEFAULT_STATE };

  closeManageAppsInCustomPlanModal = (resetApps) => {
    const nextState = {
      manageInCustomPlanAppId: null,
      isModalForManageManyAppsOpen: false,
    };
    if (resetApps) {
      nextState.selectedApps = {};
    }

    this.setState(nextState);
  };

  openManageAppInCustomPlanModal = (appId) => {
    this.setState({
      manageInCustomPlanAppId: appId,
    });
  };

  isChecked = (app) => {
    const { selectedApps } = this.state;
    return Boolean(selectedApps[app.id]);
  };

  toggleMany = (appIds, value) => {
    this.setState((prevState) => ({
      selectedApps: {
        ...prevState.selectedApps,
        ...createObjectWithKeys(appIds, value),
      },
    }));
  };

  handleAppChecked = (e) => {
    const appId = e.target.value;
    this.toggleMany([appId], e.target.checked);
  };

  getCheckedAppIds() {
    const { selectedApps } = this.state;
    return Object.keys(pickBy(selectedApps, (value) => Boolean(value)));
  }

  getAppsForManageModal() {
    const { manageInCustomPlanAppId, isModalForManageManyAppsOpen } =
      this.state;

    let appIds = [];
    if (manageInCustomPlanAppId) {
      appIds = [manageInCustomPlanAppId];
    } else if (isModalForManageManyAppsOpen) {
      appIds = this.getCheckedAppIds();
    }

    return appIds;
  }

  handleBulkAction = (e) => {
    const { onCreateNewCustomDistributionPlan } = this.props;
    const selectedApps = this.getCheckedAppIds();
    if (selectedApps.length === 0) {
      return;
    }

    switch (e.target.value) {
      case BULK_ACTION.MANAGE:
        this.setState({
          isModalForManageManyAppsOpen: true,
        });
        break;
      case BULK_ACTION.CREATE_NEW:
        onCreateNewCustomDistributionPlan(selectedApps);
        break;
    }
  };

  renderBulkActionButtons() {
    const { applications, loading, error } = this.props;

    const selectedCount = this.getCheckedAppIds().length;
    const areAllSelected = applications.every(this.isChecked);
    const appIds = applications.map((app) => app.id);

    if (error) {
      return null;
    }

    return (
      <div>
        <Checkbox
          name="distribution-apps-list-check-all"
          checked={areAllSelected}
          value={areAllSelected}
          onChange={() => this.toggleMany(appIds, !areAllSelected)}
          disabled={loading}
          className={styles.checkAll}
          dataTestId="distribution-apps-list-check-all"
        />
        <div className={styles.selectedItemsText}>
          <span className={styles.selectedItemsCount}>{selectedCount}</span>
          {trans.ITEMS_SELECTED_MANY()}
        </div>
        <Select
          name="distribution-apps-list-bulk-action"
          values={BULK_ACTION_SELECT_VALUES.map((value) => ({
            ...value,
            disabled: selectedCount === 0,
          }))}
          initOption={BULK_ACTION_INIT_OPTION}
          onChange={this.handleBulkAction}
          size="compact"
          className={classes(styles.bulkActionSelect, {
            [styles.bulkActionSelectDisabled]: selectedCount === 0,
          })}
          value=""
        />
      </div>
    );
  }

  render() {
    const {
      applications,
      count,
      loading,
      error,
      onApproveForDefPlan,
      onRejectForDefPlan,
      searchParams,
      onChangeSearchParams,
      approveForDefPlanProgress,
      rejectForDefPlanProgress,
      onManageAppsInCustomPlans,
      onCreateNewCustomDistributionPlan,
      info,
      availableFilters,
      onExport,
      getSearchSuggestions,
      location,
    } = this.props;

    const appsForManageModal = this.getAppsForManageModal();
    const locationMessage = get(location, "state.message");

    return (
      <div>
        <AddToCustomPlanModalData
          isOpen={appsForManageModal.length > 0}
          onClose={this.closeManageAppsInCustomPlanModal}
          onSubmit={onManageAppsInCustomPlans}
          selectedApplications={appsForManageModal}
        />
        <Section>
          <SearchBarWithFilters
            filtersDataChoices={availableFilters}
            onSearch={onChangeSearchParams}
            searchParams={searchParams}
            suggestionApiEndpoint={getSearchSuggestions}
          />
        </Section>
        <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
        <Section>{info && <Info type={info.type}>{info.text}</Info>}</Section>
        <Section>
          {this.renderBulkActionButtons()}
          <DefaultSortContext.Consumer>
            {(defaultOrder) => (
              <TabbedTable
                tabsConfig={DistributionAppListRend.tabsConfig}
                data={{
                  results: applications,
                  count: count,
                  loading: loading,
                  error: error,
                  defaultOrder: defaultOrder,
                }}
                onExport={onExport}
                renderTableBody={(data) => {
                  return (
                    <TableBody>
                      {data.map((app) => {
                        return (
                          <TableRow key={app.id}>
                            <TableRowColumn>
                              <Checkbox
                                name={`app-checkbox-${app.id}`}
                                onChange={this.handleAppChecked}
                                value={app.id}
                                checked={this.isChecked(app)}
                              />
                            </TableRowColumn>
                            <TableRowColumnExtended icon={app.icon} />
                            <TableRowColumnExtended
                              text={app.name}
                              to={`/applications/${APP_ROUTE_DISTRIBUTION}/${app.id}/details`}
                              className={styles.nameColumn}
                            />
                            <TableRowColumnExtended text={app.category} />
                            <TableRowColumn
                              className={classes({
                                [styles.pending]:
                                  app.defaultPlanStatus ===
                                  DEFAULT_PLAN_STATUSES.pending,
                                [styles.approved]:
                                  app.defaultPlanStatus ===
                                  DEFAULT_PLAN_STATUSES.approved,
                                [styles.rejected]:
                                  app.defaultPlanStatus ===
                                  DEFAULT_PLAN_STATUSES.rejected,
                              })}
                            >
                              <span className={styles.planStatusCell}>
                                {DEFAULT_PLAN_STATUSES_NAMES[
                                  app.defaultPlanStatus
                                ] || NO_VALUE_PLACEHOLDER}
                              </span>
                              {app.defaultPlanStatusInconsistent && (
                                <Tooltip
                                  content={trans.APP_LIST_DISTRIBUTION__STATUS_INCONSISTENT()}
                                  alignment="bottom-end"
                                >
                                  <GrabsonIcon
                                    name="pending"
                                    className={styles.statusIcon}
                                    font="micro"
                                  />
                                </Tooltip>
                              )}
                            </TableRowColumn>
                            <TableRowColumn>
                              <WarningsCell app={app} />
                            </TableRowColumn>
                            <TableRowColumn>
                              <DistributionAppPopupMenu
                                app={app}
                                onApproveForDefPlan={onApproveForDefPlan}
                                onRejectForDefPlan={onRejectForDefPlan}
                                approveForDefPlanProgress={
                                  approveForDefPlanProgress
                                }
                                rejectForDefPlanProgress={
                                  rejectForDefPlanProgress
                                }
                                openManageAppInCustomPlanModal={
                                  this.openManageAppInCustomPlanModal
                                }
                                onCreateNewCustomDistributionPlan={
                                  onCreateNewCustomDistributionPlan
                                }
                              />
                            </TableRowColumn>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  );
                }}
              />
            )}
          </DefaultSortContext.Consumer>
        </Section>
      </div>
    );
  }
}
