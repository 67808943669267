// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { UPLOAD_STATUS_PROP_TYPES } from "./constants";

export const FormatPropType = PropTypes.arrayOf(PropTypes.string);

export const AwsUploaderChildrenProps = PropTypes.shape({
  status: PropTypes.oneOf(UPLOAD_STATUS_PROP_TYPES),
  /**
   * Cancel button handler:
   *
   * Type: `() => void`
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * Callback invoked when user picks a file
   *
   * Type: `(event: Event) => void`
   */
  onUploadFile: PropTypes.func.isRequired,
  /**
   * File upload progress percentage when uploader is still uploading
   */
  progressPercentage: PropTypes.number,
  /*
   * Date when we started uploading current file.
   */
  startDate: PropTypes.instanceOf(Date),
});
