// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

const parseHTML = (htmlStr) => {
  return <span dangerouslySetInnerHTML={{ __html: htmlStr }} />;
};

const adaptFilterSuggestion = (filterSuggestion) => ({
  id: filterSuggestion.id,
  displayName: filterSuggestion.displayName,
  param: parseHTML(filterSuggestion.param),
  description: parseHTML(filterSuggestion.description),
  isExcludable: filterSuggestion.isExcludable,
  values:
    filterSuggestion.values && filterSuggestion.values.length
      ? filterSuggestion.values
      : [],
});

export const convertSearchSuggestionsForSearchBar = (gqlQueryResult, key) => {
  const filterSuggestions = get(gqlQueryResult, key, null);

  if (filterSuggestions) {
    return {
      sections: filterSuggestions.map((section) => ({
        ...section,
        options: section.options.map(adaptFilterSuggestion),
      })),
    };
  }
  return undefined;
};
