// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withRouter } from "utils/decorators/withRouter";

import { DeviceModelDetailsRend } from "./DeviceModelDetailsRend";

@withRouter
@restrictedArea((props) => {
  const allowed = [ROLES.moderator.moderator];

  if (props.forAdmin) {
    allowed.push(ROLES.administrator.deviceAdmin);
  } else {
    allowed.push(ROLES.distributor.distributionPlanner);
  }

  return {
    allowed,
  };
})
@connect(null, (dispatch, ownProps) => ({
  getDeviceModel: async (id) => {
    let response;

    if (ownProps.forAdmin) {
      response = await dispatch(
        apiThatThrows.getDeviceModel.action({
          params: { id },
        })
      );
    } else {
      response = await dispatch(
        apiThatThrows.getMyDeviceModel.action({
          params: { id },
        })
      );
    }

    return response.results;
  },

  getDeviceFamily: async (id) => {
    let response;

    if (ownProps.forAdmin) {
      response = await dispatch(
        apiThatThrows.getDeviceFamily.action({
          params: { id },
        })
      );
    } else {
      response = await dispatch(
        apiThatThrows.getMyDeviceFamily.action({
          params: { id },
        })
      );
    }

    const { results } = response;
    return results;
  },

  getOrganizationPublicInfo: async (id) => {
    const { results } = await dispatch(
      apiThatThrows.getOrganizationPublicInfo.action({
        params: { organizationId: id },
      })
    );

    return results;
  },
}))
export class DeviceModelDetailsData extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,

    // from @withRouter
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,

    // from @connect
    getDeviceModel: PropTypes.func.isRequired,
    getDeviceFamily: PropTypes.func.isRequired,
    getOrganizationPublicInfo: PropTypes.func.isRequired,
  };

  state = {
    loading: 0,
    error: undefined,
    deviceModel: {},
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.updateData();
    }
  }

  updateData = async () => {
    const {
      params,
      getDeviceModel,
      getDeviceFamily,
      getOrganizationPublicInfo,
    } = this.props;

    this.setState((prevState) => ({ loading: prevState.loading + 1 }));

    try {
      const deviceModel = await getDeviceModel(params.id);
      const deviceFamily = await getDeviceFamily(deviceModel.device_family.id);
      const organization = await getOrganizationPublicInfo(
        deviceFamily.owner.id
      );

      this.setState((prevState) => ({
        deviceModel: {
          name: deviceModel.name,
          distributorName: organization.name,
          familyName: deviceFamily.name,
          slug: deviceModel.slug,
        },
        error: undefined,
        loading: prevState.loading - 1,
      }));
    } catch (err) {
      this.setState((prevState) => ({
        error: err,
        loading: prevState.loading - 1,
      }));
    }
  };

  render() {
    const { deviceModel, error, loading } = this.state;

    return (
      <DeviceModelDetailsRend
        deviceModel={deviceModel}
        error={error}
        loading={Boolean(loading)}
      />
    );
  }
}
