// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { apiThatThrows } from "containers/Request";
import { isVersionValid } from "pages/Applications/_utils";
import { trans } from "src/translations";

import { prepareAppData } from "../../appVersionDataAdapters";

export const withVersions = (ComposedComponent) => {
  @connect(null, (dispatch) => ({
    getAppVersion: (version_id) =>
      dispatch(
        apiThatThrows.getApplicationFullVersion.action({
          params: { version_id },
        })
      ),
  }))
  class WithVersionsWrapper extends Component {
    static propTypes = {
      // from @connect
      getAppVersion: PropTypes.func.isRequired,
    };

    state = {
      loading: true,
      error: null,
      activeVersion: null,
      latestVersion: null,
    };

    refreshVersions = async (application) => {
      try {
        this.setState({
          loading: true,
          error: null,
          activeVersion: null,
          latestVersion: null,
        });

        const { latestVersion, activeVersion } =
          await this.getVersionsData(application);

        this.setState({
          loading: false,
          latestVersion,
          activeVersion,
        });
      } catch (error) {
        this.setState({
          loading: false,
          error: error.message || error,
        });
      }
    };

    getVersionsData = async (application) => {
      const { activeVersionId, latestVersionId } =
        this.getVersionIds(application);
      const shouldRequestActiveVersion =
        activeVersionId && latestVersionId !== activeVersionId;

      let latestVersion;
      let activeVersion;
      if (shouldRequestActiveVersion) {
        [latestVersion, activeVersion] = await Promise.all([
          this.getAppVersion(latestVersionId),
          this.getAppVersion(activeVersionId),
        ]);
      } else {
        latestVersion = await this.getAppVersion(latestVersionId);
        activeVersion = latestVersion;
      }

      return {
        latestVersion: prepareAppData(latestVersion),
        activeVersion: prepareAppData(activeVersion),
      };
    };

    getVersionIds(application) {
      // We have to use versions from application as the qaSummaries endpoint
      // is not available for all user roles
      return {
        activeVersionId: get(application, "active_version.id"),
        latestVersionId: get(application, "last_submitted_version.id"),
      };
    }

    async getAppVersion(versionId) {
      if (versionId === undefined) {
        throw trans.APP_DETAILS_VERSION__UNEXPECTED_ERROR();
      }

      const { getAppVersion } = this.props;
      const { results: version } = await getAppVersion(versionId);

      if (!isVersionValid(version)) {
        throw trans.APP_DETAILS_VERSION__UNEXPECTED_ERROR();
      }

      return version;
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          refreshVersions={this.refreshVersions}
          versionsLoading={this.state.loading}
          versionsError={this.state.error}
          activeVersion={this.state.activeVersion}
          latestVersion={this.state.latestVersion}
        />
      );
    }
  }

  return WithVersionsWrapper;
};
