// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { SearchableListFooter, SearchableList } from "components/elements";
import { Label } from "components/form";
import { Checkbox } from "components/form/elements";
import { Tooltip } from "components/popups";
import { InfiniteData } from "containers/InfiniteData";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import { ITEMS_PER_PAGE } from "./AddToCustomPlanModalData";
import styles from "./PlansList.scss";
import { PlansRow } from "./PlansRow";

export class PlansList extends Component {
  static propTypes = {
    addedCount: PropTypes.number.isRequired,
    removedCount: PropTypes.number.isRequired,
    getPlanSelectionState: PropTypes.func.isRequired,
    toggleMany: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    mapData: PropTypes.func.isRequired,
    selectedApps: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
  };

  toggleMany = (items, value) => {
    const { toggleMany } = this.props;
    toggleMany(
      items.map((e) => e.id),
      value
    );
  };

  toggleOne = (plan, value) => this.toggleMany([plan], value);

  renderStatusTooltip = () => {
    return (
      <div className={styles.statusTooltip}>
        <h4 className={styles.tooltipHeader}>
          {trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TOOLTIP_HEADER()}
        </h4>
        <div className={styles.tooltipRow}>
          <div className={styles.tooltipCheckbox}>
            <Checkbox checked={true} value={true} onChange={() => {}} />
          </div>
          <p className={styles.tooltipCheckboxDescription}>
            {trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TOOLTIP_CHECKED()}
          </p>
        </div>
        <div className={styles.tooltipRow}>
          <div className={styles.tooltipCheckbox}>
            <Checkbox
              checked={true}
              value={true}
              indeterminate={true}
              onChange={() => {}}
            />
          </div>
          <p className={styles.tooltipCheckboxDescription}>
            {trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TOOLTIP_INDETERMINATE()}
          </p>
        </div>
        <div className={styles.tooltipRow}>
          <div className={styles.tooltipCheckbox}>
            <Checkbox checked={false} value={false} onChange={() => {}} />
          </div>
          <p className={styles.tooltipCheckboxDescription}>
            {trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TOOLTIP_UNCHECKED()}
          </p>
        </div>
      </div>
    );
  };

  isEveryPlanChecked(plans) {
    const { getPlanSelectionState, selectedApps } = this.props;
    return plans.every(
      (p) => getPlanSelectionState(selectedApps, p.id).checked
    );
  }

  renderHeaders = (visibleItems, finishedLoading) => {
    const checked = Boolean(
      finishedLoading && this.isEveryPlanChecked(visibleItems)
    );

    return (
      <div className={styles.headers}>
        <Checkbox
          name="distribution-plans-list-check-all"
          checked={checked}
          value={checked}
          onChange={() => this.toggleMany(visibleItems, !checked)}
          disabled={!finishedLoading}
          className={styles.checkAll}
          dataTestId="distribution-plans-list-check-all"
        />
        <Tooltip
          alignment="bottom"
          content={this.renderStatusTooltip()}
          className={styles.statusHeader}
        >
          <span
            className={classes({
              [styles.statusHeaderDisabled]: !finishedLoading,
            })}
          >
            {trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_STATUS()}
            {" (?)"}
          </span>
        </Tooltip>
        <div className={styles.includedAppHeader}>
          {trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_INCLUDED_APPS()}
        </div>
      </div>
    );
  };

  render() {
    const {
      fetchData,
      mapData,
      className,
      addedCount,
      removedCount,
      selectedApps,
      getPlanSelectionState,
    } = this.props;

    return (
      <Label
        text={trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_HEADER()}
        className={className}
      >
        <InfiniteData
          fetchData={fetchData}
          mapData={mapData}
          itemsPerPage={ITEMS_PER_PAGE}
        >
          {({
            data,
            pending,
            error,
            allDataLoaded,
            handleScroll,
            handleSearchChange,
          }) => (
            <SearchableList
              list={data}
              onScroll={handleScroll}
              onSearchChange={handleSearchChange}
              withLoader={true}
              loading={pending}
              allDataLoaded={allDataLoaded}
              error={error ? error.message : null}
              className={styles.list}
              classNameList={styles.listContent}
              placeholder={trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_PLACEHOLDER()}
              optionsListElement={(visibleItems) =>
                this.renderHeaders(visibleItems, allDataLoaded && !pending)
              }
            >
              {(item) => (
                <PlansRow
                  key={item.id}
                  item={item}
                  getPlanSelectionState={getPlanSelectionState}
                  onChecked={this.toggleOne}
                  selectedApps={selectedApps}
                />
              )}
            </SearchableList>
          )}
        </InfiniteData>
        <SearchableListFooter className={styles.selectedCount}>
          {trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_CHANGED_COUNT({
            addedCount,
            removedCount,
          })}
        </SearchableListFooter>
      </Label>
    );
  }
}
