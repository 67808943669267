// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext } from "react";

import { Button } from "components/buttons";
import { RestrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";
import { useToggle } from "utils/hooks";

import { getManifestQuery } from "../gql";
import { ManifestDataContext } from "../ManifestData";
import { ManifestRemoveModal } from "../ManifestRemoveModal/ManifestRemoveModal";

export const ManifestRemoveSection = () => {
  const { appId, results } = useContext(ManifestDataContext);
  const [isOpen, toggleOpen] = useToggle();

  return (
    <RestrictedArea
      allowed={[ROLES.moderator.appIntegrator]}
      showFallback={false}
    >
      <ManifestRemoveModal
        manifestData={results}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        refetchQuery={{
          query: getManifestQuery,
          variables: { applicationId: appId },
        }}
      />
      {Object.keys(results?.manifest || {}).length > 0 ? (
        <Button onClick={toggleOpen}>
          {trans.APP_DETAILS_MANIFEST__REMOVE_BUTTON()}
        </Button>
      ) : null}
    </RestrictedArea>
  );
};
