// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { restrictedArea, ROLES } from "containers/Permissions";
import { api } from "containers/Request";
import { HtmlTranslation, trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";

import { InviteUserForm } from "./forms/InviteUserForm";

const jsonPointerToFieldName = {
  "/data/attributes/email": "email",
};

@withRouter
@restrictedArea(() => ({
  allowed: [ROLES.organization.roleManager],
}))
@connect(null, (dispatch) => ({
  inviteUserToOrganization: (body) =>
    dispatch(
      api.inviteUserToOrganization.action({
        options: { body },
      })
    ),
}))
export class InviteUserData extends Component {
  static propTypes = {
    // from @connect
    inviteUserToOrganization: PropTypes.func.isRequired,

    // from @withRouter
    navigate: PropTypes.object.isRequired,
  };

  overwriteErrorMessages = (errors) => {
    const NO_ACCOUNT_BACKEND_ERR = "could not find this account in our system";

    const isNoAccountError =
      errors.email &&
      errors.email.toLowerCase().includes(NO_ACCOUNT_BACKEND_ERR.toLowerCase());

    if (isNoAccountError) {
      errors.email = (
        <HtmlTranslation
          transFunc={trans.USER_INVITE__NO_ACCOUNT}
          transParams={{
            cloudUrl: window.location.origin,
          }}
        />
      );
    }

    return errors;
  };

  submit = async ({ email }, { setErrors }) => {
    const { inviteUserToOrganization, navigate } = this.props;
    const { error } = await inviteUserToOrganization(
      createBody({
        type: "invitations",
        email: email,
      })
    );
    if (error) {
      const { _error, email: emailErr } = this.overwriteErrorMessages(
        prepareErrorsForForm(error, jsonPointerToFieldName)
      );
      setErrors({
        _error: _error ?? emailErr,
      });
    } else {
      navigate("/users/list", {
        state: { message: trans.USER_INVITE__SUCCESS() },
      });
    }
  };

  render() {
    return <InviteUserForm submitFunc={this.submit} />;
  }
}
