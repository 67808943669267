import { trans } from "src/translations";

export const SWITCH_LABEL = trans.APP_MANIFESTS_TARGET_TAB_SWITCH_LABEL();

export const APP_ID_FORM_FIELDS = {
  applicationId: "applicationId",
  internalApp: "internalApp",
};

export const MANIFEST_FIELDS = {
  resolution: "resolution",
  defaultBackgroundColor: "defaultBackgroundColor",
  keyRemapping: "keyRemapping",
  custom: "custom",
  manifestError: "manifestError", // this fields is only used to store errors
};

export const tabsConfig = [
  {
    path: "application-id",
    text: "Application",
    dataTestId: "manifest-name-tab",
    validateFields: [APP_ID_FORM_FIELDS.applicationId],
  },
  {
    path: "manifest",
    text: "Manifest",
    dataTestId: "manifest-manifest-tab",
    validateFields: Object.values(MANIFEST_FIELDS),
  },
];
