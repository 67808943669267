// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import Markdown from "components/elements/Markdown/Markdown";
import { GrabsonIcon } from "components/icons";
import { Section, TableRow, TableRowColumn } from "components/layout";
import { Link, Header } from "components/typography";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./MessageDetailsRow.scss";

export class MessageDetailsRow extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    expanded: PropTypes.bool.isRequired,
    setExpanded: PropTypes.func.isRequired,
    contents: PropTypes.string.isRequired,
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        tag: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  };

  static defaultProps = {
    tasks: [],
    devices: [],
  };

  onRowClicked = () => {
    const { setExpanded, id, expanded } = this.props;
    if (!expanded) {
      setExpanded(id, true);
    }
  };

  onCloseClicked = () => {
    const { setExpanded, id, expanded } = this.props;
    if (expanded) {
      setExpanded(id, false);
    }
  };

  renderRelatedTasks() {
    const { tasks } = this.props;
    if (tasks.length === 0) {
      return null;
    }

    return (
      <Section className={styles.TasksList}>
        <Header
          className={styles.ItemsListHeader}
          type="h6"
          uppercase={true}
          color="tertiary"
        >
          {trans.APP_DETAILS_MESSAGES__RELATED_TASKS()}
        </Header>
        <ul>
          {tasks.map((task, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i} className={styles.ListItem}>
              <Link
                href={task.url}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={styles.Tag}
              >
                {task.tag}
              </Link>
            </li>
          ))}
        </ul>
      </Section>
    );
  }

  renderDevices() {
    const { devices } = this.props;
    if (devices.length === 0) {
      return null;
    }

    return (
      <Section className={styles.DevicesList}>
        <Header
          className={styles.ItemsListHeader}
          type="h6"
          uppercase={true}
          color="tertiary"
        >
          {trans.APP_DETAILS_MESSAGES__RELATED_DEVICES()}
        </Header>
        <ul>
          {devices.map((device) => (
            <li key={device.id} className={styles.ListItem}>
              {device.name}
            </li>
          ))}
        </ul>
      </Section>
    );
  }

  getFirstLine(text) {
    const lineBreakIdx = text.indexOf("\n");
    if (lineBreakIdx === -1) {
      return text;
    }
    return text.substring(0, lineBreakIdx);
  }

  renderCollapsed() {
    const { contents, className } = this.props;
    return (
      <TableRow
        className={classes(className, styles.TableRowCollapsed)}
        onClick={this.onRowClicked}
      >
        <TableRowColumn colspan={2}>
          <div
            className={classes(
              styles.MessageContent,
              styles.MessageContentCollapsed
            )}
          >
            <div className={styles.CollapsedText}>
              <Markdown className={styles.MessageMarkdown}>
                {this.getFirstLine(contents)}
              </Markdown>
            </div>
          </div>
        </TableRowColumn>
        <TableRowColumn />
      </TableRow>
    );
  }

  render() {
    const { contents, expanded, className } = this.props;

    if (!expanded) {
      return this.renderCollapsed();
    }

    return (
      <TableRow className={className}>
        <TableRowColumn colspan={2}>
          <div
            className={classes(
              styles.MessageContent,
              styles.MessageContentExpanded
            )}
          >
            <Markdown className={styles.MessageMarkdown}>{contents}</Markdown>
            {this.renderDevices()}
            {this.renderRelatedTasks()}
          </div>
        </TableRowColumn>
        <TableRowColumn columnClass={styles.CloseButtonColumn}>
          <p className={styles.CloseButton} onClick={this.onCloseClicked}>
            <span className={styles.closeButtonText}>{trans.CLOSE()}</span>
            <GrabsonIcon
              name="arrow-up"
              size="sm"
              font="micro"
              className={styles.CloseButtonIcon}
            />
          </p>
        </TableRowColumn>
      </TableRow>
    );
  }
}
