// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const DEVICE_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string.isRequired,
  activationDate: PropTypes.string.isRequired,
  deviceModel: PropTypes.string.isRequired,
  activeAlertsNumber: PropTypes.number.isRequired,
  dismissedAlertsNumber: PropTypes.number.isRequired,
}).isRequired;
