// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const GRPC_ERROR_CODE_ALREADY_EXISTS = 6;

export const IMPORT_ATTRIBUTE_FILE_TYPE_MIME = ["CSV", "text/csv"];
export const IMPORT_ATTRIBUTE_FILE_TYPE_STRING = "csv";
export const MAX_VISIBLE_DEVICES_WITH_ALERT_COUNT = 1000;
