// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { trans } from "src/translations";

const getInfoBox = (type, message) => (
  <Info type={type} dataTestId={`app-name-change-feedback-${type}`}>
    {message}
  </Info>
);

export const AppNameChangeFeedback = ({ status }) => {
  if (status.error) {
    return getInfoBox("error", trans.APP__NAME_CHANGE_ERROR());
  } else if (status.success) {
    return getInfoBox("normal", trans.APP__NAME_CHANGE_SUCCESS());
  }

  return null;
};

AppNameChangeFeedback.propTypes = {
  status: PropTypes.shape({
    error: PropTypes.bool,
    success: PropTypes.bool,
  }),
};
