// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Notification } from "components/elements";
import { TablePagination } from "components/layout";

import { NOTIFICATIONS_PER_PAGE } from "./constants";

export class Notifications extends Component {
  static propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    notificationsCount: PropTypes.number.isRequired,
  };

  render() {
    const { notifications, notificationsCount } = this.props;

    return (
      <div>
        {notifications.map((n) => (
          <Notification key={n.id} {...n} />
        ))}
        <TablePagination
          totalCount={notificationsCount}
          rowsPerPage={NOTIFICATIONS_PER_PAGE}
        />
      </div>
    );
  }
}
