// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const listSoftwarePackagesQuery = gql`
  query softwarePackages($pagination: PaginationInput, $search: SearchInput) {
    softwarePackages(pagination: $pagination, search: $search) {
      softwarePackages {
        items {
          id
          name
          versions {
            id
            version
          }
        }
        pagination {
          count
        }
      }
    }
  }
`;
