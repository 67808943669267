// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { Tabs, TabContent, Tab } from "components/navigation";
import { trans } from "src/translations";

export class LayoutOrganization extends Component {
  static propTypes = {
    children: PropTypes.node,

    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { params } = this.props;

    return (
      <div>
        <PageHeader>
          {trans.ORGANIZATION__ORGANIZATION_PAGE_HEADER()}
        </PageHeader>
        <PageContent>
          <Tabs>
            <Tab
              to={`/organization/${params.id}/details`}
              text={trans.VIEW_DETAILS()}
              dataTestId="details-tab"
            />
            <Tab
              to={`/organization/${params.id}/edit`}
              text={trans.EDIT_DETAILS()}
              dataTestId="edit-tab"
            />
          </Tabs>
          <TabContent>
            <TabContent>{this.props.children}</TabContent>
          </TabContent>
        </PageContent>
      </div>
    );
  }
}
