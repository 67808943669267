// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";
import queryString from "query-string";

import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withRouter } from "utils/decorators";
import { retrieveFiltersFromInclude } from "utils/url";

import { getAllApplications } from "../_utils/getAllApps";
import { PromotionsTable } from "./PromotionsTable";

@withRouter
@restrictedArea((ownProps) => {
  if (ownProps.forAdmin) {
    return {
      allowed: [ROLES.administrator.promotionAdmin],
    };
  }

  return {
    allowed: [ROLES.promotionManagement.promotionManager],
  };
})
@connect(null, (dispatch, ownProps) => ({
  getPromotionsPaginated: async (location) => {
    const fetchPromotionsAction = ownProps.forAdmin
      ? apiThatThrows.getPromotionsPaginated.action
      : apiThatThrows.getMyPromotionsPaginated.action;

    const adaptedQueryParams = retrieveFiltersFromInclude(
      queryString.parse(location.search)
    );
    return await dispatch(
      fetchPromotionsAction({
        queryParams: {
          page: 1,
          ...adaptedQueryParams,
          admin_managed: ownProps.forAdmin ? true : undefined,
          /**
           * See https://bugs.vewd.com/browse/YGG-6188
           * We use query-string with default settings to parse url query params
           * (https://www.npmjs.com/package/query-string) which includes arrays
           * in a form ?param=a&param=b&param=c. This endpoint parses arrays expecting
           * &param=a,b,c and while this can be changed in the query-string options,
           * this breaks other endpoints (e.g. User Catalogue).
           *
           * This is a quick hack to make this work and not break everything.
           */
          include: "promotion_type,promotion_details",
        },
      })
    );
  },
  getApplications: async (appIds, page = 1) => {
    if (appIds.length === 0) {
      return [];
    }

    const { results } = await dispatch(
      apiThatThrows.getApplicationsPublicInfoPaginated.action({
        queryParams: {
          public_id: appIds,
          limit: appIds.length,
          page,
        },
      })
    );

    return results;
  },
}))
export class PromotionsTableData extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,

    // from @withRouter
    location: PropTypes.object.isRequired,

    // from @connect
    getPromotionsPaginated: PropTypes.func.isRequired,
    getApplications: PropTypes.func.isRequired,
  };

  state = {
    promotions: [],
    count: 0,
    error: undefined,
    loading: true,
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.updateData();
    }
  }

  async getAppDetailsFromAppInventory(appsPerPromotions) {
    const { getApplications } = this.props;

    const appIds = uniq(appsPerPromotions.map((a) => a.application_id));

    const apps = await getAllApplications(appIds, getApplications);

    const result = {};
    apps.forEach((app) => {
      result[app.id] = app.name;
    });
    return result;
  }

  adaptApiPromotionsForUi = async (promotions) => {
    const { forAdmin } = this.props;

    const appsPerPromotions = promotions.map(
      (promotion) => promotion.promotion_details
    );

    const appNames = await this.getAppDetailsFromAppInventory(
      appsPerPromotions.flat()
    );

    return promotions.map((promotion, i) => {
      const promotionApps = appsPerPromotions[i];
      const appsToDisplay = promotionApps.map(
        (app) => appNames[app.application_id]
      );

      return {
        id: promotion.id,
        name: promotion.name,
        isReadonly: promotion.admin_managed && !forAdmin,
        appsToDisplay: appsToDisplay.filter((name) => Boolean(name)),
        type: promotion.promotion_type.name,
        campaignsCount: promotion.campaigns.length,
      };
    });
  };

  updateData = async () => {
    const { getPromotionsPaginated, location } = this.props;

    try {
      this.setState({ loading: true, error: undefined });

      const { results, meta } = await getPromotionsPaginated(location);
      const promotions = await this.adaptApiPromotionsForUi(results);

      this.setState({
        promotions: promotions,
        count: meta.count,
      });
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { forAdmin } = this.props;
    const { promotions, count, error, loading } = this.state;

    return (
      <PromotionsTable
        promotions={promotions}
        count={count}
        error={error}
        loading={loading}
        forAdmin={forAdmin}
      />
    );
  }
}
