// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";
import queryString from "query-string";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { api } from "containers/Request";
import { getResult, getPending, getError } from "containers/Request/selectors";
import { withRouter } from "utils/decorators";

import { CAMPAIGN_TYPES } from "../constants";
import { Schedule } from "./Schedule";

@withRouter
@withActiveOrganization
@restrictedArea((ownProps) => {
  if (ownProps.forAdmin) {
    return {
      allowed: [ROLES.administrator.promotionAdmin],
    };
  }

  return {
    allowed: [ROLES.promotionManagement.promotionManager],
  };
})
@connect(
  (state, props) => {
    const searchObj = queryString.parse(props.location.search);
    if (!searchObj.page) {
      searchObj.page = 1;
    }
    searchObj.campaign_type = props.forAdmin ? CAMPAIGN_TYPES.ADMIN : undefined;

    const fetchCampaigns = props.forAdmin
      ? api.getCampaignsPaginated
      : api.getMyCampaignsPaginated;

    return {
      result: getResult(state, fetchCampaigns, null, {
        ...searchObj,
        limit: "nolimit",
      }),
      loading: getPending(state, fetchCampaigns, null, {
        ...searchObj,
        limit: "nolimit",
      }),
      error: getError(state, fetchCampaigns, null, {
        ...searchObj,
        limit: "nolimit",
      }),
    };
  },
  (dispatch, props) => {
    const searchObj = queryString.parse(props.location.search);
    return {
      getCampaigns: () => {
        const fetchCampaignsAction = props.forAdmin
          ? api.getCampaignsPaginated.action
          : api.getMyCampaignsPaginated.action;

        return dispatch(
          fetchCampaignsAction({
            queryParams: {
              ...searchObj,
              limit: "nolimit",
              campaign_type: props.forAdmin ? CAMPAIGN_TYPES.ADMIN : undefined,
            },
          })
        );
      },
    };
  }
)
export class ScheduleData extends Component {
  static propTypes = {
    getCampaigns: PropTypes.func,
    result: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
    location: PropTypes.object,
    forAdmin: PropTypes.bool,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.getCampaigns();
  }

  componentDidUpdate(prevProps) {
    const { location, getCampaigns, activeOrganization } = this.props;
    const hasLocationChanged = location !== prevProps.location;
    const hasOrganizationChanged =
      activeOrganization.id !== prevProps.activeOrganization.id;

    if (hasLocationChanged || hasOrganizationChanged) {
      getCampaigns();
    }
  }

  getData() {
    const { result, error, loading } = this.props;
    const data = get(result, "results", []);

    return {
      campaigns: data.map(ScheduleData.mapCampaign),
      loading: Boolean(loading),
      error: error ? error.message || error : undefined,
    };
  }

  static mapCampaign(campaign) {
    return {
      id: campaign.id,
      label: campaign.name,
      startDate: campaign.start_date,
      endDate: campaign.end_date,
      isEnabled: campaign.is_enabled,
    };
  }

  render() {
    const { forAdmin } = this.props;
    const { campaigns, loading, error } = this.getData();

    return (
      <Schedule
        campaigns={campaigns}
        loading={loading}
        error={error}
        forAdmin={forAdmin}
      />
    );
  }
}
