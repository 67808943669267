// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { ManifestData, ManifestSummary } from "pages/AppManifests/_shared";

import { ManifestEdit } from "./Manifests";

export class ManifestTab extends Component {
  static propTypes = {
    application: PropTypes.object,
  };

  render() {
    const { id, type } = this.props.application;
    return (
      <ManifestData appId={id} appType={type}>
        <ManifestSummary />
        <ManifestEdit />
      </ManifestData>
    );
  }
}
