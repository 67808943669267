// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Subsection } from "components/layout";
import { Permissions } from "containers/Permissions";
import { ROLES } from "containers/Permissions/roles";
import { Text } from "pages/Applications/AppDetails/Certification/Text";
import {
  ApproveModal,
  RejectModal,
} from "pages/Applications/AppDetails/RightsAndContract/CertificationModals";
import { trans } from "src/translations";

export class ContentManagerApproval extends PureComponent {
  static propTypes = {
    qaSummariesId: PropTypes.string.isRequired,
    refreshData: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    isForExaminationOnly: PropTypes.bool.isRequired,
    isApproved: PropTypes.bool.isRequired,
    isRejected: PropTypes.bool.isRequired,
  };

  state = {
    rejectModalOpen: false,
    approveModalOpen: false,
  };

  handleClose = () => {
    this.setState({
      rejectModalOpen: false,
      approveModalOpen: false,
    });
  };

  render() {
    const {
      isApproved,
      isRejected,
      isForExaminationOnly,
      qaSummariesId,
      refreshData,
      userId,
    } = this.props;

    const { approveModalOpen, rejectModalOpen } = this.state;

    let status = trans.APP_DETAILS_RIGHTS__CMA_STATUS_WAITING();
    let tooltip = trans.APP_DETAILS_RIGHTS__CMA_STATUS_TOOLTIP();
    if (isApproved) {
      status = trans.APP_DETAILS_RIGHTS__CMA_STATUS_ACCEPTED();
      tooltip = null;
    } else if (isRejected) {
      status = trans.APP_DETAILS_RIGHTS__CMA_STATUS_REJECTED();
      tooltip = null;
    }

    const isProcessed = isApproved || isRejected;

    return (
      <Fragment>
        <Subsection header={trans.APP_DETAILS_RIGHTS__CMA_HEADER()}>
          <Text text={status} tooltip={tooltip} />

          <Permissions allowed={[ROLES.moderator.contentManager]}>
            {(isAllowedToEdit) => (
              <ButtonsWrapper position={"left"}>
                <Button
                  size="small"
                  type="green"
                  onClick={() => this.setState({ approveModalOpen: true })}
                  disabled={isProcessed || !isAllowedToEdit}
                >
                  {trans.APPROVE()}
                </Button>

                <Button
                  size="small"
                  type="red"
                  onClick={() => this.setState({ rejectModalOpen: true })}
                  disabled={isProcessed || !isAllowedToEdit}
                >
                  {trans.REJECT()}
                </Button>
              </ButtonsWrapper>
            )}
          </Permissions>
        </Subsection>

        {approveModalOpen && (
          <ApproveModal
            qaSummariesId={qaSummariesId}
            isOpen={approveModalOpen}
            onClose={this.handleClose}
            refreshData={refreshData}
            userId={userId}
            isForExaminationOnly={isForExaminationOnly}
          />
        )}

        {rejectModalOpen && (
          <RejectModal
            qaSummariesId={qaSummariesId}
            isOpen={rejectModalOpen}
            onClose={this.handleClose}
            refreshData={refreshData}
            userId={userId}
            isForExaminationOnly={isForExaminationOnly}
          />
        )}
      </Fragment>
    );
  }
}
