// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextField } from "components/form/fields";
import { Section, Row, Column } from "components/layout";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";

import { IconField } from "./fields";
import styles from "./FormBasicInfo.scss";

const jsonPointerToFieldName = {
  "/data/attributes/description": "description",
  "/data/attributes/icon": "icon.error",
  "/data/attributes/name": "title",
};

export class FormBasicInfo extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    onPost: PropTypes.func,
    onPatch: PropTypes.func,
    refreshData: PropTypes.func,
    initialValues: PropTypes.object,
  };

  handleSubmit = async (e, { setErrors }) => {
    const { onPost, onPatch, isEdit, refreshData } = this.props;

    try {
      if (isEdit) {
        await onPatch(e);
      } else {
        await onPost(e);
      }
    } catch (err) {
      setErrors(prepareErrorsForForm(err, jsonPointerToFieldName));
    }

    refreshData();
  };

  render() {
    const { isEdit, initialValues } = this.props;

    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({ dirty, errors, isSubmitting, values }) => {
          return (
            <Form>
              <Section header={trans.ASSET__BASIC_INFO_HEADER()}>
                <Row>
                  <Column className={styles.columnBasicInfoLeft}>
                    <Row>
                      <TextField
                        name="title"
                        label={trans.ASSET__TITLE_LABEL()}
                        tooltip={trans.ASSET__TITLE_TOOLTIP()}
                        className={styles.titleWrapper}
                      />
                      {isEdit && (
                        <TextField
                          name="id"
                          label={trans.ASSET__ID_LABEL()}
                          tooltip={trans.ASSET__ID_TOOLTIP()}
                          disabled
                          className={styles.idWrapper}
                        />
                      )}
                    </Row>
                    <TextField
                      name="description"
                      label={trans.ASSET__DESCRIPTION_LABEL()}
                      tooltip={trans.ASSET__DESCRIPTION_TOOLTIP()}
                      required={false}
                    />
                  </Column>
                  <Column className={styles.columnBasicInfoRight}>
                    <IconField
                      name="icon"
                      icon={values.icon}
                      required={false}
                    />
                  </Column>
                </Row>
                {errors._error && (
                  <Info type="error" className={styles.error}>
                    {errors._error}
                  </Info>
                )}
                <ButtonsWrapper>
                  <Button
                    buttonType="submit"
                    type="green"
                    processing={isSubmitting}
                    disabled={
                      isSubmitting ||
                      !dirty ||
                      Boolean(Object.entries(errors).length)
                    }
                    className={styles.button}
                  >
                    {isEdit ? trans.SAVE() : trans.CREATE()}
                  </Button>
                </ButtonsWrapper>
              </Section>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
