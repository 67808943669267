// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { ALERT_STATES } from "./constants";

export const DeviceAttributePropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const DevicePackagePropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
}).isRequired;

export const DevicePdidPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  vcc: PropTypes.string.isRequired,
  generatedAt: PropTypes.string.isRequired,
  lastUsedAt: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  alertState: PropTypes.oneOf(Object.values(ALERT_STATES)),
});

export const DevicePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  hardwareId: PropTypes.string.isRequired,
  architecture: PropTypes.string,
  country: PropTypes.string,
  deviceModel: PropTypes.string,
  locale: PropTypes.string,
  totalRam: PropTypes.string,
  totalStorage: PropTypes.string,
  activationDate: PropTypes.instanceOf(Date).isRequired,
  lastActivityDate: PropTypes.instanceOf(Date).isRequired,
  managerAttributes: PropTypes.arrayOf(DeviceAttributePropType).isRequired,
  installedPackages: PropTypes.arrayOf(DevicePackagePropType).isRequired,
  pdids: PropTypes.shape({
    items: PropTypes.arrayOf(DevicePdidPropType).isRequired,
    pagination: PropTypes.shape({ count: PropTypes.number.isRequired }),
  }).isRequired,
  hardwareRevision: PropTypes.string,
  systemSoftwareVersion: PropTypes.string,
  deviceStatus: PropTypes.string,
  userType: PropTypes.string,
  deviceLocation: PropTypes.string,
});
