// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextField } from "components/form/fields";
import { Overlay } from "components/layout";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";

import { PAIRED, WAITING } from "./constants";

const jsonPointerToFieldName = {
  "/data/attributes/profile_hash": "profile_hash",
  "/data/attributes/name": "name",
};
const fieldName = "name";
const fieldDeviceId = "profile_hash";

export class DevicePairingForm extends Component {
  static propTypes = {
    pairDevice: PropTypes.func.isRequired,
    lastPairingStatus: PropTypes.string,
  };

  handleSubmit = async (values, { setStatus, setErrors, resetForm }) => {
    const { pairDevice } = this.props;
    try {
      await pairDevice(values);
      resetForm();
      setStatus(true);
    } catch (err) {
      const submitErrors = prepareErrorsForForm(err, jsonPointerToFieldName);
      setErrors(submitErrors);
    }
  };

  render() {
    const { lastPairingStatus } = this.props;
    return (
      <Formik
        initialValues={{
          [fieldDeviceId]: "",
          [fieldName]: "",
        }}
        onSubmit={this.handleSubmit}
      >
        {({ dirty, errors, isSubmitting, status }) => (
          <Overlay active={isSubmitting}>
            <Form aria-label="form">
              <TextField
                name={fieldName}
                label={trans.DEVICE_PAIRING__CUSTOM_NAME()}
              />
              <TextField
                name={fieldDeviceId}
                label={trans.DEVICE_PAIRING__ID_FOR_DEVELOPERS_DEVICE_ID()}
              />
              <ButtonsWrapper>
                <Button
                  type="green"
                  disabled={
                    isSubmitting ||
                    !dirty ||
                    Boolean(Object.entries(errors).length)
                  }
                  buttonType="submit"
                >
                  {isSubmitting
                    ? trans.DEVICE_PAIRING__BUTTON_PAIRING()
                    : trans.DEVICE_PAIRING__BUTTON_PAIR()}
                </Button>
              </ButtonsWrapper>
              {errors?._error && <Info type="error">{errors?._error}</Info>}
              {status && lastPairingStatus === PAIRED && (
                <Info type="normal">{trans.DEVICE_PAIRING__SUCCESS()}</Info>
              )}
              {status && lastPairingStatus === WAITING && (
                <Info type="normal">{trans.DEVICE_PAIRING__CONFIRM()}</Info>
              )}
            </Form>
          </Overlay>
        )}
      </Formik>
    );
  }
}
