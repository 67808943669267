// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import logo from "images/logo.svg";
import logoName from "images/logoName.svg";

import { isSubItemMatching } from "./_utils/isSubItemMatching";
import { menuConfig } from "./config";
import { MainItem } from "./MainItem";
import { MenuSwitcher } from "./MenuSwitcher/MenuSwitcher";
import styles from "./sideMenu.scss";
import { SubItem } from "./SubItem";

/**
 * Component that renders the left sidemenu. Configuration is read from
 * `src/containers/Layout/SideMenu/config.js`.
 *
 * <br/><br/><br/>
 * # MANIPULATING THIS EXAMPLE MAY BREAK STYLEGUIDE URL.
 * <br/><br/><br/>
 */

export const SideMenu = ({ collapsed, dataTestId }) => {
  const location = useLocation();

  const isMainItemActive = (item) => location.pathname.startsWith(item.path);

  const isSubItemActive = (subItem) =>
    isSubItemMatching(location.pathname, subItem.isActiveForPaths);

  return (
    <div
      data-test-id={dataTestId}
      className={collapsed ? styles.collapse : styles.expand}
    >
      <a href="/" className={styles.logoImage}>
        <img src={collapsed ? logo : logoName} />
      </a>
      <ul className={styles.menu}>
        {menuConfig().map((mainItem) => (
          <MainItem
            key={mainItem.path}
            collapsed={collapsed}
            isActive={isMainItemActive(mainItem)}
            {...mainItem}
          >
            {mainItem.items &&
              mainItem.items.map((subItem) => (
                <SubItem
                  key={subItem?.path ?? subItem.href}
                  collapsed={collapsed}
                  isActive={isSubItemActive(subItem)}
                  {...subItem}
                />
              ))}
          </MainItem>
        ))}
      </ul>
      <MenuSwitcher collapsed={collapsed} className={styles.menuSwitcher} />
    </div>
  );
};

SideMenu.propTypes = {
  collapsed: PropTypes.bool,
  dataTestId: PropTypes.string,
};

SideMenu.defaultProps = {
  dataTestId: "side-menu",
};
