// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const errorPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    message: PropTypes.string.isRequired,
    isForbidden: PropTypes.bool,
    isNotFound: PropTypes.bool,
  }),
]);
