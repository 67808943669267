// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TextOverflowPopup } from "components/popups";
import { Header } from "components/typography";
import { classes } from "utils/classes";

import styles from "./PageHeader.scss";

/**
 * Page header component. Mainly used with [PageContent](#pagecontent).
 */
export const PageHeader = ({ children, className }) => (
  <div className={classes(styles.container, className)}>
    <Header type="h2" bold={true}>
      {typeof children === "string" ? (
        <TextOverflowPopup>{children}</TextOverflowPopup>
      ) : (
        children
      )}
    </Header>
  </div>
);

PageHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
