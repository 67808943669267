// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getDeviceQuery = gql`
  query device($id: String!) {
    device(deviceId: $id) {
      device {
        id
        activationDate
        lastActivityDate
        manufacturer
        hardwareId
        architecture
        country
        registerCountry
        deviceModel
        locale
        totalRam
        totalStorage
        currentUpdateJob {
          updateJobId
          name
          lastContactDate
          status
        }
        installedPackages {
          name
          version
          id
          versionId
        }
        platformComponents {
          name
          version
          id
          versionId
        }
        registeredComponents {
          name
          version
          id
          versionId
        }
        managerAttributes {
          key
          value
        }
        provisioningProtocolVersion
        supportedSignatureAlgorithms
        vcc
        pdids {
          items {
            id
            isCurrent
            lastUsedAt
            generatedAt
            alertState
            vcc
          }
          pagination {
            count
          }
        }
        hardwareRevision
        systemSoftwareVersion
        deviceStatus
        deviceLocation
        userType
        erpNumbers
        generic1
        generic2
        generic3
        generic4
        generic5
        generic6
        generic7
        generic8
        generic9
        generic10
        activeAlertsNumber
        dismissedAlertsNumber
      }
    }
  }
`;

export const getDeviceFragmentQuery = gql`
  fragment device on DeviceType {
    id
    lastActivityDate
    activationDate
    deviceModel
    activeAlertsNumber
    dismissedAlertsNumber
  }
`;

export const getDevicePdidsFragmentQuery = gql`
  fragment device on DeviceType {
    pdids {
      items {
        id
        isCurrent
        lastUsedAt
        generatedAt
        alertState
        vcc
      }
      pagination {
        count
      }
    }
  }
`;

export const getDeviceUpdateJobFragmentQuery = gql`
  query device($id: String!) {
    device(deviceId: $id) {
      device {
        id
        currentUpdateJob {
          updateJobId
          status
          lastContactDate
          name
        }
      }
    }
  }
`;
