// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { FieldArray } from "formik";
import PropTypes from "prop-types";

import { ValidationBadge } from "components/form/ValidationBadge/ValidationBadge";

import { ForAdminContext } from "../../../AdminCampaignForm/context";
import PromotionsTable from "./PromotionsTable";

export const FIELD_NAME = "promotions";

export class PromotionsField extends Component {
  static propTypes = {
    isReadonly: PropTypes.bool,
  };

  static contextType = ForAdminContext;

  renderFields = ({
    move,
    push,
    remove,
    form: { values, setFieldValue, getFieldMeta },
  }) => {
    const forAdmin = this.context;
    const { isReadonly } = this.props;
    const { error, touched } = getFieldMeta(FIELD_NAME);

    return (
      <>
        <PromotionsTable
          promotionIds={values.promotions}
          forAdmin={forAdmin}
          isReadonly={isReadonly}
          move={move}
          push={push}
          remove={remove}
          name={FIELD_NAME}
          setFieldValue={setFieldValue}
        />
        <ValidationBadge error={error} touched={touched} />
      </>
    );
  };

  render() {
    return <FieldArray name={FIELD_NAME} component={this.renderFields} />;
  }
}
