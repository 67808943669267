// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import set from "lodash-es/set";

import { urlToBase64 } from "utils/converters";

import { CHANGELOG_TYPES } from "../constants";

const processUrl = async (oldUrl) => {
  if (typeof oldUrl === "string" && oldUrl.indexOf("blob:") === 0) {
    try {
      return await urlToBase64(oldUrl);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  return oldUrl;
};

export const beforeSave = async (values) => {
  await Promise.all(
    ["icon_small", "icon_big", "thumbnail", "billboard"].map(
      async (imgName) => {
        const oldUrl = values?.metadata?.[imgName]?.url;
        const newUrl = await processUrl(oldUrl);
        set(values, `metadata.${imgName}`, newUrl);
      }
    )
  );

  const oldScreenshots = values?.metadata?.screenshot ?? [];

  await Promise.all(
    oldScreenshots.map(async (image, i) => {
      const newUrl = await processUrl(image.url);
      set(values, `metadata.screenshot[${i}].url`, newUrl);
    })
  );

  // backend requires changelog even for the first version of application
  if (!values?.changelog?.change_type) {
    values.changelog = {
      change_type: CHANGELOG_TYPES.INITIAL,
      additional_information: "",
    };
  }
  return values;
};
