// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import queryString from "query-string";

import { restrictedArea } from "containers/Permissions";
import { ALL_DISTRIBUTION_PLANNERS } from "containers/Permissions/groups";
import { api } from "containers/Request";
import { withRouter } from "utils/decorators";

import { CustomPlansRend } from "./CustomPlansRend";

@restrictedArea(() => {
  return {
    allowed: ALL_DISTRIBUTION_PLANNERS,
  };
})
@withRouter
@connect(null, (dispatch) => ({
  getPlansPaginated: (location) =>
    dispatch(
      api.getMyDistributionPlansPaginated.action({
        queryParams: {
          page: 1,
          ...queryString.parse(location.search),
        },
      })
    ),
}))
export class CustomPlansData extends Component {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object.isRequired,

    // from @connect
    getPlansPaginated: PropTypes.func.isRequired,
  };

  state = {
    results: [],
    count: 0,
    error: undefined,
    loading: true,
  };

  async componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.updateData();
    }
  }

  async updateData() {
    const { getPlansPaginated, location } = this.props;

    this.setState({ loading: true });

    const plansRequest = await getPlansPaginated(location);

    if (plansRequest.error) {
      this.setState({ error: plansRequest.error, loading: false });
      return;
    }

    const plansResults = plansRequest.result.results;
    const meta = plansRequest.result.meta;

    const results = plansResults.map((plan) => ({
      id: plan.id,
      name: plan.label,
      appsCount: plan.applications.length,
    }));

    this.setState({
      results: results,
      count: meta.count,
      error: undefined,
      loading: false,
    });
  }

  render() {
    const { results, count, error, loading } = this.state;

    return (
      <CustomPlansRend
        results={results}
        count={count}
        error={error}
        loading={loading}
      />
    );
  }
}
