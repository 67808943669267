// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { PDF } from "components/elements";
import { Info } from "components/feedback";
import { Label } from "components/form";
import { CheckboxField } from "components/form/fields";
import { Header, Link } from "components/typography";
import {
  organizationsPropTypes,
  profilePropTypes,
} from "containers/Auth/propTypes";
import { PDFViewer } from "containers/PDFViewer";
import { ORGANIZATION_PLAN } from "pages/Organization/constants";
import { trans, HtmlTranslation } from "src/translations";
import { isSignatoryUser } from "src/utils/user";

/*
 * When either Certiﬁcation Program or Linking Agreement is updated, please also
 * update AGREEMENT_VERSION in src/pages/Applications/EditApp/constants.js.
 */
import {
  DISTRIBUTION_TYPES,
  UPGRADE_TO_PREMIUM_ORG_MAIL,
} from "../../constants";
import blurStyle from "../../utils/blur.scss";
import { HAS_REVENUE } from "../Monetization";
import certificationProgram from "./certification-program-24-02.pdf";
import styles from "./Contracts.scss";
import linkingAgreement from "./linking-agreement-24-02.pdf";

@connect(({ auth }) => ({
  organizations: auth.organizations,
  currentUser: auth.profile,
}))
export class Contracts extends Component {
  static propTypes = {
    distributeInTvStoreType: PropTypes.string.isRequired,
    revenueOption: PropTypes.string,
    //from @Formik
    setFieldTouched: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    // from @connect
    organizations: organizationsPropTypes,
    currentUser: profilePropTypes,
  };

  showRevenueWarning = () => {
    const { organizations, currentUser, revenueOption } = this.props;

    const activeOrganization = organizations[currentUser.activeOrganizationId];
    const isPremium =
      activeOrganization.organizationPlan === ORGANIZATION_PLAN.Premium;
    const isDirectRevenue = revenueOption === HAS_REVENUE;

    return !isPremium && isDirectRevenue;
  };

  renderDocuments = () => {
    const { distributeInTvStoreType } = this.props;

    return (
      <div>
        {distributeInTvStoreType ===
          DISTRIBUTION_TYPES.VEWD_CERTIFICATION_PROGRAM && (
          <PDFViewer
            className={styles.pdfViewer}
            url={certificationProgram}
            text={trans.DOWNLOAD_AS_PDF()}
            fileName="Vewd Certification Program"
            dataTestId="certification-pdf"
          >
            <PDF className={styles.pdf} url={certificationProgram} />
          </PDFViewer>
        )}
        {distributeInTvStoreType === DISTRIBUTION_TYPES.VEWD_CATALOGUE && (
          <PDFViewer
            className={styles.pdfViewer}
            url={linkingAgreement}
            text={trans.DOWNLOAD_AS_PDF()}
            fileName="Linking Agreement"
            dataTestId="linking-agreement-pdf"
          >
            <PDF className={styles.pdf} url={linkingAgreement} />
          </PDFViewer>
        )}
      </div>
    );
  };

  renderAgreements = () => {
    const { distributeInTvStoreType, setFieldTouched, handleChange } =
      this.props;

    return (
      <Fragment>
        <Label
          text={`${trans.APP_EDIT_CERTIFICATION__AGREEMENT()}:`}
          tooltip={trans.APP_EDIT_CERTIFICATION__AGREEMENT_TOOLTIP()}
        />

        <CheckboxField
          name="metadata.terms_accepted"
          className={styles.checkbox}
          label={trans.APP_EDIT_CERTIFICATION__ACCEPT_TERMS_AND_CONDITIONS()}
          dataTestId="terms-and-conditions-field"
          onChange={(e) => {
            setFieldTouched(e.target.name);
            handleChange(e);
          }}
        />
        {distributeInTvStoreType === DISTRIBUTION_TYPES.VEWD_CATALOGUE && (
          <>
            <CheckboxField
              name="metadata.accepted_criteria"
              className={styles.checkbox}
              dataTestId="acceptance-criteria-field"
              onChange={(e) => {
                setFieldTouched(e.target.name);
                handleChange(e);
              }}
            >
              <span>
                {trans.APP_EDIT_CERTIFICATION__I_VE_READ()}{" "}
                <Link target="_blank" href="/guidelines/#acceptance-criteria">
                  {trans.APP_EDIT_CERTIFICATION__ACCEPTANCE()}
                </Link>{" "}
                {trans.APP_EDIT_CERTIFICATION__CRITERIA()}
              </span>
            </CheckboxField>

            {this.showRevenueWarning() ? (
              <Info
                type="warning"
                className={styles.revenueWarning}
                dataTestId="monetization-revenue-warning-field"
              >
                <HtmlTranslation
                  transFunc={trans.APP_EDIT_MARKETS__NON_PREMIUM_WARNING}
                  transParams={{
                    link: (
                      <Link href={`mailto:${UPGRADE_TO_PREMIUM_ORG_MAIL}`}>
                        {UPGRADE_TO_PREMIUM_ORG_MAIL}
                      </Link>
                    ),
                  }}
                />
              </Info>
            ) : null}
          </>
        )}
      </Fragment>
    );
  };

  render() {
    const { distributeInTvStoreType, organizations, currentUser } = this.props;

    if (distributeInTvStoreType === DISTRIBUTION_TYPES.EXAMINE) {
      return null;
    }
    const signatoryUser = isSignatoryUser(organizations, currentUser);

    return (
      <div className={!signatoryUser ? blurStyle.blur : ""}>
        <Header
          className={styles.header}
          uppercase={true}
          bold={true}
          color="secondary"
        >
          {trans.APP_EDIT_CERTIFICATION__TERMS_SECTION_NAME()}
        </Header>
        {this.renderDocuments()}
        <div className={styles.agreementContainer}>
          {this.renderAgreements()}
        </div>
      </div>
    );
  }
}
