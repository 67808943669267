// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect } from "react";

import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";

import { RangeInput } from "components/form/elements";
import { trans } from "src/translations";

import { calculateScore } from "../../../_utils";

const FIELD_NAME = "score";

export const ScoreField = ({ disabled = false }) => {
  const {
    values: {
      targets,
      score,
      scope,
      is_score_autogenerated: isScoreAutogenerated,
    },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (isScoreAutogenerated) {
      setFieldValue(FIELD_NAME, calculateScore(targets, scope));
    }
  }, [score, targets, scope, setFieldValue, isScoreAutogenerated]);

  return (
    <Field name={FIELD_NAME} disabled={disabled}>
      {({ field, meta }) => (
        <RangeInput
          {...field}
          {...meta}
          label={trans.CAMPAIGN_EDIT__FIELD_SCORE()}
          required={true}
          disabled={disabled}
          dataTestId="campaign-score"
        />
      )}
    </Field>
  );
};

ScoreField.propTypes = {
  disabled: PropTypes.bool,
};
