// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import cloneDeep from "lodash-es/cloneDeep";

import { HOME_ID, HOME_SECTION } from "pages/Snap/shared/constants";
import { urlToBase64 } from "utils/converters";
import { initialFile } from "utils/formik/utils";

const imagesToBase64 = async (image) => {
  if (image.indexOf("blob:") !== 0) {
    return image;
  }
  return await urlToBase64(image);
};

const parseSections = (sections) => {
  const parsed = sections.map((section) => ({
    show: true,
    ...section,
    icon: section.icon.toLowerCase(),
  }));

  parsed.sort((section1, section2) => {
    return section1.order - section2.order;
  });

  if (
    !parsed[HOME_ID] ||
    parsed[HOME_ID].name !== "Home" ||
    parsed[HOME_ID].live === true
  ) {
    parsed.unshift(cloneDeep(HOME_SECTION));
  }
  return parsed;
};

const indexOfLang = (code, languages) =>
  languages.findIndex((lang) => lang.code === code);

const parseInitialSnapData = (revision, languages) => {
  const translations = revision.translations.map((translation) => ({
    application_title: translation.application_title,
    default_translation: translation.language === revision.default_lang,
    language: translation.language,
    sections: parseSections(translation.sections),
  }));
  if (languages.length) {
    translations.sort(
      (trans1, trans2) =>
        indexOfLang(trans1.language, languages) -
        indexOfLang(trans2.language, languages)
    );
  }
  delete revision.appName;
  return {
    ...revision,
    background: initialFile(revision.app_background_url, "background", 0),
    logo: initialFile(revision.app_logo_url, "logo", 0),
    ads_enabled: `${revision.ads_enabled}`,
    autodarken_background: `${revision.autodarken_background}`,
    categories_from_tags: `${revision.categories_from_tags}`,
    translations,
  };
};

const adaptDataToRequest = async (values) => {
  const queryParams = Object.assign({}, values);
  const default_lang = queryParams.translations.find(
    (translation) => translation.default_translation
  ).language;

  if (values["categories_from_tags"] === "false") {
    queryParams["keyword_prefix"] = "";
  }

  if (queryParams["logo"] && queryParams["logo"].url) {
    queryParams.app_logo_url = await imagesToBase64(queryParams["logo"].url);
  } else {
    queryParams.app_logo_url = "";
  }

  if (queryParams["background"] && queryParams["background"].url) {
    queryParams.app_background_url = await imagesToBase64(
      queryParams["background"].url
    );
  } else {
    queryParams.app_background_url = "";
  }

  queryParams["default_lang"] = default_lang;
  queryParams.translations = queryParams.translations.map(
    ({ language, sections, application_title }) => ({
      application_title,
      language,
      sections: sections
        .filter((section) => section.show)
        .map((section, index) => {
          const adaptSection = Object.assign({}, section);
          if (adaptSection.live_feeds && !adaptSection.live_feeds.length) {
            delete adaptSection.live_feeds;
          }
          delete adaptSection.show;
          adaptSection.icon = adaptSection.icon.toUpperCase();
          return {
            order: index,
            ...adaptSection,
          };
        }),
    })
  );
  delete queryParams.logo;
  delete queryParams.background;
  return queryParams;
};

const adaptSnapToCloudBody = (cloudAppId, appUrl, snapValues, cloudValues) => {
  const { translations } = snapValues;
  let draftData;
  if (cloudValues) {
    // if there is data from cloud, use it and apply snap changes
    draftData = cloudValues;
    if (draftData.changelog) {
      delete draftData.changelog;
    }
    draftData.translations = draftData.translations.map((translation) => ({
      ...translation,
      is_default: false,
    }));
    draftData.application_url = appUrl;
    translations.forEach((item) => {
      const idx = draftData.translations.findIndex(
        (translation) => translation.language === item.language
      );
      if (idx > -1) {
        // existing translation
        draftData.translations[idx].app_title = item.application_title;
        draftData.translations[idx].is_default = item.default_translation;
      } else {
        // new translation
        draftData.translations.push({
          language: item.language,
          app_title: item.application_title,
          app_short_description: "",
          is_default: item.default_translation,
        });
      }
    });
  } else {
    draftData = {
      application_url: appUrl,
      translations: translations.map(
        ({ application_title, default_translation, language }) => ({
          language,
          app_title: application_title,
          app_short_description: "",
          is_default: default_translation,
        })
      ),
    };
  }

  return {
    data: {
      type: "DraftVersionModel",
      attributes: {
        draft: draftData,
      },
      relationships: {
        application: {
          data: {
            type: "ApplicationModel",
            id: cloudAppId,
          },
        },
      },
    },
  };
};

export { adaptDataToRequest, parseInitialSnapData, adaptSnapToCloudBody };
