// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Column, Row } from "components/layout";
import { checkAccess, RestrictedArea, ROLES } from "containers/Permissions";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { capitalize } from "utils/string";

import {
  APP_ENVIRONMENT,
  APP_ENVIRONMENT_STATUS,
  APP_TYPE,
} from "../../AppDetails/constants";
import { APP_TYPES_CONFIG } from "../../constants";
import styles from "./AppEnvironmentStatuses.scss";

const AppEnvironmentStatusType = PropTypes.oneOf(
  Object.values(APP_ENVIRONMENT_STATUS)
);

const AppEnvironmentType = PropTypes.shape({
  status: AppEnvironmentStatusType,
});

export class AppEnvironmentStatuses extends Component {
  static propTypes = {
    appType: PropTypes.oneOf(Object.values(APP_TYPE)).isRequired,
    profiles: PropTypes.shape({
      [APP_ENVIRONMENT.LINUX]: PropTypes.arrayOf(PropTypes.object),
      [APP_ENVIRONMENT.ANDROID]: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    environments: PropTypes.shape({
      [APP_ENVIRONMENT.GENERIC]: AppEnvironmentType,
      [APP_ENVIRONMENT.APP_STORE]: AppEnvironmentType,
      [APP_ENVIRONMENT.HBBTV]: AppEnvironmentType,
      [APP_ENVIRONMENT.LINUX]: AppEnvironmentType,
      [APP_ENVIRONMENT.ANDROID]: AppEnvironmentType,
    }).isRequired,
    // Required only when readOnly=false and user has permissions to approve
    onApprove: PropTypes.func,
    // Required only when readOnly=false and user has permissions to approve
    onReject: PropTypes.func,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    readOnly: true,
    onApprove: () => {
      console.error("onApprove function not provided");
    },
    onReject: () => {
      console.error("onReject function not provided");
    },
  };

  getAppTypeConfigEnvironments = () => {
    const { appType, profiles } = this.props;
    const appTypeConfig = APP_TYPES_CONFIG[this.props.appType];

    if (!appTypeConfig) {
      return [];
    }

    const { environments } = appTypeConfig;

    if (appType !== APP_TYPE.NATIVE) {
      return environments;
    }

    // when app type is native, show only environments that have at least 1 profile
    const availableTypes = Object.entries(profiles)
      .filter(([, envProfiles]) => envProfiles.length > 0)
      .map(([type]) => type);

    return environments.filter(({ type }) => availableTypes.includes(type));
  };

  getUIAppEnvironments = () => {
    const environments = this.getAppTypeConfigEnvironments();

    return environments.map(({ text, type }) => {
      const status = get(
        this.props,
        `environments[${type}].status`,
        NO_VALUE_PLACEHOLDER
      );
      const id = get(this.props, `environments[${type}].id`);
      return {
        id: id,
        name: text,
        status: status,
      };
    });
  };

  render() {
    const { onApprove, onReject, readOnly } = this.props;

    const loading = false; // todo: [kzurek] CS-867 unmock when integration with backend ready

    return (
      <Fragment>
        {this.getUIAppEnvironments().map(({ id, name, status }) => (
          <Row key={id} className={styles.environment}>
            <Column className={styles.nameColumn}>
              {name} -{" "}
              <span className={styles[status.toLowerCase()]}>
                {capitalize(status.toLowerCase())}
              </span>
            </Column>
            <RestrictedArea
              allowed={[
                ({ activeOrganization }) => {
                  const hasAccess = checkAccess({ activeOrganization }, [
                    ROLES.moderator.moderator,
                  ]);

                  return !readOnly && hasAccess;
                },
              ]}
              showFallback={false}
            >
              <Column>
                <ButtonsWrapper>
                  <Button
                    size="small"
                    type="green"
                    disabled={
                      loading || status === APP_ENVIRONMENT_STATUS.APPROVED
                    }
                    onClick={() => onApprove(id)}
                  >
                    {trans.APPROVE()}
                  </Button>
                  <Button
                    size="small"
                    type="red"
                    disabled={
                      loading || status === APP_ENVIRONMENT_STATUS.REJECTED
                    }
                    onClick={() => onReject(id)}
                  >
                    {trans.REJECT()}
                  </Button>
                </ButtonsWrapper>
              </Column>
            </RestrictedArea>
          </Row>
        ))}
      </Fragment>
    );
  }
}
