// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as actions from "./actionTypes";

export const addDeviceRegionsActive = (asset, model, version) => ({
  type: actions.ADD_DEVICE_REGIONS_ACTIVE,
  asset,
  model,
  version,
});

export const addDeviceRegionsInactive = () => ({
  type: actions.ADD_DEVICE_REGIONS_INACTIVE,
});
