// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { TableBody, TableRow, TableRowColumn } from "components/layout";
import { Tooltip } from "components/popups";
import { Header } from "components/typography";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import { DismissAlertModal } from ".";
import { ALERT_STATES } from "../constants";
import { DevicePdidPropType } from "../propTypes";
import styles from "./PdidHistoryTable.scss";

const ITEMS_PER_PAGE = 50;

const TABS_CONFIG = [
  {
    dataTestId: "device-details-pdid-history-list",
    columns: [
      {
        id: "id",
        label: "PDID",
        dataTestId: trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_ID_COLUMN(),
      },
      {
        id: "vcc",
        label: trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_VCC_COLUMN(),
        dataTestId: "vcc-column-header",
      },
      {
        id: "generationDate",
        label:
          trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_GENERATION_DATE_COLUMN(),
        dataTestId: "generation-date-column-header",
      },
      {
        id: "lastUsageDate",
        label: (
          <Fragment>
            {trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_LAST_USAGE_DATE_COLUMN()}
            <GrabsonIcon name="arrow-down" font="normal" />
          </Fragment>
        ),
        dataTestId: "generation-date-column-header",
      },
    ],
  },
];

export class PdidHistoryTable extends PureComponent {
  static propTypes = {
    pdids: PropTypes.arrayOf(DevicePdidPropType).isRequired,
    pdidsCount: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
    isModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    handleDismissAlert: PropTypes.func.isRequired,
    handleActiveAlertClick: PropTypes.func,
  };

  getActiveAlert = (id) => {
    const { handleActiveAlertClick } = this.props;
    return (
      <Tooltip
        content={
          <div>
            <p>
              {trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_ACTIVE_ALERT_TOOLTIP()}
            </p>
            <p className={styles.dismissAlert}>
              <span onClick={handleActiveAlertClick(id)}>
                {trans.DEVICES__DEVICE_DETAILS_TABLE_DISMISS_ALERT_BUTTON()}
              </span>
            </p>
          </div>
        }
        alignment="bottom-end"
        classNamePopup={styles.alertsTooltip}
        dataTestId="pdid-alert-active-tooltip"
      >
        <GrabsonIcon
          name="alert-circle"
          font="normal"
          className={styles.error}
          size="normal"
          dataTestId="pdid-alert-active-row-icon"
        />
      </Tooltip>
    );
  };

  getDismissedAlert = () => {
    return (
      <Tooltip
        content={trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_DISMISSED_ALERT_TOOLTIP()}
        alignment="bottom-end"
        classNamePopup={styles.alertsTooltip}
        dataTestId="pdid-alert-dismissed-tooltip"
      >
        <GrabsonIcon
          name="alert-circle"
          font="normal"
          className={styles.dismissed}
          size="normal"
          dataTestId="pdid-alert-dismissed-row-icon"
        />
      </Tooltip>
    );
  };

  renderRow = (pdid) => (
    <TableRow
      key={pdid.id}
      className={pdid.isCurrent ? styles.currentPdid : styles.greyedOut}
    >
      <TableRowColumn dataTestId="pdid-history-id-column" className={styles.id}>
        {pdid.id}
      </TableRowColumn>
      <TableRowColumn
        dataTestId="pdid-history-vcc-column"
        className={styles.vcc}
      >
        {pdid.vcc}
      </TableRowColumn>
      <TableRowColumn dataTestId="pdid-history-generated-at-column">
        {formatDateTime(new Date(pdid.generatedAt))}
      </TableRowColumn>
      <TableRowColumn
        dataTestId="pdid-history-last-used-at-column"
        className={
          pdid.alertState === ALERT_STATES.ACTIVE ? styles.dateError : ""
        }
      >
        {formatDateTime(new Date(pdid.lastUsedAt))}
        {pdid.alertState === ALERT_STATES.ACTIVE &&
          this.getActiveAlert(pdid.id)}
        {pdid.alertState === ALERT_STATES.DISMISSED && this.getDismissedAlert()}
      </TableRowColumn>
    </TableRow>
  );

  renderTableBody = (rows) => {
    return <TableBody>{rows.map(this.renderRow)}</TableBody>;
  };

  renderTable = (results, count, loading, error) => {
    return (
      <TabbedTable
        data={{ results, count, loading, error }}
        tabsConfig={TABS_CONFIG}
        renderTableBody={this.renderTableBody}
        rowsPerPage={ITEMS_PER_PAGE}
        noResultsMessage={trans.DEVICES__DEVICE_DETAILS_TABLE_NO_PDID_HISTORY_FOUND()}
        legend={
          <div className={styles.legend}>
            <Header
              className={styles.header}
              type="h6"
              uppercase={true}
              color="tertiary"
            >
              {trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_LEGEND_HEADER()}
            </Header>
            <div className={styles.lastGenerated}>
              <div className={styles.box}></div>
              <span
                className={styles.text}
                data-test-id="pdid-history-last-generated-legend-text"
              >
                {trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_LAST_GENERATED_LEGEND()}
              </span>
            </div>
            <div className={styles.previouslyGenerated}>
              <div className={styles.box}></div>
              <span
                className={styles.text}
                data-test-id="pdid-history-previously-generated-legend-text"
              >
                {trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_PREVIOUSLY_GENERATED_LEGEND()}
              </span>
            </div>
          </div>
        }
      />
    );
  };

  render() {
    const {
      pdids,
      pdidsCount,
      loading,
      error,
      isModalOpen,
      handleDismissAlert,
      handleModalClose,
    } = this.props;

    return (
      <Fragment>
        {this.renderTable(pdids, pdidsCount, loading, error)}
        <DismissAlertModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          dismissAlert={handleDismissAlert}
        />
      </Fragment>
    );
  }
}
