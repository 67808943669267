// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import PropTypes from "prop-types";

import { AppRoutes } from "components/AppRoutes";

import { AnalyticsTracker } from "./AnalyticsTracker";
import { ApolloWrapper } from "./ApolloWrapper";

class App extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  };

  render() {
    const { store } = this.props;

    return (
      <Router>
        <ApolloWrapper>
          <Provider store={store}>
            <AnalyticsTracker />
            <AppRoutes />
          </Provider>
        </ApolloWrapper>
      </Router>
    );
  }
}

export default hot(App);
