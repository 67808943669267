// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { SearchableList, SearchableListPanel } from "components/elements";
import { Radio } from "components/form/elements";
import {
  Section,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
} from "components/layout";
import { Header, Link } from "components/typography";
import { InfiniteData } from "containers/InfiniteData";
import { trans, HtmlTranslation } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery } from "utils/graphql";
import { calulateOffsetFromPage } from "utils/pagination";

import styles from "./DevicesQuery.scss";
import { listDeviceFiltersQuery } from "./gql/listDeviceFiltersQuery";

const ITEMS_PER_PAGE = 20;

const adaptDeviceFilter = ({ id, name, devices }) => ({
  id,
  name,
  numberOfDevices: get(devices, "pagination.count"),
});

@withQuery({ name: "deviceFilters", query: listDeviceFiltersQuery })
export class DevicesQuery extends Component {
  static propTypes = {
    // from @withQuery
    deviceFiltersQuery: PropTypes.func,

    selectedQuery: PropTypes.object.isRequired,
    onSelectQuery: PropTypes.func.isRequired,
  };

  fetchDeviceFilters = async (queryParams) => {
    const { deviceFiltersQuery } = this.props;

    const result = await deviceFiltersQuery({
      variables: {
        pagination: {
          offset: calulateOffsetFromPage(queryParams.page, ITEMS_PER_PAGE),
          limit: ITEMS_PER_PAGE,
        },
        search: {
          query: queryParams.search || "",
        },
      },
    });

    const count = get(
      result,
      "deviceFilters.deviceFilters.pagination.count",
      0
    );
    const results = get(result, "deviceFilters.deviceFilters.items", []);

    return {
      meta: { count },
      results: results.map(adaptDeviceFilter),
    };
  };

  getHandleSelectQuery = (item) => () => {
    this.props.onSelectQuery(item);
  };

  renderRow = (item) => {
    const { selectedQuery } = this.props;
    const devicesList = `/devices/filters/${item.id}/devices`;
    return (
      <SearchableListPanel key={item.id} className={styles.row}>
        <Radio
          onChange={this.getHandleSelectQuery(item)}
          value={selectedQuery && selectedQuery.id}
          defaultValue={item.id}
          className={styles.radio}
        />
        <Link target="_blank" href={devicesList} className={styles.link}>
          {item.name}
        </Link>
      </SearchableListPanel>
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <Section
          className={styles.section}
          header={trans.TARGETS_QUERY_AND_FILTER()}
        >
          <Header type="h4" bold={true}>
            <HtmlTranslation
              className={styles.header}
              transFunc={trans.TARGETS_QUERY_AND_FILTER_NOTICE}
              transParams={{
                deviceInventoryLink: (
                  <Link target="_blank" href="/devices/inventory">
                    {trans.DEVICES__DEVICE_INVENTORY_ROUTE_NAME()}
                  </Link>
                ),
              }}
            />
          </Header>

          <div>{trans.TARGETS_QUERY_AND_FILTER_DESCRIPTION()}</div>
          <InfiniteData
            fetchData={this.fetchDeviceFilters}
            itemsPerPage={ITEMS_PER_PAGE}
          >
            {({
              data,
              pending,
              error,
              allDataLoaded,
              handleScroll,
              handleSearchChange,
            }) => (
              <Table className={styles.table}>
                <TableBody>
                  <SearchableList
                    dataTestId="add-target-query-list"
                    withLoader={true}
                    list={data}
                    searchBy="name"
                    onSearchChange={handleSearchChange}
                    loading={pending}
                    allDataLoaded={allDataLoaded}
                    error={error && getGqlErrorMessage(error)}
                    placeholder={trans.SEARCH()}
                    onScroll={handleScroll}
                    optionsListElement={() => (
                      <TableHeader>
                        <TableHeaderColumn className={styles.tableHeaderColumn}>
                          {trans.QUERY_NAME()}
                        </TableHeaderColumn>
                      </TableHeader>
                    )}
                  >
                    {(item) => this.renderRow(item)}
                  </SearchableList>
                </TableBody>
              </Table>
            )}
          </InfiniteData>
        </Section>
      </div>
    );
  }
}
