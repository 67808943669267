// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import styles from "./DeviceQueries.scss";

export const tabConfig = [
  {
    id: "",
    dataTestId: "device-queries-list",
    label: "",
    columns: [
      {
        id: "name",
        label: trans.DEVICES__DEVICE_FILTERS_TABLE_FILTER_NAME(),
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "filter_quantity",
        label: trans.DEVICES__DEVICE_FILTERS_TABLE_FILTER_QUANTITY(),
        sortBy: "filter_quantity",
        dataTestId: "filter-quantity-column-header",
        className: styles.statusColumn,
      },
      {
        id: "creation_date",
        label: trans.DEVICES__DEVICE_FILTERS_TABLE_CREATION_DATE(),
        sortBy: "creation_date",
        dataTestId: "creation-date-column-header",
        className: styles.dateColumn,
      },
      {
        id: "actions",
        label: "",
        dataTestId: "actions-column-header",
        className: styles.optionsColumn,
      },
    ],
  },
];
