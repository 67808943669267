import { addQueryParamsToLocation, getQueryParamValue } from "utils/url";

import { APP_TYPE } from "../../Applications/AppDetails/constants";

export const createTabPath = (tabId) => {
  return addQueryParamsToLocation(location, {
    tab: tabId,
  });
};

export const getActiveTabId = (appType) => {
  const tabId = getQueryParamValue(location, "tab");
  const defaultTab = appType === APP_TYPE.NATIVE ? "custom" : "generic";
  return tabId ? tabId : defaultTab;
};
