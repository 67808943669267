import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { api } from "containers/Request";
import { isVersionValid } from "pages/Applications/_utils";
import { DEFAULT_APP_PROPS } from "pages/Snap/shared/constants";
import { adaptSnapToCloudBody } from "pages/Snap/SnapEdit/adaptData";

const mapDispatchToProps = (dispatch) => ({
  createCloudDraftCall: (body) =>
    dispatch(
      api.createApplicationDraft.action({
        options: {
          body: JSON.stringify(body),
        },
      })
    ),
  createCloudAppCall: (body) =>
    dispatch(
      api.createApplication.action({
        options: {
          body,
        },
      })
    ),
  getCloudDraftCall: (app_id) =>
    dispatch(
      api.getApplicationDraft.action({
        params: {
          app_id,
        },
      })
    ),
  getCloudFullVersionCall: (version_id) =>
    dispatch(
      api.getApplicationFullVersion.action({
        params: {
          version_id,
        },
      })
    ),
  updateCloudAppCall: (app_id, body) =>
    dispatch(
      api.updateApplication.action({
        params: {
          app_id,
        },
        options: {
          body,
        },
      })
    ),
  setSubmissionStartedCall: (revision_id, id, body) =>
    dispatch(
      api.setSubmissionStartedStatus.action({
        params: {
          id,
          revision_id,
        },
        options: {
          body,
        },
      })
    ),
});

export const withSnapEditData = (WrappedComponent) => {
  class WithConnect extends Component {
    constructor(props) {
      super(props);
    }
    static propTypes = {
      // from @connect
      createCloudDraftCall: PropTypes.func.isRequired,
      createCloudAppCall: PropTypes.func.isRequired,
      getCloudDraftCall: PropTypes.func.isRequired,
      getCloudFullVersionCall: PropTypes.func.isRequired,
      setSubmissionStartedCall: PropTypes.func.isRequired,
      updateCloudAppCall: PropTypes.func.isRequired,
      // from passed component
      storeAppId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    };
    createCloudApp = async (statusUrl) => {
      const { createCloudAppCall, name } = this.props;
      const body = JSON.stringify({
        data: {
          type: "ApplicationModel",
          attributes: {
            name,
            notify_version_status_url: statusUrl,
            ...DEFAULT_APP_PROPS,
          },
        },
      });
      const { result, error } = await createCloudAppCall(body);

      if (error) {
        throw new Error(error.message);
      }
      return result.results;
    };

    updateCloudApp = async (statusUrl) => {
      const { updateCloudAppCall, storeAppId, name } = this.props;
      // TVSNAP-3296 This is just a temporary solution, because backend is not ready yet
      // api.updateApplication should use PATCH (currently not supported by cloud)
      // only changed fields like notify_version_status_url should be included in request payload (id is mandatory)
      const body = JSON.stringify({
        data: {
          id: storeAppId,
          type: "ApplicationModel",
          attributes: {
            name,
            notify_version_status_url: statusUrl,
            ...DEFAULT_APP_PROPS,
          },
        },
      });
      const { result, error } = await updateCloudAppCall(storeAppId, body);
      if (error) {
        throw new Error(error.message);
      }
      return result.results;
    };

    createCloudDraft = async (values, cloudApp, appUrl) => {
      const {
        createCloudDraftCall,
        getCloudDraftCall,
        getCloudFullVersionCall,
      } = this.props;
      let cloudVersion = null;
      if (cloudApp.has_draft_version) {
        const { result } = await getCloudDraftCall(cloudApp.id);
        if (result) {
          cloudVersion = result.results.draft;
        }
      } else if (cloudApp.has_submitted_version) {
        const { result } = await getCloudFullVersionCall(
          cloudApp.last_submitted_version.id
        );
        if (result && isVersionValid(result.results)) {
          cloudVersion = result.results;
        }
      }

      const draftBody = adaptSnapToCloudBody(
        cloudApp.id,
        appUrl,
        values,
        cloudVersion
      );
      const draft = await createCloudDraftCall(draftBody);

      if (draft.error) {
        throw new Error(draft.error.message);
      }
    };

    setSubmittedStatus = async (revision_id, appId, cloudAppId) => {
      const { setSubmissionStartedCall } = this.props;

      const statusBody = JSON.stringify({
        app_id: cloudAppId,
        status: "SUBMISSION_STARTED",
      });
      const { error } = await setSubmissionStartedCall(
        revision_id,
        appId,
        statusBody
      );
      if (error) {
        throw new Error(error.message);
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          setSubmittedStatus={this.setSubmittedStatus}
          createCloudApp={this.createCloudApp}
          updateCloudApp={this.updateCloudApp}
          createCloudDraft={this.createCloudDraft}
        />
      );
    }
  }
  return connect(null, mapDispatchToProps)(WithConnect);
};
