// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { restrictedArea, ROLES } from "containers/Permissions";
import { api, apiThatThrows } from "containers/Request";
import { withTargetsReceive } from "pages/_shared/TargetsField/decorators/withTargetsReceive";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";

import { AdminDefaultPlanForm } from "./AdminDefaultPlanForm";

const INITIAL_VALUES = {
  distributor: undefined,
  targets: [],
};

@restrictedArea(() => ({
  allowed: [ROLES.administrator.distributionAdmin],
}))
@withRouter
@withTargetsReceive({ distributorFieldNameInRequest: "device_owners" })
@connect(null, (dispatch) => ({
  getDefaultPlan: async (planId) => {
    const result = await dispatch(
      apiThatThrows.getDefaultDistributionPlan.action({
        params: { id: planId },
      })
    );

    return result.results;
  },
  getTargetOfPlan: async (planId, targetId) => {
    const request = await dispatch(
      api.getTargetOfDefaultDistributionPlan.action({
        params: {
          planId,
          targetId,
        },
      })
    );
    if (!request.result) {
      throw {
        message: trans.DISTR_PLAN__TARGET_NOT_FOUND_ERROR({
          targetId,
          planId,
        }),
      };
    }
    return request.result.results;
  },
  getDistributorInfo: async (distributorId) => {
    const { results } = await dispatch(
      apiThatThrows.getOrganizationPublicInfo.action({
        params: { organizationId: distributorId },
      })
    );

    return results;
  },
}))
export class AdminDefaultPlanData extends Component {
  static propTypes = {
    // from @withRouter
    params: PropTypes.object.isRequired,

    // from @connect
    getDefaultPlan: PropTypes.func.isRequired,
    getTargetOfPlan: PropTypes.func.isRequired,
    getDistributorInfo: PropTypes.func.isRequired,

    // from @withTargetsReceive
    getTargetsData: PropTypes.func,
  };

  state = {
    loading: true,
    error: undefined,
    initialValues: INITIAL_VALUES,
  };

  componentDidMount() {
    this.updateInitialValues();
  }

  getPlanId = () => {
    const { params } = this.props;
    return params.id;
  };

  updateInitialValues = async () => {
    const {
      getDefaultPlan,
      getTargetOfPlan,
      getTargetsData,
      getDistributorInfo,
    } = this.props;

    try {
      this.setState({ loading: true, error: undefined });

      const plan = await getDefaultPlan(this.getPlanId());
      const targetsOfPlan = await Promise.all(
        plan.targets.map(async (target) => getTargetOfPlan(plan.id, target.id))
      );
      const targets = await getTargetsData(targetsOfPlan);
      const distributor = await getDistributorInfo(plan.owner);

      this.setState({
        initialValues: {
          distributor: distributor.id,
          targets: targets,
        },
      });
    } catch (err) {
      this.setState({ error: err.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, error, initialValues } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error}</Info>;
    }

    return (
      <AdminDefaultPlanForm
        planId={this.getPlanId()}
        initialValues={initialValues}
      />
    );
  }
}
