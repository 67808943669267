// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { APP_TYPES } from "./EditApp/constants";

export const appDataType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  is_test: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  owner_public_id: PropTypes.string.isRequired,
  has_draft_version: PropTypes.bool.isRequired,
  has_submitted_version: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([
    APP_TYPES.HBBTV,
    APP_TYPES.HTML,
    APP_TYPES.NATIVE,
    APP_TYPES.SNAP,
  ]).isRequired,
});
