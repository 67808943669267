// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import queryString from "query-string";

import {
  SEARCH_PARAM_NAME,
  ARRAY_FORMAT,
  ARRAY_FORMAT_SEPARATOR,
} from "components/elements/SearchBarWithFilters/constants";
import { ensureArray } from "utils/array";

// converts filters to a format expected by gql's "include"
const convertFiltersForRequest = (filters) =>
  Object.entries(filters).map(([name, values]) => ({
    name,
    values: ensureArray(values),
  }));

// converts queryParams to format expected by gql
export const adaptQueryParamsToGqlSearchArg = (queryParams) => {
  const { [SEARCH_PARAM_NAME]: search, page, ...rest } = queryParams;

  return {
    query: search || "",
    include: convertFiltersForRequest(
      queryString.parse(rest.contains, {
        arrayFormat: ARRAY_FORMAT,
        arrayFormatSeparator: ARRAY_FORMAT_SEPARATOR,
      }) || {}
    ),
    exclude: convertFiltersForRequest(
      queryString.parse(rest.not_contains, {
        arrayFormat: ARRAY_FORMAT,
        arrayFormatSeparator: ARRAY_FORMAT_SEPARATOR,
      }) || {}
    ),
  };
};
