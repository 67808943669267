// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable no-async-promise-executor */
import { trans } from "../translations";
import { urlToBlob } from "./converters";

/**
 * Returns true if given file has correct type.
 *
 * @param file - file from FileList object
 * @param formats - allowed formats, e.g. ["image/jpeg", "image/png"]
 */
export const isFormatSupported = (file: File, formats: string[]): boolean => {
  const re = new RegExp(`^${formats.join("|")}$`, "i");
  return re.test(file.type);
};

/**
 * Turns arrayBuffer into Blob with given mimetype
 */
export const getBlob = (buffer: ArrayBuffer, mime: string): Blob => {
  return new Blob([buffer], { type: mime });
};

/**
 * creates URL for given ArrayBuffer
 */
export const createUrl = (buffer: ArrayBuffer, mime: string): string => {
  return URL.createObjectURL(getBlob(buffer, mime));
};

/**
 * Revokes url, frees up memory
 */
export const revokeUrl = (url: string): void => {
  return URL.revokeObjectURL(url);
};

/**
 * Takes ArrayBuffer and returns resized and cropped ArrayBuffer
 */
export const cropImage = (
  blobUrl: string,
  type: string,
  maxWidth: number,
  maxHeight: number
): Promise<string> => {
  const img = new Image();

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const tmpCanvas = document.createElement("canvas");
  const tmpCtx = tmpCanvas.getContext("2d");

  if (ctx == null || tmpCtx == null) {
    throw new Error("Couldn't initialize a rendering context");
  }

  let ratio = 1;

  const promise = new Promise<string>((resolve, reject) => {
    img.onload = () => {
      if (img.width === maxWidth && img.height === maxHeight) {
        resolve(blobUrl);
        return;
      }

      if (img.width / maxWidth > img.height / maxHeight) {
        ratio = maxWidth / img.width;
      } else {
        ratio = maxHeight / img.height;
      }

      tmpCanvas.width = img.width;
      tmpCanvas.height = img.height;

      tmpCtx.drawImage(img, 0, 0, tmpCanvas.width, tmpCanvas.height);

      const rounds = 3;

      // This part is responsible for downsampling
      // Default browser downsampling is shitty

      for (let i = rounds; i >= 1; i -= 1) {
        canvas.width = img.width * ratio * i;
        canvas.height = img.height * ratio * i;

        ctx.drawImage(
          tmpCanvas,
          0,
          0,
          tmpCanvas.width,
          tmpCanvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        tmpCanvas.width = canvas.width;
        tmpCanvas.height = canvas.height;

        tmpCtx.drawImage(canvas, 0, 0, tmpCanvas.width, tmpCanvas.height);
      }

      // Now we take down-sampled image and crop it.

      canvas.width = maxWidth;
      canvas.height = maxHeight;

      const xOffset = (maxWidth - img.width * ratio) / 2;
      const yOffset = (maxHeight - img.height * ratio) / 2;

      ctx.drawImage(
        tmpCanvas,
        xOffset,
        yOffset,
        tmpCanvas.width,
        tmpCanvas.height
      );

      canvas.toBlob((blob) => {
        if (!blob) {
          reject("Could not create a blob.");
          return;
        }
        URL.revokeObjectURL(blobUrl);
        const newBlobUrl = URL.createObjectURL(blob);
        resolve(newBlobUrl);
      });
    };

    img.onerror = (err) => reject(err);
  });

  img.src = blobUrl;

  return promise;
};

/**
 * Calculates greatest common divisor
 */
function greatestCommonDivisor(a: number, b: number): number {
  while (b !== 0) {
    // eslint-disable-next-line no-param-reassign
    [a, b] = [b, a % b];
  }
  return a;
}

const compareFloatsEqual = (a: number, b: number): boolean => {
  const EPS = 0.0005;
  const diff = Math.abs(a - b);
  return diff < EPS;
};

/**
 * Checks if image's dimensions are greater than or equal to given minimum values and if the aspect ratio matches with those provided.
 * Only JPEG, PNG and GIF files are allowed.
 */
export interface ImageValidationMetadata {
  minWidth: number;
  minHeight: number;
  maxWidth: number;
  maxHeight: number;
  maxSize: number;
  validateAspectRatio: boolean;
}

export const validateImage = (
  blobUrl: string,
  {
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    maxSize,
    validateAspectRatio,
  }: ImageValidationMetadata
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const blob = await urlToBlob(blobUrl);
    if (blob.size > maxSize) {
      reject(trans.ERROR__IMAGE_FILE_TOO_BIG({ maxSize: maxSize / 1024 }));
      return;
    }

    const img = new Image();

    img.onload = function () {
      if (img.width < minWidth || img.height < minHeight) {
        reject(
          trans.ERROR__IMAGE_DIMENSIONS_TOO_SMALL({ minWidth, minHeight })
        );
        return;
      }

      if (
        maxWidth &&
        maxHeight &&
        (img.width > maxWidth || img.height > maxHeight)
      ) {
        reject(trans.ERROR__IMAGE_DIMENSIONS_TOO_BIG({ maxWidth, maxHeight }));
        return;
      }

      const requiredAspectRatio = minWidth / minHeight;
      const aspectRatioMatches = compareFloatsEqual(
        img.width / img.height,
        requiredAspectRatio
      );

      if (validateAspectRatio && !aspectRatioMatches) {
        const gcd = greatestCommonDivisor(minWidth, minHeight);
        let ratio = "";

        if (minWidth / gcd > minHeight / gcd) {
          ratio = minWidth / gcd + ":" + minHeight / gcd;
        } else {
          ratio = minHeight / gcd + ":" + minWidth / gcd;
        }

        reject(trans.ERROR__IMAGE_INVALID_RATIO({ ratio }));
      }

      resolve(blobUrl);
    };

    img.src = blobUrl;
  });
};
