// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

const filterOptionPropTypes = PropTypes.shape({
  /** Unique id that will be added to window location along with user-provided value */
  id: PropTypes.string.isRequired,
  /** Representation displayed to user. Cannot contain " ", use "_" instead' */
  displayName: PropTypes.string.isRequired,
  /** Text hint for expected value e.g. date format or 'Yes/No' */
  param: PropTypes.node.isRequired,
  /** Human readable explanation  */
  description: PropTypes.node.isRequired,
  /**
   * Possible values. If empty array or null, then filter accepts any string.
   * If not empty, selecting this filter option will show dropdown
   * with dropdown options based on this field
   */
  values: PropTypes.arrayOf(
    PropTypes.shape({
      /** this value will be used as value in query string */
      query: PropTypes.string.isRequired,
      /** this value will be used for display */
      display: PropTypes.string.isRequired,
    })
  ),
  /**
   * If set to true and `<SearchBarWithFilters>` has `suggestionApiEndpoint`
   * prop, the `suggestionApiEndpoint` will be called to provide values
   * for this filter.
   */
  hasSuggestions: PropTypes.bool,
});

export const filterSectionPropTypes = PropTypes.shape({
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(filterOptionPropTypes).isRequired,
});

export const SearchBarWithFiltersPropTypes = {
  /**
   * Called when search parameters changed (which means that either
   * value or filters changed).
   *
   * First param contains actual filters and value in same format
   * like searchParams prop.
   */
  onSearch: PropTypes.func,
  /**
   * Scheme for available filters.
   */
  filtersDataChoices: PropTypes.shape({
    sections: PropTypes.arrayOf(filterSectionPropTypes).isRequired,
  }),

  /**
   * Input placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Endpoint queried for value suggestions. It's expected that this
   * function will throw on http error. It will be invoked with 2 queryParams
   * "for_filter" and "text".
   * Filter that has suggested async values should have `hasSuggestions` set.
   *
   * @Returns
   * {
   *   results: {
   *     filter_value: string
   *     text: string
   *     icon: strin;
   *   }[]
   * }
   */
  suggestionApiEndpoint: PropTypes.func,
  look: PropTypes.oneOf(["for-gray-bg", "for-white-bg"]),
  className: PropTypes.string,
  /**
   * Function that returns button element
   */
  actionTrigger: PropTypes.func,
  /**
   * Modal that opens when action trigger is clicked
   */
  actionModal: PropTypes.func,
  /**
   *  Function for listening to changes in search bar
   */
  onChange: PropTypes.func,
  hasExcludeOption: PropTypes.bool,
  /**
   * Error present for available filters fetching failure
   */
  availableFiltersError: PropTypes.object,
};
