// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { ScheduleData } from "./ScheduleData";

export class SchedulePage extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,
  };

  render() {
    const { forAdmin } = this.props;
    return (
      <div>
        <PageHeader>{trans.SCHEDULE__PAGE_HEADER()}</PageHeader>
        <PageContent>
          <ScheduleData forAdmin={forAdmin} />
        </PageContent>
      </div>
    );
  }
}
