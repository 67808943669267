// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import styles from "./PageNavigation.scss";

/**
 * Special component to add buttons on the top of the page. Automatically
 * added to each page. You probably do not need to use it.
 */
export const PageNavigation = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

PageNavigation.propTypes = {
  children: PropTypes.node,
};
