// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const mutationResultType = PropTypes.shape({
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  called: PropTypes.bool.isRequired,
  client: PropTypes.object,
});
