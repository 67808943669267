// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { adaptSteps } from "./adaptSteps";

const adaptTarget = ({ id, name, devices }) => {
  const deviceCount = get(devices, "pagination.count");
  return { id, name, deviceCount };
};

export const adaptUpdateJobData = (data) => {
  if (data == null) {
    return undefined;
  }

  return {
    ...data,
    steps: adaptSteps(data),
    logsStartDate: new Date(data.creationDate),
    target: adaptTarget(data.target),
  };
};
