// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";
import { Routes, Navigate, Route } from "react-router-dom";

import get from "lodash-es/get";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { Loader } from "components/elements";
import { PageError } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { ExtendedRoute } from "containers/ExtendedRoute";
import { api } from "containers/Request";
import { APP_ROUTE_MY, SUBMISSION_STATUS } from "pages/Applications/constants";
import { trans } from "src/translations";

import { getConfig } from "./config";
import { EditApp } from "./EditApp";
import { adaptToForm } from "./utils/appInventoryApiAdapter";
import { parseInitialData } from "./utils/parseInitialData";

@withActiveOrganization
@connect(null, (dispatch) => ({
  loadApplication: (id) => {
    return dispatch(
      api.getApplication.action({
        params: { id },
        // todo: YGG-4047 remove 'nocache' when YGG-4046 ready
        // The 'nocache' query param forces backend to ignore a cache.
        // This is a temporary & hacky solution needed for the 1.2.0 release.
        // It has to be removed in the next release when caching in backend is fixed.
        queryParams: {
          nocache: uuid(),
        },
      })
    );
  },
  loadApplicationData: (id) =>
    Promise.all([
      dispatch(api.getApplicationDraft.action({ params: { app_id: id } })),
      dispatch(
        api.getApplicationFullVersionsList.action({
          params: { app_id: id },
          queryParams: { ordering: "-created_date" },
        })
      ),
    ]),
  loadTestApplicationData: (id) =>
    dispatch(api.getApplicationTestVersion.action({ params: { app_id: id } })),
}))
export class EditAppRoute extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,

    // from @connect
    loadApplication: PropTypes.func.isRequired,
    loadApplicationData: PropTypes.func.isRequired,
    loadTestApplicationData: PropTypes.func.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      roles: PropTypes.array.isRequired,
    }).isRequired,
  };

  state = {
    isLoaded: false,
    application: null,
    draftVersion: null,
    fullVersion: null,
    testVersion: null,
    error: null,
    runServerValidation: false,
  };

  componentDidMount() {
    this.reloadState();
  }

  async componentDidUpdate(prevProps) {
    const activeOrganizationId = get(this.props, "activeOrganization.id");
    const prevActiveOrganizationId = get(prevProps, "activeOrganization.id");
    const orgChanged = activeOrganizationId !== prevActiveOrganizationId;

    const applicationIdChanged = this.props.params.id !== prevProps.params.id;

    if (orgChanged || applicationIdChanged) {
      this.reloadState();
    }
  }

  async makeRequests() {
    const {
      params: { id },
    } = this.props;
    this.setState({
      application: null,
      draftVersion: null,
      fullVersion: null,
      testVersion: null,
      isLoaded: false,
      error: null,
    });
    const applicationRequest = await this.props.loadApplication(id);
    if (!applicationRequest.error) {
      this.setState({
        application: get(applicationRequest, "result.results"),
      });

      const { is_test } = applicationRequest.result.results;
      if (is_test) {
        const testApplicationRequest =
          await this.props.loadTestApplicationData(id);
        this.setState({
          testVersion: get(testApplicationRequest, "result.results"),
          isLoaded: true,
        });
      } else {
        const [draftVersionRequest, fullVersionRequest] =
          await this.props.loadApplicationData(id);
        this.setState({
          draftVersion: get(draftVersionRequest, "result.results"),
          fullVersion: get(fullVersionRequest, "result.results[0]"),
          isLoaded: true,
        });
      }
    } else {
      throw applicationRequest.error;
    }
  }

  async reloadState({ runServerValidation } = { runServerValidation: false }) {
    try {
      this.setState({ runServerValidation });
      await this.makeRequests();
    } catch (error) {
      this.setState({ error, isLoaded: true });
    }
  }

  prepareApplicationProps() {
    const { application, draftVersion, fullVersion, testVersion } = this.state;

    const appData = { ...application };
    let initAppData = {};

    if (application.is_test) {
      const testApp = testVersion || {};

      initAppData = {
        ...initAppData,
        ...adaptToForm(testApp),
      };
    }

    if (application.has_draft_version) {
      const draft = draftVersion.draft;

      initAppData = {
        ...initAppData,
        ...adaptToForm(draft),
      };
    }

    if (application.has_submitted_version && !application.has_draft_version) {
      initAppData = { ...initAppData, ...adaptToForm(fullVersion) };
    }

    return {
      appData: appData,
      initAppData: parseInitialData(initAppData, application.has_draft_version),
    };
  }

  getApplicationError() {
    const { application } = this.state;

    let message = null;
    const appIsInReview =
      get(application, "submission_status") === SUBMISSION_STATUS.IN_REVIEW.id;

    if (!application) {
      message = trans.APP_EDIT__ERROR_PAGE_DEFAULT_MESSAGE();
    } else if (appIsInReview) {
      message = trans.APP_EDIT__ERROR_PAGE_APP_IS_IN_REVIEW();
    }

    return message ? { message } : null;
  }

  getTabName = (config) => (match) => {
    // match that is injected from parent component does not yet have
    // the resolved value for ':tab' parameter. We have to use
    // better one from child ExtendedRoute component
    const activeTab =
      config.tabs.find((t) => t.url === match.tab) || config.tabs[0];
    return activeTab.name;
  };

  render() {
    const {
      params: { id },
      activeOrganization,
    } = this.props;
    const { isLoaded } = this.state;

    if (!isLoaded) {
      return <Loader />;
    }

    const error = this.state.error || this.getApplicationError();
    if (error) {
      return (
        <PageError
          pageHeader={trans.APP_EDIT__ERROR_PAGE_HEADER()}
          error={error}
          defaultMessage={trans.APP_EDIT__ERROR_PAGE_DEFAULT_MESSAGE()}
        />
      );
    }
    const applicationProps = this.prepareApplicationProps();
    const config = getConfig(applicationProps, activeOrganization);
    const defaultTab = config.tabs[0].url;
    const getTabName = this.getTabName(config);

    return (
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={`/applications/${APP_ROUTE_MY}/${id}/edit/${defaultTab}`}
              replace={true}
            />
          }
        />
        <Route
          exact
          path="/edit"
          element={
            <Navigate
              to={`/applications/${APP_ROUTE_MY}/${id}/edit/${defaultTab}`}
              replace={true}
            />
          }
        />
        <Route
          exact
          path="/edit/*"
          element={
            <ExtendedRoute
              nameFromMatch={getTabName}
              render={(routeProps) => (
                <EditApp
                  {...applicationProps}
                  {...routeProps}
                  reloadState={this.reloadState.bind(this)}
                  config={config}
                  runServerValidation={this.state.runServerValidation}
                />
              )}
            />
          }
        />
      </Routes>
    );
  }
}
