// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";

import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableNoResults,
} from "components/layout";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";
import { reorderFields } from "utils/formik/utils";

import { getAllApplications } from "../../../_utils/getAllApps";
import AddPromotionModal from "./AddPromotionModal/AddPromotionModal";
import { PromotionItem } from "./PromotionItem";
import styles from "./PromotionsTable.scss";

const PROMOTION_ITEMS_COLUMNS = [
  {
    id: "drag",
    label: "",
    className: styles.dragHeader,
  },
  {
    id: "promotion-name",
    label: trans.CAMPAIGN_EDIT__FIELD_PROMOTIONS_PROMOTION_NAME(),
    className: styles.nameHeader,
  },
  {
    id: "include-apps",
    label: trans.CAMPAIGN_EDIT__FIELD_PROMOTIONS_INCLUDE_APPS(),
    className: styles.includeAppsHeader,
  },
  {
    id: "move-arrows",
    label: "",
    className: styles.menuHeader,
  },
  {
    id: "menu",
    label: "",
    className: styles.menuHeader,
  },
];

const transformPromotion = (promotionResponse) =>
  promotionResponse.reduce((acc, response) => {
    const id = get(response, "results.id");
    return {
      ...acc,
      [id]: {
        id,
        name: get(response, "results.name"),
        includeApps: get(response, "results.promotion_details", []).map((app) =>
          get(app, "application_id")
        ),
      },
    };
  }, {});

export const getAppNames = async (applicationIds, getApplications) => {
  const appIds = uniq(applicationIds);

  const apps = await getAllApplications(appIds, getApplications);

  const appsMap = apps.reduce((acc, app) => ({ ...acc, [app.id]: app }), {});

  return appIds.reduce(
    (acc, id) => ({
      ...acc,
      [id]: { appId: id, name: appsMap[id]?.name },
    }),
    {}
  );
};

@connect(null, (dispatch, ownProps) => ({
  getPromotion: (id) => {
    const getPromotionAction = ownProps.forAdmin
      ? apiThatThrows.getPromotion.action
      : apiThatThrows.getMyPromotion.action;
    return dispatch(
      getPromotionAction({
        params: { id },
        queryParams: { include: ["promotion_details"] },
      })
    );
  },
  getApplications: async (appIds, page = 1) => {
    if (appIds.length === 0) {
      return [];
    }

    const { results } = await dispatch(
      apiThatThrows.getApplicationsPublicInfoPaginated.action({
        queryParams: {
          public_id: appIds,
          // This endpoint supports up to 100 apps per request
          limit: Math.min(100, appIds.length),
          page,
        },
      })
    );

    return results;
  },
}))
export default class PromotionsTable extends Component {
  static propTypes = {
    promotionIds: PropTypes.arrayOf(PropTypes.string),
    forAdmin: PropTypes.bool,
    isReadonly: PropTypes.bool,
    push: PropTypes.func.isRequired,
    move: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,

    // from @connect
    selectedPromotions: PropTypes.object,
    getPromotion: PropTypes.func.isRequired,
    getApplications: PropTypes.func.isRequired,
  };

  static defaultProps = {
    promotionIds: [],
  };

  state = {
    promotions: {},
    appDetails: {},
    isAddPromotionModalOpen: false,
    loading: false,
    error: null,
  };

  componentDidMount() {
    const { promotionIds } = this.props;
    this.fetchData(promotionIds);
  }

  fetchData = async (promotionIds) => {
    const { getPromotion, getApplications } = this.props;
    const { promotions, appDetails } = this.state;
    this.setState({ loading: true });
    try {
      const promotionsData = await Promise.all(
        promotionIds.map((promotionId) => getPromotion(promotionId))
      );

      const fetchedPromotions = transformPromotion(promotionsData);
      const applicationIds = Object.values(fetchedPromotions)
        .flatMap((promotion) => promotion.includeApps)
        .filter((e) => e);

      const fetchedApps = await getAppNames(
        applicationIds.sort(),
        getApplications
      );

      this.setState({
        promotions: { ...promotions, ...fetchedPromotions },
        appDetails: { ...appDetails, ...fetchedApps },
      });
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  };

  toggleAddPromotionModal = () => {
    this.setState((state) => ({
      isAddPromotionModalOpen: !state.isAddPromotionModalOpen,
    }));
  };

  handleMove = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    const { move } = this.props;
    move(source.index, destination.index);
  };

  handleSubmit = (selectedPromotions) => {
    const { setFieldValue, name } = this.props;

    this.setState({ loading: true });
    setFieldValue(name, selectedPromotions);
    this.fetchData(selectedPromotions);
    this.toggleAddPromotionModal();
  };

  handleRemove = (index) => {
    const { remove } = this.props;
    remove(index);
  };

  getPromotions = () => {
    const { promotions } = this.state;
    const { promotionIds } = this.props;
    if (isEmpty(promotions)) {
      return [];
    }
    return promotionIds
      .map((id) => promotions[id])
      .filter((app) => Boolean(app));
  };

  renderBody = (provided) => {
    const { appDetails, loading, error } = this.state;
    const { forAdmin, isReadonly, promotionIds, move } = this.props;
    const promotionsData = this.getPromotions();

    if (error) {
      return (
        <TableNoResults
          message={trans.DEFAULT_ERROR_MESSAGE()}
          isError={Boolean(error)}
          colspan={4}
          loading={loading}
        />
      );
    }

    if (loading) {
      return <TableNoResults colspan={5} loading={loading} />;
    }

    return (
      <TableBody>
        {promotionsData.map((promotion, index) => {
          return (
            <Draggable
              key={promotion.id}
              draggableId={promotion.id}
              index={index}
              isDragDisabled={isReadonly}
            >
              {(provided, snapshot) => (
                <PromotionItem
                  key={promotion.id}
                  promotion={promotion}
                  isReadonly={isReadonly}
                  appDetails={appDetails}
                  forAdmin={forAdmin}
                  index={index}
                  isDragging={snapshot.isDragging}
                  innerRef={provided.innerRef}
                  onRemove={this.handleRemove}
                  onMove={(index, direction) =>
                    reorderFields(promotionIds, index, direction, move)
                  }
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                />
              )}
            </Draggable>
          );
        })}
        {provided.placeholder}
      </TableBody>
    );
  };

  render() {
    const { isAddPromotionModalOpen, loading } = this.state;
    const { isReadonly } = this.props;

    return (
      <div data-test-id="campaign-promotions-table">
        <DragDropContext onDragEnd={this.handleMove}>
          <Droppable droppableId="package-items-droppable">
            {(provided) => (
              <Table
                innerRef={provided.innerRef}
                layout="fixed"
                className={styles.table}
              >
                <TableHeader position="sticky" loading={loading}>
                  {PROMOTION_ITEMS_COLUMNS.map(({ id, label, className }) => (
                    <TableHeaderColumn key={id} className={className}>
                      {label}
                    </TableHeaderColumn>
                  ))}
                </TableHeader>
                {this.renderBody(provided)}
              </Table>
            )}
          </Droppable>
        </DragDropContext>
        {isAddPromotionModalOpen && (
          <AddPromotionModal
            isOpen={isAddPromotionModalOpen}
            onSubmit={this.handleSubmit}
            onClose={this.toggleAddPromotionModal}
          />
        )}
        <div className={styles.buttonContainer}>
          {!isReadonly && (
            <Button
              dataTestId="add-promotion-button"
              onClick={this.toggleAddPromotionModal}
            >
              {trans.CAMPAIGN_EDIT__FIELD_PROMOTIONS_CHANGE_PROMOTIONS()}
            </Button>
          )}
        </div>
      </div>
    );
  }
}
