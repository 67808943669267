// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getSoftwarePackageVersionQuery = gql`
  query softwarePackageVersion($version: ID!) {
    softwarePackageVersion(softwarePackageVersionId: $version) {
      softwarePackageVersion {
        id
        version
        fileName
        uploadDate
        rawManifest
        fileSize
        softwarePackage {
          id
          name
        }
      }
    }
  }
`;
