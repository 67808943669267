// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { Info } from "components/feedback";
import { Section } from "components/layout";
import { trans } from "src/translations";

import { PdidHistoryData } from "../components/PdidHistoryData";
import { DevicePropType } from "../propTypes";

export function PdidHistoryTab({ device }) {
  return (
    <Fragment>
      {device.activeAlertsNumber ? (
        <Info type="error">
          {trans.DEVICES__DEVICE_DETAILS_PDID_HISTORY_ACTIVE_ALERT_WARNING_MESSAGE()}
        </Info>
      ) : null}
      <Section header={trans.DEVICES__DEVICE_DETAILS_TAB_PDID_HISTORY()}>
        <PdidHistoryData deviceId={device.id} />
      </Section>
    </Fragment>
  );
}

PdidHistoryTab.propTypes = {
  device: DevicePropType,
};
