// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { RolesField } from "pages/_shared/RolesField";
import { ROLES } from "src/containers/Permissions/roles";
import { trans } from "src/translations";
import { isRequired, isEmail, pipeValidators } from "utils/validation";

import { AccountStatusData } from "./AccountStatus/AccountStatusData";
import { textFieldsConfig, fieldArrayName, avatarConfig } from "./constants";
import { CurrentOrganization } from "./CurrentOrganization";
import styles from "./editUserForm.scss";
import { OrganizationMembershipField } from "./OrganizationMembership/OrganizationMembershipField";
import { UserBasicDetails } from "./UserBasicDetails";

export const EditUserForm = ({
  onPatch,
  activeOrganization,
  editUser,
  onMembershipRemoval,
  onRefreshData,
  forUserAdmin,
}) => {
  const validate = (values) => {
    const errors = {};
    const emailField = textFieldsConfig.find(
      (field) => field?.type === "email"
    );
    const validators = [
      ...textFieldsConfig.map((textField) => isRequired(textField.name)),
      isEmail(emailField.name),
    ];
    return pipeValidators(...validators)(values, null, errors);
  };

  return (
    <Formik
      initialValues={{
        email: editUser.email,
        given_name: editUser.given_name,
        family_name: editUser.family_name,
        [avatarConfig.name]: editUser.avatar,
        [fieldArrayName]: editUser.grants.filter(
          ({ role }) => role !== ROLES.organization.member
        ),
      }}
      validate={validate}
      onSubmit={async (values, { setErrors }) =>
        await onPatch(values, setErrors)
      }
    >
      {({ dirty, errors, isSubmitting }) => (
        <Form aria-label="form">
          <Section header={trans.USER_EDIT__BASIC()}>
            <UserBasicDetails
              textFieldsConfig={textFieldsConfig}
              uploaderWithPreviewFieldConfig={avatarConfig}
            />
            {forUserAdmin ? (
              <>
                <AccountStatusData
                  user={editUser}
                  onRefreshData={onRefreshData}
                />
                <OrganizationMembershipField name={fieldArrayName} />
              </>
            ) : (
              <>
                <Section header={trans.USER_EDIT__ORG_MEMBESHIP()}>
                  <CurrentOrganization
                    onRemoveOrganizationClick={onMembershipRemoval}
                    organization={activeOrganization}
                    editUser={editUser}
                  />
                </Section>
                <Section header={trans.USER_EDIT__ROLES()}>
                  <RolesField name={fieldArrayName} />
                </Section>
              </>
            )}
            {errors?._error && <Info type="error">{errors._error}</Info>}
            <ButtonsWrapper>
              <Button
                buttonType="submit"
                type="green"
                processing={isSubmitting}
                disabled={
                  isSubmitting ||
                  !dirty ||
                  Boolean(Object.entries(errors).length)
                }
                className={styles.button}
                dataTestId="submit-button"
              >
                {trans.UPDATE()}
              </Button>
            </ButtonsWrapper>
          </Section>
        </Form>
      )}
    </Formik>
  );
};

EditUserForm.propTypes = {
  editUser: PropTypes.object,
  onPatch: PropTypes.func,
  onMembershipRemoval: PropTypes.func,
  onRefreshData: PropTypes.func,
  activeOrganization: PropTypes.object,
  forUserAdmin: PropTypes.bool,
};
