// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Input } from "components/form/elements";
import { GrabsonIcon } from "components/icons";

import styles from "./LockedInputField.scss";

export function LockedInputField({ lockMessage, dataTestId }) {
  return (
    <div className={styles.lockedSearchContainer}>
      <Input
        disabled={true}
        look="simple"
        className={styles.lockedSearchInput}
        value={lockMessage}
        onChange={() => {}}
        dataTestId={dataTestId}
      />
      <GrabsonIcon name="lock" className={styles.lockedSearchIcon} size="lg" />
    </div>
  );
}

LockedInputField.propTypes = {
  lockMessage: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
};
