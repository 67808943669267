// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable react/prop-types */
import { Component } from "react";
import { connect } from "react-redux";

import { API_URLS } from "config";
import get from "lodash-es/get";
import intersection from "lodash-es/intersection";
import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";
import queryString from "query-string";

import { Loader } from "components/elements";
import {
  withActiveOrganization,
  activeOrganizationPropTypes,
} from "containers/Auth/decorators/withActiveOrganization";
import { ROLES } from "containers/Permissions/roles";
import { apiThatThrows } from "containers/Request";
import { withRouter } from "utils/decorators/withRouter";
import { adaptSortingQueryParams, retrieveFiltersFromInclude } from "utils/url";
import { getAuthorName } from "utils/user";

import { INBOXES, INBOX_ALL, ROLES_POST_TO_DISTRIBUTOR } from "./constants";
import { MessagesRend } from "./MessagesRend";

@withActiveOrganization
@withRouter
@connect(null, (dispatch) => ({
  getMessages: (appId, queryParams) =>
    dispatch(
      apiThatThrows.getMessagesPaginated.action({
        params: {
          applicationId: appId,
        },
        queryParams: {
          ...queryParams,
          include: ["author", "author_organization", "device_models"],
        },
      })
    ),
  getMessageFilters: (appId) =>
    dispatch(
      apiThatThrows.getMessageFilters.action({
        params: {
          applicationId: appId,
        },
      })
    ),
  getOems: () =>
    dispatch(
      apiThatThrows.getOrganizationsPaginated.action({
        queryParams: { type: "Distributor", limit: "nolimit" },
      })
    ),
}))
export class MessagesData extends Component {
  static propTypes = {
    applicationId: PropTypes.string.isRequired,

    // from @withActiveOrganization
    activeOrganization: activeOrganizationPropTypes,

    // from @withRouter
    location: PropTypes.object,

    // from @connect
    getMessages: PropTypes.func.isRequired,
    getMessageFilters: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    messagesLoading: true,
    error: null,
    messages: undefined,
    oems: [],
    messageFilters: [],
  };

  /**
   * Method returns inboxes, to which current user is allowed to post messages.
   */
  getAllowedInboxes() {
    const { roles } = this.props.activeOrganization;
    const { provision, moderation, distribution } = INBOXES;

    const mapping = {
      [ROLES.provider.creator]: [provision],
      [ROLES.provider.signatory]: [provision],
      [ROLES.moderator.moderator]: [provision, moderation, distribution],
      [ROLES.moderator.contentManager]: [provision, moderation],
      [ROLES.distributor.distributionPlanner]: [distribution],
    };

    return uniq(
      Object.entries(mapping).reduce((total, [checkedRole, inboxes]) => {
        if (roles.some((role) => role.indexOf(checkedRole) > -1)) {
          return [...total, ...inboxes];
        }
        return total;
      }, [])
    );
  }

  async componentDidMount() {
    try {
      const oems = await this.refreshOems();
      const messageFilters = await this.refreshMessageFilters();
      const messages = await this.refreshMessageList();

      this.setState({
        loading: false,
        messagesLoading: false,
        error: null,
        messages,
        oems,
        messageFilters,
      });
    } catch (error) {
      this.setState({
        loading: false,
        messagesLoading: false,
        error,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location.search !== location.search) {
      this.refreshData();
    }
  }

  canSelectDistributors() {
    const { activeOrganization } = this.props;
    const rolesSelectDistr = intersection(
      activeOrganization.roles,
      ROLES_POST_TO_DISTRIBUTOR
    );
    return rolesSelectDistr.length > 0;
  }

  async refreshOems() {
    const { getOems } = this.props;

    if (!this.canSelectDistributors()) {
      return [];
    }

    const { results } = await getOems();
    return results.map((o) => ({ value: o.id, text: o.name }));
  }

  async refreshMessageFilters() {
    const { getMessageFilters, applicationId } = this.props;

    const { results } = await getMessageFilters(applicationId);
    return results;
  }

  createJiraIssueLink(tag) {
    return `${API_URLS.JIRA}/browse/${tag}`;
  }

  createMessage = (message) => {
    return {
      id: message.id,
      authorName: getAuthorName(message),
      authorAvatar: get(message, "author_organization.logo"),
      date: Date.parse(message.created_at),
      contents: message.contents,
      devices: message.device_models.map((m) => ({ id: m.id, name: m.name })),
      tasks: message.tickets.map((ticket) => ({
        tag: ticket,
        url: this.createJiraIssueLink(ticket),
      })),
      wasRead: true, // mocked // todo: fix when YGG-1776 ready
      type: message.title,
    };
  };

  getSearchParameters() {
    const { applicationId, location } = this.props;

    const defaultParams = {
      inbox: INBOX_ALL,
      ticket: undefined,
      search: "",
    };

    return {
      ...defaultParams,
      ...queryString.parse(location.search),
      application: applicationId,
    };
  }

  async refreshMessageList() {
    const { getMessages, applicationId } = this.props;

    let queryParams = this.getSearchParameters();
    queryParams = adaptSortingQueryParams(queryParams);
    queryParams = retrieveFiltersFromInclude(queryParams);

    const { results, meta } = await getMessages(applicationId, queryParams);

    return {
      result: results.map(this.createMessage),
      meta: meta,
    };
  }

  refreshData = async () => {
    try {
      this.setState({
        messagesLoading: true,
        error: null,
      });

      const messages = await this.refreshMessageList();

      this.setState({
        messagesLoading: false,
        messages,
      });
    } catch (error) {
      this.setState({
        messagesLoading: false,
        error,
      });
    }
  };

  markMessageAsRead(/*messageId*/) {
    // TODO YGG-427: waiting for endpoint. Consider carefully the flow when
    // we are going to 'grey-out' the message we have just read
    // You MAY need to have separate 'messages read' list on frontend
  }

  getInitialDistributor = () => {
    const { activeOrganization } = this.props;
    const { oems } = this.state;

    if (this.canSelectDistributors()) {
      return get(oems, "[0].value", "");
    }

    return activeOrganization.id;
  };

  getInitialInbox = () => {
    const allowedInboxes = this.getAllowedInboxes();
    return get(allowedInboxes, "[0].key", "");
  };

  getInitialValues = () => {
    return {
      contents: "",
      distributor: this.getInitialDistributor(),
      inbox: this.getInitialInbox(),
    };
  };

  render() {
    const { applicationId } = this.props;
    const { messages, oems, messageFilters, loading, messagesLoading, error } =
      this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <MessagesRend
        applicationId={applicationId}
        inboxes={this.getAllowedInboxes()}
        oems={oems}
        messages={messages}
        messageFilters={messageFilters}
        markMessageAsRead={this.markMessageAsRead}
        loading={messagesLoading}
        error={error}
        initialValues={this.getInitialValues()}
        refreshData={this.refreshData}
      />
    );
  }
}
