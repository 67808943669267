// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";

import { cloneUpdateJobFormLocation } from "../UpdateJobForm/utils";
import { cloneAction, actionList } from "./constants";

export const UpdateJobDetailsActionButtons = ({
  actions = [],
  openModal,
  clone,
  updateJobId,
}) => {
  if (actions.length === 0 && !clone) {
    return null;
  }

  const actionsToRender = actionList.filter(({ key }) => actions.includes(key));
  const { to, state } = cloneUpdateJobFormLocation(updateJobId);

  return (
    <ButtonsWrapper>
      {clone && (
        <Button to={to} state={state} dataTestId="clone-update-job-button">
          {cloneAction.label}
        </Button>
      )}

      {actionsToRender.map(({ key, label }) => (
        <Button
          key={key}
          onClick={() => openModal(key)}
          dataTestId={`${key.toLowerCase()}-update-job-button`}
        >
          {label}
        </Button>
      ))}
    </ButtonsWrapper>
  );
};

UpdateJobDetailsActionButtons.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
  clone: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  updateJobId: PropTypes.string.isRequired,
};
