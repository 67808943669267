import { useEffect, useState } from "react";

import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Label, ValidationBadge } from "components/form";
import { Section } from "components/layout";
import { EditorField, getErrorId, TimeField } from "src/components/form/fields";
import { trans } from "src/translations";

import { TargetDevicesModal } from "./components/TargetDevicesModal";
import { ConfirmCommandModal } from "./ConfirmCommandModal";
import { FIELDS, VALIDATION_SCHEMA } from "./constants";
import styles from "./CreateCommandRend.scss";

export const CreateCommandRend = ({
  error,
  loading,
  onSubmit,
  initialValues,
  isClone,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (isClone) {
      setConfirmModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => setConfirmModalOpen(true)}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ dirty, isSubmitting }) => (
        <Form>
          <ConfirmCommandModal
            isOpen={confirmModalOpen}
            onSubmit={onSubmit}
            onClose={() => setConfirmModalOpen(false)}
          />
          <Section
            header={trans.DEVICES__COMMANDS_CREATE_HEADER_TARGET_DEVICES()}
          >
            <TargetDevicesModal {...FIELDS.targetDevice} />
          </Section>
          <Section
            header={trans.DEVICES__COMMANDS_CREATE_HEADER_DEVICE_COMMAND()}
            className={styles.deviceCommandSection}
          >
            <Field name={FIELDS.name.name}>
              {({ field, meta }) => (
                <div className={styles.nameField}>
                  <Label
                    text={FIELDS.name.label}
                    required={FIELDS.name.required}
                  >
                    <input {...field} data-test-id={FIELDS.name.dataTestId} />
                  </Label>
                  <ValidationBadge
                    error={meta.error}
                    errorId={getErrorId(FIELDS.name.name)}
                    touched={meta.touched}
                  />
                </div>
              )}
            </Field>
            <EditorField {...FIELDS.payload} className={styles.payloadField} />
            <TimeField {...FIELDS.timeToLive} />
          </Section>
          <ButtonsWrapper position="center">
            <Button
              buttonType="submit"
              disabled={(!dirty && !isClone) || isSubmitting || loading}
              processing={loading}
              type="green"
            >
              {trans.DEVICES__COMMANDS_CREATE_SEND_COMMAND()}
            </Button>
          </ButtonsWrapper>
          {error && (
            <Info className={styles.error} type="error">
              {error}
            </Info>
          )}
        </Form>
      )}
    </Formik>
  );
};

CreateCommandRend.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  isClone: PropTypes.bool,
};
