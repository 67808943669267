// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { Overlay } from "components/layout";
import { classes } from "utils/classes";

import styles from "./menuItem.scss";

/**
 * Row of `<ActionMenu>`.
 */
export class MenuItem extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    /** Link to different page */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** State to be passed to the component, works only with <Link /> component */
    state: PropTypes.object,
    /**
     * Click handler
     *
     * Type: (e: Event) => void
     */
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    isLoading: false,
    dataTestId: "menu-item",
    state: {},
  };

  renderDisabledItem = () => {
    const { children, dataTestId } = this.props;

    return (
      <div
        data-test-id={dataTestId}
        className={classes(styles.item, styles.disabled)}
      >
        {children}
      </div>
    );
  };

  renderLinkItem = () => {
    const { children, to, state, dataTestId } = this.props;

    return (
      <Link
        data-test-id={dataTestId}
        to={to}
        state={state}
        className={styles.item}
      >
        {children}
      </Link>
    );
  };

  renderNormalItem = () => {
    const { children, onClick, dataTestId } = this.props;

    return (
      <div data-test-id={dataTestId} onClick={onClick} className={styles.item}>
        {children}
      </div>
    );
  };

  getItem = () => {
    const { to, disabled } = this.props;

    if (disabled) {
      return this.renderDisabledItem();
    }

    if (to !== undefined) {
      return this.renderLinkItem();
    }

    return this.renderNormalItem();
  };

  render() {
    const { isLoading } = this.props;

    return (
      <Overlay loaderSize="small" active={isLoading} className={styles.overlay}>
        {this.getItem()}
      </Overlay>
    );
  }
}
