// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";

import { Formik } from "formik";
import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

import { Section } from "components/layout";
import { Link } from "components/typography";
import { RestrictedArea, ROLES } from "containers/Permissions";
import { ALL_PROVIDERS, ALL_MODERATORS } from "containers/Permissions/groups";
import { ProfileModal } from "pages/Applications/EditApp/components/Profiles/ProfileModal";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import { APP_ENVIRONMENT, APP_TYPE } from "../../constants";
import { SubsectionWithUpdatedStatus } from "../_shared";
import styles from "../Version.scss";
import { ProfilesTable } from "./ProfilesTable";

export class RundataSection extends Component {
  static propTypes = {
    appType: PropTypes.oneOf(Object.values(APP_TYPE)),
    // Available only for non-native app types
    applicationUrl: PropTypes.string,
    // Available only for non-native app types
    distributorUrl: PropTypes.string,
    // Available only for Native app types
    profiles: PropTypes.shape({
      [APP_ENVIRONMENT.ANDROID]: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          action: PropTypes.string.isRequired,
          packageName: PropTypes.string,
          className: PropTypes.string,
        })
      ),
      [APP_ENVIRONMENT.LINUX]: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          nativeId: PropTypes.string.isRequired,
        })
      ),
    }),
  };

  state = {
    openedProfile: undefined,
  };

  openProfileModal = (environment, data) => {
    this.setState({
      openedProfile: {
        ...data,
        environment,
      },
    });
  };

  closeProfileModal = () => {
    this.setState({
      openedProfile: undefined,
    });
  };

  areProfilesUpdated = (environmentType) => (activeVersion, latestVersion) => {
    const activeProfiles = activeVersion.profiles[environmentType];
    const latestProfiles = latestVersion.profiles[environmentType];

    return !isEqual(activeProfiles, latestProfiles);
  };

  render() {
    const { appType, applicationUrl, distributorUrl, profiles } = this.props;
    const { openedProfile } = this.state;

    return (
      <Section
        header={trans.APP_DETAILS_VERSION__SECTION_RUNDATA()}
        dataTestId="rundata-section"
      >
        {appType !== APP_TYPE.NATIVE && (
          <SubsectionWithUpdatedStatus
            headerContent={trans.APP_DETAILS_VERSION__LINK_TO_APP()}
            isUpdatedPath="application_url"
            subsectionClassName={styles.linkToApp}
          >
            <RestrictedArea
              allowed={[...ALL_PROVIDERS, ...ALL_MODERATORS]}
              showFallback={false}
            >
              <Link
                className={classes(styles.titleText, styles.linkToApp)}
                href={applicationUrl}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {applicationUrl}
              </Link>
            </RestrictedArea>
            <RestrictedArea
              allowed={[
                ROLES.distributor.distributionPlanner,
                ROLES.administrator.distributionAdmin,
              ]}
              showFallback={false}
            >
              <Link
                className={classes(styles.titleText, styles.linkToApp)}
                href={distributorUrl}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {distributorUrl}
              </Link>
            </RestrictedArea>
          </SubsectionWithUpdatedStatus>
        )}
        {appType === APP_TYPE.NATIVE && (
          <Fragment>
            <SubsectionWithUpdatedStatus
              headerContent={trans.APP_DETAILS_VERSION__ANDROID_PROFILES()}
              isUpdatedFunc={this.areProfilesUpdated(APP_ENVIRONMENT.ANDROID)}
            >
              <ProfilesTable
                environment={APP_ENVIRONMENT.ANDROID}
                profiles={profiles[APP_ENVIRONMENT.ANDROID]}
                onClickProfileName={this.openProfileModal}
              />
            </SubsectionWithUpdatedStatus>
            <SubsectionWithUpdatedStatus
              headerContent={trans.APP_DETAILS_VERSION__LINUX_PROFILES()}
              isUpdatedFunc={this.areProfilesUpdated(APP_ENVIRONMENT.LINUX)}
            >
              <ProfilesTable
                environment={APP_ENVIRONMENT.LINUX}
                profiles={profiles[APP_ENVIRONMENT.LINUX]}
                onClickProfileName={this.openProfileModal}
              />
            </SubsectionWithUpdatedStatus>
            {openedProfile &&
              openedProfile.environment === APP_ENVIRONMENT.ANDROID && (
                <Formik
                  initialValues={{
                    name: openedProfile.name,
                    action: openedProfile.action,
                    package: openedProfile.packageName,
                    class: openedProfile.className,
                  }}
                >
                  <ProfileModal
                    appEnvironmentType={APP_ENVIRONMENT.ANDROID}
                    onClose={this.closeProfileModal}
                    readOnly={true}
                  />
                </Formik>
              )}
            {openedProfile &&
              openedProfile.environment === APP_ENVIRONMENT.LINUX && (
                <Formik
                  initialValues={{
                    name: openedProfile.name,
                    native_id: openedProfile.nativeId,
                  }}
                >
                  <ProfileModal
                    appEnvironmentType={APP_ENVIRONMENT.LINUX}
                    onClose={this.closeProfileModal}
                    readOnly={true}
                  />
                </Formik>
              )}
          </Fragment>
        )}
      </Section>
    );
  }
}
