// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { trans } from "src/translations";

import { CommandDetails } from "./CommandDetails/CommandDetailsData";
import { CommandsList } from "./CommandsList/CommandsListData";
import { CreateCommand } from "./CreateCommand";

export class DeviceCommandsRoute extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<CommandsList />} />
        <Route
          exact
          path="/:id"
          element={
            <ExtendedRoute
              name={trans.DEVICES__COMMAND_DETAILS_ROUTE_NAME()}
              component={CommandDetails}
            />
          }
        />
        <Route
          exact
          path="/create"
          element={
            <ExtendedRoute
              name={trans.DEVICES__COMMANDS_CREATE_ROUTE_NAME()}
              component={CreateCommand}
            />
          }
        />
        <Route exact path="*" element={<Navigate to="/404" />} />
      </Routes>
    );
  }
}
