// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import pick from "lodash-es/pick";
import set from "lodash-es/set";
import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { PageContentError } from "components/layout";
import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows, api } from "containers/Request";
import { trans } from "src/translations";
import { urlToBase64 } from "utils/converters";
import { withRouter } from "utils/decorators/withRouter";
import { prepareErrorsForForm } from "utils/errors";
import { dataFormatter } from "utils/jsonApi";

import { getAllApplications } from "../_utils/getAllApps";
import { ModeContext } from "./context";
import { PromotionDetailsForm } from "./PromotionDetailsForm";
import {
  adaptActionValueToBackend,
  adaptContentItemsToBackend,
  DEFAULT_CONFIGURATION,
  getConfiguration,
  prepareInitialValues,
} from "./utils";

/** CS-1961: BACKEND: depplink validation error source pointers should
 * be rewritten to allow for highlighing specific deeplink fields
 * ==== implement error propagation in UI when CS-1961 done
 * */
const jsonPointerToFieldName = (tabId) => ({
  "/data/attributes/name": "name",
  "/data/attributes/application_id": `apps[${tabId}].application`,
  "/data/attributes/icon": `apps[${tabId}].icon.url`,
  "/data/attributes/thumbnail": `apps[${tabId}].thumbnail.url`,
  "/data/attributes/title": `apps[${tabId}].title`,
  "/data/attributes/description": `apps[${tabId}].description`,
  "/data/attributes/background": `apps[${tabId}].background.url`,
  "/data/attributes/action": `apps[${tabId}].action`,
  "/data/attributes/metadata": `apps[${tabId}].json`,
});

@withRouter
@restrictedArea(({ forAdmin }) => {
  if (forAdmin) {
    return { allowed: [ROLES.administrator.promotionAdmin] };
  }
  return { allowed: [ROLES.promotionManagement.promotionManager] };
})
@connect(null, (dispatch, ownProps) => ({
  createPromotion: (body) => {
    const createPromotionAction = ownProps.forAdmin
      ? apiThatThrows.createPromotion.action
      : apiThatThrows.createMyPromotion.action;
    return dispatch(createPromotionAction({ options: { body } }));
  },
  updatePromotion: (id, body) => {
    const updatePromotionAction = ownProps.forAdmin
      ? apiThatThrows.updatePromotion.action
      : apiThatThrows.updateMyPromotion.action;
    return dispatch(
      updatePromotionAction({
        params: { id },
        options: { body },
      })
    );
  },
  getApplications: async (appIds, page = 1) => {
    if (appIds.length === 0) {
      return [];
    }

    const { results } = await dispatch(
      apiThatThrows.getApplicationsPublicInfoPaginated.action({
        queryParams: {
          public_id: appIds,
          limit: appIds.length,
          page,
        },
      })
    );

    return results;
  },
  getPromotion: async (id) => {
    const getPromotionAction = ownProps.forAdmin
      ? api.getPromotion.action
      : api.getMyPromotion.action;
    const { result, error } = await dispatch(
      getPromotionAction({
        params: { id },
        queryParams: { include: "promotion_details,promotion_type" },
      })
    );

    if (error) {
      throw error;
    }

    return result.results;
  },
  getCampaigns: (ids) =>
    dispatch(
      apiThatThrows.getCampaignsPaginated.action({
        queryParams: {
          public_id: ids,
          limit: "nolimit",
        },
      })
    ),
}))
export class PromotionDetailsData extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,

    // from @connect
    getApplications: PropTypes.func.isRequired,
    createPromotion: PropTypes.func.isRequired,
    updatePromotion: PropTypes.func.isRequired,
    getPromotion: PropTypes.func.isRequired,
    getCampaigns: PropTypes.func.isRequired,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
  };

  state = {
    isReadonly: false,
    isLoading: true,
    promotionDetails: undefined,
    error: "",
    ongoingCampaigns: [],
    configuration: DEFAULT_CONFIGURATION,
    initialValues: prepareInitialValues(),
  };

  isEdit() {
    const { params } = this.props;
    return typeof params.id !== "undefined";
  }

  componentDidMount() {
    if (this.isEdit()) {
      this.updateData();
    } else {
      this.setState({ isLoading: false });
    }
  }

  getPromotionId = () => this.props.params.id;

  getOngoingCampaigns = async (camaignsIds) => {
    const { getCampaigns } = this.props;

    if (!camaignsIds?.length) {
      return [];
    }

    const campaigns = await getCampaigns(camaignsIds);

    return campaigns?.results?.map((campaign) => ({
      id: campaign.id,
      name: campaign.name,
    }));
  };

  getPromotionDetails = async () => {
    const { getApplications, getPromotion, forAdmin } = this.props;

    const promotionDetailsAttrs = [
      "application_id",
      "background",
      "description",
      "icon",
      "thumbnail",
      "title",
      "action",
      "metadata",
      "assets",
      "order",
    ];

    const promotion = await getPromotion(this.getPromotionId());

    const camaignsIds = promotion?.campaigns?.map((campaign) => campaign.id);
    const appIds = uniq(
      promotion.promotion_details.map((detail) => detail.application_id)
    );

    const [ongoingCampaigns, apps] = await Promise.all([
      this.getOngoingCampaigns(camaignsIds),
      getAllApplications(appIds, getApplications),
    ]);

    return {
      isReadonly: promotion.admin_managed && !forAdmin,
      promotion,
      configuration: getConfiguration(promotion.promotion_type),
      promotionDetails: promotion.promotion_details.map((app) => ({
        ...pick(app, promotionDetailsAttrs),
        application: apps.find((a) => a.id === app.application_id),
      })),
      ongoingCampaigns,
    };
  };

  handleError = (error, appsCount, setErrors) => {
    let errors = {};
    for (let i = 0; i < appsCount; i++) {
      const submitErrors = prepareErrorsForForm(
        error,
        jsonPointerToFieldName(i)
      );
      errors = {
        ...errors,
        ...submitErrors,
      };
    }

    setErrors({
      ...errors,
      _submitFailed: true,
    });
  };

  async updateData() {
    this.setState({ isLoading: true, error: "" });

    try {
      const {
        configuration,
        promotion,
        promotionDetails,
        isReadonly,
        ongoingCampaigns,
      } = await this.getPromotionDetails();

      this.setState({
        isReadonly,
        initialValues: prepareInitialValues(
          configuration,
          promotion,
          promotionDetails
        ),
        configuration,
        promotionDetails,
        ongoingCampaigns,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  createBody = async (values) => {
    const { forAdmin } = this.props;

    const apps = await Promise.all(
      values.apps.map(async (app) => {
        const [icon, thumbnail, background, assets] = await Promise.all([
          app.icon ? urlToBase64(app.icon.url) : null,
          app.thumbnail ? urlToBase64(app.thumbnail.url) : null,
          app.background ? urlToBase64(app.background.url) : null,
          app.contentItems ? adaptContentItemsToBackend(app.contentItems) : [],
        ]);

        /**
         * Event though some properties might not be present in promotion
         * configuration - they have to be set to 'null' since backend
         * requires all of the fields to be present in request payload
         * TODO: consider if backend validation should be changed;
         */
        return {
          type: "promotion_details",
          attributes: {
            action: app.action
              ? adaptActionValueToBackend(app.action, app["actionCustomName"])
              : null,
            title: app.title ?? null,
            description: app.description ?? null,
            application_id: app.application ?? null,
            metadata: app.json ?? null,
            icon: icon,
            thumbnail: thumbnail,
            background: background,
            assets: assets,
          },
        };
      })
    );

    const data = {
      type: "promotion",
      name: values.name,
      admin_managed: forAdmin,
      promotion_type: {
        type: "promotion_type",
        id: values.type,
      },
      relationshipNames: ["promotion_type"],
    };

    if (this.getPromotionId()) {
      data.id = this.getPromotionId();
    }

    const body = dataFormatter.serialize({ stuff: data });

    set(body, "data.relationships.promotion_details.data", apps);

    return JSON.stringify(body);
  };

  submitPromotion = async (values, { setErrors, setStatus, setSubmitting }) => {
    const { navigate, forAdmin, updatePromotion, createPromotion } = this.props;

    const body = await this.createBody(values);

    try {
      if (this.isEdit()) {
        await updatePromotion(this.getPromotionId(), body);
      } else {
        await createPromotion(body);
      }

      setStatus(null);

      navigate(
        {
          pathname: `/promotions${forAdmin ? "/admin" : ""}/list`,
        },
        {
          state: { message: trans.PROMOTION_DETAILS__SUBMIT_SUCCESS() },
        }
      );
    } catch (error) {
      setSubmitting(false);
      this.handleError(error, values.apps.length, setErrors);
    }
  };

  onConfigurationUpdate = (type, values) => {
    this.setState({
      configuration: type.configuration,
      initialValues: {
        name: values.name,
        type: type.value,
        apps: prepareInitialValues(type.configuration).apps,
      },
    });
  };

  render() {
    const {
      configuration,
      isReadonly,
      initialValues,
      promotionDetails,
      ongoingCampaigns,
      error,
      isLoading,
    } = this.state;
    const { forAdmin } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return <PageContentError error={error} />;
    }

    return (
      <ModeContext.Provider
        value={{
          forAdmin,
          isEdit: this.isEdit(),
          isReadonly,
        }}
      >
        <PromotionDetailsForm
          submitPromotion={this.submitPromotion}
          promotionDetails={promotionDetails}
          initialValues={initialValues}
          configuration={configuration}
          onConfigurationUpdate={this.onConfigurationUpdate}
          ongoingCampaigns={ongoingCampaigns}
        />
      </ModeContext.Provider>
    );
  }
}
