// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Row, Column } from "components/layout";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import {
  IMG_TYPE_SCREENSHOT,
  IMG_TYPE_THUMBNAIL,
  IMG_TYPE_ICON_BIG,
  IMG_TYPE_ICON_SMALL,
  IMG_TYPE_BILLBOARD,
} from "../constants";
import { SubsectionWithUpdatedStatus } from "./_shared";
import { getImagesWithType } from "./_utils/assets";
import styles from "./Assets.scss";

export class Assets extends Component {
  static propTypes = {
    images: PropTypes.array,
  };

  state = {
    screenshotIdx: 0,
    currentImages: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (props.images !== state.currentImages) {
      return {
        screenshotIdx: 0,
        currentImages: props.images,
      };
    }
    return null;
  }

  handleChangeScreenshot(idx) {
    return () => {
      this.setState({ screenshotIdx: idx });
    };
  }

  getAllScreenshots() {
    const { images } = this.props;
    return images.filter((img) => img.type === IMG_TYPE_SCREENSHOT);
  }

  getImage(type) {
    const { images } = this.props;
    return images.find((img) => img.type === type);
  }

  getCurrentScreenshot() {
    const { screenshotIdx } = this.state;
    const screenshots = this.getAllScreenshots();
    return screenshots[screenshotIdx];
  }

  renderScreenshotNavigation() {
    const { screenshotIdx } = this.state;

    const renderImageNumber = (idx) => {
      const className = classes(styles.item, {
        [styles.active]: screenshotIdx === idx,
      });

      return (
        <li
          key={idx}
          className={className}
          onClick={this.handleChangeScreenshot(idx)}
        >
          {idx + 1}
        </li>
      );
    };

    return (
      <span>
        {trans.APP_DETAILS_VERSION__SCREENSHOTS()}
        <ul className={styles.screenshotNavigation}>
          {this.getAllScreenshots().map((_, idx) => renderImageNumber(idx))}
        </ul>
      </span>
    );
  }

  renderImage(image) {
    return image ? (
      <div>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={image.image} />
        </div>
        <div className={styles.assetDownload}>
          <Button
            className={styles.downloadButton}
            to={image.image}
            download={image.image}
          >
            {trans.DOWNLOAD()}
          </Button>
        </div>
      </div>
    ) : null;
  }

  isImageUpdated = (imageType) => (activeVersion, latestVersion) => {
    const activeImages = getImagesWithType(activeVersion, imageType);
    const latestImages = getImagesWithType(latestVersion, imageType);

    return !isEqual(activeImages.slice().sort(), latestImages.slice().sort());
  };

  render() {
    const billboard = this.getImage(IMG_TYPE_BILLBOARD);

    return (
      <Fragment>
        <Row>
          <Column>
            <SubsectionWithUpdatedStatus
              headerContent={trans.APP_DETAILS_VERSION__ICON_THUMBNAIL()}
              isUpdatedFunc={this.isImageUpdated(IMG_TYPE_THUMBNAIL)}
              subsectionClassName={styles.assetsSubsection}
            >
              {this.renderImage(this.getImage(IMG_TYPE_THUMBNAIL))}
            </SubsectionWithUpdatedStatus>
          </Column>
          <Column>
            <SubsectionWithUpdatedStatus
              headerContent={trans.APP_DETAILS_VERSION__ICON_BIG()}
              isUpdatedFunc={this.isImageUpdated(IMG_TYPE_ICON_BIG)}
              subsectionClassName={styles.assetsSubsection}
            >
              {this.renderImage(this.getImage(IMG_TYPE_ICON_BIG))}
            </SubsectionWithUpdatedStatus>
          </Column>
          <Column>
            <SubsectionWithUpdatedStatus
              headerContent={trans.APP_DETAILS_VERSION__ICON_SMALL()}
              isUpdatedFunc={this.isImageUpdated(IMG_TYPE_ICON_SMALL)}
              subsectionClassName={styles.assetsSubsection}
            >
              {this.renderImage(this.getImage(IMG_TYPE_ICON_SMALL))}
            </SubsectionWithUpdatedStatus>
          </Column>
          <Column>
            <SubsectionWithUpdatedStatus
              headerContent={this.renderScreenshotNavigation()}
              isUpdatedFunc={this.isImageUpdated(IMG_TYPE_SCREENSHOT)}
              subsectionClassName={styles.assetsSubsection}
            >
              {this.renderImage(this.getCurrentScreenshot())}
            </SubsectionWithUpdatedStatus>
          </Column>
        </Row>
        {billboard && (
          <Row>
            <Column>
              <SubsectionWithUpdatedStatus
                headerContent={trans.APP_DETAILS_VERSION__ICON_BILLBOARD()}
                isUpdatedFunc={this.isImageUpdated(IMG_TYPE_BILLBOARD)}
                subsectionClassName={styles.assetsSubsection}
              >
                {this.renderImage(billboard)}
              </SubsectionWithUpdatedStatus>
            </Column>
            <Column />
          </Row>
        )}
      </Fragment>
    );
  }
}
