// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const listProcessingQueueQuery = gql`
  query processingSoftwarePackages(
    $pagination: PaginationInput
    $search: SearchInput
    $sort: ProcessingSoftwarePackagesSortInput
  ) {
    processingSoftwarePackages(
      pagination: $pagination
      search: $search
      sort: $sort
    ) {
      processingSoftwarePackages {
        items {
          id
          name
          status
          errorMessage
          fileUrl
          uploadDate
          softwarePackageVersion {
            id
            softwarePackage {
              id
            }
          }
        }
        pagination {
          count
        }
      }
    }
  }
`;
