// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import appData from "./appDataTab";
import comments from "./commentsTab";
import marketsAndMonetization from "./marketsAndMonetizationTab";
import terms from "./termsTab";

export default {
  name: "generic",
  tabs: [appData, comments, marketsAndMonetization, terms],
};
