// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import camelCase from "lodash-es/camelCase";
import mergeWith from "lodash-es/mergeWith";

// deepMerge : a -> a -> a
export const deepMerge = <TObject1 = unknown, TObject2 = unknown>(
  a: TObject1,
  b: TObject2
): TObject1 & TObject2 => {
  const customizer = (a: TObject1, b: TObject2) => {
    if (typeof a === "object" && typeof b === "object") {
      if (Array.isArray(a)) {
        return Object.values(deepMerge(a, b));
      }

      return deepMerge(a, b);
    }

    return b;
  };

  return mergeWith({}, a, b, customizer);
};

export const isEmpty = <T>(data: T): boolean => {
  if (Array.isArray(data)) {
    return data.length === 0;
  }

  if (typeof data === "object" && data !== null) {
    return Object.keys(data).length === 0;
  }

  console.error("Data has to be an array or an object");
  return false;
};

export const camelCaseKeys = <T = unknown>(
  data: Record<string, T>
): Record<string, T> => {
  return Object.entries(data).reduce<Record<string, T>>((acc, [key, val]) => {
    acc[camelCase(key)] = val;
    return acc;
  }, {});
};

export const createObjectWithKeys = <T = unknown>(
  keys: string[],
  value: T
): Record<string, T> => {
  return keys.reduce<Record<string, T>>((acc, key) => {
    acc[key] = value;
    return acc;
  }, {});
};
