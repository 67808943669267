// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createContext } from "react";

const PreviousLocationContext = createContext({});

export const PreviousLocationProvider = PreviousLocationContext.Provider;
export const PreviousLocationConsumer = PreviousLocationContext.Consumer;
