// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { API_URLS } from "config";
import lodashGet from "lodash-es/get";

import { request } from "./actions";
import { get, post, patch, put, del } from "./requests";

export const INITIAL_PAGINATION_QUERY_PARAMS = {
  limit: 50,
  page: 1,
};

const snapEndpointsOptions = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

const endpoints = {
  // AUTH BAKER
  updateCookieNoRedirect: {
    method: get,
    path: API_URLS.AUTH_BAKER + "/update_cookie_no_redirect",
  },
  switchOrganizationNoRedirect: {
    method: get,
    path: API_URLS.AUTH_BAKER + "/switch_organization_no_redirect",
  },

  // DEVICE MODELS
  getDeviceModelsPaginated: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/device_models",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  createDeviceModel: {
    method: post,
    path: API_URLS.DEVICE_MGMT + "/device_models",
  },
  getDeviceModel: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/device_models/:id",
  },
  getMyDeviceModel: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/my/device_models/:id",
  },
  updateDeviceModel: {
    method: patch,
    path: API_URLS.DEVICE_MGMT + "/device_models/:id",
  },

  // DEVICE FAMILIES
  getDeviceFamiliesPaginated: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/device_families",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getDeviceFamily: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/device_families/:id",
  },
  getMyDeviceFamily: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/my/device_families/:id",
  },
  createDeviceFamily: {
    method: post,
    path: API_URLS.DEVICE_MGMT + "/device_families",
  },
  updateDeviceFamily: {
    method: patch,
    path: API_URLS.DEVICE_MGMT + "/device_families/:id",
  },

  // DEVICE PAIRING
  pairDevice: {
    method: post,
    path: API_URLS.USER_CATALOGUE + "/my/pairings",
  },
  unpairDevice: {
    method: del,
    path: API_URLS.USER_CATALOGUE + "/my/pairings/:pairId",
  },
  getPairedDevicesPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/my/pairings",
  },

  // VIDEO FORMAT TYPES
  getVideoFormatTypesPaginated: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/video_format_types",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },

  // DRM FORMAT TYPES
  getStreamingFormatTypesPaginated: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/streaming_format_types",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },

  // DRM FORMAT TYPES
  getDrmFormatTypesPaginated: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/drm_format_types",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },

  // DEVICE OWNER
  getDeviceOwnersPaginated: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/owners",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getDeviceOwners: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/owners",
    initial: {
      queryParams: { limit: "nolimit" },
    },
  },
  getDeviceOwner: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/owners/:id",
  },
  getMyFamiliesPaginated: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/my/device_families",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMyDeviceModelsPaginated: {
    method: get,
    path: API_URLS.DEVICE_MGMT + "/my/device_models",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },

  // ELASTIC-SEARCH APP-LIST
  getAvailableFilters: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/moderation/applications/filters",
  },
  searchApplicationsPaginated: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/moderation/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getFiltersSugestions: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/moderation/applications/suggestions",
  },
  getDistributionApplicationsPaginated: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/distribution/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getDistributionApplicationsFilters: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/distribution/applications/filters",
  },
  getDistributionApplicationsSuggestions: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/distribution/applications/suggestions",
  },
  getMyDistributionApplicationsPaginated: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/my/distribution/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMyDistributionAvailableApplicationsPaginated: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/my/distribution/available_applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },

  getMyDistributionApplicationsFilters: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/my/distribution/applications/filters",
  },
  getMyDistributionApplicationsSuggestions: {
    method: get,
    path: API_URLS.ELASTIC_SEARCH + "/my/distribution/applications/suggestions",
  },
  exportMyDistributionApplicationsPaginated: {
    method: post,
    path: API_URLS.ELASTIC_SEARCH + "/my/distribution/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  exportDistributionApplicationsPaginated: {
    method: post,
    path: API_URLS.ELASTIC_SEARCH + "/distribution/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  exportDeviceModelApplicationsPaginated: {
    method: post,
    path: API_URLS.ELASTIC_SEARCH + "/distribution/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  exportMyDeviceModelApplicationsPaginated: {
    method: post,
    path: API_URLS.ELASTIC_SEARCH + "/my/distribution/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },

  // APP-INVENTORY
  getApplicationsPaginated: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getApplicationsPublicInfoPaginated: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/applications/public_info",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMyApplicationsPaginated: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/applications/my_list",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getApplication: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/applications/:id",
  },
  getApplicationPublicInfo: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/applications/:id/public_info",
  },
  getApplicationDraft: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/applications/:app_id/draft_version",
  },
  getApplicationTestVersion: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/applications/:app_id/test_version",
  },
  getApplicationFullVersionsList: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/applications/:app_id/full_versions",
  },
  getApplicationDraftValidation: {
    method: post,
    path:
      API_URLS.APP_INVENTORY + "/applications/:app_id/draft_version/validate",
  },
  createApplicationDraft: {
    method: post,
    path: API_URLS.APP_INVENTORY + "/draft_versions",
  },
  createTestApplication: {
    method: post,
    path: API_URLS.APP_INVENTORY + "/test_versions",
  },
  deleteApplicationDraft: {
    method: del,
    path: API_URLS.APP_INVENTORY + "/applications/:app_id/draft_version",
  },
  createApplication: {
    method: post,
    path: API_URLS.APP_INVENTORY + "/applications",
  },
  updateApplication: {
    method: put,
    path: API_URLS.APP_INVENTORY + "/applications/:app_id",
  },
  setExclusive: {
    method: patch,
    path: API_URLS.APP_INVENTORY + "/applications/:id/set_exclusive",
  },
  submitApplicationFullVersion: {
    method: post,
    path: API_URLS.APP_INVENTORY + "/full_versions/submit",
  },
  getApplicationFullVersion: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/full_versions/:version_id",
  },
  getApplicationCategoriesPaginated: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/categories",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getVideoFormatsPaginated: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/video_formats",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getVideoDrmsPaginated: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/video_drms",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getVideoAdaptiveStreamingPaginated: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/video_adaptive_streaming",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getLanguages: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/languages",
  },
  getCountries: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/countries",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getTopMarkets: {
    method: get,
    path: API_URLS.APP_INVENTORY + "/applications/:id/top_annotation",
  },
  setTopMarkets: {
    method: post,
    path: API_URLS.APP_INVENTORY + "/applications/:id/top_annotation",
  },
  setAppEnvironmentStatus: {
    method: post,
    path: API_URLS.APP_INVENTORY + "/app_environments/:id/assess",
  },
  changeAppName: {
    method: patch,
    path: API_URLS.APP_INVENTORY + "/applications/:id/rename",
  },

  // OLD ENDPOINTS
  getAssetsPaginated: {
    method: get,
    path: API_URLS.CONTENT_MGMT + "/software_assets?include=versions",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  setDefaultAssetVersion: {
    method: post,
    path:
      API_URLS.CONTENT_MGMT +
      "/software_assets/:assetId/versions/:versionNumber/defaulted",
  },
  postAssetRegions: {
    method: post,
    path:
      API_URLS.CONTENT_MGMT +
      "/software_assets/:assetId/versions/:assetVersion/models/:deviceModel/countries",
  },
  postAssetRegionsWorldwide: {
    method: post,
    path:
      API_URLS.CONTENT_MGMT +
      "/software_assets/:assetId/versions/:assetVersion/models/:deviceModel/worldwided",
  },
  getAssetDeviceModelsPaginated: {
    method: get,
    path:
      API_URLS.CONTENT_MGMT +
      "/software_assets/:assetId/versions/:assetVersion/models",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  postAssetDeviceModels: {
    method: post,
    path:
      API_URLS.CONTENT_MGMT +
      "/software_assets/:assetId/versions/:assetVersion/models",
  },
  getAsset: {
    method: get,
    path: API_URLS.CONTENT_MGMT + "/software_assets/:assetId",
  },
  patchAsset: {
    method: patch,
    path: API_URLS.CONTENT_MGMT + "/software_assets/:assetId",
  },
  postAsset: {
    method: post,
    path: API_URLS.CONTENT_MGMT + "/software_assets",
  },
  getAssetVersionsPaginated: {
    method: get,
    path: API_URLS.CONTENT_MGMT + "/software_assets/:assetId/versions",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  postAssetVersions: {
    method: post,
    path: API_URLS.CONTENT_MGMT + "/software_assets/:assetId/versions",
  },
  postDefaultAssetVersion: {
    method: post,
    path:
      API_URLS.CONTENT_MGMT +
      "/software_assets/:assetId/versions/:version/defaulted",
  },
  getPromotionsPaginated: {
    method: get,
    path: API_URLS.PROMOTIONS + "/promotions",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMyPromotionsPaginated: {
    method: get,
    path: API_URLS.PROMOTIONS + "/my/promotions",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  createPromotion: {
    method: post,
    path: API_URLS.PROMOTIONS + "/promotions",
  },
  createMyPromotion: {
    method: post,
    path: API_URLS.PROMOTIONS + "/my/promotions",
  },
  updatePromotion: {
    method: patch,
    path: API_URLS.PROMOTIONS + "/promotions/:id",
  },
  updateMyPromotion: {
    method: patch,
    path: API_URLS.PROMOTIONS + "/my/promotions/:id",
  },
  getPromotion: {
    method: get,
    path: API_URLS.PROMOTIONS + "/promotions/:id",
  },
  getMyPromotion: {
    method: get,
    path: API_URLS.PROMOTIONS + "/my/promotions/:id",
  },
  removePromotion: {
    method: del,
    path: API_URLS.PROMOTIONS + "/promotions/:id",
  },
  removeMyPromotion: {
    method: del,
    path: API_URLS.PROMOTIONS + "/my/promotions/:id",
  },
  getCampaignsPaginated: {
    method: get,
    path: API_URLS.PROMOTIONS + "/campaigns",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMyCampaignsPaginated: {
    method: get,
    path: API_URLS.PROMOTIONS + "/my/campaigns",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  createCampaign: {
    method: post,
    path: API_URLS.PROMOTIONS + "/campaigns",
  },
  createMyCampaign: {
    method: post,
    path: API_URLS.PROMOTIONS + "/my/campaigns",
  },
  patchCampaign: {
    method: patch,
    path: API_URLS.PROMOTIONS + "/campaigns/:id",
  },
  patchMyCampaign: {
    method: patch,
    path: API_URLS.PROMOTIONS + "/my/campaigns/:id",
  },
  deleteCampaign: {
    method: del,
    path: API_URLS.PROMOTIONS + "/campaigns/:id",
  },
  deleteMyCampaign: {
    method: del,
    path: API_URLS.PROMOTIONS + "/my/campaigns/:id",
  },
  getCampaignTargetsPaginated: {
    method: get,
    path: API_URLS.PROMOTIONS + "/campaigns/:campaignId/targets",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMyCampaignTargetsPaginated: {
    method: get,
    path: API_URLS.PROMOTIONS + "/my/campaigns/:campaignId/targets",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  deleteCampaignTarget: {
    method: del,
    path: API_URLS.PROMOTIONS + "/campaigns/:campaignId/targets/:id",
  },
  getCampaingsFilters: {
    method: get,
    path: API_URLS.PROMOTIONS + "/campaigns/filters",
  },
  getMyCampaingsFilters: {
    method: get,
    path: API_URLS.PROMOTIONS + "/my/campaigns/filters",
  },

  // DISTRIBUTION PLANS
  getMyDistributionPlansPaginated: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/my/plans",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  createMyDistributionPlan: {
    method: post,
    path: API_URLS.APP_DISTRIBUTION + "/my/plans",
  },
  createDistributionPlan: {
    method: post,
    path: API_URLS.APP_DISTRIBUTION + "/plans",
  },
  getMyDistributionPlan: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/my/plans/:id",
  },
  getDefaultDistributionPlan: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/default_plans/:id",
  },
  updateDefaultDistributionPlan: {
    method: patch,
    path: API_URLS.APP_DISTRIBUTION + "/default_plans/:planId",
  },
  getMyDefaultDistributionPlan: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/my/default_plan",
  },
  updateMyDistributionPlan: {
    method: patch,
    path: API_URLS.APP_DISTRIBUTION + "/my/plans/:id",
  },
  updateDistributionPlan: {
    method: patch,
    path: API_URLS.APP_DISTRIBUTION + "/plans/:id",
  },
  updateMyDefaultDistributionPlan: {
    method: patch,
    path: API_URLS.APP_DISTRIBUTION + "/my/default_plan",
  },
  removeMyDistributionPlan: {
    method: del,
    path: API_URLS.APP_DISTRIBUTION + "/my/plans/:id",
  },
  getTargetOfMyDistributionPlan: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/my/plans/:planId/targets/:targetId",
  },
  getTargetOfDistributionPlan: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/plans/:planId/targets/:targetId",
  },
  getTargetOfDefaultDistributionPlan: {
    method: get,
    path:
      API_URLS.APP_DISTRIBUTION + "/default_plans/:planId/targets/:targetId",
  },
  getTargetOfMyDefaultDistributionPlan: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/my/default_plan/targets/:targetId",
  },
  createMyDistributionTarget: {
    method: post,
    path: API_URLS.APP_DISTRIBUTION + "/my/plans/:planId/targets",
  },
  createMyDefaultDistributionTarget: {
    method: post,
    path: API_URLS.APP_DISTRIBUTION + "/my/default_plan/targets",
  },
  getDistributedApplicationsPaginated: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/distributed_applications",
  },
  getDistributionPlansPaginated: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/plans",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  updateAppStatusesForDefaultPlan: {
    method: patch,
    path: API_URLS.APP_DISTRIBUTION + "/my/default_plan/applications",
  },
  getAppStatusesForDefaultPlanPaginated: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/my/default_plan/applications",
  },
  getDefaultPlanApplicationsPaginated: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/default_plans/:planId/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getDistributionPlan: {
    method: get,
    path: API_URLS.APP_DISTRIBUTION + "/plans/:planId",
  },
  postBulkCustomPlanApplications: {
    method: post,
    path: API_URLS.APP_DISTRIBUTION + "/my/bulk/plans/applications",
  },

  getCampaign: {
    method: get,
    path: API_URLS.PROMOTIONS + "/campaigns/:id",
  },
  getMyCampaign: {
    method: get,
    path: API_URLS.PROMOTIONS + "/my/campaigns/:id",
  },

  // MODERATION
  getQASummariesPaginated: {
    method: get,
    path: API_URLS.MODERATION + "/qa_summaries",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  patchQASummary: {
    method: patch,
    path: API_URLS.MODERATION + "/qa_summaries/:id",
  },
  getModerationResultsPaginated: {
    method: get,
    path: API_URLS.MODERATION + "/moderation_results",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getActiveModerationResultsPaginated: {
    method: get,
    path: API_URLS.MODERATION + "/moderation_results/active",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getModerationResult: {
    method: get,
    path: API_URLS.MODERATION + "/moderation_results/:id",
  },
  patchModerationResults: {
    method: patch,
    path: API_URLS.MODERATION + "/moderation_results/:id",
  },
  startModeration: {
    method: put,
    path: API_URLS.MODERATION + "/moderation_results/:id/start_moderation",
  },
  doneModeration: {
    method: put,
    path: API_URLS.MODERATION + "/moderation_results/:id/moderation_done",
  },
  approveModeration: {
    method: put,
    path: API_URLS.MODERATION + "/moderation_results/:id/approve",
  },
  rejectModeration: {
    method: put,
    path: API_URLS.MODERATION + "/moderation_results/:id/reject",
  },
  getSalesforceAgreementsPaginated: {
    method: get,
    path: API_URLS.MODERATION + "/salesforce_contract",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  postSalesforceAgreement: {
    method: post,
    path: API_URLS.MODERATION + "/salesforce_contract",
  },
  deleteSalesforceAgreement: {
    method: del,
    path: API_URLS.MODERATION + "/salesforce_contract/:id",
  },
  patchCertification: {
    method: patch,
    path: API_URLS.MODERATION + "/certification_results/:id",
  },

  getCertificationResults: {
    method: get,
    path: API_URLS.MODERATION + "/certification_results/:id",
  },

  getSDKVersionsPaginated: {
    method: get,
    path: API_URLS.MODERATION + "/sdks",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  exportModerationApplicationsPaginated: {
    method: post,
    path: API_URLS.ELASTIC_SEARCH + "/moderation/applications",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },

  // MESSAGE_CENTER
  getStorageCredentialsAttachments: {
    method: get,
    path: API_URLS.MESSAGE_CENTER + "/storage_credentials/attachments",
  },
  getDashboardMessagesPaginated: {
    method: get,
    path: API_URLS.MESSAGE_CENTER + "/messages",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMessagesPaginated: {
    method: get,
    path: API_URLS.MESSAGE_CENTER + "/messages/:applicationId",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },

  getMessageFilters: {
    method: get,
    path: API_URLS.MESSAGE_CENTER + "/messages/:applicationId/filters",
  },

  createMessage: {
    method: post,
    path: API_URLS.MESSAGE_CENTER + "/messages/:applicationId",
  },

  // USER CATALOGUE
  getCurrentUser: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/users/me",
  },
  getUserGrantsPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/my/users/:userId/grants",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getAllUserGrantsPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/users/:userId/grants",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  changeUserGrants: {
    method: post,
    path: API_URLS.USER_CATALOGUE + "/my/users/:userId/grants",
  },
  removeUserGrant: {
    method: del,
    path: API_URLS.USER_CATALOGUE + "/my/users/:userId/grants/:grantId",
  },
  getRoles: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/roles",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getOrganizationsPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/organizations",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMyOrganizationsPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/my/organizations",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getOrganization: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/organizations/:organizationId",
  },
  getMyOrganization: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/my/organizations/:organizationId",
  },
  getOrganizationPublicInfo: {
    method: get,
    path:
      API_URLS.USER_CATALOGUE + "/organizations/:organizationId/public_info",
  },
  createOrganization: {
    method: post,
    path: API_URLS.USER_CATALOGUE + "/organizations",
  },
  updateOrganization: {
    method: patch,
    path: API_URLS.USER_CATALOGUE + "/organizations/:organizationId",
  },
  updateMyOrganization: {
    method: patch,
    path: API_URLS.USER_CATALOGUE + "/my/organizations/:organizationId",
  },
  upgradeOrganizationPlan: {
    method: put,
    path:
      API_URLS.USER_CATALOGUE +
      "/organizations/:organizationId/organization_plan/:organizationPlan",
  },
  getAllOrganizationTypesPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/types",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getTypesForOrganizationPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/organizations/:organizationId/types",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getTypesForMyOrganizationPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/my/types",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  putTypeForOrganization: {
    method: put,
    path: API_URLS.USER_CATALOGUE + "/organizations/:organizationId/types",
  },
  removeTypeFromOrganization: {
    method: del,
    path:
      API_URLS.USER_CATALOGUE +
      "/organizations/:organizationId/types/:organizationType",
  },
  getUsersPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/users",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMyUsersPaginated: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/my/users",
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getUserProfileNoOrganization: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/users/:id",
  },
  getUserProfile: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/my/users/:id",
  },
  patchUserProfile: {
    method: patch,
    path: API_URLS.USER_CATALOGUE + "/my/users/:id",
  },
  patchUserProfileNoOrganization: {
    method: patch,
    path: API_URLS.USER_CATALOGUE + "/users/:id",
  },
  inviteUserToOrganization: {
    method: post,
    path: API_URLS.USER_CATALOGUE + "/my/invitations",
  },
  getUserGrantsList: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/users/:id/all-grants",
  },
  setUserGrantsList: {
    method: put,
    path: API_URLS.USER_CATALOGUE + "/users/:id/all-grants",
  },
  getUsersFilterSuggestions: {
    method: get,
    path: API_URLS.USER_CATALOGUE + "/users/filters",
  },

  //SNAP API
  discardDraft: {
    method: post,
    path: `${API_URLS.SNAP_API}/apps/:id/discard-draft/`,
    options: snapEndpointsOptions,
  },
  setSubmissionStartedStatus: {
    method: post,
    path: `${API_URLS.SNAP_API}/apps/:id/revisions/:revision_id/submission-started/`,
    options: snapEndpointsOptions,
  },
  getSnapApplicationsPaginated: {
    method: get,
    path: `${API_URLS.SNAP_API}/apps/`,
    options: snapEndpointsOptions,
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getSnapApplication: {
    method: get,
    path: `${API_URLS.SNAP_API}/apps/:id/`,
    options: snapEndpointsOptions,
  },
  getSnapRevisionsList: {
    method: get,
    path: `${API_URLS.SNAP_API}/apps/:id/revisions/`,
    options: snapEndpointsOptions,
  },
  getLatestSubmittedRevision: {
    method: get,
    path: `${API_URLS.SNAP_API}/apps/:id/revisions/latest-submitted/`,
    options: snapEndpointsOptions,
  },
  getLatestRevision: {
    method: get,
    path: `${API_URLS.SNAP_API}/apps/:id/revisions/latest/`,
    options: snapEndpointsOptions,
  },
  createSnapApplication: {
    method: post,
    path: `${API_URLS.SNAP_API}/apps/`,
    options: snapEndpointsOptions,
  },
  createSnapRevision: {
    method: post,
    path: `${API_URLS.SNAP_API}/apps/:id/revisions/`,
    options: snapEndpointsOptions,
  },

  // PROMOTIONS
  getPromotionType: {
    method: get,
    path: `${API_URLS.PROMOTIONS}/promotion_types/:id`,
  },
  getMyPromotionType: {
    method: get,
    path: `${API_URLS.PROMOTIONS}/my/promotion_types/:id`,
  },
  createPromotionType: {
    method: post,
    path: `${API_URLS.PROMOTIONS}/promotion_types`,
  },
  updatePromotionType: {
    method: patch,
    path: `${API_URLS.PROMOTIONS}/promotion_types/:id`,
  },
  getPromotionTypesPaginated: {
    method: get,
    path: `${API_URLS.PROMOTIONS}/promotion_types`,
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  getMyPromotionTypesPaginated: {
    method: get,
    path: `${API_URLS.PROMOTIONS}/my/promotion_types`,
    initial: {
      queryParams: INITIAL_PAGINATION_QUERY_PARAMS,
    },
  },
  removePromotionType: {
    method: del,
    path: `${API_URLS.PROMOTIONS}/promotion_types/:id`,
  },
  getPromotionDetailsList: {
    method: get,
    path: `${API_URLS.PROMOTIONS}/promotions/:promotionId/promotion_details`,
  },
  getMyPromotionDetailsList: {
    method: get,
    path: `${API_URLS.PROMOTIONS}/my/promotions/:promotionId/promotion_details`,
  },
  getPromotionDetails: {
    method: get,
    path: `${API_URLS.PROMOTIONS}/promotions/:promotionId/promotion_details/:promotionDetailsId`,
  },
  getMyPromotionDetails: {
    method: get,
    path: `${API_URLS.PROMOTIONS}/my/promotions/:promotionId/promotion_details/:promotionDetailsId`,
  },
  createPromotionDetails: {
    method: post,
    path: `${API_URLS.PROMOTIONS}/promotions/:promotionId/promotion_details`,
  },
  createMyPromotionDetails: {
    method: post,
    path: `${API_URLS.PROMOTIONS}/my/promotions/:promotionId/promotion_details`,
  },
  updatePromotionDetails: {
    method: patch,
    path: `${API_URLS.PROMOTIONS}/promotions/:promotionId/promotion_details/:promotionDetailsId`,
  },
  updateMyPromotionDetails: {
    method: patch,
    path: `${API_URLS.PROMOTIONS}/my/promotions/:promotionId/promotion_details/:promotionDetailsId`,
  },
  deletePromotionDetails: {
    method: del,
    path: `${API_URLS.PROMOTIONS}/promotions/:promotionId/promotion_details/:promotionDetailsId`,
  },
};

const prepareApiObject = (endpoints, key, throwsErrors = false) => {
  const initialParams = lodashGet(endpoints, `${key}.initial.params`);
  const initialQueryParams = lodashGet(endpoints, `${key}.initial.queryParams`);

  return {
    name: key,
    method: endpoints[key].method.func,
    methodName: endpoints[key].method.name,
    path: endpoints[key].path,
    action: ({ params, queryParams, cache, options, abortSignal } = {}) => {
      return request(
        endpoints[key].method.name,
        endpoints[key].method.func,
        endpoints[key].path,
        { ...initialParams, ...params },
        { ...initialQueryParams, ...queryParams },
        cache,
        {
          ...endpoints[key].options,
          ...options,
        },
        throwsErrors,
        abortSignal
      );
    },
  };
};

const api = {};
const apiThatThrows = {};
Object.keys(endpoints).forEach((key) => {
  api[key] = prepareApiObject(endpoints, key);
  apiThatThrows[key] = prepareApiObject(endpoints, key, true);
});
export { api, apiThatThrows }; // api action creators
