// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./scrollButton.scss";

export class ScrollButton extends Component {
  static propTypes = {
    direction: PropTypes.oneOf(["left", "right"]),
    onScroll: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    direction: "left",
    onScroll: Function.prototype,
    disabled: false,
  };

  state = {
    requestId: null,
  };

  componentWillUnmount = () => {
    this.stopScroll();
  };

  startScroll = () => {
    const { disabled, onScroll } = this.props;

    if (!disabled) {
      this.setState({ requestId: requestAnimationFrame(this.startScroll) });
      onScroll();
    }
  };

  stopScroll = () => {
    if (this.state.requestId) {
      cancelAnimationFrame(this.state.requestId);
      this.setState({ requestId: null });
    }
  };

  render() {
    const { direction, disabled, className } = this.props;

    return (
      <div
        className={classes(styles[direction], className, {
          [styles.disabled]: disabled,
        })}
        onMouseDown={this.startScroll}
        onMouseUp={this.stopScroll}
        onMouseLeave={this.stopScroll}
      >
        <div className={styles.arrow} />
      </div>
    );
  }
}

export default ScrollButton;
