// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { TranslationKeysType } from "translations/langs/def_lang";

import { DEFAULT_LANG_KEY } from "./constants";
import { getAllTranslations } from "./getAllTranslations";

export type TranslateFunction = (
  placeholders?: Record<string, string | number>
) => string;

const replacePlaceholders = (
  text: string,
  placeholders: Record<string, string | number>
): string => {
  let newText = text;
  Object.entries(placeholders).forEach(([key, value]) => {
    newText = newText.replace(`#{${key}}`, value?.toString());
  });
  return newText;
};

const createTranslationFunc =
  (id: TranslationKeysType, text: string): TranslateFunction =>
  (placeholders?: Record<string, string | number>) => {
    if (!text) {
      const allTrans = getAllTranslations();
      return allTrans[DEFAULT_LANG_KEY][id] || "";
    }

    if (!placeholders) {
      return text;
    }

    return replacePlaceholders(text, placeholders);
  };

export const getAllTranslationsAsFunc = (): Record<
  string,
  Record<TranslationKeysType, TranslateFunction>
> => {
  const allTransAsFunc: Record<
    string,
    Record<TranslationKeysType, TranslateFunction>
  > = {};
  const allTrans = getAllTranslations();

  for (const [lang, langTrans] of Object.entries(allTrans)) {
    allTransAsFunc[lang] = {} as Record<TranslationKeysType, TranslateFunction>;

    const langTransAndDefaults = {
      ...allTrans[DEFAULT_LANG_KEY],
      ...langTrans,
    };

    for (const [id, text] of Object.entries(langTransAndDefaults)) {
      allTransAsFunc[lang][id as TranslationKeysType] = createTranslationFunc(
        id as TranslationKeysType,
        text
      );
    }
  }

  return allTransAsFunc;
};
