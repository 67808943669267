// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createFieldValidator, isRequired } from "utils/validation";

export const createMixedValidator = (name, required, validate) => {
  const customValidator = (value) =>
    validate && (required || value) ? validate(value) : undefined;

  const requiredValidator = required
    ? createFieldValidator(name, isRequired)
    : () => undefined;

  return (value) => customValidator(value) || requiredValidator(value);
};

export const getErrorId = (name) => name + "-validation-error";

export const getAriaProps = ({ name, error, touched }) => {
  const hasError = error && touched;
  return {
    "aria-invalid": hasError,
    ...(hasError ? { "aria-errormessage": getErrorId(name) } : {}),
  };
};
