// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Field } from "formik";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { SelectField } from "components/form/fields";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

@connect(null, (dispatch) => ({
  getDistributors: () =>
    dispatch(
      apiThatThrows.getOrganizationsPaginated.action({
        queryParams: { type: "Distributor", limit: "nolimit" },
      })
    ),
}))
export class DistributorField extends Component {
  static propTypes = {
    disabled: PropTypes.bool,

    // from @connect
    getDistributors: PropTypes.func.isRequired,
  };

  state = {
    distributors: [],
    loadingCount: 0,
    error: null,
  };

  componentDidMount = () => {
    this.updateData();
  };

  updateData = async () => {
    const { getDistributors } = this.props;

    try {
      this.setState((prevState) => ({
        loadingCount: prevState.loadingCount + 1,
        error: null,
      }));

      const { results } = await getDistributors();

      const distributors = results;

      this.setState((prevState) => ({
        loadingCount: prevState.loadingCount - 1,
        distributors: distributors.map((d) => ({ id: d.id, name: d.name })),
      }));
    } catch (err) {
      this.setState((prevState) => ({
        loadingCount: prevState.loadingCount - 1,
        error: err.message,
      }));
    }
  };

  prepareDistributorList = () => {
    const { distributors } = this.state;

    return distributors.map((d) => ({
      value: d.id,
      text: d.name,
    }));
  };

  renderField = ({ disabled, field: { name } }) => {
    return (
      <SelectField
        name={name}
        initOption={{
          value: "",
          text: trans.DISTR_PLAN__FIELD_DISTRIBUTOR_PLACEHOLDER(),
        }}
        label={trans.DISTR_PLAN__FIELD_DISTRIBUTOR()}
        values={this.prepareDistributorList()}
        disabled={disabled}
      />
    );
  };

  render() {
    const { disabled } = this.props;
    const { loadingCount, error } = this.state;

    if (Boolean(loadingCount)) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error}</Info>;
    }

    return (
      <Field
        name="distributor"
        component={this.renderField}
        disabled={disabled}
      />
    );
  }
}
