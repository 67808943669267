import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Loader, CodeEditor } from "components/elements";
import {
  PageHeader,
  PageContent,
  PageError,
  Subsection,
  Section,
} from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import { CommandStatus } from "../_shared/CommandStatus";
import { STATUS_NAMES } from "../_shared/constant";
import { getStatusNameForSingleTarget } from "../_shared/getSingleStatus";
import styles from "./CommandDetails.scss";
import { RefetchSection } from "./components/RefetchSection";

const secondsToString = (timeInSeconds) => {
  const SECONDS_IN_MINUTE = 60;

  const minutes = Math.floor(timeInSeconds / SECONDS_IN_MINUTE);
  const seconds = timeInSeconds % SECONDS_IN_MINUTE;

  return `${minutes ? `${minutes}m` : ""}${seconds}s`;
};

const renderStatusesForMultipleTargets = (command) => {
  const statusList = [
    STATUS_NAMES.PROCESSING,
    STATUS_NAMES.SENT,
    STATUS_NAMES.RECEIVED,
    STATUS_NAMES.REJECTED,
  ];

  return (
    <Subsection header={trans.DEVICES__COMMAND_DETAILS_STATUS_COUNT()}>
      <div className={styles.statuses}>
        {statusList.map((statusName) => (
          <div className={styles.status} key={statusName}>
            <CommandStatus
              status={statusName}
              creationDate={command.creationDate}
            />
            <span className={styles.statusCount}>
              {command.status[statusName.toLowerCase()]}
            </span>
          </div>
        ))}
      </div>
    </Subsection>
  );
};

export const CommandDetailsRend = ({
  loading,
  error,
  command,
  refetchDetails,
}) => {
  if (loading && !command) {
    return <Loader />;
  }

  if (error || !command) {
    return (
      <PageError
        defaultMessage={error ? error : trans.DEFAULT_ERROR_MESSAGE()}
        pageHeader={trans.DEVICES__COMMAND_DETAILS_DEFAULT_HEADER()}
      />
    );
  }
  const isTargetQuery = Boolean(command.target.collectionID);
  const formatedCreationDate = formatDateTime(
    parseISO(String(command.creationDate))
  );

  const payloadWithName = `${command.name}\n${JSON.stringify(
    JSON.parse(command.payload),
    null,
    "\t"
  )}`;

  return (
    <div>
      <PageHeader>
        {trans.DEVICES__COMMAND_DETAILS_HEADER({
          creationDate: formatedCreationDate,
        })}
      </PageHeader>
      <PageContent>
        <div className={styles.topSection}>
          <RefetchSection loading={loading} refetchDetails={refetchDetails} />
          <Button
            to="/devices/commands/create"
            state={{
              command: {
                name: command.name,
                payload: command.payload,
                target: command.target,
                timeToLive: command.timeToLive,
              },
            }}
          >
            {trans.DEVICES__COMMAND_DETAILS_CLONE()}
          </Button>
        </div>
        <Section header={trans.DEVICES__COMMAND_DETAILS_SECTION_HEADER()}>
          <div className={styles.details}>
            <Subsection
              header={
                isTargetQuery
                  ? trans.DEVICES__COMMAND_DETAILS_QUERY()
                  : trans.DEVICES__COMMAND_DETAILS_DEVICE()
              }
            >
              {isTargetQuery ? (
                <Link to={`/devices/filters/${command.target.collectionID}`}>
                  {command.target.collectionName}
                </Link>
              ) : (
                command.target.devicePublicID
              )}
            </Subsection>

            <Subsection header={trans.DEVICES__COMMAND_DETAILS_CREATION_DATE()}>
              {formatedCreationDate}
            </Subsection>

            <Subsection
              header={trans.DEVICES__COMMAND_DETAILS_COMMAND()}
              className={styles.command}
            >
              <CodeEditor value={payloadWithName} />
            </Subsection>

            <Subsection header={trans.DEVICES__COMMAND_DETAILS_TARGET_COUNT()}>
              {isTargetQuery ? command.target.collectionDeviceCount : 1}
            </Subsection>

            <Subsection header={trans.DEVICES__COMMAND_DETAILS_TTL()}>
              {secondsToString(command.timeToLive)}
            </Subsection>

            {isTargetQuery ? (
              renderStatusesForMultipleTargets(command)
            ) : (
              <Subsection header={trans.DEVICES__COMMAND_DETAILS_STATUS()}>
                <CommandStatus
                  status={getStatusNameForSingleTarget(command.status)}
                  creationDate={command.creationDate}
                  timeToLive={command.timeToLive}
                  errorMessage={command.message}
                />
                {command.message && (
                  <p className={styles.message}>{command.message}</p>
                )}
              </Subsection>
            )}
          </div>
        </Section>
      </PageContent>
    </div>
  );
};

CommandDetailsRend.propTypes = {
  command: PropTypes.shape({
    name: PropTypes.string.isRequired,
    payload: PropTypes.string.isRequired,
    target: PropTypes.shape({
      collectionID: PropTypes.string,
      collectionName: PropTypes.string,
      collectionDeviceCount: PropTypes.number,
      devicePublicID: PropTypes.string,
    }).isRequired,
    status: PropTypes.shape({
      processing: PropTypes.number,
      sent: PropTypes.number,
      received: PropTypes.number,
      rejected: PropTypes.number,
    }).isRequired,
    creationDate: PropTypes.string.isRequired,
    timeToLive: PropTypes.number.isRequired,
    receivedCount: PropTypes.number.isRequired,
    message: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  refetchDetails: PropTypes.func.isRequired,
};
