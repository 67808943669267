// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as React from "react";
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";

export type RouterNavigateType = ReturnType<typeof useNavigate>;
export interface WithRouterProps {
  location: ReturnType<typeof useLocation>;
  params: Record<string, string>;
  match: Record<string, string>;
  navigate: RouterNavigateType;
}

export const withRouter = <Props extends WithRouterProps>(
  ComposedComponent: React.ComponentType<Props>
) =>
  function WithRouter(props: Omit<Props, keyof WithRouterProps>) {
    return (
      <ComposedComponent
        {...(props as Props)}
        location={useLocation()}
        navigate={useNavigate()}
        params={useParams()}
        match={useMatch(location.pathname)}
      />
    );
  };
