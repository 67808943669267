// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createRef, Component } from "react";
import { findDOMNode } from "react-dom";
import { Manager, Reference } from "react-popper";

import PropTypes from "prop-types";

import { Popup } from "components/popups";
import { classes } from "utils/classes";

import { popupAlignmentType } from "../Popup/types";
import styles from "./popupOnClick.scss";

export class PopupOnClick extends Component {
  static propTypes = {
    dataTestId: PropTypes.string,
    renderPopup: PropTypes.func.isRequired,
    renderTrigger: PropTypes.func.isRequired,
    alignment: popupAlignmentType,
    className: PropTypes.string,
    classNamePopup: PropTypes.string,
    classNamePopupWrapper: PropTypes.string,
  };

  static defaultProps = {
    alignment: "bottom-end",
    dataTestId: "popup-on-click",
  };

  state = {
    isPopupOpen: false,
  };

  triggerRef = createRef();
  popupRef = createRef();

  componentDidUpdate(prevProps, prevState) {
    const { isPopupOpen } = this.state;
    if (isPopupOpen !== prevState.isPopupOpen) {
      this.updateClickOutsideListener(isPopupOpen);
    }
  }

  componentWillUnmount() {
    this.updateClickOutsideListener(false);
  }

  updateClickOutsideListener(shouldAddListener) {
    if (shouldAddListener) {
      document.addEventListener("mousedown", this.handleClickOutside, true);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside, true);
    }
  }

  handleClose = () => {
    this.setState({ isPopupOpen: false });
  };

  handleTriggerClick = (ev) => {
    ev.preventDefault();

    this.setState(({ isPopupOpen }) => ({
      isPopupOpen: !isPopupOpen,
    }));
  };

  handleClickOutside = (ev) => {
    if (!this.triggerRef.current || !this.popupRef.current) {
      return;
    }

    const triggerNode = findDOMNode(this.triggerRef.current);
    const popupNode = findDOMNode(this.popupRef.current);

    const outsideTrigger = !triggerNode.contains(ev.target);
    const outsidePopup = !popupNode.contains(ev.target);

    if (outsideTrigger && outsidePopup) {
      this.handleClose(ev);
    }
  };

  renderTrigger() {
    const { renderTrigger } = this.props;
    const { isPopupOpen } = this.state;
    return renderTrigger({ isPopupOpen });
  }

  render() {
    const {
      dataTestId,
      renderPopup,
      alignment,
      className,
      classNamePopup,
      classNamePopupWrapper,
    } = this.props;

    return (
      <Manager>
        <Reference>
          {({ ref }) => {
            return (
              <div
                ref={(elem) => {
                  ref(elem);
                  this.triggerRef.current = elem;
                }}
                onClick={this.handleTriggerClick}
                className={classes(styles.triggerWrapper, className)}
                data-test-id={`${dataTestId}-trigger`}
              >
                {this.renderTrigger()}
              </div>
            );
          }}
        </Reference>
        <div ref={this.popupRef}>
          {this.state.isPopupOpen && (
            <Popup
              alignment={alignment}
              className={classNamePopup}
              classNameWrapper={classNamePopupWrapper}
              dataTestId={`${dataTestId}-popup`}
            >
              {renderPopup({ closePopup: this.handleClose })}
            </Popup>
          )}
        </div>
      </Manager>
    );
  }
}
