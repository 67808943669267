// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";

import { API_URLS } from "config";
import PropTypes from "prop-types";

import { ScrollToTop } from "components/elements";
import { Page404 } from "components/layout";
import { authorize } from "containers/Auth/actions";
import * as authStatus from "containers/Auth/actionTypes";
import { ExtendedRoute } from "containers/ExtendedRoute";
import { LayoutSideBar, PageLoader } from "containers/Layout";
import { PreviousLocation } from "containers/PreviousLocation";
import { ApplicationsRoute } from "pages/Applications";
import { AppManifestsRoute } from "pages/AppManifests";
import { Dashboard } from "pages/Dashboard";
import { DevicesRoute } from "pages/Devices";
import { DistributionRoute } from "pages/Distribution";
import { Guidelines } from "pages/Guidelines";
import { OrganizationRoute } from "pages/Organization";
import { PromotionsRoute } from "pages/Promotions";
import { SnapRoute } from "pages/Snap";
import { SoftwareAssetsRoute } from "pages/SoftwareAssets";
import { Unauthorized } from "pages/Unauthorized";
import { UpdatesRoute } from "pages/Updates";
import { UserRoute } from "pages/Users";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";

@connect(
  ({ auth }) => ({ auth }),
  (dispatch) => ({
    updateUser: (auth) => dispatch(authorize(auth)),
  })
)
@withRouter
export class AppRoutes extends Component {
  static propTypes = {
    // from @connect
    auth: PropTypes.object,
    updateUser: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object,
  };

  handleTabChange = () => {
    if (document.visibilityState === "visible") {
      this.props.updateUser(this.props.auth);
    }
  };

  componentDidUpdate() {
    const { auth, location } = this.props;
    if (auth.state === authStatus.UNAUTHORIZED) {
      window.location.href = `${API_URLS.AUTH_BAKER}/?target_uri=${encodeURIComponent(
        location.state || window.location.origin
      )}`;
    }
  }

  componentDidMount() {
    window.addEventListener("visibilitychange", this.handleTabChange);
  }

  componentWillUnmount() {
    window.removeEventListener("visibilitychange", this.handleTabChange);
  }

  render() {
    const { auth } = this.props;

    if (auth.state === authStatus.INITIAL) {
      return <PageLoader text={trans.INITIALIZING()} />;
    }
    if (auth.state === authStatus.UNAUTHORIZED) {
      return <PageLoader text={trans.REDIRECTING_TO_LOGIN_PAGE()} />;
    }

    return (
      <Routes>
        <Route
          path=""
          element={
            <PreviousLocation>
              <ScrollToTop>
                <LayoutSideBar>
                  <Outlet />
                </LayoutSideBar>
              </ScrollToTop>
            </PreviousLocation>
          }
        >
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/applications/*"
            element={
              <ExtendedRoute
                path="/applications"
                name={trans.APPLICATIONS()}
                component={ApplicationsRoute}
              />
            }
          />
          <Route
            path="/snap/*"
            element={
              <ExtendedRoute
                path="/snap"
                name={trans.SNAP_APP__CREATOR_ROUTE_NAME()}
                component={SnapRoute}
              />
            }
          />
          <Route
            exact
            path="/manifests/*"
            element={
              <ExtendedRoute
                path="/manifests"
                name={trans.APP_MANIFESTS_ROUTE_NAME()}
                component={AppManifestsRoute}
              />
            }
          />
          <Route
            path="/devices/*"
            element={
              <ExtendedRoute
                path="/devices"
                name={trans.DEVICES__ROUTE_NAME()}
                component={DevicesRoute}
              />
            }
          />
          <Route
            path="/updates/*"
            element={
              <ExtendedRoute
                path="/updates"
                name={trans.UPDATES__ROUTE_NAME()}
                component={UpdatesRoute}
              />
            }
          />
          <Route
            path="/distribution/*"
            element={
              <ExtendedRoute
                path="/distribution"
                name={trans.DISTRIBUTION()}
                component={DistributionRoute}
              />
            }
          />
          <Route
            exact
            path="/software-assets/*"
            element={
              <ExtendedRoute
                path="/software-assets"
                name={trans.ASSET__ASSETS_ROUTE_NAME()}
                component={SoftwareAssetsRoute}
              />
            }
          />
          <Route
            path="/organization/*"
            element={
              <ExtendedRoute
                path="/organization"
                name={trans.ORGANIZATION__ORGANIZATION_ROUTE_NAME()}
                component={OrganizationRoute}
              />
            }
          />
          <Route
            path="/promotions/*"
            element={
              <ExtendedRoute
                path="/promotions"
                name={trans.PROMOTIONS__ROUTE_NAME()}
                component={PromotionsRoute}
              />
            }
          />
          <Route
            path="/users/*"
            element={
              <ExtendedRoute
                path="/users"
                name={trans.USERS()}
                component={UserRoute}
              />
            }
          />
          <Route
            path="/guidelines"
            element={
              <ExtendedRoute
                exact
                path="/guidelines"
                name={trans.GUIDELINES__ROUTE_NAME()}
                component={Guidelines}
              />
            }
          />
          <Route exact path="*" element={<Page404 />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Route>
      </Routes>
    );
  }
}
