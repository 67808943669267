// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { PopupOnClick } from "components/popups";
import { classes } from "utils/classes";

import { popupAlignmentType } from "../Popup/types";
import styles from "./actonMenu.scss";
import { MenuButton } from "./MenuButton";
import { buttonSizeType } from "./types";

/**
 * Menu used to display action items for each table row.
 */
export class ActionMenu extends Component {
  static propTypes = {
    /** Array of MenuItem */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    alignment: popupAlignmentType,
    buttonSize: buttonSizeType,
    /** Optional custom component to render the `<ActionMenu>` */
    triggerChildren: PropTypes.func,
    /** Optional style fix */
    shifted: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    alignment: "bottom-end",
    shifted: true,
    dataTestId: "action-menu",
  };

  getDefaultTrigger = (triggerProps) => {
    const { buttonSize } = this.props;
    return <MenuButton size={buttonSize} {...triggerProps} />;
  };

  renderTrigger = (triggerProps) => {
    const { triggerChildren } = this.props;
    if (triggerChildren) {
      return triggerChildren(triggerProps);
    }
    return this.getDefaultTrigger(triggerProps);
  };

  renderPopup = (opts) => {
    const { children } = this.props;
    return typeof children === "function" ? children(opts) : children;
  };

  render() {
    const { alignment, shifted, dataTestId } = this.props;

    return (
      <PopupOnClick
        dataTestId={dataTestId}
        renderTrigger={this.renderTrigger}
        renderPopup={this.renderPopup}
        alignment={alignment}
        classNamePopupWrapper={classes({
          [styles.shifted]: shifted,
          [styles.popupBottom]: alignment.includes("bottom"),
          [styles.popupTop]: alignment.includes("top"),
          [styles.popupLeft]: alignment.includes("left"),
          [styles.popupRight]: alignment.includes("right"),
        })}
      />
    );
  }
}
