import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import {
  ApplicationField,
  SwitchField,
  TextField,
} from "components/form/fields";
import { Row, Column, Section } from "components/layout";
import { ContentBox, Header } from "components/typography";
import { trans, HtmlTranslation } from "src/translations";

import { getManifestQuery } from "../../_shared/gql";
import { APP_ID_FORM_FIELDS, SWITCH_LABEL } from "./constants";
import styles from "./ManifestForm.scss";

export function TargetTab({ initialValues, onSubmit, isReadonly, innerRef }) {
  const apolloClient = useApolloClient();

  const getManifest = useCallback(
    async (id) => {
      const response = await apolloClient.query({
        query: getManifestQuery,
        fetchPolicy: "network-only",
        variables: { applicationId: id ?? "" },
      });
      return response?.data?.manifest;
    },
    [apolloClient]
  );

  const validate = (value) => {
    if (!value || isReadonly) {
      return "";
    }

    return getManifest(value).then((manifest) => {
      return manifest.id === null
        ? ""
        : trans.APP_MANIFESTS_TARGET_TAB_APP_ID_ERROR();
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      innerRef={innerRef}
      validateOnBlur={false}
    >
      {({ values, handleChange, setFieldValue }) => {
        return (
          <div className={styles.appId}>
            <Form>
              <Section>
                <Row>
                  <Column>
                    <Header uppercase={true} bold={true} color="secondary">
                      {values.internalApp
                        ? trans.APP_MANIFESTS_TARGET_TAB_HEADER_PRIMARY()
                        : trans.APP_MANIFESTS_TARGET_TAB_HEADER_SECONDARY()}
                    </Header>
                    {values.internalApp ? (
                      <ApplicationField
                        name={APP_ID_FORM_FIELDS.applicationId}
                        isReadonly={isReadonly}
                        validate={validate}
                      />
                    ) : (
                      <TextField
                        name={APP_ID_FORM_FIELDS.applicationId}
                        label={trans.APP_MANIFESTS_TARGET_TAB_APP_ID_LABEL()}
                        readOnly={isReadonly}
                        disabled={isReadonly}
                        required={false}
                        dataTestId="application-id"
                      />
                    )}
                    <SwitchField
                      name={APP_ID_FORM_FIELDS.internalApp}
                      required={false}
                      label={SWITCH_LABEL}
                      dataTestId="appId-options-toggle"
                      disabled={isReadonly}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(APP_ID_FORM_FIELDS.applicationId, "");
                      }}
                    />
                  </Column>
                  <Column>
                    <ContentBox
                      title={trans.APP_MANIFESTS_APPLICATION_GUIDE_TITLE()}
                    >
                      <HtmlTranslation
                        component="p"
                        transFunc={trans.APP_MANIFESTS_APPLICATION_GUIDE}
                        className={styles.contentBox}
                      />
                    </ContentBox>
                  </Column>
                </Row>
              </Section>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

TargetTab.propTypes = {
  isReadonly: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  innerRef: PropTypes.shape({ current: PropTypes.object }),
};
