// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Popper } from "react-popper";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./popup.scss";
import { PopupRend } from "./PopupRend";
import { popupAlignmentType } from "./types";

/**
 * Popup has to be used inside react-popper's Manager component
 */
export class Popup extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    alignment: popupAlignmentType,
    className: PropTypes.string,
    classNameWrapper: PropTypes.string,
    dataTestId: PropTypes.string,
    offset: PropTypes.string,
  };

  static defaultProps = {
    alignment: "bottom-end",
    dataTestId: "popup",
  };

  render() {
    const {
      children,
      alignment,
      className,
      classNameWrapper,
      dataTestId,
      offset,
    } = this.props;

    return (
      <Popper
        placement={alignment}
        modifiers={{
          hide: { enabled: false },
          preventOverflow: { enabled: false },
          offset: { offset: offset ? offset : "0px" },
          shift: { enabled: true },
        }}
      >
        {({ ref, style, placement, arrowProps }) => {
          return (
            <div
              ref={ref}
              data-placement={placement}
              className={classes(styles.container, classNameWrapper)}
              style={style}
            >
              <PopupRend
                arrowProps={arrowProps}
                alignment={alignment}
                className={className}
                dataTestId={dataTestId}
              >
                {children}
              </PopupRend>
            </div>
          );
        }}
      </Popper>
    );
  }
}
