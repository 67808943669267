// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import PropTypes from "prop-types";

import { api } from "containers/Request";
import { trans } from "src/translations";
import { queryResultType, withQuery } from "utils/graphql";

import { createTarget } from "../_utils";
import {
  DEVICE_TYPE_DISTRIBUTOR,
  DEVICE_TYPE_FAMILY,
  DEVICE_TYPE_MODEL,
  DEVICE_TYPE_QUERY,
} from "../constants";
import { getDeviceFilterQuery } from "./gql/getDeviceFilterQuery";

export const withTargetsReceive =
  (
    // to denote 'All devices from my organization', distribution plans use 'device_owners' and campaigns use 'organizations'
    { distributorFieldNameInRequest }
  ) =>
  (ComposedComponent) => {
    @connect(null, (dispatch, ownProps) => ({
      getDeviceModel: async (id) => {
        let response;

        if (ownProps.forAdmin) {
          response = await dispatch(
            api.getDeviceModel.action({ params: { id } })
          );
        } else {
          response = await dispatch(
            api.getMyDeviceModel.action({ params: { id } })
          );
        }

        if (!response.result) {
          throw {
            message: trans.TARGETS__FETCH_DEVICE_ERROR({ id }),
          };
        }
        return response.result.results;
      },
      getDeviceFamily: async (id) => {
        let response;

        if (ownProps.forAdmin) {
          response = await dispatch(
            api.getDeviceFamily.action({ params: { id } })
          );
        } else {
          response = await dispatch(
            api.getMyDeviceFamily.action({ params: { id } })
          );
        }

        if (!response.result) {
          throw {
            message: trans.TARGETS__FETCH_FAMILY_ERROR({ id }),
          };
        }
        return response.result.results;
      },
      getOrganizationPublicInfo: async (id) => {
        const response = await dispatch(
          api.getOrganizationPublicInfo.action({
            params: { organizationId: id },
          })
        );

        if (!response.result) {
          throw {
            message: trans.TARGETS__FETCH_ORGANIZATION_ERROR({ id }),
          };
        }
        return response.result.results;
      },
    }))
    @withQuery({
      name: "deviceFilter",
      query: getDeviceFilterQuery,
    })
    class WithTargetsReceiveHOC extends Component {
      static propTypes = {
        forAdmin: PropTypes.bool,

        // from @connect
        getDeviceModel: PropTypes.func,
        getDeviceFamily: PropTypes.func,
        getOrganizationPublicInfo: PropTypes.func.isRequired,

        // from @withQuery
        deviceFilterQuery: PropTypes.func,
        deviceFilterQueryStatus: queryResultType,
      };

      static defaultProps = {
        forAdmin: false,
      };

      getDeviceModels = async (target) => {
        const { device_models } = target;
        const modelsPromises = device_models.map(async ({ id }) => {
          const deviceModel = await this.props.getDeviceModel(id);
          deviceModel.type = DEVICE_TYPE_MODEL;
          return deviceModel;
        });
        return await Promise.all(modelsPromises);
      };

      getDeviceFamilies = async (target) => {
        const { device_families } = target;
        const familiesPromises = device_families.map(async ({ id }) => {
          const deviceFamily = await this.props.getDeviceFamily(id);
          deviceFamily.type = DEVICE_TYPE_FAMILY;
          return deviceFamily;
        });
        return await Promise.all(familiesPromises);
      };

      getDistributors = async (target) => {
        const { getOrganizationPublicInfo, forAdmin } = this.props;

        const distributors = get(target, distributorFieldNameInRequest, []);

        return Promise.all(
          distributors.map(async (e) => {
            const distributor = await getOrganizationPublicInfo(e.id);

            return {
              type: DEVICE_TYPE_DISTRIBUTOR,
              id: distributor.id,
              name: forAdmin
                ? distributor.name
                : trans.TARGETS__DEVICES_MODAL_MY(),
            };
          })
        );
      };

      async getDevices(target) {
        const { device_models, device_families } = target;

        // get all the data that will be required to calculate name.
        // ATM we only have {type: "device_model", id: "3497"},
        // which is not enough
        if (device_models.length > 0) {
          return this.getDeviceModels(target);
        } else if (device_families.length > 0) {
          return this.getDeviceFamilies(target);
        }
        return await this.getDistributors(target);
      }

      async getCollections(target, collectionData) {
        const { collections } = target;

        if (isEmpty(collections)) {
          return undefined;
        }

        const collectionsDataForTarget = collectionData.find(
          (data) => data.id === collections[0].id
        );
        return { type: DEVICE_TYPE_QUERY, ...collectionsDataForTarget };
      }

      async fetchCollections(targets) {
        const deviceFilterIds = targets
          .map((target) => get(target, "collections[0].id"))
          .filter((id) => id !== undefined);

        if (!deviceFilterIds.length) {
          return [];
        }

        const result = await this.props.deviceFilterQuery({
          variables: { ids: deviceFilterIds },
        });

        return get(result, "deviceFilters.deviceFilters.items", []).map(
          (filter) => ({
            name: filter.name,
            id: filter.id,
            numberOfDevices: filter.devices.pagination.count,
          })
        );
      }

      getTargetDetails = (collectionData) => async (target) => {
        const countries = get(target, "countries", []).map(({ id }) => id);
        const devices = await this.getDevices(target);
        const collections = await this.getCollections(target, collectionData);
        return createTarget(target, devices, collections, countries);
      };

      getTargetsData = async (targets) => {
        const collectionsData = await this.fetchCollections(targets);
        const targetPromises = targets.map(
          this.getTargetDetails(collectionsData)
        );
        return await Promise.all(targetPromises);
      };

      render() {
        return (
          <ComposedComponent
            {...this.props}
            getTargetsData={this.getTargetsData}
          />
        );
      }
    }

    return WithTargetsReceiveHOC;
  };
