// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { WarningModal } from "components/layout";
import { trans } from "src/translations";

export const NoEnvironmentModal = ({ isOpen, onClose }) => {
  return (
    <WarningModal
      title={trans.APP_DETAILS_MODERATION_NO_ENVIRONMENT_SELECTED()}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <ButtonsWrapper>
          <Button type="normal" onClick={onClose}>
            {trans.GO_BACK()}
          </Button>
        </ButtonsWrapper>
      }
      size="medium"
    >
      <div>
        {trans.APP_DETAILS_MODERATION_NO_ENVIRONMENT_SELECTED_WARNING()}
      </div>
    </WarningModal>
  );
};

NoEnvironmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
