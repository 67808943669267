// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { COUNTRY_TYPE_FILTERS } from "../../_shared/constants";

export const adaptFilters = (filters, countries) => {
  if (countries.error || countries.loading) {
    return filters;
  }

  return filters.map((filter) => {
    // "any" is a keyword that means filter with operator "exists"
    // that is device with attribute with defined value
    if (filter.displayValue === "any") {
      return {
        ...filter,
        displayValue: filter.displayValue.toLocaleUpperCase(),
      };
    }
    if (
      COUNTRY_TYPE_FILTERS.find(
        (countryFilter) => filter.displayName === countryFilter
      )
    ) {
      const country = countries.allCountries.find(
        (country) => country.code === filter.displayValue
      );

      return {
        ...filter,
        displayValue: country ? country.name : filter.displayValue,
      };
    }
    return filter;
  });
};
