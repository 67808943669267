// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./column.scss";

/**
 * `<Column>` component allows to easily display data in multiple columns.
 * Please do not use it as substitute to `<Table>` components.
 * It is only for e.g. 2 column layouts on details views.
 *
 * It should be used inside `<Row>` component.
 */
export class Column extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    const { children, className, ...props } = this.props;

    return (
      <div className={classes(styles.column, className)} {...props}>
        {children}
      </div>
    );
  }
}
