// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Formik, Form } from "formik";
import isEmpty from "lodash-es/isEmpty";
import PropTypes from "prop-types";
import queryString from "query-string";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { FormHasErrors } from "components/form";
import { TextField } from "components/form/fields";
import { Section } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import { addQueryParamsToLocation } from "utils/url";

import { FIELDS } from "./constants";
import { ModeContext } from "./context";
import { TypeField } from "./fields";
import { PromotionDetailsAppTab } from "./PromotionDetailsAppTab";
import { PromotionDetailsAppTabs } from "./PromotionDetailsAppTabs";
import { PromotionDetailsWarnModal } from "./PromotionDetailsWarnModal";
import { configurationType, prepareInitialValues } from "./utils";
import { promotionSchemaGenerator } from "./validation";

const submitAttempted = "submit-attempted";

@withRouter
@withActiveOrganization
export class PromotionDetailsForm extends Component {
  static contextType = ModeContext;

  static propTypes = {
    configuration: configurationType.isRequired,
    onConfigurationUpdate: PropTypes.func.isRequired,
    submitPromotion: PropTypes.func.isRequired,
    ongoingCampaigns: PropTypes.array,
    promotionDetails: PropTypes.array,
    initialValues: PropTypes.object,

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    promotionDetails: [],
    ongoingCampaigns: [],
  };

  state = {
    isWarnModalOpen: false,
    currentTab: 0,
  };

  componentDidMount() {
    this.updateCurrentTab(this.props.location.search);
  }

  componentDidUpdate = (prevProps) => {
    const { activeOrganization, location, navigate } = this.props;
    const { search } = location;
    if (!activeOrganization || !prevProps.activeOrganization) {
      if (activeOrganization !== prevProps.activeOrganization) {
        setTimeout(() => {
          navigate(`/promotions${this.context.forAdmin ? "/admin" : ""}/list`);
        });
      }
      return;
    }
    if (activeOrganization.id !== prevProps.activeOrganization.id) {
      setTimeout(() => {
        navigate(`/promotions${this.context.forAdmin ? "/admin" : ""}/list`);
      });
      return;
    }
    if (search !== prevProps.location.search) {
      this.updateCurrentTab(search);
    }
  };

  updateCurrentTab = (search) => {
    const searchObj = queryString.parse(search);
    const currentTab = Number(searchObj.tab) || 0;
    this.openTab(currentTab);
  };

  openTab = (tabIdx) => {
    const { navigate } = this.props;
    const newLocation = addQueryParamsToLocation(location, {
      tab: tabIdx,
    });
    navigate(newLocation, { replace: true });
    this.setState({ currentTab: tabIdx });
  };

  openWarnModal = () => {
    this.setState({ isWarnModalOpen: true });
  };

  closeWarnModal = () => {
    this.setState({ isWarnModalOpen: false });
  };

  shouldWarnBeforeEdit() {
    const { ongoingCampaigns } = this.props;
    return ongoingCampaigns.length > 0;
  }

  getFirstTabIdxWithSyncError = (errors) => {
    const { appsCount } = this.props.configuration;
    return [...Array(appsCount)].findIndex((_, i) => errors?.apps?.[i]);
  };

  handleSubmit = async (formikProps) => {
    formikProps.setStatus(submitAttempted);
    const { isWarnModalOpen } = this.state;
    const errors = await formikProps.validateForm();
    if (Object.keys(errors).length > 0) {
      const invalidTabIdx = this.getFirstTabIdxWithSyncError(errors);
      if (invalidTabIdx !== -1) {
        this.openTab(invalidTabIdx);
      }
      return;
    }

    const shouldShowWarning = this.shouldWarnBeforeEdit() && !isWarnModalOpen;
    if (shouldShowWarning) {
      formikProps.setSubmitting(false);
      this.openWarnModal();
      return;
    }

    await this.props.submitPromotion(formikProps.values, formikProps);

    if (isWarnModalOpen) {
      this.closeWarnModal();
    }
  };

  updateConfiguration = (type, { setFieldValue, values }) => {
    const { onConfigurationUpdate } = this.props;
    const { apps } = prepareInitialValues(type.configuration);
    setFieldValue(FIELDS.PROMO_APPS.name, apps);

    if (type.configuration.appsCount < this.props.configuration.appsCount) {
      this.openTab(0);
    }
    onConfigurationUpdate(type, values);
  };

  render() {
    const { configuration, initialValues, ongoingCampaigns, promotionDetails } =
      this.props;

    const appsCount = configuration.appsCount;
    const appsSectionHeader =
      appsCount > 0
        ? trans.PROMOTION_DETAILS__SECTION_DETAILS()
        : trans.PROMOTION_DETAILS__NON_APP_SECTION_DETAILS();

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={promotionSchemaGenerator(configuration)}
        onSubmit={(values, formikProps) =>
          this.handleSubmit({ values, ...formikProps })
        }
        enableReinitialize={true}
      >
        {({ isSubmitting, dirty, errors, status, ...formikProps }) => {
          const submitFailed =
            (errors?._submitFailed ||
              (!isEmpty(errors) && status === submitAttempted)) ??
            false;

          const genericServerError = errors?._error;

          return (
            <>
              <PromotionDetailsWarnModal
                isOpen={this.state.isWarnModalOpen}
                submitting={isSubmitting}
                onClose={this.closeWarnModal}
                ongoingCampaigns={ongoingCampaigns}
                onSubmit={formikProps.handleSubmit}
              />
              <Form aria-label="form">
                <Section header={trans.PROMOTION_EDIT__SECTION_BASIC_DETAILS()}>
                  <TextField
                    {...FIELDS.PROMO_NAME}
                    disabled={this.context.isReadonly}
                  />
                  <TypeField
                    newTypeSelected={(type) => {
                      this.updateConfiguration(type, formikProps);
                    }}
                    readonly={this.context.isEdit}
                  />
                </Section>
                {appsCount >= 0 && (
                  <Section header={appsSectionHeader}>
                    <PromotionDetailsAppTabs
                      configuration={configuration}
                      currentTab={this.state.currentTab}
                      openTab={this.openTab}
                      promotionDetails={promotionDetails}
                    />
                    <PromotionDetailsAppTab
                      configuration={configuration}
                      name={`${FIELDS.PROMO_APPS.name}.${this.state.currentTab}`}
                      key={this.state.currentTab}
                    />

                    {genericServerError && (
                      <Info type="error">{genericServerError}</Info>
                    )}
                    <FormHasErrors submitFailed={submitFailed} />

                    {!this.context.isReadonly && (
                      <ButtonsWrapper>
                        <Button
                          type="green"
                          buttonType="submit"
                          disabled={!dirty || isSubmitting}
                          processing={
                            this.shouldWarnBeforeEdit() ? false : isSubmitting
                          }
                          dataTestId="submit-button"
                        >
                          {this.context.isEdit
                            ? trans.CHANGE()
                            : trans.PROMOTION_CREATE__SUBMIT_BUTTON()}
                        </Button>
                      </ButtonsWrapper>
                    )}
                  </Section>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}
