// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { Section, TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";

import { DeviceModelApplicationsRow } from "./DeviceModelApplicationsRow";

@withRouter
export class DeviceModelApplicationsRend extends Component {
  static propTypes = {
    applicationsData: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    onExport: PropTypes.func,

    // from @withRouter
    location: PropTypes.object,
  };

  static tabsConfig = [
    {
      dataTestId: "device-model-applications-list",
      columns: [
        { label: trans.DEVICES__ICON_COLUMN_NAME() },
        { label: trans.DEVICES__NAME_COLUMN_NAME() },
      ],
    },
  ];

  renderBody = (applicationsData) => {
    return (
      <TableBody>
        {applicationsData.map((app) => (
          <DeviceModelApplicationsRow key={app.id} app={app} />
        ))}
      </TableBody>
    );
  };

  render() {
    const { applicationsData, count, error, loading, onExport, location } =
      this.props;

    const locationMessage = get(location, "state.message");

    return (
      <Section header={trans.DEVICES__APPS_PUBLISHED_ON_DEVICE_HEADER()}>
        <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
        <TabbedTable
          tabsConfig={DeviceModelApplicationsRend.tabsConfig}
          data={{ results: applicationsData, count, loading, error }}
          renderTableBody={this.renderBody}
          onExport={onExport}
        />
      </Section>
    );
  }
}
