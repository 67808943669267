// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Field } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { GrabsonIcon } from "components/icons";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
  TableNoResults,
} from "components/layout";
import {
  organizationsPropTypes,
  profilePropTypes,
} from "containers/Auth/propTypes";
import { isAllowedToRemoveRole } from "containers/Permissions";
import { trans } from "src/translations";

import styles from "./Roles.scss";
import { RolesModal } from "./RolesModal";

@connect(({ auth }) => ({
  currentUser: auth.profile,
  organizations: auth.organizations,
}))
export class RolesComponent extends Component {
  static propTypes = {
    // helper functions for Formik <FieldArray />
    remove: PropTypes.func,
    // formik form props
    form: PropTypes.object,
    name: PropTypes.string,
    // filtered company roles
    organizationRoles: PropTypes.array,
    disabled: PropTypes.bool,
    error: PropTypes.object,

    // from @connect
    currentUser: profilePropTypes,
    organizations: organizationsPropTypes,
  };

  state = {
    isModalOpen: false,
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  onModalSave = (roles) => {
    const {
      form: { getFieldHelpers },
      name,
      disabled,
    } = this.props;

    const { setValue } = getFieldHelpers(name);

    if (!disabled) {
      setValue(roles.map((role) => ({ role })));
    }
  };

  getRoleName = ({ field }) => {
    const { organizationRoles } = this.props;
    const asRole = organizationRoles.find((role) => role.id === field.name);
    return asRole?.name ?? field.name;
  };

  renderTableBody = ({
    currentUserRoles,
    editUserRoles,
    organizationRoles,
    remove,
    disabled,
  }) => {
    return (
      <TableBody>
        {editUserRoles.map(({ role }, index) => (
          <TableRow key={role} dataTestId="role-row">
            <TableRowColumn>
              <Field
                name={role}
                component={this.getRoleName}
                roles={organizationRoles}
              />
            </TableRowColumn>
            {!disabled &&
            isAllowedToRemoveRole(
              currentUserRoles,
              editUserRoles.map(({ role }) => role),
              role
            ) ? (
              <TableRowColumn
                onClick={() => remove(index)}
                dataTestId="role-remove"
                columnClass={styles.removeColumn}
              >
                <GrabsonIcon
                  dataTestId="role-remove-icon"
                  name="close"
                  font="micro"
                />
              </TableRowColumn>
            ) : (
              <TableRowColumn />
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  render() {
    const {
      form: { values },
      remove,
      name,
      organizationRoles,
      currentUser,
      organizations,
      disabled,
      error,
      ...rest
    } = this.props;
    const { isModalOpen } = this.state;
    const editUserRoles = values[name];
    const currentUserRoles =
      organizations[currentUser.activeOrganizationId].roles;

    if (error) {
      return <Info type="error">{error.message || error}</Info>;
    }

    return (
      <Fragment>
        <Table {...rest}>
          <TableHeader>
            <TableHeaderColumn>{trans.ROLE()}</TableHeaderColumn>
            <TableHeaderColumn />
          </TableHeader>
          {editUserRoles.length ? (
            this.renderTableBody({
              currentUserRoles,
              editUserRoles,
              organizationRoles,
              remove,
              disabled,
            })
          ) : (
            <TableNoResults />
          )}
        </Table>

        <RolesModal
          organizationRoles={organizationRoles}
          editUserRoles={editUserRoles}
          currentUserRoles={currentUserRoles}
          isOpen={isModalOpen}
          onClose={this.closeModal}
          onSave={this.onModalSave}
        />

        {!disabled && (
          <ButtonsWrapper position="center">
            <Button
              type="normal"
              onClick={this.openModal}
              dataTestId="new-role-button"
            >
              {trans.NEW_ROLE()}
            </Button>
          </ButtonsWrapper>
        )}
      </Fragment>
    );
  }
}
