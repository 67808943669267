// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Checkbox } from "components/form/elements";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./CheckAllButton.scss";

/** Button used with `<SearchableList>` when list contains checkable items */
export class CheckAllButton extends Component {
  static propTypes = {
    /** Unique checkbox name. Used as HTML `id` */
    fieldName: PropTypes.string.isRequired,
    /**
     * Change handler.
     *
     * Type: (nextValue: boolean) => void
     */
    onChange: PropTypes.func.isRequired,
    /** Decides current state of the button  */
    checked: PropTypes.bool.isRequired,
    /** Relative position of icon and text */
    isRtl: PropTypes.bool,
    className: PropTypes.string,
    checkboxClassName: PropTypes.string,
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string,
    withLabel: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    isRtl: false,
    dataTestId: "check-all-button",
    withLabel: true,
  };

  handleChange = () => {
    const { onChange, checked } = this.props;
    onChange(!checked);
  };

  getClasses() {
    const { className, disabled, isRtl } = this.props;
    return classes(
      styles.container,
      isRtl ? styles.containerRtl : styles.containerLtr,
      { [styles.disabled]: disabled },
      className
    );
  }

  render() {
    const {
      fieldName,
      checkboxClassName,
      disabled,
      checked,
      isRtl,
      dataTestId,
      withLabel,
    } = this.props;
    const value = Boolean(!disabled && checked);

    return (
      <div className={this.getClasses()} data-test-id={dataTestId}>
        <Checkbox
          name={fieldName}
          checked={value}
          value={value}
          onChange={this.handleChange}
          disabled={disabled}
          inputClassName={classes(styles.input, checkboxClassName)}
          isRtl={isRtl}
          dataTestId={`${dataTestId}-checkbox`}
        >
          {withLabel && (
            <span className={styles.label}>{trans.CHECK_UNCHECK_ALL()}</span>
          )}
        </Checkbox>
      </div>
    );
  }
}
