// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Button } from "components/buttons";
import { GrabsonIcon } from "components/icons";
import { Section } from "components/layout";
import { API_URLS } from "src/config";
import { HtmlTranslation, trans } from "src/translations";
import { classes } from "utils/classes";
import { useToggle } from "utils/hooks";

import { ManifestRemoveSection } from "./ManifestRemoveSection";
import styles from "./ManifestSummary.scss";
import { ManifestSummaryTable } from "./ManifestSummaryTable";

const documentationURL = `${API_URLS.CONTENT_SUITE_API}/docs/api#/paths/~1v1~1apps~1manifests/get`;

export const ManifestSummary = () => {
  const [isVisible, toggleVisibility] = useToggle();

  return (
    <>
      <Section
        className={styles.textSection}
        header={trans.APP_DETAILS_MANIFEST__DETAILS_HEADER()}
      >
        <HtmlTranslation
          transFunc={trans.APP_DETAILS_MANIFEST__DETAILS_DESCRIPTION}
          transParams={{
            url: documentationURL,
          }}
          component="p"
        />
        <ManifestRemoveSection />
      </Section>
      <Section
        header={
          <>
            {trans.APP_DETAILS_MANIFEST__CONFIG_HEADER()}
            <Button
              type="plain"
              onClick={toggleVisibility}
              className={styles.toggleButton}
            >
              <GrabsonIcon
                name={isVisible ? "arrow-up" : "arrow-down"}
                font="micro"
              />
            </Button>
          </>
        }
        className={classes(styles.manifestSection, {
          [styles.hidden]: !isVisible,
        })}
      >
        <ManifestSummaryTable />
      </Section>
    </>
  );
};
