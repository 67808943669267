// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Section } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";
import { createBody } from "utils/jsonApi";
import { timeout } from "utils/timeout";

import { WAITING } from "./constants";
import { DevicePairingForm } from "./DevicePairingForm";
import { DevicePairingList } from "./DevicePairingList";

// After pairing new device we are going to refresh peridically to see
// if the pairing was accepted on the device.
const REFRESH_RETRY_COUNT = 20;
const REFRESH_RETRY_DELAY_MS = 2000;

@withActiveOrganization
@connect(undefined, (dispatch) => ({
  unpairDevice: async (pairId) => {
    await dispatch(apiThatThrows.unpairDevice.action({ params: { pairId } }));
  },
  getPairedDevicesPaginated: async () => {
    const { results } = await dispatch(
      apiThatThrows.getPairedDevicesPaginated.action()
    );

    return results;
  },
  pairDevice: async (body) => {
    const { results } = await dispatch(
      apiThatThrows.pairDevice.action({ options: { body } })
    );

    return results;
  },
}))
export class DevicePairingListData extends Component {
  static propTypes = {
    // from @connect
    unpairDevice: PropTypes.func,
    getPairedDevicesPaginated: PropTypes.func,
    pairDevice: PropTypes.func,

    // from @withActiveOrganization
    activeOrganization: PropTypes.object,
  };

  state = {
    pairings: [],
    count: 0,
    lastPairingId: null,
    error: null,
    loading: true,
  };

  componentDidMount() {
    this.isStillMounted = true;
    this.refreshPairedDevicesByPooling();
  }

  async componentDidUpdate(prevProps) {
    const { activeOrganization } = this.props;
    if (activeOrganization.id !== prevProps.activeOrganization.id) {
      this.refreshPairedDevicesByPooling();
    }
  }

  componentWillUnmount() {
    this.isStillMounted = false;
  }

  setStateSafely(state) {
    if (this.isStillMounted) {
      this.setState(state);
    }
  }

  async refreshPairedDevices(inBackground = false) {
    try {
      this.setStateSafely({ error: null });

      if (!inBackground) {
        this.setStateSafely({ loading: true });
      }

      const pairings = await this.props.getPairedDevicesPaginated();

      this.setStateSafely({
        pairings: pairings,
        count: pairings.length,
      });

      return pairings;
    } catch (err) {
      this.setStateSafely({
        error: err,
      });
      return [];
    } finally {
      this.setStateSafely({
        loading: false,
      });
    }
  }

  handlePairingProcess = async ({ profile_hash, name }) => {
    const body = createBody({
      type: "pairings",
      name,
      profile_hash,
    });
    const results = await this.props.pairDevice(body);

    this.setStateSafely({
      lastPairingId: get(results, "id"),
    });

    // do not add 'await' here, we just want to schedule some periodic
    // updates for some time in the future
    this.refreshPairedDevicesByPooling();
  };

  handleUnpairingProcess = async (pairId) => {
    try {
      await this.props.unpairDevice(pairId);
      this.refreshPairedDevices();
    } catch (err) {
      this.setStateSafely({ error: err });
    }
  };

  /**
   * Stable list result means that it will have no reason to change anytime soon.
   * This means that we can stop polling the results
   */
  isListResultStable(results) {
    return !results.some(({ status }) => status === WAITING);
  }

  async refreshPairedDevicesByPooling() {
    for (let i = 0; i < REFRESH_RETRY_COUNT; i++) {
      const pairings = await this.refreshPairedDevices(i !== 0);
      if (this.isListResultStable(pairings || [])) {
        return;
      }

      await timeout(REFRESH_RETRY_DELAY_MS);
    }
  }

  getLastPairingStatus() {
    const { lastPairingId } = this.state;
    const allPairings = this.state.pairings || [];
    const lastPairing = allPairings.find((p) => p.id === lastPairingId);
    return get(lastPairing, "status");
  }

  render() {
    const { pairings, count, loading, error } = this.state;

    return (
      <Fragment>
        <Section header={trans.DEVICE_PAIRING__PAIR_NEW_DEVICE()}>
          <DevicePairingForm
            lastPairingStatus={this.getLastPairingStatus()}
            pairDevice={this.handlePairingProcess}
          />
        </Section>
        <Section header={trans.DEVICE_PAIRING__PAIRED_DEVICES()}>
          <DevicePairingList
            pairings={pairings}
            count={count}
            loading={loading}
            unpairDevice={this.handleUnpairingProcess}
            error={error}
          />
        </Section>
      </Fragment>
    );
  }
}
