// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { get } from "lodash-es";

import { Input } from "components/form/elements";
import { trans } from "src/translations";
import { pipeValidators, hasOneElementWhen } from "utils/validation";

import { LinuxProfilesForm } from "../../../components/Profiles/LinuxProfilesForm";
import { APP_PROFILES_SECTION_APP_TYPES } from "../../../constants";

export default {
  name: trans.APP_EDIT__LINUX_PROFILES_SECTION_NAME(),
  isEnabled: (props) =>
    APP_PROFILES_SECTION_APP_TYPES.includes(props.appData.type),
  components: [
    {
      component: LinuxProfilesForm,
      fields: [
        "metadata.linux_profiles[].name",
        "metadata.linux_profiles[].native_id",
        "metadata.linux_profiles_error", // special field only for displaying errors for group of fields
      ],
      validate: pipeValidators(
        hasOneElementWhen("metadata.linux_profiles_error", (values) => {
          return (
            values.metadata.android_profiles.length === 0 &&
            values.metadata.linux_profiles.length === 0
          );
        })
      ),
      initialValues: {
        metadata: {
          linux_profiles: [],
        },
      },
      props: ({ formikProps }) => ({
        profilesFieldName: "metadata.linux_profiles",
        generalError: get(formikProps, "errors.metadata.linux_profiles_error"),
        ...formikProps,
      }),
    },
    {
      component: Input,
      props: (props) => ({
        label: trans.APP_EDIT__ID_LABEL(),
        disabled: true,
        value: props.appData.id,
        dataTestId: "id-field",
        tooltip: trans.APP_EDIT__ID_TOOLTIP(),
      }),
    },
  ],
};
