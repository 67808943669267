// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import ReactMarkdown from "react-markdown";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./Markdown.scss";

export default class Markdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    /**
     * Just put string inside >< brackets and it will be fine
     * Remember that HTML itself cut lines from preformatted text
     * so sometimes it need to be put in {``}
     */
    children: PropTypes.string.isRequired,
  };

  render() {
    const { className, children } = this.props;
    return (
      <div className={classes(className, styles.Markdown)}>
        <ReactMarkdown>{children}</ReactMarkdown>
      </div>
    );
  }
}
