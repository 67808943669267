// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";

import {
  DEVICE_TYPE_MODEL,
  DEVICE_TYPE_FAMILY,
  DEVICE_TYPE_DISTRIBUTOR,
  DEVICE_TYPE_QUERY,
  DEVICE_TYPE_ALL,
} from "../constants";

export const withTargetsSend =
  (
    // to denote 'All devices from my organization', distribution plans
    // use 'device_owners' and campaigns use 'organizations'
    { distributorFieldNameInRequest, distributorDeviceType }
  ) =>
  (ComposedComponent) => {
    class WithTargetsSendHOC extends Component {
      createCountries(target) {
        if (target.type === DEVICE_TYPE_QUERY) {
          return {};
        }
        const createCountry = (id) => ({ type: "country", id });
        return {
          countries: {
            data: get(target, "countries", []).map(createCountry),
          },
        };
      }

      createDeviceData = (type) => (device) => ({ id: device.id, type });

      createDevicesByType(target) {
        const result = {};

        switch (target.type) {
          case DEVICE_TYPE_MODEL: {
            const models = get(target, "deviceModels", []);
            result.device_models = {
              data: models.map(this.createDeviceData("device_model")),
            };
            break;
          }
          case DEVICE_TYPE_FAMILY: {
            const families = get(target, "deviceFamilies", []);
            result.device_families = {
              data: families.map(this.createDeviceData("device_family")),
            };
            break;
          }
          case DEVICE_TYPE_DISTRIBUTOR: {
            const distributors = get(target, "distributors", []);
            result[distributorFieldNameInRequest] = {
              data: distributors.map(
                this.createDeviceData(distributorDeviceType)
              ),
            };
            break;
          }
          case DEVICE_TYPE_QUERY: {
            result.collections = {
              data: [this.createDeviceData("collection")(target.collections)],
            };
            break;
          }
          case DEVICE_TYPE_ALL: {
            result.all_devices = { data: true };
          }
        }

        return result;
      }

      getAttributes = ({ allDevices }) =>
        allDevices ? { attributes: { all_devices: true } } : {};

      createTarget = (type) => (target) => {
        return {
          type,
          ...this.getAttributes(target),
          relationships: {
            ...this.createDevicesByType(target),
            ...this.createCountries(target),
          },
        };
      };

      createTargetsBody = (targetsType, targets) => {
        const targetsBody = targets.map(this.createTarget(targetsType));
        return { data: targetsBody };
      };

      render() {
        return (
          <ComposedComponent
            {...this.props}
            createTargetsBody={this.createTargetsBody}
          />
        );
      }
    }

    return WithTargetsSendHOC;
  };
