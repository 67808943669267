// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const ACTION_VALUES = {
  WATCH: "watch",
  LISTEN: "listen",
  OPEN: "open",
  MORE: "more",
  CUSTOM: "custom",
};

export const ACTIONS_FIXED = Object.values(ACTION_VALUES).filter(
  (a) => a !== ACTION_VALUES.CUSTOM
);

export const FIELDS = {
  PROMO_NAME: {
    dataTestId: "name-field",
    label: trans.PROMOTION_EDIT__FIELD_NAME(),
    name: "name",
  },
  PROMO_TYPE: {
    capitalize: false,
    label: trans.PROMOTION_EDIT__FIELD_TYPE(),
    name: "type",
    required: true,
  },
  PROMO_APPS: {
    name: "apps",
  },
  ACTION: {
    dataTestId: "action-field",
    label: trans.PROMOTION_DETAILS__FIELD_ACTION(),
    name: "action",
    required: true,
  },
  ACTION_CUSTOM: {
    dataTestId: "custom-action",
    look: "simple",
    name: "actionCustomName",
    placeholder: trans.PROMOTION_DETAILS__CUSTOM_ACTION_PLACEHOLDER(),
  },
  APPLICATION: {
    inputPlaceholder: trans.PROMOTION_DETAILS__FIELD_APP_PLACEHOLDER(),
    label: trans.PROMOTION_DETAILS__FIELD_APP(),
    name: "application",
    required: true,
  },
  BACKGROUND: {
    formats: ["image/jpeg"],
    label: trans.PROMOTION_DETAILS__FIELD_FULL_PAGE_LANDSCAPE(),
    name: "background",
    required: true,
  },
  CONTENT_ITEMS: {
    name: "contentItems",
  },
  DESCRIPTION: {
    label: trans.PROMOTION_DETAILS__FIELD_DESCRIPTION(),
    name: "description",
  },
  ICON: {
    formats: ["image/png"],
    label: trans.PROMOTION_DETAILS__FIELD_ICON(),
    name: "icon",
    required: true,
  },
  JSON: {
    formats: ["application/json"],
    label: trans.PROMOTION_DETAILS__FIELD_JSON(),
    language: "json",
    name: "json",
    uploadActionText: trans.PROMOTION_DETAILS__FIELD_JSON_UPLOAD(),
  },
  THUMBNAIL: {
    formats: ["image/png"],
    label: trans.PROMOTION_DETAILS__FIELD_THUMBNAIL(),
    name: "thumbnail",
    required: true,
  },
  TITLE: {
    name: "title",
    label: trans.PROMOTION_DETAILS__FIELD_TITLE(),
  },
};

export const TYPE_NONE = "";
