// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";
import { isRequired, isUnique, pipeValidators } from "utils/validation";

export const validateVersionUrl = (allValues, name) => {
  const errors = pipeValidators(
    isRequired(name),
    isUnique(
      name,
      /versions\[\d+\].url/,
      trans.ASSET__URL_SHOULD_BE_UNIQUE_ERR_MSG()
    )
  )(allValues, allValues);

  if (errors) {
    return errors[name];
  }

  return undefined;
};
