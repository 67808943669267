// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { SearchableListPanel } from "components/elements";
import { Checkbox } from "components/form/elements";

import styles from "./OrganizationsSearchableListRow.scss";

export const OrganizationsSearchableListRow = ({
  id,
  name,
  isChecked,
  onCheck,
}) => (
  <SearchableListPanel key={id} className={styles.container}>
    <Checkbox
      name={id}
      dataTestId={`organization-${id}`}
      value={id}
      onChange={() => onCheck(id)}
      checked={isChecked(id)}
    >
      <span className={styles.item}>{name}</span>
    </Checkbox>
  </SearchableListPanel>
);

OrganizationsSearchableListRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
};
