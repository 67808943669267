// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Manager, Reference } from "react-popper";

import PropTypes from "prop-types";

import { Popup } from "components/popups";
import { classes } from "utils/classes";

import { popupAlignmentType } from "../Popup/types";
import styles from "./tooltip.scss";

export class Tooltip extends Component {
  static propTypes = {
    /** Element that will have tooltip. Hovering over it will show the tooltip content. */
    children: PropTypes.node.isRequired,
    /** Content of the tooltip */
    content: PropTypes.node,
    alignment: popupAlignmentType,
    className: PropTypes.string,
    classNamePopup: PropTypes.string,
    /** Add a title on top of the tooltip */
    title: PropTypes.string,
    /** Disable tooltip state change. This may leave tooltip always open! */
    disabled: PropTypes.bool,
    offset: PropTypes.string,
  };

  static defaultProps = {
    alignment: "right",
    disabled: false,
  };

  state = {
    isOpen: false,
  };

  isEmpty = () => {
    const { title, content } = this.props;
    return !title && !content;
  };

  handleMouseEnter = () => {
    const { disabled } = this.props;

    if (disabled || this.isEmpty()) {
      return;
    }

    this.setState({ isOpen: true });
  };

  handleMouseLeave = () => {
    const { disabled } = this.props;

    if (disabled || this.isEmpty()) {
      return;
    }

    this.setState({ isOpen: false });
  };

  handleClickOnReference = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const {
      children,
      content,
      alignment,
      className,
      classNamePopup,
      title,
      offset,
    } = this.props;

    return (
      <Manager>
        <div
          className={classes(styles.wrapper, className)}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <Reference>
            {({ ref }) => {
              return (
                <div ref={ref} onClick={this.handleClickOnReference}>
                  {children}
                </div>
              );
            }}
          </Reference>
          {this.state.isOpen && (
            <Popup
              alignment={alignment}
              className={classes(styles.popup, classNamePopup)}
              offset={offset}
            >
              {title && <p className={styles.title}>{title}</p>}
              {content}
            </Popup>
          )}
        </div>
      </Manager>
    );
  }
}
