// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const createReducer = (reducer, subtype) => (state, action) => {
  const firstCall = typeof state === "undefined";

  if (action.subtype !== subtype && !firstCall) {
    return state;
  }

  return reducer(state, action);
};
