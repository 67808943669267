// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";
import { hasOneElement } from "utils/validation";

import { Locations, MARKET_COUNTRIES } from "../../../components";

export default {
  name: trans.APP_EDIT_MARKETS__MARKET_MAIN_SECTION_NAME(),
  components: [
    {
      component: Locations,
      fields: ["metadata.location_codes", "metadata.location_market"],
      initialValues: {
        metadata: {
          location_market: MARKET_COUNTRIES,
          location_codes: [],
        },
      },
      validate: hasOneElement("metadata.location_codes"),
      props: ({ formikProps }) => ({
        required: true,
        description: trans.APP_EDIT_MARKETS__APP_DESIGNED_FOR_MARKETS(),
        tooltip: trans.APP_EDIT_MARKETS__APP_DESIGNED_FOR_MARKETS_TOOLTIP(),
        worldwideLabel: trans.WORLDWIDE(),
        countriesLabel: trans.APP_EDIT_MARKETS__SPECIFIC_COUNTRIES(),
        locationCodesField: "metadata.location_codes",
        locationMarketField: "metadata.location_market",
        dataTestId: "markets-main-section",
        ...formikProps,
      }),
    },
  ],
};
