// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { PageContent, PageHeader } from "components/layout";
import { Tabs, TabContent, Tab } from "components/navigation";
import { TextOverflowPopup } from "components/popups";
import { trans } from "src/translations";
import { HtmlTranslation } from "src/translations/utils/components/HtmlTranslation";
import { classes } from "utils/classes";
import { CountriesPropType } from "utils/continents";

import {
  TABS_IDS,
  TAB_ID_MANAGER_ATTRIBUTES,
  TAB_ID_SOFTWARE,
  TAB_ID_SIGNATURES,
  TAB_ID_PDID_HISTORY,
  TAB_ID_GENERIC_ATTRIBUTES,
  getTab,
  TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_KEY,
} from "./constants";
import styles from "./DeviceDetails.scss";
import { DevicePropType } from "./propTypes";
import {
  GeneralTab,
  ManagerAttributesTab,
  SoftwareTab,
  SignaturesTab,
  PdidHistoryTab,
} from "./tabs";
import { GenericAttributesTab } from "./tabs/GenericAttributesTab";

export class DeviceDetailsRend extends PureComponent {
  static propTypes = {
    device: DevicePropType,
    countries: CountriesPropType,
    tabId: PropTypes.string.isRequired,
    subTabId: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchCurrentUpdateJob: PropTypes.func.isRequired,
    updateJobError: PropTypes.string,
    updateJobLoading: PropTypes.bool.isRequired,
    deviceUpdateJobRefreshDate: PropTypes.instanceOf(Date),
  };

  isTabActive = (checkedTabId) => checkedTabId === this.props.tabId;

  renderTabContent() {
    const {
      tabId,
      subTabId,
      device,
      countries,
      fetchCurrentUpdateJob,
      updateJobError,
      updateJobLoading,
      deviceUpdateJobRefreshDate,
    } = this.props;

    switch (tabId) {
      case TAB_ID_MANAGER_ATTRIBUTES: {
        return <ManagerAttributesTab device={device} />;
      }
      case TAB_ID_SOFTWARE: {
        return (
          <SoftwareTab
            device={device}
            activeSubTabId={subTabId}
            fetchCurrentUpdateJob={fetchCurrentUpdateJob}
            updateJobError={updateJobError}
            updateJobLoading={updateJobLoading}
            deviceUpdateJobRefreshDate={deviceUpdateJobRefreshDate}
          />
        );
      }
      case TAB_ID_SIGNATURES: {
        return <SignaturesTab device={device} />;
      }
      case TAB_ID_PDID_HISTORY: {
        return <PdidHistoryTab device={device} />;
      }
      case TAB_ID_GENERIC_ATTRIBUTES: {
        return <GenericAttributesTab device={device} />;
      }
      default:
        return <GeneralTab device={device} countries={countries} />;
    }
  }

  renderContent() {
    const { device, loading, error } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (error || !device) {
      return (
        <Info type="error">
          {error ? error : trans.DEFAULT_ERROR_MESSAGE()}
        </Info>
      );
    }

    return <TabContent>{this.renderTabContent()}</TabContent>;
  }

  render() {
    const { device, loading } = this.props;
    const showTabs = Boolean(!loading && device);
    const isTestDeviceUnit = get(device, "managerAttributes", []).find(
      (attr) => attr.key === TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_KEY
    );

    return (
      <Fragment>
        <PageHeader
          className={classes({
            [styles.headerWithTabs]: showTabs,
          })}
        >
          <TextOverflowPopup>{device?.id ?? ""}</TextOverflowPopup>
          {showTabs && (
            <Tabs className={styles.tabs}>
              {TABS_IDS.map((tabId) => {
                const tab = getTab(tabId, device);
                if (tab.isEmpty) {
                  return null;
                }
                return (
                  <Tab
                    key={tab.id}
                    to={`/devices/inventory/${device.id}/details/${tab.id}`}
                    text={tab.text}
                    dataTestId={tab.dataTestId}
                    className={tab.className}
                    dataTestIconId={`${tab.dataTestId}-alert-icon`}
                    active={this.isTabActive(tab.id)}
                    icon={tab.icon}
                    iconSize="normal"
                    iconColor="error"
                    customIcon={tab.customIcon}
                  />
                );
              })}
            </Tabs>
          )}
        </PageHeader>
        <Fragment>
          <PageContent
            bannerClassName={styles.banner}
            bannerText={
              isTestDeviceUnit
                ? trans.DEVICES__DEVICE_DETAILS_PAGE_BANNER()
                : undefined
            }
            bannerTooltipContent={
              <HtmlTranslation
                transFunc={trans.DEVICES__DEVICE_DETAILS_PAGE_BANNER_TOOLTIP}
              />
            }
          >
            {this.renderContent()}
          </PageContent>
        </Fragment>
      </Fragment>
    );
  }
}
