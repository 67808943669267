// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./Footer.scss";

/** Standard page footer with copyright etc. It should be added automatically and there is no need to manually use this component. */
export class Footer extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;
    return (
      <footer className={classes(styles.footer, className)}>
        {trans.COPYRIGHT()}
      </footer>
    );
  }
}
