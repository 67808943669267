// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { InfiniteData } from "containers/InfiniteData";

import { Autocomplete } from "./Autocomplete";

export class AutocompleteWithInfiniteScroll extends Component {
  static propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    initial: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    itemsPerPage: PropTypes.number,
    /**
     * Fetches data that are passed into "mapData" function.
     */
    fetchData: PropTypes.func.isRequired,
    /**
     * Needed to display Autocomplete's list. Should return array of objects with keys: id, label, value, img.
     */
    mapData: PropTypes.func,
    /**
     * Fetches data that are passed into "mapInitialItem" function.
     *
     * Type: (value: string) => InitialItemType
     */
    // eslint-disable-next-line react/no-unused-prop-types
    fetchInitialItem: PropTypes.func,
    /**
     * Needed to initialize Autocomplete component. Should return object with keys: label, value, img.
     *
     * Type: InitialItemType => {value: string, label: string, img?: string,}
     */
    // eslint-disable-next-line react/no-unused-prop-types
    mapInitialItem: PropTypes.func.isRequired,

    /** props required for Formik managed field */
    name: PropTypes.string,
  };

  state = {
    initialItem: null,
    error: null,
  };

  componentDidMount = () => {
    this.updateInitialItem(this.props);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.initial !== prevProps.initial) {
      this.updateInitialItem(this.props);
    }
  };

  updateInitialItem = async (props) => {
    const { fetchInitialItem, mapInitialItem, initial, value } = props;

    const initialValue = value || initial;

    if (!initialValue) {
      this.setState({ initialItem: null, error: null });
      return;
    }

    try {
      const { results } = await fetchInitialItem(initialValue);
      const mappedData = mapInitialItem(results);

      this.setState({
        initialItem: {
          value: mappedData.value,
          label: mappedData.label,
          img: mappedData.img,
        },
        error: null,
      });
    } catch (error) {
      this.setState({ initialItem: null, error });
    }
  };

  render() {
    const {
      label,
      required,
      tooltip,
      error,
      touched,
      onChange,
      inputPlaceholder,
      fetchData,
      mapData,
      value,
      itemsPerPage,
      disabled,
      name,
    } = this.props;
    const { initialItem } = this.state;

    const fieldError = error || this.state.error;

    return (
      <InfiniteData
        fetchData={fetchData}
        mapData={mapData}
        initialSearch={get(initialItem, "label")}
        itemsPerPage={itemsPerPage}
      >
        {({
          data,
          pending,
          error,
          allDataLoaded,
          handleScroll,
          handleSearchChange,
        }) => (
          <Autocomplete
            onChange={onChange}
            label={label}
            required={required}
            error={fieldError}
            touched={touched}
            tooltip={tooltip}
            items={data}
            initialItem={initialItem}
            inputPlaceholder={inputPlaceholder}
            handleScroll={handleScroll}
            handleSearchChange={handleSearchChange}
            dataPending={pending}
            dataError={error}
            dataLoaded={allDataLoaded}
            value={value}
            disabled={disabled}
            name={name}
          />
        )}
      </InfiniteData>
    );
  }
}

export default AutocompleteWithInfiniteScroll;
