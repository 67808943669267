// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { FiltersList } from "components/elements/SearchBarWithFilters";
import { Info } from "components/feedback";
import { Section, Subsection } from "components/layout";
import { trans } from "src/translations";
import { CountriesPropType } from "utils/continents";
import { formatDateTime } from "utils/date";

import { DEVICE_PROP_TYPES } from "../_shared/DeviceInventoryTable";
import { fromDevicePropertyGetCountry } from "../DeviceDetails/components";
import { LockedInputField } from "./components";
import styles from "./DeviceFilterDetails.scss";
import {
  DETAILS_TAB,
  DEVICE_LIST_TAB,
} from "./DeviceFilterDetailsLayoutWithData";
import { DeviceFilterDetailsTabs } from "./DeviceFilterDetailsTabs";
import { DeviceFilterDeviceListTable } from "./DeviceFilterDeviceListTable";
import { adaptFilters } from "./utils/adaptFilters";

export class DeviceFilterDetailsRend extends PureComponent {
  static propTypes = {
    tabId: PropTypes.string.isRequired,
    filterId: PropTypes.string.isRequired,
    countries: CountriesPropType,
    deviceFilter: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        creationDate: PropTypes.instanceOf(Date).isRequired,
        filtersCount: PropTypes.number.isRequired,
        query: PropTypes.string.isRequired,
        filters: PropTypes.arrayOf(
          PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            displayValue: PropTypes.string.isRequired,
          })
        ).isRequired,
        exclusionFilters: PropTypes.arrayOf(
          PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            displayValue: PropTypes.string.isRequired,
          })
        ),
      }),
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
    }),
    deviceList: PropTypes.shape({
      data: PropTypes.arrayOf(DEVICE_PROP_TYPES).isRequired,
      count: PropTypes.number.isRequired,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.node,
    }),
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
    refreshList: PropTypes.func.isRequired,
  };

  renderDetailsTab = () => {
    const { deviceFilter, countries } = this.props;

    if (deviceFilter.error) {
      return (
        <Info type="error" dataTestId="info-error">
          {deviceFilter.error}
        </Info>
      );
    }

    if (deviceFilter.loading) {
      return <Loader />;
    }

    return (
      <>
        <Section>
          <LockedInputField
            lockMessage={deviceFilter.data.query}
            dataTestId="filter-query-field"
          />
          <FiltersList
            filters={adaptFilters(deviceFilter.data.filters, countries)}
            exclusionFilters={adaptFilters(
              deviceFilter.data.exclusionFilters || [],
              countries
            )}
          />
        </Section>
        <Section
          header={trans.DEVICES__FILTERS_DETAILS_SUMMARY()}
          className={styles.summaryInfo}
        >
          <Subsection
            header={trans.DEVICES__FILTERS_DETAILS_FILTERS()}
            dataTestId="filter-count-section"
          >
            {deviceFilter.data.filtersCount !== 0
              ? deviceFilter.data.filtersCount
              : trans.DEVICES_FILTERS_DETAILS_ALL_FILTER()}
          </Subsection>
          <Subsection
            header={trans.DEVICES__FILTERS_DETAILS_CREATION_DATE()}
            dataTestId="creation-date-section"
          >
            {formatDateTime(deviceFilter.data.creationDate)}
          </Subsection>
        </Section>
      </>
    );
  };

  renderDeviceListTab = () => {
    const {
      deviceList: { data, loading, count, error },
      searchValue,
      changeSearch,
      refreshList,
      countries,
    } = this.props;

    return (
      <DeviceFilterDeviceListTable
        results={data}
        loading={loading}
        count={count}
        error={error}
        searchValue={searchValue}
        changeSearch={changeSearch}
        refreshList={refreshList}
        getCountry={fromDevicePropertyGetCountry("country", countries)}
      />
    );
  };

  renderContent = () => {
    switch (this.props.tabId) {
      case DETAILS_TAB:
        return this.renderDetailsTab();
      case DEVICE_LIST_TAB:
        return this.renderDeviceListTab();
    }
    return null;
  };

  render() {
    const { tabId, filterId } = this.props;

    return (
      <>
        <Section dataTestId="filter-section">
          <DeviceFilterDetailsTabs filterId={filterId} tabId={tabId} />
        </Section>
        {this.renderContent()}
      </>
    );
  }
}
