// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext } from "react";

import PropTypes from "prop-types";

import { Uploader } from "components/form/Uploader";
import { trans } from "src/translations";
import { cropImage, validateImage } from "utils/image";

import { FIELDS } from "../constants";
import { ModeContext } from "../context";

const MIN_WIDTH = 480;
const MIN_HEIGHT = 270;

export const ThumbnailField = ({ name }) => {
  const { isReadonly } = useContext(ModeContext);

  const validate = (blobUrl) => {
    return validateImage(blobUrl, {
      minWidth: MIN_WIDTH,
      minHeight: MIN_HEIGHT,
      maxSize: 9999999,
      validateAspectRatio: true,
    });
  };

  return (
    <Uploader
      {...FIELDS.THUMBNAIL}
      name={`${name}.${FIELDS.THUMBNAIL.name}`}
      info={trans.PROMOTION_DETAILS__FIELD_THUMBNAIL_INFO({
        format: "png",
        minWidth: MIN_WIDTH,
        minHeight: MIN_HEIGHT,
        aspectRatio: "16:9",
      })}
      validate={validate}
      disabled={isReadonly}
      preprocess={(blobUrl, type) =>
        cropImage(blobUrl, type, MIN_WIDTH, MIN_HEIGHT)
      }
    />
  );
};

ThumbnailField.propTypes = {
  name: PropTypes.string.isRequired,
};
