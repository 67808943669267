// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { Label, UploaderAWSField } from "components/form";
import { Input } from "components/form/elements";
import { Link } from "components/typography";
import { trans } from "src/translations";

import { AwsUploaderChildrenProps, FormatPropType } from "../propTypes";
import { TextsPropTypes } from "./propTypes";
import styles from "./UploaderAWSModalContent.scss";
import { validateExternalUploadUrl } from "./utils";

export class UploaderAWSModalContent extends PureComponent {
  static propTypes = {
    onExternalUrlInputChange: PropTypes.func.isRequired,
    uploaderStatus: AwsUploaderChildrenProps.isRequired,
    externalFileUrl: PropTypes.string.isRequired,
    hasSubmittedInvalidExternalUrl: PropTypes.bool.isRequired,
    formats: FormatPropType,
    texts: TextsPropTypes,
  };

  state = {
    showLinkFromWeb: false,
  };

  toggleLinkFromWebInput = () => {
    this.setState((state) => ({
      showLinkFromWeb: !state.showLinkFromWeb,
    }));
  };

  render() {
    const {
      onExternalUrlInputChange,
      uploaderStatus,
      formats,
      texts,
      externalFileUrl,
      hasSubmittedInvalidExternalUrl,
    } = this.props;
    const { showLinkFromWeb } = this.state;
    const showExternalUrlError =
      hasSubmittedInvalidExternalUrl &&
      !validateExternalUploadUrl(externalFileUrl);

    return (
      <Fragment>
        {texts.description && (
          <p className={styles.description}>{texts.description}</p>
        )}

        <Label
          text={trans.UPLOADER_AWS_MODAL_EXTERNAL_FILE()}
          className={styles.fileUpload}
        >
          <UploaderAWSField
            uploaderStatus={uploaderStatus}
            formats={formats}
            uploadHint={
              texts.fileExtensionHint && (
                <div className={styles.fileTypeHintContainer}>
                  {texts.fileExtensionHint}
                </div>
              )
            }
          />
        </Label>

        <div className={styles.linkFromWebContainer}>
          {trans.UPLOADER_AWS_MODAL_LINK_FROM_WEB()}{" "}
          <Link
            onClick={this.toggleLinkFromWebInput}
            data-test-id="show-external-url-input-button"
          >
            {trans.UPLOADER_AWS_MODAL_LINK_FROM_WEB_BUTTON()}
          </Link>
          {showLinkFromWeb && (
            <div>
              {trans.UPLOADER_AWS_MODAL_LINK_FROM_WEB_URL()}{" "}
              <Input
                look="simple"
                placeholder={trans.UPLOADER_AWS_MODAL_LINK_FROM_WEB_INPUT_PLACEHOLDER()}
                className={styles.linkFromWebInput}
                onChange={onExternalUrlInputChange}
                dataTestId="link-input"
                value={externalFileUrl}
              />
              {showExternalUrlError && (
                <Info
                  type="error"
                  className={styles.errorMessage}
                  dataTestId="invalid-url-error"
                >
                  {trans.UPLOADER_AWS_MODAL_EXTERNAL_URL_VALIDATION_ERROR()}
                </Info>
              )}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
