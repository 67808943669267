// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { trans } from "../../translations";

export interface Continent {
  code: string;
  name: string;
}

export interface Country {
  code: string;
  continents: string[];
  name: string;
  type: string;
}
/**
 * Backend returns 2-letter codes for each continent. Since we have to display
 * actual names, we will use conversion table as follows.
 *
 * KEYS MUST MATCH ENTRIES IN GeoMap/utils/continents.js!
 */
export const CONTINENTS = {
  AFRICA: { code: "ZA", name: trans.CONTINENTS__AFRICA() },
  ANTARCTICA: { code: "AQ", name: trans.CONTINENTS__ANTARCTICA() },
  ASIA: { code: "AS", name: trans.CONTINENTS__ASIA() },
  EUROPE: { code: "EU", name: trans.CONTINENTS__EUROPE() },
  NORTH_AMERICA: { code: "NA", name: trans.CONTINENTS__NORTH_AMERICA() },
  OCEANIA: { code: "OC", name: trans.CONTINENTS__OCEANIA() },
  SOUTH_AMERICA: { code: "SA", name: trans.CONTINENTS__SOUTH_AMERICA() },
  WORLD: { code: "ZZ", name: trans.CONTINENTS__WORLD() },
};

export const getContinentName = (code: string): string | undefined => {
  const continent = Object.values(CONTINENTS).find((c) => c.code === code);
  return get(continent, "name");
};

export const CONTINENT_WORLD: string = getContinentName("ZZ") as string;
export const CONTINENT_EUROPE: string = getContinentName("EU") as string;

export const isRealContinent = (c: string): boolean => c !== CONTINENT_WORLD;

export const getContinentNames = (): string[] => {
  const realContinents = Object.values(CONTINENTS).reduce<string[]>(
    (acc, continent) => {
      if (isRealContinent(continent.name)) {
        acc.push(continent.name);
      }
      return acc;
    },
    []
  );

  return [CONTINENT_WORLD, ...realContinents.sort()];
};

export const getCountriesForContinent = (
  countries: Country[],
  continent: string
): Country[] => {
  if (!isRealContinent(continent)) {
    return countries;
  }
  return countries.filter((country) => country.continents.includes(continent));
};
