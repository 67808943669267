// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import { IconPropTypes } from "../propTypes";
import styles from "./GrabsonIcon.scss";

export class GrabsonIcon extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired, // check names defined in GrabsonIcon.scss
    size: IconPropTypes.size,
    font: PropTypes.oneOf(["normal", "micro"]),
    className: PropTypes.string,
    dataTestId: PropTypes.string,
  };
  static defaultProps = {
    size: "sm",
    font: "normal",
    className: "",
    dataTestId: "icon",
  };

  render() {
    const { name, size, font, className, dataTestId, ...props } = this.props;

    return (
      <span
        className={classes(className, styles.wrapper, styles[`icon-${size}`])}
        data-test-id={dataTestId}
      >
        <i
          {...props}
          className={classes(
            styles.icon,
            styles[`icon-${name}`],
            styles[`icon-${size}`],
            styles[`font-${font}`]
          )}
        />
      </span>
    );
  }
}
export default GrabsonIcon;
