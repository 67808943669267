// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./progressbar.scss";

const DEFAULT_PROGRESS_TOTAL = 100;

export class Progressbar extends Component {
  static propTypes = {
    loaded: PropTypes.number,
    total: PropTypes.number,
    showPercentage: PropTypes.bool,
    isError: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    loaded: 0,
    total: DEFAULT_PROGRESS_TOTAL,
    showPercentage: false,
    isError: false,
  };

  getProgressClass() {
    const { isError } = this.props;

    if (isError) {
      return styles.progressError;
    }

    return styles.progress;
  }

  render() {
    const { loaded, total, showPercentage, className } = this.props;

    if (!Number.isInteger(loaded) || !Number.isInteger(total)) {
      return (
        <Info type="error">
          {trans.PROGRESSBAR__LOADED_AND_TOTAL_INTEGER_ERROR()}
        </Info>
      );
    }

    if (total === 0) {
      return (
        <Info type="error">{trans.PROGRESSBAR__TOTAL_NOT_ZERO_ERROR()}</Info>
      );
    }

    if (loaded > total) {
      return (
        <Info type="error">
          {trans.PROGRESSBAR__TOTAL_LESS_THAN_LOADED_ERROR()}
        </Info>
      );
    }

    const progress = Math.floor((loaded * DEFAULT_PROGRESS_TOTAL) / total);

    return (
      <div
        className={classes(styles.progressbar, className)}
        data-test-id="progress-bar"
      >
        <div
          className={this.getProgressClass()}
          style={{ width: `${progress}%` }}
        />
        {showPercentage && (
          <span className={styles.percentageText}>{`${progress}%`}</span>
        )}
      </div>
    );
  }
}
