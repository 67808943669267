import { useState, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { FormPagination } from "components/form";
import { Overlay } from "components/layout";
import { Tabs, Tab } from "components/navigation";
import { Permissions, ROLES } from "containers/Permissions";
import { trans } from "src/translations";
import { pathToUrl } from "utils/url";

import { ManifestEditForm } from "../../_shared";
import {
  ManifestDataContext,
  adaptDataToForm,
} from "../../_shared/ManifestData";
import { getActiveTabId as getActiveSubTab } from "../../_shared/utils";
import { tabsConfig, APP_ID_FORM_FIELDS } from "./constants";
import { ErrorNotification } from "./ErrorNotification";
import { ManifestTab } from "./ManifestTab";
import { TargetTab } from "./TargetTab";

const createTabLink = ({ tabId, id }) => {
  const routePath = id
    ? "/manifests/edit/:id/:tabId"
    : "/manifests/create/:tabId";

  return pathToUrl(routePath, {
    id: encodeURIComponent(id),
    tabId,
  });
};

const isTabValid = (fields, errors) => {
  return fields.every((fieldName) => !errors[fieldName]);
};

export function ManifestForm({
  children,
  appIdInitValues,
  updateAppType,
  updating,
}) {
  const innerFormRef = useRef();
  const navigate = useNavigate();
  const { tabId, id } = useParams();
  const { results, appType } = useContext(ManifestDataContext);
  const [errorsBanner, setErrorsBanner] = useState(false);

  const getActiveTab = () => tabsConfig.findIndex(({ path }) => path === tabId);

  const updateAppId = async () => {
    if (innerFormRef.current && innerFormRef.current.dirty) {
      await innerFormRef.current.submitForm();
    }
  };

  const handleNav = (num) => {
    const nextPage = tabsConfig[getActiveTab() + num];
    return createTabLink({ tabId: nextPage.path, id });
  };

  const handleNextClick = () => {
    updateAppId();
    return handleNav(1);
  };

  const getTabs = (errors) =>
    tabsConfig.map((tab, idx) => {
      const isValid = isTabValid(tab.validateFields, errors);
      return (
        <Tab
          key={tab.path}
          active={getActiveTab() === idx}
          text={tab.text}
          icon={isValid ? "ok" : "warning"}
          iconColor={isValid ? "success" : "error"}
          dataTestId={tab.dataTestId}
          onClick={() => {
            if (idx === 1) {
              updateAppId();
            }
            navigate(createTabLink({ tabId: tab.path, id }));
          }}
        />
      );
    });

  return (
    <ManifestEditForm
      initialValues={{
        ...adaptDataToForm(results?.manifest ?? {}),
        [APP_ID_FORM_FIELDS.applicationId]:
          appIdInitValues[APP_ID_FORM_FIELDS.applicationId],
        [APP_ID_FORM_FIELDS.internalApp]:
          appIdInitValues[APP_ID_FORM_FIELDS.internalApp],
      }}
      render={({
        isSubmitting,
        errors,
        isValid,
        setValues,
        handleSubmit,
        values,
      }) => (
        <Permissions allowed={[ROLES.moderator.appIntegrator]}>
          {(isAllowed) => (
            <>
              <Tabs>{getTabs(errors)}</Tabs>
              <Overlay
                active={isSubmitting || updating}
                message={trans.SUBMITTING()}
              >
                <ErrorNotification />
                {getActiveTab() === 0 && (
                  <TargetTab
                    initialValues={{
                      applicationId: values[APP_ID_FORM_FIELDS.applicationId],
                      internalApp: values[APP_ID_FORM_FIELDS.internalApp],
                    }}
                    onSubmit={(updatedValues) => {
                      setValues({ ...values, ...updatedValues });
                      updateAppType(updatedValues.applicationId);
                    }}
                    isReadonly={Boolean(id) || !isAllowed}
                    innerRef={innerFormRef}
                  />
                )}
                {getActiveTab() === 1 &&
                  (isAllowed ? (
                    <ManifestTab
                      tabId={tabId}
                      appType={appType}
                      getActiveSubTab={getActiveSubTab}
                      errorsBanner={errorsBanner}
                      errors={errors}
                    >
                      {children}
                    </ManifestTab>
                  ) : (
                    children
                  ))}
                <FormPagination
                  activeTab={getActiveTab()}
                  dirty={!isValid}
                  submitting={isSubmitting}
                  onNext={handleNextClick}
                  onPrev={() => handleNav(-1)}
                  onSubmit={isAllowed ? handleSubmit : () => {}}
                  tabCount={tabsConfig.length}
                  tabTitle={tabsConfig.map((t) => t.text)[getActiveTab()]}
                />
              </Overlay>
            </>
          )}
        </Permissions>
      )}
      manifestData={results}
      setErrorsBanner={setErrorsBanner}
      onCreateCompleted={(appId) => {
        navigate(createTabLink({ tabId: tabId, id: appId }));
        window.scrollTo(0, 0);
      }}
    />
  );
}

ManifestForm.propTypes = {
  appIdInitValues: PropTypes.object.isRequired,
  children: PropTypes.node,
  updateAppType: PropTypes.func.isRequired,
  updating: PropTypes.bool,
};
