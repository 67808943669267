// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { APP_ENVIRONMENT } from "pages/Applications/AppDetails/constants";

import { ProfilesTable } from "./ProfilesTable";

export class Profiles extends PureComponent {
  static propTypes = {
    appEnvironmentType: PropTypes.oneOf([
      APP_ENVIRONMENT.LINUX,
      APP_ENVIRONMENT.ANDROID,
    ]).isRequired,
    createModalForm: PropTypes.func.isRequired,
    generalError: PropTypes.string,
    // from @Formik
    profilesFieldName: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    getFieldMeta: PropTypes.func.isRequired,
  };

  state = {
    modalActiveProfileIndex: null,
    showModal: false,
  };

  closeModal = () => {
    this.setState({
      modalActiveProfileIndex: null,
      showModal: false,
    });
  };

  deleteProfile = (index, profiles) => {
    const { setFieldValue, profilesFieldName } = this.props;
    const newProfilesValues = [
      ...profiles.slice(0, index),
      ...profiles.slice(index + 1),
    ];

    setFieldValue(profilesFieldName, newProfilesValues);
  };

  editProfile = (index) => {
    this.setState({
      modalActiveProfileIndex: index,
      showModal: true,
    });
  };

  acceptChanges = (newProfile, profiles) => {
    const { setFieldValue, profilesFieldName } = this.props;
    const { modalActiveProfileIndex } = this.state;
    let newProfilesValues;

    if (modalActiveProfileIndex === null) {
      newProfilesValues = [...profiles, newProfile];
    } else {
      newProfilesValues = [
        ...profiles.slice(0, modalActiveProfileIndex),
        newProfile,
        ...profiles.slice(modalActiveProfileIndex + 1),
      ];
    }

    setFieldValue(profilesFieldName, newProfilesValues);
    this.closeModal();
  };

  render() {
    const { showModal, modalActiveProfileIndex } = this.state;
    const {
      appEnvironmentType,
      profilesFieldName,
      getFieldMeta,
      generalError,
      createModalForm,
    } = this.props;

    const { value: profiles, error } = getFieldMeta(profilesFieldName);
    const handleAcceptChanges = (newProfile) =>
      this.acceptChanges(newProfile, profiles);

    return (
      <Fragment>
        <ProfilesTable
          editProfile={this.editProfile}
          deleteProfile={(index) => this.deleteProfile(index, profiles)}
          profiles={profiles}
          generalError={generalError}
          error={error}
        />

        {showModal &&
          createModalForm(profiles[this.state.modalActiveProfileIndex], {
            appEnvironmentType: appEnvironmentType,
            onClose: this.closeModal,
            acceptChanges: handleAcceptChanges,
            isEdit: modalActiveProfileIndex !== null,
          })}
      </Fragment>
    );
  }
}
