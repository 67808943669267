// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Row, Section, Subsection } from "components/layout";
import { FIELDS, SECTIONS } from "pages/Snap/shared/constants";

import styles from "../SnapApplicationDetails.scss";

export class SnapGraphics extends PureComponent {
  static propTypes = {
    revision: PropTypes.object.isRequired,
  };

  static renderImage(image, type) {
    return (
      <div className={styles[type]}>
        {image && <img src={image} alt={type} />}
      </div>
    );
  }

  render() {
    const { revision } = this.props;

    return (
      <Section header={SECTIONS.GRAPHICS.header}>
        <Row>
          <Subsection
            header={FIELDS.LOGO.label}
            className={styles.assetsSection}
          >
            {SnapGraphics.renderImage(revision.app_logo_url, "logo")}
          </Subsection>
          <Subsection
            header={FIELDS.BACKGROUND.label}
            className={styles.assetsSection}
          >
            {SnapGraphics.renderImage(
              revision.app_background_url,
              "background"
            )}
          </Subsection>
        </Row>
        <Subsection header={FIELDS.AUTODARKEN_BG.label}>
          {revision.autodarken_background === "true"
            ? SECTIONS.GRAPHICS.autodarkenEnabled
            : SECTIONS.GRAPHICS.autodarkenDisabled}
        </Subsection>
      </Section>
    );
  }
}
