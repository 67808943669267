// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField } from "formik";

import { createMixedValidator } from ".";
import { Textarea } from "../elements";
import { FieldProps } from "./propTypes";

export const TextareaField = (props) => {
  const { required = true, validate, ...rest } = props;

  const [field, meta] = useField({
    ...props,
    required,
    validate: createMixedValidator(props.name, required, validate),
    type: "textarea",
  });

  return (
    <Textarea
      required={required}
      {...field}
      {...meta}
      {...rest}
      isFormik={true}
    />
  );
};

TextareaField.propTypes = FieldProps;
