// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import queryString from "query-string";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBar } from "components/elements";
import { Section } from "components/layout";
import { restrictedArea, ROLES } from "containers/Permissions";
import { api } from "containers/Request";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { withRouter, withSearch } from "utils/decorators";

import { AssetTableBody } from "./AssetTableBody";
import { tabsConfig } from "./tabsConfig";

@withRouter
@withSearch
@restrictedArea(() => ({
  allowed: [ROLES.administrator.distributionAdmin],
}))
@connect(null, (dispatch, { location: { search } }) => ({
  getAssetsPaginated: () => {
    const searchObj = queryString.parse(search);
    if (!searchObj.page) {
      searchObj.page = 1;
    }
    return dispatch(api.getAssetsPaginated.action({ queryParams: searchObj }));
  },
}))
export class AssetTableContent extends Component {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object.isRequired,

    // from @connect
    getAssetsPaginated: PropTypes.func,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
  };

  state = { assets: [], loading: true, error: null, count: 0 };

  async componentDidMount() {
    await this.requestAssets();
  }

  async componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      await this.requestAssets();
    }
  }

  async requestAssets() {
    const { getAssetsPaginated } = this.props;

    this.setState({ loading: true });

    const { result, error } = await getAssetsPaginated();

    if (error) {
      this.setState({
        error: error,
        loading: false,
      });
    } else {
      this.setState({
        error: null,
        loading: false,
        assets: result.results.map(this.mapAsset),
        count: result.meta.total_objects,
      });
    }
  }

  mapAsset = ({ id, name, description, versions }) => ({
    id,
    name,
    description,
    versions: versions.map(this.mapVersion),
  });

  mapVersion = ({ id, label: number, url }) => ({
    id,
    number,
    url,
  });

  renderTableBody(assets) {
    return <AssetTableBody assets={assets} />;
  }

  renderAddButton = () => {
    return (
      <ButtonsWrapper>
        <Button type="green" to="/software-assets/new">
          {trans.ASSET__ADD_NEW_ASSET_BUTTON()}
        </Button>
      </ButtonsWrapper>
    );
  };

  render() {
    const { searchValue, changeSearch } = this.props;
    const { assets, loading, error, count } = this.state;

    return (
      <div>
        <Section header={trans.ASSET__SOFTWARE_ASSETS_HEADER()}>
          <Section>
            <SearchBar
              value={searchValue}
              onSearch={changeSearch}
              placeholder={trans.ASSET__SEARCH_ASSET_PLACEHOLDER()}
            />
          </Section>
          {this.renderAddButton()}
          <TabbedTable
            tabsConfig={tabsConfig}
            data={{
              results: assets,
              count,
              loading,
              error,
            }}
            renderTableBody={this.renderTableBody}
          />
          {this.renderAddButton()}
        </Section>
      </div>
    );
  }
}
