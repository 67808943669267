// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { trans } from "src/translations";

import styles from "./Results.scss";

export class Results extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    legend: PropTypes.node,
  };

  render() {
    const { page, total, rowsPerPage, legend } = this.props;
    let from = (page - 1) * rowsPerPage + 1;
    const to = page * rowsPerPage > total ? total : page * rowsPerPage;

    if (!total) {
      from = 0;
    }

    return (
      <div className={styles.results}>
        <span className={styles.counter}>
          {from} - {to} {trans.TABLE__RESULTS_OF_TOTAL()}{" "}
          <span className={styles.total}>{total}</span>
        </span>
        {legend}
      </div>
    );
  }
}
