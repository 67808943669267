// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Routes, Navigate, Route } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";
import { trans } from "src/translations";

import { ProcessingQueue } from "./ProcessingQueue/ProcessingQueue";
import { SoftwarePackageDetailsData } from "./SoftwarePackageDetails/SoftwarePackageDetailsData";
import { SoftwarePackages } from "./SoftwarePackages/SoftwarePackages";
import { UpdateJobDetailsData } from "./UpdateJobDetails/UpdateJobDetailsData";
import { UpdateJobForm } from "./UpdateJobForm/UpdateJobForm";
import { UpdateJobList } from "./UpdateJobList/UpdateJobList";

export class UpdatesRoute extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<RedirectDependingOnRoles />} />
        <Route
          exact
          path="/packages"
          element={
            <ExtendedRoute
              name={trans.UPDATES__SOFTWARE_PACKAGES__ROUTE_NAME()}
              component={SoftwarePackages}
            />
          }
        />
        <Route
          exact
          path="/packages/:id/version/:version"
          element={
            <ExtendedRoute
              name={trans.DETAILS()}
              component={SoftwarePackageDetailsData}
            />
          }
        />
        <Route
          exact
          path="/queue"
          element={
            <ExtendedRoute
              name={trans.UPDATES__PROCESSING_QUEUE__ROUTE_NAME()}
              component={ProcessingQueue}
            />
          }
        />
        <Route
          exact
          path="/create"
          element={
            <ExtendedRoute
              name={trans.UPDATES__CREATE_UPDATE_JOB_ROUTE_NAME()}
              component={UpdateJobForm}
            />
          }
        />
        <Route
          exact
          path="/jobs/list/:tabId"
          element={
            <ExtendedRoute
              name={trans.UPDATES__UPDATE_JOB_LIST__ROUTE_NAME()}
              component={UpdateJobList}
            />
          }
        />
        <Route
          exact
          path="/jobs"
          element={<Navigate to="/updates/jobs/list/active" replace={true} />}
        />

        <Route
          exact
          path="/jobs/:id"
          element={
            <ExtendedRoute
              name={trans.DETAILS()}
              component={UpdateJobDetailsData}
            />
          }
        />
        <Route
          exact
          path="/jobs/:id/eligible_devices"
          element={
            <ExtendedRoute
              name={trans.UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_ROUTE_NAME()}
              component={UpdateJobDetailsData}
            />
          }
        />
        <Route path="*" element={() => <Navigate to="/404" />} />
      </Routes>
    );
  }
}
