// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const UPLOAD_STATUS = {
  idle: "idle",
  progress: "progress",
  completed: "completed",
  error: "error",
};

export const UPLOAD_STATUS_PROP_TYPES = Object.values(UPLOAD_STATUS);

/**
 * We get no saying in that. S3 only supports xml responses.
 */
export const EXPECTED_AWS_S3_RESPONSE_CONTENT_TYPE = "application/xml";
