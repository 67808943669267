// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import cloneDeep from "lodash-es/cloneDeep";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { TextField } from "components/form/fields";
import { VerticalTabs } from "components/layout";
import { trans } from "src/translations";

export class VersionsField extends Component {
  static propTypes = {
    onSelectVersion: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    initSelectedIndex: PropTypes.number.isRequired,
    versions: PropTypes.array.isRequired,
    errors: PropTypes.array,
  };

  bumpVersion = (version = "0.0.0") => {
    const arr = version.split(".");
    const lastIdx = arr.length - 1;
    arr[lastIdx] = Number(arr[lastIdx]) + 1;
    return arr.join(".");
  };

  removeVersion = (index) => {
    const { setFieldValue, versions } = this.props;
    const mutableVersions = cloneDeep(versions);

    if (index === 0) {
      return;
    }

    mutableVersions.splice(index, 1);
    setFieldValue("versions", mutableVersions);
  };

  addVersion = () => {
    const { versions, setFieldValue } = this.props;
    const lastVersion = versions[versions.length - 1];

    setFieldValue(`versions[${versions.length}]`, {
      number: this.bumpVersion(lastVersion.number),
      url: "",
      isSaved: false,
      isDefault: false,
    });
  };

  generateTabNames = () => {
    const { versions } = this.props;

    return versions.map((v, idx) => {
      const isFirst = idx === 0;

      return {
        name: v.number,
        noRemove: isFirst || v.isSaved || v.isDefault,
        invalid: this.isVersionInvalid(idx),
        default: v.isDefault,
      };
    });
  };

  duplicateVersion = (versionToDuplicate) => () => {
    const { versions, setFieldValue } = this.props;
    const lastVersion = versions[versions.length - 1];

    setFieldValue(`versions[${versions.length}]`, {
      ...versionToDuplicate,
      number: this.bumpVersion(lastVersion.number),
      isSaved: false,
      isDefault: false,
    });
  };

  handleTabChange = (index) => {
    const { onSelectVersion } = this.props;
    onSelectVersion(index);
  };

  setVersionAsDefault = (index) => () => {
    const { setFieldValue, versions } = this.props;
    const mutableVersions = cloneDeep(versions);
    const oldDefaultIndex = mutableVersions.findIndex((el) => el.isDefault);

    mutableVersions[oldDefaultIndex].isDefault = false;
    mutableVersions[index].isDefault = true;

    setFieldValue("versions", mutableVersions);
  };

  isVersionInvalid = (idx) => {
    const { errors } = this.props;
    let isInvalid = false;

    if (errors) {
      const tabErrors = errors[idx];
      if (tabErrors) {
        isInvalid = Object.values(tabErrors).some((val) => Boolean(val));
      }
    }

    return isInvalid;
  };

  render() {
    const { initSelectedIndex, versions } = this.props;

    return (
      <VerticalTabs
        onRemove={this.removeVersion}
        onAdd={this.addVersion}
        tabNames={this.generateTabNames()}
        disabled={false}
        labelAddNewItem={trans.ASSET__ADD_VERSION_BUTTON()}
        onTabChange={this.handleTabChange}
        initSelectedIndex={initSelectedIndex}
      >
        {(index) => {
          const selectedVersion = versions[index];
          const isCurrentVersionInvalid = this.isVersionInvalid(index);

          if (!selectedVersion) {
            return null;
          }

          return (
            <div>
              <TextField
                name={`versions[${index}].number`}
                label={trans.ASSET__VERSION_LABEL()}
                tooltip={trans.ASSET__VERSION_TOOLTIP()}
                disabled={selectedVersion.isSaved}
                touched={true}
              />
              <TextField
                name={`versions[${index}].url`}
                label={trans.ASSET__VERSION_URL_LABEL()}
                tooltip={trans.ASSET__VERSION_URL_TOOLTIP()}
                touched={true}
              />

              <ButtonsWrapper position="center">
                <Button
                  onClick={this.setVersionAsDefault(index)}
                  disabled={selectedVersion.isDefault}
                >
                  {trans.ASSET__SET_VERSION_AS_DEFAULT_BUTTON()}
                </Button>
                <Button
                  onClick={this.duplicateVersion(selectedVersion)}
                  type="normal"
                  disabled={isCurrentVersionInvalid}
                >
                  {trans.ASSET__DUPLICATE_VERSION_BUTTON()}
                </Button>
              </ButtonsWrapper>
            </div>
          );
        }}
      </VerticalTabs>
    );
  }
}
