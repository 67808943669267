// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable";
import { SECTIONS } from "pages/Snap/shared/constants";
import { SnapApplicationTableRow } from "pages/Snap/SnapList";

export default class SnapApplicationTable extends Component {
  static propTypes = {
    applicationsNumber: PropTypes.number.isRequired,
    applicationList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        broadcaster_id: PropTypes.string.isRequired,
        owner_id: PropTypes.string,
      })
    ),
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
  };

  static tabsConfig = [
    {
      dataTestId: "promotions-list",
      columns: [
        { label: SECTIONS.APPS_LIST.colName },
        { label: SECTIONS.APPS_LIST.colCreated },
        { label: SECTIONS.APPS_LIST.colStatus },
        { label: SECTIONS.APPS_LIST.colSubmitted },
        {},
        {},
      ],
    },
  ];

  isRefreshingData() {
    const { applicationList, loading } = this.props;
    return loading && applicationList && applicationList.length > 0;
  }

  renderBody = () => {
    const { applicationList } = this.props;
    return (
      <TableBody>
        {applicationList.map((application) => (
          <SnapApplicationTableRow
            appId={application.broadcaster_id}
            key={application.broadcaster_id}
            {...application}
          />
        ))}
      </TableBody>
    );
  };

  render() {
    const { applicationList, applicationsNumber, loading, error } = this.props;

    return (
      <TabbedTable
        tabsConfig={SnapApplicationTable.tabsConfig}
        data={{
          results: applicationList,
          count: applicationsNumber,
          loading,
          error,
        }}
        renderTableBody={this.renderBody}
        noResultsMessage={
          <p>
            You have not created any application. Please use the following{" "}
            <NavLink to="/snap/create">link</NavLink> to create a new Snap
            application.
          </p>
        }
      />
    );
  }
}
