// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Tab } from "components/navigation";
import { classes } from "utils/classes";
import { innerRefType } from "utils/types";

import styles from "./subTab.scss";

export class SubTab extends Component {
  static propTypes = {
    /** If true tab is set as active */
    active: PropTypes.bool,
    /** Use if GrabsonIcon is not sufficent. */
    customIcon: PropTypes.node,
    dataTestId: PropTypes.string,
    /** Icon name. See [GrabsonIcon](#grabsonicon) for available icons. */
    icon: PropTypes.string,
    /** Icon color. */
    iconColor: PropTypes.oneOf(["default", "success", "error"]),
    innerRef: innerRefType,
    look: PropTypes.oneOf(["default", "gray2"]),
    /** Called on tab click */
    onClick: PropTypes.func,
    /** Scroll to active element on first render */
    scrollIntoView: PropTypes.bool,
    /** Tab label text. */
    text: PropTypes.string,
    /** HTML link. */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  static defaultProps = {
    dataTestId: "sub-tab",
    iconColor: "default",
    look: "default",
    scrollIntoView: false,
  };

  render() {
    const { active, dataTestId, look } = this.props;

    return (
      <Tab
        {...this.props}
        className={classes(styles.container, styles[look], {
          [styles.active]: active,
        })}
        activeClassName={styles.active}
        dataTestId={dataTestId}
      />
    );
  }
}

export default SubTab;
