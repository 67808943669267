// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { APP_ENVIRONMENT } from "pages/Applications/AppDetails/constants";

import { NO_REVENUE, HAS_REVENUE } from "../components/Monetization";
import { DISTRIBUTION_TYPES, APP_TYPES } from "../constants";

const getDistributionFlags = ({ distribute_in_tvstore, appType }) => {
  if (distribute_in_tvstore === DISTRIBUTION_TYPES.EXAMINE) {
    return {
      for_examination_only: true,
      is_certifiable: false,
      is_distributable: false,
    };
  }

  if (distribute_in_tvstore === DISTRIBUTION_TYPES.VEWD_CERTIFICATION_PROGRAM) {
    return {
      for_examination_only: false,
      is_certifiable: true,
      is_distributable: false,
    };
  }

  if (appType === APP_TYPES.HTML) {
    return {
      for_examination_only: false,
      is_certifiable: true,
      is_distributable: true,
    };
  }

  return {
    for_examination_only: false,
    is_certifiable: false,
    is_distributable: true,
  };
};

export const getDistributionType = ({
  for_examination_only,
  is_certifiable,
  is_distributable,
}) => {
  if (for_examination_only) {
    return DISTRIBUTION_TYPES.EXAMINE;
  }

  if (is_certifiable && !is_distributable) {
    return DISTRIBUTION_TYPES.VEWD_CERTIFICATION_PROGRAM;
  }

  return DISTRIBUTION_TYPES.VEWD_CATALOGUE;
};

/**
 * Checks if there is monetization object and if any of its value is truthy
 * @param {Array} monetization
 * @returns {boolean}
 */
const isMonetized = (monetization) => {
  return (
    monetization && Object.values(monetization).some((value) => Boolean(value))
  );
};

/**
 * This function convert data from old IronMaiden applications api, to format
 * for new app inventory service api.
 *
 * It's quick solution. If you don't like it, you should rewrite submission form
 * to work on app inventory api directly. It's doable, but it will require
 * a lot of small fixes and testing.
 * @author k.orzechowski
 */
export const adaptToRequest = (imData, appType) => {
  const { metadata, support_email, changelog } = imData;
  const {
    application_url,
    category,
    distribute_in_tvstore,
    agreement_version,
    terms_accepted,
    accepted_criteria,
    comment,
    additional_comments,
    android_profiles,
    linux_profiles,
  } = metadata;

  const data = {
    application_url,
    category,
    support_email,
    additional_comments,
    description_for_moderators: comment,
    agreement_version,
    terms_accepted,
    accepted_criteria,
    changelog,
    ...getDistributionFlags({ distribute_in_tvstore, appType }),
  };

  if (metadata.translations) {
    data.translations = metadata.translations.map((t) => ({
      language: t.language,
      app_title: t.name,
      app_short_description: t.short_description,
      is_default: t.default_translation,
    }));
  }

  data.images = [
    ...["icon_big", "icon_small", "thumbnail", "billboard"]
      .filter(
        (type) => typeof metadata[type] === "string" && metadata[type] !== ""
      )
      .map((type) => ({
        image: metadata[type],
        type: type.toUpperCase(),
      })),

    ...metadata.screenshot
      .filter(
        (imageObj) =>
          Boolean(imageObj) &&
          typeof imageObj.url === "string" &&
          imageObj.url !== ""
      )
      .map((imageObj) => {
        return {
          image: imageObj.url,
          type: "SCREENSHOT",
        };
      }),
  ];

  data.technical_details = {
    video_type: {
      contains_live_video: Boolean(metadata.contains_live_video),
      contains_vod: Boolean(metadata.contains_vod),
    },
    contains_video: Boolean(metadata.contains_video),
    video_formats:
      (metadata.metadata_video_formats &&
        metadata.metadata_video_formats.map(({ format, comment }) => ({
          slug: format,
          comment,
        }))) ||
      [],
    video_adaptive_streaming:
      (metadata.metadata_video_adaptive_streamings &&
        metadata.metadata_video_adaptive_streamings.map(
          ({ streaming, comment }) => ({
            slug: streaming,
            comment,
          })
        )) ||
      [],
    video_drms:
      (metadata.metadata_video_drms &&
        metadata.metadata_video_drms.map(({ drm, comment }) => ({
          slug: drm,
          comment,
        }))) ||
      [],
  };

  if (appType === APP_TYPES.NATIVE) {
    data.profiles = [
      ...linux_profiles.map((profile) => ({
        environment_type: APP_ENVIRONMENT.LINUX,
        name: profile.name,
        data: {
          native_id: profile.native_id,
        },
      })),

      ...android_profiles.map((profile) => ({
        environment_type: APP_ENVIRONMENT.ANDROID,
        name: profile.name,
        data: {
          action: profile.action,
          package_name: profile.package,
          class_name: profile.class,
        },
      })),
    ];
  }

  data.monetization = {
    advertisement: Boolean(metadata.produce_revenue_value?.advertisement),
    subscriptions: Boolean(metadata.produce_revenue_value?.subscriptions),
    transactions: Boolean(metadata.produce_revenue_value?.transactions),
    other: metadata?.produce_revenue_value?.other_comment ?? "",
  };

  data.markets = {
    app_designed_for: {
      country_codes: metadata.location_codes,
      selection: metadata.location_market,
    },
    app_is_available_on: {
      country_codes: metadata.available_location_codes,
      selection: metadata.available_location_market,
    },
  };

  return data;
};

/**
 * This function convert data from new app inventory service api, to old
 * IronMaiden api, which submission form accept.
 *
 * It's quick solution. If you don't like it, you should rewrite submission form
 * to work on app inventory api directly. It's doable, but it will require
 * a lot of small fixes and testing.
 * @author k.orzechowski
 */
export const adaptToForm = (aiData) => {
  const {
    application_url,
    category,
    support_email,
    translations,
    images,
    agreement_version,
    terms_accepted,
    accepted_criteria,
    technical_details,
    additional_comments,
    description_for_moderators,
    for_examination_only,
    is_certifiable,
    is_distributable,
    monetization,
    markets,
    changelog,
    profiles,
  } = aiData;

  const imData = {
    support_email,
    metadata: {
      application_url,
      agreement_version,
      terms_accepted,
      accepted_criteria,
      distribute_in_tvstore: getDistributionType({
        for_examination_only,
        is_certifiable,
        is_distributable,
      }),
      category,
      additional_comments,
      comment: description_for_moderators,
    },
    changelog,
  };

  if (technical_details) {
    const {
      video_formats = [],
      video_adaptive_streaming = [],
      video_drms = [],
    } = technical_details;

    imData.metadata = {
      ...imData.metadata,
      contains_video: technical_details.contains_video,
      contains_live_video: technical_details.video_type?.contains_live_video,
      contains_vod: technical_details.video_type?.contains_vod,
      metadata_video_formats: video_formats.map(({ slug, comment }) => ({
        comment,
        format: slug,
      })),
      metadata_video_adaptive_streamings: video_adaptive_streaming.map(
        ({ slug, comment }) => ({ comment, streaming: slug })
      ),
      metadata_video_drms: video_drms.map(({ slug, comment }) => ({
        comment,
        drm: slug,
      })),
    };
  }

  if (Array.isArray(profiles)) {
    imData.metadata.linux_profiles = profiles
      .filter((profile) => profile.environment_type === APP_ENVIRONMENT.LINUX)
      .map((profile) => ({
        name: profile.name,
        native_id: profile.data.native_id,
      }));

    imData.metadata.android_profiles = profiles
      .filter((profile) => profile.environment_type === APP_ENVIRONMENT.ANDROID)
      .map((profile) => ({
        name: profile.name,
        package: profile.data.package_name,
        action: profile.data.action,
        class: profile.data.class_name,
      }));
  }

  if (translations) {
    imData.metadata.translations = translations.map((t) => ({
      language: t.language,
      name: t.app_title,
      short_description: t.app_short_description,
      default_translation: t.is_default,
    }));
  }

  if (images) {
    ["icon_big", "icon_small", "thumbnail", "billboard"].forEach((imgName) => {
      const imageObj = images.find((i) => i.type === imgName.toUpperCase());
      if (imageObj) {
        imData.metadata[imgName] = imageObj.image;
      }
    });
    const screenshots = images.filter((i) => i.type === "SCREENSHOT");

    imData.metadata.screenshot = screenshots.map((imageObj, i) => ({
      url: imageObj.image,
      id: i,
    }));
  }

  if (!isMonetized(monetization)) {
    imData.metadata.produce_revenue = NO_REVENUE;
  } else {
    const { advertisement, subscriptions, transactions, other } = monetization;

    imData.metadata = {
      ...imData.metadata,
      produce_revenue: HAS_REVENUE,
      produce_revenue_value: {
        other_comment: other || "",
        other: Boolean(other),
        advertisement: advertisement,
        subscriptions,
        transactions,
      },
    };
  }

  if (markets) {
    const designed = markets.app_designed_for;
    const available = markets.app_is_available_on;

    if (available) {
      imData.metadata = {
        ...imData.metadata,
        available_location_codes: available.country_codes,
        available_location_market: available.selection,
      };
    }
    if (designed) {
      imData.metadata = {
        ...imData.metadata,
        location_codes: designed.country_codes,
        location_market: designed.selection,
      };
    }
  }
  return imData;
};
