// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { RadioGroupField } from "components/form/fields";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

import { VideoOptions } from "./VideoOptions";
import { VideoTypes } from "./VideoTypes";

@connect(null, (dispatch) => ({
  getVideoAdaptiveStreaming: () =>
    dispatch(
      apiThatThrows.getVideoAdaptiveStreamingPaginated.action({
        queryParams: { limit: "nolimit" },
      })
    ),
  getVideoFormats: () =>
    dispatch(
      apiThatThrows.getVideoFormatsPaginated.action({
        queryParams: { limit: "nolimit" },
      })
    ),
  getVideoDrms: () =>
    dispatch(
      apiThatThrows.getVideoDrmsPaginated.action({
        queryParams: { limit: "nolimit" },
      })
    ),
}))
export class TechnicalInfo extends Component {
  static propTypes = {
    videoFormatsError: PropTypes.string,
    videoTypesError: PropTypes.string,
    // from @Formik

    values: PropTypes.object.isRequired,
    getFieldMeta: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    // from @connect
    getVideoAdaptiveStreaming: PropTypes.func.isRequired,
    getVideoFormats: PropTypes.func.isRequired,
    getVideoDrms: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    error: null,
    videoDrms: null,
    videoFormats: null,
    adaptiveStreamings: null,
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const {
      values: {
        metadata: { contains_video },
      },
    } = this.props;

    if (!contains_video && prevProps.values.metadata.contains_video) {
      this.cleanUpCheckboxes();
    }
  }

  async updateData() {
    const { getVideoDrms, getVideoFormats, getVideoAdaptiveStreaming } =
      this.props;

    try {
      const requests = [
        getVideoDrms(),
        getVideoFormats(),
        getVideoAdaptiveStreaming(),
      ];
      const results = await Promise.all(requests);

      this.setState({
        videoDrms: results[0].results,
        videoFormats: results[1].results,
        adaptiveStreamings: results[2].results,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }

  isCheckedVideoOption = (values, key, value) =>
    values.findIndex((item) => {
      return item[key] === value;
    });

  handleOnChange = (e, key, { name, push, remove }) => {
    const { getFieldMeta } = this.props;
    const { value: fieldValues } = getFieldMeta(name);
    const index = this.isCheckedVideoOption(fieldValues, key, e.target.value);
    if (index === -1) {
      push({ comment: "", [key]: e.target.value });
    } else {
      remove(index);
    }
  };

  isVideoTypeChecked() {
    const {
      values: { metadata },
    } = this.props;
    return metadata?.contains_live_video || metadata?.contains_vod;
  }

  cleanUpCheckboxes() {
    const { setFieldValue } = this.props;
    const checkboxesFieldsResetValues = [
      ["metadata.metadata_video_formats", []],
      ["metadata.metadata_video_adaptive_streamings", []],
      ["metadata.metadata_video_drms", []],
      ["metadata.contains_live_video", false],
      ["metadata.contains_vod", false],
    ];
    checkboxesFieldsResetValues.forEach(([field, resetValue]) => {
      setFieldValue(field, resetValue);
    });
  }

  render() {
    const {
      values: { metadata },
      videoTypesError,
      videoFormatsError,
      setFieldValue,
    } = this.props;
    const { loading, error, videoFormats, adaptiveStreamings, videoDrms } =
      this.state;

    if (loading) {
      return <Loader />;
    }
    if (error) {
      return <Info type="error">{error.message}</Info>;
    }

    return (
      <div>
        <RadioGroupField
          name={"metadata.contains_video"}
          label={trans.APP_EDIT__MY_APP_HAS_VIDEO_LABEL()}
          inline={true}
          values={[
            { label: trans.YES(), value: 1, dataTestId: "radio-yes" },
            { label: trans.NO(), value: 0, dataTestId: "radio-no" },
          ]}
          onChange={(e) => setFieldValue(e.target.name, Number(e.target.value))}
          tooltip={trans.APP_EDIT__MY_APP_HAS_VIDEO_TOOLTIP()}
          dataTestId="has-video-field"
          required={false}
        />
        {metadata?.contains_video ? (
          <>
            <VideoTypes videoTypesError={videoTypesError} />
            <VideoOptions
              videoFormatsError={videoFormatsError}
              videoDrms={videoDrms}
              adaptiveStreamings={adaptiveStreamings}
              videoFormats={videoFormats}
              handleOnChange={this.handleOnChange}
              isCheckedVideoOption={this.isCheckedVideoOption}
              disabled={!this.isVideoTypeChecked()}
            />
          </>
        ) : null}
      </div>
    );
  }
}
