// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { ValidationBadge, Label } from "components/form";
import { getAriaProps, getErrorId } from "components/form/fields";
import { classes } from "utils/classes";
import { innerRefType } from "utils/types";

import styles from "./input.scss";

export class Input extends Component {
  static propTypes = {
    /** This is mostly for scenario where we need to link custom label to input element */
    id: PropTypes.string,
    label: PropTypes.node,
    required: PropTypes.bool,
    /** Current field value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onFocus: PropTypes.func,
    /**
     * Change handler called on every letter. Required when readOnly=false
     *
     * Type: (e: Event) => void
     */
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    /** HTML `type` attribute. Use more specialized components if such exist.
     * This should be used only for types that don't have multiple inputs per single "name", because of validation */
    type: PropTypes.oneOf([
      "color",
      "date",
      "datetime-local",
      "email",
      "month",
      "number",
      "password",
      "search",
      "tel",
      "text",
      "time",
      "url",
      "week",
    ]),
    /** Validation error. Shown when `touched` is true */
    error: PropTypes.string,
    touched: PropTypes.bool,
    placeholder: PropTypes.string,
    /** HTML `readOnly` attribute */
    readOnly: PropTypes.bool,
    /** HTML `disabled` attribute */
    disabled: PropTypes.bool,
    /** Optional children that will be rendered between label and validation message */
    children: PropTypes.node,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    /**
     * Variants:
     *
     * - "wrapped" - has grey horizontal line below. It is most common in forms.
     * - "simple" - input with no additional decorators
     */
    look: PropTypes.oneOf(["wrapped", "simple"]),
    /** Optional React's `ref` */
    reference: innerRefType,
    /* Additional props */
    props: PropTypes.object,
  };

  static defaultProps = {
    look: "wrapped",
    dataTestId: "input",
    required: false,
  };

  render() {
    const {
      id,
      label,
      required,
      value,
      onFocus,
      onChange,
      tooltip,
      type = "text",
      error,
      touched,
      placeholder,
      readOnly,
      disabled,
      children,
      className,
      dataTestId,
      look,
      reference,
      props,
    } = this.props;

    const extraProps = props
      ? "value" in props && type === "text"
        ? { ...props, value: props.value || "" }
        : props
      : {};

    return (
      <div
        data-test-id={dataTestId}
        className={classes(styles[look], className)}
      >
        <Label required={required} text={label} tooltip={tooltip}>
          <input
            id={id}
            data-test-id={`${dataTestId}-input`}
            className={error && touched ? styles.error : styles.normal}
            placeholder={placeholder}
            type={type}
            onFocus={onFocus}
            onChange={onChange}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            ref={reference}
            {...getAriaProps({ name: this.props.props?.name, error, touched })}
            {...extraProps}
          />
        </Label>
        <div className={styles.children}>{children}</div>
        <ValidationBadge
          className={styles.error}
          error={error}
          touched={touched}
          errorId={getErrorId(this.props.props?.name)}
        />
      </div>
    );
  }
}
