// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { Column, Row } from "components/layout";
import { Header } from "components/typography";
import { withActiveOrganization } from "containers/Auth/decorators";
import { ORGANIZATION_PLAN } from "pages/Organization/constants";
import { FIELDS, SECTIONS } from "pages/Snap/shared/constants";
import { InfoBox } from "pages/Snap/shared/InfoBox/InfoBox";

import styles from "../Monetization/Monetization.scss";
import { AdMidRoll } from "./AdMidRoll";
import { AdPreRoll } from "./AdPreRoll";
import { AdType } from "./AdType";

@withActiveOrganization
export class Ads extends PureComponent {
  static propTypes = {
    values: PropTypes.object,
    formId: PropTypes.string,
    onChange: PropTypes.func,
    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,
  };

  onTypeChange = ({ type, payload }) => {
    const { onChange, formId } = this.props;
    if (type === "toggle_type") {
      onChange(`${formId}.${FIELDS.AD_TYPES.MID.name}`, payload);
      onChange(
        `${formId}.${FIELDS.AD_MID_INTERVAL.name}`,
        FIELDS.AD_MID_INTERVAL.default_time
      );
      onChange(
        `${formId}.${FIELDS.AD_MID_OFFSET.name}`,
        FIELDS.AD_MID_OFFSET.default_time
      );
    }
    if (type === "change_breakpoint") {
      onChange(
        `${formId}.${FIELDS.AD_MID_INTERVAL.name}`,
        payload === "OUR"
          ? FIELDS.AD_MID_INTERVAL.default_time
          : FIELDS.AD_MID_INTERVAL.default_percentage
      );
      onChange(
        `${formId}.${FIELDS.AD_MID_OFFSET.name}`,
        payload === "OUR"
          ? FIELDS.AD_MID_OFFSET.default_time
          : FIELDS.AD_MID_OFFSET.default_percentage
      );
      onChange(`${formId}.${FIELDS.AD_TYPES.MID.name}`, payload);
    }
  };

  isPremiumOrganzation() {
    const { activeOrganization } = this.props;
    return activeOrganization.organizationPlan === ORGANIZATION_PLAN.Premium;
  }

  render() {
    const { values, formId } = this.props;
    const preRollType = values[formId][FIELDS.AD_TYPES.PRE.name];
    const midRollType = values[formId][FIELDS.AD_TYPES.MID.name];

    const message = (
      <Fragment>
        {SECTIONS.ADS.info}
        <a href={"mailto:" + SECTIONS.ADS.email} className={styles.link}>
          {SECTIONS.ADS.email}
        </a>
      </Fragment>
    );

    return (
      <Fragment>
        <Row className={styles.adTypeRow}>
          <Column>
            <Header bold={true} color="secondary" uppercase={true}>
              {SECTIONS.ADS.typeHeader}
            </Header>

            <AdType
              formPrefix={formId}
              midRollType={midRollType}
              preRollType={preRollType}
              onTypeChange={this.onTypeChange}
            />
          </Column>
        </Row>
        <Row className={styles.row}>
          {preRollType !== "DISABLED" && <AdPreRoll formId={formId} />}
          {midRollType !== "DISABLED" && (
            <AdMidRoll formId={formId} onTypeChange={this.onTypeChange} />
          )}
        </Row>
        {!this.isPremiumOrganzation() && (
          <InfoBox type="info" message={message} />
        )}
      </Fragment>
    );
  }
}
