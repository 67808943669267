// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import {
  CONTINENT_WORLD,
  getContinentNames,
  getCountriesForContinent,
} from "utils/continents";

export const calculateOpenContinentTab = (selectedCountries, countries) => {
  if (selectedCountries.length === countries.length) {
    return { isRadioWorld: true, continent: CONTINENT_WORLD };
  }

  const continents = getContinentNames();
  const realContinents = continents.filter((c) => c !== CONTINENT_WORLD);

  const isCountrySelected = (country) =>
    selectedCountries.indexOf(country.code) !== -1;

  const hasSelectedCountry = (continent) => {
    const continentCountries = getCountriesForContinent(countries, continent);
    return continentCountries.some(isCountrySelected);
  };

  return {
    isRadioWorld: false,
    continent: realContinents.find(hasSelectedCountry) || CONTINENT_WORLD,
  };
};
