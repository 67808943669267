// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { trans } from "src/translations";

import { AssetForm } from "./AssetForm";
import { AssetTable } from "./Table/AssetTable";

export class SoftwareAssetsRoute extends Component {
  render() {
    return (
      <Routes>
        <Route
          index
          element={
            <ExtendedRoute
              name={trans.ASSET__LIST_ROUTE_NAME()}
              component={AssetTable}
            />
          }
        />
        <Route
          exact
          path="/new"
          element={
            <ExtendedRoute
              name={trans.ASSET__CREATE_ROUTE_NAME()}
              component={AssetForm}
            />
          }
        />
        <Route
          exact
          path="/:id"
          element={
            <ExtendedRoute
              name={trans.ASSET__EDIT_ROUTE_NAME()}
              component={AssetForm}
            />
          }
        />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    );
  }
}
