// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, useState } from "react";

import { Info } from "components/feedback";
import { Section } from "components/layout";
import { trans } from "src/translations";

import { ManagerAttributesTable } from "../components";
import { DevicePropType } from "../propTypes";

export function ManagerAttributesTab({ device }) {
  const [successMessage, setSuccessMessage] = useState(null);

  return (
    <Fragment>
      {successMessage != null && successMessage.length > 0 && (
        <Info type="normal">{successMessage}</Info>
      )}

      <Section header={trans.DEVICES__DEVICE_DETAILS_TAB_MANAGER_ATTRIBUTES()}>
        <ManagerAttributesTable
          deviceId={device.id}
          managerAttributes={device.managerAttributes}
          setSuccessMessage={setSuccessMessage}
        />
      </Section>
    </Fragment>
  );
}

ManagerAttributesTab.propTypes = {
  device: DevicePropType,
};
