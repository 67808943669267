// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Field } from "formik";
import PropTypes from "prop-types";

import { createFieldValidator, isRequired } from "utils/validation";

import { FIELDS } from "../constants";
import { DeviceFamiliesSelect } from "./DeviceFamiliesSelect";

const validate = createFieldValidator(FIELDS.deviceFamily.name, isRequired);

export const DeviceFamiliesSelectField = ({ ownerId }) => (
  <Field name={FIELDS.deviceFamily.name} validate={validate}>
    {({ field, meta }) => (
      <DeviceFamiliesSelect input={field} meta={meta} ownerId={ownerId} />
    )}
  </Field>
);

DeviceFamiliesSelectField.propTypes = {
  ownerId: PropTypes.string.isRequired,
};
