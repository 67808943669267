// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { TextField } from "components/form/fields";
import { Row, Column, Section } from "components/layout";
import { ContentBox, Header } from "components/typography";

import { FIELDS, SECTIONS } from "../shared/constants";

export function SnapNameSection() {
  return (
    <Section>
      <Row>
        <Column>
          <Header uppercase={true} bold={true} color="secondary">
            {SECTIONS.NAME.header}
          </Header>
          <TextField {...FIELDS.NAME} />
        </Column>
        <Column>
          <ContentBox title={SECTIONS.NAME.title}>
            {SECTIONS.NAME.content}
          </ContentBox>
        </Column>
      </Row>
    </Section>
  );
}
