// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback } from "react";
import { Link } from "react-router-dom";

import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { TableRowColumn, TableRow } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { BUTTONS } from "pages/Snap/shared/constants";
import { SnapState } from "pages/Snap/shared/SnapState/SnapState";
import { formatDate } from "utils/date";

import { useSnapState, KEYS } from "../../shared/SnapState/useSnapState";
import styles from "../SnapList.scss";

export default function SnapApplicationTableRow({
  name,
  created_at,
  broadcaster_id,
}) {
  const { getRevision, isNew, appStates, loading } = useSnapState({
    id: broadcaster_id,
  });

  const getLastSubmittedDate = useCallback(() => {
    const submitted = getRevision(KEYS.CREATED);
    const active = getRevision(KEYS.ACTIVE);
    const lastSubmitted =
      (submitted ? new Date(submitted.updated_at) : null) >
      (active ? new Date(active.updated_at) : null)
        ? submitted
        : active;
    return lastSubmitted
      ? formatDate(parseISO(String(lastSubmitted.updated_at)))
      : "";
  }, [getRevision]);

  const hasRevisions = !loading && !isNew;

  return (
    <TableRow>
      <TableRowColumn className={styles.name}>
        {!hasRevisions ? (
          name
        ) : (
          <Link to={`/snap/${broadcaster_id}/details`} className={styles.link}>
            {name}
          </Link>
        )}
      </TableRowColumn>
      <TableRowColumn>
        {formatDate(parseISO(String(created_at)))}
      </TableRowColumn>
      <TableRowColumn>
        <SnapState
          style="light"
          withLinks={true}
          appStates={appStates}
          appId={broadcaster_id}
        />
      </TableRowColumn>
      <TableRowColumn>{getLastSubmittedDate()}</TableRowColumn>
      <TableRowColumn>
        <ActionMenu>
          <MenuItem to={`/snap/${broadcaster_id}/edit/content-and-graphics`}>
            {BUTTONS.DROPLIST.editDetails}
          </MenuItem>
          {hasRevisions && (
            <MenuItem to={`/snap/${broadcaster_id}/details`}>
              {BUTTONS.DROPLIST.details}
            </MenuItem>
          )}
        </ActionMenu>
      </TableRowColumn>
    </TableRow>
  );
}

SnapApplicationTableRow.propTypes = {
  name: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  broadcaster_id: PropTypes.string.isRequired,
};
