// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Label } from "components/form";
import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";
import { Link } from "components/typography";
import { FIELDS, SECTIONS } from "pages/Snap/shared/constants";

import styles from "./ApplicationURL.scss";

export function ApplicationURL({ revision, revisionType, mode = "TV" }) {
  if (!revision) {
    return null;
  }

  const httpLength = 5;
  let appURL = revision.app_display_url;

  let label = FIELDS.APPLICATION_LINK.title;
  if (appURL && revisionType === "DRAFT") {
    const end =
      appURL.lastIndexOf(":") > httpLength
        ? appURL.lastIndexOf(":")
        : appURL.length;
    appURL = appURL.substr(0, end) + ":latest";
    label = SECTIONS.PREVIEW.titleTV;
  }

  if (mode === "TV") {
    return (
      <>
        <Label text={label} />
        <Link href={appURL} target="_blank">
          {appURL}
        </Link>
      </>
    );
  }

  if (mode === "AUTO") {
    appURL = appURL.replace(/\/a\//, "/").replace("snapapi", "snap-touch");

    return (
      <div className={styles.tooltipOuterWrapper}>
        <Tooltip content={SECTIONS.PREVIEW.helpText} alignment="top">
          <div className={styles.tooltipInnerWrapper}>
            <Label text={SECTIONS.PREVIEW.titleAuto} />
            <GrabsonIcon name="help-filled" size="xl" />
          </div>
          <Link href={appURL} target="_blank">
            {appURL}
          </Link>
        </Tooltip>
      </div>
    );
  }
}

ApplicationURL.propTypes = {
  revision: PropTypes.object,
  revisionType: PropTypes.string,
  mode: PropTypes.string,
};
