// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const versionPropTypes = PropTypes.shape({
  id: PropTypes.string,
  version: PropTypes.string,
});

export const packagePropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  versions: PropTypes.arrayOf(versionPropTypes),
});

export const currentVersionPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  packageName: PropTypes.string.isRequired,
  versionName: PropTypes.string.isRequired,
});
