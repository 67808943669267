// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import { optionPropType } from "./propTypes";
import styles from "./SearchDropdown.scss";

export class SearchDropdown extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(optionPropType),
    onClick: PropTypes.func,
    OptionComponent: PropTypes.func.isRequired,
    focusedOptionIndex: PropTypes.number,
    handleOptionMouseHover: PropTypes.func,
    scrollInto: PropTypes.bool,
    setBlockOptionHover: PropTypes.func,
    dropdownContent: PropTypes.node,
  };

  renderSectionOption(option, optionFlatIndex) {
    const { OptionComponent, onClick } = this.props;
    const isFocused = this.isFocused(optionFlatIndex);

    const onOptionClick = () => {
      if (onClick) {
        onClick(option);
      }
    };

    // Don't replace onMouseDown with onClick!
    // Dropdown is hidden when blur event happens on containing element. onMouseDown happens before onBlur,
    // and onClick happens after. With onClick, click function will never execute.
    return (
      <li
        className={styles.Option}
        data-focused={isFocused ? "true" : null}
        key={optionFlatIndex}
        onMouseDown={onOptionClick}
        onMouseOver={this.handleOptionHover(optionFlatIndex)}
        ref={this.tryScrollIntoViewIfFocused(optionFlatIndex)}
      >
        <OptionComponent option={option} focused={isFocused} />
      </li>
    );
  }

  tryScrollIntoViewIfFocused = (optionFlatIndex) => (el) => {
    const { scrollInto } = this.props;
    if (scrollInto && this.isFocused(optionFlatIndex) && el !== null) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  handleOptionHover = (optionFlatIndex) => () => {
    const { handleOptionMouseHover } = this.props;

    if (!this.isFocused(optionFlatIndex) && handleOptionMouseHover) {
      handleOptionMouseHover(optionFlatIndex);
    }
  };

  handleMouseMove = () => {
    const { setBlockOptionHover } = this.props;
    setBlockOptionHover(false);
  };

  isFocused(optionFlatIndex) {
    return optionFlatIndex === this.props.focusedOptionIndex;
  }

  renderSection(section, alreadyRenderedItems) {
    return (
      <li className={styles.Section} key={section.title}>
        <div className={styles.Header}>{section.title}</div>
        <ul>
          {section.options.map((option, i) =>
            this.renderSectionOption(option, i + alreadyRenderedItems)
          )}
        </ul>
      </li>
    );
  }

  onMouseDown(e) {
    // It's blocking of unnecesary and unrelated blur events outside.
    // menu should blur only on outside clicks
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const { data, dropdownContent } = this.props;

    if (dropdownContent) {
      return <div className={styles.SearchDropdown}>{dropdownContent}</div>;
    }

    if (!data || data.length === 0) {
      return null;
    }

    let alreadyRenderedItems = 0;
    const options = data.map((section) => {
      const jsx = this.renderSection(section, alreadyRenderedItems);
      alreadyRenderedItems += section.options.length;
      return jsx;
    });

    return (
      <div
        className={classes(
          styles.SearchDropdown,
          styles.SearchDropdownWithOptions
        )}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.handleMouseMove}
      >
        {options}
      </div>
    );
  }
}
