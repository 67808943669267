import * as Yup from "yup";

import { trans } from "src/translations";

export const FIELDS = {
  name: {
    name: "name",
    label: trans.DEVICES__COMMANDS_CREATE_FIELD_COMMAND_LABEL(),
    required: true,
    dataTestId: "name-input",
  },
  payload: {
    name: "payload",
    label: trans.DEVICES__COMMANDS_CREATE_FIELD_PAYLOAD_LABEL(),
    formats: ["application/json"],
    height: "300px",
    language: "json",
    look: "simple",
    required: true,
    uploadActionText: trans.DEVICES__COMMANDS_CREATE_FIELD_PAYLOAD_UPLOAD(),
    dataTestId: "payload-input",
  },
  targetDevice: {
    name: "targetDevice",
    label: trans.DEVICES__COMMANDS_CREATE_FIELD_TARGET_DEVICE_LABEL(),
    modalConfig: {
      pdid: {
        title: trans.DEVICES__COMMANDS_CREATE_FIELD_TARGET_DEVICE_TITLE(),
        description:
          trans.DEVICES__COMMANDS_CREATE_FIELD_TARGET_DEVICE_DESCRIPTION(),
      },
      query: {
        title: trans.TARGETS_QUERY_AND_FILTER(),
      },
    },
    required: true,
    dataTestId: "targetDevice-input",
  },
  timeToLive: {
    name: "timeToLive",
    label: trans.DEVICES__COMMANDS_CREATE_FIELD_TIME_TO_LIVE_LABEL(),
    max: 300,
    required: true,
    tooltip: trans.DEVICES__COMMANDS_CREATE_FIELD_TIME_TO_LIVE_TOOLTIP(),
  },
};

export const INITIAL_VALUES = {
  [FIELDS.name.name]: "",
  [FIELDS.payload.name]: "",
  [FIELDS.targetDevice.name]: null,
  [FIELDS.timeToLive.name]: 0,
};

export const TARGET_DEVICES_ITEMS_PER_PAGE = 10;

export const VALIDATION_SCHEMA = Yup.object().shape({
  [FIELDS.name.name]: Yup.string().required(trans.ERROR__FIELD_REQUIRED()),
  [FIELDS.payload.name]: Yup.string()
    .required(trans.ERROR__FIELD_REQUIRED())
    .test("JSON", trans.ERROR__INVALID_JSON(), (value) => {
      try {
        JSON.parse(value);
      } catch (e) {
        return false;
      }
      return true;
    }),
  [FIELDS.targetDevice.name]: Yup.object()
    .required(trans.ERROR__FIELD_REQUIRED())
    .typeError(trans.ERROR__FIELD_REQUIRED()),
});

export const DEVICE_TYPE_QUERY = "query";
export const DEVICE_TYPE_PDID = "pdid";
