// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectById } from "containers/Layout/SideMenu/redirect/RedirectById";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";

import { SnapApplicationDetailsTabs } from "./SnapAppDetails/SnapApplicationDetailsTabs";
import { SnapCreate } from "./SnapCreate";
import { SnapEdit } from "./SnapEdit/SnapEdit";
import { SnapList } from "./SnapList";

export class SnapRoute extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<RedirectDependingOnRoles />} />
        <Route
          exact
          path="/my"
          element={
            <ExtendedRoute name="Application List" component={SnapList} />
          }
        />
        <Route
          exact
          path="/create"
          element={<ExtendedRoute name="Create" component={SnapCreate} />}
        />
        <Route
          exact
          path="/:id/details/"
          element={
            <RedirectById path="/snap/:id/details/content-and-graphics" />
          }
        />
        <Route
          path="/:id/details/:tabId"
          element={
            <ExtendedRoute
              name="Application Details"
              component={SnapApplicationDetailsTabs}
            />
          }
        />
        <Route
          exact
          path="/:id/edit/"
          element={<RedirectById path="/snap/:id/edit/content-and-graphics" />}
        />
        <Route
          path="/:id/edit/:tabId"
          element={<ExtendedRoute name="Edit" component={SnapEdit} />}
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    );
  }
}
