// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

// Since we have to show country names in device pages and filter by them,
// and we communicate with backend through country codes we need to map filter names listed here
// code<->name back and forth. If there will be another field with same issue it should suffice to add it here
// and all the conditionals should take that field into account
export const COUNTRY_TYPE_FILTERS = ["country", "register_country"];
