// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Status } from "components/feedback";
import { TableRowColumn, TableSubRow } from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";

import styles from "./deviceRow.scss";
import { DeviceRowActionMenu } from "./DeviceRowActionMenu";

export const DeviceRow = ({
  forAdmin,
  device,
  ownerId,
  toggleDeviceModelInProgress,
  onToggleDeviceModel,
  dataTestId = "device-row",
}) => {
  const linkToDetails = `/devices/${forAdmin ? "models" : "my"}/${device.id}`;
  return (
    <TableSubRow dataTestId={dataTestId}>
      <TableRowColumn className={styles.name} dataTestId={`${dataTestId}-name`}>
        <Link to={linkToDetails}>{device.name}</Link>
        {device.isTesting && (
          <Status>{trans.DEVICES__TEST_DEVICE_STATUS()}</Status>
        )}{" "}
        {device.isDisabled && (
          <Status>{trans.DEVICES__DISABLED_STATUS()}</Status>
        )}
      </TableRowColumn>
      <TableRowColumn className={styles.menu} dataTestId={`${dataTestId}-menu`}>
        <DeviceRowActionMenu
          device={device}
          ownerId={ownerId}
          forAdmin={forAdmin}
          toggleDeviceModelInProgress={toggleDeviceModelInProgress}
          onToggleDeviceModel={onToggleDeviceModel}
        />
      </TableRowColumn>
    </TableSubRow>
  );
};

DeviceRow.propTypes = {
  forAdmin: PropTypes.bool.isRequired,
  device: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isTesting: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
  }).isRequired,
  ownerId: PropTypes.string.isRequired,
  toggleDeviceModelInProgress: PropTypes.bool.isRequired,
  onToggleDeviceModel: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};
