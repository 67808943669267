// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Checkbox } from "components/form/elements";
import { Section } from "components/layout";
import { trans } from "src/translations";

import styles from "./AllDevices.scss";

export const AllDevices = () => (
  <div className={styles.container}>
    <Section header={trans.TARGETS_ALL_DEVICES()}>
      <div>{trans.TARGETS_ALL_DEVICES_DESCRIPTION()}</div>
      <div className={styles.checkboxes}>
        <Checkbox
          disabled
          checked
          labelClassName={styles.checkbox}
          label={trans.TARGETS_ALL_DEVICES_FROM_ORG_WITHOUT_NAME()}
        />
        <Checkbox
          disabled
          checked
          label={trans.TARGETS_DEVICE_MODELS()}
          labelClassName={styles.checkbox}
        />
        <Checkbox
          disabled
          checked
          label={trans.TARGETS_DEVICE_FAMILIES()}
          labelClassName={styles.checkbox}
        />
      </div>
    </Section>
  </div>
);
