// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { trans } from "src/translations";
import { classes } from "utils/classes";

import stylesButton from "../Button/button.scss";
import styles from "./fileButton.scss";

/** Simple styled input with type "file". */
export class FileButton extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    /**
     * Array of MIME types or extensions, e.g. ["image/jpeg", "image/*", ".zip"]
     */
    formats: PropTypes.arrayOf(PropTypes.string).isRequired,
    text: PropTypes.string,
    /**
     * See [Button](#button) `type` prop for possible values.
     */
    color: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(["large", "default", "small"]),
    dataTestId: PropTypes.string,
    className: PropTypes.string,
    /**
     * 'name' prop used for Formik managed fields
     */
    name: PropTypes.string,
  };

  static defaultProps = {
    text: trans.CHOOSE_FILE(),
    color: "normal",
    size: "default",
    dataTestId: "file-button",
    name: null,
  };

  id = uuid();

  handleOnClick = (ev) => {
    // Needed to trigger 'onChange' event even if the same file uploaded.
    ev.target.value = "";
  };

  render() {
    const {
      onChange,
      formats,
      text,
      color,
      disabled,
      size,
      dataTestId,
      className,
      name,
    } = this.props;

    return (
      <div data-test-id={dataTestId} className={className}>
        <label
          className={classes(stylesButton[size], stylesButton[color], {
            [stylesButton.disabled]: disabled,
          })}
          htmlFor={`file-${this.id}`}
          data-test-id={`${dataTestId}-label`}
        >
          {text}
        </label>
        <input
          name={name}
          className={styles.hiddenInput}
          id={`file-${this.id}`}
          type="file"
          accept={formats.join(", ")}
          onChange={onChange}
          disabled={disabled}
          data-test-id={`${dataTestId}-input`}
          onClick={this.handleOnClick}
        />
      </div>
    );
  }
}
