import * as React from "react";

import queryString from "query-string";

import { addQueryParamsToLocation } from "utils/url";

import { withQueryParams, WithQueryParamsProps } from "./withQueryParams";
import { withRouter, WithRouterProps } from "./withRouter";

export interface WithSearchProps {
  searchValue: string;
  changeSearch: (value: string) => void;
}

export const withSearch = <TProps extends WithSearchProps = WithSearchProps>(
  ComposedComponent: React.ComponentType<TProps>
) => {
  class WithSearch extends React.Component<
    TProps & WithQueryParamsProps & WithRouterProps
  > {
    onSearchChange = (value: string) => {
      // eslint-disable-next-line babel/no-invalid-this
      const { navigate, location } = this.props;
      const queryParams = queryString.parse(location.search);

      const newQueryParams = {
        search: value,
        ...(queryParams.page ? { page: 1 } : {}),
      };

      const newLocation = addQueryParamsToLocation(location, newQueryParams);
      navigate(newLocation);
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          searchValue={this.props.queryParams.search}
          changeSearch={this.onSearchChange}
        />
      );
    }
  }

  return withQueryParams(withRouter(WithSearch));
};
