// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect } from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { slugify } from "utils/string";

import { TextField, SwitchField } from ".";
import { FieldProps } from "./propTypes";

export const SLUG_FIELD_NAME = "slug";
export const AUTOSLUG_FIELD_NAME = "autoSlug";

const generateSlug = (name = "", organizationName = "") => {
  const cleanOrganizationName = organizationName
    ? `${organizationName.match(/\w/g).join("")}-`
    : "";
  return slugify(`${cleanOrganizationName}${name}`);
};

export const SlugField = ({
  name = SLUG_FIELD_NAME,
  autoSlugFieldName = AUTOSLUG_FIELD_NAME,
  autoSlugLabel,
  organizationName,
  nameFieldValue,
  disabled = false,
  ...props
}) => {
  const [{ value: isAutoGenerated }] = useField(autoSlugFieldName);
  const [slugField, slugMeta, slugHelper] = useField(name);

  useEffect(() => {
    if (
      !isAutoGenerated ||
      nameFieldValue.trim() === "" ||
      organizationName === ""
    ) {
      return;
    }
    let nextValue;
    if (slugMeta.initialValue) {
      nextValue = slugMeta.initialValue;
    } else {
      nextValue = generateSlug(nameFieldValue, organizationName);
    }
    if (nextValue !== slugField.value) {
      slugHelper.setTouched();
      slugHelper.setValue(nextValue);
    }
    // Note that helper and meta.initialValue should not change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slugField.value, nameFieldValue, isAutoGenerated, organizationName]);

  return (
    <>
      <TextField name={name} disabled={disabled || isAutoGenerated} {...props}>
        <SwitchField
          name={autoSlugFieldName}
          label={autoSlugLabel}
          disabled={disabled}
          dataTestId={"slug-switch"}
        />
      </TextField>
    </>
  );
};

SlugField.propTypes = {
  ...FieldProps,
  autoSlugName: PropTypes.string,
  autoSlugLabel: PropTypes.string.isRequired,
  nameFieldValue: PropTypes.string.isRequired,
  organizationName: PropTypes.string,
};
