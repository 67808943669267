import queryString from "query-string";

const ASC = "ASC";
const DESC = "DESC";
const DEFAULT_SORT_ORDER = DESC;
const DEFAULT_SORT_BY = "created_date";
const ALLOWED_SORT_BY_VALUES = ["created_date"];

export const getSortOrder = (queryParams) => {
  const { order } = queryParams;

  return order === ASC || order === DESC ? order : DEFAULT_SORT_ORDER;
};

export const getSortBy = (queryParams) => {
  const { sort_by: sortBy } = queryParams;
  if (!sortBy) {
    return DEFAULT_SORT_BY;
  }

  if (Array.isArray(sortBy)) {
    return DEFAULT_SORT_BY;
  }

  if (
    !Array.isArray(ALLOWED_SORT_BY_VALUES) ||
    ALLOWED_SORT_BY_VALUES.length === 0
  ) {
    return sortBy;
  }

  if (ALLOWED_SORT_BY_VALUES.includes(sortBy)) {
    return sortBy;
  }

  return DEFAULT_SORT_BY;
};

export const getSearchParams = (location) => queryString.parse(location.search);
