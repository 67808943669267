// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Column, Row } from "components/layout";
import { APP_ROUTE_MY } from "pages/Applications/constants";
import { SECTIONS } from "pages/Snap/shared/constants";

import styles from "./ViewSubmittedDetails.scss";

class ViewSubmittedDetails extends Component {
  static propTypes = {
    revisionsList: PropTypes.array,
    storeAppId: PropTypes.string.isRequired,
  };

  render() {
    const { storeAppId, revisionsList } = this.props;

    const hasSubmitted = revisionsList.some(
      ({ vewd_cloud_version_status }) =>
        vewd_cloud_version_status !== "NOT_SUBMITTED" &&
        vewd_cloud_version_status !== "SUBMISSION_STARTED"
    );

    return (
      hasSubmitted && (
        <div className={styles.boxWrapper}>
          <div className={styles.informationWrapper}>
            <Row className={styles.centeredRow}>
              <Column>
                <p>{SECTIONS.SUBMITTED_VERSION.content}</p>
              </Column>
              <Column className={styles.buttonWrapper}>
                <Button
                  type="wizardSubmit"
                  to={`/applications/${APP_ROUTE_MY}/${storeAppId}/details/`}
                  className={styles.viewSubmittedButton}
                >
                  {SECTIONS.SUBMITTED_VERSION.button}
                </Button>
              </Column>
            </Row>
          </div>
        </div>
      )
    );
  }
}

export default ViewSubmittedDetails;
