// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./popupRend.scss";
import { popupAlignmentType } from "./types";

export class PopupRend extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    arrowProps: PropTypes.object.isRequired,
    alignment: popupAlignmentType.isRequired,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "popup",
  };

  render() {
    const { children, arrowProps, alignment, className, dataTestId } =
      this.props;

    return (
      <div
        data-test-id={dataTestId}
        className={classes(
          styles.popup,
          {
            [styles.popupBottom]: alignment.includes("bottom"),
            [styles.popupTop]: alignment.includes("top"),
            [styles.popupLeft]: alignment.includes("left"),
            [styles.popupRight]: alignment.includes("right"),
          },
          className
        )}
      >
        <div>{children}</div>
        <div
          ref={arrowProps.ref}
          className={classes(styles.arrow, {
            [styles.arrowTop]: alignment.includes("bottom"),
            [styles.arrowBottom]: alignment.includes("top"),
            [styles.arrowRight]: alignment.includes("left"),
            [styles.arrowLeft]: alignment.includes("right"),
          })}
          style={{
            top: arrowProps.style.top + "px",
            left: arrowProps.style.left + "px",
          }}
        />
      </div>
    );
  }
}
