import { trans } from "src/translations";

export const CONTENT_TYPES = {
  VIDEO_URL: "video",
  FULL_PAGE_LANDSCAPE: "full_page_landscape",
};

export const CONTENT_RADIOS = [
  {
    label: trans.PROMOTION_DETAILS__CONTENT_VIDEO_URL(),
    defaultValue: CONTENT_TYPES.VIDEO_URL,
  },
  {
    label: trans.PROMOTION_DETAILS__CONTENT_FULL_PAGE_LANDSCAPE(),
    defaultValue: CONTENT_TYPES.FULL_PAGE_LANDSCAPE,
  },
];

export const FIELDS = {
  videoUrl: {
    name: "videoUrl",
    label: trans.PROMOTION_DETAILS__CONTENT_VIDEO_URL(),
    dataTestId: "videoUrl",
  },
  deepLinkUrl: {
    name: "deepLinkUrl",
    label: trans.PROMOTION_DETAILS__CONTENT_ITEM_DEEPLINK(),
    tooltip: trans.PROMOTION_DETAILS__CONTENT_ITEM_DEEPLINK_INFO(),
    required: false,
    dataTestId: "deepLinkUrl",
  },
  customTitle: {
    name: "customTitle",
    label: trans.PROMOTION_DETAILS__CONTENT_ITEM_CUSTOM_TITLE(),
    required: false,
    dataTestId: "customTitle",
  },
  customDescription: {
    name: "customDescription",
    label: trans.PROMOTION_DETAILS__CONTENT_ITEM_CUSTOM_DESCRIPTION(),
    required: false,
    dataTestId: "customDescription",
  },
};
