// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { Column, Row, Section, Subsection } from "components/layout";
import { Link } from "components/typography";
import { APP_TYPE } from "pages/Applications/AppDetails/constants";
import { trans } from "src/translations";

import { VersionsData } from "./VersionsData";

export const SectionCertification = ({
  id,
  type,
  certificationVersionId,
  alreadyProcessed,
  accepted,
  isCertifiable,
  isDistributable,
  isForExaminationOnly,
}) => {
  const isNativeOrHbbTv = [APP_TYPE.HBBTV, APP_TYPE.NATIVE].includes(type);
  const isHtml = type === APP_TYPE.HTML;

  const isCertOnly = isCertifiable && !isDistributable;
  const isCertOnlyPending = isCertOnly && (!alreadyProcessed || !accepted);
  const isCertOnlyApproved = isCertOnly && alreadyProcessed && accepted;

  // cert only approved or distribution
  const hasCertVersionId = certificationVersionId !== null;
  const shouldRenderVersions =
    (isCertOnlyApproved || isDistributable) && hasCertVersionId;

  return (
    <Section header={trans.APP_DETAILS_CERTIFICATION__SECTION_HEADER()}>
      {isNativeOrHbbTv && (
        <Info type={"warning"}>
          {trans.APP_DETAILS_CERTIFICATION__UNAVAILABLE_DUE_TO_APP_TYPE()}
        </Info>
      )}

      {isHtml && (
        <Fragment>
          {isForExaminationOnly && (
            <Info type={"warning"}>
              {trans.APP_DETAILS_CERTIFICATION__UNAVAILABLE_DUE_TO_CERT_TYPE()}
            </Info>
          )}

          {isCertOnlyPending && (
            <Info type={"error"}>
              {trans.APP_DETAILS_CERTIFICATION__WAITING_FOR_APPROVAL_A()}{" "}
              <Link to={`/applications/moderation/${id}/details/rights`}>
                {trans.APP_DETAILS_CERTIFICATION__WAITING_FOR_APPROVAL_B()}
              </Link>{" "}
              {trans.APP_DETAILS_CERTIFICATION__WAITING_FOR_APPROVAL_C()}
            </Info>
          )}

          {shouldRenderVersions && (
            <Row>
              <Column>
                <Subsection header={trans.VERSIONS()}>
                  <VersionsData
                    certificationVersionId={certificationVersionId}
                  />
                </Subsection>
              </Column>
              <Column />
            </Row>
          )}
        </Fragment>
      )}
    </Section>
  );
};

SectionCertification.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  certificationVersionId: PropTypes.string,
  alreadyProcessed: PropTypes.bool,
  accepted: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isCertifiable: PropTypes.bool.isRequired,
  isDistributable: PropTypes.bool.isRequired,
  isForExaminationOnly: PropTypes.bool.isRequired,
};
