// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable react/button-has-type */
import { Component } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { classes } from "utils/classes";

import styles from "./button.scss";

export class Button extends Component {
  state = {
    hover: false,
  };

  static propTypes = {
    /** If provided, works as a download button. */
    download: PropTypes.string,
    /** If provided, works as a link. */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** State to be passed to the component, works only with <Link /> component */
    state: PropTypes.object,
    /** if provided, component works the same way as the HTML link */
    href: PropTypes.string,
    /** If provided, works as a button. */
    onClick: PropTypes.func,
    children: PropTypes.node,
    /** Can be used to change button's text/children on hover */
    childrenOnHover: PropTypes.node,
    type: PropTypes.oneOf([
      "red",
      "green",
      "normal",
      "navy",
      "plain",
      "wizardNormal",
      "wizardSubmit",
      "menuButton",
      "menuButtonSmall",
    ]),
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    /** If true, spinner shown */
    processing: PropTypes.bool,
    size: PropTypes.oneOf(["large", "default", "small"]),
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    /** Corresponds to HTML type attribute on button element */
    buttonType: PropTypes.oneOf(["button", "submit", "reset"]),
  };

  static defaultProps = {
    type: "normal",
    disabled: false,
    processing: false,
    size: "default",
    className: "",
    dataTestId: "button",
    buttonType: "button",
    state: {},
  };

  getChildren() {
    const { children, childrenOnHover, disabled } = this.props;

    if (childrenOnHover) {
      return this.state.hover && !disabled ? childrenOnHover : children;
    }

    return children;
  }

  onClick = (e) => {
    const { onClick, disabled } = this.props;
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  render() {
    const {
      children,
      type,
      onFocus,
      to,
      state,
      href,
      disabled,
      processing,
      className,
      dataTestId,
      size,
      download,
      buttonType,
    } = this.props;

    const classNames = classes(styles[size], styles[type], className, {
      [styles.disabled]: disabled,
    });

    if (!disabled && href !== undefined) {
      return (
        <a href={href} className={classNames} data-test-id={dataTestId}>
          <div className={styles.children}>{children}</div>
        </a>
      );
    }

    if (!disabled && download) {
      return (
        <a
          href={to}
          className={classNames}
          download={download}
          data-test-id={dataTestId}
        >
          <div className={styles.children}>{children}</div>
        </a>
      );
    }

    if (!disabled && to) {
      return (
        <Link
          to={to}
          state={state}
          className={classNames}
          download={download}
          data-test-id={dataTestId}
        >
          <div className={styles.children}>{children}</div>
        </Link>
      );
    }

    return (
      <button
        data-test-id={dataTestId}
        className={classNames}
        onClick={this.onClick}
        disabled={disabled}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseOut={() => this.setState({ hover: false })}
        onFocus={onFocus}
        type={buttonType}
      >
        {processing ? (
          <div className={styles.loaderWrapper}>
            <Loader position="left" size="small" inline={true} />
          </div>
        ) : null}
        <div className={styles.children}>{this.getChildren()}</div>
      </button>
    );
  }
}

export default Button;
