// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const PAIRED = "PAIRED";
export const ERROR = "ERROR";
export const WAITING = "WAITING";
export const REJECTED = "REJECTED";
