// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { deepMerge, isEmpty } from "./object";
import { unflatten } from "./serialize";

export const isFieldValid = (
  props: string[],
  fields: Record<string, any>
): boolean => {
  const prop = props.shift();

  if (!prop) {
    return !fields.error;
  }

  if (prop.endsWith("[]")) {
    // eslint-disable-next-line no-magic-numbers
    return fields[prop.slice(0, -2)].every((item: any) =>
      isFieldValid(props.slice(), item)
    );
  }

  return isFieldValid(props.slice(), fields[prop]);
};

export const isFieldInErrors = (
  props: string[],
  errors: Record<string, any>
): boolean => {
  const prop = props.shift();

  if (!prop) {
    return Boolean(errors);
  }

  if (prop.endsWith("[]")) {
    // eslint-disable-next-line no-magic-numbers
    const errorKey = prop.slice(0, -2);
    if (errors[errorKey]) {
      return errors[errorKey].every((item: any) =>
        isFieldInErrors(props.slice(), item)
      );
    }
    return false;
  }
  const error = (errors && errors[prop]) || false;
  return isFieldInErrors(props.slice(), error);
};

export const getTreeWithProp = (
  data: Record<string, any>,
  propName: string
): any => {
  if (!data) {
    return null;
  }

  if (data[propName]) {
    return data[propName];
  }

  if (typeof data === "object") {
    const isArray = Array.isArray(data);
    let res: Record<string | number, any> = isArray ? [] : {};
    Object.keys(data).forEach((key, idx) => {
      const tmp = getTreeWithProp(data[key], propName);

      if (tmp) {
        if (isArray) {
          res[idx] = tmp;
        } else {
          res = deepMerge(res, { [key]: tmp });
        }
      }
    });

    return isEmpty(res) ? null : res;
  }

  return null;
};

export const getBranchValue = (
  data: Record<string, any>,
  path: string
): Record<string, any> => {
  const pathArray = path.split(".");

  return pathArray.reduce((acc, value) => {
    const regex = /(.+)\[(\d)]$/;
    const regexResult = value.match(regex);

    if (regexResult) {
      return acc[regexResult[1]][regexResult[2]];
    }

    return acc[value];
  }, data);
};

export const getBranch = (
  data: Record<string, any>,
  path: string
): Record<string, any> => {
  const branchValue = getBranchValue(data, path);
  return unflatten({ [path]: branchValue });
};
