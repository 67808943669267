// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { ValidationBadge } from "components/form";
import { Radio } from "components/form/elements";
import { CheckboxField, TextField } from "components/form/fields";
import { withActiveOrganization } from "containers/Auth/decorators";
import { trans } from "src/translations";

import styles from "./Monetization.scss";

export const NO_REVENUE = "none";
export const HAS_REVENUE = "monetized";

const CHECKBOX_FIELDS = {
  TRANSACTIONS: {
    name: "transactions",
    label: trans.APP_EDIT_MARKETS__APP_HAS_REVENUE_TRANSACTIONS(),
    dataTestId: "monetization-has-revenue-transactions-field",
  },
  SUBSCRIPTIONS: {
    name: "subscriptions",
    label: trans.APP_EDIT_MARKETS__APP_HAS_REVENUE_SUBSCRIPTIONS(),
    dataTestId: "monetization-has-revenue-subscriptions-field",
  },
  ADVERTISEMENT: {
    name: "advertisement",
    label: trans.APP_EDIT_MARKETS__APP_HAS_REVENUE_ADVERTISEMENT(),
    dataTestId: "monetization-has-revenue-advertisement-field",
  },
  OTHER: {
    name: "other",
    label: trans.OTHER(),
    dataTestId: "monetization-has-revenue-other-field",
  },
};

@withActiveOrganization
export class Monetization extends Component {
  static propTypes = {
    revenueOptionField: PropTypes.string.isRequired,
    revenueValueField: PropTypes.string.isRequired,
    revenueOtherTextField: PropTypes.string.isRequired,
    revenueValueDefault: PropTypes.object.isRequired,
    // from @ Formik
    getFieldMeta: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { value: otherFieldValue } = prevProps.getFieldMeta(
      `${prevProps.revenueValueField}.${CHECKBOX_FIELDS.OTHER.name}`
    );

    const { value: revenueOtherText } = prevProps.getFieldMeta(
      prevProps.revenueOtherTextField
    );

    if (!otherFieldValue && revenueOtherText !== "") {
      prevProps.setFieldValue(prevProps.revenueOtherTextField, "");
    }
  }

  render() {
    const {
      revenueOptionField,
      revenueValueField,
      getFieldMeta,
      setFieldValue,
      revenueOtherTextField,
      revenueValueDefault,
    } = this.props;
    const {
      value: revenueOption,
      error,
      touched,
    } = getFieldMeta(revenueOptionField);

    const { value: otherFieldValue } = getFieldMeta(
      `${revenueValueField}.${CHECKBOX_FIELDS.OTHER.name}`
    );

    return (
      <div className={styles.container}>
        <Field name={revenueOptionField}>
          {({ field, meta }) => (
            <Radio
              {...field}
              {...meta}
              defaultValue={NO_REVENUE}
              dataTestId="monetization-no-revenue-field"
              onChange={(e) => {
                setFieldValue(revenueValueField, revenueValueDefault);
                field.onChange(e);
              }}
            >
              {trans.APP_EDIT_MARKETS__APP_HAS_NO_REVENUE()}
            </Radio>
          )}
        </Field>
        <Field name={revenueOptionField}>
          {({ field, meta }) => (
            <Radio
              {...field}
              {...meta}
              defaultValue={HAS_REVENUE}
              dataTestId="monetization-has-revenue-field"
            >
              {trans.APP_EDIT_MARKETS__APP_HAS_REVENUE()}
            </Radio>
          )}
        </Field>
        {revenueOption === HAS_REVENUE && (
          <div>
            {Object.values(CHECKBOX_FIELDS).map(({ name, ...rest }) => (
              <CheckboxField
                key={name}
                name={`${revenueValueField}.${name}`}
                className={styles.checkbox}
                {...rest}
              />
            ))}
            {otherFieldValue && (
              <TextField
                name={revenueOtherTextField}
                dataTestId="monetization-has-revenue-other-description-field"
                placeholder={trans.APP_EDIT_MARKETS__APP_HAS_REVENUE_OTHER_PLACEHOLDER()}
              />
            )}
          </div>
        )}

        <ValidationBadge error={error} touched={touched} />
      </div>
    );
  }
}
