// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

import { deviceInventoryQueryFragment } from "../../_shared/DeviceInventoryTable";

export const getDevicesWithAlertQuery = gql`
  query devicesWithAlert(
    $pagination: PaginationInput!
    $search: SearchInput
    $alertState: DevicePdidAlertStatesEnum!
    $sort: DevicesSortInput
  ) {
    devicesWithAlert(
      pagination: $pagination
      search: $search
      alertState: $alertState
      sort: $sort
    ) {
      devices {
        ...DeviceInventoryFragment
      }
    }
  }
  ${deviceInventoryQueryFragment}
`;
