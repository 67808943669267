import { useField } from "formik";

import { classes } from "utils/classes";

import styles from "./CheckboxGroupField.scss";
import { FieldWithOptionsProps } from "./propTypes";

export const CheckboxGroupField = (props) => {
  const {
    name,
    values,
    disabled,
    dataTestId,
    className,
    inputClassName,
    labelClassName,
    ...rest
  } = props;

  const makeLabel = (label, value) => (
    <label
      htmlFor={`form-checkbox-${name}-${value}`}
      className={classes(styles.label, labelClassName)}
      data-test-id={`${dataTestId}-label`}
    >
      {label}
    </label>
  );

  return (
    <div role="group" aria-labelledby="checkbox-group">
      {values.map(({ value, label }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [field] = useField({
          type: "checkbox",
          name,
          value,
        });

        return (
          <div
            key={`${name}-${value}`}
            className={classes(className, { [styles.disabled]: disabled })}
            data-test-id={dataTestId}
          >
            <div className={styles.checkbox}>
              <input
                {...field}
                type="checkbox"
                disabled={disabled}
                className={classes(styles.checkboxInput, inputClassName)}
                id={`form-checkbox-${name}-${value}`}
                data-test-id={dataTestId ? `${dataTestId}-${value}` : undefined}
                {...rest}
              />
              {makeLabel(label, value)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

CheckboxGroupField.propTypes = FieldWithOptionsProps;
