// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { ErrorResponse, onError } from "@apollo/client/link/error";
import get from "lodash-es/get";

import { RouterNavigateType } from "../decorators/withRouter";
import { APOLLO_STATUS } from "./constants";

const isUnauthorizedError = (err: ErrorResponse): boolean => {
  const networkErrors = get(err, "networkError.result.errors", []);
  return networkErrors.some(
    (e: { extensions: { code: string } }) =>
      get(e, "extensions.code") === APOLLO_STATUS.UNAUTHENTICATED
  );
};

export const unauthorizedApolloLink = (navigate: RouterNavigateType) =>
  onError((err: ErrorResponse): void => {
    if (isUnauthorizedError(err)) {
      navigate("/unauthorized", { state: history.state?.usr || location.href });
    }
  });
