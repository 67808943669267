// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Loader } from "components/elements";
import { GrabsonIcon } from "components/icons";
import { Avatar } from "components/images";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
  Modal,
} from "components/layout";
import { profilePropTypes } from "containers/Auth/propTypes";
import { trans } from "src/translations";

import styles from "./editUserForm.scss";

@connect(({ auth }) => ({
  currentUser: auth.profile,
}))
export class CurrentOrganization extends Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    onRemoveOrganizationClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    editUser: PropTypes.object.isRequired,

    // @from connect
    currentUser: profilePropTypes,
  };

  state = { isModalOpen: false };

  showModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });

  handleRemoveOrganizationClick = () => {
    const { onRemoveOrganizationClick, disabled } = this.props;

    this.closeModal();

    if (!disabled) {
      onRemoveOrganizationClick();
    }
  };

  render() {
    const { currentUser, editUser, organization, disabled } = this.props;

    if (!organization) {
      return <Loader />;
    }

    return (
      <Fragment>
        <Table>
          <TableHeader>
            <TableHeaderColumn>{trans.ICON()}</TableHeaderColumn>
            <TableHeaderColumn>{trans.ORGANIZATION()}</TableHeaderColumn>
            <TableHeaderColumn />
          </TableHeader>
          <TableBody>
            <TableRow key={1}>
              <TableRowColumn>
                <Avatar size="36px" src={organization.img} />
              </TableRowColumn>
              <TableRowColumn>{organization.name}</TableRowColumn>
              {!disabled && (
                <TableRowColumn
                  onClick={this.showModal}
                  dataTestId="remove-organization-button"
                  columnClass={styles.removeColumn}
                >
                  <GrabsonIcon
                    dataTestId="remove-organization-button-icon"
                    name="close"
                    font="micro"
                  />
                </TableRowColumn>
              )}
            </TableRow>
          </TableBody>
        </Table>
        <Modal
          title={trans.WARNING()}
          isOpen={this.state.isModalOpen}
          onClose={this.closeModal}
          actions={
            <ButtonsWrapper>
              <Button
                type="green"
                onClick={this.handleRemoveOrganizationClick}
                dataTestId="remove-organization-confirm-button"
              >
                {trans.UNDERSTAND()}
              </Button>
            </ButtonsWrapper>
          }
        >
          {editUser.id === currentUser.id ? (
            <span data-test-id="remove-organization-modal-text">
              {trans.USER_EDIT__REMOVE_MEMBERSHIP_SELF({
                organizationName: organization.name,
              })}
            </span>
          ) : (
            <span data-test-id="remove-organization-modal-text">
              {trans.USER_EDIT__REMOVE_MEMBERSHIP({
                organizationName: organization.name,
              })}
            </span>
          )}
        </Modal>
      </Fragment>
    );
  }
}
