// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { CustomPlansData } from "./CustomPlansData";

export class CustomPlans extends Component {
  render() {
    return (
      <div>
        <PageHeader>{trans.DISTR_CUSTOM_PLANS__PAGE_HEADER()}</PageHeader>
        <PageContent>
          <CustomPlansData />
        </PageContent>
      </div>
    );
  }
}
