// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import {
  TableRow,
  TableRowColumn,
  TableRowColumnOverflow,
} from "components/layout";
import { Link } from "components/typography";
import { HOME_ID, FIELDS, SECTIONS } from "pages/Snap/shared/constants";
import styles from "pages/Snap/shared/Translations.scss";
import * as iconStyles from "pages/Snap/SnapEdit/components/SnapIconPicker/icons.scss";

export class SectionFeed extends PureComponent {
  static propTypes = {
    index: PropTypes.number,
    section: PropTypes.object,
  };

  static getOptions(section, isGenerated) {
    const options = [
      FIELDS.OPTION_RECENT,
      FIELDS.OPTION_POPULAR,
      FIELDS.OPTION_DATE,
    ];
    if (isGenerated) {
      options.push(FIELDS.OPTION_PICKS);
    }
    const selected = options.reduce((result, option) => {
      if (section.hasOwnProperty(option.name) && section[option.name]) {
        result.push(option.label);
      }
      return result;
    }, []);
    return selected.join(", ") || SECTIONS.TRANSLATIONS.noOptions;
  }

  static getType(isGenerated) {
    if (isGenerated) {
      return SECTIONS.TRANSLATIONS.sectionGenerated;
    }
    return SECTIONS.TRANSLATIONS.sectionFeed;
  }

  render() {
    const { index, section } = this.props;
    const isHome = index === HOME_ID && section.name === "Home";
    const isGenerated = isHome && !section.url;
    return (
      <Fragment>
        <TableRow className={styles.noBorder} highlighting={false}>
          <TableRowColumn>
            <div className={iconStyles[section.icon.toLowerCase()]} />
          </TableRowColumn>
          <TableRowColumnOverflow columnClass={styles.title} colSpan={2}>
            {section.name}
          </TableRowColumnOverflow>
          <TableRowColumnOverflow overflowText={section.url}>
            <Link href={section.url} target="_blank">
              {section.url}
            </Link>
          </TableRowColumnOverflow>
          <TableRowColumn>{SectionFeed.getType(isGenerated)}</TableRowColumn>
        </TableRow>
        <TableRow className={styles.rowDescription} highlighting={false}>
          <TableRowColumn />
          <TableRowColumn colSpan="3">
            Autogenerated categories: {SectionFeed.getOptions(section, isHome)}
          </TableRowColumn>
          <TableRowColumn />
        </TableRow>
      </Fragment>
    );
  }
}
