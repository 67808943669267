// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { ProcessingAttributesStatuses } from "../constants";

const getStatus = (statusGql) =>
  get(ProcessingAttributesStatuses, statusGql, {
    id: statusGql,
    label: statusGql,
  });

export const adaptProcessingItem = (item) => {
  return {
    id: item.id,
    name: item.filename,
    actionDate: new Date(item.uploadDate),
    status: getStatus(item.status),
  };
};
