// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useMemo } from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { createMixedValidator } from ".";
import { Input } from "../elements";
import { FieldProps } from "./propTypes";

export const NumberField = (props) => {
  const {
    name,
    required = true,
    validate: validateFn,
    min,
    max,
    step,
    ...rest
  } = props;

  const validate = useMemo(
    () => createMixedValidator(name, required, validateFn),
    [name, required, validateFn]
  );

  const [field, meta] = useField({
    ...props,
    required,
    validate,
    type: "number",
  });

  return (
    <Input
      required={required}
      props={{
        ...field,
        min,
        max,
        step,
      }}
      {...meta}
      {...rest}
      type="number"
    />
  );
};

NumberField.propTypes = {
  ...FieldProps,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
};
