// Copyright © 2023 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import Editor from "@monaco-editor/react";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Loader } from "components/elements";
import { Switch } from "components/form/elements";
import { trans } from "src/translations";

import styles from "./CodeEditor.scss";
import { THEMES, useEditorTheme } from "./hooks/useEditorTheme";

const EDITOR_LINE_HEIGHT = 18;

export const CodeEditor = ({
  value,
  enableMouseWheel = false,
  download,
  dataTestId = "code",
  ...editorThemeProps
}) => {
  const defaultTheme = useEditorTheme();
  const {
    editorTheme = defaultTheme.editorTheme,
    toggleTheme = defaultTheme.toggleTheme,
  } = editorThemeProps;
  const isLightTheme = editorTheme === THEMES.light;

  const renderButtons = () => (
    <div className={styles.buttons}>
      {download ? (
        <Button
          to={`data:text/json;base64,${btoa(value)}`}
          download={download}
          dataTestId={`${dataTestId}-download-button`}
        >
          {trans.DOWNLOAD()}
        </Button>
      ) : null}
      <div className={styles.themeSwitch}>
        <p className={styles.modeName}>
          {isLightTheme ? trans.LIGHT_MODE() : trans.DARK_MODE()}
        </p>
        <Switch
          className={styles.switchButton}
          checked={isLightTheme}
          onChange={toggleTheme}
        />
      </div>
    </div>
  );

  return (
    <div>
      {renderButtons()}
      <Editor
        height="500px"
        value={value}
        theme={editorTheme}
        options={{
          readOnly: true,
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          lineHeight: EDITOR_LINE_HEIGHT,
          tabSize: 2,
          padding: {
            top: EDITOR_LINE_HEIGHT,
            bottom: EDITOR_LINE_HEIGHT,
          },
          scrollbar: {
            handleMouseWheel: enableMouseWheel,
          },
        }}
        language="json"
        loading={<Loader />}
      />
    </div>
  );
};

CodeEditor.propTypes = {
  value: PropTypes.string.isRequired,
  enableMouseWheel: PropTypes.bool,
  download: PropTypes.string,
  dataTestId: PropTypes.string,
  editorTheme: PropTypes.oneOf([THEMES.light, THEMES.dark]),
  toggleTheme: PropTypes.func,
};
