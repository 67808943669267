import PropTypes from "prop-types";

import { Section } from "components/layout";
import { SubTab, SubTabs } from "components/navigation";

import { FormComponent } from "../../_shared";
import { getTabsConfig } from "../../_shared/constants";
import { createTabPath } from "../../_shared/utils";

export function ManifestTab({
  children,
  tabId,
  appType,
  getActiveSubTab,
  errorsBanner,
  errors,
}) {
  return (
    <Section>
      {children}
      <SubTabs>
        {getTabsConfig(appType).map(({ id, text }) => (
          <SubTab
            key={id}
            active={id === getActiveSubTab(appType)}
            text={text()}
            to={createTabPath(id)}
            look="gray2"
          />
        ))}
      </SubTabs>
      <FormComponent
        {...{
          tabId,
          tabData: getTabsConfig(appType).find(
            (tab) => tab.id === getActiveSubTab(appType)
          ),
          errorsBanner,
          errors,
        }}
      />
    </Section>
  );
}

ManifestTab.propTypes = {
  tabId: PropTypes.string.isRequired,
  appType: PropTypes.string.isRequired,
  getActiveSubTab: PropTypes.func.isRequired,
  errorsBanner: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  children: PropTypes.node,
};
