// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as types from "./actionTypes";

export const defaultState = {
  state: types.INITIAL,
  profile: {
    id: "",
    name: "",
    email: "",
    img: "",
    roles: {},
    activeOrganizationId: "",
  },
  organizations: {},
};

export const auth = (state = defaultState, action) => {
  switch (action.type) {
    case types.AUTHORIZED: {
      return {
        ...state,
        state: types.AUTHORIZED,
        profile: action.profile,
        organizations: action.organizations,
      };
    }

    case types.UNAUTHORIZED: {
      return {
        ...state,
        ...defaultState,
        state: types.UNAUTHORIZED,
      };
    }

    default: {
      return state;
    }
  }
};
