// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { GrabsonIcon } from "components/icons";
import { Modal, Section } from "components/layout";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";

import styles from "./AttributeModal.scss";

const DEFAULT_STATE = {
  error: null,
  submitting: false,
};

export class DismissAlertModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dismissAlert: PropTypes.func.isRequired,
  };

  state = { ...DEFAULT_STATE };

  handleClose = () => {
    this.setState({ ...DEFAULT_STATE });
    this.props.onClose();
  };

  handleSubmit = async () => {
    const { dismissAlert } = this.props;
    this.setState({ submitting: true });
    try {
      await dismissAlert();
    } catch (e) {
      this.setState((prevState) => {
        if (!prevState.submitting) {
          // The modal has been closed.
          return prevState;
        }
        return {
          error: getGqlErrorMessage(e),
          submitting: false,
        };
      });
      return;
    }
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;
    const { error, submitting } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onClose={this.handleClose}
        size="small"
        title={
          <Fragment>
            <GrabsonIcon name="alert-circle" size="xl" /> {trans.WARNING()}
          </Fragment>
        }
        dataTestId="dismiss-alert-modal"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={this.handleClose}
              dataTestId="dismiss-alert-cancel-button"
              disabled={submitting}
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.handleSubmit}
              dataTestId="dismiss-alert-submit-button"
              disabled={submitting}
              processing={submitting}
            >
              {trans.DEVICES__DEVICE_DETAILS_DISMISS_ALERT_MODAL_SUBMIT_BUTTON()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <Section className={styles.modalDescription}>
          {trans.DEVICES__DEVICE_DETAILS_DISMISS_ALERT_MODAL_DESCRIPTION()}
        </Section>

        {error && <Info type="error">{error}</Info>}
      </Modal>
    );
  }
}
