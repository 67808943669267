// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as H from "history";
import omit from "lodash-es/omit";
import queryString from "query-string";

import {
  ARRAY_FORMAT,
  ARRAY_FORMAT_SEPARATOR,
} from "components/elements/SearchBarWithFilters/constants";
import { INITIAL_PAGINATION_QUERY_PARAMS } from "containers/Request/api";

export const createLocationWithSearch = (
  location: H.Location,
  searchObj: Record<string, any>
): H.Location => {
  let search = queryString.stringify(searchObj);
  if (search.length > 0) {
    search = "?" + search;
  }

  return {
    ...location,
    search: search,
  };
};

/**
 * Returns new location with given query params
 * @param location - from react-router
 * @param newQueryParams - query params, e.g. { key: "value", key2: "value2" }
 */
export const addQueryParamsToLocation = (
  location: H.Location,
  newQueryParams: Record<string, any>
): H.Location => {
  const searchObj = {
    ...queryString.parse(location.search),
    ...newQueryParams,
  };
  return createLocationWithSearch(location, searchObj);
};

/**
 * Returns new location without given query params
 * @param location - from react-router
 * @param keys - query param keys
 * @returns new location object
 */
export const removeQueryParamsFromLocation = (
  location: H.Location,
  keys: Record<string, any>
): H.Location => {
  const searchObj = omit(queryString.parse(location.search), keys);
  return createLocationWithSearch(location, searchObj);
};

/**
 * Returns query param value
 * @param location - from react-router
 * @param key - query param key
 * @returns query param value
 */
export const getQueryParamValue = (
  location: H.Location,
  key: string
): string | (string | null)[] | null => {
  const searchObj = queryString.parse(location.search);
  return searchObj[key];
};

/**
 * Replaces placeholders with params in given path
 * @param path, example: /devices/:id
 * @param params, example: { id: 5 }
 * @returns example: /devices/5
 */
export const pathToUrl = (path: string, params: Record<string, any>): string =>
  path.replace(/:[\w]+(?=\b)/g, (token) => {
    const paramName = token.slice(1);

    const paramIsPort = Number.isInteger(Number(paramName));
    if (paramIsPort) {
      return token;
    }

    const replacement = params[paramName];

    if (replacement === undefined) {
      throw new Error(`Missing param ${token} for path: ${path}`);
    }

    return replacement;
  });

export interface PaginationQuery {
  page: number;
  offset: number;
  limit: number | "nolimit";
}

type QueryParams = PaginationQuery & queryString.ParsedQuery<number | string>;

export const prepareQueryParams = ({
  page,
  limit = INITIAL_PAGINATION_QUERY_PARAMS.limit,
  ...queryParams
}: QueryParams): QueryParams & queryString.ParsedQuery<number | string> => {
  if (queryParams.offset) {
    console.warn(
      "For pagination, use 'page & limit' query params instead of 'offset & limit'"
    );
  }

  const paginationQueryParams: PaginationQuery = {} as PaginationQuery;

  if (limit === "nolimit") {
    paginationQueryParams.limit = limit;
  } else if (typeof limit === "number" && page !== undefined) {
    paginationQueryParams.offset = limit * (page - 1);
    paginationQueryParams.limit = limit;
  }

  return {
    ...queryParams,
    ...paginationQueryParams,
  };
};

export const addQueryParamsToUrl = (
  url: string,
  queryParams: QueryParams
): string => {
  if (queryParams) {
    const preparedQueryParams = prepareQueryParams(queryParams);

    const [urlPath, urlQueryString] = url.split("?");
    const urlQueryParams = queryString.parse(urlQueryString);

    const newQueryString = queryString.stringify({
      ...preparedQueryParams,
      ...urlQueryParams,
    });

    if (newQueryString) {
      return `${urlPath}?${newQueryString}`;
    }

    return urlPath;
  }

  return url;
};

/**
 * Creates location object from given path.
 * Convenient to use with react-router v4.
 *
 * Example:
 * path: "/example?param=value#hash-example"
 * returns: { pathname: "/example", search: "?param=value", hash: "#hash-example" }
 *
 * @param path
 * @returns {pathname: String, search: String, hash: String}
 */
export const pathToLocation = (
  path?: any
): { pathname: string; search: string; hash: string } | null => {
  // Appratnely tests cover cases where path is not a string and this should be null
  if (path === undefined || typeof path !== "string") {
    return null;
  }

  const [, pathname, search, hash] =
    /([^?#]*)?(\?[^#]*)?(#.*)?/gm.exec(path) ?? [];

  return {
    pathname,
    search,
    hash,
  };
};

export const adaptSortingQueryParams = (
  { sort_by = "name", order, ...rest }: QueryParams,
  orderParamName = "order" // TODO YGG-3694: remove this arg. when applications/my_list endpoint uses "order" instead of "ordering"
): QueryParams => {
  const backendSorting: Record<string, any> = {};

  if (sort_by) {
    backendSorting[orderParamName] = sort_by;

    if (order === "DESC") {
      backendSorting[orderParamName] = "-" + backendSorting[orderParamName];
    }
  }

  return { ...rest, ...backendSorting };
};

/**
 * Function that provides backward compatibility for SearchBarWithFilters
 * from before negative filters (contains/not_contains nesting) by flattening and parsing 'contains' string
 *
 * example param: {not_contains: "", contains: "moderator=organization owner", search: "00-test", order: "name"}
 * example return: { moderator: "organization owner", search: "00-test", order: "name"}
 *
 * @param: {not_contains: String, contains: String, search: String, order: String}
 * @returns: {[filterName]: Any, search: String, order: String}
 */
export const retrieveFiltersFromInclude = (
  queryParams: QueryParams
): QueryParams => {
  const result = {
    ...queryParams,
    ...queryString.parse(queryParams.contains as string, {
      arrayFormat: ARRAY_FORMAT,
      arrayFormatSeparator: ARRAY_FORMAT_SEPARATOR,
    }),
  };
  delete result.contains;
  delete result.not_contains;
  return result;
};
