// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const FIELDS = {
  NAME: {
    name: "name",
    label: trans.ORGANIZATION__NAME_LABEL(),
    dataTestId: "name-field",
  },
  DESCRIPTION: {
    name: "description",
    label: trans.ORGANIZATION__DESCRIPTION_LABEL(),
    dataTestId: "description-field",
  },
  LOGO: {
    name: "logo",
    label: trans.ORGANIZATION__LOGO_LABEL(),
    tooltip: trans.ORGANIZATION__LOGO_TOOLTIP(),
  },
  STREET: {
    name: "street",
    label: trans.ORGANIZATION__STREET_LABEL(),
    dataTestId: "street-field",
  },
  CITY: {
    name: "city",
    label: trans.ORGANIZATION__CITY_LABEL(),
    dataTestId: "city-field",
  },
  ZIP_CODE: {
    name: "zipCode",
    label: trans.ORGANIZATION__ZIP_CODE_LABEL(),
    dataTestId: "zip-code-field",
  },
  COUNTRY: {
    name: "country",
    label: trans.ORGANIZATION__COUNTRY_LABEL(),
    dataTestId: "country-field",
  },
  SUPPORT_EMAIL: {
    name: "supportEmail",
    label: trans.ORGANIZATION__SUPPORT_EMAIL_LABEL(),
    dataTestId: "support-email-field",
  },
  WEBSITE: {
    name: "website",
    label: trans.ORGANIZATION__WEBSITE_LABEL(),
    dataTestId: "website-field",
  },
  PHONE: {
    name: "phone",
    label: trans.ORGANIZATION__PHONE_LABEL(),
    dataTestId: "phone-field",
  },
};

export const LOGO = {
  MIN_WIDTH: 128,
  MIN_HEIGHT: 128,
  MAX_WIDTH: 256,
  MAX_HEIGHT: 256,
};

export const jsonPointerToFieldName = {
  "/data/attributes/name": FIELDS.NAME.name,
  "/data/attributes/description": FIELDS.DESCRIPTION.name,
  "/data/attributes/support_email": FIELDS.SUPPORT_EMAIL.name,
  "/data/attributes/website": FIELDS.NAME.name,
  "/data/attributes/phone": FIELDS.PHONE.name,
  "/data/attributes/logo": FIELDS.LOGO.name,
  "/data/attributes/street": FIELDS.STREET.name,
  "/data/attributes/city": FIELDS.CITY.name,
  "/data/attributes/zip_code": FIELDS.ZIP_CODE.name,
  "/data/attributes/country": FIELDS.COUNTRY.name,
};
