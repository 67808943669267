// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";
import { connect } from "react-redux";

import { Field } from "formik";
import PropTypes from "prop-types";

import { SearchableList, SearchableListPanel } from "components/elements";
import { ValidationBadge } from "components/form";
import { Radio } from "components/form/elements";
import { InfiniteData } from "containers/InfiniteData";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";
import { createFieldValidator, isRequired } from "utils/validation";

import styles from "./OrganizationField.scss";

const ITEMS_PER_PAGE = 50;

@connect(null, (dispatch) => ({
  getOrganizationsPaginated: (queryParams) =>
    dispatch(apiThatThrows.getOrganizationsPaginated.action({ queryParams })),
}))
export class OrganizationField extends PureComponent {
  static propTypes = {
    getOrganizationsPaginated: PropTypes.func,
    name: PropTypes.string,
  };

  validate = createFieldValidator(this.props.name, isRequired);

  renderField = ({ field, form: { getFieldMeta, setTouched } }) => {
    const { getOrganizationsPaginated } = this.props;
    const meta = getFieldMeta(field.name);
    const renderRow = ({ id, name }) => (
      <SearchableListPanel key={id}>
        <Radio
          {...meta}
          {...field}
          onChange={(e) => {
            setTouched({ [field.name]: true });
            field.onChange(e);
          }}
          dataTestId={`organization-${id}`}
          defaultValue={id}
        >
          <span className={styles.item}>{name}</span>
        </Radio>
      </SearchableListPanel>
    );

    return (
      <Fragment>
        <InfiniteData
          fetchData={getOrganizationsPaginated}
          itemsPerPage={ITEMS_PER_PAGE}
        >
          {({
            data,
            pending,
            error,
            allDataLoaded,
            handleScroll,
            handleSearchChange,
          }) => (
            <SearchableList
              dataTestId="select-target-organization-list"
              list={data}
              onScroll={handleScroll}
              onSearchChange={handleSearchChange}
              withLoader={true}
              loading={pending}
              allDataLoaded={allDataLoaded}
              error={error && trans.DEFAULT_REQUEST_ERROR_MESSAGE()}
              placeholder={trans.SEARCH()}
            >
              {renderRow}
            </SearchableList>
          )}
        </InfiniteData>

        {Boolean(meta.error) && (
          <ValidationBadge error={meta.error} touched={meta.touched} />
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.renderField}
        validate={this.validate}
      />
    );
  }
}
