// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const sortAsc = <T>(values: T[], sortBy?: keyof T): T[] => {
  if (!sortBy) {
    return values.sort();
  }

  return values.sort((x, y) => {
    if (x[sortBy] > y[sortBy]) {
      return 1;
    }

    if (x[sortBy] < y[sortBy]) {
      return -1;
    }

    return 0;
  });
};

export const ensureArray = <T>(v?: null | T): T[] => {
  if (v === null || v === undefined) {
    return [];
  }

  if (Array.isArray(v)) {
    return v;
  }

  return [v];
};
