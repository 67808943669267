// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import queryString from "query-string";

import {
  SEARCH_PARAM_NAME,
  PAGE_PARAM_NAME,
  ARRAY_FORMAT,
  ARRAY_FORMAT_SEPARATOR,
} from "../constants";

/**
 * This method converts filters (like the ones created in createFilter)
 * back to queryString.parse format
 */
const filtersToQueryParams = (filters) => {
  return filters.reduce((total, { value, id: key }) => {
    if (typeof total[key] === "undefined") {
      total[key] = value;
    } else if (typeof total[key] === "object") {
      total[key].push(value);
    } else {
      total[key] = [total[key], value];
    }
    return total;
  }, {});
};

/**
 * On new search we have to preserve certain non-search related
 * parameters (e.g. tab). We also have to reset the pagination, cause after
 * we add filter the previous page number may not be valid
 */
export const createSearchParams = (
  previousSearchParams,
  { value, filters, exclusionFilters }
) => {
  const contains = {
    contains: queryString.stringify(filtersToQueryParams(filters), {
      arrayFormat: ARRAY_FORMAT,
      arrayFormatSeparator: ARRAY_FORMAT_SEPARATOR,
      encode: true,
    }),
  };
  const not_contains = {
    not_contains: queryString.stringify(
      filtersToQueryParams(exclusionFilters),
      {
        arrayFormat: ARRAY_FORMAT,
        arrayFormatSeparator: ARRAY_FORMAT_SEPARATOR,
        encode: true,
      }
    ),
  };

  const searchParams = {
    ...previousSearchParams,
    ...contains,
    ...not_contains,
  };
  delete searchParams["search"];
  if (value) {
    searchParams[SEARCH_PARAM_NAME] = value;
  }

  delete searchParams[PAGE_PARAM_NAME];
  return searchParams;
};
