// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Avatar } from "components/images";
import {
  TableRowColumn,
  TableRow,
  TableRowColumnExtended,
} from "components/layout";

import styles from "./deviceModelApplicationsRow.scss";

export class DeviceModelApplicationsRow extends Component {
  static propTypes = {
    app: PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { app } = this.props;

    return (
      <TableRow>
        <TableRowColumn columnClass={styles.avatarColumnSize}>
          <Avatar size="24px" src={app.icon} />
        </TableRowColumn>
        <TableRowColumnExtended
          text={app.name}
          to={`/applications/distribution/${app.id}/details`}
        />
      </TableRow>
    );
  }
}
