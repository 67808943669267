// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { parseISO } from "date-fns";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { formatDateTime } from "utils/date";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";

import { getSignedPackageQuery } from "./gql/getSignedPackageQuery";
import { getSoftwarePackageVersionQuery } from "./gql/getSoftwarePackageVersionQuery";
import { SoftwarePackageDetailsVersionRend } from "./SoftwarePackageDetailsVersionRend";

const formatJson = (text) => {
  try {
    const parsed = JSON.parse(text);
    const formatted = JSON.stringify(parsed, null, "  ");
    return [formatted, true];
  } catch (e) {
    return ["", false];
  }
};

@withActiveOrganization
@withQuery({
  name: "softwarePackageVersion",
  query: getSoftwarePackageVersionQuery,
})
@withQuery({ name: "signedPackage", query: getSignedPackageQuery })
export class SoftwarePackageDetailsVersionData extends PureComponent {
  static propTypes = {
    version: PropTypes.string.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,

    // from @withQuery
    softwarePackageVersionQuery: PropTypes.func,
    softwarePackageVersionQueryStatus: queryResultType,

    // from @withQuery
    signedPackageQuery: PropTypes.func,
    signedPackageQueryStatus: queryResultType,
  };

  componentDidMount() {
    this.fetchSoftwarePackageVersion();
  }

  componentDidUpdate(prevProps) {
    const organizationId = this.props.activeOrganization.id;
    const { version } = this.props;

    if (
      prevProps.activeOrganization.id !== organizationId ||
      prevProps.version !== version
    ) {
      this.fetchSoftwarePackageVersion();
    }
  }

  fetchSoftwarePackageVersion = () => {
    const { version, softwarePackageVersionQuery } = this.props;

    softwarePackageVersionQuery({ variables: { version } });
  };

  fetchSignedPackage = async () => {
    const { signedPackageQuery, version } = this.props;

    await signedPackageQuery({
      variables: { softwarePackageVersionId: version },
    });
  };

  getSignedPackageData = () => {
    const { data, loading, error } = this.props.signedPackageQueryStatus;

    const content = get(data, "signedPackage.signedPackage", {});

    return {
      loading,
      error: error && getGqlErrorMessage(error),
      data: content,
    };
  };

  getVersionData = () => {
    const { data, loading, error } =
      this.props.softwarePackageVersionQueryStatus;

    const content = get(
      data,
      "softwarePackageVersion.softwarePackageVersion",
      {}
    );

    const [formatted, isValid] = formatJson(content.rawManifest);

    return {
      error: error && getGqlErrorMessage(error),
      loading: loading || !data,
      data: {
        id: content.id,
        packageName: get(content, "softwarePackage.name"),
        version: content.version,
        uploadDate: formatDateTime(parseISO(String(content.uploadDate))),
        fileName: content.fileName,
        fileSize: content.fileSize,
        manifestFormatted: formatted,
        manifestIsValid: isValid,
      },
    };
  };

  downloadSignedPackage = async () => {
    await this.fetchSignedPackage();
    const {
      data: { downloadUrl, packageMetadata, metadataSignature },
    } = this.getSignedPackageData();

    const zip = new JSZip();
    const metadata = new Blob([packageMetadata], {
      type: "text/plain;charset=utf-8",
    });
    const signature = new Blob([metadataSignature], {
      type: "text/plain;charset=utf-8",
    });
    const packageResponse = await fetch(downloadUrl);
    const packageBlob = await packageResponse.blob();

    const zipFile = await zip
      .file("metadata.json", metadata)
      .file("metadata_signature.json", signature)
      .file("update_package.pkg", packageBlob)
      .generateAsync({ type: "blob" });

    saveAs(zipFile, "update_package.pkg.zip");
  };

  render() {
    const { loading, error, data } = this.getVersionData();
    return (
      <SoftwarePackageDetailsVersionRend
        error={error}
        loading={loading}
        softwarePackageVersion={data}
        downloadSignedPackage={this.downloadSignedPackage}
      />
    );
  }
}
