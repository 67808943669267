// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

export class Logo extends Component {
  static propTypes = {
    src: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  render() {
    const { src, width = "100px", height, ...rest } = this.props;
    const placeholder =
      "http://www-static.operacdn.com/static-heap/12/120c2f072b45385b85594eae63d6fd2ea0094c4d/opera-software-logo.png";

    return (
      <div {...rest}>
        <img
          src={src || placeholder}
          width={width}
          height={height}
          alt="Logo"
          style={{ objectFit: "contain" }}
        />
      </div>
    );
  }
}
