// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { TableSubRow, TableRowColumn } from "components/layout";

import { AssetActionMenu } from "./AssetActionMenu";
import styles from "./AssetVersionRow.scss";

export class AssetVersionRow extends Component {
  static propTypes = {
    asset: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      versions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          number: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
    version: PropTypes.shape({
      number: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  };

  render() {
    const { version, asset } = this.props;

    return (
      <TableSubRow>
        <TableRowColumn className={styles.name}>
          {version.number}
        </TableRowColumn>
        <TableRowColumn className={styles.description}>
          {version.url}
        </TableRowColumn>
        <TableRowColumn className={styles.menu}>
          <AssetActionMenu
            asset={asset}
            versionNumber={version.number}
            buttonSize="small"
          />
        </TableRowColumn>
      </TableSubRow>
    );
  }
}
