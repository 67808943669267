import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Modal } from "components/layout";
import { trans } from "src/translations";
import { useToggle } from "utils/hooks";

export const ManifestPreviewModal = ({ title, children }) => {
  const [isVisible, toggleVisibility] = useToggle();

  return (
    <>
      <Button onClick={toggleVisibility}>
        {trans.APP_DETAILS_MANIFEST__CONFIG_PREVIEW_BUTTON()}
      </Button>
      <Modal
        title={title}
        isOpen={isVisible}
        onClose={toggleVisibility}
        size="huge"
      >
        {children}
      </Modal>
    </>
  );
};

ManifestPreviewModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
