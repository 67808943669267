// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withTargetsReceive } from "pages/_shared/TargetsField/decorators/withTargetsReceive";
import { withRouter } from "utils/decorators";

import { AdminPlanFormRend } from "./AdminPlanFormRend";

const initialValues = {
  name: "",
  description: "",
  distributor: null,
  targets: [],
  applications: [],
};

@restrictedArea(() => ({
  allowed: [ROLES.administrator.distributionAdmin],
}))
@withRouter
@withTargetsReceive({ distributorFieldNameInRequest: "device_owners" })
@connect(null, (dispatch) => ({
  getPlan: async (planId) => {
    const { results } = await dispatch(
      apiThatThrows.getDistributionPlan.action({ params: { planId } })
    );

    return results;
  },
  getTargetOfPlan: async (planId, targetId) => {
    const { results } = await dispatch(
      apiThatThrows.getTargetOfDistributionPlan.action({
        params: {
          planId,
          targetId,
        },
      })
    );

    return results;
  },
}))
export class AdminPlanFormData extends Component {
  static propTypes = {
    isEdit: PropTypes.bool.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,

    // from @connect
    getPlan: PropTypes.func.isRequired,
    getTargetOfPlan: PropTypes.func.isRequired,

    // from @withTargetsReceive
    getTargetsData: PropTypes.func,
  };

  state = {
    loadingCount: 0,
    error: null,
    initialValues: initialValues,
  };

  componentDidMount() {
    this.updateInitialValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.updateInitialValues();
    }
  }

  getPlanId = () => {
    const { params } = this.props;

    return params.id;
  };

  updateInitialValues = async () => {
    const { getTargetOfPlan, getTargetsData, getPlan, isEdit } = this.props;

    if (!isEdit) {
      return;
    }

    try {
      this.setState((prevState) => ({
        loadingCount: prevState.loadingCount + 1,
        error: null,
      }));

      const plan = await getPlan(this.getPlanId());

      const targetsOfPlan = await Promise.all(
        plan.targets.map(async (target) => getTargetOfPlan(plan.id, target.id))
      );
      const targets = await getTargetsData(targetsOfPlan);

      this.setState((prevState) => ({
        initialValues: {
          ...prevState.initialValues,
          name: plan.label,
          description: plan.description,
          distributor: plan.owner,
          targets: targets,
          applications: plan.applications.map((app) => app.id),
        },
      }));
    } catch (err) {
      this.setState({
        error: err.message,
      });
    } finally {
      this.setState((prevState) => ({
        loadingCount: prevState.loadingCount - 1,
      }));
    }
  };

  render() {
    const { isEdit } = this.props;
    const { loadingCount, error, initialValues } = this.state;

    if (Boolean(loadingCount)) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error}</Info>;
    }

    return (
      <AdminPlanFormRend
        initialValues={initialValues}
        isEdit={isEdit}
        planId={this.getPlanId()}
      />
    );
  }
}
