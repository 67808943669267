// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { withActiveOrganization } from "containers/Auth/decorators";
import { apiThatThrows } from "containers/Request";
import { withCountries } from "utils/continents";
import { withRouter } from "utils/decorators";

import { CreateEditOrganizationForm } from "./CreateEditOrganizationForm";

const DEFAULT_INITIAL_VALUES = {
  name: "",
  description: "",
  supportEmail: "",
  website: "",
  phone: "",
  logo: null,
  street: "",
  city: "",
  zipCode: "",
  country: "",
  type: ["Provider"],
};

// Can't remove type 'Organization', as it is connected to managing users
const isOrganizationTypeEditable = (typeId) => {
  const blacklist = ["Organization"];
  return !blacklist.includes(typeId);
};

/**
 * The form works in two modes: edit and create
 */
@withRouter
@withActiveOrganization
@withCountries
@connect(null, (dispatch, ownProps) => ({
  getOrganization: async (id) => {
    let response;

    if (ownProps.forAdmin) {
      response = await dispatch(
        apiThatThrows.getOrganization.action({
          params: { organizationId: id },
        })
      );
    } else {
      response = await dispatch(
        apiThatThrows.getMyOrganization.action({
          params: { organizationId: id },
        })
      );
    }

    const { results } = response;
    return results;
  },
  getTypesForOrganization: async (id) => {
    let response;

    if (ownProps.forAdmin) {
      response = await dispatch(
        apiThatThrows.getTypesForOrganizationPaginated.action({
          params: { organizationId: id },
          queryParams: { limit: "nolimit" },
        })
      );
    } else {
      response = await dispatch(
        apiThatThrows.getTypesForMyOrganizationPaginated.action({
          queryParams: { limit: "nolimit" },
        })
      );
    }

    const { results } = response;
    return results;
  },
  getAllOrganizationTypes: async () => {
    const { results } = await dispatch(
      apiThatThrows.getAllOrganizationTypesPaginated.action({
        queryParams: { limit: "nolimit" },
      })
    );

    return results;
  },
}))
export class CreateEditOrganizationFormData extends Component {
  static propTypes = {
    /* If true, endpoints for admins used instead of '/my' versions */
    forAdmin: PropTypes.bool,
    isEdit: PropTypes.bool,

    // from @connect
    getOrganization: PropTypes.func.isRequired,
    getTypesForOrganization: PropTypes.func.isRequired,
    getAllOrganizationTypes: PropTypes.func.isRequired,

    // from @withRouter
    params: PropTypes.object.isRequired,

    // from @withCountries
    countriesLoading: PropTypes.bool.isRequired,
    countriesError: PropTypes.object,
    allCountries: PropTypes.array.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.object,
  };

  static defaultProps = {
    forAdmin: false,
  };

  state = {
    isLoading: false,
    initialValues: DEFAULT_INITIAL_VALUES,
    allOrganizationTypes: [],
    error: "",
  };

  async componentDidMount() {
    await this.updateData();
  }

  async componentDidUpdate(prevProps) {
    const activeOrganizationId = get(this.props, "activeOrganization.id");
    const prevActiveOrganizationId = get(prevProps, "activeOrganization.id");

    if (activeOrganizationId !== prevActiveOrganizationId) {
      await this.updateData();
    }
  }

  updateData = async () => {
    try {
      this.setState({ isLoading: true, error: "" });

      const initialValues = await this.getInitialValues();
      const allOrganizationTypes = await this.getAllOrganizationTypes();

      this.setState({
        initialValues,
        allOrganizationTypes,
      });
    } catch (err) {
      this.setState({ error: err.message });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  createInitialEditValues(organization, organizationTypes) {
    return {
      name: organization.name,
      description: organization.description,
      supportEmail: organization.support_email,
      website: organization.website,
      phone: organization.phone,
      logo: organization.logo || this.state.initialValues.logo,
      street: organization.street,
      city: organization.city,
      zipCode: organization.zip_code,
      country: organization.country,
      type: organizationTypes
        .map((type) => type.id)
        .filter(isOrganizationTypeEditable),
    };
  }

  getInitialValues = async () => {
    const { getOrganization, getTypesForOrganization, isEdit } = this.props;

    if (!isEdit) {
      return { ...DEFAULT_INITIAL_VALUES };
    }

    const organizationId = this.getOrganizationId();
    const organization = await getOrganization(organizationId);
    const typesForOrganization = await getTypesForOrganization(organizationId);

    return this.createInitialEditValues(organization, typesForOrganization);
  };

  getAllOrganizationTypes = async () => {
    const { getAllOrganizationTypes } = this.props;
    const allOrganizationTypes = await getAllOrganizationTypes();

    const isEditable = (type) => isOrganizationTypeEditable(type.id);
    return allOrganizationTypes.filter(isEditable);
  };

  getOrganizationId() {
    const { activeOrganization, params, isEdit } = this.props;

    if (!isEdit) {
      return null;
    }
    return params.id || get(activeOrganization, "id", null);
  }

  render() {
    const { forAdmin, countriesLoading, countriesError, allCountries, isEdit } =
      this.props;
    const countriesSorted = allCountries
      .slice()
      .sort(({ name: a }, { name: b }) => a.localeCompare(b));
    const error = this.state.error || countriesError;
    if (error) {
      return <Info type="error">{error.message || error}</Info>;
    }

    return (
      <CreateEditOrganizationForm
        forAdmin={forAdmin}
        isEdit={isEdit}
        organizationId={this.getOrganizationId()}
        initialValues={this.state.initialValues}
        isLoading={this.state.isLoading || countriesLoading}
        allOrganizationTypes={this.state.allOrganizationTypes}
        countries={countriesSorted}
        refreshData={this.updateData}
      />
    );
  }
}
