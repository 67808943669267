// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Tooltip } from "components/popups";
import { NO_VALUE_PLACEHOLDER } from "src/constants";

import styles from "./Text.scss";

export const Text = ({ text, tooltip, placeholder }) => {
  if (!text) {
    return <div>{placeholder}</div>;
  }

  return (
    <div>
      {text}
      {tooltip && (
        <span className={styles.tooltip}>
          <Tooltip content={tooltip}>(?)</Tooltip>
        </span>
      )}
    </div>
  );
};

Text.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
};

Text.defaultProps = {
  placeholder: NO_VALUE_PLACEHOLDER,
};
