// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { Handlers } from "./Handlers";

const INITIAL_STATE = {
  itemsCount: 0,
  pagesCount: 1,
  loadedPagesCount: 0,
  searchRequested: "",
  pageRequested: 1,
  dataCache: [],
  pending: false,
  error: "",
};

/**
 * Component that automatically sends request for more data when user reached
 * the bottom of the scrollable container.
 *
 * See:
 * - [SearchableList](#SearchableList)
 */
export class InfiniteData extends Component {
  static propTypes = {
    /** How many items are per each page */
    itemsPerPage: PropTypes.number,
    /**
     * Function returning loaded data
     *
     * Type:
     * <pre>
     *   interface Args { page: number; search: string }
     *   interface Result {
     *      error: String,
     *      result: {
     *        results: T[],
     *        meta: {
     *          count: number; // total number of items
     *        }
     *      }
     *   }
     *   (args: Args) => Result // fetchData type
     * </pre>
     */
    fetchData: PropTypes.func.isRequired,
    /**
     * Function mapping data fetched from server to the structure expected by children component
     *
     * Type: T[] => T[]
     */
    mapData: PropTypes.func,
    /** Function that will render children */
    children: PropTypes.func.isRequired,
    /** Initial state of search */
    initialSearch: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** If the component should fetch all the data from the start */
    fetchAllData: PropTypes.bool,
    /** Notify when new data got loaded */
    newDataLoaded: PropTypes.func,
  };

  static defaultProps = {
    itemsPerPage: 10,
    mapData: (data) => data,
    fetchAllData: false,
  };

  state = {
    ...INITIAL_STATE,
    searchRequested: this.props.initialSearch || INITIAL_STATE.searchRequested,
    fetchedAllPages: false,
  };

  lastRequestId = null;

  componentDidMount() {
    this.fetchPage(1);
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialSearch, fetchAllData } = this.props;
    const { searchRequested, pageRequested, pagesCount } = this.state;

    const initialSearchChanged = initialSearch !== prevProps.initialSearch;
    if (initialSearchChanged) {
      this.requestNewSearch(initialSearch);
    }

    const searchChanged = searchRequested !== prevState.searchRequested;

    if (fetchAllData) {
      if (searchChanged) {
        this.setState(
          { fetchedAllPages: false, loadedPagesCount: 0 },
          async () => {
            // First, update page count.
            await this.fetchPage(pageRequested);
            // Second, fetch all pages.
            await this.fetchAllPages();
          }
        );
      } else if (!prevProps.fetchAllData) {
        this.fetchAllPages();
      }
    } else {
      const pageRequestedChanged = pageRequested !== prevState.pageRequested;
      const pageRequestedExists = pageRequested <= pagesCount;
      const requestNewPage = pageRequestedChanged && pageRequestedExists;
      if (searchChanged || requestNewPage) {
        this.fetchPage(pageRequested);
      }
    }
  }

  componentWillUnmount() {
    this.lastRequestId = null;
  }

  fetchPage = async (page) => {
    const { fetchData, mapData, itemsPerPage, newDataLoaded } = this.props;
    const { searchRequested } = this.state;

    this.setState({ pending: true });

    try {
      const requestId = uuid();
      this.lastRequestId = requestId;

      const queryParams = { page, search: searchRequested };
      const { meta, results } = await fetchData(queryParams);

      // ignore outdated responses, as we do not use cancelable promises
      const shouldIgnoreResponse = this.lastRequestId !== requestId;
      if (shouldIgnoreResponse) {
        return;
      }

      this.setState(
        (prevState) => ({
          loadedPagesCount: page,
          itemsCount: meta.count,
          pagesCount: Math.ceil(meta.count / itemsPerPage),
          dataCache: [...prevState.dataCache, ...mapData(results)],
          fetchedAllPages:
            meta.count === 0 || prevState.pagesCount + 1 === page,
        }),
        () => {
          newDataLoaded?.(this.state.dataCache, this.state.fetchedAllPages);
        }
      );
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ pending: false });
    }
  };

  requestNewSearch = (value) => {
    if (value !== this.state.searchRequested) {
      this.setState({
        ...INITIAL_STATE,
        dataCache: [],
        searchRequested: value,
      });
    }
  };

  requestNextPage = () => {
    this.setState((prevState) => ({
      pageRequested: prevState.loadedPagesCount + 1,
    }));
  };

  fetchAllPages = async () => {
    const { newDataLoaded, fetchData, mapData } = this.props;
    const { dataCache, loadedPagesCount, searchRequested, pagesCount } =
      this.state;

    this.setState({ pending: true });

    newDataLoaded?.(dataCache, false);

    try {
      const responses = await Promise.all(
        Array.from({
          length: pagesCount - loadedPagesCount,
        }).map(async (_, i) => {
          const queryParams = {
            page: loadedPagesCount + i + 1,
            search: searchRequested,
          };
          return await fetchData(queryParams);
        })
      );

      this.setState(
        (prevState) => ({
          dataCache: [
            ...prevState.dataCache,
            ...mapData(responses.flatMap(({ results }) => results)),
          ],
          loadedPagesCount: prevState.pagesCount,
          fetchedAllPages: true,
        }),
        () => {
          newDataLoaded?.(this.state.dataCache, true);
        }
      );
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ pending: false });
    }
  };

  render() {
    const { children } = this.props;
    const {
      pagesCount,
      loadedPagesCount,
      itemsCount,
      dataCache,
      pending,
      error,
    } = this.state;

    const allDataLoaded = pagesCount === 0 || pagesCount === loadedPagesCount;

    return (
      <Handlers
        requestNextPage={this.requestNextPage}
        requestNewSearch={this.requestNewSearch}
      >
        {({ handlers }) =>
          children({
            count: itemsCount,
            data: dataCache,
            pending: pending,
            error: error,
            allDataLoaded: allDataLoaded,
            ...handlers,
          })
        }
      </Handlers>
    );
  }
}

export default InfiniteData;
