// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withAbort } from "utils/decorators";

import { createFilterFromSearchString } from "./_utils";
import { SearchBarWithFiltersPropTypes } from "./propTypes";

const DEFAULT_STATE = {
  isLoading: false,
  error: null,
  suggestions: [],
};

// This component is responsible for sending request to get backend suggestions
// for search filter. We send e.g. {for_filter: "app_name", text: "My applica"}
// to provided endpoint and receive suggestions array.
// This component is noop if `props.suggestionApiEndpoint` is undefined.
export const withSuggestions = (ComposedComponent) => {
  @withAbort
  class WithSuggestionsHOC extends Component {
    static propTypes = {
      ...SearchBarWithFiltersPropTypes,

      // from @withAbort
      isAbortError: PropTypes.func.isRequired,
      createAbortSignal: PropTypes.func.isRequired,
      abort: PropTypes.func.isRequired,
    };

    static defaultProps = {
      // eslint-disable-next-line react/default-props-match-prop-types
      filtersDataChoices: { sections: [] },
    };

    state = { ...DEFAULT_STATE };

    abortFetchingSuggestionsSignal = null;

    abortFetchingSuggestions = (value) => {
      const { abort, filtersDataChoices } = this.props;
      abort(this.abortFetchingSuggestionsSignal);

      const filter = createFilterFromSearchString(value, filtersDataChoices);
      if (value && this.hasBackendValueSuggestions(filter)) {
        // this fixes showing previous suggestions for split second
        // after switching filters. We will show loader now,
        // and the call to refreshSuggestions() later will load new data.
        this.setState({ ...DEFAULT_STATE, isLoading: true });
      }
    };

    hasBackendValueSuggestions = (filter) => {
      const filterHasSuggestions = Boolean(
        get(filter, "filterSpecObject.hasSuggestions")
      );

      return this.isUsingBackendSuggestions() && filterHasSuggestions;
    };

    createSuggestionRequestParams(filter) {
      return filter
        ? {
            for_filter: filter.id,
            text: filter.value,
          }
        : null;
    }

    createSuggestion = (suggestion) => ({
      query: suggestion.filter_value,
      display: suggestion.text,
      icon: suggestion.icon,
    });

    refreshSuggestions = async (value) => {
      const {
        suggestionApiEndpoint,
        createAbortSignal,
        isAbortError,
        filtersDataChoices,
      } = this.props;

      try {
        this.setState({ error: null });

        this.abortFetchingSuggestions();
        this.abortFetchingSuggestionsSignal = createAbortSignal();

        const filter = createFilterFromSearchString(value, filtersDataChoices);
        const queryParams = this.createSuggestionRequestParams(filter);
        if (!this.hasBackendValueSuggestions(filter) || !queryParams) {
          this.setState({ isLoading: false });
          return;
        }

        const { results } = await suggestionApiEndpoint(
          queryParams,
          this.abortFetchingSuggestionsSignal.signal
        );

        // update state since the request was not aborted
        this.setState({
          isLoading: false,
          suggestions: results.map(this.createSuggestion),
        });
      } catch (error) {
        if (isAbortError(error)) {
          return;
        }
        this.setState({
          isLoading: false,
          error: error,
        });
      }
    };

    createChildProps() {
      const { isLoading, error, suggestions } = this.state;

      if (this.isUsingBackendSuggestions()) {
        const hasData = Boolean(!isLoading && !error && suggestions != null);
        return {
          refreshSuggestions: this.refreshSuggestions,
          abortFetchingSuggestions: this.abortFetchingSuggestions,
          hasBackendValueSuggestions: this.hasBackendValueSuggestions,
          suggestions: hasData ? suggestions : undefined,
          isLoadingSuggestions: isLoading,
          suggestionsError: error,
        };
      }

      return {
        refreshSuggestions: () => undefined,
        abortFetchingSuggestions: () => undefined,
        hasBackendValueSuggestions: () => false,
        suggestions: undefined,
        isLoadingSuggestions: false,
        suggestionsError: undefined,
      };
    }

    isUsingBackendSuggestions() {
      return Boolean(this.props.suggestionApiEndpoint);
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          backendSuggestions={this.createChildProps()}
        />
      );
    }
  }

  return WithSuggestionsHOC;
};
