/* eslint-disable react/button-has-type */
// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createRef, PureComponent } from "react";

import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Label } from "components/form";
import {
  CheckboxField,
  RadioGroupField,
  ResolutionField,
  SelectField,
  TextareaField,
  TextField,
} from "components/form/fields";
import { Modal } from "components/layout";
import {
  HOME_ID,
  HOME_SECTION_OPTIONS,
  DEFAULT_SECTION_OPTIONS,
  FIELDS,
  MODALS,
  BUTTONS,
  AUTO_GENERATED_OPTIONS,
} from "pages/Snap/shared/constants";
import { SnapIconPicker } from "pages/Snap/SnapEdit/components/SnapIconPicker/SnapIconPicker";
import { isUrl, required } from "pages/Snap/SnapEdit/validation";
import { trans } from "src/translations";

import styles from "../../Modal.scss";

const LIMIT_CHAR_FOR_DESCRIPTION = 500;
const MINIMUM_DIMENSION_LENGTH = 256;

const isLimitExceeded = (value) =>
  value.length <= LIMIT_CHAR_FOR_DESCRIPTION
    ? undefined
    : trans.ERROR__NO_LONGER_THAN({ max: LIMIT_CHAR_FOR_DESCRIPTION });
const isValidUrl = (value) =>
  isUrl(value) ? undefined : trans.ERROR__INVALID_URL();
const isValidResolution = (value) =>
  value &&
  value.split("x").some((x) => isNaN(x) || x < MINIMUM_DIMENSION_LENGTH)
    ? trans.ERROR__IMAGE_DIMENSIONS_TOO_SMALL({
        minWidth: MINIMUM_DIMENSION_LENGTH,
        minHeight: MINIMUM_DIMENSION_LENGTH,
      })
    : undefined;
const pickIcon = required("Pick icon");
const urlFieldName = `${FIELDS.SECTION_URL.name}`;

class TranslationsModal extends PureComponent {
  static propTypes = {
    focusTitle: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    sectionIndex: PropTypes.number.isRequired,
    formType: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
  };

  static defaultProps = {
    focusTitle: false,
  };

  constructor(props) {
    super(props);
    this.titleRef = createRef();
    this.state = {
      homeFromFeed: props.fields[HOME_ID].url ? "true" : "false",
    };
  }

  componentDidMount() {
    const { focusTitle } = this.props;
    if (focusTitle && this.titleRef.current) {
      this.titleRef.current.focus();
    }
  }

  renderLiveStreamContent = () => {
    const textFieldsConfig = [
      {
        name: FIELDS.LIVE_TITLE.name,
        label: FIELDS.LIVE_TITLE.label,
        reference: this.titleRef,
        dataTestId: "title-field",
      },

      {
        name: urlFieldName,
        label: FIELDS.LIVE_URL.label,
        validate: isValidUrl,
        dataTestId: "url-field",
      },
      {
        name: FIELDS.LIVE_THUMB.name,
        label: FIELDS.LIVE_THUMB.label,
        validate: isValidUrl,
        dataTestId: "thumbnail-field",
      },
    ];

    return (
      <>
        {textFieldsConfig.map((props) => (
          <TextField key={props.dataTestId} {...props} />
        ))}
        <ResolutionField
          name={FIELDS.LIVE_THUMB_RESOLUTION.name}
          label={FIELDS.LIVE_THUMB_RESOLUTION.label}
          validate={isValidResolution}
          dataTestId="thumbnail-resolution-field"
        />
        <SelectField
          name={`${FIELDS.LIVE_TYPE.name}`}
          values={FIELDS.LIVE_TYPE.options}
          label={FIELDS.LIVE_TYPE.label}
          dataTestId="protocol-field"
        />
        <TextareaField
          name={`${FIELDS.LIVE_DESCRIPTION.name}`}
          label={FIELDS.LIVE_DESCRIPTION.label}
          dataTestId="description-field"
          validate={isLimitExceeded}
        />
      </>
    );
  };

  renderBasicSectionFields = () => {
    return (
      <div className={styles.iconSelect}>
        <Field
          name={FIELDS.SECTION_ICON.name}
          component={SnapIconPicker}
          validate={pickIcon}
          required={true}
        />
        <TextField
          name={FIELDS.SECTION_NAME.name}
          label={FIELDS.SECTION_NAME.label}
          look="simple"
          className={styles.iconLabel}
          reference={this.titleRef}
          dataTestId="title-field"
        />
      </div>
    );
  };

  renderSectionOptions = (options, readOnly) => {
    return (
      <div>
        <Label text="Autogenerated categories" />
        {Object.keys(options).map((key, index) => (
          <CheckboxField
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            name={FIELDS[key].name}
            label={FIELDS[key].label}
            dataTestId={FIELDS[key].dataTestId}
            disabled={
              readOnly && key === AUTO_GENERATED_OPTIONS.SERIES_CATEGORIES
            }
          />
        ))}
      </div>
    );
  };

  renderHomeSectionContent = (setFieldValue, setTouched) => {
    const { homeFromFeed } = this.state;
    const isCustomSection = () => homeFromFeed === "true";

    return (
      <>
        <RadioGroupField
          name={FIELDS.SECTION_HOME_TYPE.name}
          label={FIELDS.SECTION_HOME_TYPE.label}
          values={FIELDS.SECTION_HOME_TYPE.radios}
          onChange={(e) => {
            if (e.target.value === "false") {
              setFieldValue(urlFieldName, "");
              setTouched({ url: false });
            }
            this.setState({ homeFromFeed: e.target.value });
          }}
          value={homeFromFeed}
          required={false}
        />
        {isCustomSection() && (
          <TextField
            name={urlFieldName}
            label={FIELDS.SECTION_URL.label}
            validate={isValidUrl}
            reference={this.titleRef}
            dataTestId="url-field"
          />
        )}
        {this.renderSectionOptions(HOME_SECTION_OPTIONS, !isCustomSection())}
      </>
    );
  };

  renderSectionContent = () => {
    return (
      <>
        {this.renderBasicSectionFields()}
        <TextField
          name={urlFieldName}
          label={FIELDS.SECTION_URL.label}
          validate={isValidUrl}
          dataTestId="url-field"
        />
        {this.renderSectionOptions(DEFAULT_SECTION_OPTIONS)}
      </>
    );
  };

  render() {
    const {
      onClose,
      onCancel,
      formType,
      isOpen,
      sectionIndex,
      onSubmit,
      initialValues,
    } = this.props;
    const { homeFromFeed } = this.state;
    const isHomeWithoutFeed =
      sectionIndex === HOME_ID && homeFromFeed === "false";

    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, handleSubmit, dirty, setFieldValue, setTouched }) => (
          <Modal
            className={styles.snapModal}
            onClose={onClose}
            isOpen={isOpen}
            title={MODALS[formType]}
            size="medium"
            actions={
              <ButtonsWrapper>
                <Button
                  type="normal"
                  onClick={onCancel}
                  dataTestId="cancel-button"
                >
                  {BUTTONS.MODAL.cancel}
                </Button>
                <Button
                  type="green"
                  onClick={handleSubmit}
                  dataTestId="ok-button"
                  disabled={isHomeWithoutFeed ? false : isSubmitting || !dirty}
                >
                  {BUTTONS.MODAL.ok}
                </Button>
              </ButtonsWrapper>
            }
          >
            <Form>
              {formType === "section" &&
                sectionIndex === HOME_ID &&
                this.renderHomeSectionContent(setFieldValue, setTouched)}
              {formType === "section" &&
                sectionIndex !== HOME_ID &&
                this.renderSectionContent()}
              {formType === "liveStream" && this.renderLiveStreamContent()}
              {formType === "liveSection" && this.renderBasicSectionFields()}
            </Form>
          </Modal>
        )}
      </Formik>
    );
  }
}

export default TranslationsModal;
