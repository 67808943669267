// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import isString from "lodash-es/isString";
import PropTypes from "prop-types";

import { SearchableListFooter } from "components/elements";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./SelectedCountFooter.scss";

/** Footer used with `<SearchableList>` when list contains checkable items. */
export class SelectedCountFooter extends Component {
  static propTypes = {
    dataTestId: PropTypes.string,
    /**
     * Either string or function that is displays the text. Please remember
     * about pluralization!
     **/
    selectedCountText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** Count of selected items */
    selectedCount: PropTypes.number.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "selected-count-footer",
  };

  defaultSelectedCountText(count) {
    return count === 1
      ? trans.ITEMS_SELECTED_ONE()
      : trans.ITEMS_SELECTED_MANY();
  }

  getText(count) {
    const { selectedCountText } = this.props;

    if (!selectedCountText) {
      return this.defaultSelectedCountText(count);
    }

    return isString(selectedCountText)
      ? selectedCountText
      : selectedCountText(count);
  }

  render() {
    const { dataTestId, selectedCount, className } = this.props;

    return (
      <SearchableListFooter
        dataTestId={dataTestId}
        className={classes(styles.footer, className)}
      >
        <span className={styles.selectedAppsCount}>{selectedCount} </span>
        {this.getText(selectedCount)}
      </SearchableListFooter>
    );
  }
}
