// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const NONE_ITEM_IDX = -1;
export const NONE_ITEM = null;
export const DEFAULT_SEARCH = "";
export const DEFAULT_VALUE = "";

export const KEY_CODES = {
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
  enter: 13,
};
