// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const dataForAppListType = PropTypes.shape({
  // common
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date_submitted: PropTypes.object,
  has_submitted_version: PropTypes.bool.isRequired,
  category: PropTypes.string,
  icon_small: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  owner: PropTypes.shape({
    name: PropTypes.string,
    organizationPlan: PropTypes.string,
  }),

  // my apps
  active_version: PropTypes.object,
  submission_status: PropTypes.string,
  distribution_status: PropTypes.string,
  is_premium: PropTypes.bool,
  flow_status: PropTypes.string,

  // moderation apps
  contentManager: PropTypes.string,
  moderator: PropTypes.string,
  needAction: PropTypes.bool,
  priority: PropTypes.string,

  // distribution admin apps
  available_since: PropTypes.object,
});
