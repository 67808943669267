// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useParams, useLocation } from "react-router-dom";

import { MessagesData } from "./Messages/MessagesData";

export const MessagesTab = () => {
  const { id } = useParams();
  const location = useLocation();

  return (
    <div>
      <MessagesData
        applicationId={id}
        location={location} // needed cause react-router is special
      />
    </div>
  );
};
