// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const APP_DETAILS = "apps-details-tab";
export const CERTIFICATION = "certification-tab";
export const MODERATION = "moderation-details";
export const TECHNICAL_DETAILS = "technical-details";

// order matters!
// order has to be the same as in ApplicationsTabbedTable.js
export const appsTabsConfig = [
  {
    id: APP_DETAILS,
    label: trans.APP_LIST__TAB_DETAILS(),
    dataTestId: "app-details-tab",
    columns: [
      {
        id: "icon",
        label: trans.APP_LIST__COLUMN_ICON(),
        dataTestId: "icon-column-header",
      },
      {
        id: "name",
        label: trans.APP_LIST__COLUMN_NAME(),
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "category",
        label: trans.APP_LIST__COLUMN_CATEGORY(),
        sortBy: "category",
        dataTestId: "category-column-header",
      },
      {
        id: "owner",
        label: trans.APP_LIST__COLUMN_OWNER(),
        sortBy: "owner_name",
        dataTestId: "owner-column-header",
      },
      {
        id: "dateSubmitted",
        label: trans.APP_LIST__COLUMN_DATE_SUBMITTED(),
        sortBy: "date_submitted",
        dataTestId: "date-column-header",
      },

      // {
      // id: "needAction",
      // dataTestId: "need-action-column-header",
      // },
      // todo: uncomment when YGG-1776 ready
      // {
      //   id: "message",
      //   label: trans.APP_LIST__COLUMN_MESSAGES(),
      //   dataTestId: "messages-column-header",
      // },
      {
        id: "",
        dataTestId: "action-menu-column-header",
      },
    ],
  },
  /* TODO YGG-3361 Hidden as not yet implemented by backend
  {
    id: CERTIFICATION,
    label: trans.APP_LIST__TAB_CERTIFICATION(),
    dataTestId: "certification-tab",
    columns: [
      {
        id: "icon",
        label: trans.APP_LIST__COLUMN_ICON(),
        dataTestId: "icon-column-header",
      },
      {
        id: "name",
        label: trans.APP_LIST__COLUMN_NAME(),
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "certification",
        label: trans.APP_LIST__COLUMN_CERTIFICATION(),
        sortBy: "certification_status",
        dataTestId: "certification-column-header",
      },
      {
        id: "contentManager",
        label: trans.APP_LIST__COLUMN_CONTENT_MANAGER(),
        sortBy: "content_manager_name",
        dataTestId: "content-manager-column-header",
      },
      {
        id: "targetStatus",
        label: trans.APP_LIST__COLUMN_TARGET_STATUS(),
        dataTestId: "target-status-column-header",
      },
      {
        id: "distribution",
        label: trans.APP_LIST__COLUMN_DISTRIBUTION(),
        dataTestId: "distribution-column-header",
      },
      {
        id: "needAction",
        sortBy: "need_action",
        dataTestId: "need-action-column-header",
      },
      // todo: uncomment when YGG-1776 ready
      // {
      //   id: "message",
      //   label: trans.APP_LIST__COLUMN_MESSAGES(),
      //   dataTestId: "messages-column-header",
      // },
      {
        id: "",
        dataTestId: "action-menu-column-header",
      },
    ],
  },
  */
  {
    id: MODERATION,
    label: trans.APP_LIST__TAB_MODERATION(),
    dataTestId: "moderation-tab",
    columns: [
      {
        id: "icon",
        label: trans.APP_LIST__COLUMN_ICON(),
        dataTestId: "icon-column-header",
      },
      {
        id: "name",
        label: trans.APP_LIST__COLUMN_NAME(),
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "dateSubmitted",
        label: trans.APP_LIST__COLUMN_DATE_SUBMITTED(),
        sortBy: "date_submitted",
        dataTestId: "date-column-header",
      },
      {
        id: "moderationStatus",
        label: trans.APP_LIST__COLUMN_MODERATION_STATUS(),
        sortBy: "moderation_status",
        dataTestId: "moderation-column-header",
      },
      {
        id: "moderator",
        label: trans.APP_LIST__COLUMN_MODERATOR(),
        sortBy: "moderator_name",
        dataTestId: "moderator-column-header",
      },
      {
        id: "priority",
        label: trans.APP_LIST__COLUMN_PRIORITY(),
        sortBy: "priority",
        dataTestId: "priority-column-header",
      },
      // {
      // id: "needAction",
      // sortBy: "need_action",
      // dataTestId: "need-action-column-header",
      // },
      // todo: uncomment when YGG-1776 ready
      // {
      //   id: "message",
      //   label: trans.APP_LIST__COLUMN_MESSAGES(),
      //   dataTestId: "messages-column-header",
      // },
      {
        id: "",
        dataTestId: "action-menu-column-header",
      },
    ],
  },
  {
    id: TECHNICAL_DETAILS,
    label: trans.APP_LIST__TAB_TECHNICAL_DETAILS(),
    dataTestId: "technical-details-tab",
    columns: [
      {
        id: "icon",
        label: trans.APP_LIST__COLUMN_ICON(),
        dataTestId: "icon-column-header",
      },
      {
        id: "name",
        label: trans.APP_LIST__COLUMN_NAME(),
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "appType",
        label: trans.APP_LIST__COLUMN_APP_TYPE(),
        sortBy: "type",
        dataTestId: "app-type-column-header",
      },
      /* TODO YGG-3361 Hidden as not yet implemented by backend
      {
        id: "videoSpec",
        label: trans.APP_LIST__COLUMN_VIDEO_SPEC(),
        dataTestId: "video-column-header",
      },
      */
      // {
      // id: "needAction",
      // dataTestId: "need-action-column-header",
      // },
      // todo: uncomment when YGG-1776 ready
      // {
      //   id: "message",
      //   label: trans.APP_LIST__COLUMN_MESSAGES(),
      //   dataTestId: "messages-column-header",
      // },
      {
        id: "",
        dataTestId: "action-menu-column-header",
      },
    ],
  },
];

export const myAppsTabsConfig = [
  {
    id: APP_DETAILS,
    label: trans.APP_LIST__TAB_DETAILS(),
    dataTestId: "app-details-tab",
    columns: [
      {
        id: "icon",
        label: trans.APP_LIST__COLUMN_ICON(),
        path: "icon_small",
        dataTestId: "icon-column-header",
      },
      {
        id: "name",
        label: trans.APP_LIST__COLUMN_NAME(),
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "category",
        label: trans.APP_LIST__COLUMN_CATEGORY(),
        // sortBy: "category", // TODO YGG-3694, sorting by "category" currently returns duplicates, which breaks react
        dataTestId: "category-column-header",
      },
      {
        id: "dateSubmitted",
        label: trans.APP_LIST__COLUMN_DATE_SUBMITTED(),
        dataTestId: "date-column-header",
      },
      {
        id: "submissionStatus",
        label: trans.APP_LIST__COLUMN_SUBMISSION_STATUS(),
        dataTestId: "submission-status-column-header",
      },
      {
        id: "distributionStatus",
        label: trans.APP_LIST__COLUMN_DISTRIBUTION_STATUS(),
        dataTestId: "distribution-status-column-header",
      },
      // todo: uncomment when YGG-1776 ready
      // {
      //   id: "message",
      //   label: trans.APP_LIST__COLUMN_MESSAGES(),
      //   dataTestId: "messages-column-header",
      // },
      {
        id: "",
        dataTestId: "action-menu-column-header",
      },
    ],
  },
  {
    id: CERTIFICATION,
    label: trans.APP_LIST__TAB_CERTIFICATION_AND_MODERATION(),
    dataTestId: "certification-tab",
    columns: [
      {
        id: "icon",
        label: trans.APP_LIST__COLUMN_ICON(),
        dataTestId: "icon-column-header",
      },
      {
        id: "name",
        label: trans.APP_LIST__COLUMN_NAME(),
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "moderationStatus",
        label: trans.APP_LIST__COLUMN_MODERATION_STATUS(),
        // sortBy: "moderation_status", // TODO YGG-3694, sorting by "moderation_status" currently returns duplicates, which breaks react
        dataTestId: "moderation-column-header",
      },
      /* TODO YGG-3361 Hidden as not yet implemented by backend
      {
        id: "targetStatus",
        label: trans.APP_LIST__COLUMN_TARGET_STATUS(),
        dataTestId: "target-status-column-header",
      },
      {
        id: "distribution",
        label: trans.APP_LIST__COLUMN_DISTRIBUTION(),
        dataTestId: "distribution-column-header",
      },
      */
      // todo: uncomment when YGG-1776 ready
      // {
      //   id: "message",
      //   label: trans.APP_LIST__COLUMN_MESSAGES(),
      //   dataTestId: "messages-column-header",
      // },
      {
        id: "",
        dataTestId: "action-menu-column-header",
      },
    ],
  },
];

export const availableAppsTabsConfig = [
  {
    id: APP_DETAILS,
    dataTestId: "app-details-tab",
    columns: [
      {
        id: "icon",
        label: trans.APP_LIST__COLUMN_ICON(),
        dataTestId: "icon-column-header",
      },
      {
        id: "name",
        label: trans.APP_LIST__COLUMN_NAME(),
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "owner",
        label: trans.APP_LIST__COLUMN_OWNER(),
        sortBy: "application_owner",
        dataTestId: "owner-column-header",
      },
      {
        id: "availableSince",
        label: trans.APP_LIST__COLUMN_AVAILABLE_SINCE(),
        sortBy: "first_approved_date",
        dataTestId: "available-since-column-header",
      },
      {
        id: "",
        dataTestId: "action-menu-column-header",
      },
    ],
  },
];
