// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { ROLES } from "src/containers/Permissions/roles";

export const getUserRoles = (userGrants, roles) => {
  return userGrants
    .filter((g) => {
      return g.role !== ROLES.organization.member;
    })
    .map((grant) => ({
      role:
        (roles.find(({ id }) => id === grant.role) || {}).name || grant.role,
    }));
};

export const getUserRolesString = (userRoles) => {
  if (!userRoles || userRoles.length === 0) {
    return NO_VALUE_PLACEHOLDER;
  }

  return userRoles.map(({ role }) => role).join(", ");
};

export const getFullName = ({ given_name, family_name }) =>
  [given_name, family_name].join(" ") || NO_VALUE_PLACEHOLDER;

const createDescription = (activeOrganization, userRoles = []) => {
  if (!activeOrganization) {
    return "";
  }
  return `${getUserRolesString(userRoles)} @ ${activeOrganization.name}`;
};

export const getUserDetailsData = (user, activeOrganization, userRoles) => ({
  name:
    [user.given_name, user.family_name].join(" ").trim() ||
    NO_VALUE_PLACEHOLDER,
  email: user.email,
  logo: user.avatar,
  description: createDescription(activeOrganization, userRoles),
});

export const getOrganizationNamesString = (organizationNames) => {
  if (!organizationNames || organizationNames.length === 0) {
    return NO_VALUE_PLACEHOLDER;
  }

  return organizationNames.join(", ");
};
