// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withTargetsReceive } from "pages/_shared/TargetsField/decorators/withTargetsReceive";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";

import { FIELDS } from "./constants";
import { DefaultPlanForm } from "./DefaultPlanForm";

const INITIAL_VALUES = {};
Object.values(FIELDS).forEach(({ name, defaultValue }) => {
  INITIAL_VALUES[name] = defaultValue;
});

@restrictedArea(() => {
  return {
    allowed: [ROLES.distributor.distributionPlanner],
  };
})
@withRouter
@withActiveOrganization
@withTargetsReceive({ distributorFieldNameInRequest: "device_owners" })
@connect(null, (dispatch) => ({
  getMyDefaultPlan: () =>
    dispatch(apiThatThrows.getMyDefaultDistributionPlan.action()),
  getTargetOfPlan: async (planId, targetId) => {
    const request = await dispatch(
      apiThatThrows.getTargetOfMyDefaultDistributionPlan.action({
        params: {
          planId,
          targetId,
        },
      })
    );
    return request.results;
  },
}))
export class DefaultPlanData extends Component {
  static propTypes = {
    // from @withRouter
    params: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,

    // from @connect
    getMyDefaultPlan: PropTypes.func.isRequired,
    getTargetOfPlan: PropTypes.func.isRequired,

    // from @withTargetsReceive
    getTargetsData: PropTypes.func,
  };

  state = {
    loading: true,
    error: null,
    info: null,
    initialValues: INITIAL_VALUES,
  };

  componentDidMount() {
    this.updateInitialValues();
  }

  async componentDidUpdate(prevProps) {
    const { activeOrganization } = this.props;
    if (activeOrganization.id !== prevProps.activeOrganization.id) {
      await this.refreshData();
    }
  }

  prepareTarget = (plan) => async (target) => {
    const { getTargetOfPlan } = this.props;

    try {
      return getTargetOfPlan(plan.id, target.id);
    } catch (err) {
      throw {
        message: trans.DISTR_PLAN__TARGET_NOT_FOUND_ERROR({
          targetId: target.id,
          planId: plan.id,
        }),
      };
    }
  };

  updateInitialValues = async () => {
    const { params, getMyDefaultPlan, getTargetsData } = this.props;

    this.setState({ loading: true });

    try {
      const { results: plan } = await getMyDefaultPlan(params.id);

      const targetRequests = plan.targets.map(this.prepareTarget(plan));
      const targetsOfPlan = await Promise.all(targetRequests);
      const targets = await getTargetsData(targetsOfPlan);

      this.setState({
        error: null,
        initialValues: {
          autoApproveTime: plan.default_plan_auto_distribute_wait_time,
          targets: targets,
        },
      });
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ loading: false });
    }
  };

  refreshData = () => {
    this.updateInitialValues();
  };

  setInfo = (info) => this.setState({ info });

  onChange = () => this.setInfo(null);

  render() {
    const { loading, error, initialValues, info } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message || error}</Info>;
    }

    return (
      <DefaultPlanForm
        initialValues={initialValues}
        refreshData={this.refreshData}
        setInfo={this.setInfo}
        info={info}
        onChange={this.onChange}
      />
    );
  }
}
