// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { sharedDefaultValues } from "src/configureStore";
import { trans } from "src/translations";
import { pipeValidators, isRequired } from "utils/validation";

import { Description } from "../../../components";

export default {
  name: trans.DESCRIPTION(),
  components: [
    {
      component: Description,
      fields: [
        "metadata.translations[].default_translation",
        "metadata.translations[].language",
        "metadata.translations[].name",
        "metadata.translations[].short_description",
      ],
      initialValues: {
        metadata: {
          translations: [
            {
              default_translation: true,
              language: sharedDefaultValues.defaultLanguageCode,
              name: "",
              short_description: "",
            },
          ],
        },
      },
      validate: pipeValidators(
        isRequired(/^metadata\.translations\[\d+\]\.language/),
        isRequired(/^metadata\.translations\[\d+\]\.name/),
        isRequired(/^metadata\.translations\[\d+\]\.short_description/)
      ),
      props: ({ formikProps }) => {
        return {
          translationFieldName: "metadata.translations",
          ...formikProps,
        };
      },
    },
  ],
};
