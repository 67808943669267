// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useMemo } from "react";

import { useField } from "formik";

import { createMixedValidator } from ".";
import { RadioGroup } from "../elements";
import { FieldWithOptionsProps } from "./propTypes";

export const RadioGroupField = (props) => {
  const {
    name,
    values,
    required = true,
    validate: validateFn,
    ...rest
  } = props;

  const validate = useMemo(
    () => createMixedValidator(name, required, validateFn),
    [name, required, validateFn]
  );

  const [field, meta] = useField({
    ...props,
    validate,
    type: "radio",
  });

  const getValues = () =>
    values.map(({ value, ...rest }) => ({
      defaultValue: value,
      ...rest,
    }));

  return (
    <RadioGroup
      required={required}
      {...field}
      {...meta}
      {...rest}
      radios={getValues()}
    />
  );
};

RadioGroupField.propTypes = FieldWithOptionsProps;
