// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

// ----------------------------------------------
// Please don't modify manually!
// ----------------------------------------------
// This file has been generated automatically based on translations from .csv file.
// All texts, that have not been translated yet, should be placed in def_lang.js file instead.
export default {};
