// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Operation } from "@apollo/client/link/core/types";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import * as Sentry from "@sentry/browser";
import get from "lodash-es/get";

const createSentryGroupKey = (): string => {
  const { protocol, host, pathname } = window.location;
  return `${protocol}//${host}${pathname}`;
};

const reportToConsole = (errMsg: string): void => {
  if (process.env.NODE_ENV !== "production") {
    console.error(errMsg);
  }
};

const addSentryOperationData = (scope: Sentry.Scope, operation: Operation) => {
  scope.setTag("is_graphql_error", String(true));

  scope.setExtra("operationName", get(operation, "operationName", "-"));
  scope.setExtra(
    "operationSource",
    get(operation, "query.loc.source.body", "-")
  );
  scope.setExtra("operationVariables", get(operation, "variables", "-"));
};

const addSentryBreadcrumb = (message: string, data: any = {}): void => {
  // normally we should use scope.addBreadcrumb, but Sentry does not register it
  // https://github.com/getsentry/sentry-javascript/issues/2009
  Sentry.addBreadcrumb({
    category: "graphql-error",
    level: "error" as Sentry.SeverityLevel,
    message,
    data,
  });
};

/*
 * e.g. error 400 when the graphql schema is invalid or CORS or other HTTP error.
 *
 * Browser will automatically report this error to sentry, we just have to add
 * more metadata. This error will not have sentryId field from api-gateway
 */
const reportNetworkError = (
  scope: Sentry.Scope,
  error: ErrorResponse
): void => {
  const { operation, graphQLErrors, networkError } = error;

  (graphQLErrors || []).forEach((err) => {
    addSentryBreadcrumb(err.message, {
      locations: err.locations,
    });
    reportToConsole(err.message);
  });

  const msg = `${operation.operationName} network error: ${networkError?.message}`;
  addSentryBreadcrumb(msg);
  reportToConsole(`[GraphQL] ${msg}`);

  // set Sentry grouping rules for this kind of graphql errors
  scope.setFingerprint([msg, createSentryGroupKey()]);
};

/**
 * e.g. when api-gateway tried to fulfil the request, but there was an execution exception.
 *
 * If the api-gateway already reported the issue to sentry,
 * we can read sentryId of the issue.
 */
const reportExecutionError = (
  scope: Sentry.Scope,
  error: ErrorResponse
): void => {
  const { operation, graphQLErrors } = error;

  (graphQLErrors || []).forEach((err) => {
    const msg = `${operation.operationName} error: ${err.message}`;
    const sentryId =
      get(err, "extensions.sentryId") ||
      get(err, "extensions.exception.sentryId");

    addSentryBreadcrumb(msg, { sentryId });
    reportToConsole(`[GraphQL] ${msg}`);
  });

  // set Sentry grouping rules for this kind of graphql errors
  scope.setFingerprint([createSentryGroupKey()]);
};

export const sentryGraphqlLink = onError((error) => {
  const { operation, networkError } = error;

  Sentry.configureScope((scope) => {
    addSentryOperationData(scope, operation);

    if (networkError) {
      reportNetworkError(scope, error);
    } else {
      reportExecutionError(scope, error);
    }
  });
});
