// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { APP_ENVIRONMENT, APP_ENVIRONMENT_STATUS } from "./constants";

export const mapVideoItem = (item) => {
  return {
    name: item.human_name,
    comment: item.comment,
  };
};

export const prepareMonetization = (srcMonetization) => {
  const res = [];

  //Direct monetization methods
  if (srcMonetization) {
    if (srcMonetization.transactions) {
      res.push({ name: "Transactions", comment: "" });
    }
    if (srcMonetization.subscriptions) {
      res.push({ name: "Subscriptions", comment: "" });
    }
    if (srcMonetization.advertisement) {
      res.push({ name: "Advertisement", comment: "" });
    }
    if (srcMonetization.other) {
      res.push({
        name: "Other",
        comment: srcMonetization.other,
      });
    }
  }

  return res;
};

export const prepareTechnicalDetails = (srcTechnicalDetails) => {
  return {
    containsVideo: srcTechnicalDetails.contains_video,
    containsLiveVideo: srcTechnicalDetails.video_type?.contains_live_video,
    containsVOD: srcTechnicalDetails.video_type?.contains_vod,
    videoAdaptiveStreaming:
      srcTechnicalDetails.video_adaptive_streaming.map(mapVideoItem),
    videoDRMs: srcTechnicalDetails.video_drms.map(mapVideoItem),
    videoFormats: srcTechnicalDetails.video_formats.map(mapVideoItem),
  };
};

export const prepareChangelog = (srcChangeLog) => {
  return {
    scope: srcChangeLog.change_type,
    description: srcChangeLog.additional_information,
  };
};

const prepareProfiles = (srcProfiles) => ({
  [APP_ENVIRONMENT.ANDROID]: srcProfiles
    .filter(
      ({ environment_type }) => environment_type === APP_ENVIRONMENT.ANDROID
    )
    .map(({ name, data }) => ({
      name: name,
      action: data.action,
      packageName: data.package_name,
      className: data.class_name,
    })),
  [APP_ENVIRONMENT.LINUX]: srcProfiles
    .filter(
      ({ environment_type }) => environment_type === APP_ENVIRONMENT.LINUX
    )
    .map(({ name, data }) => ({
      name: name,
      nativeId: data.native_id,
    })),
});

const getEnvironmentStatus = (isFunctional) => {
  switch (isFunctional) {
    case null: {
      return APP_ENVIRONMENT_STATUS.PENDING;
    }
    case true: {
      return APP_ENVIRONMENT_STATUS.APPROVED;
    }
    case false: {
      return APP_ENVIRONMENT_STATUS.REJECTED;
    }
    default: {
      return undefined;
    }
  }
};

const prepareEnvironments = (srcEnvironments) => {
  const environments = {};

  srcEnvironments.forEach(({ id, type, is_functional }) => {
    environments[type] = {
      id,
      status: getEnvironmentStatus(is_functional),
    };
  });

  return environments;
};

export const prepareAppData = (srcData) => {
  if (!srcData) {
    return null;
  }
  const {
    technical_details,
    monetization,
    changelog,
    profiles,
    environments,
    ...data
  } = srcData;
  data.technicalDetails = prepareTechnicalDetails(technical_details);
  data.monetization = prepareMonetization(monetization);
  data.changelog = prepareChangelog(changelog);
  data.profiles = prepareProfiles(profiles);
  data.environments = prepareEnvironments(environments);

  return data;
};
