// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Label } from "components/form";
import { Checkbox } from "components/form/elements";
import { ImgIcon } from "components/icons";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./ApplicationsList.scss";

export class ApplicationsList extends Component {
  static propTypes = {
    isChecked: PropTypes.func.isRequired,
    setChecked: PropTypes.func.isRequired,
    applications: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string,
  };

  handleChecked = (e) => {
    const { setChecked } = this.props;
    setChecked(e.target.value, e.target.checked);
  };

  getHeaderText() {
    const { applications, isChecked } = this.props;
    const checkedApps = applications.filter((app) => isChecked(app.id));
    return trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_APPS_SELECTED_MANY({
      count: checkedApps.length,
    });
  }

  renderAppRow = (app) => {
    const { isChecked } = this.props;
    return (
      <li key={app.id}>
        <Checkbox
          name={`app-in-plans-modal-checkbox-${app.id}`}
          checked={isChecked(app.id)}
          value={app.id}
          onChange={this.handleChecked}
        >
          <ImgIcon src={app.icon} className={styles.appIcon} />
          <span className={styles.appName}>{app.name}</span>
        </Checkbox>
      </li>
    );
  };

  render() {
    const { applications, className } = this.props;

    return (
      <Label
        text={this.getHeaderText()}
        className={classes(className, styles.container)}
      >
        <ul className={styles.appsList}>
          {applications.map(this.renderAppRow)}
        </ul>
      </Label>
    );
  }
}
