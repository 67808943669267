// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { parseISO } from "date-fns";

import { formatDateTime, formatDate } from "utils/date";

/*
 *  availabilityDates are missing time (it is reset to 00:00:00), so there is an issue on how
 *  to calculate local time to show it to end user. To get around this issue we copy hours from creationDate
 *  to show to the end user local time for availability dates (without it there was +/- 1 day difference),
 */
export const adaptUpdateJobDates = (
  creationDate,
  availabilityStartDate,
  availabilityEndDate
) => {
  const creationDateString = formatDateTime(parseISO(String(creationDate)));
  const creationDateObject = new Date(creationDate);
  const startDateWithTime = new Date(availabilityStartDate).setUTCHours(
    creationDateObject.getUTCHours(),
    creationDateObject.getUTCMinutes()
  );
  const endDateWithTime = new Date(availabilityEndDate).setUTCHours(
    creationDateObject.getUTCHours(),
    creationDateObject.getUTCMinutes()
  );
  const startDate = formatDate(new Date(startDateWithTime));
  const endDate = formatDate(new Date(endDateWithTime));

  return {
    creation: creationDateString,
    start: startDate,
    end: endDate,
  };
};
