// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { TableSubRow, TableRowColumn } from "components/layout";

import styles from "./PromotionRow.scss";

export class PromotionRow extends Component {
  static propTypes = {
    promotion: PropTypes.object,
  };

  render() {
    const { name } = this.props.promotion;

    return (
      <TableSubRow>
        <TableRowColumn className={styles.name}>{name}</TableRowColumn>
        <TableRowColumn />
        <TableRowColumn />
        <TableRowColumn />
        <TableRowColumn />
        <TableRowColumn />
        <TableRowColumn />
        <TableRowColumn />
      </TableSubRow>
    );
  }
}
