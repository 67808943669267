// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Certification } from "../../../components/index";

export default {
  components: [
    {
      component: Certification,
      props: ({ appData }) => {
        return { appType: appData.type };
      },
    },
  ],
};
