// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { TableBody } from "components/layout";
import { Tooltip } from "components/popups";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { HtmlTranslation } from "src/translations/utils/components/HtmlTranslation";

import { DefaultSortContext } from "../../_shared/Context/defaultSortContext";
import { resultsPropTypes } from "./propTypes";
import styles from "./UpdateJobList.scss";
import { ITEMS_PER_PAGE } from "./UpdateJobListData";
import { UpdateJobListRow } from "./UpdateJobListRow";

export class UpdateJobListTable extends PureComponent {
  static propTypes = {
    results: resultsPropTypes,
    loading: PropTypes.bool,
    count: PropTypes.number,
    error: PropTypes.string,
    openModal: PropTypes.func.isRequired,
  };

  getTabsConfig = () => {
    return [
      {
        id: "UpdateJobTable",
        columns: [
          {
            id: "name",
            label: trans.UPDATES__UPDATE_JOB_LIST__TABLE_NAME(),
            sortBy: "name",
            dataTestId: "name-column-header",
          },
          {
            id: "status",
            label: trans.UPDATES__UPDATE_JOB_LIST__TABLE_STATUS(),
            sortBy: "status",
            dataTestId: "status-column-header",
            className: styles.statusColumn,
          },
          {
            id: "updateStep",
            label: trans.UPDATES__UPDATE_JOB_LIST__TABLE_UPDATE_STEP(),
            dataTestId: "update-step-column-header",
            className: styles.stepColumn,
          },
          {
            id: "creationDate",
            label: trans.UPDATES__UPDATE_JOB_LIST__TABLE_CREATION_DATE(),
            sortBy: "created_at",
            dataTestId: "creation-date-column-header",
            className: styles.dateColumn,
          },
          {
            id: "isRepeatable",
            label: (
              <Tooltip
                content={
                  <HtmlTranslation
                    transFunc={
                      trans.UPDATES__UPDATE_JOB_LIST__TABLE_IS_REPEATABLE_TOOLTIP
                    }
                  />
                }
                alignment="left"
                className={styles.isRepeatableTooltip}
              >
                {trans.UPDATES__UPDATE_JOB_LIST__TABLE_IS_REPEATABLE()}
                <span className={styles.tooltipIcon}>(?)</span>
              </Tooltip>
            ),
            sortBy: "is_repeatable",
            dataTestId: "is-repeatable-column-header",
            className: styles.statusColumn,
          },
          { id: "actions", label: "", className: styles.optionsColumn },
        ],
      },
    ];
  };

  render() {
    const { results, loading, count, error, openModal } = this.props;

    return (
      <DefaultSortContext.Consumer>
        {(defaultOrder) => (
          <TabbedTable
            tabsConfig={this.getTabsConfig()}
            data={{
              loading,
              results,
              count,
              error,
              defaultOrder,
            }}
            dataTestId="update-job-table"
            noResultsMessage={
              <p>{trans.UPDATES__UPDATE_JOB_LIST__NO_RESULTS_MESSAGE()}</p>
            }
            rowsPerPage={ITEMS_PER_PAGE}
            renderTableBody={(row) => (
              <TableBody>
                {row.map((job) => (
                  <UpdateJobListRow
                    key={job.id}
                    updateJob={job}
                    openModal={openModal}
                  />
                ))}
              </TableBody>
            )}
            layout="fixed"
          />
        )}
      </DefaultSortContext.Consumer>
    );
  }
}
