// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

export const OrganizationActionMenu = ({ organizationId }) => (
  <ActionMenu>
    <MenuItem to={`/organization/${organizationId}/details`}>
      {trans.VIEW_DETAILS()}
    </MenuItem>
    <MenuItem to={`/organization/${organizationId}/edit`}>
      {trans.EDIT_DETAILS()}
    </MenuItem>
  </ActionMenu>
);

OrganizationActionMenu.propTypes = {
  organizationId: PropTypes.string.isRequired,
};
