// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import queryString from "query-string";

import { restrictedArea, ROLES } from "containers/Permissions";
import { api } from "containers/Request";
import { withRouter } from "utils/decorators";

import { PromotionTypesTable } from "./PromotionTypesTable";

@withRouter
@restrictedArea(() => {
  return {
    allowed: [ROLES.administrator.promotionAdmin],
  };
})
@connect(null, (dispatch) => ({
  getPromotionTypesPaginated: (location) =>
    dispatch(
      api.getPromotionTypesPaginated.action({
        queryParams: {
          page: 1,
          ...queryString.parse(location.search),
        },
      })
    ),
}))
export class PromotionsTypesTableData extends Component {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object.isRequired,

    // from @connect
    getPromotionTypesPaginated: PropTypes.func.isRequired,
  };

  state = {
    promotionTypes: [],
    count: 0,
    error: undefined,
    loading: true,
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.updateData();
    }
  }

  refreshData = () => {
    this.updateData();
  };

  updateData = async () => {
    const { getPromotionTypesPaginated, location } = this.props;

    this.setState({ loading: true });

    const promotionTypesRequest = await getPromotionTypesPaginated(location);

    if (promotionTypesRequest.error) {
      this.setState({ error: promotionTypesRequest.error, loading: false });
      return;
    }

    const promotionTypesResults = promotionTypesRequest.result.results;
    const promotionTypesMeta = promotionTypesRequest.result.meta;

    const promotionTypes = [];
    promotionTypesResults.forEach((p) => {
      promotionTypes.push({
        id: p.id,
        name: p.name,
        softwareAssets: p.software_assets,
        promotionsCount: p.promotions_count,
      });
    });

    this.setState({
      promotionTypes: promotionTypes,
      count: promotionTypesMeta.count,
      error: null,
      loading: false,
    });
  };

  render() {
    const { promotionTypes, count, error, loading } = this.state;

    return (
      <PromotionTypesTable
        promotionTypes={promotionTypes}
        count={count}
        loading={loading}
        error={error}
        refreshData={this.refreshData}
      />
    );
  }
}
