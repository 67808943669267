// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { PopupOnClick } from "components/popups";
import { withActiveOrganization } from "containers/Auth/decorators";
import {
  organizationsPropTypes,
  profilePropTypes,
} from "containers/Auth/propTypes";

import styles from "./AccountMenu.scss";
import { Item } from "./Item";
import { Menu } from "./Menu";

@withActiveOrganization
@connect(({ auth }) => ({
  organizations: auth.organizations,
  currentUser: auth.profile,
}))
export class AccountMenu extends Component {
  static propTypes = {
    organizations: organizationsPropTypes,
    currentUser: profilePropTypes,
    dataTestId: PropTypes.string,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      img: PropTypes.string,
    }),
    changeActiveOrganization: PropTypes.func.isRequired,
    isChangingActiveOrganization: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    dataTestId: "account-menu",
  };

  changeActiveOrganization = (closePopup) => async (organizationId) => {
    await this.props.changeActiveOrganization(organizationId);
    closePopup();
  };

  render() {
    const {
      organizations,
      currentUser,
      activeOrganization,
      isChangingActiveOrganization,
      dataTestId,
    } = this.props;

    return (
      <div className={styles.container}>
        <PopupOnClick
          dataTestId={dataTestId}
          renderTrigger={() => (
            <Item
              organizationImg={activeOrganization ? activeOrganization.img : ""}
              userImg={currentUser.img}
              text={currentUser.name}
              showArrow={true}
              className={styles.account}
            />
          )}
          renderPopup={({ closePopup }) => (
            <Menu
              currentUser={currentUser}
              organizations={organizations}
              activeOrganization={activeOrganization}
              isChangingActiveOrganization={isChangingActiveOrganization}
              changeActiveOrganization={this.changeActiveOrganization(
                closePopup
              )}
            />
          )}
        />
      </div>
    );
  }
}
