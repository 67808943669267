// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { withRouter, withSearch } from "utils/decorators";

import { AdminPlansTableBody } from "./AdminPlansTableBody";

@withRouter
@withSearch
export class AdminPlansRend extends Component {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object.isRequired,

    // props from parent data component
    results: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
    // onPlanRemove: PropTypes.func.isRequired,
  };

  tabsConfig = [
    {
      dataTestId: "admin-plans-list",
      columns: [
        { label: trans.NAME() },
        { label: trans.APPS() },
        { label: trans.DISTRIBUTOR() },
        { label: "" },
      ],
    },
  ];

  tableBody = (data) => {
    return <AdminPlansTableBody data={data} />;
  };

  renderCreateButton = () => {
    return (
      <ButtonsWrapper>
        <Button type="green" to="/distribution/admin-plans/create">
          {trans.DISTR_ADMIN_PLANS__CREATE_BUTTON()}
        </Button>
      </ButtonsWrapper>
    );
  };

  render() {
    const {
      results,
      count,
      error,
      loading,
      searchValue,
      changeSearch,
      location,
    } = this.props;

    const locationMessage = get(location, "state.message");
    const locationError = get(location, "state.error");

    return (
      <div>
        <Section>
          <SearchBar onSearch={changeSearch} value={searchValue} />
        </Section>
        <Section>
          {locationMessage && <Info>{locationMessage}</Info>}
          {locationError && <Info type="error">{locationError}</Info>}
        </Section>
        {this.renderCreateButton()}
        <Section>
          <TabbedTable
            tabsConfig={this.tabsConfig}
            renderTableBody={this.tableBody}
            data={{ results, count, loading, error }}
          />
        </Section>
        {this.renderCreateButton()}
      </div>
    );
  }
}
