// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Radio, InputWithModal } from "components/form/elements";
import { Modal } from "components/layout";
import { Header } from "components/typography";
import { RestrictedArea, ORGANIZATION_TYPES } from "containers/Permissions";
import { trans } from "src/translations";

import { DEVICE_TYPE_PDID, DEVICE_TYPE_QUERY } from "../constants";
import styles from "./TargetDevices.scss";
import { TargetList } from "./TargetList";

const validate = (value, required) => {
  let error;
  if (required && !value) {
    error = trans.ERROR__FIELD_REQUIRED();
  }
  return error;
};

const getFieldDisplayValue = (value) => {
  // None
  if (!value) {
    return "";
  }
  // Query
  if ("deviceCount" in value) {
    return `${value.name} (${trans.TARGET_DEVICES__DEVICE_COUNT({
      deviceCount: value.deviceCount,
    })})`;
  }
  // Single device
  return value.name;
};

export const TargetDevicesModal = ({
  name,
  required,
  label,
  modalConfig,
  dataTestId,
}) => {
  const [field, meta, { setTouched, setValue }] = useField({
    name,
    validate: (value) => validate(value, required),
  });

  const [mode, setMode] = useState(DEVICE_TYPE_QUERY);
  const [stagingValue, setStagingValue] = useState(field.value);

  const onChangeMode = (e) => {
    setMode(e.target.value);
  };

  const onSelectItem = (item) => setStagingValue(item);

  const handleClose = (onClose) => {
    setTouched();
    onClose();
  };

  const handleSave = (onClose) => {
    handleClose(onClose);
    setValue(stagingValue);
  };

  return (
    <InputWithModal
      label={label}
      look="horizontal"
      meta={meta}
      required={required}
      value={getFieldDisplayValue(field.value)}
      dataTestId={dataTestId}
    >
      {(isOpen, onClose) => {
        return (
          <Modal
            className={styles.modal}
            isOpen={isOpen}
            onClose={() => handleClose(onClose)}
            title={trans.TARGETS__DEVICES_MODAL_TITLE()}
            size="small"
            actions={
              <ButtonsWrapper>
                <Button onClick={() => handleClose(onClose)}>
                  {trans.CANCEL()}
                </Button>
                <Button
                  type="green"
                  onClick={() => handleSave(onClose)}
                  disabled={!stagingValue}
                >
                  {trans.SAVE()}
                </Button>
              </ButtonsWrapper>
            }
          >
            <div className={styles.content} data-test-id="devices-modal">
              <div className={styles.leftSection}>
                <Header
                  className={styles.header}
                  uppercase={true}
                  color="secondary"
                  type="h6"
                >
                  {trans.TARGETS__DEVICES_MODAL_CONTENT()}
                </Header>
                <RestrictedArea
                  allowedOrganizationTypes={[
                    ORGANIZATION_TYPES.deviceInventory,
                  ]}
                  showFallback={false}
                >
                  <Radio
                    dataTestId="devices-modal-radio-query"
                    onChange={onChangeMode}
                    label={trans.TARGETS_DEVICE_UNITS_BY_QUERY()}
                    value={mode}
                    defaultValue={DEVICE_TYPE_QUERY}
                    icon="target_granular"
                  />
                </RestrictedArea>
                <Radio
                  dataTestId="devices-modal-radio-pdid"
                  onChange={onChangeMode}
                  label={trans.TARGETS_DEVICE_PDID()}
                  value={mode}
                  defaultValue={DEVICE_TYPE_PDID}
                  icon="target_boarding"
                />
              </div>
              <div className={styles.rightSection}>
                <TargetList
                  mode={mode}
                  selectedItem={stagingValue}
                  onSelectItem={onSelectItem}
                  modalConfig={modalConfig}
                />
              </div>
            </div>
          </Modal>
        );
      }}
    </InputWithModal>
  );
};

TargetDevicesModal.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  modalConfig: PropTypes.object.isRequired,
  dataTestId: PropTypes.string.isRequired,
};
