// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import {
  adaptSortingQueryParams,
  prepareQueryParams,
  retrieveFiltersFromInclude,
} from "utils/url";

export const createExportQueryParams = ({
  view,
  fileFormat,
  includeSensitiveData = false,
  isAppListExclusive = false,
  queryParams,
}) => {
  let adaptedQueryParams = { ...queryParams };
  adaptedQueryParams = prepareQueryParams(adaptedQueryParams);
  adaptedQueryParams = adaptSortingQueryParams(adaptedQueryParams);
  adaptedQueryParams = retrieveFiltersFromInclude(adaptedQueryParams);

  const { search, order, offset, limit, ...filters } = adaptedQueryParams;

  return {
    view: view,
    search: search,
    include_sensitive_data: includeSensitiveData,
    order: order,
    format: fileFormat,
    is_exclusive: isAppListExclusive ? false : undefined,
    ...filters,
  };
};
