// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { Field } from "formik";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { AutocompleteWithInfiniteScroll } from "components/form/elements";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

import { FieldProps } from "./propTypes";

const itemsPerPage = 10;

export const MODE = {
  distribution: "distribution",
  distributionAdmin: "distributionAdmin",
  default: "default",
};

const mapInitialItem = (data) => {
  return {
    value: data.id,
    label: data.name,
    img: data.icon,
  };
};
const mapData = (data) => {
  return data.map((app) => {
    const { id, name, icon } = app;

    return {
      id,
      label: name,
      value: id,
      img: icon,
    };
  });
};

const filteredApps = (data, idsList) =>
  data.filter((app) => !idsList.includes(app.id));

export function ApplicationField(props) {
  const dispatch = useDispatch();

  const getApplication = useCallback(
    (applicationId) => {
      return dispatch(
        apiThatThrows.getApplicationPublicInfo.action({
          params: { id: applicationId },
          // todo: YGG-4047 remove 'nocache' when YGG-4046 ready
          // The 'nocache' query param forces backend to ignore a cache.
          // This is a temporary & hacky solution needed for the 1.2.0 release.
          // It has to be removed in the next release when caching in backend is fixed.
          queryParams: { include: "active_version", nocache: uuid() },
        })
      );
    },
    [dispatch]
  );

  const getApplicationsPaginated = useCallback(
    (queryParams) => {
      switch (props.mode) {
        case MODE.distribution:
          return dispatch(
            apiThatThrows.getMyDistributionAvailableApplicationsPaginated.action(
              {
                queryParams: {
                  ...queryParams,
                  limit: itemsPerPage,
                  include_test_apps: true,
                  without_on_hold_all: true,
                },
              }
            )
          );
        case MODE.distributionAdmin:
          return dispatch(
            apiThatThrows.getDistributionApplicationsPaginated.action({
              queryParams: {
                ...queryParams,
                limit: itemsPerPage,
                include_test_apps: true,
              },
            })
          );
        case MODE.default:
        default:
          return dispatch(
            apiThatThrows.searchApplicationsPaginated.action({
              queryParams: {
                ...queryParams,
                limit: itemsPerPage,
              },
            })
          );
      }
    },
    [dispatch, props.mode]
  );

  const fetchData = async (query) => {
    const data = await getApplicationsPaginated({
      ...query,
    });

    return {
      ...data,
      results: filteredApps(data.results ?? [], props.excludeIDs ?? []),
    };
  };

  const fetchInitialItem = (initialValue) => {
    return getApplication(initialValue);
  };

  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, meta, form: { getFieldHelpers } }) => {
        const { setValue, setTouched } = getFieldHelpers(props.name);
        return (
          <AutocompleteWithInfiniteScroll
            inputPlaceholder={props.inputPlaceholder}
            label={props.label}
            required={props.required}
            {...field}
            {...meta}
            onChange={(e) => {
              // it needs to be this way, otherwise error will be cleaned up
              setValue(e).then(() => setTouched(true));
            }}
            fetchData={fetchData}
            mapData={mapData}
            fetchInitialItem={fetchInitialItem}
            mapInitialItem={mapInitialItem}
            itemsPerPage={itemsPerPage}
            disabled={props.loading || props.isReadonly}
          />
        );
      }}
    </Field>
  );
}

ApplicationField.propTypes = {
  ...FieldProps,
  label: FieldProps.label,
  required: FieldProps.required,
  mode: PropTypes.oneOf([
    MODE.distribution,
    MODE.distributionAdmin,
    MODE.default,
  ]),
  inputPlaceholder: PropTypes.string,
  validate: PropTypes.func,
};

ApplicationField.defaultProps = {
  mode: MODE.default,
  inputPlaceholder: trans.PROMOTION_DETAILS__FIELD_APP_PLACEHOLDER(),
  label: trans.PROMOTION_DETAILS__FIELD_APP(),
  required: true,
};
