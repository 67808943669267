// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Link } from "components/typography";
import { trans } from "src/translations";

import { APP_TYPE, APP_ENVIRONMENT } from "./AppDetails/constants";

//import { ORGANIZATION_TYPES } from "containers/Permissions";

const APP_NAMING_RULES =
  "https://wiki.vewd.com/pages/viewpage.action?spaceKey=TVPLATFORM&title=App+Naming+Rules";

export const APP_TYPE_HTML_DEPRECATED = "html"; // todo: WSRP-228 fix
export const APP_TYPE_SNAP_DEPRECATED = "snap"; // todo: WSRP-228 fix

export const APP_ROUTE_MY = "my";
export const APP_ROUTE_MODERATION = "moderation";
export const APP_ROUTE_DISTRIBUTION = "distribution";
export const APP_ROUTE_AVAILABLE = "available";

export const APP_TYPES = {
  HTML: "html",
  HBBTV: "hbbtv",
  NATIVE: "native",
  SNAP: "snap",
};

export const SUBMISSION_STATUS = {
  DRAFT: {
    id: "DRAFT",
    name: trans.APP_LIST__COLUMN_SUBMISSION_STATUS_DRAFT(),
  },
  WAITING_FOR_REVIEW: {
    id: "WAITING_FOR_REVIEW",
    name: trans.APP_LIST__COLUMN_SUBMISSION_STATUS_WAITING_FOR_REVIEW(),
  },
  IN_REVIEW: {
    id: "IN_REVIEW",
    name: trans.APP_LIST__COLUMN_SUBMISSION_STATUS_IN_REVIEW(),
  },
  REJECTED: {
    id: "REJECTED",
    name: trans.APP_LIST__COLUMN_SUBMISSION_STATUS_REJECTED(),
  },
};

export const DISTRIBUTION_STATUS = {
  READY_FOR_DISTRIBUTION: {
    id: "READY_FOR_DISTRIBUTION",
    name: trans.APP_LIST__COLUMN_DISTRIBUTION_STATUS_READY_FOR_DISTRIBUTION(),
  },
  LIVE: {
    id: "LIVE",
    name: trans.APP_LIST__COLUMN_DISTRIBUTION_STATUS_LIVE(),
  },
  ON_HOLD: {
    id: "ON_HOLD",
    name: trans.APP_LIST__COLUMN_DISTRIBUTION_STATUS_ON_HOLD(),
  },
};

export const APP_INTRO_FORM = {
  NAME: {
    name: "name",
    label: trans.APP_SUBMIT__APPLICATION_NAME(),
    employeeLabel: (
      <>
        {trans.APP_SUBMIT__APPLICATION_NAME()}
        {" - "}
        <Link target="_blank" href={APP_NAMING_RULES}>
          App naming rules
        </Link>
      </>
    ),
    tooltip: trans.APP_SUBMIT__APPLICATION_NAME_TOOLTIP(),
    dataTestId: "name-field",
  },
  IS_TEST: {
    name: "is_test",
    dataTestId: "is-test-field",
  },
  TYPES: {
    name: "type",
    label: trans.APP_SUBMIT__APPLICATION_TYPE(),
    dataTestId: "type-field",
    required: true,
    values: [
      {
        value: APP_TYPES.HTML,
        label: trans.APP_TYPE__HTML(),
        tooltip: trans.APP_SUBMIT__APPLICATION_TYPE_HTML_TOOLTIP(),
        dataTestId: `type-field-${APP_TYPES.HTML}`,
      },
      {
        value: APP_TYPES.HBBTV,
        label: trans.APP_TYPE__HBBTV(),
        tooltip: trans.APP_SUBMIT__APPLICATION_TYPE_HBBTV_TOOLTIP(),
        dataTestId: `type-field-${APP_TYPES.HBBTV}`,
      },
      {
        value: APP_TYPES.NATIVE,
        label: trans.APP_TYPE__NATIVE(),
        dataTestId: `type-field-${APP_TYPES.NATIVE}`,
      },
    ],
  },
};
export const APP_INTRO_FORM_STATE = {
  name: "",
  is_test: false,
  type: APP_TYPES.HTML,
};

const APP_ENVIRONMENTS_CONFIG = {
  generic: {
    type: APP_ENVIRONMENT.GENERIC,
    text: trans.APP_ENVIRONMENT__GENERIC(),
  },
  appStore: {
    type: APP_ENVIRONMENT.APP_STORE,
    text: trans.APP_ENVIRONMENT__APP_STORE(),
  },
  hbbTV: {
    type: APP_ENVIRONMENT.HBBTV,
    text: trans.APP_ENVIRONMENT__HBBTV(),
  },
  linux: {
    type: APP_ENVIRONMENT.LINUX,
    text: trans.APP_ENVIRONMENT__LINUX(),
  },
  android: {
    type: APP_ENVIRONMENT.ANDROID,
    text: trans.APP_ENVIRONMENT__ANDROID(),
  },
};

export const APP_TYPES_CONFIG = {
  [APP_TYPE.HTML]: {
    text: trans.APP_TYPE__HTML(),
    environments: [
      APP_ENVIRONMENTS_CONFIG.generic,
      APP_ENVIRONMENTS_CONFIG.appStore,
    ],
  },
  [APP_TYPE.HBBTV]: {
    text: trans.APP_TYPE__HBBTV(),
    environments: [APP_ENVIRONMENTS_CONFIG.hbbTV],
  },
  [APP_TYPE.NATIVE]: {
    text: trans.APP_TYPE__NATIVE(),
    environments: [
      APP_ENVIRONMENTS_CONFIG.linux,
      APP_ENVIRONMENTS_CONFIG.android,
    ],
  },
};

export const CHANGELOG_TYPES = {
  INITIAL: "INITIAL",
  REWRITE: "REWRITE",
  BUGFIXES: "BUGFIXES",
  NOCODECHANGES: "NOCODECHANGES",
  OTHER: "OTHER",
};

export const UPGRADE_TO_PREMIUM_ORG_MAIL = "cloud-bizdev@vewd.com";

export const DISTRIBUTION_TYPES = {
  VEWD_CATALOGUE: "vewd-catalogue",
  VEWD_CERTIFICATION_PROGRAM: "vewd-certification-program",
  EXAMINE: "examine",
};
