// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const filterTranslations = {
  displayName: {
    date_span: trans.CAMAPIGN_FILTER_NAME__DATE(),
    campaign_type: trans.CAMPAIGN_FILTER_NAME__CREATOR(),
    promotion: trans.CAMPAIGN_FILTER_NAME__PROMOTION(),
    search: trans.CAMPAIGN_FILTER_NAME__SEARCH(),
    device_model: trans.CAMPAIGN_FILTER_NAME__DEVICE_MODEL(),
    device_family: trans.CAMPAIGN_FILTER_NAME__DEVICE_FAMILY(),
    country: trans.CAMPAIGN_FILTER_NAME__COUNTRY(),
  },
  description: {
    date_span: trans.CAMPAIGN_LIST__FILTERS_DATE_SPAN(),
    campaign_type: trans.PROMOTION_LIST__FILTER_MANAGER_DESCRIPTION(),
    promotion: trans.CAMPAIGN_LIST__FILTERS_PROMOTION_NAME(),
    search: trans.CAMPAIGN_LIST__FILTERS_SEARCH(),
    device_model: trans.CAMPAIGN_LIST__FILTERS_DEVICE_MODEL_DESCRIPTION(),
    device_family: trans.CAMPAIGN_LIST__FILTERS_DEVICE_FAMILY_DESCRIPTION(),
    country: trans.CAMPAIGN_LIST__FILTERS_COUNTRY(),
  },
  param: {
    date_span: "YYYY-MM-DD,YYYY-MM-DD",
    campaign_type: `${trans.ADMIN()} / ${trans.MANAGER()}`,
    promotion: trans.NAME(),
    search: trans.NAME(),
    device_model: trans.NAME(),
    device_family: trans.NAME(),
    country: trans.NAME(),
  },
};

export const mapFiltersToTranslations = (filters, forAdmin) => {
  return filters
    .map(({ id, has_suggestions, type }) => {
      /* TODO: remove below condition and add translation
    for public_id filter when it's fixed in YGG-7197 */
      if (id === "public_id") {
        return null;
      }

      const isCampaignType = id === "campaign_type";
      return {
        id,
        has_suggestions,
        type,
        description: filterTranslations.description[id],
        display_name: filterTranslations.displayName[id],
        param: filterTranslations.param[id],
        values:
          isCampaignType && !forAdmin
            ? [
                { query: "admin_managed", display: "Admin" },
                { query: "customer", display: "Manager" },
              ]
            : [],
      };
    })
    .filter((e) => e);
};
