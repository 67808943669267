// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { FileButton } from "components/buttons";

import { FormatPropType } from "./propTypes";
import styles from "./UploaderAWSIdle.scss";

export const UploaderAWSIdle = ({ formats, onUploadFile, uploadHint }) => {
  return (
    <div className={styles.uploaderIdleContainer}>
      <FileButton formats={formats} onChange={onUploadFile} />
      {Boolean(uploadHint) && uploadHint}
    </div>
  );
};

UploaderAWSIdle.propTypes = {
  formats: FormatPropType,
  onUploadFile: PropTypes.func.isRequired,
  uploadHint: PropTypes.element,
};
