// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

const getRatio = (successes, total, threshold) => {
  let ratio = null;
  let isWithinThreshold = false;

  if (total > 0) {
    ratio = Math.round((successes / total) * 100);
    isWithinThreshold = ratio >= threshold;
  }

  return [ratio, isWithinThreshold];
};

export const adaptSteps = (updateJobData) => {
  const { steps, activeStepId, threshold } = updateJobData;
  const activeStepIndex = steps.findIndex((step) => step.id === activeStepId);

  return steps.map((step, stepIndex) => {
    const total = parseInt(step.deviceCount, 10);
    const successes = parseInt(step.successDeviceCount, 10);
    const inProgress = parseInt(step.inProgressDeviceCount, 10);
    const failures = parseInt(step.failureDeviceCount, 10);
    const pendingReboot = parseInt(
      step.postUpdateRebootCounters.rebootPendingCounter,
      10
    );
    const rebootFailures = parseInt(
      step.postUpdateRebootCounters.rebootFailuresCounter,
      10
    );
    const completed = successes + failures;
    const isCompletedDevicesCountAboveThreshold =
      completed >= (total * threshold) / 100;
    const [ratio, isWithinThreshold] = getRatio(successes, total, threshold);

    return {
      stepIndex,
      stepId: step.id,
      status: step.status,
      hasStarted: stepIndex <= activeStepIndex,
      isActive: stepIndex === activeStepIndex,
      total,
      successes,
      inProgress,
      failures,
      pendingReboot,
      rebootFailures,
      isCompletedDevicesCountAboveThreshold,
      ratio,
      isWithinThreshold,
    };
  });
};
