import { useCallback } from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Label } from "components/form";
import { Modal } from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import styles from "./ConfirmCommandModal.scss";
import { FIELDS } from "./constants";

const SECONDS_IN_MINUTE = 60;

const getMinutes = (value) => Math.floor(value / SECONDS_IN_MINUTE);

const getSeconds = (value) => Math.floor(value % SECONDS_IN_MINUTE);

const formatTimeToLive = (timeToLive) => {
  return `${getMinutes(timeToLive)} minutes ${getSeconds(timeToLive)} seconds`;
};

export const ConfirmCommandModal = ({ isOpen, onClose, onSubmit }) => {
  const { values, setSubmitting } = useFormikContext();
  const targetDevice = values[FIELDS.targetDevice.name];
  const timeToLive = values[FIELDS.timeToLive.name];

  const handleCancel = useCallback(() => {
    setSubmitting(false);
    onClose();
  }, [onClose, setSubmitting]);

  const handleSend = useCallback(() => {
    onSubmit(values);
    setSubmitting(false);
    onClose();
  }, [values, onClose, onSubmit, setSubmitting]);

  return (
    <Modal
      actions={
        <ButtonsWrapper>
          <Button type="normal" onClick={handleCancel}>
            {trans.CANCEL()}
          </Button>
          <Button type="green" onClick={handleSend}>
            {trans.SEND()}
          </Button>
        </ButtonsWrapper>
      }
      isOpen={isOpen}
      size="big"
      title={trans.DEVICES__COMMANDS_CREATE_MODAL_TITLE()}
      onClose={handleCancel}
    >
      <span className={styles.timestamp}>
        Checked on: {formatDateTime(Date.now())}
      </span>
      <Label text={trans.DEVICES__COMMANDS_CREATE_MODAL_DEVICE_SELECTED()}>
        <Link
          className={styles.value}
          href={`/devices/inventory/${targetDevice?.id}/details`}
          target="_blank"
        >
          {targetDevice?.name ?? ""}
        </Link>
      </Label>
      <Label text={trans.DEVICES__COMMANDS_CREATE_MODAL_TIME_TO_LIVE()}>
        <span className={styles.value}>{formatTimeToLive(timeToLive)}</span>
      </Label>
      <span className={styles.warning}>
        {trans.DEVICES__COMMANDS_CREATE_MODAL_WARNING()}
      </span>
    </Modal>
  );
};

ConfirmCommandModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
