// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

const OTHER_NAME = "Other";

export const isMonetized = (version) => {
  return version?.monetization?.length > 0;
};

export const getRevenueSubtypes = (version) => {
  if (isMonetized(version)) {
    const monetization = version?.monetization ?? [];
    return monetization.map(({ name }) => name);
  }
  return [];
};

export const getOtherComment = (version) => {
  if (isMonetized(version)) {
    const monetization = version?.monetization ?? [];
    return monetization.find(({ name }) => name === OTHER_NAME);
  }
  return undefined;
};
