// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";
import { connect } from "react-redux";

import { useField } from "formik";
import PropTypes from "prop-types";

import {
  CheckAllButton,
  SelectedCountFooter,
  SearchableList,
  SearchableListPanel,
} from "components/elements";
import { ValidationBadge, Label } from "components/form";
import { Checkbox } from "components/form/elements";
import { InfiniteData } from "containers/InfiniteData";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

import styles from "./SoftwareAssetsField.scss";

const itemsPerPage = 10;

const SoftwareAssetsComponent = ({
  name,
  arrayHelpers,
  disabled = false,
  getAssetsPaginated,
}) => {
  const [field, meta, helpers] = useField(name);

  const [allChecked, setAllChecked] = useState(false);
  const [dataSize, setDataSize] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const check = (item) => arrayHelpers.push(item.id);
  const getCount = () => field.value.length;
  const getIndex = (item) => field.value.indexOf(item.id);
  const isChecked = (item) => field.value.includes(item.id);
  const uncheck = (index) => arrayHelpers.remove(index);

  const toggle = (item) => {
    const index = getIndex(item);
    if (index >= 0) {
      uncheck(index);
      setAllChecked(false);
    } else {
      if (getCount() + 1 === dataSize) {
        setAllChecked(true);
      }
      check(item);
    }
  };

  const onCheckAll = (items) => {
    setAllChecked(!allChecked);
    setLoading(!allChecked && items.length !== dataSize);
    helpers.setValue(!allChecked ? items.map((item) => item.id) : []);
  };

  const onNewDataLoaded = (items, allLoaded) => {
    setDataSize(items.length);
    if (allChecked) {
      if (allLoaded) {
        setLoading(false);
        helpers.setValue(items.map((item) => item.id));
      }
    }
  };

  return (
    <>
      <Label text={trans.PROMOTION_TYPE_EDIT__FIELD_SOFTWARE_ASSETS()} />
      <InfiniteData
        fetchAllData={allChecked}
        itemsPerPage={itemsPerPage}
        fetchData={getAssetsPaginated}
        mapData={(data) => data}
        newDataLoaded={onNewDataLoaded}
      >
        {({
          data,
          pending,
          error,
          allDataLoaded,
          handleScroll,
          handleSearchChange,
        }) => {
          return (
            <SearchableList
              list={data}
              onScroll={handleScroll}
              onSearchChange={handleSearchChange}
              withLoader={true}
              loading={pending || isLoading}
              allDataLoaded={allDataLoaded}
              error={error.message}
              optionsListElement={(visibleItems) => (
                <CheckAllButton
                  fieldName={name + "CheckAll"}
                  onChange={() => onCheckAll(visibleItems)}
                  checked={allChecked}
                  className={styles.checkAllButton}
                  checkboxClassName={styles.checkAllButtonCheckbox}
                  disabled={disabled || isLoading || (pending && allChecked)}
                />
              )}
            >
              {(item) => (
                <SearchableListPanel key={item.id} disabled={disabled}>
                  <Checkbox
                    name={item.id}
                    checked={isChecked(item)}
                    disabled={disabled || (pending && allChecked)}
                    value={isChecked(item)}
                    onChange={() => toggle(item)}
                  >
                    {item.name}
                  </Checkbox>
                </SearchableListPanel>
              )}
            </SearchableList>
          );
        }}
      </InfiniteData>
      <SelectedCountFooter selectedCount={getCount()} />
      <ValidationBadge {...meta} />
    </>
  );
};

SoftwareAssetsComponent.propTypes = {
  name: PropTypes.string.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  getAssetsPaginated: PropTypes.func.isRequired,
};

export const SoftwareAssetsField = connect(null, (dispatch) => ({
  getAssetsPaginated: (queryParams) =>
    dispatch(
      apiThatThrows.getAssetsPaginated.action({
        queryParams: { ...queryParams, limit: itemsPerPage },
      })
    ),
}))(SoftwareAssetsComponent);
