// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextField } from "components/form/fields";
import { Section } from "components/layout";
import { ResetFormOnOrgChange } from "pages/_shared";
import { trans } from "src/translations";
import { createFieldValidator, isEmail } from "utils/validation";

const fieldName = "email";

export class InviteUserForm extends Component {
  static propTypes = {
    submitFunc: PropTypes.func,
  };

  render() {
    const { submitFunc } = this.props;
    const validate = createFieldValidator(fieldName, isEmail);
    return (
      <Formik initialValues={{ email: "" }} onSubmit={submitFunc}>
        {({ dirty, errors, isSubmitting }) => (
          <Form aria-label="form">
            <Section header={trans.USER_INVITE__USER_DETAILS()}>
              <TextField
                name={fieldName}
                label={trans.EMAIL()}
                validate={validate}
                type="email"
                dataTestId={`${fieldName}-field`}
              />
            </Section>
            {errors?._error && <Info type="error">{errors._error}</Info>}
            <ButtonsWrapper position="right">
              <Button
                buttonType="submit"
                type="green"
                processing={isSubmitting}
                disabled={
                  isSubmitting ||
                  !dirty ||
                  Boolean(Object.entries(errors).length)
                }
                dataTestId="submit-button"
              >
                {trans.USER_INVITE__BUTTON()}
              </Button>
            </ButtonsWrapper>
            <ResetFormOnOrgChange />
          </Form>
        )}
      </Formik>
    );
  }
}
