// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { Tooltip } from "components/popups";

import styles from "./RoleDescriptionTooltip.scss";
import { roleDescriptionMap } from "./utils/roleDescriptionMap";

export const RoleDescriptionTooltip = (props) => (
  <Fragment>
    {props.name}{" "}
    {roleDescriptionMap[props.id] && (
      <Tooltip
        className={styles.tooltip}
        content={roleDescriptionMap[props.id]}
      >
        (?)
      </Tooltip>
    )}
  </Fragment>
);

RoleDescriptionTooltip.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
