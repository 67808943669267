// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { VerticalTabs } from "components/layout";
import { apiThatThrows } from "containers/Request";
import { sharedDefaultValues } from "src/configureStore";
import { trans } from "src/translations";
import { capitalize } from "utils/string";
import { getDefaultTranslationIdx } from "utils/translations";

import { DescriptionTab } from "./DescriptionTab";

@connect(null, (dispatch) => ({
  getLanguages: () => dispatch(apiThatThrows.getLanguages.action()),
}))
export class Description extends Component {
  static propTypes = {
    translationFieldName: PropTypes.string.isRequired,
    languages: PropTypes.array,
    isLanguageRequired: PropTypes.bool,
    isTitleRequired: PropTypes.bool,
    isDescriptionRequired: PropTypes.bool,
    // from @Formik
    setFieldValue: PropTypes.func.isRequired,
    getFieldMeta: PropTypes.func.isRequired,
    getFieldProps: PropTypes.func.isRequired,
    // from @connect
    getLanguages: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLanguageRequired: true,
    isTitleRequired: true,
    isDescriptionRequired: true,
  };

  state = {
    loading: true,
    error: null,
    languages: [],
  };

  componentDidMount() {
    this.updateData();
  }

  async updateData() {
    const { getLanguages } = this.props;
    try {
      const { results } = await getLanguages();
      this.setState({
        languages: results,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }

  /**
   * Sends action that creates new translation form group.
   *
   * @param {Event} e
   */

  addTranslation(e, translations) {
    const { setFieldValue, translationFieldName } = this.props;
    e.preventDefault();
    const defaults = {
      default_translation: true,
      language: sharedDefaultValues.defaultLanguageCode,
      name: "",
      short_description: "",
    };
    if (translations.length > 0) {
      defaults.default_translation = false;
      defaults.language = "";
    }
    setFieldValue(translationFieldName, [...translations, defaults]);
  }

  /**
   * Sends action that removes translation form group at a specific index.
   *
   * @param {Number} index
   * @param {Event} e
   */
  removeTranslation(index, e, translations) {
    e.preventDefault();
    const { setFieldValue, translationFieldName } = this.props;

    if (getDefaultTranslationIdx(translations) === index) {
      this.toggleDefaultTranslation(0, translations);
    }
    const newTranslations = [
      ...translations.slice(0, index),
      ...translations.slice(index + 1),
    ];
    setFieldValue(translationFieldName, newTranslations);
  }

  toggleDefaultTranslation(index, translations) {
    const { setFieldValue, translationFieldName } = this.props;
    const newDefaultTranslation = translations.map(
      (translation, transIndex) => ({
        ...translation,
        default_translation: index === transIndex,
      })
    );
    setFieldValue(translationFieldName, newDefaultTranslation);
  }

  getTabsNames() {
    const { getFieldMeta, getFieldProps, translationFieldName } = this.props;
    const { languages } = this.state;
    const { value: translations, name } = getFieldProps(translationFieldName);

    return translations.map((translation, index) => {
      const { error, touched } = getFieldMeta(`${name}[${index}]`);
      const language = languages.filter(
        (language) => language.code === translation.language
      );
      let languageName = trans.UNTITLED();
      if (language?.[0]?.name) {
        languageName = `${capitalize(
          language[0].name
        )} (${language[0].code.toUpperCase()})`;
      }

      return {
        default: translation.default_translation,
        name: languageName,
        invalid: Boolean(Object.keys(error ?? {}).length) && touched,
      };
    });
  }

  isAnyTabUntitled = () =>
    this.getTabsNames().some(({ name }) => name === trans.UNTITLED());

  isAddButtonDisplayed = () => {
    const tabsCount = this.getTabsNames().length;
    const maxTabsCount = this.state.languages.length;
    return this.isAnyTabUntitled() ? false : tabsCount < maxTabsCount;
  };

  render() {
    const {
      translationFieldName,
      getFieldMeta,
      isLanguageRequired,
      isTitleRequired,
      isDescriptionRequired,
    } = this.props;
    const { loading, error, languages } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message}</Info>;
    }
    const { value: translations } = getFieldMeta(translationFieldName);

    return (
      <VerticalTabs
        onAdd={(e) => this.addTranslation(e, translations)}
        onRemove={(index, e) => this.removeTranslation(index, e, translations)}
        tabNames={this.getTabsNames()}
        labelAddNewItem={`+ ${trans.APP_EDIT__NEW_TRANSLATION()}`}
        dataTestId="description"
        isAddButtonDisplayed={this.isAddButtonDisplayed()}
      >
        {(index) => (
          <DescriptionTab
            key={index}
            fieldName={`${translationFieldName}[${index}]`}
            {...translations[index]}
            excluded={translations.map((translation) => translation.language)}
            toggleDefaultTranslation={(index) =>
              this.toggleDefaultTranslation(index, translations)
            }
            languages={languages}
            index={index}
            isLanguageRequired={isLanguageRequired}
            isTitleRequired={isTitleRequired}
            isDescriptionRequired={isDescriptionRequired}
            translationsCount={translations.length}
          />
        )}
      </VerticalTabs>
    );
  }
}
