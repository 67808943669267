// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

const OTHER_NAME = "Other";

const getSpecificTechnicalDetails = (version, dataKey) => {
  return get(version, `technicalDetails.${dataKey}`, []);
};

export const getNames = (version, dataKey) => {
  const specificTechnicalDetails = getSpecificTechnicalDetails(
    version,
    dataKey
  );
  return specificTechnicalDetails.map((f) => f.name);
};

export const getOtherComment = (version, dataKey) => {
  const specificTechnicalDetails = getSpecificTechnicalDetails(
    version,
    dataKey
  );
  const other = specificTechnicalDetails.find(
    ({ name }) => name === OTHER_NAME
  );

  if (!other) {
    return undefined;
  }

  return other.comment;
};
