// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { ProcessingQueueData } from "./ProcessingQueueData";

export class ProcessingQueue extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>{trans.UPDATES__PROCESSING_QUEUE__ROUTE_NAME()}</PageHeader>
        <PageContent>
          <ProcessingQueueData />
        </PageContent>
      </div>
    );
  }
}
