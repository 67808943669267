// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { ColoredText } from "components/elements";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
} from "components/layout";
import { Tooltip } from "components/popups";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import { UpdateJobStepStatus } from "../_shared/UpdateJobStepStatus";
import { STEP_QUANTITY_INFINITY } from "./constants";
import { UpdateJobStepPropType } from "./propTypes";
import styles from "./UpdateJobDetailsStepTable.scss";

export class UpdateJobDetailsStepTable extends PureComponent {
  static propTypes = {
    steps: PropTypes.arrayOf(UpdateJobStepPropType).isRequired,
  };

  renderDeviceCount = ({ successes, total }) => {
    const totalFormatted = total === STEP_QUANTITY_INFINITY ? "∞" : total;

    return (
      <Fragment>
        <ColoredText color="green" dataTestId="devices-successes">
          {successes}
        </ColoredText>{" "}
        {trans.UPDATES__UPDATE_DETAILS__TABLE_UPDATED_DEVICES_CONJUNCTION()}{" "}
        <ColoredText color="black" dataTestId="devices-total">
          {totalFormatted}
        </ColoredText>
      </Fragment>
    );
  };

  renderFailures = ({ failures, hasStarted }) => {
    if (!hasStarted) {
      return NO_VALUE_PLACEHOLDER;
    }

    return (
      <ColoredText color="red" dataTestId="devices-failures">
        {failures}
      </ColoredText>
    );
  };

  renderRebootFailures = ({ rebootFailures, hasStarted }) => {
    if (!hasStarted || isNaN(rebootFailures)) {
      return NO_VALUE_PLACEHOLDER;
    }

    return (
      <ColoredText color="red" dataTestId="devices-reboot-failures">
        {rebootFailures}
      </ColoredText>
    );
  };

  renderInProgress = ({ inProgress, hasStarted }) => {
    if (!hasStarted || inProgress <= 0) {
      return NO_VALUE_PLACEHOLDER;
    }

    return (
      <ColoredText color="black" dataTestId="devices-in_progress">
        {inProgress}
      </ColoredText>
    );
  };

  renderSuccessRatio = ({
    ratio,
    isCompletedDevicesCountAboveThreshold,
    isWithinThreshold,
  }) => {
    if (!ratio) {
      return NO_VALUE_PLACEHOLDER;
    }
    let textColor = "black";

    if (isCompletedDevicesCountAboveThreshold) {
      textColor = isWithinThreshold ? "green" : "red";
    }

    return (
      <ColoredText color={textColor} dataTestId="ratio">
        {ratio}%
      </ColoredText>
    );
  };

  renderPendingReboot = ({ pendingReboot, successes, hasStarted }) => {
    if (!hasStarted || isNaN(pendingReboot)) {
      return NO_VALUE_PLACEHOLDER;
    }

    return (
      <>
        <ColoredText color="black" dataTestId="pendingReboot">
          {pendingReboot}
        </ColoredText>{" "}
        {trans.UPDATES__UPDATE_DETAILS__TABLE_UPDATED_DEVICES_CONJUNCTION()}{" "}
        <ColoredText color="black" dataTestId="devices-total">
          {successes}
        </ColoredText>
      </>
    );
  };

  renderStep = (step) => {
    const stepName = trans.UPDATES__UPDATE_DETAILS__UPDATE_STEP({
      step: String(step.stepIndex + 1),
    });

    return (
      <TableRow key={step.stepId} dataTestId="step-row">
        <TableRowColumn dataTestId="step-column">{stepName}</TableRowColumn>

        <TableRowColumn dataTestId="status-column" className={styles.center}>
          <UpdateJobStepStatus status={step.status} />
        </TableRowColumn>

        <TableRowColumn dataTestId="devices-column" className={styles.center}>
          {this.renderDeviceCount(step)}
        </TableRowColumn>

        <TableRowColumn dataTestId="ratio-column" className={styles.center}>
          {this.renderSuccessRatio(step)}
        </TableRowColumn>

        <TableRowColumn
          dataTestId="in_progress-column"
          className={styles.center}
        >
          {this.renderInProgress(step)}
        </TableRowColumn>

        <TableRowColumn dataTestId="failures-column" className={styles.center}>
          {this.renderFailures(step)}
        </TableRowColumn>

        <TableRowColumn
          dataTestId="reboot-failures-column"
          className={styles.center}
        >
          {this.renderRebootFailures(step)}
        </TableRowColumn>

        <TableRowColumn
          dataTestId="pending-reboot-column"
          className={styles.center}
        >
          {this.renderPendingReboot(step)}
        </TableRowColumn>
      </TableRow>
    );
  };

  render() {
    const { steps } = this.props;

    return (
      <Fragment>
        <Table>
          <TableHeader>
            <TableHeaderColumn dataTestId="step-column">
              {trans.UPDATES__UPDATE_DETAILS__TABLE_HEADER_STEP()}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataTestId="status-column"
              className={styles.center}
            >
              {trans.UPDATES__UPDATE_DETAILS__TABLE_HEADER_STATUS()}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataTestId="devices-column"
              className={styles.center}
            >
              {trans.UPDATES__UPDATE_DETAILS__TABLE_HEADER_QUANTITY()}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataTestId="ratio-column"
              className={styles.center}
            >
              {trans.UPDATES__UPDATE_DETAILS__TABLE_HEADER_RATIO()}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataTestId="in_progress-column"
              className={styles.center}
            >
              {trans.UPDATES__UPDATE_DETAILS__TABLE_HEADER_IN_PROGRESS()}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataTestId="failures-column"
              className={styles.center}
            >
              {trans.UPDATES__UPDATE_DETAILS__TABLE_HEADER_FAILURES()}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataTestId="post-reboot-failures-column"
              className={styles.center}
            >
              <Tooltip
                classNamePopup={styles.tooltipText}
                content={trans.UPDATE_DETAILS__TABLE_POST_REBOOT_FAILURES_TOOLTIP_HEADER()}
                alignment="top"
              >
                {trans.UPDATES__UPDATE_DETAILS__TABLE_HEADER_POST_REBOOT_FAILURES()}
                <span className={styles.tooltipIcon}>(?)</span>
              </Tooltip>
            </TableHeaderColumn>

            <TableHeaderColumn
              dataTestId="reboot-pending-column"
              className={styles.center}
            >
              <Tooltip
                classNamePopup={styles.tooltipText}
                content={trans.UPDATE_DETAILS__TABLE_REBOOT_PENDING_TOOLTIP_HEADER()}
                alignment="top"
              >
                {trans.UPDATES__UPDATE_DETAILS__TABLE_HEADER_REBOOT_PENDING()}
                <span className={styles.tooltipIcon}>(?)</span>
              </Tooltip>
            </TableHeaderColumn>
          </TableHeader>
          <TableBody>{steps.map((step) => this.renderStep(step))}</TableBody>
        </Table>
      </Fragment>
    );
  }
}
