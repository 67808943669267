// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

/** Semantical element to be used with [SubTabs](#subtabs) and [Tabs](#tabs) */
export class TabContent extends Component {
  static propTypes = {
    /** Content */
    children: PropTypes.node,
  };

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default TabContent;
