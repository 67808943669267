// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { RestrictedArea } from "containers/Permissions";
import { trans } from "src/translations";

import { errorPropType } from "./errorPropType";
import { PageContent404 } from "./PageContent404";

/**
 * Display error page based on `Error` object or a `string`. This also handles
 * special error flags related to requests: `error.isNotFound`, `error.isForbidden`.
 *
 * Examples:
 * - ```<PageContentError error="Something went wrong"/>```
 * - ```
 *   const error = new Error("No permissions");
 *   error.isForbidden = true;
 *   <PageContentError error={error}/>
 *   ```
 * - ```
 *   const error = new Error("Something went wrong");
 *   <PageContentError error={error}/>
 *   ```
 */
export const PageContentError = ({ error, defaultMessage }) => {
  const message = get(error, "message", error) || defaultMessage;
  let content = <Info type="error">{message}</Info>;

  if (get(error, "isNotFound", false)) {
    content = <PageContent404 />;
  }

  return (
    <RestrictedArea allowed={[() => !get(error, "isForbidden", false)]}>
      {content}
    </RestrictedArea>
  );
};

PageContentError.propTypes = {
  defaultMessage: PropTypes.string,
  error: errorPropType,
};

PageContentError.defaultProps = {
  defaultMessage: trans.DEFAULT_ERROR_MESSAGE(),
};
