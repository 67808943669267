// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  tableColPropType,
  TableHeader,
  TableHeaderColumn,
  tableLayoutPropType,
  TableNoResults,
} from "components/layout";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./ScrollTable.scss";

/**
 * ScrollTable displays tabular data with possibility to scroll it vertically.
 * It fills whole available vertical space and adds scrollbar if height limit is reached.
 */
export class ScrollTable extends Component {
  static propTypes = {
    /**
     * Function to render each row.
     * Type: (item: T, index: number) => React.Element
     */
    children: PropTypes.func.isRequired,
    className: PropTypes.string,
    /** Array of columns */
    columns: PropTypes.arrayOf(tableColPropType).isRequired,
    /** Data to display. Usually paginated */
    data: PropTypes.shape({
      /** Optional error that happened e.g. during data request */
      error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.string.isRequired,
        }),
      ]),
      /** True if loading more data */
      loading: PropTypes.bool.isRequired,
      /** Items to display */
      results: PropTypes.array.isRequired,
    }).isRequired,
    dataTestId: PropTypes.string,
    /** Table layout - fixed or auto */
    layout: tableLayoutPropType,
    onScroll: PropTypes.func.isRequired,
    selectAllComponent: PropTypes.node,
  };

  static defaultProps = {
    dataTestId: "scrollable-table",
    layout: "auto",
  };

  renderHeader() {
    const {
      columns,
      data: { loading },
      selectAllComponent,
    } = this.props;
    return (
      <TableHeader position="sticky" loading={loading}>
        {columns.map((col) => (
          <TableHeaderColumn
            key={col.id}
            className={col.className}
            dataTestId={col.dataTestId}
          >
            {col.id === "select" && !col.label ? selectAllComponent : col.label}
          </TableHeaderColumn>
        ))}
      </TableHeader>
    );
  }

  renderBody() {
    const {
      children,
      columns,
      data: { results, loading, error },
    } = this.props;
    const errorMessage = error ? error.message : undefined;

    if (errorMessage) {
      return (
        <TableNoResults
          colspan={columns.length}
          message={errorMessage}
          isError={true}
          loading={loading}
        />
      );
    }
    if (results.length === 0) {
      return (
        <TableNoResults
          colspan={columns.length}
          message={trans.TABLE_EMPTY()}
          loading={loading}
        />
      );
    }
    return <TableBody>{results.map(children)}</TableBody>;
  }

  render() {
    const { className, dataTestId, layout, onScroll } = this.props;
    return (
      <div
        className={classes(styles.wrapper, className)}
        data-test-id={dataTestId}
        // Starting with React 17, the onScroll event does not bubble.
        onScrollCapture={onScroll}
      >
        <div className={styles.scroll}>
          <Table className={styles.table} layout={layout}>
            {this.renderHeader()}
            {this.renderBody()}
          </Table>
        </div>
      </div>
    );
  }
}
