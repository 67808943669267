// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { trans } from "src/translations";

import styles from "./list.scss";

export class List extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dataPending: PropTypes.bool,
    dataError: PropTypes.string,
    dataLoaded: PropTypes.bool,
    handleScroll: PropTypes.func,
    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    dataPending: false,
    dataError: "",
    dataLoaded: true,
    handleScroll: () => {},
    isOpen: false,
  };

  render() {
    const {
      children,
      handleScroll,
      dataPending,
      dataError,
      dataLoaded,
      isOpen,
    } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <div className={styles.listWrapper}>
        <div className={styles.list} onScroll={handleScroll}>
          {children}
        </div>
        <div className={styles.listFooter}>
          {dataPending && (
            <Loader size="small" message={`${trans.LOADING()}...`} />
          )}
          {!dataPending && dataLoaded && !dataError && (
            <span>{trans.ALL_DATA_LOADED()}</span>
          )}
          {dataError && <span className={styles.error}>{dataError}</span>}
        </div>
      </div>
    );
  }
}
