// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { API_URLS } from "src/config";

export const REDUX_KEY = "request";

export const HTTP_STATUS = {
  NO_CONTENT_204: 204,
  UNAUTHORIZED_401: 401,
  FORBIDDEN_403: 403,
  NOT_FOUND_404: 404,
  INTERNAL_SERVER_ERROR_500: 500,
};

export const METHOD_NAMES = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
};

// Api that include v1 do not support JSON API
export const API_URLS_NOT_SUPPORTING_JSON_API = [API_URLS.SNAP_API];
