// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextareaField } from "components/form/fields";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";

const jsonPointerToFieldName = {
  "/data/attributes/reject_reason": "reason",
};

const fieldName = "reason";

export const RejectVersion = ({ onRejectMetadata, close }) => {
  const handleSubmit = async (values, { setErrors }) => {
    try {
      await onRejectMetadata(values.reason);
    } catch (error) {
      const submitErrors = prepareErrorsForForm(error, jsonPointerToFieldName);
      setErrors(submitErrors);
    }
  };

  return (
    <Formik
      initialValues={{
        [fieldName]: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ dirty, errors, isSubmitting }) => (
        <Form>
          <TextareaField
            name={fieldName}
            label={trans.APP_DETAILS_VERSION__REJECT_REASON()}
          />
          {errors?._error && <Info type="error">{errors._error}</Info>}
          <ButtonsWrapper>
            <Button onClick={close}>{trans.CANCEL()}</Button>
            <Button
              buttonType="submit"
              processing={isSubmitting}
              disabled={
                isSubmitting || !dirty || Boolean(Object.entries(errors).length)
              }
              type="red"
            >
              {trans.APP_DETAILS_VERSION__REJECT_METADATA_MODAL_REJECT()}
            </Button>
          </ButtonsWrapper>
        </Form>
      )}
    </Formik>
  );
};

RejectVersion.propTypes = {
  close: PropTypes.func.isRequired,
  onRejectMetadata: PropTypes.func.isRequired,
};
