// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Radio, Checkbox } from "components/form/elements";
import { GrabsonIcon } from "components/icons";
import { Modal } from "components/layout";
import { Link } from "components/typography";
import { CONTACT_US_MAIL_AS_LINK } from "src/constants";
import { trans, HtmlTranslation } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";

import styles from "./exportModal.scss";

const FILE_FORMATS = {
  xlsx: "xlsx",
  csv: "csv",
};

const DEFAULT_STATE = {
  fileFormat: FILE_FORMATS.xlsx,
  withoutSensitiveData: true,
  error: undefined,
  exporting: false,
};

@withRouter
export class ExportModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    withSensitiveDataCheckbox: PropTypes.bool.isRequired,
    dataTestId: PropTypes.string,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = DEFAULT_STATE;

  clearState = () => {
    this.setState(DEFAULT_STATE);
  };

  handleClose = () => {
    const { closeModal } = this.props;
    closeModal();
    this.clearState();
  };

  handleExport = async () => {
    try {
      const { onExport, closeModal, navigate, location } = this.props;
      const { fileFormat, withoutSensitiveData } = this.state;
      this.setState({ error: undefined, exporting: true });
      await onExport({
        fileFormat,
        includeSensitiveData: !withoutSensitiveData,
      });
      closeModal();
      navigate(
        {
          ...location,
        },
        {
          state: {
            ...location.state,
            message: trans.TABLE__EXPORT_SUCCESS_MESSAGE(),
          },
        }
      );
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ exporting: false });
    }
  };

  renderModalTitle = () => {
    return (
      <div>
        <GrabsonIcon name="export" size="xl" className={styles.headerIcon} />
        {trans.TABLE__EXPORT_MODAL_HEADER()}
      </div>
    );
  };

  handleFileFormatChange = (ev) => {
    this.setState({ fileFormat: ev.target.value });
  };

  handleTogglewithoutSensitiveData = () => {
    this.setState((state) => ({
      withoutSensitiveData: !state.withoutSensitiveData,
    }));
  };

  render() {
    const { isOpen, withSensitiveDataCheckbox, dataTestId } = this.props;
    const { fileFormat, error, exporting, withoutSensitiveData } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onClose={this.handleClose}
        title={this.renderModalTitle()}
        size="medium"
        dataTestId={dataTestId}
        actions={
          <div className={styles.wrapper}>
            <ButtonsWrapper>
              <Button onClick={this.handleClose}>{trans.CANCEL()}</Button>
              <Button
                type="green"
                onClick={this.handleExport}
                processing={exporting}
                disabled={exporting}
              >
                {trans.TABLE__EXPORT_BUTTON()}
              </Button>
            </ButtonsWrapper>
            {withSensitiveDataCheckbox && (
              <Checkbox
                checked={withoutSensitiveData}
                value={withoutSensitiveData}
                onChange={this.handleTogglewithoutSensitiveData}
                label={trans.TABLE__EXPORT_MODAL_SENSITIVE_DATA_CHECKBOX()}
              />
            )}
          </div>
        }
      >
        <div className={styles.section}>
          <HtmlTranslation
            transFunc={trans.TABLE__EXPORT_MODAL_DESCRIPTION}
            transParams={{
              contactUsLink: (
                <Link href={CONTACT_US_MAIL_AS_LINK}>{trans.CONTACT_US()}</Link>
              ),
            }}
          />
        </div>
        <div className={styles.section}>
          <p className={styles.label}>
            {trans.TABLE__EXPORT_MODAL_FILE_FORMAT_LABEL()}
          </p>
          <Radio
            name="fileFormat"
            label={trans.TABLE__EXPORT_MODAL_FILE_FORMAT_XLSX_LABEL()}
            defaultValue={FILE_FORMATS.xlsx}
            value={fileFormat}
            onChange={this.handleFileFormatChange}
            className={styles.fileFormatButton}
          />
          <Radio
            name="fileFormat"
            label={trans.TABLE__EXPORT_MODAL_FILE_FORMAT_CSV_LABEL()}
            defaultValue={FILE_FORMATS.csv}
            value={fileFormat}
            onChange={this.handleFileFormatChange}
            className={styles.fileFormatButton}
          />
        </div>
        {error && <Info type="error">{error.message || error}</Info>}
      </Modal>
    );
  }
}
