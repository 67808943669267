// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { trans } from "src/translations";

import { DevicePropertiesSection, fromDeviceProperty } from "../components";
import { DevicePropType } from "../propTypes";

const NUMBER_OF_GENERIC_ATTRIBUTES = 10;

export const GENERIC_ATTRIBUTES = [
  ...Array(NUMBER_OF_GENERIC_ATTRIBUTES).keys(),
].map((i) => {
  const j = i + 1;
  return {
    name: trans.DEVICES__DEVICE_DETAILS_GENERIC_ATTRIBUTE({ number: j }),
    dataTestId: `device-generic${j}`,
    valueFn: fromDeviceProperty(`generic${j}`),
  };
});

export function GenericAttributesTab({ device }) {
  return (
    <Fragment>
      <DevicePropertiesSection
        device={device}
        header={trans.DEVICES__DEVICE_DETAILS_TAB_GENERIC_ATTRIBUTES()}
        properties={GENERIC_ATTRIBUTES}
        isSingleColumn={true}
        hiddenIfEmpty={true}
      />
    </Fragment>
  );
}

GenericAttributesTab.propTypes = {
  device: DevicePropType,
};
