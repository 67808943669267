// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { classes } from "utils/classes";

import { PageBanner } from "./PageBanner";
import styles from "./PageContent.scss";

/**
 * Generic page layout that works well with sidebar.
 * Most of pages in our system use this component alog with [PageHeader](#pageheader).
 */
export const PageContent = ({
  children,
  className,
  bannerClassName,
  bannerText,
  bannerIcon,
  bannerTooltipContent,
  dataTestId,
}) => (
  <div
    className={classes(
      bannerText ? styles.containerWithBanner : styles.container,
      className
    )}
    data-test-id={dataTestId}
  >
    {bannerText && (
      <PageBanner
        className={classes(styles.banner, bannerClassName)}
        text={bannerText}
        icon={bannerIcon}
        tooltipContent={bannerTooltipContent}
      />
    )}
    {children}
  </div>
);

PageContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  bannerClassName: PropTypes.string,
  bannerText: PropTypes.string,
  /* GrabsonIcon name */
  bannerIcon: PropTypes.string,
  bannerTooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dataTestId: PropTypes.string,
};

PageContent.defaultProps = {
  dataTestId: "page-content-container",
};
