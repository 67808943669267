// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import {
  Label,
  UploaderAWSField,
  UPLOAD_STATUS,
  AwsUploaderChildrenProps,
} from "components/form";
import { Checkbox } from "components/form/elements";
import { Modal } from "components/layout";
import { trans } from "src/translations";

import {
  IMPORT_ATTRIBUTE_FILE_TYPE_MIME,
  IMPORT_ATTRIBUTE_FILE_TYPE_STRING,
} from "../constants";
import styles from "./ImportManagerAttributesModal.scss";

export class ImportManagerAttributesModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleUpload: PropTypes.func.isRequired,
    handleReplaceCheckboxChange: PropTypes.func.isRequired,
    uploadedFileUrl: PropTypes.string,
    replaceAttributes: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    hasSubmitError: PropTypes.bool.isRequired,
    uploaderStatus: AwsUploaderChildrenProps.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { isOpen, uploaderStatus } = this.props;

    if (prevProps.isOpen !== isOpen) {
      // reset uploader state to clear currently selected file/error status etc.
      uploaderStatus.onCancel();
    }
  }

  render() {
    const {
      isOpen,
      closeModal,
      handleUpload,
      handleReplaceCheckboxChange,
      uploadedFileUrl,
      replaceAttributes,
      isSubmitting,
      hasSubmitError,
      uploaderStatus,
    } = this.props;
    const isUploadingToAWS = uploaderStatus === UPLOAD_STATUS.progress;
    const pristine = uploadedFileUrl == null || uploadedFileUrl.length < 1;

    return (
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={trans.DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_MODAL_TITLE()}
        dataTestId="import-manager-attributes-modal"
        size="medium"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={closeModal}
              dataTestId="close-button"
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={handleUpload}
              dataTestId="continue-button"
              disabled={pristine || isSubmitting || isUploadingToAWS}
              processing={isSubmitting}
            >
              {trans.UPLOAD()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <Label
          text={trans.UPLOADER_AWS_MODAL_EXTERNAL_FILE()}
          className={styles.fileUpload}
        >
          <UploaderAWSField
            uploaderStatus={uploaderStatus}
            formats={IMPORT_ATTRIBUTE_FILE_TYPE_MIME}
            uploadHint={
              <div className={styles.fileTypeHintContainer}>
                <span className={styles.bold}>
                  {IMPORT_ATTRIBUTE_FILE_TYPE_STRING}
                </span>{" "}
                {trans.UPLOADER_AWS_MODAL_FILE_EXTENSION()}
              </div>
            }
          />
        </Label>

        <div className={styles.description}>
          {trans.DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_DESCRIPTION()}
        </div>

        <Checkbox
          onChange={handleReplaceCheckboxChange}
          value={replaceAttributes}
          checked={replaceAttributes}
          name="replace-attributes"
          dataTestId="replace-attributes-checkbox"
          inputClassName={styles.replaceCheckboxInput}
        >
          <p className={styles.replaceCheckboxLabel}>
            {trans.DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_REPLACE_WARNING_A()}{" "}
            <span className={styles.bold}>
              {trans.DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_REPLACE_WARNING_B()}
            </span>
          </p>
        </Checkbox>

        {hasSubmitError && (
          <Info type="error" className={styles.errorMessage}>
            {trans.UPLOADER_AWS_MODAL_ERROR()}
          </Info>
        )}
      </Modal>
    );
  }
}
