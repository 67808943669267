// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";
import { connect } from "react-redux";

import cloneDeep from "lodash-es/cloneDeep";
import PropTypes from "prop-types";

//components
import { Loader } from "components/elements/Loader";
import { PageHeader, PageContent, PageError } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { RestrictedArea } from "containers/Permissions";
import { ALL_PROVIDERS } from "containers/Permissions/groups";
import { api } from "containers/Request";
import { INITIAL_VALUES, MESSAGES } from "pages/Snap/shared/constants";
import { sortLanguages } from "utils/translations";

//SnapEdit
import { adaptDataToRequest, parseInitialSnapData } from "./adaptData";
import { SnapEditForm } from "./SnapEditForm";

const DEFAULT_STATE = {
  application: null,
  error: undefined,
  languages: [],
  revision: null,
  revisionsList: [],
};

@connect(null, (dispatch) => ({
  discardDraftCall: (id) =>
    dispatch(
      api.discardDraft.action({
        params: {
          id,
        },
      })
    ),
  getApplicationCall: (id) =>
    dispatch(api.getSnapApplication.action({ params: { id } })),
  getCloudLanguagesCall: () =>
    dispatch(api.getLanguages.action({ cache: true })),
  getLatestRevisionCall: (id) =>
    dispatch(api.getLatestRevision.action({ params: { id } })),
  getRevisionsListCall: (id) =>
    dispatch(api.getSnapRevisionsList.action({ params: { id } })),
  saveDraftCall: (id, values) =>
    dispatch(
      api.createSnapRevision.action({
        params: { id },
        options: {
          body: values,
        },
      })
    ),
}))
@withActiveOrganization
export class SnapEdit extends PureComponent {
  static propTypes = {
    params: PropTypes.object.isRequired,
    discardDraftCall: PropTypes.func.isRequired,
    getApplicationCall: PropTypes.func,
    getCloudLanguagesCall: PropTypes.func,
    getLatestRevisionCall: PropTypes.func.isRequired,
    getRevisionsListCall: PropTypes.func.isRequired,
    saveDraftCall: PropTypes.func.isRequired,

    // withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,
  };

  state = DEFAULT_STATE;

  componentDidMount() {
    this.fetchSnapData();
  }

  componentDidUpdate(prevProps) {
    const didOrgChange =
      prevProps.activeOrganization.id !== this.props.activeOrganization.id;

    if (didOrgChange) {
      this.fetchSnapData();
    }
  }

  fetchSnapData = async () => {
    const id = this.getAppId();
    try {
      const [application, languages, revisionsList] = await Promise.all([
        this.getApplication(),
        this.getLanguages(),
        this.getRevisionList(),
      ]);
      const revision = revisionsList.length
        ? parseInitialSnapData(revisionsList[0], languages)
        : cloneDeep(INITIAL_VALUES);
      this.setState({
        application,
        languages,
        revisionsList,
        error: false,
        revision: {
          [id]: revision,
        },
      });
    } catch (error) {
      this.setState({
        ...DEFAULT_STATE,
        error: {
          isNotFound: error?.isNotFound,
          isForbidden: error?.isForbidden,
          message: MESSAGES.genericError,
        },
        revision: null,
      });
    }
  };

  getAppId = () => {
    const { params } = this.props;
    return params?.id;
  };

  getApplication = async () => {
    const { error, result } = await this.props.getApplicationCall(
      this.getAppId()
    );
    if (error) {
      throw new Error();
    }
    return result;
  };

  getLanguages = async () => {
    const { error, result } = await this.props.getCloudLanguagesCall();
    if (error || !result.results) {
      throw new Error();
    }
    return sortLanguages(result.results);
  };

  getLatest = async () => {
    const { result, error } = await this.props.getLatestRevisionCall(
      this.getAppId()
    );
    if (result && Object.keys(result).length !== 0) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const revision = parseInitialSnapData(result, this.state.languages);
      this.setState({ revision });
      return revision;
    }
    if (error) {
      throw new Error();
    }
    return {};
  };

  getRevisionList = async () => {
    const { result, error } = await this.props.getRevisionsListCall(
      this.getAppId()
    );
    if (error) {
      throw new Error();
    }
    return result.results;
  };

  discardDraft = async () => {
    try {
      await this.props.discardDraftCall(this.getAppId());
      const response = await this.getRevisionList();
      return { response };
    } catch (error) {
      return { error };
    }
  };

  saveDraft = async (values) => {
    const id = this.getAppId();
    const queryParams = await adaptDataToRequest(values[id]);
    const response = await this.props.saveDraftCall(
      id,
      JSON.stringify(queryParams)
    );
    await this.getRevisionList();
    return response;
  };

  render() {
    const { application, error, revision, revisionsList, languages } =
      this.state;

    if (error) {
      return <PageError error={error} />;
    }

    return (
      <Fragment>
        <PageHeader>
          <span data-test-id="app-name">{application?.name || ""}</span>
        </PageHeader>
        <PageContent>
          <RestrictedArea allowed={ALL_PROVIDERS}>
            {!revision && <Loader />}
            {revision && (
              <SnapEditForm
                name={application.name}
                appId={this.getAppId()}
                discardRevisions={this.discardDraft}
                saveRevision={this.saveDraft}
                languages={languages}
                revision={revision}
                revisions={revisionsList}
                getLatest={this.getLatest}
                storeAppId={application.vewd_cloud_app_id}
              />
            )}
          </RestrictedArea>
        </PageContent>
      </Fragment>
    );
  }
}
