// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea } from "containers/Permissions";
import { ROLES } from "containers/Permissions/roles";
import { withRouter, withSearch, withSorting } from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import { DeviceQueriesRend, ITEMS_PER_PAGE } from "./DeviceQueriesRend";
import { listDeviceFiltersQuery } from "./gql/listDeviceFiltersQuery";
import { tabConfig } from "./tabConfig";

const allowedSortByValues = tabConfig[0].columns
  .map(({ sortBy }) => sortBy)
  .filter((column) => Boolean(column));
const defaultSortBy = "creation_date";

const adaptFilter = ({ creationDate, filtersCount, id, name }) => ({
  id,
  name,
  creationDate,
  filtersCount,
});

@restrictedArea(() => ({
  allowed: [ROLES.deviceInventory.deviceManager],
}))
@withQuery({ name: "deviceFilters", query: listDeviceFiltersQuery })
@withSearch
@withRouter
@withActiveOrganization
@withSorting({
  defaultSortBy,
  allowedSortByValues,
  defaultSortOrder: "DESC",
})
export class DeviceQueriesData extends PureComponent {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withQuery
    deviceFiltersQuery: PropTypes.func,
    deviceFiltersQueryStatus: queryResultType,

    // from @withSorting
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  };

  state = {
    isEditSuccessful: false,
  };

  componentDidMount() {
    this.fetchDeviceFilters();
    this.setState({
      isEditSuccessful: this.checkEditSuccessful(),
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location === prevProps.location &&
      this.props.activeOrganization.id === prevProps.activeOrganization.id
    ) {
      return;
    }

    this.fetchDeviceFilters();
  }

  fetchDeviceFilters = () => {
    const { deviceFiltersQuery, location, searchValue, sortOrder, sortBy } =
      this.props;

    deviceFiltersQuery({
      variables: {
        pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
        sort: {
          name: sortBy.toUpperCase(),
          order: sortOrder,
        },
        search: {
          query: searchValue || "",
        },
      },
    });
  };

  getResults = () => {
    const { data, loading, error } = this.props.deviceFiltersQueryStatus;

    const count = get(data, "deviceFilters.deviceFilters.pagination.count", 0);
    const results = get(data, "deviceFilters.deviceFilters.items", []).map(
      adaptFilter
    );

    return {
      results,
      count,
      loading,
      error: error && getGqlErrorMessage(error),
    };
  };

  checkEditSuccessful = () => {
    const { location, navigate } = this.props;
    if (location.state && location.state.isEditSuccessful) {
      navigate("/devices/filters", {
        replace: true,
        state: { isEditSuccessful: false },
      });
      return true;
    }
    return false;
  };

  render() {
    const { searchValue, changeSearch, sortBy, sortOrder } = this.props;
    return (
      <DeviceQueriesRend
        {...this.getResults()}
        onSearch={changeSearch}
        search={searchValue}
        isEditSuccessful={this.state.isEditSuccessful}
        defaultOrder={{ sortBy, sortOrder }}
      />
    );
  }
}
