// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { restrictedArea, ROLES } from "containers/Permissions";

import { CreateEditOrganizationFormData } from "../_shared";

@restrictedArea((props) => {
  const allowed = [];

  if (props.forAdmin) {
    allowed.push(ROLES.administrator.organizationAdmin);
  } else {
    allowed.push(ROLES.organization.owner);
    allowed.push(ROLES.administrator.organizationAdmin);
  }

  return { allowed };
})
export class EditOrganization extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,
  };

  static defaultProps = {
    forAdmin: false,
  };

  render() {
    const { forAdmin } = this.props;
    return (
      <div>
        <CreateEditOrganizationFormData isEdit={true} forAdmin={forAdmin} />
      </div>
    );
  }
}
