// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { createExportQueryParams } from "components/layout";
import { restrictedArea } from "containers/Permissions";
import { ALL_MODERATORS } from "containers/Permissions/groups";
import { apiThatThrows } from "containers/Request";
import { withQueryParams } from "utils/decorators";

import { APP_ROUTE_MODERATION } from "../constants";
import { appsTabsConfig } from "./ApplicationsTabbedTableConfig";
import { ApplicationsTabbedTableLogic } from "./ApplicationsTabbedTableLogic";

@restrictedArea(() => ({
  allowed: ALL_MODERATORS,
}))
@withQueryParams
@connect(null, (dispatch) => ({
  getAvailableFilters: () =>
    dispatch(apiThatThrows.getAvailableFilters.action({ cache: true })),
  searchApplications: (queryParams) =>
    dispatch(apiThatThrows.searchApplicationsPaginated.action({ queryParams })),
  getSearchSuggestions: (queryParams, abortSignal) =>
    dispatch(
      apiThatThrows.getFiltersSugestions.action({
        queryParams,
        abortSignal,
      })
    ),
  exportModerationApplicationsPaginated: async (queryParams) => {
    await dispatch(
      apiThatThrows.exportModerationApplicationsPaginated.action({
        queryParams,
      })
    );
  },
}))
export class ModerationAppListContent extends Component {
  static propTypes = {
    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @connect
    getAvailableFilters: PropTypes.func.isRequired,
    searchApplications: PropTypes.func.isRequired,
    exportModerationApplicationsPaginated: PropTypes.func.isRequired,
    getSearchSuggestions: PropTypes.func.isRequired,
  };

  onExport = async ({ fileFormat }) => {
    const { exportModerationApplicationsPaginated, queryParams } = this.props;

    const exportQueryParams = createExportQueryParams({
      fileFormat,
      queryParams,
    });

    await exportModerationApplicationsPaginated(exportQueryParams);
  };

  render() {
    const { searchApplications, getAvailableFilters, getSearchSuggestions } =
      this.props;

    return (
      <ApplicationsTabbedTableLogic
        appRouteType={APP_ROUTE_MODERATION}
        tabsConfig={appsTabsConfig}
        getApplicationsPaginated={searchApplications}
        getAvailableFilters={getAvailableFilters}
        getSearchSuggestions={getSearchSuggestions}
        // onExport={this.onExport} // todo: YGG-4296 uncomment when backend ready
      />
    );
  }
}
