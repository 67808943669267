// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { ROLES, RestrictedArea } from "containers/Permissions";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { withRouter, withSearch } from "utils/decorators";

import { CustomPlansTableBody } from "./CustomPlansTableBody";
import { RemoveCustomPlanModal } from "./RemoveCustomPlanModal";

@withRouter
@withSearch
export class CustomPlansRend extends Component {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object.isRequired,

    // props from parent data component
    results: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
  };

  state = {
    planToRemove: null,
  };

  tabsConfig = [
    {
      dataTestId: "custom-plans-list",
      columns: [
        { label: trans.NAME(), dataTestId: "column-header-name" },
        { label: trans.APPS(), dataTestId: "column-header-apps" },
        { label: "", dataTestId: "column-header-action-menu" },
      ],
    },
  ];

  handlePlanRemove = (plan) => {
    this.setState({
      planToRemove: plan,
    });
  };

  tableBody = (data) => {
    return (
      <CustomPlansTableBody data={data} onPlanRemove={this.handlePlanRemove} />
    );
  };

  handleCloseRemovePlanModal = () => {
    this.setState({
      planToRemove: null,
    });
  };

  renderCreateButton = () => {
    return (
      <RestrictedArea
        showFallback={false}
        allowed={[ROLES.distributor.distributionPlanner]}
      >
        <ButtonsWrapper>
          <Button
            type="green"
            to="/distribution/custom-plans/create"
            dataTestId="create-plan-btn"
          >
            {trans.DISTR_CUSTOM_PLANS__CREATE_BUTTON()}
          </Button>
        </ButtonsWrapper>
      </RestrictedArea>
    );
  };

  render() {
    const {
      results,
      count,
      error,
      loading,
      searchValue,
      changeSearch,
      location,
    } = this.props;

    const { planToRemove } = this.state;

    const locationMessage = get(location, "state.message");
    const locationError = get(location, "state.error");

    const isRemovePlanModalOpen = planToRemove !== null;

    return (
      <div>
        <RemoveCustomPlanModal
          isOpen={isRemovePlanModalOpen}
          onClose={this.handleCloseRemovePlanModal}
          plan={planToRemove}
        />
        <Section>
          <SearchBar onSearch={changeSearch} value={searchValue} />
        </Section>
        <Section>
          {locationMessage && <Info>{locationMessage}</Info>}
          {locationError && <Info type="error">{locationError}</Info>}
        </Section>
        {this.renderCreateButton()}
        <Section>
          <TabbedTable
            tabsConfig={this.tabsConfig}
            renderTableBody={this.tableBody}
            data={{ results, count, loading, error }}
          />
        </Section>
        {this.renderCreateButton()}
      </div>
    );
  }
}
