// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { UploaderAWS } from "components/form";

import { FormatPropType } from "../propTypes";
import { TextsPropTypes } from "./propTypes";
import { UploaderAWSModalRend } from "./UploaderAWSModalRend";

const DEFAULT_IDLE_STATE = {
  hasSubmitError: false,
  isSubmitting: false,
  // provided using 'link from web' feature
  // need to store in state so we can disable/enable "continue" button
  externalFileUrl: "",
  // url of file uploaded to AWS S3
  // need to store in state to refresh disable prop on "continue" button
  uploadedFileUrl: "",
  uploadedFileName: "",
};

export class UploaderAWSModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    texts: TextsPropTypes,
    /*
     * Called after the "Upload" button is clicked.
     * Type: ({url: string, name: string}) => Promise<void>
     */
    onUpload: PropTypes.func.isRequired,
    /**
     * See [UploaderAWS](#UploaderAWS) `getCredentials` prop.
     */
    getCredentials: PropTypes.func.isRequired,
    /**
     * See [UploaderAWS](#UploaderAWS) `formats` prop.
     */
    formats: FormatPropType,
  };

  static defaultProps = {
    formats: ["*"],
  };

  state = { ...DEFAULT_IDLE_STATE };

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (prevProps.isOpen !== isOpen) {
      this.setState({ ...DEFAULT_IDLE_STATE });
    }
  }

  onAWSComplete = ({ fileUrl, fileName }) => {
    this.setState({
      uploadedFileUrl: fileUrl,
      uploadedFileName: fileName,
      hasSubmitError: false,
    });
  };

  onAWSCancel = () => {
    // also called when file was uploaded successfully, but user resigned
    this.setState({
      uploadedFileUrl: null,
      hasSubmitError: false,
    });
  };

  onUpload = async (fileToUpload) => {
    try {
      this.setState({
        isSubmitting: true,
        hasSubmitError: false,
      });
      await this.props.onUpload(fileToUpload);
      this.props.onClose();
    } catch (e) {
      this.setState({ hasSubmitError: true });
      throw e; // for sentry
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  onExternalUrlInputChange = (e) => {
    this.setState({
      externalFileUrl: e.target.value || "",
      hasSubmitError: false,
    });
  };

  onResetSubmitError = () => this.setState({ hasSubmitError: false });

  renderModal = (awsUploaderProps) => {
    const { isOpen, onClose, formats, texts } = this.props;
    const {
      hasSubmitError,
      isSubmitting,
      externalFileUrl,
      uploadedFileUrl,
      uploadedFileName,
    } = this.state;

    return (
      <UploaderAWSModalRend
        isOpen={isOpen}
        onClose={onClose}
        onUpload={this.onUpload}
        formats={formats}
        texts={texts}
        uploaderStatus={awsUploaderProps}
        submitting={isSubmitting}
        hasSubmitRequestError={hasSubmitError}
        onResetSubmitError={this.onResetSubmitError}
        onExternalUrlInputChange={this.onExternalUrlInputChange}
        externalFileUrl={externalFileUrl}
        uploadedFileUrl={uploadedFileUrl}
        uploadedFileName={uploadedFileName}
      />
    );
  };

  render() {
    return (
      <UploaderAWS
        getCredentials={this.props.getCredentials}
        onCancel={this.onAWSCancel}
        onComplete={this.onAWSComplete}
      >
        {this.renderModal}
      </UploaderAWS>
    );
  }
}
