// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const MODERATION_STATUS = {
  PENDING: { id: "PENDING", name: trans.PENDING() },
  TESTING: { id: "TESTING", name: trans.TESTING() },
  DONE: { id: "DONE", name: trans.DONE() },
};

export const DEVICE_ACCEPTED = "ACCEPTED";
export const DEVICE_REJECTED = "REJECTED";
export const DEVICE_PENDING = "PENDING";
export const DEVICE_OEM_APPROVED = "OEM_ACCEPTED";
export const DEVICE_OEM_REJECTED = "OEM_REJECTED";

export const DISTRIBUTOR_STATUS_PENDING = "PENDING";
export const DISTRIBUTOR_STATUS_ACCEPTED = "ACCEPTED";
export const DISTRIBUTOR_STATUS_REJECTED = "REJECTED";

export const QA_STATUS_PENDING = "PENDING";
export const QA_STATUS_ACCEPTED = "ACCEPTED";
export const QA_STATUS_REJECTED = "REJECTED";

export const APP_TYPE = {
  HTML: "html",
  HBBTV: "hbbtv",
  NATIVE: "native",
};

export const APP_ENVIRONMENT = {
  GENERIC: "HTML_GENERIC",
  APP_STORE: "HTML_APP_STORE",
  HBBTV: "HBBTV",
  LINUX: "NATIVE_LINUX",
  ANDROID: "NATIVE_ANDROID",
};

export const APP_ENVIRONMENT_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const UPDATE_SCOPE_MINOR = "MINOR";
export const UPDATE_SCOPE_MAJOR = "MAJOR";
export const UPDATE_SCOPE_NONE = "NONE";

export const INVALID_ID = "-1";

export const PRIORITY_P1 = 1;
export const PRIORITY_P2 = 2;
export const PRIORITY_P3 = 3;
export const PRIORITY_P4 = 4;
export const PRIORITY_P5 = 5;

export const UNKNOWN_VALUE = "Unknown";

export const IMG_TYPE_SCREENSHOT = "SCREENSHOT";
export const IMG_TYPE_THUMBNAIL = "THUMBNAIL";
export const IMG_TYPE_BILLBOARD = "BILLBOARD";
export const IMG_TYPE_ICON_BIG = "ICON_BIG";
export const IMG_TYPE_ICON_SMALL = "ICON_SMALL";
