// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { SearchBar } from "../SearchBar";
import { filterShownValues } from "../SearchBarWithFilters/_utils/filterShownValues";
import { sectionPropType } from "./propTypes";
import styles from "./SearchBarWithSuggestions.scss";
import { SearchDropdown } from "./SearchDropdown";

const FIRST_INDEX = 0;

const KEY_CODE_ENTER = 13;
const KEY_CODE_ARROW_DOWN = 40;
const KEY_CODE_ARROW_UP = 38;

/**
 * `<Searchbar>` that shows filtered search suggestions. All suggestions are provided
 * in `suggestionsInSections` prop. Only requirement is that each option contains
 * `suggestion` property. Since suggestions are shown after 1 letter, we usually
 * start suggestions with "/".
 */
export class SearchBarWithSuggestions extends Component {
  static propTypes = {
    /**
     * Called everytime when value in search bar changes. Args: (newValue)
     */
    onChange: PropTypes.func,
    /**
     * Initial value of search bar
     */
    value: PropTypes.string,
    /**
     * Object containing suggestions divided by sections. Sample structure:
     * <pre>
     * [{
     *   title: 'Section 1',
     *   options: [
     *     { suggestion: 'suggestion', ... },
     *     ...
     *   ]
     * }, ...
     * ]
     * </pre>
     */
    suggestionsInSections: PropTypes.arrayOf(sectionPropType),
    /**
     * Every suggestion will be rendered using this react Component.
     * Data is passed via "option" prop.
     */
    optionComponent: PropTypes.func.isRequired,
    /**
     * Called when SearchBar looses or gain focus. Args: (isFocused)
     */
    onFocusChange: PropTypes.func,
    /**
     * Placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Called when enter key is pressed or user clicked on a suggested item.
     */
    onSearch: PropTypes.func,
    /**
     * Optional prop used to override content of dropdown. Can be used to
     * e.g. comunicate information about errors
     */
    dropdownContent: PropTypes.node,
    /**
     * Decide if given `suggestionsInSections` should be additionaly filtered
     * (case insensitive) by current last word. Should be false when e.g.
     * suggestions list is provided from backend.
     */
    filterBeforeDisplay: PropTypes.bool,
    look: PropTypes.oneOf(["for-gray-bg", "for-white-bg"]),
  };

  static defaultProps = {
    look: "for-gray-bg",
    dropdownContent: null,
    filterBeforeDisplay: true,
    value: "",
  };

  state = {
    value: this.props.value,
    isFocused: false,
    focusedIndex: FIRST_INDEX,
    scrollIntoFocusedOption: false,
    /**
     * If allow to switch option using mouseover event. If switching options using
     * arrows, it may happen we scroll the list. This makes the mouse cursor
     * hover on OTHER element and triggers change of currently focusedIndex.
     * This flag depends on the last action that changed focusedIndex. If we detected
     * user used mouse (mousemove was triggered) it is false. Value is true
     * in any other case. Corresponds to flag with same name in react-select lib.
     */
    blockOptionHover: true,
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ value: value });
    }
  }

  getDropdownData() {
    const { suggestionsInSections, filterBeforeDisplay } = this.props;
    if (!suggestionsInSections || suggestionsInSections.length === 0) {
      return null;
    }

    if (!filterBeforeDisplay) {
      return suggestionsInSections;
    }

    return filterShownValues(this.state.value, suggestionsInSections);
  }

  flattenDropdownData(dropDownData) {
    return (dropDownData || []).reduce(
      (all, section) => [...all, ...section.options],
      []
    );
  }

  getCurrentlySuggestedItem() {
    const { focusedIndex } = this.state;
    const dropdownSections = this.getDropdownData();
    const dropDownDataFlat = this.flattenDropdownData(dropdownSections);

    if (dropDownDataFlat.length === 1) {
      return dropDownDataFlat[0];
    }

    return dropDownDataFlat[focusedIndex];
  }

  onSearchBarFocusChange = (isFocused) => {
    const partialState = { isFocused: isFocused };
    if (!isFocused) {
      partialState.focusedIndex = FIRST_INDEX;
    }
    this.setState(partialState);
    if (this.props.onFocusChange) {
      this.props.onFocusChange(isFocused);
    }
  };

  onSearchDropDownOptionClick = (option) => {
    if (!option || typeof option.suggestion !== "string") {
      return;
    }

    const newValue = option.suggestion;
    this.setState({
      value: newValue,
      focusedIndex: 0,
    });

    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
    if (this.props.onSearch) {
      this.props.onSearch(newValue, false);
    }
  };

  onSearchBarKeyDown = (e) => {
    const stopKeyEventPropagation = () => {
      this.setBlockOptionHover(true);
      e.preventDefault();
      e.stopPropagation();
    };

    switch (e.keyCode) {
      case KEY_CODE_ENTER:
        this.onSearchBarKeyEnter();
        e.preventDefault();
        break;
      case KEY_CODE_ARROW_DOWN:
        this.moveDropDownFocus(+1);
        stopKeyEventPropagation();
        break;
      case KEY_CODE_ARROW_UP:
        this.moveDropDownFocus(-1);
        stopKeyEventPropagation();
        break;
    }
  };

  moveDropDownFocus = (direction) => {
    const { focusedIndex } = this.state;

    const dropdownSections = this.getDropdownData();
    const dropdownOptions = this.flattenDropdownData(dropdownSections);
    let newFocusedIndex = focusedIndex + direction;
    newFocusedIndex = Math.min(newFocusedIndex, dropdownOptions.length - 1);
    newFocusedIndex = Math.max(newFocusedIndex, FIRST_INDEX);

    this.setState({
      focusedIndex: newFocusedIndex,
      scrollIntoFocusedOption: true,
    });
  };

  onOptionMouseHover = (newIndex) => {
    if (!this.state.blockOptionHover) {
      this.setState({
        focusedIndex: newIndex,
        scrollIntoFocusedOption: false,
      });
    }
  };

  setBlockOptionHover = (blockMouse) => {
    if (blockMouse !== this.state.blockOptionHover) {
      this.setState({
        blockOptionHover: blockMouse,
      });
    }
  };

  onSearchBarKeyEnter() {
    const { isFocused } = this.state;
    let value = this.state.value;
    let hasSelectedSuggestion = false;

    if (isFocused) {
      const autocompletedItem = this.getCurrentlySuggestedItem();
      const suggestion =
        (autocompletedItem && autocompletedItem.suggestion) || "";

      // check if user selected valid suggestion
      if (suggestion.length > 0) {
        hasSelectedSuggestion = true;

        // use the suggestion to autocomplete
        if (suggestion.length > value.length) {
          value = suggestion;
        }

        this.setState({
          value,
          focusedIndex: 0,
        });

        if (this.props.onChange) {
          this.props.onChange(value);
        }
      }
    }

    if (this.props.onSearch) {
      this.props.onSearch(value, !hasSelectedSuggestion);
    }
  }

  onSearchBarChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    const { value, focusedIndex, scrollIntoFocusedOption, isFocused } =
      this.state;
    const { optionComponent, placeholder, look, dropdownContent } = this.props;

    return (
      <div className={styles.SearchBarWithSuggestions}>
        <SearchBar
          value={value}
          onKeyDown={this.onSearchBarKeyDown}
          onChange={this.onSearchBarChange}
          onFocusChange={this.onSearchBarFocusChange}
          placeholder={placeholder}
          look={look}
        />
        {isFocused && (
          <SearchDropdown
            data={this.getDropdownData()}
            onClick={this.onSearchDropDownOptionClick}
            OptionComponent={optionComponent}
            focusedOptionIndex={focusedIndex}
            handleOptionMouseHover={this.onOptionMouseHover}
            scrollInto={scrollIntoFocusedOption}
            setBlockOptionHover={this.setBlockOptionHover}
            dropdownContent={dropdownContent}
          />
        )}
      </div>
    );
  }
}
