// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./exportButton.scss";
import { ExportModal } from "./ExportModal";

export class ExportButton extends Component {
  static propTypes = {
    /**
     * Handler for export functionality.
     *
     * Type: (fileType: string) => void
     */
    onExport: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    withSensitiveDataCheckbox: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    withSensitiveDataCheckbox: false,
  };

  state = {
    isModalOpen: false,
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  renderTooltip = () => {
    return (
      <div>
        <p>
          <span className={styles.tooltipHeader}>
            {trans.TABLE__EXPORT_BUTTON_TOOLTIP_HEADER()}
          </span>
          {trans.TABLE__EXPORT_BUTTON_TOOLTIP_PARAGRAPH_1()}
        </p>
        <p className={styles.tooltipParagraph}>
          {trans.TABLE__EXPORT_BUTTON_TOOLTIP_PARAGRAPH_2()}
        </p>
      </div>
    );
  };

  render() {
    const { onExport, disabled, withSensitiveDataCheckbox } = this.props;
    const { isModalOpen } = this.state;

    return (
      <Fragment>
        <ExportModal
          isOpen={isModalOpen}
          closeModal={this.closeModal}
          onExport={onExport}
          withSensitiveDataCheckbox={withSensitiveDataCheckbox}
          dataTestId="export-button-modal"
        />
        <Tooltip
          alignment="left"
          content={this.renderTooltip()}
          disabled={disabled}
        >
          <Button
            size="small"
            className={classes(styles.button)}
            onClick={this.openModal}
            disabled={disabled}
            dataTestId="export-button"
          >
            <GrabsonIcon name="export" size="xs" className={styles.icon} />
            {trans.TABLE__EXPORT_BUTTON()}
          </Button>
        </Tooltip>
      </Fragment>
    );
  }
}
