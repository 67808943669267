// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { DatePicker, Label } from "components/form";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { formatDate } from "utils/date";

import styles from "./ToolBar.scss";
import { addUnits, createBeginOfMonthDate } from "./utils";

export class ToolBar extends Component {
  static propTypes = {
    /** Current date */
    date: PropTypes.instanceOf(Date),
    /** Scale value */
    scale: PropTypes.oneOf(["week", "month", "year"]),
    /** Called on scale value change */
    onScaleChange: PropTypes.func,
    /** Called on date change */
    onDateChange: PropTypes.func,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "schedule-chart-toolbar",
  };

  state = { isDatePickerOpen: false };

  handleClick = (e) => {
    this.props.onScaleChange(e.target.value);
  };

  handleDateChange = (date) => {
    this.props.onDateChange(date);
    this.setState({ isDatePickerOpen: false });
  };

  handlePrevious = () => {
    const { scale, date, onDateChange } = this.props;
    onDateChange(addUnits(scale, date, -1));
  };

  handleNext = () => {
    const { scale, date, onDateChange } = this.props;
    onDateChange(addUnits(scale, date, 1));
  };

  handleToday = () => {
    const { scale, onDateChange } = this.props;
    onDateChange(createBeginOfMonthDate(scale, Date.now()));
  };

  handleOpenDatePicker = () => {
    this.setState({ isDatePickerOpen: true });
  };

  handleDatePickerClose = () => {
    this.setState({ isDatePickerOpen: false });
  };

  render() {
    const { scale, date, dataTestId } = this.props;
    const { isDatePickerOpen } = this.state;

    return (
      <div
        className={styles.container}
        data-test-id={`${dataTestId}-container`}
      >
        <div
          className={styles.dateInput}
          data-test-id={`${dataTestId}-date-input`}
        >
          <Label
            text={trans.START_DATE()}
            tooltip={trans.SELECT_START_DATE_TOOLTIP()}
          />
          <p onClick={this.handleOpenDatePicker}>{formatDate(date)}</p>
          <DatePicker
            isOpen={isDatePickerOpen}
            onSelect={this.handleDateChange}
            onClose={this.handleDatePickerClose}
          />
        </div>
        <div
          className={styles.pagination}
          data-test-id={`${dataTestId}-pagination`}
        >
          <button
            type="button"
            onClick={this.handlePrevious}
          >{`< ${trans.PREV()}`}</button>
          <button
            type="button"
            className={styles.today}
            onClick={this.handleToday}
          >
            {trans.TODAY()}
          </button>
          <button
            type="button"
            onClick={this.handleNext}
          >{`${trans.NEXT()} >`}</button>
        </div>
        <div className={styles.scale}>
          <button
            type="button"
            value="week"
            onClick={this.handleClick}
            className={classes({ [styles.active]: scale === "week" })}
          >
            {trans.WEEK()}
          </button>
          <button
            type="button"
            value="month"
            onClick={this.handleClick}
            className={classes({ [styles.active]: scale === "month" })}
          >
            {trans.MONTH()}
          </button>
          <button
            type="button"
            value="year"
            onClick={this.handleClick}
            className={classes({ [styles.active]: scale === "year" })}
          >
            {trans.YEAR()}
          </button>
        </div>
      </div>
    );
  }
}

export default ToolBar;
