// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Section, TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { withRouter, withSearch } from "utils/decorators";
import { isEmpty } from "utils/object";

import { FamilyFormModal } from "../_shared/DeviceFamilyForm/FamilyFormModal";
import { FamilyRow, DeviceRow, InfoRow } from "../_shared/DeviceList";
import { withToggleModel } from "../_shared/DeviceList/withToggleModel";
import { ORGANIZATION_NONE, DEVICE_FAMILY_NONE } from "../constants";

@withRouter
@withSearch
@withToggleModel()
export class MyDevicesRend extends Component {
  static propTypes = {
    familiesData: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    refreshData: PropTypes.func.isRequired,
    forAdmin: PropTypes.bool.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withToggleModel
    toggleModel: PropTypes.func.isRequired,
    toggleModelInProgress: PropTypes.bool.isRequired,
  };

  static tabsConfig = [
    {
      dataTestId: "my-devices-list",
      columns: [{ label: trans.DEVICES__NAME_COLUMN_NAME() }, { label: "" }],
    },
  ];

  state = {
    familiesExpanded: {},
    isModalOpen: false,
    ownerId: ORGANIZATION_NONE,
    deviceFamilyId: DEVICE_FAMILY_NONE,
  };

  handleEditDeviceFamilySuccess = (deviceFamilyId, isEdit) => {
    const { navigate, refreshData } = this.props;

    this.closeEditDeviceFamilyModal();

    const message = isEdit
      ? trans.CHANGES_SAVE_SUCCESS()
      : trans.DEVICES__DEVICE_FAMILY_ADDED();

    // eslint-disable-next-line no-undef
    navigate(_, {
      state: { message: message },
    });

    refreshData();
  };

  onExpandToggle = (id, stateKey) => () => {
    this.setState((prevState) => ({
      [stateKey]: {
        ...prevState[stateKey],
        [id]: !prevState[stateKey][id],
      },
    }));
  };

  handleToggleDeviceModel = async (modelId, enable) => {
    const { toggleModel, refreshData } = this.props;
    return toggleModel(modelId, enable, refreshData);
  };

  handleEditDeviceFamily = (ownerId, deviceFamilyId) => () => {
    this.setState({
      isModalOpen: true,
      ownerId,
      deviceFamilyId,
    });
  };

  closeEditDeviceFamilyModal = () => {
    this.setState({
      isModalOpen: false,
      ownerId: ORGANIZATION_NONE,
      deviceFamilyId: DEVICE_FAMILY_NONE,
    });
  };

  renderBody = (familiesData) => {
    const { toggleModelInProgress, forAdmin } = this.props;
    const { familiesExpanded } = this.state;

    return (
      <TableBody>
        {familiesData.reduce((acc, f) => {
          acc.push(
            <FamilyRow
              key={`family-${f.id}`}
              family={f}
              expanded={familiesExpanded[f.id] || false}
              onEditDeviceFamily={this.handleEditDeviceFamily(f.ownerId, f.id)}
              onExpandToggle={this.onExpandToggle(f.id, "familiesExpanded")}
            />
          );

          if (familiesExpanded[f.id]) {
            if (isEmpty(f.deviceModels)) {
              acc.push(
                <InfoRow key={`no-device-models-${f.id}`} colspan={3}>
                  {trans.DEVICES__NO_DEVICE_MODELS_INFO()}
                </InfoRow>
              );
            }

            f.deviceModels.forEach((d) => {
              acc.push(
                <DeviceRow
                  key={`device-model-${d.id}`}
                  forAdmin={forAdmin}
                  device={d}
                  ownerId={f.ownerId}
                  toggleDeviceModelInProgress={toggleModelInProgress}
                  onToggleDeviceModel={this.handleToggleDeviceModel}
                />
              );
            });
          }

          return acc;
        }, [])}
      </TableBody>
    );
  };

  render() {
    const {
      searchValue,
      changeSearch,
      familiesData,
      count,
      loading,
      error,
      location,
    } = this.props;

    const locationMessage = get(location, "state.message");

    return (
      <div>
        <FamilyFormModal
          isModalOpen={this.state.isModalOpen}
          ownerId={this.state.ownerId}
          deviceFamilyId={this.state.deviceFamilyId}
          onRequestSuccess={this.handleEditDeviceFamilySuccess}
          closeModal={this.closeEditDeviceFamilyModal}
        />
        <Section>
          <SearchBar
            onSearch={changeSearch}
            value={searchValue}
            placeholder={trans.DEVICES__MY_DEVICES_SEARCH_PLACEHOLDER()}
          />
        </Section>
        <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
        <Section>
          <TabbedTable
            tabsConfig={MyDevicesRend.tabsConfig}
            data={{ results: familiesData, count, loading, error }}
            renderTableBody={this.renderBody}
          />
        </Section>
      </div>
    );
  }
}
