// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { TableBody } from "components/layout";

import { CustomPlanRow } from "./CustomPlanRow";

export class CustomPlansTableBody extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onPlanRemove: PropTypes.func.isRequired,
  };

  render() {
    const { data, onPlanRemove } = this.props;

    return (
      <TableBody>
        {data.reduce((acc, plan) => {
          acc.push(
            <CustomPlanRow
              key={`plan-${plan.id}`}
              plan={plan}
              onPlanRemove={onPlanRemove}
            />
          );
          return acc;
        }, [])}
      </TableBody>
    );
  }
}
