// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import styles from "./LineLoader.scss";

export const LineLoader = ({ loading, dataTestId }) => {
  return (
    <div
      className={loading ? styles.loading : styles.notLoading}
      data-test-id={dataTestId}
    />
  );
};

LineLoader.propTypes = {
  /** If true loading animation is displayed. */
  loading: PropTypes.bool,
  dataTestId: PropTypes.string,
};

LineLoader.defaultProps = {
  loading: false,
  dataTestId: "line-loader",
};

export default LineLoader;
