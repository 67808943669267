import { useEffect, useState, useMemo } from "react";

import { uniqWith, isEqual } from "lodash-es";
import queryString from "query-string";

import { useSearchParams } from "utils/hooks";

import { parseSearchParams, createFilterFromSearchString } from "../_utils";
import { AvailableFilters, Filter } from "../_utils/types";
import {
  PAIR_VALUES_SEPARATOR,
  ARRAY_FORMAT,
  ARRAY_FORMAT_SEPARATOR,
} from "../constants";

type TparseSearchParamsToFilters = (
  availableFilters: AvailableFilters,
  searchParam: string,
  isExclusionFilter: boolean
) => { filters: Filter[] };

const parseSearchParamsToFilters: TparseSearchParamsToFilters = (
  availableFilters,
  searchParam = "",
  isExclusionFilter
) =>
  parseSearchParams(
    queryString.parse(searchParam, {
      arrayFormat: ARRAY_FORMAT,
      arrayFormatSeparator: ARRAY_FORMAT_SEPARATOR,
    }),
    availableFilters,
    isExclusionFilter
  );

type UseFilters = (props: {
  availableFilters: AvailableFilters;
  onSearchParamChange?: () => void;
}) => {
  includeFilters: Filter[];
  excludeFilters: Filter[];
  addFilter: (searchValue: string) => boolean;
  removeFilter: (filterIdx: number) => void;
};

export const useFilters: UseFilters = ({
  availableFilters,
  onSearchParamChange,
}) => {
  const searchParams = useSearchParams();
  const getFiltersFromSearchParams = () => {
    const { filters } = parseSearchParamsToFilters(
      availableFilters,
      searchParams?.contains as string,
      false
    );
    const { filters: exclusionFilters } = parseSearchParamsToFilters(
      availableFilters,
      searchParams?.not_contains as string,
      true
    );

    return uniqWith([...filters, ...exclusionFilters], isEqual);
  };

  const [filters, setFilters] = useState<Filter[]>(
    getFiltersFromSearchParams()
  );
  const includeFilters = useMemo(
    () => filters.filter((filter) => !filter.isExclusionFilter),
    [filters]
  );
  const excludeFilters = useMemo(
    () => filters.filter((filter) => filter.isExclusionFilter),
    [filters]
  );

  const addFilter = (searchValue: string) => {
    const filter: Filter | undefined = createFilterFromSearchString(
      searchValue,
      availableFilters
    );
    const isFilterValid = Boolean(
      (filter?.value?.length ?? 0) > 0 &&
        (!filter?.value?.endsWith(PAIR_VALUES_SEPARATOR) ?? false)
    );

    if (!isFilterValid) {
      return false;
    }

    setFilters(uniqWith([...filters, filter as Filter], isEqual));
    return true;
  };

  const removeFilter = (filterIdx: number) => {
    setFilters((filters) => filters.filter((_, idx) => idx !== filterIdx));
  };

  useEffect(() => {
    setFilters(getFiltersFromSearchParams());

    if (onSearchParamChange) {
      onSearchParamChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFilters]);

  return {
    includeFilters,
    excludeFilters,
    addFilter,
    removeFilter,
  };
};
