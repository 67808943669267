// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import partition from "lodash-es/partition";
import PropTypes from "prop-types";

import { SubTab, SubTabs } from "components/navigation";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import {
  getTranslationLanguageName,
  sortTranslations,
} from "utils/translations";
import { addQueryParamsToLocation, getQueryParamValue } from "utils/url";

import { SubsectionWithUpdatedStatus } from "./_shared";
import styles from "./BasicDetails.scss";

const LANGUAGE_URL_PARAM = "lang";

@withRouter
export class BasicDetails extends Component {
  static propTypes = {
    languages: PropTypes.array.isRequired,
    versionData: PropTypes.object.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
  };

  getActiveLanguage() {
    const { location } = this.props;
    return getQueryParamValue(location, LANGUAGE_URL_PARAM);
  }

  createLangSubTabLocation(translation) {
    const { location } = this.props;

    return addQueryParamsToLocation(location, {
      [LANGUAGE_URL_PARAM]: translation.language,
    });
  }

  getSubTabName(translation) {
    const { languages } = this.props;
    let name = getTranslationLanguageName(languages, translation);
    if (translation.is_default) {
      name += ` (${trans.DEFAULT()})`;
    }
    return name;
  }

  sortTranslationsForRender(translations) {
    const { languages } = this.props;
    const [defaultLangs, notDefaultLangs] = partition(
      translations,
      (t) => t.is_default
    );

    return [
      ...sortTranslations(languages, defaultLangs),
      ...sortTranslations(languages, notDefaultLangs),
    ];
  }

  isKeyInLangUpdated =
    (keyInLang, currentTranslation) => (activeVersion, latestVersion) => {
      const lang = currentTranslation.language;

      const activeTransArr = get(activeVersion, "translations", []);
      const activeTrans = activeTransArr.find(({ language }) => {
        return language === lang;
      });

      const latestTransArr = get(latestVersion, "translations", []);
      const latestTrans = latestTransArr.find(({ language }) => {
        return language === lang;
      });

      const activeValue = get(activeTrans, keyInLang);
      const latestValue = get(latestTrans, keyInLang);

      return activeValue !== latestValue;
    };

  render() {
    const {
      versionData: { translations },
    } = this.props;

    const sortedTranslations = this.sortTranslationsForRender(translations);
    const currentLanguage = this.getActiveLanguage();
    let currentTranslation = sortedTranslations.find(
      (t) => t.language === currentLanguage
    );
    currentTranslation = currentTranslation || sortedTranslations[0];

    return (
      <div>
        <SubTabs>
          {sortedTranslations.map((translation) => (
            <SubTab
              key={translation.language}
              to={this.createLangSubTabLocation(translation)}
              text={this.getSubTabName(translation)}
              active={translation.language === currentTranslation.language}
            />
          ))}
        </SubTabs>
        <SubsectionWithUpdatedStatus
          subsectionClassName={styles.descriptionText}
          headerContent={trans.TITLE()}
          isUpdatedFunc={this.isKeyInLangUpdated(
            "app_title",
            currentTranslation
          )}
        >
          <p className={styles.titleText}>{currentTranslation.app_title}</p>
        </SubsectionWithUpdatedStatus>
        <SubsectionWithUpdatedStatus
          subsectionClassName={styles.descriptionText}
          headerContent={trans.DESCRIPTION()}
          isUpdatedFunc={this.isKeyInLangUpdated(
            "app_short_description",
            currentTranslation
          )}
        >
          {currentTranslation.app_short_description}
        </SubsectionWithUpdatedStatus>
      </div>
    );
  }
}
