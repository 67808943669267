// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as actions from "./actionTypes";

const defaultState = {
  collapsed: false,
};

export const menuSwitcher = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SWITCH_MENU:
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    default:
      return state;
  }
};
