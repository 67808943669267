// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { PageContent, PageHeader, Section } from "components/layout";
import { TabContent } from "components/navigation";

import { SoftwarePackageDetailsVersionData } from "./SoftwarePackageDetailsVersionData";
import { SoftwarePackageVersionTabs } from "./SoftwarePackageVersionTabs";

export class SoftwarePackageDetailsRend extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired,
      })
    ),
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    activeVersionId: PropTypes.string.isRequired,
    packageId: PropTypes.string.isRequired,
  };

  render() {
    const { name, loading, error, activeVersionId, packageId, versions } =
      this.props;

    if (loading) {
      return (
        <div>
          <PageHeader>{""}</PageHeader>
          <PageContent>
            <Loader />
          </PageContent>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <PageHeader>{""}</PageHeader>
          <PageContent>
            <Info type={"error"}>{error}</Info>
          </PageContent>
        </div>
      );
    }

    return (
      <div>
        <PageHeader>{name}</PageHeader>
        <PageContent>
          <Section>
            <SoftwarePackageVersionTabs
              activeVersionId={activeVersionId}
              packageId={packageId}
              versions={versions}
            />
          </Section>

          <TabContent>
            <SoftwarePackageDetailsVersionData version={activeVersionId} />
          </TabContent>
        </PageContent>
      </div>
    );
  }
}
