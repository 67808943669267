// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { Tabs, TabContent, Tab } from "components/navigation";
import { ROLES, RestrictedArea } from "containers/Permissions";
import { trans } from "src/translations";

export class LayoutMyOrganization extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div>
        <PageHeader>{trans.ORGANIZATION__MY_PAGE_HEADER()}</PageHeader>
        <PageContent>
          <Tabs>
            <Tab
              to={"/organization/my/details"}
              text={trans.VIEW_DETAILS()}
              dataTestId="details-tab"
            />
            <RestrictedArea
              allowed={[
                ROLES.organization.owner,
                ROLES.administrator.organizationAdmin,
              ]}
              showFallback={false}
            >
              <Tab
                to={"/organization/my/edit"}
                text={trans.EDIT_DETAILS()}
                dataTestId="edit-tab"
              />
            </RestrictedArea>
          </Tabs>
          <TabContent>
            <TabContent>{this.props.children}</TabContent>
          </TabContent>
        </PageContent>
      </div>
    );
  }
}
