// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { MonetizationAndStats } from "./components/MonetizationAndStats";
import { SnapDetails } from "./components/SnapDetails";
import { SnapGraphics } from "./components/SnapGraphics";
import { Translations } from "./components/Translations/Translations";
import styles from "./SnapApplicationDetails.scss";

export class SnapApplicationDetails extends Component {
  static propTypes = {
    appId: PropTypes.string,
    languages: PropTypes.array.isRequired,
    revision: PropTypes.object,
    revisionType: PropTypes.string,
  };

  render() {
    const { appId, languages, revision, revisionType } = this.props;
    return (
      <div className={styles.pageWrapper}>
        <SnapDetails
          revision={revision}
          revisionType={revisionType}
          appId={appId}
        />
        <Translations
          languages={languages}
          revision={revision}
          revisionType={revisionType}
        />
        <SnapGraphics revision={revision} />
        <MonetizationAndStats revision={revision} />
      </div>
    );
  }
}
