// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { api } from "containers/Request";
import { config } from "src/main";

import { adaptToRequest } from "./utils/appInventoryApiAdapter";
import { beforeSave } from "./utils/request";

export const useSubmissionRequests = () => {
  // this will be replaced with useDispatch once react-redux gets bumped.
  // In current version useDispatch is not supported
  const dispatch = config.store.dispatch;

  const validateDraft = (app_id) =>
    dispatch(api.getApplicationDraftValidation.action({ params: { app_id } }));

  const discardDraft = (app_id) =>
    dispatch(api.deleteApplicationDraft.action({ params: { app_id } }));

  const getApplicationData = (appId) =>
    dispatch(
      api.getApplicationFullVersionsList.action({
        params: { app_id: appId },
        queryParams: { ordering: "-created_date" },
      })
    );

  const save = (body) =>
    dispatch(
      api.createApplicationDraft.action({
        options: {
          body: JSON.stringify(body),
        },
      })
    );

  const saveTest = async (values, { id }) => {
    const attributes = adaptToRequest(await beforeSave(values));
    const data = {
      data: {
        type: "TestVersionModel",
        attributes: attributes,
        relationships: {
          application: {
            data: {
              type: "ApplicationModel",
              id,
            },
          },
        },
      },
    };

    return dispatch(
      api.createTestApplication.action({
        options: {
          body: JSON.stringify(data),
        },
      })
    );
  };

  const submit = async (values, { id, type }) => {
    const attributes = adaptToRequest(await beforeSave(values), type);
    const data = {
      data: {
        type: "FullVersionModel",
        attributes: attributes,
        relationships: {
          application: {
            data: {
              type: "ApplicationModel",
              id,
            },
          },
        },
      },
    };

    return dispatch(
      api.submitApplicationFullVersion.action({
        options: {
          body: JSON.stringify(data),
        },
      })
    );
  };

  const saveDraft = async (values, { type, id }) => {
    const processedValues = await beforeSave(values);
    const draftData = adaptToRequest(processedValues, type);

    const body = {
      data: {
        type: "DraftVersionModel",
        attributes: {
          draft: draftData,
        },
        relationships: {
          application: {
            data: {
              type: "ApplicationModel",
              id,
            },
          },
        },
      },
    };
    return save(body);
  };

  return {
    validateDraft,
    discardDraft,
    getApplicationData,
    saveDraft,
    saveTest,
    submit,
  };
};
