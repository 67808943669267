// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getManifestListQuery = gql`
  query manifestList(
    $pagination: PaginationInput!
    $search: FilterSearchInput
    $sort: ManifestListSortInput
  ) {
    manifests(pagination: $pagination, search: $search, sort: $sort) {
      manifests {
        items {
          id
          applicationId
          manifest {
            createdDate
            modifiedDate
          }
        }
        pagination {
          count
        }
      }
    }
  }
`;
