// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import { isEmpty } from "lodash-es";
import PropTypes from "prop-types";
import queryString from "query-string";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Input } from "components/form/elements";
import { GrabsonIcon } from "components/icons";
import { WarningModal, DismissableModal } from "components/layout";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";
import { ensureArray } from "utils/array";
import { CountriesPropType } from "utils/continents";
import { parseGqlError } from "utils/errors";
import { withMutation, mutationResultType } from "utils/graphql";

import { COUNTRY_TYPE_FILTERS } from "../_shared/constants";
import { getDeviceListErrorMessage } from "../_shared/DeviceInventoryTable";
import { createDeviceFilterMutation } from "./gql/createDeviceFilterMutation";
import styles from "./SaveDeviceInventoryFilterModal.scss";
import { adaptQueryParamsToGqlSearchArg } from "./utils";

const adaptFilters = (queryParams, countries) =>
  Object.entries(queryParams)
    .map(([key, values]) =>
      ensureArray(values).map((value) => {
        if (countries.error || countries.loading) {
          return { key, value };
        }
        if (
          COUNTRY_TYPE_FILTERS.find((countryFilter) => key === countryFilter)
        ) {
          const country = countries.allCountries.find(
            (country) => country.code === value
          );

          return {
            key,
            value: country ? country.name : value,
          };
        }
        return { key, value };
      })
    )
    .flat();

const DEFAULT_STATE = {
  value: "",
  nameFieldError: "",
  formError: "",
};

@withMutation({
  name: "createDeviceFilter",
  mutation: createDeviceFilterMutation,
})
export class SaveDeviceInventoryFilterModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    queryParams: PropTypes.object,
    countries: CountriesPropType,

    // from @withMutation
    createDeviceFilterMutation: PropTypes.func,
    createDeviceFilterMutationStatus: mutationResultType,
  };

  state = { ...DEFAULT_STATE };

  onCreate = async () => {
    try {
      const { onSave, onClose, createDeviceFilterMutation, queryParams } =
        this.props;
      const { value } = this.state;

      // if there is an error, it will throw an exception
      // and onClose below will not execute
      await createDeviceFilterMutation({
        variables: {
          input: {
            name: value.trim(),
            search: adaptQueryParamsToGqlSearchArg(queryParams),
          },
        },
      });

      onSave(value);
      onClose();
    } catch (error) {
      const gqlError = parseGqlError(error);
      if (gqlError?.isAlreadyExists) {
        this.setState({
          nameFieldError:
            trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_NAME_EXISTS_ERROR(),
        });
      } else {
        this.setState({ formError: getDeviceListErrorMessage(error) });
      }
    }
  };

  onInputChange = (e) => {
    this.setState({
      value: e.currentTarget.value,
    });
  };

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (prevProps.isOpen !== isOpen) {
      this.setState({ ...DEFAULT_STATE });
    }
  }

  renderFilterString(filter, sign, index) {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index}>
        {Object.keys(filter).map((filterName) => {
          if (Array.isArray(filter[filterName])) {
            return filter[filterName].map((singleValue, i) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={styles.filter}
              >{`${sign}${filterName}:${singleValue}`}</span>
            ));
          }
          return (
            <span
              key={filterName}
              className={styles.filter}
            >{`${sign}${filterName}:${filter[filterName]}`}</span>
          );
        })}
      </div>
    );
  }

  getFilters = () => {
    const {
      queryParams: { search, ...filtersFromQuery },
      countries,
    } = this.props;

    const filters = adaptFilters(filtersFromQuery, countries);
    const included = [];
    const excluded = [];
    filters
      .slice()
      .sort((a, b) => a.key.localeCompare(b.key))
      .forEach(({ key, value }) => {
        const v = queryString.parse(value, {
          arrayFormat: "separator",
          arrayFormatSeparator: "∧",
        });
        if (!isEmpty(v)) {
          if (key === "not_contains") {
            excluded.push(v);
          } else if (key === "contains") {
            included.push(v);
          }
        }
      });

    return { included, excluded };
  };

  renderWarningModalActions = (handleSubmit) => (
    <ButtonsWrapper position="modal">
      <Button
        type="normal"
        onClick={handleSubmit}
        dataTestId="dismiss-ok-button"
      >
        {trans.DEVICES__DEVICE_INVENTORY_MODAL_NO_FILTERS_ACCEPT()}
      </Button>
      <Button
        type="green"
        onClick={this.props.onClose}
        dataTestId="dismiss-dissmis-button"
      >
        {trans.GO_BACK()}
      </Button>
    </ButtonsWrapper>
  );

  renderModal = () => {
    const {
      isOpen,
      onClose,
      queryParams: { search },
      createDeviceFilterMutationStatus: { loading },
    } = this.props;

    const { value, nameFieldError, formError } = this.state;
    const { included, excluded } = this.getFilters();
    const isCreateButtonDisabled = loading || value.trim() === "";

    return (
      <WarningModal
        isOpen={isOpen}
        onClose={onClose}
        title={trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_HEADER()}
        icon={"plus-filled"}
        size={"medium"}
        dataTestId="save-device-filter-modal"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={onClose}
              disabled={loading}
              dataTestId="save-device-filter-cancel-button"
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.onCreate}
              processing={loading}
              disabled={isCreateButtonDisabled}
              dataTestId="save-device-filter-ok-button"
            >
              {trans.CREATE()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <Input
          onChange={this.onInputChange}
          value={value}
          label={trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_PLACEHOLDER()}
          look="simple"
          required
          touched={true}
          error={nameFieldError}
          dataTestId="name-input"
        />

        <div className={styles.spacing}>
          {Boolean(search) && (
            <Fragment>
              <p className={styles.header}>
                {trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_SEARCH_LABEL()}
              </p>
              <div className={styles.container}>
                <Tooltip
                  content={trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_TOOLTIP_SEARCH()}
                  alignment={"top"}
                >
                  <GrabsonIcon
                    name={"ok-filled"}
                    size={"xl"}
                    className={styles.green}
                  />
                </Tooltip>
                <div className={styles.filtersWrapper}>{search}</div>
              </div>
            </Fragment>
          )}

          <p className={styles.header}>
            {trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_INCLUDED_LABEL()}
          </p>
          {included.length > 0 && (
            <div className={styles.container}>
              <Tooltip
                content={trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_TOOLTIP_INCLUDED()}
                alignment={"top"}
              >
                <GrabsonIcon
                  name={"ok-filled"}
                  size={"xl"}
                  className={styles.green}
                />
              </Tooltip>
              <div className={styles.filtersWrapper} data-test-id="filters">
                {included.map((filter, index) => {
                  return this.renderFilterString(filter, "/", index);
                })}
              </div>
            </div>
          )}
          {excluded.length > 0 && (
            <div className={styles.container}>
              <Tooltip
                content={trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_TOOLTIP_EXCLUDED()}
                alignment={"top"}
              >
                <GrabsonIcon
                  name={"close-filled"}
                  size={"xl"}
                  className={styles.red}
                />
              </Tooltip>
              <div className={styles.filtersWrapper} data-test-id="filters">
                {excluded.map((filter, index) => {
                  return this.renderFilterString(filter, "-", index);
                })}
              </div>
            </div>
          )}
          {included.length === 0 && excluded.length === 0 ? (
            <div className={styles.container}>
              <Tooltip
                content={trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_TOOLTIP_INCLUDED()}
                alignment={"top"}
              >
                <GrabsonIcon
                  name={"ok-filled"}
                  size={"xl"}
                  className={styles.green}
                />
              </Tooltip>
              <span className={styles.filter}>
                {trans.DEVICES__DEVICE_INVENTORY_SAVE_MODAL_ALL_FILTERS()}
              </span>
            </div>
          ) : null}
        </div>
        {formError && <Info type="error">{formError}</Info>}
      </WarningModal>
    );
  };

  render() {
    const {
      isOpen,
      onClose,
      queryParams: { contains, not_contains, search },
    } = this.props;
    const areFiltersEmpty = !(contains || not_contains || search);

    return areFiltersEmpty ? (
      <DismissableModal
        isOpen={isOpen}
        title={trans.DEVICES__DEVICE_INVENTORY_MODAL_NO_FILTERS_HEADER()}
        size="medium"
        type="warning"
        onClose={onClose}
        renderSubmitted={this.renderModal}
        renderActions={this.renderWarningModalActions}
        dataTestId="save-query-warning-modal"
      >
        <p>{trans.DEVICES__DEVICE_INVENTORY_MODAL_NO_FILTERS()}</p>
      </DismissableModal>
    ) : (
      this.renderModal()
    );
  }
}
