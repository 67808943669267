// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import {
  PRIORITY_P1,
  PRIORITY_P2,
  PRIORITY_P3,
  PRIORITY_P4,
  PRIORITY_P5,
} from "../../constants";

const PREMIUM_PRIORITIES = [
  { value: PRIORITY_P1, text: "P1" },
  { value: PRIORITY_P2, text: "P2" },
  { value: PRIORITY_P3, text: "P3" },
];

const STANDARD_PRIORITIES = [
  { value: PRIORITY_P4, text: "P4" },
  { value: PRIORITY_P5, text: "P5" },
];

export const getPrioritiesForSupportLevel = (isPremium) =>
  isPremium ? PREMIUM_PRIORITIES : STANDARD_PRIORITIES;
