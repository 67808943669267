// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const CERTIFICATION_STATUS = {
  ACCEPTED: {
    id: "ACCEPTED",
    name: trans.APP_DETAILS_CERTIFICATION__ACCEPTED(),
    color: "green",
  },
  PENDING: {
    id: "PENDING",
    name: trans.APP_DETAILS_CERTIFICATION__PENDING(),
    color: "yellow",
  },
  REJECTED: {
    id: "REJECTED",
    name: trans.APP_DETAILS_CERTIFICATION__REJECTED(),
    color: "red",
  },
};
