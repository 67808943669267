// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Modal } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

import { STATUS_NAMES } from "../_utils";
import styles from "./CampaignActionMenu.scss";

export class CampaignActionMenu extends Component {
  static propTypes = {
    campaign: PropTypes.object,
    status: PropTypes.string,
    onRemoveCampaign: PropTypes.func,
    forAdmin: PropTypes.bool,
    isReadonly: PropTypes.bool,
  };

  state = { isOpen: false };

  handleRemoveCampaign = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleRemoveCampaignConfirm = () => {
    const { onRemoveCampaign, campaign } = this.props;
    onRemoveCampaign(campaign);
    this.setState({ isOpen: false });
  };

  render() {
    const { status, campaign, forAdmin, isReadonly } = this.props;
    const { name, id } = campaign;
    const { isOpen } = this.state;

    return (
      <div>
        {!isOpen && (
          <ActionMenu>
            <MenuItem
              to={`/promotions${forAdmin ? "/admin" : ""}/campaigns/${id}`}
            >
              {isReadonly ? trans.VIEW_DETAILS() : trans.EDIT_DETAILS()}
            </MenuItem>
            <MenuItem onClick={this.handleRemoveCampaign} disabled={isReadonly}>
              {trans.CAMPAIGN_LIST__REMOVE_CAMPAIGN()}
            </MenuItem>
          </ActionMenu>
        )}
        <Modal
          title={trans.CAMPAIGN_LIST__REMOVE_CAMPAIGN_MODAL_TITLE()}
          isOpen={isOpen}
          onClose={this.handleClose}
          actions={
            <ButtonsWrapper>
              <Button onClick={this.handleClose} type="normal">
                {trans.NO()}
              </Button>
              <Button onClick={this.handleRemoveCampaignConfirm} type="green">
                {trans.YES()}
              </Button>
            </ButtonsWrapper>
          }
        >
          <span className={styles.campaignName}>{name}</span>{" "}
          {trans.CAMPAIGN_LIST__REMOVE_CAMPAIGN_MODAL_TEXT({
            status: STATUS_NAMES[status],
          })}
        </Modal>
      </div>
    );
  }
}
