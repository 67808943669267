// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBarWithFilters } from "components/elements";
import { Pill } from "components/elements/Pill";
import { filterSectionPropTypes } from "components/elements/SearchBarWithFilters/propTypes";
import {
  Section,
  TableBody,
  TableRow,
  TableRowColumn,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { ROLES } from "containers/Permissions";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import { ManifestRemoveModal } from "../_shared";
import {
  ITEMS_PER_PAGE,
  TABS_CONFIG,
  CREATE_TAB_PATH,
  EDIT_TAB_PATH,
} from "../constants";
import styles from "./ManifestList.scss";

const getEditPath = (appId) =>
  EDIT_TAB_PATH.replace(":appId", encodeURIComponent(appId));

export const ManifestsListRend = ({
  results = [],
  loading,
  count,
  error,
  onSearch,
  availableFilters,
  userRoles,
  defaultOrder,
  refetchQuery,
}) => {
  const [appId, setAppId] = useState();
  const isAppIntegrator = userRoles.includes(ROLES.moderator.appIntegrator);
  const selectedManifest =
    results.find(({ manifest }) => manifest.appId === appId) ?? {};

  const renderTableBody = (manifests) => {
    return (
      <TableBody>
        {manifests.map(
          ({ id, manifest: { appId, appName, createdDate, modifiedDate } }) => {
            return (
              <TableRow key={id}>
                <TableRowColumn
                  to={getEditPath(appId)}
                  text={appName || appId}
                  rightPillText={!appName ? trans.EXTERNAL() : undefined}
                >
                  <Link to={getEditPath(appId)}>{appName || appId}</Link>
                  {!appName && <Pill text={trans.EXTERNAL()} />}
                </TableRowColumn>
                <TableRowColumn>
                  {formatDateTime(parseISO(String(createdDate)))}
                </TableRowColumn>
                <TableRowColumn>
                  {formatDateTime(parseISO(String(modifiedDate)))}
                </TableRowColumn>
                <TableRowColumn>
                  <ActionMenu alignment="left">
                    <MenuItem to={getEditPath(appId)}>
                      {trans.APP_MANIFESTS_EDIT_MANIFEST()}
                    </MenuItem>
                    <MenuItem
                      onClick={() => setAppId(appId)}
                      disabled={!isAppIntegrator}
                    >
                      {trans.DELETE()}
                    </MenuItem>
                  </ActionMenu>
                </TableRowColumn>
              </TableRow>
            );
          }
        )}
      </TableBody>
    );
  };

  return (
    <>
      <Section>
        <SearchBarWithFilters
          onSearch={onSearch}
          placeholder={trans.APP_MANIFESTS_SEARCH_PLACEHOLDER()}
          filtersDataChoices={availableFilters}
        />
      </Section>
      <ButtonsWrapper>
        <Button
          className={styles.button}
          type="green"
          to={CREATE_TAB_PATH}
          dataTestId="create-manifest-button"
          disabled={!isAppIntegrator}
        >
          {trans.APP_MANIFESTS_CREATE_MANIFEST()}
        </Button>
      </ButtonsWrapper>
      <TabbedTable
        data={{
          loading,
          results,
          count,
          error,
          defaultOrder,
        }}
        noResultsMessage={trans.APP_MANIFESTS_NO_RESULTS()}
        renderTableBody={renderTableBody}
        tabsConfig={TABS_CONFIG}
        rowsPerPage={ITEMS_PER_PAGE}
        layout="fixed"
      />
      <ManifestRemoveModal
        manifestData={selectedManifest}
        isOpen={Boolean(appId)}
        toggleOpen={() => setAppId(undefined)}
        refetchQuery={refetchQuery}
      />
    </>
  );
};

ManifestsListRend.propTypes = {
  onSearch: PropTypes.func.isRequired,
  refetchQuery: PropTypes.object.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      manifest: PropTypes.shape({
        appId: PropTypes.string,
        creationDate: PropTypes.string,
        modifiedDate: PropTypes.string,
      }),
    })
  ),
  count: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  availableFilters: PropTypes.shape({
    sections: PropTypes.arrayOf(filterSectionPropTypes),
  }),
  userRoles: PropTypes.arrayOf(PropTypes.string),
  defaultOrder: PropTypes.shape({
    sortBy: PropTypes.string,
    sortOrder: PropTypes.string,
  }),
};
