// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withRouter } from "utils/decorators/withRouter";
import { withSearch } from "utils/decorators/withSearch";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import { ITEMS_PER_PAGE } from "./constants";
import { listProcessingQueueQuery } from "./gql";
import { ProcessingQueueRend } from "./ProcessingQueueRend";
import { adaptProcessingItem } from "./utils/adaptProcessingItem";

@restrictedArea(() => ({
  allowed: [ROLES.deviceInventory.deviceManager],
}))
@withQuery({ name: "processingQueue", query: listProcessingQueueQuery })
@withSearch
@withRouter
@withActiveOrganization
export class ProcessingQueueData extends PureComponent {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,

    // from @withQuery
    processingQueueQuery: PropTypes.func,
    processingQueueQueryStatus: queryResultType,
  };

  componentDidMount() {
    this.fetchProcessingQueue();
  }

  componentDidUpdate(prevProps) {
    const { location, activeOrganization } = this.props;

    const changedLocation = location !== prevProps.location;
    const changedOrganization =
      activeOrganization.id !== prevProps.activeOrganization.id;
    if (changedLocation || changedOrganization) {
      this.fetchProcessingQueue();
    }
  }

  fetchProcessingQueue = () => {
    const { processingQueueQuery, location, searchValue } = this.props;

    processingQueueQuery({
      variables: {
        pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
        search: {
          query: searchValue || "",
        },
      },
    });
  };

  getResults = () => {
    const { data, loading, error } = this.props.processingQueueQueryStatus;

    const key = "processingDeviceAttributes.processingDeviceAttributes";
    const count = get(data, `${key}.pagination.count`, 0);
    const results = get(data, `${key}.items`, []);

    return {
      results: results.map(adaptProcessingItem),
      count,
      loading,
      error: error && getGqlErrorMessage(error),
    };
  };

  render() {
    return (
      <ProcessingQueueRend
        {...this.getResults()}
        onSearch={this.props.changeSearch}
        searchValue={this.props.searchValue}
      />
    );
  }
}
