// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Avatar } from "components/images";
import { classes } from "utils/classes";

import styles from "./Item.scss";

export class Item extends Component {
  static propTypes = {
    organizationImg: PropTypes.string,
    userImg: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    showArrow: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    showArrow: false,
  };

  render() {
    const { organizationImg, userImg, text, showArrow, className } = this.props;

    return (
      <div className={classes(styles.container, className)}>
        <div className={styles.avatars}>
          <Avatar size="24px" src={organizationImg} className={styles.avatar} />
          <Avatar size="24px" src={userImg} className={styles.avatar} />
        </div>
        <div>
          <span className={styles.name}>{text}</span>
          {showArrow && (
            <GrabsonIcon
              name="arrow-down"
              font="micro"
              size="xs"
              className={styles.arrowIcon}
            />
          )}
        </div>
      </div>
    );
  }
}
