// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as actions from "./actionTypes";

const defaultState = {
  isActive: false,
  asset: {
    id: "",
    name: "",
    description: "",
    defaultVersion: "",
    organization: "",
    versions: [],
  },
  versionNumber: "",
};

export const addAssetDevice = (state = defaultState, action) => {
  switch (action.type) {
    case actions.ADD_ASSET_DEVICE_ACTIVE:
      return {
        ...state,
        isActive: true,
        asset: action.asset,
        versionNumber: action.versionNumber,
      };
    case actions.ADD_ASSET_DEVICE_INACTIVE:
      return {
        ...state,
        isActive: false,
      };
    default:
      return state;
  }
};
