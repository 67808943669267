// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { FieldArray } from "formik";
import { uniqBy } from "lodash-es";
import PropTypes from "prop-types";

import {
  CheckAllButton,
  SelectedCountFooter,
  SearchableListPanel,
  SearchableList,
} from "components/elements";
import { ValidationBadge } from "components/form";
import { Checkbox } from "components/form/elements";
import { Avatar } from "components/images";
import { InfiniteData } from "containers/InfiniteData/InfiniteData";
import { apiThatThrows } from "src/containers/Request/api";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./appsField.scss";

const ITEMS_PER_PAGE = 10;
const FIELD_NAME = "applications";

@connect(null, (dispatch, ownProps) => ({
  getDistributionApplicationsPaginated: (queryParams) => {
    const { forAdmin, devicesDistributorId } = ownProps;
    if (forAdmin) {
      return dispatch(
        apiThatThrows.getDistributionApplicationsPaginated.action({
          queryParams: {
            ...queryParams,
            distributor: devicesDistributorId,
          },
        })
      );
    }

    return dispatch(
      apiThatThrows.getMyDistributionApplicationsPaginated.action({
        queryParams: {
          ...queryParams,
        },
      })
    );
  },
}))
export class AppsField extends Component {
  static propTypes = {
    dataTestId: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    forAdmin: PropTypes.bool,
    devicesDistributorId: PropTypes.string,

    // from @connect
    getDistributionApplicationsPaginated: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dataTestId: "apps-field",
    forAdmin: false,
  };

  toggleApplication(selectedApps, id, arrayHelpers) {
    const idx = selectedApps.findIndex((appId) => appId === id);
    if (idx !== -1) {
      arrayHelpers.remove(idx);
    } else {
      arrayHelpers.push(id);
    }
  }

  isEveryAppChecked(selectedApps, availableApps) {
    return availableApps.every(
      (app) =>
        Boolean(selectedApps.find((appId) => appId === app.id)) ||
        this.isAppDisabled(app)
    );
  }

  isCheckAllButtonDisabled(visibleApps, pending, allDataLoaded) {
    return (
      !visibleApps.some((app) => !this.isAppDisabled(app)) ||
      pending ||
      !allDataLoaded
    );
  }

  isAppDisabled = (app) => app.isRejected || app.isOnHold;

  getAppStatusText(app) {
    if (app.isRejected) {
      return (
        <span className={styles.notAvailableText}>
          {trans.DISTR_PLAN__FIELD_APP_REJECTED()}
        </span>
      );
    }
    if (app.isOnHold) {
      return (
        <span className={styles.notAvailableText}>
          {trans.DISTR_PLAN__FIELD_APP_ON_HOLD_A()}{" "}
          <span className={styles.notAvailableTextOnHold}>
            {trans.DISTR_PLAN__FIELD_APP_ON_HOLD_B()}
          </span>{" "}
          {trans.DISTR_PLAN__FIELD_APP_ON_HOLD_C()}
        </span>
      );
    }
    return null;
  }

  getSelectedCountText = (count) => {
    return count === 1
      ? trans.DISTR_PLAN__APPS_SELECTED_ONE()
      : trans.DISTR_PLAN__APPS_SELECTED_MANY();
  };

  renderRow({ selectedApps, app, arrayHelpers, callSetTouched }) {
    const { dataTestId } = this.props;
    const { id, iconSmallUrl, name } = app;
    const disabledStatusText = this.getAppStatusText(app);

    return (
      <SearchableListPanel
        key={id}
        className={classes(styles.item, {
          [styles.notAvailable]: Boolean(disabledStatusText),
        })}
      >
        <Checkbox
          onChange={() => {
            this.toggleApplication(selectedApps, id, arrayHelpers);
            callSetTouched();
          }}
          checked={Boolean(selectedApps.find((appId) => appId === id))}
          value={id}
          disabled={Boolean(disabledStatusText)}
        />
        <Avatar
          src={iconSmallUrl}
          size="32px"
          round={false}
          className={styles.appAvatar}
        />
        <div data-test-id={`${dataTestId}-name`}>
          {name}
          {disabledStatusText}
        </div>
      </SearchableListPanel>
    );
  }

  checkAllToggle({ canCheckAll, visibleApps, callSetTouched, setValue }) {
    const value = canCheckAll
      ? visibleApps
          .filter((app) => !this.isAppDisabled(app))
          .map((app) => app.id)
      : [];

    setValue(value);
    callSetTouched();
  }

  renderField = ({
    form: { getFieldMeta, setTouched, getFieldHelpers },
    name,
    ...arrayHelpers
  }) => {
    const { devicesDistributorId, dataTestId } = this.props;
    const { value: selectedApps, touched, error } = getFieldMeta(name);
    const { setValue } = getFieldHelpers(name);
    const callSetTouched = () => setTouched({ [name]: true });

    return (
      <div>
        <InfiniteData
          fetchData={(queryParams) => this.fetchData(queryParams, selectedApps)}
          mapData={this.mapData}
          itemsPerPage={ITEMS_PER_PAGE}
          key={devicesDistributorId}
        >
          {({
            data,
            pending,
            error,
            allDataLoaded,
            handleScroll,
            handleSearchChange,
          }) => (
            <SearchableList
              dataTestId={dataTestId}
              list={data || []}
              loading={pending}
              onScroll={handleScroll}
              onSearchChange={handleSearchChange}
              withLoader={true}
              allDataLoaded={allDataLoaded}
              error={error && error.message}
              optionsListElement={(visibleApps) => (
                <CheckAllButton
                  fieldName={FIELD_NAME}
                  onChange={(canCheckAll) =>
                    this.checkAllToggle({
                      canCheckAll,
                      visibleApps,
                      callSetTouched,
                      setValue,
                    })
                  }
                  checked={this.isEveryAppChecked(selectedApps, visibleApps)}
                  disabled={this.isCheckAllButtonDisabled(
                    visibleApps,
                    pending,
                    allDataLoaded
                  )}
                  className={styles.checkAllButton}
                  checkboxClassName={styles.checkAllButtonCheckbox}
                />
              )}
            >
              {(app) =>
                this.renderRow({
                  selectedApps,
                  app,
                  arrayHelpers,
                  callSetTouched,
                })
              }
            </SearchableList>
          )}
        </InfiniteData>
        <SelectedCountFooter
          selectedCount={selectedApps.length}
          selectedCountText={this.getSelectedCountText}
        />
        <ValidationBadge error={error} touched={touched} />
      </div>
    );
  };

  fetchData = async (queryParams, selectedAppsIds = []) => {
    const { getDistributionApplicationsPaginated } = this.props;

    if (queryParams.page !== 1 || selectedAppsIds.length === 0) {
      return await this.getNotSelectedApps(
        { ...queryParams, limit: ITEMS_PER_PAGE },
        selectedAppsIds
      );
    }

    const [selectedApps, allApps] = await Promise.all([
      getDistributionApplicationsPaginated({
        ...queryParams,
        id: selectedAppsIds,
      }),
      getDistributionApplicationsPaginated({
        ...queryParams,
        limit: ITEMS_PER_PAGE,
      }),
    ]);

    return {
      results: uniqBy([...selectedApps.results, ...allApps.results], "id"),
      meta: allApps.meta,
    };
  };

  getNotSelectedApps = async (queryParams, selectedAppsIds) => {
    const { getDistributionApplicationsPaginated } = this.props;
    const { results, meta } =
      await getDistributionApplicationsPaginated(queryParams);
    const clearResults = this.removeSelectedApps(results, selectedAppsIds);

    return { results: clearResults, meta };
  };

  removeSelectedApps = (apps, selectedAppsIds) =>
    apps.filter((app) => app.id && !selectedAppsIds.includes(app.id));

  mapData = (data) => {
    return data.map((item) => ({
      id: item.id,
      name: item.name,
      iconSmallUrl: item.icon,
      isRejected: item?.distributors_extras[0]?.is_rejected || false,
      isOnHold: item?.distributors_extras[0]?.is_on_hold || false,
    }));
  };

  render() {
    return <FieldArray name={FIELD_NAME} component={this.renderField} />;
  }
}
