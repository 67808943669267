// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableNoResults,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "components/layout";
import { Tooltip, ActionMenu, MenuItem } from "components/popups";
import { Link } from "src/components/typography/Link/Link";
import { trans } from "src/translations";

import { DevicePackagePropType } from "../propTypes";
import styles from "./PackagesTable.scss";

const MAX_VISIBLE_NAME_LENGTH = 35;

const renderRow = (name, version, packageVersionLink) => (
  <TableRow key={name}>
    <TableRowColumn className={styles.strong} dataTestId="package-name-column">
      <Tooltip
        content={name}
        classNamePopup={styles.tooltip}
        alignment="bottom-start"
        disabled={name.length < MAX_VISIBLE_NAME_LENGTH}
      >
        {packageVersionLink ? (
          <Link to={packageVersionLink}>{name}</Link>
        ) : (
          <div className={styles.name}>{name}</div>
        )}
      </Tooltip>
    </TableRowColumn>
    <TableRowColumn
      colspan={packageVersionLink ? 1 : 2}
      dataTestId="package-version-column"
    >
      {packageVersionLink ? (
        <Link to={packageVersionLink}>{version}</Link>
      ) : (
        version
      )}
    </TableRowColumn>

    {packageVersionLink && (
      <TableRowColumn
        dataTestId="action-menu-column"
        className={styles.cellWithActionMenu}
      >
        <ActionMenu>
          <MenuItem>
            <Link to={packageVersionLink}>
              {trans.DEVICES__DEVICE_DETAILS_ACTION_MENU_PACKAGE_VERSION()}
            </Link>
          </MenuItem>
        </ActionMenu>
      </TableRowColumn>
    )}
  </TableRow>
);

export const PackagesTable = ({ packages }) => {
  return (
    <div className={styles.scrollableTableContainer}>
      <Table layout="fixed" className={styles.table}>
        <TableHeader position="sticky">
          <TableHeaderColumn dataTestId="name-column-header">
            {trans.DEVICES__DEVICE_DETAILS_TABLE_HEADER_PRODUCT()}
          </TableHeaderColumn>
          <TableHeaderColumn colspan={2} dataTestId="version-column-header">
            {trans.DEVICES__DEVICE_DETAILS_TABLE_HEADER_PACKAGE_VERSION()}
          </TableHeaderColumn>
        </TableHeader>

        {!packages ||
          (packages.length === 0 && (
            <TableNoResults
              colspan={3}
              message={trans.DEVICES__DEVICE_DETAILS_TABLE_NO_INSTALLED_PACKAGES()}
            />
          ))}

        {packages.length > 0 && (
          <TableBody>
            {packages.map(({ name, version, id, versionId }) => {
              const isPackageAvailable = id && versionId;
              const packageVersionLink = isPackageAvailable
                ? `/updates/packages/${id}/version/${versionId}`
                : undefined;

              return renderRow(name, version, packageVersionLink);
            })}
          </TableBody>
        )}
      </Table>
    </div>
  );
};

PackagesTable.propTypes = {
  packages: PropTypes.arrayOf(DevicePackagePropType).isRequired,
};
