// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";
import { pipeValidators, isRequired } from "utils/validation";

import { Media } from "../../../components";

export const mediaField = function* (field) {
  for (const subField of [
    "id",
    "name",
    "loaded",
    "total",
    "status",
    "url",
    "kind",
    "error",
    "preview",
  ]) {
    yield `${field}.${subField}`;
  }
};

export default {
  name: trans.APP_EDIT__MEDIA_SECTION_NAME(),
  components: [
    {
      component: Media,
      fields: [
        "id",
        ...mediaField("metadata.icon_small"),
        ...mediaField("metadata.icon_big"),
        ...mediaField("metadata.thumbnail"),
        ...mediaField("metadata.billboard"),
        ...mediaField("metadata.screenshot[]"),
      ],
      validate: pipeValidators(
        isRequired("metadata.icon_small.url"),
        isRequired("metadata.icon_big.url"),
        isRequired("metadata.thumbnail.url"),
        isRequired("metadata.screenshot[0].url")
      ),
      props: ({ appData, formikProps }) => {
        return {
          appId: appData.id,
          ...formikProps,
        };
      },
    },
  ],
};
