// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "utils/decorators/withRouter";

import { PreviousLocationProvider } from "./context";

@withRouter
export class PreviousLocation extends Component {
  static propTypes = {
    children: PropTypes.node,
    // from @withRouter
    location: PropTypes.object.isRequired,
  };

  state = { previousLocation: null };

  componentDidUpdate = (prevProps) => {
    if (prevProps.location !== this.props.location) {
      this.setState({ previousLocation: prevProps.location });
    }
  };

  render() {
    const { children } = this.props;
    return (
      <PreviousLocationProvider
        value={{
          previousLocation: this.state.previousLocation,
        }}
      >
        {children}
      </PreviousLocationProvider>
    );
  }
}
