import { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import queryString from "query-string";

import { SubTab, SubTabs } from "components/navigation";

import { FIELDS } from "./constants";
import { ModeContext } from "./context";
import { configurationType } from "./utils";

export const PromotionDetailsAppTabs = ({
  configuration,
  currentTab,
  openTab,
  promotionDetails,
}) => {
  const { isReadonly } = useContext(ModeContext);
  const { values, setFieldValue } = useFormikContext();
  const { pathname, search } = useLocation();

  const subTabs = (values?.apps ?? []).map(({ order }, idx) => ({
    key: idx,
    text: promotionDetails?.[order]?.application?.name ?? `App ${idx + 1}`,
  }));

  const searchObj = queryString.parse(search);
  const activeTabIndex = Number(searchObj.tab) || 0;

  const handleDragDrop = ({ destination, source }) => {
    const destinationIndex = destination?.index;

    if (destinationIndex === undefined) {
      return;
    }

    const sourceIndex = source?.index;
    const newApps = [...values.apps];
    const currentApp = newApps[currentTab];
    const [moved] = newApps.splice(sourceIndex, 1);

    newApps.splice(destinationIndex, 0, moved);
    openTab(newApps.findIndex((app) => app === currentApp));

    setFieldValue(FIELDS.PROMO_APPS.name, newApps);
  };

  return configuration.appsCount > 0 ? (
    <DragDropContext onDragEnd={handleDragDrop}>
      <Droppable
        droppableId="promotions-apps-tabs-droppable"
        direction="horizontal"
      >
        {(provided) => (
          <SubTabs innerRef={provided.innerRef}>
            {subTabs.map((tab) => {
              searchObj.tab = tab.key;
              const to = `${pathname}?${queryString.stringify(searchObj)}`;
              return (
                <Draggable
                  isDragDisabled={isReadonly}
                  key={tab.key}
                  draggableId={tab.key.toString()}
                  index={tab.key}
                >
                  {(provided) => (
                    <SubTab
                      icon={isReadonly ? undefined : "dragdrop2"}
                      active={tab.key === activeTabIndex}
                      to={to}
                      text={tab.text}
                      innerRef={provided.innerRef}
                      draggableProps={provided.draggableProps}
                      dragHandleProps={provided.dragHandleProps}
                    />
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </SubTabs>
        )}
      </Droppable>
    </DragDropContext>
  ) : null;
};

PromotionDetailsAppTabs.propTypes = {
  configuration: configurationType.isRequired,
  currentTab: PropTypes.number.isRequired,
  openTab: PropTypes.func.isRequired,
  promotionDetails: PropTypes.array,
};
