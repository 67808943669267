// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import {
  FILTER_EXCLUSION_TRIGGER_CHARACTER,
  FILTER_TRIGGER_CHARACTER,
} from "../constants";

/**
 * Find filter object by name
 */
export const getFilterObjectByName = (name, allFilters) =>
  allFilters.find((f) => f.displayName.toLowerCase() === name.toLowerCase());

/**
 * Find filter object by id
 */
export const getFilterObjectById = (id, allFilters) =>
  allFilters.find((f) => f.id === id);

/**
 * Given all possible values for filter, find one matching the string value.
 * E.g. we can filter by distributor with id=7, but we should not display
 * the value as a number - distributor name will be more informative.
 */
const getDisplayedFilterValue = (filterObject, value) => {
  const allValues = get(filterObject, "values", []);
  const valueObj = allValues.find((v) => v.query === value);
  return get(valueObj, "display", value);
};

/**
 * Create new filter that is going to be displayed under the search box as tag.
 * Filter as described below would add "distributorId=7" to search query
 * and would show as "Distributor id: Skyworth" to the user.
 */
export const createFilter = (
  filterObject,
  value,
  isExclusionFilter = false
) => ({
  // used to create url parameter e.g. "distributorId"
  id: filterObject.id,
  // used to display filter to the user e.g. "distributor_id"
  displayName: filterObject.displayName,
  // used to create url parameter e.g. "7"
  value,
  // used to display filter to the user e.g. "Skyworth"
  displayValue: getDisplayedFilterValue(filterObject, value),
  // filter description as received from backend, see `filterOptionPropTypes`
  // in `propTypes.js`.
  filterSpecObject: filterObject,
  isExclusionFilter,
});

/**
 * There are 2 types of suggestions:
 *  - FilterDropdownOption used to display initial list of filters
 *  - ValueDropdownOption used to display possible filter values e.g. ["yes", "no"]
 */
export const createDropdownSuggestion = (filterObject, value, userInput) => {
  const filterTriggerChar =
    userInput && userInput.startsWith(FILTER_EXCLUSION_TRIGGER_CHARACTER)
      ? FILTER_EXCLUSION_TRIGGER_CHARACTER
      : FILTER_TRIGGER_CHARACTER;
  const suggestion = `${filterTriggerChar}${filterObject.displayName}:`;
  return value ? `${suggestion}${value.display}` : suggestion;
};

/**
 * Returns flat list of all possible filters
 */
export const flattenFilterDataChoices = (filters) => {
  return filters.sections.reduce(
    (allFilters, section) => [...allFilters, ...section.options],
    []
  );
};
