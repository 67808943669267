// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

import { api, apiThatThrows } from "containers/Request";

import * as actions from "./action";
import { DevicesModal } from "./DevicesModal";

const ITEMS_PER_PAGE = 10;

@connect(
  ({ addAssetDevice }) => ({ addAssetDevice }),
  (dispatch) => ({
    addAssetDeviceInactive: () => dispatch(actions.addAssetDeviceInactive()),
    getAssetDeviceModels: (assetId, assetVersion) =>
      dispatch(
        api.getAssetDeviceModelsPaginated.action({
          params: { assetId, assetVersion },
          queryParams: { limit: "nolimit" },
        })
      ),
    postAssetDeviceModels: (assetId, assetVersion, options) =>
      dispatch(
        api.postAssetDeviceModels.action({
          params: { assetId, assetVersion },
          options,
        })
      ),
    getOrganizations: (ids) =>
      dispatch(
        apiThatThrows.getOrganizationsPaginated.action({
          queryParams: {
            id: ids,
            limit: "nolimit",
          },
        })
      ),
    getDeviceOwnersPaginated: (queryParams) =>
      dispatch(
        apiThatThrows.getDeviceOwnersPaginated.action({
          queryParams: {
            ...queryParams,
            include: ["device_families", "device_models"],
            limit: ITEMS_PER_PAGE,
          },
        })
      ),
  })
)
export class DevicesModalContainer extends Component {
  static propTypes = {
    // from @connect
    addAssetDevice: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
      versionNumber: PropTypes.string,
      asset: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        defaultVersion: PropTypes.string,
      }),
    }),
    addAssetDeviceInactive: PropTypes.func,
    getAssetDeviceModels: PropTypes.func,
    postAssetDeviceModels: PropTypes.func,
    getOrganizations: PropTypes.func,
    getDeviceOwnersPaginated: PropTypes.func,
  };

  state = {
    loading: false,
    publishedModels: [],
    error: null,
  };

  async componentDidMount() {
    await this.getPublishedModels();
  }

  async componentDidUpdate(prevProps) {
    const { addAssetDevice } = this.props;

    if (!isEqual(prevProps.addAssetDevice, addAssetDevice)) {
      await this.getPublishedModels();
    }
  }

  async getPublishedModels() {
    const {
      addAssetDevice: { asset, versionNumber, isActive },
      getAssetDeviceModels,
    } = this.props;

    if (!isActive) {
      return;
    }

    this.setState({ loading: true });

    const { result, error } = await getAssetDeviceModels(
      asset.id,
      versionNumber || asset.defaultVersion
    );

    const err = error || result.error;

    this.setState({
      publishedModels: err ? [] : result.results.map((model) => model.id),
      error: err,
      loading: false,
    });
  }

  createModelData = (model) => ({
    id: model.id,
    name: model.name,
    isTesting: model.is_testing,
    isDisabled: !model.is_enabled,
  });

  createFamilyData = (owner) => (family) => {
    const deviceModels = owner.device_models
      .filter(
        (model) => model.device_family && model.device_family.id === family.id
      )
      .map(this.createModelData);

    return {
      id: family.id,
      name: family.name,
      models: deviceModels,
    };
  };

  createOwnerData = (organizations) => (owner) => {
    const asOrganization = organizations.find((o) => o.id === owner.id);
    const families = owner.device_families.map(this.createFamilyData(owner));

    return {
      id: String(owner.id),
      name: asOrganization ? asOrganization.name : "",
      deviceFamilies: families,
    };
  };

  async getOrganizations(orgIds) {
    const { getOrganizations } = this.props;

    if (!orgIds || orgIds.length === 0) {
      return { results: [] };
    }
    return await getOrganizations(orgIds);
  }

  fetchData = async (queryParams) => {
    const { getDeviceOwnersPaginated } = this.props;

    const oems = await getDeviceOwnersPaginated(queryParams);

    const { results: organizations } = await this.getOrganizations(
      oems.results.map((o) => o.id)
    );

    return {
      meta: oems.meta,
      results: oems.results.map(this.createOwnerData(organizations)),
    };
  };

  render() {
    const { addAssetDevice, addAssetDeviceInactive, postAssetDeviceModels } =
      this.props;
    const { publishedModels, error, loading } = this.state;

    return (
      <DevicesModal
        addAssetDevice={addAssetDevice}
        addAssetDeviceInactive={addAssetDeviceInactive}
        postAssetDeviceModels={postAssetDeviceModels}
        publishedModels={publishedModels}
        devicesErrors={error}
        fetchData={this.fetchData}
        itemsPerPage={ITEMS_PER_PAGE}
        isLoading={loading}
      />
    );
  }
}
