// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";
import { innerRefType } from "utils/types";

import styles from "./TableRow.scss";

/**
 * Corresponds to `<tr>`. Should be placed inside `<TableBody>`
 *
 */
export class TableRow extends Component {
  static propTypes = {
    /**
     * [TableRowColumn](#tablerowcolum) or [TableRowColumnExtended](#tablerowcolumnextended)
     * elements.
     */
    children: PropTypes.node,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    /**
     * Event handler.
     *
     * Type: `(e: Event) => void`
     */
    onClick: PropTypes.func,
    innerRef: innerRefType,
    /** Optional object with properties used to implement drag and drop functionalty. */
    dragProps: PropTypes.object,
    highlighting: PropTypes.bool,
  };

  static defaultProps = {
    dataTestId: "table-row",
    highlighting: true,
  };

  render() {
    const {
      children,
      className,
      onClick,
      dataTestId,
      innerRef,
      dragProps,
      highlighting,
    } = this.props;
    return (
      <tr
        ref={innerRef}
        data-test-id={dataTestId}
        className={classes(styles.container, className, {
          [styles.highlight]: highlighting,
        })}
        onClick={onClick}
        {...dragProps}
      >
        {children}
      </tr>
    );
  }
}

export default TableRow;
