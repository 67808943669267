// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";
import { classes } from "utils/classes";

import styles from "./Tag.scss";

const MAX_VISIBLE_TAG_VALUE_LENGTH = 90;

/** `<Tag>` component. */
export class Tag extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    isExclude: PropTypes.bool,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * `onDeleteClick` prop will be called with this value. Should be unique
         * for this name-value combination.
         */
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    /**
     * If not provided, this `<Tag/>` is read-only.
     *
     * Type: (id: string, isExclude: bool) => void
     */
    onDeleteClick: PropTypes.func,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    className: "",
  };

  renderValue = (id, value) => {
    const { onDeleteClick, isExclude } = this.props;
    const isDeleteAvailable = typeof onDeleteClick === "function";

    let onClick;
    if (isDeleteAvailable) {
      onClick = () => onDeleteClick(id, isExclude);
    }

    return (
      <Tooltip
        key={id}
        content={value}
        disabled={value.length < MAX_VISIBLE_TAG_VALUE_LENGTH}
        alignment="bottom"
        classNamePopup={styles.tooltip}
      >
        <div
          onClick={onClick}
          data-test-id="clickable-filter-value"
          className={classes(styles.ValueContainer, {
            [styles.Clickable]: isDeleteAvailable,
            [styles.ClickableExclude]: isDeleteAvailable && isExclude,
          })}
        >
          <span className={styles.Value}>{value}</span>

          {isDeleteAvailable && (
            <GrabsonIcon
              className={styles.Delete}
              name="close"
              size="xs"
              font="micro"
            />
          )}
        </div>
      </Tooltip>
    );
  };

  render() {
    const { name, values, className, dataTestId } = this.props;

    if (values.length === 0) {
      return null;
    }

    return (
      <li className={classes(styles.Tag, className)} data-test-id={dataTestId}>
        <span className={styles.Name}>{name}</span>
        <span className={styles.Separator}>:</span>
        {values.map(({ id, value }) => this.renderValue(id, value))}
      </li>
    );
  }
}
