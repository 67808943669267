// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectById } from "containers/Layout/SideMenu/redirect/RedirectById";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";
import { trans } from "src/translations";

import { InviteUser } from "./InviteUser";
import { UserDetails } from "./UserDetails";
import { UserEdit } from "./UserEdit";
import { UserLayout } from "./UserLayout";
import { UsersAdmin } from "./UsersAdmin";
import { UsersTable } from "./UsersTable";

export class UserRoute extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<RedirectDependingOnRoles />} />
        <Route
          exact
          path="/list"
          element={
            <ExtendedRoute
              name={trans.USER_LIST__PAGE_HEADER()}
              component={UsersTable}
            />
          }
        />
        <Route
          exact
          path="/admin"
          element={
            <ExtendedRoute
              name={trans.USER_ADMIN__PAGE_HEADER()}
              component={UsersAdmin}
            />
          }
        />
        <Route
          exact
          path="/invite"
          element={
            <ExtendedRoute
              name={trans.USER_INVITE__ROUTE_NAME()}
              component={InviteUser}
            />
          }
        />
        <Route
          exact
          path="/:id"
          element={<RedirectById path="/users/:id/details" />}
        />
        <Route
          exact
          path="/:id/details"
          element={
            <ExtendedRoute
              name={trans.VIEW_DETAILS()}
              layout={UserLayout}
              component={UserDetails}
            />
          }
        />
        <Route
          exact
          path="/:id/edit"
          element={
            <ExtendedRoute
              name={trans.EDIT_DETAILS()}
              layout={UserLayout}
              component={UserEdit}
              componentProps={{ forUserAdmin: false }}
            />
          }
        />
        <Route
          exact
          path="/admin/:id/details"
          element={
            <ExtendedRoute
              name={trans.VIEW_DETAILS()}
              layout={UserLayout}
              component={UserDetails}
              componentProps={{ forUserAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/admin/:id/edit"
          element={
            <ExtendedRoute
              name={trans.EDIT_DETAILS()}
              layout={UserLayout}
              component={UserEdit}
              componentProps={{ forUserAdmin: true }}
            />
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    );
  }
}
