// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Field } from "formik";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { Select } from "components/form/elements";
import { withActiveOrganization } from "containers/Auth/decorators";
import { apiThatThrows } from "containers/Request";

import { FIELDS, TYPE_NONE } from "../constants";
import { ModeContext } from "../context";
import { getConfiguration } from "../utils";

const TypeField = ({ readonly, newTypeSelected, activeOrganization }) => {
  const { forAdmin } = useContext(ModeContext);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [lastOrganization, setLastOrganization] = useState(activeOrganization);
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState([]);

  const getPromotionTypes = useCallback(() => {
    const fetchPromotionTypesAction = forAdmin
      ? apiThatThrows.getPromotionTypesPaginated.action
      : apiThatThrows.getMyPromotionTypesPaginated.action;
    const queryParams = {
      limit: "nolimit",
    };
    if (!forAdmin && !readonly) {
      queryParams.available = true;
    }
    return dispatch(
      fetchPromotionTypesAction({
        queryParams,
      })
    );
  }, [dispatch, forAdmin, readonly]);

  const updateData = useCallback(async () => {
    const createEntry = (promotionType) => ({
      text: promotionType.name,
      value: promotionType.id,
      configuration: getConfiguration(promotionType),
    });
    try {
      setLoading(true);
      const { results } = await getPromotionTypes();
      setTypes(results.map(createEntry));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [getPromotionTypes]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  useEffect(() => {
    if (!activeOrganization || !lastOrganization) {
      if (activeOrganization !== lastOrganization) {
        setLastOrganization(activeOrganization);
        updateData();
      }
      return;
    }
    if (activeOrganization.id !== lastOrganization.id) {
      setLastOrganization(activeOrganization);
      updateData();
    }
  }, [activeOrganization, lastOrganization, updateData]);

  const onChange = (event) => {
    newTypeSelected(types.find((type) => type.value === event.target.value));
  };

  const renderField = ({ field, form: { getFieldMeta } }) => {
    const meta = getFieldMeta(FIELDS.PROMO_TYPE.name);

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message}</Info>;
    }

    return (
      <Select
        {...FIELDS.PROMO_TYPE}
        {...field}
        {...meta}
        disabled={readonly}
        initOption={{
          value: TYPE_NONE,
          field,
        }}
        values={types}
        onSelectChange={onChange}
      />
    );
  };

  return <Field name={FIELDS.PROMO_TYPE.name} component={renderField} />;
};

TypeField.propTypes = {
  readonly: PropTypes.bool.isRequired,
  newTypeSelected: PropTypes.func.isRequired,

  // from @withActiveOrganization
  activeOrganization: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default withActiveOrganization(TypeField);
