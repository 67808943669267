// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./Event.scss";

const STATUS_NAMES = {
  scheduled: trans.SCHEDULED(),
  ongoing: trans.ONGOING(),
  finished: trans.FINISHED(),
};

export class Event extends Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    /** Event width in % */
    width: PropTypes.number,
    /** Event left position in % */
    left: PropTypes.number,
    /** If true left border is not shown */
    hideLeftBorder: PropTypes.bool,
    /** Top position in px */
    top: PropTypes.number,
    /** Height in px */
    height: PropTypes.number,
    /** Event status. For each status different style is applied */
    status: PropTypes.oneOf(["scheduled", "ongoing", "finished"]),
    /** If event is enabled */
    isEnabled: PropTypes.bool,
    /** Called on click with event id */
    onClick: PropTypes.func,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "schedule-chart-event",
  };

  handleClick = () => {
    const { id, onClick } = this.props;
    onClick(id);
  };

  getClasses() {
    const { hideLeftBorder, status, isEnabled } = this.props;

    return classes(
      styles.container,
      isEnabled ? styles[status] : styles["disabled"],
      { [styles.overflow]: hideLeftBorder }
    );
  }

  render() {
    const { label, width, left, top, height, status, dataTestId } = this.props;

    return (
      <div
        title={STATUS_NAMES[status]}
        className={this.getClasses()}
        style={{
          width: `${width}%`,
          left: `${left}%`,
          top: `${top}px`,
          height: `${height}px`,
        }}
        onClick={this.handleClick}
        data-test-id={dataTestId}
      >
        <p className={styles.label}>{label}</p>
      </div>
    );
  }
}

export default Event;
