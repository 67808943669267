// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const haveOrganizationsChanged = (
  prevOrganizationIds,
  nextOrganizationIds
) => {
  if (prevOrganizationIds.length !== nextOrganizationIds.length) {
    return true;
  }
  return !nextOrganizationIds.every((item) =>
    prevOrganizationIds.includes(item)
  );
};
