// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBarWithFilters } from "components/elements";
import { Info } from "components/feedback";
import { Section, TableBody } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { withQueryParams, withRouter } from "utils/decorators";
import { createLocationWithSearch } from "utils/url";

import { CampaignRow } from "./CampaignRow";
import styles from "./CampaignsTable.scss";
import { PromotionRow } from "./PromotionRow";
import { SwitchOngoingCampaignModal } from "./SwitchOngoingCampaignModal";
import { parseCampaignsFilters, mapFiltersToTranslations } from "./utils";

@withRouter
@withQueryParams
@withActiveOrganization
export class CampaignsTable extends Component {
  static propTypes = {
    campaigns: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,
    count: PropTypes.number,
    onToggleCampaign: PropTypes.func,
    onRemoveCampaign: PropTypes.func,
    forAdmin: PropTypes.bool,
    getAvailableFilters: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  };

  static tabsConfig = [
    {
      dataTestId: "campaigns-list",
      columns: [
        { label: trans.NAME() },
        { label: trans.START_DATE() },
        { label: trans.END_DATE() },
        { label: trans.CAMPAIGN_LIST__COLUMN_SCORE() },
        { label: trans.CAMPAIGN_LIST__COLUMN_SCHEDULE() },
        { label: trans.CAMPAIGN_LIST__COLUMN_IS_ENABLED() },
        { label: "" },
      ],
    },
  ];

  state = {
    expandedRows: {},
    switchOngoingCampaignId: null,
    errorOnUpdatingAvailableFilters: null,
    filtersDataChoices: parseCampaignsFilters(),
  };

  componentDidMount() {
    this.updateFilters();
  }

  handleRowExpand = ({ id }) => {
    this.setState(({ expandedRows }) => ({
      expandedRows: {
        ...expandedRows,
        [id]: !expandedRows[id],
      },
    }));
  };

  setIsEnabledField(campaignId, nextValue) {
    const { onToggleCampaign } = this.props;
    onToggleCampaign(campaignId, nextValue);
  }

  handleToggleCampaign = (campaign, nextIsEnabled) => {
    if (campaign.isOngoing) {
      this.setState({ switchOngoingCampaignId: campaign.id });
    } else {
      this.setIsEnabledField(campaign.id, nextIsEnabled);
    }
  };

  handleSwitchOngoingConfirm = (nextIsEnabled) => {
    const { switchOngoingCampaignId } = this.state;
    this.setIsEnabledField(switchOngoingCampaignId, nextIsEnabled);
    this.handleCloseSwitchOngoingModal();
  };

  handleCloseSwitchOngoingModal = () => {
    this.setState({ switchOngoingCampaignId: null });
  };

  onChangeSearchParams = (newSearchParams) => {
    const { location, navigate } = this.props;
    const newLocation = createLocationWithSearch(location, newSearchParams);
    navigate(newLocation);
  };

  updateFilters = async () => {
    const { getAvailableFilters, forAdmin } = this.props;

    try {
      let { results } = await getAvailableFilters();
      results = mapFiltersToTranslations(results, forAdmin);

      this.setState({
        errorOnUpdatingAvailableFilters: null,
        filtersDataChoices: parseCampaignsFilters(results),
      });
    } catch (err) {
      this.setState({ errorOnUpdatingAvailableFilters: err });
    }
  };

  renderBody = () => {
    const { campaigns, onRemoveCampaign, forAdmin } = this.props;
    const { expandedRows } = this.state;

    return (
      <TableBody>
        {campaigns.reduce((acc, campaign) => {
          acc.push(
            <CampaignRow
              key={campaign.id}
              campaign={campaign}
              expanded={expandedRows[campaign.id]}
              onToggleExpand={this.handleRowExpand}
              onToggleCampaign={this.handleToggleCampaign}
              onRemoveCampaign={onRemoveCampaign}
              forAdmin={forAdmin}
            />
          );

          if (expandedRows[campaign.id]) {
            campaign.promotions.forEach((promotion) => {
              acc.push(
                <PromotionRow
                  key={`${campaign.id}-${promotion.id}`}
                  promotion={promotion}
                />
              );
            });
          }

          return acc;
        }, [])}
      </TableBody>
    );
  };

  renderCreateButton = () => {
    const { forAdmin } = this.props;
    return (
      <ButtonsWrapper>
        <Button
          type="green"
          className={styles.button}
          to={
            forAdmin
              ? "/promotions/admin/campaigns/create"
              : "/promotions/campaigns/create"
          }
        >
          {trans.CAMPAIGN_LIST__CREATE_BUTTON()}
        </Button>
      </ButtonsWrapper>
    );
  };

  getCampaign = (campaignId) => {
    const { campaigns } = this.props;
    return campaigns.find((c) => c.id === campaignId);
  };

  isCampaignEnabled = (campaignId) => {
    const campaign = this.getCampaign(campaignId);
    return campaign && campaign.isEnabled;
  };

  render() {
    const {
      campaigns,
      loading,
      error,
      count,
      queryParams,
      location,
      forAdmin,
      activeOrganization: { name },
    } = this.props;
    const locationMessage = location?.state?.message;
    const {
      switchOngoingCampaignId,
      filtersDataChoices,
      errorOnUpdatingAvailableFilters,
    } = this.state;

    return (
      <div data-test-id="campaigns-table">
        <Section
          header={
            forAdmin
              ? trans.CAMPAIGN_LIST__SECTION_NAME_ADMIN()
              : trans.CAMPAIGN_LIST__SECTION_NAME({ organization: name })
          }
        >
          <Section>
            <SearchBarWithFilters
              placeholder={trans.CAMPAIGN_LIST__SEARCH_PLACEHOLDER()}
              look="for-white-bg"
              onSearch={this.onChangeSearchParams}
              filtersDataChoices={filtersDataChoices}
              searchParams={queryParams}
              availableFiltersError={errorOnUpdatingAvailableFilters}
            />
          </Section>
          <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
          {this.renderCreateButton()}
          <Section>
            <TabbedTable
              tabsConfig={CampaignsTable.tabsConfig}
              data={{ results: campaigns, count, loading, error }}
              renderTableBody={this.renderBody}
            />
          </Section>
          {this.renderCreateButton()}
          <SwitchOngoingCampaignModal
            isOpen={switchOngoingCampaignId !== null}
            onClose={this.handleCloseSwitchOngoingModal}
            onConfirm={this.handleSwitchOngoingConfirm}
            isCampaignEnabled={this.isCampaignEnabled(switchOngoingCampaignId)}
          />
        </Section>
      </div>
    );
  }
}
