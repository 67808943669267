import * as React from "react";

import queryString, { ParsedQuery } from "query-string";

import { withRouter, WithRouterProps } from "./withRouter";

export interface WithQueryParamsProps {
  queryParams: ParsedQuery;
}

export const withQueryParams = <
  TProps extends WithQueryParamsProps = WithQueryParamsProps,
>(
  ComposedComponent: React.ComponentType<TProps>
) => {
  class WithQueryParams extends React.Component<TProps & WithRouterProps> {
    render() {
      return (
        <ComposedComponent
          {...this.props}
          queryParams={queryString.parse(this.props.location.search)}
        />
      );
    }
  }

  return withRouter(WithQueryParams);
};
