// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { getActiveOrganizationSelector } from "src/containers/Auth/selectors.js";
import { createBody } from "utils/jsonApi";

import { AppNameForm } from "./AppNameForm";
import { StatusBadge } from "./StatusBadge";

const AppNameComponent = ({
  appData,
  dataTestId = "app-name-test-id",
  showAppState,
  updateStatus,
  roles,
  changeAppName,
}) => {
  const [inEdit, setInEdit] = useState(false);

  const onAppNameSubmit = async ({ appName }, { setSubmitting, resetName }) => {
    const body = createBody({
      type: "ApplicationModel",
      id: appData.id,
      new_application_name: appName,
    });

    try {
      await changeAppName(appData.id, body);
      updateStatus({ success: true, error: false });
    } catch (err) {
      resetName();
      updateStatus({ success: false, error: true });
    } finally {
      setInEdit(false);
      setSubmitting(false);
    }
  };

  const moderatorAndNotDraftApp =
    roles.includes(ROLES.moderator.moderator) &&
    appData?.submission_status !== "DRAFT";

  const cspAndDraftApp =
    roles.includes(ROLES.provider.signatory) && // role assigned to CSP
    appData?.submission_status === "DRAFT";

  let appNameRender;

  if (!cspAndDraftApp && !moderatorAndNotDraftApp) {
    appNameRender = (
      <>
        <span data-test-id="app-name">{appData.name}</span>
        {showAppState ? <StatusBadge appData={appData} /> : null}
      </>
    );
  } else {
    appNameRender = (
      <AppNameForm
        appData={appData}
        inEdit={inEdit}
        onModeToggle={() => setInEdit(!inEdit)}
        onSubmit={onAppNameSubmit}
        showAppState={showAppState}
      />
    );
  }

  return <div data-test-id={dataTestId}>{appNameRender}</div>;
};

AppNameComponent.propTypes = {
  showAppState: PropTypes.bool,
  appData: PropTypes.object.isRequired,
  updateStatus: PropTypes.func,
  dataTestId: PropTypes.string,
  /** from connect */
  roles: PropTypes.array.isRequired,
  changeAppName: PropTypes.func.isRequired,
};

export const AppName = connect(
  (state) => ({
    roles: getActiveOrganizationSelector(state)?.roles ?? [],
  }),
  (dispatch) => ({
    changeAppName: (appId, body) =>
      dispatch(
        apiThatThrows.changeAppName.action({
          params: { id: appId },
          options: { body },
        })
      ),
  })
)(AppNameComponent);
