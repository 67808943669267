// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField } from "formik";

import { Switch } from "../elements";
import { FieldProps } from "./propTypes";

export const SwitchField = (props) => {
  const [field, meta] = useField({
    ...props,
    type: "switch",
  });

  return <Switch checked={field.value} {...field} {...meta} {...props} />;
};

SwitchField.propTypes = FieldProps;
