// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { SearchableListPanel } from "components/elements";
import { Flag } from "components/images";
import { classes } from "utils/classes";

import styles from "./GeoMapListItem.scss";

export class GeoMapListItem extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    flagCode: PropTypes.string,
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    flagCode: null,
    dataTestId: "geomap-list-item",
  };

  onChecked = () => {
    const { onChange, value } = this.props;
    onChange(value);
  };

  render() {
    const {
      className,
      labelClassName,
      disabled,
      checked,
      flagCode,
      value,
      text,
      dataTestId,
    } = this.props;

    return (
      <SearchableListPanel className={className} dataTestId={dataTestId}>
        <label
          className={classes(styles.label, labelClassName, {
            [styles.disabled]: disabled,
          })}
        >
          {flagCode && <Flag countryCode={flagCode} />}
          <div className={styles.countryInfo}>
            <span className={styles.name}> {text}</span>
          </div>
          <div className={styles.countryCheckbox}>
            <input
              type="checkbox"
              onChange={this.onChecked}
              value={value}
              checked={checked}
              disabled={disabled}
              data-test-id={`${dataTestId}-input`}
            />
          </div>
        </label>
      </SearchableListPanel>
    );
  }
}
