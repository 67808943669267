// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { api } from "containers/Request";
import { STATES } from "pages/Snap/shared/constants";

export const KEYS = {
  ACTIVE: "ACTIVE",
  CREATED: "CREATED",
  DRAFT: "DRAFT",
  NEW: "NEW",
};

const adaptState = (key, rev) => {
  return {
    key: key,
    label: STATES[key],
    revision: rev ? rev : null,
  };
};

const getStates = (results) => {
  const states = [];
  if (results.length === 0) {
    return [adaptState(KEYS.NEW)];
  }

  // if first revision is not submitted then it is a draft
  if (results[0].vewd_cloud_version_status === "NOT_SUBMITTED") {
    states.push(adaptState(KEYS.DRAFT, results[0]));
  }

  for (let i = 0; i < results.length; i++) {
    // any state below Active does not matter
    if (results[i].vewd_cloud_version_status === "ACCEPTED") {
      states.unshift(adaptState(KEYS.ACTIVE, results[i]));
      break;
    }
    // pick first occurrence of Created state
    if (
      results[i].vewd_cloud_version_status !== "NOT_SUBMITTED" &&
      results[i].vewd_cloud_version_status !== "ACCEPTED"
    ) {
      if (!states.find((state) => state.key === KEYS.CREATED)) {
        states.unshift(adaptState(KEYS.CREATED, results[i]));
      }
    }
  }
  return states;
};

export function useSnapState({ id } = { id: null }) {
  const [loading, setLoading] = useState(true);
  const [appStates, setAppStates] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const appId = id ?? params?.id ?? null;

  const getLastVersion = useCallback(() => {
    const ln = appStates.length;
    return ln > 0 ? appStates[ln - 1] : null;
  }, [appStates]);

  const getRevision = useCallback(
    (revState) => {
      const found =
        appStates.length > 0
          ? appStates.find((version) => version.key === revState)
          : [];
      return found ? found.revision : null;
    },
    [appStates]
  );

  const getRevisionsListCall = useCallback(
    (id) =>
      dispatch(
        api.getSnapRevisionsList.action({
          params: { id },
          cache: true,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    if (appId) {
      getRevisionsListCall(appId)
        .then(({ result }) => {
          if (result.error) {
            throw new Error();
          }
          setAppStates(getStates(result?.results));
        })
        .catch(() => setAppStates(getStates([])))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  return {
    appStates,
    loading,
    getLastVersion,
    getRevision,
    isNew: !loading && getLastVersion().key === KEYS.NEW,
  };
}
