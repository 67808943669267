// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { debounce } from "utils/debounce";

const SEARCH_CHANGE_DEBOUNCE = 300;

export const handleSearchChangeDecorator = (ComposedComponent) => {
  return class SearchChangeHandler extends Component {
    static propTypes = {
      requestNewSearch: PropTypes.func.isRequired,
    };

    handleSearchChange = debounce((value) => {
      const { requestNewSearch } = this.props;
      requestNewSearch(value);
    }, SEARCH_CHANGE_DEBOUNCE);

    render() {
      return (
        <ComposedComponent
          {...this.props}
          handleSearchChange={this.handleSearchChange}
        />
      );
    }
  };
};
