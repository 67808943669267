// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

export const queryResultType = PropTypes.shape({
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  variables: PropTypes.object,
  networkStatus: PropTypes.number,
  refetch: PropTypes.func,
  fetchMore: PropTypes.func,
  startPolling: PropTypes.func,
  stopPolling: PropTypes.func,
  subscribeToMore: PropTypes.func,
  updateQuery: PropTypes.func,
  client: PropTypes.object,
  called: PropTypes.bool,
});
