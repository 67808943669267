// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

enum DeviceUpdateJobStatusEnum {
  SCHEDULED = "SCHEDULED",
  WAITING = "WAITING",
  ONGOING = "ONGOING",
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

interface Device {
  lastContactDate: string;
  id: string;
  status: DeviceUpdateJobStatusEnum;
}

export interface AdaptedDevice {
  lastContactDate: Date | null;
  id: string;
  status: DeviceUpdateJobStatusEnum;
}

export const adaptDevices = (devices: Device[]): AdaptedDevice[] =>
  devices.map(({ lastContactDate, ...other }) => ({
    lastContactDate: lastContactDate ? new Date(lastContactDate) : null,
    ...other,
  }));
