// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getDeviceSearchSuggestionsQuery = gql`
  query deviceSearchSuggestions {
    deviceSearchSuggestions {
      deviceSearchSuggestions {
        title
        options {
          id
          displayName
          param
          description
          isExcludable
          values {
            query
            display
          }
        }
      }
    }
  }
`;
