// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Link } from "components/typography";
import { RestrictedArea, ROLES } from "containers/Permissions";
import { isMonetized } from "pages/Applications/AppDetails/AppVersions/_utils/monetization";
import { APP_ROUTE_MODERATION } from "pages/Applications/constants";
import { ORGANIZATION_PLAN } from "pages/Organization/constants";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import {
  MODERATION_STATUS,
  QA_STATUS_ACCEPTED,
  QA_STATUS_PENDING,
} from "../constants";
import styles from "./ModerationStatusSection.scss";

export class ModerationStatusSection extends Component {
  static propTypes = {
    applicationId: PropTypes.string,
    appOwner: PropTypes.object,
    moderationStatus: PropTypes.string,
    versionData: PropTypes.object,
    latestModerationResult: PropTypes.object,
    pendingApproveRequest: PropTypes.bool.isRequired,
    onChangeModerationStatus: PropTypes.func.isRequired,
    onApproveMetadata: PropTypes.func.isRequired,
    openRejectModal: PropTypes.func.isRequired,
  };

  isNotPremiumWithRevenue() {
    const { versionData, appOwner } = this.props;
    const organizationPlan =
      appOwner?.organization_plan ?? ORGANIZATION_PLAN.Basic;
    return (
      isMonetized(versionData) && organizationPlan === ORGANIZATION_PLAN.Basic
    );
  }

  canApproveMetadata() {
    const { latestModerationResult } = this.props;

    const hideBasedOnRevenue = this.isNotPremiumWithRevenue();
    const isAlreadyApproved =
      latestModerationResult.status === QA_STATUS_ACCEPTED;

    return !hideBasedOnRevenue && !isAlreadyApproved;
  }

  canRejectMetadata() {
    const { latestModerationResult } = this.props;
    // After version was approved it is already distributed over
    // the whole system. We should not be able to reject it.
    // See comments for YGG-4476
    return latestModerationResult.status === QA_STATUS_PENDING;
  }

  showMetadataButtons() {
    const { latestModerationResult } = this.props;
    if (!latestModerationResult) {
      return false;
    }

    const { flow_status } = latestModerationResult;
    return flow_status === MODERATION_STATUS.TESTING.id;
  }

  handleChangeModerationStatus = (status) => () => {
    const { onChangeModerationStatus } = this.props;
    onChangeModerationStatus(status);
  };

  getModerationStatusText(moderationStatus) {
    const status = Object.values(MODERATION_STATUS).find(
      (status) => status.id === moderationStatus
    );
    return status?.name ?? NO_VALUE_PLACEHOLDER;
  }

  render() {
    const {
      moderationStatus,
      applicationId,
      pendingApproveRequest,
      onApproveMetadata,
      openRejectModal,
    } = this.props;

    return (
      <Fragment>
        <div className={styles.moderationStatus}>
          {this.getModerationStatusText(moderationStatus)}
          <Link
            className={styles.link}
            to={`/applications/${APP_ROUTE_MODERATION}/${applicationId}/details/messages`}
          >
            {`(${trans.APP_DETAILS_MODERATION__VIEW_HISTORY()})`}
          </Link>
        </div>
        <RestrictedArea
          allowed={[ROLES.moderator.moderator]}
          showFallback={false}
        >
          <ButtonsWrapper
            position="left"
            className={styles.moderationStatusButtons}
          >
            {moderationStatus !== MODERATION_STATUS.TESTING.id && (
              <Button
                onClick={this.handleChangeModerationStatus(
                  MODERATION_STATUS.TESTING.id
                )}
              >
                {trans.APP_DETAILS_MODERATION__START_MODERATION()}
              </Button>
            )}
            {moderationStatus === MODERATION_STATUS.TESTING.id && (
              <Button
                onClick={this.handleChangeModerationStatus(
                  MODERATION_STATUS.DONE.id
                )}
              >
                {trans.APP_DETAILS_MODERATION__DONE_MODERATION()}
              </Button>
            )}
          </ButtonsWrapper>

          {this.showMetadataButtons() ? (
            <Fragment>
              <ButtonsWrapper
                position="left"
                className={styles.approveRejectButtons}
              >
                <Button
                  processing={pendingApproveRequest}
                  disabled={pendingApproveRequest || !this.canApproveMetadata()}
                  type="green"
                  onClick={onApproveMetadata}
                >
                  {trans.APP_DETAILS_MODERATION__APPROVE_METADATA_BUTTON()}
                </Button>
                <Button
                  disabled={pendingApproveRequest || !this.canRejectMetadata()}
                  type="red"
                  onClick={openRejectModal}
                >
                  {trans.APP_DETAILS_MODERATION__REJECT_METADATA_BUTTON()}
                </Button>
              </ButtonsWrapper>
              <Info type="warning" className={styles.finalActionWarning}>
                <p>{trans.APP_DETAILS_MODERATION__FINAL_ACTION_WARNING()}</p>
              </Info>
            </Fragment>
          ) : null}
        </RestrictedArea>
      </Fragment>
    );
  }
}
