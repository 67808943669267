// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import queryString from "query-string";

import { restrictedArea, ROLES } from "containers/Permissions";
import { api, apiThatThrows } from "containers/Request";
import { withRouter } from "utils/decorators";
import { createBody } from "utils/jsonApi";
import { retrieveFiltersFromInclude } from "utils/url";

import { STATUSES, getCampaignStatus } from "../_utils";
import { CAMPAIGN_TYPES } from "../constants";
import { CampaignsTable } from "./CampaignsTable";

@withRouter
@restrictedArea(({ forAdmin }) => {
  if (forAdmin) {
    return { allowed: [ROLES.administrator.promotionAdmin] };
  }
  return { allowed: [ROLES.promotionManagement.promotionManager] };
})
@connect(null, (dispatch, ownProps) => ({
  getCampaignsPaginated: (location) => {
    const fetchCampaignsAction = ownProps.forAdmin
      ? apiThatThrows.getCampaignsPaginated.action
      : apiThatThrows.getMyCampaignsPaginated.action;

    const adaptedQueryParams = retrieveFiltersFromInclude(
      queryString.parse(location.search)
    );
    return dispatch(
      fetchCampaignsAction({
        queryParams: {
          page: 1,
          campaign_type: ownProps.forAdmin ? CAMPAIGN_TYPES.ADMIN : undefined,
          ...adaptedQueryParams,
          include: ["promotions"],
        },
      })
    );
  },
  updateCampaign: (id, body) => {
    const patchCampaignAction = ownProps.forAdmin
      ? api.patchCampaign.action
      : api.patchMyCampaign.action;
    return dispatch(patchCampaignAction({ params: { id }, options: { body } }));
  },
  removeCampaign: (id) => {
    const removeCampaignAction = ownProps.forAdmin
      ? api.deleteCampaign.action
      : api.deleteMyCampaign.action;
    return dispatch(removeCampaignAction({ params: { id } }));
  },
  getAvailableFilters: (forAdmin) => {
    const config = { cache: true };
    if (forAdmin) {
      return dispatch(apiThatThrows.getCampaingsFilters.action(config));
    }
    return dispatch(apiThatThrows.getMyCampaingsFilters.action(config));
  },
}))
export class CampaignsTableData extends Component {
  static propTypes = {
    // from @connect
    // eslint-disable-next-line react/no-unused-prop-types
    getCampaignsPaginated: PropTypes.func,
    updateCampaign: PropTypes.func,
    removeCampaign: PropTypes.func,
    getAvailableFilters: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object,

    forAdmin: PropTypes.bool,
  };

  state = {
    campaigns: [],
    count: 0,
    error: null,
    loading: 0,
  };

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.updateData();
    }
  }

  createCampaign = async (c) => {
    const { forAdmin } = this.props;
    const status = getCampaignStatus(c.start_date, c.end_date);

    return {
      id: c.id,
      name: c.name,
      startDate: c.start_date,
      endDate: c.end_date,
      score: c.score,
      isEnabled: c.is_enabled,
      isReadonly: c.campaign_type === CAMPAIGN_TYPES.ADMIN_MANAGED && !forAdmin,
      isOngoing: status === STATUSES.ONGOING,
      promotions: c.promotions.map((p) => ({
        id: p.id,
        name: p.name,
      })),
    };
  };

  updateData = async () => {
    this.setState((prevState) => ({
      loading: prevState.loading + 1,
      error: null,
    }));

    try {
      const { results: campaignsList, meta: campaignsMeta } =
        await this.getCampaignsPaginated();
      const campaignRequests = campaignsList.map(this.createCampaign);
      const campaigns = await Promise.all(campaignRequests);

      this.setState({
        campaigns,
        count: campaignsMeta.count,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState((prevState) => ({
        loading: prevState.loading - 1,
      }));
    }
  };

  async getCampaignsPaginated() {
    const { getCampaignsPaginated, location } = this.props;
    return await getCampaignsPaginated(location);
  }

  handleToggleCampaign = (id, enable) => {
    const { updateCampaign } = this.props;

    const body = createBody({
      type: "campaign",
      id: id,
      is_enabled: enable,
    });

    updateCampaign(id, body).then(this.updateData);
  };

  handleRemoveCampaign = (campaign) => {
    const { removeCampaign } = this.props;
    removeCampaign(campaign.id).then(this.updateData);
  };

  fetchFilters = async () => {
    const { forAdmin, getAvailableFilters } = this.props;
    return await getAvailableFilters(forAdmin);
  };

  render() {
    const { campaigns, count, error, loading } = this.state;
    const { forAdmin } = this.props;

    return (
      <CampaignsTable
        campaigns={campaigns}
        loading={loading > 0}
        error={error}
        count={count}
        onToggleCampaign={this.handleToggleCampaign}
        onRemoveCampaign={this.handleRemoveCampaign}
        forAdmin={forAdmin}
        getAvailableFilters={this.fetchFilters}
      />
    );
  }
}
