// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { VersionSubTabs } from "./_shared/VersionSubTabs";
import { VERSION_TABS } from "./constants";
import { Version } from "./Version";

export class VersionsTab extends Component {
  static propTypes = {
    application: PropTypes.object.isRequired,
    latestVersion: PropTypes.object,
    activeVersion: PropTypes.object,
    allCountries: PropTypes.array,
    categories: PropTypes.array,
    languages: PropTypes.array,
    appOwner: PropTypes.object,
    error: PropTypes.string,
    loading: PropTypes.bool,
    upgradeOrganizationPlan: PropTypes.func,
  };

  static defaultProps = {
    loading: true,
    allCountries: [],
    categories: [],
    languages: [],
  };

  getDisplayedVersion(subTabId) {
    const { latestVersion, activeVersion } = this.props;

    if (subTabId === VERSION_TABS.latest) {
      return latestVersion;
    }

    return activeVersion;
  }

  canEditVersion(currentTabId, tabLatestVisible) {
    if (currentTabId === VERSION_TABS.active) {
      return !tabLatestVisible;
    }
    return true;
  }

  render() {
    const {
      loading,
      error,
      allCountries,
      categories,
      languages,
      application,
      appOwner,
      activeVersion,
      latestVersion,
      upgradeOrganizationPlan,
    } = this.props;

    return (
      <VersionSubTabs
        loading={loading}
        error={error}
        activeVersion={activeVersion}
        latestVersion={latestVersion}
        application={application}
      >
        {({ subTabId, renderTabsList, isTabShown }) => {
          const shownVersionData = this.getDisplayedVersion(subTabId);

          return (
            <div>
              {renderTabsList()}
              <Version
                versionData={shownVersionData}
                canEditVersion={this.canEditVersion(
                  subTabId,
                  isTabShown(VERSION_TABS.latest)
                )}
                allCountries={allCountries}
                categories={categories}
                languages={languages}
                application={application}
                appOwner={appOwner}
                upgradeOrganizationPlan={upgradeOrganizationPlan}
              />
            </div>
          );
        }}
      </VersionSubTabs>
    );
  }
}
