// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./Unit.scss";

export class Unit extends Component {
  static propTypes = {
    /** Unit label */
    label: PropTypes.node,
    /** Unit sub label displayed under label */
    subLabel: PropTypes.string,
    /** Unit width in % */
    width: PropTypes.number,
    /** If true active style is applied */
    active: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "schedule-chart-unit",
  };

  render() {
    const { active, label, subLabel, width, dataTestId } = this.props;

    return (
      <div
        className={classes(styles.container, {
          [styles.active]: active,
        })}
        style={{ width: `${width}%` }}
        data-test-id={dataTestId}
      >
        {label}
        <div className={styles.subLabel}>{subLabel}</div>
      </div>
    );
  }
}

export default Unit;
