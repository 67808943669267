// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Navigate, useParams } from "react-router-dom";

import PropTypes from "prop-types";

export const RedirectById = ({ path }) => {
  const params = useParams();

  if (typeof path === "string") {
    const redirectPath = path.replace(":id", params.id);
    return <Navigate to={redirectPath} replace={true} />;
  }

  return null;
};

RedirectById.propTypes = {
  path: PropTypes.string.isRequired,
};
