// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import { MANIFEST_FIELDS } from "../../../CreateEditManifest/components/constants";
import styles from "./ManifestEdit.scss";

export const ManifestEditButtons = ({ position = "top", setErrorsBanner }) => {
  const { dirty, isSubmitting, errors } = useFormikContext();

  const hasEmptyManifest = Boolean(errors[MANIFEST_FIELDS.manifestError]);

  return (
    <ButtonsWrapper className={classes(styles.buttonsRow, styles[position])}>
      <Button
        buttonType="reset"
        disabled={isSubmitting || !dirty}
        className={classes({ [styles.hidden]: !dirty })}
        onClick={() => setErrorsBanner(false)}
      >
        {trans.APP_DETAILS_MANIFEST__EDIT_DISCARD()}
      </Button>
      <Button
        buttonType="submit"
        disabled={isSubmitting || !dirty || hasEmptyManifest}
        processing={isSubmitting}
        type={dirty ? "green" : "normal"}
        onClick={() => setErrorsBanner(true)}
      >
        {trans.APP_DETAILS_MANIFEST__EDIT_SUBMIT()}
      </Button>
    </ButtonsWrapper>
  );
};

ManifestEditButtons.propTypes = {
  position: PropTypes.oneOf(["top", "bottom"]),
  setErrorsBanner: PropTypes.func.isRequired,
};
