// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import cloneDeep from "lodash-es/cloneDeep";
import get from "lodash-es/get";
import mergeWith from "lodash-es/mergeWith";

import { CREATE_INITIAL_VALUES } from "../constants";

const STEP_INFINITY_DEVICE_COUNT = "-1";

const createSoftwarePackageInitialData = (versionData) => {
  if (versionData == null || versionData.softwarePackage == null) {
    return {};
  }

  return {
    id: versionData.id,
    packageName: versionData.softwarePackage.name,
    versionName: versionData.version,
  };
};

const createTargetInitialData = (targetData) => {
  if (targetData == null) {
    return {};
  }

  return {
    id: targetData.id,
    name: targetData.name,
    deviceCount: targetData.devices.pagination.count,
  };
};

const adaptSteps = (steps = []) => {
  return steps
    .filter((e) => e.deviceCount !== STEP_INFINITY_DEVICE_COUNT)
    .map((e) => String(e.deviceCount));
};

export const createInitialDataWithClone = (queryStatus) => {
  if (!queryStatus.data) {
    return queryStatus;
  }

  const initialData = cloneDeep(CREATE_INITIAL_VALUES);
  const data = get(queryStatus, "data.updateJob.updateJob", {});
  const steps = adaptSteps(data.steps);

  const clonedData = {
    type: data.type,
    description: data.description,
    softwarePackageVersion: createSoftwarePackageInitialData(
      data.softwarePackageVersion
    ),
    targetDevices: createTargetInitialData(data.target),
    deviceQuantityPerStep: [
      ...steps,
      ...CREATE_INITIAL_VALUES.deviceQuantityPerStep.slice(steps.length),
    ],
    numberOfSteps: steps.length + 1,
    threshold: String(data.threshold),
    availabilityScope: {
      start: new Date(data.availabilityStartDate),
      end: new Date(data.availabilityEndDate),
    },
    isRepeatable: data.isRepeatable,
    // Show the isRepeatable toggle if the isRepeatable is set
    showAdvancedOptions: data.isRepeatable ?? false,
  };

  const customizer = (objValue, srcValue) => {
    return srcValue != null ? srcValue : objValue;
  };

  return {
    ...queryStatus,
    data: mergeWith(initialData, clonedData, customizer),
  };
};
