// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Subsection } from "components/layout";
import Link from "components/typography/Link/Link";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { formatDateTime } from "utils/date";

import { DeviceUpdateJobStatus } from "../_shared/DeviceUpdateJobStatus";
import { DevicePropType } from "../propTypes";
import styles from "./DeviceProperties.scss";

export const fromDevicePropertyGetCountry =
  (propertyName, countries) => (device) => {
    const countryCode = get(device, propertyName);

    const areCountriesLoaded =
      countries && !countries.error && !countries.loading;

    if (!areCountriesLoaded) {
      return countryCode;
    }
    const country = countries.allCountries.find(
      (country) => country.code === countryCode
    );
    return get(country, "name", countryCode);
  };

export const fromDeviceDateProperty = (propertyName) => (device) =>
  formatDateTime(get(device, propertyName));

export const fromDeviceProperty = (propertyName) => (device) => {
  const data = get(device, propertyName);
  if (Array.isArray(data)) {
    return data.join(", ");
  }
  return data;
};

export const fromDeviceUpdateJobProperty = (
  propertyName,
  updateJobId = undefined
) =>
  function valueFn(device) {
    const data = get(device, propertyName);
    if (!data) {
      return null;
    }
    if (propertyName === "currentUpdateJob.status") {
      return (
        <DeviceUpdateJobStatus status={data} className={styles.narrowPill} />
      );
    }
    if (propertyName === "currentUpdateJob.name") {
      return <Link to={`/updates/jobs/${updateJobId}`}>{data}</Link>;
    }
    return data;
  };

const checkIsEmpty = (value) => value == null || value.length === 0;

export const DeviceProperty = ({ device, property, hiddenIfEmpty }) => {
  const value = property.valueFn(device);
  if (hiddenIfEmpty && checkIsEmpty(value)) {
    return null;
  }
  return (
    <Subsection
      header={property.name}
      dataTestId={property.dataTestId}
      className={styles.deviceProperty}
    >
      {checkIsEmpty(value) ? NO_VALUE_PLACEHOLDER : value}
    </Subsection>
  );
};

export const DevicePropertyPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  valueFn: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
}).isRequired;

DeviceProperty.propTypes = {
  device: DevicePropType,
  property: DevicePropertyPropType,
  hiddenIfEmpty: PropTypes.bool,
};
