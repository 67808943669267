// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { GrabsonIcon } from "components/icons";
import { TableRow, TableRowColumn } from "components/layout";
import { RegionsModal } from "pages/_shared/RegionsModal/RegionsModal";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { toRegionsString } from "utils/continents";

import { toDevicesString, roundNumberOfDevices } from "./_utils";
import { DEVICE_TYPE_QUERY } from "./constants";
import { RegionsPreviewModal } from "./RegionsPreviewModal/RegionsPreviewModal";
import { TargetsActionMenu } from "./TargetsActionMenu";
import styles from "./TargetsRow.scss";

export class TargetsRow extends Component {
  static propTypes = {
    dataTestId: PropTypes.string,
    target: PropTypes.object,
    onDelete: PropTypes.func,
    onSelectCountries: PropTypes.func,
    onSelectDevicesClicked: PropTypes.func,
    allCountries: PropTypes.array,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
  };

  state = {
    isRegionModalOpen: false,
  };

  handleOpenRegionModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isRegionModalOpen: true });
  };

  handleCloseRegionModal = () => {
    this.setState({ isRegionModalOpen: false });
  };

  handleRegionSelect = (data) => {
    this.props.onSelectCountries(data);
    this.setState({ isRegionModalOpen: false });
  };

  handleSelectDeviceButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelectDevicesClicked();
  };

  renderColumnAsText(id, text, handler, readonly) {
    const { disabled } = this.props;
    return (
      <span
        data-test-id={id}
        onClick={handler}
        className={classes(styles.columnValueText, {
          [styles.disabled]: disabled || readonly,
        })}
      >
        {text}
      </span>
    );
  }

  renderColumnAsButton(id, text, handler) {
    const { disabled } = this.props;
    return (
      <Button
        dataTestId={id}
        className={styles.columnValueButton}
        onClick={handler}
        disabled={disabled}
      >
        {text}
      </Button>
    );
  }

  renderClickableColumn = (id, text, handler, readonly, textIfBtn) => {
    const { disabled } = this.props;
    const clickHandler = disabled || readonly ? Function.prototype : handler;

    if (text !== undefined && text.length > 0) {
      return this.renderColumnAsText(id, text, clickHandler, readonly);
    }
    return this.renderColumnAsButton(id, textIfBtn, clickHandler);
  };

  getRegionsString = () => {
    const { target, allCountries } = this.props;
    if (target.type === DEVICE_TYPE_QUERY) {
      const numberOfDevices = get(target, "collections.numberOfDevices", 0);
      return trans.TARGETS_DEVICE_APPROX_NUMBER({
        number: roundNumberOfDevices(numberOfDevices),
      });
    }
    return toRegionsString(target.countries, allCountries || []);
  };

  getIcon = () => {
    const { target } = this.props;
    if (target.type === DEVICE_TYPE_QUERY) {
      return "target_granular";
    }
    return "target_boarding";
  };

  render() {
    const { dataTestId, target, onDelete, allCountries, disabled, readonly } =
      this.props;
    const { isRegionModalOpen } = this.state;
    return (
      <TableRow className={styles.container} dataTestId={dataTestId}>
        <TableRowColumn>
          <GrabsonIcon
            name={this.getIcon()}
            size="lg"
            className={styles.icon}
          />
        </TableRowColumn>
        <TableRowColumn className={styles.nameColumn}>
          {this.renderClickableColumn(
            "devices-btn",
            toDevicesString(target),
            this.handleSelectDeviceButton,
            readonly
          )}
        </TableRowColumn>
        <TableRowColumn>
          {this.renderClickableColumn(
            "regions-btn",
            this.getRegionsString(),
            this.handleOpenRegionModal,
            target.type === DEVICE_TYPE_QUERY,
            trans.TARGETS__NO_REGION_BUTTON_TEXT()
          )}
          {readonly ? (
            <RegionsPreviewModal
              isOpen={isRegionModalOpen}
              onClose={this.handleCloseRegionModal}
              allCountries={allCountries}
              countries={target.countries}
            />
          ) : (
            <RegionsModal
              label={trans.TARGETS__REGIONS_MODAL_CONTENT()}
              selectedCountriesCodes={target.countries}
              isOpen={isRegionModalOpen}
              onClose={this.handleCloseRegionModal}
              onSelect={this.handleRegionSelect}
              allCountries={allCountries}
            />
          )}
        </TableRowColumn>
        {!disabled && !readonly && (
          <TableRowColumn className={styles.actionMenuColumn}>
            <TargetsActionMenu
              onDelete={onDelete}
              onEditDevices={this.handleSelectDeviceButton}
              onEditRegions={this.handleOpenRegionModal}
              targetType={target.type}
            />
          </TableRowColumn>
        )}
      </TableRow>
    );
  }
}
