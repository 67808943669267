// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, useState } from "react";

import { useField } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { trans } from "src/translations";
import { classes } from "utils/classes";

import { Input } from "../elements";
import { FieldProps } from "./propTypes";
import styles from "./TimeField.scss";

const SECONDS_IN_MINUTE = 60;
const SEPARATOR = ":";
const TIME_FORMAT_REGEX = /(?:[0-5][0-9]):(?:[0-5][0-9])$/;
const DOUBLE_DIGITS = 10;

const getMinutes = (value) => Math.floor(value / SECONDS_IN_MINUTE);
const getSeconds = (value) => Math.floor(value % SECONDS_IN_MINUTE);

const getTimeString = (timeValue) => {
  if (!timeValue) {
    return "";
  }
  const minutes = getMinutes(timeValue);
  const seconds = getSeconds(timeValue);

  return `${minutes >= DOUBLE_DIGITS ? minutes : `0${minutes}`}:${seconds >= DOUBLE_DIGITS ? seconds : `0${seconds}`}`;
};

const validateFormat = (value) =>
  Yup.string()
    .matches(TIME_FORMAT_REGEX, trans.TIME_FIELD_FORMAT_ERROR())
    .validateSync(value);

const validateInTimeRange = (value, min, max) => {
  const rangeErrorMessage = trans.ERROR__NOT_IN_TIME_RANGE({
    timeMin: getTimeString(min),
    timeMax: getTimeString(max),
  });

  Yup.number()
    .min(min, rangeErrorMessage)
    .max(max, rangeErrorMessage)
    .validateSync(value);
};

const getSecondsFromStringValue = (timeValue) => {
  const [minutes, seconds] = timeValue.split(SEPARATOR);
  return Number(minutes) * SECONDS_IN_MINUTE + Number(seconds);
};

export const TimeField = ({
  name,
  className,
  look = "simple",
  max,
  min = 0,
  dataTestId,
  ...props
}) => {
  const [input, meta, helper] = useField({
    name,
    validate: (value) => {
      let error;
      try {
        validateFormat(timeValue);
        validateInTimeRange(value, min, max);
      } catch (err) {
        error = err.message;
      }
      return error;
    },
  });

  const [timeValue, setTimeValue] = useState(getTimeString(input.value));

  useEffect(() => {
    if (!timeValue) {
      return;
    }
    const value = getSecondsFromStringValue(timeValue);

    helper.setTouched(true);
    helper.setValue(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeValue]);

  return (
    <div
      className={classes(styles[look], className)}
      data-test-id={dataTestId ?? name}
    >
      <Input
        {...props}
        {...meta}
        value={timeValue}
        name={name}
        placeholder="mm:ss"
        onChange={(e) => setTimeValue(e.target.value)}
        dataTestId={dataTestId ?? name}
      />
    </div>
  );
};

TimeField.propTypes = {
  ...FieldProps,
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
};
