// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Pill } from "components/elements/Pill";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnOverflow,
} from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import { UpdateJobStatus } from "../_shared/UpdateJobStatus";
import { CLONEABLE_STATUSES } from "../UpdateJobDetails/constants";
import { updateJobPropTypes } from "./propTypes";
import { UpdateJobListActionMenu } from "./UpdateJobListActionMenu";
import styles from "./UpdateJobListRow.scss";

export class UpdateJobListRow extends PureComponent {
  static propTypes = {
    updateJob: updateJobPropTypes,
    openModal: PropTypes.func.isRequired,
  };

  getDetailsLink = () => {
    return `/updates/jobs/${this.props.updateJob.id}`;
  };

  render() {
    const { name, status, updateStep, creationDate, isRepeatable } =
      this.props.updateJob;
    const { openModal } = this.props;

    return (
      <TableRow>
        <TableRowColumnOverflow dataTestId="name-column" overflowText={name}>
          <Link to={this.getDetailsLink()}>{name}</Link>
        </TableRowColumnOverflow>
        <TableRowColumn dataTestId="status-column">
          <UpdateJobStatus status={status} className={styles.centerColumn} />
        </TableRowColumn>
        <TableRowColumn
          dataTestId="update-step-column"
          className={styles.centerColumn}
        >
          {updateStep}
        </TableRowColumn>
        <TableRowColumn
          dataTestId="creation-date-column"
          className={styles.centerColumn}
        >
          {formatDateTime(creationDate)}
        </TableRowColumn>
        <TableRowColumn
          dataTestId="repeatable-column"
          className={styles.centerColumn}
        >
          {isRepeatable ? (
            <Pill
              text={trans.UPDATES__FORM_FIELD_REPEATABLE_PILL()}
              className={styles.repeatablePill}
            />
          ) : null}
        </TableRowColumn>
        <TableRowColumn dataTestId="actions-column">
          <UpdateJobListActionMenu
            updateJob={this.props.updateJob}
            openModal={openModal}
            clone={CLONEABLE_STATUSES.includes(status)}
          />
        </TableRowColumn>
      </TableRow>
    );
  }
}
