// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

export const handleScrollDecorator = (ComposedComponent) => {
  return class ScrollHandler extends Component {
    static propTypes = {
      requestNextPage: PropTypes.func.isRequired,
    };

    state = {
      fetchNeeded: false,
    };

    async componentDidUpdate(prevProps, prevState) {
      const { requestNextPage } = this.props;
      const { fetchNeeded } = this.state;
      if (fetchNeeded && fetchNeeded !== prevState.fetchNeeded) {
        requestNextPage();
        this.setState({ fetchNeeded: false });
      }
    }

    handleScroll = (ev) => {
      if (
        ev.target.scrollHeight <=
        Math.ceil(ev.target.scrollTop + ev.target.clientHeight)
      ) {
        this.setState({ fetchNeeded: true });
      }
    };

    render() {
      return (
        <ComposedComponent {...this.props} handleScroll={this.handleScroll} />
      );
    }
  };
};
