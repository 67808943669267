// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { FieldArray, Form, Formik } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { FormHasErrors } from "components/form";
import {
  SlugField,
  TextField,
  SelectField,
  CheckboxField,
} from "components/form/fields";
import { Section, Subsection } from "components/layout";
import { api } from "containers/Request";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";
import { createFieldValidator, isRequired } from "utils/validation";

import { FORM } from "./constants";
import { SoftwareAssetsField, AvailabilityField } from "./fields";
import styles from "./PromotionTypeForm.scss";

const jsonPointerToFieldName = {
  "/data/attributes/name": "name",
  "/data/attributes/slug": "slug",
  "/data/attributes/slug_autogenerated": "autoSlug",
  "/data/attributes/number_of_apps": "appsCount",
  "/data/attributes/custom_icon": "icon",
  "/data/attributes/custom_thumbnail": "thumbnail",
  "/data/attributes/custom_title": "title",
  "/data/attributes/custom_description": "description",
  "/data/attributes/additional_background_image": "background",
  "/data/attributes/additional_action": "action",
  // This will make error show up under contentItems checkbox, which is the last item under Additional Metadata
  "/data/attributes/applications_count": "contentItems",
  "/data/attributes/additional_metadata": "json",
  "/data/attributes/additional_application_assets": "contentItems",
  "/data/relationships/software_assets": "softwareAssets",
  "/data/relationships/distributors/:id": "distributors._error[:id]",
};

const validate = (values) => {
  const errors = {};

  if (values.appsCount === "0") {
    const isAnyAdditionalMetadataSelected =
      values.action ||
      values.background ||
      values.description ||
      values.icon ||
      values.json ||
      values.thumbnail ||
      values.title ||
      values.contentItems;

    if (!isAnyAdditionalMetadataSelected) {
      errors.contentItems =
        trans.PROMOTION_TYPE_EDIT__ERROR_AT_LEAST_ONE_ADDITIONAL_METADATA();
    }
  }

  return errors;
};

@withRouter
@connect(null, (dispatch) => ({
  createPromotionType: (body) =>
    dispatch(api.createPromotionType.action({ options: { body } })),
  updatePromotionType: (id, body) =>
    dispatch(
      api.updatePromotionType.action({
        params: { id },
        options: { body },
      })
    ),
}))
export class PromotionTypeForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    isEdit: PropTypes.bool.isRequired,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,

    // from @connect
    createPromotionType: PropTypes.func.isRequired,
    updatePromotionType: PropTypes.func.isRequired,
  };

  state = {
    info: "",
  };

  prepareBody = (values) => {
    const { isEdit, params } = this.props;

    const data = {
      type: "promotion_type",
      name: values.name,
      slug: values.slug,
      slug_autogenerated: values.autoSlug,
      number_of_apps: Number(values.appsCount),
      software_assets: values.softwareAssets.map((sa) => ({
        type: "software_asset",
        id: sa,
      })),
      distributors: values.distributors.map((distributorId) => ({
        type: "organization",
        id: distributorId,
      })),
      custom_icon: values.icon,
      custom_thumbnail: values.thumbnail,
      custom_title: values.title,
      custom_description: values.description,
      additional_background_image: values.background,
      additional_metadata: values.json,
      additional_action: values.action,
      relationshipNames: ["software_assets"],
      additional_application_assets: values.contentItems,
    };

    if (isEdit) {
      data.id = params.id;
    }

    return createBody(data);
  };

  updatePromotionType = async (values) => {
    const { updatePromotionType, params } = this.props;

    const body = this.prepareBody(values);
    const { error } = await updatePromotionType(params.id, body);

    if (error) {
      throw prepareErrorsForForm(error, jsonPointerToFieldName);
    }
  };

  createPromotionType = async (values) => {
    const { createPromotionType } = this.props;

    const body = this.prepareBody(values);
    const { error } = await createPromotionType(body);

    if (error) {
      throw prepareErrorsForForm(error, jsonPointerToFieldName);
    }
  };

  onSubmit = async (values, { setErrors }) => {
    const { isEdit, navigate } = this.props;

    try {
      if (isEdit) {
        await this.updatePromotionType(values);
      } else {
        await this.createPromotionType(values);
      }

      const message = isEdit
        ? trans.CHANGES_SAVE_SUCCESS()
        : trans.PROMOTION_TYPE_CREATE__SUCCESS();

      navigate(
        { pathname: "/promotions/admin/types" },
        { state: { message: message } }
      );
    } catch (errors) {
      const availabilityKey = FORM.availability.name;
      if (availabilityKey in errors) {
        errors[availabilityKey] =
          Object.values(errors[availabilityKey]._error ?? {})[0] ??
          trans.ERROR_SERVER_VALIDATION();
      }
      setErrors(errors);
    }
  };

  render() {
    const { isEdit, initialValues } = this.props;

    const isBoundToPromotion = initialValues.promotionsCount > 0;

    return (
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validate={validate}
        >
          {({ values, errors, dirty, isSubmitting, submitCount }) => (
            <Form>
              <Section
                header={trans.PROMOTION_TYPE_EDIT__SECTION_BASIC_DETAILS()}
              >
                <TextField {...FORM.name} />
                <SlugField
                  {...FORM.slug}
                  disabled={isBoundToPromotion}
                  nameFieldValue={values.name}
                  validate={createFieldValidator(isRequired)}
                />
                <SelectField
                  {...FORM.appsCount}
                  disabled={isBoundToPromotion}
                />
                <FieldArray name={FORM.softwareAssets.name}>
                  {(arrayHelpers) => (
                    <SoftwareAssetsField
                      {...FORM.softwareAssets}
                      arrayHelpers={arrayHelpers}
                      disabled={isBoundToPromotion}
                    />
                  )}
                </FieldArray>
              </Section>
              <Section
                className={styles.metadataSection}
                header={trans.PROMOTION_TYPE_EDIT__SECTION_METADATA()}
              >
                <Subsection>
                  {[
                    FORM.icon,
                    FORM.thumbnail,
                    FORM.title,
                    FORM.description,
                    FORM.background,
                    FORM.json,
                    FORM.action,
                    FORM.contentItems,
                  ].map((fieldProps) => (
                    <CheckboxField
                      {...fieldProps}
                      key={fieldProps.name}
                      disabled={isBoundToPromotion}
                    />
                  ))}
                </Subsection>
              </Section>
              <Section
                className={styles.metadataSection}
                header={trans.PROMOTION_TYPE_EDIT__ORGANIZATIONS_SECTION_HEADER()}
              >
                <AvailabilityField {...FORM.availability} />
              </Section>
              {this.state.info && !dirty && <Info>{this.state.info}</Info>}
              <FormHasErrors
                submitFailed={Object.keys(errors).length > 0 && submitCount > 0}
              />
              <ButtonsWrapper>
                <Button
                  type="green"
                  disabled={!dirty || isSubmitting}
                  processing={isSubmitting}
                  buttonType="submit"
                  dataTestId="submit-button"
                >
                  {isEdit
                    ? trans.SUBMIT_CHANGES()
                    : trans.PROMOTION_TYPE_CREATE__SUBMIT_BUTTON()}
                </Button>
              </ButtonsWrapper>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
