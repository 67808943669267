// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import {
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import { PromotionActionMenu } from "./PromotionActionMenu";
import styles from "./PromotionRow.scss";

export class PromotionRow extends Component {
  static propTypes = {
    promotion: PropTypes.object,
    forAdmin: PropTypes.bool,
  };

  render() {
    const { promotion, forAdmin } = this.props;
    const { id, name, appsToDisplay, type, campaignsCount, isReadonly } =
      promotion;

    const appsNamesStr = appsToDisplay.join(", ");

    return (
      <TableRow>
        <TableRowColumnExtended
          className={styles.bold}
          text={name}
          to={`/promotions${forAdmin ? "/admin" : ""}/edit/${id}`}
          rightPillText={isReadonly && trans.PREDEFINED()}
        />
        <TableRowColumn className={styles.apps}>
          {appsNamesStr || NO_VALUE_PLACEHOLDER}
        </TableRowColumn>
        <TableRowColumn className={styles.normal}>{type}</TableRowColumn>
        <TableRowColumn className={styles.normal}>
          {campaignsCount}
        </TableRowColumn>
        <TableRowColumn>
          <PromotionActionMenu
            promotionId={id}
            promotionName={name}
            campaignsCount={campaignsCount}
            forAdmin={forAdmin}
            isReadonly={isReadonly}
          />
        </TableRowColumn>
      </TableRow>
    );
  }
}
