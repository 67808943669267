// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { ValidationBadge } from "components/form";
import { Tooltip } from "components/popups";
import { classes } from "utils/classes";

import styles from "./switch.scss";

export class Switch extends Component {
  static propTypes = {
    /** Replacement for label */
    children: PropTypes.node,
    className: PropTypes.string,
    /** Label string displayed to the user */
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
    ]),
    /**
     * Change callback.
     *
     * Type: (e: Event) => void
     */
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    /** Children displayed if checkbox checked */
    childrenIfChecked: PropTypes.node,
    dataTestId: PropTypes.string,
    /**
     * Formik props should be passed in 'props', if validation used */
    props: PropTypes.shape({
      name: PropTypes.string,
    }),
  };

  static defaultProps = {
    props: {},
  };

  render() {
    const {
      children,
      label,
      labelClassName,
      tooltip = "",
      className,
      childrenIfChecked,
      dataTestId,
      props,
      ...field
    } = this.props;
    const { name, value, onChange, checked, disabled, error, touched } = field;

    return (
      <div className={classes(className)}>
        <Tooltip content={tooltip}>
          <label
            htmlFor={"form-id-" + name}
            className={classes(
              styles.wrapper,
              {
                [styles.disabled]: disabled,
                [styles.checked]: checked,
              },
              labelClassName
            )}
          >
            <input
              className={styles.input}
              id={"form-id-" + name}
              disabled={disabled}
              type="checkbox"
              value={value}
              checked={checked}
              onChange={onChange}
              data-test-id={dataTestId}
              name={name}
            />
            <span className={styles.switch} />
            <div className={styles.children}>{children ? children : label}</div>
          </label>
        </Tooltip>
        <ValidationBadge error={error} touched={touched} />
        {checked && childrenIfChecked}
      </div>
    );
  }
}
