// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as Sentry from "@sentry/browser";
import localforage from "localforage";
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import createActionBuffer from "redux-action-buffer";
import { persistStore, autoRehydrate } from "redux-persist";
import { REHYDRATE } from "redux-persist/constants";
import { thunk } from "redux-thunk";

import config from "./config";
import reducers from "./reducers";
import { reduxMiddlewareForSentry } from "./reduxMiddlewareForSentry";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: config.sentry.url,
    release: config.sentry.release,
    environment: config.sentry.environment,
    autoSessionTracking: false,
  });
}

const middlewares = [thunk, createActionBuffer(REHYDRATE)];

if (process.env.NODE_ENV === "production") {
  middlewares.push(reduxMiddlewareForSentry);
}

const enhancer = compose(
  autoRehydrate(),
  applyMiddleware(...middlewares),
  window.devToolsExtension ? window.devToolsExtension() : (f) => f
);

export const configureStore = () => {
  const store = createStore(reducers, {}, enhancer);

  persistStore(store, {
    storage: localforage,
    whitelist: ["themeSwitcher", "menuSwitcher"],
  });
  return { store };
};

export const sharedDefaultValues = {
  defaultLanguageCode: "en",
  supportEmail: "tvapps-support@vewd.com",
  snapBaseUrl: "http://apps.tvstore.opera.com/video_application/",
};
