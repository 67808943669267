// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

// This end and start dates are formatted YYYY-MM-DD; when using
// Date contructor, dates with no timezone specified asuume the use
// of UTC and then convert it to local system timezone, which can
// flip the day in some cases.

// This is a workaround which uses a different new Date overload;
// first argument is just year, then month and day, which returns
// the correct date adjusted to local timezone.

export const adaptDate = (dateString = "") => {
  const [year, month, day] = dateString.split("-").map(Number);

  // This constructor uses 0-based months, but 1-based years and days
  return new Date(year, month - 1, day);
};
