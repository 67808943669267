// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "utils/decorators/withRouter";

/** Scroll to top on each location change. Uses `@withRouter` decorator internally. */
@withRouter
class ScrollToTop extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;

    if (pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

// styleguidist does not like exporting classes that use decorators
export { ScrollToTop };
