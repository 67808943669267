import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useMatch, useParams } from "react-router-dom";

import { v4 as uuid } from "uuid";

import * as actions from "./actions";

export const useBreadcrumbs = ({ name, nameFromMatch, path }) => {
  const dispatch = useDispatch();
  const { current: id } = useRef(uuid());
  const params = useParams();
  const match = useMatch(location.pathname);

  const getName = () => {
    if (!name && !nameFromMatch) {
      console.warn(
        "ExtendedRoute requires 'name' or 'nameFromMatch' property."
      );
      return "";
    }

    if (name && nameFromMatch) {
      console.warn(
        "Properties 'name' and 'nameFromMatch' provided to the ExtendedRoute exclude each other."
      );
    }
    if (nameFromMatch) {
      return nameFromMatch(params);
    }

    return name;
  };

  const addBreadcrumb = () => {
    const computedName = getName();
    const url = path || match?.pathname;

    dispatch(actions.addBreadcrumb(id, computedName, url));
  };

  const removeBreadcrumb = () => {
    dispatch(actions.removeBreadcrumb(id));
  };

  return { addBreadcrumb, removeBreadcrumb };
};
