// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getDeviceFilterQuery = gql`
  query deviceFilters(
    $pagination: PaginationInput
    $sort: DeviceFiltersSortInput
    $search: SearchInput
    $ids: [ID!]
  ) {
    deviceFilters(
      pagination: $pagination
      sort: $sort
      search: $search
      deviceFilterIds: $ids
    ) {
      deviceFilters {
        items {
          id
          name
          devices(pagination: { noLimit: true }) {
            pagination {
              count
            }
          }
        }
      }
    }
  }
`;
