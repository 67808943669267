// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { UploaderWithPreviewField, TextField } from "components/form/fields";
import { Column, Row } from "components/layout";

import styles from "./editUserForm.scss";

export const UserBasicDetails = ({
  textFieldsConfig,
  uploaderWithPreviewFieldConfig,
}) => {
  return (
    <Row>
      <Column>
        {textFieldsConfig.map((fieldConfig) => (
          <TextField key={fieldConfig.name} {...fieldConfig} />
        ))}
      </Column>
      <Column className={styles.avatarColumn}>
        <UploaderWithPreviewField {...uploaderWithPreviewFieldConfig} />
      </Column>
    </Row>
  );
};

UserBasicDetails.propTypes = {
  textFieldsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      dataTestId: PropTypes.string,
      readOnly: PropTypes.bool,
      tooltip: PropTypes.string,
    })
  ).isRequired,
  uploaderWithPreviewFieldConfig: PropTypes.shape({
    name: PropTypes.string,
    formats: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.string,
    info: PropTypes.string,
    validationImgOptions: PropTypes.object,
  }),
};
