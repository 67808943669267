// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { TableRow, TableRowColumn } from "components/layout";
import { SECTIONS } from "pages/Snap/shared/constants";
import styles from "pages/Snap/shared/Translations.scss";
import * as iconStyles from "pages/Snap/SnapEdit/components/SnapIconPicker/icons.scss";

import { SectionLiveStream } from "./SectionLiveStream";

export class SectionLive extends PureComponent {
  static propTypes = {
    section: PropTypes.object,
  };

  renderLiveSectionRows = () => {
    const { section } = this.props;
    return section.live_feeds.map((feed, index) => {
      // eslint-disable-next-line react/no-array-index-key
      return <SectionLiveStream feed={feed} key={"live-stream" + index} />;
    });
  };

  render() {
    const { section } = this.props;
    return (
      <Fragment>
        <TableRow highlighting={false}>
          <TableRowColumn>
            <div className={iconStyles[section.icon.toLowerCase()]} />
          </TableRowColumn>
          <TableRowColumn colSpan={3} columnClass={styles.title}>
            {section.name}
          </TableRowColumn>
          <TableRowColumn>{SECTIONS.TRANSLATIONS.sectionLive}</TableRowColumn>
        </TableRow>
        {this.renderLiveSectionRows()}
      </Fragment>
    );
  }
}
