// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useState } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { WarningModal } from "components/layout";
import { trans } from "src/translations";
import { useSearchParams } from "utils/hooks";

export const ActionModal = ({
  actionModal,
  handleSearch,
  searchValue,
  isOpen,
  handleCloseModal,
}) => {
  const [showWarning, setShowWarning] = useState(true);
  const searchParams = useSearchParams();

  const onClose = () => {
    setShowWarning(true);
    handleCloseModal();
  };

  const isUnsavedFilter = searchValue !== (searchParams?.search || "");
  const showWarningModal = isUnsavedFilter && showWarning;

  return (
    <>
      {showWarningModal ? (
        <WarningModal
          isOpen={isOpen}
          onClose={onClose}
          title={trans.UNSAVED_CHANGES_WARNING_MODAL_HEADER()}
          size="medium"
          type="warning"
          actions={
            <ButtonsWrapper position="modal">
              <Button
                type="normal"
                onClick={() => setShowWarning(false)}
                dataTestId="dismiss-cancel-button"
              >
                {trans.UNSAVED_CHANGES_WARNING_MODAL_CANCEL()}
              </Button>
              <Button
                type="green"
                onClick={() => {
                  setShowWarning(false);
                  handleSearch(searchValue, true);
                }}
                dataTestId="dismiss-ok-button"
              >
                {trans.UNSAVED_CHANGES_WARNING_MODAL_ACCEPT()}
              </Button>
            </ButtonsWrapper>
          }
        >
          <p>{trans.DEVICES__DEVICE_INVENTORY_WARNING_MODAL_INFO()}</p>
        </WarningModal>
      ) : (
        actionModal({
          isOpen,
          handleCloseModal: onClose,
          handleSearch,
          searchValue,
        })
      )}
    </>
  );
};

ActionModal.propTypes = {
  actionModal: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
