// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { SearchBarWithFilters } from "components/elements";
import { filterSectionPropTypes } from "components/elements/SearchBarWithFilters/propTypes";
import {
  Section,
  TableBody,
  TableRow,
  TableRowColumn,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { restrictedArea, ROLES } from "containers/Permissions";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import { CommandStatus } from "../_shared/CommandStatus";
import { STATUS_NAMES } from "../_shared/constant";
import { getStatusNameForSingleTarget } from "../_shared/getSingleStatus";
import styles from "./CommandsList.scss";
import { ITEMS_PER_PAGE, TABS_CONFIG } from "./config";

@restrictedArea(() => ({
  allowed: [ROLES.deviceInventory.configControlManager],
}))
export class CommandsListRend extends Component {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    availableFilters: PropTypes.shape({
      sections: PropTypes.arrayOf(filterSectionPropTypes),
    }),
    commands: PropTypes.shape({
      results: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          creationDate: PropTypes.string,
          target: PropTypes.shape({
            collectionID: PropTypes.string,
            collectionName: PropTypes.string,
            devicePublicID: PropTypes.string,
          }),
          status: PropTypes.shape({
            processing: PropTypes.number,
            sent: PropTypes.number,
            received: PropTypes.number,
            rejected: PropTypes.number,
          }),
          timeToLive: PropTypes.number,
          message: PropTypes.string,
        })
      ),
      count: PropTypes.number.isRequired,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
      defaultOrder: PropTypes.object,
    }),
  };

  renderTableBody(commands) {
    return (
      <TableBody>
        {commands.map(
          ({ id, name, creationDate, target, status, timeToLive, message }) => {
            const linkToCommandDetails = `/devices/commands/${id}`;
            const isTargetQuery = Boolean(target.collectionID);

            return (
              <TableRow key={id}>
                <TableRowColumn>
                  <Link to={linkToCommandDetails}>{name}</Link>
                </TableRowColumn>

                <TableRowColumn dataTestId="device-model-column">
                  {isTargetQuery
                    ? target.collectionName
                    : target.devicePublicID}
                </TableRowColumn>

                <TableRowColumn dataTestId="activation-date-column">
                  {formatDateTime(parseISO(String(creationDate)))}
                </TableRowColumn>
                <TableRowColumn dataTestId="last-activity-date-column">
                  <CommandStatus
                    status={
                      isTargetQuery
                        ? STATUS_NAMES.STATUS_IN_DETAILS
                        : getStatusNameForSingleTarget(status)
                    }
                    timeToLive={timeToLive}
                    creationDate={creationDate}
                    errorMessage={message}
                  />
                </TableRowColumn>
                <TableRowColumn dataTestId="activation-date-column">
                  <ActionMenu alignment="left">
                    <MenuItem to={linkToCommandDetails}>
                      Command details
                    </MenuItem>
                  </ActionMenu>
                </TableRowColumn>
              </TableRow>
            );
          }
        )}
      </TableBody>
    );
  }

  render() {
    return (
      <div>
        <Section
          header={
            <div className={styles.createCommandHeader}>
              {trans.DEVICES__COMMANDS_LIST_HEADER()}
              <Button
                type="green"
                dataTestId="create-command-button"
                to="/devices/commands/create"
                className={styles.createCommandLink}
              >
                {trans.DEVICES__COMMANDS_LIST_CREATE_COMMAND()}
              </Button>
            </div>
          }
        />
        <SearchBarWithFilters
          filtersDataChoices={this.props.availableFilters}
          onSearch={this.props.onSearch}
        />
        <TabbedTable
          data={this.props.commands}
          renderTableBody={this.renderTableBody}
          tabsConfig={TABS_CONFIG}
          rowsPerPage={ITEMS_PER_PAGE}
        />
      </div>
    );
  }
}
