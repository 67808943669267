// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { FieldArray } from "formik";
import PropTypes from "prop-types";

import { UploaderGroup, UploaderPreview, Uploader } from "components/form";
import { Row, Column } from "components/layout";
import { trans } from "src/translations";
import { validateImage, cropImage } from "utils/image";

import {
  BILLBOARD,
  THUMBNAIL,
  ICON_BIG,
  ICON_SMALL,
  SCREENSHOT,
} from "../constants";
import {
  createAddScreenshotBtn,
  MAX_SCREENSHOT_NUMBER,
} from "../utils/parseInitialData";

const GRAPHICS_FIELDS = [
  {
    name: "metadata.thumbnail",
    kind: "thumbnail",
    label: trans.APP_EDIT__THUMBNAIL_UPLOADER_LABEL(),
    formats: ["image/jpeg"],
    dataTestId: "thumbnail-field",
    info: trans.IMAGE_REQUIREMENTS({
      format: "jpeg",
      minResolution: `${THUMBNAIL.MIN_WIDTH}X${THUMBNAIL.MIN_HEIGHT} PX`,
      aspectRatio: THUMBNAIL.ASPECT_RATIO,
    }),
    imageValidationOptions: {
      minWidth: THUMBNAIL.MIN_WIDTH,
      minHeight: THUMBNAIL.MIN_HEIGHT,
      validateAspectRatio: true,
    },
    graphicDimensions: [THUMBNAIL.MIN_WIDTH, THUMBNAIL.MIN_HEIGHT],
    tooltip: trans.APP_EDIT__THUMBNAIL_UPLOADER_TOOLTIP(),
  },
  {
    name: "metadata.billboard",
    kind: "billboard",
    label: trans.APP_EDIT__BILLBOARD_UPLOADER_LABEL(),
    formats: ["image/jpeg", "image/png"],
    dataTestId: "billboard-field",
    info: trans.IMAGE_REQUIREMENTS({
      format: `jpeg ${trans.OR()} png`,
      minResolution: `${BILLBOARD.MIN_WIDTH}X${BILLBOARD.MIN_HEIGHT} PX`,
      aspectRatio: BILLBOARD.ASPECT_RATIO,
    }),
    imageValidationOptions: {
      minWidth: BILLBOARD.MIN_WIDTH,
      minHeight: BILLBOARD.MIN_HEIGHT,
      validateAspectRatio: true,
    },
    graphicDimensions: [BILLBOARD.MIN_WIDTH, BILLBOARD.MIN_HEIGHT],
    tooltip: trans.APP_EDIT__BILLBOARD_UPLOADER_TOOLTIP(),
  },
  {
    name: "metadata.icon_big",
    kind: "icon_big",
    label: trans.APP_EDIT__ICON_BIG_UPLOADER_LABEL(),
    formats: ["image/jpeg", "image/png"],
    dataTestId: "icon-big-field",
    info: trans.IMAGE_REQUIREMENTS({
      format: `jpeg ${trans.OR()} png`,
      minResolution: `${ICON_BIG.MIN_WIDTH}X${ICON_BIG.MIN_HEIGHT} PX`,
      aspectRatio: ICON_BIG.ASPECT_RATIO,
    }),
    imageValidationOptions: {
      minWidth: ICON_BIG.MIN_WIDTH,
      minHeight: ICON_BIG.MIN_HEIGHT,
      validateAspectRatio: true,
    },
    graphicDimensions: [ICON_BIG.MIN_WIDTH, ICON_BIG.MIN_HEIGHT],
    tooltip: trans.APP_EDIT__ICON_BIG_UPLOADER_TOOLTIP(),
  },
  {
    name: "metadata.icon_small",
    kind: "icon_small",
    label: trans.APP_EDIT__ICON_SMALL_UPLOADER_LABEL(),
    formats: ["image/jpeg", "image/png"],
    dataTestId: "icon-small-field",
    info: trans.IMAGE_REQUIREMENTS({
      format: `jpeg ${trans.OR()} png`,
      minResolution: `${ICON_SMALL.MIN_WIDTH}X${ICON_SMALL.MIN_HEIGHT} PX`,
      aspectRatio: ICON_SMALL.ASPECT_RATIO,
    }),
    imageValidationOptions: {
      minWidth: ICON_SMALL.MIN_WIDTH,
      minHeight: ICON_SMALL.MIN_HEIGHT,
      validateAspectRatio: true,
    },
    graphicDimensions: [ICON_SMALL.MIN_WIDTH, ICON_SMALL.MIN_HEIGHT],
    tooltip: trans.APP_EDIT__ICON_SMALL_UPLOADER_TOOLTIP(),
  },
];

export class Media extends Component {
  static propTypes = {
    isThumbnailRequired: PropTypes.bool,
    isBillboardRequired: PropTypes.bool,
    isIconBigRequired: PropTypes.bool,
    isIconSmallRequired: PropTypes.bool,
    isScreenshotRequired: PropTypes.bool,
    // from @Formik
    getFieldMeta: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isThumbnailRequired: true,
    isBillboardRequired: false,
    isIconBigRequired: true,
    isIconSmallRequired: true,
    isScreenshotRequired: true,
  };

  state = {
    currentScreenshot: 0,
  };

  getScreenshots() {
    const { getFieldMeta } = this.props;
    const { value: screenshot } = getFieldMeta("metadata.screenshot");
    return screenshot || [];
  }

  getPreviewedScreenshotUrl() {
    const { currentScreenshot } = this.state;
    const screenshots = this.getScreenshots();
    return screenshots[currentScreenshot] && screenshots[currentScreenshot].url;
  }

  changeScreenshot = (index) => {
    this.setState({ currentScreenshot: index });
  };

  areAllScreenshotsValid() {
    const screenshots = this.getScreenshots();
    const isScreenshotValid = (screenshot) => {
      const value = screenshot.url;
      return Boolean(value) && value !== "";
    };
    return screenshots.every(isScreenshotValid);
  }

  canAddMoreScreenshots() {
    const screenshots = this.getScreenshots();
    return (
      this.areAllScreenshotsValid() &&
      screenshots.length < MAX_SCREENSHOT_NUMBER
    );
  }

  handleNewScreenshot = ({ push }) => {
    if (this.canAddMoreScreenshots()) {
      push(createAddScreenshotBtn());
    }
  };

  handleRemoveScreenshot = (index, { remove }) => {
    const { currentScreenshot } = this.state;
    if (index === currentScreenshot) {
      this.setState({ currentScreenshot: 0 });
    }
    remove(index);
  };

  render() {
    const {
      isThumbnailRequired,
      isBillboardRequired,
      isIconBigRequired,
      isIconSmallRequired,
      isScreenshotRequired,
      values,
      ...rest
    } = this.props;

    const isRequired = {
      "metadata.thumbnail": isThumbnailRequired,
      "metadata.billboard": isBillboardRequired,
      "metadata.icon_big": isIconBigRequired,
      "metadata.icon_small": isIconSmallRequired,
    };

    return (
      <Row>
        <Column>
          {GRAPHICS_FIELDS.map((props, index) => (
            <Uploader
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              {...props}
              validate={(blobUrl) =>
                validateImage(blobUrl, props.imageValidationOptions)
              }
              preprocess={(blobUrl, type) =>
                cropImage(blobUrl, type, ...props.graphicDimensions)
              }
              required={isRequired[props.name]}
              disablePreview={true}
            />
          ))}
          <FieldArray
            name="metadata.screenshot"
            render={(arrayHelpers) => (
              <UploaderGroup
                currentlyPreviewedId={this.state.currentScreenshot}
                onPreviewClick={this.changeScreenshot}
                label={trans.APP_EDIT__SCREENSHOTS_UPLOADER_LABEL()}
                kind="screenshot"
                formats={["image/jpeg"]}
                info={trans.IMAGE_REQUIREMENTS({
                  format: "jpeg",
                  minResolution: `${SCREENSHOT.MIN_WIDTH}X${SCREENSHOT.MIN_HEIGHT} PX`,
                  aspectRatio: SCREENSHOT.ASPECT_RATIO,
                })}
                fieldName={arrayHelpers.name}
                validate={(blobUrl) =>
                  validateImage(blobUrl, {
                    minWidth: SCREENSHOT.MIN_WIDTH,
                    minHeight: SCREENSHOT.MIN_HEIGHT,
                    validateAspectRatio: true,
                  })
                }
                preprocess={(blobUrl, type) =>
                  cropImage(
                    blobUrl,
                    type,
                    SCREENSHOT.MIN_WIDTH,
                    SCREENSHOT.MIN_HEIGHT
                  )
                }
                required={isScreenshotRequired}
                onNewItem={() => this.handleNewScreenshot(arrayHelpers)}
                onRemove={(index) => {
                  this.handleRemoveScreenshot(index, arrayHelpers);
                }}
                dataTestId="screenshots-field"
                tooltip={trans.APP_EDIT__SCREENSHOTS_UPLOADER_TOOLTIP()}
                disablePreview={true}
                {...rest}
              />
            )}
          />
        </Column>
        <Column>
          <UploaderPreview
            icon={values.metadata?.icon_big?.url}
            thumbnail={values.metadata?.thumbnail?.url}
            billboard={values.metadata?.billboard?.url}
            icon_small={values.metadata?.icon_small?.url}
            screenshot={this.getPreviewedScreenshotUrl()}
          />
        </Column>
      </Row>
    );
  }
}
