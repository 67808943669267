// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { isDistributorSelected } from "reduxReusable/selectedTargets/selectors";

import { ColoredText } from "components/elements";
import { Checkbox } from "components/form/elements";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { NO_VALUE_PLACEHOLDER } from "src/constants";

import { getRowExpandIcon } from "./getRowExpandIcon";
import styles from "./OrganizationRow.scss";
import { SELECTED_TARGETS_STORE_PATH } from "./redux/constants";

const makeMapStateToProps = () => {
  return (state, props) => {
    const distributorId = props.id;

    return {
      isDistributorSelected: isDistributorSelected(state, {
        storePath: SELECTED_TARGETS_STORE_PATH,
        distributorId,
      }),
    };
  };
};

@connect(makeMapStateToProps)
export class OrganizationRow extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    isExpandable: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    onChecked: PropTypes.func.isRequired,
    isDistributorSelected: PropTypes.bool.isRequired,
    pendingDevicesCount: PropTypes.number.isRequired,
    acceptedDevicesCount: PropTypes.number.isRequired,
    rejectedDevicesCount: PropTypes.number.isRequired,
    onHoldDevicesCount: PropTypes.number.isRequired,
    isReadonly: PropTypes.bool,
  };

  handleChange = () => {
    const { id, onChecked } = this.props;
    onChecked(id);
  };

  renderStatusCount(count, color, className = styles.statusCountColumn) {
    return (
      <TableRowColumn className={className}>
        {count > 0 ? (
          <ColoredText color={color}>{count}</ColoredText>
        ) : (
          NO_VALUE_PLACEHOLDER
        )}
      </TableRowColumn>
    );
  }

  render() {
    const {
      id,
      name,
      onToggle,
      isDistributorSelected,
      isExpanded,
      isExpandable,
      pendingDevicesCount,
      acceptedDevicesCount,
      rejectedDevicesCount,
      onHoldDevicesCount,
      isReadonly,
    } = this.props;

    return (
      <TableRow className={styles.row}>
        <TableRowColumn>
          {!isReadonly && (
            <Checkbox
              value={name}
              onChange={this.handleChange}
              checked={isDistributorSelected}
            />
          )}
        </TableRowColumn>
        <TableRowColumnExtended
          className={styles.columnName}
          fontIcon={getRowExpandIcon(isExpandable, isExpanded)}
          fontIconClassName={isExpanded ? styles.expandedIcon : ""}
          fontIconType="micro"
          fontIconSize="sm"
          text={name}
          onClick={onToggle(id)}
        />
        {this.renderStatusCount(pendingDevicesCount, "yellow")}
        {this.renderStatusCount(acceptedDevicesCount, "green")}
        {this.renderStatusCount(rejectedDevicesCount, "red")}
        {this.renderStatusCount(
          onHoldDevicesCount,
          "red",
          styles.onHoldCountColumn
        )}
      </TableRow>
    );
  }
}
