// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { TableRow, TableRowColumn } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import playImage from "images/play.svg";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { innerRefType } from "utils/types";

import { ModeContext } from "../context";
import { CONTENT_TYPES } from "./constants";
import styles from "./ContentItemRow.scss";

const getContentItemImage = (contentItem) => {
  return contentItem.contentType === CONTENT_TYPES.FULL_PAGE_LANDSCAPE
    ? get(contentItem, "fullPageLandscape.url")
    : get(contentItem, "videoUrlThumbnail.url");
};

const getPlaceholderIfNotExists = (text) =>
  !text || text === "" ? trans.NO_DATA() : text;

export const ContentItemRow = ({
  contentItem,
  index,
  onEdit,
  onRemove,
  isDragging,
  innerRef,
  error,
  ...draggingProps
}) => {
  const { isReadonly } = useContext(ModeContext);
  return (
    <TableRow
      highlighting={false}
      dragProps={draggingProps}
      innerRef={innerRef}
      className={classes(styles.row, {
        [styles.dragging]: isDragging,
        [styles.error]: error,
      })}
    >
      <TableRowColumn columnClass={styles.dragColumn}>
        {!isReadonly && (
          <GrabsonIcon name="dragdrop2" className={styles.dragIcon} />
        )}
      </TableRowColumn>
      <TableRowColumn columnClass={styles.titleColumn}>
        {getPlaceholderIfNotExists(contentItem.customTitle)}
      </TableRowColumn>
      <TableRowColumn columnClass={styles.contentColumn}>
        <img
          src={getContentItemImage(contentItem)}
          className={styles.imgPreview}
        />
        {contentItem.contentType === CONTENT_TYPES.VIDEO_URL && (
          <img src={playImage} className={styles.playImage} />
        )}
      </TableRowColumn>
      <TableRowColumn
        columnClass={classes(styles.descriptionColumn, {
          [styles.noDataDescription]: !contentItem.customDescription,
        })}
      >
        {getPlaceholderIfNotExists(contentItem.customDescription)}
      </TableRowColumn>
      <TableRowColumn columnClass={styles.menuColumn}>
        {!isReadonly && (
          <ActionMenu>
            <MenuItem onClick={() => onEdit({ editItemIdx: index })}>
              {trans.EDIT_DETAILS()}
            </MenuItem>
            <MenuItem onClick={() => onRemove(index)}>
              {trans.DELETE()}
            </MenuItem>
          </ActionMenu>
        )}
      </TableRowColumn>
    </TableRow>
  );
};

ContentItemRow.propTypes = {
  contentItem: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isDragging: PropTypes.bool,
  innerRef: innerRefType,
  index: PropTypes.number,
  error: PropTypes.object,
};
