// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Pill } from "components/elements";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./DeviceUpdateJobStatus.scss";

const statuses = {
  SCHEDULED: {
    color: "grey",
    label: trans.DEVICES__DEVICE_DETAILS__UPDATE_LABEL_SCHEDULED(),
  },
  WAITING: {
    color: "grey",
    label: trans.DEVICES__DEVICE_DETAILS__UPDATE_LABEL_WAITING(),
  },
  ONGOING: {
    color: "green",
    label: trans.DEVICES__DEVICE_DETAILS__UPDATE_LABEL_ONGOING(),
  },
  FAILURE: {
    color: "red",
    label: trans.DEVICES__DEVICE_DETAILS__UPDATE_LABEL_FAILURE(),
  },
  SUCCESS: {
    color: "green",
    label: trans.DEVICES__DEVICE_DETAILS__UPDATE_LABEL_SUCCESS(),
  },
};

export const DeviceUpdateJobStatus = ({ status, className }) => {
  const matchingStatus = statuses[status];
  if (!matchingStatus) {
    return null;
  }

  return (
    <Pill
      text={matchingStatus.label}
      className={classes(
        styles[matchingStatus.color],
        styles.middle,
        className
      )}
      dataTestId="device-update-job-status"
    />
  );
};

DeviceUpdateJobStatus.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};
