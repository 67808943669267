// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { GrabsonIcon } from "components/icons";
import { Modal } from "components/layout";
import { Link } from "components/typography";
import { CONTACT_US_MAIL_AS_LINK } from "src/constants";
import { trans } from "src/translations";

import styles from "./UpdateJobLogs.scss";

export const LogsDownloadModal = ({
  isOpen,
  onClose,
  onConfirm,
  isSubmitting,
  error,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Fragment>
          <GrabsonIcon className={styles.icon} name="download" size="xl" />{" "}
          {trans.UPDATES__UPDATE_DETAILS__LOGS_MODAL_TITLE()}
        </Fragment>
      }
      dataTestId="upload-software-package-modal"
      size="medium"
      actions={
        <ButtonsWrapper position="modal">
          <Button type="normal" onClick={onClose} dataTestId="close-button">
            {trans.CANCEL()}
          </Button>
          <Button
            type="green"
            onClick={onConfirm}
            dataTestId="download-button"
            disabled={isSubmitting}
            processing={isSubmitting}
          >
            {trans.DOWNLOAD()}
          </Button>
        </ButtonsWrapper>
      }
    >
      {trans.UPDATES__UPDATE_DETAILS__LOGS_MODAL_TEXT()}{" "}
      <Link href={CONTACT_US_MAIL_AS_LINK}>{trans.CONTACT_US()}</Link>
      {"."}
      {error && (
        <Info type="error" className={styles.errorMessage}>
          {error}
        </Info>
      )}
    </Modal>
  );
};

LogsDownloadModal.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  error: PropTypes.string,
};
