// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBarWithFilters } from "components/elements/SearchBarWithFilters";
import { filterSectionPropTypes } from "components/elements/SearchBarWithFilters/propTypes";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { TabContent } from "components/navigation";
import { Tooltip } from "components/popups";
import { Link } from "components/typography";
import { trans } from "src/translations";
import { CountriesPropType } from "utils/continents";

import {
  DeviceInventoryTable,
  DEVICE_PROP_TYPES,
} from "../_shared/DeviceInventoryTable";
import { fromDevicePropertyGetCountry } from "../DeviceDetails/components";
import { ImportManagerAttributes } from "./components";
import styles from "./DeviceInventoryRend.scss";
import { DeviceInventoryTabs } from "./DeviceInventoryTabs";
import { DeviceInventoryWithAlertsTabContent } from "./DeviceInventoryWithAlertsTabContent";
import { SaveDeviceInventoryFilterModal } from "./SaveDeviceInventoryFilterModal";

export const DEVICE_INVENTORY_DEFAULT_PATH = "/devices/inventory";
export const DEVICE_INVENTORY_ALERTS_PATH = "/devices/inventory/alerts";
export const DEVICE_INVENTORY_DISMISSED_PATH = "/devices/inventory/dismissed";

export class DeviceInventoryRend extends PureComponent {
  static propTypes = {
    searchValue: PropTypes.string,
    filters: PropTypes.object,
    handleFiltersChange: PropTypes.func,
    path: PropTypes.string,
    availableFilters: PropTypes.shape({
      sections: PropTypes.arrayOf(filterSectionPropTypes).isRequired,
    }),

    countries: CountriesPropType,

    results: PropTypes.arrayOf(DEVICE_PROP_TYPES).isRequired,
    loading: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
    devicesWithAlertCount: PropTypes.number,
    fetchDevicesWithActiveAlertCount: PropTypes.func.isRequired,
    error: PropTypes.node,
  };

  state = {
    savedFilterName: null,
    successMessage: null,
  };

  onSaveModalSuccess = (name) => {
    this.setState({
      savedFilterName: name,
      successMessage: null,
    });
  };

  setSuccessImportMessage = (msg) => {
    this.setState({
      successMessage: msg,
      savedFilterName: null,
    });
  };

  renderSaveFilterButton = (handleOpenModal) => (
    <ButtonsWrapper>
      <Tooltip
        content={trans.DEVICES__DEVICE_INVENTORY_SAVE_FILTER_TOOLTIP()}
        alignment={"left"}
      >
        <Button onClick={handleOpenModal} dataTestId={"save-filter-button"}>
          {trans.DEVICES__DEVICE_INVENTORY_SAVE_FILTER()}
        </Button>
      </Tooltip>
    </ButtonsWrapper>
  );

  getSuccessMessage() {
    const { savedFilterName, successMessage } = this.state;

    if (savedFilterName != null) {
      return (
        <div className={styles.successInfo}>
          {trans.DEVICES__DEVICE_INVENTORY_FILTER_SAVED_A({
            name: savedFilterName,
          })}{" "}
          <Link to={"/devices/filters"}>
            {trans.DEVICES__FILTERS_AND_QUERIES_ROUTE_NAME()}
          </Link>{" "}
          {trans.DEVICES__DEVICE_INVENTORY_FILTER_SAVED_B()}
        </div>
      );
    }

    return successMessage;
  }

  renderTabContent = () => {
    const {
      path,
      filters,
      handleFiltersChange,
      availableFilters,
      countries,
      results,
      error,
      count,
      loading,
      fetchDevicesWithActiveAlertCount,
    } = this.props;

    if (path === DEVICE_INVENTORY_DEFAULT_PATH) {
      return (
        <>
          <Section>
            <SearchBarWithFilters
              searchParams={filters}
              onSearch={handleFiltersChange}
              placeholder={trans.DEVICES__DEVICE_INVENTORY_SEARCH_PLACEHOLDER()}
              filtersDataChoices={availableFilters}
              actionModal={({ isOpen, handleCloseModal }) => {
                return (
                  <SaveDeviceInventoryFilterModal
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    countries={countries || {}}
                    onSave={this.onSaveModalSuccess}
                    queryParams={filters}
                  />
                );
              }}
              actionTrigger={this.renderSaveFilterButton}
              hasExcludeOption
            />
          </Section>
          <Section>
            <DeviceInventoryTable
              results={results}
              loading={loading}
              count={count}
              error={error}
              fetchDevicesWithActiveAlertCount={
                fetchDevicesWithActiveAlertCount
              }
              getCountry={fromDevicePropertyGetCountry("country", countries)}
            />
          </Section>
        </>
      );
    }

    return <DeviceInventoryWithAlertsTabContent {...this.props} />;
  };

  render() {
    const { path, devicesWithAlertCount } = this.props;
    const successMessage = this.getSuccessMessage();
    return (
      <Fragment>
        <Section
          header={
            <Fragment>
              {trans.DEVICES__DEVICE_INVENTORY_MY_DEVICES_HEADER()}
              <ImportManagerAttributes
                setSuccessImportMessage={this.setSuccessImportMessage}
                className={styles.importCSVButton}
              />
            </Fragment>
          }
          className={styles.text}
        >
          {trans.DEVICES__DEVICE_INVENTORY_MY_DEVICES_TEXT_A()}
          <br />
          {trans.DEVICES__DEVICE_INVENTORY_MY_DEVICES_TEXT_B()}
        </Section>

        {successMessage && (
          <Section>
            <Info type="normal">{successMessage}</Info>
          </Section>
        )}
        <Section>
          <DeviceInventoryTabs
            path={path}
            devicesWithAlertCount={devicesWithAlertCount}
          />
        </Section>
        <Section>
          <TabContent>{this.renderTabContent()}</TabContent>
        </Section>
      </Fragment>
    );
  }
}
