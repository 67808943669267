// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const textFieldsConfig = [
  {
    name: "email",
    label: trans.EMAIL(),
    dataTestId: "email-field",
    tooltip: trans.EMAIL(),
    readOnly: true,
    type: "email",
  },
  {
    name: "given_name",
    label: trans.FIRST_NAME(),
    tooltip: trans.FIRST_NAME(),
    dataTestId: "first-name-field",
  },
  {
    name: "family_name",
    label: trans.LAST_NAME(),
    tooltip: trans.LAST_NAME(),
    dataTestId: "last-name-field",
  },
];

export const avatarConfig = {
  name: "avatar",
  formats: ["image/png"],
  label: "Avatar",
  info: trans.USER_EDIT_AVATAR_IMAGE_INFO({
    format: "PNG",
    maxResolution: "256x256",
    aspectRatio: "1:1",
  }),
  validationImgOptions: {
    maxWidth: 256,
    maxHeight: 256,
    minWidth: 128, // we need to check whether img has 1:1 aspect ratio
    minHeight: 128,
    validateAspectRatio: true,
  },
};

export const fieldArrayName = "roles";
