// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as actions from "./actionTypes";

export const addAssetDeviceActive = (asset, versionNumber) => ({
  type: actions.ADD_ASSET_DEVICE_ACTIVE,
  asset,
  versionNumber,
});

export const addAssetDeviceInactive = () => ({
  type: actions.ADD_ASSET_DEVICE_INACTIVE,
});
