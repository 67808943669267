// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectById } from "containers/Layout/SideMenu/redirect/RedirectById";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";
import { trans } from "src/translations";

import { CreateOrganization } from "./CreateOrganization";
import {
  LayoutOrganization,
  LayoutMyOrganization,
  EditOrganization,
  OrganizationDetailsData,
} from "./Organization";
import { OrganizationsTable } from "./OrganizationsTable";

export class OrganizationRoute extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<RedirectDependingOnRoles />} />
        <Route exact path="/list" element={<OrganizationsTable />} />
        <Route
          exact
          path="/create"
          element={
            <ExtendedRoute
              name={trans.CREATE()}
              component={CreateOrganization}
            />
          }
        />
        <Route
          exact
          path="/my"
          element={<Navigate to="/organization/my/details" replace={true} />}
        />
        <Route
          exact
          path="/my/edit"
          element={
            <ExtendedRoute
              name={trans.EDIT()}
              layout={LayoutMyOrganization}
              component={EditOrganization}
            />
          }
        />
        <Route
          exact
          path="/my/details"
          element={
            <ExtendedRoute
              name={trans.DETAILS()}
              layout={LayoutMyOrganization}
              component={OrganizationDetailsData}
            />
          }
        />
        <Route
          exact
          path="/:id"
          element={<RedirectById path="/organization/:id/details/" />}
        />

        <Route
          exact
          path="/:id/edit"
          element={
            <ExtendedRoute
              name={trans.EDIT()}
              layout={LayoutOrganization}
              component={EditOrganization}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/:id/details"
          element={
            <ExtendedRoute
              name={trans.DETAILS()}
              layout={LayoutOrganization}
              component={OrganizationDetailsData}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    );
  }
}
