// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Label, Uploader } from "components/form";
import { trans } from "src/translations";

import styles from "./IconField.scss";

export const IconField = ({
  name,
  icon,
  required,
  dataTestId = "icon-field",
}) => {
  return (
    <div className={styles.assetIcon}>
      <Label
        text={trans.ASSET__ICON_LABEL()}
        tooltip={trans.ASSET__ICON_TOOLTIP()}
        tooltipAlignment="bottom-start"
        className={styles.assetIconTitle}
        required={required}
      />
      <img
        className={styles.assetIconArea}
        src={icon.url}
        alt=""
        data-test-id={`${dataTestId}-preview`}
      />
      <Uploader
        name={name}
        formats={["image/png"]}
        className={styles.uploader}
        required={required}
        disablePreview
      />
    </div>
  );
};

IconField.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  required: PropTypes.bool,
  dataTestId: PropTypes.string,
};
