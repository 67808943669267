// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import {
  TableSubRow,
  TableRowColumnExtended,
  TableRowColumn,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { RestrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";

import styles from "./familyRow.scss";

export const FamilyRow = ({
  family,
  expanded,
  onEditDeviceFamily,
  onExpandToggle,
  dataTestId = "family-row",
}) => {
  return (
    <TableSubRow dataTestId={dataTestId}>
      <TableRowColumnExtended
        className={styles.name}
        fontIcon={expanded ? "arrow-up" : "arrow-down"}
        fontIconSize="sm"
        fontIconType="micro"
        onClick={onExpandToggle}
        text={family.name}
        dataTestId={`${dataTestId}-name`}
      />
      <TableRowColumn className={styles.menu} dataTestId={`${dataTestId}-menu`}>
        <ActionMenu buttonSize="small">
          <RestrictedArea
            allowed={[ROLES.administrator.deviceAdmin]}
            showFallback={true}
            fallbackChildren={
              <MenuItem disabled={true}>{trans.EDIT_DETAILS()}</MenuItem>
            }
          >
            <MenuItem onClick={onEditDeviceFamily}>
              {trans.EDIT_DETAILS()}
            </MenuItem>
          </RestrictedArea>
        </ActionMenu>
      </TableRowColumn>
    </TableSubRow>
  );
};

FamilyRow.propTypes = {
  family: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  onEditDeviceFamily: PropTypes.func.isRequired,
  onExpandToggle: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};
