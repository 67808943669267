// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import {
  APP_ROUTE_MY,
  APP_ROUTE_MODERATION,
  SUBMISSION_STATUS,
} from "pages/Applications/constants";
import { trans } from "src/translations";

export const ApplicationPopupMenu = ({ app, appRouteType }) => {
  const isAppSubmitted = app.has_submitted_version;
  const isMyApp = appRouteType === APP_ROUTE_MY;
  const isModerationApp = appRouteType === APP_ROUTE_MODERATION;
  const hasActiveVersion = Boolean(app.active_version);

  const showMessagesItem = isMyApp || isModerationApp;

  return (
    <ActionMenu>
      {isMyApp && (
        <MenuItem
          to={`/applications/${appRouteType}/${app.id}/edit`}
          disabled={app.submission_status === SUBMISSION_STATUS.IN_REVIEW.id}
        >
          {hasActiveVersion
            ? trans.APP_LIST__ACTION_MENU_UPDATE()
            : trans.APP_LIST__ACTION_MENU_EDIT()}
        </MenuItem>
      )}
      {isAppSubmitted && (
        <MenuItem to={`/applications/${appRouteType}/${app.id}/details`}>
          {trans.APP_LIST__ACTION_MENU_DETAILS()}
        </MenuItem>
      )}
      {isAppSubmitted && showMessagesItem && (
        <MenuItem
          to={`/applications/${appRouteType}/${app.id}/details/messages`}
        >
          {trans.APP_LIST__ACTION_MENU_MESSAGE_CENTER()}
        </MenuItem>
      )}
    </ActionMenu>
  );
};

ApplicationPopupMenu.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    has_submitted_version: PropTypes.bool.isRequired,
    active_version: PropTypes.object,
    submission_status: PropTypes.string,
  }),
  appRouteType: PropTypes.string.isRequired,
};
