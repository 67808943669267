// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Pill } from "components/elements";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./UpdateJobStatus.scss";

export const UPDATE_JOB_STEP_STATUSES = {
  NOT_STARTED: {
    color: "grey",
    label: trans.UPDATES__UPDATE_DETAILS__LABEL_NOT_STARTED(),
  },
  IN_PROGRESS: {
    color: "green",
    label: trans.UPDATES__UPDATE_DETAILS__LABEL_IN_PROGRESS(),
  },
  FAILED: {
    color: "red",
    label: trans.UPDATES__UPDATE_DETAILS__LABEL_FAILED(),
  },
  DONE: {
    color: "grey",
    label: trans.UPDATES__UPDATE_DETAILS__LABEL_DONE(),
  },
  STOPPED: {
    color: "grey",
    label: trans.UPDATES__UPDATE_DETAILS__LABEL_STOPPED(),
  },
  PAUSED: {
    color: "yellow",
    label: trans.UPDATES__UPDATE_DETAILS__LABEL_PAUSED(),
  },
};

export const UpdateJobStepStatus = ({ status, className }) => {
  const matchingStatus = UPDATE_JOB_STEP_STATUSES[status];

  if (!matchingStatus) {
    return null;
  }

  return (
    <Pill
      text={matchingStatus.label}
      className={classes(
        styles[matchingStatus.color],
        styles.middle,
        className
      )}
      dataTestId="update-job-step-status"
    />
  );
};

UpdateJobStepStatus.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};
