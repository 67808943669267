// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { PageContentError } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { api, apiThatThrows } from "containers/Request";
import { withRouter } from "utils/decorators";

import { FORMAT_OTHER } from "../../constants";
import { DeviceModelForm } from "./DeviceModelForm";

const INITIAL_VALUES = {
  name: undefined,
  slug: undefined,
  autoSlug: true,
  drmSupport: [],
  videoFormatSupport: [],
  streamingSupport: [],
  testing: false,
  enabled: true,
  deviceFamily: undefined,
  organization: undefined,
};

@withRouter
@withActiveOrganization
@restrictedArea(() => {
  return {
    allowed: [
      ROLES.administrator.deviceAdmin,
      // Don't use 'allowInOrganization' for this view!
      // This form should be available in all organizations for specific user roles.
      // If user is allowed to edit/create device model only in specific organization,
      // you should list all allowed organizations in "distribution customer" field.
    ],
  };
})
@connect(null, (dispatch) => ({
  getDeviceModel: (id) =>
    dispatch(api.getDeviceModel.action({ params: { id } })),
  getVideoFormatTypes: () =>
    dispatch(
      api.getVideoFormatTypesPaginated.action({
        queryParams: { limit: "nolimit" },
        cache: true,
      })
    ),
  getStreamingFormatTypes: () =>
    dispatch(
      api.getStreamingFormatTypesPaginated.action({
        queryParams: { limit: "nolimit" },
        cache: true,
      })
    ),
  getDrmFormatTypes: () =>
    dispatch(
      api.getDrmFormatTypesPaginated.action({
        queryParams: { limit: "nolimit" },
        cache: true,
      })
    ),
  getOrganizations: () =>
    dispatch(
      apiThatThrows.getOrganizationsPaginated.action({
        queryParams: { type: "Distributor", limit: "nolimit" },
      })
    ),
}))
export class DeviceModelFormData extends Component {
  static propTypes = {
    // from @connect
    getDeviceModel: PropTypes.func.isRequired,
    getVideoFormatTypes: PropTypes.func.isRequired,
    getStreamingFormatTypes: PropTypes.func.isRequired,
    getDrmFormatTypes: PropTypes.func.isRequired,
    getOrganizations: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  initialState = {
    error: "",
    loading: 0,
    initialValues: INITIAL_VALUES,
    otherCheckboxesIds: {
      videoFormatSupportOtherId: -1,
      streamingFormatSupportOtherId: -1,
      drmFormatSupportOtherId: -1,
    },
    videoFormats: [],
    streamingFormats: [],
    drmFormats: [],
    organizations: [],
  };

  state = this.initialState;

  async componentDidMount() {
    this.updateInitialData();
    this.updateOtherCheckboxesIds();
    this.updateOrganizations();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search !== prevProps.location.search) {
      this.updateInitialData();
      this.updateOtherCheckboxesIds();
    }
  }

  increaseLoading = () => {
    this.setState((prevState) => ({ loading: prevState.loading + 1 }));
  };

  decreaseLoading = () => {
    this.setState((prevState) => ({ loading: prevState.loading - 1 }));
  };

  static findOtherFormat(formats) {
    return formats.find((format) => {
      return format.display_name.toLowerCase() === FORMAT_OTHER;
    });
  }

  async updateInitialData() {
    const { getDeviceModel } = this.props;

    if (this.isEdit()) {
      this.increaseLoading();

      const deviceId = this.getDeviceId();
      const deviceModel = await getDeviceModel(deviceId);

      // handle fetch errors
      if (deviceModel.error) {
        this.setState({
          error: deviceModel.error,
        });
        this.decreaseLoading();
        return;
      }

      const deviceModelResults = deviceModel.result.results;

      const otherVideoFormat = DeviceModelFormData.findOtherFormat(
        deviceModelResults.video_formats
      );
      const otherStreamingFormat = DeviceModelFormData.findOtherFormat(
        deviceModelResults.streaming_formats
      );
      const otherDrmFormat = DeviceModelFormData.findOtherFormat(
        deviceModelResults.drm_formats
      );

      this.setState({
        error: "",
        initialValues: {
          id: deviceId,
          name: deviceModelResults.name,
          slug: deviceModelResults.slug,
          autoSlug: deviceModelResults.is_auto_slug,
          organization: deviceModelResults.owner.id,
          deviceFamily: deviceModelResults.device_family.id,
          enabled: deviceModelResults.is_enabled,
          testing: deviceModelResults.is_testing,
          videoFormatSupport: deviceModelResults.video_formats.map((f) =>
            f.video_format_type.toString()
          ),
          videoFormatSupportComment: otherVideoFormat?.comment,
          streamingSupport: deviceModelResults.streaming_formats.map((f) =>
            f.streaming_format_type.toString()
          ),
          streamingSupportComment: otherStreamingFormat?.comment,
          drmSupport: deviceModelResults.drm_formats.map((f) =>
            f.drm_format_type.toString()
          ),
          drmSupportComment: otherDrmFormat?.comment,
        },
      });
      this.decreaseLoading();
    } else {
      this.setState(this.initialState);
    }
  }

  async updateOtherCheckboxesIds() {
    const { getVideoFormatTypes, getStreamingFormatTypes, getDrmFormatTypes } =
      this.props;

    this.increaseLoading();

    const videoFormatPromise = getVideoFormatTypes();
    const streamingFormatPromise = getStreamingFormatTypes();
    const drmFormatPromise = getDrmFormatTypes();

    const [videoFormat, streamingFormat, drmFormat] = await Promise.all([
      videoFormatPromise,
      streamingFormatPromise,
      drmFormatPromise,
    ]);

    // handle fetch errors
    const error = videoFormat.error || streamingFormat.error || drmFormat.error;
    if (error) {
      this.setState({
        error: error,
        otherCheckboxesIds: {
          videoFormatSupportOtherId: "-1",
          streamingFormatSupportOtherId: "-1",
          drmFormatSupportOtherId: "-1",
        },
      });
      this.decreaseLoading();
      return;
    }

    const videoFormatResults = videoFormat.result.results;
    const streamingFormatResults = streamingFormat.result.results;
    const drmFormatResults = drmFormat.result.results;

    const videoFormatSupportOther =
      DeviceModelFormData.findOtherFormat(videoFormatResults);
    const streamingFormatSupportOther = DeviceModelFormData.findOtherFormat(
      streamingFormatResults
    );
    const drmFormatSupportOther =
      DeviceModelFormData.findOtherFormat(drmFormatResults);

    const prepareId = (data) => data?.id?.toString() ?? "-1";

    this.setState({
      error: "",
      videoFormats: videoFormatResults,
      streamingFormats: streamingFormatResults,
      drmFormats: drmFormatResults,
      otherCheckboxesIds: {
        videoFormatSupportOtherId: prepareId(videoFormatSupportOther),
        streamingFormatSupportOtherId: prepareId(streamingFormatSupportOther),
        drmFormatSupportOtherId: prepareId(drmFormatSupportOther),
      },
    });
    this.decreaseLoading();
  }

  async updateOrganizations() {
    const { getOrganizations } = this.props;

    try {
      this.increaseLoading();
      const { results } = await getOrganizations();
      this.setState({
        organizations: results,
      });
    } catch (error) {
      this.setState({
        error: error,
      });
    } finally {
      this.decreaseLoading();
    }
  }

  refreshData = () => {
    this.updateInitialData();
  };

  getDeviceId() {
    return this.props.params.id;
  }

  isEdit() {
    return typeof this.getDeviceId() !== "undefined";
  }

  render() {
    const {
      initialValues,
      loading,
      error,
      videoFormats,
      streamingFormats,
      drmFormats,
      organizations,
    } = this.state;

    if (loading > 0) {
      return <Loader />;
    }

    if (error) {
      return <PageContentError error={error} />;
    }

    return (
      <DeviceModelForm
        isEdit={this.isEdit()}
        otherCheckboxesIds={this.state.otherCheckboxesIds}
        videoFormats={videoFormats}
        streamingFormats={streamingFormats}
        drmFormats={drmFormats}
        initialValues={initialValues}
        organizations={organizations}
      />
    );
  }
}
