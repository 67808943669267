// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import set from "lodash-es/set";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextField, TextareaField } from "components/form/fields";
import { Section, Subsection } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { api } from "containers/Request";
import { TargetsField, ResetFormOnOrgChange } from "pages/_shared";
import { withTargetsSend } from "pages/_shared/TargetsField/decorators/withTargetsSend";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";
import { prepareErrorsForForm } from "utils/errors";
import { dataFormatter } from "utils/jsonApi";
import { pipeValidators, hasOneElement } from "utils/validation";

import { AppsField } from "../_shared/fields";
import { PLAN_ID_DEFAULT } from "../constants";

const jsonPointerToFieldName = {
  "/data/attributes/label": "name",
  "/data/attributes/description": "description",
  "/data/relationships/applications": "applications",
  "/data/relationships/targets/data": "targets._error",
};

@withRouter
@withTargetsSend({
  distributorFieldNameInRequest: "device_owners",
  distributorDeviceType: "device_owner",
})
@withActiveOrganization
@connect(null, (dispatch) => ({
  createPlan: (body) =>
    dispatch(api.createMyDistributionPlan.action({ options: { body } })),
  updatePlan: (id, body) =>
    dispatch(
      api.updateMyDistributionPlan.action({
        params: { id },
        options: { body },
      })
    ),
}))
export class CustomPlanFormRend extends Component {
  static propTypes = {
    id: PropTypes.string,
    initialValues: PropTypes.object.isRequired,

    // from @withRouter
    navigate: PropTypes.func.isRequired,

    // from @connect
    createPlan: PropTypes.func.isRequired,
    updatePlan: PropTypes.func.isRequired,

    // from @withTargetsSend
    createTargetsBody: PropTypes.func,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  createFormData = (values) => ({
    type: "plans",
    label: values.name,
    description: values.description,
    applications: values.applications.map((appId) => ({
      type: "applications",
      id: appId,
    })),
    relationshipNames: ["applications"],
  });

  validate = (formValues) => {
    const validators = [
      hasOneElement("applications"),
      hasOneElement("targets"),
    ];
    return pipeValidators(...validators)(formValues);
  };

  createBody(values) {
    // Due to targets relationship, this request has a very unusual structure
    // that does not conform with jsona.
    const { createTargetsBody } = this.props;

    const data = this.createFormData(values);
    const body = dataFormatter.serialize({ stuff: data });

    const targetsBody = createTargetsBody("targets", values.targets);
    set(body, "data.relationships.targets", targetsBody);

    return JSON.stringify(body);
  }

  handleSubmit = async (values, { setErrors }) => {
    const { id, navigate, createPlan, updatePlan } = this.props;

    const body = this.createBody(values);

    let request;

    if (this.isEdit()) {
      request = await updatePlan(id, body);
    } else {
      request = await createPlan(body);
    }

    if (request.error) {
      const submitErrors = prepareErrorsForForm(
        request.error,
        jsonPointerToFieldName
      );
      setErrors(submitErrors);
    } else {
      const message = this.isEdit()
        ? trans.CHANGES_SAVE_SUCCESS()
        : trans.DISTR_PLAN__CREATE_SUCCESS();

      navigate(
        {
          pathname: "/distribution/custom-plans",
        },
        {
          state: { message: message },
        }
      );
    }
  };

  isEdit() {
    const { id } = this.props;
    return id !== PLAN_ID_DEFAULT;
  }

  render() {
    const { initialValues, activeOrganization } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        validate={this.validate}
      >
        {({ dirty, errors, isSubmitting }) => {
          return (
            <Form aria-label="form">
              <Section header={trans.DISTR_PLAN__SECTION_BASIC_DETAILS()}>
                <TextField
                  name="name"
                  label={trans.DISTR_PLAN__FIELD_NAME()}
                  dataTestId="name-field"
                />
                <TextareaField
                  name="description"
                  label={trans.DISTR_PLAN__FIELD_DESCRIPTION()}
                  dataTestId="description-field"
                />
              </Section>
              <Section header={trans.DISTR_PLAN__SECTION_TARGETS()}>
                <TargetsField
                  devicesDistributorId={activeOrganization.id}
                  required={true}
                />
              </Section>
              <Section header={trans.DISTR_PLAN__SECTION_APPS_TO_DISTRIBUTE()}>
                <Subsection
                  header={trans.DISTR_PLAN__SECTION_APPS_TO_DISTRIBUTE_HELP()}
                >
                  <AppsField />
                </Subsection>
              </Section>
              {errors?._error && <Info type="error">{errors._error}</Info>}
              <ButtonsWrapper>
                <Button
                  buttonType="submit"
                  type="green"
                  processing={isSubmitting}
                  disabled={
                    isSubmitting ||
                    !dirty ||
                    Boolean(Object.entries(errors).length)
                  }
                >
                  {this.isEdit() ? trans.SUBMIT_CHANGES() : trans.PUBLISH()}
                </Button>
              </ButtonsWrapper>
              <ResetFormOnOrgChange />
            </Form>
          );
        }}
      </Formik>
    );
  }
}
