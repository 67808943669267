// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Label, ValidationBadge } from "components/form";
import { getErrorId } from "components/form/fields";
import { classes } from "utils/classes";

import { Radio, RadioValuePropType } from "./Radio";
import styles from "./RadioGroup.scss";

export class RadioGroup extends Component {
  static propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    /** Radio buttons to display */
    radios: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        children: PropTypes.node,
        defaultValue: RadioValuePropType,
        value: RadioValuePropType,
        dataTestId: PropTypes.string,
        tooltip: PropTypes.string,
        className: PropTypes.string,
      })
    ).isRequired,
    /**
     * Change callback.
     *
     * Type: (e: Event) => void
     */
    onChange: PropTypes.func.isRequired,
    /** Render `<Radio>`s in single line */
    inline: PropTypes.bool,
    /** `<RadioGroup>` can also be used as tabs */
    displayAsTabs: PropTypes.bool,
    tooltip: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    label: "",
    dataTestId: "radio-group",
    inline: false,
    displayAsTabs: false,
  };

  render() {
    const {
      label,
      required,
      radios,
      inline,
      displayAsTabs,
      tooltip,
      dataTestId,
      ...field
    } = this.props;
    const { disabled, error, touched } = field;

    return (
      <div
        className={classes(styles.radioGroup, {
          [styles.radioGroupInline]: inline,
          [styles.radioGroupTabs]: displayAsTabs,
          [styles.disabled]: disabled,
        })}
        data-test-id={dataTestId}
        role="radiogroup"
      >
        <Label text={label} required={required} tooltip={tooltip}>
          <ul>
            {radios.map((radio, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={`${radio.defaultValue}_${index}`}
                className={styles.item}
              >
                <Radio
                  defaultValue={radio.defaultValue}
                  label={radio.label}
                  value={radio.value}
                  tooltip={radio.tooltip}
                  className={radio.className}
                  {...field}
                  dataTestId={radio.dataTestId}
                >
                  {radio.children}
                </Radio>
              </li>
            ))}
          </ul>
          <ValidationBadge
            error={error}
            touched={touched}
            errorId={getErrorId(field.name)}
          />
        </Label>
      </div>
    );
  }
}
