// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

import { TableBody } from "components/layout";
import { trans } from "src/translations";
import { isEmpty } from "utils/object";

import {
  FamilyRow,
  DeviceRow,
  InfoRow,
  OrganizationRow,
} from "../_shared/DeviceList";

export class DevicesTableBody extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    onToggleDeviceModel: PropTypes.func.isRequired,
    onAddDeviceFamily: PropTypes.func.isRequired,
    onEditDeviceFamily: PropTypes.func.isRequired,
    toggleDeviceModelInProgress: PropTypes.bool.isRequired,
  };

  state = {
    organizationsExpanded: {},
    familiesExpanded: {},
  };

  componentDidUpdate(prevProps) {
    const prevOrgIds = prevProps.data.map((org) => org.id);
    const currOrgIds = this.props.data.map((org) => org.id);
    if (!isEqual(prevOrgIds.sort(), currOrgIds.sort())) {
      this.collapseAll();
    }
  }

  collapseAll() {
    this.setState({
      organizationsExpanded: {},
      familiesExpanded: {},
    });
  }

  onExpandToggle = (id, stateKey) => {
    return () => {
      this.setState((prevState) => ({
        [stateKey]: {
          ...prevState[stateKey],
          [id]: !prevState[stateKey][id],
        },
      }));
    };
  };

  isOrganizationExpanded(organization) {
    const { organizationsExpanded } = this.state;
    return organizationsExpanded[organization.id] || false;
  }

  isDeviceFamilyExpanded(family) {
    const { familiesExpanded } = this.state;
    return familiesExpanded[family.id] || false;
  }

  renderOrganizationRow(organization) {
    const { onAddDeviceFamily } = this.props;

    return (
      <OrganizationRow
        key={`organization-${organization.id}`}
        organization={organization}
        onExpandToggle={this.onExpandToggle(
          organization.id,
          "organizationsExpanded"
        )}
        expanded={this.isOrganizationExpanded(organization)}
        onAddDeviceFamily={onAddDeviceFamily(organization.id)}
      />
    );
  }

  renderDeviceFamilyRow(organization, family) {
    const { onEditDeviceFamily } = this.props;

    return (
      <FamilyRow
        key={`family-${family.id}`}
        family={family}
        expanded={this.isDeviceFamilyExpanded(family)}
        onEditDeviceFamily={onEditDeviceFamily(organization.id, family.id)}
        onExpandToggle={this.onExpandToggle(family.id, "familiesExpanded")}
      />
    );
  }

  renderDeviceModelRow(organization, device) {
    const { onToggleDeviceModel, toggleDeviceModelInProgress, forAdmin } =
      this.props;
    return (
      <DeviceRow
        key={`device-${device.id}`}
        forAdmin={forAdmin}
        device={device}
        ownerId={organization.id}
        toggleDeviceModelInProgress={toggleDeviceModelInProgress}
        onToggleDeviceModel={onToggleDeviceModel}
      />
    );
  }

  render() {
    const { data } = this.props;

    return (
      <TableBody>
        {data.reduce((acc, organization) => {
          acc.push(this.renderOrganizationRow(organization));

          if (this.isOrganizationExpanded(organization)) {
            if (isEmpty(organization.families)) {
              acc.push(
                <InfoRow key={`no-families-${organization.id}`} colspan={2}>
                  {trans.DEVICES__NO_FAMILES_INFO()}
                </InfoRow>
              );
            }

            organization.families.forEach((family) => {
              acc.push(this.renderDeviceFamilyRow(organization, family));

              if (this.isDeviceFamilyExpanded(family)) {
                if (isEmpty(family.devices)) {
                  acc.push(
                    <InfoRow key={`no-devices-${family.id}`} colspan={2}>
                      {trans.DEVICES__NO_DEVICES_INFO()}
                    </InfoRow>
                  );
                }

                family.devices.forEach((device) => {
                  acc.push(this.renderDeviceModelRow(organization, device));
                });
              }
            });
          }

          return acc;
        }, [])}
      </TableBody>
    );
  }
}
