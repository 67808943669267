// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/*
 * matches "/key:value" with groups: "key", "value" for include filters
 * matches "-key:value" with groups: "key", "value" for exlude filters
 * ignores white chars in front and at the end of key and value
 */
export const PARSE_FILTER_REGEXP = /^[-/]{1}\s*(\w*)\s*:\s*(.*?)\s*$/;
// character that triggers the filter mechanism
export const FILTER_TRIGGER_CHARACTER = "/";
export const FILTER_EXCLUSION_TRIGGER_CHARACTER = "-";

export const SEARCH_PARAM_NAME = "search";
export const PAGE_PARAM_NAME = "page";
export const SUGGESTIONS_REQUEST_DEBOUNCE = 300;

export const ARRAY_FORMAT = "separator";
/*
 * we use special unicode symbol to make sure it won't be used as a value in any filter
 * "AND" Logical operator: "∧"
 * Unicode: U+2227, HTML: &#8743, CSS: \2227, Entity: &and;
 */
export const ARRAY_FORMAT_SEPARATOR = "∧";
export const PAIR_VALUES_SEPARATOR = ":";
