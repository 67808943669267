// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { ValidationBadge, Label } from "components/form";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import styles from "./InputWithModal.scss";

export class InputWithModal extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.string,
    children: PropTypes.func.isRequired,
    dataTestId: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      error: PropTypes.string,
      initialError: PropTypes.string,
      initialTouched: PropTypes.bool,
      initialValue: PropTypes.any,
      touched: PropTypes.bool,
      value: PropTypes.any,
    }).isRequired,
    look: PropTypes.oneOf(["default", "horizontal"]),
  };

  static defaultProps = {
    look: "default",
  };

  state = { isOpen: false };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  render() {
    const { children, label, value, required, dataTestId, meta, look } =
      this.props;
    const { isOpen } = this.state;

    const hasValue = value != null && value.length > 0;
    const formattedValue = hasValue ? value : NO_VALUE_PLACEHOLDER;

    return (
      <div>
        <Label required={required} text={label}>
          <div className={styles[look]}>
            <div>
              <div data-test-id={dataTestId} className={styles.valueText}>
                {formattedValue}
              </div>
              <Button
                type={hasValue ? "normal" : "green"}
                onClick={this.handleOpen}
                dataTestId={`${dataTestId}-open-modal-button`}
              >
                {hasValue ? trans.CHANGE() : trans.SELECT()}
              </Button>
              <ValidationBadge
                error={meta.error}
                touched={meta.touched}
                className={styles.error}
              />
            </div>
            {children(isOpen, this.handleClose)}
          </div>
          <hr className={styles.border} />
        </Label>
      </div>
    );
  }
}
