// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import styles from "./Flag.scss";
import "./flags.min.css";

export const Flag = ({ countryCode }) => (
  <div className={`flag flag-${countryCode} ${styles.container}`} />
);

Flag.propTypes = {
  countryCode: PropTypes.string.isRequired,
};

export default Flag;
