// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { WarningModal } from "components/layout";
import { trans } from "src/translations";

import styles from "./NoPermissionModal.scss";

export const NoPermissionModal = ({ isOpen, onClose, onProceed }) => {
  return (
    <WarningModal
      title={trans.WARNING()}
      isOpen={isOpen}
      titleClassName={styles.red}
      className={styles.red}
      actions={
        <ButtonsWrapper position="modal">
          <Button
            type="plain"
            dataTestId="no-permission-warning-proceed"
            onClick={onProceed}
          >
            {trans.YES_PROCEED()}
          </Button>
          <Button
            type="normal"
            dataTestId="no-permission-warning-cancel"
            onClick={onClose}
          >
            {trans.CANCEL()}
          </Button>
        </ButtonsWrapper>
      }
    >
      <p>{trans.APP_DETAILS_MODERATION_NO_PERMISSIONS_WARNING()}</p>
    </WarningModal>
  );
};

NoPermissionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
};
