// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";
import {
  isRequiredWhen,
  isAtLeastOneTrueInFields,
  pipeValidators,
} from "utils/validation";

import {
  Monetization,
  HAS_REVENUE,
  NO_REVENUE,
} from "../../../components/index";

const revenueValueDefault = {
  other: "",
  advertisement: false,
  subscriptions: false,
  transactions: false,
  other_comment: "",
};

export default {
  name: trans.APP_EDIT_MARKETS__MONETIZATION_SECTION_NAME(),
  components: [
    {
      component: Monetization,
      fields: [
        "metadata.produce_revenue",
        "metadata.produce_revenue_value.other_comment",
        "metadata.produce_revenue_value.transactions",
        "metadata.produce_revenue_value.subscriptions",
        "metadata.produce_revenue_value.advertisement",
        "metadata.produce_revenue_value.other",
      ],
      initialValues: {
        metadata: {
          produce_revenue: NO_REVENUE,
          produce_revenue_value: revenueValueDefault,
        },
      },
      validate: pipeValidators(
        isRequiredWhen(
          "metadata.produce_revenue_value.other_comment",
          (values) => values.metadata.produce_revenue_value.other
        ),
        isAtLeastOneTrueInFields(
          "metadata.produce_revenue",
          [
            "metadata.produce_revenue_value.transactions",
            "metadata.produce_revenue_value.subscriptions",
            "metadata.produce_revenue_value.advertisement",
            "metadata.produce_revenue_value.other",
          ],
          (values) => values.metadata.produce_revenue === HAS_REVENUE,
          trans.APP_EDIT_MONETIZATION__REVENUE_TYPE_IS_REQUIRED()
        )
      ),
      props: ({ formikProps }) => {
        return {
          ...formikProps,
          revenueOptionField: "metadata.produce_revenue",
          revenueValueField: "metadata.produce_revenue_value",
          revenueOtherTextField: "metadata.produce_revenue_value.other_comment",
          revenueValueDefault: revenueValueDefault,
        };
      },
    },
  ],
};
