import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBar } from "components/elements";
import { Section } from "components/layout";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";
import { getGqlErrorMessage } from "utils/errors";

import styles from "./UpdateJobDetailsEligibleDevicesTab.scss";
import { UpdateJobDetailsEligibleDevicesTable } from "./UpdateJobDetailsEligibleDevicesTable";
import { AdaptedDevice } from "./utils/adaptEligibleDevices";

interface UpdateJobDetailsEligibleDevicesTabProps {
  fetchDevices: () => void;
  fetchDevicesStatus: {
    loading: boolean;
    error?: object;
    data: {
      items: AdaptedDevice[];
      total: number;
    };
  };
  changeSearch: () => void;
  devicesRefreshDate: Date;
}

export const UpdateJobDetailsEligibleDevicesTab = ({
  fetchDevices,
  fetchDevicesStatus,
  changeSearch,
  devicesRefreshDate,
}: UpdateJobDetailsEligibleDevicesTabProps) => {
  const {
    loading,
    error,
    data: { items, total },
  } = fetchDevicesStatus;

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          {trans.UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_DESCRIPTION()}
        </div>
        <ButtonsWrapper>
          <Button
            onClick={fetchDevices}
            dataTestId="device-refresh"
            disabled={loading}
            processing={loading}
          >
            {trans.REFRESH()}
          </Button>
        </ButtonsWrapper>
        <p className={styles.checkDate} data-test-id="check-date">
          {trans.UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_CHECKED_ON({
            dateString: formatDateTime(devicesRefreshDate),
          })}
        </p>
      </div>
      <Section>
        <SearchBar
          onSearch={changeSearch}
          placeholder={trans.UPDATES__UPDATE_DETAILS__ELIGIBLE_DEVICES_LIST_SEARCH_PLACEHOLDER()}
        />
        <UpdateJobDetailsEligibleDevicesTable
          loading={loading}
          count={total}
          error={error && getGqlErrorMessage(error)}
          results={items}
        />
      </Section>
    </>
  );
};
