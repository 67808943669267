// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import intersection from "lodash-es/intersection";

export const allowInOrganization =
  (organizationId, allowedRoles) =>
  ({ activeOrganization = {} } = {}) => {
    if (!allowedRoles || allowedRoles.length === 0) {
      return true;
    }
    if (!activeOrganization) {
      return false;
    }
    const sameOrganization = activeOrganization.id === organizationId;
    const hasRequiredRole =
      intersection(allowedRoles, activeOrganization.roles).length > 0;

    return sameOrganization && hasRequiredRole;
  };
