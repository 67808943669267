// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { RadioGroupField } from "components/form/fields";
import { Row, Column } from "components/layout";
import { ContentBox, Header, Link } from "components/typography";
import { FIELDS, SECTIONS } from "pages/Snap/shared/constants";
import { Ads } from "pages/Snap/SnapEdit/components/Ads/Ads";
import { trans, HtmlTranslation } from "src/translations";

import styles from "./Monetization.scss";

export const Monetization = ({ formId }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Fragment>
      <Row className={styles.row}>
        <Column>
          <Header bold={true} color="secondary" uppercase={true}>
            {SECTIONS.MONETIZATION.header}
          </Header>
          <RadioGroupField
            label={FIELDS.MONETIZATION.label}
            dataTestId="monetization-field"
            name={`${formId}.${FIELDS.MONETIZATION.name}`}
            values={FIELDS.MONETIZATION.radios}
            required={false}
          />
        </Column>
        <Column>
          <ContentBox title={SECTIONS.MONETIZATION.title}>
            {
              <HtmlTranslation
                transFunc={trans.SNAP_APP__EDIT_MONETIZATION_GUIDE}
                transParams={{
                  link: (
                    <Link href="mailto:adops@vewd.com">adops@vewd.com</Link>
                  ),
                }}
              />
            }
          </ContentBox>
        </Column>
      </Row>
      {values[formId][FIELDS.MONETIZATION.name] === "true" && (
        <Ads formId={formId} values={values} onChange={setFieldValue} />
      )}
    </Fragment>
  );
};
Monetization.propTypes = {
  formId: PropTypes.string.isRequired,
};
