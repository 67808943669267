// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { IsUpdatedProvider } from "utils/context/IsUpdated";
import { withRouter } from "utils/decorators/withRouter";

import { getVersionSubTabFromUrl } from "../getVersionSubTabFromUrl";
import { VersionContext } from "./context";

@withRouter
export class IsVersionUpdatedProvider extends Component {
  static propTypes = {
    activeVersion: PropTypes.object,
    latestVersion: PropTypes.object,
    children: PropTypes.node.isRequired,

    // from @withRouter
    location: PropTypes.object,
  };

  render() {
    const { activeVersion, latestVersion, children, location } = this.props;

    return (
      <IsUpdatedProvider
        Context={VersionContext}
        firstObj={activeVersion}
        secondObj={latestVersion}
        extraValue={{
          currentVersionId: getVersionSubTabFromUrl(location),
        }}
      >
        {children}
      </IsUpdatedProvider>
    );
  }
}
