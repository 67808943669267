// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Header } from "components/typography";
import { UNAUTHORIZED } from "containers/Auth/actionTypes";
import { trans } from "src/translations";

import styles from "./noPermissions.scss";

export const NoPermissions = ({ type, children }) => (
  <div>
    <div className={styles.container}>
      <div className={styles.content}>
        <GrabsonIcon name="rejected" size="3xl" className={styles.icon} />
        <Header bold={true} className={styles.header}>
          {type === UNAUTHORIZED
            ? trans.UNAUTHORIZE_HEADER_TEXT()
            : trans.PERMISSIONS__NO_PERMISSIONS_FALLBACK_HEADER()}
        </Header>
        <p className={styles.info}>
          {type === UNAUTHORIZED
            ? trans.UNAUTHORIZE_MESSAGE()
            : trans.PERMISSIONS__NO_PERMISSIONS_FALLBACK_INFO()}
        </p>
        {children}
      </div>
    </div>
  </div>
);

NoPermissions.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};
