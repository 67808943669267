// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { get } from "lodash-es";

import { isEmpty } from "utils/object";
import { flatten } from "utils/serialize";

import { trans } from "../../translations";
import { createValidator, find, Validator, ValidatorPath } from "./utils";

export const VALIDATION_PASSED = false;

export const colorRegex = /^(?:#[\da-f]{8}|#[\da-f]{6})$/i;
export const deviceSlugRegex = /^[a-z0-9]+-[^\s"(),/;<=>?@[\]{}]+$/i;
export const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
const uriSymbolsRegex = /^(?=.*[A-Za-z])[^"<>{}|\\^`\s]+$/i;
export const phoneRegexp = /^[\d\-()\s+]*$/i;
export const resolutionRegex = /^(?:0|[1-9]\d*)x(?:0|[1-9]\d*)$/i;
export const slugRegex = /^[a-z0-9!_-]+$/i;
export const urlRegex =
  /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff0-9]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const versionRegex = /^(\d+\.)(\d+\.)(\d+)$/;

export const isRequired = (
  path: ValidatorPath,
  message = trans.ERROR__FIELD_REQUIRED()
): Validator<string, never> =>
  createValidator(
    (field /* rawValues */) => {
      if (field === undefined || field === null || typeof field === "boolean") {
        return !field;
      }

      return !`${field}`;
    },
    message,
    path
  );

export const isRequiredWhen = <T = unknown>(
  path: ValidatorPath,
  when: (values: Record<string, T>) => boolean
): Validator<string, T> =>
  createValidator(
    (field, rawValues) => (when(rawValues) ? !field : VALIDATION_PASSED),
    trans.ERROR__FIELD_REQUIRED(),
    path
  );

export const isEmail = (path: ValidatorPath): Validator<string, never> =>
  createValidator(
    (field /* rawValues */) => !emailRegex.test(field),
    trans.ERROR__INVALID_EMAIL(),
    path
  );

export const isPhoneNumber = (path: ValidatorPath): Validator<string, never> =>
  createValidator(
    (field) => !phoneRegexp.test(field),
    trans.ERROR__INVALID_PHONE(),
    path
  );

export const hasValidURIChars = (
  path: ValidatorPath
): Validator<string, never> =>
  createValidator(
    (field) => !uriSymbolsRegex.test(field || ""),
    trans.ERROR__INVALID_URI_SYMBOLS(),
    path
  );

export const isUrl = (path: ValidatorPath): Validator<string, never> =>
  createValidator(
    (field) => !urlRegex.test(field),
    trans.ERROR__INVALID_URL(),
    path
  );

export const isVersion = (path: ValidatorPath): Validator<string, never> =>
  createValidator(
    (field) => !versionRegex.test(field),
    trans.ERROR__INVALID_ASSET_VERSION(),
    path
  );

const hasOneElementImpl = (field: unknown): boolean =>
  !(field instanceof Array) || field.length < 1;

export const hasOneElement = (path: ValidatorPath): Validator<unknown, never> =>
  createValidator(
    (field) => hasOneElementImpl(field),
    trans.ERROR__ONE_ELEMENT_REQUIRED(),
    path
  );

export const hasOneElementWhen = <T = unknown>(
  path: ValidatorPath,
  when: (values: Record<string, T>) => boolean
): Validator<unknown, T> =>
  createValidator(
    (field, rawValues) =>
      when(rawValues) ? hasOneElementImpl(field) : VALIDATION_PASSED,
    trans.ERROR__ONE_ELEMENT_REQUIRED(),
    path
  );

export const maxLengthOfStr = (
  path: ValidatorPath,
  max: number
): Validator<unknown, never> => {
  return createValidator(
    (field) => {
      if (typeof field !== "string") {
        return VALIDATION_PASSED;
      }
      return field.length > max;
    },
    trans.ERROR__NO_LONGER_THAN({
      max: max,
    }),
    path
  );
};

export const isNotEqual = (
  path: ValidatorPath,
  compareWith: string,
  errorMessage: string
): Validator<string, never> => {
  return createValidator((field) => field === compareWith, errorMessage, path);
};

export const isSlug = (path: ValidatorPath): Validator<string, never> =>
  createValidator(
    (field) => !slugRegex.test(field),
    trans.ERROR__INVALID_SLUG(),
    path
  );

export const isDeviceSlug = (path: ValidatorPath): Validator<string, never> =>
  createValidator(
    (field) => !deviceSlugRegex.test(field),
    trans.ERROR__INVALID_DEVICE_SLUG(),
    path
  );

const count = <T = unknown>(x: T, xs: T[]) => xs.filter((e) => e === x).length;

export const isUnique = <
  T extends Record<string, unknown> = Record<string, unknown>,
>(
  fieldName: string,
  fieldNameRegex: RegExp,
  errorMessage: string
): Validator<string, Record<string, T>> => {
  return createValidator(
    (fieldValue, rawValues) => {
      const flatValues = flatten(rawValues, [fieldName]);
      const allFieldValues = Object.values(find(fieldNameRegex, flatValues));
      return count(fieldValue, allFieldValues) !== 1;
    },
    errorMessage,
    fieldName
  );
};

export const isAtLeastOneTrueInFields = <T = unknown>(
  fieldName: string,
  fieldNames: string[],
  when: (values: Record<string, T>) => boolean,
  errorMessage: string
): Validator<never, T> => {
  return createValidator(
    (_, rawValues) => {
      if (!when(rawValues)) {
        return VALIDATION_PASSED;
      }

      const allFieldValues = fieldNames.map((name) => get(rawValues, name));
      return !allFieldValues.some((value) => Boolean(value));
    },
    errorMessage,
    fieldName
  );
};

export const hasAtLeastOneElementInFields = <T = unknown>(
  fieldNameToSetError: string,
  fieldNamesToCheckValues: string[],
  errorMessage: string,
  when: (values: Record<string, T>) => boolean
): Validator<never, T> => {
  return createValidator(
    (_, rawValues) => {
      if (when && !when(rawValues)) {
        return VALIDATION_PASSED;
      }

      const allFieldValues = fieldNamesToCheckValues.map((name) =>
        get(rawValues, name)
      );
      return !allFieldValues.some((value) => !isEmpty(value));
    },
    errorMessage,
    fieldNameToSetError
  );
};
