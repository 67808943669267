// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Input } from "src/components/form/elements";
import { TextField } from "src/components/form/fields";
import { trans } from "src/translations";
import { pipeValidators, isRequired, isUrl } from "utils/validation";

import { Category } from "../../../components";
import {
  DISABLED_APPLICATION_URL_APP_TYPES,
  GENERAL_SECTION_APP_TYPES,
} from "../../../constants";

export default {
  name: trans.APP_EDIT__GENERAL_SECTION_NAME(),
  isEnabled: (props) => GENERAL_SECTION_APP_TYPES.includes(props.appData.type),
  components: [
    {
      component: TextField,
      fields: ["metadata.application_url"],
      initialValues: {
        metadata: { application_url: "" },
      },
      validate: pipeValidators(
        isUrl("metadata.application_url"),
        isRequired("metadata.application_url")
      ),
      props: (props) => ({
        disabled: DISABLED_APPLICATION_URL_APP_TYPES.includes(
          props.appData.template
        ),
        placeholder: trans.URL_PLACEHOLDER(),
        name: "metadata.application_url",
        label: trans.APP_EDIT__URL_LABEL(),
        tooltip: trans.APP_EDIT__URL_LABEL_TOOLTIP(),
        dataTestId: "url-field",
      }),
    },
    {
      component: Input,
      props: (props) => ({
        label: trans.APP_EDIT__ID_LABEL(),
        disabled: true,
        value: props.appData.id,
        dataTestId: "id-field",
        tooltip: trans.APP_EDIT__ID_TOOLTIP(),
      }),
    },
    {
      component: Category,
      fields: ["metadata.category"],
      initialValues: {
        metadata: { category: "" },
      },
      validate: pipeValidators(isRequired("metadata.category")),
      props: ({ formikProps }) => ({
        fieldName: "metadata.category",
        ...formikProps,
      }),
    },
  ],
};
