// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { API_URLS } from "src/config";

export const FILE_TYPE_MIME = [
  "zip",
  "application/zip",
  "application/x-zip",
  "application/octet-stream",
  "application/x-zip-compressed",
  "multipart/x-zip",
];
export const FILE_TYPE_STRING = "zip";

export const MANIFEST_INSTRUCTIONS = API_URLS.SU_MANIFEST_INSTRUCTIONS;
