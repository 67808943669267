// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { TextareaField, SelectField } from "components/form/fields";
import { Row, Column } from "components/layout";
import { RestrictedArea } from "containers/Permissions";
import { api } from "containers/Request/index";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";

import styles from "./CommentEditor.scss";
import { ROLES_POST_TO_DISTRIBUTOR, INBOXES, FIELDS } from "./constants";
import { InboxField } from "./fields/InboxField";

const jsonPointerToFieldName = {
  "/data/attributes/contents": "contents",
  "/data/attributes/inbox": "inbox",
  "/data/attributes/target_distributor": "distributor",
};

@connect(null, (dispatch) => ({
  createMessage: (appId, attributes) =>
    dispatch(
      api.createMessage.action({
        params: {
          applicationId: appId,
        },
        options: {
          body: createBody(attributes),
        },
      })
    ),
}))
export class CommentEditor extends Component {
  static propTypes = {
    applicationId: PropTypes.string.isRequired,
    oems: PropTypes.array,
    inboxes: PropTypes.array,
    refreshData: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    // from @connect
    createMessage: PropTypes.func.isRequired,
  };

  handleSubmit = async (values, { setErrors, resetForm }) => {
    const { createMessage, applicationId, refreshData } = this.props;

    const body = {
      type: "messages",
      contents: values.contents,
      inbox: values.inbox,
    };

    if (values.inbox === INBOXES.distribution.key) {
      body.target_distributor = values.distributor;
    }

    const { error } = await createMessage(applicationId, body);

    if (error) {
      const submitErrors = prepareErrorsForForm(error, jsonPointerToFieldName);
      setErrors(submitErrors);
    } else {
      resetForm();
      refreshData();
    }
  };

  getOemsValues() {
    return this.props.oems.map((oem) => ({
      value: oem.value,
      label: oem.text,
    }));
  }

  render() {
    const { inboxes, initialValues } = this.props;

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ dirty, errors, isSubmitting, getFieldMeta }) => {
          const isDistributionSelected =
            getFieldMeta(FIELDS.INBOX.name).value === INBOXES.distribution.key;

          return (
            <Form>
              <TextareaField {...FIELDS.CONTENTS} />
              <Row>
                <Column className={styles.PostToOptions}>
                  <InboxField {...FIELDS.INBOX} inboxes={inboxes} />
                  <RestrictedArea
                    allowed={ROLES_POST_TO_DISTRIBUTOR}
                    showFallback={false}
                  >
                    {isDistributionSelected && (
                      <SelectField
                        className={styles.Dropdown}
                        values={this.getOemsValues()}
                        {...FIELDS.DISTRIBUTOR}
                      />
                    )}
                  </RestrictedArea>
                </Column>
                <Column className={styles.PostToButton}>
                  <ButtonsWrapper>
                    <Button
                      type="green"
                      disabled={
                        isSubmitting ||
                        !dirty ||
                        Boolean(Object.entries(errors).length)
                      }
                      buttonType="submit"
                    >
                      {trans.APP_DETAILS_MESSAGES__SUBMIT_BUTTON()}
                    </Button>
                  </ButtonsWrapper>
                </Column>
              </Row>
              {errors?._error && <Info type="error">{errors?._error}</Info>}
            </Form>
          );
        }}
      </Formik>
    );
  }
}
