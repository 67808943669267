import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";

import { unauthorizedApolloLink } from "utils/graphql";
import { sentryGraphqlLink } from "utils/sentry";

import { API_URLS } from "../config";

export const ApolloWrapper = ({ children }) => {
  const navigate = useNavigate();

  const apolloClient = useMemo(() => {
    const query = queryString.parse(location.search);
    const jagerDebugId = query["jaeger-debug-id"];
    const headers = jagerDebugId ? { "jaeger-debug-id": jagerDebugId } : {};

    const links = [
      unauthorizedApolloLink(navigate),
      sentryGraphqlLink,
      new HttpLink({
        uri: API_URLS.API_GATEWAY,
        credentials: "include",
        headers,
      }),
    ];

    return new ApolloClient({
      link: ApolloLink.from(links),
      cache: new InMemoryCache(),
    });

    // we need only initialize appolloClient once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

ApolloWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
