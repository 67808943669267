// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { SearchBar } from "components/elements";
import {
  Section,
  TableRowColumn,
  TableBody,
  TableRow,
  TableRowColumnOverflow,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { TabbedTable } from "containers/TabbedTable";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import { DefaultSortContext } from "../../_shared/Context/defaultSortContext";
import styles from "./ProcessingQueueRend.scss";
import {
  ProcessingQueueStatus,
  PROCESSING_QUEUE_ITEM_STATUSES,
} from "./ProcessingQueueStatus";

export const ITEMS_PER_PAGE = 50;

const tabConfig = [
  {
    id: "processingQueueTable",
    dataTestId: "processing-queue-table",
    columns: [
      {
        id: "name",
        label: trans.UPDATES__PROCESSING_QUEUE__TABLE_NAME(),
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "created_at",
        label: trans.UPDATES__PROCESSING_QUEUE__TABLE_UPLOAD_DATE(),

        sortBy: "created_at",
        dataTestId: "created-at-column-header",
        className: styles.dateColumn,
      },
      {
        id: "status",
        label: trans.UPDATES__PROCESSING_QUEUE__TABLE_STATUS(),
        sortBy: "status",
        dataTestId: "status-column-header",
        className: styles.statusColumn,
      },
      {
        id: "actions",
        label: "",
        className: styles.optionsColumn,
      },
    ],
  },
];

export class ProcessingQueueRend extends PureComponent {
  static propTypes = {
    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        fileUrl: PropTypes.string,
        status: PropTypes.string.isRequired,
        errorMessage: PropTypes.string,
        uploadDate: PropTypes.string.isRequired,
        softwarePackageVersion: PropTypes.shape({
          id: PropTypes.string.isRequired,
          softwarePackage: PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        }),
      })
    ),
    onSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    count: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  renderNoResultsMessage = () => (
    <p data-test-id="no-results">
      {trans.UPDATES__PROCESSING_QUEUE__TABLE_NO_RESULTS()}
    </p>
  );

  getSoftwarePackageUrl = (softwarePackage, status) => {
    if (status !== PROCESSING_QUEUE_ITEM_STATUSES.DONE.id || !softwarePackage) {
      return null;
    }

    const {
      id: versionId,
      softwarePackage: { id: packageId },
    } = softwarePackage;

    return `/updates/packages/${packageId}/version/${versionId}`;
  };

  isUploadDone = (softwarePackage, status) =>
    status === PROCESSING_QUEUE_ITEM_STATUSES.DONE.id &&
    softwarePackage !== null;

  renderNameColumn = (name, fileUrl, softwarePackage, status) => {
    const renderedText = name || fileUrl;

    if (this.isUploadDone(softwarePackage, status)) {
      return (
        <Link to={this.getSoftwarePackageUrl(softwarePackage, status)}>
          {renderedText}
        </Link>
      );
    }

    return renderedText;
  };

  renderTableRow = ({
    id,
    name,
    fileUrl,
    softwarePackageVersion,
    status,
    uploadDate,
    errorMessage,
  }) => {
    const packageUrl = this.getSoftwarePackageUrl(
      softwarePackageVersion,
      status
    );

    return (
      <TableRow key={id}>
        <TableRowColumnOverflow dataTestId="name-column">
          {this.renderNameColumn(name, fileUrl, softwarePackageVersion, status)}
        </TableRowColumnOverflow>

        <TableRowColumn dataTestId="created-at-column">
          {formatDateTime(parseISO(String(uploadDate)))}
        </TableRowColumn>

        <TableRowColumn dataTestId="status-column">
          <ProcessingQueueStatus status={status} errorMessage={errorMessage} />
        </TableRowColumn>

        <TableRowColumn dataTestId="action-column">
          <ActionMenu alignment={"bottom"}>
            <MenuItem
              disabled={!this.isUploadDone(softwarePackageVersion, status)}
              to={packageUrl}
              dataTestId="details-item"
            >
              {trans.DETAILS()}
            </MenuItem>
          </ActionMenu>
        </TableRowColumn>
      </TableRow>
    );
  };

  renderTableBody = (rows) => (
    <TableBody>{rows.map((row) => this.renderTableRow(row))}</TableBody>
  );

  render() {
    const { results, count, loading, error, onSearch, searchValue } =
      this.props;

    return (
      <Fragment>
        <Section>{trans.UPDATES__PROCESSING_QUEUE__DESCRIPTION()}</Section>
        <Section>
          <SearchBar
            onSearch={onSearch}
            value={searchValue || ""}
            placeholder={trans.UPDATES__PROCESSING_QUEUE__SEARCH_PLACEHOLDER()}
          />
        </Section>
        <Section>
          <DefaultSortContext.Consumer>
            {(defaultOrder) => (
              <TabbedTable
                tabsConfig={tabConfig}
                data={{ loading, error, results, count, defaultOrder }}
                noResultsMessage={this.renderNoResultsMessage()}
                rowsPerPage={ITEMS_PER_PAGE}
                renderTableBody={this.renderTableBody}
                layout="fixed"
              />
            )}
          </DefaultSortContext.Consumer>
        </Section>
      </Fragment>
    );
  }
}
