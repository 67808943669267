// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getUpdateJobQuery = gql`
  query updateJob($id: ID!, $devicesPagination: PaginationInput!) {
    updateJob(updateJobId: $id) {
      updateJob {
        id
        type
        description
        threshold
        availabilityStartDate
        availabilityEndDate
        downloadUrl
        isRepeatable
        steps {
          id
          deviceCount
        }
        softwarePackageVersion {
          id
          version
          softwarePackage {
            id
            name
          }
        }
        target {
          id
          name
          devices(pagination: $devicesPagination) {
            pagination {
              count
            }
          }
        }
      }
    }
  }
`;
