// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableNoResults,
} from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";

import { APP_ENVIRONMENT } from "../../constants";

export class ProfilesTable extends PureComponent {
  static propTypes = {
    environment: PropTypes.oneOf([
      APP_ENVIRONMENT.ANDROID,
      APP_ENVIRONMENT.LINUX,
    ]),
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        // and other not required fields dependent on the environment
      })
    ).isRequired,
    onClickProfileName: PropTypes.func.isRequired,
  };

  renderTableBody = () => {
    const { environment, profiles, onClickProfileName } = this.props;

    if (profiles.length === 0) {
      return <TableNoResults />;
    }

    return (
      <TableBody>
        {profiles.map((profile) => (
          <TableRow key={profile.name}>
            <TableRowColumn>
              <Link onClick={() => onClickProfileName(environment, profile)}>
                {profile.name}
              </Link>
            </TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  render() {
    return (
      <Table>
        <TableHeader>
          <TableHeaderColumn>
            {trans.APP_DETAILS_VERSION__PROFILE()}
          </TableHeaderColumn>
        </TableHeader>
        {this.renderTableBody()}
      </Table>
    );
  }
}
