// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { prepareErrorsForForm } from "utils/errors";

import { jsonPointerToFieldName } from "./jsonPointerToFieldName";

export const onSuccess = (data, values) => {
  const icon_small = get(values, "metadata.icon_small");
  const icon_big = get(values, "metadata.icon_big");
  const thumbnail = get(values, "metadata.thumbnail");
  const billboard = get(values, "metadata.billboard");
  const screenshot = get(values, "metadata.screenshot");

  if (icon_small) {
    URL.revokeObjectURL(icon_small);
  }

  if (icon_big) {
    URL.revokeObjectURL(icon_big);
  }

  if (thumbnail) {
    URL.revokeObjectURL(thumbnail);
  }

  if (billboard) {
    URL.revokeObjectURL(billboard);
  }

  if (screenshot && Array.isArray(screenshot)) {
    screenshot.forEach((item) => {
      URL.revokeObjectURL(item);
    });
  }

  return data;
};

export const checkResponse = (response) => {
  if (response.error) {
    throw prepareErrorsForForm(response.error, jsonPointerToFieldName);
  }

  return response;
};

export const catchError = () => (err) => {
  if (err instanceof Error) {
    setTimeout(() => {
      throw err;
    });
  }

  throw err;
};
