// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable react/no-multi-comp */
import { Field } from "formik";
import PropTypes from "prop-types";

import { Switch } from "components/form/elements";
import { trans } from "src/translations";

export const EnableField = ({ isFinished }) => (
  <Field name="is_enabled">
    {({ field, meta }) => (
      <Switch
        onChange={() => {}}
        {...field}
        checked={field.value}
        label={trans.CAMPAIGN_EDIT__FIELD_IS_ENABLED()}
        tooltip={trans.CAMPAIGN_EDIT__FIELD_IS_ENABLED_TOOLTIP()}
        disabled={isFinished}
        {...meta}
      />
    )}
  </Field>
);

EnableField.propTypes = {
  isFinished: PropTypes.bool.isRequired,
};
