// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import cloneDeep from "lodash-es/cloneDeep";
import PropTypes from "prop-types";

import { VerticalTabs } from "components/layout";
import { NEW_TRANSLATION } from "pages/Snap/shared/constants";
import { TranslationContent } from "pages/Snap/SnapEdit/components/Translations/TranslationContent";
import { isValidTranslation } from "pages/Snap/SnapEdit/validation";
import { trans } from "src/translations";
import { capitalize } from "utils/string";

const UNTITLED_TAB_NAME = trans.UNTITLED();

export class Translations extends PureComponent {
  static propTypes = {
    languages: PropTypes.array.isRequired,
    /** props used for Formik managed field */
    name: PropTypes.string,
    /**  from Formik context */
    push: PropTypes.func,
    remove: PropTypes.func,
    form: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.getDefaultTab(),
    };
  }

  handleTabChange = (index) => {
    const { activeTab } = this.state;
    if (index === activeTab) {
      return;
    }
    this.setState({ activeTab: index });
  };

  getDefaultTab = () => {
    const {
      form: { getFieldMeta },
      name,
    } = this.props;

    const { value: translations } = getFieldMeta(name);
    return translations?.findIndex((lang) => lang.default_translation) ?? 0;
  };

  getLanguageName = (translationLanguage) => {
    const { languages } = this.props;
    const language = languages.filter(
      (language) => language.code === translationLanguage
    );

    return language?.[0]?.name
      ? `${capitalize(language[0].name)} (${language[0].code.toUpperCase()})`
      : UNTITLED_TAB_NAME;
  };

  getTabNames = (translation) => ({
    default: translation.default_translation,
    name: this.getLanguageName(translation.language),
    invalid: !isValidTranslation(translation),
  });

  isAnyTabUntitled = () => {
    const tabNames = this.getFieldTabNames();
    return tabNames.some(({ name }) => name === UNTITLED_TAB_NAME);
  };

  isAddButtonDisplayed = () => {
    const { languages } = this.props;
    const tabNames = this.getFieldTabNames();
    const tabsCount = tabNames.length;
    const maxTabsCount = languages.length;

    if (this.isAnyTabUntitled()) {
      return false;
    }

    return tabsCount < maxTabsCount;
  };

  getFieldTabNames = () => {
    const {
      form: { getFieldMeta },
      name,
    } = this.props;
    const { value: fieldsList } = getFieldMeta(name);

    return fieldsList.map(this.getTabNames);
  };

  render() {
    const {
      form: { getFieldMeta },
      languages,
      push,
      remove,
      name,
    } = this.props;
    const { activeTab } = this.state;
    const { value } = getFieldMeta(name);

    const tabNames = this.getFieldTabNames();
    return (
      <VerticalTabs
        initSelectedIndex={activeTab}
        tabNames={tabNames}
        onAdd={(e) => {
          e.preventDefault();
          push(cloneDeep(NEW_TRANSLATION));
        }}
        onRemove={(index, e) => {
          e.preventDefault();
          remove(index);
          if (activeTab === value.length - 1) {
            // eslint-disable-next-line no-magic-numbers
            this.setState({ activeTab: value.length - 2 });
          }
        }}
        onTabChange={this.handleTabChange}
        labelAddNewItem={`+ ${trans.APP_EDIT__NEW_TRANSLATION()}`}
        isAddButtonDisplayed={this.isAddButtonDisplayed()}
      >
        {() => (
          <TranslationContent
            languages={languages}
            name={name}
            excluded={
              value ? value.map((translation) => translation.language) : []
            }
            activeTab={activeTab}
          />
        )}
      </VerticalTabs>
    );
  }
}
