// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { DeviceModelFormData } from "../_shared/DeviceModelForm/DeviceModelFormData";

export class CreateDeviceModel extends Component {
  render() {
    return (
      <div>
        <PageHeader>{trans.DEVICES__ADD_NEW_MODEL_PAGE_HEADER()}</PageHeader>
        <PageContent>
          <DeviceModelFormData />
        </PageContent>
      </div>
    );
  }
}
