// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { Select } from "components/form/elements";
import { trans } from "src/translations";
import { createFieldValidator, isRequired } from "utils/validation";

import styles from "./InboxField.scss";

export const InboxField = ({ inboxes, name }) => {
  const validate = createFieldValidator(name, isRequired);
  return (
    <Field name={name} validate={validate}>
      {({ meta, field }) => {
        // there is no need to display this field if user has no choice
        // (only 1 available inbox)
        if (inboxes.length < 2) {
          return null;
        }

        return (
          <Fragment>
            <span className={styles.PostTo}>
              {trans.APP_DETAILS_MESSAGES__FIELD_INBOX_POST_TO()}
            </span>
            <Select
              className={styles.Dropdown}
              values={inboxes}
              size="compact"
              {...meta}
              {...field}
            />
          </Fragment>
        );
      }}
    </Field>
  );
};

InboxField.propTypes = {
  inboxes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  name: PropTypes.string,
};
