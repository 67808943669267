// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { withApollo } from "@apollo/client/react/hoc";
import get from "lodash-es/get";
import PropTypes from "prop-types";
import queryString from "query-string";

import { withRouter } from "utils/decorators/withRouter";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType, withMutation } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";

import { dismissDevicePdidAlertMutation } from "../gql/dismissDevicePdidAlertMutation";
import { getDeviceHistoricalPdidsQuery } from "../gql/getDeviceHistoricalPdidsQuery";
import {
  getDeviceFragmentQuery,
  getDevicePdidsFragmentQuery,
} from "../gql/getDeviceQuery";
import { PdidHistoryTable } from "./PdidHistoryTable";

const ITEMS_PER_PAGE = 50;

@withQuery({
  name: "deviceHistoricalPdids",
  query: getDeviceHistoricalPdidsQuery,
})
@withMutation({
  name: "dismissDevicePdidAlert",
  mutation: dismissDevicePdidAlertMutation,
})
@withRouter
@withApollo
export class PdidHistoryData extends PureComponent {
  static propTypes = {
    deviceId: PropTypes.string,

    // from @withQuery
    deviceHistoricalPdidsQuery: PropTypes.func,
    deviceHistoricalPdidsQueryStatus: queryResultType,

    // from @withMutation
    dismissDevicePdidAlertMutation: PropTypes.func,

    // from @withRouter
    location: PropTypes.object,

    // from @withApollo
    client: PropTypes.object,
  };

  state = {
    loading: false,
    error: undefined,
    isModalOpen: false,
    pdidToDismiss: null,
  };
  componentDidMount() {
    const { page } = queryString.parse(this.props.location.search);
    if (page > 1) {
      this.fetchHistoricalPdids();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchHistoricalPdids();
    }
  }

  fetchHistoricalPdids = async () => {
    const { deviceId, location, deviceHistoricalPdidsQuery, client } =
      this.props;
    this.setState({
      loading: true,
    });
    await deviceHistoricalPdidsQuery({
      variables: {
        deviceId,
        pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
      },
    });
    const { loading, error, results } = this.getDeviceHistoricalPdidsData();
    const deviceFromCache = client.readFragment({
      fragment: getDevicePdidsFragmentQuery,
      id: `DeviceType:${deviceId}`,
    });
    client.writeFragment({
      id: `DeviceType:${deviceId}`,
      fragment: getDevicePdidsFragmentQuery,
      data: {
        ...deviceFromCache,
        pdids: {
          ...deviceFromCache.pdids,
          items: [...results],
        },
      },
    });
    this.setState({
      loading,
      error,
    });
  };

  getDeviceHistoricalPdidsData = () => {
    const { data, error, loading } =
      this.props.deviceHistoricalPdidsQueryStatus;

    return {
      error: error && getGqlErrorMessage(error),
      loading,
      results: get(data, "deviceHistoricalPdids.pdids", []),
    };
  };

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
      pdidToDismiss: null,
    });
  };

  handleDismissAlert = async () => {
    const { dismissDevicePdidAlertMutation, deviceId } = this.props;
    const { pdidToDismiss } = this.state;

    await dismissDevicePdidAlertMutation({
      variables: {
        input: {
          pdid: pdidToDismiss,
        },
      },
      update: (apolloClient) => {
        // We have to update apollo cache for the device as we are dismissing
        // one of the pdid alerts - alerts counters should be updated too
        // as e.g. error message is shown based on those counters
        const deviceFromCache = apolloClient.readFragment({
          fragment: getDeviceFragmentQuery,
          id: `DeviceType:${deviceId}`,
        });
        apolloClient.writeFragment({
          id: `DeviceType:${deviceId}`,
          fragment: getDeviceFragmentQuery,
          data: {
            ...deviceFromCache,
            activeAlertsNumber: deviceFromCache.activeAlertsNumber - 1,
            dismissedAlertsNumber: deviceFromCache.dismissedAlertsNumber + 1,
          },
        });
        this.setState({
          isModalOpen: false,
        });
      },
    });
  };

  handleActiveAlertClick = (id) => () => {
    this.setState({
      isModalOpen: true,
      pdidToDismiss: id,
    });
  };

  render() {
    const { deviceId, client } = this.props;
    const { isModalOpen, loading, error } = this.state;

    const results = client.readFragment({
      fragment: getDevicePdidsFragmentQuery,
      id: `DeviceType:${deviceId}`,
    });
    return (
      <PdidHistoryTable
        pdids={loading ? [] : results.pdids.items || []}
        pdidsCount={get(results.pdids, "pagination.count", 0)}
        loading={loading}
        error={error}
        handleModalClose={this.handleModalClose}
        handleDismissAlert={this.handleDismissAlert}
        handleActiveAlertClick={this.handleActiveAlertClick}
        isModalOpen={isModalOpen}
      />
    );
  }
}
