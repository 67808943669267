// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { CustomPlanFormData } from "./CustomPlanFormData";

export class CustomPlanForm extends Component {
  static propTypes = {
    // from @ExtendedRoute
    params: PropTypes.object.isRequired,
  };

  isEdit = () => {
    return this.props.params.id !== undefined;
  };

  render() {
    return (
      <div>
        <PageHeader>
          {this.isEdit()
            ? trans.DISTR_PLAN__EDIT_PAGE_HEADER()
            : trans.DISTR_PLAN__CREATE_PAGE_HEADER()}
        </PageHeader>
        <PageContent>
          <CustomPlanFormData isEdit={this.isEdit()} />
        </PageContent>
      </div>
    );
  }
}
