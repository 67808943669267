// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import { APOLLO_STATUS, GRPC_STATUS } from "../graphql/constants";

// https://www.apollographql.com/docs/react/data/error-handling/
const APOLLO_GQL_ERRORS_KEY = "graphQLErrors";
const APOLLO_NETWORK_ERROR_KEY = "networkError";

const GENERIC_MESSAGE = {
  ALREADY_EXISTS_ERROR: trans.GENERIC_ERROR__ALREADY_EXISTS_ERROR(),
  FIELD_ERROR: trans.GENERIC_ERROR__FIELD_ERROR(),
  FORBIDDEN_ERROR: trans.GENERIC_ERROR__FORBIDDEN_ERROR(),
  NETWORK_ERROR: trans.GENERIC_ERROR__NETWORK_ERROR(),
  NO_ERROR: trans.GENERIC_ERROR__NO_ERROR(),
  NOT_FOUND_ERROR: trans.GENERIC_ERROR__NOT_FOUND_ERROR(),
  REQUEST_ERROR: trans.GENERIC_ERROR__REQUEST_ERROR(),
  SERVER_ERROR: trans.GENERIC_ERROR__SERVER_ERROR(),
  USER_INPUT_ERROR: trans.GENERIC_ERROR__USER_INPUT_ERROR(),
};

const isArray = (x) => Array.isArray(x);
const isObject = (x) => Boolean(x && typeof x === "object" && !isArray(x));
const isNonEmptyString = (x) => Boolean(x && typeof x === "string" && x !== "");
const isNotSet = (x) => x === null || x === undefined;

const getGenericMessage = (apolloStatus, grpcStatus, isNetworkError) => {
  if (isNetworkError) {
    return GENERIC_MESSAGE.NETWORK_ERROR;
  }
  switch (grpcStatus) {
    case GRPC_STATUS.OK_0:
      return GENERIC_MESSAGE.NO_ERROR;
    case GRPC_STATUS.INVALID_ARGUMENT_3:
      return GENERIC_MESSAGE.USER_INPUT_ERROR;
    case GRPC_STATUS.NOT_FOUND_5:
      return GENERIC_MESSAGE.NOT_FOUND_ERROR;
    case GRPC_STATUS.ALREADY_EXISTS_6:
      return GENERIC_MESSAGE.ALREADY_EXISTS_ERROR;
    case GRPC_STATUS.PERMISSION_DENIED_7:
      return GENERIC_MESSAGE.FORBIDDEN_ERROR;
  }
  switch (apolloStatus) {
    case APOLLO_STATUS.BAD_USER_INPUT:
      return GENERIC_MESSAGE.USER_INPUT_ERROR;
    case APOLLO_STATUS.INTERNAL_SERVER_ERROR:
      return GENERIC_MESSAGE.SERVER_ERROR;
  }
  return GENERIC_MESSAGE.REQUEST_ERROR;
};

const buildErrorObject = ({
  apolloStatus,
  grpcStatus,
  isNetworkError,
  message,
  formMessages,
  preferGenericMessages,
}) => {
  const derivedMessage =
    preferGenericMessages || !isNonEmptyString(message)
      ? getGenericMessage(apolloStatus, grpcStatus, isNetworkError)
      : message;
  return {
    message: derivedMessage,
    formMessages: isObject(formMessages) ? formMessages : {},
    grpcStatus: Number(grpcStatus) ? grpcStatus : null,
    isAlreadyExists: grpcStatus === GRPC_STATUS.ALREADY_EXISTS_6,
    isForbidden: grpcStatus === GRPC_STATUS.PERMISSION_DENIED_7,
    isNotFound: grpcStatus === GRPC_STATUS.NOT_FOUND_5,
    isOk: !isNetworkError && grpcStatus === GRPC_STATUS.OK_0,
    isProduction: process.env.NODE_ENV === "production",
  };
};

const parseDetails = (error, translations) => {
  if (error?.extensions?.grpcCode !== GRPC_STATUS.INVALID_ARGUMENT_3) {
    return null;
  }
  const details = error?.extensions?.details;
  if (!isArray(details)) {
    return null;
  }
  return details.flat().reduce((previous, fieldDetails) => {
    if (!isObject(fieldDetails)) {
      return previous;
    }
    const { description, field, message, title } = fieldDetails;
    if (!isNonEmptyString(field)) {
      return previous;
    }
    if (isNonEmptyString(previous[field])) {
      // Ignore repeated field.
      return previous;
    }
    let value = null;
    if (translations) {
      const translation = translations[title];
      if (isNonEmptyString(translation)) {
        value = translation;
      }
    }
    if (!value && isNonEmptyString(message)) {
      value = message;
    }
    if (!value && isNonEmptyString(description)) {
      value = description;
    }
    return {
      ...previous,
      [field]: value,
    };
  }, {});
};

const parseField = (response, options, isFieldKey) => {
  if (response[APOLLO_NETWORK_ERROR_KEY] || !response[APOLLO_GQL_ERRORS_KEY]) {
    // No error, but only for this field. There are other errors.
    return null;
  }
  const gqlErrors = response[APOLLO_GQL_ERRORS_KEY];
  if (!isArray(gqlErrors)) {
    // No error, but only for this field. There are other errors.
    return null;
  }
  let fieldError;
  let fieldMessage;
  const grpcErrors = gqlErrors.filter((error) => error?.extensions?.grpcCode);
  for (const error of grpcErrors) {
    const details = parseDetails(error, options.translations);
    if (!details) {
      continue;
    }
    const fieldKey = Object.keys(details).find(isFieldKey);
    if (fieldKey) {
      fieldError = error;
      fieldMessage = details[fieldKey];
      break;
    }
  }
  if (!fieldError) {
    // No error, but only for this field. There are other errors.
    return null;
  }
  // Return a gRPC error for this field.
  return buildErrorObject({
    apolloStatus: fieldError?.extensions?.code,
    grpcStatus: fieldError?.extensions?.grpcCode,
    message: fieldMessage ?? GENERIC_MESSAGE.FIELD_ERROR,
    preferGenericMessages: options.preferGenericMessages,
  });
};

const parseForm = (response, options, handleException) => {
  if (response[APOLLO_NETWORK_ERROR_KEY]) {
    const networkError = response[APOLLO_NETWORK_ERROR_KEY];
    if (!isObject(networkError)) {
      handleException("The response structure is invalid", response);
      return null;
    }
    const apolloStatus = networkError?.extensions?.code;
    if (apolloStatus) {
      // Return an Apollo error.
      console.error(`[Apollo GrapQL] ${networkError?.message}`);
      return buildErrorObject({
        apolloStatus,
        message: options.allowRawApolloMessages ? networkError?.message : null,
        preferGenericMessages: options.preferGenericMessages,
      });
    }
    // Return a network error.
    return buildErrorObject({
      isNetworkError: true,
      preferGenericMessages: options.preferGenericMessages,
    });
  } else if (response[APOLLO_GQL_ERRORS_KEY]) {
    const gqlErrors = response[APOLLO_GQL_ERRORS_KEY];
    if (!isArray(gqlErrors) || gqlErrors.length === 0) {
      handleException("The response structure is invalid", response);
      return null;
    }
    const grpcErrors = gqlErrors.filter((error) => error?.extensions?.grpcCode);
    if (grpcErrors.length === 0) {
      const apolloStatus = gqlErrors[0]?.extensions?.code;
      // Workaround for unhandled gRPC errors in API Gateway.
      if (apolloStatus === APOLLO_STATUS.INTERNAL_SERVER_ERROR) {
        const exceptionCode = gqlErrors[0]?.extensions?.exception?.code;
        const exceptionMessage = gqlErrors[0]?.extensions?.exception?.details;
        if (
          Number(exceptionCode) < GRPC_STATUS._END &&
          isNonEmptyString(exceptionMessage)
        ) {
          // Probably an unhandled gRPC error. Return it as such.
          return buildErrorObject({
            grpcStatus: exceptionCode,
            message: exceptionMessage,
            preferGenericMessages: options.preferGenericMessages,
          });
        }
      }
      // Return the first Apollo error.
      console.error(`[Apollo GrapQL] ${gqlErrors[0]?.message}`);
      return buildErrorObject({
        apolloStatus,
        message: options.allowRawApolloMessages ? gqlErrors[0]?.message : null,
        preferGenericMessages: options.preferGenericMessages,
      });
    }
    const details = grpcErrors.reduce((previous, error) => {
      const current = parseDetails(error, options.translations);
      return {
        ...current,
        ...previous,
      };
    }, {});
    for (const [key, value] of Object.entries(details)) {
      if (!value) {
        details[key] = GENERIC_MESSAGE.FIELD_ERROR;
      }
    }
    // Return the first gRPC error.
    return buildErrorObject({
      apolloStatus: grpcErrors[0]?.extensions?.code,
      grpcStatus: grpcErrors[0]?.extensions?.grpcCode,
      message: grpcErrors[0]?.extensions?.grpcMessage,
      formMessages: details,
      preferGenericMessages: options.preferGenericMessages,
    });
  }
  handleException("The response structure is invalid", response);
  return null;
};

/**
 * Parses GraphQL error response into a standard error object.
 *
 * The function returns either:
 * - the standard error object,
 * - or null, when parsing fails or there is no error.
 *
 * The standard error object has the following structure:
 * - `message`: a non-empty, human-readable string describing what caused the
 * error;
 * - `formMessages`: an object maping form field names to their coresponding
 * error messages, if there are any;
 * - `grpcStatus`: a number describing the status of the gRPC operation or null,
 * - `isAlreadyExists`, `isForbidden`, `isNotFound`: the boolean flags
 * describing if a corresponding gRPC error occurred;
 * - `isOk`: a boolean flag describing if an error occurred at all (then it is
 * false`);
 * - `isProduction`: a boolean flag describing if the current configuration is
 * the production one (can be useful when displaying a full error message to the
 * end user is not desirable);
 *
 * When `fieldName` or `fieldPattern` is set, the function operates in a special
 * mode that searches for the first error that matches the field. If none is
 * found, the function returns `null` without logging or throwing an exception.
 * In this mode, `message` is the field error message and `formMessages` is
 * always `{}`. This can be useful when checking if an error of a single field
 * or a group of fields occurred or when the exact name of the field is not
 * known.
 *
 * @param response - GraphQL response.
 * @param allowRawApolloMessages - when set to `true`, the function returns
 *     raw Apollo messages, instead of generic messages, for non-gRPC errors.
 * @param fieldName - the exact name of the field used to search for the first
 *     error of the field.
 * @param fieldPattern - a string pattern (passed to `RegExp`) used to search
 *     for the first error of the field or the group or fields.
 * @param preferGenericMessages - when set to `true`, the function always
 *     returns generic messages. This does not affect `formMessages`.
 * @param throwExceptions - when set to `true`, the function throws exceptions
 *     instead of logging them to the console.
 * @param translations - an object mapping error titles (`title`) to their
 *     coresponding translations.
 * @returns a standard error object or `null`
 */
export const parseGqlError = (
  response,
  options = {
    allowRawApolloMessages: false,
    fieldName: null,
    fieldPattern: null,
    preferGenericMessages: false,
    throwExceptions: false,
    translations: null,
  }
) => {
  /* --- Helper functions --- */
  const handleException = (message, argument) => {
    let derivedMessage;
    try {
      const argumentString = JSON.stringify(argument, null, 2);
      if (argumentString.includes("\n")) {
        derivedMessage = message + ":\n" + argumentString;
      } else {
        derivedMessage = message + ": " + argumentString;
      }
    } catch (e) {
      derivedMessage = message + ": " + argument;
    }
    if (options.throwExceptions) {
      throw new Error(derivedMessage);
    } else {
      console.error(derivedMessage);
    }
  };
  /* --- Argument validation --- */
  if (!isObject(response)) {
    handleException("The 'response' argument is invalid", response);
    return null;
  }
  if (!isNotSet(options.fieldName) && !isNonEmptyString(options.fieldName)) {
    handleException("The 'fieldName' argument is invalid", options.fieldName);
    return null;
  }
  if (
    !isNotSet(options.fieldPattern) &&
    !isNonEmptyString(options.fieldPattern)
  ) {
    handleException(
      "The 'fieldPattern' argument is invalid",
      options.fieldPattern
    );
    return null;
  }
  if (!isNotSet(options.translations) && !isObject(options.translations)) {
    handleException(
      "The 'translations' argument is invalid",
      options.translations
    );
    return null;
  }
  /* --- Parsing a single field --- */
  let isFieldKey;
  if (options.fieldPattern) {
    try {
      const pattern = new RegExp(options.fieldPattern);
      isFieldKey = (key) => pattern.test(key);
    } catch (e) {
      handleException(
        "The 'fieldPattern' argument is invalid",
        options.fieldPattern
      );
      return null;
    }
  } else if (options.fieldName) {
    isFieldKey = (key) => key === options.fieldName;
  }
  if (isFieldKey) {
    return parseField(response, options, isFieldKey);
  }
  /* --- Parsing an entire form --- */
  return parseForm(response, options, handleException);
};

/**
 * Calls parseGqlError with default options. Returns only the message. If
 * parseGqlError does not return any message, returns the default message.
 *
 * @param response - GraphQL response.
 */
export const getGqlErrorMessage = (response) =>
  parseGqlError(response)?.message ?? trans.DEFAULT_REQUEST_ERROR_MESSAGE();
