// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import {
  getFilterObjectById,
  createFilter,
  flattenFilterDataChoices,
} from "./utils";

/**
 * Given information about filter object and value/array of values,
 * create search filters that are going to be displayed as tags.
 */
const createFiltersFromValue = (filterObject, value, isExclusionFilter) => {
  if (Array.isArray(value)) {
    return value.map((v) => createFilter(filterObject, v, isExclusionFilter));
  }
  return [createFilter(filterObject, value, isExclusionFilter)];
};

/**
 * When refreshing page, we need to read search parameters from url
 * and convert them to search text value and array of filters.
 */

export const parseSearchParams = (
  searchParams,
  availableFilters,
  isExclusionFilter
) => {
  const partialState = {
    filters: [],
  };
  const allFilters = flattenFilterDataChoices(availableFilters);
  Object.entries(searchParams || {}).forEach(([key, value]) => {
    const filterObject = getFilterObjectById(key, allFilters);
    if (filterObject) {
      partialState.filters.push(
        ...createFiltersFromValue(filterObject, value, isExclusionFilter)
      );
    }
  });

  return partialState;
};
