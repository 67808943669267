// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable no-magic-numbers */
import { trans } from "src/translations";
import {
  pipeValidators,
  isRequiredWhen,
  maxLengthOfStr,
  isNotEqual,
} from "utils/validation";

import { Changelog } from "../../../components";
import { CHANGELOG_TYPES } from "../../../constants";

export default {
  name: trans.APP_EDIT_FOR_MODERATORS__CHANGELOG_SECTION_NAME(),
  isEnabled: (props) => props.appData.has_submitted_version,
  components: [
    {
      component: Changelog,
      fields: ["changelog.change_type", "changelog.additional_information"],
      initialValues: {
        changelog: {
          change_type: CHANGELOG_TYPES.REWRITE,
          additional_information: "",
        },
      },
      validate: (flatValues, values, errors, appData) => {
        const hasSubmittedVersion = appData.has_submitted_version;
        return pipeValidators(
          isRequiredWhen("changelog.change_type", () => hasSubmittedVersion),
          isNotEqual(
            "changelog.change_type",
            CHANGELOG_TYPES.INITIAL,
            trans.APP_EDIT_FOR_MODERATORS__CHANGELOG_NOT_INITIAL_VERSION_WARNING()
          ),
          isRequiredWhen(
            "changelog.additional_information",
            () => hasSubmittedVersion
          ),
          maxLengthOfStr("changelog.additional_information", 1024)
        )(flatValues, values, errors, appData);
      },
      props: ({ formikProps }) => {
        return {
          changelogTypeField: "changelog.change_type",
          changelogAdditionalInfoField: "changelog.additional_information",
          ...formikProps,
        };
      },
    },
  ],
};
