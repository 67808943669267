// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as actions from "./actionTypes";

const defaultState = {
  isActive: false,
  asset: {
    id: "",
    name: "",
    description: "",
    defaultVersion: "",
    organization: "",
    versions: [],
  },
  model: {
    id: "",
    name: "",
    regions: "",
    countries: [],
    isWorldwide: false,
  },
  version: "",
};

export const addDeviceRegions = (state = defaultState, action) => {
  switch (action.type) {
    case actions.ADD_DEVICE_REGIONS_ACTIVE:
      return {
        ...state,
        isActive: true,
        asset: action.asset,
        model: action.model,
        version: action.version,
      };
    case actions.ADD_DEVICE_REGIONS_INACTIVE:
      return {
        ...state,
        isActive: false,
      };
    default:
      return state;
  }
};
