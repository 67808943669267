// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { classes } from "utils/classes";

import styles from "./overlay.scss";

/**
 * Transparent overlay that hides content beneath it.
 */
export class Overlay extends Component {
  static propTypes = {
    /** Optional text message */
    message: PropTypes.string,
    children: PropTypes.node,
    /** If true, `children` content will be hidden. If false, this component does nothing */
    active: PropTypes.bool,
    /** Override style */
    style: PropTypes.object,
    /** Currently `<Overlay>` can be used only with loader */
    loaderSize: PropTypes.oneOf(["small", "medium", "large"]),
    className: PropTypes.string,
  };

  static defaultProps = {
    message: "",
    active: false,
  };

  overlay(message) {
    const { style, loaderSize, className } = this.props;

    return (
      <div className={classes(styles.overlay, className)} style={style}>
        <Loader message={message} size={loaderSize} />
      </div>
    );
  }

  render() {
    const { message, children, active } = this.props;

    return (
      <div className={styles.wrapper}>
        {active ? this.overlay(message) : null}
        {children}
      </div>
    );
  }
}
