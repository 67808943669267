// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import popupStyles from "components/popups/Popup/popupRend.scss";
import { classes } from "utils/classes";

import styles from "./TextOverflowPopup.scss";

/**
 * Simple component protecting content from overflowing page with too long content
 */
// @todo Component does not update overflow state on page resize. It might happen whenever user resize browser window after page render.
export class TextOverflowPopup extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    overflowText: PropTypes.node,
  };

  state = {
    overflow: false,
  };

  checkOverflow = () => {
    this.setState({
      overflow: this.element.offsetWidth < this.element.scrollWidth,
    });
  };

  componentDidMount() {
    this.checkOverflow();
  }

  componentDidUpdate() {
    this.checkOverflow();
  }

  render() {
    const { children, className, overflowText } = this.props;
    const { overflow } = this.state;
    const uiOffset = 10;

    return (
      <div
        ref={(reference) => {
          this.element = reference;
        }}
        className={classes(styles.popup, className)}
      >
        {overflow ? (
          <div
            className={classes(popupStyles.popup, styles.popupContent)}
            style={{
              marginTop: this.element.offsetHeight + uiOffset + "px",
            }}
          >
            {overflowText || children}
            <div
              className={classes(
                popupStyles.arrow,
                popupStyles.arrowTop,
                styles.arrow
              )}
            />
          </div>
        ) : null}
        {children}
      </div>
    );
  }
}
