// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { withAbort } from "utils/decorators/withAbort";

import { UPLOAD_STATUS } from "./constants";
import { parseAWSResponse, sendFileUploadRequest } from "./utils";

const DEFAULT_IDLE_STATE = {
  status: UPLOAD_STATUS.idle,
  progress: 0,
  startDate: undefined,
};

/**
 * This component uploads directly to AWS S3. First we need to get presigned
 * upload url (generate_presigned_post in AWS). This allows one time file
 * upload without credentials. Then, we use received url and headers
 * in normal file upload operation. Last step is to call finish callback.
 *
 * This component composes nicely with `<UploaderAWSField>`. You can
 * also use it with custom components to make use of `status` prop
 * to e.g. disable submit button.
 */
@withAbort
class UploaderAWS extends Component {
  static propTypes = {
    /**
     * Function that renders the upload state.
     *
     * Type: (props: AwsUploaderChildrenProps) => React.Element
     */
    children: PropTypes.func.isRequired,
    /**
     * Called after upload encounters an error.
     *
     * Type: `(err: Error) => void`
     */
    onError: PropTypes.func,
    /**
     * Called after upload finished. Can be a promise if needed. If this callback
     * throws, `onError` will be invoked.
     *
     * Type: `({
     *   fileUrl: string,
     *   fileName: string,
     * }) => Promise<void>`
     */
    onComplete: PropTypes.func.isRequired,
    /**
     * Called when user cancelled the file upload. Also invoked when file was
     * already uploaded, but user clicked 'x' button. This callback will
     * not call `onError`. You do not need to handle interaction between
     * request cancel and `getCredentials`. The result of `getCredentials`
     * will be ignored.
     *
     * Type: `() => void`
     */
    onCancel: PropTypes.func,
    /**
     * An async function returning credentials required by AWS to upload a file.
     * Part of credentials is the actual upload url. Should throw on error.
     *
     * This function is always called before any file is uploaded and can be
     * used as `onStart` callback.
     *
     * Type: () => {
     *   url: string;
     *   headers: { ... } // special headers from AWS API `fields`
     * }
     */
    getCredentials: PropTypes.func.isRequired,

    // from @withAbort
    /** @ignore */
    createAbortSignal: PropTypes.func.isRequired,
    /** @ignore */
    abort: PropTypes.func.isRequired,
    /** @ignore */
    isAbortError: PropTypes.func.isRequired,
  };

  state = { ...DEFAULT_IDLE_STATE };

  abortSignal = null;

  getAwsCredentials = async () => {
    const result = await this.props.getCredentials();
    return {
      url: result.url,
      headers: result.headers,
    };
  };

  prepareRequestBody = (awsHeaders, file) => {
    const body = new FormData();

    Object.keys(awsHeaders).forEach((headerName) => {
      body.append(headerName, awsHeaders[headerName]);
    });

    body.append("file", file);

    return body;
  };

  handleProgress = (e) => {
    const progressPercentage = (e.loaded * 100) / e.total;
    this.setState({
      progress: Math.floor(progressPercentage),
    });
  };

  uploadFile = async (file) => {
    const { createAbortSignal } = this.props;

    const abortSignal = (this.abortSignal = createAbortSignal());

    const { url, headers } = await this.getAwsCredentials();

    const body = this.prepareRequestBody(headers, file);

    // If signal was aborted during waiting for credentials, this will throw.
    // We do not rely on `getCredentials` to cancel itself.
    const uploadResponse = await sendFileUploadRequest(url, {
      body,
      signal: abortSignal.signal,
      onProgress: this.handleProgress,
    });

    return parseAWSResponse(uploadResponse);
  };

  handleUploadFileEvent = async (event) => {
    try {
      const [file] = event.target.files;
      if (!file) {
        return;
      }

      this.setState({
        status: UPLOAD_STATUS.progress,
        progress: 0,
        startDate: new Date(),
      });

      const fileUrl = await this.uploadFile(file);

      // remember: uploading file may take a while.
      // No deconstruction just in case ref changed.
      this.props.onComplete({
        fileUrl,
        fileName: file.name,
      });

      this.setState({
        status: UPLOAD_STATUS.completed,
      });
    } catch (error) {
      this.handleUploadError(error);
    }
  };

  handleUploadError(error) {
    const { onError, isAbortError } = this.props;

    if (isAbortError(error)) {
      return;
    }

    this.setState({
      status: UPLOAD_STATUS.error,
    });

    if (onError) {
      onError(error);
    }

    // rethrow for sentry
    error.customMessage = "UploaderAWS error - could not upload file to AWS";
    throw error;
  }

  handleCancelClick = () => {
    const { onCancel, abort } = this.props;

    abort(this.abortSignal);
    this.abortSignal = undefined;

    this.setState({ ...DEFAULT_IDLE_STATE });

    if (onCancel) {
      onCancel();
    }
  };

  createChildProps() {
    return {
      status: this.state.status,
      onUploadFile: this.handleUploadFileEvent,
      progressPercentage: this.state.progress,
      startDate: this.state.startDate,
      onCancel: this.handleCancelClick,
    };
  }

  render() {
    const childrenProps = this.createChildProps();
    return this.props.children(childrenProps);
  }
}

// styleguidist does not like exporting classes that use decorators
export { UploaderAWS };
