// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { TextField } from "src/components/form/fields";
import { trans } from "src/translations";
import { isEmail } from "utils/validation";

export default {
  name: trans.APP_EDIT__SUPPORT_EMAIL_SECTION_NAME(),
  components: [
    {
      component: TextField,
      fields: ["support_email"],
      initialValues: {
        support_email: "",
      },
      validate: isEmail("support_email"),
      props: () => {
        return {
          name: "support_email",
          label: trans.APP_EDIT__SUPPORT_EMAIL_LABEL(),
          placeholder: trans.APP_EDIT__ADD_AN_EMAIL_PLACEHOLDER(),
          tooltip: trans.APP_EDIT__SUPPORT_EMAIL_TOOLTIP(),
          dataTestId: "support-email-field",
        };
      },
    },
  ],
};
