// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as actions from "./actionTypes";

export const addBreadcrumb = (id, name, url) => ({
  type: actions.ADD_BREADCRUMB,
  id,
  name,
  url,
});

export const removeBreadcrumb = (id) => ({
  type: actions.REMOVE_BREADCRUMB,
  id,
});
