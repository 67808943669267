// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBarWithFilters } from "components/elements";
import { Info } from "components/feedback";
import { Section, TableBody } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";
import { withQueryParams, withRouter } from "utils/decorators";
import { createLocationWithSearch } from "utils/url";

import { PromotionRow } from "./PromotionRow";
import styles from "./PromotionsTable.scss";

const PROMOTIONS_FILTERS = {
  sections: [
    {
      title: trans.PROMOTION_LIST__FILTER_SECTION_NAME(),
      options: [
        {
          id: "admin_managed",
          displayName: "promotion_creator",
          param: trans.MANAGER(),
          description: trans.PROMOTION_LIST__FILTER_MANAGER_DESCRIPTION(),
          values: [{ query: "false", display: "Manager" }],
        },
      ],
    },
  ],
};

@withRouter
@withQueryParams
@withActiveOrganization
export class PromotionsTable extends Component {
  static propTypes = {
    promotions: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.any,
    count: PropTypes.number,
    forAdmin: PropTypes.bool,

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  };

  static tabsConfig = [
    {
      dataTestId: "promotions-list",
      columns: [
        { label: trans.NAME() },
        { label: trans.PROMOTION_LIST__COLUMN_APPS() },
        { label: trans.TYPE() },
        { label: trans.PROMOTION_LIST__COLUMN_CAMPAIGNS() },
        { label: "" },
      ],
    },
  ];

  onChangeSearchParams = (newSearchParams) => {
    const { location, navigate } = this.props;
    const newLocation = createLocationWithSearch(location, newSearchParams);
    navigate(newLocation);
  };

  getFilters = () => {
    const { forAdmin } = this.props;
    return forAdmin ? undefined : PROMOTIONS_FILTERS;
  };

  renderBody = () => {
    const { promotions, forAdmin } = this.props;
    return (
      <TableBody>
        {promotions.map((promotion) => (
          <PromotionRow
            key={promotion.id}
            promotion={promotion}
            forAdmin={forAdmin}
          />
        ))}
      </TableBody>
    );
  };

  renderCreateButton = () => {
    const { forAdmin } = this.props;
    return (
      <ButtonsWrapper>
        <Button
          type="green"
          className={styles.button}
          to={forAdmin ? "/promotions/admin/create" : "/promotions/create"}
        >
          {trans.PROMOTION_LIST__CREATE_BUTTON()}
        </Button>
      </ButtonsWrapper>
    );
  };

  render() {
    const {
      promotions,
      loading,
      error,
      count,
      location,
      queryParams,
      forAdmin,
      activeOrganization: { name },
    } = this.props;
    const locationMessage = get(location, "state.message");

    return (
      <div>
        <Section
          header={
            forAdmin
              ? trans.PROMOTION_LIST__SECTION_NAME_ADMIN()
              : trans.PROMOTION_LIST__SECTION_NAME({ organization: name })
          }
        >
          <Section>
            <SearchBarWithFilters
              onSearch={this.onChangeSearchParams}
              look="for-white-bg"
              placeholder={trans.PROMOTION_LIST__SEARCH_PLACEHOLDER()}
              searchParams={queryParams}
              filtersDataChoices={this.getFilters()}
            />
          </Section>
          <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
          {this.renderCreateButton()}
          <Section>
            <TabbedTable
              tabsConfig={PromotionsTable.tabsConfig}
              data={{ results: promotions, count, loading, error }}
              renderTableBody={this.renderBody}
            />
          </Section>
          {this.renderCreateButton()}
        </Section>
      </div>
    );
  }
}
