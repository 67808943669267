// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { API_URLS } from "config";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { Section, Subsection, Row, Column, Modal } from "components/layout";
import { Link } from "components/typography";
import { RestrictedArea, Permissions, ROLES } from "containers/Permissions";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import {
  PRIORITY_P1,
  PRIORITY_P2,
  PRIORITY_P3,
  PRIORITY_P4,
  PRIORITY_P5,
  UPDATE_SCOPE_MAJOR,
  UPDATE_SCOPE_MINOR,
  UPDATE_SCOPE_NONE,
  UNKNOWN_VALUE,
} from "../constants";
import { ChangeMenu } from "./_shared";
import { Exclusivity } from "./Exclusivity";
import { ModerationStatusSection } from "./ModerationStatusSection";
import { RejectVersion } from "./RejectVersion/RejectVersion";
import styles from "./SectionModeration.scss";
import { SupportLevel, getPrioritiesForSupportLevel } from "./SupportLevel";
import { User } from "./User";

export class SectionModeration extends Component {
  static propTypes = {
    error: PropTypes.object,
    loading: PropTypes.bool,
    moderationStatus: PropTypes.string,
    qualityRound: PropTypes.string,
    updateScope: PropTypes.oneOf([
      UPDATE_SCOPE_MAJOR,
      UPDATE_SCOPE_MINOR,
      UPDATE_SCOPE_NONE,
      UNKNOWN_VALUE,
    ]),
    moderationPriority: PropTypes.oneOf([
      PRIORITY_P1,
      PRIORITY_P2,
      PRIORITY_P3,
      PRIORITY_P4,
      PRIORITY_P5,
    ]),
    mainModerator: PropTypes.object,
    contentManager: PropTypes.object,
    supportLevel: PropTypes.bool,
    isExclusive: PropTypes.bool,
    applicationId: PropTypes.string,
    isAppStoreTesting: PropTypes.bool.isRequired,
    onPriorityChange: PropTypes.func,
    onUpdateScopeChange: PropTypes.func,
    onSupportLevelChange: PropTypes.func,
    onExclusivityChange: PropTypes.func,
    onModeratorChange: PropTypes.func,
    onContentManagerChange: PropTypes.func,
    onChangeModerationStatus: PropTypes.func.isRequired,
    onIsAppStoreTestingChange: PropTypes.func.isRequired,
    appOwner: PropTypes.object,
    latestModerationResult: PropTypes.object,
    latestVersionData: PropTypes.object,
    onApproveMetadata: PropTypes.func.isRequired,
    onRejectMetadata: PropTypes.func.isRequired,
  };

  state = {
    isRejectModalOpen: false,
    pendingApproveRequest: false,
  };

  openRejectModal = () => {
    this.setState({
      isRejectModalOpen: true,
    });
  };

  closeRejectModal = () => {
    this.setState({
      isRejectModalOpen: false,
    });
  };

  approveMetadata = async () => {
    this.setState({ pendingApproveRequest: true });
    await this.props.onApproveMetadata();
    this.setState({ pendingApproveRequest: false });
  };

  getJiraIssueLink() {
    const { qualityRound } = this.props;
    return `${API_URLS.JIRA}/browse/${qualityRound}`;
  }

  getUpdateScopeText() {
    const { updateScope } = this.props;
    switch (updateScope) {
      case UPDATE_SCOPE_MAJOR:
        return trans.CHANGE_MAJOR();
      case UPDATE_SCOPE_MINOR:
        return trans.CHANGE_MINOR();
      default:
        return undefined;
    }
  }

  getModerationPriorityText = (value) => {
    const { supportLevel } = this.props;
    const item = getPrioritiesForSupportLevel(supportLevel).find(
      (i) => value === i.value
    );

    if (item) {
      return item.text;
    }

    return NO_VALUE_PLACEHOLDER;
  };

  render() {
    const {
      error,
      loading,
      moderationStatus,
      qualityRound,
      moderationPriority,
      mainModerator,
      contentManager,
      supportLevel,
      isExclusive,
      applicationId,
      isAppStoreTesting,
      appOwner,
      latestModerationResult,
      latestVersionData,
      onPriorityChange,
      onUpdateScopeChange,
      onSupportLevelChange,
      onExclusivityChange,
      onModeratorChange,
      onContentManagerChange,
      onIsAppStoreTestingChange,
      onChangeModerationStatus,
      onRejectMetadata,
    } = this.props;
    const { isRejectModalOpen, pendingApproveRequest } = this.state;

    if (error) {
      return <Info type="error">{error.message || error}</Info>;
    }

    return (
      <div className={classes(styles.container, { [styles.loading]: loading })}>
        {loading && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        {latestModerationResult && (
          <Modal
            title={trans.APP_DETAILS_VERSION__REJECT_METADATA_MODAL_TITLE()}
            isOpen={isRejectModalOpen}
            onClose={this.closeRejectModal}
          >
            <RejectVersion
              close={this.closeRejectModal}
              onRejectMetadata={onRejectMetadata}
            />
          </Modal>
        )}
        <Section header={trans.APP_DETAILS_MODERATION__SECTION_HEADER()}>
          <Row>
            <Column>
              <Subsection header={trans.APP_DETAILS_MODERATION__STATUS()}>
                <ModerationStatusSection
                  applicationId={applicationId}
                  appOwner={appOwner}
                  moderationStatus={moderationStatus}
                  versionData={latestVersionData}
                  latestModerationResult={latestModerationResult}
                  openRejectModal={this.openRejectModal}
                  onChangeModerationStatus={onChangeModerationStatus}
                  onApproveMetadata={this.approveMetadata}
                  pendingApproveRequest={pendingApproveRequest}
                />
              </Subsection>
              <Subsection header={trans.APP_DETAILS_MODERATION__QA_ROUND()}>
                <Link
                  href={this.getJiraIssueLink()}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className={styles.qualityRoundNumber}
                >
                  {qualityRound}
                </Link>
                <Permissions allowed={[ROLES.moderator.contentManager]}>
                  {(isAllowed) => (
                    <ChangeMenu
                      value={this.getModerationPriorityText(moderationPriority)}
                      buttonText={`(${trans.APP_DETAILS_MODERATION__CHANGE_PRIORITY()})`}
                      options={getPrioritiesForSupportLevel(supportLevel).map(
                        ({ value, text }) => ({
                          id: value,
                          text: text,
                          onClick: onPriorityChange(value),
                        })
                      )}
                      disabled={!isAllowed}
                      valueClassName={styles[`p${moderationPriority}`]}
                    />
                  )}
                </Permissions>
              </Subsection>
              <Subsection header={trans.APP_DETAILS_MODERATION__UPDATE_SCOPE()}>
                <RestrictedArea
                  allowed={[ROLES.moderator.moderator]}
                  fallbackChildren={<p>{this.getUpdateScopeText()}</p>}
                >
                  <ChangeMenu
                    value={this.getUpdateScopeText()}
                    options={[
                      {
                        id: UPDATE_SCOPE_MAJOR,
                        text: trans.CHANGE_MAJOR(),
                        onClick: onUpdateScopeChange(UPDATE_SCOPE_MAJOR),
                      },
                      {
                        id: UPDATE_SCOPE_MINOR,
                        text: trans.CHANGE_MINOR(),
                        onClick: onUpdateScopeChange(UPDATE_SCOPE_MINOR),
                      },
                    ]}
                  />
                </RestrictedArea>
              </Subsection>
            </Column>
            <Column>
              <Subsection
                header={trans.APP_DETAILS_MODERATION__MAIN_MODERATOR()}
              >
                <RestrictedArea
                  allowed={[ROLES.moderator.moderator]}
                  fallbackChildren={<User user={mainModerator} />}
                >
                  <User onClick={onModeratorChange} user={mainModerator} />
                </RestrictedArea>
              </Subsection>
              <Subsection
                header={trans.APP_DETAILS_MODERATION__CONTENT_MANAGER()}
              >
                <RestrictedArea
                  allowed={[ROLES.moderator.contentManager]}
                  fallbackChildren={<User user={contentManager} />}
                >
                  <User
                    onClick={onContentManagerChange}
                    user={contentManager}
                  />
                </RestrictedArea>
              </Subsection>
              <Subsection
                header={trans.APP_DETAILS_MODERATION__SUPPORT_LEVEL()}
              >
                <SupportLevel
                  isPremium={supportLevel}
                  onSupportLevelChange={onSupportLevelChange}
                />
              </Subsection>
              <Subsection header={trans.APP_DETAILS_MODERATION__EXCLUSIVITY()}>
                <Exclusivity
                  isExclusive={isExclusive}
                  onExclusivityChange={onExclusivityChange}
                />
              </Subsection>
              <Subsection
                header={trans.APP_DETAILS_MODERATION__VEWD_APP_STORE_TESTING()}
              >
                <RestrictedArea
                  allowed={[ROLES.moderator.moderator]}
                  fallbackChildren={
                    <p>
                      {isAppStoreTesting
                        ? trans.APP_DETAILS_MODERATION__APP_IS_TESTED()
                        : trans.APP_DETAILS_MODERATION__APP_IS_NOT_TESTED()}
                    </p>
                  }
                >
                  <ButtonsWrapper>
                    <Button
                      size="small"
                      onClick={onIsAppStoreTestingChange(true)}
                      disabled={isAppStoreTesting}
                    >
                      {trans.APP_DETAILS_MODERATION__START_TESTING()}
                    </Button>
                    <Button
                      size="small"
                      onClick={onIsAppStoreTestingChange(false)}
                      disabled={!isAppStoreTesting}
                    >
                      {trans.APP_DETAILS_MODERATION__STOP_TESTING()}
                    </Button>
                  </ButtonsWrapper>
                </RestrictedArea>
              </Subsection>
            </Column>
          </Row>
        </Section>
      </div>
    );
  }
}
