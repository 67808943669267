// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { SubTabs, TabContent, SubTab } from "components/navigation";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import {
  DevicePropertiesSection,
  fromDeviceDateProperty,
  fromDeviceUpdateJobProperty,
  PackagesTable,
} from "../components";
import { TAB_ID_SOFTWARE } from "../constants";
import { DevicePropType } from "../propTypes";
import styles from "./SoftwareTab.scss";

export const INSTALLED_PACKAGES_SUBTAB_ID = "packages";
export const UPDATE_SUBTAB_ID = "update";
export const SOFTWARE_SUB_TAB_IDS = [
  INSTALLED_PACKAGES_SUBTAB_ID,
  UPDATE_SUBTAB_ID,
];

function renderInstalledTabContent(device) {
  return (
    <Fragment>
      <Section
        header={trans.DEVICES__DEVICE_DETAILS_INSTALLED_PACKAGES()}
        tooltip={trans.DEVICES__DEVICE_DETAILS_INSTALLED_PACKAGES_TOOLTIP()}
      >
        <PackagesTable packages={device.installedPackages} />
      </Section>
      <Section
        header={trans.DEVICES__DEVICE_DETAILS_REGISTERED_COMPONENTS()}
        tooltip={trans.DEVICES__DEVICE_DETAILS_REGISTERED_COMPONENTS_TOOLTIP()}
      >
        <PackagesTable packages={device.registeredComponents} />
      </Section>
      <Section
        header={trans.DEVICES__DEVICE_DETAILS_PLATFORM_COMPONENTS()}
        tooltip={trans.DEVICES__DEVICE_DETAILS_PLATFORM_COMPONENTS_TOOLTIP()}
      >
        <PackagesTable packages={device.platformComponents} />
      </Section>
    </Fragment>
  );
}

function renderCurrentUpdateJobContent(
  device,
  fetchCurrentUpdateJob,
  updateJobError,
  updateJobLoading,
  deviceUpdateJobRefreshDate
) {
  const DEVICE_CURRENT_UPDATE_JOB = [
    {
      name: trans.DEVICES__DEVICE_DETAILS_CURRENT_UPDATE_JOB_NAME(),
      dataTestId: "device-current-update-job-name",
      valueFn: fromDeviceUpdateJobProperty(
        "currentUpdateJob.name",
        device?.currentUpdateJob?.updateJobId
      ),
    },
    {
      name: trans.DEVICES__DEVICE_DETAILS_CURRENT_UPDATE_JOB_LAST_CONTACT_DATE(),
      dataTestId: "device-current-update-job-last-contact-date",
      valueFn: fromDeviceDateProperty("currentUpdateJob.lastContactDate"),
    },
    {
      name: trans.DEVICES__DEVICE_DETAILS_CURRENT_UPDATE_JOB_STATUS(),
      dataTestId: "device-current-update-job-status",
      valueFn: fromDeviceUpdateJobProperty("currentUpdateJob.status"),
    },
  ];

  if (updateJobError) {
    return <Info type="error">{updateJobError}</Info>;
  }

  return (
    <DevicePropertiesSection
      device={device}
      header={
        <Fragment>
          {trans.DEVICES__DEVICE_DETAILS_SOFTWARE_UPDATE_JOB_HEADER()}
          <div className={styles.headerContainer}>
            <Button
              onClick={fetchCurrentUpdateJob}
              dataTestId="device-current-update-job-refresh"
              disabled={updateJobLoading}
              processing={updateJobLoading}
            >
              {trans.REFRESH()}
            </Button>
            <p className={styles.textContent}>
              ({trans.DEVICES__DEVICE_DETAILS_CURRENT_UPDATE_JOB_CHECKED_ON()}{" "}
              {formatDateTime(deviceUpdateJobRefreshDate)})
            </p>
          </div>
        </Fragment>
      }
      properties={DEVICE_CURRENT_UPDATE_JOB}
      isSingleColumn={true}
    />
  );
}

export function SoftwareTab({
  device,
  activeSubTabId,
  fetchCurrentUpdateJob,
  updateJobError,
  updateJobLoading,
  deviceUpdateJobRefreshDate,
}) {
  return (
    <Fragment>
      <Section>
        <SubTabs>
          {SOFTWARE_SUB_TAB_IDS.map((subTabId) => {
            return (
              <SubTab
                key={subTabId}
                to={`/devices/inventory/${device.id}/details/${TAB_ID_SOFTWARE}/${subTabId}`}
                text={
                  subTabId === INSTALLED_PACKAGES_SUBTAB_ID
                    ? trans.DEVICES__DEVICE_DETAILS_SOFTWARE_SUBTAB_INSTALLED()
                    : trans.DEVICES__DEVICE_DETAILS_SOFTWARE_SUBTAB_UPDATE()
                }
                dataTestId={`software-subtab-${subTabId}`}
                active={activeSubTabId === subTabId}
                iconSize="normal"
                iconColor="error"
              />
            );
          })}
        </SubTabs>
      </Section>
      <TabContent>
        {activeSubTabId === UPDATE_SUBTAB_ID
          ? renderCurrentUpdateJobContent(
              device,
              fetchCurrentUpdateJob,
              updateJobError,
              updateJobLoading,
              deviceUpdateJobRefreshDate
            )
          : renderInstalledTabContent(device)}
      </TabContent>
    </Fragment>
  );
}

SoftwareTab.propTypes = {
  device: DevicePropType,
  activeSubTabId: PropTypes.string,
  fetchCurrentUpdateJob: PropTypes.func.isRequired,
  updateJobError: PropTypes.bool.isRequired,
  updateJobLoading: PropTypes.bool.isRequired,
  deviceUpdateJobRefreshDate: PropTypes.instanceOf(Date),
};
