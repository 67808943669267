// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { api } from "containers/Request";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";
import { createBody } from "utils/jsonApi";

export const withToggleModel = () => (ComposedComponent) => {
  @withRouter
  @connect(null, (dispatch) => ({
    toggleModel: (id, enable) =>
      dispatch(
        api.updateDeviceModel.action({
          params: { id },
          options: {
            body: createBody({
              type: "DeviceModel",
              id: id,
              is_enabled: enable,
            }),
          },
        })
      ),
  }))
  class WithToggleModel extends Component {
    static propTypes = {
      // from @withRouter
      location: PropTypes.object.isRequired,
      navigate: PropTypes.func.isRequired,

      // from @connect
      toggleModel: PropTypes.func.isRequired,
    };

    state = {
      toggleModelInProgress: false,
    };

    toggleModel = async (modelId, enable) => {
      const { toggleModel, navigate, location } = this.props;

      this.setState({ toggleModelInProgress: true });

      await toggleModel(modelId, enable);

      this.setState({ toggleModelInProgress: false });

      navigate(
        {
          ...location,
        },
        {
          state: {
            message: trans.DEVICES__DEVICE_MODEL_ENABLED_OR_DISABLED({
              status: enable
                ? trans.DEVICES__DEVICE_MODEL_ENABLED_STATUS()
                : trans.DEVICES__DEVICE_MODEL_DISABLED_STATUS(),
            }),
          },
        }
      );
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          toggleModel={this.toggleModel}
          toggleModelInProgress={this.state.toggleModelInProgress}
        />
      );
    }
  }
  return WithToggleModel;
};
