// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";

import isEmpty from "lodash-es/isEmpty";
import semver from "semver";

import { GrabsonIcon } from "components/icons";
import { TableRowColumn, TableRow } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { trans } from "src/translations";

import { packagePropTypes } from "./propTypes";
import styles from "./SoftwarePackage.scss";
import { SoftwarePackageVersionRow } from "./SoftwarePackageVersionRow";

export class SoftwarePackageRow extends Component {
  static propTypes = {
    softwarePackage: packagePropTypes,
  };

  state = {
    isExpanded: false,
  };

  onToggle = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  getLatestSoftwarePackageVersionLink = () => {
    const {
      softwarePackage: { versions, id },
    } = this.props;
    if (versions.length > 0) {
      const latest = versions
        .slice()
        .sort(({ version }, { version: version2 }) =>
          semver.rcompare(version, version2)
        )[0];
      return `/updates/packages/${id}/version/${latest.id}`;
    }
    return `/updates/packages/${id}`;
  };

  render() {
    const { isExpanded } = this.state;
    const softwarePackage = this.props.softwarePackage;
    return (
      <Fragment>
        <TableRow>
          <TableRowColumn
            className={styles.package}
            onClick={this.onToggle}
            dataTestId="name-column"
          >
            <GrabsonIcon
              name={isExpanded ? "arrow-up" : "arrow-down"}
              size="sm"
              font="micro"
              className={styles.toggleIcon}
            />
            <div>
              <Link to={this.getLatestSoftwarePackageVersionLink()}>
                <h4 className={styles.name}>{softwarePackage.name}</h4>
              </Link>
            </div>
          </TableRowColumn>
          <TableRowColumn dataTestId="version-column">
            {softwarePackage.versions.length}
          </TableRowColumn>
          <TableRowColumn dataTestId="action-column">
            <ActionMenu>
              <MenuItem
                to={this.getLatestSoftwarePackageVersionLink()}
                dataTestId="details-item"
              >
                {trans.UPDATES__SOFTWARE_PACKAGES_PACKAGE_DETAILS_ACTION()}
              </MenuItem>
            </ActionMenu>
          </TableRowColumn>
        </TableRow>
        {isExpanded &&
          !isEmpty(softwarePackage.versions) &&
          softwarePackage.versions
            .slice()
            .sort(({ version }, { version: version2 }) =>
              semver.rcompare(version, version2)
            )
            .map((ver) => (
              <SoftwarePackageVersionRow
                key={ver.id}
                version={ver}
                softwarePackage={softwarePackage}
              />
            ))}
      </Fragment>
    );
  }
}
