// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";

import { withActiveOrganization } from "containers/Auth/decorators";
import { checkAccess, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

import { AppDetails } from "./AppDetails";

@withActiveOrganization
@connect(null, (dispatch) => ({
  getApp: (appId) => {
    return dispatch(
      apiThatThrows.getApplication.action({
        params: { id: appId },
        // todo: YGG-4047 remove 'nocache' when YGG-4046 ready
        // The 'nocache' query param forces backend to ignore a cache.
        // This is a temporary & hacky solution needed for the 1.2.0 release.
        // It has to be removed in the next release when caching in backend is fixed.
        queryParams: { nocache: uuid() },
      })
    );
  },
  getQASummariesPaginated: (appId) => {
    return dispatch(
      apiThatThrows.getQASummariesPaginated.action({
        queryParams: {
          application_id: appId,
          limit: "nolimit",
          include:
            "active_moderation_result,latest_moderation_result,latest_certification_result",
        },
      })
    );
  },
}))
export class AppDetailsData extends Component {
  static propTypes = {
    // from @ExtendedRoute

    params: PropTypes.shape({
      id: PropTypes.string,
    }),

    // from @connect
    getApp: PropTypes.func,
    getQASummariesPaginated: PropTypes.func,

    // from @withActiveOrganization
    activeOrganization: PropTypes.object,
  };

  state = {
    application: null,
    qaSummaries: null,
    loading: true,
    error: null,
  };

  /**
   * Only users with moderation privileges can request moderation data like
   * QASummaries, other will get 403 from backend. To avoid rewriting AppDetails
   * and AppDetailsData, additional check is performed here (AppDetails is doing
   * it's check of privileges too late, request is already made)
   */
  canRequestQASummaries() {
    const { activeOrganization } = this.props;
    return checkAccess({ activeOrganization }, [
      ROLES.moderator.moderator,
      ROLES.moderator.contentManager,
    ]);
  }

  async componentDidMount() {
    await this.refreshData();
  }

  async componentDidUpdate(prevProps) {
    const activeOrganizationId = get(this.props, "activeOrganization.id");
    const prevActiveOrganizationId = get(prevProps, "activeOrganization.id");
    const organizationChanged =
      activeOrganizationId !== prevActiveOrganizationId;

    const appChanged = this.getAppId() !== get(prevProps, "params.id");

    if (organizationChanged || appChanged) {
      await this.refreshData();
    }
  }

  refreshData = async () => {
    try {
      this.setState({ loading: true });

      const [application, qaSummaries] = await Promise.all([
        this.getApp(),
        ...(this.canRequestQASummaries() ? [this.getQASummaries()] : []),
      ]);

      this.setState({
        application,
        qaSummaries,
        loading: false,
        error: null,
      });
      return { application, qaSummaries };
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
      return null;
    }
  };

  refreshQASummaries = async () => {
    try {
      this.setState({ loading: true });
      const qaSummaries = await this.getQASummaries();
      this.setState({
        loading: false,
        qaSummaries,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
    }
  };

  async getApp() {
    const { getApp } = this.props;
    const { results } = await getApp(this.getAppId());
    return results;
  }

  async getQASummaries() {
    const { results } = await this.props.getQASummariesPaginated(
      this.getAppId()
    );

    if (!results || results.length !== 1) {
      throw {
        message: trans.APP_DETAILS_VERSION__UNEXPECTED_ERROR(),
      };
    }

    const qaSummaries = results[0];

    if (!qaSummaries.latest_moderation_result) {
      throw {
        message: trans.APP_DETAILS_VERSION__UNEXPECTED_ERROR(),
      };
    }

    return qaSummaries;
  }

  getAppId() {
    return this.props.params.id;
  }

  render() {
    const { application, ...rest } = this.state;
    return (
      <AppDetails
        application={application}
        refreshData={this.refreshData}
        refreshQASummaries={this.refreshQASummaries}
        activeOrganization={this.props.activeOrganization}
        {...rest}
      />
    );
  }
}
