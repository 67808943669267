// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { CheckboxField } from "components/form/fields";
import { TableRowColumn, TableRow } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { BUTTONS, FIELDS, SECTIONS } from "pages/Snap/shared/constants";
import styles from "pages/Snap/shared/Translations.scss";
import * as iconStyles from "pages/Snap/SnapEdit/components/SnapIconPicker/icons.scss";
import { isValidHomeSection } from "pages/Snap/SnapEdit/validation";
import { classes } from "utils/classes";

export const SectionHome = ({ fieldName, index, item, fields, openModal }) => {
  const homeEnabled = fields[index][FIELDS.HOME_VISIBLE.name];
  const rowClass = isValidHomeSection(item, fields.length)
    ? styles.rowSuccess
    : styles.rowError;

  return (
    <TableRow
      className={classes(rowClass, styles.clickable)}
      dataTestId="home-section-table-row"
    >
      <TableRowColumn />
      <TableRowColumn onClick={openModal}>
        <div className={iconStyles[item.icon]} />
      </TableRowColumn>
      <TableRowColumn
        columnClass={styles.titleColumn}
        onClick={() => openModal(true)}
      >
        {item.name}
      </TableRowColumn>
      <TableRowColumn columnClass={styles.contentColumn}>
        <Link href={item.url} target="_blank">
          {item.url}
        </Link>
      </TableRowColumn>
      <TableRowColumn onClick={openModal}>
        {item.url
          ? SECTIONS.TRANSLATIONS.sectionFeed
          : SECTIONS.TRANSLATIONS.sectionGenerated}
      </TableRowColumn>
      <TableRowColumn />
      <TableRowColumn>
        <ActionMenu>
          <MenuItem onClick={openModal} dataTestId="edit-menu-item">
            {BUTTONS.DROPLIST.edit}
          </MenuItem>
          <MenuItem dataTestId="delete-menu-item">
            <CheckboxField
              name={`${fieldName}.${FIELDS.HOME_VISIBLE.name}`}
              checked={homeEnabled}
              label={BUTTONS.DROPLIST.delete}
              className={styles.checkboxAsOption}
            />
          </MenuItem>
        </ActionMenu>
      </TableRowColumn>
    </TableRow>
  );
};

SectionHome.propTypes = {
  fieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
};
