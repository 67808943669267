// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { pipeValidators, isRequiredWhen } from "utils/validation";

import { Contracts } from "../../../components/index";
import { DISTRIBUTION_TYPES } from "../../../constants";

export default {
  components: [
    {
      component: Contracts,
      fields: [
        "metadata.agreement_version",
        "metadata.terms_accepted",
        "metadata.accepted_criteria",
      ],
      validate: pipeValidators(
        isRequiredWhen(
          "metadata.terms_accepted",
          (values) =>
            values.metadata.distribute_in_tvstore !== DISTRIBUTION_TYPES.EXAMINE
        ),
        isRequiredWhen(
          "metadata.accepted_criteria",
          (values) =>
            values.metadata.distribute_in_tvstore ===
            DISTRIBUTION_TYPES.VEWD_CATALOGUE
        )
      ),
      props: ({ formikProps }) => {
        return {
          distributeInTvStoreType:
            formikProps.values.metadata.distribute_in_tvstore,
          ...formikProps,
          revenueOption: formikProps.values.metadata.produce_revenue,
        };
      },
    },
  ],
};
