// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Modal } from "components/layout";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";
import { withMutation, mutationResultType } from "utils/graphql";

import { actionList } from "../UpdateJobDetails/constants";
import { changeUpdateJobStatusMutation } from "./gql/changeUpdateJobStatusMutation";

@withMutation({
  name: "changeUpdateJobStatus",
  mutation: changeUpdateJobStatusMutation,
})
export class UpdateJobStatusModal extends PureComponent {
  static propTypes = {
    updateJobId: PropTypes.string,
    action: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,

    // from @withMutation
    changeUpdateJobStatusMutation: PropTypes.func,
    changeUpdateJobStatusMutationStatus: mutationResultType,
  };

  onSuccess = async () => {
    const { onSuccess, changeUpdateJobStatusMutation, updateJobId, action } =
      this.props;

    // if there is an error, it will throw an exception
    // and onClose below will not execute
    await changeUpdateJobStatusMutation({
      variables: {
        input: {
          updateJobId,
          status: action,
        },
      },
    });

    onSuccess();
  };

  render() {
    const {
      isOpen,
      onClose,
      action,
      changeUpdateJobStatusMutationStatus: { loading, error },
    } = this.props;

    const actionMapped = actionList.find(({ key }) => key === action);
    if (!actionMapped) {
      return null;
    }

    const actionLabel = actionMapped.label.toUpperCase();

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={actionLabel}
        dataTestId="change-job-status-modal"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={onClose}
              disabled={loading}
              dataTestId="change-job-status-cancel-button"
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.onSuccess}
              processing={loading}
              disabled={loading}
              dataTestId="change-job-status-ok-button"
            >
              {trans.UPDATES__UPDATE_MODAL__ACCEPT_BUTTON({
                action: actionMapped.label,
              })}
            </Button>
          </ButtonsWrapper>
        }
      >
        <p>
          {trans.UPDATES__UPDATE_MODAL__DESCRIPTION({
            action: actionLabel,
          })}
        </p>

        {error && <Info type="error">{getGqlErrorMessage(error)}</Info>}
      </Modal>
    );
  }
}
