// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useFormikContext } from "formik";

import { CheckboxField } from "components/form/fields/CheckboxField";
import { Section } from "components/layout";
import { Header } from "components/typography";
import { APP_INTRO_FORM, APP_TYPES } from "pages/Applications/constants";
import { HtmlTranslation, trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./CreateApp.scss";

export function IsTestSection() {
  const {
    values: { type },
  } = useFormikContext();

  return (
    <Section>
      <Header
        uppercase={true}
        bold={true}
        color="secondary"
        className={classes(styles.sectionHeader, {
          [styles.disabled]: type !== APP_TYPES.HTML,
        })}
      >
        {trans.APP_SUBMIT__ADDITIONAL_OPTIONS()}
      </Header>
      <CheckboxField
        {...APP_INTRO_FORM.IS_TEST}
        disabled={type !== APP_TYPES.HTML}
        className={styles.checkboxInput}
        label={
          <>
            <p className={styles.checkboxTitle}>
              {trans.APP_SUBMIT__THIS_IS_TEST_ONLY_APP()}
            </p>
            <HtmlTranslation
              className={styles.checkboxDescription}
              component="p"
              transFunc={trans.APP_SUBMIT__THIS_IS_TEST_ONLY_APP_DESC}
            />
          </>
        }
      />
    </Section>
  );
}
