// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Modal } from "components/layout";
import { BUTTONS, SECTIONS } from "pages/Snap/shared/constants";

import styles from "./Modal.scss";

export const DiscardModal = ({
  isDiscarding,
  discardRevision,
  onClose,
  isDiscardModalOpen,
}) => {
  return (
    <Modal
      className={styles.snapModal}
      onClose={onClose}
      isOpen={isDiscardModalOpen}
      title={SECTIONS.FORM.discardDraftTitle}
      actions={
        <ButtonsWrapper>
          <Button
            processing={isDiscarding}
            disabled={isDiscarding}
            type="normal"
            onClick={onClose}
          >
            {BUTTONS.MODAL.keepDraft}
          </Button>
          <Button
            type="green"
            processing={isDiscarding}
            disabled={isDiscarding}
            onClick={discardRevision}
          >
            {BUTTONS.MODAL.discardDraft}
          </Button>
        </ButtonsWrapper>
      }
    >
      <p className={styles.textWrapper}>{SECTIONS.FORM.discardDraftMsg}</p>
    </Modal>
  );
};

DiscardModal.propTypes = {
  discardRevision: PropTypes.func.isRequired,
  isDiscarding: PropTypes.bool.isRequired,
  isDiscardModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
