// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { api, apiThatThrows } from "containers/Request";
import { withCountries } from "utils/continents";
import { withRouter } from "utils/decorators/withRouter";
import { camelCaseKeys } from "utils/object";

import { IsVersionUpdatedProvider } from "./_shared/IsVersionUpdated";
import { withVersions } from "./_shared/withVersionsDecorator";
import { VersionsTab } from "./VersionsTab";

@withRouter
@withVersions
@connect(null, (dispatch) => ({
  getCategoriesPaginated: () => {
    return dispatch(
      api.getApplicationCategoriesPaginated.action({
        queryParams: { limit: "nolimit" },
      })
    );
  },
  getLanguages: () => {
    return dispatch(api.getLanguages.action());
  },
  getOrganization: (organizationId) => {
    return dispatch(
      api.getOrganizationPublicInfo.action({
        params: { organizationId },
      })
    );
  },
  upgradeOrganizationPlan: (organizationId, organizationPlan) => {
    return dispatch(
      apiThatThrows.upgradeOrganizationPlan.action({
        params: { organizationId, organizationPlan },
      })
    );
  },
}))
@withCountries
export class VersionsTabData extends Component {
  static propTypes = {
    application: PropTypes.object.isRequired,

    // from @connect
    getCategoriesPaginated: PropTypes.func,
    getLanguages: PropTypes.func,
    getOrganization: PropTypes.func,
    upgradeOrganizationPlan: PropTypes.func,

    // from @withVersions
    refreshVersions: PropTypes.func.isRequired,
    versionsLoading: PropTypes.bool.isRequired,
    versionsError: PropTypes.string,
    activeVersion: PropTypes.object,
    latestVersion: PropTypes.object,

    // from @withCountries
    allCountries: PropTypes.array.isRequired,
    countriesLoading: PropTypes.bool.isRequired,
    countriesError: PropTypes.object,
  };

  state = {
    loading: true,
    error: "",
    categories: [],
    languages: [],
    appOwner: null,
  };

  async componentDidMount() {
    const { refreshVersions, application } = this.props;

    try {
      this.setState({ loading: true });
      const [categories, languages] = await Promise.all([
        this.getCategories(),
        this.getLanguages(),
      ]);

      await refreshVersions(application);

      const appOwner = await this.getAppOwner();

      this.setState({
        loading: false,
        categories,
        languages: languages.results,
        appOwner,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: error.toString(),
      });
    }
  }

  async getCategories() {
    const { error, result } = await this.props.getCategoriesPaginated();
    if (error) {
      throw new Error(error);
    }
    return result.results;
  }

  async getLanguages() {
    const { error, result } = await this.props.getLanguages();
    if (error) {
      throw new Error(error);
    }
    return result;
  }

  async getAppOwner() {
    const { getOrganization, application } = this.props;

    const { error, result } = await getOrganization(
      application.owner_public_id
    );
    if (error) {
      throw new Error(error);
    }

    return camelCaseKeys(result.results);
  }
  async upgradeOrganizationPlan(id, plan) {
    const { upgradeOrganizationPlan } = this.props;
    this.setState({ loading: true, error: "" });
    try {
      await upgradeOrganizationPlan(id, plan);
      const appOwner = await this.getAppOwner();
      this.setState({
        appOwner,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }
  render() {
    const {
      application,
      allCountries,
      countriesLoading,
      countriesError,
      versionsLoading,
      versionsError,
      activeVersion,
      latestVersion,
    } = this.props;

    return (
      <IsVersionUpdatedProvider
        activeVersion={activeVersion}
        latestVersion={latestVersion}
      >
        <VersionsTab
          activeVersion={activeVersion}
          latestVersion={latestVersion}
          categories={this.state.categories}
          languages={this.state.languages}
          loading={this.state.loading || countriesLoading || versionsLoading}
          error={this.state.error || countriesError?.message || versionsError}
          allCountries={allCountries}
          application={application}
          appOwner={this.state.appOwner}
          upgradeOrganizationPlan={async (id, plan) =>
            this.upgradeOrganizationPlan(id, plan)
          }
        />
      </IsVersionUpdatedProvider>
    );
  }
}
