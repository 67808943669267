// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Tooltip } from "components/popups";

import styles from "./OrganizationsTable.scss";

const NOT_SHOWN_TYPES = ["organization"];

const isTypeVisible = (type) =>
  !NOT_SHOWN_TYPES.includes(type.id.toLowerCase());

const getTypes = (types) =>
  types.reduce((acc, type) => {
    if (isTypeVisible(type)) {
      acc.push(type.name);
    }
    return acc;
  }, []);

const renderTypeTooltip = (types) => (
  <ul>
    {getTypes(types).map((type) => (
      <li key={type}>{type},</li>
    ))}
  </ul>
);

export const TypeColumn = ({ types }) => (
  <Tooltip
    alignment="left"
    content={renderTypeTooltip(types)}
    className={styles.typeColumnWrapper}
  >
    <div className={styles.typeColumnContent}>{getTypes(types).join(", ")}</div>
  </Tooltip>
);

TypeColumn.propTypes = {
  types: PropTypes.array.isRequired,
};
