// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";
import { getGqlErrorMessage, parseGqlError } from "utils/errors";

const FIELDS_WITH_LETTERS_NUMBERS_UNDERSCORE_DASH_VALIDATION = [
  "manager_attributes",
];

const FIELDS_WITH_POSITIVE_NUMBERS_VALIDATION = ["erp_numbers"];

const FIELDS_WITH_SEMVER_VALUE_VALIDATION = [
  "installed_packages",
  "registered_components",
];

const FIELDS_WITH_SAME_CHARACTERS_AS_SEMVER_PLUS_UNDERSCORE_VALIDATION = [
  "platform_components",
  "system_software_version",
];

const FIELDS_WITH_DATE_VALIDATION = ["last_activity_date", "register_date"];

const parseError = (error) => {
  const isFieldsError = (fields) =>
    Boolean(parseGqlError(error, { fieldPattern: fields.join("|") }));
  if (isFieldsError(FIELDS_WITH_SEMVER_VALUE_VALIDATION)) {
    return (
      <>
        {trans.DEVICES__DEVICE_INVENTORY_INCORRECT_FILTER()}
        <br />
        {trans.DEVICES__DEVICE_INVENTORY_PACKAGE_NAME_ALLOWED_CHARACTERS_LIST()}
        <br />
        {trans.DEVICES__DEVICE_INVENTORY_PACKAGE_VERSION_ALLOWED_CHARACTERS_LIST()}
      </>
    );
  }
  if (isFieldsError(FIELDS_WITH_LETTERS_NUMBERS_UNDERSCORE_DASH_VALIDATION)) {
    return (
      <>
        {trans.DEVICES__DEVICE_INVENTORY_FIELDS_WITH_LETTERS_NUMBERS_UNDERSCORE_DASH_VALIDATION_FILTER_QUERY_ERROR()}
        <br />
        {trans.DEVICES__DEVICE_INVENTORY_ALLOWED_MANAGER_ATTRIBUTES_CHARACTERS_LIST()}
        .
      </>
    );
  }
  if (
    isFieldsError(
      FIELDS_WITH_SAME_CHARACTERS_AS_SEMVER_PLUS_UNDERSCORE_VALIDATION
    )
  ) {
    return (
      <>
        {trans.DEVICES__DEVICE_INVENTORY_INCORRECT_FILTER()}
        <br />
        {trans.DEVICES__DEVICE_INVENTORY_NO_SEMVER_PACKAGE_VERSION_ALLOWED_CHARACTERS_LIST()}
      </>
    );
  }
  if (isFieldsError(FIELDS_WITH_POSITIVE_NUMBERS_VALIDATION)) {
    return (
      <>
        {trans.DEVICES__DEVICE_INVENTORY_INCORRECT_FILTER()}
        <br />
        {trans.DEVICES__DEVICE_INVENTORY_ONLY_POSITIVE_NUMBERS_ALLOWED_VALIDATION_ERROR()}
      </>
    );
  }
  if (isFieldsError(FIELDS_WITH_DATE_VALIDATION)) {
    return trans.DEVICES__DEVICE_INVENTORY_DATES_VALIDATION_ERROR();
  }
  return getGqlErrorMessage(error);
};

export const getDeviceListErrorMessage = (error) => error && parseError(error);
