// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./SearchBar.scss";

const KEY_CODE_ENTER = 13;

/**
 * Pure searchbar without suggestions
 */
export class SearchBar extends Component {
  static propTypes = {
    /**
     * Called everytime when value in search bar changes.
     *
     * Type: `(newValue: string) => void`
     */
    onChange: PropTypes.func,
    /**
     * Initial value of search bar
     */
    value: PropTypes.string,
    /**
     * Called when key is pressed on component.
     *
     * Type: `() => void`
     */
    onKeyDown: PropTypes.func,
    /**
     * Called when enter key is pressed.
     *
     * Type: `(newValue: string) => void`
     */
    onSearch: PropTypes.func,
    /**
     * Placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Called when SearchBar looses or gain focus.
     *
     * Type: `(isFocused: boolean) => void`
     */
    onFocusChange: PropTypes.func,
    look: PropTypes.oneOf(["for-gray-bg", "for-white-bg"]),
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    actionHint: PropTypes.string,
  };

  static defaultProps = {
    look: "for-gray-bg",
    dataTestId: "search-bar",
  };

  state = {
    value: this.props.value || "",
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ value: value });
    }
  }

  onInputChange = (e) => {
    const newValue = e.target.value;

    this.setState({ value: newValue });

    const { onChange } = this.props;
    if (onChange) {
      onChange(newValue);
    }
  };

  onKeyDown = (e) => {
    const { onKeyDown } = this.props;

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  onKeyUp = (e) => {
    const { onSearch } = this.props;

    if (onSearch && e.keyCode === KEY_CODE_ENTER) {
      onSearch(e.target.value);
    }
  };

  onFocus = () => {
    this.changeFocus(true);
  };

  onBlur = () => {
    this.changeFocus(false);
  };

  changeFocus(value) {
    const { onFocusChange } = this.props;

    if (onFocusChange) {
      onFocusChange(value);
    }
  }

  render() {
    const { value } = this.state;
    const { placeholder, look, className, dataTestId, actionHint } = this.props;

    return (
      <div
        data-test-id={dataTestId}
        className={classes(styles.SearchBar, className, styles[look])}
      >
        <input
          data-test-id={`${dataTestId}-input`}
          onChange={this.onInputChange}
          value={value || ""}
          className={styles.SearchBarInput}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
          placeholder={placeholder}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          type="search"
        />
        {actionHint && <div className={styles.SearchHint}>{actionHint}</div>}
      </div>
    );
  }
}

export default SearchBar;
