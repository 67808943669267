// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useContext } from "react";

import PropTypes from "prop-types";

import { Uploader } from "components/form/Uploader";
import { trans } from "src/translations";
import { cropImage, validateImage } from "utils/image";

import { FIELDS } from "../constants";
import { ModeContext } from "../context";

const BACKGROUND_MIN_WIDTH = 1600;
const BACKGROUND_MIN_HEIGHT = 900;
const BACKGROUND_MAX_WIDTH = 1920;
const BACKGROUND_MAX_HEIGHT = 1080;

export const FullPageLandscapeField = ({ name }) => {
  const { isReadonly } = useContext(ModeContext);

  const validate = (blobUrl) => {
    return validateImage(blobUrl, {
      minWidth: BACKGROUND_MIN_WIDTH,
      minHeight: BACKGROUND_MIN_HEIGHT,
      maxWidth: BACKGROUND_MAX_WIDTH,
      maxHeight: BACKGROUND_MAX_HEIGHT,
      maxSize: 9999999,
      validateAspectRatio: true,
    });
  };

  return (
    <Uploader
      {...FIELDS.BACKGROUND}
      name={`${name}.${FIELDS.BACKGROUND.name}`}
      info={trans.PROMOTION_DETAILS__FIELD_FULL_PAGE_LANDSCAPE_INFO({
        format: "jpeg",
        minWidth: BACKGROUND_MIN_WIDTH,
        minHeight: BACKGROUND_MIN_HEIGHT,
        maxWidth: BACKGROUND_MAX_WIDTH,
        maxHeight: BACKGROUND_MAX_HEIGHT,
        aspectRatio: "16:9",
      })}
      validate={validate}
      disabled={isReadonly}
      preprocess={(blobUrl, type) =>
        cropImage(blobUrl, type, BACKGROUND_MIN_WIDTH, BACKGROUND_MIN_HEIGHT)
      }
    />
  );
};

FullPageLandscapeField.propTypes = {
  name: PropTypes.string.isRequired,
};
