// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { trans } from "src/translations";

import styles from "./status.scss";

const isUploading = (field) => {
  if (Array.isArray(field)) {
    return field.some((file) => file.status.progress && !file.url);
  }
  return field.status.progress && !field.url;
};

const isRemoving = (field) => {
  if (Array.isArray(field)) {
    return field.some((file) => file.status.progress && file.url);
  }
  return field.status.progress && field.url;
};

export const Status = ({ field }) => {
  return (
    <div className={styles.status}>
      {isUploading(field) ? (
        <Loader
          message={trans.UPLOADING()}
          position="left"
          size="small"
          inline={true}
        />
      ) : null}
      {isRemoving(field) ? (
        <Loader
          message={trans.REMOVING()}
          position="left"
          size="small"
          inline={true}
        />
      ) : null}
    </div>
  );
};

Status.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      loaded: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      status: PropTypes.object.isRequired,
      url: PropTypes.string.isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        loaded: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        status: PropTypes.object.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  ]).isRequired,
};
