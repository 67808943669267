// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { Details } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withRouter } from "utils/decorators";

const DEFAULT_ORGANIZATION_DATA = {
  id: "",
  name: "",
  description: "",
  supportEmail: "",
  phone: "",
  logo: "",
};

@withRouter
@withActiveOrganization
@restrictedArea((props) => {
  const allowed = [];

  if (props.forAdmin) {
    allowed.push(ROLES.administrator.organizationAdmin);
  } else {
    allowed.push(ROLES.organization.member);
    allowed.push(ROLES.organization.owner);
    allowed.push(ROLES.administrator.organizationAdmin);
  }

  return {
    allowed,
  };
})
@connect(null, (dispatch, ownProps) => ({
  getOrganization: async (id) => {
    let response;

    if (ownProps.forAdmin) {
      response = await await dispatch(
        apiThatThrows.getOrganization.action({
          params: { organizationId: id },
        })
      );
    } else {
      response = await await dispatch(
        apiThatThrows.getMyOrganization.action({
          params: { organizationId: id },
        })
      );
    }

    const { results } = response;
    return results;
  },
}))
export class OrganizationDetailsData extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    forAdmin: PropTypes.bool,

    // from @connect
    getOrganization: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,
  };

  static defaultProps = {
    forAdmin: false,
  };

  state = {
    loading: true,
    error: "",
    organizationData: DEFAULT_ORGANIZATION_DATA,
  };

  componentDidMount() {
    this.refreshData();
  }

  async componentDidUpdate(prevProps) {
    const { activeOrganization, location } = this.props;

    const orgChanged =
      activeOrganization.id !== prevProps.activeOrganization.id;
    const locationChanged = location !== prevProps.location;
    if (orgChanged || locationChanged) {
      await this.refreshData();
    }
  }

  refreshData = async () => {
    const { getOrganization } = this.props;

    this.setState({ loading: true, error: "" });

    try {
      const id = this.getOrganizationId();
      const organization = await getOrganization(id);

      this.setState({
        organizationData: {
          id,
          name: organization.name,
          description: organization.description,
          email: organization.support_email,
          phone: organization.phone,
          logo: organization.logo,
        },
      });
    } catch (err) {
      this.setState({
        error: err.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  getOrganizationId() {
    const { activeOrganization, params } = this.props;
    return params.id || activeOrganization.id;
  }

  render() {
    const { loading, error, organizationData } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error}</Info>;
    }

    return <Details data={organizationData} />;
  }
}
