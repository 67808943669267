// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { GrabsonIcon } from "components/icons";
import { Modal, Section } from "components/layout";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";

import { DeviceAttributePropType } from "../propTypes";
import styles from "./AttributeModal.scss";

const DEFAULT_STATE = {
  error: null,
  submitting: false,
};

export class AttributeRemoveModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setAttributeRequest: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    attribute: DeviceAttributePropType,
  };

  state = { ...DEFAULT_STATE };

  getAttributeName = () => get(this.props.attribute, "key", "");

  getAttributeText = () => {
    const value = get(this.props.attribute, "value", "");
    return `/manager_attribute:${this.getAttributeName()}=${value}`;
  };

  handleClose = () => {
    this.setState({ ...DEFAULT_STATE });
    this.props.onClose();
  };

  handleSubmit = async () => {
    const { setAttributeRequest, setSuccessMessage } = this.props;
    const name = this.getAttributeName();
    this.setState({
      submitting: true,
    });
    try {
      await setAttributeRequest(name, undefined);
    } catch (e) {
      this.setState((prevState) => {
        if (!prevState.submitting) {
          // The modal has been closed.
          return prevState;
        }
        return {
          error: getGqlErrorMessage(e),
          submitting: false,
        };
      });
      return;
    }
    this.setState({
      submitting: false,
    });
    setSuccessMessage(
      trans.DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_SUCCESS_REMOVE()
    );
  };

  render() {
    const { isOpen } = this.props;
    const { error, submitting } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onClose={this.handleClose}
        size="big"
        title={
          <Fragment>
            <GrabsonIcon name="cross-circle" size="xl" />{" "}
            {trans.DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_MODAL_HEADER()}
          </Fragment>
        }
        dataTestId="remove-attribute-modal"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={this.handleClose}
              dataTestId="remove-attribute-cancel-button"
              disabled={submitting}
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.handleSubmit}
              dataTestId="remove-attribute-submit-button"
              disabled={submitting}
              processing={submitting}
            >
              {trans.DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_MODAL_BUTTON()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <Section className={styles.modalDescription}>
          {trans.DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_MODAL_DESCRIPTION_PT_1()}
          <p className={styles.attributeToRemoveText}>
            {this.getAttributeText()}
          </p>
          {trans.DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_MODAL_DESCRIPTION_PT_2()}
        </Section>

        {error && <Info type="error">{error}</Info>}
      </Modal>
    );
  }
}
