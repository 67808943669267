// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Header } from "components/typography";

import styles from "./ContentBox.scss";

export function ContentBox({ text, title, children, uppercaseTitle = true }) {
  return (
    <aside className={styles.contentBox}>
      <div className={styles.additionalInformation}>
        <Header
          uppercase={uppercaseTitle}
          bold={true}
          color="secondary"
          className={styles.informationHeader}
        >
          {title}
        </Header>
        {text || children}
      </div>
    </aside>
  );
}

ContentBox.propTypes = {
  title: PropTypes.string || PropTypes.node,
  text: PropTypes.node,
  children: PropTypes.node,
  uppercaseTitle: PropTypes.bool,
};
