// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Label } from "components/form";
import { PopupOnClick } from "components/popups";
import { classes } from "utils/classes";

import styles from "./icons.scss";

export const SNAP_ICONS = [
  "actors",
  "categories",
  "channels",
  "games",
  "genres",
  "kids",
  "live",
  "movies",
  "music",
  "news",
  "series",
  "videos",
];

const renderItems = (icon, index, value, changeValue) => {
  const activeClass = icon === value ? styles.currentIcon : styles.iconWrapper;
  return (
    <div key={`${icon}_${index}`} className={activeClass}>
      <div
        className={classes(
          icon === "" ? styles.empty : styles[icon],
          styles.iconButton
        )}
        onClick={() => {
          changeValue(icon);
        }}
        data-test-id={`${icon}-icon`}
      />
    </div>
  );
};

export const SnapIconPicker = ({
  field: { value, name },
  form: { setFieldValue },
}) => {
  const iconClass = value ? styles[value] : styles.videos;
  const changeValue = (icon) => setFieldValue(name, icon);
  return (
    <div className={styles.pickerWrapper}>
      <Label text="Icon">
        <PopupOnClick
          dataTestId="icon-popup-on-click"
          alignment="right"
          renderTrigger={() => (
            <div className={styles.iconPicker}>
              <div className={iconClass} />
            </div>
          )}
          renderPopup={({ closePopup }) => (
            <div className={styles.popUp} onClick={closePopup}>
              {SNAP_ICONS.map((icon, index) =>
                renderItems(icon, index, value, changeValue)
              )}
            </div>
          )}
        />
      </Label>
    </div>
  );
};

SnapIconPicker.propTypes = {
  // Formik props
  field: PropTypes.object,
  form: PropTypes.object,
};
