// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import {
  SearchableList,
  CheckAllButton,
  SelectedCountFooter,
} from "components/elements";
import { InfiniteData } from "containers/InfiniteData";
import { trans } from "src/translations";

import { ITEMS_PER_PAGE } from "./AddOrganizationModal";
import styles from "./OrganizationsSearchableList.scss";
import { OrganizationsSearchableListRow } from "./OrganizationsSearchableListRow";

export const OrganizationsSearchableList = ({
  getOrganizationsPaginated,
  isChecked,
  onCheck,
  onCheckAll,
  organizations,
}) => {
  return (
    <Fragment>
      <InfiniteData
        fetchData={getOrganizationsPaginated}
        itemsPerPage={ITEMS_PER_PAGE}
      >
        {({
          data,
          pending,
          error,
          allDataLoaded,
          handleScroll,
          handleSearchChange,
        }) => {
          const canCheckAll = allDataLoaded && !pending;
          return (
            <div>
              <SearchableList
                dataTestId="select-target-organization-list"
                list={data}
                classNameList={styles.list}
                onScroll={handleScroll}
                onSearchChange={handleSearchChange}
                withLoader={true}
                loading={pending}
                allDataLoaded={allDataLoaded}
                error={error && trans.DEFAULT_REQUEST_ERROR_MESSAGE()}
                placeholder={trans.SEARCH()}
                optionsListElement={(visibleItems) => (
                  <CheckAllButton
                    fieldName="organizations"
                    checked={visibleItems.every((item) => isChecked(item.id))}
                    onChange={(value) => onCheckAll(visibleItems, value)}
                    disabled={!canCheckAll || visibleItems.length === 0}
                  />
                )}
              >
                {(props) => (
                  <OrganizationsSearchableListRow
                    {...props}
                    isChecked={isChecked}
                    onCheck={onCheck}
                  />
                )}
              </SearchableList>
              <SelectedCountFooter
                selectedCountText={trans.PROMOTION_TYPE_EDIT__ORGANIZATIONS_MODAL_STATUS()}
                selectedCount={organizations.length}
                className={styles.statusContainer}
              />
            </div>
          );
        }}
      </InfiniteData>
    </Fragment>
  );
};

OrganizationsSearchableList.propTypes = {
  getOrganizationsPaginated: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCheck: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
};
