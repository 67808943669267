// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import queryString from "query-string";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea } from "containers/Permissions";
import { ALL_ORGANIZATION_MEMBERS } from "containers/Permissions/groups";
import { api } from "containers/Request";

import { getUserRoles } from "../_utils";
import { UsersTableRend } from "./UsersTableRend";

@restrictedArea(() => ({
  allowed: ALL_ORGANIZATION_MEMBERS,
}))
@withActiveOrganization
@connect(null, (dispatch) => ({
  getMyUsersPaginated: (queryParams) =>
    dispatch(
      api.getMyUsersPaginated.action({
        queryParams,
      })
    ),
  getUserGrants: (userId) =>
    dispatch(
      api.getUserGrantsPaginated.action({
        params: { userId },
        queryParams: { limit: "nolimit" },
      })
    ),
  getRoles: () => dispatch(api.getRoles.action()),
}))
export class UsersTableData extends Component {
  static propTypes = {
    // from @ExtendedRoute
    location: PropTypes.object.isRequired,
    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    // from @connect
    getMyUsersPaginated: PropTypes.func.isRequired,
    getUserGrants: PropTypes.func.isRequired,
    getRoles: PropTypes.func.isRequired,
  };

  state = {
    users: [],
    count: 0,
    error: undefined,
    loading: true,
  };

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location !== this.props.location ||
      prevProps.activeOrganization !== this.props.activeOrganization
    ) {
      this.refreshData();
    }
  }

  createUserProfile = (user, userGrants, allRoles) => ({
    id: user.id,
    avatar: user.avatar,
    email: user.email,
    family_name: user.family_name,
    given_name: user.given_name,
    authorizations: user.authorizations,
    roles: getUserRoles(userGrants, allRoles),
  });

  loadUserData = (allRoles) => async (user) => {
    const { getUserGrants } = this.props;

    const { result, error } = await getUserGrants(user.id);
    if (error) {
      return { error };
    }

    return {
      result: this.createUserProfile(user, result.results, allRoles),
    };
  };

  refreshData = async () => {
    const { getMyUsersPaginated, getRoles, location } = this.props;

    this.setState({ loading: true });

    const queryParams = queryString.parse(location.search);

    const { error, result } = await getMyUsersPaginated(queryParams);
    if (error) {
      this.setState({ error, loading: false });
      return;
    }

    const { result: rolesResult, error: rolesError } = await getRoles();
    if (rolesError) {
      this.setState({ error: rolesError, loading: false });
      return;
    }

    const users = await Promise.all(
      result.results.map(this.loadUserData(rolesResult.results))
    );
    const firstError = users.find((result) => Boolean(result.error));
    if (firstError) {
      this.setState({ error: firstError.error, loading: false });
      return;
    }

    this.setState({
      users: users.map((result) => result.result),
      count: result.meta.count,
      error: undefined,
      loading: false,
    });
  };

  render() {
    const { users, count, error, loading } = this.state;

    return (
      <UsersTableRend
        users={users}
        count={count}
        error={error}
        loading={loading}
      />
    );
  }
}
