// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { FieldArray } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Modal } from "components/layout";
import { trans } from "src/translations";

import { ForAdminContext } from "../../../../AdminCampaignForm/context";
import { PromotionsSearchableList } from "./PromotionsSearchableList";

export default class AddPromotionModal extends Component {
  static contextType = ForAdminContext;

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    dataSize: 0,
    allChecked: false,
    loading: false,
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  isChecked = (promotionId, promotions) => {
    return Boolean(promotions.find((p) => p === promotionId));
  };

  handleCheck = (promotionId, promotions, fields) => {
    if (this.isChecked(promotionId, promotions)) {
      const index = promotions.findIndex((id) => id === promotionId);
      fields.remove(index);
      this.setState({ allChecked: false });
    } else {
      fields.push(promotionId);
      this.setState((state) => ({
        allChecked: fields.length + 1 === state.dataSize,
      }));
    }
  };

  handleCheckAll = (setValue, setTouched) => (items) => {
    this.setState(
      (state) => ({
        allChecked: !state.allChecked,
        loading: true,
      }),
      () => {
        setTouched(true);
        setValue(this.state.allChecked ? items.map(({ id }) => id) : []);
      }
    );
  };

  newDataLoaded = (setValue) => (data, allDataLoaded) => {
    const { allChecked } = this.state;

    this.setState((state) => ({
      dataSize: data.length,
      loading: state.allChecked && !allDataLoaded,
    }));

    if (allChecked) {
      setValue(data.map(({ id }) => id));
    }
  };

  renderFields = ({
    name,
    remove,
    push,
    form: { getFieldMeta, getFieldHelpers, values },
  }) => {
    const { onClose, onSubmit } = this.props;
    const { value: selectedPromotions, touched } = getFieldMeta(name);
    const { setTouched, setValue } = getFieldHelpers(name);
    const { allChecked, loading } = this.state;
    const forAdmin = this.context;

    if (values.promotions.length && !selectedPromotions?.length) {
      setValue(values.promotions);
    }

    return (
      <>
        <PromotionsSearchableList
          selectedCount={selectedPromotions?.length ?? 0}
          onCheck={(promotionId) => {
            this.handleCheck(promotionId, selectedPromotions, {
              remove,
              push,
              length: selectedPromotions?.length ?? 0,
            });
            setTouched(true);
          }}
          onCheckAll={this.handleCheckAll(setValue, setTouched)}
          isChecked={(promotionId) =>
            this.isChecked(promotionId, selectedPromotions)
          }
          forAdmin={forAdmin}
          newDataLoaded={this.newDataLoaded(setValue)}
          allChecked={allChecked}
        />
        <ButtonsWrapper position="modal">
          <Button
            type="normal"
            onClick={onClose}
            dataTestId="edit-package-cancel-button"
          >
            {trans.CANCEL()}
          </Button>
          <Button
            type="green"
            onClick={() => {
              setTouched(false);
              onSubmit(selectedPromotions);
            }}
            dataTestId="edit-package-add-button"
            disabled={!touched || loading}
          >
            {trans.CHANGE()}
          </Button>
        </ButtonsWrapper>
      </>
    );
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Modal
        title={trans.CAMPAIGN_EDIT__FIELD_PROMOTIONS_CHANGE_PROMOTIONS()}
        isOpen={isOpen}
        onClose={onClose}
        size="big"
        dataTestId="edit-package-items-modal"
      >
        <FieldArray name="selectedPromotions" component={this.renderFields} />
      </Modal>
    );
  }
}
