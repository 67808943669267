// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getCommandsListQuery = gql`
  query commandsList(
    $pagination: PaginationInput!
    $search: FilterSearchInput
    $sort: CommandSortInput
  ) {
    commands(pagination: $pagination, search: $search, sort: $sort) {
      commands {
        items {
          name
          target {
            collectionID
            collectionName
            devicePublicID
          }
          status {
            processing
            sent
            received
            rejected
          }
          id
          creationDate
          timeToLive
          message
        }
        pagination {
          count
        }
      }
    }
  }
`;
