// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { PageHeader, PageContent, PageContent404 } from "components/layout";
import { trans } from "src/translations";

import styles from "./Page404.scss";

/**
 * The 404 page. Should be displayed automatically when `<Redirect to="/404" />`
 * is the last item in `react-router`'s `<Switch>`.
 */
export class Page404 extends Component {
  render() {
    return (
      <div>
        <PageHeader>{trans.PAGE_404__HEADER()}</PageHeader>
        <PageContent className={styles.container}>
          <PageContent404 />
        </PageContent>
      </div>
    );
  }
}
