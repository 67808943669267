import { useEffect, useState, useRef } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import { Form } from "formik";
import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { FormPagination } from "components/form";
import { Section, Overlay } from "components/layout";
import {
  Tabs,
  Tab,
  TabContent,
  getActiveTabIndex,
} from "components/navigation";
import { Permissions, ROLES } from "containers/Permissions";
import { allowInOrganization } from "containers/Permissions/groups";
import { trans } from "src/translations";
import { flatten } from "utils/serialize";
import { isTabFieldInErrors } from "utils/tabs";

import { forEachComponentInTab } from "./utils/config";

const getSection = (key, section, props) => {
  return (
    <Section key={key} header={section.name}>
      {section.components.map(
        ({ component: Component, props: makeProps }, idx) => {
          // eslint-disable-next-line react/no-array-index-key
          return <Component key={idx} {...makeProps(props)} />;
        }
      )}
    </Section>
  );
};

const getPageContent = ({ sectionItems, appData, formikProps }) => {
  const { isSubmitting } = formikProps;
  const { discarding, saving, validatingDraft } = formikProps.status ?? {};
  let message = "";

  if (saving) {
    message = trans.SAVING();
  } else if (discarding) {
    message = trans.DISCARDING();
  } else if (isSubmitting) {
    message = trans.SUBMITTING();
  } else if (validatingDraft) {
    message = trans.VALIDATING();
  }

  return (
    <Overlay
      message={message}
      active={isSubmitting || saving || discarding || validatingDraft}
    >
      {sectionItems.map((section, idx) =>
        getSection(idx, section, { formikProps, appData })
      )}
    </Overlay>
  );
};

const renderFeedback = ({ status }) => {
  const { message, submitError } = status ?? {};
  if (message) {
    return <Info type={message?.type}>{message.text}</Info>;
  }

  if (submitError) {
    return <Info type="error">{submitError}</Info>;
  }

  return null;
};

export const BaseForm = ({
  appData,
  config,
  redirectToInvalidTab,
  handleDiscardDraft,
  formikProps,
  handleSaveDraft,
  runServerValidation,
  checkIfDraftValid,
}) => {
  const formMounted = useRef(false);
  const params = useParams();
  const [activeTab, setActiveTab] = useState(
    getActiveTabIndex(params, "*", config.tabs)
  );

  const { saving, discarding, validatingDraft } = formikProps?.status ?? {};

  useEffect(() => {
    if (appData.has_draft_version && !formMounted.current) {
      formikProps.validateForm().then((errors) => {
        for (const path of Object.keys(flatten(errors))) {
          formikProps.setFieldTouched(path, true, false);
        }
      });
      formMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (runServerValidation) {
      checkIfDraftValid(formikProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveTab(getActiveTabIndex(params, "*", config.tabs));
  }, [params, config.tabs]);

  const getRolesWithSubmitPermission = () => {
    const roles = [ROLES.provider.signatory];

    if (appData.is_test) {
      roles.push(ROLES.provider.creator);
    }
    return roles;
  };

  const getLink = (tabId) => {
    return `/applications/my/${params.id}/edit/${config.tabs[tabId].url}`;
  };

  const isTabInvalid = (tab, errors) => {
    let fieldsStructures = [];
    forEachComponentInTab(tab, (component) => {
      if (component.fields) {
        fieldsStructures = [...fieldsStructures, ...component.fields];
      }
    });

    return isTabFieldInErrors(errors, fieldsStructures);
  };

  const getTabList = () => {
    return config.tabs.map((tab, idx) => {
      const invalid = isTabInvalid(tab, formikProps.errors);
      return (
        <Tab
          key={tab.url}
          to={getLink(idx)}
          text={tab.name}
          icon={invalid ? "warning" : "ok"}
          iconColor={invalid ? "error" : "success"}
          dataTestIconId={invalid ? "icon-error" : "icon-success"}
          dataTestId={tab.dataTestId}
        />
      );
    });
  };

  const checkErrorsBeforeSubmit = (formikProps) => {
    formikProps.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        redirectToInvalidTab(errors);
        const submitError = appData.is_test
          ? trans.TEST_APP_EDIT__SUBMIT_ERROR()
          : trans.APP_EDIT__SUBMIT_ERROR();
        formikProps.setStatus({ submitError });
      }
      formikProps.handleSubmit(redirectToInvalidTab);
    });
  };

  return (
    <>
      <Tabs>{getTabList()}</Tabs>
      <TabContent>
        {renderFeedback(formikProps)}
        <Form aria-label="form">
          <Routes>
            {config.tabs.map((tab) => (
              <Route
                // eslint-disable-next-line react/no-array-index-key
                key={tab.name}
                path={tab.url}
                element={getPageContent({
                  sectionItems: tab.sections,
                  appData,
                  formikProps,
                })}
              />
            ))}
          </Routes>
          {formikProps.errors?._error ? (
            <Info type="error">{formikProps.errors?._error}</Info>
          ) : null}
          <Permissions
            allowed={[
              allowInOrganization(
                appData.owner_public_id,
                getRolesWithSubmitPermission()
              ),
            ]}
          >
            {(isAllowed) => {
              const handleDiscard = appData.has_draft_version
                ? handleDiscardDraft
                : undefined;

              const handleSubmit = isAllowed
                ? () => checkErrorsBeforeSubmit(formikProps)
                : undefined;

              const handleSave = !appData.is_test ? handleSaveDraft : undefined;

              return (
                <FormPagination
                  activeTab={activeTab}
                  dirty={formikProps.dirty}
                  submitting={formikProps.isSubmitting}
                  saving={saving}
                  discarding={discarding}
                  validatingDraft={validatingDraft}
                  onNext={() => getLink(activeTab + 1)}
                  onPrev={() => getLink(activeTab - 1)}
                  onSave={handleSave}
                  onSubmit={handleSubmit}
                  onDiscard={handleDiscard}
                  tabCount={config.tabs.length}
                  tabTitle={config.tabs.map((t) => t.name)[activeTab]}
                />
              );
            }}
          </Permissions>
        </Form>
      </TabContent>
    </>
  );
};

BaseForm.propTypes = {
  appData: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  redirectToInvalidTab: PropTypes.func.isRequired,
  handleDiscardDraft: PropTypes.func.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  runServerValidation: PropTypes.bool,
  checkIfDraftValid: PropTypes.func.isRequired,
  // from @Formik
  formikProps: PropTypes.object.isRequired,
};
