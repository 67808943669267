// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

import * as actions from "../ModalRegions/action";

@connect(null, (dispatch, props) => ({
  addDeviceRegionsActive: () =>
    dispatch(
      actions.addDeviceRegionsActive(props.asset, props.model, props.version)
    ),
}))
export class DeviceModelActionMenu extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    asset: PropTypes.object.isRequired,
    model: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    version: PropTypes.string.isRequired,
    onRemoveDevice: PropTypes.func.isRequired,
    addDeviceRegionsActive: PropTypes.func,
  };

  state = {
    isRemovingDevice: false,
  };

  handleEditRegions = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { addDeviceRegionsActive } = this.props;
    addDeviceRegionsActive();
  };

  handleRemoveDevice = async () => {
    const { model, onRemoveDevice } = this.props;
    try {
      this.setState({ isRemovingDevice: true });
      await onRemoveDevice(model);
    } finally {
      this.setState({ isRemovingDevice: false });
    }
  };

  render() {
    return (
      <ActionMenu>
        <MenuItem onClick={this.handleEditRegions}>
          {trans.ASSET__EDIT_REGIONS_BUTTON()}
        </MenuItem>
        <MenuItem
          onClick={this.handleRemoveDevice}
          isLoading={this.state.isRemovingDevice}
        >
          {trans.ASSET__REMOVE_DEVICE_BUTTON()}
        </MenuItem>
      </ActionMenu>
    );
  }
}
