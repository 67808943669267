// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { LineLoader } from "components/elements";
import { tableHeadPosPropType } from "components/layout/Table/propTypes";
import { classes } from "utils/classes";

import styles from "./TableLoader.scss";

/** part of `<TableHeader>`, indicates loading data. */
export class TableLoader extends Component {
  static propTypes = {
    loading: PropTypes.bool /** If true loading animation is displayed. */,
    colspan: PropTypes.number /* Number of columns in table */,
    /** Defines if table header stays in place on table scroll */
    position: tableHeadPosPropType,
  };

  static defaultProps = {
    loading: false,
    colspan: 1,
    position: "default",
  };

  render() {
    return (
      <tr className={classes(styles.container, styles[this.props.position])}>
        <td colSpan={this.props.colspan}>
          <LineLoader loading={this.props.loading} />
        </td>
      </tr>
    );
  }
}

export default TableLoader;
