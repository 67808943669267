// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { Link } from "components/typography";
import { classes } from "utils/classes";

import styles from "./NavBar.scss";

/**
 * Container for link in top-right corner.
 */
export class NavBar extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        path: PropTypes.string,
        onClick: PropTypes.func,
      })
    ).isRequired,
    className: PropTypes.string,
  };

  static createNavigationLink = (label, path) => {
    return (
      <NavLink
        key={label}
        to={path}
        className={({ isActive }) =>
          isActive ? classes(styles.active, styles.link) : styles.link
        }
      >
        {label}
      </NavLink>
    );
  };

  static createButtonLink = (label, onClick) => {
    return (
      <Link
        key={label}
        onClick={() => (onClick ? onClick() : null)}
        className={styles.link}
      >
        {label}
      </Link>
    );
  };

  static createLink = (label, href) => {
    return (
      <Link key={label} href={href} className={styles.link}>
        {label}
      </Link>
    );
  };

  render() {
    const { items = [], className = "" } = this.props;

    return (
      <nav className={className}>
        {items.map((item) => {
          if (item.path) {
            return NavBar.createNavigationLink(item.label, item.path);
          }

          if (item.href) {
            return NavBar.createLink(item.label, item.href);
          }

          return NavBar.createButtonLink(item.label, item.onClick);
        })}
      </nav>
    );
  }
}

export default NavBar;
