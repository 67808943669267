// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { convertSearchSuggestionsForSearchBar } from "pages/_utils/convertSearchSuggestionsForSearchBar";

export const convertDeviceSearchSuggestionsForSearchBar = (gqlQueryResult) =>
  convertSearchSuggestionsForSearchBar(
    gqlQueryResult,
    "deviceSearchSuggestions.deviceSearchSuggestions"
  );
