// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import InfiniteCalendar, { withRange, Calendar } from "react-infinite-calendar";
import "react-infinite-calendar/styles.css";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./DatePicker.scss";

const EVENT_END_DATE_SELECTED = 3;

export class DatePicker extends Component {
  static propTypes = {
    /** If true date picker is displayed */
    isOpen: PropTypes.bool,
    /** If true user can select date range */
    range: PropTypes.bool,
    /**
     *  Selected date or date range.
     *  Is required when range=true, you must specified it as {start: Date, end: Date}
     *  Is optional when range=false than you can provide just Date instance
     */
    selected: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.shape({
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
      }),
    ]),
    /** Called on date select with selected Date. If range=true called with selected start and end Date */
    onSelect: PropTypes.func.isRequired,
    /** Called when picker is closed, click outside picker */
    onClose: PropTypes.func,
    /** User class name applied to top container */
    className: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    selected: new Date(),
    range: false,
    isOpen: false,
    onClose: Function.prototype,
    dataTestId: "date-picker",
  };

  componentDidMount() {
    if (this.props.isOpen) {
      document.addEventListener("mousedown", this.handleClickOutside, true);
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        document.addEventListener("mousedown", this.handleClickOutside, true);
      } else {
        document.removeEventListener(
          "mousedown",
          this.handleClickOutside,
          true
        );
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, true);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClose();
    }
  };

  handleSelect = (date) => {
    const { range, onSelect } = this.props;
    if (range) {
      if (date.eventType === EVENT_END_DATE_SELECTED) {
        onSelect(date.start, date.end);
      }
    } else {
      onSelect(date);
    }
  };

  render() {
    const { selected, range, isOpen, className, dataTestId } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <div
        ref={this.setWrapperRef}
        className={classes(styles.container, className)}
        data-test-id={dataTestId}
      >
        <InfiniteCalendar
          onSelect={this.handleSelect}
          Component={range ? withRange(Calendar) : undefined}
          width={400}
          height={350}
          selected={
            range ? { start: selected.start, end: selected.end } : selected
          }
          locale={{ headerFormat: "MMM Do" }}
          theme={{
            accentColor: "#376179",
            headerColor: "#376179",
            weekdayColor: "#41839a",
            selectionColor: "#41839a",
            floatingNav: {
              background: "rgba(39, 59, 79, 0.9)",
              color: "#FFF",
              chevron: "#ffb819",
            },
          }}
        />
      </div>
    );
  }
}

export default DatePicker;
