import { Component } from "react";

import PropTypes from "prop-types";

import { Header, Link } from "components/typography";
import { trans, HtmlTranslation } from "src/translations";
import { withQuery } from "utils/graphql";
import { calulateOffsetFromPage } from "utils/pagination";

import {
  TARGET_DEVICES_ITEMS_PER_PAGE,
  DEVICE_TYPE_QUERY,
  DEVICE_TYPE_PDID,
} from "../constants";
import { devicesAcceptingCommandsQuery } from "./gql/devicesAcceptingCommandsQuery";
import { listDeviceFiltersQuery } from "./gql/listDeviceFiltersQuery";
import styles from "./TargetDevices.scss";
import { TargetRow } from "./TargetRow";

const adaptDeviceFilter = ({ id, name, devices }) => ({
  id,
  name,
  deviceCount: devices?.pagination?.count,
});

const adaptTargetDevice = ({ id }) => ({
  id: id,
  name: id,
});

@withQuery({ name: "deviceFilters", query: listDeviceFiltersQuery })
@withQuery({
  name: "devicesAcceptingCommands",
  query: devicesAcceptingCommandsQuery,
})
export class TargetList extends Component {
  static propTypes = {
    selectedItem: PropTypes.object,
    onSelectItem: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    modalConfig: PropTypes.object.isRequired,

    // from @withQuery
    deviceFiltersQuery: PropTypes.func,
    devicesAcceptingCommandsQuery: PropTypes.func,
  };

  fetchDeviceFilters = async (queryParams) => {
    const { deviceFiltersQuery } = this.props;

    const result = await deviceFiltersQuery({
      variables: {
        pagination: {
          offset: calulateOffsetFromPage(
            queryParams.page,
            TARGET_DEVICES_ITEMS_PER_PAGE
          ),
          limit: TARGET_DEVICES_ITEMS_PER_PAGE,
        },
        search: {
          query: queryParams.search || "",
        },
      },
    });

    const count = result.deviceFilters?.deviceFilters?.pagination?.count || 0;
    const results = result.deviceFilters?.deviceFilters?.items || [];

    return {
      meta: { count },
      results: results.map(adaptDeviceFilter),
    };
  };

  fetchDevicePDIDs = async (queryParams) => {
    const { devicesAcceptingCommandsQuery } = this.props;
    const result = await devicesAcceptingCommandsQuery({
      variables: {
        pagination: {
          offset: calulateOffsetFromPage(
            queryParams.page,
            TARGET_DEVICES_ITEMS_PER_PAGE
          ),
          limit: TARGET_DEVICES_ITEMS_PER_PAGE,
        },
        search: {
          query: queryParams.search || "",
        },
      },
    });
    if (result.error) {
      throw result.error;
    }
    const payload = result?.devicesAcceptingCommands?.devicesAcceptingCommands;
    const count = payload?.pagination?.count || 0;
    const items = payload?.items || [];

    return {
      meta: {
        count,
      },
      results: items.map(adaptTargetDevice),
    };
  };

  render() {
    const { selectedItem, onSelectItem, mode, modalConfig } = this.props;

    if (DEVICE_TYPE_QUERY === mode) {
      return (
        <TargetRow
          key={DEVICE_TYPE_QUERY}
          mode={mode}
          selectedItem={selectedItem}
          onSelectItem={(query) =>
            onSelectItem({
              type: DEVICE_TYPE_QUERY,
              id: query.id,
              name: query.name,
              deviceCount: query.deviceCount,
            })
          }
          itemsPerPage={TARGET_DEVICES_ITEMS_PER_PAGE}
          fetchFunc={this.fetchDeviceFilters}
          header={modalConfig.query.title}
          description={
            <>
              <Header type="h4" bold={true}>
                <HtmlTranslation
                  className={styles.header}
                  transFunc={trans.TARGETS_QUERY_AND_FILTER_NOTICE}
                  transParams={{
                    deviceInventoryLink: (
                      <Link target="_blank" href="/devices/inventory">
                        {trans.DEVICES__DEVICE_INVENTORY_ROUTE_NAME()}
                      </Link>
                    ),
                  }}
                />
              </Header>

              <div>{trans.TARGETS_QUERY_AND_FILTER_DESCRIPTION()}</div>
            </>
          }
        />
      );
    }

    return (
      <TargetRow
        key={DEVICE_TYPE_PDID}
        mode={mode}
        selectedItem={selectedItem}
        onSelectItem={(pdid) =>
          onSelectItem({ type: DEVICE_TYPE_PDID, id: pdid.id, name: pdid.name })
        }
        itemsPerPage={TARGET_DEVICES_ITEMS_PER_PAGE}
        fetchFunc={this.fetchDevicePDIDs}
        header={modalConfig.pdid.title}
        description={modalConfig.pdid.description}
      />
    );
  }
}
