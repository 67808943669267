// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import omit from "lodash-es/omit";
import PropTypes from "prop-types";

import { UPLOAD_STATUS } from "./constants";
import { AwsUploaderChildrenProps, FormatPropType } from "./propTypes";
import { UploaderAWSIdle } from "./UploaderAWSIdle";
import { UploaderAWSProgress } from "./UploaderAWSProgress";

/**
 * Shows curent state of AWS S3 file upload.
 */
export class UploaderAWSField extends PureComponent {
  static propTypes = {
    /**
     * Array of MIME types or extensions, e.g. ["image/jpeg", "image/*", ".zip"]
     */
    formats: FormatPropType,
    /**
     * Additional hint for idle uploader.
     * e.g. "tar.gz File extension"
     */
    uploadHint: PropTypes.node,
    /**
     * Faac argument for AwsUploader
     */
    uploaderStatus: AwsUploaderChildrenProps,
  };

  static defaultProps = {
    formats: ["*"],
  };

  render() {
    const { uploaderStatus } = this.props;

    const childProps = {
      ...omit(this.props, "uploaderStatus"),
      ...uploaderStatus,
    };

    if (uploaderStatus.status === UPLOAD_STATUS.progress) {
      return <UploaderAWSProgress {...childProps} />;
    }

    if (uploaderStatus.status === UPLOAD_STATUS.completed) {
      return <UploaderAWSProgress {...childProps} isDone={true} />;
    }

    if (uploaderStatus.status === UPLOAD_STATUS.error) {
      return <UploaderAWSProgress {...childProps} isError={true} />;
    }

    return <UploaderAWSIdle {...childProps} />;
  }
}
