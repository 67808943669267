// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";
import { connect } from "react-redux";

import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";

import { apiThatThrows } from "containers/Request";

import { SalesforceAgreements } from "./SalesforceAgreements";

const adaptAgreement = (item) => ({
  id: item.id,
  organizationId: item.organization_id,
  salesforceId: item.salesforce_id,
  salesforceUrl: item.salesforce_url,
});

const adaptOrganization = ({ id, name }) => [id, name];

@connect(null, (dispatch) => ({
  getSalesforceAgreements: (qaSummariesId) =>
    dispatch(
      apiThatThrows.getSalesforceAgreementsPaginated.action({
        queryParams: {
          qa_summary_id: qaSummariesId,
          limit: "nolimit",
        },
      })
    ),
  deleteSalesforceAgreement: (id) =>
    dispatch(
      apiThatThrows.deleteSalesforceAgreement.action({ params: { id } })
    ),
  getOrganizationsPaginated: (ids) =>
    dispatch(
      apiThatThrows.getOrganizationsPaginated.action({
        queryParams: {
          id: ids,
          limit: "nolimit",
        },
      })
    ),
  getModerationResult: (id) =>
    dispatch(
      apiThatThrows.getModerationResult.action({
        params: { id },
        queryParams: { include: "moderation_targets" },
      })
    ),
}))
export class SalesforceAgreementsData extends PureComponent {
  static propTypes = {
    qaSummariesId: PropTypes.string.isRequired,
    latestModerationResult: PropTypes.object.isRequired,

    // from @connect
    getSalesforceAgreements: PropTypes.func.isRequired,
    deleteSalesforceAgreement: PropTypes.func.isRequired,
    getOrganizationsPaginated: PropTypes.func.isRequired,
    getModerationResult: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    error: false,

    organizations: {},
    agreements: [],
    moderationTargets: [],
  };

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      await Promise.all([
        this.fetchAgreementsWithOrganizations(),
        this.fetchModerationTargets(),
      ]);
    } catch (e) {
      this.setState({ error: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  fetchModerationTargets = async () => {
    const { latestModerationResult, getModerationResult } = this.props;
    const id = latestModerationResult.id;
    const { results } = await getModerationResult(id);
    const moderationTargets = results.moderation_targets || [];
    this.setState({ moderationTargets });
  };

  fetchAgreementsWithOrganizations = async () => {
    const agreements = await this.fetchAgreements();
    const organizationIds = agreements.map((item) => item.organizationId);

    if (organizationIds.length > 0) {
      await this.fetchOrganizations(organizationIds);
    }
  };

  fetchOrganizations = async (organizationIds) => {
    const { getOrganizationsPaginated } = this.props;

    const response = await getOrganizationsPaginated(organizationIds);
    this.setState((state) => ({
      organizations: {
        ...state.organizations,
        ...Object.fromEntries(
          response.results.map((item) => adaptOrganization(item))
        ),
      },
    }));
  };

  fetchAgreements = async () => {
    const { getSalesforceAgreements, qaSummariesId } = this.props;

    const response = await getSalesforceAgreements(qaSummariesId);
    const agreements = response.results.map((item) => adaptAgreement(item));

    this.setState({ agreements });

    return agreements;
  };

  deleteAgreement = async (deleteAgreementId) => {
    const { deleteSalesforceAgreement } = this.props;
    const { agreements, organizations } = this.state;

    try {
      await deleteSalesforceAgreement(deleteAgreementId);

      const newAgreements = agreements.filter(
        ({ id }) => id !== deleteAgreementId
      );

      const newOrganizations = Object.fromEntries(
        newAgreements.map(({ organizationId }) => [
          organizationId,
          organizations[organizationId],
        ])
      );

      this.setState({
        organizations: newOrganizations,
        agreements: newAgreements,
      });
    } catch (e) {
      this.setState({ error: true });
    }
  };

  addAgreementSuccess = async (response) => {
    const { agreements } = this.state;

    const agreement = adaptAgreement(response.results);

    await this.fetchOrganizations([agreement.organizationId]);

    this.setState({
      agreements: [...agreements, agreement],
    });
  };

  getOrganizationsWithAcceptedTargets = () => {
    const { moderationTargets } = this.state;
    return uniq(
      moderationTargets
        .filter((target) => target.status === "ACCEPTED")
        .map((target) => target.owner_id)
    );
  };

  render() {
    const { qaSummariesId } = this.props;
    const { agreements, loading, error, organizations } = this.state;

    const agreementsWithOrganization = agreements.map((item) => ({
      ...item,
      organizationName: organizations[item.organizationId] || "",
    }));

    return (
      <SalesforceAgreements
        qaSummariesId={qaSummariesId}
        agreements={agreementsWithOrganization}
        loading={loading}
        error={error}
        deleteAgreement={this.deleteAgreement}
        onAddAgreement={this.addAgreementSuccess}
        organizationsWithAcceptedTargets={this.getOrganizationsWithAcceptedTargets()}
      />
    );
  }
}
