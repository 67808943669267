// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import {
  TableRowColumn,
  TableRow,
  TableRowColumnExtended,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

import styles from "./customPlanRow.scss";

export class CustomPlanRow extends Component {
  static propTypes = {
    plan: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      appsCount: PropTypes.number.isRequired,
    }).isRequired,
    onPlanRemove: PropTypes.func.isRequired,
  };

  render() {
    const { plan, onPlanRemove } = this.props;
    return (
      <TableRow>
        <TableRowColumnExtended
          dataTestId="column-name"
          className={styles.name}
          text={plan.name}
          to={`/distribution/custom-plans/edit/${plan.id}`}
        />
        <TableRowColumn
          className={styles.column}
          dataTestId="column-apps-count"
        >
          {plan.appsCount}
        </TableRowColumn>
        <TableRowColumn className={styles.menu} dataTestId="column-action-menu">
          <ActionMenu>
            <MenuItem
              dataTestId="edit-item"
              to={`/distribution/custom-plans/edit/${plan.id}`}
            >
              {trans.EDIT_DETAILS()}
            </MenuItem>
            <MenuItem
              dataTestId="delete-item"
              onClick={() => onPlanRemove(plan)}
            >
              {trans.DELETE()}
            </MenuItem>
          </ActionMenu>
        </TableRowColumn>
      </TableRow>
    );
  }
}
