// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const ITEMS_PER_PAGE = 20;
export const TABS_CONFIG = [
  {
    id: "",
    dataTestId: "device-queries-list",
    label: "",
    columns: [
      {
        id: "name",
        label: "Command name",
        sortBy: "name",
        dataTestId: "name-column-header",
      },
      {
        id: "target",
        label: "PDID / Query",
        dataTestId: "target-column-header",
      },
      {
        id: "creation_date",
        label: "Creation date",
        sortBy: "creation_date",
        dataTestId: "creation-date-column-header",
      },
      {
        id: "status",
        label: "status",
        sortBy: "status",
        dataTestId: "actions-column-header",
      },
      {
        id: "actions",
        label: "",
        dataTestId: "actions-column-header",
      },
    ],
  },
];
