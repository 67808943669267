// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";

import styles from "./pdfViewer.scss";

export class PDFViewer extends Component {
  static propTypes = {
    className: PropTypes.string,
    /** Style override */
    style: PropTypes.object,
    text: PropTypes.string,
    url: PropTypes.string,
    children: PropTypes.node,
    fileName: PropTypes.string.isRequired,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "pdf-viewer",
  };

  render() {
    const { className, style, url, text, children, fileName, dataTestId } =
      this.props;

    return (
      <div className={className} style={style} data-test-id={dataTestId}>
        <div className={styles.viewer}>{children}</div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            type="normal"
            to={url}
            download={fileName}
            dataTestId={`${dataTestId}-button`}
          >
            {text}
          </Button>
        </div>
      </div>
    );
  }
}
