// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./tabs.scss";

/**
 * Component that implements tabs.  Use with [TabContent](#tabcontent)
 */
export class Tabs extends Component {
  static propTypes = {
    className: PropTypes.string,
    /** [Tab](#tab) elements */
    children: PropTypes.node,
  };

  render() {
    const { children, className } = this.props;
    return (
      <div className={classes(styles.container, className)}>
        <div className={styles.tabs}>{children}</div>
      </div>
    );
  }
}

export default Tabs;
