// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { UpdateJobFormData } from "./UpdateJobFormData";

export function UpdateJobForm() {
  return (
    <Fragment>
      <PageHeader>{trans.UPDATES__CREATE_UPDATE_JOB_HEADER()}</PageHeader>
      <PageContent>
        <UpdateJobFormData />
      </PageContent>
    </Fragment>
  );
}
