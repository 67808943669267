// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import set from "lodash-es/set";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { SelectField } from "components/form/fields";
import { Section, Subsection } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { apiThatThrows } from "containers/Request";
import { TargetsField } from "pages/_shared";
import { withTargetsSend } from "pages/_shared/TargetsField/decorators/withTargetsSend";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";
import { dataFormatter } from "utils/jsonApi";
import { pipeValidators, hasOneElement } from "utils/validation";

import { FIELDS, AUTO_APPROVE_TIME_OPTIONS } from "./constants";
import styles from "./defaultPlanForm.scss";

const jsonPointerToFieldName = {
  "/data/attributes/default_plan_auto_distribute_wait_time": "autoApproveTime",
  "/data/relationships/targets/data": "targets._error",
};

@withTargetsSend({
  distributorFieldNameInRequest: "device_owners",
  distributorDeviceType: "device_owner",
})
@withActiveOrganization
@connect(null, (dispatch) => ({
  updatePlan: (body) =>
    dispatch(
      apiThatThrows.updateMyDefaultDistributionPlan.action({
        options: { body },
      })
    ),
}))
export class DefaultPlanForm extends Component {
  static propTypes = {
    refreshData: PropTypes.func.isRequired,
    setInfo: PropTypes.func.isRequired,
    info: PropTypes.string,
    initialValues: PropTypes.object,
    onChange: PropTypes.func,
    // from @connect
    updatePlan: PropTypes.func.isRequired,

    // from @withTargetsSend
    createTargetsBody: PropTypes.func,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  createBody = ({ autoApproveTime, targets }) => {
    // Due to targets relationship, this request has a very unusual structure
    // that does not conform with jsona.
    const { createTargetsBody } = this.props;

    const data = {
      type: "default-plans",
      default_plan_auto_distribute_wait_time: autoApproveTime,
    };
    const body = dataFormatter.serialize({ stuff: data });

    const targetsBody = createTargetsBody("targets", targets);
    set(body, "data.relationships.targets", targetsBody);

    return JSON.stringify(body);
  };

  validate = (formValues) =>
    pipeValidators(hasOneElement("targets"))(formValues);

  handleSubmit = async (values, { setErrors }) => {
    const { updatePlan, refreshData, setInfo } = this.props;

    try {
      const body = this.createBody(values);
      await updatePlan(body);

      setInfo(trans.CHANGES_SAVE_SUCCESS());
      refreshData();
    } catch (error) {
      setInfo(null);
      const submitErrors = prepareErrorsForForm(error, jsonPointerToFieldName);
      setErrors(submitErrors);
    }
  };

  render() {
    const { activeOrganization, info, initialValues, onChange } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validate={this.validate}
      >
        {({
          dirty,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          setFieldTouched,
        }) => {
          return (
            <Form aria-label="form" onChange={() => info && onChange()}>
              <Section header={trans.DISTR_PLAN__SECTION_TARGETS()}>
                <TargetsField
                  devicesDistributorId={activeOrganization.id}
                  required={true}
                />
              </Section>
              <Section header={trans.DISTR_PLAN__SECTION_APPS_TO_DISTRIBUTE()}>
                <Subsection
                  className={styles.helpText}
                  dataTestId="default-distribution-plan-help"
                >
                  {trans.DISTR_DEFAULT_PLAN__SECTION_APPS_TO_DISTRIBUTE_HELP()}
                </Subsection>
                <SelectField
                  values={[
                    AUTO_APPROVE_TIME_OPTIONS.IMMEDIATELY,
                    AUTO_APPROVE_TIME_OPTIONS.ONE_WEEK,
                    AUTO_APPROVE_TIME_OPTIONS.TWO_WEEKS,
                  ]}
                  dataTestId="auto-approve-time-select"
                  label={trans.DISTR_DEFAULT_PLAN__AUTOAPPROVE_LABEL()}
                  name={FIELDS.AUTO_APPROVE_TIME.name}
                  onChange={(e) => {
                    setFieldTouched(FIELDS.AUTO_APPROVE_TIME.name, true);
                    handleChange(e);
                  }}
                />
              </Section>
              {errors?._error && <Info type="error">{errors._error}</Info>}
              {info && <Info>{info}</Info>}

              <ButtonsWrapper>
                <Button
                  dataTestId="submit-button"
                  type="green"
                  processing={isSubmitting}
                  disabled={
                    isSubmitting ||
                    !dirty ||
                    Boolean(Object.entries(errors).length)
                  }
                  onClick={handleSubmit}
                >
                  {trans.SUBMIT_CHANGES()}
                </Button>
              </ButtonsWrapper>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
