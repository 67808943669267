// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { fixUrl } from "utils/string";

const isSubitemMatchingString = (pathname, subpage) => {
  const pathnameFix = fixUrl(pathname);
  const subpageFix = fixUrl(subpage);
  return pathnameFix.startsWith(subpageFix);
};

export const isSubItemMatching = (pathname, activeForPaths) => {
  const subpageMatches = (subpage) => {
    if (subpage instanceof RegExp) {
      return subpage.test(pathname);
    } else {
      return isSubitemMatchingString(pathname, subpage);
    }
  };

  return (
    Boolean(activeForPaths) && activeForPaths.find(subpageMatches) !== undefined
  );
};
