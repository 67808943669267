// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import { INVALID_ID } from "../constants";
import styles from "./user.scss";

export class User extends Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string,
      isUnknown: PropTypes.bool,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
    }),
    onClick: PropTypes.func,
  };

  getUserName = () => {
    const { givenName, familyName, isUnknown, id } = this.props.user;
    return isUnknown
      ? trans.APP_DETAILS_MODERATION__UNKNOWN_USER({ id })
      : [givenName, familyName].join(" ");
  };

  renderUser = () => {
    const {
      onClick,
      user: { isUnknown },
    } = this.props;

    return (
      <div>
        <GrabsonIcon
          name={isUnknown ? "warning" : "users"}
          size="normal"
          className={isUnknown ? styles.iconWarn : styles.icon}
        />
        <span
          onClick={onClick}
          className={classes(styles.button, {
            [styles.disabled]: Boolean(!onClick),
          })}
        >
          {this.getUserName()}
        </span>
      </div>
    );
  };

  renderButton = () => {
    const { onClick } = this.props;

    return (
      <div>
        {NO_VALUE_PLACEHOLDER}
        {onClick && (
          <span onClick={onClick} className={styles.button}>
            {`(${trans.CHANGE()})`}
          </span>
        )}
      </div>
    );
  };

  render() {
    const { user } = this.props;
    return (
      <div>
        {user && user.id !== INVALID_ID
          ? this.renderUser()
          : this.renderButton()}
      </div>
    );
  }
}
