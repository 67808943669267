// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";
import queryString from "query-string";

import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import { Section } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea } from "containers/Permissions";
import { ALL_PROVIDERS } from "containers/Permissions/groups";
import { apiThatThrows } from "containers/Request";
import { APP_ROUTE_MY } from "pages/Applications/constants";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { withRouter, withSearch, withAbort } from "utils/decorators";
import { adaptSortingQueryParams } from "utils/url";

import { ApplicationsTabbedTable } from "./ApplicationsTabbedTable";
import { myAppsTabsConfig } from "./ApplicationsTabbedTableConfig";

@restrictedArea(() => ({
  allowed: ALL_PROVIDERS,
}))
@withAbort
@withRouter
@withSearch
@withActiveOrganization
@connect(null, (dispatch) => ({
  getMyApplicationsPaginated: (queryParams, abortSignal) =>
    dispatch(
      apiThatThrows.getMyApplicationsPaginated.action({
        queryParams,
        abortSignal,
      })
    ),

  loadApplicationDraftData: async (appId, abortSignal) => {
    const { results } = await dispatch(
      apiThatThrows.getApplicationDraft.action({
        params: { app_id: appId },
        abortSignal,
      })
    );

    return results || {};
  },
  loadApplicationFullVersionData: async (appId, abortSignal) => {
    const { results } = await dispatch(
      apiThatThrows.getApplicationFullVersionsList.action({
        params: { app_id: appId },
        queryParams: { ordering: "-created_date", limit: 1, page: 1 },
        abortSignal,
      })
    );

    return get(results, "[0]", {});
  },
  loadTestApplicationData: async (appId, abortSignal) => {
    try {
      const { results } = await dispatch(
        apiThatThrows.getApplicationTestVersion.action({
          params: { app_id: appId },
          abortSignal,
        })
      );

      return results || {};
    } catch (err) {
      // This means that there is no test version for given application
      if (err.isNotFound) {
        return {};
      }

      throw err;
    }
  },
  loadQaSummary: async (id, abortSignal) => {
    const { results } = await dispatch(
      apiThatThrows.getModerationResultsPaginated.action({
        queryParams: {
          version_id: id,
          include: "qa_summary",
          limit: 1,
          page: 1,
        },
        abortSignal,
      })
    );

    return get(results, "[0].qa_summary");
  },
}))
export class MyAppListContent extends Component {
  static propTypes = {
    // from @connect
    getMyApplicationsPaginated: PropTypes.func.isRequired,
    loadApplicationDraftData: PropTypes.func.isRequired,
    loadApplicationFullVersionData: PropTypes.func.isRequired,
    loadTestApplicationData: PropTypes.func.isRequired,
    loadQaSummary: PropTypes.func.isRequired,

    // from @withAbort
    createAbortSignal: PropTypes.func.isRequired,
    abort: PropTypes.func.isRequired,
    isAbortError: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,

    // from @withSearch
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
  };

  abortFetchingTableDataSignal = null;

  state = {
    apps: [],
    count: 0,
    loading: true,
    error: null,
    defaultOrder: {},
  };

  componentWillUnmount = () => {
    this.abortFetchingTableData();
  };

  abortFetchingTableData = () => {
    const { abort } = this.props;
    abort(this.abortFetchingTableDataSignal);
    this.setState({ loading: false });
  };

  async getModerationData(appVersionId) {
    const { loadQaSummary } = this.props;

    let moderationData = {
      is_premium: false,
      flow_status: NO_VALUE_PLACEHOLDER,
      priority: NO_VALUE_PLACEHOLDER,
      status: NO_VALUE_PLACEHOLDER,
    };

    const qaSummary = await loadQaSummary(
      appVersionId,
      this.abortFetchingTableDataSignal.signal
    );

    if (qaSummary) {
      moderationData = {
        is_premium: qaSummary.is_premium,
        flow_status: get(
          qaSummary,
          "latest_moderation_result.flow_status",
          NO_VALUE_PLACEHOLDER
        ),
        priority: qaSummary.priority,
        status: get(
          qaSummary,
          "latest_moderation_result.status",
          NO_VALUE_PLACEHOLDER
        ),
      };
    }

    return moderationData;
  }

  getIconSmallImg = (app) => {
    const iconSmall = get(app, "images", []).find(
      (i) => i.type === "ICON_SMALL"
    );
    return iconSmall ? iconSmall.image : null;
  };

  getDateSubmitted = (app) => {
    return app.created_date ? new Date(app.created_date) : null;
  };

  prepareDataFromApp = (data) => {
    return {
      id: data.id,
      active_version: data.active_version,
      name: data.name,
      has_submitted_version: data.has_submitted_version,
      submission_status: data.submission_status,
      distribution_status: data.distribution_status,
      type: data.type,
      partial_result: data.partial_result,
    };
  };

  prepareDataFromVersion = (data) => {
    return {
      date_submitted: this.getDateSubmitted(data),
      category: data.category,
      icon_small: this.getIconSmallImg(data),
    };
  };

  prepareDataFromModeration = (data) => {
    return {
      status: data.status,
      is_premium: data.is_premium,
      flow_status: data.flow_status,
    };
  };

  prepareAppDataForTable = ({ app, appVersion, appModeration }) => {
    return {
      ...this.prepareDataFromApp(app),
      ...this.prepareDataFromVersion(appVersion),
      ...this.prepareDataFromModeration(appModeration),
    };
  };

  async fetchApplicationsData(apps) {
    const {
      loadTestApplicationData,
      loadApplicationDraftData,
      loadApplicationFullVersionData,
    } = this.props;

    return Promise.all(
      apps.map(async (app) => {
        const { id, is_test, has_draft_version, has_submitted_version } = app;

        let appVersion = {};
        let appModeration = {};

        /* If an app has an only test version, draft version or full versions we need to do different request. */
        try {
          if (is_test) {
            appVersion = await loadTestApplicationData(
              id,
              this.abortFetchingTableDataSignal.signal
            );
          } else if (has_submitted_version) {
            appVersion = await loadApplicationFullVersionData(
              id,
              this.abortFetchingTableDataSignal.signal
            );
            appModeration = await this.getModerationData(appVersion.id);
          } else if (has_draft_version) {
            appVersion = await loadApplicationDraftData(
              id,
              this.abortFetchingTableDataSignal.signal
            );
          }
        } catch (e) {
          console.error(e.message);
          app.partial_result = true;
        }

        return { app, appVersion, appModeration };
      })
    );
  }

  createQueryParams() {
    const { location } = this.props;
    let queryParams = queryString.parse(location.search);
    queryParams = adaptSortingQueryParams(queryParams, "ordering");
    delete queryParams.tab;

    return queryParams;
  }

  async makeRequests() {
    const { getMyApplicationsPaginated, createAbortSignal, isAbortError } =
      this.props;

    this.abortFetchingTableData();
    this.abortFetchingTableDataSignal = createAbortSignal();

    this.setState({ loading: true });
    try {
      const queryParams = this.createQueryParams();
      const { results: myApplications, meta } =
        await getMyApplicationsPaginated(
          queryParams,
          this.abortFetchingTableDataSignal.signal
        );

      const count = get(meta, "count");

      /* In order to show full info in an Application Row, we have to fetch application version */

      const apps = await this.fetchApplicationsData(myApplications);

      this.setState({
        apps: apps.map(this.prepareAppDataForTable),
        count,
        error: null,
        defaultOrder: { sortBy: queryParams.ordering, sortOrder: "ASC" },
      });
    } catch (error) {
      if (isAbortError(error)) {
        return;
      }

      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.makeRequests();
  }

  componentDidUpdate(prevProps) {
    /* we need to rerender app list only when url params changed, but ignoring tab parameter */
    const prevUrlObject = queryString.parse(prevProps.location.search);
    const urlObject = queryString.parse(this.props.location.search);
    delete urlObject.tab;
    delete prevUrlObject.tab;
    if (
      !isEqual(prevUrlObject, urlObject) ||
      this.props.activeOrganization.id !== prevProps.activeOrganization.id
    ) {
      this.makeRequests();
    }
  }

  render() {
    const { apps, count, loading, error, defaultOrder } = this.state;
    const { changeSearch, searchValue } = this.props;
    const locationMessage = get(this.props, "location.state.message");

    return (
      <div>
        <Section>
          <SearchBar onSearch={changeSearch} value={searchValue} />
        </Section>
        <Section>{locationMessage && <Info>{locationMessage}</Info>}</Section>
        <Section>
          <ApplicationsTabbedTable
            appRouteType={APP_ROUTE_MY}
            tabsConfig={myAppsTabsConfig}
            data={{
              results: apps,
              count: count,
              defaultOrder,
              loading: loading,
              error: error,
            }}
          />
        </Section>
      </div>
    );
  }
}
