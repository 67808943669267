// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

const DEFAULT_DEBOUNCE = 500;

export const debounce = (
  fn: (...args: unknown[]) => unknown,
  wait = DEFAULT_DEBOUNCE
): ((...args: unknown[]) => unknown) => {
  let timeoutId: NodeJS.Timeout;

  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout((args) => fn(...args), wait, args);
  };
};
