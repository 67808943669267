// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { classes } from "utils/classes";

import style from "./status.scss";

/**
 * Small component usually used to indicate some particular state of the resource.
 *
 * Examples:
 *
 * - device: `Disabled`/`Test only`
 * - application: `new`/`test`/`draft`/`submitted`
 */

export const Status = ({ children, dataTestId = "status", className = "" }) => (
  <div data-test-id={dataTestId} className={classes(style.status, className)}>
    {children}
  </div>
);

Status.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};
