// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { InfoBox } from "components/layout";
import { Header } from "components/typography";
import {
  organizationsPropTypes,
  profilePropTypes,
} from "containers/Auth/propTypes";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { isSignatoryUser } from "utils/user";

import { APP_TYPES } from "../constants";
import blurStyle from "../utils/blur.scss";
import styles from "./certification.scss";

@connect(({ auth }) => ({
  organizations: auth.organizations,
  currentUser: auth.profile,
}))
export class Certification extends Component {
  static propTypes = {
    appType: PropTypes.string.isRequired,

    // from @connect
    organizations: organizationsPropTypes,
    currentUser: profilePropTypes,
  };

  render() {
    const { appType, organizations, currentUser } = this.props;
    const signatoryUser = isSignatoryUser(organizations, currentUser);
    const classNames = signatoryUser
      ? [styles.certification]
      : [styles.certification, blurStyle.blur];

    return (
      <Fragment>
        {!signatoryUser && (
          <InfoBox
            message={trans.APP_EDIT_CERTIFICATION__SIGNATORY_ROLE_WARNING()}
            type="info"
          />
        )}
        <div className={classes(...classNames)}>
          <Header
            className={styles.header}
            uppercase={true}
            bold={true}
            color="secondary"
          >
            {trans.APP_EDIT_CERTIFICATION__SECTION_NAME()}
          </Header>
          <p>{trans.APP_EDIT_CERTIFICATION__HEADER_LINE_1() + " "}</p>
          {appType === APP_TYPES.HTML && (
            <p>
              <b>Vewd Certify</b>
              {` ${trans.APP_EDIT_CERTIFICATION__HEADER_LINE_2()}`}
            </p>
          )}
        </div>
      </Fragment>
    );
  }
}
