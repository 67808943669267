// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import style from "./spinner.scss";

export class Spinner extends Component {
  static propTypes = {
    size: PropTypes.string,
  };

  static defaultProps = {
    size: "medium",
  };

  render() {
    const { size } = this.props;

    return (
      <div
        className={`${style.spinner} ${
          style[size] || Spinner.defaultProps.size
        }`}
      >
        <div />
        <div />
        <div />
      </div>
    );
  }
}
