// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { GrabsonIcon } from "components/icons";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
  Section,
} from "components/layout";
import { api, apiThatThrows } from "containers/Request";
import placeholder from "src/images/avatarDefault.png";
import { trans } from "src/translations";

import { Accordion } from "./Accordion";
import { ChangeGrantsModal } from "./ChangeGrantsModal";
import styles from "./OrganizationMembership.scss";

const MODAL_MODE = {
  CLOSED: "closed",
  CHANGE_ROLE: "change-role",
  ADD_ORGANIZATION: "add-organization",
};

const transform = (orgGrants, organizationsInfo, allRoles) => {
  if (!organizationsInfo) {
    return [];
  }
  return orgGrants.map((org) => ({
    ...org,
    logo: get(
      organizationsInfo.find((orgName) => orgName.id === org.organization),
      "logo"
    ),
    organizationName: get(
      organizationsInfo.find((orgName) => orgName.id === org.organization),
      "name"
    ),
    grants: org.grants.map((grant) => ({
      ...grant,
      roleName: get(
        allRoles.find((role) => role.id === grant.role),
        "name"
      ),
    })),
  }));
};

@connect(null, (dispatch) => ({
  getRoles: async () => {
    const { results } = await dispatch(apiThatThrows.getRoles.action());
    return results;
  },
  getOrganizations: (queryParams) =>
    dispatch(
      api.getOrganizationsPaginated.action({
        queryParams: { limit: "nolimit", ...queryParams },
      })
    ),
}))
export class OrganizationMembership extends Component {
  static propTypes = {
    // helper functions for Formik <FieldArray />
    remove: PropTypes.func,
    replace: PropTypes.func,
    push: PropTypes.func,
    // formik form prop
    form: PropTypes.object,
    name: PropTypes.string,

    getRoles: PropTypes.func.isRequired,
    getOrganizations: PropTypes.func.isRequired,
  };

  state = {
    expandedOrganizations: [],
    modal: MODAL_MODE.CLOSED,
    editedOrg: undefined,
    editedRoles: [],
    allRoles: [],
    organizations: [],
  };

  fetchOrganizations = async (organizationIds) => {
    const { getOrganizations } = this.props;

    if (!organizationIds.length) {
      return;
    }

    const organizationsResponse = await getOrganizations({
      id: organizationIds,
    });
    const organizations = organizationsResponse.result.results;
    this.setState((prevState) => ({
      organizations: [
        ...prevState.organizations,
        ...organizations.map(({ id, name, logo }) => ({
          id,
          name,
          logo,
        })),
      ],
    }));
  };

  async componentDidMount() {
    const {
      getRoles,
      form: {
        values: { roles: organizationGrants },
      },
    } = this.props;

    const organizationIds = organizationGrants.map(
      (orgGrant) => orgGrant.organization
    );
    await this.fetchOrganizations(organizationIds);
    const allRoles = await getRoles();
    this.setState({
      allRoles,
    });
  }

  toggleExpand = (id) => {
    const { expandedOrganizations } = this.state;
    if (expandedOrganizations.includes(id)) {
      this.setState({
        expandedOrganizations: expandedOrganizations.filter(
          (orgId) => orgId !== id
        ),
      });
    } else {
      this.setState({ expandedOrganizations: [...expandedOrganizations, id] });
    }
  };

  toggleModal = (mode, organization, roles) => {
    const { modal } = this.state;
    if (modal !== MODAL_MODE.CLOSED) {
      return this.setState({
        modal: MODAL_MODE.CLOSED,
        editedOrg: undefined,
        editedRoles: undefined,
      });
    }
    return this.setState({
      modal: mode,
      editedOrg: organization,
      editedRoles: roles,
    });
  };

  handleRemove = (index) => {
    const { remove } = this.props;
    remove(index);
  };

  handleSubmit = ({ organization, roles, editedOrganization }) => {
    const {
      push,
      form: { values },
      name,
      replace,
    } = this.props;

    const grants = roles.map((role) => ({ role }));

    if (editedOrganization) {
      const organizations = values[name];
      const index = organizations.findIndex(
        (org) => org.organization === editedOrganization
      );
      replace(index, { organization: editedOrganization, grants });
    } else {
      push({ organization, grants });
      this.fetchOrganizations([organization]);
    }
  };

  render() {
    const {
      form: {
        values: { roles: organizationGrants },
      },
    } = this.props;

    const {
      allRoles,
      organizations,
      expandedOrganizations,
      modal,
      editedOrg,
      editedRoles,
    } = this.state;

    const transformedGrants = transform(
      organizationGrants,
      organizations,
      allRoles
    );

    return (
      <Fragment>
        <Section header={trans.USER_EDIT__ORG_MEMBESHIP()}>
          <Table>
            <TableHeader>
              <TableHeaderColumn />
              <TableHeaderColumn>{trans.ICON()}</TableHeaderColumn>
              <TableHeaderColumn>{trans.ORGANIZATION()}</TableHeaderColumn>
              <TableHeaderColumn />
            </TableHeader>
            <TableBody>
              {transformedGrants.map((org, index) => (
                <Fragment key={org.organization}>
                  <TableRow>
                    <TableRowColumn
                      onClick={() => this.toggleExpand(org.organization)}
                      columnClass={styles.expandColumn}
                    >
                      <GrabsonIcon
                        name={
                          expandedOrganizations.includes(org.organization)
                            ? "arrow-up"
                            : "arrow-down"
                        }
                        size="sm"
                        font="micro"
                      />
                    </TableRowColumn>
                    <TableRowColumn columnClass={styles.logoColumn}>
                      <img
                        src={org.organizationLogo || placeholder}
                        className={styles.logo}
                        alt="organization logo"
                      />
                    </TableRowColumn>
                    <TableRowColumn>{org.organizationName}</TableRowColumn>
                    <TableRowColumn columnClass={styles.deleteColumn}>
                      <GrabsonIcon
                        onClick={() => this.handleRemove(index)}
                        name="close-filled"
                        size="lg"
                        className={styles.removeIcon}
                      />
                    </TableRowColumn>
                  </TableRow>
                  <Accordion
                    isExpanded={expandedOrganizations.includes(
                      org.organization
                    )}
                  >
                    {org.grants.map((grant) => (
                      <TableRow
                        key={grant.roleId || grant.roleName}
                        className={styles.roleRow}
                      >
                        <TableRowColumn columnClass={styles.expandColumn} />
                        <TableRowColumn columnClass={styles.logoColumn} />
                        <TableRowColumn>{grant.roleName}</TableRowColumn>
                        <TableRowColumn columnClass={styles.deleteColumn} />
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableRowColumn colspan={4}>
                        <Button
                          type="normal"
                          className={styles.changeRoleButton}
                          onClick={() =>
                            this.toggleModal(
                              MODAL_MODE.CHANGE_ROLE,
                              org.organization,
                              org.grants.map((grant) => grant.role)
                            )
                          }
                          dataTestId="change-role"
                        >
                          {trans.USER_EDIT__CHANGE_ROLE()}
                        </Button>
                      </TableRowColumn>
                    </TableRow>
                  </Accordion>
                </Fragment>
              ))}
            </TableBody>
          </Table>
          <Button
            type="normal"
            onClick={() => this.toggleModal(MODAL_MODE.ADD_ORGANIZATION)}
            dataTestId="add-organization"
            className={styles.addOrganizationButton}
          >
            {trans.USER_EDIT__ADD_ORGANIZATION()}
          </Button>
        </Section>
        {modal !== MODAL_MODE.CLOSED && (
          <ChangeGrantsModal
            assignedOrganizations={transformedGrants.map(
              (org) => org.organization
            )}
            editedOrganization={editedOrg}
            allRoles={allRoles}
            selectedRoles={editedRoles}
            isOpen={modal !== MODAL_MODE.CLOSED}
            onClose={this.toggleModal}
            onSave={this.handleSubmit}
          />
        )}
      </Fragment>
    );
  }
}
