// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { ButtonsWrapper, Button } from "components/buttons";
import { ValidationBadge } from "components/form";
import {
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

import styles from "./ProfilesTable.scss";

export class ProfilesTable extends PureComponent {
  static propTypes = {
    profiles: PropTypes.arrayOf(PropTypes.object),
    editProfile: PropTypes.func.isRequired,
    deleteProfile: PropTypes.func.isRequired,
    generalError: PropTypes.string,
    error: PropTypes.string,
  };

  renderNoProfile() {
    return (
      <TableRow>
        <TableRowColumn colspan={2}>
          {trans.APP_EDIT__PROFILE_NO_PROFILE()}
        </TableRowColumn>
      </TableRow>
    );
  }

  renderProfile(profile, index) {
    const { editProfile, deleteProfile } = this.props;

    return (
      <TableRow key={`${profile.name}-${index}`}>
        <TableRowColumnExtended
          onClick={() => editProfile(index)}
          to={"#"}
          text={profile.name}
        />
        <TableRowColumn>
          <ActionMenu buttonSize={"small"}>
            <MenuItem onClick={() => editProfile(index)}>
              {trans.EDIT()}
            </MenuItem>

            <MenuItem onClick={() => deleteProfile(index)}>
              {trans.DELETE()}
            </MenuItem>
          </ActionMenu>
        </TableRowColumn>
      </TableRow>
    );
  }

  render() {
    const { profiles, editProfile, generalError, error } = this.props;

    return (
      <Fragment>
        <Table>
          <TableHeader>
            <TableHeaderColumn>
              {trans.APP_EDIT__PROFILE_TABLE_HEADER()}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.actionColumn} />
          </TableHeader>

          <TableBody>
            {profiles.length === 0 && this.renderNoProfile()}
            {profiles.map((profile, index) =>
              this.renderProfile(profile, index)
            )}
          </TableBody>
        </Table>

        <ButtonsWrapper position={"center"}>
          <Button onClick={() => editProfile(null)}>
            {trans.APP_EDIT__PROFILE_ACTION_ADD_PROFILE()}
          </Button>
        </ButtonsWrapper>

        {generalError && <ValidationBadge error={generalError} touched />}
        {error && (
          <ValidationBadge error={trans.APP_EDIT__PROFILE_ERROR()} touched />
        )}
      </Fragment>
    );
  }
}
