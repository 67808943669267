// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { Modal } from "components/layout";
import { Header } from "components/typography";
import { api } from "containers/Request";
import { trans } from "src/translations";
import { createBody } from "utils/jsonApi";

@connect(null, (dispatch) => ({
  rejectForCertification: (id, body) =>
    dispatch(api.patchQASummary.action({ params: { id }, options: { body } })),
}))
export class RejectModal extends Component {
  static propTypes = {
    qaSummariesId: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    refreshData: PropTypes.func,
    isForExaminationOnly: PropTypes.bool.isRequired,

    // from @connect
    rejectForCertification: PropTypes.func,
  };

  state = {
    submitting: false,
    error: null,
  };

  handleReject = async () => {
    const { onClose, refreshData, rejectForCertification, qaSummariesId } =
      this.props;

    // TODO: adapt to work with examination, backend not ready yet
    this.setState({ submitting: true });
    const { error } = await rejectForCertification(
      qaSummariesId,
      createBody({
        type: "QASummary",
        accepted: false,
        id: qaSummariesId,
      })
    );
    this.setState({ submitting: false });
    if (error) {
      this.setState({ error: error.message });
      return;
    }
    onClose();
    refreshData();
  };

  render() {
    const { isOpen, onClose, isForExaminationOnly } = this.props;
    const { submitting, error } = this.state;

    let title =
      trans.APP_DETAILS_CERTIFICATION__ACP_REJECT_CERTIFICATION_MODAL_TITLE();
    let content =
      trans.APP_DETAILS_CERTIFICATION__ACP_REJECT_CERTIFICATION_MODAL_CONTENT();
    if (isForExaminationOnly) {
      title =
        trans.APP_DETAILS_CERTIFICATION__ACP_REJECT_EXAMINATION_MODAL_TITLE();
      content =
        trans.APP_DETAILS_CERTIFICATION__ACP_REJECT_EXAMINATION_MODAL_CONTENT();
    }

    return (
      <Modal
        title={title}
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.CANCEL()}
            </Button>
            <Button
              type="red"
              processing={submitting}
              onClick={this.handleReject}
            >
              {trans.REJECT()}
            </Button>
          </ButtonsWrapper>
        }
        size="big"
      >
        <Header type="h5">{content}</Header>
        {error && <Info type="error">{error}</Info>}
      </Modal>
    );
  }
}
