// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import set from "lodash-es/set";

import { trans } from "src/translations";

/**
 * Checks if pointer matches given pointerPattern
 *
 * Example:
 * pointer: "/data/attributes/translations/2"
 * pointerPattern: "/data/attributes/translations/:id"
 * Returns: true
 *
 * @param pointer - JSON pointer returned from server
 * @param pointerPattern - JSON pointer with placeholders
 * @returns {boolean}
 */
export const isMatched = (pointer: string, pointerPattern: string): boolean => {
  let match = true;

  const splittedPointerPattern = pointerPattern.split("/");
  const splittedPointer = pointer.split("/");

  if (splittedPointerPattern.length !== splittedPointer.length) {
    return false;
  }

  for (let idx = 0; idx < splittedPointerPattern.length; idx++) {
    const item = splittedPointerPattern[idx];

    if (!item.startsWith(":")) {
      if (item !== splittedPointer[idx]) {
        match = false;
        break;
      }
    }
  }

  return match;
};

/**
 * Replaces placeholders in given fieldPattern using values from pointer.
 *
 * Example:
 * pointer: "/data/attributes/translations/2"
 * pointerPattern: "/data/attributes/translations/:id"
 * fieldPattern: "metadata.translations[:id]"
 * Returns: "metadata.translations[2]"
 *
 * @param pointer - JSON pointer returned from server
 * @param pointerPattern - JSON pointer with placeholders
 * @param fieldPattern - field name with placeholders
 * @returns {String} - field name
 */
export const replacePlaceholdersInFieldPattern = (
  pointer: string,
  pointerPattern: string,
  fieldPattern: string
): string => {
  const splittedPointerPattern = pointerPattern.split("/");
  const splittedPointer = pointer.split("/");

  let result = fieldPattern;

  splittedPointerPattern.forEach((item, idx) => {
    if (item.startsWith(":")) {
      result = result.replace(item, splittedPointer[idx]);
    }
  });

  return result;
};

/**
 * Returns field name for given JSON pointer using relations defined in jsonPointerToFieldName map.
 * Returned field name is in a format required by redux-form's (v5) error handler.
 *
 * Example:
 * pointer: "/data/attributes/translations/2"
 * jsonPointerToFieldName: { "/data/attributes/translations/:id": "metadata.translations[:id]"}
 * Returns: "metadata.translations[2]"
 *
 * @param pointer - JSON pointer returned from server
 * @param jsonPointerToFieldName - object consisting of pointerPattern:fieldPattern pairs
 * @returns Field name for given pointer, if exists.
 */
export const findFieldForPointer = (
  pointer: string,
  jsonPointerToFieldName: Record<string, string>
): string | undefined => {
  let result;

  for (const [pointerPattern, fieldPattern] of Object.entries(
    jsonPointerToFieldName
  )) {
    if (isMatched(pointer, pointerPattern)) {
      result = replacePlaceholdersInFieldPattern(
        pointer,
        pointerPattern,
        fieldPattern
      );
      break;
    }
  }

  return result;
};

/**
 * Returns error translation
 * @param {String} pointer -  value with error pointer information
 * @returns {String|null} - error translation
 */
const translateError = (pointer: string): string | undefined => {
  switch (true) {
    case pointer.includes("deep_link_url"):
      /**
       * This error is returned as currently backend won't return error with
       * source pointer that carries information for which app tab issue occured
       * CS-1961 awaits to be implemented
       */
      return trans.PROMOTION_DETAILS__CONTENT_ITEM_DEEPLINK_ERROR_GENERIC();
    default:
      return undefined;
  }
};

/**
 * Returns errors in the form accepted by redux-form
 * @param {Object} err {message: string, serverErrors: Array}
 * @param {Object} jsonPointerToFieldName - e.g. { "/data/attributes/translations/:id": "translations[:id]" }
 * @returns {Object}
 */
export const prepareErrorsForForm = (
  err: { message: string; serverErrors: Array<any> },
  jsonPointerToFieldName: Record<string, string> = {}
): Record<string, any> => {
  const fieldErrors: Record<string, any> = {};

  const serverErrors = get(err, "serverErrors", []);

  if (serverErrors.length) {
    serverErrors.forEach((e: any) => {
      if (typeof e.source === "undefined") {
        return;
      }

      let formFieldName: string | undefined =
        jsonPointerToFieldName[e.source.pointer];

      // if this is a pointer with params
      if (!formFieldName) {
        formFieldName = findFieldForPointer(
          e.source.pointer,
          jsonPointerToFieldName
        );
      }

      if (formFieldName) {
        set(fieldErrors, formFieldName, e.detail);
      } else {
        fieldErrors["_error"] =
          translateError(e.source.pointer) ??
          trans.DEFAULT_FORM_SUBMIT_ERROR_MESSAGE();
        console.error(
          trans.ERROR__UNDEFINED_FIELD_FROM_BACKEND({
            fieldName: e.source.pointer,
          })
        );
      }
    });
  }

  if (!isEmpty(fieldErrors)) {
    return fieldErrors;
  }
  return {
    _error: err.message,
  };
};

/**
 * Alias for reduxForm usages
 */
export const prepareErrorsForReduxForm = prepareErrorsForForm;
