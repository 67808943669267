// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { withCountries } from "utils/continents";
import {
  withQueryParams,
  withRouter,
  withSearch,
  withSorting,
} from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors/gqlErrors";
import { queryResultType, withQuery } from "utils/graphql";
import { parseLocationToLimitOffset } from "utils/pagination";
import { createLocationWithSearch } from "utils/url";

import { DefaultSortContext } from "../../_shared/Context/defaultSortContext";
import {
  getDeviceListErrorMessage,
  ITEMS_PER_PAGE,
  TABS_CONFIG,
} from "../_shared/DeviceInventoryTable";
import { ALERT_STATES } from "../DeviceDetails/constants";
import {
  DEVICE_INVENTORY_ALERTS_PATH,
  DEVICE_INVENTORY_DEFAULT_PATH,
  DEVICE_INVENTORY_DISMISSED_PATH,
  DeviceInventoryRend,
} from "./DeviceInventoryRend";
import { getDeviceInventoryQuery } from "./gql/getDeviceInventoryQuery";
import { getDeviceSearchSuggestionsQuery } from "./gql/getDeviceSearchSuggestionsQuery";
import { getDevicesWithActiveAlertCountQuery } from "./gql/getDevicesWithActiveAlertCountQuery";
import { getDevicesWithAlertQuery } from "./gql/getDevicesWithAlertQuery";
import {
  adaptQueryParamsToGqlSearchArg,
  convertDeviceSearchSuggestionsForSearchBar,
} from "./utils";

@restrictedArea(() => ({
  allowed: [ROLES.deviceInventory.deviceManager],
}))
@withQueryParams
@withRouter
@withActiveOrganization
@withQuery({
  name: "deviceInventory",
  query: getDeviceInventoryQuery,
})
@withQuery({
  name: "deviceSearchSuggestions",
  query: getDeviceSearchSuggestionsQuery,
})
@withQuery({
  name: "devicesWithAlert",
  query: getDevicesWithAlertQuery,
})
@withQuery({
  name: "devicesWithActiveAlertCount",
  query: getDevicesWithActiveAlertCountQuery,
})
@withCountries
@withSorting({
  defaultSortBy: "last_activity_date",
  allowedSortByValues: TABS_CONFIG[0].columns
    .map(({ sortBy }) => sortBy)
    .filter((column) => Boolean(column)),
  defaultSortOrder: "DESC",
})
@withSearch
export class DeviceInventoryData extends PureComponent {
  static propTypes = {
    // from @withQueryParams
    queryParams: PropTypes.object,

    // from @withRouter
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withSearch
    searchValue: PropTypes.string,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withQuery
    deviceInventoryQuery: PropTypes.func,
    deviceInventoryQueryStatus: queryResultType,
    devicesWithAlertQuery: PropTypes.func,
    devicesWithAlertQueryStatus: queryResultType,
    deviceSearchSuggestionsQuery: PropTypes.func,
    deviceSearchSuggestionsQueryStatus: queryResultType,
    devicesWithActiveAlertCountQuery: PropTypes.func,
    devicesWithActiveAlertCountQueryStatus: queryResultType,

    // from @withCountries
    countriesLoading: PropTypes.bool.isRequired,
    countriesError: PropTypes.object,
    allCountries: PropTypes.array.isRequired,

    // from @withSorting
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.fetchDeviceSearchSuggestions();
    this.fetchDevicesWithActiveAlertCount();
    this.fetchDevicesBasedOnTab();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location === prevProps.location &&
      this.props.activeOrganization.id === prevProps.activeOrganization.id
    ) {
      return;
    }
    if (this.props.activeOrganization.id !== prevProps.activeOrganization.id) {
      this.fetchDevicesWithActiveAlertCount();
    }
    this.fetchDevicesBasedOnTab();
  }

  fetchDevicesBasedOnTab = () => {
    if (this.props.location.pathname === DEVICE_INVENTORY_DISMISSED_PATH) {
      this.fetchDevicesWithAlert(ALERT_STATES.DISMISSED);
      return;
    }
    if (this.props.location.pathname === DEVICE_INVENTORY_ALERTS_PATH) {
      this.fetchDevicesWithAlert(ALERT_STATES.ACTIVE);
      return;
    }
    this.fetchDeviceInventory();
  };

  fetchDeviceInventory = () => {
    const { deviceInventoryQuery, queryParams, location, sortBy, sortOrder } =
      this.props;

    deviceInventoryQuery({
      variables: {
        pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
        sort: {
          name: sortBy.toUpperCase(),
          order: sortOrder,
        },
        search: adaptQueryParamsToGqlSearchArg(queryParams),
      },
    });
  };

  fetchDevicesWithActiveAlertCount = () => {
    const { devicesWithActiveAlertCountQuery } = this.props;

    devicesWithActiveAlertCountQuery();
  };

  fetchDeviceSearchSuggestions = () => {
    const { deviceSearchSuggestionsQuery } = this.props;
    deviceSearchSuggestionsQuery();
  };

  fetchDevicesWithAlert = (alertState) => {
    const { devicesWithAlertQuery, searchValue, location, sortBy, sortOrder } =
      this.props;

    devicesWithAlertQuery({
      variables: {
        alertState,
        pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
        sort: {
          name: sortBy.toUpperCase(),
          order: sortOrder,
        },
        search: { query: searchValue || "" },
      },
    });
  };

  getDeviceSearchSuggestionsData = () => {
    const { deviceSearchSuggestionsQueryStatus: status } = this.props;
    return {
      ...status,
      data: convertDeviceSearchSuggestionsForSearchBar(status.data),
      error: status.error && getGqlErrorMessage(status.error),
    };
  };

  getDeviceInventoryData = () => {
    const { data, error, loading } = this.props.deviceInventoryQueryStatus;

    return {
      error: getDeviceListErrorMessage(error),
      loading,
      count: get(data, "devices.devices.pagination.count", 0),
      results: get(data, "devices.devices.items", []),
    };
  };

  getDevicesWithAlertData = () => {
    const { data, error, loading } = this.props.devicesWithAlertQueryStatus;

    return {
      error: getDeviceListErrorMessage(error),
      loading,
      count: get(data, "devicesWithAlert.devices.pagination.count", 0),
      results: get(data, "devicesWithAlert.devices.items", []),
    };
  };

  getDevicesWithActiveAlertCountData = () => {
    const { data } = this.props.devicesWithActiveAlertCountQueryStatus;

    return {
      count: get(data, "devicesWithActiveAlertCount.count", undefined),
    };
  };

  onFiltersChange = (filters) => {
    const { location, navigate } = this.props;

    const newLocation = createLocationWithSearch(location, filters);
    navigate(newLocation);
  };

  onPdidSearch = (pdid) => {
    const { location, navigate } = this.props;
    const newLocation = createLocationWithSearch(location, { search: pdid });
    navigate(newLocation);
  };

  renderDeviceInventory = (devicesWithActiveAlertCount) => {
    const {
      queryParams,
      countriesError,
      countriesLoading,
      allCountries,
      location,
    } = this.props;
    const availableFilters = this.getDeviceSearchSuggestionsData();
    const { error, loading, count, results } = this.getDeviceInventoryData();

    if (availableFilters.error) {
      return <Info type="error">{availableFilters.error}</Info>;
    }

    if (availableFilters.loading || availableFilters.data === undefined) {
      return <Loader />;
    }

    return (
      <DefaultSortContext.Provider
        value={{
          sortBy: this.props.sortBy,
          sortOrder: this.props.sortOrder,
        }}
      >
        <DeviceInventoryRend
          handleFiltersChange={this.onFiltersChange}
          results={results}
          filters={queryParams}
          path={location.pathname}
          countries={{
            error: Boolean(countriesError),
            loading: countriesLoading,
            allCountries,
          }}
          loading={loading}
          count={count}
          devicesWithAlertCount={devicesWithActiveAlertCount}
          fetchDevicesWithActiveAlertCount={
            this.fetchDevicesWithActiveAlertCount
          }
          error={error}
          availableFilters={availableFilters.data}
          queryParams={queryParams}
        />
      </DefaultSortContext.Provider>
    );
  };

  renderDeviceInventoryWithAlerts = (devicesWithActiveAlertCount) => {
    const {
      queryParams,
      location,
      searchValue,
      countriesError,
      countriesLoading,
      allCountries,
    } = this.props;
    const { results, loading, count, error } = this.getDevicesWithAlertData();

    return (
      <DefaultSortContext.Provider
        value={{
          sortBy: this.props.sortBy,
          sortOrder: this.props.sortOrder,
        }}
      >
        <DeviceInventoryRend
          handleFiltersChange={this.onPdidSearch}
          searchValue={searchValue}
          results={results}
          filters={queryParams}
          path={location.pathname}
          countries={{
            error: Boolean(countriesError),
            loading: countriesLoading,
            allCountries,
          }}
          loading={loading}
          count={count}
          devicesWithAlertCount={devicesWithActiveAlertCount}
          fetchDevicesWithActiveAlertCount={
            this.fetchDevicesWithActiveAlertCount
          }
          error={error}
        />
      </DefaultSortContext.Provider>
    );
  };

  render() {
    const { count: devicesWithActiveAlertCount } =
      this.getDevicesWithActiveAlertCountData();

    if (this.props.location.pathname === DEVICE_INVENTORY_DEFAULT_PATH) {
      return this.renderDeviceInventory(devicesWithActiveAlertCount);
    }
    return this.renderDeviceInventoryWithAlerts(devicesWithActiveAlertCount);
  }
}
