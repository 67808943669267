// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import { urlToBase64 } from "utils/converters";
import { initialFile } from "utils/formik/utils";

import { ACTION_VALUES, ACTIONS_FIXED } from "../constants";

export const adaptActionValueToBackend = (selectedAction, customActionName) => {
  if (selectedAction == null) {
    return null;
  }

  if (selectedAction === ACTION_VALUES.CUSTOM) {
    return customActionName;
  }

  return selectedAction;
};

export const adaptActionToUI = (backendActionStr) => {
  if (backendActionStr == null) {
    return undefined;
  }

  const isCustomAction = !ACTIONS_FIXED.some((i) => i === backendActionStr);

  if (isCustomAction) {
    return {
      action: ACTION_VALUES.CUSTOM,
      actionCustomName: backendActionStr,
    };
  }

  return {
    action: backendActionStr,
  };
};

const prepareContentItemToBackend = async (contentItem) => {
  const thumbnailPromise = urlToBase64(
    get(contentItem, "videoUrlThumbnail.url", "")
  );
  const fullPageLandscapePromise = urlToBase64(
    get(contentItem, "fullPageLandscape.url", "")
  );

  return {
    asset_type: contentItem.contentType,
    video_url: contentItem.videoUrl || "",
    video_thumbnail: await thumbnailPromise,
    full_page_landscape: await fullPageLandscapePromise,
    deep_link_url: contentItem.deepLinkUrl || "",
    title: contentItem.customTitle || "",
    description: contentItem.customDescription || "",
  };
};

export const adaptContentItemsToBackend = async (contentItems = []) => {
  return await Promise.all(
    contentItems.map((contentItem) => prepareContentItemToBackend(contentItem))
  );
};

export const adaptContentItemsToUI = (assets) => {
  return assets.map((asset, index) => ({
    id: `content-item-${index}`,
    contentType: asset.asset_type,
    videoUrl: asset.video_url,
    videoUrlThumbnail: initialFile(asset.video_thumbnail, "videoUrlThumbnail"),
    fullPageLandscape: initialFile(
      asset.full_page_landscape,
      "videoUrlThumbnail"
    ),
    deepLinkUrl: asset.deep_link_url,
    customTitle: asset.title,
    customDescription: asset.description,
  }));
};
