// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import availability from "./availabilitySection";
import mainMarkets from "./mainMarketsSection";
import monetization from "./monetizationSection";

export default {
  name: trans.APP_EDIT_MARKETS__TAB(),
  url: "markets-and-monetization",
  sections: [mainMarkets, availability, monetization],
  dataTestId: "markets-tab",
};
