// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { unauthorize } from "containers/Auth/actions";
import { activeOrganizationIdSelector } from "containers/Auth/selectors";
import { createError } from "containers/Request/error";
import { dataFormatter } from "utils/jsonApi";
import { isEmpty } from "utils/object";
import { pathToUrl, addQueryParamsToUrl } from "utils/url";

import * as actions from "./actionTypes";
import {
  HTTP_STATUS,
  METHOD_NAMES,
  API_URLS_NOT_SUPPORTING_JSON_API,
} from "./constants";
import { getKeyInStore } from "./getKeyInStore";
import { getRequestState, getResult } from "./selectors";

const doRequest = (keyInStore) => ({
  type: actions.REQUEST,
  keyInStore,
});

const getFromCache = (keyInStore) => ({
  type: actions.CACHE,
  keyInStore,
});

const success = (keyInStore, result) => ({
  type: actions.REQUEST_SUCCESS,
  keyInStore,
  result,
});

const successCache = (keyInStore, result) => ({
  type: actions.CACHE_SUCCESS,
  keyInStore,
  result,
});

const failure = (keyInStore, error) => ({
  type: actions.REQUEST_FAILURE,
  keyInStore,
  error,
});

const supportsJSONApi = (url) => {
  // Api that include v1 do not support JSON API
  const isOldAPI = API_URLS_NOT_SUPPORTING_JSON_API.some((oldApiUrl) =>
    url.startsWith(oldApiUrl)
  );
  return !isOldAPI;
};

/**
 * Makes HTTP requests.
 * Writes results to the redux store.
 *
 * @param {String} methodName - One of METHOD_NAMES ("GET", "POST", ...)
 * @param {Function} methodFunc - One of the functions defined in requests.js
 * @param {String} path - path with placeholders, e.g. /devices/:id
 * @param {Object} params - params needed to create URL from the path, e.g. { id: 1 }
 * @param {Object} queryParams - query params needed to create URL with query string, e.g. { page: 1, owner: [1,2,3] }
 * @param {Boolean} cache - if true, results returned from cache if available
 * @param {Object} options - fetch options
 * @param {Boolean} throwsErrors - if true, function throws errors instead of returning error object
 * @param {Object} abortSignal - signal from an instance of AbortController
 *
 * @returns {Function} An action creator returning a function
 */
export const request =
  (
    methodName,
    methodFunc,
    path,
    params = {},
    queryParams = {},
    cache = false,
    options = {},
    throwsErrors,
    abortSignal
  ) =>
  async (dispatch, getState) => {
    let url = pathToUrl(path, params);
    url = addQueryParamsToUrl(url, queryParams);
    const keyInStore = getKeyInStore(methodName, url);

    const requestState = getRequestState(
      getState(),
      methodName,
      path,
      params,
      queryParams
    );

    const isGet = methodName === METHOD_NAMES.GET;

    if (cache && !isGet) {
      console.warn(
        `Caching works only for GET methods (keyInStore: ${keyInStore})`
      );
    }

    // Return cached value for GET requests when possible
    if (cache && isGet && requestState && !isEmpty(requestState.result)) {
      dispatch(getFromCache(keyInStore));
      dispatch(successCache(keyInStore, requestState.result));

      const result = getResult(
        getState(),
        { methodName, path },
        params,
        queryParams
      );

      // todo: temporary 'hybrid' solution - read more here YGG-4112
      if (throwsErrors) {
        return result;
      } else {
        return {
          result,
          error: null,
        };
      }
    }

    dispatch(doRequest(keyInStore));

    let result = {};

    try {
      const activeOrganizationId = activeOrganizationIdSelector(getState());
      const response = await methodFunc({
        activeOrganizationId,
        url,
        abortSignal,
        options,
      });
      if (!response.ok) {
        if (response.status === HTTP_STATUS.UNAUTHORIZED_401) {
          dispatch(unauthorize());
          throw { error: response.statusText, responseStatus: response.status };
        }

        if (
          response.status === HTTP_STATUS.INTERNAL_SERVER_ERROR_500 ||
          response.status === HTTP_STATUS.NOT_FOUND_404
        ) {
          throw { error: response.statusText, responseStatus: response.status };
        }

        const result = await response.json();
        throw { error: result.errors, responseStatus: response.status };
      }

      if (response.status !== HTTP_STATUS.NO_CONTENT_204) {
        result = await response.json();
      }

      if (supportsJSONApi(path)) {
        result = {
          results: dataFormatter.deserialize(result),
          meta: result.meta,
        };
      }
    } catch (err) {
      console.error(err);
      const error = createError(
        err.error ? err.error : err,
        err.responseStatus
      );
      dispatch(failure(keyInStore, error));

      // todo: temporary 'hybrid' solution - read more here YGG-4112
      if (throwsErrors) {
        throw error;
      }

      return {
        result: null,
        error: error,
      };
    }

    dispatch(success(keyInStore, result));

    // todo: temporary 'hybrid' solution - read more here YGG-4112
    if (throwsErrors) {
      return result;
    } else {
      return { result, error: null };
    }
  };
