// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import cloneDeep from "lodash-es/cloneDeep";
import get from "lodash-es/get";
import set from "lodash-es/set";

import * as actions from "./actionTypes";
import { ID_PREFIX } from "./constants";

const defaultState = {};

const allModelsSelected = (state, action) => {
  const models = get(
    state,
    `${ID_PREFIX}${action.distributorId}.families.${ID_PREFIX}${action.familyId}.models`
  );

  if (!models) {
    return false;
  }

  const modelsCount = Object.keys(models).length;
  const modelsSelectedCount = Object.values(models).filter(
    (m) => m.isSelected
  ).length;

  return modelsCount === modelsSelectedCount;
};

const allFamiliesSelected = (state, action) => {
  const families = get(state, `${ID_PREFIX}${action.distributorId}.families`);

  if (!families) {
    return false;
  }

  const familiesCount = Object.keys(families).length;
  const familiesSelectedCount = Object.values(families).filter(
    (m) => m.isSelected
  ).length;

  return familiesCount === familiesSelectedCount;
};

export const selectedTargets = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_TARGETS: {
      const newState = cloneDeep(state);

      action.results.forEach((distributor) => {
        set(newState, `${ID_PREFIX}${distributor.id}`, {
          name: distributor.name,
          isSelected: Boolean(
            get(state, `${ID_PREFIX}${distributor.id}.isSelected`)
          ),
        });

        distributor.families.forEach((family) => {
          set(
            newState,
            `${ID_PREFIX}${distributor.id}.families.${ID_PREFIX}${family.id}`,
            {
              name: family.name,
              isSelected: Boolean(
                get(
                  state,
                  `${ID_PREFIX}${distributor.id}.families.${ID_PREFIX}${family.id}.isSelected`
                )
              ),
            }
          );

          family.devices.forEach((model) => {
            set(
              newState,
              `${ID_PREFIX}${distributor.id}.families.${ID_PREFIX}${family.id}.models.${ID_PREFIX}${model.id}`,
              {
                name: model.name,
                isSelected: Boolean(
                  get(
                    state,
                    `${ID_PREFIX}${distributor.id}.families.${ID_PREFIX}${family.id}.models.${ID_PREFIX}${model.id}.isSelected`
                  )
                ),
              }
            );
          });
        });
      });

      return newState;
    }
    case actions.TOGGLE_DEVICE_MODEL: {
      const newState = cloneDeep(state);

      // toggle model
      const prevModelIsSelected = get(
        state,
        `${ID_PREFIX}${action.distributorId}.families.${ID_PREFIX}${action.familyId}.models.${ID_PREFIX}${action.modelId}.isSelected`
      );
      set(
        newState,
        `${ID_PREFIX}${action.distributorId}.families.${ID_PREFIX}${action.familyId}.models.${ID_PREFIX}${action.modelId}.isSelected`,
        !prevModelIsSelected
      );

      // toggle family if needed
      const familyIsSelected = allModelsSelected(newState, action);
      set(
        newState,
        `${ID_PREFIX}${action.distributorId}.families.${ID_PREFIX}${action.familyId}.isSelected`,
        familyIsSelected
      );

      // toggle distributor if needed
      const distributorIsSelected = allFamiliesSelected(newState, action);
      set(
        newState,
        `${ID_PREFIX}${action.distributorId}.isSelected`,
        distributorIsSelected
      );

      return newState;
    }
    case actions.TOGGLE_DEVICE_FAMILY: {
      const newState = cloneDeep(state);

      const prevFamilyIsSelected = get(
        state,
        `${ID_PREFIX}${action.distributorId}.families.${ID_PREFIX}${action.familyId}.isSelected`
      );

      // toggle all models
      const models = get(
        newState,
        `${ID_PREFIX}${action.distributorId}.families.${ID_PREFIX}${action.familyId}.models`
      );
      if (models) {
        Object.keys(models).forEach((modelIdWithPrefix) => {
          set(
            newState,
            `${ID_PREFIX}${action.distributorId}.families.${ID_PREFIX}${action.familyId}.models.${modelIdWithPrefix}.isSelected`,
            !prevFamilyIsSelected
          );
        });
      }

      // toggle family
      set(
        newState,
        `${ID_PREFIX}${action.distributorId}.families.${ID_PREFIX}${action.familyId}.isSelected`,
        !prevFamilyIsSelected
      );

      // toggle distributor if needed
      const distributorIsSelected = allFamiliesSelected(newState, action);
      set(
        newState,
        `${ID_PREFIX}${action.distributorId}.isSelected`,
        distributorIsSelected
      );

      return newState;
    }
    case actions.TOGGLE_DISTRIBUTOR: {
      const newState = cloneDeep(state);

      const prevDistributorIsSelected = get(
        state,
        `${ID_PREFIX}${action.distributorId}.isSelected`
      );

      // toggle all families
      const families = get(
        newState,
        `${ID_PREFIX}${action.distributorId}.families`
      );

      if (families) {
        Object.keys(families).forEach((familyIdWithPrefix) => {
          set(
            newState,
            `${ID_PREFIX}${action.distributorId}.families.${familyIdWithPrefix}.isSelected`,
            !prevDistributorIsSelected
          );
        });

        // toggle all models from all families
        Object.keys(families).forEach((familyIdWithPrefix) => {
          const models = get(
            newState,
            `${ID_PREFIX}${action.distributorId}.families.${familyIdWithPrefix}.models`
          );

          if (models) {
            Object.keys(models).forEach((modelIdWithPrefix) => {
              set(
                newState,
                `${ID_PREFIX}${action.distributorId}.families.${familyIdWithPrefix}.models.${modelIdWithPrefix}.isSelected`,
                !prevDistributorIsSelected
              );
            });
          }
        });
      }

      // toggle distributor
      set(
        newState,
        `${ID_PREFIX}${action.distributorId}.isSelected`,
        !prevDistributorIsSelected
      );

      return newState;
    }
    case actions.DESELECT_ALL: {
      return {};
    }
    default:
      return state;
  }
};
