// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Subsection } from "components/layout";
import { APP_TYPE } from "pages/Applications/AppDetails/constants";
import { trans } from "src/translations";

export const CertificationAndDistributionType = ({
  appType,
  isCertifiable,
  isDistributable,
  isForExaminationOnly,
}) => {
  const isNativeOrHbbTv = [APP_TYPE.HBBTV, APP_TYPE.NATIVE].includes(appType);
  const isHtml = appType === APP_TYPE.HTML;

  // TODO: this most likely needs adjustments
  // what should be the default value when none of the conditions is met?
  let distributionType = null;
  if (isNativeOrHbbTv) {
    if (isDistributable) {
      distributionType = trans.APP_DETAILS_RIGHTS__CD_TYPE_CATALOG_DIST();
    } else if (isForExaminationOnly) {
      distributionType = trans.APP_DETAILS_RIGHTS__CD_TYPE_EXAMINE();
    }
  } else if (isHtml) {
    if (isCertifiable && !isDistributable) {
      distributionType =
        trans.APP_DETAILS_RIGHTS__CD_TYPE_CERTIFICATION_PROGRAM();
    } else if (isCertifiable && isDistributable) {
      distributionType = trans.APP_DETAILS_RIGHTS__CD_TYPE_CATALOG();
    } else if (isForExaminationOnly) {
      distributionType = trans.APP_DETAILS_RIGHTS__CD_TYPE_EXAMINE();
    }
  }

  if (distributionType === null) {
    return null;
  }

  return (
    <Subsection header={trans.APP_DETAILS_RIGHTS__CD_TYPE_HEADER()}>
      {distributionType}
    </Subsection>
  );
};

CertificationAndDistributionType.propTypes = {
  appType: PropTypes.string.isRequired,
  isCertifiable: PropTypes.bool.isRequired,
  isDistributable: PropTypes.bool.isRequired,
  isForExaminationOnly: PropTypes.bool.isRequired,
};
