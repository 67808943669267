// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField } from "formik";
import PropTypes from "prop-types";

import { TextField, RadioGroupField } from "components/form/fields";
import { FIELDS, RADIO_DEFAULT } from "pages/Snap/shared/constants";

export const Keywords = ({ appId }) => {
  const fieldName = `${appId}.${FIELDS.KEYWORDS_USE.name}`;
  const [field] = useField(fieldName);
  return (
    <div>
      <RadioGroupField
        name={field.name}
        label={FIELDS.KEYWORDS_USE.label}
        inline={true}
        values={RADIO_DEFAULT}
        tooltip={FIELDS.KEYWORDS_USE.tooltip}
        dataTestId="keywords-use-field"
        required={false}
      />
      {field?.value === "true" && (
        <TextField
          name={`${appId}.${FIELDS.KEYWORDS_PREFIX.name}`}
          label={FIELDS.KEYWORDS_PREFIX.label}
          dataTestId="keywords-prefix-field"
          required={false}
        />
      )}
    </div>
  );
};

Keywords.propTypes = {
  appId: PropTypes.string.isRequired,
};
