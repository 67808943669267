// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { SubTab, SubTabs } from "components/navigation";
import { trans } from "src/translations";

export const SoftwarePackageVersionTabs = ({
  versions,
  activeVersionId,
  packageId,
}) => {
  if (!Array.isArray(versions) || versions.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <SubTabs
        name={trans.UPDATES__SOFTWARE_PACKAGES__PACKAGE_VERSION_TABS_NAME()}
      >
        {versions.map(({ id, version }) => (
          <SubTab
            key={id}
            active={id === activeVersionId}
            to={`/updates/packages/${packageId}/version/${id}`}
            text={version}
            dataTestId="version-tab"
          />
        ))}
      </SubTabs>
    </Fragment>
  );
};

SoftwarePackageVersionTabs.propTypes = {
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired,
    })
  ).isRequired,

  activeVersionId: PropTypes.string.isRequired,
  packageId: PropTypes.string.isRequired,
};
