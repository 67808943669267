// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Link as RouterLink } from "react-router-dom";

import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./link.scss";

const prepareSafeRelAttribute = (rel) => {
  const clientRelArr = typeof rel === "string" ? rel.split(" ") : [];
  const safeRelArr = ["nofollow", "noopener", "noreferrer"];

  return uniq([...clientRelArr, ...safeRelArr]).join(" ");
};

/** Ensures consistent style for all types of links */
export const Link = (props) => {
  const { children, className, ...rest } = props;

  const isTargetBlank = rest && rest.target && rest.target === "_blank";
  if (isTargetBlank) {
    rest.rel = prepareSafeRelAttribute(rest.rel);
  }

  if (props.onClick !== undefined) {
    return (
      <span className={classes(styles.link, className)} {...rest}>
        {children}
      </span>
    );
  }

  if (props.href !== undefined) {
    return (
      <a className={classes(styles.link, className)} {...rest}>
        {children}
      </a>
    );
  }

  if (rest.to !== undefined) {
    return (
      <RouterLink className={classes(styles.link, className)} {...rest}>
        {children}
      </RouterLink>
    );
  }

  console.error(
    "One of the following props have to be defined: 'onCLick' or 'href' or 'to'"
  );
  return null;
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  /** if provided, component works the same way as the HTML link */
  href: PropTypes.string,
  /** if provided, component works the same way as the Link component from the react-router library */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** if provided, component works the same way as a button */
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Link;
