// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Routes, Navigate, Route } from "react-router-dom";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";
import { trans } from "src/translations";

import { AppDetailsData } from "./AppDetails/AppDetailsData";
import { APP_DETAILS } from "./AppList/ApplicationsTabbedTableConfig";
import { AvailableAppList } from "./AppList/AvailableAppList/AvailableAppList";
import { DistributionAppList } from "./AppList/DistributionAppList/DistributionAppList";
import { ModerationAppList } from "./AppList/ModerationAppList";
import { MyAppList } from "./AppList/MyAppList";
import {
  APP_ROUTE_MY,
  APP_ROUTE_MODERATION,
  APP_ROUTE_DISTRIBUTION,
  APP_ROUTE_AVAILABLE,
} from "./constants";
import { CreateApp } from "./CreateApp/CreateApp";
import { EditAppRoute } from "./EditApp";
import { Status } from "./Status/Status";

export class ApplicationsRoute extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<RedirectDependingOnRoles />} />
        <Route
          exact
          path={`/${APP_ROUTE_MY}`}
          element={
            <Navigate to={`/applications/${APP_ROUTE_MY}/${APP_DETAILS}`} />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_MY}/:tabId`}
          element={
            <ExtendedRoute
              name={trans.APP_LIST_MY__ROUTE_NAME()}
              component={MyAppList}
            />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_MY}/:id/details`}
          element={
            <ExtendedRoute name={trans.DETAILS()} component={AppDetailsData} />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_MY}/:id/details/:tabId`}
          element={
            <ExtendedRoute name={trans.DETAILS()} component={AppDetailsData} />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_MODERATION}`}
          element={
            <Navigate
              to={`/applications/${APP_ROUTE_MODERATION}/${APP_DETAILS}`}
            />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_MODERATION}/:tabId`}
          element={
            <ExtendedRoute
              name={trans.APP_LIST_MODERATION__ROUTE_NAME()}
              component={ModerationAppList}
            />
          }
        />
        {/* React router v6 does not support optional dynamic Url parameter
         *
         * https://github.com/remix-run/react-router/discussions/9208
         *
         *
         */}
        <Route
          exact
          path={`/${APP_ROUTE_MODERATION}/:id/details`}
          element={
            <ExtendedRoute name={trans.DETAILS()} component={AppDetailsData} />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_MODERATION}/:id/details/:tabId`}
          element={
            <ExtendedRoute name={trans.DETAILS()} component={AppDetailsData} />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_DISTRIBUTION}`}
          element={
            <ExtendedRoute
              name={trans.APP_LIST_DISTRIBUTION__ROUTE_NAME()}
              component={DistributionAppList}
            />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_DISTRIBUTION}/:id/details`}
          element={
            <ExtendedRoute name={trans.DETAILS()} component={AppDetailsData} />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_DISTRIBUTION}/:id/details/:tabId`}
          element={
            <ExtendedRoute name={trans.DETAILS()} component={AppDetailsData} />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_AVAILABLE}`}
          element={
            <ExtendedRoute
              name={trans.APP_LIST_AVAILABLE__ROUTE_NAME()}
              component={AvailableAppList}
            />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_AVAILABLE}/:id/details`}
          element={
            <ExtendedRoute name={trans.DETAILS()} component={AppDetailsData} />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_AVAILABLE}/:id/details/:tabId`}
          element={
            <ExtendedRoute name={trans.DETAILS()} component={AppDetailsData} />
          }
        />
        <Route
          exact
          path="/create"
          element={
            <ExtendedRoute name={trans.CREATE()} component={CreateApp} />
          }
        />
        <Route
          exact
          path={`/${APP_ROUTE_MY}/:id/status`}
          element={<ExtendedRoute name={trans.STATUS()} component={Status} />}
        />
        <Route
          path={`/${APP_ROUTE_MY}/:id/*`}
          element={
            <ExtendedRoute name={trans.EDIT()} component={EditAppRoute} />
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    );
  }
}
