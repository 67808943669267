// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable no-magic-numbers */

const roundToNearest = (numToRound, numToRoundTo) => {
  return Math.round(numToRound / numToRoundTo) * numToRoundTo;
};

const getMilionsString = (number) => {
  const milions = number / 1000000;
  const roundedToFirstDecimal = Math.round(milions * 10) / 10;
  return `${roundedToFirstDecimal}M`;
};

export const roundNumberOfDevices = (number) => {
  if (number < 20) {
    return number.toString();
  }

  if (number < 100) {
    return roundToNearest(number, 10).toString();
  }

  if (number < 1000) {
    return roundToNearest(number, 100).toString();
  }

  if (number < 999500) {
    return `${roundToNearest(number, 1000) / 1000}K`;
  }

  return getMilionsString(number);
};
