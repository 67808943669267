// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Permissions } from "containers/Permissions";

/**
 * HOC version of [`<Permissions>`](#permissions)
 *
 * @example ./Permissions.md
 */
export const permissions = (optionsFunc) => (ComposedComponent) => {
  return class PermissionsWrapper extends Component {
    render() {
      let options = {};
      if (optionsFunc && typeof optionsFunc === "function") {
        options = optionsFunc(this.props);
      }

      return (
        <Permissions {...options}>
          {(isAllowed) => (
            <ComposedComponent isAllowed={isAllowed} {...this.props} />
          )}
        </Permissions>
      );
    }
  };
};
