// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";
import { Link } from "components/typography";
import { APP_ROUTE_DISTRIBUTION } from "pages/Applications/constants";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import styles from "./warningsCell.scss";

export class WarningsCell extends Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
  };

  getDisplayedData() {
    const { app } = this.props;
    if (app.isRejected) {
      return {
        className: styles.rejected,
        text: trans.APP_LIST_DISTRIBUTION__REJECTED(),
        tooltip: trans.APP_LIST_DISTRIBUTION__REJECTED_POPUP(),
      };
    }

    if (app.isOnHold) {
      return {
        className: styles.onHold,
        text: trans.APP_LIST_DISTRIBUTION__ON_HOLD(),
        tooltip: trans.APP_LIST_DISTRIBUTION__ON_HOLD_POPUP(),
      };
    }

    return null;
  }

  render() {
    const { app } = this.props;
    const data = this.getDisplayedData();

    if (!data) {
      return <div className={styles.content}>{NO_VALUE_PLACEHOLDER}</div>;
    }

    return (
      <div className={styles.content}>
        <Tooltip
          alignment="bottom"
          content={
            <div>
              <p>{data.tooltip}</p>
              <p className={styles.moreDetails}>
                {trans.CLICK_FOR_MORE_DETAILS()}
              </p>
            </div>
          }
        >
          <Link
            to={`/applications/${APP_ROUTE_DISTRIBUTION}/${app.id}/details/messages`}
            className={data.className}
          >
            {data.text}
            <GrabsonIcon
              className={data.className}
              name="help-filled"
              size="normal"
            />
          </Link>
        </Tooltip>
      </div>
    );
  }
}
