// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const STATES = {
  idle: {
    addButton: true,
    removeButton: false,
    removeButtonDisabled: false,
    progress: false,
    progressBar: "",
  },
  progress: {
    addButton: false,
    removeButton: true,
    removeButtonDisabled: true,
    progress: true,
    progressBar: "blue",
  },
  uploaded: {
    addButton: false,
    removeButton: true,
    removeButtonDisabled: false,
    progress: false,
    progressBar: "green",
  },
  errorOnUploading: {
    addButton: true,
    removeButton: false,
    removeButtonDisabled: false,
    progress: false,
    progressBar: "",
  },
  errorOnRemoving: {
    addButton: false,
    removeButton: true,
    removeButtonDisabled: false,
    progress: false,
    progressBar: "green",
  },
};
