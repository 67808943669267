// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Avatar } from "components/images";
import { PageHeader, PageContent } from "components/layout";
import { Header } from "components/typography";
import { trans } from "src/translations";

import styles from "./dashboard.scss";
import { NotificationsData } from "./NotificationsData";

@connect(({ auth }) => ({ auth }))
export class Dashboard extends Component {
  static propTypes = {
    auth: PropTypes.object,
  };

  render() {
    const {
      auth: { profile },
    } = this.props;

    return (
      <div>
        <PageHeader>{trans.DASHBOARD__PAGE_HEADER()}</PageHeader>
        <PageContent>
          <div className={styles.welcome} data-test-id="welcome">
            <Avatar
              size="164px"
              src={profile.img}
              className={styles.dashboardAvatar}
            />
            <div className={styles.welcomeMessage}>
              <h1>
                {trans.HELLO()} {profile.name}!
              </h1>
              <p>{trans.DASHBOARD__WELCOME_MESSAGE()}</p>
            </div>
          </div>
          <div className={styles.widgets}>
            <div className={styles.notifications}>
              <Header
                type="h3"
                bold={true}
                uppercase={true}
                color="tertiary"
                className={styles.header}
              >
                {trans.DASHBOARD__NOTIFICATIONS_HEADER()}
              </Header>
              <NotificationsData />
            </div>
            <div className={styles.blogPost}>
              {/*

              YGG-7979 - BlogPost component was removed and it was decided to leave this div empty.

              */}
            </div>
          </div>
        </PageContent>
      </div>
    );
  }
}
