// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./VerticalTabs.scss";

const DEFAULT_SELECTED_INDEX = 0;

export class VerticalTabs extends Component {
  static propTypes = {
    /**
     * Function as a child that renders tab content.
     *
     * Type: (selectedTabIndex: number) => React.Node
     */
    children: PropTypes.func,
    /** Type: (event: Event) => void */
    onAdd: PropTypes.func,
    /*
     * Remove callback. Optional if all tabs have `noRemove` set to `true`.
     *
     * Type: (tabIndex: number, event: Event) => void
     */
    onRemove: PropTypes.func,
    /**
     * Called when tab is changed.
     *
     * Type: (selectedTabIndex: number) => void
     */
    onTabChange: PropTypes.func,
    /** Tabs to display */
    tabNames: PropTypes.arrayOf(
      PropTypes.shape({
        invalid: PropTypes.bool,
        /** Mark tab with special style. Does not preselect the tab */
        default: PropTypes.bool,
        noRemove: PropTypes.bool,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    /** Name of 'add new item' button */
    labelAddNewItem: PropTypes.string,
    /** Index of tab to initialy select */
    // eslint-disable-next-line react/no-unused-prop-types
    initSelectedIndex: PropTypes.number,
    dataTestId: PropTypes.string,
    /** Show or hide the 'add new item' button */
    isAddButtonDisplayed: PropTypes.bool,
  };

  static defaultProps = {
    labelAddNewItem: `+ ${trans.APP_EDIT__ADD_NEW_ITEM()}`,
    onTabChange: Function.prototype,
    dataTestId: "vertical-tabs",
    isAddButtonDisplayed: true,
  };

  constructor(props) {
    super();
    this.state = {
      selectedIndex: get(props, "initSelectedIndex", DEFAULT_SELECTED_INDEX),
    };
  }

  componentDidMount() {
    const { onTabChange } = this.props;
    onTabChange(this.state.selectedIndex);
  }

  componentDidUpdate(prevProps, prevState) {
    const { tabNames, onTabChange } = this.props;

    if (prevProps.tabNames.length !== tabNames.length) {
      this.setState({ selectedIndex: tabNames.length - 1 });
    }

    if (this.state.selectedIndex !== prevState.selectedIndex) {
      onTabChange(this.state.selectedIndex);
    }
  }

  removeTab(e, index) {
    const { onRemove } = this.props;
    const { selectedIndex } = this.state;
    e.stopPropagation();
    onRemove(index, e);
    if (index === selectedIndex) {
      this.setState({ selectedIndex: DEFAULT_SELECTED_INDEX });
    } else if (index < selectedIndex) {
      const index = selectedIndex - 1;
      this.setState({ selectedIndex: index });
    }
  }

  render() {
    const {
      onAdd,
      tabNames,
      labelAddNewItem,
      dataTestId,
      isAddButtonDisplayed,
    } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div className={styles.verticalTabs} data-test-id={dataTestId}>
        <div
          className={styles.verticalTabsMenu}
          data-test-id={`${dataTestId}-menu`}
        >
          <ul>
            {tabNames.map((tabName, index) => {
              const itemTestId = `tab-${index}`;
              return (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onClick={() => {
                    this.setState({ selectedIndex: index });
                  }}
                  className={classes(styles.tab, {
                    [styles.selected]: index === selectedIndex,
                    [styles.invalid]: tabName.invalid,
                    [styles.default]: tabName.default,
                    [styles.noRemove]: tabName.noRemove,
                  })}
                  data-test-id={itemTestId}
                >
                  {!tabName.default ? (
                    <button
                      type="button"
                      className={styles.remove}
                      onClick={(e) => this.removeTab(e, index)}
                      data-test-id={`${itemTestId}-remove`}
                    >
                      <GrabsonIcon name="close" font="micro" />
                    </button>
                  ) : null}
                  <span data-test-id={`${itemTestId}-name`}>
                    {tabName.name}
                  </span>
                </li>
              );
            })}
          </ul>
          {isAddButtonDisplayed && (
            <button
              type="button"
              className={styles.addButton}
              onClick={(e) => {
                onAdd(e);
                this.setState({ selectedIndex: tabNames.length });
              }}
              data-test-id={`${dataTestId}-add-new-button`}
            >
              {labelAddNewItem}
            </button>
          )}
        </div>
        <div className={styles.verticalTabsContent}>
          {this.props.children(selectedIndex)}
        </div>
      </div>
    );
  }
}
