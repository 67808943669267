// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import differenceWith from "lodash-es/differenceWith";
import intersectionWith from "lodash-es/intersectionWith";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { PageContentError } from "components/layout";
import { updateAuthCookie, unauthorize } from "containers/Auth/actions";
import { RestrictedArea } from "containers/Permissions";
import { isAllowedToEditUser } from "containers/Permissions/groups";
import { api } from "containers/Request";
import { ROLES } from "src/containers/Permissions/roles";
import { trans } from "src/translations";
import { urlToBase64 } from "utils/converters";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";

import { withUserViewEdit } from "./_utils";
import { EditUserForm } from "./forms/EditUserForm";

const jsonPointerToFieldName = {
  "/data/attributes/family_name": "family_name",
  "/data/attributes/given_name": "given_name",
  "/data/attributes/email": "email",
  "/data/attributes/avatar": "avatar",
};

const adaptAdminData = (data) => {
  const organization = data.organization;
  const grants = data.grants.map((grant) => ({
    role: grant?.data?.attributes?.role,
    roleId: grant?.data?.id,
  }));
  return { organization, grants };
};

const adaptData = ({ id, role, organization }) => ({ id, role, organization });

const calculateDiff = (oldGrants, newGrants) => {
  const stayingGrants = intersectionWith(
    oldGrants,
    newGrants,
    (a, b) => a.role === b.role
  );
  stayingGrants.push({ role: ROLES.organization.member });

  return {
    removeGrants: differenceWith(
      oldGrants,
      stayingGrants,
      (a, b) => a.role === b.role
    ),

    addGrants: differenceWith(
      newGrants,
      stayingGrants,
      (a, b) => a.role === b.role
    ),
  };
};

const findError = (grants) => {
  if (Array.isArray(grants)) {
    return grants.find(({ error }) => Boolean(error))?.error;
  }

  return grants?.error;
};
function UserEditComp({
  forUserAdmin,
  getUserProfile,
  getUserGrants,
  patchUserProfile,
  currentUserId,
  activeOrganization,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    id,
    avatar: null,
    given_name: "",
    family_name: "",
    grants: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isEditingSelf = currentUserId === id;
  const redirectTo = forUserAdmin ? "/users/admin" : "/users/list";

  const handleUnauthorize = useCallback(
    () => dispatch(unauthorize()),
    [dispatch]
  );

  const handleUpdateAuthCookie = useCallback(
    () => dispatch(updateAuthCookie()),
    [dispatch]
  );

  const removeUserGrant = useCallback(
    (grantId, userId) =>
      dispatch(
        api.removeUserGrant.action({
          params: { userId, grantId },
        })
      ),
    [dispatch]
  );

  const setUserGrantsList = useCallback(
    (userId, body) =>
      dispatch(
        api.setUserGrantsList.action({
          params: { id: userId },
          options: { body },
        })
      ),
    [dispatch]
  );

  const getUserGrantsList = useCallback(
    (userId) =>
      dispatch(api.getUserGrantsList.action({ params: { id: userId } })),
    [dispatch]
  );

  const changeUserGrants = useCallback(
    (userId, body) =>
      dispatch(
        api.changeUserGrants.action({
          params: { userId },
          options: { body },
        })
      ),
    [dispatch]
  );

  const onMembershipRemoval = async () => {
    const memberGrant = user.grants.find(
      (g) => g.role === ROLES.organization.member
    );

    const { error } = await removeUserGrant(memberGrant.id, user.id);
    if (error) {
      setError({ error: error.message });
      return;
    }

    if (isEditingSelf) {
      await handleUnauthorize();
    }

    navigate(redirectTo, {
      state: { message: trans.USER_EDIT__REMOVE_FROM_ORG_SUCCESS() },
    });
  };

  const getGrants = async () => {
    const fetchGrants = forUserAdmin ? getUserGrantsList : getUserGrants;
    const adapter = forUserAdmin ? adaptAdminData : adaptData;

    const { result, error } = await fetchGrants(id);
    return {
      error,
      result: (result?.results || []).map(adapter),
    };
  };

  const updateGrantsAdmin = async (userId, roles) => {
    const body = createBody(
      roles.map((role) => ({
        ...role,
        type: "grants-list",
        grants: role.grants.map((grant) => {
          const { roleId, ...rest } = grant; //fix for YGG-7008
          return {
            data: { type: "grants", attributes: { ...rest } },
          };
        }),
      }))
    );

    return await setUserGrantsList(userId, body);
  };

  const updateGrants = async (userId, roles) => {
    const { removeGrants, addGrants } = calculateDiff(user.grants, roles);

    const requestAddGrant = (role) =>
      changeUserGrants(
        userId,
        createBody({
          type: "grants",
          ...role,
        })
      );
    const requestRemoveGrant = (role) => removeUserGrant(role.id, userId);

    const requests = [
      ...addGrants.map(requestAddGrant),
      ...removeGrants.map(requestRemoveGrant),
    ];
    return await Promise.all(requests);
  };

  const handlePatch = async ({ roles, avatar, ...user }, setErrors) => {
    let avatarImg;

    try {
      avatarImg = await urlToBase64(avatar);
    } catch (error) {
      setErrors({
        _error: trans.USER_EDIT_AVATAR_IMAGE_ERROR({ error: error.message }),
      });
      return;
    }

    const { result, error } = await patchUserProfile(
      id,
      createBody({
        type: "users",
        ...user,
        avatar: avatarImg,
      })
    );
    if (error) {
      const errors = prepareErrorsForForm(error, jsonPointerToFieldName);

      setErrors({ ...errors });
      return;
    }

    if (activeOrganization) {
      const updateGrantsFunc = forUserAdmin ? updateGrantsAdmin : updateGrants;

      const grants = await updateGrantsFunc(result.results.id, roles);
      const error = findError(grants);

      if (error) {
        setErrors({ _error: error.message });
        return;
      }
    }

    if (isEditingSelf) {
      await handleUpdateAuthCookie();
    }

    navigate(redirectTo, { state: { message: trans.CHANGES_SAVE_SUCCESS() } });
  };

  useEffect(() => {
    if (!id || !loading) {
      return;
    }

    setError(null);
    const fetchData = async () => {
      const [userProfileRes, userGrantsRes] = await Promise.all([
        getUserProfile(id),
        getGrants(),
      ]);

      if (userProfileRes.error || userGrantsRes.error) {
        throw (
          userProfileRes.error ||
          userGrantsRes.error ||
          new Error(trans.DEFAULT_REQUEST_ERROR_MESSAGE())
        );
      }

      return {
        ...userProfileRes.result.results,
        grants: userGrantsRes.result,
      };
    };

    fetchData()
      .then((user) => setUser(user))
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, loading]);

  useEffect(() => {
    setLoading(true);
  }, [activeOrganization.id]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <PageContentError error={error} />;
  }

  return (
    <RestrictedArea allowed={[isAllowedToEditUser(user)]} showFallback={true}>
      <EditUserForm
        editUser={user}
        onPatch={handlePatch}
        onMembershipRemoval={onMembershipRemoval}
        forUserAdmin={forUserAdmin}
        activeOrganization={activeOrganization}
        onRefreshData={() => setLoading(true)}
      />
    </RestrictedArea>
  );
}

UserEditComp.propTypes = {
  // from @withUserViewEdit
  getUserProfile: PropTypes.func.isRequired,
  getUserGrants: PropTypes.func.isRequired,
  patchUserProfile: PropTypes.func.isRequired,
  currentUserId: PropTypes.string,
  activeOrganization: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  // from parent
  forUserAdmin: PropTypes.bool,
};

export const UserEdit = withUserViewEdit(UserEditComp);
