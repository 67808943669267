// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { FileButton, Button, ButtonsWrapper } from "components/buttons";
import { Label, ValidationBadge } from "components/form";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";
import { isFormatSupported, validateImage } from "utils/image";

import styles from "../UploaderWithPreview/uploaderWithPreview.scss";

/**
 * Image uploader that also displays image. Cannot preview this component,
 * as it requires `redux` and `redux-form`.
 */
export class UploaderWithPreviewField extends Component {
  static propTypes = {
    /** Name of redux-form field*/
    name: PropTypes.string.isRequired,
    /**
     * Array of MIME types, e.g. ["image/jpeg"]
     */
    formats: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Label string displayed to the user */
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    tooltipAlignment: PropTypes.string,
    required: PropTypes.bool,
    /**
     * Additional information displayed in header's label
     */
    info: PropTypes.string,
    validationImgOptions: PropTypes.shape({
      minWidth: PropTypes.number,
      minHeight: PropTypes.number,
      maxWidth: PropTypes.number,
      maxHeight: PropTypes.number,
      maxSize: PropTypes.number,
      validateAspectRatio: PropTypes.bool,
    }),
  };

  static defaultProps = {
    required: false,
    tooltip: undefined,
    tooltipAlignment: "bottom-start",
    validationImgOptions: {},
  };

  state = {
    validationError: null,
  };

  uploadFile = (name, setFieldValue) => async (ev) => {
    const { formats, validationImgOptions } = this.props;
    const [file] = ev.target.files;

    if (!file) {
      return;
    }

    this.setState({ validationError: null });

    if (!isFormatSupported(file, formats)) {
      setFieldValue(name, null);
      this.setState({
        validationError: trans.INVALID_IMAGE_FORMAT({
          formats: formats.join(", ").replace(/image\//gi, ""),
        }),
      });
      return;
    }
    const blobUrl = URL.createObjectURL(file);

    try {
      await validateImage(blobUrl, validationImgOptions);
      setFieldValue(name, blobUrl);
    } catch (err) {
      setFieldValue(name, null);
      this.setState({
        validationError: err,
      });
    }
  };

  reset = (name, setFieldValue, initialValue) => () => {
    this.setState({ validationError: null });
    setFieldValue(name, initialValue);
  };

  renderField = ({
    field: { value },
    form: { getFieldMeta, setFieldValue },
  }) => {
    const { formats, info, name } = this.props;
    const { validationError } = this.state;
    const { initialValue, error } = getFieldMeta(name);
    const fileChanged = value !== initialValue;

    return (
      <Fragment>
        <img
          src={value || null}
          className={styles.previewImg}
          data-test-id="uploader-preview"
        />
        {info && <p className={styles.info}>{info}</p>}
        <ValidationBadge error={validationError || error} touched={true} />
        <ButtonsWrapper>
          <FileButton
            onChange={this.uploadFile(name, setFieldValue)}
            formats={formats}
            className={styles.button}
            name={name}
          />
          {fileChanged && (
            <Button
              type="red"
              onClick={this.reset(name, setFieldValue, initialValue)}
            >
              {trans.RESET()}
            </Button>
          )}
        </ButtonsWrapper>
      </Fragment>
    );
  };

  render() {
    const { name, label, required, tooltipAlignment, tooltip } = this.props;
    return (
      <Fragment>
        <Tooltip alignment={tooltipAlignment} content={tooltip}>
          <Label
            text={`${label}${tooltip ? " (?)" : ""}`}
            required={required}
          />
        </Tooltip>
        <Field name={name} component={this.renderField} />
      </Fragment>
    );
  }
}
