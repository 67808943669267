// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";

export const isVersionValid = (version) => {
  const translations = get(version, "translations", []);
  return !isEmpty(translations);
};
