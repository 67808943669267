// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { FieldArray } from "formik";
import get from "lodash-es/get";
import some from "lodash-es/some";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { withActiveOrganization } from "containers/Auth/decorators";
import { apiThatThrows } from "containers/Request";
import { ROLES } from "src/containers/Permissions/roles";

import { RolesComponent } from "./RolesComponent";

@withActiveOrganization
@connect(null, (dispatch) => ({
  getRoles: async () => {
    const { results } = await dispatch(apiThatThrows.getRoles.action());
    return results;
  },
  getMyOrganizationTypes: async () => {
    const { results } = await dispatch(
      apiThatThrows.getTypesForMyOrganizationPaginated.action({
        queryParams: { limit: "nolimit" },
      })
    );

    return results;
  },
}))
export class RolesField extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,

    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,

    // from @connect
    getRoles: PropTypes.func.isRequired,
    getMyOrganizationTypes: PropTypes.func.isRequired,
  };

  state = {
    error: undefined,
    organizationRoles: [],
    loading: true,
  };

  componentDidMount() {
    this.mounted = true;

    this.updateRoles();
  }

  componentDidUpdate(prevProps) {
    const activeOrganizationId = get(this.props, "activeOrganization.id");
    const prevActiveOrganizationId = get(prevProps, "activeOrganization.id");

    if (activeOrganizationId !== prevActiveOrganizationId) {
      this.updateRoles();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateRoles = async () => {
    const { getRoles, getMyOrganizationTypes } = this.props;

    try {
      this.setState({ loading: true });

      const allRoles = await getRoles();
      const types = await getMyOrganizationTypes();

      if (!this.mounted) {
        return;
      }

      this.setState({
        organizationRoles: this.parseRolesResponse(types, allRoles),
      });
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ loading: false });
    }
  };

  parseRolesResponse(orgTypes, allRoles) {
    const isRoleAllowedForOrganization = (roleId) => {
      return some(orgTypes, (orgType) =>
        some(orgType.roles, (typeRole) => typeRole.id === roleId)
      );
    };

    return allRoles.filter((role) => {
      return (
        isRoleAllowedForOrganization(role.id) &&
        role.id !== ROLES.organization.member
      );
    });
  }

  render() {
    const { organizationRoles, error, loading } = this.state;
    const { disabled, name } = this.props;

    if (loading) {
      return <Loader />;
    }

    const props = {
      organizationRoles,
      error,
      disabled,
      name,
    };

    return (
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <RolesComponent {...arrayHelpers} {...props} />
        )}
      />
    );
  }
}
