// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";
import { createFieldValidator, isNotEqual } from "utils/validation";

export const APPS_COUNT_NONE = "-1";

export const FORM = {
  name: {
    name: "name",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_NAME(),
    required: true,
    dataTestId: "name-field",
  },
  slug: {
    name: "slug",
    autoSlugFieldName: "autoSlug",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_SLUG_B(),
    autoSlugLabel: trans.PROMOTION_TYPE_EDIT__FIELD_AUTO_SLUG(),
    required: true,
    dataTestId: "slug-field",
  },
  appsCount: {
    name: "appsCount",
    dataTestId: "apps-count-field",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_APPS_COUNT(),
    initOption: {
      value: APPS_COUNT_NONE,
      text: trans.PROMOTION_TYPE_EDIT__FIELD_APPS_COUNT_PLACEHOLDER(),
    },
    values: [
      {
        text: `${trans.PROMOTION_TYPE_EDIT__APPS_COUNT_ZERO_TEXT()}`,
        value: "0",
      },
      { text: "1", value: "1" },
      { text: "2", value: "2" },
      { text: "3", value: "3" },
    ],
    validate: createFieldValidator("appsCount", {
      validator: isNotEqual,
      args: [
        APPS_COUNT_NONE,
        trans.PROMOTION_TYPE_EDIT__FIELD_APPS_COUNT_REQUIRED_WARNING(),
      ],
    }),
  },
  softwareAssets: {
    name: "softwareAssets",
  },
  icon: {
    name: "icon",
    dataTestId: "icon-field",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_ICON(),
  },
  thumbnail: {
    name: "thumbnail",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_THUMBNAIL(),
  },
  title: {
    name: "title",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_TITLE(),
  },
  description: {
    name: "description",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_DESCRIPTION(),
  },
  background: {
    name: "background",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_FULL_PAGE_LANDSCAPE(),
  },
  json: {
    name: "json",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_JSON(),
  },
  action: {
    name: "action",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_ACTION(),
  },
  contentItems: {
    name: "contentItems",
    label: trans.PROMOTION_TYPE_EDIT__FIELD_CONTENT_ITEMS(),
  },
  availability: {
    name: "distributors",
  },
};
