// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { Column, Row } from "components/layout";
import { trans } from "src/translations";
import { CountriesPropType } from "utils/continents";

import {
  DevicePropertiesSection,
  fromDeviceDateProperty,
  fromDeviceProperty,
  fromDevicePropertyGetCountry,
} from "../components";
import { DEVICE_ATTRIBUTE_DEVICE_MODEL } from "../constants";
import { DevicePropType } from "../propTypes";
import styles from "./GeneralTab.scss";

export const GENERAL_INFORMATION_ATTRIBUTES = [
  {
    name: trans.DEVICES__DEVICE_DETAILS_HARDWARE_ID(),
    dataTestId: "device-hardware-id",
    valueFn: fromDeviceProperty("hardwareId"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_ORGANIZATION(),
    dataTestId: "device-organization",
    valueFn: fromDeviceProperty("manufacturer"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_VERSION_OF_PROVISIONING_PROTOCOL(),
    dataTestId: "device-provisioning-protocol-version",
    valueFn: fromDeviceProperty("provisioningProtocolVersion"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_VCC_SERIAL_NUMBER(),
    dataTestId: "device-vcc",
    valueFn: fromDeviceProperty("vcc"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_DEVICE_MODEL(),
    dataTestId: "device-device-model",
    valueFn: fromDeviceProperty(DEVICE_ATTRIBUTE_DEVICE_MODEL),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_ARCHITECTURE(),
    dataTestId: "device-architecture",
    valueFn: fromDeviceProperty("architecture"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_TOTAL_RAM(),
    dataTestId: "device-total-ram",
    valueFn: fromDeviceProperty("totalRam"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_TOTAL_STORAGE(),
    dataTestId: "device-total-storage",
    valueFn: fromDeviceProperty("totalStorage"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_LOCALE(),
    dataTestId: "device-locale",
    valueFn: fromDeviceProperty("locale"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_HARDWARE_REVISION(),
    dataTestId: "device-hardware-revision",
    valueFn: fromDeviceProperty("hardwareRevision"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_SYSTEM_SOFTWARE_VERSION(),
    dataTestId: "device-system-software-version",
    valueFn: fromDeviceProperty("systemSoftwareVersion"),
  },
];

export const DEVICE_ACTIVITY_ATTRIBUTES = (countries) => {
  return [
    {
      name: trans.DEVICES__DEVICE_DETAILS_LAST_CONTACT(),
      dataTestId: "device-last-activity-date",
      valueFn: fromDeviceDateProperty("lastActivityDate"),
    },
    {
      name: trans.DEVICES__DEVICE_DETAILS_LAST_CONTACT_LOCATION(),
      dataTestId: "device-last-activity-location",
      valueFn: fromDevicePropertyGetCountry("country", countries),
    },
    {
      name: trans.DEVICES__DEVICE_DETAILS_FIRST_REGISTRATION(),
      dataTestId: "device-registration-date",
      valueFn: fromDeviceDateProperty("activationDate"),
    },
    {
      name: trans.DEVICES__DEVICE_DETAILS_FIRST_REGISTRATION_LOCATION(),
      dataTestId: "device-register-country",
      valueFn: fromDevicePropertyGetCountry("registerCountry", countries),
    },
  ];
};

export const DEVICE_OTHER_INFORMATION = [
  {
    name: trans.DEVICES__DEVICE_DETAILS_STATUS(),
    dataTestId: "device-status",
    valueFn: fromDeviceProperty("deviceStatus"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_USER_TYPE(),
    dataTestId: "device-user-type",
    valueFn: fromDeviceProperty("userType"),
  },
  {
    name: trans.DEVICES__DEVICE_DETAILS_LOCATION(),
    dataTestId: "device-location",
    valueFn: fromDeviceProperty("deviceLocation"),
  },
];

export const DEVICE_ERP_NUMBERS = [
  {
    name: trans.DEVICES__DEVICE_DETAILS_NUMBER_LIST(),
    dataTestId: "device-erp-numbers",
    valueFn: fromDeviceProperty("erpNumbers"),
  },
];
export function GeneralTab({ device, countries }) {
  return (
    <Fragment>
      <DevicePropertiesSection
        device={device}
        header={trans.DEVICES__DEVICE_DETAILS_TAB_DEVICE_INFORMATION()}
        properties={GENERAL_INFORMATION_ATTRIBUTES}
      />
      <DevicePropertiesSection
        device={device}
        header={trans.DEVICES__DEVICE_DETAILS_DEVICE_ACTIVITY()}
        properties={DEVICE_ACTIVITY_ATTRIBUTES(countries)}
      />
      <Row>
        <Column className={styles.columnHalfWidth}>
          <DevicePropertiesSection
            device={device}
            header={trans.DEVICES__DEVICE_DETAILS_OTHER_INFORMATION()}
            properties={DEVICE_OTHER_INFORMATION}
          />
        </Column>
        <Column className={styles.columnHalfWidth}>
          <DevicePropertiesSection
            device={device}
            header={trans.DEVICES__DEVICE_DETAILS_ERP_NUMBERS()}
            properties={DEVICE_ERP_NUMBERS}
            isSingleColumn={true}
          />
        </Column>
      </Row>
    </Fragment>
  );
}

GeneralTab.propTypes = {
  device: DevicePropType,
  countries: CountriesPropType,
};
