// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { NumberField, SelectField } from "components/form/fields";
import { Column } from "components/layout";
import { Header } from "components/typography";
import { FIELDS, SECTIONS } from "pages/Snap/shared/constants";
import { isValidNaturalNumber } from "pages/Snap/SnapEdit/validation";

const numFields = (formId) => {
  const {
    AD_MID_OFFSET: midOffset,
    AD_MID_INTERVAL: midInterval,
    AD_MID_MIN_OFFSET: minOffset,
    AD_MID_MIN_INTERVAL: minInterval,
  } = FIELDS;

  return [midOffset, midInterval, minOffset, minInterval].map(
    ({ name, label, tooltip }, idx) => (
      <NumberField
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        name={`${formId}.${name}`}
        label={label}
        tooltip={tooltip}
        required={false}
        min={0}
        step={1}
        validate={isValidNaturalNumber}
      />
    )
  );
};
export const AdMidRoll = ({ formId, onTypeChange }) => {
  return (
    <Column>
      <Header bold={true} color="secondary" uppercase={true}>
        {SECTIONS.ADS.midrollHeader}
      </Header>
      <SelectField
        name={`${formId}.${FIELDS.AD_TYPES.MID.name}`}
        values={FIELDS.AD_MID_TYPE_SELECT.options}
        label={FIELDS.AD_MID_TYPE_SELECT.label}
        tooltip={FIELDS.AD_MID_TYPE_SELECT.tooltip}
        onChange={(e) => {
          onTypeChange({ type: "change_breakpoint", payload: e.target.value });
        }}
      />
      {numFields(formId)}
    </Column>
  );
};
AdMidRoll.propTypes = {
  formId: PropTypes.string.isRequired,
  onTypeChange: PropTypes.func.isRequired,
};
