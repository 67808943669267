// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { restrictedArea, ROLES } from "containers/Permissions";
import { api } from "containers/Request";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { withQueryParams, withRouter } from "utils/decorators";

import { AdminPlansRend } from "./AdminPlansRend";

@restrictedArea(() => {
  return {
    allowed: [ROLES.administrator.distributionAdmin],
  };
})
@withRouter
@withQueryParams
@connect(null, (dispatch) => ({
  getPlansPaginated: async (queryParams) => {
    const { result, error } = await dispatch(
      api.getDistributionPlansPaginated.action({
        queryParams,
      })
    );

    if (error) {
      throw new Error(error);
    }

    return { results: result.results, meta: result.meta };
  },
  getOrganizations: async (orgIds) => {
    if (!orgIds || orgIds.length === 0) {
      return [];
    }
    const { result, error } = await dispatch(
      api.getOrganizationsPaginated.action({
        queryParams: { id: orgIds, limit: orgIds.length },
      })
    );

    if (error) {
      throw new Error(error);
    }

    return { results: result.results };
  },
}))
export class AdminPlansData extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    getPlansPaginated: PropTypes.func.isRequired,
    getOrganizations: PropTypes.func.isRequired,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,
  };

  state = {
    results: [],
    count: 0,
    error: null,
    loadingCount: 0,
  };

  async componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.updateData();
    }
  }

  async updateData() {
    const { queryParams, getPlansPaginated, getOrganizations } = this.props;

    this.setState((prevState) => ({
      loadingCount: prevState.loadingCount + 1,
    }));

    try {
      const { results: plansResults, meta } =
        await getPlansPaginated(queryParams);
      const orgIds = plansResults.map((p) => p.owner);
      const { results: organizationsResults } = await getOrganizations(orgIds);

      this.setState({
        results: this.adaptApiDataForUi(plansResults, organizationsResults),
        count: meta.count,
        error: null,
      });
    } catch (err) {
      this.setState({ error: { message: err.message } });
    } finally {
      this.setState((prevState) => ({
        loadingCount: prevState.loadingCount - 1,
      }));
    }
  }

  getOrganizationName = (organizations, organizationId) => {
    const organization = organizations.find((o) => o.id === organizationId);
    return organization ? organization.name : NO_VALUE_PLACEHOLDER;
  };

  adaptApiDataForUi = (plans, organizations) => {
    return plans.map((p) => ({
      id: p.id,
      name: p.label,
      isDefault: p.is_default_plan,
      appsCount: p.applications.length,
      distributorName: this.getOrganizationName(organizations, p.owner),
    }));
  };

  render() {
    const { results, count, error, loadingCount } = this.state;

    return (
      <AdminPlansRend
        results={results}
        count={count}
        error={error}
        loading={Boolean(loadingCount)}
      />
    );
  }
}
