// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createRef, Component } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { restrictedArea } from "containers/Permissions";
import { classes } from "utils/classes";

import styles from "./mainItem.scss";

@restrictedArea(({ allowed }) => ({
  allowed: allowed,
  showFallback: false,
}))
export class MainItem extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    collapsed: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    children: PropTypes.node,
    allowed: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    ),
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "main-item",
  };

  constructor(props) {
    super(props);
    this.mainItemRef = createRef();
    this.subItemsListCollapsedRef = createRef();
  }

  handleHover = () => {
    const mainItemEl = this.mainItemRef.current;
    const subItemsListCollapsedEl = this.subItemsListCollapsedRef.current;

    if (
      !mainItemEl ||
      !subItemsListCollapsedEl ||
      mainItemEl.getClientRects().length === 0
    ) {
      return;
    }

    const offsetTop = mainItemEl.getClientRects()[0].y;
    subItemsListCollapsedEl.style.top = `${offsetTop}px`;
  };

  handleClick = (e) => {
    const { onClick } = this.props;

    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  createNavLinkProps() {
    const { path } = this.props;
    return {
      to: path,
      onClick: this.handleClick,
    };
  }

  renderSubItemsListExpanded() {
    const { children, isActive } = this.props;
    if (!children || !isActive) {
      return null;
    }
    return <ul className={styles.subItemsExpanded}>{children}</ul>;
  }

  renderSubItemsListCollapsed() {
    const { label, children } = this.props;
    return (
      <div
        className={styles.collapsedPopup}
        ref={this.subItemsListCollapsedRef}
      >
        <NavLink className={styles.navLink} {...this.createNavLinkProps()}>
          <span className={styles.label}>{label}</span>
        </NavLink>

        {children && <ul className={styles.subItemsCollapsed}>{children}</ul>}
      </div>
    );
  }

  getClasses() {
    const { collapsed, isActive } = this.props;

    return classes(
      styles.mainItem,
      { [styles.mainItemActive]: isActive },
      collapsed ? styles.sideBarCollapsed : styles.sideBarExpanded
    );
  }

  render() {
    const { icon, label, collapsed, dataTestId } = this.props;

    return (
      <li
        className={this.getClasses()}
        ref={this.mainItemRef}
        onMouseOver={this.handleHover}
      >
        <NavLink
          {...this.createNavLinkProps()}
          className={styles.navLink}
          data-test-id={dataTestId}
        >
          <div className={styles.mainItemContent}>
            {!collapsed && <span className={styles.label}>{label}</span>}
            <GrabsonIcon className={styles.icon} name={icon} size="xl" />
          </div>
        </NavLink>

        {collapsed
          ? this.renderSubItemsListCollapsed()
          : this.renderSubItemsListExpanded()}
      </li>
    );
  }
}
