// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

const MINUTES_IN_HOUR = 60;

/* UI renders local time for user and backend works with utc timestamps so
+we need to take into account TZ offset to make sure that there won't be
+one day differences between what user picked in datepicker and actual availability date
+eg. user in Honolulu at the evening wants to start update today local time
+but in UTC it is already tomorrow, so without this function the job won't start
+ immidiately as it should */
const adaptDateForGql = (date) => {
  const now = new Date();
  const d = new Date(date.getTime());
  // datepicker resets time to 00:00:00.000 so we have to manually add the time when user picked availability dates
  d.setHours(
    now.getHours(),
    now.getMinutes(),
    now.getSeconds(),
    now.getMilliseconds()
  );
  d.setUTCHours(d.getHours() + d.getTimezoneOffset() / MINUTES_IN_HOUR);
  return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
};

export const adaptCreateDataForGql = (values) => {
  // expotential notation is allowed, we need to use Number instead of parseInt
  const parseInteger = (x) => Number(x);
  const parseDeviceCount = (x) => String(parseInteger(x));

  return {
    name: values.name,
    description: values.description || "",
    type: values.type,
    softwarePackageVersionId: get(values, "softwarePackageVersion.id"),
    target: get(values, "targetDevices.id"),
    deviceCountPerStep: get(values, "deviceQuantityPerStep", [])
      .map(parseDeviceCount)
      .slice(0, values.numberOfSteps - 1),
    threshold: parseInteger(values.threshold),
    availabilityStartDate: adaptDateForGql(
      get(values, "availabilityScope.start")
    ),
    availabilityEndDate: adaptDateForGql(get(values, "availabilityScope.end")),
    startAutomatically: values.startAutomatically,
    downloadUrl: values.customDownloadUrl || "",
    isRepeatable: values.isRepeatable || false,
  };
};
