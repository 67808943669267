// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as Sentry from "@sentry/browser";
import { get, isEqual } from "lodash-es";

import { api } from "containers/Request";

import * as types from "./actionTypes";

const prepareOrganization = (organization, authorization) => {
  const grants = get(authorization, "grants", []);

  return {
    id: organization.id,
    name: organization.name,
    types: organization.organization_types.map((item) => item.type),
    img: organization.logo,
    roles: grants.map((g) => g.role),
    organizationPlan: organization.organization_plan,
  };
};

const getOrganizations = async (dispatch, authorizations) => {
  const organizations = {};

  const organizationsReq = await dispatch(
    api.getMyOrganizationsPaginated.action({
      queryParams: { limit: "nolimit" },
    })
  );

  const { error, result } = organizationsReq;

  if (error) {
    // todo: YGG-2754 Handle organizations request error during authorization
    return organizations;
  }

  result.results.forEach((organization) => {
    if (organization.id) {
      const authorization =
        authorizations.filter((a) => a.organization === organization.id)[0] ||
        {};
      organizations[organization.id] = prepareOrganization(
        organization,
        authorization
      );
    }
  });

  return organizations;
};

export const unauthorize = () => ({
  type: types.UNAUTHORIZED,
});

const createUserProfile = ({
  id,
  given_name,
  family_name,
  email,
  avatar,
  current_organization,
}) => ({
  id,
  name: `${given_name || ""} ${family_name || ""}`.trim(),
  email,
  img: avatar,
  activeOrganizationId: current_organization,
});

export const authorize =
  (auth = {}) =>
  async (dispatch) => {
    const { error, result } = await dispatch(api.getCurrentUser.action());

    if (error) {
      dispatch(unauthorize());
      return;
    }

    const { authorizations } = result.results;
    const organizations = await getOrganizations(dispatch, authorizations);
    const profile = createUserProfile(result.results);

    const userChanged =
      !isEqual(organizations, auth.organizations) ||
      !isEqual(profile, auth.profile);

    if (userChanged) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: profile.id,
          email: profile.email,
          activeOrganizationId: profile.activeOrganizationId,
          organizations: organizations,
        });
      });

      dispatch({
        type: types.AUTHORIZED,
        profile: profile,
        organizations: organizations,
      });
    }
  };

export const updateAuthCookie = () => async (dispatch) => {
  const { error } = await dispatch(api.updateCookieNoRedirect.action());

  if (error) {
    dispatch(unauthorize());
    return;
  }

  await dispatch(authorize());
};

export const changeActiveOrganization =
  (organizationId) => async (dispatch) => {
    const { error } = await dispatch(
      api.switchOrganizationNoRedirect.action({
        queryParams: { organization: organizationId },
      })
    );

    if (error) {
      dispatch(unauthorize());
      return;
    }

    await dispatch(authorize());
  };
