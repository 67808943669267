// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "utils/decorators/withRouter";

import { SectionModerationData } from "./Moderation/SectionModerationData";

@withRouter
export class ModerationTab extends Component {
  static propTypes = {
    params: PropTypes.object,
    qaSummaries: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
    refreshQASummaries: PropTypes.func.isRequired,
    refreshData: PropTypes.func.isRequired,
  };

  render() {
    const { qaSummaries, refreshQASummaries, application, refreshData } =
      this.props;
    const { id } = this.props.params;

    return (
      <div>
        <SectionModerationData
          applicationId={id}
          application={application}
          refreshData={refreshData}
          refreshQASummaries={refreshQASummaries}
          qaSummaries={qaSummaries}
        />
      </div>
    );
  }
}
