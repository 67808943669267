// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const SUBMIT_ATTEMPTED = "submit-attempted";

export const FIELDS = {
  NAME: {
    name: "name",
    label: trans.CAMPAIGN_EDIT__FIELD_NAME(),
    tooltip: trans.CAMPAIGN_EDIT__FIELD_NAME_TOOLTIP(),
    dataTestId: "campaign-name",
  },
  DESCRIPTION: {
    name: "description",
    label: trans.CAMPAIGN_EDIT__FIELD_DESCRIPTION(),
    tooltip: trans.CAMPAIGN_EDIT__FIELD_DESCRIPTION_TOOLTIP(),
    dataTestId: "campaign-description",
  },
};
