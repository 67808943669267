// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { TableBody } from "components/layout";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";

import { DefaultSortContext } from "../../_shared/Context/defaultSortContext";
import { resultsPropTypes } from "./propTypes";
import styles from "./SoftwarePackage.scss";
import { SoftwarePackageRow } from "./SoftwarePackageRow";
import { ITEMS_PER_PAGE } from "./SoftwarePackagesData";

export class SoftwarePackagesTable extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    results: resultsPropTypes,
    count: PropTypes.number.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
  };

  getTabsConfig = () => {
    return [
      {
        id: "softwarePackagesTable",
        columns: [
          {
            id: "name",
            label: trans.UPDATES__SOFTWARE_PACKAGES_PACKAGE_NAME_HEADER(),
            className: styles.nameColumnLabel,
            sortBy: "name",
            dataTestId: "name-column-header",
          },
          {
            id: "versionQuantity",
            label: trans.UPDATES__SOFTWARE_PACKAGES_VERSION_QUANTITY_HEADER(),
            dataTestId: "version-column-header",
          },
          { id: "actions", label: "" },
        ],
      },
    ];
  };

  render() {
    const { loading, error, results, count } = this.props;
    return (
      <DefaultSortContext.Consumer>
        {(defaultOrder) => (
          <TabbedTable
            tabsConfig={this.getTabsConfig()}
            data={{
              loading,
              error,
              results,
              count,
              defaultOrder,
            }}
            dataTestId="software-packages-table"
            noResultsMessage={
              <p>
                {trans.UPDATES__SOFTWARE_PACKAGES_SOFTWARE_LIST_NO_RESULTS()}
              </p>
            }
            rowsPerPage={ITEMS_PER_PAGE}
            renderTableBody={(row) => (
              <TableBody>
                {row.map((pack) => (
                  <SoftwarePackageRow key={pack.id} softwarePackage={pack} />
                ))}
              </TableBody>
            )}
          />
        )}
      </DefaultSortContext.Consumer>
    );
  }
}
