// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { SelectField } from "components/form/fields";
import { Modal } from "components/layout";
import { Header } from "components/typography";
import { api } from "containers/Request";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";
import { createBody } from "utils/jsonApi";

import { PRIORITY_P1, PRIORITY_P2, PRIORITY_P3 } from "../../constants";

const jsonPointerToFieldName = {
  "/data/attributes/priority": "priority",
};

@connect(null, (dispatch) => ({
  approveForCertification: (id, body) =>
    dispatch(api.patchQASummary.action({ params: { id }, options: { body } })),
}))
export class ApproveModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    refreshData: PropTypes.func,
    qaSummariesId: PropTypes.string,
    userId: PropTypes.string,
    isForExaminationOnly: PropTypes.bool.isRequired,
    // from @connect
    approveForCertification: PropTypes.func,
  };

  handleSubmit = async (values, { setErrors }) => {
    const {
      onClose,
      refreshData,
      approveForCertification,
      userId,
      qaSummariesId,
    } = this.props;

    // TODO: adapt to work with examination, backend not ready yet
    const { error } = await approveForCertification(
      qaSummariesId,
      createBody({
        type: "QASummary",
        accepted: true,
        id: qaSummariesId,

        is_premium: true,
        support_level: values.priority,
        priority: values.priority,
        content_manager_id: userId,
      })
    );

    if (error) {
      const submitErrors = prepareErrorsForForm(error, jsonPointerToFieldName);
      setErrors(submitErrors);
    } else {
      onClose();
      refreshData();
    }
  };

  render() {
    const { isOpen, onClose, isForExaminationOnly } = this.props;

    const title = isForExaminationOnly
      ? trans.APP_DETAILS_CERTIFICATION__ACP_APPROVE_EXAMINATION_MODAL_TITLE()
      : trans.APP_DETAILS_CERTIFICATION__ACP_APPROVE_CERTIFICATION_MODAL_TITLE();
    const content = isForExaminationOnly
      ? trans.APP_DETAILS_CERTIFICATION__ACP_APPROVE_EXAMINATION_MODAL_CONTENT()
      : trans.APP_DETAILS_CERTIFICATION__ACP_APPROVE__CERTIFICATION_MODAL_CONTENT();

    return (
      <Formik initialValues={{ priority: "" }} onSubmit={this.handleSubmit}>
        {({
          dirty,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          setFieldTouched,
        }) => {
          return (
            <Modal
              title={title}
              isOpen={isOpen}
              onClose={onClose}
              actions={
                <ButtonsWrapper>
                  <Button type="normal" onClick={onClose}>
                    {trans.CANCEL()}
                  </Button>
                  <Button
                    type="green"
                    processing={isSubmitting}
                    disabled={
                      isSubmitting ||
                      !dirty ||
                      Boolean(Object.entries(errors).length)
                    }
                    onClick={handleSubmit}
                  >
                    {trans.PROCEED()}
                  </Button>
                </ButtonsWrapper>
              }
              size="big"
            >
              <Form>
                <Header type="h5">{content}</Header>
                <SelectField
                  initOption={{
                    value: "",
                    text: NO_VALUE_PLACEHOLDER,
                  }}
                  values={[
                    { value: PRIORITY_P1, text: "P1" },
                    { value: PRIORITY_P2, text: "P2" },
                    { value: PRIORITY_P3, text: "P3" },
                  ]}
                  label={trans.APP_DETAILS_CERTIFICATION__PRIORITY()}
                  name="priority"
                  onChange={(e) => {
                    setFieldTouched("priority", true);
                    handleChange(e);
                  }}
                />
                {errors?._error && <Info type="error">{errors._error}</Info>}
              </Form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}
