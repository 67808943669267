// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { apiThatThrows } from "containers/Request";
import { createBody } from "utils/jsonApi";

import { SectionEnvironments } from "./SectionEnvironments";

@connect(null, (dispatch) => ({
  setAppEnvironmentStatus: (id, body) =>
    dispatch(
      apiThatThrows.setAppEnvironmentStatus.action({
        params: { id },
        options: { body },
      })
    ),
}))
export class SectionEnvironmentsData extends Component {
  static propTypes = {
    application: PropTypes.object.isRequired,
    appVersion: PropTypes.object,
    refreshVersionsData: PropTypes.func.isRequired,

    // from @connect
    setAppEnvironmentStatus: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    error: undefined,
  };

  changeAppEnvironment = async (id, isApproved) => {
    const { setAppEnvironmentStatus } = this.props;

    try {
      this.setState({ loading: true });

      const body = createBody({
        type: "Environment",
        is_functional: isApproved,
      });

      await setAppEnvironmentStatus(id, body);
      this.props.refreshVersionsData();
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleApproveAppEnvironment = async (id) => {
    await this.changeAppEnvironment(id, true);
  };

  handleRejectAppEnvironment = async (id) => {
    await this.changeAppEnvironment(id, false);
  };

  render() {
    const { application, appVersion } = this.props;
    const { loading, error } = this.state;

    return (
      <SectionEnvironments
        application={application}
        appVersion={appVersion}
        loading={loading}
        error={error}
        onApproveAppEnvironment={this.handleApproveAppEnvironment}
        onRejectAppEnvironment={this.handleRejectAppEnvironment}
      />
    );
  }
}
