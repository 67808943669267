// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const stripTypename = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(stripTypename);
  } else if (obj !== null && typeof obj === "object") {
    const cleanObj = {};
    for (const prop in obj) {
      if (prop !== "__typename") {
        cleanObj[prop] = stripTypename(obj[prop]);
      }
    }
    return cleanObj;
  }
  return obj;
};
