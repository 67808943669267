// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Section, Subsection } from "components/layout";
import { Link } from "components/typography";
import { RestrictedArea, ROLES } from "containers/Permissions";
import { ORGANIZATION_PLAN } from "pages/Organization/constants";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./Owner.scss";

export class Owner extends Component {
  static propTypes = {
    organization: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      website: PropTypes.string,
      organizationPlan: PropTypes.string,
    }).isRequired,
    upgradeOrganizationPlan: PropTypes.func,
  };

  render() {
    const { upgradeOrganizationPlan } = this.props;
    const { id, name, website, organizationPlan } = this.props.organization;
    const isPremium = organizationPlan === ORGANIZATION_PLAN.Premium;

    return (
      <Section
        header={trans.APP_DETAILS_VERSION__SECTION_OWNER()}
        dataTestId="owner-section"
      >
        <Subsection header={trans.ORGANIZATION()}>
          <p className={classes(styles.titleText, styles.descriptionText)}>
            {name}
            {isPremium && (
              <span
                className={styles.premiumPill}
                data-test-id="app-owner-is-premium"
              >
                {trans.PREMIUM()}
              </span>
            )}
          </p>
          {!isPremium && (
            <RestrictedArea
              allowed={[ROLES.moderator.contentManager]}
              showFallback={false}
            >
              <ButtonsWrapper position="left">
                <Button
                  type="green"
                  onClick={() => upgradeOrganizationPlan(id, "PREMIUM")}
                  dataTestId="upgrade-owner-to-premium-button"
                >
                  {trans.APP_DETAILS_VERSION__UPGRADE_ORGANIZATION_TO_PREMIUM()}
                </Button>
              </ButtonsWrapper>
            </RestrictedArea>
          )}
        </Subsection>
        <Subsection header={trans.WEBSITE()}>
          {website ? (
            <Link
              className={classes(styles.titleText, styles.ownerWebsite)}
              href={website}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              {website}
            </Link>
          ) : (
            NO_VALUE_PLACEHOLDER
          )}
        </Subsection>
        <RestrictedArea
          allowed={[ROLES.moderator.contentManager, ROLES.moderator.moderator]}
          showFallback={false}
        >
          <Subsection header={trans.APP_DETAILS_VERSION__ORGANIZATION_ID()}>
            {id}
          </Subsection>
        </RestrictedArea>
      </Section>
    );
  }
}
