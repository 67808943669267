// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, Component } from "react";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";

const ResetFormHandler = ({ activeOrganization }) => {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    resetForm();
  }, [activeOrganization, resetForm]);

  return null;
};

ResetFormHandler.propTypes = {
  activeOrganization: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

@withActiveOrganization
export class ResetFormOnOrgChange extends Component {
  static propTypes = {
    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { activeOrganization } = this.props;
    return <ResetFormHandler activeOrganization={activeOrganization} />;
  }
}
