// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Pill } from "components/elements/Pill";
import { Label } from "components/form";
import { CheckboxField } from "components/form/fields";
import { trans } from "src/translations";
import { HtmlTranslation } from "src/translations/utils/components/HtmlTranslation";

import styles from "./IsRepeatableField.scss";

export const IsRepeatableField = () => {
  return (
    <>
      <Label
        text={trans.UPDATES__FORM_FIELD_IS_REPEATABLE_HEADER()}
        tooltip={
          <HtmlTranslation
            transFunc={trans.UPDATES__FORM_FIELD_IS_REPEATABLE_TOOLTIP}
          />
        }
      >
        <CheckboxField
          name="isRepeatable"
          key="is-repeatable-field"
          label={<Pill text={trans.UPDATES__FORM_FIELD_REPEATABLE_PILL()} />}
          dataTestId="is-repeatable-field"
          labelClassName={styles.checkboxLabel}
          tooltipClassName={styles.tooltip}
        />
      </Label>
    </>
  );
};
