// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent, Fragment } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "components/layout";
import { ActionMenu, MenuItem, Tooltip } from "components/popups";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";
import { HtmlTranslation } from "src/translations/utils/components/HtmlTranslation";
import { withMutation } from "utils/graphql";

import { TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_KEY } from "../constants";
import { setDeviceAttributeMutation } from "../gql/setDeviceAttributeMutation";
import { DeviceAttributePropType } from "../propTypes";
import {
  AttributeAddModal,
  AttributeEditModal,
  AttributeRemoveModal,
} from "./index";
import styles from "./ManagerAttributesTable.scss";

const DEFAULT_STATE = {
  isAddAttributeModalOpen: false,
  isEditAttributeModalOpen: false,
  isDeleteAttributeModalOpen: false,
  // attribute that we are currently editing/removing
  changingAttribute: null,
};

@withMutation({
  name: "setDeviceAttribute",
  mutation: setDeviceAttributeMutation,
})
export class ManagerAttributesTable extends PureComponent {
  static propTypes = {
    deviceId: PropTypes.string.isRequired,
    managerAttributes: PropTypes.arrayOf(DeviceAttributePropType).isRequired,
    setSuccessMessage: PropTypes.func.isRequired,

    // from @withMutation
    setDeviceAttributeMutation: PropTypes.func.isRequired,
  };

  state = { ...DEFAULT_STATE };

  handleAddAction = () => {
    this.setState({
      ...DEFAULT_STATE,
      isAddAttributeModalOpen: true,
    });
  };

  handleEditAction = (attr) => {
    this.setState({
      ...DEFAULT_STATE,
      isEditAttributeModalOpen: true,
      changingAttribute: attr,
    });
  };

  handleRemoveAction = (attr) => {
    this.setState({
      ...DEFAULT_STATE,
      isDeleteAttributeModalOpen: true,
      changingAttribute: attr,
    });
  };

  handleModalClose = () => {
    this.setState({ ...DEFAULT_STATE });
  };

  setAttributeRequest = async (attrName, attrValue) => {
    const { deviceId, setDeviceAttributeMutation } = this.props;
    await setDeviceAttributeMutation({
      variables: {
        input: {
          deviceId: deviceId,
          attributeName: attrName,
          attributeValue: attrValue,
        },
      },
    });
    this.handleModalClose();
  };

  renderRow = (attribute) => {
    const { key, value } = attribute;

    return (
      <TableRow key={key}>
        <TableRowColumn
          dataTestId="attribute-name-column"
          className={styles.tableManagerAttributeName}
        >
          {key}
          {key === TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_KEY && (
            <Tooltip
              content={
                <HtmlTranslation
                  transFunc={
                    trans.DEVICES__DEVICE_DETAILS_TEST_DEVICE_UNIT_ATTRIBUTE_TOOLTIP
                  }
                />
              }
              alignment="bottom-start"
              className={styles.testDeviceUnitTooltip}
              dataTestId="test-device-unit-tooltip"
            >
              ?
            </Tooltip>
          )}
        </TableRowColumn>
        <TableRowColumn
          dataTestId="attribute-value-column"
          className={styles.tableManagerAttributeValue}
        >
          {value}
        </TableRowColumn>
        <TableRowColumn>
          <ActionMenu>
            {attribute.key !== TEST_DEVICE_UNIT_MANAGER_ATTRIBUTE_KEY && (
              <MenuItem dataTestId="edit-attribute-action-item">
                <div onClick={() => this.handleEditAction(attribute)}>
                  {trans.DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_BUTTON()}
                </div>
              </MenuItem>
            )}
            <MenuItem dataTestId="delete-attribute-action-item">
              <div onClick={() => this.handleRemoveAction(attribute)}>
                {trans.DEVICES__DEVICE_DETAILS_REMOVE_ATTRIBUTE_BUTTON()}
              </div>
            </MenuItem>
          </ActionMenu>
        </TableRowColumn>
      </TableRow>
    );
  };

  render() {
    const { managerAttributes, setSuccessMessage } = this.props;
    const {
      isAddAttributeModalOpen,
      isEditAttributeModalOpen,
      isDeleteAttributeModalOpen,
      changingAttribute,
    } = this.state;

    return (
      <Fragment>
        <Table className={styles.table}>
          <TableHeader position="sticky">
            <TableHeaderColumn dataTestId="attribute-name-column-header">
              {trans.DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_NAME_COLUMN()}
            </TableHeaderColumn>
            <TableHeaderColumn dataTestId="attribute-value-column-header">
              {trans.DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_VALUE_COLUMN()}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataTestId="attribute-actions-column-header"
              className={styles.columnActionButton}
            />
          </TableHeader>

          {managerAttributes.length === 0 && (
            <TableBody>
              <TableRow>
                <TableRowColumn dataTestId="attribute-name-column">
                  {NO_VALUE_PLACEHOLDER}
                </TableRowColumn>
                <TableRowColumn dataTestId="attribute-value-column" />
              </TableRow>
            </TableBody>
          )}

          {managerAttributes.length > 0 && (
            <TableBody>{managerAttributes.map(this.renderRow)}</TableBody>
          )}
        </Table>
        <Button
          onClick={this.handleAddAction}
          dataTestId={"add-attribute-button"}
          className={styles.managerAttributesButtons}
        >
          {trans.DEVICES__DEVICE_DETAILS_ADD_ATTRIBUTE_BUTTON()}
        </Button>

        <AttributeAddModal
          isOpen={isAddAttributeModalOpen}
          onClose={this.handleModalClose}
          setAttributeRequest={this.setAttributeRequest}
          setSuccessMessage={setSuccessMessage}
        />
        <AttributeEditModal
          isOpen={isEditAttributeModalOpen}
          onClose={this.handleModalClose}
          attribute={changingAttribute}
          setAttributeRequest={this.setAttributeRequest}
          setSuccessMessage={setSuccessMessage}
        />
        <AttributeRemoveModal
          isOpen={isDeleteAttributeModalOpen}
          onClose={this.handleModalClose}
          attribute={changingAttribute}
          setAttributeRequest={this.setAttributeRequest}
          setSuccessMessage={setSuccessMessage}
        />
      </Fragment>
    );
  }
}
