// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { isFieldValid, isFieldInErrors } from "./fields";

export const isTabValid = (
  fields: Record<string, any>,
  fieldsStructures: string[]
): boolean => {
  let isValid = true;

  fieldsStructures.forEach((fieldStructure) => {
    if (!isFieldValid(fieldStructure.split("."), fields)) {
      isValid = false;
    }
  });

  return isValid;
};

export const isTabFieldInErrors = (
  errors: Record<string, any>,
  fieldsStructures: string[]
): boolean => {
  return fieldsStructures.some((fieldStructure) => {
    return isFieldInErrors(fieldStructure.split("."), errors);
  });
};
