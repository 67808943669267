// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

export const getDeviceFilterQuery = gql`
  query deviceFilter($id: ID!) {
    deviceFilter(deviceFilterId: $id) {
      deviceFilter {
        id
        name
        creationDate
        filtersCount
        search {
          query
          include {
            values
            name
          }
          exclude {
            values
            name
          }
        }
      }
    }
    deviceSearchSuggestions {
      deviceSearchSuggestions {
        title
        options {
          id
          displayName
        }
      }
    }
  }
`;
