// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { TableBody } from "components/layout";

import { AssetRow } from "./AssetRow";
import { AssetVersionRow } from "./AssetVersionRow";

export class AssetTableBody extends Component {
  static propTypes = {
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        versions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            number: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  };

  state = { assetsExpanded: {} };

  toggleRow = ({ id }) => {
    this.setState((prevState) => ({
      assetsExpanded: {
        [id]: prevState.assetsExpanded[id]
          ? !prevState.assetsExpanded[id]
          : true,
      },
    }));
  };

  render() {
    const { assets } = this.props;
    const { assetsExpanded } = this.state;

    return (
      <TableBody>
        {assets.reduce((acc, asset) => {
          acc.push(
            <AssetRow
              key={asset.id}
              asset={asset}
              expanded={assetsExpanded[asset.id]}
              onClick={this.toggleRow}
            />
          );

          if (assetsExpanded[asset.id]) {
            asset.versions.forEach((version) => {
              acc.push(
                <AssetVersionRow
                  key={version.id}
                  asset={asset}
                  version={version}
                />
              );
            });
          }

          return acc;
        }, [])}
      </TableBody>
    );
  }
}
