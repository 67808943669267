// Copyright © 2021 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import * as React from "react";

import { v4 as uuid } from "uuid";

const ABORT_ERROR_NAME = "AbortError";

/**
 * There is no build-in AbortError we can use. Inheritance does not work either.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/DOMException
 */
export const createAbortError = (
  message: "This request was aborted"
): DOMException => {
  return new DOMException(message, ABORT_ERROR_NAME);
};

interface AbortSignalWithId {
  id: string;
  signal: AbortSignal;
}

export interface WithAbortProps {
  createAbortSignal: () => AbortSignalWithId;
  abort: (signal: AbortSignal) => void;
  isAbortError: (error: Error) => boolean;
}

export const withAbort = <TProps extends WithAbortProps = WithAbortProps>(
  ComposedComponent: React.ComponentType<TProps>
) => {
  class WithAbort extends Component<TProps & WithAbortProps> {
    controllers: Record<string, AbortController> = {};

    createAbortSignal = (): AbortSignalWithId => {
      const id = uuid();
      // eslint-disable-next-line babel/no-invalid-this
      const controllers = this.controllers;
      controllers[id] = new AbortController();
      return { id, signal: controllers[id].signal };
    };

    abort = (abortSignal: AbortSignalWithId): void => {
      if (!abortSignal || !abortSignal.id) {
        return;
      }
      // eslint-disable-next-line babel/no-invalid-this
      const controllers = this.controllers;
      const controller = controllers[abortSignal.id];
      if (controller) {
        controller.abort();
        delete controllers[abortSignal.id];
      }
    };

    isAbortError = (error: Error): boolean => {
      return error.name === ABORT_ERROR_NAME;
    };

    render() {
      return (
        <ComposedComponent
          {...this.props}
          createAbortSignal={this.createAbortSignal}
          abort={this.abort}
          isAbortError={this.isAbortError}
        />
      );
    }
  }

  return WithAbort;
};
