// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import styles from "./DeviceInventoryTable.scss";

export const ITEMS_PER_PAGE = 50;

export const TABS_CONFIG = [
  {
    dataTestId: "filters-details-device-list",
    columns: [
      {
        id: "checkbox",
        label: "",
        dataTestId: "checkbox-column-header",
      },
      {
        id: "id",
        label: trans.DEVICES__FILTERS_DETAILS_DEVICES_TABLE_HEADER_ID(),
        dataTestId: "id-column-header",
        className: styles.deviceIdColumn,
      },
      {
        id: "device_model",
        label:
          trans.DEVICES__DEVICE_INVENTORY_DEVICES_TABLE_HEADER_DEVICE_MODEL(),
        sortBy: "device_model",
        dataTestId: "device-model-column-header",
        className: styles.deviceModelColumn,
      },
      {
        id: "country",
        label: trans.DEVICES__DEVICE_INVENTORY_DEVICES_TABLE_HEADER_COUNTRY(),
        sortBy: "country",
        dataTestId: "country-column-header",
        className: styles.countryColumn,
      },
      {
        id: "activation_date",
        label:
          trans.DEVICES__FILTERS_DETAILS_DEVICES_TABLE_HEADER_ACTIVATION_DATE(),
        sortBy: "activation_date",
        dataTestId: "activation-date-column-header",
        className: styles.dateColumn,
      },
      {
        id: "last_activity_date",
        label:
          trans.DEVICES__FILTERS_DETAILS_DEVICES_TABLE_HEADER_LAST_CONTACT_DATE(),
        sortBy: "last_activity_date",
        dataTestId: "activation-date-column-header",
        className: styles.dateColumn,
      },
    ],
  },
];

export const BULK_ACTIONS = {
  DISMISS_ALERT: "bulk_dismiss_alert",
};

export const BULK_ACTION_SELECT_VALUES = [
  {
    value: BULK_ACTIONS.DISMISS_ALERT,
    text: trans.DEVICES__DEVICE_INVENTORY_DEVICES_TABLE_DISMISS_ALERT_SELECT_OPTION(),
  },
];
