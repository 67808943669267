// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Validator } from "./utils";

// Copyright © 2021 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/**
 * Validation method used mostly to verify many fields with different names.
 * Main use case is to provide validation to whole form at once,
 * or when field has subfields (e.g. date range field range may want
 * to validate both start and end dates).
 *
 * Example usage:
 * ```
 *  ...
 *   validate = async (values) => {
 *    ...
 *    return pipeValidators(
 *     isRequired("email"),
 *     isRequired("family_name"),
 *   )(values),
 *  }
 * ```
 */
export const pipeValidators =
  <T = unknown, K = unknown>(...fns: Validator<T, K>[]) =>
  (
    values: Record<string, T>,
    rawValues: Record<string, K>,
    errors?: Record<string, string> | undefined
  ): Record<string, string> | undefined => {
    return fns.reduce(
      (errorsAcc, fn) => fn(values, rawValues, errorsAcc),
      errors
    );
  };

/**
 * Read validator definition for a single field. Simple validators
 * can be provided as functions e.g.
 *   `createFieldValidator(fieldName, isRequired, isEmail),`
 * but when some other arguments are required (e.g. maxLengthOfStr requires
 * number to compare the length to), it's recommended to use object:
 *   ```
 *   createFieldValidator(fieldName, isRequired, {
 *      validator: maxLengthOfStr,
 *      // the 'path' argument will be automatically injected later
 *      args: [MAX_NAME_LENGTH],
 *    }),
 *   ```
 *
 * Example of usage:
 *
 *  ```
 *  export class DescriptionField extends Component {
 *   validate = createFieldValidator("appDesc", {
 *     validator: maxLengthOfStr,
 *     args: [200],
 *   });
 *   render () {
 *     return <Field name="appDesc" validate={this.validate} ... />;
 *    }
 *  }
 *  ```
 */

type ValidatorDefinition<T = unknown, K = unknown, TArgs = unknown> =
  | ((path: string) => Validator<T, K>)
  | { validator: (...args: TArgs[]) => Validator<T, K>; args?: TArgs[] };

const getValidator =
  <T = unknown, K = unknown>(path: string) =>
  (validator: ValidatorDefinition<T, K>): Validator<T, K> => {
    if (typeof validator === "object") {
      const args = [path, ...(validator.args || [])];
      return validator.validator(...args);
    }

    return validator(path);
  };

export const createFieldValidator =
  (name: string, ...validationDefs: ValidatorDefinition[]) =>
  (value: unknown) => {
    let error = undefined;

    const validators = validationDefs.map(getValidator(name));
    const allValues = { [name]: value };
    const errors = pipeValidators(...validators)(allValues, allValues);

    if (errors) {
      error = errors[name];
    }

    return error;
  };
