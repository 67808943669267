import { Component, Context } from "react";
import * as React from "react";

import get from "lodash-es/get";
import isEqual from "lodash-es/isEqual";

import { IsUpdatedInterface } from "./IsUpdated.interface";

export interface IsUpdatedConsumerProps<T, S, U> {
  Context: Context<IsUpdatedInterface<T, S, U>>;
  path: string;
  isUpdated: (firstObj: T, secondObj: S) => boolean;
  children: ({
    isUpdated,
    extraValue,
  }: {
    isUpdated: boolean;
    extraValue: U;
  }) => React.ReactNode;
}

export class IsUpdatedConsumer<
  T = unknown,
  S = unknown,
  U = unknown,
> extends Component<IsUpdatedConsumerProps<T, S, U>> {
  isUpdated = (firstObj: T, secondObj: S) => {
    // eslint-disable-next-line babel/no-invalid-this
    const { path } = this.props;

    if (path === undefined) {
      throw new Error("Path has to be defined if default isUpdated used.");
    }

    const firstVal = get(firstObj, path);
    const secondVal = get(secondObj, path);

    return !isEqual(firstVal, secondVal);
  };

  render() {
    const { Context, children } = this.props;

    const isUpdatedFunc = this.props.isUpdated || this.isUpdated;

    return (
      <Context.Consumer>
        {({ firstObj, secondObj, extraValue }) => {
          return children({
            isUpdated: isUpdatedFunc(firstObj, secondObj),
            extraValue,
          });
        }}
      </Context.Consumer>
    );
  }
}
