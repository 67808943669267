// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import { createSelector } from "reselect";

const organizationsSelector = (state) => state.auth.organizations;
export const activeOrganizationIdSelector = (state) => {
  return get(state, "auth.profile.activeOrganizationId");
};

export const getActiveOrganizationSelector = createSelector(
  organizationsSelector,
  activeOrganizationIdSelector,
  (organizations, activeOrganizationId) => organizations[activeOrganizationId]
);
