// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Routes, Navigate, Route } from "react-router-dom";

import { DYNAMIC_FEATURES } from "config";

import { ExtendedRoute } from "containers/ExtendedRoute";
import { RedirectById } from "containers/Layout/SideMenu/redirect/RedirectById";
import { RedirectDependingOnRoles } from "containers/Layout/SideMenu/redirect/RedirectDependingOnRoles";
import { DeviceFilterDetailsLayoutWithData } from "pages/Devices/DeviceFilterDetails";
import { DevicePairingPage } from "pages/Devices/DevicePairing/DevicePairingPage";
import { trans } from "src/translations";

import { DeviceModelFormData } from "./_shared/DeviceModelForm/DeviceModelFormData";
import { CreateDeviceModel } from "./CreateDeviceModel/CreateDeviceModel";
import { DeviceCommandsRoute } from "./DeviceCommands";
import { TAB_ID_DEFAULT } from "./DeviceDetails/constants";
import { DeviceDetailsData } from "./DeviceDetails/DeviceDetailsData";
import { DeviceInventory } from "./DeviceInventory/DeviceInventory";
import { DeviceModelApplicationsData } from "./DeviceModelDetails/DeviceModelApplicationsData";
import { DeviceModelDetailsData } from "./DeviceModelDetails/DeviceModelDetailsData";
import { DeviceModelLayout } from "./DeviceModelDetails/DeviceModelLayout";
import { DeviceQueries } from "./DeviceQueries/DeviceQueries";
import { Devices } from "./Devices/Devices";
import { MyDevices } from "./MyDevices/MyDevices";
import { ProcessingQueue } from "./ProcessingQueue/ProcessingQueue";

export class DevicesRoute extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<RedirectDependingOnRoles />} />
        <Route
          exact
          path="/inventory/:id/details"
          element={
            <RedirectById
              path={`/devices/inventory/:id/details/${TAB_ID_DEFAULT}`}
            />
          }
        />
        <Route
          exact
          path="/inventory/:id/details/:tabId"
          element={
            <ExtendedRoute
              name={trans.DETAILS()}
              component={DeviceDetailsData}
            />
          }
        />
        <Route
          exact
          path="/inventory/:id/details/:tabId/:subTabId"
          element={
            <ExtendedRoute
              name={trans.DETAILS()}
              component={DeviceDetailsData}
            />
          }
        />
        <Route
          exact
          path="/inventory"
          element={
            <ExtendedRoute
              name={trans.DEVICES__DEVICE_INVENTORY_ROUTE_NAME()}
              component={DeviceInventory}
            />
          }
        />
        <Route
          exact
          path="/inventory/alerts"
          element={
            <ExtendedRoute
              name={trans.DEVICES__DEVICE_INVENTORY_ALERTS_ROUTE_NAME()}
              component={DeviceInventory}
            />
          }
        />
        <Route
          exact
          path="/inventory/dismissed"
          element={
            <ExtendedRoute
              name={trans.DEVICES__DEVICE_INVENTORY_DISMISSED_ROUTE_NAME()}
              component={DeviceInventory}
            />
          }
        />
        <Route
          exact
          path="/queue"
          element={
            <ExtendedRoute
              name={trans.DEVICES__PROCESSING_QUEUE_ROUTE_NAME()}
              component={ProcessingQueue}
            />
          }
        />
        <Route
          exact
          path="/models"
          element={
            <ExtendedRoute
              name={trans.DEVICES__DEVICE_LIST_ROUTE_NAME()}
              component={Devices}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/my"
          element={
            <ExtendedRoute
              name={trans.DEVICES__MY_DEVICE_MODELS_ROUTE_NAME()}
              component={MyDevices}
            />
          }
        />

        <Route
          exact
          path="/create"
          element={
            <ExtendedRoute
              name={trans.DEVICES__ADD_DEVICE_MODEL_ROUTE_NAME()}
              component={CreateDeviceModel}
            />
          }
        />
        <Route
          exact
          path="/filters"
          element={
            <ExtendedRoute
              name={trans.DEVICES__FILTERS_AND_QUERIES_ROUTE_NAME()}
              component={DeviceQueries}
            />
          }
        />
        <Route
          exact
          path="/models/:id"
          element={<RedirectById path="/devices/models/:id/details" />}
        />
        <Route
          exact
          path="/my/:id"
          element={<RedirectById path="/devices/my/:id/details" />}
        />
        <Route
          exact
          path="/models/:id/edit"
          element={
            <ExtendedRoute
              name={trans.DEVICES__EDIT_DEVICE_MODEL_ROUTE_NAME()}
              layout={DeviceModelLayout}
              component={DeviceModelFormData}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/my/:id/edit"
          element={<RedirectById path="/devices/models/:id/edit" />}
        />
        <Route
          exact
          path="/models/:id/details"
          element={
            <ExtendedRoute
              name={trans.DEVICES__DEVICE_MODEL_DETAILS_ROUTE_NAME()}
              layout={DeviceModelLayout}
              component={DeviceModelDetailsData}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/my/:id/details"
          element={
            <ExtendedRoute
              name={trans.DEVICES__MY_DEVICE_MODEL_DETAILS_ROUTE_NAME()}
              layout={DeviceModelLayout}
              component={DeviceModelDetailsData}
            />
          }
        />
        <Route
          exact
          path="/models/:id/applications"
          element={
            <ExtendedRoute
              name={trans.DEVICES__DEVICE_MODEL_APPLICATIONS_ROUTE_NAME()}
              layout={DeviceModelLayout}
              component={DeviceModelApplicationsData}
              componentProps={{ forAdmin: true }}
            />
          }
        />
        <Route
          exact
          path="/my/:id/applications"
          element={
            <ExtendedRoute
              name={trans.DEVICES__MY_DEVICE_MODEL_APPLICATIONS_ROUTE_NAME()}
              layout={DeviceModelLayout}
              component={DeviceModelApplicationsData}
            />
          }
        />
        <Route
          exact
          path="/pairing"
          element={
            <ExtendedRoute
              name={trans.DEVICE_PAIRING__ROUTE_NAME()}
              component={DevicePairingPage}
            />
          }
        />
        <Route
          exact
          path="/filters/:id/:tabId"
          element={
            <ExtendedRoute
              name={trans.DEVICES__FILTERS_AND_QUERIES_ROUTE_NAME()}
              component={DeviceFilterDetailsLayoutWithData}
            />
          }
        />
        <Route
          exact
          path="/filters/:id"
          element={<RedirectById path="/devices/filters/:id/details" />}
        />
        {DYNAMIC_FEATURES.DEVICE_COMMANDS === "true" && (
          <Route
            path="/commands/*"
            element={
              <ExtendedRoute
                name={trans.DEVICES__COMMANDS_ROUTE_NAME()}
                component={DeviceCommandsRoute}
              />
            }
          />
        )}

        <Route exact path="*" element={<Navigate to="/404" />} />
      </Routes>
    );
  }
}
