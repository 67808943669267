// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

const createSearchFilters = (filter, availableFilters) => {
  const filterObject = availableFilters.find((e) => e.id === filter.name);

  return filter.values.map((value) => ({
    displayName: get(filterObject, "displayName", filter.name),
    displayValue: value,
  }));
};

export const mapFilterDetailsForSearchBar = (
  details,
  availableFilterSections
) => {
  const availableFilters = availableFilterSections.flatMap(
    (section) => section.options
  );

  return {
    id: details.id,
    name: details.name,
    creationDate: new Date(details.creationDate),
    filtersCount: details.filtersCount,
    query: get(details, "search.query", ""),
    filters: get(details, "search.include", []).flatMap((filter) =>
      createSearchFilters(filter, availableFilters)
    ),
    exclusionFilters: get(details, "search.exclude", []).flatMap((filter) =>
      createSearchFilters(filter, availableFilters)
    ),
  };
};
