// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import * as actions from "./actionTypes";

export const breadcrumbs = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_BREADCRUMB:
      return [...state, { id: action.id, name: action.name, url: action.url }];
    case actions.REMOVE_BREADCRUMB:
      return state.filter((breadcrumb) => {
        return breadcrumb.id !== action.id;
      });
    default:
      return state;
  }
};
