// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import additionalComments from "./additionalCommentsSection";
import changelog from "./changelogSection";
import comment from "./commentSection";

export default {
  name: trans.APP_EDIT_FOR_MODERATORS__TAB(),
  url: "info-for-moderators",
  sections: [comment, additionalComments, changelog],
  dataTestId: "info-for-moderators-tab",
};
