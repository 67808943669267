import { Form } from "formik";
import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { GrabsonIcon } from "components/icons";
import { Section } from "components/layout";
import { trans } from "src/translations";

import { FIELDS } from "../../constants";
import styles from "./FormComponent.scss";
import { ManifestEditField } from "./ManifestEditField";

const renderErrors = (errors) => {
  if (errors?.requestError) {
    return <Info type="error">{trans.DEFAULT_REQUEST_ERROR_MESSAGE()}</Info>;
  }
  if (
    Object.keys(errors).some((field) => Object.keys(FIELDS).includes(field))
  ) {
    return (
      <Info type="error">{trans.DEFAULT_FORM_SUBMIT_ERROR_MESSAGE()}</Info>
    );
  }
  return null;
};

export function FormComponent({
  tabId,
  tabData,
  errorsBanner,
  errors,
  renderButton = () => null,
}) {
  return (
    <Form aria-label="form" className={styles.formContent}>
      {errorsBanner && renderErrors(errors)}
      <Section header={tabData.header()} className={styles.section}>
        {renderButton()}
        {tabData.description && (
          <p className={styles.warning}>
            <GrabsonIcon
              className={styles.warningIcon}
              name="alert-circle"
              font="micro"
              size="xl"
            />
            {tabData.description()}
          </p>
        )}
        {tabData.fields.map((field) => (
          <ManifestEditField key={field.name} {...field} />
        ))}

        {renderButton("bottom")}
      </Section>
      {(tabId === "generic" || tabId === "navigation") &&
        errorsBanner &&
        renderErrors(errors)}
    </Form>
  );
}

FormComponent.propTypes = {
  tabId: PropTypes.string.isRequired,
  tabData: PropTypes.object.isRequired,
  errorsBanner: PropTypes.bool,
  renderButton: PropTypes.func,
  errors: PropTypes.object,
};
