// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { TableBody, TableRow, TableRowColumn } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { TabbedTable } from "containers/TabbedTable/TabbedTable";
import { trans } from "src/translations";

import { PAIRED, WAITING, REJECTED, ERROR } from "./constants";
import styles from "./DevicePairingList.scss";

export class DevicePairingList extends Component {
  static propTypes = {
    pairings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
    count: PropTypes.number,
    loading: PropTypes.bool,
    unpairDevice: PropTypes.func.isRequired,
    error: PropTypes.object,
  };

  static tabsConfig = [
    {
      dataTestId: "device-pairings-list",
      columns: [
        { label: trans.NAME(), id: "name" },
        { label: trans.STATUS(), id: "status" },
        { id: "actionMenu" },
      ],
    },
  ];

  renderTableBody = (devicePairingData) => {
    const { unpairDevice } = this.props;

    return (
      <TableBody>
        {devicePairingData.map(({ id, name, status }) => {
          return (
            <TableRow key={id}>
              <TableRowColumn>{name}</TableRowColumn>
              <TableRowColumn>
                {status === PAIRED && (
                  <span className={styles.paired}>
                    {trans.DEVICE_PAIRING__PAIRED()}
                  </span>
                )}
                {status === WAITING && (
                  <span className={styles.waiting}>
                    {trans.DEVICE_PAIRING__PAIRING_WAITING()}
                  </span>
                )}
                {status === REJECTED && (
                  <span className={styles.error}>
                    {trans.DEVICE_PAIRING__REJECTED()}
                  </span>
                )}
                {status === ERROR && (
                  <span className={styles.error}>
                    {trans.DEVICE_PAIRING__ERROR()}
                  </span>
                )}
              </TableRowColumn>
              <TableRowColumn>
                <ActionMenu>
                  <MenuItem onClick={() => unpairDevice(id)}>
                    {trans.DEVICE_PAIRING__UNPAIR()}
                  </MenuItem>
                </ActionMenu>
              </TableRowColumn>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  render() {
    const { pairings, count, error, loading } = this.props;
    return (
      <TabbedTable
        renderTableBody={this.renderTableBody}
        tabsConfig={DevicePairingList.tabsConfig}
        data={{
          results: pairings,
          count: count,
          loading: loading,
          error: error,
        }}
      />
    );
  }
}
