// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const VERSION_TABS = {
  active: "active",
  latest: "latest",
};

export const VALID_TABS = [VERSION_TABS.active, VERSION_TABS.latest];

export const VERSION_URL_PARAM_NAME = "version";
