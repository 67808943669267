// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { TextareaField } from "components/form/fields";
import { trans } from "src/translations";
import { pipeValidators, isRequired } from "utils/validation";

export default {
  name: trans.APP_EDIT_FOR_MODERATORS__SECTION_NAME(),
  components: [
    {
      component: TextareaField,
      fields: ["metadata.comment"],
      validate: pipeValidators(isRequired("metadata.comment")),
      initialValues: { metadata: { comment: "" } },
      props: () => {
        return {
          name: "metadata.comment",
          label: trans.APP_EDIT_FOR_MODERATORS__DESCRIPTION_LABEL(),
          tooltip: trans.APP_EDIT_FOR_MODERATORS__DESCRIPTION_LABEL_TOOLTIP(),
          dataTestId: "moderator-comments-field",
        };
      },
    },
  ],
};
