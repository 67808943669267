import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { RestrictedArea } from "containers/Permissions";
import { ALL_MODERATORS } from "containers/Permissions/groups";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

import { APP_TYPES } from "../../Applications/constants";
import { ManifestData } from "../_shared/ManifestData";
import { ManifestSummary } from "../_shared/ManifestSummary/ManifestSummary";
import { APP_ID_FORM_FIELDS } from "./components/constants";
import { ManifestForm } from "./components/ManifestForm";

function CreateEditManifestComponent({ getApplication }) {
  const [appIdInitValues, setAppIdInitValues] = useState({
    [APP_ID_FORM_FIELDS.internalApp]: false,
    [APP_ID_FORM_FIELDS.applicationId]: "",
  });
  const [appType, setAppType] = useState(APP_TYPES.HTML);
  const [updating, setUpdating] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getApplication(id)
        .then((res) => {
          setAppIdInitValues({ internalApp: true, applicationId: id });
          setAppType(res.results.type);
        })
        .catch(() =>
          setAppIdInitValues({ internalApp: false, applicationId: id })
        );
    }
  }, [id, getApplication]);

  const updateAppType = useCallback(
    (appId) => {
      setUpdating(true);
      getApplication(appId)
        .then((res) => setAppType(res.results.type))
        .catch(() => {
          /* 404 is returned for external app id's*/
        })
        .finally(() => setUpdating(false));
    },
    [getApplication]
  );

  return (
    <>
      <PageHeader>{trans.APP_MANIFESTS_EDIT_PAGE_HEADER()}</PageHeader>
      <PageContent>
        <RestrictedArea allowed={ALL_MODERATORS} showFallback={true}>
          <ManifestData appId={id ?? ""} appType={appType}>
            <ManifestForm
              appIdInitValues={appIdInitValues}
              updateAppType={updateAppType}
              updating={updating}
            >
              <ManifestSummary />
            </ManifestForm>
          </ManifestData>
        </RestrictedArea>
      </PageContent>
    </>
  );
}

CreateEditManifestComponent.propTypes = {
  getApplication: PropTypes.func.isRequired,
};

export const CreateEditManifest = connect(null, (dispatch) => ({
  getApplication: (appId) =>
    dispatch(
      apiThatThrows.getApplication.action({
        params: { id: appId },
      })
    ),
}))(CreateEditManifestComponent);
