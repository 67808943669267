// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { SearchBar } from "components/elements";
import { Section } from "components/layout";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import {
  DEVICE_PROP_TYPES,
  DeviceInventoryTable,
} from "../_shared/DeviceInventoryTable";
import styles from "./DeviceFilterDetails.scss";

export class DeviceFilterDeviceListTable extends PureComponent {
  static propTypes = {
    results: PropTypes.arrayOf(DEVICE_PROP_TYPES).isRequired,
    loading: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.node,
    searchValue: PropTypes.string,
    changeSearch: PropTypes.func.isRequired,
    refreshList: PropTypes.func.isRequired,
    getCountry: PropTypes.func.isRequired,
  };

  renderRefreshDate = () => {
    const { refreshList } = this.props;
    return (
      <p className={styles.filterDateInformation}>
        {`${trans.DEVICES__FILTERS_DETAILS_DEVICES_TABLE_FILTER_DATE()}`}{" "}
        <span className={styles.bold} data-test-id="refresh-date">
          {formatDateTime(new Date())}
        </span>
        <Button
          className={styles.refreshButton}
          onClick={refreshList}
          dataTestId="refresh-button"
        >
          {trans.DEVICES__FILTERS_DETAILS_DEVICES_TABLE_REFRESH()}
        </Button>
      </p>
    );
  };

  render() {
    const {
      results,
      loading,
      count,
      error,
      searchValue,
      changeSearch,
      getCountry,
    } = this.props;

    return (
      <Fragment>
        <Section header={trans.DEVICES__FILTERS_DETAILS_DEVICES_TABLE_HEADER()}>
          <Section>
            {`${trans.DEVICES__FILTERS_DETAILS_DEVICES_TABLE_FILTER_INFORMATION()}`}
            {this.renderRefreshDate()}
          </Section>
          <SearchBar
            value={searchValue || ""}
            onSearch={changeSearch}
            placeholder={trans.DEVICES__FILTERS_DETAILS_DEVICES_TABLE_SEARCH_BAR_PLACEHOLDER()}
            dataTestId={"filters-details-device-table-search"}
          />
        </Section>
        <DeviceInventoryTable
          results={results}
          count={count}
          loading={loading}
          error={error}
          getCountry={getCountry}
        />
      </Fragment>
    );
  }
}
