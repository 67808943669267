// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./Accordion.scss";

export const Accordion = ({ isExpanded, children, dataTestId }) => {
  return (
    <tr
      className={classes({ [styles.hidden]: !isExpanded })}
      data-test-id={dataTestId}
    >
      <td colSpan={4}>
        <table className={styles.table}>
          <tbody>{children}</tbody>
        </table>
      </td>
    </tr>
  );
};

Accordion.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
};

Accordion.defaultProps = {
  dataTestId: "table-row-unwrapped",
};
