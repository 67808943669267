// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import { v4 } from "uuid";

import {
  DEVICE_TYPE_MODEL,
  DEVICE_TYPE_FAMILY,
  DEVICE_TYPE_DISTRIBUTOR,
  DEVICE_TYPE_QUERY,
  DEVICE_TYPE_NOT_ASSIGNED,
  DEVICE_TYPE_ALL,
} from "../constants";

const getTargetType = (target, devices, mode) => {
  if (mode) {
    return mode;
  }

  if (target.all_devices) {
    return DEVICE_TYPE_ALL;
  }

  if (get(target, "collections", []).length !== 0) {
    return DEVICE_TYPE_QUERY;
  }

  const typeFromDevices = get(devices, "[0].type");
  const typeFromTarget = get(target, "type");
  return typeFromDevices || typeFromTarget || DEVICE_TYPE_NOT_ASSIGNED;
};

const getDevicesAsObject = (type, devices, collections) => {
  const devicesObject = {
    deviceModels: [],
    deviceFamilies: [],
    distributors: [],
  };

  switch (type) {
    case DEVICE_TYPE_MODEL:
      devicesObject.deviceModels = devices;
      break;
    case DEVICE_TYPE_FAMILY:
      devicesObject.deviceFamilies = devices;
      break;
    case DEVICE_TYPE_DISTRIBUTOR:
      devicesObject.distributors = devices;
      break;
    case DEVICE_TYPE_QUERY:
      devicesObject.collections = collections;
      break;
    case DEVICE_TYPE_ALL:
      devicesObject.allDevices = true;
      break;
  }

  return devicesObject;
};

const getCountriesObject = ({ countries, target, collections }) => {
  if (get(collections, "length")) {
    return {};
  }

  if (!isEmpty(countries)) {
    return { countries };
  }

  return { countries: get(target, "countries", []) };
};

export const createTarget = (
  target = {},
  devices = [],
  collections = [],
  countries = [],
  mode
) => {
  const targetType = getTargetType(target, devices, mode);
  const countriesObject = getCountriesObject({
    countries,
    target,
    collections,
  });
  return {
    id: v4(),
    type: targetType,
    ...countriesObject,
    ...getDevicesAsObject(targetType, devices, collections),
  };
};
