// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./TableRowColumn.scss";

/**
 * Very simple table cell data. Corresponds to `<td>`.
 * Should be placed inside `<TableRow>` or `<TableSubRow>`.
 * All additional props for this component will be set on result `<td>` tag.
 */
export class TableRowColumn extends Component {
  static propTypes = {
    /** Content. Can be anything */
    children: PropTypes.node,
    /** Cell can span many columns */
    colspan: PropTypes.number,
    /** Custom user class name. */
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    /** Custom user class name for wrapping div. */
    columnClass: PropTypes.string,
    /**
     * Event handler.
     *
     * Type: `(e: Event) => void`
     */
    onClick: PropTypes.func,
  };

  static defaultProps = {
    dataTestId: "table-column",
  };

  render() {
    const {
      children,
      colspan,
      className,
      dataTestId,
      onClick,
      columnClass,
      ...props
    } = this.props;

    return (
      <td
        data-test-id={dataTestId}
        className={classes(styles.container, columnClass)}
        colSpan={colspan}
        onClick={onClick}
        {...props}
      >
        <div className={classes(className)}>{children}</div>
      </td>
    );
  }
}

export default TableRowColumn;
