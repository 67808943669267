// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { errorPropType } from "./errorPropType";
import { PageContentError } from "./PageContentError";

export const PageError = ({ pageHeader, error, defaultMessage }) => (
  <Fragment>
    <PageHeader>{pageHeader}</PageHeader>
    <PageContent>
      <PageContentError error={error} defaultMessage={defaultMessage} />
    </PageContent>
  </Fragment>
);

PageError.propTypes = {
  pageHeader: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
  error: errorPropType,
};

PageError.defaultProps = {
  defaultMessage: trans.DEFAULT_ERROR_MESSAGE(),
};
