// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import {
  TableRow,
  TableRowColumnExtended,
  TableRowColumn,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { RestrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";

import styles from "./organizationRow.scss";

export const OrganizationRow = ({
  organization,
  onExpandToggle,
  expanded,
  onAddDeviceFamily,
}) => {
  return (
    <TableRow>
      <TableRowColumnExtended
        fontIcon={expanded ? "arrow-up" : "arrow-down"}
        fontIconSize="sm"
        fontIconType="micro"
        className={styles.name}
        onClick={onExpandToggle}
        text={organization.name}
      />
      <TableRowColumn className={styles.menu}>
        <ActionMenu>
          <RestrictedArea
            allowed={[ROLES.administrator.deviceAdmin]}
            showFallback={true}
            fallbackChildren={
              <MenuItem disabled={true}>{trans.DEVICES__ADD_FAMILY()}</MenuItem>
            }
          >
            <MenuItem onClick={onAddDeviceFamily}>
              {trans.DEVICES__ADD_FAMILY()}
            </MenuItem>
          </RestrictedArea>
        </ActionMenu>
      </TableRowColumn>
    </TableRow>
  );
};

OrganizationRow.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onExpandToggle: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  onAddDeviceFamily: PropTypes.func.isRequired,
};
