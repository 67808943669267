// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useField } from "formik";

import { Checkbox } from "components/form/elements";

import { createMixedValidator } from ".";
import { FieldProps } from "./propTypes";

export const CheckboxField = (props) => {
  const { validate } = props;

  const [field, meta] = useField({
    ...props,
    validate: createMixedValidator(props.name, props.required, validate),
    type: "checkbox",
  });
  return <Checkbox {...field} {...meta} {...props} />;
};

CheckboxField.propTypes = FieldProps;
