// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withCountries } from "utils/continents";
import { createBody } from "utils/jsonApi";

import { AppRankRend } from "./AppRankRend";

@restrictedArea(() => ({
  allowed: [ROLES.moderator.moderator, ROLES.moderator.contentManager],
}))
@connect(null, (dispatch) => ({
  getTopMarkets: (applicationId) => {
    return dispatch(
      apiThatThrows.getTopMarkets.action({
        params: { id: applicationId },
      })
    );
  },
  setTopMarkets: (applicationId, body) => {
    return dispatch(
      apiThatThrows.setTopMarkets.action({
        params: { id: applicationId },
        options: { body },
      })
    );
  },
}))
@withCountries
export class AppRankData extends Component {
  static propTypes = {
    // from @withCountries
    allCountries: PropTypes.array.isRequired,
    countriesLoading: PropTypes.bool,
    countriesError: PropTypes.object,

    applicationId: PropTypes.string,
    getTopMarkets: PropTypes.func,
    setTopMarkets: PropTypes.func,
  };
  state = {
    loading: true,
    processing: false,
    error: null,
    formError: null,
    isGlobal: false,
    countries: [],
  };

  async componentDidMount() {
    await this.refreshData();
  }

  refreshData = async () => {
    const { applicationId, getTopMarkets } = this.props;
    try {
      this.setState({ loading: true });

      const { results } = await getTopMarkets(applicationId);
      const { countries, is_global } = results;

      this.setState({
        countries: countries,
        isGlobal: is_global,
        loading: false,
        processing: false,
        error: null,
        formError: null,
      });
      return { countries, is_global };
    } catch (error) {
      this.setState({
        loading: false,
        processing: false,
        error,
      });
      return null;
    }
  };

  onSelectCountries = async (countries) => {
    const { applicationId, setTopMarkets, allCountries } = this.props;
    try {
      this.setState({ processing: true });
      // TODO check why I cannot set proper countries here?
      const isGlobal = allCountries.length === countries.length;
      await setTopMarkets(
        applicationId,
        createBody({
          type: "TopAppsAnnotation",
          countries: isGlobal ? ["GLOBAL"] : countries,
          is_global: isGlobal,
        })
      );
      this.refreshData();
      return countries;
    } catch (error) {
      this.setState({
        processing: false,
        formError: error.message || error,
      });
      return null;
    }
  };

  render() {
    const { allCountries, countriesLoading, countriesError } = this.props;
    const { countries, isGlobal, loading, error, processing, formError } =
      this.state;

    const topMarkets = {
      selection: "countries",
      country_codes: isGlobal
        ? allCountries.map((country) => country.code)
        : countries,
      isGlobal,
    };
    return (
      <div>
        <AppRankRend
          loading={loading || countriesLoading}
          error={error || countriesError}
          processing={processing}
          formError={formError}
          topMarkets={topMarkets}
          allCountries={allCountries}
          onSelectCountries={this.onSelectCountries}
        />
      </div>
    );
  }
}
