// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { withTargetsReceive } from "pages/_shared/TargetsField/decorators/withTargetsReceive";
import { withRouter } from "utils/decorators";

import { adaptDate } from "../_utils/adaptDate";
import { AdminCampaignFormRend } from "./AdminCampaignFormRend";

export const INITIAL_VALUES = {
  name: "",
  description: "",
  score: "0",
  is_score_autogenerated: true,
  is_enabled: true,
  distributor: null,
  scope: {
    start: "",
    end: "",
  },
  targets: [],
  promotions: [],
  selectedPromotions: [],
};

@withRouter
@withActiveOrganization
@restrictedArea(({ forAdmin }) => {
  if (forAdmin) {
    return { allowed: [ROLES.administrator.promotionAdmin] };
  }
  return { allowed: [ROLES.promotionManagement.promotionManager] };
})
@withTargetsReceive({ distributorFieldNameInRequest: "organizations" })
@connect(null, (dispatch, ownProps) => ({
  getCampaign: (id) => {
    const getCampaignAction = ownProps.forAdmin
      ? apiThatThrows.getCampaign.action
      : apiThatThrows.getMyCampaign.action;
    return dispatch(getCampaignAction({ params: { id } }));
  },
  getCampaignTargets: async (campaignId) => {
    const getCampaignTargetsAction = ownProps.forAdmin
      ? apiThatThrows.getCampaignTargetsPaginated.action
      : apiThatThrows.getMyCampaignTargetsPaginated.action;
    return await dispatch(
      getCampaignTargetsAction({
        params: {
          campaignId,
        },
        queryParams: { limit: "nolimit" },
      })
    );
  },
}))
export class AdminCampaignFormData extends Component {
  static propTypes = {
    // from @withRouter
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,

    // from @connect
    getCampaign: PropTypes.func,
    getCampaignTargets: PropTypes.func.isRequired,

    // from @withTargetsReceive:
    getTargetsData: PropTypes.func,

    forAdmin: PropTypes.bool,
  };

  state = {
    initialValues: INITIAL_VALUES,
    loading: false,
    error: null,
  };

  async componentDidMount() {
    await this.updateInitialValues();
  }

  componentDidUpdate(prevProps) {
    const {
      activeOrganization: { id },
      location,
      navigate,
      forAdmin,
    } = this.props;

    if (id !== prevProps.activeOrganization.id) {
      setTimeout(() => {
        navigate(`/promotions${forAdmin ? "/admin" : ""}/campaigns`);
      });
    } else if (location !== prevProps.location) {
      this.updateInitialValues();
    }
  }

  getPlanId() {
    return this.props.params.id;
  }

  isEdit() {
    return typeof this.getPlanId() !== "undefined";
  }

  updateInitialValues = async () => {
    if (this.isEdit()) {
      this.setState({ loading: true });

      try {
        const initialValues = await this.getInitialEditValues();
        this.setState({
          error: null,
          initialValues,
        });
      } catch (error) {
        this.setState({ error });
      } finally {
        this.setState({ loading: false });
      }
    } else {
      this.setState({
        loading: false,
        error: null,
        initialValues: INITIAL_VALUES,
      });
    }
  };

  async getInitialEditValues() {
    const { getCampaign, getCampaignTargets, getTargetsData } = this.props;

    const { results: campaign } = await getCampaign(this.getPlanId());
    const { results: targetsResults } = await getCampaignTargets(campaign.id);

    const targets = await getTargetsData(targetsResults);

    const start = adaptDate(campaign.start_date);
    const end = adaptDate(campaign.end_date);

    return {
      ...campaign,
      scope: {
        start,
        end,
      },
      score: String(campaign.score),
      promotions: campaign.promotions.map((p) => p.id),
      targets: targets,
    };
  }

  refreshData = () => {
    this.updateInitialValues();
  };

  render() {
    const { loading, error, initialValues } = this.state;
    const { forAdmin } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message}</Info>;
    }

    return (
      <AdminCampaignFormRend
        id={this.getPlanId()}
        initialValues={initialValues}
        refreshData={this.refreshData}
        loaded={!loading}
        forAdmin={forAdmin}
      />
    );
  }
}
