// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import defaultIcon from "images/missing_icon.svg";
import { classes } from "utils/classes";

import styles from "./ImgIcon.scss";

export const ImgIcon = ({ src, className }) => {
  return (
    <img
      className={classes(styles.container, className)}
      src={src || defaultIcon}
    />
  );
};

ImgIcon.propTypes = {
  /** link or path to image; placeholder displayed if src not provided */
  src: PropTypes.string,
  className: PropTypes.string,
};

ImgIcon.defaultProps = {
  src: null,
};

export default ImgIcon;
