// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable no-magic-numbers */
import { differenceInCalendarDays } from "date-fns";
import inRange from "lodash-es/inRange";

import {
  DEVICE_TYPE_MODEL,
  DEVICE_TYPE_FAMILY,
  DEVICE_TYPE_DISTRIBUTOR,
} from "pages/_shared/TargetsField/constants";

const SCORES_TABLE = {
  min: 10,
  avg: 20,
  max: 30,
};

const getScoreByTargetType = (target) => {
  switch (target.type) {
    case DEVICE_TYPE_MODEL:
      return SCORES_TABLE.max;
    case DEVICE_TYPE_FAMILY:
      return SCORES_TABLE.avg;
    case DEVICE_TYPE_DISTRIBUTOR:
      return SCORES_TABLE.min;
  }
  return 0;
};

const getScoreByCountriesCount = (target) => {
  if (!target.countries) {
    return 0;
  }

  const countries = target.countries.length;
  if (inRange(countries, 1, 31)) {
    return SCORES_TABLE.max;
  }
  if (inRange(countries, 31, 91)) {
    return SCORES_TABLE.avg;
  }
  if (countries >= 91) {
    return SCORES_TABLE.min;
  }
  return 0;
};

const getScoreByScope = (scope) => {
  if (!scope.end || !scope.start) {
    return 0;
  }

  const timeScope = differenceInCalendarDays(scope.end, scope.start);
  if (inRange(timeScope, 0, 8)) {
    return SCORES_TABLE.max;
  }
  if (inRange(timeScope, 8, 31)) {
    return SCORES_TABLE.avg;
  }
  if (timeScope >= 31) {
    return SCORES_TABLE.min;
  }
  return 0;
};

export const calculateScore = (targets, scope) => {
  if (targets.length === 0) {
    return "0";
  }

  const scoreByScope = getScoreByScope(scope);

  const scores = targets.map((target) => {
    let score = scoreByScope;
    score += getScoreByTargetType(target);
    score += getScoreByCountriesCount(target);
    return score;
  });

  return Math.min(...scores).toString();
};
