// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Status } from "components/feedback";
import { trans } from "src/translations";

const getStatus = (appData) => {
  switch (true) {
    case appData?.has_draft_version:
      return trans.STATUS_DRAFT();
    case appData?.has_submitted_version:
      return trans.STATUS_SUBMITTED();
    case appData?.is_test:
      return trans.STATUS_TEST();
    default:
      return trans.STATUS_NEW();
  }
};

export const StatusBadge = ({ appData }) => {
  const statusInfo = getStatus(appData);
  return statusInfo && <Status dataTestId="app-status">{statusInfo}</Status>;
};

StatusBadge.propTypes = {
  appData: PropTypes.object.isRequired,
};
