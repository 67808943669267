// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";

import { cloneAction, actionList } from "../UpdateJobDetails/constants";
import { cloneUpdateJobFormLocation } from "../UpdateJobForm/utils";

@withRouter
export class UpdateJobListActionMenu extends PureComponent {
  static propTypes = {
    updateJob: PropTypes.shape({
      id: PropTypes.string.isRequired,
      statusActions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    clone: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
  };

  onCloneClick = () => {
    const {
      navigate,
      updateJob: { id },
    } = this.props;
    const { to, state } = cloneUpdateJobFormLocation(id);
    navigate(to, { state });
  };

  render() {
    const {
      openModal,
      clone,
      updateJob: { id, statusActions },
    } = this.props;

    const actionsToRender = actionList.filter(({ key }) =>
      statusActions.includes(key)
    );

    return (
      <ActionMenu>
        <MenuItem to={`/updates/jobs/${id}`} dataTestId="details-item">
          {trans.UPDATES__UPDATE_JOB_LIST__DETAILS_ACTION()}
        </MenuItem>

        {clone && (
          <MenuItem onClick={this.onCloneClick} dataTestId="clone-item">
            {cloneAction.label}
          </MenuItem>
        )}

        {actionsToRender.map(({ key, label }) => (
          <MenuItem
            key={key}
            onClick={() => openModal(id, key)}
            dataTestId={`${key.toLowerCase()}-item`}
          >
            {label}
          </MenuItem>
        ))}
      </ActionMenu>
    );
  }
}
