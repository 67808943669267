// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { TabIndicator } from "components/form";
import { trans } from "src/translations";

import styles from "./FormPagination.scss";

/***
 *
 *   This component can handle use cases as follow :
 *
 *      variant 1 : onSubmit & onSave function are being passed then component will show draftButtons and Submit on the last page
 *      variant 2 : onSubmit & !onSave then component should not render draftButtons and show Submit on the last page ( test app form or structure form )
 *      variant 3 : !onSubmit & onSave then component will render Save on the last page
 *
 *    Another example for variant 1, discard button will be disabled when onDiscard callback is not being passed.
 *    Parent component decides whether to pass callback depending on that the app has draft or not ( see edit snap app or app submission forms )
 *
 *
 */

export const FormPagination = ({
  activeTab,
  dirty,
  discarding,
  saving,
  submitting,
  validatingDraft,
  onDiscard,
  onNext,
  onPrev,
  onSave,
  onSubmit,
  tabCount,
  tabTitle,
  dataTestId = "pagination",
}) => {
  const navigate = useNavigate();
  const goTo = (pathname) => {
    navigate(pathname);
  };
  const disabled = submitting || saving || discarding || validatingDraft;
  const isSaveDraftAsMainAction =
    typeof onSubmit !== "function" && typeof onSave === "function";
  const isLastTab = activeTab === tabCount - 1;

  const getActionButtonConfig = () => {
    switch (true) {
      case !isLastTab:
        return {
          dataTestId: "pagination-next",
          children: trans.NEXT(),
          disabled: disabled || !onNext,
          onClick: () => goTo(onNext()),
        };
      case isSaveDraftAsMainAction:
        return {
          dataTestId: `${dataTestId}-submit-button`,
          children: submitting ? trans.SAVING() : trans.SAVE(),
          disabled: disabled || !onNext,
          onClick: onSave,
        };
      default:
        return {
          dataTestId: `${dataTestId}-submit-button`,
          children: submitting ? trans.SUBMITTING() : trans.SUBMIT(),
          disabled: disabled || !onNext,
          onClick: onSubmit,
        };
    }
  };

  return (
    <div className={styles.pagination}>
      <div>
        {activeTab !== 0 ? (
          <Button
            className={styles.btnPrevious}
            type="wizardNormal"
            onClick={() => goTo(onPrev())}
            size="large"
            disabled={disabled || !onPrev}
            dataTestId="pagination-previous"
          >
            {trans.PREV()}
          </Button>
        ) : null}
      </div>
      <div className={styles.tabName}>{tabTitle}</div>
      <TabIndicator tabsCount={tabCount} activeTab={activeTab} />
      {onSave ? (
        <div className={styles.draftButtonsContainer}>
          {!isSaveDraftAsMainAction && (
            <Button
              type="normal"
              disabled={disabled || !dirty}
              processing={saving}
              onClick={onSave}
              dataTestId={`${dataTestId}-save-draft-button`}
            >
              {saving ? trans.SAVING() : trans.APP_EDIT__DRAFT_SAVE_BUTTON()}
            </Button>
          )}
          <Button
            type="normal"
            onClick={onDiscard}
            disabled={!onDiscard || disabled}
            processing={discarding}
            dataTestId={`${dataTestId}-discard-draft-button`}
          >
            {discarding
              ? trans.DISCARDING()
              : trans.APP_EDIT__DRAFT_DISCARD_BUTTON()}
          </Button>
        </div>
      ) : null}
      <div>
        <Button type="wizardNormal" size="large" {...getActionButtonConfig()} />
      </div>
    </div>
  );
};

FormPagination.propTypes = {
  /** Index of active tab */
  activeTab: PropTypes.number.isRequired,
  /** Names of all tabs */
  tabCount: PropTypes.number.isRequired,
  tabTitle: PropTypes.string.isRequired,
  /** True if form values are not deeply equal from initial values */
  dirty: PropTypes.bool,
  /** Form processing indicators */
  discarding: PropTypes.bool,
  saving: PropTypes.bool,
  submitting: PropTypes.bool,
  validatingDraft: PropTypes.bool,
  /** Discard handler passed when draft form exists. Will discard saved draft state of the form. */
  onDiscard: PropTypes.func,
  /** Function will return pathname for the next page */
  onNext: PropTypes.func,
  /** Function will return pathname for the previous page */
  onPrev: PropTypes.func,
  /** Save handler. Will save draft state of the form. */
  onSave: PropTypes.func,
  /** Submit handler. Trigger a form submission. */
  onSubmit: PropTypes.func,
  /** test id for cypress */
  dataTestId: PropTypes.string,
};
