import { useContext } from "react";

import PropTypes from "prop-types";

import { CodeEditor } from "components/elements";
import { trans } from "src/translations";
import { base64ToText } from "utils/converters";

import { EditorThemeContext } from "../ManifestData";
import { ManifestPreviewModal } from "./ManifestPreviewModal";

export const ManifestSummaryUserScript = ({ value }) => {
  const { editorTheme, toggleTheme } = useContext(EditorThemeContext);

  return (
    <ManifestPreviewModal
      title={trans.APP_DETAILS_MANIFEST__CONFIG_PREVIEW_TITLE()}
    >
      <CodeEditor
        editorTheme={editorTheme}
        toggleTheme={toggleTheme}
        value={base64ToText(value)}
        enableMouseWheel
      />
    </ManifestPreviewModal>
  );
};

ManifestSummaryUserScript.propTypes = {
  value: PropTypes.string.isRequired,
};
