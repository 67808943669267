// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { LineLoader } from "components/elements";

import { Event } from "./Event";
import styles from "./ScheduleChart.scss";
import { ToolBar } from "./ToolBar";
import { Unit } from "./Unit";
import {
  createEventsData,
  createUnits,
  createBeginOfMonthDate,
} from "./utils.js";

export class ScheduleChart extends Component {
  static propTypes = {
    /** Start date of chart */
    chartStartDate: PropTypes.instanceOf(Date),
    /** Chart scale */
    scale: PropTypes.oneOf(["week", "month", "year"]),
    /** Chart data */
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        isEnabled: PropTypes.bool,
      })
    ).isRequired,
    /** Chart header height in px */
    headerHeight: PropTypes.number,
    /** Event height in px */
    eventHeight: PropTypes.number,
    /** Event bottom margin in px */
    eventMargin: PropTypes.number,
    /** Called on event click with event id */
    onEventClick: PropTypes.func,
    /** If true loading indicator is displayed */
    loading: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    scale: "year",
    headerHeight: 80,
    eventHeight: 60,
    eventMargin: 15,
    dataTestId: "schedule-chart",
  };

  state = {
    scale: "year",
    chartStartDate: createBeginOfMonthDate("year", Date.now()),
  };

  componentDidMount() {
    const { scale } = this.props;
    this.setState({ scale });
  }

  componentDidUpdate(prevProps) {
    const { chartStartDate, scale } = this.props;

    if (
      prevProps.scale !== scale ||
      prevProps.chartStartDate !== chartStartDate
    ) {
      this.setState({
        scale,
        chartStartDate: createBeginOfMonthDate(scale, chartStartDate),
      });
    }
  }

  handleSetChartDate = (date) => {
    this.setState(({ scale }) => ({
      chartStartDate: createBeginOfMonthDate(scale, date),
    }));
  };

  handleScaleChange = (scale) => {
    this.setState(({ chartStartDate }) => ({
      scale,
      chartStartDate: createBeginOfMonthDate(scale, chartStartDate),
    }));
  };

  handleEventClick = (id) => {
    this.props.onEventClick(id);
  };

  render() {
    const {
      headerHeight,
      eventHeight,
      eventMargin,
      data,
      loading,
      dataTestId,
    } = this.props;
    const { scale, chartStartDate } = this.state;
    const events = createEventsData(scale, chartStartDate, data);
    const units = createUnits(scale, chartStartDate);
    const eventTotalHeight = eventHeight + eventMargin;
    const chartHeight = eventTotalHeight * events.length + headerHeight;

    return (
      <div data-test-id={dataTestId}>
        <div
          className={styles.actionMenu}
          data-test-id={`${dataTestId}-action-menu`}
        >
          <ToolBar
            scale={scale}
            date={chartStartDate}
            onScaleChange={this.handleScaleChange}
            onDateChange={this.handleSetChartDate}
          />
        </div>
        <div
          className={styles.container}
          style={{ height: `${chartHeight}px` }}
          data-test-id={`${dataTestId}-container`}
        >
          <LineLoader loading={loading} />
          {events.map(
            (
              { id, label, width, left, leftOverflow, status, isEnabled },
              i
            ) => (
              <Event
                key={id}
                id={id}
                label={label}
                width={width}
                left={left}
                hideLeftBorder={leftOverflow}
                top={headerHeight + eventTotalHeight * i}
                height={eventHeight}
                status={status}
                isEnabled={isEnabled}
                onClick={this.handleEventClick}
              />
            )
          )}
          {units.map(({ label, subLabel, width, active, isThisMonth }, i) => (
            <Unit
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              label={label}
              subLabel={subLabel}
              width={width}
              active={active}
              disabled={!isThisMonth}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default ScheduleChart;
