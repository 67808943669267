// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Modal } from "components/layout";
import { trans } from "src/translations";

export class DeleteSalesforceAgreementWarningModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { onClose } = this.props;

    return (
      <Modal
        title={trans.APP_DETAILS_RIGHTS__AGREEMENTS_DELETE()}
        isOpen={true}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.GO_BACK()}
            </Button>
          </ButtonsWrapper>
        }
        size="big"
      >
        <div>
          {trans.APP_DETAILS_RIGHTS__AGREEMENTS_MODAL_DELETE_WARNING_DESCRIPTION()}
        </div>
      </Modal>
    );
  }
}
