// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Radio } from "components/form/elements";
import { Modal } from "components/layout";
import { Header } from "components/typography";
import { RestrictedArea, ORGANIZATION_TYPES } from "containers/Permissions";
import { trans } from "src/translations";

import {
  DEVICE_TYPE_MODEL,
  DEVICE_TYPE_FAMILY,
  DEVICE_TYPE_DISTRIBUTOR,
  DEVICE_TYPE_ALL,
  DEVICE_TYPE_QUERY,
  TARGET_FIELD_MODE,
} from "../constants";
import { TargetFieldModePropType } from "../propTypes";
import { DevicesList } from "./DevicesList";
import styles from "./DevicesModal.scss";

export const DevicesModal = ({
  distributors,
  families,
  isDeviceChecked,
  isOpen,
  mode,
  models,
  onChangeMode,
  onClose,
  onNext,
  onToggleManyDevices,
  selectedDevices,
  selectedQuery,
  onSelectQuery,
  hasNext,
  targetFieldMode,
  loading,
}) => {
  const selectedCount = Object.keys(selectedDevices).length;
  const isAnythingSelected =
    selectedCount !== 0 || Boolean(selectedQuery) || mode === DEVICE_TYPE_ALL;
  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      title={trans.TARGETS__DEVICES_MODAL_TITLE()}
      size="big"
      actions={
        <ButtonsWrapper>
          <Button onClick={onClose}>{trans.CANCEL()}</Button>
          <Button type="green" onClick={onNext} disabled={!isAnythingSelected}>
            {hasNext ? trans.NEXT() : trans.SAVE()}
          </Button>
        </ButtonsWrapper>
      }
    >
      <div className={styles.content} data-test-id="devices-modal">
        <div className={styles.leftSection}>
          <Header
            className={styles.header}
            uppercase={true}
            color="secondary"
            type="h6"
          >
            {trans.TARGETS__DEVICES_MODAL_CONTENT()}
          </Header>
          {targetFieldMode === TARGET_FIELD_MODE.ALL_DEVICES && (
            <Radio
              dataTestId="devices-modal-radio-all"
              onChange={onChangeMode}
              label={trans.TARGETS_ALL_DEVICES()}
              value={mode}
              defaultValue={DEVICE_TYPE_ALL}
              icon="target_boarding"
            />
          )}
          <Radio
            dataTestId="devices-modal-radio-all-from-org"
            onChange={onChangeMode}
            label={trans.TARGETS__ALL_DEVICES_FROM_ORGANIZATION({
              orgName: "",
            })}
            value={mode}
            defaultValue={DEVICE_TYPE_DISTRIBUTOR}
            icon="target_boarding"
          />
          <Radio
            dataTestId="devices-modal-radio-devices"
            onChange={onChangeMode}
            label={trans.TARGETS__DEVICES_MODAL_MODE_DEVICES()}
            value={mode}
            defaultValue={DEVICE_TYPE_MODEL}
            icon="target_boarding"
          />
          <Radio
            dataTestId="devices-modal-radio-families"
            onChange={onChangeMode}
            label={trans.TARGETS__DEVICES_MODAL_MODE_FAMILIES()}
            value={mode}
            defaultValue={DEVICE_TYPE_FAMILY}
            icon="target_boarding"
          />
          <RestrictedArea
            allowedOrganizationTypes={[ORGANIZATION_TYPES.deviceInventory]}
            showFallback={false}
          >
            {targetFieldMode === TARGET_FIELD_MODE.GRANULAR && (
              <Radio
                dataTestId="devices-modal-radio-query"
                onChange={onChangeMode}
                label={trans.TARGETS_DEVICE_UNITS_BY_QUERY()}
                value={mode}
                defaultValue={DEVICE_TYPE_QUERY}
                icon="target_granular"
              />
            )}
          </RestrictedArea>
        </div>
        <div className={styles.rightSection}>
          <DevicesList
            mode={mode}
            loading={loading}
            models={models}
            families={families}
            distributors={distributors}
            isChecked={isDeviceChecked}
            toggleMany={onToggleManyDevices}
            selectedCount={selectedCount}
            selectedQuery={selectedQuery}
            onSelectQuery={onSelectQuery}
          />
        </div>
      </div>
    </Modal>
  );
};

DevicesModal.propTypes = {
  distributors: PropTypes.array,
  families: PropTypes.array,
  isDeviceChecked: PropTypes.func,
  isOpen: PropTypes.bool,
  mode: PropTypes.string,
  models: PropTypes.array,
  onChangeMode: PropTypes.func,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onToggleManyDevices: PropTypes.func,
  selectedDevices: PropTypes.object,
  selectedQuery: PropTypes.object,
  onSelectQuery: PropTypes.func,
  hasNext: PropTypes.bool,
  targetFieldMode: TargetFieldModePropType,
  loading: PropTypes.bool,
};
