// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, PureComponent } from "react";

import { parseISO } from "date-fns";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { SearchBar } from "components/elements";
import { Info } from "components/feedback";
import {
  Section,
  TableBody,
  TableRow,
  TableRowColumn,
  TableRowColumnOverflow,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { TabbedTable } from "containers/TabbedTable";
import { updateJobFormLocationWithTarget } from "pages/Updates/UpdateJobForm/utils";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import { tabConfig } from "./tabConfig";

export const ITEMS_PER_PAGE = 50;

export class DeviceQueriesRend extends PureComponent {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    search: PropTypes.string,

    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        creationDate: PropTypes.string.isRequired,
        filtersCount: PropTypes.number.isRequired,
      })
    ).isRequired,
    count: PropTypes.number.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    isEditSuccessful: PropTypes.bool,
    /** Data order by column */
    defaultOrder: PropTypes.shape({
      sortBy: PropTypes.string,
      sortOrder: PropTypes.string,
    }),
  };

  renderTableRow = ({ id, name, filtersCount, creationDate }) => (
    <TableRow key={id}>
      <TableRowColumnOverflow dataTestId="name-column" overflowText={name}>
        <Link to={`/devices/filters/${id}/details`}>{name}</Link>
      </TableRowColumnOverflow>
      <TableRowColumn dataTestId="filter-quantity-column">
        {filtersCount !== 0
          ? filtersCount
          : trans.DEVICES_FILTERS_DETAILS_ALL_FILTER()}
      </TableRowColumn>
      <TableRowColumn dataTestId="creation-date-column">
        {formatDateTime(parseISO(String(creationDate)))}
      </TableRowColumn>
      <TableRowColumn dataTestId="actions-column">
        <ActionMenu alignment={"bottom"}>
          <MenuItem datatestId="details-item">
            <Link to={`/devices/filters/${id}/details`}>
              {trans.DEVICES__DEVICE_FILTERS_DETAILS()}
            </Link>
          </MenuItem>
          <MenuItem datatestId="create-update-job-item">
            <Link {...updateJobFormLocationWithTarget(id, name)}>
              {trans.DEVICES__DEVICE_FILTERS_CREATE_UPDATE()}
            </Link>
          </MenuItem>
        </ActionMenu>
      </TableRowColumn>
    </TableRow>
  );

  renderTableBody = (rows) => (
    <TableBody>{rows.map((row) => this.renderTableRow(row))}</TableBody>
  );

  renderCreateFilter = () => (
    <ButtonsWrapper>
      <Button
        type="normal"
        to="/devices/inventory"
        dataTestId="create-query-button"
      >
        {trans.DEVICES__DEVICE_FILTERS_CREATE()}
      </Button>
    </ButtonsWrapper>
  );

  render() {
    const {
      onSearch,
      search,
      results,
      count,
      loading,
      error,
      isEditSuccessful,
      defaultOrder,
    } = this.props;
    return (
      <Fragment>
        <Section>
          <SearchBar
            value={search}
            onSearch={onSearch}
            placeholder={trans.DEVICES__DEVICE_FILTERS_SEARCH_PLACEHOLDER()}
            dataTestId={"filter-search"}
          />
        </Section>
        <Section>
          {isEditSuccessful && (
            <Info dataTestId={"info-success"}>
              {trans.DEVICES__DEVICE_FILTERS_EDIT_FILTER_SUCCESS_INFO()}
            </Info>
          )}
          {this.renderCreateFilter()}
          <TabbedTable
            data={{
              results,
              count,
              loading,
              error,
              defaultOrder,
            }}
            tabsConfig={tabConfig}
            renderTableBody={this.renderTableBody}
            noResultsMessage={trans.DEVICES__DEVICE_FILTERS_NO_RESULTS()}
            rowsPerPage={ITEMS_PER_PAGE}
            layout="fixed"
          />
          {this.renderCreateFilter()}
        </Section>
      </Fragment>
    );
  }
}
