// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { ROLES } from "containers/Permissions";
import { trans } from "src/translations";

export const roleDescriptionMap = {
  [ROLES.organization.owner]: trans.ROLE_ORGANIZATION_OWNER(),
  [ROLES.organization.roleManager]: trans.ROLE_ORGANIZATION_ROLE_MANAGER(),
  [ROLES.organization.member]: trans.ROLE_ORGANIZATION_MEMBER(),
  [ROLES.provider.creator]: trans.ROLE_PROVIDER_CREATOR(),
  [ROLES.provider.signatory]: trans.ROLE_PROVIDER_SIGNATORY(),
  [ROLES.moderator.appIntegrator]: trans.ROLE_MODERATOR_APP_INTEGRATOR(),
  [ROLES.moderator.moderator]: trans.ROLE_MODERATOR_MODERATOR(),
  [ROLES.moderator.contentManager]: trans.ROLE_MODERATOR_CONTENT_MANAGER(),
  [ROLES.administrator.browserConfigAdmin]: trans.ROLE_BROWSER_CONFIG_ADMIN(),
  [ROLES.administrator.vewdIDsManager]: trans.ROLE_VEWD_IDS_MANAGER(),
  [ROLES.administrator.organizationAdmin]:
    trans.ROLE_ADMINISTRATOR_ORGANIZATION_ADMIN(),
  [ROLES.administrator.distributionAdmin]:
    trans.ROLE_ADMINISTRATOR_DISTRIBUTION_ADMIN(),
  [ROLES.administrator.deviceAdmin]: trans.ROLE_ADMINISTRATOR_DEVICE_ADMIN(),
  [ROLES.administrator.promotionAdmin]:
    trans.ROLE_ADMINISTRATOR_PROMOTION_ADMIN(),
  [ROLES.administrator.userAdmin]: trans.ROLE_ADMINISTRATOR_USER_ADMIN(),
  [ROLES.distributor.distributionPlanner]:
    trans.ROLE_DISTRIBUTOR_DISTRIBUTION_PLANNER(),
  [ROLES.distributor.deviceManager]: trans.ROLE_DISTRIBUTOR_DEVICE_MANAGER(),
  [ROLES.softwareUpdates.updateManager]:
    trans.ROLE_SOFTWARE_UPDATES_UPDATE_MANAGER(),
  [ROLES.deviceInventory.deviceManager]:
    trans.ROLE_DEVICE_INVENTORY_DEVICE_MANAGER(),
  [ROLES.promotionManagement.promotionManager]:
    trans.ROLE_PROMOTION_MANAGEMENT_PROMOTION_MANAGER(),
};
