// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { VersionContext } from "./_shared/IsVersionUpdated/context";
import { createBasicDetailsDiff } from "./_utils/basicDetails";

export class BasicDetailsDiffProvider extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  renderDetails = (contextValues) => {
    const { children } = this.props;
    const { firstObj: activeVersion, secondObj: latestVersion } = contextValues;

    const changes = createBasicDetailsDiff(activeVersion, latestVersion);

    return children(changes);
  };

  render() {
    return (
      <VersionContext.Consumer>{this.renderDetails}</VersionContext.Consumer>
    );
  }
}
