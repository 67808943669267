// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./searchableListFooter.scss";

/**
 * Footer for `<SearchableList>`. This component is not required to use,
 * but contains some useful styles that are common through our app.
 */
export class SearchableListFooter extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "list-footer",
  };

  render() {
    const { children, className, dataTestId } = this.props;
    return (
      <div
        className={classes(styles.wrapper, className)}
        data-test-id={dataTestId}
      >
        {children}
      </div>
    );
  }
}
