// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { Section, Subsection } from "components/layout";
import { trans } from "src/translations";

import { AppEnvironmentStatuses } from "../../_shared/AppEnvironmentStatuses";

export class SectionEnvironments extends Component {
  static propTypes = {
    application: PropTypes.object.isRequired,
    appVersion: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    onApproveAppEnvironment: PropTypes.func.isRequired,
    onRejectAppEnvironment: PropTypes.func.isRequired,
  };

  render() {
    const {
      application,
      appVersion,
      loading,
      error,
      onApproveAppEnvironment,
      onRejectAppEnvironment,
    } = this.props;

    return (
      <Section
        header={trans.APP_DETAILS_MODERATION_TARGETS__ENVIRONMENTS_SECTION_HEADER()}
      >
        <Subsection header={trans.APP_DETAILS_MODERATION__APP_ENVIRONMENT()}>
          {loading && <Loader />}
          {!loading && error && <Info type="error">{error.message}</Info>}
          {!loading && !error && (
            <AppEnvironmentStatuses
              appType={application.type}
              environments={get(appVersion, "environments", {})}
              profiles={appVersion.profiles}
              onApprove={onApproveAppEnvironment}
              onReject={onRejectAppEnvironment}
              readOnly={false}
            />
          )}
        </Subsection>
      </Section>
    );
  }
}
