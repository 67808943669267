// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Field } from "formik";
import PropTypes from "prop-types";

import { Input } from "components/form/elements";
import { innerRefType } from "utils/types";

import { ACTION_VALUES, FIELDS } from "../constants";

export const ActionCustomField = ({
  isDisabled,
  name,
  reference,
  touched,
  setTouched,
}) => (
  <Field name={`${name}.${FIELDS.ACTION_CUSTOM.name}`}>
    {({ field, meta, form: { setFieldValue } }) => {
      /** Touched state of this field needs to be managed in a custom way
       * due to issue with 'touched' flow when it's left for Formik
       * this is most likely caused by the conditional nature of the field
       */
      const props = {
        ...field,
        onBlur: () => {
          setTouched(true);
        },
      };

      return (
        <Input
          {...FIELDS.ACTION_CUSTOM}
          {...meta}
          props={props}
          touched={touched}
          disabled={isDisabled}
          onFocus={() => {
            setFieldValue(
              `${name}.${FIELDS.ACTION.name}`,
              ACTION_VALUES.CUSTOM
            );
          }}
          reference={reference}
        />
      );
    }}
  </Field>
);

ActionCustomField.propTypes = {
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  reference: innerRefType,
  touched: PropTypes.bool.isRequired,
  setTouched: PropTypes.func.isRequired,
};
