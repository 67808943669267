// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./validationBadge.scss";

/** This component is mostly used by already created form field components. */
export class ValidationBadge extends Component {
  static propTypes = {
    error: PropTypes.string,
    errorId: PropTypes.string,
    touched: PropTypes.bool,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "validation-badge",
  };

  render() {
    const { error, errorId, touched, className, dataTestId } = this.props;

    return (
      <div
        data-test-id={dataTestId}
        className={classes(styles.validationBadge, className)}
      >
        {error && touched ? (
          <p
            role="alert"
            data-test-id={`${dataTestId}-text`}
            {...(errorId ? { id: errorId } : {})}
          >
            {error}
          </p>
        ) : null}
      </div>
    );
  }
}
