// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";

import queryString from "query-string";

export const useToggle = (initialState = false): [boolean, () => void] => {
  const [state, setState] = useState<boolean>(initialState);
  const toggle = useCallback((): void => setState((state) => !state), []);
  return [state, toggle];
};

export const useSearchParams = () => {
  const location = useLocation();
  const memoizedParams = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );
  return memoizedParams;
};

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = () => setIsOpen(false);
  const handleOpenModal = () => setIsOpen(true);

  return [isOpen, handleCloseModal, handleOpenModal];
};
