// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { PageContentError } from "components/layout";
import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";
import { urlToBase64 } from "utils/converters";
import { withRouter } from "utils/decorators";

import { FormBasicInfo } from "./FormBasicInfo/FormBasicInfo";
import { FormVersions } from "./FormVersions/FormVersions";
import { DevicesModalContainer } from "./ModalDevices/DevicesModalContainer";
import { ModalRegions } from "./ModalRegions/ModalRegions";

const DEFAULT_VERSION = {
  number: "1.0.0",
  url: "",
  isSaved: false,
  isDefault: true,
};

@withRouter
@restrictedArea(() => ({
  allowed: [ROLES.administrator.distributionAdmin],
}))
@connect(null, (dispatch) => ({
  getAsset: (assetId) =>
    dispatch(apiThatThrows.getAsset.action({ params: { assetId } })),
  patchAsset: (assetId, body) =>
    dispatch(
      apiThatThrows.patchAsset.action({
        params: { assetId },
        options: { body },
      })
    ),
  postAsset: (body) =>
    dispatch(apiThatThrows.postAsset.action({ options: { body } })),
  postVersions: (assetId, body) =>
    dispatch(
      apiThatThrows.postAssetVersions.action({
        params: { assetId },
        options: { body },
      })
    ),
}))
export class AssetFormContent extends Component {
  static propTypes = {
    // from @withRouter
    params: PropTypes.object,
    navigate: PropTypes.func,

    // from @connect
    getAsset: PropTypes.func,
    patchAsset: PropTypes.func,
    postAsset: PropTypes.func,
    postVersions: PropTypes.func,
  };

  state = {
    asset: {
      id: "",
      name: "",
      description: "",
      icon: null,
    },
    error: null,
    versions: [],
    loading: false,
  };

  componentDidMount() {
    this.refreshData();
  }

  getAssetId = () => {
    return this.props.params.id;
  };

  refreshData = async () => {
    if (this.isEdit()) {
      const { getAsset } = this.props;

      try {
        this.setState({ loading: true, error: null });

        const assetId = this.getAssetId();
        const { results } = await getAsset(assetId);
        const asset = this.mapAsset(results);
        const versions = results.versions.map(this.mapVersion);

        this.setState({
          asset: asset,
          versions: this.getInitialVersions(versions),
        });
      } catch (err) {
        this.setState({ error: err });
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  mapAsset({ id, name, icon, description }) {
    return {
      id,
      name,
      icon,
      description,
    };
  }

  mapVersion({ label: number, url, is_default: isDefault }) {
    return { number, url, isDefault, isSaved: true };
  }

  async createBody({ title, description, icon }) {
    return JSON.stringify({
      data: {
        type: "software_assets",
        attributes: {
          name: title,
          description: description,
          icon: await urlToBase64(icon.url),
        },
      },
    });
  }

  getInitialVersions = (versions) => {
    return versions.length === 0 ? [DEFAULT_VERSION] : versions;
  };

  handlePatch = async (event) => {
    const { patchAsset } = this.props;
    const assetId = this.getAssetId();
    const body = await this.createBody(event);
    const result = await patchAsset(assetId, body);
    this.setState({
      asset: this.mapAsset(result.results),
    });
  };

  handlePost = async (event) => {
    const { postAsset, navigate } = this.props;
    const body = await this.createBody(event);
    const result = await postAsset(body);
    const versions = result.results.versions;
    this.setState({
      asset: this.mapAsset(result.results),
      versions: this.getInitialVersions(versions),
    });
    navigate(`/software-assets/${result.results.id}`);
  };

  handleVersionsPost = async ({ versions }) => {
    const { postVersions } = this.props;
    const { asset } = this.state;

    const body = JSON.stringify({
      data: versions.map((v) => ({
        type: "software_asset_versions",
        attributes: {
          label: v.number,
          url: v.url,
          is_default: v.isDefault,
        },
      })),
    });

    const result = await postVersions(asset.id, body);
    this.setState({
      versions: result.results.map(this.mapVersion),
    });
  };

  isEdit() {
    const assetId = this.getAssetId();
    return Boolean(assetId);
  }

  render() {
    const { asset, error, versions, loading } = this.state;
    const isEdit = this.isEdit();

    if (loading) {
      return <Loader message={`${trans.LOADING()}...`} />;
    }

    if (error) {
      return <PageContentError error={error} />;
    }

    return (
      <div>
        <ModalRegions />
        <DevicesModalContainer />
        <FormBasicInfo
          isEdit={isEdit}
          onPatch={this.handlePatch}
          onPost={this.handlePost}
          initialValues={{
            id: asset.id,
            title: asset.name,
            description: asset.description,
            icon: {
              url: asset.icon,
              status: {
                addButton: true,
                progressBar: "",
                progress: false,
                removeButton: false,
                removeButtonDisabled: false,
              },
              loaded: 0,
              total: 100,
            },
          }}
          refreshData={this.refreshData}
        />
        {isEdit && (
          <FormVersions
            initialValues={{
              versions: versions,
            }}
            onVersionsPost={this.handleVersionsPost}
            asset={asset}
            versions={versions}
          />
        )}
      </div>
    );
  }
}
