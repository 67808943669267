// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const getOrganizationName = (organizationId, organizations) => {
  const organizationData = organizations.find(
    (o) => organizationId.toString() === o.id.toString()
  );
  return organizationData ? organizationData.name : "";
};
