// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable babel/no-invalid-this */

/* eslint no-invalid-this: 0 */

/* eslint no-loop-func: 0 */
import { Component } from "react";

import * as d3 from "d3";
import { geoCylindricalStereographic } from "d3-geo-projection";
import PropTypes from "prop-types";

import { CONTINENT_WORLD, CONTINENT_EUROPE } from "utils/continents";

import styles from "./GeoMapShape.scss";
import { prepareContinentsForD3 } from "./utils/prepareContinentsForD3";

const EUROPE_VIEWPORT_OFFSET_X = 60;
// this will force to decide viewport based on height
const EUROPE_VIEWPORT_IGNORE_WIDTH = 1;

const ANIMATION_ZOOM_SHOW_WORLD_DURATION = 200;
const ANIMATION_ZOOM_HIDE_DURATION = 200;
const ANIMATION_ZOOM_SHOW_DURATION = 400;
const PROJECTION_SCALE = 100;

export class GeoMapShape extends Component {
  static propTypes = {
    displayedContinent: PropTypes.string,
    allCountries: PropTypes.array,
    markedCountries: PropTypes.array,
    disabledCountries: PropTypes.array,
    onCountrySelected: PropTypes.func.isRequired,
  };

  height = null;
  width = null;
  worldDefaultTransformation = null;

  static defaultProps = {
    displayedContinent: CONTINENT_WORLD,
    allCountries: [],
    markedCountries: [],
    disabledCountries: [],
  };

  componentDidMount() {
    const { allCountries } = this.props;
    const self = this;

    const projection = geoCylindricalStereographic().scale(PROJECTION_SCALE);
    const path = d3.geoPath().projection(projection);
    const svg = d3.select(this.svg);
    this.width = svg.node().getBoundingClientRect().width;
    this.height = svg.node().getBoundingClientRect().height;
    svg
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("class", styles.worldmap);

    this.group = svg.append("g").attr("width", "100%").attr("height", "100%");

    const removeSpaces = (str) => str.replace(" ", "");

    const continents = prepareContinentsForD3(allCountries);

    this.group
      .selectAll(`.${styles.continent}`)
      .data(continents)
      .enter()
      .call((continentSelection) => {
        return continentSelection
          .append("g")
          .attr(
            "class",
            (selectedCountinent) =>
              `${styles.continent} ${removeSpaces(selectedCountinent.name)}`
          )
          .selectAll(`.${styles.country}`)
          .data((selectedCountry) => selectedCountry.features)
          .enter()
          .insert("path")
          .attr("class", styles.country)
          .attr("data-continent", (selectedCountry) =>
            removeSpaces(selectedCountry.properties.continent)
          )
          .attr("class", (selectedCountry) => {
            const { code } = selectedCountry.properties;
            if (!code) {
              return styles.countryDisabled;
            }
            if (this.isCountryMarked(code)) {
              return `${styles.country} ${styles.countrySelected}`;
            }
            return styles.country;
          })
          .attr("d", path)
          .attr("id", (selectedCountry) => `country-${selectedCountry.id}`)
          .on("click", self.handleCountrySelected)
          .on("mouseover", self.handleCountryHover)
          .on("mouseout", self.handleCountryOut);
      });

    this.fitWorld();
    this.zoomContinentToWorld();
  }

  componentDidUpdate(prevProps) {
    const { markedCountries, disabledCountries, displayedContinent } =
      this.props;

    if (markedCountries !== prevProps.markedCountries) {
      this.updateSelectedCountries();
    }

    if (disabledCountries !== prevProps.disabledCountries) {
      this.updateDisabledCountries();
    }

    if (prevProps.displayedContinent !== displayedContinent) {
      if (displayedContinent === CONTINENT_WORLD) {
        this.zoomContinentToWorld();
      } else {
        this.zoomWorldToContinent(displayedContinent);
      }
    }
  }

  createTransformFromBBox = (bBox) => {
    const { x, y } = bBox;
    const { width: imgWidth, height: imgHeight } = this;

    const imgCenter = [0.5 * imgWidth, 0.5 * imgHeight];
    const scaleFactor = Math.min(
      imgWidth / bBox.width,
      imgHeight / bBox.height
    );
    const translateX = imgCenter[0] - scaleFactor * (x + 0.5 * bBox.width);
    const translateY = imgCenter[1] - scaleFactor * (y + 0.5 * bBox.height);

    return `translate(${translateX},${translateY}) scale(${scaleFactor})`;
  };

  getContinentBBox(continentName, continentD3) {
    let bBox = continentD3.getBBox();

    if (continentName === CONTINENT_EUROPE) {
      // If we have left the default values, Russia would hog the view
      // and would make other countries very small in comparison.
      // Since the problem is only due to width, we will force
      // zoom calculations based only on height.
      // Multiply bBox.height by e.g. 0.75 to zoom even more
      bBox = {
        x: bBox.x + EUROPE_VIEWPORT_OFFSET_X,
        y: bBox.y,
        height: bBox.height,
        width: EUROPE_VIEWPORT_IGNORE_WIDTH,
      };
    }

    return bBox;
  }

  zoomWorldToContinent(continent) {
    this.group.transition().attr("transform", "");
    const self = this;

    const limitToSelected = (expectedIsSelected) => (c) => {
      const isSelected = c.name === continent;
      return isSelected === expectedIsSelected;
    };

    // select not choosen continents and hide them
    this.group
      .selectAll(`.${styles.continent}`)
      .filter(limitToSelected(false))
      .classed(styles.unfocused, true)
      .transition()
      .duration(ANIMATION_ZOOM_HIDE_DURATION)
      .attr("transform", "");

    // select choosen continent and zoom onto it
    this.group
      .selectAll(`.${styles.continent}`)
      .filter(limitToSelected(true))
      .attr("class", `${styles.continent} ${styles.focused}`)
      .transition()
      .duration(ANIMATION_ZOOM_SHOW_DURATION)
      .attr("transform", function () {
        const bBox = self.getContinentBBox(continent, this);
        return self.createTransformFromBBox(bBox);
      });
  }

  zoomContinentToWorld() {
    this.group
      .selectAll(`.${styles.continent}`)
      .transition()
      .duration(ANIMATION_ZOOM_SHOW_WORLD_DURATION)
      .attr("transform", "")
      .each(() => {
        this.group
          .selectAll(`.${styles.continent}`)
          .attr(
            "class",
            (selectedContinent) =>
              `${styles.continent} ${selectedContinent.name.replace(" ", "")}`
          );
        this.updateSelectedCountries();
      });
    this.fitWorld();
  }

  // Center map
  fitWorld() {
    let { worldDefaultTransformation } = this;
    const self = this;

    if (!worldDefaultTransformation) {
      this.group.attr("transform", function () {
        const bBox = this.getBBox();
        worldDefaultTransformation = self.createTransformFromBBox(bBox);
      });
    } else {
      this.group.transition().attr("transform", worldDefaultTransformation);
    }
    this.worldDefaultTransformation = worldDefaultTransformation;
  }

  isCountryDisabled = (countryCode) => {
    const { disabledCountries, allCountries } = this.props;
    return (
      disabledCountries.some((code) => code === countryCode) ||
      !allCountries.some((c) => c.code === countryCode)
    );
  };

  isCountryMarked = (countryCode) => {
    const { markedCountries } = this.props;
    return markedCountries.some((code) => code === countryCode);
  };

  updateDisabledCountries() {
    this.group
      .selectAll(`.${styles.country}`)
      .filter((country) => {
        return this.isCountryDisabled(country.properties.code)
          ? country
          : false;
      })
      .attr("class", `${styles.country} ${styles.countrySelectedDisabled}`);
  }

  updateSelectedCountries() {
    this.group
      .selectAll(`.${styles.country}`)
      .attr("class", styles.country)
      .filter((selectedCountry) => {
        const { code } = selectedCountry.properties;

        if (this.isCountryDisabled(code)) {
          this.updateDisabledCountries();
          return false;
        }

        if (this.isCountryMarked(code)) {
          return selectedCountry;
        }
        return false;
      })
      .attr("class", `${styles.country} ${styles.countrySelected}`);
  }

  handleCountryOut = (event, country) => {
    this.group
      .select(`#country-${country.id}`)
      .classed(styles.countryFocused, false);
  };

  handleCountryHover = (event, country) => {
    if (country.properties.code) {
      this.group
        .select(`#country-${country.id}`)
        .classed(styles.countryFocused, true);
    }
  };

  handleCountrySelected = (event, selectedCountry) => {
    const { onCountrySelected } = this.props;
    const code = selectedCountry.properties.code;
    if (this.isCountryDisabled(code)) {
      return false;
    }
    return onCountrySelected(code);
  };

  render() {
    return (
      <div className={styles.mapSvg}>
        <svg
          id="svg"
          width="600"
          height="300"
          ref={(c) => {
            this.svg = c;
          }}
        />
      </div>
    );
  }
}
