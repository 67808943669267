// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const UPDATE_TARGETS = "selected-targets/UPDATE_TARGETS";

export const TOGGLE_DEVICE_MODEL = "selected-targets/TOGGLE_DEVICE_MODEL";
export const TOGGLE_DEVICE_FAMILY = "selected-targets/TOGGLE_DEVICE_FAMILY";
export const TOGGLE_DISTRIBUTOR = "selected-targets/TOGGLE_DISTRIBUTOR";
export const DESELECT_ALL = "selected-targets/DESELECT_ALL";
