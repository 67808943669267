// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const cloneUpdateJobFormLocation = (id) => ({
  to: "/updates/create",
  state: {
    updateJobId: id,
  },
});

export const updateJobFormLocationWithTarget = (id, name) => ({
  to: "/updates/create",
  state: {
    targetDevices: {
      id,
      name,
    },
  },
});

export const updateJobFormLocationWithVersion = (
  versionId,
  packageName,
  versionName
) => ({
  to: "/updates/create",
  state: {
    softwarePackageVersion: {
      id: versionId,
      packageName,
      versionName,
    },
  },
});
