// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import chunk from "lodash-es/chunk";
import get from "lodash-es/get";
import PropTypes from "prop-types";
import queryString from "query-string";
import { v4 as uuid } from "uuid";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { api } from "containers/Request";
import { withRouter } from "utils/decorators/withRouter";

import { DevicesRend } from "./DevicesRend";

export const ITEMS_PER_PAGE = 10;

@withRouter
@withActiveOrganization
@restrictedArea(() => {
  return {
    allowed: [ROLES.administrator.deviceAdmin, ROLES.moderator.moderator],
  };
})
@connect(null, (dispatch) => ({
  getDeviceOwners: () =>
    dispatch(
      api.getDeviceOwners.action({
        queryParams: {
          include: ["device_families", "device_models"],
          nocache: uuid(),
        },
      })
    ),
  getOrganizations: (ids) =>
    dispatch(
      api.getOrganizationsPaginated.action({
        queryParams: { id: ids, limit: ids.length, page: 1 },
      })
    ),
}))
export class DevicesData extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,

    // from @connect
    getDeviceOwners: PropTypes.func.isRequired,
    getOrganizations: PropTypes.func.isRequired,

    // from @withRouter
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    forAdmin: false,
  };

  state = {
    rawResults: [],
    results: [],
    count: 0,
    error: undefined,
    loading: true,
  };

  async componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState((prevState) =>
        this.processRawResults(prevState.rawResults)
      );
    }
  }

  createDeviceModelData = (model) => ({
    name: model.name,
    id: model.id,
    isTesting: model.is_testing,
    isDisabled: !model.is_enabled,
  });

  createDeviceFamilyData = (owner, family) => {
    const isModelFromFamily = (model) => model.device_family.id === family.id;

    const devices = owner.device_models
      .filter(isModelFromFamily)
      .map(this.createDeviceModelData);

    return {
      name: family.name,
      id: family.id,
      devices,
    };
  };

  createOwnerData(name, owner) {
    const families = owner.device_families.map((family) => {
      return this.createDeviceFamilyData(owner, family);
    });

    return {
      name,
      id: owner.id,
      families,
    };
  }

  processRawResults(rawResults) {
    const filteredResults = this.searchDeviceModelAndFamily(rawResults);
    return {
      results: chunk(filteredResults, ITEMS_PER_PAGE),
      count: filteredResults.length,
    };
  }

  searchDeviceModelAndFamily(rawResults) {
    const { location } = this.props;
    const { search } = queryString.parse(location.search);
    if (!search) {
      return rawResults;
    }
    const filteredOwnersData = [];
    const searchTerm = search.toLowerCase();

    rawResults.forEach((rawResult) => {
      const ownerData = {
        ...rawResult,
        families: rawResult.families.reduce((array, family) => {
          if (!family) {
            return array;
          }

          if (family.name.toLowerCase().includes(searchTerm)) {
            array.push(family);
          } else {
            const filteredFamily = {
              ...family,
              devices: family.devices.filter((device) =>
                device.name.toLowerCase().includes(searchTerm)
              ),
            };
            if (filteredFamily.devices.length) {
              array.push(filteredFamily);
            }
          }

          return array;
        }, []),
      };

      if (ownerData.families.length) {
        filteredOwnersData.push(ownerData);
      }
    });

    return filteredOwnersData;
  }

  getOrganizations = async (orgIds) => {
    const { getOrganizations } = this.props;

    if (!orgIds || orgIds.length === 0) {
      return { result: [] };
    }

    return await getOrganizations(orgIds);
  };

  loadData = async () => {
    const { getDeviceOwners } = this.props;
    this.setState({ loading: true });

    const { error, result } = await getDeviceOwners();

    if (error) {
      this.setState({ error: error, loading: false });
      return;
    }
    const { results: ownersResults } = result;

    const { error: orgError, result: orgResult } = await this.getOrganizations(
      ownersResults.map((o) => o.id)
    );

    if (orgError) {
      this.setState({ error: orgError, loading: false });
      return;
    }

    const getOwnerName = (ownerId) => {
      const asOrganization = orgResult.results.find((o) => o.id === ownerId);
      return get(asOrganization, "name", "");
    };

    const rawResults = ownersResults.map((owner) => {
      return this.createOwnerData(getOwnerName(owner.id), owner);
    });

    const { results, count } = this.processRawResults(rawResults);

    this.setState({
      rawResults,
      results,
      count,
      error: undefined,
      loading: false,
    });
  };

  render() {
    const { results, count, error, loading } = this.state;
    const { forAdmin, location } = this.props;
    const query = queryString.parse(location.search);
    const page = (parseInt(query.page, 10) || 1) - 1;
    const paginatedResult = results[page] || [];

    return (
      <DevicesRend
        results={paginatedResult}
        count={count}
        error={error}
        loading={loading}
        forAdmin={forAdmin}
        refreshData={this.loadData}
      />
    );
  }
}
