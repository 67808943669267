// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { SelectField } from "components/form/fields";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";
import { sortAsc } from "utils/array";

@connect(null, (dispatch) => ({
  getApplicationCategories: () =>
    dispatch(
      apiThatThrows.getApplicationCategoriesPaginated.action({
        queryParams: { limit: "nolimit" },
        cache: true,
      })
    ),
}))
export class Category extends Component {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    tooltip: PropTypes.string,
    initOption: PropTypes.object,
    sort: PropTypes.func,

    // from @connect
    getApplicationCategories: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: trans.CATEGORY(),
    required: true,
    tooltip: trans.APP_EDIT__CATEGORY_TOOLTIP(),
    initOption: { value: -1, text: trans.CHOOSE_CATEGORY() },
    sort: sortAsc,
  };

  state = {
    loading: true,
    error: null,
    categories: [],
  };

  componentDidMount() {
    this.updateData();
  }

  async updateData() {
    const { getApplicationCategories } = this.props;

    const createCategory = (category) => ({
      value: category.id,
      text: category.human_name,
    });

    try {
      this.setState({ loading: true });

      const { results } = await getApplicationCategories();
      this.setState({
        categories: results.map(createCategory),
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { label, required, tooltip, initOption, sort, fieldName } =
      this.props;
    const { loading, error, categories } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message}</Info>;
    }

    return (
      <SelectField
        name={fieldName}
        label={label}
        required={required}
        tooltip={tooltip}
        initOption={initOption}
        values={categories}
        sort={sort}
        dataTestId="category-field"
      />
    );
  }
}
