// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import {
  TableRow,
  TableRowColumn,
  TableRowColumnExtended,
} from "components/layout";

import { AssetActionMenu } from "./AssetActionMenu";
import styles from "./AssetRow.scss";

export class AssetRow extends Component {
  static propTypes = {
    asset: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      versions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          number: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
    expanded: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  };

  handleClick = () => {
    const { asset, onClick } = this.props;
    onClick(asset);
  };

  render() {
    const { asset, expanded } = this.props;

    return (
      <TableRow>
        <TableRowColumnExtended
          className={styles.name}
          fontIcon={expanded ? "arrow-up" : "arrow-down"}
          fontIconType="micro"
          fontIconSize="sm"
          text={asset.name}
          onClick={this.handleClick}
          to={asset.id}
        />
        <TableRowColumn className={styles.description}>
          {asset.description}
        </TableRowColumn>
        <TableRowColumn className={styles.menu}>
          <AssetActionMenu asset={asset} />
        </TableRowColumn>
      </TableRow>
    );
  }
}
