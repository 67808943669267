// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Modal } from "components/layout";
import { trans } from "src/translations";

export class ChangeOngoingCampaignModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    submitting: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    submitting: false,
  };

  handleSubmit = async () => {
    const { onSubmit, onClose } = this.props;
    await onSubmit();
    onClose();
  };

  render() {
    const { isOpen, submitting, onClose } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              processing={submitting}
              onClick={this.handleSubmit}
            >
              {trans.CONFIRM()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <p>{trans.CAMPAIGN_EDIT__ONGOING_CAMPAIGN_WARNING()}</p>
      </Modal>
    );
  }
}
