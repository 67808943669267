// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Spinner } from "components/elements";
import { classes } from "utils/classes";

import style from "./loader.scss";

/** Pulsing loader */
export class Loader extends Component {
  static propTypes = {
    /** Optional text next to the spinner */
    message: PropTypes.string,
    position: PropTypes.oneOf(["left", "center"]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
    /** Uses `display: inline-flex;` instead of `display: flex;`  */
    inline: PropTypes.bool,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    position: "center",
    size: "medium",
    className: "",
    dataTestId: "loader",
  };

  render() {
    const { message, position, size, inline, className, dataTestId } =
      this.props;

    return (
      <div
        className={classes(className, style.loader, style[position], {
          [style.inline]: inline,
          [style.block]: !inline,
        })}
        data-test-id={dataTestId}
      >
        <Spinner size={size} />
        {message ? (
          <p
            className={`${style.message} ${
              style[size] || Loader.defaultProps.size
            }`}
          >
            {message}
          </p>
        ) : null}
      </div>
    );
  }
}
