// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { ModerationAppListContent } from "./ModerationAppListContent";

export class ModerationAppList extends Component {
  render() {
    return (
      <div>
        <PageHeader>{trans.APP_LIST_MODERATION__PAGE_HEADER()}</PageHeader>
        <PageContent>
          <ModerationAppListContent />
        </PageContent>
      </div>
    );
  }
}
