// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const getAppRouteType = (currentUrl) => {
  let url = currentUrl;
  if (url.startsWith("/")) {
    url = url.substr(1);
  }
  return url.split("/")[1];
};
