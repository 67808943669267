// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Select } from "components/form/elements";
import { Modal } from "components/layout";
import { trans } from "src/translations";

import { getPrioritiesForSupportLevel } from "./getPrioritiesForSupportLevel";

export class SupportLevelChangeModal extends Component {
  static propTypes = {
    nextIsPremium: PropTypes.bool,
    isOpen: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      priority: this.getInitPriority(props.nextIsPremium),
    };
  }

  componentDidUpdate(prevProps) {
    const { nextIsPremium } = this.props;

    if (prevProps.nextIsPremium !== nextIsPremium) {
      this.setState({
        priority: this.getInitPriority(nextIsPremium),
      });
    }
  }

  getInitPriority(isPremium) {
    const priorities = getPrioritiesForSupportLevel(isPremium);
    return priorities[0].value;
  }

  onPrioritySelected = (e) => {
    this.setState({
      priority: e.target.value,
    });
  };

  onSave = () => {
    const { onSave, nextIsPremium } = this.props;
    const { priority } = this.state;
    onSave({
      isPremium: nextIsPremium,
      priority: Number.parseInt(priority, 10),
    });
  };

  getTextValues() {
    const { nextIsPremium } = this.props;

    if (nextIsPremium) {
      return {
        title: trans.APP_DETAILS_MODERATION__UPGRADE_TO_PREMIUM(),
        confirmBtnText: trans.UPGRADE(),
        textContent: trans.APP_DETAILS_MODERATION__UPGRADE_TO_PREMIUM_LINE1(),
        textLine2: trans.APP_DETAILS_MODERATION__UPGRADE_TO_PREMIUM_LINE2(),
      };
    }

    return {
      title: trans.APP_DETAILS_MODERATION__DOWNGRADE_TO_STANDARD(),
      confirmBtnText: trans.DOWNGRADE(),
      textContent: trans.APP_DETAILS_MODERATION__DOWNGRADE_TO_STANDARD_LINE1(),
      textLine2: trans.APP_DETAILS_MODERATION__DOWNGRADE_TO_STANDARD_LINE2(),
    };
  }

  render() {
    const { isOpen, onClose, nextIsPremium } = this.props;
    const { priority } = this.state;
    const texts = this.getTextValues();

    return (
      <Modal
        title={texts.title}
        isOpen={isOpen}
        onClose={onClose}
        size="medium"
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.CLOSE()}
            </Button>
            <Button type="normal" onClick={this.onSave}>
              {texts.confirmBtnText}
            </Button>
          </ButtonsWrapper>
        }
      >
        <p>{texts.textContent}</p>
        <p>{texts.textLine2}</p>

        <Select
          name="support-level-priority"
          label={`${trans.APP_DETAILS_MODERATION__SET_PRIORITY()}:`}
          value={priority}
          values={getPrioritiesForSupportLevel(nextIsPremium)}
          onChange={this.onPrioritySelected}
        />
      </Modal>
    );
  }
}
