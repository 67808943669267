// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const DEFAULT_LANG_KEY = "def_lang";
export const GENERATED_AUTOMATICALLY_WARN = `
// ----------------------------------------------
// Please don't modify manually!
// ----------------------------------------------
// This file has been generated automatically based on translations from .csv file.
// All texts, that have not been translated yet, should be placed in ${DEFAULT_LANG_KEY}.js file instead.
`;
