// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { RestrictedArea } from "containers/Permissions";
import { isAllowedToEditUser } from "containers/Permissions/groups";
import { trans } from "src/translations";

export const UserActionMenu = ({ user }) => (
  <ActionMenu>
    <MenuItem to={`/users/${user.id}/details`} dataTestId="view-details-item">
      {trans.VIEW_DETAILS()}
    </MenuItem>
    <RestrictedArea
      allowed={[isAllowedToEditUser(user)]}
      showFallback={true}
      fallbackChildren={
        <MenuItem disabled={true} dataTestId="edit-item">
          {trans.EDIT_DETAILS()}
        </MenuItem>
      }
    >
      <MenuItem to={`/users/${user.id}/edit`} dataTestId="edit-item">
        {trans.EDIT_DETAILS()}
      </MenuItem>
    </RestrictedArea>
  </ActionMenu>
);

UserActionMenu.propTypes = {
  user: PropTypes.object.isRequired,
};
