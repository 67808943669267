// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

// we ignore mutation result if no error
export const createSoftwarePackageVersion = gql`
  mutation createSoftwarePackageVersion(
    $input: CreateSoftwarePackageVersionInput!
  ) {
    createSoftwarePackageVersion(input: $input) {
      __typename
    }
  }
`;
