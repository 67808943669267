// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

export const STEP_QUANTITY_INFINITY = -1;

export const actionList = [
  {
    key: "SCHEDULE",
    label: trans.UPDATES__UPDATE_ACTION__SCHEDULE(),
  },
  {
    key: "ACTIVATE",
    label: trans.UPDATES__UPDATE_ACTION__RESUME(),
  },
  {
    key: "PAUSE",
    label: trans.UPDATES__UPDATE_ACTION__PAUSE(),
  },
  {
    key: "DELETE",
    label: trans.UPDATES__UPDATE_ACTION__DELETE(),
  },
  {
    key: "STOP",
    label: trans.UPDATES__UPDATE_ACTION__STOP(),
  },
  {
    key: "ARCHIVE",
    label: trans.UPDATES__UPDATE_ACTION__ARCHIVE(),
  },
];

export const cloneAction = {
  key: "CLONE",
  label: trans.UPDATES__UPDATE_ACTION__CLONE(),
};

export const CLONEABLE_STATUSES = ["FAILED", "STOPPED", "ENDED", "DONE"];
