// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { Loader } from "components/elements/Loader";
import { PageHeader, PageContent } from "components/layout";
import { SubTabs, SubTab } from "components/navigation";
import { RestrictedArea } from "containers/Permissions";
import { ALL_PROVIDERS } from "containers/Permissions/groups";
import { api } from "containers/Request";
import { ERRORS, MESSAGES, STATES } from "pages/Snap/shared/constants";
import { InfoBox } from "pages/Snap/shared/InfoBox/InfoBox";
import { useSnapState } from "pages/Snap/shared/SnapState/useSnapState";
import { parseInitialSnapData } from "pages/Snap/SnapEdit/adaptData";

import { SnapApplicationDetails } from "./SnapApplicationDetails";

export function SnapApplicationDetailsTabs() {
  const [application, setApplication] = useState();
  const [error, setError] = useState(false);
  const [languages, setLanguages] = useState([]);
  const { id, tabId } = useParams();
  const dispatch = useDispatch();
  const { appStates, getRevision, isNew, loading } = useSnapState();

  const getApplication = useCallback(
    (id) => dispatch(api.getSnapApplication.action({ params: { id } })),
    [dispatch]
  );

  const getLanguages = useCallback(
    () => dispatch(api.getLanguages.action({ cache: true })),
    [dispatch]
  );

  useEffect(() => {
    Promise.all([getApplication(id), getLanguages()])
      .then(([applicationRes, languagesRes]) => {
        if (
          applicationRes.error ||
          languagesRes.error ||
          !languagesRes.result.results
        ) {
          throw new Error();
        }
        setApplication(applicationRes.result);
        setLanguages(languagesRes.result.results);
      })
      .catch(() => setError(MESSAGES.genericError));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findTabId = (tabId) => {
    const id = tabId.toUpperCase();

    if (isNew) {
      return null;
    }
    return appStates.map(({ key }) => key).includes(id)
      ? id
      : appStates[0]?.key;
  };

  const generateTabUrl = (tabId) =>
    `/snap/${id}/details/${tabId.toLowerCase()}`;

  const dataReady = !loading && application;
  const currentTab = loading ? null : findTabId(tabId);

  return (
    <>
      <PageHeader>{application ? application.name : ""}</PageHeader>
      <PageContent>
        <RestrictedArea allowed={ALL_PROVIDERS}>
          {!dataReady && !error && <Loader />}
          {dataReady && currentTab && (
            <SubTabs>
              {appStates.length &&
                appStates.map(({ key }) => {
                  return (
                    <SubTab
                      key={key}
                      to={generateTabUrl(key)}
                      text={STATES[key]}
                      active={key === currentTab}
                    />
                  );
                })}
            </SubTabs>
          )}
          {dataReady && currentTab && (
            <SnapApplicationDetails
              appId={id}
              languages={languages}
              revision={parseInitialSnapData(
                getRevision(currentTab),
                languages
              )}
              revisionType={currentTab}
            />
          )}
          {error && <InfoBox message={error} />}
          {dataReady && isNew && (
            <InfoBox message={ERRORS.NO_REVISION_AVAILABLE} />
          )}
        </RestrictedArea>
      </PageContent>
    </>
  );
}
