// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { ImgIcon } from "components/icons";
import {
  TableRow,
  TableRowColumn,
  TableRowColumnOverflow,
} from "components/layout";
import { Link } from "components/typography";

import { OrganizationActionMenu } from "./OrganizationActionMenu";
import styles from "./OrganizationsTable.scss";
import { TypeColumn } from "./TypeColumn";

export const OrganizationRow = ({ organization }) => (
  <TableRow>
    <TableRowColumn>
      <ImgIcon src={organization.icon} />
    </TableRowColumn>
    <TableRowColumnOverflow overflowText={organization.name}>
      <Link
        to={`/organization/${organization.id}`}
        className={styles.organizationName}
      >
        {organization.name}
      </Link>
    </TableRowColumnOverflow>
    <TableRowColumn>
      <TypeColumn types={organization.types} />
    </TableRowColumn>
    <TableRowColumn>{organization.usersCount}</TableRowColumn>
    <TableRowColumn>
      <OrganizationActionMenu organizationId={organization.id} />
    </TableRowColumn>
  </TableRow>
);

OrganizationRow.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    types: PropTypes.array.isRequired,
    usersCount: PropTypes.number.isRequired,
  }).isRequired,
};
