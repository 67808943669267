// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { Pill } from "components/elements/Pill";
import { GrabsonIcon, ImgIcon } from "components/icons";
import { Avatar } from "components/images";
import { TableRowColumn } from "components/layout";
import { Link } from "components/typography";
import { classes } from "utils/classes";

import { tableURLPropType } from "./propTypes";
import styles from "./TableRowColumnExtended.scss";

/**
 * Complex yet flexible table cell data. Corresponds to `<td>`, but has many extensions.
 * Should be placed inside `<TableRow>` or `<TableSubRow>`.
 * All additional props for this component will be set on result `<td>` tag.
 *
 * @deprecated Please avoid this monstrosity in favor of using things directly in TableRowColumn or TableRowColumnOverflow
 */
export const TableRowColumnExtended = ({
  avatar,
  icon,
  className,
  dataTestId,
  columnClass,
  defaultAvatar,
  defaultIcon,
  fontIcon,
  fontIconType,
  fontIconActive,
  fontIconSize,
  fontIconClassName,
  rightIcon,
  rightFontIcon,
  rightFontIconClassName,
  rightPillText,
  rightPillIcon,
  to,
  text,
  additionalText,
  textClassName,
  urlTarget,
  onClick,
  showDefaultIcon,
  ...props
}) => (
  <TableRowColumn dataTestId={dataTestId} columnClass={columnClass} {...props}>
    <div className={classes(styles.content, className)} onClick={onClick}>
      {(defaultAvatar || avatar) && <Avatar src={avatar} size="25px" />}
      {(defaultIcon || icon || showDefaultIcon) && <ImgIcon src={icon} />}
      {fontIcon && (
        <GrabsonIcon
          dataTestId={`${dataTestId}-icon`}
          name={fontIcon}
          size={fontIconSize}
          font={fontIconType}
          className={classes(
            {
              [styles.active]: fontIconActive,
              [styles.disable]: !fontIconActive,
            },
            fontIconClassName
          )}
        />
      )}
      {to ? (
        <Fragment>
          <Link
            to={to}
            className={textClassName}
            target={urlTarget ? `_${urlTarget}` : undefined}
          >
            {text}
          </Link>
          {additionalText}
        </Fragment>
      ) : (
        <span className={textClassName}>{text}</span>
      )}
      {rightIcon && <ImgIcon src={rightIcon} />}
      {rightFontIcon && (
        <GrabsonIcon
          dataTestId={`${dataTestId}-icon`}
          name={rightFontIcon}
          size={fontIconSize}
          className={classes(
            {
              [styles.active]: fontIconActive,
              [styles.disable]: !fontIconActive,
            },
            rightFontIconClassName
          )}
        />
      )}
      {rightPillText && <Pill text={rightPillText} icon={rightPillIcon} />}
    </div>
  </TableRowColumn>
);

TableRowColumnExtended.propTypes = {
  /** Link or path to image. If empty avatar is not displayed. */
  avatar: PropTypes.string,
  /** If present and __avatar__ property empty default avatar is displayed. */
  defaultAvatar: PropTypes.bool,
  /** Link or path to image. If empty image is not displayed. */
  icon: PropTypes.string,
  /** If present and __icon__ property empty display default image. */
  defaultIcon: PropTypes.bool,
  /** Link or path to icon image. If empty icon is not displayed. */
  rightIcon: PropTypes.string,
  /** Font icon name place on right text side. See [GrabsonIcon](#grabsonicon) for available names. */
  rightFontIcon: PropTypes.string,
  /** Font icon className */
  rightFontIconClassName: PropTypes.string,
  /** Text for right pill */
  rightPillText: PropTypes.string,
  /** Icon for right pill */
  rightPillIcon: PropTypes.string,
  /** Custom class name. */
  className: PropTypes.string,
  /** Id for automated tests. */
  dataTestId: PropTypes.string,
  /** Font icon name. See [GrabsonIcon](#grabsonicon) for available names. */
  fontIcon: PropTypes.string,
  fontIconType: PropTypes.string,
  /** Custom user class name for wrapping div. */
  columnClass: PropTypes.string,
  /** If present font icon changes its color to active. */
  fontIconActive: PropTypes.bool,
  /** Font icon size. See [GrabsonIcon](#grabsonicon) for available sizes. */
  fontIconSize: PropTypes.string,
  /** Font icon className */
  fontIconClassName: PropTypes.string,
  /** HTML link. */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Regular text. */
  text: PropTypes.node,
  /** When 'to' prop is provided text is a link, this is for elements that you want to have outside of the link */
  additionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Custom class name for value in `text` prop. */
  textClassName: PropTypes.string,
  /**
   * Event handler.
   *
   * Type: `(e: Event) => void`
   */
  onClick: PropTypes.func,
  /** Target for opening URLs */
  urlTarget: tableURLPropType,
  showDefaultIcon: PropTypes.bool,
};

TableRowColumnExtended.defaultProps = {
  fontIconSize: "normal",
  fontIconType: "normal",
  showDefaultIcon: false,
  dataTestId: "table-column",
};

export default TableRowColumnExtended;
