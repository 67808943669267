// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { FILTER_TRIGGER_CHARACTER } from "../constants";
import styles from "./FilterDropdownOption.scss";

const NAME_SEPARATOR = ":";

export class FilterDropdownOption extends Component {
  static propTypes = {
    option: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      description: PropTypes.node.isRequired,
      param: PropTypes.node,
      suggestion: PropTypes.string,
    }).isRequired,
    focused: PropTypes.bool,
  };

  getDisplayedName() {
    const { option } = this.props;
    return `${
      option.suggestion ? option.suggestion.charAt(0) : FILTER_TRIGGER_CHARACTER
    }${option.displayName}${NAME_SEPARATOR}`;
  }

  render() {
    const { option, focused } = this.props;

    return (
      <div
        className={styles.FilterDropdownOption}
        data-focused={Boolean(focused)}
      >
        <span className={styles.Command}>{this.getDisplayedName()}</span>

        {option.param && <span className={styles.Example}>{option.param}</span>}

        <span className={styles.Description}>{option.description}</span>
      </div>
    );
  }
}
