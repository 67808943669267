// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Field } from "formik";

import { Checkbox } from "components/form/elements";

import { FIELDS } from "../constants";

export const SettingsFields = () => {
  const renderField = ({ field, meta, label }) => (
    <Checkbox label={label} {...field} {...meta} />
  );

  return (
    <>
      <Field {...FIELDS.enabled} component={renderField} />
      <Field {...FIELDS.testing} component={renderField} />
    </>
  );
};
