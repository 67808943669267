// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { Navigate } from "react-router-dom";

import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";

import { NoOrganization, NoPermissions } from "./fallbacks";
import { Permissions } from "./Permissions";

/**
 * RestrictedArea component **hides** some UI according to user's roles.
 * Checks if currently logged in user has permissions to render component's children.
 *
 * This component also has HOC version as [`@restrictedArea`](#restrictedareadecorator).

 * @example ./RestrictedArea.md
 */
@withActiveOrganization
class RestrictedArea extends Component {
  static propTypes = {
    /**
     * Array of Strings and/or functions `({activeOrganization, currentUser}) => boolean`.
     *
     * Children displayed when `allowed` array is empty or user has at least one role listed in the `allowed` array or at least one function supplied via this array returns true. Otherwise, permissions are denied.
     */
    allowed: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    ),
    allowedOrganizationTypes: PropTypes.arrayOf(PropTypes.string),
    /** If true and permissions denied, default fallback displayed in place of children */
    showFallback: PropTypes.bool,
    /** If provided, used instead of the default fallback component if role verification failed */
    fallbackChildren: PropTypes.node,
    /** Redirects user to 'redirectionPath' if permissions denied. Works only if 'showFallback' value is falsy */
    redirectionPath: PropTypes.string,
    /** Children to display if user has required permissions */
    children: PropTypes.node.isRequired,

    // from @withActiveOrganization
    /** @ignore */
    activeOrganization: PropTypes.shape({
      roles: PropTypes.array.isRequired,
    }),
  };

  static defaultProps = {
    allowed: [],
    showFallback: true,
    redirectionPath: "",
  };

  renderContent = (denied) => {
    const {
      children,
      showFallback,
      fallbackChildren,
      redirectionPath,
      activeOrganization,
    } = this.props;

    if (!denied) {
      return children;
    }

    if (denied && showFallback && fallbackChildren) {
      return fallbackChildren;
    }

    if (denied && showFallback && !fallbackChildren) {
      if (!activeOrganization) {
        return <NoOrganization />;
      }

      return <NoPermissions />;
    }

    if (denied && !showFallback && redirectionPath) {
      return <Navigate to={redirectionPath} />;
    }

    return null;
  };

  render() {
    const { allowed, allowedOrganizationTypes } = this.props;

    return (
      <Permissions
        allowed={allowed}
        allowedOrganizationTypes={allowedOrganizationTypes}
      >
        {(isAllowed) => this.renderContent(!isAllowed)}
      </Permissions>
    );
  }
}

// styleguidist does not like exporting classes that use decorators
export { RestrictedArea };
