import { useState } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { trans } from "src/translations";
import { formatDateTime } from "utils/date";

import styles from "./RefetchSection.scss";

export const RefetchSection = ({
  loading,
  refetchDetails,
  dataTestId = "refetch-section",
}) => {
  const [fetchDate, setFetchDate] = useState(new Date());

  const handleRefetchDetails = async () => {
    try {
      await refetchDetails();
    } finally {
      setFetchDate(new Date());
    }
  };

  const checkedOnDate = formatDateTime(fetchDate);

  return (
    <div className={styles.refreshData} data-test-id={dataTestId}>
      <p
        className={styles.refreshTimestamp}
        data-test-id={`${dataTestId}-timestamp`}
      >
        {trans.DEVICES__COMMAND_DETAILS_CHECKED_ON({
          checkedOnDate,
        })}
      </p>
      <Button processing={loading} onClick={handleRefetchDetails}>
        {trans.REFRESH()}
      </Button>
    </div>
  );
};

RefetchSection.propTypes = {
  loading: PropTypes.bool.isRequired,
  refetchDetails: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
};
