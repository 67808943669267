// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { ACTIVE_ORGANIZATION_HEADER_NAME } from "containers/Auth/constants";

import { METHOD_NAMES } from "./constants";

const createRequest = (
  methodName,
  { activeOrganizationId, url, abortSignal, options = {} }
) => {
  const { headers } = options;

  const restOptions = { ...options };
  delete restOptions.headers;

  const contentType = {};
  if (options.body) {
    contentType["Content-Type"] = "application/vnd.api+json";
  }

  return fetch(url, {
    method: methodName,
    headers: {
      Accept: "application/vnd.api+json",
      [ACTIVE_ORGANIZATION_HEADER_NAME]: activeOrganizationId,
      ...contentType,
      ...headers,
    },
    credentials: "include",
    signal: abortSignal,
    ...restOptions,
  });
};

const createReqObj = (methodName) => ({
  name: methodName,
  func: (params) => createRequest(methodName, params),
});

export const get = createReqObj(METHOD_NAMES.GET);
export const post = createReqObj(METHOD_NAMES.POST);
export const patch = createReqObj(METHOD_NAMES.PATCH);
export const put = createReqObj(METHOD_NAMES.PUT);
export const del = createReqObj(METHOD_NAMES.DELETE);
