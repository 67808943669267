// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createRef, Component } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Loader } from "components/elements";
import { Pill } from "components/elements/Pill";
import { Info } from "components/feedback";
import { Section, Subsection } from "components/layout";
import { restrictedArea, ROLES } from "containers/Permissions";
import { RegionsModal } from "pages/_shared/RegionsModal/RegionsModal";
import { Markets } from "pages/Applications/_shared/Markets";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import styles from "./AppRank.scss";

@restrictedArea(() => ({
  allowed: [ROLES.moderator.moderator, ROLES.moderator.contentManager],
}))
export class AppRankRend extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
    processing: PropTypes.bool,
    formError: PropTypes.string,
    topMarkets: PropTypes.shape({
      selection: PropTypes.string,
      country_codes: PropTypes.array,
      isGlobal: PropTypes.bool,
    }),
    allCountries: PropTypes.array,
    onSelectCountries: PropTypes.func,
  };

  static defaultProps = {
    loading: false,
    error: null,
  };
  state = {
    isRegionModalOpen: false,
  };

  constructor(props) {
    super(props);
    this.errorInfo = createRef();
  }

  componentDidUpdate() {
    if (this.errorInfo.current) {
      window.scrollTo(0, this.errorInfo.current.offsetTop);
    }
  }

  handleOpenRegionModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ isRegionModalOpen: true });
  };

  handleCloseRegionModal = () => {
    this.setState({ isRegionModalOpen: false });
  };

  handleRegionSelect = (data) => {
    this.props.onSelectCountries(data);
    this.setState({ isRegionModalOpen: false });
  };

  getCountriesNumber = () => {
    const { allCountries, topMarkets } = this.props;
    let countriesCount = "(Global)";
    if (!topMarkets.isGlobal) {
      countriesCount = `(Local ${topMarkets.country_codes.length}/${allCountries.length})`;
    }
    return (
      <p>
        <span className={classes(styles.bold, styles.statusPositive)}>
          {trans.APP_DETAILS_APP_RANK__MARKED_STATUS()}
        </span>{" "}
        {countriesCount} <Pill text={trans.APP_DETAILS_APP_RANK__TOP()} />
      </p>
    );
  };

  render() {
    const { allCountries, topMarkets, loading, error, processing, formError } =
      this.props;
    const { isRegionModalOpen } = this.state;
    if (loading) {
      return <Loader message={`${trans.LOADING()}...`} />;
    }
    if (error) {
      return <Info type="error">{error.message}</Info>;
    }

    return (
      <div>
        <Section
          className={styles.rankDefinition}
          header={trans.APP_DETAILS_APP_RANK__SECTION_HEADER()}
        >
          <p>{trans.APP_DETAILS_APP_RANK__SECTION_DEFINITION()}</p>
          <p>
            {trans.APP_DETAILS_APP_RANK__FILTER()}{" "}
            <span className={styles.bold}>
              {trans.APP_DETAILS_APP_RANK__FILTER_KEY()}
            </span>
            <span className={classes(styles.bold, styles.filterValue)}>
              {trans.APP_DETAILS_APP_RANK__FILTER_VALUE()}
            </span>
          </p>
          <br />
          <p>
            {trans.APP_DETAILS_APP_RANK__FILTER_CATEGORIES()}
            <span className={styles.bold}>
              {" "}
              {trans.APP_DETAILS_APP_RANK__FILTER_TOP_COUNTRIES()}
            </span>{" "}
            {trans.APP_DETAILS_APP_RANK__FILTER_OR()}{" "}
            <span className={styles.bold}>
              {" "}
              {trans.APP_DETAILS_APP_RANK__FILTER_TOP_GLOBAL()}
            </span>
          </p>
        </Section>
        <Section header={trans.APP_DETAILS_APP_RANK__RANK_STATUS()}>
          <Subsection header={trans.APP_DETAILS_APP_RANK__STATUS()}>
            <div className={styles.rankStatus}>
              {!topMarkets.country_codes || !topMarkets.country_codes.length
                ? trans.APP_DETAILS_APP_RANK__NONE_STATUS()
                : this.getCountriesNumber()}
            </div>
          </Subsection>
        </Section>
        <Section
          className={styles.markets}
          header={trans.APP_DETAILS_APP_RANK__TOP_MARKETS()}
        >
          <Markets
            allCountries={allCountries}
            selectedLocations={topMarkets}
            simple={true}
          />
          <Button
            type="green"
            onClick={this.handleOpenRegionModal}
            dataTestId="open-countries-modal"
            processing={processing}
          >
            {trans.TARGETS__NO_REGION_BUTTON_TEXT()}
          </Button>
          <RegionsModal
            label={trans.TARGETS__REGIONS_MODAL_CONTENT()}
            selectedCountriesCodes={topMarkets.country_codes}
            isOpen={isRegionModalOpen}
            onClose={this.handleCloseRegionModal}
            onSelect={this.handleRegionSelect}
            allCountries={allCountries}
            allowEmpty={true}
          />
        </Section>
        {formError && (
          <div ref={this.errorInfo}>
            <Info type="error">{formError}</Info>
          </div>
        )}
      </div>
    );
  }
}
