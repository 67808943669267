// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";

import { RestrictedArea } from "containers/Permissions";
import { ALL_MODERATORS } from "containers/Permissions/groups";
import { apiThatThrows } from "containers/Request";
import { adaptQueryParamsToGqlSearchArg } from "pages/_utils/adaptQueryParamsToGqlSearchArg";
import { getGqlErrorMessage } from "utils/errors/gqlErrors";
import { DEFAULT_APOLLO_OPTIONS } from "utils/graphql/constants";
import { parseLocationToLimitOffset } from "utils/pagination";
import { createLocationWithSearch } from "utils/url";

import { ITEMS_PER_PAGE } from "../constants";
import { getManifestListFiltersQuery, getManifestListQuery } from "./gql";
import { ManifestsListRend } from "./ManifestListRend";
import { getSortOrder, getSortBy, getSearchParams } from "./utils";

const adaptManifest = (appNames, manifest) => {
  const appName = appNames.find(
    ({ value }) => value.id === manifest.applicationId
  );

  return {
    id: manifest.id,
    manifest: {
      appId: manifest.applicationId,
      appName: appName?.value?.name,
      createdDate: manifest.manifest.createdDate,
      modifiedDate: manifest.manifest.modifiedDate,
    },
  };
};

export function ManifestListDataComponent({ getApplication, userRoles }) {
  const [availableFilters, setAvailableFilters] = useState();
  const [results, setResults] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = getSearchParams(location);
  const sortBy = getSortBy(searchParams);
  const sortOrder = getSortOrder(searchParams);

  const [
    fetchManifests,
    { loading: isLoadingManifests, error: manifestError, data: manifestData },
  ] = useLazyQuery(getManifestListQuery, {
    ...DEFAULT_APOLLO_OPTIONS,
  });

  const [fetchManifestFilters, { data: manifestFitersData }] = useLazyQuery(
    getManifestListFiltersQuery,
    {
      ...DEFAULT_APOLLO_OPTIONS,
    }
  );

  const getAppNameById = useCallback(
    (id) => {
      return getApplication(id)
        .then(({ results }) => {
          return { id, name: results.name };
        })
        .catch((err) => ({ name: undefined, id, error: err }));
    },
    [getApplication]
  );

  const getManifestListQueryVariables = useCallback(
    () => ({
      pagination: parseLocationToLimitOffset(location, ITEMS_PER_PAGE),
      sort: {
        name: sortBy.toUpperCase(),
        order: sortOrder,
      },
      search: adaptQueryParamsToGqlSearchArg(searchParams),
    }),
    [location, searchParams, sortBy, sortOrder]
  );

  useEffect(() => {
    fetchManifestFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manifestFitersData) {
      const filters =
        manifestFitersData.manifestListFilters?.manifestListFilters;
      setAvailableFilters({ sections: filters ? [filters] : [] });
    }
  }, [manifestFitersData]);

  useEffect(() => {
    if (manifestData && !isLoadingManifests) {
      setLoading(true);
      const results = manifestData.manifests?.manifests?.items || [];
      const count = manifestData.manifests?.manifests?.pagination?.count || 0;
      Promise.allSettled(
        results.map(({ applicationId }) => getAppNameById(applicationId))
      )
        .then((appNames) => {
          setCount(count);
          setResults(results.map(adaptManifest.bind(null, appNames)));
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifestData, isLoadingManifests]);

  useEffect(() => {
    fetchManifests({
      variables: getManifestListQueryVariables(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSearch = (filters) => {
    const updatedLocation = createLocationWithSearch(location, filters);
    navigate(updatedLocation);
  };

  return (
    <RestrictedArea allowed={ALL_MODERATORS} showFallback={true}>
      <ManifestsListRend
        results={results}
        count={count}
        loading={loading || isLoadingManifests}
        error={manifestError && getGqlErrorMessage(manifestError)}
        availableFilters={availableFilters}
        onSearch={handleSearch}
        userRoles={userRoles}
        defaultOrder={{
          sortBy,
          sortOrder,
        }}
        refetchQuery={{
          query: getManifestListQuery,
          variables: getManifestListQueryVariables(),
        }}
      />
    </RestrictedArea>
  );
}

ManifestListDataComponent.propTypes = {
  // from @connect
  getApplication: PropTypes.func.isRequired,
  userRoles: PropTypes.array.isRequired,
};

export const ManifestListData = connect(
  ({ auth }) => ({
    userRoles: auth.organizations[auth.profile.activeOrganizationId]?.roles,
  }),
  (dispatch) => ({
    getApplication: (appId) =>
      dispatch(
        apiThatThrows.getApplication.action({
          params: { id: appId },
        })
      ),
  })
)(ManifestListDataComponent);
