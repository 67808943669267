// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

import { Section } from "components/layout";
import { trans } from "src/translations";

import { CommentableList } from "./_shared/CommentableList";
import {
  isMonetized,
  getRevenueSubtypes,
  getOtherComment,
} from "./_utils/monetization";

export class Revenue extends Component {
  static propTypes = {
    monetization: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        comment: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    monetization: [],
  };

  isMonetizationUpdated = (activeVersion, latestVersion) => {
    const activeRevenueType = isMonetized(activeVersion);
    const latestRevenueType = isMonetized(latestVersion);

    return activeRevenueType !== latestRevenueType;
  };

  isMonetizationTypeUpdated = (activeVersion, latestVersion) => {
    const activeRevenueSubtypes = getRevenueSubtypes(activeVersion);
    const latestRevenueSubtypes = getRevenueSubtypes(latestVersion);

    return !isEqual(
      activeRevenueSubtypes.slice().sort(),
      latestRevenueSubtypes.slice().sort()
    );
  };

  isCommentFromAuthorUpdated = (activeVersion, latestVersion) => {
    const activeComment = getOtherComment(activeVersion);
    const latestComment = getOtherComment(latestVersion);

    return activeComment !== latestComment;
  };

  getCommentableListData = () => {
    const { monetization } = this.props;
    return monetization.length
      ? monetization
      : [{ name: trans.NO(), comment: "" }];
  };

  render() {
    return (
      <div>
        <Section
          header={trans.APP_DETAILS_VERSION__SECTION_MONETIZATION()}
          dataTestId="revenue-section"
        >
          <CommentableList
            data={this.getCommentableListData()}
            listTitle={trans.APP_DETAILS_VERSION__MONETIZATION_TYPES()}
            isListUpdatedFunc={this.isMonetizationTypeUpdated}
            isCommentUpdatedFunc={this.isCommentFromAuthorUpdated}
            defaultEmptyComment={false}
          />
        </Section>
      </div>
    );
  }
}
