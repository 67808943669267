// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Checkbox } from "components/form/elements";
import { WarningModal } from "components/layout";
import { trans } from "src/translations";

import styles from "./DismissableModal.scss";

/**
 * By default `WarningModal` should be displayed with possibility to hide it permanently
 * You can add more props from `<Modal>` component if needed.
 */
export class DismissableModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    /**  executed on every cancel and as a fallback when `onSubmit` or `onContinue` are not available **/
    onClose: PropTypes.func.isRequired,
    /** executed when modal is hidden, but it is launched for display (fallbacks to onClose) **/
    onContinue: PropTypes.func,
    /** executed when modal is submitted (fallbacks to onContinue) */
    onSubmit: PropTypes.func,
    /** Callback executed in render() when this modal was accepted or dismissed on accept (saved in localstorage) */
    renderSubmitted: PropTypes.func,
    /** Custom actions that are executed in render(), DismissableModal helpers are passed to this function */
    renderActions: PropTypes.func,
    /** Content of the modal that can be dismissed */
    children: PropTypes.node,
    submitButtonText: PropTypes.string,
  };

  static defaultProps = {
    submitButtonText: trans.UNDERSTAND(),
  };

  dataKey = `dismissModal_${this.props.id || location.pathname}`;

  isHidden = () => {
    try {
      return JSON.parse(localStorage.getItem(this.dataKey)) || false;
    } catch (e) {
      return false;
    }
  };

  state = {
    dontShowAgain: this.isHidden(), // used only for checkbox value, should be set to false on every modal open
    isHidden: this.isHidden(), // tells if dismissable modal should be omitted
  };

  componentDidMount() {
    this.checkOpen();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && prevProps.isOpen !== this.props.isOpen) {
      this.setState(
        {
          dontShowAgain: false,
          isHidden: this.isHidden(),
        },
        this.checkOpen
      );
    }
  }

  // checks if modal can be opened (if there is actual content to display)
  // if not, then props.onClose is triggered
  checkOpen = () => {
    const { isOpen, onClose, onContinue, renderSubmitted } = this.props;
    if (isOpen && this.state.isHidden && !renderSubmitted) {
      if (onContinue) {
        onContinue();
      } else {
        onClose();
      }
    }
  };

  // change of checkbox state
  handleChange = () => {
    this.setState((state) => ({ dontShowAgain: !state.dontShowAgain }));
  };

  // executed on modal submit
  // sets "hidden" state if checkbox was selected
  handleSubmit = () => {
    const { dontShowAgain } = this.state;
    const { onClose, onContinue, onSubmit, renderSubmitted } = this.props;
    this.setState({ isHidden: true });
    if (dontShowAgain) {
      localStorage.setItem(this.dataKey, dontShowAgain);
    }
    if (onSubmit) {
      onSubmit();
    } else if (!renderSubmitted) {
      if (onContinue) {
        onContinue();
      } else {
        onClose();
      }
    }
  };

  renderButtons = () => {
    const { onClose, onSubmit, renderSubmitted, submitButtonText } = this.props;
    const { dontShowAgain } = this.state;
    return (
      <ButtonsWrapper position="modal">
        <Checkbox
          className={styles.checkbox}
          label={trans.MODAL__NOT_SHOW_LABEL()}
          onChange={this.handleChange}
          value="dismissed"
          checked={dontShowAgain}
        />
        {(onSubmit || renderSubmitted) && (
          <Button
            type="normal"
            onClick={onClose}
            dataTestId="dismiss-cancel-button"
          >
            {trans.CANCEL()}
          </Button>
        )}
        <Button
          type="green"
          onClick={this.handleSubmit}
          dataTestId="dismiss-ok-button"
        >
          {submitButtonText}
        </Button>
      </ButtonsWrapper>
    );
  };

  render() {
    const { children, isOpen, onClose, renderSubmitted, renderActions } =
      this.props;
    const { isHidden } = this.state;

    return isOpen && isHidden ? (
      (renderSubmitted && renderSubmitted()) || null
    ) : (
      <WarningModal
        {...this.props}
        onClose={onClose}
        actions={
          renderActions
            ? renderActions(this.handleSubmit)
            : this.renderButtons()
        }
      >
        {children}
      </WarningModal>
    );
  }
}
