// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import {
  Section,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableRowColumn,
} from "components/layout";
import { api } from "containers/Request";
import { trans } from "src/translations";
import { withCountries, toRegionsString } from "utils/continents";

import * as actions from "../ModalDevices/action";
import styles from "./AssetDeviceTable.scss";
import { DeviceModelActionMenu } from "./DeviceModelActionMenu";

@connect(
  ({ addAssetDevice, addDeviceRegions }) => ({
    publishAssetDevice: addAssetDevice,
    addDeviceRegions,
  }),
  (dispatch, props) => ({
    getAssetModels: (assetId, assetVersion) =>
      dispatch(
        api.getAssetDeviceModelsPaginated.action({
          params: { assetId, assetVersion },
          queryParams: { limit: "nolimit" },
        })
      ),
    getModelsData: (ids) =>
      dispatch(
        api.getDeviceModelsPaginated.action({
          queryParams: { id: ids, limit: ids.length, page: 1 },
        })
      ),
    addAssetDevice: () =>
      dispatch(actions.addAssetDeviceActive(props.asset, props.version)),
    postAssetDeviceModels: (assetId, assetVersion, options) =>
      dispatch(
        api.postAssetDeviceModels.action({
          params: { assetId, assetVersion },
          options,
        })
      ),
  })
)
@withCountries
export class AssetDeviceTable extends Component {
  static propTypes = {
    asset: PropTypes.object.isRequired,
    version: PropTypes.string.isRequired,
    publishAssetDevice: PropTypes.object,
    addDeviceRegions: PropTypes.object,

    // from @connect
    getAssetModels: PropTypes.func,
    getModelsData: PropTypes.func,
    addAssetDevice: PropTypes.func,
    postAssetDeviceModels: PropTypes.func,

    // from @withCountries
    allCountries: PropTypes.array.isRequired,
    countriesLoading: PropTypes.bool.isRequired,
  };

  state = { models: [], loading: true };

  async componentDidMount() {
    await this.getAssetDeviceModels();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  async componentDidUpdate(prevProps) {
    const { publishAssetDevice, addDeviceRegions } = this.props;

    const prevDeviceModalOpen = prevProps.publishAssetDevice.isActive;
    const deviceModalOpen = publishAssetDevice.isActive;
    const deviceModalWasJustClosed = prevDeviceModalOpen && !deviceModalOpen;

    const prevRegionModalOpen = prevProps.addDeviceRegions.isActive;
    const regionModalOpen = addDeviceRegions.isActive;
    const regionModalWasJustClosed = prevRegionModalOpen && !regionModalOpen;

    if (deviceModalWasJustClosed || regionModalWasJustClosed) {
      await this.getAssetDeviceModels();
    }
  }

  async getAssetDeviceModels() {
    const { asset, version, getAssetModels, getModelsData } = this.props;

    this.setState({ loading: true });

    const { result, error } = await getAssetModels(asset.id, version);

    if (this.unmounted) {
      return;
    }

    if (!error) {
      const modelsIds = result.results.map((model) => model.id);
      const assetModels = result.results;

      if (modelsIds.length === 0) {
        this.setState({ loading: false, models: [] });
        return;
      }

      const rsp = await getModelsData(modelsIds);

      if (this.unmounted) {
        return;
      }

      if (!rsp.error) {
        const modelsData = rsp.result.results;
        const models = assetModels.map((model) => {
          const modelData = modelsData.find((m) => m.id === model.id);
          return {
            id: model.id,
            name: modelData ? modelData.name : "",
            countries: model.countries,
            isWorldwide: model.is_available_worldwide,
          };
        });
        this.setState({ models, loading: false });
      }
    } else {
      this.setState({ loading: false, models: [] });
    }
  }

  formatRegions(model) {
    const { allCountries } = this.props;
    const { countries, is_available_worldwide } = model;
    if (is_available_worldwide) {
      return trans.ASSET__WORLDWIDE_ALL();
    }

    return toRegionsString(countries, allCountries || []);
  }

  handleEditDevices = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { addAssetDevice } = this.props;
    addAssetDevice();
  };

  handleRemoveDevice = async (model) => {
    const { postAssetDeviceModels, asset, version } = this.props;

    this.setState({ loading: true });

    const models = this.state.models.filter((m) => m.id !== model.id);
    const modelsIds = models.map((m) => m.id);
    const body = JSON.stringify({
      data: modelsIds.map((id) => ({
        type: "software_asset_version_models",
        id: id,
      })),
    });

    const { error } = await postAssetDeviceModels(asset.id, version, { body });
    if (!error) {
      this.setState({ models, loading: false });
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    const { asset, version, countriesLoading } = this.props;
    const { models } = this.state;
    const loading = this.state.loading || countriesLoading;

    return (
      <Section
        header={trans.ASSET__DEVICES_HEADER()}
        className={styles.sectionDevices}
      >
        <Table>
          <TableHeader loading={loading}>
            <TableHeaderColumn className={styles.headerName}>
              {trans.ASSET__NAME_COLUMN()}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.headerRegions}>
              {trans.ASSET__REGIONS_COLUMN()}
            </TableHeaderColumn>
            <TableHeaderColumn className={styles.headerMenu} />
          </TableHeader>
          <TableBody>
            {models.map((model) => (
              <TableRow key={model.id}>
                <TableRowColumn className={styles.modelName}>
                  {model.name}
                </TableRowColumn>
                <TableRowColumn className={styles.modelRegions}>
                  {this.formatRegions(model)}
                </TableRowColumn>
                <TableRowColumn className={styles.menu}>
                  <DeviceModelActionMenu
                    asset={asset}
                    model={model}
                    version={version}
                    onRemoveDevice={this.handleRemoveDevice}
                  />
                </TableRowColumn>
              </TableRow>
            ))}
            {models.length === 0 && (
              <TableRow>
                <TableRowColumn colSpan="3" className={styles.noDevicesRow}>
                  {trans.ASSET__NO_DEVICES_SELECTED()}
                </TableRowColumn>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <ButtonsWrapper position="center">
          <Button
            type={models.length === 0 ? "green" : "normal"}
            onClick={this.handleEditDevices}
            disabled={loading}
          >
            {models.length === 0
              ? trans.ASSET__ADD_DEVICES_BUTTON()
              : trans.ASSET__EDIT_DEVICES_BUTTON()}
          </Button>
        </ButtonsWrapper>
      </Section>
    );
  }
}
