import { Component } from "react";

import PropTypes from "prop-types";

import { PageError } from "components/layout";
import { restrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, queryResultType } from "utils/graphql";

import { CommandDetailsRend } from "./CommandDetailsRend";
import { getCommandQuery } from "./gql/getCommandQuery";

@withRouter
@withQuery({
  name: "command",
  query: getCommandQuery,
})
@restrictedArea(() => {
  return {
    allowed: [ROLES.deviceInventory.configControlManager],
    fallbackChildren: (
      <PageError
        pageHeader={trans.DEVICES__COMMAND_DETAILS_DEFAULT_HEADER()}
        error={{ isForbidden: true }}
      />
    ),
  };
})
export class CommandDetails extends Component {
  static propTypes = {
    // from @withQuery
    commandQuery: PropTypes.func,
    commandQueryStatus: queryResultType,

    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.fetchCommandDetails();
  }

  fetchCommandDetails = async () => {
    await this.props.commandQuery({
      variables: {
        id: this.props.params.id,
      },
    });
  };

  render() {
    const { data, loading, error } = this.props.commandQueryStatus;

    return (
      <CommandDetailsRend
        refetchDetails={this.fetchCommandDetails}
        command={data?.command?.command}
        loading={loading}
        error={error && getGqlErrorMessage(error)}
      />
    );
  }
}
