// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment, Component } from "react";

import pick from "lodash-es/pick";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { Modal } from "components/layout";
import { trans } from "src/translations";

import styles from "./AddToCustomPlanModalRend.scss";
import { ApplicationsList } from "./ApplicationsList";
import { PlansList } from "./PlansList";

export class AddToCustomPlanModalRend extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    submitError: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    applications: PropTypes.arrayOf(PropTypes.object),
    isAppChecked: PropTypes.func.isRequired,
    setAppChecked: PropTypes.func.isRequired,
    selectedApps: PropTypes.arrayOf(PropTypes.string).isRequired,
    getPlanSelectionState: PropTypes.func.isRequired,
    addedCount: PropTypes.number.isRequired,
    removedCount: PropTypes.number.isRequired,
    toggleMany: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    fetchData: PropTypes.func.isRequired,
    mapData: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  renderModalContent() {
    const {
      selectedApps,
      isAppChecked,
      setAppChecked,
      applications,
      submitError,
      error,
      loading,
    } = this.props;

    if (loading || applications === null) {
      return <Loader />;
    }

    if (error) {
      return <Info type="error">{error.message}</Info>;
    }

    return (
      <Fragment>
        <div className={styles.modalContentContainer}>
          <ApplicationsList
            applications={applications}
            isChecked={isAppChecked}
            setChecked={setAppChecked}
            className={styles.appsListContainer}
          />
          <PlansList
            {...pick(this.props, [
              "addedCount",
              "removedCount",
              "toggleMany",
              "fetchData",
              "mapData",
              "getPlanSelectionState",
            ])}
            className={styles.plansListContainer}
            selectedApps={selectedApps}
          />
        </div>
        {submitError && (
          <Info type="error" className={styles.errorMessage}>
            {submitError.message}
          </Info>
        )}
      </Fragment>
    );
  }

  handleClose = () => this.props.onClose();

  render() {
    const {
      isOpen,
      selectedApps,
      addedCount,
      removedCount,
      onSubmit,
      isSubmitting,
    } = this.props;

    const changedCount = addedCount + removedCount;

    return (
      <Modal
        isOpen={isOpen}
        onClose={this.handleClose}
        title={trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_TITLE()}
        size="big"
        actions={
          <ButtonsWrapper className={styles.buttonsWrapper}>
            <Button onClick={this.handleClose}>{trans.CANCEL()}</Button>
            <Button
              type="green"
              onClick={onSubmit}
              disabled={
                isSubmitting || changedCount === 0 || selectedApps.length === 0
              }
              processing={isSubmitting}
            >
              {trans.DONE()}
            </Button>
          </ButtonsWrapper>
        }
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}
