// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { TableRowColumn, TableRow } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { BUTTONS, SECTIONS } from "pages/Snap/shared/constants";
import styles from "pages/Snap/shared/Translations.scss";
import * as iconStyles from "pages/Snap/SnapEdit/components/SnapIconPicker/icons.scss";
import { OrderColumn } from "pages/Snap/SnapEdit/components/Translations/OrderColumn";
import { isValidSection } from "pages/Snap/SnapEdit/validation";
import { classes } from "utils/classes";

export const SectionFeed = ({
  index,
  item,
  fields,
  openModal,
  dataTestId,
  remove,
  ...rest
}) => {
  const rowClass = isValidSection(item, index)
    ? styles.rowSuccess
    : styles.rowError;

  return (
    <TableRow
      className={classes(rowClass, styles.clickable)}
      dataTestId={dataTestId}
      draggable
    >
      <TableRowColumn />
      <TableRowColumn onClick={openModal}>
        <div className={iconStyles[item.icon]} />
      </TableRowColumn>
      <TableRowColumn
        columnClass={styles.titleColumn}
        onClick={() => openModal(true)}
      >
        {item.name}
      </TableRowColumn>
      <TableRowColumn columnClass={styles.contentColumn}>
        <Link href={item.url} target="_blank">
          {item.url}
        </Link>
      </TableRowColumn>
      <TableRowColumn onClick={openModal}>
        {SECTIONS.TRANSLATIONS.sectionFeed}
      </TableRowColumn>
      <OrderColumn
        arrowUpPossible={index - 1 >= 1}
        arrowDownPossible={index + 1 !== fields.length}
        fields={fields}
        index={index}
        {...rest}
      />
      <TableRowColumn>
        <ActionMenu>
          <MenuItem onClick={openModal} dataTestId="edit-menu-item">
            {BUTTONS.DROPLIST.edit}
          </MenuItem>
          <MenuItem
            onClick={() => {
              remove(index);
            }}
            dataTestId="delete-menu-item"
          >
            {BUTTONS.DROPLIST.delete}
          </MenuItem>
        </ActionMenu>
      </TableRowColumn>
    </TableRow>
  );
};

SectionFeed.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  // Formik field helper
  remove: PropTypes.func.isRequired,
};
