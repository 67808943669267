// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { ROLES } from "containers/Permissions";
import { trans } from "src/translations";

export const INBOXES = {
  provision: {
    key: "provision",
    text: trans.APP_DETAILS_MESSAGES__INBOX_PROVISION(),
  },
  moderation: {
    key: "moderation",
    text: trans.APP_DETAILS_MESSAGES__INBOX_MODERATION(),
  },
  distribution: {
    key: "distribution",
    text: trans.APP_DETAILS_MESSAGES__INBOX_DISTRIBUTION(),
  },
};

export const INBOX_ALL = "";

export const ROLES_POST_TO_DISTRIBUTOR = [ROLES.moderator.moderator];

export const FIELDS = {
  CONTENTS: {
    name: "contents",
    label: trans.APP_DETAILS_MESSAGES__FIELD_COMMENT(),
    tooltipAlignment: "bottom-start",
    tooltip: trans.APP_DETAILS_MESSAGES__COMMENT_TOOLTIP(),
  },
  DISTRIBUTOR: {
    name: "distributor",
    dataTestId: "distributor-select-field",
    size: "compact",
    required: false,
  },
  INBOX: { name: "inbox" },
};
