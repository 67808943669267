// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { SearchableListPanel } from "components/elements";
import { Checkbox } from "components/form/elements";
import { Flag } from "components/images";

import styles from "./RegionsRow.scss";

export class RegionsRow extends PureComponent {
  static propTypes = {
    country: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    isChecked: PropTypes.bool.isRequired,
    onChecked: PropTypes.func.isRequired,
  };

  handleChange = () => {
    const { country, isChecked, onChecked } = this.props;
    onChecked(country, !isChecked);
  };

  render() {
    const { country, isChecked } = this.props;

    return (
      <SearchableListPanel>
        <label className={styles.label}>
          <Flag countryCode={country.code.toLowerCase()} />
          <div className={styles.targetName}>{country.name}</div>
          <Checkbox
            checked={isChecked}
            onChange={this.handleChange}
            value={country.code}
          />
        </label>
      </SearchableListPanel>
    );
  }
}
