// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { NavBar } from "components/navigation";
import { trans } from "src/translations";

export default class NavMenu extends Component {
  items = [
    { label: trans.NAV_MENU__REQUIREMENTS(), path: "/guidelines" },
    {
      label: trans.NAV_MENU__SUPPORT_AND_HELP(),
      href: "https://www.vewd.com/contact",
    },
  ];

  render() {
    return <NavBar items={this.items} />;
  }
}
