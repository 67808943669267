// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { FieldArray, connect } from "formik";
import PropTypes from "prop-types";

import { Row, Column, Section } from "components/layout";
import { FIELDS, SECTIONS } from "pages/Snap/shared/constants";
import { Keywords } from "pages/Snap/SnapEdit/components/Keywords/Keywords";
import { SelectSource } from "pages/Snap/SnapEdit/components/SelectSource/SelectSource";
import { SnapGraphics } from "pages/Snap/SnapEdit/components/SnapGraphics/SnapGraphics";
import { SnapPreview } from "pages/Snap/SnapEdit/components/SnapPreview/SnapPreview";
import { Translations } from "pages/Snap/SnapEdit/components/Translations/Translations";

const SnapDetails = ({ formik, appId, languages }) => {
  return (
    <>
      <SelectSource name={`${appId}.${FIELDS.VT_TYPE.name}`} {...formik} />
      <Section header={SECTIONS.CONFIGURATION.header}>
        <FieldArray name={`${appId}.${FIELDS.TRANSLATIONS.name}`}>
          {(fieldHelpers) => (
            <>
              <Translations
                {...fieldHelpers}
                languages={languages}
                appId={appId}
              />
            </>
          )}
        </FieldArray>
        <Row>
          <Column>
            <Keywords appId={appId} />
          </Column>
          <Column />
        </Row>
      </Section>
      <Row>
        <SnapGraphics appId={appId} />
        <SnapPreview
          appId={appId}
          pristine={!formik.dirty}
          key={formik.values[appId]["revision_id"]}
        />
      </Row>
    </>
  );
};

export const SnapDetailsComponent = connect(SnapDetails);

SnapDetails.propTypes = {
  appId: PropTypes.string,
  languages: PropTypes.array.isRequired,
  // formik context
  formik: PropTypes.object,
};
