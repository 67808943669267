// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";
import * as React from "react";

import PropTypes from "prop-types";

import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

import { DEVICE_TYPE_QUERY } from "./constants";

export class TargetsActionMenu extends Component {
  static propTypes = {
    onDelete: PropTypes.func,
    onEditDevices: PropTypes.func,
    onEditRegions: PropTypes.func,
    targetType: PropTypes.string,
  };

  renderMenuItems = ({ closePopup }) => {
    const { onDelete, onEditDevices, onEditRegions, targetType } = this.props;

    const handleTargetRemove = () => {
      onDelete();
      closePopup();
    };

    return (
      <React.Fragment>
        {targetType === DEVICE_TYPE_QUERY ? (
          <MenuItem dataTestId="edit-query-item" onClick={onEditDevices}>
            {trans.TARGETS__EDIT_QUERY()}
          </MenuItem>
        ) : (
          <Fragment>
            <MenuItem dataTestId="edit-devices-item" onClick={onEditDevices}>
              {trans.TARGETS__EDIT_DEVICES()}
            </MenuItem>
            <MenuItem dataTestId="edit-regions-item" onClick={onEditRegions}>
              {trans.TARGETS__EDIT_REGIONS()}
            </MenuItem>
          </Fragment>
        )}
        <MenuItem dataTestId="remove-item" onClick={handleTargetRemove}>
          {trans.TARGETS__REMOVE()}
        </MenuItem>
      </React.Fragment>
    );
  };

  render() {
    return <ActionMenu>{this.renderMenuItems}</ActionMenu>;
  }
}
