import {
  SLUG_FIELD_NAME,
  AUTOSLUG_FIELD_NAME,
} from "components/form/fields/SlugField.js";
import { trans } from "src/translations";

export const FIELDS = {
  autoSlug: {
    name: AUTOSLUG_FIELD_NAME,
    label: trans.DEVICES__AUTOGENERATED_LABEL(),
    test: {
      label: trans.DEVICES__AUTOGENERATED_LABEL(),
      isDefault: true,
    },
  },
  deviceFamily: {
    name: "deviceFamily",
    test: {
      label: trans.DEVICES__FAMILY_LABEL(),
      isDefault: false,
    },
  },
  enabled: {
    name: "enabled",
    type: "checkbox",
    label: trans.DEVICES__ENABLED_LABEL(),
    test: {
      label: trans.DEVICES__ENABLED_LABEL(),
      isDefault: true,
    },
  },
  name: {
    name: "name",
    required: true,
    label: trans.DEVICES__MODEL_NAME_LABEL(),
    dataTestId: "name-field",
    test: {
      label: trans.DEVICES__MODEL_NAME_LABEL(),
      isDefault: true,
    },
  },
  organization: {
    name: "organization",
    test: {
      label: trans.DEVICES__DISTR_CUSTOMER_LABEL(),
      isDefault: true,
    },
  },
  slug: {
    name: SLUG_FIELD_NAME,
    label: trans.DEVICES__SLUG_LABEL(),
    test: {
      label: trans.DEVICES__SLUG_LABEL(),
      isDefault: true,
    },
  },
  testing: {
    name: "testing",
    type: "checkbox",
    label: trans.DEVICES__TESTING_LABEL(),
    test: {
      label: trans.DEVICES__TESTING_LABEL(),
      isDefault: true,
    },
  },
};
