// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

import {
  PARSE_FILTER_REGEXP,
  PAIR_VALUES_SEPARATOR,
  FILTER_EXCLUSION_TRIGGER_CHARACTER,
} from "../constants";
import {
  createFilter,
  getFilterObjectByName,
  flattenFilterDataChoices,
} from "./utils";

/**
 * Given the value of the filter (part after ":" in user-provided search query),
 * find the selected value. If the filter has predefined set of values,
 * will return it's "query" property. Otherwise, will return
 * raw user-provided string.
 */
const getFilterValue = (filterObject, filterValue) => {
  const values = get(filterObject, "values", []);
  const normalizedFilterValue = filterValue.toLowerCase();

  const pairValues = normalizedFilterValue
    .split(PAIR_VALUES_SEPARATOR)
    .filter((val) => val);

  if (pairValues.length === 2) {
    // process filter value consiting of 2 items
    // used in 'Structures' country-device target filtering
    const valueObject = values.find(
      (v) => v.display.toLowerCase() === `${pairValues[0]}:`
    );

    // If no valueObject is found it means that user typed two ":" outside 'Structures' countr-device target filter
    if (valueObject) {
      const secondValue = filterValue.split(PAIR_VALUES_SEPARATOR)[1];
      return `${valueObject.query}${secondValue}`;
    }
  }

  const valueObject = values.find(
    (v) => v.display.toLowerCase() === normalizedFilterValue
  );
  return get(valueObject, "query", filterValue);
};

/**
 * Given search query (e.g. "/priority:p2") and data about all possible filters,
 * will try to find a matching filter.
 */
export const createFilterFromSearchString = (
  searchValue = "",
  filtersDataChoices
) => {
  const allFilters = flattenFilterDataChoices(filtersDataChoices);

  const [match, filterName, filterValue] =
    searchValue.match(PARSE_FILTER_REGEXP) || [];

  if (!match) {
    return undefined;
  }

  const filterObject = getFilterObjectByName(filterName, allFilters);
  if (!filterObject) {
    return undefined;
  }

  const isExclusionFilter = match.startsWith(
    FILTER_EXCLUSION_TRIGGER_CHARACTER
  );
  const value = getFilterValue(filterObject, filterValue);
  return createFilter(filterObject, value, isExclusionFilter);
};
