// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";
import { capitalize } from "utils/string";

import styles from "./buttonsWrapper.scss";

/**
 * Adds margins between wrapped buttons.
 */
export class ButtonsWrapper extends Component {
  static propTypes = {
    /**
     * Button components
     */
    children: PropTypes.node,
    position: PropTypes.oneOf(["left", "right", "center", "modal"]),
    className: PropTypes.string,
  };

  static defaultProps = {
    position: "right",
  };

  render() {
    const { children, position, className } = this.props;
    return (
      <div
        className={classes(
          styles.wrapper,
          styles[`align${capitalize(position)}`],
          className
        )}
        style={{
          textAlign: position,
        }}
      >
        {children}
      </div>
    );
  }
}

export default ButtonsWrapper;
