// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { format } from "date-fns";

import { NO_VALUE_PLACEHOLDER } from "../constants";

const isDate = (date: Date): boolean =>
  date instanceof Date && !isNaN(date.getTime());

export const formatDate = (date: Date): string =>
  isDate(date) ? format(date, "yyyy-LLL-dd") : NO_VALUE_PLACEHOLDER;

export const formatTime = (time: Date): string =>
  isDate(time) ? format(time, "hh:mm a") : NO_VALUE_PLACEHOLDER;

export const formatDateTime = (dateTime: Date): string =>
  isDate(dateTime)
    ? format(dateTime, "yyyy-LLL-dd, hh:mm a")
    : NO_VALUE_PLACEHOLDER;
