// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { format } from "date-fns";
import { Formik, Form } from "formik";
import isEmpty from "lodash-es/isEmpty";
import set from "lodash-es/set";
import PropTypes from "prop-types";

import { withPreviousLocation } from "containers/PreviousLocation";
import { api } from "containers/Request";
import { TARGETS_FIELD_NAME } from "pages/_shared";
import { withTargetsSend } from "pages/_shared/TargetsField/decorators/withTargetsSend";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";
import { dataFormatter } from "utils/jsonApi";
import {
  pipeValidators,
  hasOneElement,
  isRequired,
  maxLengthOfStr,
} from "utils/validation";

import { FIELD_NAME as PROMOTIONS_FIELD_NAME } from "../_shared/CampaignForm/fields/PromotionsField";
import { CAMPAIGN_TYPES } from "../constants";
import { AdminCampaignFormRendFields } from "./AdminCampaignFormRendFields";
import { FIELDS, SUBMIT_ATTEMPTED } from "./constants";
import { ForAdminContext } from "./context";

const jsonPointerToFieldName = {
  "/data/attributes/name": "name",
  "/data/attributes/description": "description",
  "/data/attributes/score": "score",
  "/data/relationships/targets": "targets._error",
  "/data/relationships/promotion": "promotion",
  "/data/attributes/start_date": "scope",
  "/data/attributes/end_date": "scope",
};

@withPreviousLocation({ defaultPath: "/promotions/campaigns" })
@withTargetsSend({
  distributorFieldNameInRequest: "organizations",
  distributorDeviceType: "organization",
})
@connect(null, (dispatch, ownProps) => ({
  createCampaign: (body) => {
    const createCampaignAction = ownProps.forAdmin
      ? api.createCampaign.action
      : api.createMyCampaign.action;
    return dispatch(createCampaignAction({ options: { body } }));
  },
  updateCampaign: (id, body) => {
    const updateMyCampaignAction = ownProps.forAdmin
      ? api.patchCampaign.action
      : api.patchMyCampaign.action;
    return dispatch(
      updateMyCampaignAction({ params: { id }, options: { body } })
    );
  },
}))
export class AdminCampaignFormRend extends Component {
  static contextType = ForAdminContext;

  static propTypes = {
    id: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    createCampaign: PropTypes.func.isRequired,
    updateCampaign: PropTypes.func.isRequired,
    forAdmin: PropTypes.bool,

    // from @withPreviousLocation
    goBack: PropTypes.func.isRequired,

    // from @withTargetsSend
    createTargetsBody: PropTypes.func,
  };

  createBody = ({ promotions, targets, scope: { start, end }, ...values }) => {
    // Due to targets relationship, this request has a very unusual structure
    // that does not conform with jsona.
    const { createTargetsBody } = this.props;
    const forAdmin = this.context;

    const data = {
      ...values,
      type: "campaign",
      start_date: start ? format(start, "yyyy-LL-dd") : start,
      end_date: end ? format(end, "yyyy-LL-dd") : end,
      campaign_type: forAdmin ? CAMPAIGN_TYPES.ADMIN : CAMPAIGN_TYPES.CUSTOMER,
      promotions: (promotions || []).map((id) => ({
        type: "promotion",
        id,
      })),
      relationshipNames: ["promotions"],
    };
    const body = dataFormatter.serialize({ stuff: data });

    const targetsBody = createTargetsBody("campaign_target", targets);
    set(body, "data.relationships.targets", targetsBody);

    return JSON.stringify(body);
  };

  validate = (values) => {
    const MAX_LENGTH = 200;

    const fields = pipeValidators(
      maxLengthOfStr(FIELDS.DESCRIPTION.name, MAX_LENGTH)
    )(values);

    const promotionsError = pipeValidators(
      hasOneElement(PROMOTIONS_FIELD_NAME)
    )(values);

    const targetsValidators = [
      hasOneElement(TARGETS_FIELD_NAME),
      ...(values[TARGETS_FIELD_NAME] || []).flatMap((_, idx) => [
        isRequired(
          `${TARGETS_FIELD_NAME}[${idx}].type`,
          trans.CAMPAIGN_EDIT__FIELD_TARGETS_DEVICE_NOT_EMPTY_ERROR()
        ),
      ]),
    ];

    const targetsError = pipeValidators(...targetsValidators)(values);

    return {
      ...fields,
      ...promotionsError,
      ...targetsError,
    };
  };

  submit = async (values, { setErrors, setStatus }) => {
    const { id, createCampaign, updateCampaign, goBack } = this.props;

    const body = this.createBody(values);

    let request;
    if (this.isEdit()) {
      request = await updateCampaign(id, body);
    } else {
      request = await createCampaign(body);
    }

    if (request.error) {
      const submitErrors = prepareErrorsForForm(
        request.error,
        jsonPointerToFieldName
      );
      setErrors({
        ...submitErrors,
        _submitFailed: true,
      });
    } else {
      // hide previously rendered errors
      setStatus(null);

      const message = this.isEdit()
        ? trans.CHANGES_SAVE_SUCCESS()
        : trans.CAMPAIGN__CREATE_SUCCESS();

      goBack({ message: message });
    }
  };

  isEdit() {
    return typeof this.props.id !== "undefined";
  }

  isReadonly = () => {
    const { initialValues, forAdmin } = this.props;
    return (
      !forAdmin && initialValues.campaign_type === CAMPAIGN_TYPES.ADMIN_MANAGED
    );
  };

  render() {
    const { initialValues } = this.props;
    return (
      <div>
        <Formik
          onSubmit={this.submit}
          initialValues={initialValues}
          validate={this.validate}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            isSubmitting,
            dirty,
            setFieldValue,
            values,
            errors,
            status,
            setStatus,
          }) => {
            const errorsPresent = !isEmpty(errors);
            const submitFailed =
              (errors?._submitFailed ||
                (errorsPresent && status === SUBMIT_ATTEMPTED)) ??
              false;

            return (
              <Form data-test-id="admin-campaign-form">
                <AdminCampaignFormRendFields
                  handleSubmit={handleSubmit}
                  initialValues={initialValues}
                  isSubmitting={isSubmitting}
                  dirty={dirty}
                  setFieldValue={setFieldValue}
                  values={values}
                  isReadonly={this.isReadonly()}
                  isEdit={this.isEdit()}
                  forAdmin={this.context}
                  submitFailed={submitFailed}
                  setStatus={setStatus}
                  errorsPresent={errorsPresent}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
