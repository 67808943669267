// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

/* eslint-disable no-magic-numbers */
import { initialFile } from "utils/formik/utils";

const SNAP_EDIT_MINIMUM_SECTION_NUMBER = 1;
const HOME_ID = 0;

// default snap sections
const HOME_SECTION = {
  icon: "home",
  name: "Home",
  url: "",
  show: false,
};

export const AUTO_GENERATED_OPTIONS = {
  RECENT: "OPTION_RECENT",
  POPULAR: "OPTION_POPULAR",
  DATE: "OPTION_DATE",
  PICKS: "OPTION_PICKS",
  SERIES_CATEGORIES: "OPTION_SERIES_CATEGORIES",
};

const HOME_SECTION_OPTIONS = {
  [AUTO_GENERATED_OPTIONS.RECENT]: true,
  [AUTO_GENERATED_OPTIONS.POPULAR]: false,
  [AUTO_GENERATED_OPTIONS.DATE]: false,
  [AUTO_GENERATED_OPTIONS.PICKS]: true,
  [AUTO_GENERATED_OPTIONS.SERIES_CATEGORIES]: false,
};

const DEFAULT_SECTION = {
  icon: "videos",
  name: "",
  url: "",
  show: true,
  live: false,
  live_feeds: [],
};

const DEFAULT_SECTION_OPTIONS = {
  [AUTO_GENERATED_OPTIONS.RECENT]: true,
  [AUTO_GENERATED_OPTIONS.POPULAR]: false,
  [AUTO_GENERATED_OPTIONS.DATE]: true,
  [AUTO_GENERATED_OPTIONS.SERIES_CATEGORIES]: false,
};

const LIVE_FEED = {
  icon: "",
  url: "",
  name: "",
  thumbnail: "",
  thumbnail_width: NaN,
  thumbnail_height: NaN,
  thumbnail_resolution: "",
  feed_type: "application/vnd.apple.mpegurl",
  description: "",
};

const NEW_LIVE_SECTION = {
  ...DEFAULT_SECTION,
  name: "Live",
  live: true,
  live_feeds: [LIVE_FEED],
};

// default snap translations
const DEFAULT_TRANSLATION = {
  language: "en",
  application_title: "",
  default_translation: true,
  sections: [HOME_SECTION],
};

const NEW_TRANSLATION = {
  language: "",
  application_title: "",
  default_translation: false,
  sections: [HOME_SECTION],
};

// default snap revision
const INITIAL_VALUES = {
  translations: [DEFAULT_TRANSLATION],
  background: initialFile("", "background", 0),
  logo: initialFile("", "logo", 0),
  ads_enabled: "false",
  autodarken_background: "false",
  categories_from_tags: "false",
  midroll_type: "DISABLED",
  midroll_offset: 480,
  midroll_interval: 0,
  midroll_min_interval: 0,
  midroll_min_offset: 0,
  preroll_type: "OUR",
  preroll_offset: 0,
  preroll_interval: 1,
  preroll_minimum_content_length: 480,
};

const DEFAULT_APP_PROPS = {
  is_test: false,
  template: "snap",
  type: "html",
};

const ERRORS = {
  ENTER_APPLICATION_TITLE: "Enter application title",
  FILL_ALL_FIELDS: "You need to fill out all required fields",
  NO_REVISION_AVAILABLE: "Application has no saved versions",
  SELECT_LANGUAGE: "Select language",
  VIDEO_SOURCE_SELECT: "Pick video hosting service",
  INCORRECT_VALUE: "Value is incorrect",
};

const GRAPHICS_DIMENSIONS = {
  LOGO: {
    formats: ["image/jpeg", "image/png"],
    width: 612,
    height: 612,
    mode: "square",
    size: 500 * 1024,
  },
  BACKGROUND: {
    formats: ["image/jpeg"],
    width: 1280,
    height: 720,
    mode: "landscape",
    size: 1024 * 1024,
  },
};

const BUTTONS = {
  DROPLIST: {
    details: "Application details",
    delete: "Delete",
    edit: "Edit",
    editDetails: "Edit details",
  },
  MODAL: {
    ok: "OK",
    cancel: "Cancel",
    keepDraft: "Keep draft",
    discardDraft: "Discard draft",
  },
  NAV: {
    discard: "Discard draft",
    next: "Next",
    previous: "Previous",
    save: "Save draft",
    create: "Create App",
    submit: "Submit App",
  },
  PREVIEW: {
    create: "Create Preview",
    refresh: "Refresh Preview",
  },
  SECTION: {
    addHome: "Add Home Section",
    addLiveStream: "Add Live Stream",
    addSection: "Add Section",
  },
};

const FIELDS = {
  AD_PROVIDER_NAME: {
    label: "Ad Provider",
    name: "adProviderName",
    radios: [
      {
        value: "SpotX",
        label: "Use Vewd advertising service",
      },
      {
        value: "External",
        label: "Use your own Ad Server",
      },
    ],
  },
  AD_PROVIDER_VAST: {
    label: "Vast Tag",
    name: "adProviderVast",
  },
  AD_TYPES: {
    label: "Ad placement",
    MID: {
      name: "midroll_type",
      label: "Mid-roll",
    },
    PRE: {
      name: "preroll_type",
      label: "Pre-roll",
    },
  },
  AD_PRE_OFFSET: {
    label: "Initial offset",
    name: "preroll_offset",
    tooltip:
      "After how many videos the first pre-roll ad will be shown. Default is 0, meaning play ads immediately, before any video is played.",
  },
  AD_PRE_INTERVAL: {
    label: "Video based frequency",
    name: "preroll_interval",
    tooltip:
      "How many videos should be played between two pre-rolls. This clause is combined with a logical AND with the Time based frequency. \n" +
      "Default is 1 (i.e. play an ad before each video).",
  },
  AD_PRE_MIN_CONTENT_LENGTH: {
    label: "Time based frequency",
    name: "preroll_minimum_content_length",
    tooltip:
      "Minimum playback time between two pre-rolls, e.g. if the value is set to 60s (1m) we will wait at least 60s before playing the next preroll. This clause is combined with a logical AND with the video based frequency. \n" +
      "Default is 0 (i.e. play an ad at the next pre-roll opportunity).",
  },
  AD_MID_TYPE_SELECT: {
    label: "Break-points definition",
    tooltip:
      "Sets how breakpoints are defined. Either fixed time intervals or percentage of the total video play progress.",
    options: [
      {
        value: "OUR",
        text: "Fixed time intervals",
      },
      {
        value: "OUR_PERCENT",
        text: "% of video progress",
      },
    ],
  },
  AD_MID_OFFSET: {
    label: "Initial offset",
    name: "midroll_offset",
    default_time: 480,
    default_percentage: 50,
    tooltip:
      "Defines after how many seconds the first ad will be displayed within every video.\n" +
      "For fixed time breakpoints, this is an absolute value in seconds. \n" +
      "For percentage of progress based breakpoints, this value will represent a percentage value of whole video.\n" +
      "Default is 0 (i.e. show ad on video start).",
  },
  AD_MID_INTERVAL: {
    label: "Frequency",
    name: "midroll_interval",
    default_time: 480,
    default_percentage: 0,
    tooltip:
      "Defines how often ads will be shown after first ad is shown.\n" +
      "For fixed time breakpoints, this is an absolute value in seconds.\n" +
      "For percentage of progress based breakpoints, this value will represent a percentage value of whole video.",
  },
  AD_MID_MIN_OFFSET: {
    label: "Min offset",
    name: "midroll_min_offset",
    tooltip:
      "When break points are defined as percentage of total video length, this is used as a minimum value i.e. if the percentage value is smaller than this min, then the min is used instead.",
  },
  AD_MID_MIN_INTERVAL: {
    label: "Min frequency",
    name: "midroll_min_interval",
    tooltip:
      "When break points are defined as percentage of total video length, this is used as a minimum value i.e. if the percentage value is smaller than this min, then the min is used instead.",
  },
  ANALYTICS: {
    label: "Google analytics id",
    name: "ga_tracker_id",
  },
  AUTODARKEN_BG: {
    label: "Autodarken Background",
    name: "autodarken_background",
  },
  BACKGROUND: {
    label: "Background",
    name: "background",
  },
  DETAILS: {
    label: "Hosting service",
    text: "Choose hosting service",
  },
  LIVE_TITLE: {
    label: "Title",
    name: "name",
  },
  LIVE_URL: {
    label: "URL",
    name: "url",
  },
  LIVE_THUMB: {
    label: "Thumbnail image URL",
    name: "thumbnail",
  },
  LIVE_THUMB_RESOLUTION: {
    label: "Thumbnail image resolution",
    name: "thumbnail_resolution",
  },
  LIVE_TYPE: {
    label: "Protocol",
    name: "feed_type",
    options: [
      {
        value: "application/vnd.apple.mpegurl",
        text: "HLS (application/vnd.apple.mpegurl)",
      },
      {
        value: "application/vnd.ms-sstr+xml",
        text: "MSSS (application/vnd.ms-sstr+xml)",
      },
    ],
  },
  LIVE_DESCRIPTION: {
    label: "Description",
    name: "description",
  },
  APPLICATION_LINK: {
    title: "Link to Snap Application",
    linkLabel: "Edit application",
    description:
      "This link will redirect you to page where you can create preview of draft application",
  },
  HOME_VISIBLE: {
    name: "show",
  },
  LOGO: {
    label: "Logo",
    name: "logo",
  },
  KEYWORDS_PREFIX: {
    label: "keywords prefix",
    name: "keyword_prefix",
  },
  KEYWORDS_USE: {
    label: "Use keywords to define categories",
    name: "categories_from_tags",
    tooltip:
      'Content of <media:keywords> tag will be used as category name. If you define prefix, only prefixed keyword will be used - for example for prefix "snap_" and keyword snap_Skiing, Skiing category will be created',
  },
  MONETIZATION: {
    label: "Revenue source",
    name: "ads_enabled",
    radios: [
      { value: "false", label: "Free", dataTestId: "free-radio" },
      {
        value: "true",
        label: "Advertisement",
        dataTestId: "advertisement-radio",
      },
    ],
  },
  NAME: {
    label: "Application Name",
    name: "name",
  },
  [AUTO_GENERATED_OPTIONS.DATE]: {
    label: "Date based categories",
    name: "date_categories_enabled",
    dataTestId: "date-option",
  },
  [AUTO_GENERATED_OPTIONS.PICKS]: {
    label: "Today's picks",
    name: "todays_picks_enabled",
    dataTestId: "picks-option",
  },
  [AUTO_GENERATED_OPTIONS.POPULAR]: {
    label: "Popular videos",
    name: "popular_enabled",
    dataTestId: "popular-option",
  },
  [AUTO_GENERATED_OPTIONS.RECENT]: {
    label: "Latest videos",
    name: "recent_enabled",
    dataTestId: "recent-option",
  },
  [AUTO_GENERATED_OPTIONS.SERIES_CATEGORIES]: {
    label: "TV Series based categories",
    name: "series_categories_enabled",
    dataTestId: "tv-series-categories-option",
  },
  SECTION_HOME_TYPE: {
    label: "Home Section type",
    name: "home_type",
    radios: [
      {
        value: "false",
        label: "New Videos from other App Sections",
        dataTestId: "new-videos-radio",
      },
      { value: "true", label: "Custom", dataTestId: "custom-radio" },
    ],
  },
  SECTION_ICON: {
    name: "icon",
  },
  SECTION_NAME: {
    label: "Title",
    name: "name",
  },
  SECTION_URL: {
    label: "URL",
    name: "url",
  },
  TRANSLATION_APP_TITLE: {
    label: "Application title",
    name: "application_title",
  },
  TRANSLATION_SELECT: {
    label: "Language",
    name: "language",
    text: "Choose language",
  },
  TRANSLATION_DEFAULT: {
    label: "Default",
    name: "default_translation",
  },
  TRANSLATIONS: {
    name: "translations",
  },
  VT_TYPE: {
    name: "vt_type",
  },
};

const MESSAGES = {
  draftCannotSave:
    "Draft cannot be saved, because there are some errors in your form",
  draftError:
    "Oops... Something wrong happened... We could not save a draft. Try again in a while...",
  draftSuccess:
    "Draft has been successfully saved. Keep in ming, you must resubmit your App to make changes happen.",
  draftSuccessNotValid:
    "Draft has been saved, but it seems that there are some errors, or missing details in the form.\r\n Make sure to correct that before resubmitting App.",
  submitErrorNotValid:
    "There are some errors or missing details in your form.\r\n Make sure to correct that before submitting App.",
  genericError: "Oops! Something went wrong...",
};

const MODALS = {
  section: "Configure Section",
  liveStream: "Configure Live Stream",
  liveSection: "Configure Live Section",
};

const RADIO_DEFAULT = [
  { value: "false", label: "No", dataTestId: "no-radio" },
  { value: "true", label: "Yes", dataTestId: "yes-radio" },
];

const SECTIONS = {
  HEADER: "My Apps",
  ADS: {
    header: "Advertisement",
    title: "Advertisement",
    midrollHeader: "Mid-Roll",
    prerollHeader: "Pre-Roll",
    typeHeader: "Ad Types",
    info: "Monetized apps require a contract addendum. Please contact the content team at ",
    email: "cloud-bizdev@vewd.com",
  },
  ANALYTICS: {
    header: "Analytics",
    title: "Analytics",
    content:
      "If you want to track app traffic within your own Google Analytics account, please provide here the tracking ID of your GA property. Note that in order to take full advantage of our tracking capabilities you need to configure your GA property as described in ",
    disabled: "Not specified",
  },
  APPS_LIST: {
    colCreated: "Date created",
    colName: "Name",
    colStatus: "Creation status",
    colSubmitted: "Latest submission",
  },
  CONFIGURATION: {
    header: "Snap configuration",
  },
  DETAILS: {
    header: "Snap Details",
  },
  FORM: {
    discardDraftTitle: "Discard Snap draft?",
    discardDraftMsg:
      "Are you sure that you want to discard your draft? This will not affect your already submitted Snap app",
  },
  GRAPHICS: {
    header: "Snap Graphics",
    autodarkenEnabled: "Enabled",
    autodarkenDisabled: "Disabled",
  },
  ICONS: {
    header: "Section icons",
  },
  KEYWORDS: {
    header: "Keywords to define categories",
    enabled: "Enabled, without prefix",
    enabledWithPrefix: 'Enabled, prefix: "$0"',
    disabled: "Disabled",
  },
  MONETIZATION: {
    header: "Monetization",
    title: "Monetization guide",
    typeHeader: "Type of monetization",
    enabledAds: "Advertisement",
    disabled: "None",
  },
  NAME: {
    header: "Let's Begin...",
    title: "Application Name",
    content:
      "The application name can not be changed after app creation. Language specific app title visible for end-user can be changed.",
  },
  PREVIEW: {
    header: "Preview",
    titleTV: "Preview Link of app version 3 (TV)",
    titleAuto: "Preview Link of app version 4 (automotive)",
    helpText:
      "For this version of the app, we recommend to test the app with touch enabled in devtools",
    no_preview:
      "Preview shows how the app will look like. Your structure preview will be generated automatically after saving a draft or submitting an app. Save draft to show the link. Preview will be also available in app details.",
    content:
      "Preview shows how the app will look like. Some features, such as search or subscription services are not enabled in preview.",
    info: "Link will be updated with all the changes done after the application is saved or submitted again.",
    outdated: "Changes made. To update save or submit the app",
  },
  SOURCE: {
    header: "Source",
    undefined: "Not specified",
  },
  SUBMITTED_VERSION: {
    content:
      "You are editing a submitted application. The draft needs to be submitted for any changes to be applied.",
    button: "View Submitted Version",
  },
  TRANSLATIONS: {
    colIcon: "Icon",
    colOrder: "",
    colTitle: "Title",
    colType: "Type",
    colURL: "URL",
    noLiveSection: "No live stream added",
    noOptions: "None",
    noSection: "No sections added",
    notEnoughSections: "Please add section or a live stream",
    tooManySections: "You have reached the limit of sections",
    sectionGenerated: "Generated",
    sectionFeed: "Section feed",
    sectionLive: "Live section",
    streamLive: "Live stream",
    draftSaved: "Draft Saved",
  },
};

const STATES = {
  ACTIVE: "Active",
  CREATED: "Created",
  DRAFT: "Draft",
  NEW: "New",
};

const TABS = {
  CONTENT: {
    label: "Content & Graphics",
    name: "content-and-graphics",
    title: "Content and graphics",
  },
  MONETIZATION: {
    label: "Monetization",
    // label: "Monetization & Stats (Optional)", TODO LVCS-2535: Disable Analytics ID field
    name: "monetization-and-stats",
    title: "Monetization",
    // title: "Monetization and stats", TODO LVCS-2535: Disable Analytics ID field
  },
};

export {
  HOME_ID,
  HOME_SECTION,
  HOME_SECTION_OPTIONS,
  LIVE_FEED,
  DEFAULT_SECTION,
  DEFAULT_SECTION_OPTIONS,
  NEW_LIVE_SECTION,
  NEW_TRANSLATION,
  SNAP_EDIT_MINIMUM_SECTION_NUMBER,
  INITIAL_VALUES,
  DEFAULT_APP_PROPS,
  ERRORS,
  GRAPHICS_DIMENSIONS,
  BUTTONS,
  FIELDS,
  MESSAGES,
  MODALS,
  RADIO_DEFAULT,
  SECTIONS,
  STATES,
  TABS,
};
