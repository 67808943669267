// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { isEmpty } from "utils/object";

import { menuConfig } from "../config";
import { DEFAULT_REDIRECT_PATH } from "./constants";

const getMainItemForGivenPath = (path, activeOrganization) => {
  return menuConfig(activeOrganization).find((mi) => mi.path === path);
};

const getFirstVisibleSubItem = (mainItem, activeOrganization) => {
  return mainItem.items.find((item) => {
    const allUsersAllowed = !item.allowed || isEmpty(item.allowed);
    const userWithoutOrganization =
      !activeOrganization || !activeOrganization.roles;

    if (allUsersAllowed) {
      return true;
    }

    if (userWithoutOrganization) {
      return false;
    }

    return item.allowed.some((allowedRole) => {
      return activeOrganization.roles.includes(allowedRole);
    });
  });
};

export const getRedirectPath = (fromPath, activeOrganization) => {
  const mainItem = getMainItemForGivenPath(fromPath, activeOrganization);

  if (!mainItem) {
    return DEFAULT_REDIRECT_PATH;
  }

  const firstSubItem = getFirstVisibleSubItem(mainItem, activeOrganization);

  if (!firstSubItem) {
    return DEFAULT_REDIRECT_PATH;
  }

  return firstSubItem.path;
};
