// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { PageHeader, PageContent, PageError } from "components/layout";
import { Tabs, TabContent, Tab } from "components/navigation";
import { apiThatThrows } from "containers/Request";
import { trans } from "src/translations";

@connect(null, (dispatch, ownProps) => ({
  getDeviceModel: async (id) => {
    let response;
    if (ownProps.forAdmin) {
      response = await dispatch(
        apiThatThrows.getDeviceModel.action({
          params: { id },
        })
      );
    } else {
      response = await dispatch(
        apiThatThrows.getMyDeviceModel.action({
          params: { id },
        })
      );
    }

    return response.results;
  },
}))
export class DeviceModelLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    // form @ExtendedRoute
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,

    forAdmin: PropTypes.bool,

    // from @connect
    getDeviceModel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    forAdmin: false,
  };

  state = {
    deviceModelName: "",
    error: undefined,
    loading: true,
  };

  componentDidMount = () => {
    this.updateDeviceModelName();
  };

  componentDidUpdate = (prevProps) => {
    const { id } = this.props.params;

    if (prevProps.params.id !== id) {
      this.updateDeviceModelName();
    }
  };

  getDeviceModelId = () => {
    return this.props.params.id;
  };

  updateDeviceModelName = async () => {
    const { getDeviceModel } = this.props;
    try {
      this.setState({ error: undefined, loading: true });
      const deviceModelId = this.getDeviceModelId();
      const deviceModel = await getDeviceModel(deviceModelId);
      this.setState({ deviceModelName: deviceModel.name });
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ loading: false });
    }
  };

  getMainPath = () => {
    const { forAdmin } = this.props;
    const deviceModelId = this.getDeviceModelId();

    if (forAdmin) {
      return `/devices/models/${deviceModelId}`;
    }

    return `/devices/my/${deviceModelId}`;
  };

  render() {
    const { children, forAdmin } = this.props;
    const { deviceModelName, error, loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <PageError
          pageHeader={trans.DEVICES__DEVICE_DETAILS_HEADER()}
          error={error}
        />
      );
    }

    const mainPath = this.getMainPath();

    return (
      <div>
        <PageHeader>{deviceModelName}</PageHeader>
        <PageContent>
          <Tabs>
            <Tab
              to={`${mainPath}/details`}
              text={trans.DEVICES__VIEW_DETAILS_TAB()}
            />
            {forAdmin && (
              <Tab
                to={`${mainPath}/edit`}
                text={trans.DEVICES__EDIT_DETAILS_TAB()}
              />
            )}
            <Tab
              to={`${mainPath}/applications`}
              text={trans.DEVICES__APPLICATIONS_TAB()}
            />
          </Tabs>
          <TabContent>
            <TabContent>{children}</TabContent>
          </TabContent>
        </PageContent>
      </div>
    );
  }
}
