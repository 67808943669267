// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import {
  TableRow,
  TableRowColumnExtended,
  TableRowColumn,
} from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { trans } from "src/translations";

import styles from "./PromotionTypeRow.scss";

const NO_PROMOTIONS_ASSIGNED = 0;

export class PromotionTypeRow extends Component {
  static propTypes = {
    promotionType: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      softwareAssets: PropTypes.array.isRequired,
      promotionsCount: PropTypes.number.isRequired,
    }),
    onRemove: PropTypes.func.isRequired,
  };

  render() {
    const { onRemove, promotionType } = this.props;
    const { id, name, softwareAssets, promotionsCount } = promotionType;
    return (
      <TableRow key={id}>
        <TableRowColumnExtended
          className={styles.bold}
          text={name}
          to={`/promotions/admin/types/edit/${id}`}
        />
        <TableRowColumn className={styles.assets}>
          {softwareAssets.length}
        </TableRowColumn>
        <TableRowColumn className={styles.count}>
          {promotionsCount}
        </TableRowColumn>
        <TableRowColumn className={styles.menu}>
          <ActionMenu>
            <MenuItem to={`/promotions/admin/types/edit/${id}`}>
              {trans.EDIT_DETAILS()}
            </MenuItem>
            <MenuItem
              onClick={() => onRemove(promotionType)}
              disabled={promotionsCount !== NO_PROMOTIONS_ASSIGNED}
            >
              {trans.PROMOTION_TYPE_LIST__REMOVE_TYPE()}
            </MenuItem>
          </ActionMenu>
        </TableRowColumn>
      </TableRow>
    );
  }
}
