// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { PageHeader, PageContent } from "components/layout";
import { trans } from "src/translations";

import { UpdateJobListData } from "./UpdateJobListData";

export class UpdateJobList extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>{trans.UPDATES__UPDATE_JOB_LIST__HEADER()}</PageHeader>
        <PageContent>
          <UpdateJobListData />
        </PageContent>
      </div>
    );
  }
}
