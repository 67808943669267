// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { FieldArray } from "formik";
import PropTypes from "prop-types";

import { Label, ValidationBadge } from "components/form";
import { Checkbox } from "components/form/elements";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";

import styles from "./TypeField.scss";

const NOT_REMOVABLE_TYPES = ["provider"];
const DISTRIBUTOR_ORG = "Distributor";
const REQUIRE_DISTRIBUTOR = [
  "Structures",
  "SoftwareUpdates",
  "DeviceInventory",
  "PromotionManagement",
];

const WithTooltip = ({ isDistributorRequired, children }) => {
  return isDistributorRequired ? (
    <div className={styles.label}>
      {children}
      <Tooltip
        className={styles.tooltip}
        content={trans.ORGANIZATION__TYPE_DISTRIBUTOR_DEPENDENT_TOOLTIP()}
      >
        (?)
      </Tooltip>
    </div>
  ) : (
    <div className={styles.label}>{children}</div>
  );
};

WithTooltip.propTypes = {
  isDistributorRequired: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export class TypeField extends PureComponent {
  static propTypes = {
    allOrganizationTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    fieldname: PropTypes.string.isRequired,
  };

  state = {
    isDistributor: null,
  };

  handleChange = (id, typeValues, remove, push) => () => {
    const selectedTypesIds = typeValues || [];
    const idx = selectedTypesIds.findIndex((selectedTypeId) => {
      return selectedTypeId === id;
    });
    if (idx !== -1 && id === DISTRIBUTOR_ORG) {
      const fieldsToUncheck = REQUIRE_DISTRIBUTOR.concat(DISTRIBUTOR_ORG);
      for (let i = selectedTypesIds.length - 1; i >= 0; i--) {
        if (fieldsToUncheck.includes(selectedTypesIds[i])) {
          remove(i);
        }
      }
      this.setState({ isDistributor: false });
    } else if (idx !== -1) {
      remove(idx);
    } else {
      push(id);
      if (id === DISTRIBUTOR_ORG) {
        this.setState({ isDistributor: true });
      }
    }
  };

  isChecked(typeId, typeValues) {
    const selectedTypesIds = typeValues || [];
    return selectedTypesIds.some((selectedTypeId) => selectedTypeId === typeId);
  }

  renderFields = ({ remove, push, form: { getFieldMeta } }) => {
    const { allOrganizationTypes, fieldname } = this.props;
    const meta = getFieldMeta(fieldname);
    const typeValues = meta.value ?? [];

    if (typeValues.includes(DISTRIBUTOR_ORG)) {
      this.setState({ isDistributor: true });
    }

    return (
      <div>
        {allOrganizationTypes.map((t) => {
          const isDistributorRequired = REQUIRE_DISTRIBUTOR.includes(t.id);
          return (
            <WithTooltip
              key={t.id}
              isDistributorRequired={isDistributorRequired}
            >
              <Checkbox
                key={t.id}
                label={t.name}
                name={t.id}
                onChange={this.handleChange(t.id, typeValues, remove, push)}
                value={t.id}
                checked={this.isChecked(t.id, typeValues)}
                disabled={
                  NOT_REMOVABLE_TYPES.includes(t.id.toLowerCase()) ||
                  (isDistributorRequired && !this.state.isDistributor)
                }
              />
            </WithTooltip>
          );
        })}
        <ValidationBadge error={meta.error} touched={meta.touched} />
      </div>
    );
  };

  render() {
    return (
      <Label text={trans.ORGANIZATION__TYPE_LABEL()} required={true}>
        <FieldArray name={this.props.fieldname} component={this.renderFields} />
      </Label>
    );
  }
}
