// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import queryString from "query-string";

export const isValidPageNumber = (
  lastPage: number,
  newPage: number
): boolean => {
  if (Number.isNaN(newPage) || !Number.isInteger(newPage)) {
    return false;
  }

  return newPage > 0 && newPage <= lastPage;
};

const isDifferentThanCurrent = (
  currentPage: number,
  newPage: number
): boolean => newPage !== currentPage;

export const shouldChangePage = (
  currentPage: number,
  lastPage: number,
  newPage: number
): boolean => {
  if (!isValidPageNumber(lastPage, newPage)) {
    return false;
  }

  return isDifferentThanCurrent(currentPage, newPage);
};

/**
 * Replaces 'page' parameter in location and converts it to a string.
 */
export const createPageUrl = (location: Location, page: string): string => {
  const { pathname, search } = location;

  const searchObj = queryString.parse(search);
  searchObj.page = page;

  return `${pathname}?${queryString.stringify(searchObj)}`;
};

/**
 * Returns offset in offset-limit pagination, given page and itemsPerPage.
 * Page is counted from 1, so when there are 10 items per page, the 2nd page
 * has offset 10.
 */
export const calulateOffsetFromPage = (
  page: number,
  itemsPerPage: number
): number => {
  const validPage = Math.max(page || 1, 1);
  return (validPage - 1) * itemsPerPage;
};

/**
 * Parse react-router's location object and calculate pagination limit and offset.
 * NOTE: REST API uses pagination with 'pages' instead of 'offset'. Do not use
 * this function with REST API!
 */
interface LimitOffset {
  offset: number;
  limit: number;
}
export const parseLocationToLimitOffset = (
  location: Location,
  itemsPerPage: number
): LimitOffset => {
  const { page } = queryString.parse(location.search);

  return {
    offset: calulateOffsetFromPage(Number(page), itemsPerPage),
    limit: itemsPerPage,
  };
};
