// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useParams, useLocation } from "react-router-dom";

import { AppRankData } from "./AppRank/AppRankData";

export const AppRankTab = () => {
  const { id } = useParams();
  const location = useLocation();

  return (
    <AppRankData
      applicationId={id}
      location={location} // needed cause react-router is special
    />
  );
};
