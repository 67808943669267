// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { GrabsonIcon } from "components/icons";
import { trans } from "src/translations";

import styles from "./ProcessingQueueItemRow.scss";

export const ITEMS_PER_PAGE = 50;

export const TABS_CONFIG = [
  {
    id: "processingQueueTable",
    dataTestId: "processing-queue-table",
    columns: [
      {
        id: "name",
        label: trans.NAME(),
        dataTestId: "name-column-header",
      },
      {
        id: "action_date",
        label: (
          <Fragment>
            {trans.DEVICES__PROCESSING_QUEUE__TABLE_ACTION_DATE()}
            <GrabsonIcon name="arrow-down" font="normal" />
          </Fragment>
        ),
        dataTestId: "action-date-column-header",
      },
      {
        id: "status",
        label: trans.DEVICES__PROCESSING_QUEUE__TABLE_STATUS(),
        dataTestId: "status-column-header",
      },
      {
        id: "actions",
        label: "",
      },
    ],
  },
];

export const ProcessingAttributesStatuses = {
  DONE: {
    id: "DONE",
    label: trans.DEVICES__PROCESSING_QUEUE__STATUS_DONE(),
    className: styles.statusDone,
  },
  PROCESSING: {
    id: "PROCESSING",
    label: trans.DEVICES__PROCESSING_QUEUE__STATUS_PROCESSING(),
  },
  ERROR: {
    id: "ERROR",
    label: trans.DEVICES__PROCESSING_QUEUE__STATUS_ERROR(),
    className: styles.statusError,
  },
};
