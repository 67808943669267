// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const getImagesWithType = (version, imageType) => {
  return version.images
    .filter(({ type }) => type === imageType)
    .map(({ image }) => image);
};
