// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { whenOneChange } from "utils/component";
import { CONTINENT_WORLD, getContinentNames } from "utils/continents";

import { CountriesList } from "./CountriesList";
import styles from "./GeoMap.scss";
import { GeoMapShape } from "./GeoMapShape";

export class GeoMap extends Component {
  static propTypes = {
    /** Unique field name. Used to create id attribute for HTML element */
    fieldName: PropTypes.string,
    /** Array of country codes that are selected */
    markedCountries: PropTypes.array,
    /** Array of country codes that are not selectable */
    disabledCountries: PropTypes.array,
    /**
     * onChange handler.
     * The 1st argument is list of country codes (e.g. ["PL", "GB"])
     * when selecting individual countries is allowed
     *
     * The 2nd argument is next boolean value describing if we want check
     * or uncheck provided elements.
     */
    onItemsSelected: PropTypes.func,
    allCountries: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(["country"]).isRequired,
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        continents: PropTypes.arrayOf(PropTypes.string).isRequired,
      })
    ).isRequired,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    disabledCountries: [],
    markedCountries: [],
    fieldName: "",
    dataTestId: "geomap",
  };

  state = {
    zoomLevel: CONTINENT_WORLD,
  };

  shouldComponentUpdate = whenOneChange(
    ["markedCountries", "disabledCountries", "allCountries"],
    ["zoomLevel"]
  );

  isCountryDisabled = (countryCode) => {
    const { disabledCountries, allCountries } = this.props;
    return (
      disabledCountries.some((code) => code === countryCode) ||
      !allCountries.some((c) => c.code === countryCode)
    );
  };

  isCountryMarked = (countryCode) => {
    const { markedCountries } = this.props;
    return markedCountries.some((code) => code === countryCode);
  };

  handleCountrySelected = (code) => {
    this.handleCountriesSelected([code], !this.isCountryMarked(code));
  };

  handleCountriesSelected = (countryCodes, value) => {
    const { onItemsSelected } = this.props;
    const selectedCountries = countryCodes.filter(
      (code) => !this.isCountryDisabled(code)
    );

    if (selectedCountries.length === 0) {
      return false;
    }

    return onItemsSelected(selectedCountries, value);
  };

  render() {
    const {
      fieldName,
      allCountries,
      markedCountries,
      disabledCountries,
      dataTestId,
    } = this.props;
    const { zoomLevel } = this.state;

    return (
      <div className={styles.geomapTopLevelContainer} data-test-id={dataTestId}>
        <div className={styles.listContainer}>
          <CountriesList
            continent={zoomLevel}
            countries={allCountries}
            isCountryDisabled={this.isCountryDisabled}
            isCountryChecked={this.isCountryMarked}
            onCountriesSelected={this.handleCountriesSelected}
            fieldName={fieldName}
          />
        </div>
        <div className={styles.mapContainer}>
          <ul className={styles.inlineTabs}>
            {getContinentNames().map((name) => (
              <li
                key={name}
                className={zoomLevel === name ? styles.active : ""}
                onClick={() => this.setState({ zoomLevel: name })}
              >
                {name}
              </li>
            ))}
          </ul>
          <GeoMapShape
            displayedContinent={zoomLevel}
            allCountries={allCountries}
            markedCountries={markedCountries}
            disabledCountries={disabledCountries}
            onCountrySelected={this.handleCountrySelected}
          />
        </div>
      </div>
    );
  }
}
