// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Subsection, Accordion, Modal } from "components/layout";
import { trans } from "src/translations";
import {
  getContinentNames,
  getCountriesForContinent,
  withCountries,
} from "utils/continents";

import styles from "./RegionsPreviewModal.scss";

@withCountries
export class RegionsPreviewModal extends Component {
  static propTypes = {
    countries: PropTypes.object,
    allCountries: PropTypes.array,
    isOpen: PropTypes.bool,
    onClose: PropTypes.bool,
  };

  static defaultProps = {
    allCountries: [],
    countries: [],
  };

  getHeaderText(region, howMany, from) {
    let header = "";

    if (howMany === from) {
      header = `${region} (${trans.ALL()})`;
    } else {
      header = `${region} (${howMany}/${from})`;
    }

    return header;
  }

  renderCountryList(selectedCountries) {
    return (
      <ul className={styles.countryList}>
        {selectedCountries.map((country) => (
          <li key={country.name} className={styles.item}>
            {country.name}
          </li>
        ))}
      </ul>
    );
  }
  renderSubsections = () => {
    const { allCountries, countries } = this.props;
    return getContinentNames().map((region) => {
      const allRegionCountries = getCountriesForContinent(allCountries, region);
      const selectedCountries = allRegionCountries.filter((country) =>
        countries.includes(country.code)
      );

      return (
        <Subsection key={region}>
          <Accordion
            header={this.getHeaderText(
              region,
              selectedCountries.length,
              allRegionCountries.length
            )}
            disabled={!selectedCountries.length}
          >
            {this.renderCountryList(selectedCountries)}
          </Accordion>
        </Subsection>
      );
    });
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Modal
        title={trans.TARGETS__REGIONS_MODAL_TITLE_READONLY()}
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.CANCEL()}
            </Button>
          </ButtonsWrapper>
        }
      >
        {this.renderSubsections()}
      </Modal>
    );
  }
}
