// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import { Button, FileButton } from "components/buttons";
import { Label, ValidationBadge } from "components/form";
import { GrabsonIcon } from "components/icons";
import { Column, Row } from "components/layout";
import { Link } from "components/typography";
import { trans } from "src/translations";
import { initialFile } from "utils/formik/utils";
import { isFormatSupported } from "utils/image";
import { createFieldValidator, isRequired } from "utils/validation";

import { STATES } from "./constants";
import { Progressbar } from "./Progressbar";
import styles from "./uploader.scss";

// `<Uploader>` component allows to upload file inside form. It has build in configurable validation and preprocessing.

export class Uploader extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string, // Uploader name displayed as a header
    info: PropTypes.string, // Additional information displayed in header's label
    formats: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of MIME types, e.g. ["image/jpeg"]
    kind: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    validate: PropTypes.func,
    preprocess: PropTypes.func,
    onRemove: PropTypes.func,
    onClick: PropTypes.func,
    onSuccess: PropTypes.func,
    dataTestId: PropTypes.string,
    tooltip: PropTypes.string,
    disablePreview: PropTypes.bool,
  };
  state = {
    error: undefined,
  };

  static defaultProps = {
    required: true,
    dataTestId: "uploader",
    disablePreview: false,
  };

  fieldName = `${this.props.name}`;
  urlFieldName = `${this.props.name}.url`;

  initialImgFile = initialFile(
    "",
    this.props.kind,
    this.props.kind === "screenshot" ? "add-new-screenshot" : 0
  );

  uploadFile = (setFieldValue, setFieldTouched) => async (ev) => {
    const { validate, preprocess, formats, onSuccess } = this.props;
    const [file] = ev.target.files;
    let blobUrl = "";

    if (!file) {
      return;
    }
    this.setState({ error: undefined });
    setFieldValue(this.fieldName, {
      ...this.initialImgFile,
      status: { ...STATES.progress },
    });

    try {
      if (!isFormatSupported(file, formats)) {
        throw trans.INVALID_IMAGE_FORMAT({
          formats: formats.join(", ").replace(/image\//gi, ""),
        });
      }

      const type = file.type;
      blobUrl = URL.createObjectURL(file);

      if (validate) {
        await validate(blobUrl);
      }
      if (preprocess) {
        blobUrl = await preprocess(blobUrl, type);
      }
    } catch (err) {
      setFieldValue(this.fieldName, {
        ...this.initialImgFile,
      });
      this.setState({ error: err });
      setFieldTouched(this.urlFieldName);
      return;
    }
    setFieldValue(this.fieldName, {
      ...this.initialImgFile,
      url: blobUrl,
      loaded: 100,
      status: { ...STATES.uploaded },
    });
    setFieldTouched(this.urlFieldName);

    if (onSuccess) {
      onSuccess();
    }
  };

  removeFile = (url, setFieldValue) => {
    const { onRemove, name } = this.props;

    URL.revokeObjectURL(url);
    setFieldValue(name, this.initialImgFile);

    if (onRemove) {
      onRemove();
    }
  };

  progress({ url, status, loaded }, setFieldValue) {
    const { disabled, onClick, dataTestId } = this.props;

    const progressClasses = [];
    progressClasses.push(styles.progress);
    if (status?.progress) {
      progressClasses.push(styles.inactive);
    }

    const icon = onClick ? (
      <Button
        className={styles.leftButton}
        onClick={onClick}
        dataTestId={`${dataTestId}-preview-button`}
      >
        {trans.SHOW_PREVIEW()}
      </Button>
    ) : (
      <div className={styles.left} data-test-id={`${dataTestId}-preview`}>
        <GrabsonIcon name="image" size="lg" />
      </div>
    );

    return (
      <div className={progressClasses.join(" ")}>
        {icon}
        <div className={styles.right}>
          <button
            type="button"
            className={styles.removeButton}
            disabled={status?.removeButtonDisabled || disabled}
            onClick={() => {
              this.removeFile(url, setFieldValue);
            }}
            data-test-id={`${dataTestId}-remove-button`}
          >
            <GrabsonIcon name="close" size="sm" />
          </button>
          <p>{trans.IMAGE_UPLOADED()}</p>
          <Progressbar loaded={loaded} total={100} />
        </div>
      </div>
    );
  }

  fileButton(setFieldValue, setFieldTouched) {
    const { formats, disabled, dataTestId, name } = this.props;
    return (
      <FileButton
        formats={formats}
        onChange={this.uploadFile(setFieldValue, setFieldTouched)}
        disabled={disabled}
        dataTestId={`${dataTestId}-file-button`}
        name={name}
      />
    );
  }

  validate = createFieldValidator(this.urlFieldName, isRequired);

  render() {
    const {
      label,
      required,
      info,
      className,
      dataTestId,
      tooltip,
      disablePreview,
    } = this.props;

    return (
      <>
        <Field name={this.urlFieldName} validate={required && this.validate}>
          {({
            field: { value: url },
            meta: { touched, error: validationError },
            form: { getFieldProps, setFieldValue, setFieldTouched },
          }) => {
            const imgObj = getFieldProps(this.props.name)?.value;

            return (
              <Row
                className={className || styles.component}
                dataTestId={dataTestId}
              >
                <Column>
                  {label ? (
                    <Label text={label} required={required} tooltip={tooltip} />
                  ) : null}
                  <div className={styles.row}>
                    {imgObj.status?.progressBar &&
                      this.progress(
                        { url, status: imgObj.status, loaded: imgObj.loaded },
                        setFieldValue
                      )}
                    {imgObj.status?.addButton &&
                      this.fileButton(setFieldValue, setFieldTouched)}
                    {info && <p className={styles.info}>{info}</p>}
                    <ValidationBadge
                      error={this.state.error || validationError}
                      touched={touched}
                    />
                  </div>
                </Column>
                {url && !disablePreview && (
                  <Column className={styles.imgPreviewColumn}>
                    <Link
                      href={url}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img src={url} className={styles.imgPreview} />
                    </Link>
                  </Column>
                )}
              </Row>
            );
          }}
        </Field>
      </>
    );
  }
}
