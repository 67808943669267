// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import queryString from "query-string";

import { apiThatThrows } from "containers/Request";
import { isMonetized } from "pages/Applications/AppDetails/AppVersions/_utils/monetization";
import { VERSION_TABS } from "pages/Applications/AppDetails/AppVersions/constants";
import { ORGANIZATION_PLAN } from "pages/Organization/constants";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators/withRouter";

import {
  QA_STATUS_REJECTED,
  DEVICE_ACCEPTED,
  DEVICE_REJECTED,
  DEVICE_PENDING,
  APP_ENVIRONMENT_STATUS,
} from "../constants";
import { SectionTargets } from "./SectionTargets";

@withRouter
@connect(null, (dispatch) => ({
  getDeviceOwnersPaginated: (queryParams) =>
    dispatch(
      apiThatThrows.getDeviceOwnersPaginated.action({
        queryParams: {
          ...queryParams,
          include: ["device_families", "device_models"],
          limit: "nolimit",
        },
      })
    ),
  getOrganization: (organizationId) => {
    return dispatch(
      apiThatThrows.getOrganizationPublicInfo.action({
        params: { organizationId },
      })
    );
  },
  getOrganizations: (ids) =>
    dispatch(
      apiThatThrows.getOrganizationsPaginated.action({
        queryParams: { id: ids, limit: ids.length, page: 1 },
      })
    ),
  getModerationResult: (id) =>
    dispatch(
      apiThatThrows.getModerationResult.action({
        params: { id },
        queryParams: { include: "moderation_targets" },
      })
    ),
  getSalesforceAgreements: (qaSummariesId) =>
    dispatch(
      apiThatThrows.getSalesforceAgreementsPaginated.action({
        queryParams: {
          qa_summary_id: qaSummariesId,
          limit: "nolimit",
        },
      })
    ),
}))
export class SectionTargetsData extends Component {
  static propTypes = {
    qaSummaries: PropTypes.object,
    application: PropTypes.object.isRequired,
    refreshQASummaries: PropTypes.func.isRequired,
    refreshVersionsData: PropTypes.func.isRequired,
    versionsLoading: PropTypes.bool.isRequired,
    versionsError: PropTypes.string,
    selectedAppVersion: PropTypes.object,
    selectedAppVersionKey: PropTypes.string,

    // from @withRouter
    location: PropTypes.object,

    // from @connect
    getDeviceOwnersPaginated: PropTypes.func,
    getOrganization: PropTypes.func.isRequired,
    getOrganizations: PropTypes.func,
    getModerationResult: PropTypes.func.isRequired,
    getSalesforceAgreements: PropTypes.func.isRequired,
  };

  state = {
    hasRevenueNonPremium: false,
    results: [],
    count: 0,
    error: undefined,
    loading: false,
    organizationsWithAgreements: [],
  };

  async componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      qaSummaries,
      versionsLoading,
      refreshVersionsData,
      selectedAppVersion,
    } = this.props;

    const versionsLoadingFinished =
      prevProps.versionsLoading && !versionsLoading;
    const reloadData =
      versionsLoadingFinished || prevProps.location !== location;

    if (prevProps.qaSummaries !== qaSummaries) {
      refreshVersionsData();
    } else if (reloadData && Boolean(selectedAppVersion)) {
      this.refreshData();
    }
  }

  isActiveAppVersionSelected = () => {
    const { selectedAppVersionKey } = this.props;
    return selectedAppVersionKey === VERSION_TABS.active;
  };

  createDeviceModelData = (target, model) => ({
    name: model.name,
    id: Number(model.id),
    moderationStatus: target.internal_status || DEVICE_PENDING,
    distributorStatus: "todo", // todo: YGG-1778 Some data are missing in the application details
    isOnHold: target.is_on_hold || false,
    isTesting: model.is_testing,
    isDisabled: !model.is_enabled,
  });

  getDeviceCountPerStatus(devices) {
    let accepted = 0;
    let rejected = 0;
    let pending = 0;

    devices.forEach((device) => {
      switch (device.moderationStatus) {
        case DEVICE_ACCEPTED:
          ++accepted;
          break;
        case DEVICE_REJECTED:
          ++rejected;
          break;
        default:
          ++pending;
          break;
      }
    });

    return {
      acceptedDevicesCount: accepted,
      rejectedDevicesCount: rejected,
      pendingDevicesCount: pending,
    };
  }

  createDeviceFamilyData = (owner, moderationTargets, family) => {
    const isModelFromFamily = (model) => model.device_family.id === family.id;
    const devices = owner.device_models
      .filter(isModelFromFamily)
      .map((model) => {
        const target = moderationTargets.find(
          (t) => Number(model.id) === Number(t.device_model_id)
        );
        return this.createDeviceModelData(target || {}, model);
      });

    return {
      name: family.name,
      id: Number(family.id),
      devices,
      onHoldDevicesCount: devices.filter((d) => d.isOnHold).length,
      ...this.getDeviceCountPerStatus(devices),
    };
  };

  createOwnerData = (name, owner, moderationTargets) => {
    const families = owner.device_families.map((family) => {
      return this.createDeviceFamilyData(owner, moderationTargets, family);
    });

    const sumFamilyProperties = (key) =>
      families.reduce((acc, f) => acc + f[key], 0);

    return {
      name,
      id: owner.id,
      families,
      acceptedDevicesCount: sumFamilyProperties("acceptedDevicesCount"),
      rejectedDevicesCount: sumFamilyProperties("rejectedDevicesCount"),
      pendingDevicesCount: sumFamilyProperties("pendingDevicesCount"),
      onHoldDevicesCount: sumFamilyProperties("onHoldDevicesCount"),
    };
  };

  refreshData = async () => {
    const { versionsError, selectedAppVersion } = this.props;

    try {
      const moderationResultToUse = this.getModeration();
      if (!moderationResultToUse) {
        return;
      }
      if (moderationResultToUse.qa_status === QA_STATUS_REJECTED) {
        throw {
          message: trans.APP_DETAILS_MODERATION_TARGETS__REJECTED_WARN(),
        };
      }

      this.setState({ loading: true });

      if (!selectedAppVersion) {
        throw {
          message:
            versionsError ||
            trans.APP_DETAILS_MODERATION_TARGETS__NO_PERMISSIONS(),
        };
      }

      const hasRevenueNonPremium =
        await this.hasRevenueNonPremium(selectedAppVersion);
      const moderationTargets = await this.getModerationTargets();
      const organizationsWithAgreements =
        await this.getOrganizationsWithSalesforceAgreement();

      const deviceOwners = await this.requestDeviceOwners(
        selectedAppVersion,
        moderationTargets,
        hasRevenueNonPremium
      );

      this.setState({
        hasRevenueNonPremium,
        results: deviceOwners.results,
        organizationsWithAgreements,
        count: deviceOwners.meta.count,
        error: undefined,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        results: [],
        loading: false,
      });
    }
  };

  async hasRevenueNonPremium(selectedAppVersion) {
    const { getOrganization, application } = this.props;
    const { results } = await getOrganization(application.owner_public_id);
    const isPremium = results?.organization_plan === ORGANIZATION_PLAN.Premium;
    return isMonetized(selectedAppVersion) && !isPremium;
  }

  async getModerationTargets() {
    const { qaSummaries, getModerationResult } = this.props;

    const keyByVersion = this.isActiveAppVersionSelected()
      ? "active_moderation_result"
      : "latest_moderation_result";
    const moderationResultId = qaSummaries?.[`${keyByVersion}`].id;
    const { results } = await getModerationResult(moderationResultId);
    return results?.moderation_targets ?? [];
  }

  async requestDeviceOwners(
    versionData,
    moderationTargets,
    hasRevenueNonPremium
  ) {
    const { getDeviceOwnersPaginated, location } = this.props;

    /*
      For applications with status certification only,
      we need to narrow number of devices on which app can be verified only to is_testing.
      Same for non premium organization and app that has revenue.
    */
    const { results: ownersResults, meta } = await getDeviceOwnersPaginated({
      ...queryString.parse(location.search),
      is_testing: versionData.is_distributable || hasRevenueNonPremium,
    });

    const { results: orgResults } = await this.getOrganizations(
      (ownersResults || []).map((o) => o.id)
    );

    const ownersData = ownersResults.map((owner) => {
      const asOrganization = orgResults.find((o) => o.id === owner.id);
      return this.createOwnerData(
        asOrganization?.name ?? "",
        owner,
        moderationTargets
      );
    });

    return {
      meta: meta,
      results: ownersData,
    };
  }

  async getOrganizations(orgIds) {
    const { getOrganizations } = this.props;

    if (!orgIds || orgIds.length === 0) {
      return { result: [] };
    }
    return await getOrganizations(orgIds);
  }

  getModeration() {
    const { qaSummaries } = this.props;
    if (!qaSummaries) {
      return null;
    }

    return this.isActiveAppVersionSelected()
      ? qaSummaries.active_moderation_result
      : qaSummaries.latest_moderation_result;
  }

  getOrganizationsWithSalesforceAgreement = async () => {
    const { getSalesforceAgreements, qaSummaries } = this.props;
    const agreements = await getSalesforceAgreements(qaSummaries.id);
    return agreements.results.map((agreement) => agreement.organization_id);
  };

  hasAnyEnvironmentApproved = () => {
    const environments = this.props.selectedAppVersion.environments || {};
    return Object.values(environments).some(
      (environment) => environment.status === APP_ENVIRONMENT_STATUS.APPROVED
    );
  };

  render() {
    const { selectedAppVersion, refreshQASummaries, application } = this.props;

    const {
      results,
      count,
      error,
      loading,
      hasRevenueNonPremium,
      organizationsWithAgreements,
    } = this.state;

    const moderation = this.getModeration() || {};

    return (
      <SectionTargets
        results={results}
        count={count}
        error={error}
        loading={loading}
        moderationId={moderation.id}
        hasOnHoldTargets={moderation.has_on_hold_targets}
        showRevenueWarning={hasRevenueNonPremium}
        refreshQASummaries={refreshQASummaries}
        application={application}
        organizationsWithAgreements={organizationsWithAgreements}
        hasAnyEnvironmentApproved={this.hasAnyEnvironmentApproved()}
        isDistributable={selectedAppVersion.is_distributable}
      />
    );
  }
}
