// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Checkbox } from "components/form/elements";
import { GrabsonIcon } from "components/icons";

import styles from "./DeviceFamilyRow.scss";
import { DeviceModelRow } from "./DeviceModelRow";

export class DeviceFamilyRow extends Component {
  static propTypes = {
    family: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      models: PropTypes.array.isRequired,
    }).isRequired,
    isModelChecked: PropTypes.func.isRequired,
    onModelChecked: PropTypes.func.isRequired,
  };

  state = {
    expanded: false,
  };

  handleExpandRow = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  isChecked() {
    const { family, isModelChecked } = this.props;
    return family.models.some((m) => isModelChecked(m.id));
  }

  render() {
    const {
      family: { name, models },
      onModelChecked,
      isModelChecked,
    } = this.props;
    const { expanded } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.row}>
          <Checkbox
            onChange={Function.prototype}
            checked={this.isChecked()}
            className={styles.checkbox}
            value={name}
            disabled
          />
          <span
            onClick={this.handleExpandRow}
            className={styles.expandContainer}
          >
            <GrabsonIcon
              name={expanded ? "arrow-up" : "arrow-down"}
              font="micro"
              className={styles.arrowIcon}
            />
            {name}
          </span>
        </div>
        {expanded &&
          models.map((model) => (
            <DeviceModelRow
              key={model.id}
              model={model}
              isModelChecked={isModelChecked}
              onModelChecked={onModelChecked}
            />
          ))}
      </div>
    );
  }
}
