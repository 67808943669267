import { PageContent, PageHeader } from "components/layout";
import { trans } from "src/translations";

import { CreateCommandData } from "./CreateCommandData";

export const CreateCommand = () => {
  return (
    <>
      <PageHeader>{trans.DEVICES__COMMANDS_CREATE_HEADER()}</PageHeader>
      <PageContent>
        <CreateCommandData />
      </PageContent>
    </>
  );
};
