// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { EXPECTED_AWS_S3_RESPONSE_CONTENT_TYPE } from "../constants";

const LOCATION_TAG_OPEN = "<location>";
const LOCATION_TAG_CLOSE = "</location>";

/**
 * The response is an XML document, and we could try to parse it as one.
 * Unfortunately, in the past I had mixed result when parsing AWS responses.
 * We will just extract raw substring instead.
 */
const getLocationTagValue = (xmlText) => {
  const xmlTextLowerCase = xmlText.toLowerCase();
  const tagOpenIdx = xmlTextLowerCase.indexOf(LOCATION_TAG_OPEN);
  const tagCloseIdx = xmlTextLowerCase.indexOf(LOCATION_TAG_CLOSE);

  if (tagOpenIdx === -1 || tagCloseIdx === -1) {
    throw new Error(
      `Invalid AWS S3 response, should contain ${LOCATION_TAG_OPEN} tag`
    );
  }

  const encodedFileUrl = xmlText.substring(
    tagOpenIdx + LOCATION_TAG_OPEN.length,
    tagCloseIdx
  );

  // filename in url is recognized as a part of query string and it replaces spaces with + signs rather than %20
  // and it is not recognizable for AWS anymore, so we change it back
  return encodedFileUrl.replace(/\+/g, " ");
};

export const parseAWSResponse = async (uploadResponse) => {
  const responseType = uploadResponse.contentType;
  if (responseType !== EXPECTED_AWS_S3_RESPONSE_CONTENT_TYPE) {
    const errMsg = `Invalid response content-type. Expected '${EXPECTED_AWS_S3_RESPONSE_CONTENT_TYPE}', got '${responseType}'`;
    throw new Error(errMsg);
  }

  return getLocationTagValue(uploadResponse.text || "");
};
