// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { NumberField, SelectField } from "components/form/fields";
import { GrabsonIcon } from "components/icons";
import { Column, Row } from "components/layout";
import { trans } from "src/translations";

import { getKeyValues } from "../../constants";
import styles from "./ManifestEdit.scss";
import { ManifestEditFieldArray } from "./ManifestEditFieldArray";

const placeholderOptionValue = {
  label: trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_KEY_PLACEHOLDER(),
  value: "",
  disabled: true,
  selected: true,
};

const renderFieldRow = ({ fieldName, action, isPlaceholder = false }) => (
  <Row key={fieldName}>
    <Column className={styles.arrayCol}>
      <SelectField
        values={
          isPlaceholder
            ? [placeholderOptionValue]
            : getKeyValues(`${fieldName}.key`)
        }
        look="simple"
        name={isPlaceholder ? "_disabled_value_" : `${fieldName}.key`}
        disabled={isPlaceholder}
        required={!isPlaceholder}
      />
      <Button className={styles.actionButton} type="plain" onClick={action}>
        <GrabsonIcon
          name={isPlaceholder ? "plus-filled" : "close-filled"}
          font="micro"
          size="xl"
          className={
            isPlaceholder ? styles.addFieldButton : styles.removeFieldButton
          }
        />
      </Button>
    </Column>
    <Column className={styles.arrayCol}>
      <NumberField
        look="simple"
        placeholder={trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING_VALUE_PLACEHOLDER()}
        name={isPlaceholder ? "_disabled_value_" : `${fieldName}.value`}
        disabled={isPlaceholder}
        required={!isPlaceholder}
      />
    </Column>
  </Row>
);

export const ManifestEditKeysField = ({ name }) => (
  <ManifestEditFieldArray
    name={name}
    label={name}
    descriptionFunc={trans.APP_DETAILS_MANIFEST__FIELD_REMAPPING}
    renderFieldRow={renderFieldRow}
    newItemValue={{
      key: "",
      value: 0,
    }}
  />
);

ManifestEditKeysField.propTypes = {
  name: PropTypes.string.isRequired,
};
