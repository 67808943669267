// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Tooltip } from "components/popups";
import { popupAlignmentType } from "components/popups/Popup/types";

import styles from "./label.scss";

export class Label extends Component {
  static propTypes = {
    children: PropTypes.node,
    htmlFor: PropTypes.string,
    text: PropTypes.node,
    info: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.string,
    tooltip: PropTypes.node,
    dataTestId: PropTypes.string,
    tooltipAlignment: popupAlignmentType,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    text: "",
    required: false,
    className: "",
    tooltip: "",
    info: "",
    dataTestId: "label",
    disabled: false,
  };

  render() {
    const {
      children,
      htmlFor,
      text,
      required,
      className,
      tooltip,
      info,
      dataTestId,
      tooltipAlignment,
      disabled,
    } = this.props;

    return (
      <div
        className={`${styles.wrapper} ${className}`}
        data-test-id={dataTestId}
      >
        {text && (
          <label className={styles.label} htmlFor={htmlFor}>
            <Tooltip
              content={tooltip}
              alignment={tooltipAlignment}
              className={styles.tooltip}
              disabled={disabled}
            >
              <div className={styles.labelContent}>
                <span data-test-id={`${dataTestId}-text`}>{text}</span>
                {required ? (
                  <abbr
                    title="required"
                    data-test-id={`${dataTestId}-required`}
                    className={styles.asterisks}
                  >
                    *
                  </abbr>
                ) : null}
                {tooltip ? (
                  <span
                    className={styles.tooltipIcon}
                    data-test-id={`${dataTestId}-tooltip`}
                  >
                    (?)
                  </span>
                ) : null}
                {info ? (
                  <div
                    className={styles.info}
                    data-test-id={`${dataTestId}-info`}
                  >
                    {info}
                  </div>
                ) : null}
              </div>
            </Tooltip>
          </label>
        )}
        {children}
      </div>
    );
  }
}
