// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
// this has to be first import!
import { render as reactRender } from "react-dom";
import { setConfig } from "react-hot-loader";

import App from "components/App";
import { authorize } from "containers/Auth/actions";
import "styles/main.scss";

import { configureStore } from "./configureStore";
import { ConsoleErrorReporter } from "./ConsoleErrorReporter";

if (process.env.NODE_ENV === "development" && process.env.MOCK === "enabled") {
  const { worker } = require("./mocks/browser");
  worker.start({ onUnhandledRequest: "bypass" });
}

window.ga("create", "UA-103803869-9", "vewd.com");

// Prevents droping files on top of this webiste
window.addEventListener(
  "dragover",
  (e) => {
    e.preventDefault();
  },
  false
);

window.addEventListener(
  "drop",
  (e) => {
    e.preventDefault();
  },
  false
);

console.info("COMMIT BRANCH", process.env.__BRANCH);
console.info("VERSION", process.env.__VERSION);
console.info("COMMIT HASH", process.env.__COMMITHASH);

const mainDiv = document.createElement("div");
document.body.appendChild(mainDiv);
document.getElementById("pre-react-banner").remove();

export const config = configureStore();

config.store.dispatch(authorize());

const render = (Component) => {
  reactRender(<Component {...config} />, mainDiv);
};

// set react-hot-loader config
setConfig({
  errorReporter: ConsoleErrorReporter,
  ErrorOverlay: ConsoleErrorReporter,
});

render(App);
