// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import isEqual from "lodash-es/isEqual";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea, ROLES } from "containers/Permissions";
import { apiThatThrows } from "containers/Request";
import { DefaultSortContext } from "pages/_shared/Context/defaultSortContext";
import { withQueryParams } from "utils/decorators";
import { adaptSortingQueryParams, retrieveFiltersFromInclude } from "utils/url";

import { parseApplicationsListFilters } from "../utils";
import { DistributionAppListLogic } from "./DistributionAppListLogic";

@restrictedArea(() => ({
  allowed: [ROLES.distributor.distributionPlanner],
}))
@withQueryParams
@withActiveOrganization
@connect(null, (dispatch) => ({
  getMyDistributionApplicationsPaginated: async (queryParams) => {
    return await dispatch(
      apiThatThrows.getMyDistributionApplicationsPaginated.action({
        queryParams,
      })
    );
  },
  getAppStatusesForDefaultPlan: async (appIds) => {
    const { results } = await dispatch(
      apiThatThrows.getAppStatusesForDefaultPlanPaginated.action({
        queryParams: { id: appIds, limit: appIds.length, page: 1 },
      })
    );

    return results;
  },
  getApplicationCategories: async () => {
    const { results } = await dispatch(
      apiThatThrows.getApplicationCategoriesPaginated.action({
        cache: true,
        queryParams: { limit: "nolimit" },
      })
    );

    return results;
  },
  getAvailableFilters: async () => {
    const { results } = await dispatch(
      apiThatThrows.getMyDistributionApplicationsFilters.action({
        cache: true,
      })
    );

    return results;
  },
  getSearchSuggestions: (queryParams, abortSignal) =>
    dispatch(
      apiThatThrows.getMyDistributionApplicationsSuggestions.action({
        queryParams,
        abortSignal,
      })
    ),
}))
export class DistributionAppListData extends Component {
  static propTypes = {
    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,

    // from @withQueryParams
    queryParams: PropTypes.object.isRequired,

    // from @connect
    getMyDistributionApplicationsPaginated: PropTypes.func.isRequired,
    getAppStatusesForDefaultPlan: PropTypes.func.isRequired,
    getApplicationCategories: PropTypes.func.isRequired,
    getAvailableFilters: PropTypes.func.isRequired,
    getSearchSuggestions: PropTypes.func.isRequired,
  };

  state = {
    applications: [],
    count: 0,
    errorOnUpdatingApplications: null,
    errorOnUpdatingAvailableFilters: null,
    loading: 0,
    availableFilters: { sections: [] },
    defaultOrder: {},
  };

  componentDidMount() {
    this.updateAvailableFilters();
    this.refreshData();
  }

  componentDidUpdate(prevProps) {
    const { queryParams, activeOrganization } = this.props;
    const queryParamsChanged = !isEqual(prevProps.queryParams, queryParams);
    const activeOrganizationChanged = !(
      prevProps.activeOrganization.id === activeOrganization.id
    );

    if (queryParamsChanged || activeOrganizationChanged) {
      this.refreshData();
    }
  }

  refreshData = () => {
    this.updateApplications();
  };

  getStatusesFromAppDistribution = async (applicationsResults) => {
    const { getAppStatusesForDefaultPlan } = this.props;

    const statusesByAppId = {};

    const appIds = applicationsResults.map((a) => a.id);
    const statuses = await getAppStatusesForDefaultPlan(appIds);

    statuses.forEach((s) => {
      statusesByAppId[s.id] = s.status;
    });

    return statusesByAppId;
  };

  getStatusesFromSearchEngine = (applicationsResults) => {
    const { activeOrganization } = this.props;

    const statusesByAppId = {};

    applicationsResults.forEach((ar) => {
      const extrasForCurrentDistributor = ar.distributors_extras.find(
        (d) => d.distributor_id === activeOrganization.id
      );

      statusesByAppId[ar.id] = extrasForCurrentDistributor.status;
    });

    return statusesByAppId;
  };

  updateApplications = async () => {
    const { getMyDistributionApplicationsPaginated, queryParams } = this.props;

    try {
      this.setState((prevState) => ({
        loading: prevState.loading + 1,
        errorOnUpdatingApplications: null,
      }));

      const categories = await this.getCategories();
      const adaptedQueryParams = retrieveFiltersFromInclude(
        adaptSortingQueryParams(queryParams)
      );
      const { results: applicationsResults, meta: applicationsMeta } =
        await getMyDistributionApplicationsPaginated(adaptedQueryParams);

      // Two sources of true needed because of some delay in search-engine service.
      // Sometimes frontend fetches data from search-engine before search-engine finish processing 'status' event from app-distribution.
      // In such a situation, frontend sets "defaultPlanStatusInconsistent" parameter to "true" and shows a warning.
      // Data from:
      // - search-engine - are needed to search, paginate, filter
      // - app-distribution - are needed to show "approved for default plan" status
      const applications = [];
      if (applicationsMeta.count > 0) {
        const statusesFromAppDistribution =
          await this.getStatusesFromAppDistribution(applicationsResults);

        const statusesFromSearchEngine =
          this.getStatusesFromSearchEngine(applicationsResults);

        applicationsResults.forEach((a) => {
          applications.push({
            id: a.id,
            icon: a.icon,
            name: a.name,
            category: get(categories, a.category, a.category),
            isOnHold: get(a, "distributors_extras[0].is_on_hold", false),
            isRejected: get(a, "distributors_extras[0].is_rejected", false),
            defaultPlanStatus: statusesFromAppDistribution[a.id] || "",
            defaultPlanStatusInconsistent:
              statusesFromAppDistribution[a.id] !==
              statusesFromSearchEngine[a.id],
          });
        });
      }
      this.setState({
        applications,
        count: applicationsMeta.count,
        defaultOrder: {
          sortBy: applicationsMeta.accepted_params.order,
          sortOrder: "ASC",
        },
      });
    } catch (err) {
      this.setState({
        errorOnUpdatingApplications: err,
      });
    } finally {
      this.setState((prevState) => ({
        loading: prevState.loading - 1,
      }));
    }
  };

  updateAvailableFilters = async () => {
    const { getAvailableFilters } = this.props;

    try {
      this.setState((prevState) => ({
        loading: prevState.loading + 1,
      }));

      const availableFilters = await getAvailableFilters();

      this.setState({
        errorOnUpdatingAvailableFilters: null,
        availableFilters: parseApplicationsListFilters(availableFilters),
      });
    } catch (err) {
      this.setState({ errorOnUpdatingAvailableFilters: err });
    } finally {
      this.setState((prevState) => ({
        loading: prevState.loading - 1,
      }));
    }
  };

  async getCategories() {
    const { getApplicationCategories } = this.props;
    const categories = await getApplicationCategories();

    return categories.reduce((acc, c) => {
      acc[c.slug] = c.human_name;
      return acc;
    }, {});
  }

  render() {
    const { getSearchSuggestions } = this.props;
    const {
      applications,
      count,
      errorOnUpdatingApplications,
      errorOnUpdatingAvailableFilters,
      loading,
      availableFilters,
      defaultOrder,
    } = this.state;

    return (
      <DefaultSortContext.Provider value={defaultOrder}>
        <DistributionAppListLogic
          applications={applications}
          count={count}
          error={errorOnUpdatingApplications || errorOnUpdatingAvailableFilters}
          loading={loading > 0}
          refreshData={this.refreshData}
          availableFilters={availableFilters}
          getSearchSuggestions={getSearchSuggestions}
        />
      </DefaultSortContext.Provider>
    );
  }
}
