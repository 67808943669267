// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Details, PageContentError } from "components/layout";
import { withActiveOrganization } from "containers/Auth/decorators";
import { apiThatThrows } from "containers/Request";

import { getUserRoles, getUserDetailsData, withUserViewEdit } from "./_utils";

@withActiveOrganization
@withUserViewEdit
@connect(null, (dispatch) => ({
  getRoles: () => dispatch(apiThatThrows.getRoles.action()),
}))
export class UserDetails extends Component {
  static propTypes = {
    // from @connect
    getRoles: PropTypes.func.isRequired,

    // from @ExtendedRoute
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),

    // from @withUserViewEdit
    getUserProfile: PropTypes.func.isRequired,
    getUserGrants: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    error: "",
    data: null,
  };

  componentDidMount() {
    this.updateInitialValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.updateInitialValues();
    }
  }

  getAllRoles = async () => {
    const { getRoles } = this.props;
    const { results } = await getRoles();
    return results;
  };

  updateInitialValues = async () => {
    const { getUserProfile, activeOrganization, getUserGrants } = this.props;

    this.setState({ loading: true });

    const { error, result } = await getUserProfile(this.getUserId());

    if (error) {
      this.setState({
        loading: false,
        error,
      });
      return;
    }

    let allRoles;
    try {
      allRoles = await this.getAllRoles();
    } catch (err) {
      this.setState({ error: err, loading: false });
      return;
    }

    const { error: userGrantsError, result: userGrantsResult } =
      await getUserGrants(this.getUserId());

    if (userGrantsError) {
      this.setState({
        loading: false,
        error: userGrantsError,
      });
      return;
    }

    this.setState({
      loading: false,
      error: "",
      data: getUserDetailsData(
        result.results,
        activeOrganization,
        getUserRoles(userGrantsResult.results, allRoles)
      ),
    });
  };

  getUserId() {
    return this.props.params.id;
  }

  render() {
    const { loading, error, data } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <PageContentError error={error} />;
    }

    return <Details data={data} />;
  }
}
