// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { SearchableListPanel } from "components/elements";
import { Status } from "components/feedback";
import { Checkbox } from "components/form/elements";
import { trans } from "src/translations";
import { classes } from "utils/classes";

import {
  DEVICE_TYPE_MODEL,
  DEVICE_TYPE_FAMILY,
  DEVICE_TYPE_DISTRIBUTOR,
} from "../constants";
import styles from "./DevicesRow.scss";

export class DevicesRow extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      type: PropTypes.oneOf([
        DEVICE_TYPE_MODEL,
        DEVICE_TYPE_FAMILY,
        DEVICE_TYPE_DISTRIBUTOR,
      ]).isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isTesting: PropTypes.bool,
      isDisabled: PropTypes.bool,
      organizationName: PropTypes.string,
    }).isRequired,
    isChecked: PropTypes.bool,
    onChecked: PropTypes.func,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    dataTestId: "devices-row",
  };

  getLabelClasses() {
    const { isChecked } = this.props;

    return classes(styles.label, { [styles.listItemChecked]: isChecked });
  }

  getName() {
    const { item } = this.props;
    return (
      <>
        {item.name} {item.isTesting && <Status>{trans.TEST_DEVICE()}</Status>}
        {item.isDisabled && <Status>{trans.DISABLED()}</Status>}
      </>
    );
  }

  handleChange = () => {
    const { onChecked, item, isChecked } = this.props;
    onChecked(item, !isChecked);
  };

  render() {
    const { item, isChecked, dataTestId } = this.props;

    return (
      <SearchableListPanel dataTestId={dataTestId}>
        <label className={this.getLabelClasses()}>
          <Checkbox
            checked={isChecked}
            onChange={this.handleChange}
            value={item.id}
            dataTestId={`${dataTestId}-checkbox`}
          />
          <div
            className={styles.targetName}
            data-test-id={`${dataTestId}-name`}
          >
            <div className={styles.row}>
              <div className={styles.column}>{this.getName()}</div>
              {item.organizationName && (
                <div className={styles.column}>{item.organizationName}</div>
              )}
            </div>
          </div>
        </label>
      </SearchableListPanel>
    );
  }
}
