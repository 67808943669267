// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component, Fragment } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { UploaderAWS } from "components/form";
import { Link } from "components/typography";
import { trans } from "src/translations";
import { withMutation } from "utils/graphql";

import {
  generateDeviceAttributeUploadUrl,
  importDeviceAttributes,
} from "../gql";
import { ImportManagerAttributesModal } from "../modals/ImportManagerAttributesModal";

const DEFAULT_IDLE_STATE = {
  isModalOpen: false,
  uploadedFileUrl: null,
  uploadedFileName: null,
  replaceAttributes: false,
  isSubmitting: false,
  hasSubmitError: false,
};

@withMutation({
  name: "generateDeviceAttributeUploadUrl",
  mutation: generateDeviceAttributeUploadUrl,
})
@withMutation({
  name: "importDeviceAttributes",
  mutation: importDeviceAttributes,
})
export class ImportManagerAttributes extends Component {
  static propTypes = {
    setSuccessImportMessage: PropTypes.func.isRequired,
    className: PropTypes.string,

    // from @withMutation
    generateDeviceAttributeUploadUrlMutation: PropTypes.func.isRequired,
    importDeviceAttributesMutation: PropTypes.func.isRequired,
  };

  state = { ...DEFAULT_IDLE_STATE };

  closeModal = () => this.setState({ ...DEFAULT_IDLE_STATE });

  openImportModal = () =>
    this.setState({ ...DEFAULT_IDLE_STATE, isModalOpen: true });

  handleReplaceCheckboxChange = () =>
    this.setState((state) => ({
      replaceAttributes: !state.replaceAttributes,
    }));

  getCredentials = async () => {
    const { generateDeviceAttributeUploadUrlMutation } = this.props;

    const resp = await generateDeviceAttributeUploadUrlMutation();
    const key = "generateDeviceAttributeUploadUrl.deviceAttributeUploadUrl";
    const data = get(resp.data, key, {});

    return {
      url: data.url,
      headers: JSON.parse(data.headers),
    };
  };

  onAWSComplete = ({ fileUrl, fileName }) => {
    this.setState({
      uploadedFileUrl: fileUrl,
      uploadedFileName: fileName,
      hasSubmitError: false,
    });
  };

  onAWSCancel = () => {
    // also called when file was uploaded successfully, but user resigned
    this.setState({
      uploadedFileUrl: null,
      hasSubmitError: false,
    });
  };

  importAttributes = async () => {
    const { importDeviceAttributesMutation, setSuccessImportMessage } =
      this.props;
    const { uploadedFileUrl, uploadedFileName } = this.state;

    const input = {
      fileUrl: uploadedFileUrl,
      filename: uploadedFileName,
      replaceAttributes: this.state.replaceAttributes,
    };
    await importDeviceAttributesMutation({
      variables: { input },
    });

    // not executed if gql has thrown
    this.closeModal();
    setSuccessImportMessage(
      <Fragment>
        {trans.DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_SUCCESS_A()}{" "}
        <Link to="/devices/queue">{trans.DEVICES__PROCESSING_QUEUE()}</Link>{" "}
        {trans.DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_SUCCESS_B()}
      </Fragment>
    );
  };

  handleUpload = async () => {
    try {
      this.setState({
        isSubmitting: true,
        hasSubmitError: false,
      });
      await this.importAttributes();
    } catch (e) {
      this.setState({ hasSubmitError: true });
      throw e; // for sentry
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  renderModal = (uploaderStatus) => {
    return (
      <ImportManagerAttributesModal
        isOpen={this.state.isModalOpen}
        closeModal={this.closeModal}
        handleUpload={this.handleUpload}
        handleReplaceCheckboxChange={this.handleReplaceCheckboxChange}
        uploadedFileUrl={this.state.uploadedFileUrl}
        replaceAttributes={this.state.replaceAttributes}
        isSubmitting={this.state.isSubmitting}
        hasSubmitError={this.state.hasSubmitError}
        uploaderStatus={uploaderStatus}
      />
    );
  };

  render() {
    const { className } = this.props;

    return (
      <Fragment>
        <Button onClick={this.openImportModal} className={className}>
          {trans.DEVICES__DEVICE_INVENTORY_IMPORT_ATTRIBUTES_BUTTON()}
        </Button>
        <UploaderAWS
          getCredentials={this.getCredentials}
          onCancel={this.onAWSCancel}
          onComplete={this.onAWSComplete}
        >
          {this.renderModal}
        </UploaderAWS>
      </Fragment>
    );
  }
}
