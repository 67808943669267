// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { SubTab, SubTabs } from "components/navigation";
import { trans } from "src/translations";

import { ACTIVE_JOBS_TAB, ARCHIVE_JOBS_TAB } from "./UpdateJobListData";

export const UpdateJobListTabs = ({ tabId }) => {
  return (
    <SubTabs>
      <SubTab
        key={ACTIVE_JOBS_TAB}
        active={tabId === ACTIVE_JOBS_TAB}
        to={`/updates/jobs/list/${ACTIVE_JOBS_TAB}`}
        text={trans.UPDATES__UPDATE_JOB_LIST__ACTIVE_JOBS_TAB()}
        dataTestId="active-tab"
      />
      <SubTab
        key={ARCHIVE_JOBS_TAB}
        active={tabId === ARCHIVE_JOBS_TAB}
        to={`/updates/jobs/list/${ARCHIVE_JOBS_TAB}`}
        text={trans.UPDATES__UPDATE_JOB_LIST__ARCHIVE_JOBS_TAB()}
        dataTestId="archived-tab"
      />
    </SubTabs>
  );
};

UpdateJobListTabs.propTypes = {
  tabId: PropTypes.string,
};

UpdateJobListTabs.defaultProps = {
  tabId: ACTIVE_JOBS_TAB,
};
