// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { ValidationBadge } from "components/form";
import { Input } from "components/form/elements";
import { Modal } from "components/layout";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";
import { getGqlErrorMessage } from "utils/errors";

import { DeviceAttributePropType } from "../propTypes";
import { validateAttributeValue } from "../utils/validateAttribute";
import styles from "./AttributeModal.scss";

const DEFAULT_STATE = {
  error: null,
  hasValidationError: false,
  submitting: false,
  valueInput: "",
};

export class AttributeEditModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setAttributeRequest: PropTypes.func.isRequired,
    setSuccessMessage: PropTypes.func.isRequired,
    attribute: DeviceAttributePropType,
  };

  state = { ...DEFAULT_STATE };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        ...DEFAULT_STATE,
        valueInput: get(this.props.attribute, "value", ""),
      });
    }
  }

  getAttributeName = () => get(this.props.attribute, "key", "");

  handleClose = () => {
    this.setState({ ...DEFAULT_STATE });
    this.props.onClose();
  };

  handleInputValueChange = (valueInput) => {
    this.setState({
      valueInput,
      hasValidationError: false,
    });
  };

  handleSubmit = async () => {
    const { setAttributeRequest, setSuccessMessage } = this.props;
    const { valueInput } = this.state;
    this.setState({
      submitting: true,
    });
    if (!validateAttributeValue(valueInput)) {
      this.setState({ hasValidationError: true, submitting: false });
      return;
    }
    const nameInput = this.getAttributeName();
    try {
      await setAttributeRequest(nameInput, valueInput);
    } catch (e) {
      this.setState((prevState) => {
        if (!prevState.submitting) {
          // The modal has been closed.
          return prevState;
        }
        return {
          error: getGqlErrorMessage(e),
          submitting: false,
        };
      });
      return;
    }
    this.setState({
      submitting: false,
    });
    setSuccessMessage(
      trans.DEVICES__DEVICE_DETAILS_MANAGER_ATTRIBUTES_SUCCESS_EDIT()
    );
  };

  render() {
    const { isOpen } = this.props;
    const { error, hasValidationError, submitting, valueInput } = this.state;
    const name = this.getAttributeName();

    return (
      <Modal
        isOpen={isOpen}
        onClose={this.handleClose}
        size="big"
        title={trans.DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_MODAL_HEADER()}
        dataTestId="edit-attribute-modal"
        actions={
          <ButtonsWrapper position="modal">
            <Button
              type="normal"
              onClick={this.handleClose}
              dataTestId="edit-attribute-cancel-button"
              disabled={submitting}
            >
              {trans.CANCEL()}
            </Button>
            <Button
              type="green"
              onClick={this.handleSubmit}
              dataTestId="edit-attribute-submit-button"
              disabled={submitting || hasValidationError}
              processing={submitting}
            >
              {trans.DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_MODAL_BUTTON()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <div className={styles.modalDescription}>
          {trans.DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_MODAL_DESCRIPTION()}
        </div>

        <form className={styles.modalForm}>
          <Tooltip
            content={name}
            alignment="bottom"
            poppupClassName={styles.modalManagerAttributeNameTooltip}
            className={styles.modalInput}
          >
            <Input
              label={trans.DEVICES__DEVICE_DETAILS_ATTRIBUTE_NAME()}
              look="simple"
              value={name}
              onChange={() => {}}
              disabled={true}
              dataTestId="add-attribute-modal-name-input"
              className={styles.modalSimpleInput}
            />
          </Tooltip>
          <span className={styles.modalColon}>{":"}</span>
          <Input
            label={trans.DEVICES__DEVICE_DETAILS_ATTRIBUTE_VALUE()}
            required={true}
            className={styles.modalInput}
            onChange={(e) => this.handleInputValueChange(e.target.value)}
            value={valueInput}
          />
        </form>

        <ValidationBadge
          error={trans.DEVICES__DEVICE_DETAILS_EDIT_ATTRIBUTE_MODAL_VALIDATION()}
          touched={hasValidationError}
          dataTestId="add-attribute-modal-validation-error"
        />

        {error && <Info type="error">{error}</Info>}
      </Modal>
    );
  }
}
