// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Row, Column } from "components/layout";
import { RestrictedArea } from "containers/Permissions";
import {
  ALL_PROVIDERS,
  allowInOrganization,
} from "containers/Permissions/groups";
import { APP_ROUTE_MY, SUBMISSION_STATUS } from "pages/Applications/constants";
import { trans } from "src/translations";

export class ActionButtons extends Component {
  static propTypes = {
    versionData: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
    canEditVersion: PropTypes.bool.isRequired,
  };

  shouldDisableVersionEdit() {
    const { application } = this.props;
    return application.submission_status === SUBMISSION_STATUS.IN_REVIEW.id;
  }

  render() {
    const { versionData, application, canEditVersion } = this.props;

    return (
      <Row>
        {canEditVersion ? (
          <RestrictedArea
            allowed={[
              allowInOrganization(application.owner_public_id, ALL_PROVIDERS),
            ]}
            showFallback={false}
          >
            <Column>
              <ButtonsWrapper position="left">
                <Button
                  to={`/applications/${APP_ROUTE_MY}/${versionData.application.id}/edit`}
                  disabled={this.shouldDisableVersionEdit()}
                >
                  {trans.APP_DETAILS_VERSION__EDIT_BUTTON()}
                </Button>
              </ButtonsWrapper>
            </Column>
          </RestrictedArea>
        ) : null}
      </Row>
    );
  }
}
