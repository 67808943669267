// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { throttle } from "lodash-es";
import PropTypes from "prop-types";

import { classes } from "utils/classes";
import { innerRefType } from "utils/types";

import { ScrollButton } from "./ScrollButton";
import styles from "./subTabs.scss";

const SCROLL_LEFT = -1;
const SCROLL_RIGHT = 1;
const HANDLE_SCROLL_THROTTLE = 200;

/**
 * Component that implements horizontally scrollabe tabs.  Use with
 * [TabContent](#tabcontent)
 */
export class SubTabs extends Component {
  static propTypes = {
    /** [SubTab](#subtab) elements */
    children: PropTypes.node,
    className: PropTypes.string,
    name: PropTypes.string,
    innerRef: innerRefType,
  };

  state = {
    scrolledToLeft: false,
    scrolledToRight: false,
  };

  componentDidMount = () => {
    this.updateScrollState(this.tabsRef);
    this.tabsRef.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount = () => {
    this.tabsRef.removeEventListener("scroll", this.handleScroll);
  };

  isScrolledToLeft = (target) => {
    const { scrollLeft } = target;
    return scrollLeft <= 0;
  };

  isScrolledToRight = (target) => {
    const { scrollWidth, scrollLeft, clientWidth } = target;
    return scrollWidth - (scrollLeft + clientWidth) <= 0;
  };

  handleScroll = throttle((ev) => {
    this.updateScrollState(ev.target);
  }, HANDLE_SCROLL_THROTTLE);

  updateScrollState = (target) => {
    this.setState({ scrolledToLeft: this.isScrolledToLeft(target) });
    this.setState({ scrolledToRight: this.isScrolledToRight(target) });
  };

  scroll = (direction) => () => {
    const scrollStep = 10;

    if (direction === SCROLL_LEFT) {
      this.tabsRef.scrollBy({ left: -scrollStep });
    } else if (direction === SCROLL_RIGHT) {
      this.tabsRef.scrollBy({ left: scrollStep });
    }
  };

  render() {
    const { className, name, innerRef } = this.props;

    return (
      <div
        ref={innerRef}
        className={classes(
          name ? styles.wrapperWithName : styles.wrapper,
          className
        )}
      >
        {name && <div className={styles.name}>{name}</div>}
        <ScrollButton
          className={styles.arrowLeft}
          direction="left"
          onScroll={this.scroll(SCROLL_LEFT)}
          disabled={this.state.scrolledToLeft}
        />
        <div className={styles.tabs} ref={(ref) => (this.tabsRef = ref)}>
          {this.props.children}
        </div>
        <ScrollButton
          className={styles.arrowRight}
          direction="right"
          onScroll={this.scroll(SCROLL_RIGHT)}
          disabled={this.state.scrolledToRight}
        />
      </div>
    );
  }
}

export default SubTabs;
