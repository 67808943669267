// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import styles from "../SearchBarWithFilters.scss";
import { createDropdownSuggestion } from "./utils";

const parseExcludeHTML = (param) => {
  return (
    <span>
      {param}
      <span className={styles.suggestionsExclude}>
        {trans.SEARCH_BAR__EXCLUDE_SUGGESTION_WARNING()}
      </span>
    </span>
  );
};

/**
 * Prepares data to be displayed in SearchDropdown as FilterDropdownOption.
 * All filters are grouped between separate sections
 */
export const getOptionSuggestions = (filters, userInput, isExcludeModeOn) => {
  const createOption = (option) => ({
    ...option,
    suggestion: createDropdownSuggestion(option, undefined, userInput),
  });

  return filters.sections.map((section) => ({
    ...section,
    options: isExcludeModeOn
      ? section.options
          .filter((option) => option.isExcludable)
          .map((option) => ({
            ...option,
            param: parseExcludeHTML(option.param),
          }))
          .map(createOption)
      : section.options.map(createOption),
  }));
};
