// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { createRef, Component } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { GrabsonIcon, IconPropTypes } from "components/icons";
import { classes } from "utils/classes";
import { innerRefType } from "utils/types";

import styles from "./tab.scss";

export class Tab extends Component {
  static propTypes = {
    /** If true tab is set as active */
    active: PropTypes.bool,
    /** User class name applied when tab is active. */
    activeClassName: PropTypes.string,
    /** User class name. */
    className: PropTypes.string,
    /** HTML link. */
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** Icon name. See [GrabsonIcon](#grabsonicon) for available icons. */
    icon: PropTypes.string,
    /** Use if GrabsonIcon is not sufficent. */
    customIcon: PropTypes.node,
    /** Icon color. */
    iconColor: PropTypes.oneOf(["default", "success", "error"]),
    /** Icon size */
    iconSize: IconPropTypes.size,
    /** Scroll to active element on first render */
    scrollIntoView: PropTypes.bool,
    /** Tab label text. */
    text: PropTypes.string,
    /** Called on tab click */
    onClick: PropTypes.func,
    dataTestId: PropTypes.string,
    dataTestIconId: PropTypes.string,
    innerRef: innerRefType,
    draggableProps: PropTypes.object,
    dragHandleProps: PropTypes.object,
  };

  static defaultProps = {
    iconColor: "default",
    iconSize: "xs",
    scrollIntoView: false,
    dataTestId: "tab",
    dataTestIconId: "tab-icon",
  };

  wrapperRef = createRef();

  componentDidMount() {
    const { scrollIntoView, active } = this.props;
    if (scrollIntoView && active && this.wrapperRef.current) {
      this.wrapperRef.current.scrollIntoView();
    }
  }

  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(this.props);
    }
  };

  activeClass(isActive) {
    const { active, activeClassName } = this.props;
    const hasActiveClass =
      typeof active === "undefined" ? isActive : this.props.active;
    return hasActiveClass ? activeClassName || styles.active : undefined;
  }

  render() {
    const {
      className,
      to,
      icon,
      customIcon,
      iconColor,
      iconSize,
      text,
      dataTestId,
      dataTestIconId,
      innerRef,
      draggableProps,
      dragHandleProps,
      active,
    } = this.props;

    if (to) {
      return (
        <NavLink
          {...draggableProps}
          ref={innerRef}
          to={to}
          className={({ isActive }) =>
            classes(className ?? styles.tab, this.activeClass(isActive))
          }
          data-test-id={`${dataTestId}-link`}
        >
          {icon && (
            <GrabsonIcon
              {...dragHandleProps}
              name={icon}
              font="micro"
              className={styles[iconColor]}
              size={iconSize}
              dataTestId={dataTestIconId}
            />
          )}
          {text}
          {customIcon}
        </NavLink>
      );
    }

    return (
      <div
        {...draggableProps}
        className={classes(className ?? styles.tab, this.activeClass(active))}
        onClick={this.handleClick}
        ref={innerRef || this.wrapperRef}
        data-test-id={dataTestId}
      >
        {icon && (
          <GrabsonIcon
            {...dragHandleProps}
            name={icon}
            className={styles[iconColor]}
            font="micro"
            size={iconSize}
          />
        )}
        {text}
      </div>
    );
  }
}

export default Tab;
