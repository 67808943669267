// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
} from "react";
import { Route } from "react-router-dom";

import PropTypes from "prop-types";

import { useBreadcrumbs } from "containers/Breadcrumbs/hooks";
import { LazyComponent } from "containers/Layout/LazyComponent";
import { withRouter } from "utils/decorators/withRouter";

function addPropsToChildren(children, props) {
  return Children.map(children, (child) => {
    // Checking isValidElement is the safe way
    if (isValidElement(child)) {
      return cloneElement(child, { ...props });
    }
    return child;
  });
}

function defaultRender(props) {
  const {
    lazy,
    layout: Layout,
    component: Component,
    componentProps,
    ...rest
  } = props;

  if (!Layout) {
    return lazy ? (
      <LazyComponent {...rest} {...componentProps} component={Component} />
    ) : (
      <Component {...componentProps} {...rest} />
    );
  }

  return (
    <Layout {...rest} {...componentProps}>
      {lazy ? (
        <LazyComponent {...componentProps} component={Component} />
      ) : (
        <Component {...rest} {...componentProps} />
      )}
    </Layout>
  );
}

/**
 *
 * HOC which adds support for breadcrumbs, lazy loading. Supports all props nomally provided
 * to `<Route>`.
 *
 * This is just code snippet, real example would be very hard to replicate in this styleguide:
 *
 * ```js
 * const Switch = require("react-router-dom").Switch;
 * const DummyComponent = () => {};
 *
 * <Routes>
 *  <Route
 *        exact
 *        path="/toMatch"
 *        element={<ExtendedRoute name="route 1" component={DummyComponent}/>}
 *  />
 * </Routes>
 * ```
 */
export const ExtendedRoute = withRouter((props) => {
  const { render, children, name } = props;
  const { addBreadcrumb, removeBreadcrumb } = useBreadcrumbs(props);

  useMemo(() => {
    removeBreadcrumb();
    addBreadcrumb();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    return () => {
      removeBreadcrumb();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (children) {
    return addPropsToChildren(children, props);
  }
  if (render) {
    return render(props);
  }

  return defaultRender(props);
});

ExtendedRoute.propTypes = {
  ...Route.propTypes,
  /**
   * Rendered wrapper on the child component. Allows to share page layout between
   * many similar pages.
   */
  layout: PropTypes.elementType,
  /** Use `<Lazy*>` variants of the components */
  lazy: PropTypes.bool,
  /** Static breadcrumb name */
  name: PropTypes.string,
  nameFromMatch: PropTypes.func,
  /**
   * Injected by `withRouter`
   * @ignore
   */
  params: PropTypes.object,
  match: PropTypes.object,

  /** Additional props that we want to provide to the component */
  componentProps: PropTypes.object,
};
