// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";
import Redbox from "redbox-react";

export const ConsoleErrorReporter = ({ error }) => {
  return <Redbox error={error} />;
};

ConsoleErrorReporter.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};
