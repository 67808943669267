// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { TableRowColumn } from "components/layout";
import iconStyles from "pages/Snap/SnapEdit/components/SnapIconPicker/icons.scss";

export const OrderColumn = ({
  fields,
  index,
  arrowUpPossible,
  arrowDownPossible,
  isLive = false,
  name,
  move,
  form: { setFieldValue },
}) => {
  const moveFields = (index, diff) => {
    if (!isLive) {
      const currentOrder = fields[index].order;
      fields[index].order = fields[index + diff].order;
      fields[index + diff].order = currentOrder;
      setFieldValue(name, fields);
    }

    move(index, index + diff);
  };

  const arrowUpClass = arrowUpPossible ? "" : iconStyles.disabledArrow;
  const arrowDownClass = arrowDownPossible ? "" : iconStyles.disabledArrow;
  return (
    <TableRowColumn>
      <GrabsonIcon
        name="arrow-up"
        size="sm"
        font="micro"
        className={arrowUpClass}
        onClick={() => {
          if (!arrowUpClass) {
            moveFields(index, -1);
          }
        }}
      />
      <GrabsonIcon
        name="arrow-down"
        size="sm"
        font="micro"
        className={arrowDownClass}
        onClick={() => {
          if (!arrowDownClass) {
            moveFields(index, 1);
          }
        }}
      />
    </TableRowColumn>
  );
};

OrderColumn.propTypes = {
  fields: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  arrowUpPossible: PropTypes.bool.isRequired,
  arrowDownPossible: PropTypes.bool.isRequired,
  isLive: PropTypes.bool,
  /** name used for formik managed fields */
  name: PropTypes.string.isRequired,
  /** formik context props */
  move: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};
