// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { classes } from "utils/classes";

import styles from "./TableSubRow.scss";

/**
 * Describes subrows of the table. Should be child of `<TableRow>`.
 */
export class TableSubRow extends Component {
  static propTypes = {
    /**
     * [TableRowColumn](#tablerowcolum) or [TableRowColumnExtended](#tablerowcolumnextended)
     * elements.
     */
    children: PropTypes.node,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    highlighting: PropTypes.bool,
  };

  static defaultProps = {
    dataTestId: "table-row",
    highlighting: true,
  };

  render() {
    const { children, className, dataTestId, highlighting } = this.props;
    return (
      <tr
        data-test-id={dataTestId}
        className={classes(styles.container, className, {
          [styles.highlight]: highlighting,
        })}
      >
        {children}
      </tr>
    );
  }
}

export default TableSubRow;
