// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";

import styles from "./Breadcrumbs.scss";
import { BreadcrumbsList } from "./BreadcrumbsList";
import { breadcrumbPropType } from "./propTypes";

@connect(({ breadcrumbs }) => ({ breadcrumbs }))
class Breadcrumbs extends Component {
  static propTypes = {
    /** @ignore */
    breadcrumbs: PropTypes.arrayOf(breadcrumbPropType),
  };

  render() {
    const { breadcrumbs = [] } = this.props;

    return (
      <div className={styles.breadcrumbs}>
        <Link to="/">
          <GrabsonIcon
            name="home"
            size="xs"
            font="micro"
            className={styles.icon}
          />
        </Link>
        <GrabsonIcon
          name="arrow-right"
          size="xs"
          font="micro"
          className={styles.icon}
        />
        <BreadcrumbsList breadcrumbs={breadcrumbs} />
      </div>
    );
  }
}

// styleguidist does not like exporting classes that use decorators
export { Breadcrumbs };
