// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { SearchableListPanel } from "components/elements";
import { Checkbox } from "components/form/elements";
import { trans } from "src/translations";

import styles from "./PlansRow.scss";

const IS_CHECKED_AFTER_INDETERMINATE = false;

export class PlansRow extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    getPlanSelectionState: PropTypes.func.isRequired,
    onChecked: PropTypes.func,
    dataTestId: PropTypes.string,
    selectedApps: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    dataTestId: "plans-row",
  };

  handleChange = () => {
    const { onChecked, item } = this.props;
    const currentState = this.getDisplayState();
    const nextChecked = currentState.indeterminate
      ? IS_CHECKED_AFTER_INDETERMINATE
      : !currentState.checked;
    onChecked(item, nextChecked);
  };

  getDisplayState() {
    const { getPlanSelectionState, selectedApps, item } = this.props;
    return getPlanSelectionState(selectedApps, item.id);
  }

  getAppCountText(displayState) {
    const { selectedApps } = this.props;
    return trans.APP_LIST_DISTRIBUTION__MANAGE_APPS_MODAL_INCLUDED_APPS_VALUE({
      selectedCount: displayState.selectedCount,
      totalApps: selectedApps.length,
    });
  }

  render() {
    const { item, dataTestId } = this.props;
    const displayState = this.getDisplayState();

    return (
      <SearchableListPanel dataTestId={dataTestId} className={styles.row}>
        <label className={styles.label}>
          <Checkbox
            onChange={this.handleChange}
            value={item.id}
            dataTestId={`${dataTestId}-checkbox`}
            className={styles.checkbox}
            checked={displayState.checked}
            indeterminate={displayState.indeterminate}
          />
          <div
            className={styles.planName}
            data-test-id={`${dataTestId}-name`}
            title={item.name}
          >
            {item.name}
          </div>
          <div className={styles.includedApps}>
            {this.getAppCountText(displayState)}
          </div>
        </label>
      </SearchableListPanel>
    );
  }
}
