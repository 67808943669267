// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import chunk from "lodash-es/chunk";

// Applications are limited to 100 results per page
const MAX_RESULTS_PER_PAGE = 100;

export const getAllApplications = async (applicationIds, getApplications) => {
  const chunks = chunk(applicationIds, MAX_RESULTS_PER_PAGE);

  const apps = await Promise.all(chunks.map((c) => getApplications(c))).then(
    (res) => res.flat()
  );

  return apps;
};
