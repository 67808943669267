// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { shape, bool, number } from "prop-types";

import { initialFile } from "utils/formik/utils";

import { ACTION_VALUES } from "../constants";
import { adaptActionToUI, adaptContentItemsToUI } from "./adapters";

export const DEFAULT_CONFIGURATION = {
  // Special initial value to indicate that no promotion type has been chosen yet
  appsCount: -1,
};

export const configurationType = shape({
  action: bool,
  appsCount: number,
  background: bool,
  contentItems: bool,
  description: bool,
  icon: bool,
  json: bool,
  thumbnail: bool,
  title: bool,
});

export const getConfiguration = (promotionType) => {
  return !promotionType
    ? null
    : {
        appsCount: promotionType.number_of_apps,
        icon: promotionType.custom_icon,
        thumbnail: promotionType.custom_thumbnail,
        title: promotionType.custom_title,
        description: promotionType.custom_description,
        background: promotionType.additional_background_image,
        action: promotionType.additional_action,
        json: promotionType.additional_metadata,
        contentItems: promotionType.additional_application_assets,
      };
};

export const prepareInitialValues = (
  {
    appsCount,
    title,
    description,
    json,
    icon,
    thumbnail,
    background,
    contentItems,
    action,
  } = DEFAULT_CONFIGURATION,
  promotion,
  details
) => {
  const extractorFn = (_, i) => {
    const promo = details?.[i];

    const appFormInitialValues = {
      order: i,
      application: appsCount > 0 && (promo?.application_id ?? ""),
      title: title && (promo?.title ?? ""),
      description: description && (promo?.description ?? ""),
      json: json && (promo?.metadata ?? ""),
      icon: icon && initialFile(promo?.icon ?? "", `icon-${i}`, i),
      thumbnail:
        thumbnail && initialFile(promo?.thumbnail ?? "", `thumbnail-${i}`, i),
      background:
        background &&
        initialFile(promo?.background ?? "", `background-${i}`, i),
      contentItems: contentItems && adaptContentItemsToUI(promo?.assets ?? []),
      ...(action ? adaptActionToUI(promo?.action ?? ACTION_VALUES.WATCH) : {}),
    };

    // remove fields not present in configuration
    Object.keys(appFormInitialValues).forEach((key) => {
      if (appFormInitialValues[key] === false) {
        delete appFormInitialValues[key];
      }
    });

    return appFormInitialValues;
  };

  return {
    name: promotion?.name ?? "",
    type: promotion?.promotion_type?.id ?? "",
    apps:
      appsCount >= 0 ? [...Array(appsCount || 1).keys()].map(extractorFn) : [],
  };
};
