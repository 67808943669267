// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import placeholder from "src/images/avatarDefault.png";
import { classes } from "utils/classes";

import styles from "./Avatar.scss";

export class Avatar extends Component {
  static propTypes = {
    /** link or path to image; placeholder displayed if src not provided */
    src: PropTypes.string,
    /** image size, e.g. "64px" */
    size: PropTypes.string,
    /** if true, avatar rounded */
    round: PropTypes.bool,
    className: PropTypes.string,
    buttonType: PropTypes.string,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    size: "64px",
    round: true,
    dataTestId: "avatar",
  };

  render() {
    const { src, size, round, className, dataTestId, ...rest } = this.props;
    return (
      <div
        style={round ? { borderRadius: size, minWidth: size } : null}
        className={classes(styles.Avatar, className)}
        data-test-id={dataTestId}
        {...rest}
      >
        <img
          src={src || placeholder}
          width={size}
          height={size}
          alt="Icon"
          style={{ objectFit: "contain", display: "block" }}
        />
      </div>
    );
  }
}
export default Avatar;
