// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { DEVICE_TYPE_DISTRIBUTOR } from "pages/_shared/TargetsField/constants";
import { trans } from "src/translations";

export const AUTO_APPROVE_TIME_OPTIONS = {
  IMMEDIATELY: {
    value: "NONE",
    text: trans.DISTR_DEFAULT_PLAN__AUTOAPPROVE_NONE(),
  },
  ONE_WEEK: {
    value: "ONE_WEEK",
    text: trans.DISTR_DEFAULT_PLAN__AUTOAPPROVE_ONE_WEEK(),
  },
  TWO_WEEKS: {
    value: "TWO_WEEKS",
    text: trans.DISTR_DEFAULT_PLAN__AUTOAPPROVE_TWO_WEEKS(),
  },
};

export const FIELDS = {
  TARGETS: {
    name: "targets",
    defaultValue: [
      {
        countries: [], // TODO by default all countries should be selected
        deviceFamilies: [],
        deviceModels: [],
        oems: [],
        type: DEVICE_TYPE_DISTRIBUTOR,
      },
    ],
  },
  AUTO_APPROVE: { name: "autoApprove", defaultValue: true },
  AUTO_APPROVE_TIME: {
    name: "autoApproveTime",
    defaultValue: AUTO_APPROVE_TIME_OPTIONS.ONE_WEEK.value,
  },
};
