// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { TextareaField, TextField } from "components/form/fields";
import { GrabsonIcon } from "components/icons";
import { Column, Row } from "components/layout";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";
import { classes } from "utils/classes";
import { useToggle } from "utils/hooks";

import styles from "./ManifestEdit.scss";
import { ManifestEditFieldArray } from "./ManifestEditFieldArray";

const DescriptionRow = ({ fieldName }) => {
  const [isVisible, toggleVisibility] = useToggle(true);

  return (
    <Row>
      <Column className={styles.descriptionCol}>
        <label>
          <Tooltip
            content={trans.APP_DETAILS_MANIFEST__FIELD_CUSTOM_DESCRIPTION_TOOLTIP()}
          >
            {trans.APP_DETAILS_MANIFEST__FIELD_CUSTOM_DESCRIPTION_LABEL()}
            <abbr title="required">*</abbr>
            &nbsp;(?)
          </Tooltip>
          <Button type="plain" onClick={toggleVisibility}>
            <GrabsonIcon
              name={isVisible ? "arrow-up" : "arrow-down"}
              font="micro"
              size="sm"
            />
          </Button>
          <TextareaField
            className={classes(styles.descriptionField, {
              [styles.hidden]: !isVisible,
            })}
            look="simple"
            name={`${fieldName}.description`}
          />
        </label>
      </Column>
    </Row>
  );
};

DescriptionRow.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

const renderFieldRow = ({ fieldName, action, isPlaceholder = false }) => {
  return (
    <Fragment key={`${fieldName}-wrapper`}>
      <Row>
        <Column className={styles.arrayCol}>
          <TextField
            look="simple"
            placeholder={trans.APP_DETAILS_MANIFEST__FIELD_CUSTOM_KEY_PLACEHOLDER()}
            name={isPlaceholder ? "_disabled_value_" : `${fieldName}.key`}
            disabled={isPlaceholder}
            required={!isPlaceholder}
          />
          <Button className={styles.actionButton} type="plain" onClick={action}>
            <GrabsonIcon
              name={isPlaceholder ? "plus-filled" : "close-filled"}
              font="micro"
              size="xl"
              className={
                isPlaceholder ? styles.addFieldButton : styles.removeFieldButton
              }
            />
          </Button>
        </Column>
        <Column className={styles.arrayCol}>
          <TextField
            look="simple"
            placeholder={trans.APP_DETAILS_MANIFEST__FIELD_CUSTOM_VALUE_PLACEHOLDER()}
            name={isPlaceholder ? "_disabled_value_" : `${fieldName}.value`}
            disabled={isPlaceholder}
            required={!isPlaceholder}
          />
        </Column>
      </Row>
      {!isPlaceholder && <DescriptionRow fieldName={fieldName} />}
    </Fragment>
  );
};

export const ManifestEditCustomField = ({ name }) => (
  <ManifestEditFieldArray
    name={name}
    descriptionFunc={trans.APP_DETAILS_MANIFEST__FIELD_CUSTOM}
    renderFieldRow={renderFieldRow}
    newItemValue={{
      key: "",
      value: "",
      description: "",
    }}
  />
);

ManifestEditCustomField.propTypes = {
  name: PropTypes.string.isRequired,
};
