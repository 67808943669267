// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useCallback, useEffect, useRef, useState } from "react";

import { useField } from "formik";

import { Label, ValidationBadge } from "components/form";
import { classes } from "utils/classes";

import { createMixedValidator, getAriaProps, getErrorId } from ".";
import { FieldProps } from "./propTypes";
import styles from "./ResolutionField.scss";

const INPUT_STATES = {
  ACTIVE: "active",
  DISABLED: "disabled",
  INACTIVE: "inactive",
};

const getLabelId = (name, id, label) => {
  if (id) {
    return id;
  } else if (label) {
    return `${name}-input-id`;
  }
  return undefined;
};

const getStateFromValue = (value) => {
  const regex = new RegExp("^(\\d*)x(\\d*)$", "i");
  const res = regex.exec(value);
  return { x: res ? res[1] : "", y: res ? res[2] : "" };
};

/*
 Here are some intentional simplifications of this implementation,
 some things that could be improved if needed:
 - Clicking on any of input fields will focus clicked element,
   but clicking on input's wrapper will focus always the last one
   (no matter if click was closer to first or second input)
 - Label will always focus first input
 - Backspace works separately for each input
   (no possibility to clear whole input with one backspace)
 */
export const ResolutionField = (props) => {
  const {
    disabled,
    id,
    label,
    look = "wrapped",
    name,
    popupPosition,
    required = true,
    tooltip,
    validate,
    dataTestId,
  } = props;
  const inputRef = useRef();

  const [field, meta, helpers] = useField({
    ...props,
    popupPosition,
    required,
    validate: createMixedValidator(name, required, validate),
    type: "text",
  });

  // internal state of "input value", split by 2 separate values
  const [resolution, setResolution] = useState(() =>
    getStateFromValue(meta.value)
  );
  useEffect(() => {
    setResolution(getStateFromValue(field.value));
  }, [field.value]);

  const setValue = (x, y) => {
    if (disabled) {
      return;
    }
    const newX = x ? parseInt(x, 10).toString() : x === "" ? x : resolution.x;
    const newY = y ? parseInt(y, 10).toString() : y === "" ? y : resolution.y;
    helpers.setValue(newX || newY ? `${newX}x${newY}` : "");
  };

  // internal state of "input state"
  const [inputState, setInputState] = useState(() =>
    disabled ? INPUT_STATES.DISABLED : INPUT_STATES.INACTIVE
  );
  const onActivate = useCallback(
    (e) => {
      if (disabled) {
        return;
      }
      if (e.target.nodeName !== "INPUT") {
        inputRef.current.focus();
      }
      helpers.setTouched();
      setInputState(INPUT_STATES.ACTIVE);
    },
    [disabled, helpers]
  );

  const onBlur = useCallback(() => {
    setInputState(INPUT_STATES.INACTIVE);
  }, []);

  const inputProps = {
    onClick: onActivate,
    onFocus: onActivate,
    onBlur: onBlur,
    className: styles.resolutionInput,
    disabled: disabled,
    ...getAriaProps({ name, error: meta.error, touched: meta.touched }),
  };

  return (
    <div className={styles[look]}>
      <Label
        required={required}
        text={label}
        tooltip={tooltip}
        htmlFor={getLabelId(name, id, label)}
      >
        <div
          className={classes(
            styles.resolutionWrapper,
            styles[meta.touched && meta.error ? "error" : "normal"],
            styles[inputState]
          )}
          onClick={onActivate}
        >
          <input
            {...inputProps}
            id={getLabelId(name, id, label)}
            onChange={(e) => setValue(e.target.value)}
            value={resolution.x}
            data-test-id={`${dataTestId}-input`}
          />
          <span>X</span>
          <input
            {...inputProps}
            onChange={(e) => setValue(null, e.target.value)}
            ref={inputRef}
            value={resolution.y}
            data-test-id={`${dataTestId}-input`}
          />
        </div>
      </Label>
      <input type="hidden" {...field} />
      <ValidationBadge
        className={styles.error}
        error={meta.error}
        touched={meta.touched}
        errorId={getErrorId(name)}
      />
    </div>
  );
};

ResolutionField.propTypes = FieldProps;
