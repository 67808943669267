// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { FormHasErrors } from "components/form";
import { Section } from "components/layout";
import { trans } from "src/translations";
import { withRouter } from "utils/decorators";
import { prepareErrorsForForm } from "utils/errors";

import { AssetDeviceTable } from "../TableDeviceModels/AssetDeviceTable";
import { validateForm } from "./fields/validators";
import { VersionsField } from "./fields/VersionsField";

const jsonPointerToFieldName = {
  "/data/:id/attributes/label": "versions[:id].number",
  "/data/:id/attributes/url": "versions[:id].url",
};

const DEFAULT_VERSION_IDX = 0;

@withRouter
export class FormVersions extends Component {
  static propTypes = {
    onVersionsPost: PropTypes.func.isRequired,
    asset: PropTypes.object.isRequired,
    versions: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,

    // from @withRouter
    // eslint-disable-next-line react/no-unused-prop-types
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();
    this.state = {
      selectedVersionIdx: this.getSelectedVersionIdxFromUrl(props),
    };
  }

  getSelectedVersionIdxFromUrl(props) {
    const { location, versions } = props;
    const urlVersion = location.state?.versionNumber;

    if (!urlVersion) {
      return DEFAULT_VERSION_IDX;
    }

    return versions.reduce((acc, version, idx) => {
      return version.number === urlVersion ? idx : acc;
    }, DEFAULT_VERSION_IDX);
  }

  handleSelectVersion = (selectedVersionIdx) => {
    this.setState({ selectedVersionIdx });
  };

  getSelectedVersion() {
    const { versions } = this.props;
    const { selectedVersionIdx } = this.state;
    return versions[selectedVersionIdx];
  }

  handleSubmit = async (values, { setErrors }) => {
    const { onVersionsPost } = this.props;

    try {
      await onVersionsPost(values);
    } catch (err) {
      const submitErrors = prepareErrorsForForm(err, jsonPointerToFieldName);

      if (submitErrors.versions) {
        submitErrors._error = true;
      }

      setErrors(submitErrors);
    }
  };

  render() {
    const { asset, initialValues } = this.props;

    const selectedVersion = this.getSelectedVersion();
    const initSelectedIndex = this.getSelectedVersionIdxFromUrl(this.props);

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validate={validateForm}
        enableReinitialize={true}
      >
        {({ dirty, errors, isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <Section header={trans.ASSET__VERSIONS_HEADER()}>
                <VersionsField
                  onSelectVersion={this.handleSelectVersion}
                  initSelectedIndex={initSelectedIndex}
                  errors={errors.versions}
                  versions={values.versions}
                  setFieldValue={setFieldValue}
                />
                <FormHasErrors submitFailed={errors._error} />
                <ButtonsWrapper>
                  <Button
                    buttonType="submit"
                    type="green"
                    disabled={
                      isSubmitting ||
                      !dirty ||
                      Boolean(Object.entries(errors).length)
                    }
                    processing={isSubmitting}
                  >
                    {trans.SAVE()}
                  </Button>
                </ButtonsWrapper>
              </Section>
              {selectedVersion && selectedVersion.isSaved && (
                <AssetDeviceTable
                  key={`${asset.id}-${selectedVersion.number}`}
                  asset={asset}
                  version={selectedVersion.number}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}
