// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Subsection } from "components/layout";

import {
  IsVersionUpdated,
  HeaderContentWithUpdatedLabel,
} from "./IsVersionUpdated";

export class SubsectionWithUpdatedStatus extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    headerContent: PropTypes.node.isRequired,
    /** If not provided, 'isUpdatedFunc' prop required */
    isUpdatedPath: PropTypes.string,
    /** If not provided, 'isUpdatedPath' prop required */
    isUpdatedFunc: PropTypes.func,
    subsectionClassName: PropTypes.string,
  };

  render() {
    const {
      children,
      headerContent,
      isUpdatedPath,
      isUpdatedFunc,
      subsectionClassName,
    } = this.props;

    return (
      <IsVersionUpdated path={isUpdatedPath} isUpdated={isUpdatedFunc}>
        {(isUpdated) => (
          <Subsection
            className={subsectionClassName}
            header={
              <HeaderContentWithUpdatedLabel
                content={headerContent}
                isUpdated={isUpdated}
              />
            }
          >
            {children}
          </Subsection>
        )}
      </IsVersionUpdated>
    );
  }
}
