// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import uniq from "lodash-es/uniq";
import PropTypes from "prop-types";
import queryString from "query-string";

import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea } from "containers/Permissions";
import { ROLES } from "containers/Permissions/roles";
import { api, apiThatThrows } from "containers/Request";
import { createBody } from "utils/jsonApi";
import {
  createLocationWithSearch,
  retrieveFiltersFromInclude,
} from "utils/url";

import { getFullName } from "../_utils";
import { WARNING_MODAL_MODES } from "./ActivationWarningModal";
import { UsersTableRend } from "./UsersTableRend";

@restrictedArea(() => ({
  allowed: [ROLES.administrator.userAdmin],
}))
@withActiveOrganization
@connect(null, (dispatch) => ({
  getUsersPaginated: (queryParams) =>
    dispatch(
      api.getUsersPaginated.action({
        queryParams: retrieveFiltersFromInclude(queryParams),
      })
    ),
  getOrganizations: (queryParams) =>
    dispatch(
      api.getOrganizationsPaginated.action({
        queryParams: {
          limit: "nolimit",
          ...retrieveFiltersFromInclude(queryParams),
        },
      })
    ),
  setUserStatus: (id, body) =>
    dispatch(
      apiThatThrows.patchUserProfileNoOrganization.action({
        params: { id },
        options: { body },
      })
    ),
}))
export class UsersTableData extends Component {
  static propTypes = {
    // from @ExtendedRoute
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,

    // from @withActiveOrganization
    activeOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    // from @connect
    getUsersPaginated: PropTypes.func.isRequired,
    getOrganizations: PropTypes.func.isRequired,
    setUserStatus: PropTypes.func.isRequired,
  };

  state = {
    users: [],
    count: 0,
    error: undefined,
    loading: true,
    warningModalMode: WARNING_MODAL_MODES.CLOSED,
    processedUser: null,
    setStatusLoading: false,
  };

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location !== this.props.location ||
      prevProps.activeOrganization !== this.props.activeOrganization
    ) {
      this.refreshData();
    }
  }

  getOrganizationNames = (authorizations, organizations) => {
    const organizationIds = authorizations.flatMap(
      (authorization) => authorization.organization
    );
    return organizationIds.map((organizationId) => {
      return get(
        organizations.find((org) => org.id === organizationId),
        "name"
      );
    });
  };

  loadUserData = (user, organizations) => {
    const organizationNames = this.getOrganizationNames(
      user.authorizations,
      organizations
    );

    return {
      id: user.id,
      family_name: user.family_name,
      given_name: user.given_name,
      is_active: user.is_active,
      organizationNames,
    };
  };

  prepareSetStatusBody = (status) => {
    const data = {
      type: "users",
      is_active: status,
    };
    return createBody(data);
  };

  performSetActiveRequest = async () => {
    const { setUserStatus } = this.props;
    const {
      processedUser: { id },
      warningModalMode,
    } = this.state;
    this.setState({ setStatusLoading: true });

    const body = this.prepareSetStatusBody(
      warningModalMode === WARNING_MODAL_MODES.ACTIVATE
    );

    try {
      await setUserStatus(id, body);
      await this.refreshData();
    } catch (error) {
      this.setState({ error });
    } finally {
      this.handleCloseModal();
    }
  };

  handleSetActive = (id, status) => {
    const { users } = this.state;
    const user = users.find((user) => user.id === id);

    this.setState({
      processedUser: { id, name: getFullName(user) },
      warningModalMode: status
        ? WARNING_MODAL_MODES.ACTIVATE
        : WARNING_MODAL_MODES.DEACTIVATE,
    });
  };

  handleCloseModal = () =>
    this.setState({
      warningModalMode: WARNING_MODAL_MODES.CLOSED,
      processedUser: null,
      setStatusLoading: false,
    });

  handleChangeSearch = (newSearchParams) => {
    this.props.navigate(
      createLocationWithSearch(this.props.location, newSearchParams)
    );
  };

  fetchOrganizations = async (organizationIds) => {
    const { getOrganizations } = this.props;
    if (organizationIds.length) {
      return getOrganizations({
        id: organizationIds,
      });
    }

    return { result: [], error: undefined };
  };

  refreshData = async () => {
    const { getUsersPaginated, location } = this.props;

    this.setState({ loading: true });

    const queryParams = queryString.parse(location.search);

    const { error, result } = await getUsersPaginated(queryParams);
    if (error) {
      this.setState({ error, loading: false });
      return;
    }

    const organizationIds = uniq(
      result.results.flatMap((user) =>
        user.authorizations.map((auth) => auth.organization)
      )
    );

    const { result: orgResult, error: orgError } =
      await this.fetchOrganizations(organizationIds);

    if (orgError) {
      this.setState({ error: orgError, loading: false });
      return;
    }

    const users = await Promise.all(
      result.results.map((user) => this.loadUserData(user, orgResult.results))
    );
    const firstError = users.find((result) => Boolean(result.error));
    if (firstError) {
      this.setState({ error: firstError.error, loading: false });
      return;
    }

    this.setState({
      users: users,
      count: result.meta.count,
      error: undefined,
      loading: false,
    });
  };

  render() {
    const {
      users,
      count,
      error,
      loading,
      warningModalMode,
      processedUser,
      setStatusLoading,
    } = this.state;

    return (
      <UsersTableRend
        users={users}
        count={count}
        error={error}
        loading={loading}
        setActive={this.handleSetActive}
        warningModalMode={warningModalMode}
        onCancelModal={this.handleCloseModal}
        onConfirmModal={this.performSetActiveRequest}
        processedUser={processedUser}
        setStatusLoading={setStatusLoading}
        onChangeSearch={this.handleChangeSearch}
      />
    );
  }
}
