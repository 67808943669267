// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { trans } from "src/translations";

import { HTTP_STATUS } from "./constants";

export const createError = (error, httpStatus) => {
  const result = {
    name: error.name,
    message: trans.DEFAULT_REQUEST_ERROR_MESSAGE(),
    serverErrors: [],
    isForbidden: httpStatus === HTTP_STATUS.FORBIDDEN_403,
    isNotFound: httpStatus === HTTP_STATUS.NOT_FOUND_404,
  };

  if (error && typeof error === "string") {
    result.message = error;
  } else if (error instanceof Error) {
    result.message = error.message;
  } else if (Array.isArray(error)) {
    const message = error.reduce((acc, err) => {
      const m = err.detail || err.title;
      return m ? acc + m : acc;
    }, "");

    if (message) {
      result.message = message;
    }
    result.serverErrors = error;
  }

  return result;
};
