// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import get from "lodash-es/get";
import PropTypes from "prop-types";

import { withQuery } from "utils/graphql";
import { calulateOffsetFromPage } from "utils/pagination";

import { ITEMS_PER_PAGE } from "./constants";
import { listSoftwarePackagesQuery } from "./gql/listSoftwarePackagesQuery";
import { currentVersionPropTypes } from "./propTypes";
import { SelectSoftwarePackageModalRend } from "./SelectSoftwarePackageModalRend";

const adaptSoftwarePackage = ({ id, name, versions }) => ({
  id,
  name,
  versions: (versions || []).map((version) => ({
    id: version.id,
    name: version.version,
  })),
});

@withQuery({ name: "softwarePackages", query: listSoftwarePackagesQuery })
export class SelectSoftwarePackageModalData extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    currentVersion: currentVersionPropTypes,

    // from @withQuery
    softwarePackagesQuery: PropTypes.func.isRequired,
  };

  state = {
    currentVersion: null,
  };

  componentDidUpdate(prevProps) {
    const { currentVersion, isOpen } = this.props;

    if (prevProps.isOpen !== isOpen) {
      this.setState({ currentVersion });
    }
  }

  fetchSoftwarePackages = async (queryParams) => {
    const { softwarePackagesQuery } = this.props;

    const result = await softwarePackagesQuery({
      variables: {
        pagination: {
          offset: calulateOffsetFromPage(queryParams.page, ITEMS_PER_PAGE),
          limit: ITEMS_PER_PAGE,
        },
        search: {
          query: queryParams.search || "",
        },
      },
    });

    const countPath = "softwarePackages.softwarePackages.pagination.count";
    const resultsPath = "softwarePackages.softwarePackages.items";
    const count = get(result, countPath, 0);
    const results = get(result, resultsPath, []);

    return {
      meta: {
        count,
      },
      results: results.map(adaptSoftwarePackage),
    };
  };

  setCurrentVersion = (version) => {
    this.setState({ currentVersion: version });
  };

  handleSubmit = () => {
    this.props.onClose();
    this.props.onSubmit(this.state.currentVersion);
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { currentVersion } = this.state;

    return (
      <SelectSoftwarePackageModalRend
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={this.handleSubmit}
        currentVersion={currentVersion}
        setCurrentVersion={this.setCurrentVersion}
        fetchSoftwarePackages={this.fetchSoftwarePackages}
      />
    );
  }
}
