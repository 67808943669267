// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { gql } from "@apollo/client";

import { deviceInventoryQueryFragment } from "../../_shared/DeviceInventoryTable";

export const getDeviceInventoryQuery = gql`
  query devices(
    $pagination: PaginationInput!
    $search: FilterSearchInput
    $sort: DevicesSortInput
  ) {
    devices(pagination: $pagination, search: $search, sort: $sort) {
      devices {
        ...DeviceInventoryFragment
      }
    }
  }
  ${deviceInventoryQueryFragment}
`;
