// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import { Section } from "components/layout";
import { trans } from "src/translations";

import { SignaturesTable } from "../components/SignaturesTable";
import { DevicePropType } from "../propTypes";

export function SignaturesTab({ device }) {
  return (
    <Fragment>
      <Section header={trans.DEVICES__DEVICE_DETAILS_TAB_SIGNATURES()}>
        <SignaturesTable signatures={device.supportedSignatureAlgorithms} />
      </Section>
    </Fragment>
  );
}

SignaturesTab.propTypes = {
  device: DevicePropType,
};
