// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { useEffect, useRef } from "react";
import * as React from "react";

import { Field, useField } from "formik";
import { isEqual, difference, union } from "lodash-es";
import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { Label } from "components/form";
import { Radio } from "components/form/elements/Radio";
import { withCountries } from "utils/continents";

import Countries from "./Countries";

export const MARKET_WORLDWIDE = "worldwide";
export const MARKET_COUNTRIES = "countries";

function useDeepComparision(value) {
  const ref = useRef(value);
  const id = useRef(0);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
    id.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => ref.current, [id.current]);
}

function useDeepCompareEffect(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useEffect(callback, useDeepComparision(dependencies));
}

export const LocationComp = ({
  required,
  description,
  worldwideLabel,
  countriesLabel,
  countriesLoading,
  allCountries,
  countriesError,
  dataTestId,
  tooltip,
  getFieldMeta,
  locationCodesField,
  locationMarketField,
  setFieldValue,
  mainLocationCodes,
}) => {
  const [field, meta, helpers] = useField(locationCodesField);
  const prevMainLocationCodes = useRef(mainLocationCodes);
  const { value: locationMarketValue } = getFieldMeta(locationMarketField);

  useDeepCompareEffect(() => {
    if (!mainLocationCodes) {
      return;
    }
    const values = meta.value ?? [];
    let newValue;
    if (prevMainLocationCodes.current.length > mainLocationCodes.length) {
      const countryToRemove = difference(
        prevMainLocationCodes.current,
        mainLocationCodes
      );
      newValue = values.filter(
        (countryCode) => !countryToRemove.includes(countryCode)
      );
    } else {
      newValue = union(mainLocationCodes, values);
    }

    if (!isEqual(values.slice().sort(), newValue.slice().sort())) {
      helpers.setValue(newValue);
    }
  }, [mainLocationCodes, meta, locationMarketValue, allCountries]);

  useEffect(() => {
    if (
      mainLocationCodes &&
      !isEqual(mainLocationCodes, prevMainLocationCodes.current)
    ) {
      prevMainLocationCodes.current = mainLocationCodes;
    }
  });

  if (countriesLoading) {
    return <Loader />;
  }

  if (countriesError) {
    return <Info type="error">{countriesError.message || countriesError}</Info>;
  }

  return (
    <div data-test-id={dataTestId}>
      <Label text={description} required={required} tooltip={tooltip} />
      <Field name={locationMarketField}>
        {({ field, meta }) => (
          <Radio
            {...field}
            {...meta}
            defaultValue={MARKET_WORLDWIDE}
            dataTestId="market-worldwide-field"
            onChange={(e) => {
              setFieldValue(
                locationCodesField,
                allCountries.map((c) => c.code)
              );
              field.onChange(e);
            }}
          >
            {worldwideLabel}
          </Radio>
        )}
      </Field>
      <Field name={locationMarketField}>
        {({ field, meta }) => (
          <Radio
            {...field}
            {...meta}
            defaultValue={MARKET_COUNTRIES}
            dataTestId="market-countries-field"
            onChange={(e) => {
              setFieldValue(locationCodesField, []);
              field.onChange(e);
            }}
          >
            {countriesLabel}
          </Radio>
        )}
      </Field>
      {locationMarketValue === MARKET_COUNTRIES && (
        <div>
          <Countries
            locationCodes={{
              value: field.value || [],
              error: meta.error,
              touched: meta.touched,
              name: field.name,
            }}
            disabledLocationCodes={mainLocationCodes ?? []}
            onCountriesCodes={(countryCode) => {
              helpers.setValue(countryCode);
              helpers.setTouched(true);
            }}
            allCountries={allCountries}
          />
        </div>
      )}
    </div>
  );
};

LocationComp.propTypes = {
  dataTestId: PropTypes.string,
  required: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  worldwideLabel: PropTypes.string.isRequired,
  countriesLabel: PropTypes.string.isRequired,
  mainLocationCodes: PropTypes.array,
  // from @Formik
  getFieldMeta: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  /** field names */
  locationCodesField: PropTypes.string.isRequired,
  locationMarketField: PropTypes.string.isRequired,

  // from @withCountries
  countriesLoading: PropTypes.bool.isRequired,
  countriesError: PropTypes.object,
  allCountries: PropTypes.array.isRequired,
  tooltip: PropTypes.string,
};
LocationComp.defaultProps = {
  dataTestId: "locations",
};
export const Locations = withCountries(LocationComp);
