// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { apiThatThrows } from "containers/Request";

import {
  DEVICE_TYPE_MODEL,
  DEVICE_TYPE_FAMILY,
  DEVICE_TYPE_DISTRIBUTOR,
  DEVICE_TYPE_NOT_ASSIGNED,
} from "../constants";
import { TargetFieldModePropType } from "../propTypes";
import { DevicesModal } from "./DevicesModal";

@connect(null, (dispatch, ownProps) => ({
  getAllFamilies: () => {
    if (ownProps.forAdmin) {
      return dispatch(
        apiThatThrows.getDeviceFamiliesPaginated.action({
          queryParams: {
            limit: "nolimit",
            owner: ownProps.useDistributorFilter
              ? ownProps.distributorId
              : null,
          },
        })
      );
    }
    return dispatch(
      apiThatThrows.getMyFamiliesPaginated.action({
        queryParams: { limit: "nolimit" },
      })
    );
  },
  getDeviceModels: () => {
    if (ownProps.forAdmin) {
      return dispatch(
        apiThatThrows.getDeviceModelsPaginated.action({
          queryParams: {
            limit: "nolimit",
            owner: ownProps.useDistributorFilter
              ? ownProps.distributorId
              : null,
          },
        })
      );
    }
    return dispatch(
      apiThatThrows.getMyDeviceModelsPaginated.action({
        queryParams: {
          limit: "nolimit",
        },
      })
    );
  },
  getOrganizationPublicInfo: (id) => {
    return dispatch(
      apiThatThrows.getOrganizationPublicInfo.action({
        params: { organizationId: id },
      })
    );
  },
  getDistributorOrganizations: (id) => {
    if (ownProps.useDistributorFilter) {
      return dispatch(
        apiThatThrows.getOrganizationsPaginated.action({
          queryParams: {
            limit: "nolimit",
            type: "Distributor",
            id,
          },
        })
      );
    }
    return dispatch(
      apiThatThrows.getOrganizationsPaginated.action({
        queryParams: {
          limit: "nolimit",
          type: "Distributor",
        },
      })
    );
  },
}))
export class DevicesModalData extends Component {
  static propTypes = {
    forAdmin: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
    distributorId: PropTypes.string,
    isDeviceChecked: PropTypes.func.isRequired,
    onToggleManyDevices: PropTypes.func.isRequired,
    selectedQuery: PropTypes.object,
    hasNext: PropTypes.bool,
    selectedDevices: PropTypes.object,
    onNext: PropTypes.func.isRequired,
    onSelectQuery: PropTypes.func,
    onChangeMode: PropTypes.func.isRequired,
    mode: PropTypes.string,
    targetFieldMode: TargetFieldModePropType,
    useDistributorFilter: PropTypes.bool,

    // from @connect
    getAllFamilies: PropTypes.func,
    getDeviceModels: PropTypes.func,
    getDistributorOrganizations: PropTypes.func.isRequired,
    getOrganizationPublicInfo: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    error: null,
    families: [],
    models: [],
    distributors: [],
  };

  componentDidUpdate(prevProps) {
    const { distributorId, forAdmin, mode } = this.props;
    if (
      prevProps.distributorId !== distributorId ||
      prevProps.forAdmin !== forAdmin ||
      (prevProps.mode === DEVICE_TYPE_NOT_ASSIGNED &&
        mode !== DEVICE_TYPE_NOT_ASSIGNED)
    ) {
      this.updateData();
    }
  }

  fetchData = (mode) => {
    switch (mode) {
      case DEVICE_TYPE_MODEL:
        return Promise.all([this.fetchModels(), this.fetchDistributors()]);
      case DEVICE_TYPE_FAMILY:
        return Promise.all([this.fetchFamilies(), this.fetchDistributors()]);
      case DEVICE_TYPE_DISTRIBUTOR:
        return this.fetchDistributors();
      default:
        return Promise.resolve();
    }
  };

  setData = (data, mode) => {
    if (mode === DEVICE_TYPE_MODEL) {
      this.setState({ models: data[0], distributors: data[1] });
    } else if (mode === DEVICE_TYPE_FAMILY) {
      this.setState({ families: data[0], distributors: data[1] });
    } else if (mode === DEVICE_TYPE_DISTRIBUTOR) {
      this.setState({ distributors: data });
    }
  };

  async updateData(changedMode) {
    this.setState({
      loading: true,
      error: null,
    });

    const mode = changedMode || this.props.mode;

    try {
      const result = await this.fetchData(mode);
      this.setData(result, mode);
    } catch (error) {
      this.setState({
        error: error.message,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  fetchDistributors = async () => {
    const {
      getOrganizationPublicInfo,
      getDistributorOrganizations,
      forAdmin,
      distributorId,
      useDistributorFilter,
    } = this.props;

    const { results } = forAdmin
      ? await getDistributorOrganizations(
          useDistributorFilter ? distributorId : null
        )
      : await getOrganizationPublicInfo(distributorId);

    if (Array.isArray(results)) {
      return results.map((org) => ({
        type: DEVICE_TYPE_DISTRIBUTOR,
        id: org.id,
        name: org.name,
      }));
    }
    return [
      {
        type: DEVICE_TYPE_DISTRIBUTOR,
        id: results.id,
        name: results.name,
      },
    ];
  };

  createFamily = (data) => ({
    type: DEVICE_TYPE_FAMILY,
    id: data.id,
    name: data.name,
    ownerId: data.owner.id,
  });

  fetchFamilies = async () => {
    const { getAllFamilies } = this.props;
    const { results } = await getAllFamilies();
    return (results || []).map(this.createFamily);
  };

  createModel = (data) => ({
    type: DEVICE_TYPE_MODEL,
    id: data.id,
    name: data.name,
    isTesting: data.is_testing,
    isDisabled: !data.is_enabled,
    ownerId: data.owner.id,
  });

  fetchModels = async () => {
    const { getDeviceModels } = this.props;
    const { results } = await getDeviceModels();
    return (results || []).map(this.createModel);
  };

  getItems = () => {
    const { families, models, distributors } = this.state;
    const { mode } = this.props;
    switch (mode) {
      case DEVICE_TYPE_FAMILY:
        return families;
      case DEVICE_TYPE_MODEL:
        return models;
      case DEVICE_TYPE_DISTRIBUTOR:
        return distributors;
      default:
        return [];
    }
  };

  isItemChecked = (item) => Boolean(this.props.selectedDevices[item.id]);

  handleToggleManyDevices = (deviceIds, value) => {
    const { onToggleManyDevices } = this.props;
    const devices = this.getItems().filter((item) =>
      deviceIds.includes(item.id)
    );

    onToggleManyDevices(devices, value);
  };

  handleChangeMode = (e) => {
    this.props.onChangeMode(e);
    this.updateData(e.target.value);
  };

  getModels = () => {
    const { models, distributors } = this.state;

    return models.map((model) => ({
      ...model,
      organizationName: distributors.find((dist) => dist.id === model.ownerId)
        ?.name,
    }));
  };

  getFamilies = () => {
    const { families, distributors } = this.state;

    return families.map((family) => ({
      ...family,
      organizationName: distributors.find((dist) => dist.id === family.ownerId)
        ?.name,
    }));
  };

  render() {
    const {
      isOpen,
      onSelect,
      onClose,
      isDeviceChecked,
      selectedDevices,
      onNext,
      selectedQuery,
      onSelectQuery,
      mode,
      hasNext,
      targetFieldMode,
    } = this.props;
    const { distributors, loading, error } = this.state;

    return (
      <DevicesModal
        isOpen={isOpen}
        hasNext={hasNext}
        loading={loading}
        error={error}
        families={this.getFamilies()}
        models={this.getModels()}
        distributors={distributors}
        selectedDevices={selectedDevices}
        onClose={onClose}
        onSelect={onSelect}
        onChangeMode={this.handleChangeMode}
        mode={mode}
        onToggleManyDevices={this.handleToggleManyDevices}
        isDeviceChecked={isDeviceChecked}
        onNext={onNext}
        selectedQuery={selectedQuery}
        onSelectQuery={onSelectQuery}
        targetFieldMode={targetFieldMode}
      />
    );
  }
}
