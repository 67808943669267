// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Fragment } from "react";

import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";
import { trans } from "src/translations";

import styles from "./ProcessingQueueStatus.scss";

export const PROCESSING_QUEUE_ITEM_STATUSES = {
  PENDING: {
    id: "PENDING",
    label: trans.UPDATES__PROCESSING_QUEUE__STATUS_PENDING(),
  },
  STARTED: {
    id: "STARTED",
    label: trans.UPDATES__PROCESSING_QUEUE__STATUS_STARTED(),
  },
  VALIDATED: {
    id: "VALIDATED",
    label: trans.UPDATES__PROCESSING_QUEUE__STATUS_VALIDATED(),
  },
  DONE: {
    id: "DONE",
    label: trans.UPDATES__PROCESSING_QUEUE__STATUS_DONE(),
    className: styles.success,
  },
  SIGNING_ERROR: {
    id: "SIGNING_ERROR",
    label: trans.UPDATES__PROCESSING_QUEUE__STATUS_SIGNING_ERROR(),
    className: styles.error,
  },
  MANIFEST_ERROR: {
    id: "MANIFEST_ERROR",
    label: trans.UPDATES__PROCESSING_QUEUE__STATUS_MANIFEST_ERROR(),
    className: styles.error,
  },
  OTHER_ERROR: {
    id: "OTHER_ERROR",
    label: trans.UPDATES__PROCESSING_QUEUE__STATUS_OTHER_ERROR(),
    className: styles.error,
  },
};

const renderErrorMessage = (errorMessage) => {
  if (errorMessage == null) {
    return null;
  }

  return (
    <Tooltip
      content={<span className={styles.errorTooltip}>{errorMessage}</span>}
      alignment="bottom-end"
    >
      <GrabsonIcon
        size="lg"
        name="help-filled"
        className={styles.errorMessageIcon}
      />
    </Tooltip>
  );
};

export const ProcessingQueueStatus = ({ status, errorMessage }) => {
  const matchingStatus = PROCESSING_QUEUE_ITEM_STATUSES[status];
  if (!matchingStatus) {
    return null;
  }

  return (
    <Fragment>
      <span className={matchingStatus.className}>{matchingStatus.label}</span>
      {renderErrorMessage(errorMessage)}
    </Fragment>
  );
};

ProcessingQueueStatus.propTypes = {
  status: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};
