// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { RestrictedArea, ROLES } from "containers/Permissions";
import { trans } from "src/translations";

import { ChangeMenu } from "../_shared";
import { SupportLevelChangeModal } from "./SupportLevelChangeModal";

export class SupportLevel extends Component {
  static propTypes = {
    isPremium: PropTypes.bool,
    onSupportLevelChange: PropTypes.func.isRequired,
  };

  state = {
    isModalOpen: false,
  };

  handleDropdownSelected = (nextIsPremium) => () => {
    const { isPremium } = this.props;

    if (isPremium !== nextIsPremium) {
      this.setState({ isModalOpen: true });
    }
  };

  hideModal = () => {
    this.setState({ isModalOpen: false });
  };

  confirmSupportLevelChange = (values) => {
    const { onSupportLevelChange } = this.props;
    onSupportLevelChange(values);
    this.setState({ isModalOpen: false });
  };

  render() {
    const { isPremium } = this.props;
    const { isModalOpen } = this.state;

    return (
      <RestrictedArea
        allowed={[ROLES.moderator.contentManager]}
        fallbackChildren={
          <p>{isPremium ? trans.PREMIUM() : trans.STANDARD()}</p>
        }
      >
        <ChangeMenu
          value={isPremium ? trans.PREMIUM() : trans.STANDARD()}
          options={[
            {
              id: "standard",
              text: trans.STANDARD(),
              onClick: this.handleDropdownSelected(false),
            },
            {
              id: "premium",
              text: trans.PREMIUM(),
              onClick: this.handleDropdownSelected(true),
            },
          ]}
        />
        <SupportLevelChangeModal
          nextIsPremium={!isPremium}
          isOpen={isModalOpen}
          onSave={this.confirmSupportLevelChange}
          onClose={this.hideModal}
        />
      </RestrictedArea>
    );
  }
}
