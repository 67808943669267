// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { TableRow, TableRowColumn } from "components/layout";
import { ActionMenu, MenuItem } from "components/popups";
import { Link } from "components/typography";
import { updateJobFormLocationWithVersion } from "pages/Updates/UpdateJobForm/utils";
import { trans } from "src/translations";

import { versionPropTypes, packagePropTypes } from "./propTypes";
import styles from "./SoftwarePackage.scss";

export class SoftwarePackageVersionRow extends PureComponent {
  static propTypes = {
    version: versionPropTypes,
    softwarePackage: packagePropTypes,
  };

  getUpdateJobFormLink = () => {
    const { version, softwarePackage } = this.props;
    return updateJobFormLocationWithVersion(
      version.id,
      softwarePackage.name,
      version.version
    );
  };

  getVersionLink = () => {
    const {
      softwarePackage,
      version: { id },
    } = this.props;
    return `/updates/packages/${softwarePackage.id}/version/${id}`;
  };

  render() {
    return (
      <TableRow>
        <TableRowColumn
          className={styles.packageVersion}
          dataTestId="name-column"
        >
          <div>
            <Link to={this.getVersionLink()}>
              <h4 className={styles.name}>{this.props.version.version}</h4>
            </Link>
          </div>
        </TableRowColumn>
        <TableRowColumn dataTestId="version-column" />
        <TableRowColumn dataTestId="action-column" className={styles.count}>
          <ActionMenu>
            <MenuItem
              {...this.getUpdateJobFormLink()}
              dataTestId="create-update-job-item"
            >
              {trans.UPDATES__SOFTWARE_PACKAGES_DEPLOY_UPDATE_ACTION()}
            </MenuItem>
            <MenuItem to={this.getVersionLink()} dataTestId="details-item">
              {trans.UPDATES__SOFTWARE_PACKAGES_PACKAGE_DETAILS_ACTION()}
            </MenuItem>
          </ActionMenu>
        </TableRowColumn>
      </TableRow>
    );
  }
}
