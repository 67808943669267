// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import { TableRowColumn, TableRow } from "components/layout";
import { formatDateTime } from "utils/date";

import { ProcessingQueueItemPropType } from "./propTypes";

export class ProcessingQueueItemRow extends PureComponent {
  static propTypes = {
    item: ProcessingQueueItemPropType,
  };

  render() {
    const { item } = this.props;

    return (
      <TableRow key={item.id}>
        <TableRowColumn dataTestId="name-column">
          <span title={item.name}>{item.name}</span>
        </TableRowColumn>

        <TableRowColumn dataTestId="action-date-column">
          {formatDateTime(item.actionDate)}
        </TableRowColumn>

        <TableRowColumn
          dataTestId="status-column"
          className={item.status.className}
        >
          {item.status.label}
        </TableRowColumn>

        <TableRowColumn dataTestId="action-column" />
      </TableRow>
    );
  }
}
