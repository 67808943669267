// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Modal } from "components/layout";
import { trans } from "src/translations";

export class SwitchOngoingCampaignModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    isCampaignEnabled: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: false,
  };

  handleConfirm = async () => {
    const { onConfirm, onClose, isCampaignEnabled } = this.props;
    await onConfirm(!isCampaignEnabled);
    onClose();
  };

  render() {
    const { isOpen, onClose, isCampaignEnabled } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <ButtonsWrapper>
            <Button type="normal" onClick={onClose}>
              {trans.CANCEL()}
            </Button>
            <Button type="green" onClick={this.handleConfirm}>
              {trans.CONFIRM()}
            </Button>
          </ButtonsWrapper>
        }
      >
        <p>
          {trans.CAMPAIGN_LIST__SWITCH_ONGOING_CAMPAIGN_MODAL_TEXT({
            action: isCampaignEnabled
              ? trans.CAMPAIGN_LIST__SWITCH_ONGOING_CAMPAIGN_MODAL_DISABLE_ACTION()
              : trans.CAMPAIGN_LIST__SWITCH_ONGOING_CAMPAIGN_MODAL_ENABLE_ACTION(),
          })}
        </p>
      </Modal>
    );
  }
}
