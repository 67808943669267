// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Loader } from "components/elements";
import { Info } from "components/feedback";
import { withActiveOrganization } from "containers/Auth/decorators";
import { restrictedArea } from "containers/Permissions/restrictedAreaDecorator";
import { ROLES } from "containers/Permissions/roles";
import { withRouter } from "utils/decorators";
import { getGqlErrorMessage } from "utils/errors";
import { withQuery, withMutation, queryResultType } from "utils/graphql";
import { calulateOffsetFromPage } from "utils/pagination";

import {
  CREATE_INITIAL_VALUES,
  TARGET_DEVICES_DEFAULT_OFFSET,
  TARGET_DEVICES_ITEMS_PER_PAGE,
} from "./constants";
import {
  countFiltersAndSoftwarePackagesQuery,
  createUpdateJobMutation,
  getUpdateJobQuery,
} from "./gql";
import { listDeviceFiltersQuery } from "./gql/listDeviceFiltersQuery";
import { UpdateJobFormRend } from "./UpdateJobFormRend";
import { adaptCreateDataForGql, createInitialDataWithClone } from "./utils";

const adaptDeviceFilter = ({ id, name, devices }) => ({
  id,
  name,
  deviceCount: devices?.pagination?.count || 0,
});

@withRouter
@withActiveOrganization
@restrictedArea(() => ({ allowed: [ROLES.softwareUpdates.updateManager] }))
@withQuery({
  name: "countFiltersAndSoftwarePackages",
  query: countFiltersAndSoftwarePackagesQuery,
})
@withQuery({ name: "deviceFilters", query: listDeviceFiltersQuery })
@withQuery({
  name: "getUpdateJob",
  query: getUpdateJobQuery,
})
@withMutation({
  name: "createUpdateJob",
  mutation: createUpdateJobMutation,
})
export class UpdateJobFormData extends PureComponent {
  static propTypes = {
    // from @withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,

    // from @withRouter
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,

    // from @withQuery
    countFiltersAndSoftwarePackagesQuery: PropTypes.func,
    countFiltersAndSoftwarePackagesQueryStatus: queryResultType,
    deviceFiltersQuery: PropTypes.func.isRequired,
    getUpdateJobQuery: PropTypes.func,
    getUpdateJobQueryStatus: queryResultType,

    // from @withMutation
    createUpdateJobMutation: PropTypes.func,
  };

  componentDidMount() {
    this.fetchCountFiltersAndSoftwarePackages();
    this.fetchInitialData();
  }

  componentDidUpdate(prevProps) {
    const organizationId = this.props.activeOrganization.id;

    if (prevProps.activeOrganization.id !== organizationId) {
      this.fetchCountFiltersAndSoftwarePackages();
      this.fetchInitialData();
    }
  }

  fetchCountFiltersAndSoftwarePackages = () => {
    const { countFiltersAndSoftwarePackagesQuery } = this.props;

    countFiltersAndSoftwarePackagesQuery();
  };

  fetchDeviceFilters = async (queryParams) => {
    const { deviceFiltersQuery } = this.props;

    const result = await deviceFiltersQuery({
      variables: {
        pagination: {
          offset: calulateOffsetFromPage(
            queryParams.page,
            TARGET_DEVICES_ITEMS_PER_PAGE
          ),
          limit: TARGET_DEVICES_ITEMS_PER_PAGE,
        },
        search: {
          query: queryParams.search || "",
        },
        devicesPagination: {
          offset: TARGET_DEVICES_DEFAULT_OFFSET,
          limit: TARGET_DEVICES_ITEMS_PER_PAGE,
        },
      },
    });

    const count = result?.deviceFilters?.deviceFilters?.pagination?.count || 0;
    const results = result?.deviceFilters?.deviceFilters?.items || [];

    return {
      meta: {
        count,
      },
      results: results.map(adaptDeviceFilter),
    };
  };

  fetchInitialData() {
    const { location, getUpdateJobQuery } = this.props;

    const jobId = location?.state?.updateJobId ?? "";
    if (jobId.length > 0) {
      getUpdateJobQuery({
        variables: {
          id: jobId,
          devicesPagination: {
            offset: TARGET_DEVICES_DEFAULT_OFFSET,
            limit: TARGET_DEVICES_ITEMS_PER_PAGE,
          },
        },
      });
    }
  }

  createInitialData() {
    const { location, getUpdateJobQueryStatus } = this.props;

    const jobId = location?.state?.updateJobId ?? "";
    if (jobId.length > 0) {
      const initialData = createInitialDataWithClone(getUpdateJobQueryStatus);
      return {
        ...initialData,
        error: initialData.error && getGqlErrorMessage(initialData.error),
      };
    }

    return {
      loading: false,
      error: undefined,
      data: {
        ...CREATE_INITIAL_VALUES,
        targetDevices:
          location?.state?.targetDevices ?? CREATE_INITIAL_VALUES.targetDevices,
        softwarePackageVersion:
          location?.state?.softwarePackageVersion ??
          CREATE_INITIAL_VALUES.softwarePackageVersion,
        numberOfSteps: CREATE_INITIAL_VALUES.deviceQuantityPerStep.length + 1,
      },
    };
  }

  createUpdateJob = async (values) => {
    const { createUpdateJobMutation } = this.props;
    const input = adaptCreateDataForGql(values);

    await createUpdateJobMutation({
      variables: {
        input: input,
      },
    });
  };

  getHasDeviceFilterAndSoftwarePackage() {
    const { data, loading, error } =
      this.props.countFiltersAndSoftwarePackagesQueryStatus;

    // no point in this form if we do not have either device filter or software package
    const deviceFiltersCount =
      data?.deviceFilters?.deviceFilters?.pagination?.count ?? 0;
    const softwarePackagesCount =
      data?.softwarePackages?.softwarePackages?.pagination?.count ?? 0;
    const hasAllRequirements =
      deviceFiltersCount > 0 && softwarePackagesCount > 0;

    return {
      data: hasAllRequirements,
      loading,
      error: error && getGqlErrorMessage(error),
    };
  }

  render() {
    const requirements = this.getHasDeviceFilterAndSoftwarePackage();
    const initialData = this.createInitialData();

    if (
      requirements.loading ||
      initialData.loading ||
      initialData.data === undefined
    ) {
      return <Loader />;
    }

    if (requirements.error) {
      return <Info type="error">{requirements.error}</Info>;
    }

    if (initialData.error) {
      return <Info type="error">{initialData.error}</Info>;
    }

    return (
      <UpdateJobFormRend
        createUpdateJob={this.createUpdateJob}
        fetchDeviceFilters={this.fetchDeviceFilters}
        initialValues={initialData.data}
        showRequirementsWarning={!requirements.data}
        navigate={this.props.navigate}
      />
    );
  }
}
