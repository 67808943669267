// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export const REQUEST = "request/REQUEST";
export const CACHE = "request/CACHE";
export const REQUEST_FAILURE = "request/REQUEST_FAILURE";
export const REQUEST_SUCCESS = "request/REQUEST_SUCCESS";
export const CACHE_SUCCESS = "request/CACHE_SUCCESS";
export const CACHE_REMOVE = "request/CACHE_REMOVE";
