// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import PropTypes from "prop-types";

import { Button, ButtonsWrapper } from "components/buttons";
import { Info } from "components/feedback";
import { PageHeader, PageContent } from "components/layout";
import { Tabs, Tab, TabContent } from "components/navigation";
import { withActiveOrganization } from "containers/Auth/decorators";
import { RestrictedArea } from "containers/Permissions";
import { ALL_PROVIDERS } from "containers/Permissions/groups";
import { api } from "containers/Request";
import { ResetFormOnOrgChange } from "pages/_shared";
import { trans } from "src/translations";
import { prepareErrorsForForm } from "utils/errors";

import styles from "./SnapCreate.scss";
import { SnapNameSection } from "./SnapNameSection";

const jsonPointerToFieldName = {
  name: "name",
};
@withActiveOrganization
@connect(null, (dispatch) => ({
  createApplication: (body) =>
    dispatch(api.createSnapApplication.action({ options: { body } })),
}))
export class SnapCreate extends Component {
  static propTypes = {
    // @from withActiveOrganization
    activeOrganization: PropTypes.object.isRequired,

    // @from @connect
    createApplication: PropTypes.func.isRequired,
    // from @ExtendedRoute
    navigate: PropTypes.func.isRequired,
  };

  createSnapApplication = async (values) => {
    const { name } = values;
    const { createApplication, activeOrganization } = this.props;

    const body = JSON.stringify({
      name,
      owner_id: activeOrganization.id,
    });
    return await createApplication(body);
  };

  submit = async (values, { setErrors }) => {
    const { navigate } = this.props;

    const { result, error } = await this.createSnapApplication(values);

    if (error) {
      const submissionError = prepareErrorsForForm(
        error,
        jsonPointerToFieldName
      );
      throw new setErrors({ ...submissionError, _submitFailed: true });
    }

    if (result && result.broadcaster_id) {
      navigate(`/snap/${result.broadcaster_id}/edit/`);
    }
  };

  render() {
    return (
      <>
        <PageHeader>Create Snap Application</PageHeader>
        <PageContent>
          <RestrictedArea allowed={ALL_PROVIDERS}>
            <Formik onSubmit={this.submit} initialValues={{ name: "" }}>
              {({ dirty, errors, isSubmitting }) => (
                <Form aria-label="form">
                  <Tabs>
                    <Tab to="/snap/create" text={trans.INTRODUCTION()} />
                  </Tabs>
                  <TabContent>
                    <SnapNameSection />

                    <ButtonsWrapper position="center">
                      <Button
                        buttonType="submit"
                        disabled={!dirty || isSubmitting}
                        type="wizardNormal"
                        size="large"
                        className={styles.buttonAlignCenter}
                      >
                        {trans.START()}
                      </Button>
                    </ButtonsWrapper>

                    {errors?._error && (
                      <Info type="error">{errors._error}</Info>
                    )}
                  </TabContent>
                  <ResetFormOnOrgChange />
                </Form>
              )}
            </Formik>
          </RestrictedArea>
        </PageContent>
      </>
    );
  }
}
