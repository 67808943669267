// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";
import { connect } from "react-redux";

import get from "lodash-es/get";
import pick from "lodash-es/pick";
import PropTypes from "prop-types";

import { withActiveOrganization } from "containers/Auth/decorators";
import { profilePropTypes } from "containers/Auth/propTypes";

import { checkAccess, checkOrganizationTypeAccess } from "./checkAccess";

/**
 * Checks if currently logged in user has permissions. Invokes `children` function
 * with the check result. Since children is a function, we can
 * e.g. conditionally disable some content.
 *
 * ```jsx
 * <Permissions allowed={[ROLES.organization.roleManager]}>
 *    {isAllowed => ... }
 * </Permissions>
 * ```
 *
 * This component also has HOC version as [`@permissions`](#permissionsdecorator).
 *
 * @example ./Permissions.md
 */
@withActiveOrganization
@connect(({ auth }) => ({
  currentUser: auth.profile,
}))
class Permissions extends Component {
  static propTypes = {
    /**
     * Array of Strings and/or functions `({organizationId, roles}) => boolean`.
     *
     * Injected `isAllowed` boolean is true if `allowed` array is empty or user has at least one role listed in the `allowed` array or at least one function supplied via this array returns true. Otherwise, permissions are denied.
     */
    // eslint-disable-next-line react/no-unused-prop-types
    allowed: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    ),
    // eslint-disable-next-line react/no-unused-prop-types
    allowedOrganizationTypes: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function that renders children depending on `isAllowed` boolean
     *
     * Type: (isAllowed: boolean) => React.Node
     */
    children: PropTypes.func.isRequired,

    // from @withActiveOrganization
    /** @ignore */
    activeOrganization: PropTypes.shape({
      roles: PropTypes.array.isRequired,
    }),

    // from @connect
    /** @ignore */
    // eslint-disable-next-line react/no-unused-prop-types
    currentUser: profilePropTypes,
  };

  static defaultProps = {
    allowed: [],
    allowedOrganizationTypes: [],
  };

  isDenied = () => {
    const { allowed, allowedOrganizationTypes, activeOrganization } =
      this.props;

    const isDeniedForUserRole = !checkAccess(
      pick(this.props, ["activeOrganization", "currentUser"]),
      allowed
    );
    const isDeniedForOrganizationType = !checkOrganizationTypeAccess(
      get(activeOrganization, "types", []),
      allowedOrganizationTypes
    );

    return isDeniedForUserRole || isDeniedForOrganizationType;
  };

  render() {
    const { children } = this.props;
    return children(!this.isDenied());
  }
}

// styleguidist does not like exporting classes that use decorators
export { Permissions };
