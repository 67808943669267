// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { RadioGroupField } from "components/form/fields";
import { Column } from "components/layout";
import { Header } from "components/typography";
import {
  FIELDS,
  GRAPHICS_DIMENSIONS,
  SECTIONS,
  RADIO_DEFAULT,
} from "pages/Snap/shared/constants";
import { LogoUploader } from "pages/Snap/SnapEdit/components/SnapGraphics/LogoUploader";
import { cropImage, validateImage } from "utils/image";

const STATIC_TEXT = {
  LOGO_FORMATS: ", PNG OR JPEG",
  BACKGROUND_FORMATS: ", JPEG",
};

const GRAPHICS_FIELDS = [
  {
    name: FIELDS.LOGO.name,
    label: FIELDS.LOGO.label,
    formats: GRAPHICS_DIMENSIONS.LOGO.formats,
    mode: GRAPHICS_DIMENSIONS.LOGO.mode,
    info: `${GRAPHICS_DIMENSIONS.LOGO.width} x ${GRAPHICS_DIMENSIONS.LOGO.height}${STATIC_TEXT.LOGO_FORMATS}`,
    imageValidationOptions: {
      minWidth: GRAPHICS_DIMENSIONS.LOGO.width,
      minHeight: GRAPHICS_DIMENSIONS.LOGO.height,
      maxSize: GRAPHICS_DIMENSIONS.LOGO.size,
    },
    uploaderDataTestId: "logo-field",
    graphicDimensions: [
      GRAPHICS_DIMENSIONS.LOGO.width,
      GRAPHICS_DIMENSIONS.LOGO.height,
    ],
    kind: "logo",
  },
  {
    name: FIELDS.BACKGROUND.name,
    label: FIELDS.BACKGROUND.label,
    formats: GRAPHICS_DIMENSIONS.BACKGROUND.formats,
    mode: GRAPHICS_DIMENSIONS.BACKGROUND.mode,
    info: `${GRAPHICS_DIMENSIONS.BACKGROUND.width} x ${GRAPHICS_DIMENSIONS.BACKGROUND.height}${STATIC_TEXT.BACKGROUND_FORMATS}`,
    imageValidationOptions: {
      minWidth: GRAPHICS_DIMENSIONS.BACKGROUND.width,
      minHeight: GRAPHICS_DIMENSIONS.BACKGROUND.height,
      maxSize: GRAPHICS_DIMENSIONS.BACKGROUND.size,
      validateAspectRatio: true,
    },
    uploaderDataTestId: "background-field",
    graphicDimensions: [
      GRAPHICS_DIMENSIONS.BACKGROUND.width,
      GRAPHICS_DIMENSIONS.BACKGROUND.height,
    ],
    kind: "background",
  },
];

export const SnapGraphics = ({ appId }) => {
  return (
    <Column>
      <Header color="secondary" uppercase={true} bold={true}>
        {SECTIONS.GRAPHICS.header}
      </Header>
      {GRAPHICS_FIELDS.map(
        ({
          name,
          label,
          formats,
          mode,
          info,
          imageValidationOptions,
          uploaderDataTestId,
          graphicDimensions,
          kind,
        }) => (
          <LogoUploader
            key={uploaderDataTestId}
            name={`${appId}.${name}`}
            label={label}
            formats={formats}
            mode={mode}
            info={info}
            validation={(blobUrl) =>
              validateImage(blobUrl, imageValidationOptions)
            }
            preprocess={(blobUrl, type) =>
              cropImage(blobUrl, type, ...graphicDimensions)
            }
            required={false}
            uploaderDataTestId={uploaderDataTestId}
            kind={kind}
          />
        )
      )}
      <RadioGroupField
        name={`${appId}.${FIELDS.AUTODARKEN_BG.name}`}
        label={FIELDS.AUTODARKEN_BG.label}
        inline={true}
        values={RADIO_DEFAULT}
        dataTestId="autodarken-radio-group"
        required={false}
      />
    </Column>
  );
};

SnapGraphics.propTypes = {
  appId: PropTypes.string.isRequired,
};
