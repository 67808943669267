// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { RestrictedArea } from "containers/Permissions";

export const restrictedArea = (optionsFunc) => (ComposedComponent) => {
  return class Wrapper extends Component {
    static propTypes = {
      allowed: PropTypes.array,
      showFallback: PropTypes.bool,
      fallbackChildren: PropTypes.node,
      redirectionPath: PropTypes.string,
    };

    render() {
      const {
        allowed,
        showFallback,
        fallbackChildren,
        redirectionPath,
        ...composedComponentProps
      } = this.props;

      const restrictedAreaProps = {
        allowed,
        showFallback,
        fallbackChildren,
        redirectionPath,
      };

      let options = {};
      if (optionsFunc && typeof optionsFunc === "function") {
        options = optionsFunc(this.props);
      }

      return (
        <RestrictedArea {...restrictedAreaProps} {...options}>
          <ComposedComponent {...composedComponentProps} />
        </RestrictedArea>
      );
    }
  };
};
