// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import get from "lodash-es/get";

/**
 * Given user provided value, find matching suggestions. All suggested,
 * options are grouped in sections, and we need to preserve that.
 * Remove sections that are empty after filtering.
 */
export const filterShownValues = (value = "", suggestedOptions = []) => {
  const userValue = value.toLowerCase();

  const matchesUserValue = (option) => {
    const suggestion = get(option, "suggestion", "").toLowerCase();
    return userValue.length > 0 && suggestion.startsWith(userValue);
  };

  return suggestedOptions.reduce((filtered, section) => {
    const optionsFiltered = section.options.filter(matchesUserValue);
    if (optionsFiltered.length > 0) {
      filtered.push({
        ...section,
        options: optionsFiltered,
      });
    }
    return filtered;
  }, []);
};
